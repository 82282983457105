import { AutoMetricsType, SymptomTypeConst } from "../../utils/enums";

export function getNewSymptomType(monitorType, autoMetricType, disableTrendMonitor) {
  if (
    [AutoMetricsType.FULL_COMPARE, AutoMetricsType.AGGREGATION_COMPARE].includes(
      autoMetricType
    ) &&
    monitorType !== SymptomTypeConst.MANUAL_THRESHOLD
  ) {
    return SymptomTypeConst.MANUAL_THRESHOLD;
  }

  if (
    [
      AutoMetricsType.NUMERICAL_DISTRIBUTION,
      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
    ].includes(autoMetricType) &&
    [
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
    ].includes(monitorType)
  ) {
    return SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS;
  }

  if (
    disableTrendMonitor &&
    monitorType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND
  ) {
    return SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS;
  }

  return monitorType;
}

export function getSelectedMetric(metricList, selectedMetric) {
  const metricInfo = metricList.filter((kpi) => kpi.metadata.uuid === selectedMetric);
  if (metricInfo.length === 0) {
    console.log(`Can't find metric id ${selectedMetric}`);
    return null;
  }
  return metricInfo[0];
}
