import React from "react";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import compareAsc from "date-fns/compareAsc";
import addDays from "date-fns/addDays";
import addHours from "date-fns/addHours";
import addSeconds from "date-fns/addSeconds";
import addWeeks from "date-fns/addWeeks";
import differenceInSeconds from "date-fns/differenceInSeconds";
import isSameDay from "date-fns/isSameDay";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import subMinutes from "date-fns/subMinutes";
import differenceInDays from "date-fns/differenceInDays";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import getDay from "date-fns/getDay";
import setDay from "date-fns/setDay";
import differenceInWeeks from "date-fns/differenceInWeeks";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import differenceInMonths from "date-fns/differenceInMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfHour from "date-fns/startOfHour";
import endOfHour from "date-fns/endOfHour";
import subSeconds from "date-fns/subSeconds";
import subHours from "date-fns/subHours";
import subDays from "date-fns/subDays";
import subWeeks from "date-fns/subWeeks";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import { getAllTimezones } from "countries-and-timezones";
import {
  SeasonalityTypeConst,
  ScheduleRecurringType,
  TimeDurationUnit,
  DashboardFilterType,
  AggregationWindowType,
} from "./enums";
import moment from "moment";
import { fnSorter } from "./sort";

const MONTH_IN_DAYS = 30;
const WEEK_IN_DAYS = 7;
const YEAR_IN_DAYS = 365;

export const SECOND_IN_SECONDS = 1;
export const MINUTE_IN_SECONDS = 60 * SECOND_IN_SECONDS;
export const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
export const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
export const WEEK_IN_SECONDS = WEEK_IN_DAYS * DAY_IN_SECONDS;
export const MONTH_IN_SECONDS = MONTH_IN_DAYS * DAY_IN_SECONDS;
export const QUARTER_IN_SECONDS = 3 * MONTH_IN_SECONDS;
export const YEAR_IN_SECONDS = YEAR_IN_DAYS * DAY_IN_SECONDS;
export const LEAP_YEAR_IN_SECONDS = 31622400;

export function getDisplayTimeFromSecond(
  seconds,
  isOneUnit = false,
  displayUnitOptions = [],
  isFull = false,
  addSpaceDivider = false
) {
  if (seconds < 0) {
    return "NAN";
  }

  if (seconds === 0) {
    return displayUnitOptions.length > 0 ? `0${displayUnitOptions[0]}` : "0s";
  }

  if (seconds < 1) {
    return `${seconds.toFixed(2)}s`;
  }

  let displayStr = "";
  let units = [
    { seconds: YEAR_IN_SECONDS, displayStr: "y", fullDisplayStr: "year" },
    { seconds: MONTH_IN_SECONDS, displayStr: "mo", fullDisplayStr: "month" },
    { seconds: WEEK_IN_SECONDS, displayStr: "w", fullDisplayStr: "week" },
    { seconds: DAY_IN_SECONDS, displayStr: "d", fullDisplayStr: "day" },
    { seconds: HOUR_IN_SECONDS, displayStr: "h", fullDisplayStr: "hour" },
    { seconds: MINUTE_IN_SECONDS, displayStr: "m", fullDisplayStr: "minute" },
    { seconds: SECOND_IN_SECONDS, displayStr: "s", fullDisplayStr: "second" },
  ];

  if (displayUnitOptions.length > 0) {
    const displayUnitOptionsSet = new Set(displayUnitOptions);
    units = units.filter((unit) => displayUnitOptionsSet.has(unit.displayStr));
  }

  let remainingSeconds = seconds;
  let currentRemainingSeconds = 0;
  let numberInUnit;
  let unit;
  for (unit of units) {
    numberInUnit = Math.floor(remainingSeconds / unit.seconds);
    currentRemainingSeconds = remainingSeconds % unit.seconds;
    // If we require to only return one unit.
    if (isOneUnit && currentRemainingSeconds > 0) {
      continue;
    }

    remainingSeconds = currentRemainingSeconds;
    if (numberInUnit !== 0) {
      let currentDisplayUnitStr = unit.displayStr;
      if (isFull) {
        currentDisplayUnitStr =
          numberInUnit === 1
            ? " " + unit.fullDisplayStr
            : " " + (unit.fullDisplayStr + "s");
        if (displayStr) {
          displayStr = " " + displayStr;
        }
      }

      let space = "";
      if (addSpaceDivider && displayStr) {
        space = " ";
      }
      displayStr += space + numberInUnit + currentDisplayUnitStr;
    }
    if (remainingSeconds === 0) {
      break;
    }
  }
  return displayStr;
}

export const TimeUnitInSeconds = Object.freeze({
  [TimeDurationUnit.SECONDS]: 1,
  [TimeDurationUnit.MINUTES]: MINUTE_IN_SECONDS,
  [TimeDurationUnit.HOURS]: HOUR_IN_SECONDS,
  [TimeDurationUnit.DAYS]: DAY_IN_SECONDS,
  [TimeDurationUnit.WEEKS]: WEEK_IN_SECONDS,
  [TimeDurationUnit.MONTHS]: MONTH_IN_SECONDS,
  [TimeDurationUnit.YEARS]: YEAR_IN_SECONDS,
});

// Returns the largest time unit whole value (in seconds) divides `seconds` without remainder.
export function largestWholeTimeUnitFactor(seconds = 0, allowedTimeUnits = []) {
  if (typeof seconds !== "number" || seconds === 0) {
    return TimeDurationUnit.SECONDS;
  }
  const intSeconds = Math.floor(seconds);
  const wholeFactors = Object.entries(TimeUnitInSeconds).filter(
    ([timeUnit, unitSeconds]) => {
      if (allowedTimeUnits.length > 0 && allowedTimeUnits.indexOf(timeUnit) === -1) {
        return false;
      }

      return intSeconds % unitSeconds === 0;
    }
  );
  wholeFactors.sort(fnSorter(([_unit, unitSeconds]) => unitSeconds, true));
  return wholeFactors?.[0]?.[0]; // largest time unit
}

export function getSecondFromDisplayTime(displayStr) {
  if (!displayStr || displayStr.length === 0) {
    return 0;
  }

  let seconds = 0;
  const units = [
    { seconds: YEAR_IN_SECONDS, displayStr: "y" },
    { seconds: MONTH_IN_SECONDS, displayStr: "mo" },
    { seconds: WEEK_IN_SECONDS, displayStr: "w" },
    { seconds: DAY_IN_SECONDS, displayStr: "d" },
    { seconds: HOUR_IN_SECONDS, displayStr: "h" },
    { seconds: MINUTE_IN_SECONDS, displayStr: "m" },
    { seconds: SECOND_IN_SECONDS, displayStr: "s" },
  ];
  let startIndex = 0;
  let unit;
  for (unit of units) {
    const unitIndex = displayStr.indexOf(unit.displayStr, startIndex);
    if (unitIndex === -1) {
      continue;
    }

    const currentUnitStr = displayStr.substring(startIndex, unitIndex);
    let parsedUnitSeconds = parseInt(currentUnitStr);
    if (isNaN(parsedUnitSeconds)) {
      console.log(`Invalid display time string ${displayStr}`);
      parsedUnitSeconds = 0;
    }
    seconds += parsedUnitSeconds * unit.seconds;
    startIndex = unitIndex + unit.displayStr.length;
    if (startIndex >= displayStr.length) {
      break;
    }
  }

  return seconds;
}

// Todo: Move 'lastDay', 'lastWeek', 'lastMonth' to const enum.
export function getTimePeriodFromString(timePeriodStr, endTime = new Date()) {
  let startTime = endTime;
  if (timePeriodStr === "1h") {
    startTime = subHours(endTime, 1);
  } else if (["1d", "lastDay"].includes(timePeriodStr)) {
    startTime = subDays(endTime, 1);
  } else if (timePeriodStr === "thisWeek") {
    startTime = startOfWeek(endTime);
  } else if (["1w", "lastWeek"].includes(timePeriodStr)) {
    startTime = subDays(endTime, WEEK_IN_DAYS);
  } else if (timePeriodStr === "thisMonth") {
    startTime = startOfMonth(endTime);
  } else if (["lastMonth", "1m"].includes(timePeriodStr)) {
    startTime = subMonths(endTime, 1);
  } else if (["last3Months", "3m"].includes(timePeriodStr)) {
    startTime = subMonths(endTime, 3);
  } else if (timePeriodStr === "last6Months") {
    startTime = subMonths(endTime, 6);
  } else {
    console.log(`Unexpected time period string ${timePeriodStr}`);
  }

  return {
    startTimestamp: getUnixTime(startTime),
    endTimestamp: getUnixTime(endTime),
  };
}

export function getTimePeriodFromSetting(timeSetting) {
  if (!timeSetting) {
    return null;
  }

  if (timeSetting.type === DashboardFilterType.TIME_DURATION) {
    const durationValue = timeSetting.value.duration;
    const { value: numberValue, unit } = durationValue;
    let subFunction = null;
    if (unit === TimeDurationUnit.SECONDS) {
      subFunction = subSeconds;
    } else if (unit === TimeDurationUnit.MINUTES) {
      subFunction = subMinutes;
    } else if (unit === TimeDurationUnit.HOURS) {
      subFunction = subHours;
    } else if (unit === TimeDurationUnit.DAYS) {
      subFunction = subDays;
    } else if (unit === TimeDurationUnit.WEEKS) {
      subFunction = subWeeks;
    } else if (unit === TimeDurationUnit.MONTHS) {
      subFunction = subMonths;
    } else if (unit === TimeDurationUnit.YEARS) {
      subFunction = subYears;
    } else {
      return null;
    }

    const endTime = new Date();
    const startTime = subFunction(endTime, numberValue);

    return {
      startTimestamp: getUnixTime(startTime),
      endTimestamp: getUnixTime(endTime),
    };
  }

  return null;
}

export function getStringFromTimePeriod(timePeriod) {
  let seconds = timePeriod.endTimestamp - timePeriod.startTimestamp;
  if (seconds === MONTH_IN_SECONDS) {
    return "lastMonth";
  } else if (seconds === WEEK_IN_SECONDS) {
    return "lastWeek";
  } else if (seconds === DAY_IN_SECONDS) {
    return "lastDay";
  } else {
    console.log(`Unknown time period ${JSON.stringify(timePeriod)}`);
    return "unknown";
  }
}

export function getStringFromTimeObject(timeObject, options = {}) {
  let formatString = "";
  if (options.onlyTime) {
    formatString = "h:mm:ss a";
  } else if (options.onlyDate) {
    formatString = "MM/dd/yyyy";
  } else if (options.timeline) {
    formatString = "LLL d";
  } else if (options.graph) {
    // time string for plotly
    formatString = "yyyy-MM-dd H:mm:ss";
  } else if (options.longLocalized) {
    formatString = "PP";
  } else if (options.includeWeekDay) {
    formatString = "dddd MMM Do yyyy";
  } else if (options.dateTimeWithTz) {
    return `${format(timeObject, "M/dd/yyyy hh:mm:ss")} ${browserLocaleTimezone()}`;
  } else if (options.formatString) {
    formatString = options.formatString;
  } else if (options.monthDay) {
    formatString = "MMM dd";
  } else {
    formatString = "MM/dd/yyyy h:mm:ss a";
  }
  return format(timeObject, formatString);
}

export function getStringFromTimeStamp(timestamp, options = {}) {
  return getStringFromTimeObject(fromUnixTime(timestamp), options);
}

export function getTimeZoneList() {
  return Object.values(getAllTimezones());
}

export function isTimePeriodSame(period1, period2) {
  return (
    compareAsc(period1.start, period2.start) === 0 &&
    compareAsc(period1.end, period2.end) === 0
  );
}

export function isTimeInPeriod(time, period) {
  return compareAsc(time, period.start) >= 0 && compareAsc(period.end, time) > 0;
}

export function getTimeAxisFromPeriod(startTimeStamp, endTimeStamp, intervals) {
  const diffs = differenceInSeconds(endTimeStamp, startTimeStamp);
  const steps = Math.floor(diffs / intervals);
  const timeSet = [];
  const timeOnlyFormatString = "KK:mma";
  const dateOnlyFormatString = "MM/dd";
  const dateAndTimeFormatString = "MM/dd KK:mma";

  let i = 0;
  let currentTimeStamp = startTimeStamp;
  const timeStampSet = [];
  while (i < intervals) {
    timeStampSet.push(currentTimeStamp);
    currentTimeStamp = addSeconds(currentTimeStamp, steps);
    i++;
  }

  let previousTimeStamp = 0;
  let selectFormatString = "";
  for (i = 0; i < timeStampSet.length; i++) {
    currentTimeStamp = timeStampSet[i];
    if (diffs <= HOUR_IN_SECONDS) {
      selectFormatString = timeOnlyFormatString;
    } else if (i === 0) {
      selectFormatString = dateAndTimeFormatString;
    } else if (diffs <= DAY_IN_SECONDS) {
      selectFormatString = timeOnlyFormatString;
    } else {
      const isTheSameDay = isSameDay(currentTimeStamp, previousTimeStamp);
      const isTheSameTime =
        getHours(currentTimeStamp) === getHours(previousTimeStamp) &&
        getMinutes(currentTimeStamp) === getMinutes(previousTimeStamp);

      if (!isTheSameTime && !isTheSameDay) {
        selectFormatString = dateAndTimeFormatString;
      } else if (isTheSameDay) {
        selectFormatString = timeOnlyFormatString;
      } else {
        selectFormatString = dateOnlyFormatString;
      }
    }
    previousTimeStamp = currentTimeStamp;
    timeSet.push(format(currentTimeStamp, selectFormatString));
  }

  return timeSet;
}

const seasonConfigMapping = {
  [SeasonalityTypeConst.NONE]: { seasonInSeconds: 0, periodInSeconds: 0 },
  [SeasonalityTypeConst.FIFTEEN_MIN_OF_DAY]: {
    seasonInSeconds: 15 * MINUTE_IN_SECONDS,
    periodInSeconds: DAY_IN_SECONDS,
  },
  [SeasonalityTypeConst.ONE_HOUR_OF_DAY]: {
    seasonInSeconds: HOUR_IN_SECONDS,
    periodInSeconds: DAY_IN_SECONDS,
  },
  [SeasonalityTypeConst.EIGHT_HOUR_OF_DAY]: {
    seasonInSeconds: 8 * HOUR_IN_SECONDS,
    periodInSeconds: DAY_IN_SECONDS,
  },
  [SeasonalityTypeConst.FIFTEEN_MIN_OF_WEEK]: {
    seasonInSeconds: 15 * MINUTE_IN_SECONDS,
    periodInSeconds: WEEK_IN_SECONDS,
  },
  [SeasonalityTypeConst.ONE_HOUR_OF_WEEK]: {
    seasonInSeconds: HOUR_IN_SECONDS,
    periodInSeconds: WEEK_IN_SECONDS,
  },
  [SeasonalityTypeConst.EIGHT_HOUR_OF_WEEK]: {
    seasonInSeconds: 8 * HOUR_IN_SECONDS,
    periodInSeconds: WEEK_IN_SECONDS,
  },
  [SeasonalityTypeConst.ONE_DAY_OF_WEEK]: {
    seasonInSeconds: DAY_IN_SECONDS,
    periodInSeconds: WEEK_IN_SECONDS,
  },
};

export function getSeasonInSecondsForSeasonConfig(season) {
  if (!seasonConfigMapping[season]) {
    return 0;
  }

  return seasonConfigMapping[season].seasonInSeconds;
}

// Convert from UI data model to backend data model for seasonality
export function getSeasonConfigFromSeason(season) {
  const { basicSeasonality, customSeasonPeriods } = season;
  if (
    basicSeasonality === SeasonalityTypeConst.AUTO ||
    !seasonConfigMapping[basicSeasonality]
  ) {
    return null;
  }

  const customSeasonPeriodsForServer = customSeasonPeriods.map((customSeasonPeriod) => {
    const { name, timeRanges, repeatIntervalInSeconds, repeatCount } =
      customSeasonPeriod;
    return {
      metadata: {
        name,
      },
      config: {
        timeRanges: timeRanges.map(({ startTimestamp, endTimestamp }) => {
          return {
            startTime: startTimestamp,
            endTime: endTimestamp,
          };
        }),
        repeatIntervalInSeconds,
        repeatCount,
      },
    };
  });

  return {
    ...seasonConfigMapping[basicSeasonality],
    customSchedules: customSeasonPeriodsForServer,
  };
}

// Convert from backend data model to UI data model for seasonality
export function getSeasonFromSeasonConfig(seasonConfig) {
  if (
    !seasonConfig ||
    typeof seasonConfig.seasonInSeconds !== "number" ||
    typeof seasonConfig.periodInSeconds !== "number"
  ) {
    return {
      basicSeasonality: SeasonalityTypeConst.AUTO,
      customSeasonPeriods: [],
    };
  }

  const {
    seasonInSeconds,
    periodInSeconds,
    customSchedules: rawCustomSeasonPeriods = [],
  } = seasonConfig;
  if (seasonInSeconds === 0 && periodInSeconds === 0) {
    return {
      basicSeasonality: SeasonalityTypeConst.NONE,
      customSeasonPeriods: [],
    };
  }

  let basicSeasonality = SeasonalityTypeConst.NONE;
  if (periodInSeconds === DAY_IN_SECONDS) {
    if (seasonInSeconds === 15 * MINUTE_IN_SECONDS) {
      basicSeasonality = SeasonalityTypeConst.FIFTEEN_MIN_OF_DAY;
    } else if (seasonInSeconds === HOUR_IN_SECONDS) {
      basicSeasonality = SeasonalityTypeConst.ONE_HOUR_OF_DAY;
    } else if (seasonInSeconds === 8 * HOUR_IN_SECONDS) {
      basicSeasonality = SeasonalityTypeConst.EIGHT_HOUR_OF_DAY;
    } else {
      console.log(`Invalid season config ${JSON.stringify(seasonConfig)}`);
    }
  } else if (periodInSeconds === WEEK_IN_SECONDS) {
    if (seasonInSeconds === 15 * MINUTE_IN_SECONDS) {
      basicSeasonality = SeasonalityTypeConst.FIFTEEN_MIN_OF_WEEK;
    } else if (seasonInSeconds === HOUR_IN_SECONDS) {
      basicSeasonality = SeasonalityTypeConst.ONE_HOUR_OF_WEEK;
    } else if (seasonInSeconds === 8 * HOUR_IN_SECONDS) {
      basicSeasonality = SeasonalityTypeConst.EIGHT_HOUR_OF_WEEK;
    } else if (seasonInSeconds === DAY_IN_SECONDS) {
      basicSeasonality = SeasonalityTypeConst.ONE_DAY_OF_WEEK;
    } else {
      console.log(`Invalid season config ${JSON.stringify(seasonConfig)}`);
    }
  } else {
    console.log(`Invalid season config ${JSON.stringify(seasonConfig)}`);
  }

  const customSeasonPeriods = rawCustomSeasonPeriods.map((rawCustomSeasonPeriod) => {
    return {
      name: rawCustomSeasonPeriod.metadata.name,
      timeRanges: rawCustomSeasonPeriod.config.timeRanges.map(
        ({ startTime, endTime }) => {
          return {
            startTimestamp: startTime,
            endTimestamp: endTime,
          };
        }
      ),
      repeatIntervalInSeconds: rawCustomSeasonPeriod.config.repeatIntervalInSeconds,
      repeatCount: rawCustomSeasonPeriod.config.repeatCount,
    };
  });

  return {
    basicSeasonality,
    customSeasonPeriods,
  };
}

export function getSeasonFromTimePeriods(startTimestamp, endTimestamp, seasonConfig) {
  const seasons = [];
  if (startTimestamp >= endTimestamp) {
    return seasons;
  }

  const seasonInSeconds = seasonConfig.season;
  const roundStartTimestamp =
    Math.floor(startTimestamp / seasonInSeconds) * seasonInSeconds;
  const roundEndTimestamp =
    Math.floor(endTimestamp / seasonInSeconds) * seasonInSeconds;
  let startTime = fromUnixTime(roundStartTimestamp);
  const endTime = fromUnixTime(roundEndTimestamp);

  while (startTime <= endTime) {
    seasons.push(startTime);
    startTime = addSeconds(startTime, seasonInSeconds);
  }

  return seasons;
}

export function getIncidentQuickTimeOptions(incident) {
  const { startTimestamp, endTimestamp, displayStartTimestamp, displayEndTimestamp } =
    incident;

  const options = {};

  options["Incident Duration"] = [
    moment(fromUnixTime(displayStartTimestamp)),
    moment(fromUnixTime(displayEndTimestamp)),
  ];

  const startTime = fromUnixTime(startTimestamp);
  const endTime = fromUnixTime(endTimestamp);
  const diffInDays = differenceInDays(endTime, startTime);
  if (diffInDays <= 1) {
    let startDayTimestamp = getUnixTime(startOfDay(startTime));
    let endDayTimestamp = getUnixTime(endOfDay(endTime));
    if (diffInDays === 1) {
      endDayTimestamp = endTimestamp;
    }

    options["Day of the incident"] = [
      moment(fromUnixTime(startDayTimestamp)),
      moment(fromUnixTime(endDayTimestamp)),
    ];
  }

  const diffInWeeks = differenceInWeeks(endTime, startTime);
  if (diffInWeeks <= 1) {
    let startWeekTimestamp = getUnixTime(startOfWeek(startTime, { weekStartsOn: 1 }));
    let endWeekTimestamp = getUnixTime(endOfWeek(endTime, { weekStartsOn: 1 }));
    if (diffInWeeks === 1) {
      endWeekTimestamp = endTimestamp;
    }

    options["Week of the incident"] = [
      moment(fromUnixTime(startWeekTimestamp)),
      moment(fromUnixTime(endWeekTimestamp)),
    ];
  }

  const diffInMonths = differenceInMonths(endTime, startTime);
  if (diffInMonths <= 1) {
    let startMonthTimestamp = getUnixTime(startOfMonth(startTime));
    let endMonthTimestamp = getUnixTime(endOfMonth(endTime));
    if (diffInMonths === 1) {
      endMonthTimestamp = endTimestamp;
    }

    options["Month of the incident"] = [
      moment(fromUnixTime(startMonthTimestamp)),
      moment(fromUnixTime(endMonthTimestamp)),
    ];
  }

  return options;
}

export function getNotificationTimeOptions() {
  return [
    {
      icon: "1h",
      label: "Last 1 hour",
      value: "1h",
      timeRange: getTimePeriodFromString.bind(null, "1h"),
    },
    {
      icon: "1d",
      label: "Last 1 day",
      value: "1d",
      timeRange: getTimePeriodFromString.bind(null, "1d"),
    },
    {
      icon: "1w",
      label: "Last 7 days",
      value: "1w",
      timeRange: getTimePeriodFromString.bind(null, "1w"),
    },
    {
      icon: "1m",
      label: "Last 1 month",
      value: "1m",
      timeRange: getTimePeriodFromString.bind(null, "1m"),
    },
  ];
}

export function getHomeTimeOptions(current = new Date()) {
  const endTime = current;
  const notificationOptions = getNotificationTimeOptions();

  return [
    {
      icon: "Live",
      label: "Live",
      value: "live",
      timeRange: getTimePeriodFromString.bind(null, "1h", endTime),
    },
    ...notificationOptions,
    {
      icon: "3m",
      label: "Last 3 months",
      value: "3m",
      timeRange: getTimePeriodFromString.bind(null, "3m"),
    },
  ];
}

export function getMetricsTimeOptions(defaultTimePeriod) {
  const notificationOptions = getNotificationTimeOptions();

  return [
    {
      icon: (
        <svg
          width="10"
          height="11"
          viewBox="0 0 10 11"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            d="M0.835571 4C0.698853 4 0.601196 4.11719 0.601196 4.23438V9.3125C0.601196 9.83984 1.01135 10.25 1.5387 10.25H8.4137C8.92151 10.25 9.3512 9.83984 9.3512 9.3125V4.23438C9.3512 4.11719 9.23401 4 9.11682 4H0.835571ZM9.3512 3.14062V2.4375C9.3512 1.92969 8.92151 1.5 8.4137 1.5H7.4762V0.484375C7.4762 0.367188 7.35901 0.25 7.24182 0.25H6.46057C6.32385 0.25 6.2262 0.367188 6.2262 0.484375V1.5H3.7262V0.484375C3.7262 0.367188 3.60901 0.25 3.49182 0.25H2.71057C2.57385 0.25 2.4762 0.367188 2.4762 0.484375V1.5H1.5387C1.01135 1.5 0.601196 1.92969 0.601196 2.4375V3.14062C0.601196 3.27734 0.698853 3.375 0.835571 3.375H9.11682C9.23401 3.375 9.3512 3.27734 9.3512 3.14062Z"
            fill="#868786"
          />
        </svg>
      ),
      label: "Default",
      value: "default",
      timeRange: defaultTimePeriod,
    },
    ...notificationOptions,
    {
      icon: "3m",
      label: "Last 3 months",
      value: "3m",
      timeRange: getTimePeriodFromString.bind(null, "3m"),
    },
  ];
}

export function getWeekDaysFromTimeRange(timeRanges) {
  const weekDayHash = [false, false, false, false, false, false, false];
  for (let { startTimestamp } of timeRanges) {
    const currentDay = getDay(fromUnixTime(startTimestamp));
    if (!weekDayHash[currentDay]) {
      weekDayHash[currentDay] = true;
    }
  }

  return weekDayHash;
}

export function getTimeRangeFromWeekdays(timeRanges, weekDays) {
  if (timeRanges.length === 0) {
    return [];
  }

  const updatedTimeRanges = [];
  const { startTimestamp: sampleStartTimestamp, endTimestamp: sampleEndTimestamp } =
    timeRanges[0];

  const sampleStartTime = fromUnixTime(sampleStartTimestamp);
  const sampleEndTime = fromUnixTime(sampleEndTimestamp);

  let i = 0;
  for (i = 0; i < weekDays.length; i++) {
    if (!weekDays[i]) {
      continue;
    }

    updatedTimeRanges.push({
      startTimestamp: getUnixTime(setDay(sampleStartTime, i)),
      endTimestamp: getUnixTime(setDay(sampleEndTime, i)),
    });
  }

  return updatedTimeRanges;
}

export function getTimeRangeFromScheduleConfig(
  timeRanges,
  scheduleRecurringType,
  repeatCount
) {
  if (timeRanges.length === 0 || repeatCount < 1) {
    return null;
  }

  const startTimestamp = timeRanges[0].startTimestamp;
  let endTime = fromUnixTime(timeRanges[0].endTimestamp);

  if (scheduleRecurringType === ScheduleRecurringType.HOURLY) {
    endTime = addHours(endTime, repeatCount - 1);
  } else if (scheduleRecurringType === ScheduleRecurringType.DAILY) {
    endTime = addDays(endTime, repeatCount - 1);
  } else if (scheduleRecurringType === ScheduleRecurringType.WEEKLY) {
    endTime = addWeeks(endTime, repeatCount - 1);
  }

  return {
    startTimestamp,
    endTimestamp: getUnixTime(endTime),
  };
}

export function getQueryListParams() {
  const endTs = getUnixTime(new Date());
  const startTs = endTs - DAY_IN_SECONDS;
  return {
    startTs,
    endTs,
  };
}

export function getAlignedTimePeriodList(startTs, endTs, interval) {
  let startFunction;
  let endFunction;
  let addFunction;
  if (interval === "1w") {
    startFunction = startOfWeek;
    endFunction = endOfWeek;
    addFunction = addWeeks;
  } else if (interval === "1d") {
    startFunction = startOfDay;
    endFunction = endOfDay;
    addFunction = addDays;
  } else if (interval === "1h") {
    startFunction = startOfHour;
    endFunction = endOfHour;
    addFunction = addHours;
  }

  let currentStartTime = startFunction(fromUnixTime(startTs));
  let currentEndTime = endFunction(currentStartTime);

  const endTime = endFunction(fromUnixTime(endTs));
  const roundUpTimePeriodList = [];
  while (compareAsc(currentEndTime, endTime) <= 0) {
    roundUpTimePeriodList.push({
      startTimestamp: getUnixTime(currentStartTime),
      endTimestamp: getUnixTime(currentEndTime),
    });

    currentStartTime = addFunction(currentStartTime, 1);
    currentEndTime = addFunction(currentEndTime, 1);
  }
  return roundUpTimePeriodList;
}

export function getDisplayTimeFromDuration(currentDuration, opts = {}) {
  const { allowNegative } = opts;
  const isNegativeDuration = currentDuration < 0;
  const durationInSecond = Math.floor(Math.abs(currentDuration));
  const durationInMilSeconds = currentDuration - durationInSecond;

  if (isNegativeDuration && !allowNegative) {
    return "";
  }

  let displayStrArray = [];
  if (durationInSecond > 0) {
    displayStrArray.push(
      getDisplayTimeFromSecond(durationInSecond, false, [], false, true)
    );
  }

  if (durationInMilSeconds > 0) {
    displayStrArray.push(`${Math.floor(1000 * durationInMilSeconds)}ms`);
  }

  const displayValue = displayStrArray.join(" ");
  return isNegativeDuration ? `-${displayValue}` : displayValue;
}

export const DefaultTimeZone = "America/Los_Angeles";

// Returns time zone names like "EDT" or "PST".
export function browserLocaleTimezone() {
  return new Date()
    .toLocaleDateString("en-US", { day: "2-digit", timeZoneName: "short" })
    .substring(4);
}

// Returns time zone in IANA format like, "America/Lima" or "Africa/Abidjan"
export function browserIANALocaleTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const aggregationIntervalSecondsMap = {
  [AggregationWindowType.NONE]: 0,
  [AggregationWindowType.SECOND]: 1,
  [AggregationWindowType.MINUTE]: MINUTE_IN_SECONDS,
  [AggregationWindowType.FIVE_MIN]: 5 * MINUTE_IN_SECONDS,
  [AggregationWindowType.TEN_MIN]: 10 * MINUTE_IN_SECONDS,
  [AggregationWindowType.FIFTEEN_MIN]: 15 * MINUTE_IN_SECONDS,
  [AggregationWindowType.HOUR]: HOUR_IN_SECONDS,
  [AggregationWindowType.DAY]: DAY_IN_SECONDS,
  [AggregationWindowType.WEEK]: WEEK_IN_SECONDS,
  [AggregationWindowType.MONTH]: MONTH_IN_SECONDS,
  [AggregationWindowType.QUARTER]: QUARTER_IN_SECONDS,
  [AggregationWindowType.YEAR]: YEAR_IN_SECONDS,
};
