import React from "react";
import TimeRangeSelector from "../../../../components/time-range-selector/";
import ChartView from "../../../../components/chart-view/ng-index";
import { getKeyForChart } from "../../utils";
import { explorerMetricUrl } from "../../../profiler/utils";
import {
  AutoMetricsType,
  IncidentCreatorType,
  IncidentValidationStatus,
  MetricConfigType,
  isFileSource,
} from "../../../../utils/enums";
import { getSliceDisplayString } from "../../../../utils/general";
import { getMonitorStatusFromRule } from "../../../../utils/monitor";
import { hasPermission } from "../../../../utils/uri-path";
import { AppPermissions } from "../../../../utils/permissions";
import fromUnixTime from "date-fns/fromUnixTime";
import "./chart-tab.scss";
import {
  getAutoMetricTypeFromKPI,
  metricTypeSupportsQueryHistory,
} from "../../../../utils/metric";
import { getMetricTypeFromConfigData } from "../../../../components/metric/utils";

function IncidentMainChart(props) {
  const {
    incident: {
      slice,
      startTime,
      endTime,
      reason,
      score,
      incidentId,
      validation,
      status,
    },
    workspaceUuid,
    incidentCreatorInfo,
    previewUuid,
    incidentUuid,
    incidentMainMetricsData,
    groupIncidentList,
    workspaceUserPermissions,
    onChangeIncidentStatusClick,
    onValidationStatusClick,
    onNextUrlChange,
    enableViewInExplorer,
    onQueryHistoryView,
  } = props;

  if (!incidentCreatorInfo) {
    return null;
  }

  const { currentIncidentMetricsData } = incidentMainMetricsData;
  let autoMetricType = "";
  let mainIncidentList = [];
  let defaultDriftTitle = "";
  let chartConfig = {};
  if (incidentCreatorInfo.type === IncidentCreatorType.FILTER) {
    const { filterInfo, kpiInfo } = incidentCreatorInfo;
    autoMetricType = getAutoMetricTypeFromKPI(kpiInfo);
    const kpiName = kpiInfo.metadata.name || "";
    const sliceString = getSliceDisplayString(slice, kpiInfo);
    defaultDriftTitle = !sliceString ? kpiName : `${kpiName} | ${sliceString}`;

    const symptomType = filterInfo.config.symptom.type;
    const feature = filterInfo.config.symptom.featureConfig.type;
    const filterUuid = filterInfo.metadata.uuid;
    chartConfig = {
      symptomType,
      feature,
      autosize: true,
      height: "25vh",
      marginTop: 5,
      startTime,
      endTime,
      detectInterval: 0,
    };

    mainIncidentList = [
      {
        startTime,
        endTime,
        main: true,
        reason,
        score,
        validationStatus: validation?.status || "",
        id: incidentId,
      },
    ];
    if (
      (filterUuid || previewUuid) &&
      groupIncidentList &&
      groupIncidentList.length > 0
    ) {
      for (let incidentItem of groupIncidentList) {
        if (
          [filterUuid, previewUuid].includes(incidentItem.creatorInfo.creatorUuid) &&
          JSON.stringify(slice) === JSON.stringify(incidentItem.slice)
        ) {
          mainIncidentList = incidentItem.incidents.map(function (currentIncident) {
            return {
              main: currentIncident.uuid === incidentUuid,
              ...currentIncident,
            };
          });
          break;
        }
      }
    }
  }

  const enableModifyIncident = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTDETAIL,
  ]);
  const incidentStatusConfig = {
    onChangeIncidentStatusClick,
    status: status,
    disabled: !enableModifyIncident,
  };

  let isValidationDisabled = false;
  let validationDisabledReason = "";
  if (incidentCreatorInfo.type === IncidentCreatorType.FILTER) {
    if (
      incidentCreatorInfo.kpiInfo.config.configType ===
      MetricConfigType.FULL_TABLE_METRIC_CONFIG
    ) {
      isValidationDisabled = true;
      validationDisabledReason = "Validation is not supported in full table metrics";
    }
  }

  const incidentValidationStatusConfig = {
    onValidation: () => onValidationStatusClick(IncidentValidationStatus.RUNNING),
    onCancelValidation: () =>
      onValidationStatusClick(IncidentValidationStatus.CANCELING),
    value: validation,
    disabled: isValidationDisabled || !enableModifyIncident,
    reason: validationDisabledReason,
  };

  const incidentViewInExplorerConfig = enableViewInExplorer
    ? {
        onClick: () => {
          const nextUrl = explorerMetricUrl(incidentCreatorInfo.kpiInfo);
          onNextUrlChange(nextUrl);
        },
      }
    : null;

  const viewQueryHistoryConfig =
    !isFileSource(incidentCreatorInfo?.dataSourceInfo) &&
    metricTypeSupportsQueryHistory(
      getMetricTypeFromConfigData(incidentCreatorInfo?.kpiInfo)
    )
      ? {
          onClick: () => {
            onQueryHistoryView(workspaceUuid, incidentCreatorInfo?.kpiInfo);
          },
        }
      : null;

  return (
    <ChartView
      metricType={autoMetricType}
      metric={incidentCreatorInfo.kpiInfo || null}
      title={defaultDriftTitle}
      data={currentIncidentMetricsData}
      incidents={mainIncidentList}
      defaultShowStatsData={!!previewUuid}
      className={"master-drift-chart-panel"}
      incidentStatusConfig={incidentStatusConfig}
      incidentValidationStatusConfig={incidentValidationStatusConfig}
      incidentViewInExplorerConfig={incidentViewInExplorerConfig}
      viewQueryHistoryConfig={viewQueryHistoryConfig}
      config={chartConfig}
      userPermissions={workspaceUserPermissions}
    />
  );
}

function CorrelationChartView(props) {
  const {
    groupIncidentListMetrics,
    kpiList,
    ruleList,
    workspaceUserPermissions = { isSuperuser: true, permissions: [] },
    incident,
    timeRange,
    correlationChartList,
    groupIncidentList,
    onNextUrlChange,
    enableViewInExplorer,
    onRemoveCorrelatedChart,
    correlationChartListMonitorSetting,
    onFocusedMonitorChange,
  } = props;

  if (
    groupIncidentListMetrics.length === 0 ||
    kpiList.length === 0 ||
    ruleList.length === 0
  ) {
    return null;
  }

  const canEditIncidentConfig = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTCONFIGVIEW,
  ]);

  const { startTime, endTime } = incident;
  const ruleInfoMapper = {};
  const kpiInfoMapper = {};
  const metricUuidToMonitorsMapper = {};

  kpiList.forEach((currentKpi) => {
    kpiInfoMapper[currentKpi.metadata.uuid] = currentKpi;
  });

  ruleList.forEach((currentRule) => {
    ruleInfoMapper[currentRule.metadata.uuid] = currentRule;
    const currentMetricUuid = currentRule.config.metrics[0];
    if (!metricUuidToMonitorsMapper[currentMetricUuid]) {
      metricUuidToMonitorsMapper[currentMetricUuid] = [];
    }
    metricUuidToMonitorsMapper[currentMetricUuid].push(currentRule);
  });

  return (
    <>
      {groupIncidentListMetrics.map(function (currentIncidentListMetrics, index) {
        if (!correlationChartList[index]) {
          return null;
        }

        let { slice = {}, metricUuid } = correlationChartList[index];
        if (!metricUuid) {
          console.log(
            `Metric uuid is null for ${JSON.stringify(correlationChartList[index])}`
          );
          return null;
        }

        const chartKey = getKeyForChart(metricUuid, slice);
        const filterUuid = correlationChartListMonitorSetting[chartKey] || "";
        let incidentList = [];
        let creatorInfo = null;
        const relatedRules = metricUuidToMonitorsMapper[metricUuid] || [];
        for (let incidentItem of groupIncidentList) {
          const filterInfo = ruleInfoMapper[incidentItem.id];
          if (!filterInfo) {
            continue;
          }
          const currentFilterMetricUuid = filterInfo.config.metrics[0];
          if (
            currentFilterMetricUuid === metricUuid &&
            JSON.stringify(slice) === JSON.stringify(incidentItem.slice)
          ) {
            incidentList.push(
              ...incidentItem.incidents.map(function ({
                startTime,
                endTime,
                reason,
                validation,
                ...otherProperties
              }) {
                startTime = Math.max(startTime, timeRange.startTime);
                endTime = Math.min(endTime, timeRange.endTime);
                return {
                  startTime,
                  endTime,
                  main: false,
                  reason,
                  validation,
                  ...otherProperties,
                };
              })
            );
          }
        }

        let defaultDriftTitle = "";
        let autoMetricType = "";
        let chartConfig = {};
        let metric = null;

        let symptomType = "";
        let feature = "";
        if (ruleInfoMapper[filterUuid]) {
          symptomType = ruleInfoMapper[filterUuid].config.symptom.type;
          feature = ruleInfoMapper[filterUuid].config.symptom.featureConfig.type;
          if (!metricUuid) {
            metricUuid = ruleInfoMapper[filterUuid].config.metrics[0];
          }
        }

        const kpiName =
          metricUuid && kpiInfoMapper[metricUuid]
            ? kpiInfoMapper[metricUuid].metadata.name
            : "";
        metric = kpiInfoMapper[metricUuid] || null;
        const sliceString = getSliceDisplayString(
          slice,
          kpiInfoMapper[metricUuid] || ""
        );
        defaultDriftTitle = !sliceString ? kpiName : `${kpiName} | ${sliceString}`;
        autoMetricType =
          metricUuid && kpiInfoMapper[metricUuid]
            ? getAutoMetricTypeFromKPI(kpiInfoMapper[metricUuid])
            : AutoMetricsType.NONE;
        chartConfig = {
          symptomType,
          feature,
          autosize: false,
          height: 160,
          marginTop: 5,
          startTime,
          endTime,
          detectInterval: 0,
        };

        const incidentViewInExplorerConfig = enableViewInExplorer
          ? {
              onClick: () => {
                let nextUrl = null;
                if (metric) {
                  nextUrl = explorerMetricUrl(metric);
                } else if (creatorInfo.type === IncidentCreatorType.FILTER) {
                  nextUrl = explorerMetricUrl(creatorInfo.kpiInfo);
                }

                onNextUrlChange(nextUrl);
              },
            }
          : null;

        const removeChartConfig = {
          onClick: () => {
            const currentItem = {
              metricUuid,
              slice,
            };
            onRemoveCorrelatedChart(currentItem);
          },
        };

        const monitorConfig = {
          monitorUuid: filterUuid || "",
          enableAddMonitors: false,
          enableEditMonitors: false,
          enableEditNotifications: false,
          enableViewMonitors: false,
          enableSwitchMonitor: true,
          enableChartOption: true,
          relatedRules: relatedRules,
          monitorStatus: getMonitorStatusFromRule(relatedRules),
          onSwitchMonitor: (newMonitorUuid) => {
            onFocusedMonitorChange(metricUuid, slice, newMonitorUuid);
          },
        };

        return (
          <ChartView
            key={index}
            metricType={autoMetricType}
            title={defaultDriftTitle}
            data={currentIncidentListMetrics}
            creatorInfo={creatorInfo}
            incidents={incidentList}
            className={"analysis-drift-chart-panel"}
            metric={metric}
            config={chartConfig}
            userPermissions={workspaceUserPermissions}
            incidentViewInExplorerConfig={incidentViewInExplorerConfig}
            removeChartConfig={canEditIncidentConfig && removeChartConfig}
            monitorConfig={monitorConfig}
            incidentConfig={{}}
            enableChartOption={false}
          />
        );
      })}
    </>
  );
}

function ChartTab(props) {
  const {
    timeRange,
    timeRangeQuickOptions,
    onTimeRangeChange,
    onChangeIncidentStatusClick,
    onValidationStatusClick,
    incident,
    incidentCreatorInfo,
    incidentUuid,
    incidentPreviewUuid,
    incidentMainMetricsData,
    groupIncidentList,
    groupIncidentListMetrics,
    incidentCommentList,
    workspaceUserPermissions,
    workspaceUuid,
    previewUuid,
    kpiList,
    ruleList,
    correlationChartList,
    onNextUrlChange,
    onRemoveCorrelatedChart,
    correlationChartListMonitorSetting,
    onFocusedMonitorChange,
    onQueryHistoryView,
  } = props;

  const enableViewInExplorer = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
  ]);

  return (
    <div className="incident-main-chart-tab-container lightup-vertical-flex-container">
      <div className="incident-main-chart-tab-time-range-container lightup-horizon-flex-container">
        <div className="incident-main-chart-tab-time-range-label-container">
          Set a timeframe
        </div>
        <div className="lightup-rest-flex-item-container" />
        <TimeRangeSelector
          value={{
            startTime: fromUnixTime(timeRange.startTime),
            endTime: fromUnixTime(timeRange.endTime),
          }}
          presetRanges={timeRangeQuickOptions}
          onChange={onTimeRangeChange}
        />
      </div>
      <div className="incident-main-chart-tab-chart-content-container lightup-rest-flex-item-container">
        <IncidentMainChart
          incident={incident}
          incidentCreatorInfo={incidentCreatorInfo}
          incidentUuid={incidentUuid}
          incidentPreviewUuid={incidentPreviewUuid}
          incidentMainMetricsData={incidentMainMetricsData}
          groupIncidentList={groupIncidentList}
          incidentCommentList={incidentCommentList}
          workspaceUserPermissions={workspaceUserPermissions}
          workspaceUuid={workspaceUuid}
          onChangeIncidentStatusClick={onChangeIncidentStatusClick}
          onValidationStatusClick={onValidationStatusClick}
          onNextUrlChange={onNextUrlChange}
          enableViewInExplorer={enableViewInExplorer}
          onQueryHistoryView={onQueryHistoryView}
        />
        <CorrelationChartView
          groupIncidentListMetrics={groupIncidentListMetrics}
          kpiList={kpiList}
          ruleList={ruleList}
          workspaceUserPermissions={workspaceUserPermissions}
          workspaceUuid={workspaceUuid}
          incident={incident}
          timeRange={timeRange}
          correlationChartList={correlationChartList}
          previewUuid={previewUuid}
          groupIncidentList={groupIncidentList}
          onNextUrlChange={onNextUrlChange}
          enableViewInExplorer={enableViewInExplorer}
          onRemoveCorrelatedChart={onRemoveCorrelatedChart}
          correlationChartListMonitorSetting={correlationChartListMonitorSetting}
          onFocusedMonitorChange={onFocusedMonitorChange}
        />
      </div>
    </div>
  );
}

export default ChartTab;
