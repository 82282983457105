import React, { useState, useEffect } from "react";
import { EyeIcon } from "../../../../components/icons/eye-icon/";
import { CirclesSeven } from "../../../../components/icons/circles-seven/";
import NgButton, { TextWithIcon } from "../../../../components/button/ng-button";
import TimeRangeSelector from "../../../../components/time-range-selector/";
import TableSchemaView from "../../../../components/metric/table-schema-view";
import TableSampleDataView from "../../../../components/metric/table-sample-data-view";
import { QueryScope } from "../../../../utils/enums";
import getUnixTime from "date-fns/getUnixTime";
import subDays from "date-fns/subDays";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "../../../../atom/spinner";

import "./profiler-schema-virtual-table-preview-view.scss";

function ProfilerSchemaVirtualTablePreviewDefaultView(props) {
  const {
    currentPreviewTime,
    isFullTable,
    onCurrentPreviewTimeChange,
    onPreview,
    enablePreview,
  } = props;
  return (
    <div className="profiler-schema-virtual-table-preview-default-view-container">
      <div className="profiler-schema-virtual-table-preview-default-view-icon-container">
        <CirclesSeven />
      </div>
      <div className="profiler-schema-virtual-table-preview-default-view-title-container">
        Preview virtual table
      </div>
      <div className="profiler-schema-virtual-table-preview-default-view-description-container">
        Once you validate the query and add the required fields (timestamp column,
        timestamp timezone, timestamp type) and partitions if defined, you will be able
        to see the table schema here
      </div>
      {!isFullTable && (
        <div className="profiler-schema-virtual-table-preview-default-view-time-select-container">
          <TimeRangeSelector
            style={{ minWidth: "300px", height: "40px" }}
            currentTime={currentPreviewTime.current}
            value={currentPreviewTime}
            onChange={(newPreviewTime) =>
              onCurrentPreviewTimeChange({
                ...currentPreviewTime,
                ...newPreviewTime,
              })
            }
          />
        </div>
      )}
      <div className="profiler-schema-virtual-table-preview-default-view-button-container">
        <NgButton outline size="large" onClick={onPreview} disabled={!enablePreview}>
          Generate Preview
        </NgButton>
      </div>
    </div>
  );
}

function ProfilerSchemaVirtualTablePreviewInProgressView(props) {
  const { currentPreviewTime, isFullTable } = props;
  return (
    <div className="profiler-schema-virtual-table-preview-in-progress-view-container">
      <div className="profiler-schema-virtual-table-preview-in-progress-icon-container">
        <Spinner size="large" />
      </div>
      <div className="profiler-schema-virtual-table-preview-in-progress-title-container">
        Generating preview ...
      </div>
      {!isFullTable && (
        <div className="profiler-schema-virtual-table-preview-in-progress-time-select-container">
          <TimeRangeSelector
            style={{ minWidth: "300px", height: "40px" }}
            currentTime={currentPreviewTime.current}
            value={currentPreviewTime}
            disabled
          />
        </div>
      )}
    </div>
  );
}

function ProfilerSchemaVirtualTablePreviewResultView(props) {
  const { schemaList, sampleDataList } = props;

  return (
    <div className="profiler-schema-virtual-table-preview-result-view-container">
      <div className="profiler-schema-virtual-table-preview-result-schema-container">
        <TableSchemaView data={schemaList} />
      </div>
      <div className="profiler-schema-virtual-table-preview-result-sample-data-container">
        <TableSampleDataView tableSchemaList={schemaList} sampleData={sampleDataList} />
      </div>
    </div>
  );
}

function getDefaultPreviewTime(currentTime) {
  return {
    startTime: subDays(currentTime, 1),
    endTime: currentTime,
    current: currentTime,
  };
}

function getSampleDataPayload(dataSource, schema, virtualTable, currentPreviewTime) {
  const {
    queryScope,
    dataTimezone,
    partitionTimezone,
    timestampColumn,
    partitions,
    partitionOffsets,
    timestampColumnType,
    sql,
  } = virtualTable.profilerConfig;
  const normalizedPreviewPeriod =
    queryScope === QueryScope.TIME_RANGE
      ? {
          previewStartTs: getUnixTime(currentPreviewTime.startTime),
          previewEndTs: getUnixTime(currentPreviewTime.endTime),
        }
      : {};

  const normalizedTableUuid = virtualTable.uuid ? { tableUuid: virtualTable.uuid } : {};

  return {
    sourceUuid: dataSource.metadata.uuid,
    schemaUuid: schema.uuid,
    ...normalizedTableUuid,
    ...normalizedPreviewPeriod,
    previewUuid: uuidv4(),
    isUserDefined: true,
    sql,
    queryScope,
    dataTimezone,
    partitionTimezone,
    timestampColumn,
    partitions,
    partitionOffsets,
    timestampColumnType,
  };
}

function ProfilerSchemaVirtualTablePreviewView(props) {
  const {
    value,
    currentTime = new Date(),
    previewVirtualTableSchemaList,
    previewVirtualTableSampleDataList,
    schemaList,
    sampleDataList,
    workspaceUuid,
    dataSource,
    schema,
    enablePreview,
  } = props;
  const [isPreviewed, setIsPreviewed] = useState(false);
  const [currentPreviewTime, setCurrentPreviewTime] = useState(
    getDefaultPreviewTime(currentTime)
  );

  useEffect(() => setIsPreviewed(false), [value]);

  function onPreview() {
    previewVirtualTableSchemaList(workspaceUuid, dataSource.metadata.uuid, value);
    previewVirtualTableSampleDataList(
      workspaceUuid,
      getSampleDataPayload(dataSource, schema, value, currentPreviewTime)
    );
    setIsPreviewed(true);
  }

  const isInProgress = schemaList.loading && sampleDataList.loading;
  const isFullTable = value?.profilerConfig?.queryScope === QueryScope.FULL_TABLE;
  return (
    <div className="profiler-schema-virtual-table-preview-view-container">
      <div className="profiler-schema-virtual-table-preview-header-container">
        <div className="profiler-schema-virtual-table-preview-header-title-container">
          <TextWithIcon icon={<EyeIcon />} iconPosition="left">
            Preview
          </TextWithIcon>
        </div>
        {isPreviewed && !isInProgress && !isFullTable && (
          <div className="profiler-schema-virtual-table-preview-header-time-select-container">
            <TimeRangeSelector
              style={{ minWidth: "300px", height: "40px" }}
              disabled={!enablePreview}
              currentTime={currentPreviewTime.current}
              value={currentPreviewTime}
              onChange={(newPreviewTime) => {
                setCurrentPreviewTime({
                  ...currentPreviewTime,
                  ...newPreviewTime,
                });
              }}
            />
          </div>
        )}
        {isPreviewed && !isInProgress && (
          <NgButton outline size="large" onClick={onPreview} disabled={!enablePreview}>
            Preview
          </NgButton>
        )}
      </div>
      <div className="profiler-schema-virtual-table-preview-content-container">
        {!isPreviewed && (
          <ProfilerSchemaVirtualTablePreviewDefaultView
            currentPreviewTime={currentPreviewTime}
            onCurrentPreviewTimeChange={setCurrentPreviewTime}
            isFullTable={isFullTable}
            onPreview={onPreview}
            enablePreview={enablePreview}
          />
        )}
        {isPreviewed && isInProgress && (
          <ProfilerSchemaVirtualTablePreviewInProgressView
            isFullTable={isFullTable}
            currentPreviewTime={currentPreviewTime}
          />
        )}
        {isPreviewed && !isInProgress && (
          <ProfilerSchemaVirtualTablePreviewResultView
            schemaList={schemaList}
            sampleDataList={sampleDataList}
          />
        )}
      </div>
    </div>
  );
}

export default ProfilerSchemaVirtualTablePreviewView;
