import React from "react";
import { getDisplayTimeFromSecond, getStringFromTimeStamp } from "../../utils/time";
import { MetricConfigType, TableType } from "../../utils/enums";
import { getQueryRecordStatusDisplayName } from "../../utils/general";
import { DynamicHeightTable } from "../table/ng-table";
import MetricConfigPreviewQueryHistoryQueryDetails from "../metric/tabs/preview/metric-config-preview-query-history-query-details";
import { getDisplayTableName, getFullTableName } from "../../utils/datasource";
import Button, { ButtonPaddingSize } from "../button/ng-button";

import "./query-history-table.scss";

function QueryHistoryTable(props) {
  const {
    queryList,
    dataSourceInfo,
    onOpenQueryDetail,
    onCloseQueryDetail,
    showName = true,
    showDetail = false,
    detailOptions = null,
    paginationClassname = "",
  } = props;

  const columns = [];

  if (showName) {
    columns.push({
      title: "NAME",
      key: "metricObject",
      dataIndex: ["metricObject"],
      render: function (metricObject, data) {
        const metricName = metricObject ? metricObject.metadata.name : "Deleted metric";
        let creatorDisplayStr = "";
        if (metricObject) {
          if (
            metricObject.config.configType ===
            MetricConfigType.FULL_COMPARE_METRIC_CONFIG
          ) {
            const currentTable = data.querySourceRef.target || "targetTable";
            const tableConfig = metricObject.config[currentTable];
            const { sourceUuid, tableName } = tableConfig;
            const dataSourceName = dataSourceInfo[sourceUuid] || "N/A";
            creatorDisplayStr = [dataSourceName, getDisplayTableName(tableName)].join(
              " \u203A "
            );
          } else if (
            metricObject.config.table &&
            metricObject.config.table.type === TableType.TABLE
          ) {
            const dataSourceName = dataSourceInfo[metricObject.config.sources[0]];
            creatorDisplayStr = [
              dataSourceName,
              getDisplayTableName(getFullTableName(metricObject.config.table)),
            ].join(" \u203A ");
          } else {
            creatorDisplayStr = "custom metric";
          }
        }

        return (
          <>
            <div
              className="name-cell-container"
              onClick={() => {
                onOpenQueryDetail(
                  <MetricConfigPreviewQueryHistoryQueryDetails
                    queryHistoryItem={data}
                    closeTakeover={onCloseQueryDetail}
                    options={detailOptions}
                  />
                );
              }}
            >
              {metricName}
            </div>
            {creatorDisplayStr && (
              <div className="name-creator-info-container">{creatorDisplayStr}</div>
            )}
          </>
        );
      },
    });
  }

  columns.push({
    title: "START TIME",
    key: "startTs",
    dataIndex: ["startTs"],
    render: function (startTs) {
      return getStringFromTimeStamp(startTs);
    },
    defaultSortOrder: "descend",
    sorter: {
      compare: (a, b) => {
        const aStartTs = a["startTs"];
        const bStartTs = b["startTs"];
        if (aStartTs === bStartTs) {
          return 0;
        }

        if (aStartTs < bStartTs) {
          return -1;
        }

        return 1;
      },
    },
  });

  columns.push({
    title: "DURATION",
    key: "endTs",
    dataIndex: ["endTs"],
    render: function (endTs, data, _index) {
      if (typeof endTs !== "number") {
        return "-";
      }

      return getDisplayTimeFromSecond(endTs - data["startTs"]);
    },
    sorter: {
      compare: (a, b) => {
        if (typeof a["endTs"] !== "number" && typeof b["endTs"] !== "number") {
          return 0;
        }

        if (typeof a["endTs"] !== "number") {
          return -1;
        }

        if (typeof b["endTs"] !== "number") {
          return 1;
        }

        const aDuration = a["endTs"] - a["startTs"];
        const bDuration = b["endTs"] - b["startTs"];

        if (aDuration === bDuration) {
          return 0;
        }

        if (aDuration < bDuration) {
          return -1;
        }

        return 1;
      },
    },
  });

  columns.push({
    title: "STATUS",
    key: "status",
    dataIndex: ["status"],
    render: (status) => getQueryRecordStatusDisplayName(status),
    sorter: {
      compare: (a, b) => {
        const aStatus = a.status;
        const bStatus = b.status;
        return aStatus.localeCompare(bStatus);
      },
    },
  });

  if (showDetail) {
    columns.push({
      title: "DETAILS",
      key: "details",
      dataIndex: ["querySql"],
      render: function (_, data) {
        return (
          <Button
            link
            paddingSize={ButtonPaddingSize.TINY}
            onClick={() => {
              onOpenQueryDetail(
                <MetricConfigPreviewQueryHistoryQueryDetails
                  queryHistoryItem={data}
                  closeTakeover={onCloseQueryDetail}
                  options={detailOptions}
                />
              );
            }}
          >
            Query Details
          </Button>
        );
      },
      defaultSortOrder: "descend",
    });
  }

  return (
    <div className="query-history-table-container">
      <DynamicHeightTable
        dataSource={queryList.data}
        columns={columns}
        rowKey={(record) => record.queryUuid}
        sortDirections={["ascend", "descend", "ascend"]}
        size={"middle"}
        pagination={{
          position: ["topRight"],
          hideOnSinglePage: false,
          size: "default",
          className: paginationClassname,
        }}
        loading={queryList.loading}
      />
    </div>
  );
}

export default QueryHistoryTable;
