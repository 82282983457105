import React from "react";
import NgButton from "../../../button/ng-button";
import Popover from "../../../popover";
import NgToggleCheckbox from "../../../toggle-checkbox/ng-toggle-checkbox";
import { LabeledInputNumber } from "../../../labeled-control/labeled-control";

import "./rule-config-custom-aggressive-edit-popup.scss";

function HorizontalDotsIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M3.5 7.875C3.98325 7.875 4.375 7.48325 4.375 7C4.375 6.51675 3.98325 6.125 3.5 6.125C3.01675 6.125 2.625 6.51675 2.625 7C2.625 7.48325 3.01675 7.875 3.5 7.875Z"
        fill="#121224"
      />
      <path
        d="M7 7.875C7.48325 7.875 7.875 7.48325 7.875 7C7.875 6.51675 7.48325 6.125 7 6.125C6.51675 6.125 6.125 6.51675 6.125 7C6.125 7.48325 6.51675 7.875 7 7.875Z"
        fill="#121224"
      />
      <path
        d="M10.5 7.875C10.9832 7.875 11.375 7.48325 11.375 7C11.375 6.51675 10.9832 6.125 10.5 6.125C10.0168 6.125 9.625 6.51675 9.625 7C9.625 7.48325 10.0168 7.875 10.5 7.875Z"
        fill="#121224"
      />
    </svg>
  );
}

function RuleConfigCustomAggressiveEditPopup(props) {
  const { aggressiveness, disabled, onChange } = props;
  const defaultOverrideEnabled =
    aggressiveness && typeof aggressiveness.override === "number";
  const [isOverrideEnabled, setIsOverrideEnabled] =
    React.useState(defaultOverrideEnabled);
  const defaultOverride =
    aggressiveness && aggressiveness.override ? aggressiveness.override : 100;

  const content = (
    <div className="rule-config-custom-aggressive-edit-popup-content-container">
      <div className="rule-config-custom-aggressive-edit-popup-content-header-container lightup-horizon-flex-container">
        <div>Aggressiveness Override</div>
        <div className="rule-mute-alert-notification-container">
          <NgToggleCheckbox
            value={isOverrideEnabled}
            size={"mini"}
            onChange={() => {
              setIsOverrideEnabled(!isOverrideEnabled);
            }}
            label={""}
          />
        </div>
      </div>
      <LabeledInputNumber
        disabled={!isOverrideEnabled}
        value={defaultOverride}
        onChange={(newOverride) => {
          defaultOverride !== newOverride && onChange({ override: newOverride });
        }}
      />
      <div className="rule-config-custom-aggressive-edit-popup-content-help-text-container">
        Override allows you to set a value to experiment with aggressiveness. Detection
        outcome may vary based on what you specify.
      </div>
    </div>
  );

  const trigger = (
    <NgButton outline disabled={disabled}>
      <HorizontalDotsIcon />
    </NgButton>
  );

  return (
    <div className="rule-config-custom-aggressive-edit-popup-container">
      {disabled ? (
        trigger
      ) : (
        <Popover trigger="click" content={content}>
          {trigger}
        </Popover>
      )}
    </div>
  );
}

export default RuleConfigCustomAggressiveEditPopup;
