import React, { Component } from "react";
import PropTypes from "prop-types";
import { getOptionByValue } from "../../utils/options";
import { LabeledInputNumber, LabeledSelect } from "../labeled-control/labeled-control";

import "./time-option.scss";

class TimeOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined,
      numberValue: "Value",
      timeUnitValue: "u",
    };

    this.onNumberChange = this.onNumberChange.bind(this);
    this.onTimeUnitChange = this.onTimeUnitChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (state.value !== value) {
      let numberValue;
      let timeUnitValue;
      if (value === undefined) {
        numberValue = "Value";
        timeUnitValue = "u";
      } else {
        const timeUnitLength = value.indexOf("mo") === -1 ? 1 : 2;
        numberValue = value.substring(0, value.length - timeUnitLength);
        /*
         * When the value is updated to 0, getDisplayTimeFromSecond in utils/time.jsx has problems figuring out what is the previous time unit
         * and the timeunit string may be wrong. this component will use the last remembered time unit if it is set before.
         */
        timeUnitValue =
          numberValue === "0" && state.timeUnitValue !== "u"
            ? state.timeUnitValue
            : value.substring(value.length - timeUnitLength);
      }

      return {
        value,
        numberValue,
        timeUnitValue,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  updateValue({ numberValue, timeUnitValue }) {
    const parsed = parseInt(numberValue);
    if (isNaN(parsed) || timeUnitValue === "u") {
      return;
    }

    this.props.onChange(numberValue + timeUnitValue);
  }

  onNumberChange(newValue) {
    const { numberValue } = this.state;
    if (newValue !== numberValue) {
      this.setState(
        {
          numberValue: newValue,
        },
        () => {
          this.updateValue(this.state);
        }
      );
    }
  }

  onTimeUnitChange(currentTimeUnitOption) {
    const { timeUnitValue } = this.state;
    if (currentTimeUnitOption !== timeUnitValue) {
      this.setState(
        {
          timeUnitValue: currentTimeUnitOption,
        },
        () => {
          this.updateValue(this.state);
        }
      );
    }
  }

  render() {
    const { label, options, layout = "legacy", disabled = false, min = 0 } = this.props;
    const { numberValue, timeUnitValue } = this.state;

    const layoutClassName =
      layout === "split" ? "time-option-container-split" : "time-option-container";

    return (
      <div className={layoutClassName}>
        <div className="time-option-input-container">
          <LabeledInputNumber
            label={label}
            staticLabel
            value={numberValue}
            disabled={disabled}
            onChange={this.onNumberChange}
            min={min}
          />
        </div>
        <div className="time-option-select-container">
          <LabeledSelect
            label={"Unit"}
            staticLabel
            value={getOptionByValue(options, timeUnitValue)}
            onChange={this.onTimeUnitChange}
            disabled={disabled}
            options={options}
          />
        </div>
      </div>
    );
  }
}

TimeOption.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimeOption;
