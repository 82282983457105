import axios from "../utils/api";
import { backendURIPath, getURIInstance } from "../utils/uri-path";

export function getApiCredentialPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .get(backendURIPath.API_CREDENTIAL_LIST, { baseURL: "/api/v1/" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addApiCredentialPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.API_CREDENTIAL_LIST, {}, { baseURL: "/api/v1/" })
      .then(function (response) {
        // Lightctl needs raw data from server for downloading credential
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function patchApiCredentialPromise(uuid, partialApiCredential) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        getURIInstance(backendURIPath.API_CREDENTIAL, { uuid }),
        partialApiCredential,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteApiCredentialPromise(uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(getURIInstance(backendURIPath.API_CREDENTIAL, { uuid }), {
        baseURL: "/api/v1/",
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
