import { connect } from "react-redux";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import { getKpiList } from "../../actions/kpi/kpi-action";
import { getTagList } from "../../actions/tag/tag-action";
import {
  deleteRules,
  getRuleIncidentList,
  getRuleList,
  updateRulesConfig,
  updateRuleTags,
  getRuleListPageConfiguration,
  updateRuleListPageConfiguration,
} from "../../actions/rule/rule-action";
import { getIntegrationList } from "../../actions/integration/integration-action";

import MonitorList from "./monitor-list";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  ruleList: state.ruleReducer.ruleList,
  ruleIncidentList: state.ruleReducer.ruleIncidentList,
  kpiList: state.kpiReducer.kpiList,
  dataSourceList: state.dataSourceReducer.dataSourceList,
  tagList: state.tagReducer.tagList,
  integrationList: state.integrationReducer.integrationList.data,
  ruleListPageConfiguration: state.ruleReducer.ruleListPageConfiguration,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getKpiList: (workspaceUuid) => dispatch(getKpiList(workspaceUuid)),
  getRuleList: (workspaceUuid, options) =>
    dispatch(getRuleList(workspaceUuid, options)),
  getRuleListPageConfiguration: (workspaceUuid) =>
    dispatch(getRuleListPageConfiguration(workspaceUuid)),
  updateRuleListPageConfiguration: (workspaceUuid, pageConfiguration) =>
    dispatch(updateRuleListPageConfiguration(workspaceUuid, pageConfiguration)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  deleteRules: (workspaceUuid, uuids) => dispatch(deleteRules(workspaceUuid, uuids)),
  getRuleIncidentList: (workspaceUuid, queryObject) =>
    dispatch(getRuleIncidentList(workspaceUuid, queryObject)),
  updateRuleTags: (workspaceUuid, rawData, newTags) =>
    dispatch(updateRuleTags(workspaceUuid, rawData, newTags)),
  updateRulesConfig: (workspaceUuid, newRulesConfig) =>
    dispatch(updateRulesConfig(workspaceUuid, newRulesConfig)),
  getTagList: (workspaceUuid) => dispatch(getTagList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorList);
