import React, { useState, useEffect } from "react";
import LegacyModalDialog from "../legacy-adapter/legacy-modal-dialog";
import AlertConfigSettingView from "./index";
import "./alert-config-setting-dialog.scss";

function AlertConfigSettingDialogFooterComponent(props) {
  const { onCancelClicked, onOKClicked } = props;

  return (
    <>
      <button className="apply-button" onClick={onOKClicked}>
        Save
      </button>
      <button className="cancel-button" onClick={onCancelClicked}>
        Cancel
      </button>
    </>
  );
}

function AlertConfigSettingDialogBodyComponent(props) {
  const { currentAlertingChannels, alertingChannelList, onChange } = props;

  return (
    <div className="alert-config-setting-dialog-body-component-container">
      <AlertConfigSettingView
        value={currentAlertingChannels}
        onChange={onChange}
        alertingChannelList={alertingChannelList}
        enableSchedule={false}
      />
    </div>
  );
}

function AlertConfigSettingDialog(props) {
  const {
    modalIsOpen,
    setModalIsOpen,
    currentAlertingChannels: defaultCurrentAlertingChannels,
    alertingChannelList,
    okClicked,
  } = props;

  const [currentAlertingChannels, setCurrentAlertingChannels] = useState(
    defaultCurrentAlertingChannels
  );
  useEffect(() => {
    setCurrentAlertingChannels(defaultCurrentAlertingChannels);
  }, [defaultCurrentAlertingChannels]);

  function closeModal() {
    setModalIsOpen(false);
    setCurrentAlertingChannels(defaultCurrentAlertingChannels);
  }

  function onOkClicked() {
    okClicked && okClicked(currentAlertingChannels);
    setModalIsOpen(false);
  }

  return (
    <LegacyModalDialog
      title={"Notification Channels"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      containerClassName={"alert-config-setting-dialog-container"}
      triggerComponent={null}
      contentComponent={
        <AlertConfigSettingDialogBodyComponent
          alertingChannelList={alertingChannelList}
          currentAlertingChannels={currentAlertingChannels}
          onChange={setCurrentAlertingChannels}
        />
      }
      footerComponent={
        <AlertConfigSettingDialogFooterComponent
          onCancelClicked={closeModal}
          onOKClicked={onOkClicked}
        />
      }
    />
  );
}

export default AlertConfigSettingDialog;
