import React, { useCallback, useEffect, useMemo, useState } from "react";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import {
  CollapsibleMetricsSection,
  SLICE_PAGE_SIZE,
} from "./incident-analysis-metrics-sidebar-section";
import {
  filterAndHighlightMetrics,
  metricItemsContext,
  metricSliceColl,
} from "./utils";
import {
  MAX_CHARTS_SHOWN,
  configAddSlices,
  configNumItemsShown,
  configRemoveMetric,
  configUpdateMetric,
  sortConfigItems,
} from "./incident-config";
import { ViewInExplorerIcon } from "../profiler/menu-icons";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import IncidentFindMetricPanel from "./incident-find-metric-panel";
import { NotificationTypeConst, TakeoverWidth } from "../../utils/enums";
import { FindAnyMetricIcon } from "./incident-analysis-view";
import { toast } from "../../components/toast/toast";
import { explorerMetricUrl } from "../profiler/utils";
import { LabeledInput } from "../../components/labeled-control/labeled-control";
import { SearchIcon } from "../../components/icons/input";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { ButtonPaddingSize } from "../../components/button/ng-button";
import { getCurrentIncidentRelatedMetrics } from "../../actions/incident/incident-action";
import {
  closeTakeover,
  openWorkspaceTakeover,
} from "../../actions/takeover/takeover-action";
import { connect } from "react-redux";
import { debounced } from "../../utils/input";
import { CollapsePanelIcon } from "../../components/icons/menu";
import { Spinner } from "../../atom/spinner";

import "./incident-analysis-metrics-sidebar.scss";

export const MIN_SEARCH_QUERY_LENGTH = 2;
export const SEARCH_DEBOUNCE_INTERVAL = 500;

const SidebarMetricsSection = Object.freeze({
  METRICS_OF_INTEREST: "metricsOfInterest",
  USER_DEFINED_RELATED: "userDefinedRelated",
  TIME_CORRELATED: "timeCorrelated",
});

function getRelatedMetricItemActionsFn(args) {
  const { configData, onConfigChange, canEditIncidentConfig, onViewInExplorer } = args;

  function onMetricAdded(metricUuid, slice) {
    const canShow = configNumItemsShown(configData) < MAX_CHARTS_SHOWN;
    const newConfig = configUpdateMetric(configData, metricUuid, slice, (item) => ({
      ...item,
      show: canShow,
    }));
    onConfigChange(newConfig);
  }

  return function (metric, slice) {
    const actions = [
      {
        label: "View in explorer",
        icon: <ViewInExplorerIcon />,
        onClick: () => onViewInExplorer(metric.metricUuid),
      },
    ];
    if (canEditIncidentConfig) {
      actions.push({
        label: "Add to metrics of interest",
        icon: <PlusOutlined />,
        onClick: () => {
          onMetricAdded(metric.metricUuid, slice);
        },
      });
    }
    return actions;
  };
}

function getInterestMetricItemActionsFn(args) {
  const {
    interestMetrics,
    configData,
    onConfigChange,
    canEditIncidentConfig,
    onViewInExplorer,
  } = args;

  function onMetricRemoved(metricUuid, slice) {
    const newConfig = configRemoveMetric(configData, metricUuid, slice);
    onConfigChange(newConfig);
  }

  function onChartShown(metricUuid, slice) {
    const newConfig = configUpdateMetric(configData, metricUuid, slice, (item) => ({
      ...item,
      show: true,
    }));
    onConfigChange(newConfig);
  }

  function onChartHidden(metricUuid, slice) {
    const newConfig = configUpdateMetric(configData, metricUuid, slice, (item) => ({
      ...item,
      show: false,
    }));
    onConfigChange(newConfig);
  }

  return function (metric, slice, _expandSection) {
    const isShown = slice ? slice.show : metric.show;
    const chartItem = isShown
      ? {
          label: "Hide chart",
          icon: <EyeInvisibleOutlined />,
          onClick: () => onChartHidden(metric.metricUuid, slice),
        }
      : {
          label: "Show chart",
          disabled: configNumItemsShown(interestMetrics) >= MAX_CHARTS_SHOWN,
          icon: <EyeOutlined />,
          onClick: () => onChartShown(metric.metricUuid, slice),
        };
    return [
      {
        label: "View in explorer",
        icon: <ViewInExplorerIcon />,
        onClick: () => onViewInExplorer(metric.metricUuid),
      },
      ...(canEditIncidentConfig
        ? [
            chartItem,
            {
              label: "Remove from metrics of interest",
              icon: <MinusOutlined />,
              onClick: () => onMetricRemoved(metric.metricUuid, slice),
            },
          ]
        : []),
    ];
  };
}

function IncidentAnalysisMetricsSidebarMetrics(props) {
  const {
    timeCorrelatedMetrics = [],
    filteredTimeCorrelatedMetrics = [],
    userDefinedRelatedMetrics = [],
    filteredUserDefinedRelatedMetrics = [],
    interestMetrics = [],
    filteredInterestMetrics = [],
    configData = [],
    metricSliceCursors = {},
    sectionsCollapsed = {},
    sectionsExpandedMetrics = {},
    dataSourcesByUuid = {},
    metricsByUuid = {},
    monitorsByMetricUuid = {},
    isSearched = false,
    canEditIncidentConfig,
    onConfigChange,
    onSectionCollapsedChange,
    onExpandedMetricsChange,
    onViewInExplorer,
    onLoadMoreSlicesClick,
  } = props;

  return (
    <div className="incident-analysis-sidebar-metrics">
      <CollapsibleMetricsSection
        title={`Metrics of interest (${interestMetrics.length})`}
        sectionCollapsed={sectionsCollapsed[SidebarMetricsSection.METRICS_OF_INTEREST]}
        metrics={filteredInterestMetrics}
        expandedMetrics={
          sectionsExpandedMetrics[SidebarMetricsSection.METRICS_OF_INTEREST]
        }
        selectedMetricSliceColl={metricSliceColl(
          interestMetrics,
          (metric, slice) => metric.show || slice?.show
        )}
        isSearched={isSearched}
        metricSliceCursors={metricSliceCursors}
        dataSourcesByUuid={dataSourcesByUuid}
        metricsByUuid={metricsByUuid}
        monitorsByMetricUuid={monitorsByMetricUuid}
        onSectionCollapsedChange={(collapsed) =>
          onSectionCollapsedChange(SidebarMetricsSection.METRICS_OF_INTEREST, collapsed)
        }
        onExpandedMetricsChange={(uuid, collapsed) =>
          onExpandedMetricsChange(
            SidebarMetricsSection.METRICS_OF_INTEREST,
            uuid,
            collapsed
          )
        }
        onLoadMoreSlicesClick={onLoadMoreSlicesClick}
        getItemActions={getInterestMetricItemActionsFn({
          interestMetrics,
          configData,
          onConfigChange,
          canEditIncidentConfig,
          onViewInExplorer,
        })}
      />
      <CollapsibleMetricsSection
        title={`Related metrics (${userDefinedRelatedMetrics.length})`}
        sectionCollapsed={sectionsCollapsed[SidebarMetricsSection.USER_DEFINED_RELATED]}
        metrics={filteredUserDefinedRelatedMetrics}
        expandedMetrics={
          sectionsExpandedMetrics[SidebarMetricsSection.USER_DEFINED_RELATED]
        }
        disabledMetricSliceColl={metricSliceColl(interestMetrics)}
        isSearched={isSearched}
        metricSliceCursors={metricSliceCursors}
        dataSourcesByUuid={dataSourcesByUuid}
        metricsByUuid={metricsByUuid}
        monitorsByMetricUuid={monitorsByMetricUuid}
        onSectionCollapsedChange={(collapsed) =>
          onSectionCollapsedChange(
            SidebarMetricsSection.USER_DEFINED_RELATED,
            collapsed
          )
        }
        onExpandedMetricsChange={(uuid, collapsed) =>
          onExpandedMetricsChange(
            SidebarMetricsSection.USER_DEFINED_RELATED,
            uuid,
            collapsed
          )
        }
        onLoadMoreSlicesClick={onLoadMoreSlicesClick}
        getItemActions={getRelatedMetricItemActionsFn({
          configData,
          onConfigChange,
          canEditIncidentConfig,
          onViewInExplorer,
        })}
      />
      <CollapsibleMetricsSection
        title={`Time correlated (${timeCorrelatedMetrics.length})`}
        sectionCollapsed={sectionsCollapsed[SidebarMetricsSection.TIME_CORRELATED]}
        metrics={filteredTimeCorrelatedMetrics}
        expandedMetrics={sectionsExpandedMetrics[SidebarMetricsSection.TIME_CORRELATED]}
        disabledMetricSliceColl={metricSliceColl(interestMetrics)}
        isSearched={isSearched}
        metricSliceCursors={metricSliceCursors}
        dataSourcesByUuid={dataSourcesByUuid}
        metricsByUuid={metricsByUuid}
        monitorsByMetricUuid={monitorsByMetricUuid}
        onSectionCollapsedChange={(collapsed) =>
          onSectionCollapsedChange(SidebarMetricsSection.TIME_CORRELATED, collapsed)
        }
        onExpandedMetricsChange={(uuid, collapsed) =>
          onExpandedMetricsChange(
            SidebarMetricsSection.TIME_CORRELATED,
            uuid,
            collapsed
          )
        }
        onLoadMoreSlicesClick={onLoadMoreSlicesClick}
        getItemActions={getRelatedMetricItemActionsFn({
          configData,
          onConfigChange,
          canEditIncidentConfig,
          onViewInExplorer,
        })}
      />
    </div>
  );
}

function useFilteredMetrics(metrics, query) {
  return useMemo(() => {
    return query.length > 0 ? filterAndHighlightMetrics(metrics, query) : metrics;
  }, [metrics, query]);
}

function expandNonEmptySlicedMetrics(metrics, expandedMetrics) {
  const newExpandedMetrics = new Set([...expandedMetrics]);
  for (let metric of metrics) {
    if (metric.sliceList.length > 0) {
      newExpandedMetrics.add(metric.metricUuid);
    }
  }
  return newExpandedMetrics;
}

function IncidentAnalysisMetricsSidebar(props) {
  const {
    workspaceUserPermissions,
    history,
    workspaceUuid,
    incidentUuid,
    correlatedMetrics,
    userDefinedRelatedMetrics,
    config,
    metrics,
    monitors,
    dataSources,
    startTime,
    endTime,
    getCurrentIncidentCorrelatedMetrics,
    getCurrentIncidentConfig,
    updateIncidentConfig,
    setLeftPanelCollapsed,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const canEditIncidentConfig = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTCONFIGVIEW,
  ]);

  const {
    data: userDefinedRelatedMetricsData,
    loading: userDefinedRelatedMetricsLoading,
  } = userDefinedRelatedMetrics;
  const { data: correlatedMetricsData, loading: correlatedMetricsLoading } =
    correlatedMetrics;
  const { data: interestMetricsData, loading: interestMetricsLoading } = config;

  // The effective query is the one that we are actively using to filter metrics.
  const [lastEffectiveQuery, setLastEffectiveQuery] = useState("");
  const [currentEffectiveQuery, setCurrentEffectiveQuery] = useState("");
  // Have we taken actions required by a change in effective query - e.g. expanding
  // sections?
  const [isLastQueryProcessed, setIsLastQueryProcessed] = useState(false);

  // A map of metric uuid -> number of slices to show, with a default of one page
  // of slices. This persists across changes to the underlying metrics.
  const [metricSliceCursors, setMetricSliceCursors] = useState({});

  // Which sections have been collapsed?
  const [sectionsCollapsed, setSectionsCollapsed] = useState({
    [SidebarMetricsSection.METRICS_OF_INTEREST]: true,
    [SidebarMetricsSection.USER_DEFINED_RELATED]: true,
    [SidebarMetricsSection.TIME_CORRELATED]: true,
  });

  // Which (sliced) metrics have been expanded?
  const [sectionsExpandedMetrics, setSectionsExpandedMetrics] = useState({
    [SidebarMetricsSection.METRICS_OF_INTEREST]: new Set(),
    [SidebarMetricsSection.USER_DEFINED_RELATED]: new Set(),
    [SidebarMetricsSection.TIME_CORRELATED]: new Set(),
  });

  // Deps below are actually exhaustive; the linter isn't smart
  // enough to see it though.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scheduleSearch = useCallback(
    debounced((newQuery) => {
      setLastEffectiveQuery(currentEffectiveQuery);
      setCurrentEffectiveQuery(newQuery);
      setIsLastQueryProcessed(false);
    }, SEARCH_DEBOUNCE_INTERVAL),
    [currentEffectiveQuery, setCurrentEffectiveQuery, setLastEffectiveQuery]
  );

  const filteredRelatedMetrics = useFilteredMetrics(
    userDefinedRelatedMetricsData.data ?? [],
    currentEffectiveQuery
  );
  const filteredCorrelatedMetrics = useFilteredMetrics(
    correlatedMetricsData.data ?? [],
    currentEffectiveQuery
  );
  const filteredInterestMetrics = useFilteredMetrics(
    interestMetricsData.annotatedData ?? [],
    currentEffectiveQuery
  );

  // Expand non-empty sections after loading.

  useEffect(() => {
    if (!interestMetricsLoading && interestMetricsData.data?.length > 0) {
      setSectionsCollapsed((sectionsCollapsed) => ({
        ...sectionsCollapsed,
        [SidebarMetricsSection.METRICS_OF_INTEREST]: false,
      }));
    }
  }, [interestMetricsLoading, interestMetricsData]);

  useEffect(() => {
    if (
      !userDefinedRelatedMetricsLoading &&
      userDefinedRelatedMetricsData.data?.length > 0
    ) {
      setSectionsCollapsed((sectionsCollapsed) => ({
        ...sectionsCollapsed,
        [SidebarMetricsSection.USER_DEFINED_RELATED]: false,
      }));
    }
  }, [userDefinedRelatedMetricsLoading, userDefinedRelatedMetricsData]);

  useEffect(() => {
    if (!correlatedMetricsLoading && correlatedMetricsData.data?.length > 0) {
      setSectionsCollapsed((sectionsCollapsed) => ({
        ...sectionsCollapsed,
        [SidebarMetricsSection.TIME_CORRELATED]: false,
      }));
    }
  }, [correlatedMetricsLoading, correlatedMetricsData]);

  // Actions to execute after a change in effective query.
  useEffect(() => {
    if (
      !isLastQueryProcessed &&
      currentEffectiveQuery.length > 0 &&
      lastEffectiveQuery.length === 0
    ) {
      // Expand non-empty sections.
      setSectionsCollapsed({
        [SidebarMetricsSection.METRICS_OF_INTEREST]:
          filteredInterestMetrics.length === 0,
        [SidebarMetricsSection.USER_DEFINED_RELATED]:
          filteredRelatedMetrics.length === 0,
        [SidebarMetricsSection.TIME_CORRELATED]: filteredCorrelatedMetrics.length === 0,
      });
      setSectionsExpandedMetrics((expandedMetrics) => ({
        [SidebarMetricsSection.METRICS_OF_INTEREST]: expandNonEmptySlicedMetrics(
          filteredInterestMetrics,
          expandedMetrics[SidebarMetricsSection.METRICS_OF_INTEREST]
        ),
        [SidebarMetricsSection.USER_DEFINED_RELATED]: expandNonEmptySlicedMetrics(
          filteredRelatedMetrics,
          expandedMetrics[SidebarMetricsSection.USER_DEFINED_RELATED]
        ),
        [SidebarMetricsSection.TIME_CORRELATED]: expandNonEmptySlicedMetrics(
          filteredCorrelatedMetrics,
          expandedMetrics[SidebarMetricsSection.TIME_CORRELATED]
        ),
      }));
      setIsLastQueryProcessed(true);
    }
  }, [
    isLastQueryProcessed,
    currentEffectiveQuery,
    lastEffectiveQuery,
    filteredInterestMetrics,
    filteredRelatedMetrics,
    filteredCorrelatedMetrics,
  ]);

  const { metricsByUuid, dataSourcesByUuid, monitorsByMetricUuid } = useMemo(() => {
    const getMetricUuid = (metric) => metric.metricUuid;
    const allMetricItemUuids = new Set([
      ...(interestMetricsData.data ?? []).map(getMetricUuid),
      ...(correlatedMetricsData.data ?? []).map(getMetricUuid),
      ...(userDefinedRelatedMetricsData.data ?? []).map(getMetricUuid),
    ]);
    return metricItemsContext({
      metricUuids: allMetricItemUuids,
      dataSources,
      metrics,
      monitors,
    });
  }, [
    correlatedMetricsData.data,
    interestMetricsData.data,
    userDefinedRelatedMetricsData.data,
    dataSources,
    metrics,
    monitors,
  ]);

  function onSectionCollapsedChange(section, collapsed) {
    setSectionsCollapsed({
      ...sectionsCollapsed,
      [section]: collapsed,
    });
  }

  function onExpandedMetricsChange(section, uuid, collapsed) {
    const newExpandedMetrics = new Set([...sectionsExpandedMetrics[section]]);
    if (collapsed) {
      newExpandedMetrics.delete(uuid);
    } else {
      newExpandedMetrics.add(uuid);
    }
    setSectionsExpandedMetrics({
      ...sectionsExpandedMetrics,
      [section]: newExpandedMetrics,
    });
  }

  function onLoadMoreSlicesClick(metricUuid) {
    const cursor = metricSliceCursors[metricUuid] ?? SLICE_PAGE_SIZE;
    setMetricSliceCursors({
      ...metricSliceCursors,
      [metricUuid]: cursor + SLICE_PAGE_SIZE,
    });
  }

  useEffect(() => {
    getCurrentIncidentCorrelatedMetrics(workspaceUuid, incidentUuid, {
      startTime,
      endTime,
    });
  }, [
    workspaceUuid,
    incidentUuid,
    getCurrentIncidentCorrelatedMetrics,
    startTime,
    endTime,
  ]);

  function onAddMetric(metricUuid, slices) {
    const configData = interestMetricsData.data ?? [];
    const showChartCapacity = MAX_CHARTS_SHOWN - configNumItemsShown(configData);
    const numToShow = slices.length > 0 ? slices.length : 1;
    const numUnableToShow = Math.max(0, numToShow - showChartCapacity);
    const newConfig =
      slices.length > 0
        ? configAddSlices(configData, metricUuid, slices)
        : configUpdateMetric(configData, metricUuid, null, (metricItem) => ({
            ...metricItem,
            show: configNumItemsShown(configData) < MAX_CHARTS_SHOWN,
          }));
    updateIncidentConfig(workspaceUuid, incidentUuid, {
      ...(interestMetricsData.timeRange ? interestMetricsData.timeRange : {}),
      data: newConfig,
    }).then(() => {
      getCurrentIncidentConfig(workspaceUuid, incidentUuid, {
        startTime,
        endTime,
        quiet: true,
      });
      if (numUnableToShow > 0) {
        const metricName = metricsByUuid[metricUuid].metadata.name;
        const msg =
          numUnableToShow === numToShow
            ? `Metric ${metricName} added to metrics of interest. Its ${numUnableToShow} chart(s) could not be added due to ${MAX_CHARTS_SHOWN} chart max`
            : `Metric ${metricName} added to metrics of interest. ${numUnableToShow} of ${numToShow} charts could not be added due to ${MAX_CHARTS_SHOWN} chart max`;
        toast(msg, NotificationTypeConst.WARNING);
      }
    });
  }

  sortConfigItems(correlatedMetricsData.data);
  sortConfigItems(interestMetricsData.annotatedData);

  const menuItems = canEditIncidentConfig
    ? [
        {
          label: "Find a metric",
          icon: <FindAnyMetricIcon />,
          onClick: () => {
            openWorkspaceTakeover(
              <IncidentFindMetricPanel
                workspaceUuid={workspaceUuid}
                metrics={metrics}
                closeTakeover={closeTakeover}
                onAdd={onAddMetric}
              />,
              TakeoverWidth.NORMAL,
              () => closeTakeover()
            );
          },
        },
      ]
    : [];
  menuItems.push({
    label: "Collapse panel",
    icon: <CollapsePanelIcon />,
    onClick: () => setLeftPanelCollapsed(true),
  });

  function onConfigChange(newConfig) {
    updateIncidentConfig(workspaceUuid, incidentUuid, {
      ...(interestMetricsData.timeRange ? interestMetricsData.timeRange : {}),
      data: newConfig,
    }).then(() => {
      return getCurrentIncidentConfig(workspaceUuid, incidentUuid, {
        startTime,
        endTime,
        quiet: true,
      });
    });
  }

  function onViewInExplorer(metricUuid) {
    history.push(explorerMetricUrl(metricsByUuid[metricUuid]));
  }

  return (
    <div className="incident-analysis-sidebar-container">
      <div className="incident-analysis-sidebar-actions">
        <div className="incident-analysis-sidebar-actions-search">
          <LabeledInput
            placeholder="Search..."
            allowClear
            suffix={<SearchIcon />}
            onChange={(e) => {
              const newQuery = e.target.value;
              scheduleSearch(
                newQuery.length >= MIN_SEARCH_QUERY_LENGTH ? newQuery : ""
              );
            }}
            disabled={correlatedMetricsLoading}
          />
        </div>
        <NgDropdownMenu
          buttonText="Actions"
          buttonProps={{
            size: "large",
            paddingSize: ButtonPaddingSize.SMALL,
            disabled: correlatedMetricsLoading,
          }}
          menuItems={menuItems}
        />
      </div>
      {correlatedMetricsLoading ||
      interestMetricsLoading ||
      userDefinedRelatedMetricsLoading ? (
        <div className="incident-analysis-sidebar-loading">
          <Spinner size="large" />
        </div>
      ) : (
        <IncidentAnalysisMetricsSidebarMetrics
          isSearched={currentEffectiveQuery.length > 0}
          timeCorrelatedMetrics={correlatedMetricsData.data}
          filteredTimeCorrelatedMetrics={filteredCorrelatedMetrics}
          userDefinedRelatedMetrics={userDefinedRelatedMetricsData.data}
          filteredUserDefinedRelatedMetrics={filteredRelatedMetrics}
          interestMetrics={interestMetricsData.annotatedData}
          filteredInterestMetrics={filteredInterestMetrics}
          sectionsCollapsed={sectionsCollapsed}
          sectionsExpandedMetrics={sectionsExpandedMetrics}
          configData={interestMetricsData.data}
          metricSliceCursors={metricSliceCursors}
          dataSourcesByUuid={dataSourcesByUuid}
          metricsByUuid={metricsByUuid}
          monitorsByMetricUuid={monitorsByMetricUuid}
          onLoadMoreSlicesClick={onLoadMoreSlicesClick}
          onConfigChange={onConfigChange}
          onSectionCollapsedChange={onSectionCollapsedChange}
          onExpandedMetricsChange={onExpandedMetricsChange}
          onViewInExplorer={onViewInExplorer}
          canEditIncidentConfig={canEditIncidentConfig}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  correlatedMetrics: state.incidentReducer.currentIncidentCorrelatedMetrics,
  userDefinedRelatedMetrics:
    state.incidentReducer.currentIncidentUserDefinedRelatedMetrics,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentIncidentCorrelatedMetrics: (workspaceUuid, incidentUuid, opts) =>
    dispatch(getCurrentIncidentRelatedMetrics(workspaceUuid, incidentUuid, opts)),
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick, className) =>
    dispatch(
      openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick, className)
    ),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentAnalysisMetricsSidebar);
