import * as types from "./data-source-action-types";
import { RequestStatus } from "../../utils/enums";
import {
  addDataSourcePromise,
  deleteDataSourcePromise,
  getDataSourcePromise,
  getDataSourceQueryListPromise,
  getDataSourceUsagePromise,
  getDataSourceListPromise,
  previewDataSourcePromise,
  updateDataSourcePromise,
  updateDataSourceProfilerConfigPromise,
} from "../../rest/datasource";
import { getKpiListPromise } from "../../rest/kpi";
import {
  getSystemIntegrationListPromise,
  getSystemIntegrationMetadataPromise,
} from "../../rest/user";
import { getTagList } from "../tag/tag-action";

function setDataSourceList(dataSourceList) {
  return { type: types.SET_DATA_SOURCE_LIST, dataSourceList };
}

function setDataSourceAlationDataSourceList(dataSourceAlationDataSourceList) {
  return {
    type: types.SET_DATA_SOURCE_ALATION_DATA_SOURCE_LIST,
    dataSourceAlationDataSourceList,
  };
}

function setDataSourceAtlanDataSourceList(dataSourceAtlanDataSourceList) {
  return {
    type: types.SET_DATA_SOURCE_ATLAN_DATA_SOURCE_LIST,
    dataSourceAtlanDataSourceList,
  };
}

function setLastPreviewTime(previewTime) {
  return { type: types.SET_LAST_PREVIEW_TIME, lastPreviewTime: previewTime };
}

function setLastPreviewStatus(previewStatus) {
  return {
    type: types.SET_LAST_PREVIEW_STATUS,
    lastPreviewStatus: previewStatus,
  };
}

function setLastPreviewResult(previewTableList) {
  return {
    type: types.SET_LAST_PREVIEW_RESULT,
    lastPreviewResult: previewTableList,
  };
}

function setAddDataSourceStatus(loading) {
  return {
    type: types.SET_ADD_DATA_SOURCE_REQUEST_STATUS,
    creatingStatus: loading,
  };
}

function setEditDataSourceStatus(loading) {
  return {
    type: types.SET_EDIT_DATA_SOURCE_REQUEST_STATUS,
    editingStatus: loading,
  };
}

function setCurrentEditData(data) {
  return { type: types.SET_CURRENT_EDIT_DATA, currentEditData: data };
}

function setCurrentCloneDataSource(data) {
  return {
    type: types.SET_CURRENT_CLONE_DATA_SOURCE,
    currentCloneDataSource: data,
  };
}

function setCurrentDataSourceUsage(data) {
  return {
    type: types.SET_CURRENT_DATA_SOURCE_USAGE,
    currentDataSourceUsage: data,
  };
}

function setCurrentDataSourceQueryList(data) {
  return {
    type: types.SET_CURRENT_DATA_SOURCE_QUERY_LIST,
    currentDataSourceQueryList: data,
  };
}

export function getDataSourceSystemIntegrationMetadata() {
  return (dispatch, getState) => {
    dispatch(setDataSourceAlationDataSourceList({ loading: true, data: [] }));
    dispatch(setDataSourceAtlanDataSourceList({ loading: true, data: [] }));
    getSystemIntegrationListPromise()
      .then(function (systemIntegrationList) {
        const alationSystemIntegration = systemIntegrationList.data.find(
          (systemIntegration) => systemIntegration.config.type === "alation"
        );
        const atlanSystemIntegration = systemIntegrationList.data.find(
          (systemIntegration) => systemIntegration.config.type === "atlan"
        );
        Promise.all(
          [alationSystemIntegration, atlanSystemIntegration].map(
            (currentIntegration) => {
              if (currentIntegration) {
                return getSystemIntegrationMetadataPromise(
                  currentIntegration.metadata.uuid
                );
              }

              return Promise.resolve([]);
            }
          )
        ).then(([dataSourceAlationDataSourceList, dataSourceAtlanDataSourceList]) => {
          dispatch(
            setDataSourceAlationDataSourceList({
              loading: false,
              data: dataSourceAlationDataSourceList,
            })
          );
          dispatch(
            setDataSourceAtlanDataSourceList({
              loading: false,
              data: dataSourceAtlanDataSourceList,
            })
          );
        });
      })
      .catch(function (err) {
        dispatch(setDataSourceAlationDataSourceList({ loading: true, data: [] }));
        dispatch(setDataSourceAtlanDataSourceList({ loading: true, data: [] }));
      });
  };
}

export function getDataSourceList(workspaceUuid, opts = {}) {
  return (dispatch, getState) => {
    const { isRefresh = false } = opts;
    !isRefresh && dispatch(setDataSourceList({ loading: true, data: [] }));
    getDataSourceListPromise(workspaceUuid)
      .then(function (dataSourceList) {
        dispatch(setDataSourceList({ loading: false, data: dataSourceList }));
      })
      .catch(function (error) {
        console.log(`Fail to load data source list for ${error}`);
        dispatch(setDataSourceList({ loading: false, data: [] }));
      });
  };
}

export function updateDataSourceProfilerConfig(
  workspaceUuid,
  dataSourceUI,
  dataSourceProfilerConfig
) {
  return (dispatch, getState) => {
    updateDataSourceProfilerConfigPromise(
      workspaceUuid,
      dataSourceUI.metadata.uuid,
      dataSourceProfilerConfig
    )
      .then(function (response) {
        dispatch(getDataSourceList(workspaceUuid));
      })
      .catch(function (err) {
        console.log(`Fail to put config data source list ${err}`);
      });
  };
}

export function getDataSourceUsage(workspaceUuid, dataSourceUI) {
  return (dispatch, getState) => {
    dispatch(
      setCurrentDataSourceUsage({
        loading: true,
        data: {},
      })
    );

    getDataSourceUsagePromise(workspaceUuid, dataSourceUI.metadata.uuid)
      .then(function (currentDatasourceUsage) {
        let totalMetrics = 0;
        let totalRules = 0;
        for (let currentMetricUsage of currentDatasourceUsage.metrics) {
          totalMetrics = totalMetrics + 1;
          for (let currentRule of currentMetricUsage.rules) {
            if (currentRule.isLive) {
              totalRules = totalRules + 1;
            }
          }
        }

        dispatch(
          setCurrentDataSourceUsage({
            loading: false,
            data: {
              totalMetrics,
              totalRules,
            },
          })
        );
      })
      .catch(function (err) {
        dispatch(
          setCurrentDataSourceUsage({
            loading: false,
            data: {},
          })
        );
        console.log(`Fail to put config data source list ${err}`);
      });
  };
}

export function addDataSource(workspaceUuid, dataSource) {
  return (dispatch, getState) => {
    dispatch(setAddDataSourceStatus(RequestStatus.DOING));
    return addDataSourcePromise(workspaceUuid, dataSource)
      .then(function (response) {
        dispatch(getDataSourceList(workspaceUuid));
        dispatch(setAddDataSourceStatus(RequestStatus.SUCCESS));
      })
      .catch(function (error) {
        console.log(`Fail to create data source for ${error}`);
        dispatch(setAddDataSourceStatus(RequestStatus.FAILURE));
      });
  };
}

export function previewDataSource(workspaceUuid, dataSource) {
  const currentTime = new Date().toLocaleTimeString();
  return (dispatch) => {
    dispatch(setLastPreviewStatus(RequestStatus.DOING));
    previewDataSourcePromise(workspaceUuid, dataSource)
      .then(function (response) {
        dispatch(setLastPreviewResult(response));
        dispatch(setLastPreviewStatus(RequestStatus.SUCCESS));
      })
      .catch(function (error) {
        console.log(`Fail to preview data source data for ${error}`);
        dispatch(
          setLastPreviewResult({
            connectionStatus: "failure",
            stacktrace: `${error}`,
          })
        );
        dispatch(setLastPreviewStatus(RequestStatus.SUCCESS));
      })
      .finally(function () {
        dispatch(setLastPreviewTime(currentTime));
      });
  };
}

export function editDataSource(workspaceUuid, id, dataSource) {
  return (dispatch, getState) => {
    dispatch(setEditDataSourceStatus(RequestStatus.DOING));
    return updateDataSourcePromise(workspaceUuid, id, dataSource)
      .then(function (response) {
        dispatch(getDataSourceList(workspaceUuid));
        dispatch(setEditDataSourceStatus(RequestStatus.SUCCESS));
      })
      .catch(function (error) {
        console.log(`Fail to edit data source for ${error}`);
        dispatch(setEditDataSourceStatus(RequestStatus.FAILURE));
      });
  };
}

export function deleteDataSource(workspaceUuid, dataSource) {
  return (dispatch, getState) => {
    deleteDataSourcePromise(workspaceUuid, dataSource.metadata.uuid)
      .then(function (response) {
        dispatch(getDataSourceList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to delete data source for ${error}`);
      });
  };
}

export function getDataSource(workspaceUuid, id, requestVersion = 0, isClone = false) {
  return (dispatch, getState) => {
    getDataSourcePromise(workspaceUuid, id)
      .then(function (dataSourceUI) {
        if (!isClone) {
          dispatch(setCurrentEditData(dataSourceUI));
        } else {
          dispatch(setCurrentCloneDataSource(dataSourceUI));
        }
      })
      .catch(function (error) {
        console.log(`Fail to get data source for ${error}`);
      })
      .finally(function () {});
  };
}

export function updateDataSourceTags(workspaceUuid, dataSourceUI, newTags) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid, tags },
    } = dataSourceUI;
    if (JSON.stringify(tags) === JSON.stringify(newTags)) {
      return;
    }

    dataSourceUI.metadata.tags = newTags;
    updateDataSourcePromise(workspaceUuid, uuid, dataSourceUI)
      .then(function (response) {
        dispatch(getDataSourceList(workspaceUuid));
        dispatch(getTagList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to update tags for data source due to ${error}`);
      });
  };
}

export function updateDataSource(workspaceUuid, dataSource) {
  return (dispatch, getState) => {
    updateDataSourcePromise(workspaceUuid, dataSource.metadata.uuid, dataSource)
      .then(function (response) {
        dispatch(getDataSourceList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to update data source due to ${error}`);
      });
  };
}

export function updateDataSourceGovernance(workspaceUuid, dataSourceUI, newGovernance) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid },
      config: { governance },
    } = dataSourceUI;
    if (JSON.stringify(governance) === JSON.stringify(newGovernance)) {
      return;
    }

    dataSourceUI.config.governance = newGovernance;
    updateDataSourcePromise(workspaceUuid, uuid, dataSourceUI)
      .then(function (responseData) {
        const { dataSourceList } = getState().dataSourceReducer;
        const newData = [];
        for (let dataSource of dataSourceList.data) {
          if (dataSource.metadata.uuid !== uuid) {
            newData.push(dataSource);
          } else {
            newData.push(responseData);
          }
        }

        dispatch(setDataSourceList({ loading: false, data: newData }));
      })
      .catch(function (error) {
        console.log(`Fail to update governance for data source due to ${error}`);
      });
  };
}

export function getDataSourceQueryList(
  workspaceUuid,
  dataSourceUuid,
  { startTs, endTs }
) {
  return (dispatch, getState) => {
    dispatch(setCurrentDataSourceQueryList({ loading: true, data: [] }));
    Promise.all([
      getDataSourceQueryListPromise(workspaceUuid, dataSourceUuid, {
        startTs,
        endTs,
      }),
      getKpiListPromise(workspaceUuid, { dataSourceUuids: [dataSourceUuid] }),
    ])
      .then(function ([queryHistoryList, kpiList]) {
        const kpiMapper = {};
        kpiList.forEach(
          (currentKpi) => (kpiMapper[currentKpi.metadata.uuid] = currentKpi)
        );

        const normalizedQueryHistoryData = [];
        for (let currentQueryHistoryItem of queryHistoryList) {
          const { querySourceRef } = currentQueryHistoryItem;
          let metricUuid;
          let metricObject = null;
          if (
            typeof querySourceRef === "string" &&
            querySourceRef.startsWith("metricUuid:")
          ) {
            metricUuid = querySourceRef.substring("metricUuid:".length);
          } else if (
            querySourceRef.metricUuid &&
            typeof querySourceRef.metricUuid === "string"
          ) {
            metricUuid = querySourceRef.metricUuid;
          } else {
            console.log(`Unknown querySourceRef:${querySourceRef}`);
          }

          if (metricUuid && kpiMapper[metricUuid]) {
            metricObject = kpiMapper[metricUuid];
          }

          normalizedQueryHistoryData.push({
            ...currentQueryHistoryItem,
            metricObject,
          });
        }

        dispatch(
          setCurrentDataSourceQueryList({
            loading: false,
            data: normalizedQueryHistoryData,
          })
        );
      })
      .catch(function (err) {
        dispatch(setCurrentDataSourceQueryList({ loading: false, data: [] }));
        console.log(
          `Fail to get query list for data source ${dataSourceUuid} due to ${err}`
        );
      });
  };
}

export function resetDataSourceStatus() {
  return (dispatch, getState) => {
    // For editing date source
    dispatch(setCurrentEditData({}));
    dispatch(setEditDataSourceStatus(RequestStatus.UNKNOWN));

    // For alation data source list
    dispatch(setDataSourceAlationDataSourceList({ loading: true, data: [] }));

    // For adding date source
    dispatch(setAddDataSourceStatus(RequestStatus.UNKNOWN));
    dispatch(setCurrentCloneDataSource(null));

    // For preview
    dispatch(setLastPreviewResult([]));
    dispatch(setLastPreviewTime(""));
    dispatch(setLastPreviewStatus(RequestStatus.UNKNOWN));
  };
}
