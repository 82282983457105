import React, { Component } from "react";
import { Redirect } from "react-router";
import { ProfilerAfterTreeNavigationActionType } from "../../../actions/profiler/profiler-action";
import TreePath from "../../../components/explorer/tree-path";
import {
  getDefaultRuleConfigForAutoMetrics,
  getDefaultRuleConfigForCustomMetric,
} from "../../../utils/defaults";
import { TreePathLevel, isFileSource as getIsFileSource } from "../../../utils/enums";
import { isActivityMetric, isAutoMetric } from "../../../utils/metric";
import { AppPermissions } from "../../../utils/permissions";
import {
  isSomeMetadataMetricEnabled,
  tableSupportsDataProfiling,
} from "../../../utils/table";
import {
  EVENT,
  PAGE,
  getMonitorDetailProps,
  getTableDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import { URIPath, getURIInstance, hasPermission } from "../../../utils/uri-path";
import DeleteMonitorConfirmationDialog from "../../monitors/delete-monitor-confirmation-dialog";
import ProfilerMonitorAllDialog from "../profiler-monitor-all-dialog";
import {
  ProfilerScanStatus,
  ProfilerTableScanFailureReason,
} from "../profiler-scan-status";
import ProfilerSummaryTabView, {
  defaultTabKey,
  nodeTypeSupportedTabs,
  tableNodeSupportsTabKey,
} from "../profiler-summary-tab-view";
import { ProfilerTreeNodeType } from "../tree-data";
import {
  ProfilerSummaryTabKey,
  buildNewRuleAndProfileConfigUpdateForTable,
  canViewChecklist,
  canViewDataProfile,
} from "../utils";
import ProfilerDataSourceTableConfigureTab from "./profiler-data-source-table-configure-tab";
import ProfilerDataSourceTableDataProfileTab from "./profiler-data-source-table-data-profile-tab";
import ProfilerDataSourceTableHealthTab from "./profiler-data-source-table-health-tab";
import ProfilerDataSourceTableMainTab from "./profiler-data-source-table-main-tab";
import ProfilerDataSourceTableManageColumnsTab from "./profiler-data-source-table-manage-columns-tab";
import ProfileDatasourceActivityLog from "./profliler-data-source-table-activity-tab";
import { deepMerge } from "../../../utils/deep-merge";

import "./profiler-data-source-table-overview.scss";

export function ProfilerDataSourceTableOverviewHeader(props) {
  const {
    datasourceType,
    datasourceName,
    table,
    schema,
    lastScannedStatus = null,
    lastScannedFailedReason = null,
    currentSelectNode,
    onCurrentSelectNodeChange,
    isTreeExpand,
    onTreeExpandChange,
  } = props;

  return (
    <div>
      <TreePath
        datasource={{ type: datasourceType, name: datasourceName }}
        selectedLevel={TreePathLevel.TABLE}
        schema={schema}
        table={table}
        statusComponent={
          <ProfilerScanStatus
            lastScannedStatus={lastScannedStatus}
            lastScannedFailedReason={lastScannedFailedReason}
          />
        }
        currentSelectNode={currentSelectNode}
        onCurrentSelectNodeChange={onCurrentSelectNodeChange}
        isTreeExpand={isTreeExpand}
        onTreeExpandChange={onTreeExpandChange}
      />
    </div>
  );
}

class ProfilerDataSourceTableOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: null,
      schemaInfo: null,
      tableInfo: null,
      nextUrl: null,
      monitorDialogContext: false,
      deleteMonitorConfirmationDialogTarget: null,
      isEventChartEnabled: false,
      isDataChartEnabled: false,
      isDataProfileEnabled: false,
      isTableMetricDefined: false,
      activeTabs: [],
    };
    this.onEnableMonitorAllClicked = this.onEnableMonitorAllClicked.bind(this);
    this.onDeleteMonitorClick = this.onDeleteMonitorClick.bind(this);
    this.onDeleteMonitorConfirmed = this.onDeleteMonitorConfirmed.bind(this);
    this.refreshCurrentTab = this.refreshCurrentTab.bind(this);
    this.onGoToRule = this.onGoToRule.bind(this);
    this.onCloneMonitor = this.onCloneMonitor.bind(this);
    this.onGoToMetric = this.onGoToMetric.bind(this);
    this.onManageAutoMetrics = this.onManageAutoMetrics.bind(this);
    this.onOpenTableConfig = this.onOpenTableConfig.bind(this);
    this.onMonitorAll = this.onMonitorAll.bind(this);
    this.onMonitorTableHealthMetric = this.onMonitorTableHealthMetric.bind(this);
    this.onTriggerAll = this.onTriggerAll.bind(this);
    this.updateTableDataProfilerEnabled =
      this.updateTableDataProfilerEnabled.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { dataSource, tableInfo, schemaInfo, waffle, workspaceUserPermissions } =
      props;
    if (
      state.dataSource === null ||
      state.dataSource.metadata.uuid !== dataSource.metadata.uuid ||
      state.schemaInfo !== schemaInfo ||
      state.tableInfo !== tableInfo
    ) {
      const isTableMetricDefined =
        isSomeMetadataMetricEnabled(tableInfo) ||
        (tableInfo.profilerConfig?.enabled &&
          (tableInfo.profilerConfig?.dataDelay?.enabled ||
            tableInfo.profilerConfig?.volume?.enabled));

      const isEventChartEnabled = hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEPROFILESUMMARYVIEW,
      ]);

      const isDataChartEnabled = hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
        AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
        AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
      ]);

      const canModifyTableSettings = hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
      ]);

      const isDataProfileEnabled = canViewDataProfile(workspaceUserPermissions);

      const isFileSource = getIsFileSource(dataSource?.config?.connection?.type);
      const activeTabs = nodeTypeSupportedTabs[ProfilerTreeNodeType.TABLE].filter(
        (tabKey) =>
          tableNodeSupportsTabKey(
            { dataSource, tableInfo, waffle, workspaceUserPermissions },
            tabKey
          )
      );

      if (
        ((isDataProfileEnabled && tableInfo?.profilerConfig?.dataProfiler?.enabled) ||
          canModifyTableSettings) &&
        !isFileSource &&
        tableSupportsDataProfiling(tableInfo)
      ) {
        tableInfo?.profilerConfig?.dataProfiler?.enabled &&
          props.getProfilerTableColumnListDataProfileData(dataSource, tableInfo);
      }

      if (canViewChecklist(workspaceUserPermissions)) {
        props.getProfilerCurrentTableOverviewSummary(dataSource, tableInfo);
      }

      return {
        ...state,
        isEventChartEnabled,
        isDataChartEnabled,
        isTableMetricDefined,
        isDataProfileEnabled,
        dataSource,
        schemaInfo,
        tableInfo,
        activeTabs,
      };
    }

    return null;
  }

  componentDidUpdate() {
    const { profilerAfterTreeNavigationAction, setProfilerAfterTreeNavigationAction } =
      this.props;
    if (profilerAfterTreeNavigationAction) {
      this.performAfterTreeNavigationAction(profilerAfterTreeNavigationAction);
      setProfilerAfterTreeNavigationAction(null);
    }
  }

  performAfterTreeNavigationAction(action) {
    switch (action.type) {
      case ProfilerAfterTreeNavigationActionType.TABLE_OPEN_CONFIG:
        this.onOpenTableConfig();
        break;
      case ProfilerAfterTreeNavigationActionType.TABLE_MONITOR_ALL_METRICS:
        this.onMonitorAll();
        break;
      case ProfilerAfterTreeNavigationActionType.TABLE_TRIGGER_ALL_METRICS:
        this.onTriggerAll();
        break;
      default:
        console.warn("Don't know how to handle action", action);
    }
  }

  componentWillUnmount() {
    this.props.resetProfilerCurrentTableSummary();
  }

  onEnableMonitorAllClicked(groupedAutoMetricsMapper, alertChannelConfig) {
    const { dataSource, schemaInfo, tableInfo } = this.state;

    trackEvent(EVENT.MONITOR_DEEP_METRICS, {
      ...getTableDetailProps(tableInfo, schemaInfo, dataSource),
      page: PAGE.EXPLORER_TABLE,
    });

    const { rules, profilerConfigUpdates } = buildNewRuleAndProfileConfigUpdateForTable(
      alertChannelConfig,
      dataSource,
      schemaInfo,
      tableInfo,
      groupedAutoMetricsMapper,
      true
    );

    trackEvent(EVENT.SAVE_MONITORS, {
      ...getTableDetailProps(tableInfo, schemaInfo, dataSource),
      page: PAGE.EXPLORER_TABLE,
    });

    this.props.monitorAllForTable({ rules, profilerConfigUpdates }).then(() => {
      this.refreshCurrentTab(true);
    });
  }

  onDeleteMonitorClick(monitor) {
    this.setState({ deleteMonitorConfirmationDialogTarget: monitor });
  }

  refreshCurrentTab(refreshAllTabs = false) {
    const {
      dataSource,
      tableInfo,
      getProfilerCurrentTableAutoMetricsData,
      getProfilerCurrentTableHealthData,
      getProfilerCurrentTableMetadataMetricsData,
      getProfilerCurrentTableOverviewSummary,
      tabKey,
    } = this.props;
    const { isEventChartEnabled, isDataChartEnabled } = this.state;

    // For triggering all for table level, it will affect all the triggered metrics under this table
    if (tabKey === ProfilerSummaryTabKey.AUTO_METRICS || refreshAllTabs) {
      getProfilerCurrentTableAutoMetricsData(dataSource, tableInfo, {
        isEventChartEnabled:
          isEventChartEnabled && tableInfo.profilerConfig?.tableSchemaChange?.enabled,
        isDataChartEnabled,
        isRefresh: true,
      });
      getProfilerCurrentTableMetadataMetricsData(dataSource, tableInfo, {
        isRefresh: true,
      });
    }

    if (tabKey === ProfilerSummaryTabKey.HEALTH || refreshAllTabs) {
      getProfilerCurrentTableHealthData(dataSource, tableInfo, { isRefresh: true });
      getProfilerCurrentTableOverviewSummary(dataSource, tableInfo);
    }
  }

  onDeleteMonitorConfirmed(monitors) {
    const { workspaceUuid, deleteRule } = this.props;
    const monitorUuid = monitors[0].metadata.uuid;

    trackEvent(EVENT.DELETE_MONITOR, {
      ...getMonitorDetailProps(monitors[0]),
    });

    deleteRule(workspaceUuid, monitorUuid).then(() => {
      this.refreshCurrentTab(true);
    });
  }

  onGoToRule(relatedMetric, ruleUuid) {
    if (!ruleUuid) {
      console.log("Related rule uuid is empty.");
      return;
    }

    this.setState({
      nextUrl: getURIInstance(URIPath.EDIT_MONITOR, {
        workspaceUuid: this.props.workspaceUuid,
        id: ruleUuid,
      }),
    });
  }

  onGoToMetric(relatedMetric) {
    if (!relatedMetric) {
      console.log("Metric is empty.");
      return;
    }

    this.setState({
      nextUrl: getURIInstance(URIPath.EDIT_METRIC, {
        workspaceUuid: this.props.workspaceUuid,
        id: relatedMetric.metadata.uuid,
      }),
    });
  }

  onCloneMonitor(relatedMetric, monitorUuid) {
    if (!monitorUuid) {
      console.log("Related rule uuid is empty.");
      return;
    }
    this.setState({
      nextUrl: `${getURIInstance(URIPath.ADD_MONITOR, {
        workspaceUuid: this.props.workspaceUuid,
      })}?cloneFrom=${monitorUuid}`,
    });
  }

  onTriggerMetrics(dataSourceUuid, payload, refreshAllTabs) {
    this.props.triggerProfilerMetrics(dataSourceUuid, payload).then(() => {
      this.refreshCurrentTab(refreshAllTabs);
    });
  }

  onMonitorTableHealthMetric(metric, symptomTypeConfig) {
    const {
      createCustomMetricRule,
      createSystemProfilerRule,
      updateSystemProfileDataSourceTableRuleAlertingChannel,
    } = this.props;
    const { dataSource, schemaInfo, tableInfo } = this.state;

    console.log(`Start monitoring for ${metric ? metric.metadata.uuid : ""}`);

    let createMonitorPromise = null;
    if (isActivityMetric(metric)) {
      const newProfilerConfig = deepMerge(tableInfo.profilerConfig, {
        metadataMetrics: {
          schemaChange: {
            monitoring: {
              enabled: true,
              alertConfig: symptomTypeConfig.alertChannelConfig,
            },
          },
        },
      });
      createMonitorPromise = updateSystemProfileDataSourceTableRuleAlertingChannel(
        dataSource.metadata.uuid,
        schemaInfo.uuid,
        tableInfo.uuid,
        newProfilerConfig,
        tableInfo.status
      );
    } else if (isAutoMetric(metric)) {
      const ruleConfig = getDefaultRuleConfigForAutoMetrics(
        symptomTypeConfig,
        metric,
        dataSource,
        tableInfo,
        null,
        metric?.status?.lastSampleTs || 0
      );
      createMonitorPromise = createSystemProfilerRule(ruleConfig);
    } else {
      const ruleConfig = getDefaultRuleConfigForCustomMetric(
        symptomTypeConfig,
        metric,
        0
      );
      createMonitorPromise = createCustomMetricRule(ruleConfig);
    }

    if (createMonitorPromise) {
      createMonitorPromise.then(() => {
        this.refreshCurrentTab(true);
      });
    }
  }

  onTriggerAll() {
    const { tableInfo, dataSource } = this.state;
    this.onTriggerMetrics(
      dataSource.metadata.uuid,
      {
        tableUuids: [tableInfo.uuid],
      },
      true
    );
  }

  onOpenTableConfig() {
    const { tableInfo, schemaInfo, dataSource } = this.state;
    trackEvent(EVENT.CONFIGURE_TABLE, {
      ...getTableDetailProps(tableInfo, schemaInfo, dataSource),
      page: PAGE.EXPLORER_TABLE,
    });
    this.props.onOpenTableConfig({ dataSource, tableList: [tableInfo] });
  }

  onManageAutoMetrics() {
    const { tableInfo, schemaInfo, dataSource } = this.state;
    trackEvent(EVENT.MANAGE_COLUMNS, {
      ...getTableDetailProps(tableInfo, schemaInfo, dataSource),
      page: PAGE.EXPLORER_TABLE,
    });
    this.props.onTabChange(ProfilerSummaryTabKey.MANAGE_COLUMNS);
  }

  onMonitorAll() {
    const { tableInfo, schemaInfo, dataSource } = this.state;
    this.props.getProfilerCurrentMonitorObject({
      sourceUuid: dataSource.metadata.uuid,
      schemaUuid: schemaInfo.uuid,
      tableUuid: tableInfo.uuid,
    });
    this.setState({ monitorDialogContext: true });
  }

  updateTableDataProfilerEnabled(newValue) {
    const { tableInfo } = this.state;
    const { updateTableConfig } = this.props;
    updateTableConfig({
      ...tableInfo,
      profilerConfig: {
        ...tableInfo.profilerConfig,
        dataProfiler: {
          ...tableInfo.profilerConfig.dataProfiler,
          enabled: newValue,
        },
      },
    });
  }

  render() {
    const {
      activeTabs,
      dataSource,
      schemaInfo,
      tableInfo,
      nextUrl,
      monitorDialogContext,
      isEventChartEnabled,
      isDataChartEnabled,
    } = this.state;
    if (nextUrl) {
      return <Redirect push to={nextUrl} />;
    }

    if (!dataSource || !tableInfo) {
      return null;
    }

    const {
      metadata: { name: datasourceName },
      config: {
        connection: { type: datasourceType },
      },
    } = dataSource;

    const {
      availableNodeKeys,
      profilerCurrentMonitorObject,
      workspaceUuid,
      // If workspaceUserPermissions not passed, allow full access
      workspaceUserPermissions = { isSuperuser: true, permissions: [] },
      profilerTableDataProfileData,
      profilerCurrentTableHealthData,
      currentSelectNode,
      onCurrentSelectNodeChange,
      isTreeExpand,
      onTreeExpandChange,
    } = this.props;

    const canModifySettings = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
      AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
    ]);

    const sharedProps = {
      metricRefreshInterval: this.props.metricRefreshInterval,
      createSystemProfilerRule: this.props.createSystemProfilerRule,
      toggleSystemProfilerRuleStatus: this.props.toggleSystemProfilerRuleStatus,
      updateProfilerTableAutoMetricsChartData:
        this.props.updateProfilerTableAutoMetricsChartData,
      updateProfilerTableMetadataMetricsChartData:
        this.props.updateProfilerTableMetadataMetricsChartData,
      updateSystemProfileTableRuleAlertingChannel:
        this.props.updateSystemProfileTableRuleAlertingChannel,
      updateSystemProfileDataSourceTableRuleAlertingChannel:
        this.props.updateSystemProfileDataSourceTableRuleAlertingChannel,
      updateTableConfig: this.props.updateTableConfig,
      triggerProfilerMetrics: (...args) => this.onTriggerMetrics(...args, false),
      openTableConfig: this.onOpenTableConfig,
      alertingChannelList: this.props.alertingChannelList,
      onGoToIncidentPage: this.props.onGoToIncidentPage,
      workspaceUserPermissions: workspaceUserPermissions,
      toggleKpiLiveStatus: this.props.toggleKpiLiveStatus,
      onCreateCustomMetric: this.props.onCreateCustomMetric,
      onDeleteMonitorClick: this.onDeleteMonitorClick,
      canModifySettings,
      onGoToRule: this.onGoToRule,
      onGoToMetric: this.onGoToMetric,
      onCloneMonitor: this.onCloneMonitor,
    };
    const configTabs = [
      {
        key: ProfilerSummaryTabKey.HEALTH,
        content: (
          <ProfilerDataSourceTableHealthTab
            key={tableInfo.uuid}
            workspaceUuid={workspaceUuid}
            dataSource={dataSource}
            schema={schemaInfo}
            table={tableInfo}
            tableMonitorSummary={this.props.profilerCurrentTableOverviewSummary}
            tableColumnList={this.props.tableColumnList}
            getProfilerConfigTableColumnList={
              this.props.getProfilerConfigTableColumnList
            }
            tableHealthData={profilerCurrentTableHealthData}
            getProfilerCurrentTableHealthData={
              this.props.getProfilerCurrentTableHealthData
            }
            onTabChange={this.props.onTabChange}
            onManageAutoMetrics={this.onManageAutoMetrics}
            onSelectNodeChange={this.props.onSelectNodeChange}
            onConfigureTable={this.onOpenTableConfig}
            onDeleteMetricClick={this.props.onDeleteMetricClick}
            updateColumnConfig={this.props.updateColumnConfig}
            tabOptions={this.props.tabOptions}
            onTabOptionsChange={this.props.onTabOptionsChange}
            onMonitorTableHealthMetric={this.onMonitorTableHealthMetric}
            setProfilerAfterTreeNavigationAction={
              this.props.setProfilerAfterTreeNavigationAction
            }
            onMonitorAll={this.onMonitorAll}
            {...sharedProps}
          />
        ),
      },
      {
        key: ProfilerSummaryTabKey.DATA_PROFILE,
        content: (
          <ProfilerDataSourceTableDataProfileTab
            key={tableInfo.uuid}
            workspaceUuid={workspaceUuid}
            dataSource={dataSource}
            schema={schemaInfo}
            table={tableInfo}
            dataProfileData={profilerTableDataProfileData}
            canModifySettings={canModifySettings}
            updateTableDataProfilerEnabled={this.updateTableDataProfilerEnabled}
            onRefresh={() => {
              this.props.getProfilerTableColumnListDataProfileData(
                dataSource,
                tableInfo
              );
            }}
          />
        ),
      },
      {
        key: ProfilerSummaryTabKey.AUTO_METRICS,
        content: (
          <ProfilerDataSourceTableMainTab
            workspaceUuid={workspaceUuid}
            integrationList={this.props.integrationList}
            currentDataSource={this.props.dataSource}
            currentTable={tableInfo}
            currentSchema={schemaInfo}
            profilerCurrentTableAutoMetricsData={
              this.props.profilerCurrentTableAutoMetricsData
            }
            getProfilerCurrentTableAutoMetricsData={
              this.props.getProfilerCurrentTableAutoMetricsData
            }
            getProfilerCurrentTableMetadataMetricsData={
              this.props.getProfilerCurrentTableMetadataMetricsData
            }
            getProfilerCurrentTableHealthData={
              this.props.getProfilerCurrentTableHealthData
            }
            eventList={this.props.eventList}
            updateTableSchemaChange={this.props.updateTableSchemaChange}
            isDataChartEnabled={isDataChartEnabled}
            isEventChartEnabled={isEventChartEnabled}
            metadataMetricsData={this.props.profilerTableMetadataMetricsData}
            onOpenMetricQueryHistory={this.props.onOpenMetricQueryHistory}
            {...sharedProps}
          />
        ),
      },
      {
        key: ProfilerSummaryTabKey.CONFIGURATION,
        content: (
          <ProfilerDataSourceTableConfigureTab
            config={{
              type: "table",
              table: tableInfo,
              dataSource: dataSource,
            }}
            waffle={this.props.waffle}
            tableColumnList={this.props.tableColumnList}
            {...sharedProps}
          />
        ),
      },
      {
        key: ProfilerSummaryTabKey.MANAGE_COLUMNS,
        content: (
          <ProfilerDataSourceTableManageColumnsTab
            key={tableInfo.uuid}
            availableNodeKeys={availableNodeKeys}
            workspaceUuid={workspaceUuid}
            config={{
              type: "table",
              dataSource,
              schema: schemaInfo,
              table: tableInfo,
            }}
            onConfigureTableClick={() => this.onOpenTableConfig()}
            onCurrentSelectNodeChange={onCurrentSelectNodeChange}
            {...sharedProps}
          />
        ),
      },
      {
        key: ProfilerSummaryTabKey.ACTIVITY,
        content: (
          <ProfileDatasourceActivityLog
            openTakeover={this.props.openTakeover}
            closeTakeover={this.props.closeTakeover}
          />
        ),
        isVisible: tableInfo.isUserDefined,
      },
    ].filter(
      (currentTabInfo) =>
        currentTabInfo.isVisible !== false && activeTabs.includes(currentTabInfo.key)
    );

    return (
      <div className="profiler-data-source-table-overview-container">
        <ProfilerDataSourceTableOverviewHeader
          datasourceType={datasourceType}
          datasourceName={datasourceName}
          schema={schemaInfo.name}
          table={tableInfo?.tableName}
          lastScannedStatus={tableInfo?.status?.lastScannedStatus}
          lastScannedFailedReason={ProfilerTableScanFailureReason}
          currentSelectNode={currentSelectNode}
          onCurrentSelectNodeChange={onCurrentSelectNodeChange}
          isTreeExpand={isTreeExpand}
          onTreeExpandChange={onTreeExpandChange}
        />
        <div className="profiler-data-source-table-overview-body-container lightup-rest-flex-item-container">
          <ProfilerSummaryTabView
            tabConfigs={configTabs}
            value={
              this.props.tabKey ||
              defaultTabKey(ProfilerTreeNodeType.TABLE, {
                dataSource,
                schemaInfo,
                tableInfo,
                workspaceUserPermissions,
              })
            }
            onChange={this.props.onTabChange}
          />
        </div>
        {monitorDialogContext && (
          <ProfilerMonitorAllDialog
            modalIsOpen={monitorDialogContext}
            setModalIsOpen={(isOpen) =>
              !isOpen && this.setState({ monitorDialogContext: false })
            }
            alertingChannelList={this.props.alertingChannelList}
            data={profilerCurrentMonitorObject}
            okClicked={this.onEnableMonitorAllClicked.bind(this)}
          />
        )}
        {this.state.deleteMonitorConfirmationDialogTarget && (
          <DeleteMonitorConfirmationDialog
            isOpen={Boolean(this.state.deleteMonitorConfirmationDialogTarget)}
            setIsOpen={(isOpen) => {
              if (!isOpen) {
                this.setState({ deleteMonitorConfirmationDialogTarget: null });
              }
            }}
            monitors={[this.state.deleteMonitorConfirmationDialogTarget]}
            onDeleteMonitors={this.onDeleteMonitorConfirmed}
          />
        )}
      </div>
    );
  }
}

export default ProfilerDataSourceTableOverview;
