import React, { Component } from "react";
import SettingTable from "../settings/setting-table";

import "./downloads.scss";

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaceUuid: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: {
        params: { workspaceUuid },
      },
    } = props;
    if (workspaceUuid && workspaceUuid !== state.workspaceUuid) {
      console.log("Loading downloads information");
      props.getDownloadFilesList(workspaceUuid);

      return {
        ...state,
        workspaceUuid,
      };
    }

    return null;
  }

  render() {
    const { workspaceUuid } = this.state;
    const { downloadFilesList } = this.props;
    const columns = [
      {
        title: "File",
        dataIndex: "filename",
        sorter: {
          compare: (a, b) => a.filename.localeCompare(b.filename),
        },
        render: function (value, data, rowIndex) {
          const link = `/api/v1/ws/${workspaceUuid}/metrics/download/${value}/`;
          return <a href={link}>{value}</a>;
        },
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: {
          compare: (a, b) => a.size.localeCompare(b.size, undefined, { numeric: true }),
        },
        defaultSortOrder: "ascend",
        render: function (value, data, rowIndex) {
          return value;
        },
      },
    ];

    return (
      <div className="download-files-container">
        <SettingTable
          columns={columns}
          dataList={downloadFilesList}
          rowKey={"filename"}
          enableSearch={false}
        />
      </div>
    );
  }
}

export default Downloads;
