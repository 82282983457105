import { AppPermissions } from "./permissions";
import queryString from "query-string";

// Backend ws related API URI
export const backendRelativeURIPath = Object.freeze({
  DATA_SOURCE_LIST: "/sources/",
  DATA_SOURCE: "/sources/:uuid",
  DATA_SOURCE_PREVIEW: "/sources/inspection",
  DATA_SOURCE_USAGE: "/sources/:uuid/usage",
  DATA_SOURCE_SUMMARY: "/sources/:uuid/profile/summary",
  DATA_SOURCE_SCHEMA_SQL: "/sources/:uuid/sql-schema",
  DATA_SOURCE_PARTITION_SAMPLE: "/sources/:uuid/partitions",
  DATA_SOURCE_PROFILER_CONFIG: "/sources/:uuid/profile/profiler-config",
  DATA_SOURCE_QUERY_HISTORY: "/sources/:uuid/query-history",
  DATA_SOURCE_TRIGGER: "/sources/:uuid/trigger",
  DATA_SOURCE_EVENT: "/sources/:uuid/profile/events/",
  DATA_SOURCE_TREE: "/sources/:uuid/profile/tree/",
  DATA_SOURCE_SCHEMA_LIST: "/sources/:uuid/profile/schemas",
  DATA_SOURCE_SCHEMA: "/sources/:uuid/profile/schemas/:schemaUuid",
  DATA_SOURCE_SCHEMA_SUMMARY: "/sources/:uuid/profile/schemas/:schemaUuid/summary",
  DATA_SOURCE_SCHEMA_USAGE: "/sources/:uuid/profile/schemas/:schemaUuid/usage",
  DATA_SOURCE_SCHEMA_PROFILER_CONFIG:
    "/sources/:uuid/profile/schemas/:schemaUuid/profiler-config",
  DATA_SOURCE_TABLE_LIST: "/sources/:uuid/profile/tables",
  DATA_SOURCE_TABLE_BLOB_LIST: "/sources/:uuid/profile/tables/blobs",
  DATA_SOURCE_TABLE: "/sources/:uuid/profile/tables/:tableUuid",
  DATA_SOURCE_TABLE_HEALTH: "/sources/:uuid/profile/tables/:tableUuid/health",
  DATA_SOURCE_TABLE_PROFILER_CONFIG:
    "/sources/:uuid/profile/tables/:tableUuid/profiler-config",
  DATA_SOURCE_TABLE_SUMMARY: "/sources/:uuid/profile/tables/:tableUuid/summary",
  DATA_SOURCE_TABLE_USAGE: "/sources/:uuid/profile/tables/:tableUuid/usage",
  DATA_SOURCE_TABLE_COLUMN_LIST: "/sources/:uuid/profile/tables/:tableUuid/columns",
  DATA_SOURCE_TABLE_COLUMN:
    "/sources/:uuid/profile/tables/:tableUuid/columns/:columnUuid",
  DATA_SOURCE_TABLE_CHANGE_LOG: "/sources/:uuid/profile/tables/:tableUuid/changelog",
  DATA_SOURCE_TABLE_COLUMN_EVENT:
    "/sources/:uuid/profile/tables/:tableUuid/columns/:columnUuid/events",
  DATA_SOURCE_TABLE_COLUMN_USAGE:
    "/sources/:uuid/profile/tables/:tableUuid/columns/:columnUuid/usage",
  DATA_SOURCE_TABLE_COLUMN_SUMMARY:
    "/sources/:uuid/profile/tables/:tableUuid/columns/:columnUuid/summary",
  DATA_SOURCE_TABLE_COLUMN_PROFILER_CONFIG:
    "/sources/:uuid/profile/tables/:tableUuid/columns/:columnUuid/profiler-config",
  DATA_SOURCE_TABLE_DATA_PROFILE: "/sources/:uuid/data-profile/tables/:tableUuid",
  DATA_SOURCE_TABLE_COLUMN_LIST_DATA_PROFILE:
    "/sources/:uuid/data-profile/tables/:tableUuid/columns",
  DATA_SOURCE_TABLE_COLUMN_DATA_PROFILE:
    "/sources/:uuid/data-profile/tables/:tableUuid/columns/:columnUuid",
  DATA_SOURCE_TABLE_LIST_LEGACY: "/sources/:uuid/tables",
  DATA_SOURCE_TABLE_COLUMN_LIST_LEGACY: "/sources/:uuid/schema",
  METRIC_LIST: "/metrics/",
  METRIC: "/metrics/:uuid",
  METRIC_RESUME: "/metrics/:uuid/resume",
  METRIC_DATA_POINT: "/metrics/:uuid/datapoints",
  METRIC_FILE_DATA: "/metrics/:uuid/file/:fileUuid",
  METRIC_QUERY_HISTORY: "/metrics/:uuid/query-history",
  METRIC_DOWNLOAD_LIST: "/metrics/download/",
  METRIC_SCHEMA: "/metrics/schema",
  METRIC_TABLE_SAMPLES_PREVIEW: "/metrics/table-samples-preview",
  METRIC_PREVIEW: "/metrics/preview",
  METRIC_PREVIEW_TERMINATION: "/metrics/preview-termination",
  METRIC_SLICE_VALUES: "/metrics/slice-values",
  METRIC_DISTINCT_VALUES: "/metrics/distinct-values",
  METRIC_FAILING_RECORDS_SQL_VALIDATION: "/metrics/failing-records-sql-validation",
  METRIC_COLLECTION_SUMMARY: "/metrics/collection-summary",
  METRIC_CUSTOM_SCHEDULE_UPCOMING_RUNS: "/metrics/custom-schedule-upcoming-runs/",
  MONITOR_LIST: "/monitors/",
  MONITOR: "/monitors/:uuid",
  MONITOR_SLICE_LIST: "/monitors/:uuid/slices",
  MONITOR_TRAINING_JOB_STATUS: "/monitors/:uuid/training-job-status",
  MONITOR_FILTER_STATUS: "/monitors/:uuid/filter-statuses",
  MONITOR_PREVIEW_LIST: "/monitors/:uuid/previews",
  MONITOR_TRAINING_DATA_POINT: "/monitors/:uuid/training-metrics",
  MONITOR_TRAINING_DATA_POINT_WITH_LTTB: "/monitors/:uuid/training-metrics-lttb",
  MONITOR_DATA_POINT: "/monitors/:uuid/metrics",
  MONITOR_DATA_POINT_WITH_LTTB: "/monitors/:uuid/metrics-lttb",
  MONITOR_PREVIEW: "/monitors/:uuid/previews/:previewUuid",
  MONITOR_PREVIEW_JOB_STATUS:
    "/monitors/:uuid/previews/:previewUuid/preview-job-status",
  INCIDENT_LIST: "/incidents/",
  INCIDENT: "/incidents/:uuid",
  INCIDENT_CONFIG: "/incident-config/:uuid",
  INCIDENT_COMMENT_LIST: "/incidents/:uuid/comments",
  INCIDENT_COMMENT: "/incidents/:uuid/comments/:id",
  INCIDENT_CORRELATED_METRICS: "/incidents/:uuid/correlated-metrics",
  INCIDENT_USER_DEFINED_RELATED_METRICS:
    "/incidents/:uuid/user-defined-related-metrics",
  INCIDENT_CONFIG2: "/incidents/:uuid/config",
  INCIDENT_SAMPLE_DATA: "/incidents/incident-samples-preview",
  INCIDENT_SAMPLE_DATA_TERMINATION: "/incidents/incident-samples-preview-termination",
  CASE_LIST: "/cases/",
  CASE: "/cases/:uuid",
  DASHBOARD_CHART_LIST: "/dashboard/",
  DASHBOARD_CHART: "/dashboard/:uuid",
  DASHBOARD_DQ_SUMMARY: "/dashboard/summary",
  DASHBOARD_INCIDENT_SUMMARY: "/dashboard/incidents",
  DASHBOARD_CONFIGURATION: "/dashboard/configuration",
  ALERT_CHANNEL_LIST: "/integrations/",
  ALERT_CHANNEL: "/integrations/:uuid",
  ALERT_CHANNEL_PREVIEW: "/integrations/inspection",
  ALERT_CHANNEL_METADATA: "/integrations/metadata",
  ALERT_CHANNEL_SEARCH: "/integrations/search",
  SCHEDULE_LIST: "/schedules/",
  SCHEDULE: "/schedules/:uuid",
  USER_LIST: "/users/",
  USER: "/users/:uuid",
  USER_PERMISSION: "/users/user-permissions/",
  TAG_LIST: "/tags/",
  EVENT_LIST: "/events/",
  EVENT_ITEM: "/events/:uuid",
  EVENT_MONITOR_LIST: "/events/monitor",
  EVENT_MONITOR_ITEM: "/events/monitor/:uuid",
  CONFIGURATIONS_PAGE: "/configurations/page",
  WORKSPACE: "",
});

export const backendURIPath = Object.freeze({
  APP_USER_LIST: "/users/",
  APP_USER: "/users/:uuid",
  HEALTH: "/healthz/",
  USER_CONFIG: "/users/user-config/",
  USER_LOGOUT: "/users/logout/",
  CURRENT_USER: "/users/current-user/",
  USER_LOGIN: "/users/login/",
  USER_ACCEPT_DISCLAIMER: "/users/user-accept-disclaimer/",
  API_CREDENTIAL_LIST: "/api-credentials/",
  API_CREDENTIAL: "/api-credentials/:uuid",
  APP_WORKSPACE_LIST: "/workspaces/",
  APP_WORKSPACE: "/workspaces/:uuid",
  SYSTEM_INTEGRATION_LIST: "/system/integrations",
  SYSTEM_INTEGRATION_INSPECTION: "/system/integrations/inspection",
  SYSTEM_INTEGRATION: "/system/integrations/:uuid",
  SYSTEM_INTEGRATION_METADATA: "/system/integrations/:uuid/metadata",
});

// UI URI List
export const workspaceURIRelativePath = Object.freeze({
  EXPLORER: "/profiler",
  SETTINGS: "/settings",
  DATA_SOURCE_LIST: "/settings/datasource",
  ADD_DATA_SOURCE: "/addDataSource",
  EDIT_DATA_SOURCE: "/editDataSource/:id",
  NG_METRIC_LIST: "/metrics",
  NG_DRAFT_LIST: "/drafts",
  METRIC_LIST: "/settings/kpi",
  EDIT_METRIC: "/editKpi/:id",
  ADD_METRIC: "/addKpi",
  NG_MONITOR_LIST: "/monitors",
  MONITOR_LIST: "/rules",
  ADD_MONITOR: "/addRule",
  EDIT_MONITOR: "/editRule/:id",
  INTEGRATION_LIST: "/settings/integration",
  SCHEDULE_LIST: "/settings/schedule",
  QUERY_GOVERNANCE: "/settings/queryGovernance",
  INCIDENT_LIST: "/incidentList",
  NG_INCIDENT_LIST: "/incidents",
  INCIDENT: "/incident/:uuid",
  DASHBOARD: "/dashboard",
  ALERT_LIST: "/systemevents",
  ALERT_ITEM: "/systemevents/:uuid",
  DOWNLOADS: "/downloads",
  EDIT_WORKSPACE: "/editWorkspace",
});

export const wsUuidParamName = "workspaceUuid";
export const wsURIPrefix = `/ws/:${wsUuidParamName}`;

export const URIPath = {
  ROOT: "/",
  ADMIN: "/admin",
  LOGIN: "/login",
  DISCLAIMER: "/disclaimer",
  FORBIDDEN: "/forbidden",
  WS_ROOT: wsURIPrefix,
  LOBBY: "/lobby",
  CREATE_WORKSPACE: "/createWorkspace",
  TRIAL_EXPIRED: "/trialExpired",
};

for (const [key, value] of Object.entries(workspaceURIRelativePath)) {
  URIPath[key] = `${wsURIPrefix}${value}`;
}
Object.freeze(URIPath);

// UI URI Permission
export const URIPathPermissions = Object.freeze({
  [URIPath.ROOT]: [],
  [URIPath.LOGIN]: [],
  [URIPath.DISCLAIMER]: [],
  [URIPath.FORBIDDEN]: [],
  [URIPath.TRIAL_EXPIRED]: [],
  [URIPath.SETTINGS]: [],
  [URIPath.ADMIN]: [
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_APPUSERLIST,
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_APPUSERDETAIL,
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACELIST,
  ],
  [URIPath.DATA_SOURCE_LIST]: [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
  ],
  [URIPath.ADD_DATA_SOURCE]: [AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCELIST],
  [URIPath.EDIT_DATA_SOURCE]: [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
  ],
  [URIPath.METRIC_LIST]: [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
  ],
  [URIPath.NG_METRIC_LIST]: [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
  ],
  [URIPath.NG_DRAFT_LIST]: [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
  ],
  [URIPath.ADD_METRIC]: [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
  ],
  [URIPath.EDIT_METRIC]: [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
  ],
  [URIPath.MONITOR_LIST]: [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
  ],
  [URIPath.NG_MONITOR_LIST]: [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
  ],
  [URIPath.ADD_MONITOR]: [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ],
  [URIPath.EDIT_MONITOR]: [AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERDETAIL],
  [URIPath.INTEGRATION_LIST]: [
    AppPermissions.BACKEND_APPS_ALERTING_CHANNEL_VIEWS_VIEW_ALERTINGCHANNELLIST,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
  ],
  [URIPath.SCHEDULE_LIST]: [
    AppPermissions.BACKEND_APPS_SCHEDULE_VIEWS_VIEW_SCHEDULELIST,
  ],
  [URIPath.QUERY_GOVERNANCE]: [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEQUERYHISTORY,
  ],
  [URIPath.INCIDENT_LIST]: [],
  [URIPath.NG_INCIDENT_LIST]: [],
  [URIPath.INCIDENT]: [AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_VIEW_INCIDENTDETAIL],
  [URIPath.DASHBOARD]: [
    AppPermissions.BACKEND_APPS_DASHBOARD_CHART_VIEWS_VIEW_DASHBOARDCHARTLIST,
    AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_VIEW_INCIDENTLIST,
  ],
  [URIPath.ALERT_LIST]: [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_EVENT_VIEWS_VIEW_EVENTLIST,
  ],
  [URIPath.ALERT_ITEM]: [AppPermissions.BACKEND_APPS_EVENT_VIEWS_VIEW_EVENTDETAIL],
  [URIPath.DOWNLOADS]: [],
  [URIPath.EDIT_WORKSPACE]: [
    AppPermissions.BACKEND_APPS_USER_VIEWS_VIEW_WORKSPACEUSERLIST,
  ],
  [URIPath.CREATE_WORKSPACE]: [
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACELIST,
  ],
  [URIPath.LOBBY]: [],
});

// Util functions

export function hasPermission(permissionObject, requirementPermissions = []) {
  if (!permissionObject) {
    console.log("Permissions object is empty");
    return true;
  }

  if (requirementPermissions.length === 0) {
    return true;
  }

  const { isSuperuser, permissions } = permissionObject;
  return (
    isSuperuser || requirementPermissions.every((perm) => permissions.includes(perm))
  );
}

export function getURIInstance(uriPath, params = {}) {
  let parsedResult = uriPath;
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      console.log(`${key} is empty`);
    }
    parsedResult = parsedResult.replace(`:${key}`, value);
  }
  return parsedResult;
}

export function getAPIURIInstance(uriRelativePath, params = {}) {
  const { workspaceUuid } = params;
  const uriPath = workspaceUuid
    ? `/ws/:${wsUuidParamName}${uriRelativePath}`
    : uriRelativePath;
  return getURIInstance(uriPath, params);
}

export function getAPIURITemplate(uriRelativePath, opts = {}) {
  const { apiVersion = "v0", isWorkspaceRelative = true } = opts;
  const paths = ["api", apiVersion];
  if (isWorkspaceRelative) {
    paths.push("ws", ":workspaceUuid");
  }
  paths.push(uriRelativePath);
  return `/${joinURIPaths(...paths)}`;
}

export function joinURIPaths(...paths) {
  return paths.join("/").replaceAll(/\/{2,}/g, "/");
}

export function queryParamsRemoved(location, params) {
  const newQueryParams = queryString.parse(location.search);
  for (let param of params) {
    delete newQueryParams[param];
  }
  return `${location.pathname}?${queryString.stringify(newQueryParams)}`;
}

export function encodeLocationObject(locationObj) {
  const { pathname, search, hash } = locationObj;
  const locationString = encodeURIComponent(JSON.stringify({ pathname, search, hash }));
  return locationString;
}

export function decodeLocationObject(locationSearchString, defaultPath = "/") {
  if (!locationSearchString) {
    return defaultPath;
  }

  try {
    const queryParams = queryString.parse(locationSearchString);
    if (!queryParams.from) {
      return defaultPath;
    }

    const locationObject = JSON.parse(decodeURIComponent(queryParams.from));
    return locationObject;
  } catch (e) {
    console.log(`Fail to decode search string ${locationSearchString}`);
    return defaultPath;
  }
}

export function getCloneFromParams(locationSearchString, defaultCloneFrom = "") {
  return getSettingFromParams("cloneFrom", locationSearchString, defaultCloneFrom);
}

export function getMetricUuidFromParams(locationSearchString, defaultMetricUuid = "") {
  return getSettingFromParams("metricUuid", locationSearchString, defaultMetricUuid);
}

export function getMonitorSymptomFromParams(
  locationSearchString,
  defaultMonitorSymptom = ""
) {
  return getSettingFromParams(
    "monitorSymptom",
    locationSearchString,
    defaultMonitorSymptom
  );
}

export function getSettingFromParams(keyName, locationSearchString, defaultValue) {
  if (!locationSearchString) {
    return defaultValue;
  }

  const queryParams = queryString.parse(locationSearchString);
  return queryParams[keyName] || defaultValue;
}

export function removeQueryParamsFromUrl(props, paramsList = []) {
  const { location, history } = props;
  const queryParams = queryString.parse(props.location.search);
  let isUpdated = false;
  for (let paramsName of paramsList) {
    if (Object.prototype.hasOwnProperty.call(queryParams, paramsName)) {
      delete queryParams[paramsName];
      isUpdated = true;
    }
  }

  if (!isUpdated) {
    return;
  }

  let newQueryString = queryString.stringify(queryParams);
  if (newQueryString) {
    newQueryString = `?${newQueryString}`;
  }
  let newLocation = `${location.pathname}${newQueryString}`;
  history.replace(newLocation);
}
