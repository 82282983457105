import {
  MetricCategory,
  QueryScope,
  MetricConfigType,
  ConformityConditionType,
  TableType,
  VirtualTableType,
  CollectionModeType,
  isFileSource,
} from "../../utils/enums";
import { isTriggeredMetric } from "../../utils/metric";
import { MetricConfigStep } from "./utils";

function validateListParams(src = [], target = []) {
  if (src.length !== target.length) {
    return false;
  }

  for (let currentList of [src, target]) {
    for (let currentItem of currentList) {
      if (!currentItem) {
        return false;
      }
    }
  }

  return true;
}

function aggregationMetricInitCallback(props, defaultData) {
  props.onDataSourceChanged(defaultData.config.sources[0]);
  props.onTableChanged(
    defaultData.config.sources[0],
    defaultData.config.table.tableUuid
  );
}

function customSqlMetricInitCallback(props, defaultData) {
  props.onDataSourceChanged(defaultData.config.sources[0]);
  if (defaultData.config.table && defaultData.config.table.tableUuid) {
    props.onCustomSqlTableChanged(
      defaultData.config.sources[0],
      defaultData.config.table.tableUuid
    );
  }
}

function dataDelayMetricInitCallback(props, defaultData) {
  aggregationMetricInitCallback(props, defaultData);
}

function dataVolumeMetricInitCallback(props, defaultData) {
  aggregationMetricInitCallback(props, defaultData);
}

function metadataMetricInitCallback(props, defaultData) {
  aggregationMetricInitCallback(props, defaultData);
}

function activityMetricInitCallback(props, defaultData) {
  aggregationMetricInitCallback(props, defaultData);
}

function fullCompareMetricInitCallback(props, defaultData) {
  const { sourceTable, targetTable } = defaultData.config;
  sourceTable.sourceUuid && props.onSrcDataSourceChanged(sourceTable.sourceUuid);
  targetTable.sourceUuid && props.onDataSourceChanged(targetTable.sourceUuid);
  if (sourceTable.table && sourceTable.table.tableUuid) {
    props.onSrcTableChanged(sourceTable.sourceUuid, sourceTable.table.tableUuid);
  }

  if (targetTable.table && targetTable.table.tableUuid) {
    props.onTableChanged(targetTable.sourceUuid, targetTable.table.tableUuid);
  }
}

function nullPercentMetricInitCallback(props, defaultData) {
  aggregationMetricInitCallback(props, defaultData);
}

function aggregationCompareMetricInitCallback(props, defaultData) {
  const { sourceTable, targetTable } = defaultData.config;
  sourceTable.sourceUuid && props.onSrcDataSourceChanged(sourceTable.sourceUuid);
  targetTable.sourceUuid && props.onDataSourceChanged(targetTable.sourceUuid);
}

function conformityCountMetricInitCallback(props, defaultData) {
  aggregationMetricInitCallback(props, defaultData);
  const {
    sources,
    aggregation: { conditions },
  } = defaultData.config;
  for (let { type, values } of conditions) {
    if (
      [
        ConformityConditionType.IN_COLUMN,
        ConformityConditionType.NOT_IN_COLUMN,
      ].includes(type)
    ) {
      const {
        table: { tableUuid },
      } = values[0];
      props.onInColumnTableChanged(sources[0], tableUuid);
      break;
    }
  }
}

function distributionMetricInitCallback(props, defaultData) {
  aggregationMetricInitCallback(props, defaultData);
}

function validateTimeRangeOrFullTableConfig(
  configData,
  dataSourceList,
  validateAggregation = true
) {
  const {
    config: {
      table,
      aggregation,
      configType,
      pollingWindow,
      pollingTimezone,
      pollingDelay,
      synchronizationDelay,
      queryTimezone,
      timestampColumn,
      sources,
    },
  } = configData;

  if (configType === MetricConfigType.METRIC_CONFIG) {
    const currentDataSource = dataSourceList?.find(
      (dataSource) => dataSource.metadata.uuid === sources?.[0]
    );

    const isValid = !!(
      (!validateAggregation ||
        table?.aggregationWindow ||
        aggregation?.aggregationWindow) &&
      queryTimezone &&
      typeof synchronizationDelay === "number"
    );

    return (
      isValid &&
      (timestampColumn || isFileSource(currentDataSource?.config.connection.type))
    );
  } else if (configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG) {
    return (
      isTriggeredMetric(configData) ||
      !!(pollingWindow && pollingTimezone && typeof pollingDelay === "number")
    );
  }

  return true;
}

function isValidName(name) {
  return !!(name || "").trim();
}

function validateInColumnConditions(whereConditions) {
  const onlyInColumnConditions = whereConditions.filter(({ conditions }) =>
    [ConformityConditionType.IN_COLUMN, ConformityConditionType.NOT_IN_COLUMN].includes(
      conditions?.[0]?.type
    )
  );
  return onlyInColumnConditions.every(({ conditions }) => {
    const { table, columnName } = conditions?.[0]?.values?.[0] ?? {};
    return Boolean(table?.schemaName && table?.tableUuid && columnName);
  });
}

function validateWhereClause(whereClause) {
  const { clauseLogic, whereConditions } = whereClause ?? {};
  if (!clauseLogic || !whereConditions) {
    return false;
  }
  if (!validateInColumnConditions(whereConditions)) {
    return false;
  }
  return true;
}

function validateAggregationMetric(configData, dataSourceList) {
  const {
    metadata: { name },
    config: { dimension, aggregation, sources, table, valueColumns = [], whereClause },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const hasValidWhereClause = !whereClause || validateWhereClause(whereClause);
  const isDataSetStepValid = !!(
    aggregation &&
    aggregation.type &&
    sources &&
    sources.length > 0 &&
    table &&
    [TableType.TABLE, VirtualTableType.USER_DEFINED_VIEW].includes(table.type) &&
    table.schemaName &&
    table.tableUuid &&
    valueColumns &&
    valueColumns.length > 0 &&
    hasValidWhereClause &&
    validateTimeRangeOrFullTableConfig(configData, dataSourceList)
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateCustomSqlMetric(configData, dataSourceList) {
  const {
    metadata: { name },
    config: { dimension, sources, table, valueColumns = [] },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const isDataSetStepValid = !!(
    sources &&
    sources.length > 0 &&
    table &&
    table.type === TableType.CUSTOM_SQL &&
    table.sql &&
    ((!table.schemaName && !table.tableUuid && !table.columnUuid) ||
      (table.schemaName && table.tableUuid)) &&
    valueColumns &&
    valueColumns.length > 0 &&
    validateTimeRangeOrFullTableConfig(configData, dataSourceList, true)
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateDataDelayMetric(configData) {
  const {
    metadata: { name },
    config: { dimension, sources, table, whereClause },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const hasValidWhereClause = !whereClause || validateWhereClause(whereClause);
  const isDataSetStepValid = !!(
    sources &&
    sources.length > 0 &&
    table &&
    [TableType.TABLE, VirtualTableType.USER_DEFINED_VIEW].includes(table.type) &&
    table.tableUuid &&
    hasValidWhereClause
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateDataVolumeMetric(configData, dataSourceList) {
  const {
    metadata: { name },
    config: { dimension, aggregation, sources, table, whereClause },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const hasValidWhereClause = !whereClause || validateWhereClause(whereClause);
  const isDataSetStepValid = !!(
    aggregation &&
    aggregation.type &&
    sources &&
    sources.length > 0 &&
    table &&
    [TableType.TABLE, VirtualTableType.USER_DEFINED_VIEW].includes(table.type) &&
    table.schemaName &&
    table.tableUuid &&
    hasValidWhereClause &&
    validateTimeRangeOrFullTableConfig(configData, dataSourceList)
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateFullCompareMetric(configData) {
  const {
    metadata: { name },
    config: {
      dimension,
      sourceTable,
      targetTable,
      queryScope,
      pollingWindow,
      pollingTimezone,
      pollingDelay,
      collectionMode,
      aggregationWindow,
    },
  } = configData;

  const isTimeRange = queryScope === QueryScope.TIME_RANGE;
  const isFullTableTriggered =
    queryScope === QueryScope.FULL_TABLE &&
    collectionMode.type === CollectionModeType.TRIGGERED;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const hasValidSourceWhereClause =
    !sourceTable.whereClause || validateWhereClause(sourceTable.whereClause);
  const hasValidTargetWhereClause =
    !targetTable.whereClause || validateWhereClause(targetTable.whereClause);
  const isDataSetStepValid = !!(
    sourceTable &&
    targetTable &&
    sourceTable.sourceUuid &&
    targetTable.sourceUuid &&
    sourceTable.table &&
    targetTable.table &&
    ((isTimeRange && sourceTable.timestampColumn && targetTable.timestampColumn) ||
      (!isTimeRange &&
        pollingWindow &&
        pollingTimezone &&
        typeof pollingDelay === "number") ||
      isFullTableTriggered) &&
    sourceTable.valueColumns &&
    sourceTable.valueColumns.length > 0 &&
    targetTable.valueColumns &&
    targetTable.valueColumns.length > 0 &&
    hasValidSourceWhereClause &&
    hasValidTargetWhereClause &&
    validateListParams(sourceTable.valueColumns, targetTable.valueColumns) &&
    validateListParams(sourceTable.attributeColumns, targetTable.attributeColumns) &&
    (!isTimeRange || (aggregationWindow && targetTable.queryTimezone))
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateNullPercentMetric(configData, dataSourceList) {
  const {
    metadata: { name },
    config: { dimension, aggregation, sources, table, valueColumns, whereClause },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const hasValidWhereClause = !whereClause || validateWhereClause(whereClause);
  const isDataSetStepValid = !!(
    aggregation &&
    aggregation.type &&
    sources &&
    sources.length > 0 &&
    table &&
    [TableType.TABLE, VirtualTableType.USER_DEFINED_VIEW].includes(table.type) &&
    table.schemaName &&
    table.tableUuid &&
    valueColumns.length > 0 &&
    hasValidWhereClause &&
    validateTimeRangeOrFullTableConfig(configData, dataSourceList)
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateAggregationCompareMetric(configData) {
  const {
    metadata: { name },
    config: { dimension, compares, sources, sourceTable },
  } = configData;

  const isBasicStepValid = !!(isValidName(name) && dimension);
  const isDataSetStepValid = !!(
    sources?.length > 0 &&
    sourceTable &&
    compares?.length > 0 &&
    compares.every(
      ({ sourceMetricUuid, targetMetricUuid }) => sourceMetricUuid && targetMetricUuid
    )
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateConformityCountMetric(configData, dataSourceList) {
  const {
    metadata: { name },
    config: { dimension, aggregation, sources, table, valueColumns, whereClause },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const hasValidWhereClause = !whereClause || validateWhereClause(whereClause);
  const isDataSetStepValid = !!(
    aggregation &&
    aggregation.type &&
    aggregation.conditions?.length > 0 &&
    sources &&
    sources.length > 0 &&
    table &&
    [TableType.TABLE, VirtualTableType.USER_DEFINED_VIEW].includes(table.type) &&
    table.schemaName &&
    table.tableUuid &&
    valueColumns.length > 0 &&
    hasValidWhereClause &&
    validateTimeRangeOrFullTableConfig(configData, dataSourceList)
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateDistributionMetric(configData, dataSourceList) {
  const {
    metadata: { name },
    config: { dimension, aggregation, sources, table, valueColumns, whereClause },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const hasValidWhereClause = !whereClause || validateWhereClause(whereClause);
  const isDataSetStepValid = !!(
    aggregation &&
    aggregation.type &&
    sources &&
    sources.length > 0 &&
    table &&
    [TableType.TABLE, VirtualTableType.USER_DEFINED_VIEW].includes(table.type) &&
    table.schemaName &&
    table.tableUuid &&
    valueColumns.length > 0 &&
    hasValidWhereClause &&
    validateTimeRangeOrFullTableConfig(configData, dataSourceList)
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateMetadataMetric(configData, _dataSourceList) {
  const {
    metadata: { name },
    config: { dimension, aggregation, sources, table },
  } = configData;
  const isBasicStepValid = !!(isValidName(name) && dimension);
  const isDataSetStepValid = !!(
    aggregation &&
    aggregation.type &&
    sources &&
    sources.length > 0 &&
    table &&
    [TableType.TABLE, VirtualTableType.USER_DEFINED_VIEW].includes(table.type) &&
    table.schemaName &&
    table.tableUuid
  );

  return {
    [MetricConfigStep.BASIC]: isBasicStepValid,
    [MetricConfigStep.DATA_ASSET]: isDataSetStepValid,
  };
}

function validateActivityMetric(configData, _dataSourceList) {
  // We don't allow user to create or edit. We assume the configuration is always correct.
  return {
    [MetricConfigStep.BASIC]: true,
    [MetricConfigStep.DATA_ASSET]: true,
  };
}

const initCallbackMapper = Object.freeze({
  [MetricCategory.AGGREGATION]: aggregationMetricInitCallback,
  [MetricCategory.CUSTOM_SQL]: customSqlMetricInitCallback,
  [MetricCategory.DATA_DELAY]: dataDelayMetricInitCallback,
  [MetricCategory.DATA_VOLUME]: dataVolumeMetricInitCallback,
  [MetricCategory.FULL_COMPARE]: fullCompareMetricInitCallback,
  [MetricCategory.NULL_PERCENT]: nullPercentMetricInitCallback,
  [MetricCategory.AGGREGATION_COMPARE]: aggregationCompareMetricInitCallback,
  [MetricCategory.CONFORMITY_COUNT]: conformityCountMetricInitCallback,
  [MetricCategory.DISTRIBUTION]: distributionMetricInitCallback,
  [MetricCategory.BYTE_COUNT]: metadataMetricInitCallback,
  [MetricCategory.ROW_COUNT]: metadataMetricInitCallback,
  [MetricCategory.UPDATE_DELAY]: metadataMetricInitCallback,
  [MetricCategory.TABLE_ACTIVITY]: activityMetricInitCallback,
  [MetricCategory.COLUMN_ACTIVITY]: activityMetricInitCallback,
  [MetricCategory.CATEGORY_ACTIVITY]: activityMetricInitCallback,
});

const validateMetricCallbackMapper = Object.freeze({
  [MetricCategory.AGGREGATION]: validateAggregationMetric,
  [MetricCategory.CUSTOM_SQL]: validateCustomSqlMetric,
  [MetricCategory.DATA_DELAY]: validateDataDelayMetric,
  [MetricCategory.DATA_VOLUME]: validateDataVolumeMetric,
  [MetricCategory.FULL_COMPARE]: validateFullCompareMetric,
  [MetricCategory.AGGREGATION_COMPARE]: validateAggregationCompareMetric,
  [MetricCategory.NULL_PERCENT]: validateNullPercentMetric,
  [MetricCategory.CONFORMITY_COUNT]: validateConformityCountMetric,
  [MetricCategory.DISTRIBUTION]: validateDistributionMetric,
  [MetricCategory.BYTE_COUNT]: validateMetadataMetric,
  [MetricCategory.ROW_COUNT]: validateMetadataMetric,
  [MetricCategory.UPDATE_DELAY]: validateMetadataMetric,
  [MetricCategory.TABLE_ACTIVITY]: validateActivityMetric,
  [MetricCategory.COLUMN_ACTIVITY]: validateActivityMetric,
  [MetricCategory.CATEGORY_ACTIVITY]: validateActivityMetric,
});

export function getInitCallback(metricCategory) {
  return initCallbackMapper[metricCategory] || null;
}

export function getValidateMetricCallback(metricCategory) {
  return validateMetricCallbackMapper[metricCategory] || null;
}

const timeRangeMetricConfigAttributeNames = [
  "timestampColumn",
  "timestampColumnFunctions",
  "queryTimezone",
  "dataTimezone",
  "partitionTimezone",
  "pollingInterval",
  "partitions",
  "collectionWindow",
];

const fullTableMetricConfigAttributeNames = [
  "pollingWindow",
  "pollingTimezone",
  "pollingDelay",
];

export function removeCollectionAttributesFromConfig(config, isTimeRange) {
  const removedCollectionAttributeNames = isTimeRange
    ? fullTableMetricConfigAttributeNames
    : timeRangeMetricConfigAttributeNames;
  const newConfig = { ...config };
  removedCollectionAttributeNames.forEach(
    (attributeName) => delete newConfig[attributeName]
  );
  return newConfig;
}

export function isDataCollectionWindowRequired(configData) {
  return (
    configData?.config?.configType === MetricConfigType.METRIC_CONFIG &&
    Boolean(configData?.config?.collectionWindow)
  );
}
