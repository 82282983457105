import React from "react";
import { classesName } from "../../utils/css";
import { DivWithClassName } from "../layout";
import Switch from "../../atom/switch";

import "./ng-step.scss";

export const NgStepDescription = DivWithClassName(
  "ng-step-item-description",
  "NgStepItemDescription"
);

function NgStepItem(props) {
  const {
    title,
    children,
    description = "",
    className: propsClassName = "",
    enabled,
    enabledProps = {},
    enabledTitle = "",
    onEnabledChange,
  } = props;

  const className = classesName("ng-step-item", propsClassName);

  return (
    <div className={className}>
      <div className="ng-step-item-title">
        <span className="ng-step-item-title-content">{title}</span>
        {onEnabledChange && (
          <>
            {enabledTitle && (
              <span className="ng-step-item-switch-title-content">{enabledTitle}</span>
            )}
            <Switch checked={enabled} onChange={onEnabledChange} {...enabledProps} />
          </>
        )}
      </div>
      {description && <NgStepDescription>{description}</NgStepDescription>}
      <div className="ng-step-item-content">{children}</div>
    </div>
  );
}

function NgStepContainer(props) {
  const { steps, className: propsClassname } = props;

  const className = classesName("ng-step-container", propsClassname);

  return (
    <div className={className}>
      {steps.map((step, index) => {
        const {
          title,
          component: childComponent = null,
          className: stepClassName,
          description,
          enabled = true,
          enabledTitle = "",
          onEnabledChange,
          enabledProps,
        } = step;

        return (
          <NgStepItem
            key={index}
            title={title}
            description={description}
            className={stepClassName}
            enabled={enabled}
            enabledTitle={enabledTitle}
            onEnabledChange={onEnabledChange}
            enabledProps={enabledProps}
          >
            {childComponent}
          </NgStepItem>
        );
      })}
    </div>
  );
}

export default NgStepContainer;
