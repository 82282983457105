export const SET_DATA_SOURCE_LIST = "SET_DATA_SOURCE_LIST";
export const SET_DATA_SOURCE_ALATION_DATA_SOURCE_LIST =
  "SET_DATA_SOURCE_ALATION_DATA_SOURCE_LIST";
export const SET_DATA_SOURCE_ATLAN_DATA_SOURCE_LIST =
  "SET_DATA_SOURCE_ATLAN_DATA_SOURCE_LIST";
export const SET_ADD_DATA_SOURCE_REQUEST_STATUS = "SET_ADD_DATA_SOURCE_REQUEST_STATUS";
export const SET_EDIT_DATA_SOURCE_REQUEST_STATUS =
  "SET_EDIT_DATA_SOURCE_REQUEST_STATUS";
export const SET_LAST_PREVIEW_TIME = "SET_LAST_PREVIEW_TIME";
export const SET_LAST_PREVIEW_STATUS = "SET_LAST_PREVIEW_STATUS";
export const SET_LAST_PREVIEW_RESULT = "SET_LAST_PREVIEW_RESULT";
export const SET_CURRENT_EDIT_DATA = "SET_CURRENT_EDIT_DATA";
export const SET_CURRENT_CLONE_DATA_SOURCE = "SET_CURRENT_CLONE_DATA_SOURCE";
export const SET_CURRENT_DATA_SOURCE_USAGE = "SET_CURRENT_DATA_SOURCE_USAGE";
export const SET_CURRENT_DATA_SOURCE_QUERY_LIST = "SET_CURRENT_DATA_SOURCE_QUERY_LIST";
