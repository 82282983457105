import { AppPermissions } from "./permissions";
import { hasPermission } from "./uri-path";

export function isEditorUser(workspaceUserPermissions) {
  return (
    !hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST,
    ]) &&
    hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_COLUMNPROFILERCONFIGDETAILVIEW,
    ])
  );
}

export function isAdminUser(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST,
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_COLUMNPROFILERCONFIGDETAILVIEW,
  ]);
}
