import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect } from "react-redux";
import Button from "../../../../components/button/ng-button";
import {
  LabeledInput,
  LabeledSelect,
} from "../../../../components/labeled-control/labeled-control";
import {
  FieldSection,
  FieldRow,
  CustomSqlConfigItem,
  DataCollectionScheduleConfigItem,
  AggregationWindowConfigItem,
  QueryTimezoneConfigItem,
  SynchronizationDelayConfigItem,
  TimestampColumnConfigItem,
  TimestampTimezoneConfigItem,
  FieldRowDivider,
} from "../../../../components/metric/fields/";
import ProfilerSchemaVirtualTablePreviewView from "./profiler-schema-virtual-table-preview-view";
import { CheckOneIcon, DataArrivalIcon } from "../../../../components/icons/success";
import { ConfigIcon, DefineIcon } from "./icons";
import {
  AggregationWindowType,
  CollectionModeType,
  SupportedFeature,
  QueryScope,
  VirtualTableType,
} from "../../../../utils/enums";
import { AppPermissions } from "../../../../utils/permissions";
import { hasPermission } from "../../../../utils/uri-path";
import {
  queryScopeOptions,
  DbColumTypeOptions,
  collectionWindowOptions,
} from "../../../../utils/options";
import {
  aggregationWindowOptions,
  betaFullTableMetricAggregationWindowOptions,
  fullTableMetricAggregationWindowOptions,
  removeSecondAndMinuteOptions,
} from "../../../../components/metric/utils";
import {
  formatCustomSql,
  isFeatureEnabled,
  isTimestampTimezoneConfigEnabled,
} from "../../../../utils/general";
import { deepcopy } from "../../../../utils/objects";
import {
  previewVirtualTableSchemaList,
  previewVirtualTableSampleDataList,
  verifyVirtualTableSql,
  getProfilerConfigTableListUsage,
  resetProfilerVirtualTableView,
} from "../../../../actions/profiler/profiler-action";
import { Collapse } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ProfilerTablePartitionEditor from "../config/table/profiler-table-partition-editor";
import { diff as jsonDiff } from "json-diff";
import ModalDialog from "../../../../components/modal-dialog/modal-dialog";
import ModalConfirmationButtons from "../../../../components/modal-dialog/modal-confirmation-buttons";
import { CustomScheduleCollectionConfig } from "../../../../components/custom-schedule";
import { isPartitionConfigEnabled } from "../../../../utils/datasource";
import ProfilerConfirmationDialog from "../../profiler-confirmation-dialog";

import "./profiler-schema-virtual-table-takeover-view.scss";

// non second and minute options
const nonSMAggregationIntervalOptions = removeSecondAndMinuteOptions(
  aggregationWindowOptions
);

function getDefaultValue(schemaUuid) {
  return {
    type: VirtualTableType.USER_DEFINED_VIEW,
    tableName: "New table",
    schemaUuid,
    profilerConfig: {
      enabled: false,
      queryScope: QueryScope.TIME_RANGE,
      sql: "",
    },
  };
}

function isValidVirtualTableConfig(virtualTableConfig) {
  return (
    virtualTableConfig?.tableName &&
    virtualTableConfig?.profilerConfig?.queryScope &&
    virtualTableConfig?.profilerConfig?.sql
  );
}

function isVirtualTableConfigComplete(virtualTableConfig) {
  if (
    !virtualTableConfig?.profilerConfig?.sql ||
    !virtualTableConfig?.profilerConfig?.queryScope
  ) {
    return false;
  }

  if (virtualTableConfig?.profilerConfig?.queryScope === QueryScope.FULL_TABLE) {
    return true;
  }

  if (virtualTableConfig?.profilerConfig?.queryScope === QueryScope.TIME_RANGE) {
    return (
      virtualTableConfig?.profilerConfig?.timestampColumn &&
      virtualTableConfig?.profilerConfig?.timestampColumnType
    );
  }

  return false;
}

function TimeRangeConfigView(props) {
  const {
    dataSource,
    value,
    onChange,
    columnList,
    isColumnListLoading = false,
    isColumnListLoaded = false,
    onLoadColumnList,
    workspaceUuid,
  } = props;
  const {
    waffle,
    collectionMode = {},
    window: aggregationWindow,
    timezone,
    syncDelay,
    timestampColumn,
    timestampColumnType,
    partitions = [],
    dataTimezone,
  } = value;

  const allowDataTimezoneConfig = isTimestampTimezoneConfigEnabled(timestampColumnType);
  const isSecondAndMinuteEnabled = !isFeatureEnabled(
    waffle,
    SupportedFeature.ENABLE_SECOND_AND_MINUTE_AGGREGATION
  );
  const aggregationIntervalOptions = isSecondAndMinuteEnabled
    ? aggregationWindowOptions
    : nonSMAggregationIntervalOptions;

  return (
    <FieldSection className="virtual-table-spaced-row">
      <FieldRow>
        <DataCollectionScheduleConfigItem
          value={collectionMode}
          onChange={(newCollectionMode) => {
            onChange({
              ...value,
              collectionMode: newCollectionMode,
            });
          }}
        />
      </FieldRow>
      {collectionMode.type === CollectionModeType.CUSTOM_SCHEDULED && (
        <>
          <FieldRowDivider />
          <CustomScheduleCollectionConfig
            workspaceUuid={workspaceUuid}
            value={collectionMode}
            onChange={(newCollectionMode) => {
              onChange({
                ...value,
                collectionMode: newCollectionMode,
              });
            }}
          />
          <FieldRowDivider />
        </>
      )}
      <FieldRow>
        <AggregationWindowConfigItem
          label="Agg Interval"
          options={aggregationIntervalOptions}
          value={aggregationWindow}
          onChange={(newAggregationWindow) => {
            onChange({
              ...value,
              window: newAggregationWindow,
            });
          }}
        />
        <QueryTimezoneConfigItem
          label="Agg Timezone"
          value={timezone}
          onChange={(newTimezone) => {
            onChange({
              ...value,
              timezone: newTimezone,
            });
          }}
        />
        <SynchronizationDelayConfigItem
          label="Eval Delay"
          value={syncDelay}
          onChange={(newSyncDelay) => {
            onChange({
              ...value,
              syncDelay: newSyncDelay,
            });
          }}
        />
      </FieldRow>
      <FieldRow>
        <TimestampColumnConfigItem
          columnList={columnList}
          loadOptionConfig={{
            enableLoadOption: true,
            isOptionLoading: isColumnListLoading,
            isOptionLoaded: isColumnListLoaded,
            onClickLoad: onLoadColumnList,
          }}
          showAllColumns={true}
          supportVirtualTimestamp={false}
          dataSource={dataSource}
          partitions={partitions}
          value={timestampColumn}
          onChange={(newTimestampColumn) => {
            onChange({
              ...value,
              timestampColumn: newTimestampColumn,
            });
          }}
        />
        <LabeledSelect
          options={DbColumTypeOptions}
          value={timestampColumnType}
          label="Timestamp type"
          onChange={(newTimestampColumnType) => {
            if (newTimestampColumnType === timestampColumnType) {
              return;
            }

            onChange({
              ...value,
              timestampColumnType: newTimestampColumnType,
            });
          }}
        />
        <TimestampTimezoneConfigItem
          label="Timezone"
          value={allowDataTimezoneConfig ? dataTimezone : "Derived"}
          disabled={!allowDataTimezoneConfig}
          onChange={(newDataTimezone) => {
            onChange({
              ...value,
              dataTimezone: newDataTimezone,
            });
          }}
        />
      </FieldRow>
    </FieldSection>
  );
}

function FullTableConfigView(props) {
  const { waffle, value, onChange, workspaceUuid } = props;

  const { collectionMode = {}, pollingWindow, pollingTimezone, pollingDelay } = value;

  return (
    <FieldSection className="virtual-table-spaced-row">
      <FieldRow>
        <DataCollectionScheduleConfigItem
          value={collectionMode}
          onChange={(newCollectionMode) => {
            onChange({
              ...value,
              collectionMode: newCollectionMode,
            });
          }}
        />
      </FieldRow>
      {collectionMode.type === CollectionModeType.CUSTOM_SCHEDULED && (
        <>
          <FieldRowDivider />
          <CustomScheduleCollectionConfig
            workspaceUuid={workspaceUuid}
            value={collectionMode}
            onChange={(newCollectionMode) => {
              onChange({
                ...value,
                collectionMode: newCollectionMode,
              });
            }}
          />
          <FieldRowDivider />
        </>
      )}
      {collectionMode.type !== CollectionModeType.TRIGGERED && (
        <FieldRow>
          <AggregationWindowConfigItem
            label="Polling Interval"
            options={
              isFeatureEnabled(waffle, SupportedFeature.FULL_TABLE_METRIC_HOURLY)
                ? betaFullTableMetricAggregationWindowOptions
                : fullTableMetricAggregationWindowOptions
            }
            value={pollingWindow}
            onChange={(newPollingWindow) => {
              onChange({
                ...value,
                pollingWindow: newPollingWindow,
              });
            }}
          />
          <QueryTimezoneConfigItem
            label="Polling Timezone"
            value={pollingTimezone}
            onChange={(newPollingTimezone) => {
              onChange({
                ...value,
                pollingTimezone: newPollingTimezone,
              });
            }}
          />
          <SynchronizationDelayConfigItem
            label="Polling Delay"
            value={pollingDelay}
            onChange={(newPollingDelay) => {
              onChange({
                ...value,
                pollingDelay: newPollingDelay,
              });
            }}
          />
        </FieldRow>
      )}
    </FieldSection>
  );
}

function DiscardChangesConfirmModal(props) {
  const { isOpen, onClose, onDiscard } = props;

  return (
    <ModalDialog
      visible={isOpen}
      title="Discard changes?"
      mask={true}
      onCancel={onClose}
      footer={
        <ModalConfirmationButtons
          okText="Discard Changes"
          cancelText="Cancel"
          onCancelClick={onClose}
          onOkClick={onDiscard}
          okButtonProps={{ danger: true }}
        />
      }
    >
      This action will discard all the changes done till now.
    </ModalDialog>
  );
}

const ProfilerSchemaVirtualTableTakeOverView = forwardRef((props, ref) => {
  const {
    isEdit = false,
    defaultValue = null,
    dataSource,
    schema,
    workspaceUuid,
    userInfo,
    workspaceUserPermissions,
    closeTakeover,
    columnList = {
      data: [],
      loading: false,
      isLoaded: false,
    },
    verifyVirtualTableSql,
    onSave,
    previewVirtualTableSchemaList,
    previewVirtualTableSampleDataList,
    getProfilerConfigTableListUsage,
    resetProfilerVirtualTableView,
    schemaList,
    sampleDataList,
    tableListUsage,
  } = props;

  const [initialValue] = useState(
    isEdit ? deepcopy(defaultValue) : getDefaultValue(schema.uuid)
  );
  const [value, setValue] = useState(initialValue);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isConfigExpand, setIsConfigExpand] = useState(false);

  useEffect(() => {
    return () => {
      resetProfilerVirtualTableView();
    };
  }, [resetProfilerVirtualTableView]);

  const { tableName, profilerConfig } = value;
  const { queryScope, sql } = profilerConfig;
  const {
    data: columnListData,
    loading: validationInProgress,
    isLoaded: isColumnListLoaded,
  } = columnList;

  const canEditTable = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_TABLEPROFILEDETAILVIEW,
  ]);

  const [validationStarted, setValidationStarted] = useState(false);
  const prevShowValidationSuccess = useRef(false);
  const showValidationSuccess =
    validationStarted && !validationInProgress && columnListData.length > 0;

  useEffect(() => {
    if (
      !prevShowValidationSuccess.current &&
      showValidationSuccess &&
      !isConfigExpand
    ) {
      setIsConfigExpand(true);
    }
    prevShowValidationSuccess.current = showValidationSuccess;
  }, [showValidationSuccess, setIsConfigExpand, isConfigExpand]);

  const isValidateSqlEnabled = typeof sql === "string" && sql !== "";

  let validateSqlButtonText = "Validate Query";
  let validateSqlButtonIcon = <DataArrivalIcon />;
  if (validationInProgress) {
    validateSqlButtonText = "Validating";
    validateSqlButtonIcon = null;
  } else if (showValidationSuccess) {
    validateSqlButtonText = "Successful Validation";
    validateSqlButtonIcon = <CheckOneIcon />;
  }

  useImperativeHandle(ref, () => ({ onCancelClicked }));

  function onLoadColumnList() {
    setValidationStarted(true);
    const payload = {
      queryScope: value.profilerConfig.queryScope,
      sql: value.profilerConfig.sql,
    };
    verifyVirtualTableSql(workspaceUuid, dataSource.metadata.uuid, payload);
  }

  function onSaveTable() {
    const newVirtualTableObject = deepcopy(value);
    newVirtualTableObject.profilerConfig.sql = formatCustomSql(
      value.profilerConfig.sql,
      dataSource.config.connection.type
    );
    onSave?.(newVirtualTableObject)
      ?.then(() => closeTakeover())
      .catch((error) => console.log(`Fail to create or update table due to ${error}`));
  }

  function onSaveClicked() {
    if (!isEdit) {
      onSaveTable();
      return;
    }

    getProfilerConfigTableListUsage(
      workspaceUuid,
      dataSource,
      [initialValue.uuid],
      [value.profilerConfig]
    );
    setIsConfirmationModalOpen(true);
  }

  function onCancelClicked() {
    if (jsonDiff(initialValue, value)) {
      setIsDiscardModalOpen(true);
    } else {
      closeTakeover();
    }
  }

  const isIncrementalQueryScope = queryScope === QueryScope.TIME_RANGE;

  const ConfigViewComponent = isIncrementalQueryScope
    ? TimeRangeConfigView
    : FullTableConfigView;

  let enablePreview;
  if (!isEdit) {
    enablePreview = showValidationSuccess && isVirtualTableConfigComplete(value);
  } else {
    enablePreview =
      (showValidationSuccess ||
        initialValue.profilerConfig.sql === value.profilerConfig.sql) &&
      isVirtualTableConfigComplete(value);
  }

  const isPartitionEnabled = isPartitionConfigEnabled(
    dataSource.config.connection.type
  );

  return (
    <div className="profiler-schema-virtual-table-takeover-view-container">
      <div className="profiler-schema-virtual-table-takeover-view-inner-container">
        <div className="profiler-schema-virtual-table-takeover-view-nav-container">
          <div className="profiler-schema-virtual-table-takeover-view-title-container">
            {`${isEdit ? "Edit" : "Create"} Virtual Table`}
          </div>
          <Button outline block onClick={onCancelClicked}>
            Cancel
          </Button>
          <Button
            primary
            block
            disabled={
              !isValidVirtualTableConfig(value) ||
              !jsonDiff(initialValue, value) ||
              !canEditTable
            }
            onClick={onSaveClicked}
          >
            Save
          </Button>
        </div>
        <div className="profiler-schema-virtual-table-takeover-view-content-container">
          <div className="profiler-schema-virtual-table-takeover-view-content-left-container">
            <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-container expanded-container">
              <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-icon-container">
                <DefineIcon />
              </div>
              <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-container expanded-column-container">
                <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-title-container">
                  Define
                </div>
                <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-content-container expanded-column-container">
                  <FieldSection className="expanded-column-container">
                    <FieldRow>
                      <LabeledInput
                        label="Table name"
                        autoComplete="off"
                        value={tableName}
                        disabled={isEdit}
                        onChange={(e) => {
                          const newTableName = e.target.value;
                          if (newTableName === tableName) {
                            return;
                          }

                          setValue({
                            ...value,
                            tableName: newTableName,
                          });
                        }}
                      />
                      <LabeledSelect
                        label="Query scope"
                        showSearch
                        options={queryScopeOptions}
                        value={queryScope}
                        disabled={isEdit}
                        onChange={(newQueryScope) => {
                          if (newQueryScope === queryScope) {
                            return;
                          }

                          setValidationStarted(false);

                          let newProfilerConfig;
                          if (newQueryScope === QueryScope.FULL_TABLE) {
                            newProfilerConfig = {
                              pollingDelay: 0,
                              pollingTimezone: "UTC",
                              pollingWindow: AggregationWindowType.HOUR,
                              window: null,
                              syncDelay: 0,
                              timezone: null,
                              dataTimezone: null,
                              timestampColumn: null,
                              timestampColumnType: null,
                            };
                          } else {
                            newProfilerConfig = {
                              pollingDelay: 0,
                              pollingTimezone: null,
                              pollingWindow: null,
                              window: AggregationWindowType.HOUR,
                              syncDelay: 0,
                              timezone: "UTC",
                              dataTimezone: "UTC",
                              timestampColumn: "",
                              timestampColumnType: "",
                              partitions: [],
                            };
                          }
                          setValue({
                            ...value,
                            profilerConfig: {
                              ...profilerConfig,
                              queryScope: newQueryScope,
                              ...newProfilerConfig,
                            },
                          });
                        }}
                      />
                    </FieldRow>
                    <FieldRow className="expanded-column-container">
                      <CustomSqlConfigItem
                        validating={validationInProgress}
                        value={sql}
                        className="custom-sql-config-item"
                        description={
                          <>
                            See &nbsp;
                            <a
                              href="https://docs.lightup.ai/docs/add-virtual-tables-for-relational-datasources"
                              target="_blank"
                              rel="noreferrer"
                            >
                              here
                            </a>
                            &nbsp; for help on writing SQL for Virtual Tables.
                          </>
                        }
                        onChange={(newSql) => {
                          if (newSql !== sql) {
                            setValidationStarted(false);
                          }

                          setValue({
                            ...value,
                            profilerConfig: {
                              ...profilerConfig,
                              timestampColumn: "",
                              partitions: [],
                              sql: newSql,
                            },
                          });
                        }}
                      />
                    </FieldRow>
                    <FieldRow>
                      <Button
                        primary
                        success={showValidationSuccess}
                        block
                        size="large"
                        disabled={!isValidateSqlEnabled || validationInProgress}
                        onClick={() => {
                          onLoadColumnList();
                        }}
                      >
                        <div className="metric-config-custom-sql-validate-query-button">
                          <span>{validateSqlButtonText}</span>
                          {validateSqlButtonIcon}
                        </div>
                      </Button>
                    </FieldRow>
                  </FieldSection>
                </div>
              </div>
            </div>
            <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-container">
              <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-icon-container">
                <ConfigIcon />
              </div>
              <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-container">
                <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-title-container">
                  Configuration
                  <div
                    className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-title-icon-container"
                    onClick={() => setIsConfigExpand(!isConfigExpand)}
                  >
                    <DownOutlined rotate={isConfigExpand ? -180 : 0} />
                  </div>
                </div>
                {isConfigExpand && (
                  <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-content-container">
                    <ConfigViewComponent
                      waffle={userInfo.waffle}
                      dataSource={dataSource}
                      columnList={columnListData}
                      isColumnListLoaded={isColumnListLoaded}
                      isColumnListLoading={validationInProgress}
                      onLoadColumnList={onLoadColumnList}
                      value={profilerConfig}
                      onChange={(newProfilerConfig) =>
                        setValue({ ...value, profilerConfig: newProfilerConfig })
                      }
                      workspaceUuid={workspaceUuid}
                    />
                    {isIncrementalQueryScope && (
                      <Collapse
                        ghost
                        expandIcon={({ isActive }) => (
                          <DownOutlined rotate={isActive ? -180 : 0} />
                        )}
                        className="profiler-schema-virtual-table-takeover-view-content-collapse-container"
                      >
                        <Collapse.Panel header="Advanced Options" key="advanced">
                          <div className="profiler-schema-virtual-table-takeover-view-content-configure-section-content-advanced">
                            <LabeledSelect
                              label="Data collection window"
                              showSearch
                              staticLabel
                              options={collectionWindowOptions}
                              value={value.profilerConfig.collectionWindow}
                              onChange={(newCollectionWindow) => {
                                setValue({
                                  ...value,
                                  profilerConfig: {
                                    ...profilerConfig,
                                    collectionWindow: newCollectionWindow,
                                  },
                                });
                              }}
                            />
                            {isPartitionEnabled && (
                              <ProfilerTablePartitionEditor
                                partitions={value.profilerConfig.partitions ?? []}
                                partitionColumnList={columnListData}
                                editEnabled={true}
                                updateTableConfig={(newConfig) => {
                                  setValue({
                                    ...value,
                                    profilerConfig: { ...profilerConfig, ...newConfig },
                                  });
                                }}
                                customizationOptions={{
                                  hideAdvancedButton: true,
                                  hideRecommended: true,
                                }}
                              />
                            )}
                          </div>
                        </Collapse.Panel>
                      </Collapse>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="profiler-schema-virtual-table-takeover-view-content-right-container">
            <ProfilerSchemaVirtualTablePreviewView
              enablePreview={enablePreview}
              workspaceUuid={workspaceUuid}
              dataSource={dataSource}
              schema={schema}
              value={value}
              schemaList={schemaList}
              sampleDataList={sampleDataList}
              previewVirtualTableSchemaList={previewVirtualTableSchemaList}
              previewVirtualTableSampleDataList={previewVirtualTableSampleDataList}
            />
          </div>
        </div>
      </div>
      <DiscardChangesConfirmModal
        isOpen={isDiscardModalOpen}
        onClose={() => setIsDiscardModalOpen(false)}
        onDiscard={() => closeTakeover()}
      />
      {isConfirmationModalOpen && (
        <ProfilerConfirmationDialog
          modalIsOpen={isConfirmationModalOpen}
          setIsOpen={(isConfirmationDialogOpen) =>
            setIsConfirmationModalOpen(isConfirmationDialogOpen)
          }
          okClicked={(paramsList) => {
            onSaveTable();
          }}
          usage={tableListUsage}
          context={{}}
          okText={"OK"}
          enableUsage={true}
          actionType={"update"}
          defaultConfirmationMsg={`You are about to update table ${initialValue.tableName}. This action is not reversible.`}
        />
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  sampleDataList: state.profilerReducer.profilerVirtualTableSampleDataList,
  schemaList: state.profilerReducer.profilerVirtualTableSchemaList,
  columnList: state.profilerReducer.profilerVirtualTableColumnList,
  tableListUsage: state.profilerReducer.profilerConfigDataSourceTableListUsage,
});

const mapDispatchToProps = (dispatch) => ({
  verifyVirtualTableSql: (workspaceUuid, dataSourceUuid, payload) =>
    dispatch(verifyVirtualTableSql(workspaceUuid, dataSourceUuid, payload)),
  previewVirtualTableSchemaList: (workspaceUuid, dataSourceUuid, virtualTable) =>
    dispatch(
      previewVirtualTableSchemaList(workspaceUuid, dataSourceUuid, virtualTable)
    ),
  previewVirtualTableSampleDataList: (workspaceUuid, payload) =>
    dispatch(previewVirtualTableSampleDataList(workspaceUuid, payload)),
  getProfilerConfigTableListUsage: (
    workspaceUuid,
    dataSource,
    tableUuids,
    tableProfilerConfigs
  ) =>
    dispatch(
      getProfilerConfigTableListUsage(
        workspaceUuid,
        dataSource,
        tableUuids,
        tableProfilerConfigs
      )
    ),
  resetProfilerVirtualTableView: () => dispatch(resetProfilerVirtualTableView()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  ProfilerSchemaVirtualTableTakeOverView
);
