import React from "react";

export function HighSeverityIcon(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 18.001C9.18629 18.001 6.5 15.3147 6.5 12.001C6.5 8.6873 9.18629 6.00101 12.5 6.00101C15.8137 6.00101 18.5 8.6873 18.5 12.001C18.5 13.5923 17.8679 15.1184 16.7426 16.2436C15.6174 17.3689 14.0913 18.001 12.5 18.001Z"
        fill="#DC7E82"
      />
    </svg>
  );
}

export function NormalSeverityIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 18.002C9.18629 18.002 6.5 15.3157 6.5 12.002C6.5 8.68829 9.18629 6.002 12.5 6.002C15.8137 6.002 18.5 8.68829 18.5 12.002C18.5 13.5933 17.8679 15.1194 16.7426 16.2446C15.6174 17.3699 14.0913 18.002 12.5 18.002Z"
        fill="#FAB800"
      />
    </svg>
  );
}
