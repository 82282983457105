import { connect } from "react-redux";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import { getKpiList } from "../../actions/kpi/kpi-action";
import { getRuleList } from "../../actions/rule/rule-action";
import { getTagList } from "../../actions/tag/tag-action";
import {
  getDashBoardChartList,
  getDashboardIncidentListPerChart,
  addDashboardChartConfig,
  updateDashboardChartConfig,
  deleteDashboardChartConfig,
} from "../../actions/dashboard/dashboard-action";
import Dashboard from "./dashboard";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  dataSourceList: state.dataSourceReducer.dataSourceList.data,
  kpiList: state.kpiReducer.kpiList.data,
  ruleList: state.ruleReducer.ruleList.data,
  tagList: state.tagReducer.tagList,
  dashboardChartList: state.dashboardReducer.dashboardChartList,
  dashboardIncidentListSetPerChart:
    state.dashboardReducer.dashboardIncidentListSetPerChart,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getKpiList: (workspaceUuid) => dispatch(getKpiList(workspaceUuid)),
  getRuleList: (workspaceUuid) => dispatch(getRuleList(workspaceUuid)),
  getTagList: (workspaceUuid) => dispatch(getTagList(workspaceUuid)),
  getDashBoardChartList: (workspaceUuid) =>
    dispatch(getDashBoardChartList(workspaceUuid)),
  getDashboardIncidentListPerChart: (workspaceUuid, chartUuid, queryObject) =>
    dispatch(getDashboardIncidentListPerChart(workspaceUuid, chartUuid, queryObject)),
  addDashboardChartConfig: (workspaceUuid, newDashboardChartConfig) =>
    dispatch(addDashboardChartConfig(workspaceUuid, newDashboardChartConfig)),
  updateDashboardChartConfig: (workspaceUuid, newDashboardChartConfig) =>
    dispatch(updateDashboardChartConfig(workspaceUuid, newDashboardChartConfig)),
  deleteDashboardChartConfig: (workspaceUuid, dashboardChartConfig) =>
    dispatch(deleteDashboardChartConfig(workspaceUuid, dashboardChartConfig)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
