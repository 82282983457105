import { classesName } from "../../utils/css";
import NgButton, { TextWithIcon } from "../button/ng-button";
import ButtonText from "../button/button-text";
import React from "react";
import NgTooltip from "../tooltip/ng-tooltip";

import "./index.scss";

const TooltipContent = ({ tooltip }) => {
  return <div className="status-badge-metric-tooltip-content">{tooltip}</div>;
};

function StatusBadge(props) {
  const {
    Icon,
    title,
    tooltip,
    clickable = false,
    color,
    onClick,
    buttonStyle = false,
  } = props;
  const className = classesName("status-badge", clickable && "clickable");
  const style = color ? { style: { color } } : {};
  const innerContent = tooltip ? (
    <NgTooltip title={<TooltipContent tooltip={tooltip} />}>
      {Icon && <Icon />}
      {title}
    </NgTooltip>
  ) : (
    <>
      {Icon && <Icon />}
      {title}
    </>
  );

  let statusBadgeContent;
  if (buttonStyle) {
    statusBadgeContent = (
      <NgButton onClick={onClick} disabled={!clickable} outline dropdown size="large">
        <TextWithIcon>{innerContent}</TextWithIcon>
      </NgButton>
    );
  } else {
    statusBadgeContent = clickable ? (
      <ButtonText onClick={onClick}>{innerContent}</ButtonText>
    ) : (
      innerContent
    );
  }

  return (
    <div className={className} {...style}>
      {statusBadgeContent}
    </div>
  );
}

export default StatusBadge;
