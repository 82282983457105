export function AddDataSourceIcon(props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M34.2857 0H5.71428C4.9241 0 4.28571 0.638393 4.28571 1.42857V38.5714C4.28571 39.3616 4.9241 40 5.71428 40H34.2857C35.0759 40 35.7143 39.3616 35.7143 38.5714V1.42857C35.7143 0.638393 35.0759 0 34.2857 0ZM7.49999 3.21429H32.5V12.5H7.49999V3.21429ZM32.5 24.6429H7.49999V15.3571H32.5V24.6429ZM32.5 36.7857H7.49999V27.5H32.5V36.7857ZM19.2857 6.42857H11.0714C10.875 6.42857 10.7143 6.58929 10.7143 6.78571V8.92857C10.7143 9.125 10.875 9.28571 11.0714 9.28571H19.2857C19.4821 9.28571 19.6429 9.125 19.6429 8.92857V6.78571C19.6429 6.58929 19.4821 6.42857 19.2857 6.42857ZM11.0714 21.4286H19.2857C19.4821 21.4286 19.6429 21.2679 19.6429 21.0714V18.9286C19.6429 18.7321 19.4821 18.5714 19.2857 18.5714H11.0714C10.875 18.5714 10.7143 18.7321 10.7143 18.9286V21.0714C10.7143 21.2679 10.875 21.4286 11.0714 21.4286ZM25.7143 32.3214C25.7143 32.795 25.9024 33.2492 26.2373 33.5841C26.5722 33.919 27.0264 34.1071 27.5 34.1071C27.9736 34.1071 28.4278 33.919 28.7627 33.5841C29.0976 33.2492 29.2857 32.795 29.2857 32.3214C29.2857 31.8478 29.0976 31.3936 28.7627 31.0587C28.4278 30.7239 27.9736 30.5357 27.5 30.5357C27.0264 30.5357 26.5722 30.7239 26.2373 31.0587C25.9024 31.3936 25.7143 31.8478 25.7143 32.3214V32.3214Z"
        fill="#4832F3"
      />
    </svg>
  );
}

export function AddMemberIcon(props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.00033C17.2386 5.00033 15 7.2389 15 10.0003C15 12.7617 17.2386 15.0003 20 15.0003C22.7615 15.0003 25 12.7617 25 10.0003C25 7.2389 22.7615 5.00033 20 5.00033ZM11.6667 10.0003C11.6667 5.39795 15.3977 1.66699 20 1.66699C24.6024 1.66699 28.3334 5.39795 28.3334 10.0003C28.3334 14.6027 24.6024 18.3337 20 18.3337C15.3977 18.3337 11.6667 14.6027 11.6667 10.0003ZM3.33337 36.667C3.33337 27.4623 10.7953 20.0003 20 20.0003C29.2048 20.0003 36.6667 27.4623 36.6667 36.667C36.6667 37.5875 35.9205 38.3337 35 38.3337C34.0796 38.3337 33.3334 37.5875 33.3334 36.667C33.3334 29.3032 27.3638 23.3337 20 23.3337C12.6363 23.3337 6.66671 29.3032 6.66671 36.667C6.66671 37.5875 5.92052 38.3337 5.00004 38.3337C4.07957 38.3337 3.33337 37.5875 3.33337 36.667ZM20 26.667C20.9205 26.667 21.6667 27.4132 21.6667 28.3337V30.8337H24.1667C25.0872 30.8337 25.8334 31.5798 25.8334 32.5003C25.8334 33.4208 25.0872 34.167 24.1667 34.167H21.6667V36.667C21.6667 37.5875 20.9205 38.3337 20 38.3337C19.0796 38.3337 18.3334 37.5875 18.3334 36.667V34.167H15.8334C14.9129 34.167 14.1667 33.4208 14.1667 32.5003C14.1667 31.5798 14.9129 30.8337 15.8334 30.8337H18.3334V28.3337C18.3334 27.4132 19.0796 26.667 20 26.667Z"
        fill="#4832F3"
      />
    </svg>
  );
}
