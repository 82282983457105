import { connect } from "react-redux";
import { getIntegrationList } from "../../actions/integration/integration-action";
import {
  getProfilerTreeData,
  resetProfilerTreeData,
  getProfilerConfigDataSourceTableList,
  getProfilerConfigTableList,
  getProfilerCurrentDataSourceSummary,
  resetProfilerCurrentDataSourceSummary,
  getProfilerCurrentSchemaSummary,
  resetProfilerCurrentSchemaSummary,
  getProfilerCurrentTableAutoMetricsData,
  resetProfilerCurrentTableSummary,
  refreshTreeDataSourceNode,
  // Table page
  getProfilerCurrentTableOverviewSummary,
  getProfilerCurrentTableHealthData,
  getProfilerCurrentTableMetadataMetricsData,
  updateProfilerTableAutoMetricsChartData,
  updateProfilerTableMetadataMetricsChartData,
  getProfilerCurrentMetricMetricsData,
  getProfilerCurrentMetricSliceValue,
  resetProfilerCurrentMetricSummary,
  createSystemProfilerRule,
  toggleSystemProfilerRuleStatus,
  updateSystemProfileDataSourceRuleAlertingChannel,
  updateSystemProfileSchemaRuleAlertingChannel,
  updateSystemProfileDataSourceTableRuleAlertingChannel,
  updateSystemProfileTableRuleAlertingChannel,
  createCustomMetricRule,
  updateCustomMetricRuleAlertingChannel,
  getProfilerTableColumnListDataProfileData,
  getProfilerConfigTableColumnList,

  // Column related API call
  getProfilerColumnCurrentMetricsData,
  getProfilerColumnDataProfileData,
  createColumnSystemProfilerRule,
  updateProfilerColumnChartData,
  updateProfilerColumnRuleAlertingChannel,
  updateProfilerColumnCategoryListChange,
  resetProfilerColumn,
  // Incident list page
  getProfilerCurrentIncidentList,
  getProfilerCurrentIncidentMetricData,
  resetProfilerCurrentIncidentList,
  updateProfilerIncidentStatus,
  // Current profiler config object,
  getProfilerCurrentMonitorObject,
  monitorAllForDataSource,
  monitorAllForSchema,
  monitorAllForTable,
  monitorAllForColumn,
  updateProfilerConfigTableList,
  updateProfilerConfigColumnList,
  createProfilerCustomMetric,
  refreshProfilerTreeCustomMetric,
  // Trigger metric
  triggerProfilerMetrics,
  resetProfilerCurrentMetricMetricsData,
  resetProfilerCurrentMetricSliceValue,
  setProfilerAfterTreeNavigationAction,
  createProfilerVirtualTable,
  getProfilerTableActivityLog,
} from "../../actions/profiler/profiler-action";
import {
  openWorkspaceTakeover,
  closeTakeover,
} from "../../actions/takeover/takeover-action";
import Profiler from "./profiler";
import { addKpi, deleteKpi, toggleKpiLiveStatus } from "../../actions/kpi/kpi-action";
import { deleteRule } from "../../actions/rule/rule-action";
import { getTagList } from "../../actions/tag/tag-action";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  alertingChannelList: state.integrationReducer.integrationList.data,
  profilerTreeData: state.profilerReducer.profilerTreeData,
  dataSourceAllTableList: state.profilerReducer.profilerConfigTableList,
  dataSourceTableList: state.profilerReducer.profilerConfigDataSourceTableList,
  tableColumnList: state.profilerReducer.profilerConfigTableColumnList,
  profilerCustomMetricTreeData: state.profilerReducer.profilerCustomMetricTreeData,
  profilerCurrentDataSourceSummary:
    state.profilerReducer.profilerCurrentDataSourceSummary,
  profilerCurrentSchemaSummary: state.profilerReducer.profilerCurrentSchemaSummary,
  profilerCurrentTableAutoMetricsData:
    state.profilerReducer.profilerCurrentTableAutoMetricsData,
  profilerCurrentTableOverviewSummary:
    state.profilerReducer.profilerCurrentTableOverviewSummary,
  profilerCurrentTableHealthData: state.profilerReducer.profilerCurrentTableHealthData,
  profilerTableMetadataMetricsData:
    state.profilerReducer.profilerTableMetadataMetricsData,
  profilerCurrentMetricMetricsData:
    state.profilerReducer.profilerCurrentMetricMetricsData,
  profilerCurrentMetricSliceValues:
    state.profilerReducer.profilerCurrentMetricSliceValues,
  profilerCurrentColumnMetricsData:
    state.profilerReducer.profilerColumnCurrentMetricsData,
  profilerCurrentIncidentList: state.profilerReducer.profilerCurrentIncidentList,
  profilerCurrentIncidentMetricsData:
    state.profilerReducer.profilerCurrentIncidentMetricsData,
  profilerCurrentMonitorObject: state.profilerReducer.profilerCurrentMonitorObject,
  profilerTableDataProfileData: state.profilerReducer.profilerTableDataProfileData,
  profilerColumnDataProfileData: state.profilerReducer.profilerColumnDataProfileData,
  profilerAfterTreeNavigationAction:
    state.profilerReducer.profilerAfterTreeNavigationAction,
  waffle: state.userReducer.currentUserInfo.waffle,
  workspaceList: state.adminReducer.userWorkspaceList.data,
  tagList: state.tagReducer.tagList,
});

const mapDispatchToProps = (dispatch) => ({
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  getProfilerTreeData: (workspaceUuid) => dispatch(getProfilerTreeData(workspaceUuid)),
  resetProfilerTreeData: () => dispatch(resetProfilerTreeData()),
  getProfilerConfigDataSourceTableList: (workspaceUuid, dataSource, schema) =>
    dispatch(getProfilerConfigDataSourceTableList(workspaceUuid, dataSource, schema)),
  getProfilerConfigTableList: (workspaceUuid, dataSource, opts) =>
    dispatch(getProfilerConfigTableList(workspaceUuid, dataSource, opts)),
  getProfilerConfigTableColumnList: (
    workspaceUuid,
    dataSource,
    table,
    enableProfilerConfigCheck = false,
    opts = {}
  ) =>
    dispatch(
      getProfilerConfigTableColumnList(
        workspaceUuid,
        dataSource,
        table,
        enableProfilerConfigCheck,
        opts
      )
    ),
  getProfilerCurrentDataSourceSummary: (workspaceUuid, currentDataSource) =>
    dispatch(getProfilerCurrentDataSourceSummary(workspaceUuid, currentDataSource)),
  refreshTreeDataSourceNode: (workspaceUuid, dataSource) =>
    dispatch(refreshTreeDataSourceNode(workspaceUuid, dataSource)),
  resetProfilerCurrentDataSourceSummary: () =>
    dispatch(resetProfilerCurrentDataSourceSummary()),
  getProfilerCurrentSchemaSummary: (
    workspaceUuid,
    currentDataSource,
    currentSchema,
    opts
  ) =>
    dispatch(
      getProfilerCurrentSchemaSummary(
        workspaceUuid,
        currentDataSource,
        currentSchema,
        opts
      )
    ),
  resetProfilerCurrentSchemaSummary: () =>
    dispatch(resetProfilerCurrentSchemaSummary()),
  getProfilerCurrentTableAutoMetricsData: (
    workspaceUuid,
    currentDataSource,
    currentTable,
    opts
  ) =>
    dispatch(
      getProfilerCurrentTableAutoMetricsData(
        workspaceUuid,
        currentDataSource,
        currentTable,
        opts
      )
    ),
  getProfilerCurrentTableOverviewSummary: (
    workspaceUuid,
    currentDataSource,
    currentTable,
    opts
  ) =>
    dispatch(
      getProfilerCurrentTableOverviewSummary(
        workspaceUuid,
        currentDataSource,
        currentTable,
        opts
      )
    ),
  getProfilerCurrentTableHealthData: (
    workspaceUuid,
    currentDataSource,
    currentTable,
    opts = {}
  ) =>
    dispatch(
      getProfilerCurrentTableHealthData(
        workspaceUuid,
        currentDataSource,
        currentTable,
        opts
      )
    ),
  getProfilerCurrentTableMetadataMetricsData: (
    workspaceUuid,
    currentDataSource,
    currentTable,
    opts
  ) =>
    dispatch(
      getProfilerCurrentTableMetadataMetricsData(
        workspaceUuid,
        currentDataSource,
        currentTable,
        opts
      )
    ),
  updateProfilerTableAutoMetricsChartData: (workspaceUuid, opts) =>
    dispatch(updateProfilerTableAutoMetricsChartData(workspaceUuid, opts)),
  updateProfilerTableMetadataMetricsChartData: (workspaceUuid, opts) =>
    dispatch(updateProfilerTableMetadataMetricsChartData(workspaceUuid, opts)),
  getProfilerTableColumnListDataProfileData: (workspaceUuid, dataSource, table) =>
    dispatch(
      getProfilerTableColumnListDataProfileData(workspaceUuid, dataSource, table)
    ),
  resetProfilerCurrentTableSummary: () => dispatch(resetProfilerCurrentTableSummary()),
  createSystemProfilerRule: (workspaceUuid, ruleConfig) =>
    dispatch(createSystemProfilerRule(workspaceUuid, ruleConfig)),
  toggleSystemProfilerRuleStatus: (workspaceUuid, ruleConfig) =>
    dispatch(toggleSystemProfilerRuleStatus(workspaceUuid, ruleConfig)),
  updateSystemProfileTableRuleAlertingChannel: (
    workspaceUuid,
    ruleConfig,
    newAlertChannels
  ) =>
    dispatch(
      updateSystemProfileTableRuleAlertingChannel(
        workspaceUuid,
        ruleConfig,
        newAlertChannels
      )
    ),
  updateSystemProfileSchemaRuleAlertingChannel: (
    workspaceUuid,
    dataSourceUuid,
    schemaUuid,
    newProfilerConfig
  ) =>
    dispatch(
      updateSystemProfileSchemaRuleAlertingChannel(
        workspaceUuid,
        dataSourceUuid,
        schemaUuid,
        newProfilerConfig
      )
    ),
  updateSystemProfileDataSourceRuleAlertingChannel: (
    workspaceUuid,
    dataSourceUuid,
    newProfilerConfig
  ) =>
    dispatch(
      updateSystemProfileDataSourceRuleAlertingChannel(
        workspaceUuid,
        dataSourceUuid,
        newProfilerConfig
      )
    ),
  updateSystemProfileDataSourceTableRuleAlertingChannel: (
    workspaceUuid,
    dataSourceUuid,
    schemaUuid,
    tableUuid,
    newProfilerConfig,
    status
  ) =>
    dispatch(
      updateSystemProfileDataSourceTableRuleAlertingChannel(
        workspaceUuid,
        dataSourceUuid,
        schemaUuid,
        tableUuid,
        newProfilerConfig,
        status
      )
    ),
  deleteRule: (workspaceUuid, uuid) => dispatch(deleteRule(workspaceUuid, uuid)),
  refreshProfilerTreeCustomMetric: (workspaceUuid, metricUuid) =>
    dispatch(refreshProfilerTreeCustomMetric(workspaceUuid, metricUuid)),
  getProfilerCurrentMetricMetricsData: (
    workspaceUuid,
    metric,
    queryOptions,
    opts = {}
  ) =>
    dispatch(
      getProfilerCurrentMetricMetricsData(workspaceUuid, metric, queryOptions, opts)
    ),
  resetProfilerCurrentMetricMetricsData: () =>
    dispatch(resetProfilerCurrentMetricMetricsData()),
  toggleKpiLiveStatus: (workspaceUuid, kpiUI, newIsLive, opts = {}) =>
    dispatch(toggleKpiLiveStatus(workspaceUuid, kpiUI, newIsLive, opts)),
  getProfilerCurrentMetricSliceValue: (workspaceUuid, metric, queryOptions) =>
    dispatch(getProfilerCurrentMetricSliceValue(workspaceUuid, metric, queryOptions)),
  resetProfilerCurrentMetricSliceValue: () =>
    dispatch(resetProfilerCurrentMetricSliceValue()),
  deleteKpi: (workspaceUuid, kpi) => dispatch(deleteKpi(workspaceUuid, kpi)),
  createCustomMetricRule: (workspaceUuid, ruleConfig) =>
    dispatch(createCustomMetricRule(workspaceUuid, ruleConfig)),
  updateCustomMetricRuleAlertingChannel: (
    workspaceUuid,
    ruleConfig,
    newAlertChannels
  ) =>
    dispatch(
      updateCustomMetricRuleAlertingChannel(workspaceUuid, ruleConfig, newAlertChannels)
    ),
  resetProfilerCurrentMetricSummary: () =>
    dispatch(resetProfilerCurrentMetricSummary()),
  getProfilerColumnCurrentMetricsData: (
    workspaceUuid,
    dataSource,
    table,
    column,
    opts
  ) =>
    dispatch(
      getProfilerColumnCurrentMetricsData(
        workspaceUuid,
        dataSource,
        table,
        column,
        opts
      )
    ),
  getProfilerColumnDataProfileData: (workspaceUuid, dataSource, table, column, opts) =>
    dispatch(
      getProfilerColumnDataProfileData(workspaceUuid, dataSource, table, column, opts)
    ),
  createColumnSystemProfilerRule: (workspaceUuid, ruleConfig) =>
    dispatch(createColumnSystemProfilerRule(workspaceUuid, ruleConfig)),
  updateProfilerColumnChartData: (workspaceUuid, opts) =>
    dispatch(updateProfilerColumnChartData(workspaceUuid, opts)),
  updateProfilerColumnRuleAlertingChannel: (
    workspaceUuid,
    ruleConfig,
    newAlertChannels
  ) =>
    dispatch(
      updateProfilerColumnRuleAlertingChannel(
        workspaceUuid,
        ruleConfig,
        newAlertChannels
      )
    ),
  updateProfilerColumnCategoryListChange: (
    workspaceUuid,
    dataSourceUuid,
    schemaUuid,
    tableUuid,
    columnUuid,
    profilerConfig
  ) =>
    dispatch(
      updateProfilerColumnCategoryListChange(
        workspaceUuid,
        dataSourceUuid,
        schemaUuid,
        tableUuid,
        columnUuid,
        profilerConfig
      )
    ),
  resetProfilerColumn: () => dispatch(resetProfilerColumn()),
  getProfilerCurrentIncidentList: (workspaceUuid, queryObject) =>
    dispatch(getProfilerCurrentIncidentList(workspaceUuid, queryObject)),
  getProfilerCurrentIncidentMetricData: (workspaceUuid, queryObject) =>
    dispatch(getProfilerCurrentIncidentMetricData(workspaceUuid, queryObject)),
  resetProfilerCurrentIncidentList: () => dispatch(resetProfilerCurrentIncidentList()),
  getProfilerCurrentMonitorObject: (workspaceUuid, queryObject) =>
    dispatch(getProfilerCurrentMonitorObject(workspaceUuid, queryObject)),
  monitorAllForDataSource: (workspaceUuid, configObjects) =>
    dispatch(monitorAllForDataSource(workspaceUuid, configObjects)),
  monitorAllForTable: (workspaceUuid, configObjects) =>
    dispatch(monitorAllForTable(workspaceUuid, configObjects)),
  monitorAllForSchema: (workspaceUuid, configObjects) =>
    dispatch(monitorAllForSchema(workspaceUuid, configObjects)),
  monitorAllForColumn: (workspaceUuid, configObjects) =>
    dispatch(monitorAllForColumn(workspaceUuid, configObjects)),
  updateProfilerIncidentStatus: (workspaceUuid, incident, newStatus) =>
    dispatch(updateProfilerIncidentStatus(workspaceUuid, incident, newStatus)),
  updateProfilerConfigTableList: (
    workspaceUuid,
    dataSource,
    paramsList,
    opts = { isRefreshTree: true }
  ) =>
    dispatch(
      updateProfilerConfigTableList(workspaceUuid, dataSource, paramsList, opts)
    ),
  updateProfilerConfigColumnList: (
    workspaceUuid,
    dataSource,
    table,
    paramsList,
    refreshTree = true
  ) =>
    dispatch(
      updateProfilerConfigColumnList(
        workspaceUuid,
        dataSource,
        table,
        paramsList,
        refreshTree
      )
    ),
  createProfilerCustomMetric: (workspaceUuid, newMetric) =>
    dispatch(createProfilerCustomMetric(workspaceUuid, newMetric, true)),
  triggerProfilerMetrics: (workspaceUuid, dataSourceUuid, payload, isRefreshTreeData) =>
    dispatch(
      triggerProfilerMetrics(workspaceUuid, dataSourceUuid, payload, isRefreshTreeData)
    ),
  setProfilerAfterTreeNavigationAction: (action) =>
    dispatch(setProfilerAfterTreeNavigationAction(action)),
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick) =>
    dispatch(openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick)),
  closeTakeover: () => dispatch(closeTakeover()),
  createProfilerVirtualTable: (workspaceUuid, dataSource, payload, isRefreshTreeData) =>
    dispatch(
      createProfilerVirtualTable(workspaceUuid, dataSource, payload, isRefreshTreeData)
    ),
  getTagList: (workspaceUuid) => dispatch(getTagList(workspaceUuid)),
  addKpi: (workspaceUuid, newKpi) => dispatch(addKpi(workspaceUuid, newKpi)),
  getProfilerTableActivityLog: (workspaceUuid, dataSourceUuid, tableUuid) =>
    dispatch(getProfilerTableActivityLog(workspaceUuid, dataSourceUuid, tableUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profiler);
