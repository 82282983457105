import React from "react";

import "./metric-config-preview-scan-stats-view.scss";

function MetricConfigPreviewScanStatsView({ queryStats = {}, rowCount }) {
  const { processTime, scannedTotalBytes, scannedTotalRows } = queryStats;

  return (
    <div className="metric-config-preview-scan-stats-view-container">
      <div className="metric-config-preview-scan-stats-view-title-container">
        Database scan statistics
      </div>
      <div className="metric-config-preview-scan-stats-view-row-container lightup-horizon-flex-container">
        <div className="metric-config-preview-scan-stats-view-cell-container">
          <span className="metric-config-preview-scan-stats-view-label-container">
            Total rows scanned
          </span>
          <span className="metric-config-preview-scan-stats-view-value-container">
            {scannedTotalRows}
          </span>
        </div>
        <div className="metric-config-preview-scan-stats-view-cell-container">
          <span className="metric-config-preview-scan-stats-view-label-container">
            Process time
          </span>
          <span className="metric-config-preview-scan-stats-view-value-container">
            {`${processTime}s`}
          </span>
        </div>
      </div>
      <div className="metric-config-preview-scan-stats-view-row-container lightup-horizon-flex-container">
        <div className="metric-config-preview-scan-stats-view-cell-container">
          <span className="metric-config-preview-scan-stats-view-label-container">
            Total data scanned
          </span>
          <span className="metric-config-preview-scan-stats-view-value-container">
            {`${scannedTotalBytes} bytes`}
          </span>
        </div>
        <div className="metric-config-preview-scan-stats-view-cell-container">
          <span className="metric-config-preview-scan-stats-view-label-container">
            Total returned count
          </span>
          <span className="metric-config-preview-scan-stats-view-value-container">
            {rowCount}
          </span>
        </div>
      </div>
    </div>
  );
}

export default MetricConfigPreviewScanStatsView;
