import { connect } from "react-redux";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import {
  editRule,
  getRule,
  deleteRule,
  resetCurrentEditRuleStatus,
  getCurrentRuleStatus,
  getMetricsData,
  addPreview,
  deletePreview,
  getPreviewList,
  getPreviewIncidentList,
  getCurrentPreviewTrainingData,
  getPreviewSummaryList,
  updatePreviewIncidentStatus,
  getCurrentPreviewMetricsData,
  getCurrentPreviewSliceValue,
  startPollTrainingStatus,
  startPollPreviewStatus,
  resetRuleTrainingInfo,
  resetRulePreviewInfo,
  resetCurrentPreviewMetricsData,
} from "../../actions/rule/rule-action";
import { getScheduleList } from "../../actions/schedule/schedule-action";
import { getKpiList } from "../../actions/kpi/kpi-action";
import { getIntegrationList } from "../../actions/integration/integration-action";
import EditRuleWizard from "./edit-rule";

const mapStateToProps = (state) => ({
  dataSourceList: state.dataSourceReducer.dataSourceList.data,
  kpiList: state.kpiReducer.kpiList.data,
  integrationList: state.integrationReducer.integrationList.data,
  scheduleList: state.scheduleReducer.scheduleList.data,
  currentEditRule: state.ruleReducer.currentEditRule,
  currentDimensionList: state.ruleReducer.currentDimensionList,
  currentDeleteRule: state.ruleReducer.currentDeleteRule,
  currentSaveRule: state.ruleReducer.currentSaveRule,
  nonSliceRuleTrainingData: state.ruleReducer.nonSliceRuleTrainingData,
  sliceRuleTrainingData: state.ruleReducer.sliceRuleTrainingData,
  previewList: state.ruleReducer.previewList,
  previewIncidentList: state.ruleReducer.previewIncidentList,
  currentPreviewTrainingData: state.ruleReducer.currentPreviewTrainingData,
  currentPreviewSummaryData: state.ruleReducer.currentPreviewSummaryData,
  currentPreviewMetricsData: state.ruleReducer.currentPreviewMetricsData,
  currentPreviewSliceValue: state.ruleReducer.currentPreviewSliceValue,
  currentRuleStatus: state.ruleReducer.currentRuleStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getKpiList: (workspaceUuid) => dispatch(getKpiList(workspaceUuid)),
  getScheduleList: (workspaceUuid) => dispatch(getScheduleList(workspaceUuid)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  getRule: (workspaceUuid, uuid) => dispatch(getRule(workspaceUuid, uuid)),
  editRule: (workspaceUuid, uuid, rule) =>
    dispatch(editRule(workspaceUuid, uuid, rule)),
  deleteRule: (workspaceUuid, uuid) => dispatch(deleteRule(workspaceUuid, uuid)),
  getCurrentRuleStatus: (workspaceUuid, uuid) =>
    dispatch(getCurrentRuleStatus(workspaceUuid, uuid)),
  addPreview: (workspaceUuid, newPreview) =>
    dispatch(addPreview(workspaceUuid, newPreview)),
  deletePreview: (workspaceUuid, currentPreview) =>
    dispatch(deletePreview(workspaceUuid, currentPreview)),
  resetCurrentEditRuleStatus: (option) => dispatch(resetCurrentEditRuleStatus(option)),
  getMetricsData: (workspaceUuid, queryObject) =>
    dispatch(getMetricsData(workspaceUuid, queryObject)),
  getPreviewList: (workspaceUuid, queryObject) =>
    dispatch(getPreviewList(workspaceUuid, queryObject)),
  getPreviewIncidentList: (workspaceUuid, queryObject) =>
    dispatch(getPreviewIncidentList(workspaceUuid, queryObject)),
  getCurrentPreviewTrainingData: (workspaceUuid, queryObject) =>
    dispatch(getCurrentPreviewTrainingData(workspaceUuid, queryObject)),
  getPreviewSummaryList: (workspaceUuid, queryObject) =>
    dispatch(getPreviewSummaryList(workspaceUuid, queryObject)),
  getCurrentPreviewMetricsData: (workspaceUuid, queryObject) =>
    dispatch(getCurrentPreviewMetricsData(workspaceUuid, queryObject)),
  getCurrentPreviewSliceValue: (workspaceUuid, queryObject) =>
    dispatch(getCurrentPreviewSliceValue(workspaceUuid, queryObject)),
  updatePreviewIncidentStatus: (workspaceUuid, uuid, status) =>
    dispatch(updatePreviewIncidentStatus(workspaceUuid, uuid, status)),
  startPollTrainingStatus: (workspaceUuid, pollManager, ruleId) =>
    dispatch(startPollTrainingStatus(workspaceUuid, pollManager, ruleId)),
  startPollPreviewStatus: (workspaceUuid, pollManager, options) =>
    dispatch(startPollPreviewStatus(workspaceUuid, pollManager, options)),
  resetRuleTrainingInfo: () => dispatch(resetRuleTrainingInfo()),
  resetRulePreviewInfo: () => dispatch(resetRulePreviewInfo()),
  resetCurrentPreviewMetricsData: () => dispatch(resetCurrentPreviewMetricsData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRuleWizard);
