export const DIFF_STATES = {
  SAME: "same",
  ADDED: "added",
  DELETED: "deleted",
  UNKNOWN: "unknown",
};

const VALID_EXPECTED_CHANGES = [DIFF_STATES.ADDED, DIFF_STATES.DELETED];

function isObject(obj) {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

function deepEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (Array.isArray(objA) && Array.isArray(objB)) {
    return arrayDiff(objA, objB);
  }

  if (!isObject(objA) || !isObject(objB)) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    return false;
  }

  return keysA.every((key) => deepEqual(objA[key], objB[key]));
}

function arrayDiff(arrA, arrB) {
  if (arrA === arrB) return true;
  if (arrA.length !== arrB.length) return false;
  return arrA.every((item, index) => deepEqual(item, arrB[index]));
}

export function sideBySide(leftObj, rightObj) {
  if (isObject(leftObj) && isObject(rightObj)) {
    const [left, right] = [{}, {}];
    const keysA = Object.keys(leftObj);
    const keysB = Object.keys(rightObj);

    // removed keys
    keysA
      .filter((key) => !keysB.includes(key))
      .forEach((key) => (left[key] = DIFF_STATES.DELETED));

    // added keys
    keysB
      .filter((key) => !keysA.includes(key))
      .forEach((key) => (right[key] = DIFF_STATES.ADDED));

    // shared keys
    keysA
      .filter((key) => keysB.includes(key))
      .forEach((key) => {
        const nodeDiff = sideBySide(leftObj[key], rightObj[key]);
        left[key] = nodeDiff.left;
        right[key] = nodeDiff.right;
      });

    return { left, right };
  }

  if (Array.isArray(leftObj) && Array.isArray(rightObj)) {
    return arrayDiff(leftObj, rightObj)
      ? { left: DIFF_STATES.SAME, right: DIFF_STATES.SAME }
      : { left: DIFF_STATES.DELETED, right: DIFF_STATES.ADDED };
  }

  return leftObj === rightObj
    ? { left: DIFF_STATES.SAME, right: DIFF_STATES.SAME }
    : { left: DIFF_STATES.DELETED, right: DIFF_STATES.ADDED };
}

/**
 * Extracts the diff state at the specified route.
 *
 * @param {object|string} currentDiff - The current diff state.
 * @param {string} route - The route to extract the diff state from.
 * @param {object} [opts={}] - Options for the extraction process.
 * @param {boolean} [opts.takeLast=false] - Whether to return the last state if the route is not found.
 * @param {string} [opts.expectChange] - The value to return if the diff state is the same, it should be either DIFF_STATES.ADDED or DIFF_STATES.DELETED.
 * @return {object|string|null} The diff state at the specified route, or null if the current diff state is null.
 */
export function extractDiffState(currentDiff, route, opts = {}) {
  if (!currentDiff) {
    return null;
  }

  if (!route) {
    return currentDiff;
  }

  let lastState = isObject(currentDiff) ? DIFF_STATES.UNKNOWN : currentDiff;

  for (const key of route.split(".")) {
    if (isObject(currentDiff) && key in currentDiff) {
      currentDiff = currentDiff[key];
      opts.takeLast && (lastState = currentDiff);
    } else {
      return opts.takeLast ? lastState : DIFF_STATES.UNKNOWN;
    }
  }

  if (
    VALID_EXPECTED_CHANGES.includes(opts.expectChange) &&
    currentDiff === DIFF_STATES.SAME
  ) {
    return opts.expectChange;
  }

  return currentDiff;
}

/**
 * Patches two objects with default values from a patch dictionary.
 *
 * This function is used to patch two objects with default values from a patch
 * dictionary. The patch dictionary is a dictionary with default values that will
 * be used if the key is not present in the object but present in the other.
 *
 * @param {object} objectA - The first object to patch.
 * @param {object} objectB - The second object to patch.
 * @param {object} patchDictDefaults - The patch dictionary with default values.
 * @return {array} An array containing the patched objects.
 */
export function sideObjectPatch(objectA, objectB, patchDictDefaults) {
  if (objectA === objectB) {
    return [objectA, objectB];
  }
  const patchedObjectA = { ...objectA };
  const patchedObjectB = { ...objectB };
  for (const [key, value] of Object.entries(patchDictDefaults)) {
    const keyExistA = key in patchedObjectA;
    const keyExistB = key in patchedObjectB;
    if (!keyExistA && !keyExistB) {
      continue;
    }
    if (!keyExistA) {
      patchedObjectA[key] = value;
    }
    if (!keyExistB) {
      patchedObjectB[key] = value;
    }
  }
  return [patchedObjectA, patchedObjectB];
}

export function combineDiffStates(diffStateA, diffStateB) {
  if (diffStateA === diffStateB) {
    return diffStateA;
  }
  const expectedStates = [DIFF_STATES.DELETED, DIFF_STATES.ADDED];
  if (expectedStates.includes(diffStateA)) {
    return diffStateA;
  }
  if (expectedStates.includes(diffStateB)) {
    return diffStateB;
  }
  return DIFF_STATES.UNKNOWN;
}
