import React from "react";
import { NextGenPalette } from "../../utils/color";

export function HeaderTableIcon(_props) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.61929 2.11929 1.5 3.5 1.5H20.5C21.8807 1.5 23 2.6193 23 4V8.99905C23 8.9997 23 9.00034 23 9.00098V15.7396C23 15.7402 23 15.7408 23 15.7415V21C23 22.3807 21.8807 23.5 20.5 23.5H3.5C2.1193 23.5 1 22.3807 1 21V15.7596C1 15.7594 1 15.7598 1 15.7596V9.01916C1 9.01897 1 9.01935 1 9.01916V4ZM3 10.018V14.7584L7.5 14.7542V10.0137L3 10.018ZM7.5 8.0137L3 8.01795V4C3 3.72386 3.22386 3.5 3.5 3.5H7.5V8.0137ZM9.5 10.0118V14.7523L14.5 14.7476V10.0071L9.5 10.0118ZM14.5 8.00709L9.5 8.01181V3.5H14.5V8.00709ZM16.5 10.0052V14.7457L21 14.7414V10.0009L16.5 10.0052ZM21 8.00094L16.5 8.0052V3.5H20.5C20.7762 3.5 21 3.72385 21 4V8.00094ZM21 16.7414L16.5 16.7457V21.5H20.5C20.7762 21.5 21 21.2762 21 21V16.7414ZM14.5 21.5V16.7476L9.5 16.7523V21.5H14.5ZM7.5 21.5V16.7542L3 16.7584V21C3 21.2762 3.22385 21.5 3.5 21.5H7.5Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}

export function QueryHistoryIcon(_props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g opacity="0.85">
        <path
          d="M2.5 5.625C2.5 3.89911 3.89911 2.5 5.625 2.5H24.375C26.1009 2.5 27.5 3.89913 27.5 5.625V15C27.5 15.6904 26.9404 16.25 26.25 16.25C25.5596 16.25 25 15.6904 25 15V5.625C25 5.27981 24.7202 5 24.375 5H5.625C5.27982 5 5 5.27982 5 5.625V24.375C5 24.7202 5.27981 25 5.625 25H15C15.6904 25 16.25 25.5596 16.25 26.25C16.25 26.9404 15.6904 27.5 15 27.5H5.625C3.89913 27.5 2.5 26.1009 2.5 24.375V5.625Z"
          fill="#121224"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 20C15 17.2386 17.2386 15 20 15C22.7614 15 25 17.2386 25 20C25 20.7301 24.8435 21.4237 24.5622 22.049L27.0309 24.024C27.5699 24.4552 27.6573 25.2418 27.2261 25.7809C26.7948 26.32 26.0082 26.4074 25.4691 25.9761L22.9997 24.0006C22.1641 24.6281 21.1255 25 20 25C17.2386 25 15 22.7614 15 20Z"
          fill="#121224"
        />
        <path
          d="M7.5 10C7.5 9.30964 8.05964 8.75 8.75 8.75H21.25C21.9404 8.75 22.5 9.30964 22.5 10C22.5 10.6904 21.9404 11.25 21.25 11.25H8.75C8.05964 11.25 7.5 10.6904 7.5 10Z"
          fill="#121224"
        />
        <path
          d="M8.75 13.75C8.05964 13.75 7.5 14.3096 7.5 15C7.5 15.6904 8.05964 16.25 8.75 16.25H13.75C14.4404 16.25 15 15.6904 15 15C15 14.3096 14.4404 13.75 13.75 13.75H8.75Z"
          fill="#121224"
        />
      </g>
    </svg>
  );
}

export function MetricBasicSvgIcon(props = {}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M9 12C9 11.4477 9.44771 11 10 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H10C9.44771 13 9 12.5523 9 12Z" />
      <path d="M10 15C9.44771 15 9 15.4477 9 16C9 16.5523 9.44771 17 10 17H18C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15H10Z" />
      <path d="M5 12C5 11.4477 5.44772 11 6 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H6C5.44772 13 5 12.5523 5 12Z" />
      <path d="M6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H7C7.55228 17 8 16.5523 8 16C8 15.4477 7.55228 15 7 15H6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4.5C1 3.11929 2.11929 2 3.5 2H20.5C21.8807 2 23 3.11929 23 4.5V19.5C23 20.8807 21.8807 22 20.5 22H3.5C2.11929 22 1 20.8807 1 19.5V4.5ZM3 4.5C3 4.22386 3.22386 4 3.5 4H20.5C20.7761 4 21 4.22386 21 4.5V6H3V4.5ZM21 8V19.5C21 19.7761 20.7761 20 20.5 20H3.5C3.22386 20 3 19.7761 3 19.5V8H21Z"
      />
    </svg>
  );
}

export function MetricDataAssetSvgIcon(props = {}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.5C1 2.94772 1.44772 2.5 2 2.5H22C22.5523 2.5 23 2.94772 23 3.5C23 4.05228 22.5523 4.5 22 4.5H2C1.44772 4.5 1 4.05228 1 3.5ZM10.5 12.75C10.5 9.8505 12.8505 7.49999 15.75 7.49999C18.6495 7.49999 21 9.8505 21 12.75C21 13.968 20.5852 15.0891 19.8892 15.9799L22.7096 18.8207C23.0988 19.2126 23.0965 19.8458 22.7046 20.2349C22.3126 20.624 21.6795 20.6217 21.2904 20.2298L18.3777 17.2961C17.6049 17.7437 16.7073 18 15.75 18C12.8505 18 10.5 15.6495 10.5 12.75ZM15.75 9.49999C13.9551 9.49999 12.5 10.9551 12.5 12.75C12.5 14.5449 13.9551 16 15.75 16C17.5449 16 19 14.5449 19 12.75C19 10.9551 17.5449 9.49999 15.75 9.49999ZM1 11.5C1 10.9477 1.44772 10.5 2 10.5H7.5C8.05228 10.5 8.5 10.9477 8.5 11.5C8.5 12.0523 8.05228 12.5 7.5 12.5H2C1.44772 12.5 1 12.0523 1 11.5ZM1 19.5C1 18.9477 1.44772 18.5 2 18.5H7.5C8.05228 18.5 8.5 18.9477 8.5 19.5C8.5 20.0523 8.05228 20.5 7.5 20.5H2C1.44772 20.5 1 20.0523 1 19.5Z"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export function MetricPreviewSvgIcon(props = {}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0044 2.49994C12.5567 2.49994 13.0044 2.94765 13.0044 3.49994V5.5507C14.1376 5.66523 15.2103 5.96789 16.1919 6.39973L16.9997 5.27261C17.3214 4.82371 17.9461 4.72062 18.395 5.04235C18.8439 5.36407 18.947 5.98879 18.6253 6.43769L17.956 7.3716C18.3311 7.62366 18.6848 7.89342 19.0145 8.17586C20.7522 9.66439 22 11.667 22 13.4999C22 15.3329 20.7522 17.3355 19.0145 18.824C17.2376 20.3461 14.7639 21.4999 12 21.4999C9.23586 21.4999 6.76222 20.3453 4.98539 18.8229C3.24791 17.3341 2 15.3316 2 13.4999C2 11.6683 3.24791 9.66575 4.98539 8.17703C5.43399 7.79267 5.927 7.43175 6.45788 7.10693L5.81904 6.21552C5.49732 5.76662 5.60042 5.1419 6.04933 4.82018C6.49823 4.49847 7.12295 4.60157 7.44466 5.05048L8.274 6.20768C9.12845 5.87727 10.0448 5.64604 11.0044 5.54984V3.49994C11.0044 2.94765 11.4521 2.49994 12.0044 2.49994ZM12 7.49995C9.79359 7.49995 7.76723 8.42724 6.28669 9.69579C4.76682 10.998 4 12.4955 4 13.4999C4 14.5044 4.76682 16.0018 6.28669 17.3041C7.76723 18.5727 9.79359 19.4999 12 19.4999C14.2066 19.4999 16.233 18.5732 17.7134 17.3051C19.2331 16.0034 20 14.5059 20 13.4999C20 12.4939 19.233 10.9965 17.7134 9.69479C16.233 8.42666 14.2066 7.49995 12 7.49995ZM8 13.4999C8 11.2908 9.79087 9.49995 12 9.49995C14.2091 9.49995 16 11.2908 16 13.4999C16 15.7091 14.2091 17.4999 12 17.4999C9.79087 17.4999 8 15.7091 8 13.4999ZM12 11.4999C10.8954 11.4999 10 12.3954 10 13.4999C10 14.6045 10.8954 15.4999 12 15.4999C13.1046 15.4999 14 14.6045 14 13.4999C14 12.3954 13.1046 11.4999 12 11.4999Z"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export function MetricRelatedMetricsIcon(props = {}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.07142 6.07142C6.51775 3.19864 9.00213 1 12 1C14.9979 1 17.4823 3.19865 17.9286 6.07142C20.8013 6.51775 23 9.00213 23 12C23 14.9979 20.8013 17.4823 17.9286 17.9286C17.4822 20.8013 14.9979 23 12 23C9.00213 23 6.51775 20.8013 6.07142 17.9286C3.19865 17.4823 1 14.9979 1 12C1 9.00213 3.19864 6.51775 6.07142 6.07142ZM6.60504 8.01925C4.5813 8.21761 3 9.92416 3 12C3 14.0758 4.5813 15.7824 6.60504 15.9807L10.5858 12L6.60504 8.01925ZM12 13.4142L8.01925 17.395C8.21761 19.4187 9.92416 21 12 21C14.0758 21 15.7824 19.4187 15.9807 17.395L12 13.4142ZM17.395 15.9807L13.4142 12L17.395 8.01925C19.4187 8.21761 21 9.92416 21 12C21 14.0758 19.4187 15.7824 17.395 15.9807ZM15.9807 6.60504L12 10.5858L8.01925 6.60504C8.21761 4.5813 9.92416 3 12 3C14.0758 3 15.7824 4.5813 15.9807 6.60504Z"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export function MetricDraftInfoIcon(props = {}) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56641 2C5.46641 2 4.56641 2.9 4.56641 4V20C4.56641 21.1 5.46641 22 6.56641 22H17.7383L15.7383 20H6.56641V4H13.5664V9H18.5664V13.1719L20.5664 15.1719V8L14.5664 2H6.56641ZM15.5664 15V17L20.7129 22.1465L22.7129 20.1465L17.5664 15H15.5664ZM23.4199 20.8535L21.4199 22.8535L22.4199 23.8535C22.6149 24.0485 22.932 24.0485 23.127 23.8535L24.4199 22.5605C24.6149 22.3645 24.6149 22.0485 24.4199 21.8535L23.4199 20.8535Z"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export function MetricDraftTrashIcon(props = {}) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M7.91699 13.75C7.45676 13.75 7.08366 14.1231 7.08366 14.5833C7.08366 15.0436 7.45676 15.4167 7.91699 15.4167H12.0837C12.5439 15.4167 12.917 15.0436 12.917 14.5833C12.917 14.1231 12.5439 13.75 12.0837 13.75H7.91699Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75033 1.25C6.34442 1.25 5.99756 1.54246 5.92897 1.94253L5.54769 4.16667H2.50033C2.04009 4.16667 1.66699 4.53976 1.66699 5C1.66699 5.46024 2.04009 5.83333 2.50033 5.83333H3.80236L4.58539 17.9703C4.61368 18.4088 4.97758 18.75 5.41699 18.75H14.5837C15.0231 18.75 15.387 18.4088 15.4153 17.9703L16.1983 5.83333H17.5003C17.9606 5.83333 18.3337 5.46024 18.3337 5C18.3337 4.53976 17.9606 4.16667 17.5003 4.16667H14.453L14.0717 1.94253C14.0031 1.54246 13.6562 1.25 13.2503 1.25H6.75033ZM12.762 4.16667H7.23867L7.45296 2.91667H12.5477L12.762 4.16667ZM6.19829 17.0833L5.47249 5.83333H14.5282L13.8024 17.0833H6.19829Z"
      />
    </svg>
  );
}

export function MetricDraftAlertIcon(props = {}) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7218 2.99964L18.6602 16.7496C18.7333 16.8763 18.7718 17.02 18.7718 17.1663C18.7718 17.3126 18.7333 17.4563 18.6602 17.583C18.587 17.7096 18.4818 17.8148 18.3552 17.888C18.2285 17.9611 18.0848 17.9996 17.9385 17.9996H2.06183C1.91555 17.9996 1.77185 17.9611 1.64517 17.888C1.51849 17.8148 1.41329 17.7096 1.34016 17.583C1.26702 17.4563 1.22851 17.3126 1.22852 17.1663C1.22852 17.02 1.26702 16.8763 1.34016 16.7496L9.27849 2.99964C9.35164 2.87296 9.45683 2.76778 9.58351 2.69464C9.71019 2.62151 9.85389 2.58301 10.0002 2.58301C10.1464 2.58301 10.2901 2.62151 10.4168 2.69464C10.5435 2.76778 10.6487 2.87296 10.7218 2.99964ZM3.50516 16.333H16.4952L10.0002 5.08297L3.50516 16.333ZM9.16683 13.833H10.8335V15.4996H9.16683V13.833ZM9.16683 7.99964H10.8335V12.1663H9.16683V7.99964Z"
      />
    </svg>
  );
}
