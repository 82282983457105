import React, { useState } from "react";
import LegacyModalDialog from "../../legacy-adapter/legacy-modal-dialog";
import Table from "../../table/table";
import { isCurrentTimestampAnIncident } from "../../../utils/general";

import "./data-distribution-full-metrics-dialog.scss";

function DataDistributionFullMetricsDialogBodyComponent(props) {
  let { data = [], incidentCategories = [] } = props;

  const tableData = [];
  for (let { category, lowerBoundValue, upperBoundValue, signalValue } of data) {
    const expectedRange =
      typeof lowerBoundValue === "number" && typeof upperBoundValue === "number"
        ? `${lowerBoundValue}% - ${upperBoundValue}%`
        : "-";

    tableData.push({
      category,
      value: `${signalValue}%`,
      expectedRange,
    });
  }

  const columns = [
    {
      header: "Category name",
      key: "category",
      render: function (category, index, data) {
        if (!incidentCategories.includes(category)) {
          return category;
        }

        return (
          <div className="incident-category-column-container lightup-horizon-flex-container">
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M14.3359 10.8359L8.71094 1.0625C8.28906 0.335938 7.1875 0.3125 6.76562 1.0625L1.14062 10.8359C0.71875 11.5625 1.25781 12.5 2.125 12.5H13.3516C14.2188 12.5 14.7578 11.5859 14.3359 10.8359ZM7.75 8.79688C8.33594 8.79688 8.82812 9.28906 8.82812 9.875C8.82812 10.4844 8.33594 10.9531 7.75 10.9531C7.14062 10.9531 6.67188 10.4844 6.67188 9.875C6.67188 9.28906 7.14062 8.79688 7.75 8.79688ZM6.71875 4.92969C6.69531 4.76562 6.83594 4.625 7 4.625H8.47656C8.64062 4.625 8.78125 4.76562 8.75781 4.92969L8.59375 8.11719C8.57031 8.28125 8.45312 8.375 8.3125 8.375H7.16406C7.02344 8.375 6.90625 8.28125 6.88281 8.11719L6.71875 4.92969Z"
                fill="#FAB800"
              />
            </svg>
            {category}
          </div>
        );
      },
    },
    {
      header: "Value",
      key: "value",
    },
    {
      header: "Expected Range",
      key: "expectedRange",
    },
  ];

  return (
    <div className="data-distribution-full-metrics-dialog-body-container">
      <div className="data-distribution-full-metrics-dialog-body-indicator-container">
        Dominant categories are categories spanning the 90th percentile of
        distributions.
      </div>
      <div className="data-distribution-full-metrics-dialog-body-total-number-container lightup-horizon-flex-container">
        <div>Dominant categories : {data.length}</div>
        {incidentCategories.length > 0 && (
          <div className="incident-total-number-container">
            Categories with incidents : {incidentCategories.length}
          </div>
        )}
      </div>
      <div className="data-distribution-full-metrics-dialog-body-table-container">
        <Table
          data={tableData}
          columns={columns}
          style={{}}
          iconStyle={{}}
          rowConfig={{}}
        />
      </div>
    </div>
  );
}

function DataDistributionFullMetricsDialogFooterComponent(props) {
  const { onOKClicked } = props;

  return (
    <button className="ok-button" onClick={onOKClicked}>
      OK
    </button>
  );
}

function DataDistributionFullMetricsDialog(props) {
  let { data = null, incidents = [], showStatsData } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!data) {
    return null;
  }

  const { timestamp, value } = data;
  if (!data.value || data.value.length < 20) {
    return null;
  }

  function closeModal() {
    setIsDialogOpen(false);
  }

  function onOKClicked() {
    closeModal();
  }

  let incidentCategories = [];
  const currentIncident = isCurrentTimestampAnIncident(incidents, timestamp);
  if (currentIncident) {
    try {
      incidentCategories = JSON.parse(currentIncident.reason)["slice_values"];
    } catch (err) {
      console.log(
        `Fail to parse incident reason with ${JSON.stringify(currentIncident)}`
      );
    }
  }

  return (
    <LegacyModalDialog
      title={`Dominant Categories`}
      modalIsOpen={isDialogOpen}
      setIsOpen={setIsDialogOpen}
      containerClassName={"data-distribution-full-metrics-dialog-container"}
      triggerComponent={
        <div className="data-distribution-full-metrics-dialog-trigger-container">
          Showing 20/{value.length}
        </div>
      }
      contentComponent={
        <DataDistributionFullMetricsDialogBodyComponent
          data={value}
          incidentCategories={incidentCategories}
          showStatsData={showStatsData}
        />
      }
      footerComponent={
        <DataDistributionFullMetricsDialogFooterComponent onOKClicked={onOKClicked} />
      }
    />
  );
}

export default DataDistributionFullMetricsDialog;
