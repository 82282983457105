import React from "react";
import { TableType } from "../../utils/enums";
import { NextGenPalette } from "../../utils/color";

function RegularKPIIcon(props) {
  const {
    size = 14,
    viewBox = "0 0 14 14",
    fillColor = NextGenPalette.systemBlack,
  } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M7.97526 9.91683H8.77506C8.89811 9.91683 9.04167 9.78011 9.04167 9.64339V6.56722C9.04167 6.4305 8.89811 6.271 8.77506 6.271H7.97526C7.85221 6.271 7.72917 6.4305 7.72917 6.56722V9.64339C7.72917 9.78011 7.85221 9.91683 7.97526 9.91683ZM9.94401 9.91683H10.7438C10.8669 9.91683 11.0104 9.78011 11.0104 9.64339V4.37972C11.0104 4.243 10.8669 4.0835 10.7438 4.0835H9.94401C9.82096 4.0835 9.69792 4.243 9.69792 4.37972V9.64339C9.69792 9.78011 9.82096 9.91683 9.94401 9.91683ZM4.03776 9.91683H4.83756C4.96061 9.91683 5.10417 9.78011 5.10417 9.64339V8.02555C5.10417 7.88883 4.96061 7.72933 4.83756 7.72933H4.03776C3.91471 7.72933 3.79167 7.88883 3.79167 8.02555V9.64339C3.79167 9.78011 3.91471 9.91683 4.03776 9.91683ZM6.00651 9.91683H6.80631C6.92936 9.91683 7.07292 9.78011 7.07292 9.64339V5.10889C7.07292 4.97217 6.92936 4.81266 6.80631 4.81266H6.00651C5.88346 4.81266 5.76042 4.97217 5.76042 5.10889V9.64339C5.76042 9.78011 5.88346 9.91683 6.00651 9.91683ZM11.9219 10.7918H2.47917V2.69808C2.47917 2.51579 2.3151 2.3335 2.15104 2.3335H2.07812C1.89355 2.3335 1.75 2.51579 1.75 2.69808V10.9377C1.75 11.3478 2.03711 11.6668 2.40625 11.6668H11.9219C12.0859 11.6668 12.25 11.5073 12.25 11.3022V11.1564C12.25 10.9741 12.0859 10.7918 11.9219 10.7918Z"
        fill={fillColor}
      />
    </svg>
  );
}

function CustomSqlIcon(props) {
  const {
    size = 14,
    fillColor = NextGenPalette.systemBlack,
    viewBox = "0 0 14 14",
  } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M6.57354 11.6576C6.69467 11.6918 6.7985 11.6234 6.83311 11.5209L9.20378 3.48931C9.23839 3.3697 9.16917 3.25008 9.06535 3.23299L8.00979 2.92539C7.88866 2.89122 7.78484 2.95957 7.75023 3.0621L5.37955 11.0937C5.34495 11.2133 5.41416 11.3158 5.51799 11.35L6.57354 11.6576ZM4.60087 9.7437L5.36225 8.94054C5.44877 8.8551 5.43147 8.73548 5.34495 8.65004L3.78757 7.28296L5.34495 5.93297C5.43147 5.84753 5.44877 5.71082 5.36225 5.64246L4.60087 4.83931C4.53165 4.75386 4.39322 4.75386 4.30669 4.83931L1.81489 7.14625C1.72837 7.21461 1.72837 7.35131 1.81489 7.43676L4.30669 9.7437C4.39322 9.82914 4.53165 9.82914 4.60087 9.7437ZM10.2766 9.7437L12.7684 7.43676C12.855 7.3684 12.855 7.21461 12.7684 7.14625L10.2766 4.83931C10.1901 4.75386 10.0517 4.75386 9.98247 4.83931L9.22108 5.62538C9.15187 5.71082 9.15187 5.84753 9.23839 5.93297L10.8131 7.28296L9.23839 8.65004C9.15187 8.73548 9.13456 8.8551 9.22108 8.94054L9.98247 9.7437C10.0517 9.82914 10.1901 9.82914 10.2766 9.7437Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function getIconFromMetric(metric) {
  if (!metric || metric.config.table !== TableType.CUSTOM_SQL) {
    return RegularKPIIcon;
  }

  return CustomSqlIcon;
}
