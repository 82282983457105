import React, { Component } from "react";
import { withRouter } from "react-router";
import MetricWizard from "../../components/metric/";
import { DraftType, MetricConfigType, MetricMode } from "../../utils/enums";
import { getURIInstance, URIPath } from "../../utils/uri-path";
import { goBackOrToDefault } from "../../utils/router";
import { getApprovalConfig } from "../../utils/approvals";

class EditKPIWizard extends Component {
  constructor(props) {
    super(props);

    this.title = "Edit metric";
    this.state = {
      workspaceUuid: "",
      uuid: "",
      currentEditKpi: {},
      approvalsConfig: false,
    };

    this.requestVersion = 1;
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDataSourceChanged = this.onDataSourceChanged.bind(this);
    this.onSrcDataSourceChanged = this.onSrcDataSourceChanged.bind(this);
    this.onTableChanged = this.onTableChanged.bind(this);
    this.onSrcTableChanged = this.onSrcTableChanged.bind(this);
    this.onInColumnTableChanged = this.onInColumnTableChanged.bind(this);
    this.onTableColumnChanged = this.onTableColumnChanged.bind(this);
    this.getCurrentKpiPartitionSampleData =
      this.getCurrentKpiPartitionSampleData.bind(this);
    this.getCurrentKpiSrcPartitionSampleData =
      this.getCurrentKpiSrcPartitionSampleData.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: {
        params: { workspaceUuid, id },
      },
    } = props;
    let isChanged = false;
    if (workspaceUuid !== state.workspaceUuid) {
      props.getDataSourceList(workspaceUuid);
      const currentWorkspace = props.workspaceList?.find(
        ({ uuid }) => uuid === workspaceUuid
      );

      const approvalsConfig = getApprovalConfig(
        currentWorkspace,
        props.workspaceUserPermissions
      );

      state = {
        ...state,
        workspaceUuid,
        approvalsConfig,
      };
      props.getTagList(workspaceUuid);
      props.getRuleList(workspaceUuid);
      props.getIntegrationList(workspaceUuid);
      isChanged = true;
    }

    if (id !== state.uuid) {
      props.getKpi(workspaceUuid, id);
      state = {
        ...state,
        uuid: id,
      };
      isChanged = true;
    }

    if (
      props.currentEditKpi !== state.currentEditKpi &&
      props.currentEditKpi &&
      props.currentEditKpi.config &&
      props.currentEditKpi.config.configType ===
        MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG
    ) {
      props.getKpiList(workspaceUuid, { isForceRefresh: true });
      state = {
        ...state,
        currentEditKpi: props.currentEditKpi,
      };
      isChanged = true;
    }

    if (isChanged) {
      return state;
    }

    return null;
  }

  exitRedirect(forceBackTo = null) {
    const {
      history,
      match: {
        params: { workspaceUuid },
      },
      currentEditKpi,
    } = this.props;

    const isDraft = currentEditKpi?.mode === MetricMode.DRAFT;
    const defaultRedirectPath = isDraft
      ? URIPath.NG_DRAFT_LIST
      : URIPath.NG_METRIC_LIST;

    setTimeout(() => {
      if (typeof forceBackTo === "string") {
        history.replace(forceBackTo);
      } else {
        goBackOrToDefault(
          history,
          getURIInstance(defaultRedirectPath, { workspaceUuid })
        );
      }
    }, 0);
  }

  onCancel() {
    this.exitRedirect();
  }

  onSave(workspaceUuid, uuid, newKPI, _, config = {}) {
    const { isForce = false, isSame = false, isDraft = false } = config;
    const savePromise = isSame
      ? Promise.resolve(newKPI)
      : this.props.editKpi(workspaceUuid, uuid, newKPI, isForce);
    const forceBackUrl = isDraft
      ? getURIInstance(URIPath.NG_DRAFT_LIST, { workspaceUuid })
      : null;

    return savePromise.then((response) => {
      if (response) {
        this.exitRedirect(forceBackUrl);
      }
    });
  }

  onCreateDraft(workspaceUuid, newKPI, newRuleList) {
    return this.props.addKpi(workspaceUuid, newKPI, newRuleList).then((responseKpi) => {
      if (responseKpi) {
        setTimeout(() => {
          this.exitRedirect(getURIInstance(URIPath.NG_DRAFT_LIST, { workspaceUuid }));
        }, 0);
      }
    });
  }

  onDataSourceChanged(workspaceUuid, dataSource) {
    this.props.resetCurrentCustomSqlKpiTableColumnList();
    this.props.getCurrentKpiSchemaList(workspaceUuid, dataSource);
    this.props.getCurrentKpiTableList(workspaceUuid, dataSource);
  }

  onSrcDataSourceChanged(workspaceUuid, dataSource) {
    this.props.getCurrentKpiSchemaList(workspaceUuid, dataSource, true);
    this.props.getCurrentKpiSrcTableList(workspaceUuid, dataSource);
  }

  onSchemaChanged(_workspaceUuid, _dataSource, _schemaName) {
    this.props.resetCurrentCustomSqlKpiTableColumnList();
  }

  onTableChanged(workspaceUuid, dataSourceUuid, tableUuid) {
    this.props.getCurrentKpiTableColumnList(workspaceUuid, dataSourceUuid, tableUuid);
  }

  onSrcTableChanged(workspaceUuid, dataSourceUuid, tableUuid) {
    this.props.getCurrentKpiSrcTableColumnList(
      workspaceUuid,
      dataSourceUuid,
      tableUuid
    );
  }

  onInColumnTableChanged(workspaceUuid, dataSourceUuid, tableUuid) {
    this.props.getCurrentKpiInColumnColumnList(
      workspaceUuid,
      dataSourceUuid,
      tableUuid
    );
  }

  onCustomSqlTableChanged(workspaceUuid, dataSource, tableUuid) {
    this.props.resetCurrentCustomSqlKpiTableColumnList();
    if (tableUuid) {
      this.props.getCurrentCustomSqlKpiTableColumnList(
        workspaceUuid,
        dataSource,
        tableUuid
      );
    }
  }

  onTableColumnChanged(workspaceUuid, kpi) {
    this.props.getCurrentKpiTableColumnValueList(workspaceUuid, kpi);
  }

  getCurrentKpiPartitionSampleData(dataSourceUuid, table) {
    this.props.getCurrentKpiPartitionSampleData(
      this.props.match.params.workspaceUuid,
      dataSourceUuid,
      table
    );
  }

  getCurrentKpiSrcPartitionSampleData(dataSourceUuid, table) {
    this.props.getCurrentKpiSrcPartitionSampleData(
      this.props.match.params.workspaceUuid,
      dataSourceUuid,
      table
    );
  }

  navigateToMetric(workspaceUuid, metricUuid) {
    const { history } = this.props;
    const metricDetailUrl = getURIInstance(URIPath.EDIT_METRIC, {
      workspaceUuid,
      id: metricUuid,
    });
    history.replace(metricDetailUrl);
  }

  render() {
    const { workspaceUuid, uuid: currentUuid, approvalsConfig } = this.state;

    const defaultData = this.props.currentEditKpi ? this.props.currentEditKpi : {};
    const uuid =
      defaultData && defaultData.metadata && defaultData.metadata.uuid
        ? defaultData.metadata.uuid
        : "";
    if (!uuid || uuid !== currentUuid || this.props.dataSourceList.length === 0) {
      return null;
    }

    const isDataCompare =
      defaultData && defaultData.config.configType !== MetricConfigType.METRIC_CONFIG;

    // Only delete drafts are disabled
    const isDisabled =
      defaultData.mode === MetricMode.DRAFT &&
      defaultData.draftMetadata?.type === DraftType.DELETE;

    return (
      <MetricWizard
        title={this.title}
        waffle={this.props.waffle}
        dataSourceList={this.props.dataSourceList}
        kpiList={this.props.kpiList}
        currentSchemaList={this.props.currentSchemaList}
        currentSrcSchemaList={this.props.currentSrcSchemaList}
        currentTableList={this.props.currentTableList}
        currentSrcTableList={this.props.currentSrcTableList}
        currentColumnList={this.props.currentColumnList}
        currentSrcColumnList={this.props.currentSrcColumnList}
        currentInColumnColumnList={this.props.currentInColumnColumnList}
        currentCustomSqlColumnList={this.props.currentCustomSqlColumnList}
        currentColumnValueList={this.props.currentColumnValueList}
        currentFullCompareSrcTableColumnValueList={
          this.props.currentFullCompareSrcTableColumnValueList
        }
        currentFullCompareTargetTableColumnValueList={
          this.props.currentFullCompareTargetTableColumnValueList
        }
        currentColumnInfo={this.props.currentColumnInfo}
        lastPreviewStatus={this.props.lastPreviewStatus}
        lastPreviewResult={this.props.lastPreviewResult}
        lastPreviewTime={this.props.lastPreviewTime}
        previewKpi={this.props.previewKpi.bind(this.props, workspaceUuid)}
        terminateKpiPreview={this.props.terminateKpiPreview.bind(
          this.props,
          workspaceUuid
        )}
        verifyKpiCustomSql={this.props.verifyKpiCustomSql.bind(
          this.props,
          workspaceUuid
        )}
        currentKpiValidateFailingRecordsSqlResult={
          this.props.currentKpiValidateFailingRecordsSqlResult
        }
        validateKpiFailingRecordsSql={this.props.validateKpiFailingRecordsSql.bind(
          this.props,
          workspaceUuid
        )}
        onSave={this.onSave.bind(this, workspaceUuid, uuid)}
        resetKPIStatus={this.props.resetKPIStatus}
        onCancel={this.onCancel}
        defaultData={defaultData}
        onDataSourceChanged={this.onDataSourceChanged.bind(this, workspaceUuid)}
        onSchemaChanged={this.onSchemaChanged.bind(this, workspaceUuid)}
        onSrcDataSourceChanged={this.onSrcDataSourceChanged.bind(this, workspaceUuid)}
        onTableChanged={this.onTableChanged.bind(this, workspaceUuid)}
        onCustomSqlTableChanged={this.onCustomSqlTableChanged.bind(this, workspaceUuid)}
        onSrcTableChanged={this.onSrcTableChanged.bind(this, workspaceUuid)}
        onInColumnTableChanged={this.onInColumnTableChanged.bind(this, workspaceUuid)}
        onTableColumnChanged={this.onTableColumnChanged.bind(this, workspaceUuid)}
        getKpiList={this.props.getKpiList.bind(this, workspaceUuid)}
        currentKpiQueryList={this.props.currentKpiQueryList}
        getCurrentKpiQueryList={this.props.getCurrentKpiQueryList.bind(
          this.props,
          workspaceUuid
        )}
        currentKpiPartitionSampleData={this.props.currentKpiPartitionSampleData}
        getCurrentKpiPartitionSampleData={this.getCurrentKpiPartitionSampleData}
        currentKpiSrcPartitionSampleData={this.props.currentKpiSrcPartitionSampleData}
        getCurrentKpiSrcPartitionSampleData={this.getCurrentKpiSrcPartitionSampleData}
        isDataCompare={isDataCompare}
        getCurrentKpiSampleDataTableSchemaList={this.props.getCurrentKpiSampleDataTableSchemaList.bind(
          this.props,
          workspaceUuid
        )}
        currentKpiSampleDataTableSchemaList={
          this.props.currentKpiSampleDataTableSchemaList
        }
        getCurrentKpiSampleDataSampleDataList={this.props.getCurrentKpiSampleDataSampleDataList.bind(
          this.props,
          workspaceUuid
        )}
        currentKpiSampleDataSampleDataList={
          this.props.currentKpiSampleDataSampleDataList
        }
        isEdit={true}
        isClone={false}
        workspaceUserPermissions={this.props.workspaceUserPermissions}
        tagList={this.props.tagList}
        ruleList={this.props.ruleList}
        approvalsConfig={approvalsConfig}
        integrationList={this.props.integrationList}
        onCreateDraft={this.onCreateDraft.bind(this, workspaceUuid)}
        onDeleteDraft={this.props.deleteDraft.bind(this, workspaceUuid)}
        navigateToMetric={this.navigateToMetric.bind(this, workspaceUuid)}
        disabled={isDisabled}
      />
    );
  }
}

export default withRouter(EditKPIWizard);
