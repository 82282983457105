import React, { useState } from "react";

import "./template-panel.scss";

function LabelValueRowTemplate(props) {
  const { label, value } = props;
  return (
    <div className="info-group-row-item-content-container">
      {label ? (
        <span className="info-group-row-item-label-container">{label}</span>
      ) : null}
      <span className="info-group-row-item-value-container">{value}</span>
    </div>
  );
}

export function LabelValueGroup({ attributeItems = [] }) {
  return (
    <div className="info-group-item-content-container">
      {attributeItems.map(function (rowItem, rowIndex) {
        const { label = "", value } = rowItem;
        return <LabelValueRowTemplate label={label} value={value} key={rowIndex} />;
      })}
    </div>
  );
}

function TemplatePanel(props) {
  const { title = "template", data, defaultExpand = true } = props;

  const [expand, setExpand] = useState(defaultExpand);
  const expandSwitch = () => setExpand((expand) => !expand);

  return (
    <div className="info-panel-container">
      <div className="info-panel-title-container">
        {title}
        <span className="info-panel-collapse-icon-container" onClick={expandSwitch}>
          {expand ? (
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M8.4375 0.855469C8.12109 0.539062 7.59375 0.539062 7.27734 0.855469L0.421875 7.67578C0.105469 8.02734 0.105469 8.55469 0.421875 8.87109L1.23047 9.67969C1.54688 9.99609 2.07422 9.99609 2.42578 9.67969L7.875 4.26562L13.2891 9.67969C13.6406 9.99609 14.168 9.99609 14.4844 9.67969L15.293 8.87109C15.6094 8.55469 15.6094 8.02734 15.293 7.67578L8.4375 0.855469Z"
                fill="#525252"
              />
            </svg>
          ) : (
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M7.27734 9.67969C7.59375 9.99609 8.12109 9.99609 8.4375 9.67969L15.293 2.85938C15.6094 2.50781 15.6094 1.98047 15.293 1.66406L14.4844 0.855469C14.168 0.539062 13.6406 0.539062 13.2891 0.855469L7.875 6.26953L2.42578 0.855469C2.07422 0.539062 1.54688 0.539062 1.23047 0.855469L0.421875 1.66406C0.105469 1.98047 0.105469 2.50781 0.421875 2.85938L7.27734 9.67969Z"
                fill="#525252"
              />
            </svg>
          )}
        </span>
      </div>
      <div className="info-panel-content-container">
        {expand
          ? data.map(function (groupItem, groupIndex) {
              return (
                <div className="info-group-item-container" key={groupIndex}>
                  <div className="info-group-item-title-container">
                    {groupItem.groupName}
                  </div>
                  <div className="info-group-item-content-container">
                    {groupItem.attributeItems.map(function (rowItem, rowIndex) {
                      const { label = "", value } = rowItem;
                      return (
                        <LabelValueRowTemplate
                          label={label}
                          value={value}
                          key={rowIndex}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default TemplatePanel;
