import React, { Component } from "react";
import { DeleteOutlined, HistoryOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "../../../components/button/ng-button";
import TimeOption from "../../../components/time-option/time-option";
import QueryHistoryTable from "../../../components/query-history-table/query-history-table";
import {
  DataSourceType,
  QueryGovernanceSchedulingMode,
  TakeoverWidth,
} from "../../../utils/enums";
import { getSettingFromParams, hasPermission } from "../../../utils/uri-path";
import {
  getDisplayTimeFromSecond,
  getSecondFromDisplayTime,
  MINUTE_IN_SECONDS,
  WEEK_IN_SECONDS,
} from "../../../utils/time";
import { isEqual } from "lodash";
import { AppPermissions } from "../../../utils/permissions";
import NgStepContainer from "../../../components/step/ng-step";
import {
  LabeledSelect,
  LabeledInputNumber,
} from "../../../components/labeled-control/labeled-control";
import NgToggleCheckbox from "../../../components/toggle-checkbox/ng-toggle-checkbox";
import ButtonIcon from "../../../components/button/button-icon";
import DurationInput from "../../../components/duration-input/duration-input";
import { getUnixTime, isAfter, isBefore, startOfDay, subDays } from "date-fns";
import TimeRangeSelector from "../../../components/time-range-selector";
import moment from "moment";

import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import { deepcopy } from "../../../utils/objects";
import QueryListStats from "./query-list-stats";

import QueryGovernanceDataSource from "./query-governance-data-source";
import { Tabs } from "antd";
import SettingConfigIcon from "../../../components/icons/setting-config";
import { Spinner } from "../../../atom/spinner";
import "./query-governance.scss";

const timeSettingOptions = [
  { value: "s", label: "second" },
  { value: "m", label: "minute" },
  { value: "h", label: "hour" },
  { value: "d", label: "day" },
  { value: "w", label: "week" },
  { value: "mo", label: "month" },
];

// Datasource types for which query governance is _fully_ supported. Query scheduling is supported for all types.
const fullySupportedDataSourceTypeList = [
  DataSourceType.REDSHIFT,
  DataSourceType.SNOWFLAKE,
  DataSourceType.DATABRICKS,
  DataSourceType.POSTGRES,
  DataSourceType.MYSQL,
];

const enforcedTimestampSourceTypeToStringMapper = {
  [DataSourceType.REDSHIFT]: "sort-keyed",
  [DataSourceType.MICROSOFTSQL]: "indexed",
  [DataSourceType.ORACLE]: "indexed",
  [DataSourceType.POSTGRES]: "indexed",
  [DataSourceType.GREENPLUM]: "indexed",
  [DataSourceType.TERADATA]: "indexed",
  [DataSourceType.MYSQL]: "indexed",
};

const defaultQueryTimeoutLimit = 15 * MINUTE_IN_SECONDS;
const defaultQueryDataRangeLimit = 2 * WEEK_IN_SECONDS;

const schedulingModeOptions = [
  {
    label: "Allow",
    value: QueryGovernanceSchedulingMode.ALLOW,
  },
  {
    label: "Deny",
    value: QueryGovernanceSchedulingMode.DENY,
  },
];

function scheduleSlotsFromUuids(scheduleUuids) {
  return scheduleUuids.map((scheduleUuid, index) => ({
    scheduleUuid,
    id: index,
  }));
}

function schedulingFromDatasource(dataSourceObject) {
  let schedulingMode = QueryGovernanceSchedulingMode.ALLOW;
  let scheduleSlots = [];
  if (dataSourceObject.config.governance.allowScheduleUuids.length > 0) {
    schedulingMode = QueryGovernanceSchedulingMode.ALLOW;
    scheduleSlots = scheduleSlotsFromUuids(
      dataSourceObject.config.governance.allowScheduleUuids
    );
  } else if (dataSourceObject.config.governance.denyScheduleUuids.length > 0) {
    schedulingMode = QueryGovernanceSchedulingMode.DENY;
    scheduleSlots = scheduleSlotsFromUuids(
      dataSourceObject.config.governance.denyScheduleUuids
    );
  }
  return {
    schedulingMode,
    scheduleSlots,
    schedulingEnabled: scheduleSlots.length > 0,
  };
}

export const MAX_LOOKBACK_DAYS = 7;

export function timeRangeDefaultRange(now = new Date()) {
  return {
    startTime: subDays(now, 1),
    endTime: now,
  };
}

export function timeRangeDisabledDate(lookbackDays, now = new Date()) {
  const earliestSelectableDate = startOfDay(subDays(now, lookbackDays));
  return (momentDate) =>
    isBefore(momentDate.toDate(), earliestSelectableDate) ||
    isAfter(momentDate.toDate(), now);
}

export function timeRangeSelectorPresets(now = new Date()) {
  const nowMoment = moment(now);
  return {
    "1d": [moment(subDays(now, 1)), nowMoment.clone()],
    "1w": [moment(subDays(now, 7)), nowMoment.clone()],
  };
}

function ScheduleSlot(props) {
  const {
    slotId,
    selectedUuid,
    scheduleOptions = [],
    onDeleteClick = (_slotId) => {},
    onSelectedScheduleChange = (_slotId, _newUuid) => {},
  } = props;

  const selectedOption = scheduleOptions.find((opt) => opt.value === selectedUuid);

  return (
    <div className="query-governance-scheduling-slot">
      <div className="query-governance-scheduling-schedule-select">
        <LabeledSelect
          label="Schedule"
          staticLabel
          options={scheduleOptions}
          value={selectedOption}
          placeholder="Select a schedule"
          onChange={(scheduleUuid) => onSelectedScheduleChange(slotId, scheduleUuid)}
        />
      </div>
      <ButtonIcon icon={<DeleteOutlined />} onClick={() => onDeleteClick(slotId)} />
    </div>
  );
}

function SchedulingStep(props) {
  const {
    schedulingEnabled = false,
    allowPreviewsWhenPaused = false,
    scheduleList = [],
    schedulingMode = QueryGovernanceSchedulingMode.ALLOW,
    scheduleSlots = [],
    onSchedulingModeChange = (_modeOpt) => {},
    onScheduleSlotAdd = () => {},
    onScheduleSlotDelete = (_slotId) => {},
    onScheduleSlotChange = (_slotId, _scheduleUuid) => {},
    onAllowPreviewsWhenPaused = (_enabled) => {},
  } = props;

  const selectedSchedules = new Set(scheduleSlots.map((slot) => slot.uuid));

  const scheduleOptions = scheduleList
    .filter((schedule) => !selectedSchedules.has(schedule.id))
    .map((schedule) => ({
      label: schedule.title,
      value: schedule.id,
    }));

  const schedulingModeValue = schedulingModeOptions.find(
    (opt) => opt.value === schedulingMode
  );
  if (!schedulingEnabled) {
    return null;
  }
  return (
    <div>
      <div className="query-governance-scheduling">
        <NgToggleCheckbox
          labelPosition="left"
          label="Always allow interactive queries"
          value={allowPreviewsWhenPaused}
          onChange={onAllowPreviewsWhenPaused}
        />
        <div className="query-governance-scheduling-controls">
          <LabeledSelect
            style={{ width: 180 }}
            label="Schedule Mode"
            options={schedulingModeOptions}
            value={schedulingModeValue}
            onChange={onSchedulingModeChange}
          />
          <Button primary size="large" onClick={onScheduleSlotAdd}>
            Add
            <PlusOutlined />
          </Button>
        </div>
        {scheduleSlots.map((slot) => (
          <ScheduleSlot
            key={slot.id}
            slotId={slot.id}
            selectedUuid={slot.scheduleUuid}
            scheduleOptions={scheduleOptions}
            onSelectedScheduleChange={onScheduleSlotChange}
            onDeleteClick={onScheduleSlotDelete}
          />
        ))}
      </div>
    </div>
  );
}

class QueryGovernance extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      workspaceUuid: "",
      currentDataSourceObject: null,
      dataSourceList: {
        loading: false,
        data: [],
      },
      queryTimeRange: timeRangeDefaultRange(now),
      schedulingEnabled: false,
      schedulingMode: QueryGovernanceSchedulingMode.ALLOW,
      scheduleSlots: [],
    };

    this.getQueryList = this.getQueryList.bind(this);
    this.onDataSourceChange = this.onDataSourceChange.bind(this);
    this.onMaxQueryDurationInSecondsChange =
      this.onMaxQueryDurationInSecondsChange.bind(this);
    this.onMaxQueryPeriodInSecondsChange =
      this.onMaxQueryPeriodInSecondsChange.bind(this);
    this.onIndexedTimestampColumnsChange =
      this.onIndexedTimestampColumnsChange.bind(this);
    this.onAllowPreviewsWhenPausedChange =
      this.onAllowPreviewsWhenPausedChange.bind(this);
    this.onAllowRecordStorageChange = this.onAllowRecordStorageChange.bind(this);
    this.onAllowRawDataSamplesChange = this.onAllowRawDataSamplesChange.bind(this);
    this.onFailingRecordsCountCacheChange =
      this.onFailingRecordsCountCacheChange.bind(this);
    this.onSaveCompleteFullCompareDiffSamplesChange =
      this.onSaveCompleteFullCompareDiffSamplesChange.bind(this);
    this.getAllowDenySchedulesConfig = this.getAllowDenySchedulesConfig.bind(this);
    this.onMaxBackfillDurationChange = this.onMaxBackfillDurationChange.bind(this);
    this.onMaxCategoricalDistinctCountChange =
      this.onMaxCategoricalDistinctCountChange.bind(this);

    this.onSave = this.onSave.bind(this);
  }

  getQueryList(dataSourceUuid) {
    this.props.getDataSourceQueryList(this.state.workspaceUuid, dataSourceUuid, {
      startTs: getUnixTime(this.state.queryTimeRange.startTime),
      endTs: getUnixTime(this.state.queryTimeRange.endTime),
    });
  }

  isFormDirty() {
    if (this.state.currentDataSourceObject === null) {
      return false;
    }
    const {
      schedulingEnabled,
      schedulingMode,
      scheduleSlots,
      currentDataSourceObject,
      originalDatasourceState,
    } = this.state;

    const hascurrentDataSourceObjectChanged = !isEqual(
      originalDatasourceState.currentDataSourceObject,
      currentDataSourceObject
    );

    const hasSchedulingSettingChanged =
      originalDatasourceState.schedulingEnabled !== schedulingEnabled;
    const hasSchedulingModeChanged =
      originalDatasourceState.schedulingMode !== schedulingMode;
    const hasSchedulingSlotsChanged = !isEqual(
      originalDatasourceState.scheduleSlots,
      scheduleSlots
    );

    if (
      hascurrentDataSourceObjectChanged ||
      hasSchedulingSettingChanged ||
      hasSchedulingModeChanged ||
      hasSchedulingSlotsChanged
    ) {
      return true;
    } else {
      return false;
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      dataSourceList,
      match: {
        params: { workspaceUuid },
      },
    } = props;

    if (workspaceUuid && workspaceUuid !== state.workspaceUuid) {
      console.log("Loading data list for data governance");
      props.getDataSourceList(workspaceUuid);
      props.getScheduleList(workspaceUuid);

      return {
        ...state,
        workspaceUuid,
      };
    } else if (dataSourceList !== state.dataSourceList) {
      const defaultDataSourceUuid = getSettingFromParams(
        "dataSourceUuid",
        props.location.search,
        ""
      );
      let currentDataSourceObject = null;
      let newSchedulingMode = state.schedulingMode;
      let newScheduleSlots = [];
      let newSchedulingEnabled = false;

      if (dataSourceList.data.length > 0) {
        if (state.currentDataSourceObject || defaultDataSourceUuid) {
          const targetDataSourceUuid =
            defaultDataSourceUuid || state.currentDataSourceObject?.metadata.uuid;
          for (let dataSource of dataSourceList.data) {
            if (targetDataSourceUuid === dataSource.metadata.uuid) {
              currentDataSourceObject = deepcopy(dataSource);
              break;
            }
          }
        }

        if (!currentDataSourceObject) {
          currentDataSourceObject = deepcopy(dataSourceList.data[0]);
        }

        if (currentDataSourceObject) {
          const {
            schedulingMode: dataSourceSchedulingMode,
            scheduleSlots: dataSourceScheduleSlots,
            schedulingEnabled: dataSourceSchedulingEnabled,
          } = schedulingFromDatasource(currentDataSourceObject);
          newSchedulingMode = dataSourceSchedulingMode;
          newScheduleSlots = dataSourceScheduleSlots;
          newSchedulingEnabled = dataSourceSchedulingEnabled;
        }
      }

      currentDataSourceObject &&
        props.getDataSourceQueryList(
          workspaceUuid,
          currentDataSourceObject.metadata.uuid,
          {
            startTs: getUnixTime(state.queryTimeRange.startTime),
            endTs: getUnixTime(state.queryTimeRange.endTime),
          }
        );
      return {
        ...state,
        schedulingEnabled: newSchedulingEnabled,
        schedulingMode: newSchedulingMode,
        scheduleSlots: newScheduleSlots,
        currentDataSourceObject,
        dataSourceList,
        originalDatasourceState: deepcopy({
          schedulingEnabled: newSchedulingEnabled,
          schedulingMode: newSchedulingMode,
          scheduleSlots: newScheduleSlots,
          currentDataSourceObject,
        }),
      };
    }

    return null;
  }

  onMaxQueryDurationInSecondsChange(newMaxQueryDurationStr) {
    const newMaxQueryDurationInSeconds =
      newMaxQueryDurationStr === undefined
        ? undefined
        : getSecondFromDisplayTime(newMaxQueryDurationStr);
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.maxQueryDurationInSeconds !== newMaxQueryDurationInSeconds) {
      currentDataSourceObject.config.governance = {
        ...governance,
        maxQueryDurationInSeconds: newMaxQueryDurationInSeconds,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onMaxQueryPeriodInSecondsChange(newMaxQueryPeriodStr) {
    const newMaxQueryPeriodInSeconds =
      newMaxQueryPeriodStr === undefined
        ? undefined
        : getSecondFromDisplayTime(newMaxQueryPeriodStr);
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.maxQueryPeriodInSeconds !== newMaxQueryPeriodInSeconds) {
      currentDataSourceObject.config.governance = {
        ...governance,
        maxQueryPeriodInSeconds: newMaxQueryPeriodInSeconds,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onIndexedTimestampColumnsChange(newIndexedTimestampColumnsOnly) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.indexedTimestampColumnsOnly !== newIndexedTimestampColumnsOnly) {
      currentDataSourceObject.config.governance = {
        ...governance,
        indexedTimestampColumnsOnly: newIndexedTimestampColumnsOnly,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onAllowRecordStorageChange(newAllowRecordStorage) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.allowRecordStorage !== newAllowRecordStorage) {
      currentDataSourceObject.config.governance = {
        ...governance,
        allowRecordStorage: newAllowRecordStorage,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onAllowRawDataSamplesChange(newAllowRawDataSamples) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.allowRawDataSamples !== newAllowRawDataSamples) {
      currentDataSourceObject.config.governance = {
        ...governance,
        allowRawDataSamples: newAllowRawDataSamples,
      };
      this.setState({ currentDataSourceObject });
    }
  }

  onFailingRecordsCountCacheChange(newAllowFailingRecordsCountCache) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.cacheFailingRecordsCount !== newAllowFailingRecordsCountCache) {
      currentDataSourceObject.config.governance = {
        ...governance,
        cacheFailingRecordsCount: newAllowFailingRecordsCountCache,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onSaveCompleteFullCompareDiffSamplesChange(newSaveCompleteFullCompareDiffSamples) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (
      governance.saveCompleteFullCompareDiffSamples !==
      newSaveCompleteFullCompareDiffSamples
    ) {
      currentDataSourceObject.config.governance = {
        ...governance,
        saveCompleteFullCompareDiffSamples: newSaveCompleteFullCompareDiffSamples,
      };
      this.setState({ currentDataSourceObject });
    }
  }

  onAllowPreviewsWhenPausedChange(newAllowPreviewsWhenPaused) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.allowPreviewsWhenPaused !== newAllowPreviewsWhenPaused) {
      currentDataSourceObject.config.governance = {
        ...governance,
        allowPreviewsWhenPaused: newAllowPreviewsWhenPaused,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onMaxBackfillDurationChange(newMaxBackfillDuration) {
    const { currentDataSourceObject } = this.state;
    this.setState({
      currentDataSourceObject: {
        ...currentDataSourceObject,
        config: {
          ...currentDataSourceObject.config,
          governance: {
            ...currentDataSourceObject.config.governance,
            maxBackfillDurationInSeconds: newMaxBackfillDuration,
          },
        },
      },
    });
  }

  onMaxCategoricalDistinctCountChange(newMaxCategoricalDistinctCount) {
    const { currentDataSourceObject } = this.state;
    this.setState({
      currentDataSourceObject: {
        ...currentDataSourceObject,
        config: {
          ...currentDataSourceObject.config,
          governance: {
            ...currentDataSourceObject.config.governance,
            maxCategoricalDistinctCount: newMaxCategoricalDistinctCount,
          },
        },
      },
    });
  }

  onSave() {
    const { currentDataSourceObject, workspaceUuid } = this.state;
    if (!currentDataSourceObject) {
      return;
    }

    Object.assign(
      currentDataSourceObject.config.governance,
      this.getAllowDenySchedulesConfig()
    );

    const oldDataSourceObject = this.state.dataSourceList.data.filter(
      (dataSourceObject) =>
        dataSourceObject.metadata.uuid === currentDataSourceObject.metadata.uuid
    )[0];

    this.props.updateDataSourceGovernance(
      workspaceUuid,
      oldDataSourceObject,
      currentDataSourceObject.config.governance || {}
    );

    trackEvent(EVENT.SAVE_QUERY_GOVERNANCE, {
      ...getDatasourceDetailProps(currentDataSourceObject),
      page: PAGE.QUERY_GOVERNANCE,
    });
  }

  onDataSourceChange(dataSourceOption) {
    const { currentDataSourceObject } = this.state;
    if (
      !currentDataSourceObject ||
      dataSourceOption !== currentDataSourceObject.metadata.uuid
    ) {
      const newDataSourceObject = this.state.dataSourceList.data.filter(
        (dataSourceObject) => dataSourceObject.metadata.uuid === dataSourceOption
      )[0];
      const { schedulingMode, scheduleSlots, schedulingEnabled } =
        schedulingFromDatasource(newDataSourceObject);
      const updatedState = {
        currentDataSourceObject: deepcopy(newDataSourceObject),
        schedulingEnabled,
        schedulingMode,
        scheduleSlots,
      };
      this.setState({
        ...updatedState,
        originalDatasourceState: deepcopy(updatedState),
      });
      this.getQueryList(newDataSourceObject.metadata.uuid);
    }
  }

  getQueryTableView() {
    const { queryList, openWorkspaceTakeover, closeTakeover } = this.props;
    const { currentDataSourceObject } = this.state;

    return (
      <div className="query-governance-data-container">
        <div className="query-governance-data-summary-container">
          <div className="query-governance-data-summary-header">
            <div className="query-governance-stats-container">
              <QueryListStats
                queryList={queryList}
                className="query-governance-data-summary-stats"
              />
            </div>
            <div className="query-governance-time-range-container">
              <TimeRangeSelector
                size="large"
                value={this.state.queryTimeRange}
                disabledDate={timeRangeDisabledDate(MAX_LOOKBACK_DAYS)}
                allowClear={false}
                ranges={timeRangeSelectorPresets()}
                onChange={(newTimeRange) => {
                  this.setState({
                    queryTimeRange: newTimeRange,
                  });
                }}
              />
              <Button
                outline
                size="large"
                onClick={() => {
                  this.getQueryList(currentDataSourceObject.metadata.uuid);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
        <div className="query-governance-data-summary-table">
          <QueryHistoryTable
            queryList={queryList}
            dataSourceInfo={{
              [currentDataSourceObject.metadata.uuid]:
                currentDataSourceObject.metadata.name,
            }}
            onOpenQueryDetail={(detailComponent) =>
              openWorkspaceTakeover(detailComponent, TakeoverWidth.WIDE, () =>
                closeTakeover()
              )
            }
            onCloseQueryDetail={closeTakeover}
            paginationClassname="query-governance-table-pagination"
          />
        </div>
      </div>
    );
  }

  getAllowDenySchedulesConfig() {
    const schedules = {
      allowScheduleUuids: [],
      denyScheduleUuids: [],
    };
    if (!this.state.schedulingEnabled) {
      return schedules;
    }
    const selectedScheduleUuids = this.state.scheduleSlots
      .filter((slot) => slot.scheduleUuid)
      .map((slot) => slot.scheduleUuid);
    if (this.state.schedulingMode === QueryGovernanceSchedulingMode.ALLOW) {
      schedules.allowScheduleUuids = selectedScheduleUuids;
    } else if (this.state.schedulingMode === QueryGovernanceSchedulingMode.DENY) {
      schedules.denyScheduleUuids = selectedScheduleUuids;
    }
    return schedules;
  }
  getDatasourceListProps() {
    const {
      currentDataSourceObject,
      dataSourceList: { data, loading },
    } = this.state;
    const currentDataSourceUuid = currentDataSourceObject
      ? currentDataSourceObject.metadata.uuid
      : "";
    const dataSourceOptions = data?.map(function (dataSource) {
      return {
        value: dataSource.metadata.uuid,
        label: dataSource.metadata.name,
      };
    });
    return {
      isLoading: loading,
      selectedDataSourceId: currentDataSourceUuid,
      dataSourceOptions,
      onDataSourceChange: this.onDataSourceChange,
    };
  }
  render() {
    const {
      workspaceUserPermissions,
      scheduleList,
      dataSourceList: { loading },
    } = this.props;
    const { schedulingEnabled, schedulingMode, scheduleSlots } = this.state;

    const enableSaveButton = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
    ]);

    const steps = [];
    if (!this.state.dataSourceList.loading) {
      const { currentDataSourceObject } = this.state;

      const currentDataSourceGovernance = currentDataSourceObject
        ? currentDataSourceObject.config.governance
        : {};

      const dataSourceType = currentDataSourceObject?.config.connection.type || "";
      const schedulingSupported =
        currentDataSourceObject && currentDataSourceGovernance;
      const governanceFullySupported =
        currentDataSourceObject &&
        currentDataSourceGovernance &&
        fullySupportedDataSourceTypeList.includes(
          currentDataSourceObject.config.connection.type
        );

      if (schedulingSupported) {
        steps.push({
          title: "Scheduling",
          component: (
            <SchedulingStep
              schedulingEnabled={schedulingEnabled}
              allowPreviewsWhenPaused={
                currentDataSourceGovernance.allowPreviewsWhenPaused
              }
              scheduleList={scheduleList}
              schedulingMode={schedulingMode}
              scheduleSlots={scheduleSlots}
              onSchedulingModeChange={(newMode) => {
                this.setState({ schedulingMode: newMode });
              }}
              onScheduleSlotChange={(slotId, newScheduleUuid) => {
                const newScheduleSlots = scheduleSlots.map((slot) => {
                  if (slot.id === slotId) {
                    slot.scheduleUuid = newScheduleUuid;
                  }
                  return slot;
                });
                this.setState({
                  scheduleSlots: newScheduleSlots,
                });
              }}
              onScheduleSlotAdd={() => {
                const highestId = scheduleSlots.reduce((maxId, slot) => {
                  return Math.max(maxId, slot.id);
                }, 0);
                const newScheduleSlots = [{ id: highestId + 1 }, ...scheduleSlots];
                this.setState({
                  scheduleSlots: newScheduleSlots,
                });
              }}
              onScheduleSlotDelete={(deletedSlotId) => {
                const newScheduleSlots = scheduleSlots.filter((slot) => {
                  return slot.id !== deletedSlotId;
                });
                this.setState({
                  scheduleSlots: newScheduleSlots,
                });
              }}
              onAllowPreviewsWhenPaused={this.onAllowPreviewsWhenPausedChange}
            />
          ),
          skipIndex: true,
          description: "Enable scheduling to control when queries are issued",
          className: "query-governance-wizard-scheduling-container query-config-step",
          enabled: schedulingEnabled,
          onEnabledChange: (enabled) => this.setState({ schedulingEnabled: enabled }),
        });
      }

      const {
        maxQueryDurationInSeconds,
        maxQueryPeriodInSeconds,
        indexedTimestampColumnsOnly,
        allowRecordStorage,
        allowRawDataSamples,
        saveCompleteFullCompareDiffSamples,
        cacheFailingRecordsCount,
      } = currentDataSourceGovernance;

      if (governanceFullySupported) {
        const queryTimeoutEnabled = maxQueryDurationInSeconds !== undefined;
        steps.push({
          title: "Query timeout",
          component: queryTimeoutEnabled && (
            <>
              Terminate after
              <TimeOption
                value={
                  !queryTimeoutEnabled
                    ? undefined
                    : getDisplayTimeFromSecond(maxQueryDurationInSeconds, true, [
                        "s",
                        "m",
                        "h",
                        "d",
                        "w",
                        "mo",
                      ])
                }
                onChange={this.onMaxQueryDurationInSecondsChange}
                options={timeSettingOptions}
              />
            </>
          ),
          skipIndex: true,
          description:
            "Terminates the query if the runtime exceeds the specified value.",
          className: "query-governance-wizard-query-timeout-container",
          enabled: queryTimeoutEnabled,
          onEnabledChange: (enabled) => {
            const newMaxQueryDurationInSeconds = enabled
              ? getDisplayTimeFromSecond(defaultQueryTimeoutLimit, true, [
                  "s",
                  "m",
                  "h",
                  "d",
                  "w",
                  "mo",
                ])
              : undefined;
            this.onMaxQueryDurationInSecondsChange(newMaxQueryDurationInSeconds);
          },
        });

        const dataRangeLimitEnabled = maxQueryPeriodInSeconds !== undefined;
        steps.push({
          title: "Query data range limit",
          component: dataRangeLimitEnabled && (
            <>
              Limit range to
              <TimeOption
                value={
                  !dataRangeLimitEnabled
                    ? undefined
                    : getDisplayTimeFromSecond(maxQueryPeriodInSeconds, true, [
                        "s",
                        "m",
                        "h",
                        "d",
                        "w",
                        "mo",
                      ])
                }
                onChange={this.onMaxQueryPeriodInSecondsChange}
                options={timeSettingOptions}
              />
            </>
          ),
          skipIndex: true,
          description:
            "Terminates the query if the data date range specified too large.",
          className: "query-governance-wizard-query-period-container",
          enabled: dataRangeLimitEnabled,
          onEnabledChange: (enabled) => {
            const newMaxQueryPeriodInSeconds = enabled
              ? getDisplayTimeFromSecond(defaultQueryDataRangeLimit, true, [
                  "s",
                  "m",
                  "h",
                  "d",
                  "w",
                  "mo",
                ])
              : undefined;
            this.onMaxQueryPeriodInSecondsChange(newMaxQueryPeriodInSeconds);
          },
        });
      }

      const enforcedTimestampDescriptionString =
        enforcedTimestampSourceTypeToStringMapper[dataSourceType];
      if (enforcedTimestampDescriptionString) {
        steps.push({
          title: `Enforce ${enforcedTimestampDescriptionString} timestamps`,
          skipIndex: true,
          description: `Prevent configuration of metrics that don't use ${enforcedTimestampDescriptionString} timestamps.`,
          className: "query-governance-wizard-indexed-timestamp-column-container",
          enabled: !!indexedTimestampColumnsOnly,
          onEnabledChange: (enabled) => this.onIndexedTimestampColumnsChange(enabled),
        });
      }

      steps.push({
        title: "Enable data storage",
        skipIndex: true,
        description:
          "Enable storage of sample data where source and target do not match",
        enabled: allowRecordStorage,
        onEnabledChange: (enabled) => this.onAllowRecordStorageChange(enabled),
      });

      steps.push({
        title: "Store full difference",
        skipIndex: true,
        description:
          "Enable storage of all data points where source and target do not match",
        enabled: saveCompleteFullCompareDiffSamples,
        onEnabledChange: (enabled) =>
          this.onSaveCompleteFullCompareDiffSamplesChange(enabled),
        enabledProps: {
          disabled: !allowRecordStorage,
        },
      });

      steps.push({
        title: "Enable data samples",
        skipIndex: true,
        description: "Allow display of raw data samples in the application",
        enabled: allowRawDataSamples,
        onEnabledChange: (enabled) => this.onAllowRawDataSamplesChange(enabled),
      });

      steps.push({
        title: "Enable failing records count cache",
        skipIndex: true,
        description:
          "Allow failing records count to be fetched and cached automatically",
        enabled: cacheFailingRecordsCount,
        onEnabledChange: (enabled) => this.onFailingRecordsCountCacheChange(enabled),
      });
    }

    steps.push({
      title: "Maximum backfill duration",
      description:
        "Limits the backfill duration for metrics. All metrics will be updated to conform to this policy.",
      component: (
        <DurationInput
          // Ensure time unit gets reset when datasource changes.
          key={this.state.currentDataSourceObject?.metadata.uuid}
          label="Backfill duration"
          value={
            this.state.currentDataSourceObject?.config.governance
              .maxBackfillDurationInSeconds
          }
          onChange={this.onMaxBackfillDurationChange}
        />
      ),
    });

    steps.push({
      title: "Maximum distinct values",
      description:
        "Categorical distribution metric will be collected only if the number of distinct values is less than this maximum.",
      component: (
        <LabeledInputNumber
          label="Maximum distinct values"
          value={
            this.state.currentDataSourceObject?.config.governance
              .maxCategoricalDistinctCount
          }
          onChange={this.onMaxCategoricalDistinctCountChange}
        />
      ),
    });
    const disableSave = this.isFormDirty()
      ? !enableSaveButton || !this.state.currentDataSourceObject
      : true;

    return (
      <div className="query-governance-container lightup-vertical-flex-container">
        <div className="query-governance-header">
          <div className="query-governance-datasource">
            <div className="query-governance-title-container lightup-auto-flex-item-container">
              Query Governance
            </div>
            <QueryGovernanceDataSource {...this.getDatasourceListProps()} />
          </div>
          <div className="query-governance-save-container">
            <Button
              block
              primary
              size="large"
              disabled={disableSave}
              onClick={this.onSave}
            >
              Save
            </Button>
          </div>
        </div>
        <div>
          <Tabs defaultActiveKey={"configure"} className="lightup">
            <Tabs.TabPane
              key="configure"
              label="Configure"
              tab={
                <span>
                  <SettingConfigIcon className="anticon" />
                  Configure
                </span>
              }
            >
              <Spinner spinning={loading}>
                <div className="query-governance-config-container lightup-auto-flex-item-container lightup-vertical-flex-container">
                  <NgStepContainer
                    steps={steps}
                    className="query-governance-step-config-container lightup-rest-flex-item-container"
                  />
                </div>
              </Spinner>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  <HistoryOutlined className="history-icon" />
                  History
                </span>
              }
            >
              <div className="query-governance-content-container lightup-rest-flex-item-container">
                {this.state.currentDataSourceObject ? (
                  this.getQueryTableView()
                ) : (
                  <div className="query-governance-no-data-container">
                    <div className="query-governance-no-data-inner-container">
                      <svg
                        width="101"
                        height="100"
                        viewBox="0 0 101 100"
                        fill="none"
                        className="query-governance-no-data-icon"
                      >
                        <g filter="url(#filter0_d)">
                          <circle cx="50.5" cy="47" r="40" fill="white" />
                        </g>
                        <mask
                          id="mask0"
                          mask-type="alpha"
                          maskUnits="userSpaceOnUse"
                          x="13"
                          y="10"
                          width="76"
                          height="75"
                        >
                          <circle cx="51" cy="47.5" r="37.5" fill="#F7C63B" />
                        </mask>
                        <g mask="url(#mask0)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M66.5726 65.8827V28.1173C66.5726 27.9939 66.4726 27.8939 66.3492 27.8939H40.2256C40.1656 27.8939 40.1082 27.9179 40.0662 27.9607L34.4579 33.6691C34.4169 33.7109 34.3939 33.7672 34.3939 33.8257V65.8827C34.3939 66.0061 34.4939 66.1061 34.6173 66.1061H66.3492C66.4726 66.1061 66.5726 66.0061 66.5726 65.8827ZM67.4665 28.1173V65.8827C67.4665 66.4998 66.9662 67 66.3492 67H34.6173C34.0002 67 33.5 66.4998 33.5 65.8827V33.8257C33.5 33.5328 33.615 33.2516 33.8203 33.0427L39.4286 27.3343C39.6386 27.1204 39.9258 27 40.2256 27H66.3492C66.9662 27 67.4665 27.5002 67.4665 28.1173Z"
                            fill="#525252"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M33.8816 33.9928C33.7408 34.1336 33.8405 34.3743 34.0396 34.3743H40.6509C40.7743 34.3743 40.8744 34.2743 40.8744 34.1508V27.5395C40.8744 27.3404 40.6337 27.2407 40.4929 27.3815L33.8816 33.9928ZM39.9805 29.158L35.6581 33.4805H39.9805V29.158Z"
                            fill="#525252"
                          />
                          <path
                            d="M52.303 30.7989H48.4718C48.2351 30.799 48.0081 30.8887 47.8406 31.0485C47.673 31.2082 47.5787 31.4249 47.5781 31.651V35.3083C47.578 35.4205 47.601 35.5315 47.6458 35.6352C47.6907 35.7388 47.7565 35.833 47.8395 35.9123C47.9225 35.9916 48.0211 36.0545 48.1296 36.0973C48.2381 36.1402 48.3544 36.1621 48.4718 36.162H52.303C52.4204 36.1621 52.5366 36.1402 52.6451 36.0973C52.7536 36.0545 52.8522 35.9916 52.9352 35.9123C53.0183 35.833 53.0841 35.7388 53.1289 35.6352C53.1738 35.5315 53.1968 35.4205 53.1966 35.3083V31.651C53.1961 31.4249 53.1017 31.2082 52.9342 31.0485C52.7667 30.8887 52.5397 30.799 52.303 30.7989ZM51.6721 33.1891C51.4663 33.189 51.2652 33.1306 51.0941 33.0213C50.9231 32.9119 50.7898 32.7567 50.7111 32.575C50.6324 32.3934 50.6118 32.1935 50.6519 32.0007C50.6921 31.8079 50.7912 31.6307 50.9367 31.4917C51.0822 31.3527 51.2676 31.2579 51.4694 31.2195C51.6713 31.1811 51.8805 31.2006 52.0707 31.2758C52.2609 31.3509 52.4235 31.4781 52.538 31.6415C52.6525 31.8049 52.7137 31.997 52.714 32.1936C52.7141 32.3243 52.6872 32.4538 52.6349 32.5746C52.5826 32.6954 52.5058 32.8052 52.4091 32.8976C52.3123 32.9901 52.1974 33.0634 52.071 33.1134C51.9445 33.1635 51.809 33.1892 51.6721 33.1891Z"
                            fill="#525252"
                          />
                          <path
                            d="M53.963 31.0542C54.5271 31.0542 54.9845 30.5969 54.9845 30.0327C54.9845 29.4685 54.5271 29.0111 53.963 29.0111C53.3988 29.0111 52.9414 29.4685 52.9414 30.0327C52.9414 30.5969 53.3988 31.0542 53.963 31.0542Z"
                            fill="#FAB800"
                          />
                          <path
                            d="M56.7402 44.8771C56.7402 44.0132 57.4406 43.3128 58.3045 43.3128H61.8799C62.7438 43.3128 63.4441 44.0132 63.4441 44.8771C63.4441 45.741 62.7438 46.4413 61.8799 46.4413H58.3045C57.4406 46.4413 56.7402 45.741 56.7402 44.8771Z"
                            fill="#525252"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M61.8799 43.5363H58.3045C57.564 43.5363 56.9637 44.1366 56.9637 44.8771C56.9637 45.6176 57.564 46.2179 58.3045 46.2179H61.8799C62.6204 46.2179 63.2207 45.6176 63.2207 44.8771C63.2207 44.1366 62.6204 43.5363 61.8799 43.5363ZM58.3045 43.3128C57.4406 43.3128 56.7402 44.0132 56.7402 44.8771C56.7402 45.741 57.4406 46.4413 58.3045 46.4413H61.8799C62.7438 46.4413 63.4441 45.741 63.4441 44.8771C63.4441 44.0132 62.7438 43.3128 61.8799 43.3128H58.3045Z"
                            fill="#525252"
                          />
                          <path
                            d="M60.5391 44.8771C60.5391 44.1366 61.1394 43.5363 61.8798 43.5363C62.6203 43.5363 63.2206 44.1366 63.2206 44.8771C63.2206 45.6176 62.6203 46.2179 61.8798 46.2179C61.1394 46.2179 60.5391 45.6176 60.5391 44.8771Z"
                            fill="#868786"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M60.7625 44.8771C60.7625 45.4942 61.2628 45.9944 61.8798 45.9944C62.4969 45.9944 62.9972 45.4942 62.9972 44.8771C62.9972 44.26 62.4969 43.7598 61.8798 43.7598C61.2628 43.7598 60.7625 44.26 60.7625 44.8771ZM61.8798 43.5363C61.1394 43.5363 60.5391 44.1366 60.5391 44.8771C60.5391 45.6176 61.1394 46.2179 61.8798 46.2179C62.6203 46.2179 63.2206 45.6176 63.2206 44.8771C63.2206 44.1366 62.6203 43.5363 61.8798 43.5363Z"
                            fill="#868786"
                          />
                          <path
                            d="M35.5112 44.8771C35.5112 44.3834 35.9114 43.9832 36.4051 43.9832H52.271C52.7647 43.9832 53.1649 44.3834 53.1649 44.8771C53.1649 45.3708 52.7647 45.771 52.271 45.771H36.4051C35.9114 45.771 35.5112 45.3708 35.5112 44.8771Z"
                            fill="#525252"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M52.271 44.2067H36.4051C36.0348 44.2067 35.7347 44.5069 35.7347 44.8771C35.7347 45.2473 36.0348 45.5475 36.4051 45.5475H52.271C52.6413 45.5475 52.9414 45.2473 52.9414 44.8771C52.9414 44.5069 52.6413 44.2067 52.271 44.2067ZM36.4051 43.9832C35.9114 43.9832 35.5112 44.3834 35.5112 44.8771C35.5112 45.3708 35.9114 45.771 36.4051 45.771H52.271C52.7647 45.771 53.1649 45.3708 53.1649 44.8771C53.1649 44.3834 52.7647 43.9832 52.271 43.9832H36.4051Z"
                            fill="#525252"
                          />
                          <path
                            d="M35.5112 52.0279C35.5112 51.5343 35.9114 51.1341 36.4051 51.1341H52.271C52.7647 51.1341 53.1649 51.5343 53.1649 52.0279C53.1649 52.5216 52.7647 52.9218 52.271 52.9218H36.4051C35.9114 52.9218 35.5112 52.5216 35.5112 52.0279Z"
                            fill="#525252"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M52.271 51.3576H36.4051C36.0348 51.3576 35.7347 51.6577 35.7347 52.0279C35.7347 52.3982 36.0348 52.6983 36.4051 52.6983H52.271C52.6413 52.6983 52.9414 52.3982 52.9414 52.0279C52.9414 51.6577 52.6413 51.3576 52.271 51.3576ZM36.4051 51.1341C35.9114 51.1341 35.5112 51.5343 35.5112 52.0279C35.5112 52.5216 35.9114 52.9218 36.4051 52.9218H52.271C52.7647 52.9218 53.1649 52.5216 53.1649 52.0279C53.1649 51.5343 52.7647 51.1341 52.271 51.1341H36.4051Z"
                            fill="#525252"
                          />
                          <path
                            d="M35.5112 59.1788C35.5112 58.6851 35.9114 58.2849 36.4051 58.2849H52.271C52.7647 58.2849 53.1649 58.6851 53.1649 59.1788C53.1649 59.6724 52.7647 60.0726 52.271 60.0726H36.4051C35.9114 60.0726 35.5112 59.6724 35.5112 59.1788Z"
                            fill="#525252"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M52.271 58.5084H36.4051C36.0348 58.5084 35.7347 58.8085 35.7347 59.1788C35.7347 59.549 36.0348 59.8492 36.4051 59.8492H52.271C52.6413 59.8492 52.9414 59.549 52.9414 59.1788C52.9414 58.8085 52.6413 58.5084 52.271 58.5084ZM36.4051 58.2849C35.9114 58.2849 35.5112 58.6851 35.5112 59.1788C35.5112 59.6724 35.9114 60.0726 36.4051 60.0726H52.271C52.7647 60.0726 53.1649 59.6724 53.1649 59.1788C53.1649 58.6851 52.7647 58.2849 52.271 58.2849H36.4051Z"
                            fill="#525252"
                          />
                          <path
                            d="M56.5168 52.0279C56.5168 51.164 57.2172 50.4637 58.0811 50.4637H61.6565C62.5204 50.4637 63.2208 51.164 63.2208 52.0279C63.2208 52.8918 62.5204 53.5922 61.6565 53.5922H58.0811C57.2172 53.5922 56.5168 52.8918 56.5168 52.0279Z"
                            fill="#525252"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M61.6565 50.6871H58.0811C57.3406 50.6871 56.7403 51.2874 56.7403 52.0279C56.7403 52.7684 57.3406 53.3687 58.0811 53.3687H61.6565C62.397 53.3687 62.9973 52.7684 62.9973 52.0279C62.9973 51.2874 62.397 50.6871 61.6565 50.6871ZM58.0811 50.4637C57.2172 50.4637 56.5168 51.164 56.5168 52.0279C56.5168 52.8918 57.2172 53.5922 58.0811 53.5922H61.6565C62.5204 53.5922 63.2208 52.8918 63.2208 52.0279C63.2208 51.164 62.5204 50.4637 61.6565 50.4637H58.0811Z"
                            fill="#525252"
                          />
                          <path
                            d="M60.3157 52.0279C60.3157 51.2874 60.916 50.6871 61.6565 50.6871C62.3969 50.6871 62.9972 51.2874 62.9972 52.0279C62.9972 52.7684 62.3969 53.3687 61.6565 53.3687C60.916 53.3687 60.3157 52.7684 60.3157 52.0279Z"
                            fill="#868786"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M60.5391 52.0279C60.5391 52.645 61.0394 53.1452 61.6565 53.1452C62.2735 53.1452 62.7738 52.645 62.7738 52.0279C62.7738 51.4108 62.2735 50.9106 61.6565 50.9106C61.0394 50.9106 60.5391 51.4108 60.5391 52.0279ZM61.6565 50.6871C60.916 50.6871 60.3157 51.2874 60.3157 52.0279C60.3157 52.7684 60.916 53.3687 61.6565 53.3687C62.3969 53.3687 62.9972 52.7684 62.9972 52.0279C62.9972 51.2874 62.3969 50.6871 61.6565 50.6871Z"
                            fill="#868786"
                          />
                          <path
                            d="M56.5168 59.1788C56.5168 58.3149 57.2172 57.6145 58.0811 57.6145H61.6565C62.5204 57.6145 63.2208 58.3149 63.2208 59.1788C63.2208 60.0427 62.5204 60.743 61.6565 60.743H58.0811C57.2172 60.743 56.5168 60.0427 56.5168 59.1788Z"
                            fill="#525252"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M61.6565 57.838H58.0811C57.3406 57.838 56.7403 58.4383 56.7403 59.1788C56.7403 59.9193 57.3406 60.5196 58.0811 60.5196H61.6565C62.397 60.5196 62.9973 59.9193 62.9973 59.1788C62.9973 58.4383 62.397 57.838 61.6565 57.838ZM58.0811 57.6145C57.2172 57.6145 56.5168 58.3149 56.5168 59.1788C56.5168 60.0427 57.2172 60.743 58.0811 60.743H61.6565C62.5204 60.743 63.2208 60.0427 63.2208 59.1788C63.2208 58.3149 62.5204 57.6145 61.6565 57.6145H58.0811Z"
                            fill="#525252"
                          />
                          <path
                            d="M60.3157 59.1788C60.3157 58.4383 60.916 57.838 61.6565 57.838C62.3969 57.838 62.9972 58.4383 62.9972 59.1788C62.9972 59.9193 62.3969 60.5195 61.6565 60.5195C60.916 60.5195 60.3157 59.9193 60.3157 59.1788Z"
                            fill="#868786"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M60.5391 59.1788C60.5391 59.7958 61.0394 60.2961 61.6565 60.2961C62.2735 60.2961 62.7738 59.7958 62.7738 59.1788C62.7738 58.5617 62.2735 58.0614 61.6565 58.0614C61.0394 58.0614 60.5391 58.5617 60.5391 59.1788ZM61.6565 57.838C60.916 57.838 60.3157 58.4383 60.3157 59.1788C60.3157 59.9193 60.916 60.5195 61.6565 60.5195C62.3969 60.5195 62.9972 59.9193 62.9972 59.1788C62.9972 58.4383 62.3969 57.838 61.6565 57.838Z"
                            fill="#868786"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d"
                            x="0.5"
                            y="0"
                            width="100"
                            height="100"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="3" />
                            <feGaussianBlur stdDeviation="5" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                      <div className="query-governance-no-data-title-container">
                        Set database query governance policy
                      </div>
                      <div className="query-governance-no-data-description-container">
                        Query governance allows you to control behaviors to avoid
                        expensive and non-performant queries from running.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default QueryGovernance;
