import React from "react";
import SlackIntegrationBasicDialog from "./slack-integration-basic-dialog";
import { AlertingChannelType } from "../../../utils/alerting";
import { EditOutlined } from "@ant-design/icons";
import "./edit-slack-integration-dialog.scss";

function EditSlackIntegrationDialog(props) {
  const {
    data,
    onEdit,
    onPreview,
    alertChannelType = AlertingChannelType.SLACK,
    modalIsOpen,
    setIsOpen,
    enablePreview,
    enableSave,
  } = props;

  const onOkClicked = (newData) => {
    onEdit(newData);
  };

  let dialogTitle = "";
  if (alertChannelType === AlertingChannelType.MSTEAMS) {
    dialogTitle = "Edit Microsoft Teams Integration";
  } else {
    dialogTitle = "Edit Slack Integration";
  }

  return (
    <SlackIntegrationBasicDialog
      defaultData={data}
      className={"edit-integration-dialog-container"}
      dialogTitle={dialogTitle}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      alertChannelType={alertChannelType}
      triggerComponent={<EditOutlined />}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enablePreview={enablePreview}
      enableSave={enableSave}
    />
  );
}

export default EditSlackIntegrationDialog;
