import React, { useState } from "react";
import OpsgenieIntegrationBasicDialog from "./opsgenie-integration-basic-dialog";
import { getReconciledEditMetadata } from "../integration-metadata";
import { AlertingChannelType } from "../../../utils/alerting";
import { useOutsideClickConfirm } from "../../../hooks/useOutsideClickConfirm";
import {
  transformOpsgenieDataFromPayload,
  transformOpsgenieDataToPayload,
} from "./opsgenie-integration-helper";

function EditOpsgenieIntegrationDialog(props) {
  const {
    data,
    currentMetadata,
    onEdit,
    onPreview,
    modalIsOpen,
    outsideClickWrap,
    setIsOpen,
  } = props;

  const [value, setValue] = useState(transformOpsgenieDataFromPayload(data));

  const reconciledMetadata = getReconciledEditMetadata(
    AlertingChannelType.OPSGENIE,
    value,
    currentMetadata
  );

  const reconciledValue = { ...value, metadata: reconciledMetadata };

  useOutsideClickConfirm({
    outsideClickWrap,
    close: () => setIsOpen(false),
    originalValue: data,
    currentValue: reconciledValue,
  });

  const onOkClicked = (newData) => {
    const payloaData = transformOpsgenieDataToPayload(newData);

    onEdit(payloaData);
  };

  const onPreviewClicked = async (newData) => {
    try {
      const payloaData = transformOpsgenieDataToPayload(newData);

      await onPreview(payloaData);
    } catch (error) {
      console.error(error);
    }
  };

  const hasFilledRequiredFields =
    reconciledValue?.title?.trim() !== "" &&
    reconciledValue?.apiKey?.trim() !== "" &&
    reconciledValue?.priority;

  return (
    <OpsgenieIntegrationBasicDialog
      value={reconciledValue}
      onChange={setValue}
      onPreview={onPreviewClicked}
      setIsOpen={setIsOpen}
      enableSave={hasFilledRequiredFields}
      modalIsOpen={modalIsOpen}
      onOkClicked={onOkClicked}
      dialogTitle="Edit Opsgenie Integration"
      enablePreview={hasFilledRequiredFields}
      currentMetadata={currentMetadata}
    />
  );
}

export default EditOpsgenieIntegrationDialog;
