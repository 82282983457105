import {
  InfoCircleOutlined,
  OrderedListOutlined,
  PlusOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import NgButton, { TextWithIcon } from "../../../../components/button/ng-button";
import { columnFn } from "../../../../components/entity-list/columns";
import { metricCategoryIconComponent } from "../../../../components/metric/fields/icons";
import {
  getMetricTypeFromConfigData,
  metricTypeNames,
} from "../../../../components/metric/utils";
import NgDropdownMenu from "../../../../components/ng-dropdown-menu";
import NgTable, {
  NgTableClickableText,
  NgTableTheme,
} from "../../../../components/table/ng-table";
import { NextGenPalette } from "../../../../utils/color";
import {
  isActivityMetric,
  isAutoCreationTypeMetric,
  tableAutoMetricTypes,
} from "../../../../utils/metric";
import { AppPermissions } from "../../../../utils/permissions";
import {
  EVENT,
  PAGE,
  getTableDetailProps,
  trackEvent,
} from "../../../../utils/telemetry";
import { hasPermission } from "../../../../utils/uri-path";
import { getIconFromColumn } from "../../column-icons";
import { CollapseIcon, DotIcon, ExpandIcon, ViewIcon, ViewItemIcon } from "../../icons";
import { DisableMetricIcon } from "../../menu-icons";
import { treeNodeColumnPermissions } from "../../tree-data";
import { TableMetricHealthyType, TableMetricHealthyTypeToColorMapper } from "./utils";
import { collectStats } from "../../../../utils/iterables";

import { ProfilerAfterTreeNavigationActionType } from "../../../../actions/profiler/profiler-action";
import ColumnMaskedIndicator from "../../../../components/column/column-masked-indicator";
import Divider from "../../../../atom/divider";
import "./table-health-summary-panel-template.scss";

export function TableMetricsInfoGridItem(props) {
  const { tooltipComponent: TooltipComponent, data, onClick } = props;
  const content = (
    <div
      className="table-metrics-info-grid-item-container"
      onClick={() => {
        onClick(data);
      }}
      style={{
        backgroundColor: TableMetricHealthyTypeToColorMapper[data.healthyType],
        width: 25,
        height: 25,
        borderRadius: "3px",
      }}
    />
  );

  return (
    <Popover
      color={NextGenPalette.systemBlack}
      content={<TooltipComponent data={data} />}
      title=""
    >
      {content}
    </Popover>
  );
}

export const focusedItemClassName = "table-metrics-info-summary-table-selected-row";

function TableHealthPopContentTemplate(props) {
  const { title, attributeListConfig } = props;

  return (
    <div className="table-metrics-info-grid-item-popup-content-container">
      {title && (
        <div className="table-metrics-info-grid-item-popup-content-metric-name-container">
          {title}
        </div>
      )}
      <div className="table-metrics-info-grid-item-popup-content-attribute-list-container">
        {attributeListConfig.map(({ label, value }) => (
          <div
            className="table-metrics-info-grid-item-popup-content-attribute-row-container"
            key={label}
          >
            <div className="table-metrics-info-grid-item-popup-content-attribute-row-label-container">
              {label}
            </div>
            <div className="table-metrics-info-grid-item-popup-content-attribute-row-value-container">
              {value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function MetricNameWithIcon(props) {
  const { metricName, metricType } = props;
  const TypeIcon = metricCategoryIconComponent(metricType);
  const icon = TypeIcon ? <TypeIcon width={14} height={14} /> : null;
  return (
    <TextWithIcon icon={icon} iconPosition="left">
      {metricName}
    </TextWithIcon>
  );
}

export function TableMetricsInfoGridItemPopupContent(props) {
  const {
    metricName,
    metricType,
    totalMonitorsCount,
    isLive,
    activeMonitorsCount,
    pausedMonitorsCount,
    activeIncidentsCount,
    pausedIncidentsCount,
  } = props.data;

  let monitorStatusValue;
  if (totalMonitorsCount === 0) {
    monitorStatusValue = "Unmonitored";
  } else {
    const monitorStatusArray = [];
    if (activeMonitorsCount > 0) {
      monitorStatusArray.push(
        totalMonitorsCount > 1 ? `${activeMonitorsCount} Live` : "Live"
      );
    }

    if (pausedMonitorsCount > 0) {
      monitorStatusArray.push(
        totalMonitorsCount > 1 ? `${pausedMonitorsCount} Paused` : "Paused"
      );
    }

    monitorStatusValue = monitorStatusArray.join(", ");
  }

  const attributeListConfig = [
    { label: "Incidents", value: activeIncidentsCount + pausedIncidentsCount },
    { label: "Metric status", value: isLive ? "Live" : "Paused" },
    {
      label: "Type",
      value: metricTypeNames[metricType],
    },
    { label: "Monitors", value: totalMonitorsCount },
    { label: "Monitor status", value: monitorStatusValue },
  ];

  return (
    <TableHealthPopContentTemplate
      title={<MetricNameWithIcon metricName={metricName} metricType={metricType} />}
      attributeListConfig={attributeListConfig}
    />
  );
}

export function MetricLinkMenu(props) {
  const {
    table,
    metric,
    columnUuid,
    workspaceUserPermissions,
    onViewClick,
    onToggleMetric,
    onMonitorMetric,
  } = props;

  const canModifyTableSettings = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
  ]);
  const canViewMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
  ]);
  const canAddMonitor = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
  ]);

  if (!canViewMetric && !canModifyTableSettings) {
    return metric.metadata.name;
  }

  const trigger = <NgTableClickableText>{metric.metadata.name}</NgTableClickableText>;

  const menuItems = [];

  if (canViewMetric) {
    menuItems.push({
      label: "View in explorer",
      icon: <ViewIcon />,
      onClick: () => onViewClick?.(metric),
    });
  }

  const isMonitoredActivityMetric =
    isActivityMetric(metric) &&
    table?.profilerConfig?.metadataMetrics?.schemaChange?.monitoring?.enabled;
  if (canAddMonitor && !isMonitoredActivityMetric) {
    menuItems.push({
      label: "Monitor metric",
      icon: <OrderedListOutlined />,
      onClick: () => {
        trackEvent(EVENT.ADD_MONITOR);
        onMonitorMetric?.(metric);
      },
    });
  }

  if (canModifyTableSettings) {
    menuItems.push({
      label: isAutoCreationTypeMetric(metric) ? "Disable metric" : "Delete metric",
      icon: <DisableMetricIcon fillColor={NextGenPalette.systemBlack} />,
      onClick: () => {
        onToggleMetric(getMetricTypeFromConfigData(metric), metric, columnUuid, false);
      },
    });
  }

  return (
    <NgDropdownMenu trigger={trigger} menuItems={menuItems} position="bottomLeft" />
  );
}

function ColumnLinkMenu(props) {
  const { columnName, columnUuid, workspaceUserPermissions, onViewClick } = props;
  const canViewColumn = hasPermission(
    workspaceUserPermissions,
    treeNodeColumnPermissions
  );

  if (canViewColumn) {
    const trigger = <NgTableClickableText>{columnName}</NgTableClickableText>;
    const menuItems = [
      {
        label: "View column",
        icon: <ViewIcon />,
        onClick: () => onViewClick?.({ columnName, columnUuid }),
      },
    ];
    return (
      <NgDropdownMenu trigger={trigger} menuItems={menuItems} position="bottomLeft" />
    );
  } else {
    return columnName;
  }
}

export const incidentsColumnFn = columnFn({
  title: "Incidents",
  key: "totalIncidentsCount",
  render: (totalIncidentsCount) => {
    return totalIncidentsCount > 0 ? (
      <div className="table-metrics-info-summary-table-incident-count-cell">
        <WarningTwoTone twoToneColor={NextGenPalette.criticalRed} />
        {totalIncidentsCount}
      </div>
    ) : (
      totalIncidentsCount
    );
  },
});

export function getTableMetricsTableColumns(
  workspaceUserPermissions,
  table,
  actions = {},
  isIncludeColumn = false
) {
  const columns = [
    columnFn({
      title: "Metric",
      key: "metricName",
      defaultSortOrder: "ascend",
      getCompareVal: (metricName) => (metricName ?? "").toLowerCase(),
      render: (metricName, rowData) => {
        const { viewOnly, configurableType } = rowData;
        if (viewOnly) {
          return metricName;
        } else if (configurableType) {
          const requiresConfiguration =
            tableAutoMetricTypes.has(configurableType) && !table.profilerConfig.enabled;
          return (
            <div className="table-metric-enable-button-cell">
              {metricName}
              {requiresConfiguration ? (
                <NgButton
                  color="blue"
                  size="small"
                  onClick={() => actions.onConfigureTable(table)}
                >
                  <span className="table-metric-enable-button-text">
                    Configure table
                  </span>
                </NgButton>
              ) : (
                <NgButton
                  color="purple"
                  size="small"
                  onClick={() =>
                    actions.onToggleMetric(configurableType, null, null, true)
                  }
                >
                  <span className="table-metric-enable-button-text">Enable</span>
                </NgButton>
              )}
            </div>
          );
        }

        return (
          <MetricLinkMenu
            table={table}
            metric={rowData.metric}
            columnUuid={rowData.columnUuid}
            onViewClick={actions?.onViewMetric || null}
            onMonitorMetric={actions?.onMonitorMetric || null}
            onToggleMetric={actions?.onToggleMetric || null}
            workspaceUserPermissions={workspaceUserPermissions}
          />
        );
      },
    })({ dataIndex: "metricName" }),
  ];

  if (isIncludeColumn) {
    columns.push(
      columnFn({
        title: "Column",
        key: "columnName",
        render: (columnName, rowData) => {
          return (
            <ColumnLinkMenu
              columnName={columnName}
              columnUuid={rowData.columnUuid}
              onViewClick={actions?.onViewColumn || null}
              workspaceUserPermissions={workspaceUserPermissions}
            />
          );
        },
      })({
        dataIndex: "columnName",
      })
    );
  }

  columns.push(
    columnFn({ title: "Monitors", key: "totalMonitorsCount" })({
      dataIndex: "totalMonitorsCount",
      width: 120,
    }),
    incidentsColumnFn({
      dataIndex: "totalIncidentsCount",
      width: 120,
    })
  );

  return columns;
}

function ColumnNameWithIcon(props) {
  const { columnName, columnTypeCategory } = props;
  const TypeIcon = getIconFromColumn({ columnTypeCategory });
  const icon = TypeIcon ? <TypeIcon /> : null;
  return (
    <TextWithIcon icon={icon} iconPosition="left">
      {columnName}
    </TextWithIcon>
  );
}

export function TableColumnHealthInfoGridItemPopupContent(props) {
  const {
    columnName,
    columnTypeCategory,
    totalMonitorsCount,
    totalMetricsCount,
    totalIncidentsCount,
    activeMonitorsCount,
    pausedMonitorsCount,
  } = props.data;
  const attributeListConfig = [
    { label: "Incidents", value: totalIncidentsCount },
    { label: "Metrics", value: totalMetricsCount },
    { label: "Monitors", value: totalMonitorsCount },
    { label: "Active monitors", value: activeMonitorsCount },
    { label: "Paused monitors", value: pausedMonitorsCount },
  ];

  return (
    <TableHealthPopContentTemplate
      title={
        <ColumnNameWithIcon
          columnName={columnName}
          columnTypeCategory={columnTypeCategory}
        />
      }
      attributeListConfig={attributeListConfig}
    />
  );
}

export function getTableColumnHealthTableColumns(
  workspaceUserPermissions,
  actions,
  tableColumnList
) {
  return [
    columnFn({
      title: "Column",
      key: "ColumnName",
      defaultSortOrder: "ascend",
      render: (columnName, rowData) => {
        const columnInfo = tableColumnList.find(
          (column) => column.uuid === rowData.columnUuid
        );
        const isMasked = columnInfo?.profilerConfig?.masked;

        return (
          <div className="table-health-table-column-name">
            {isMasked && <ColumnMaskedIndicator tooltip="Column Masked" />}
            <ColumnLinkMenu
              columnName={columnName}
              columnUuid={rowData.columnUuid}
              onViewClick={actions?.onViewColumn || null}
              workspaceUserPermissions={workspaceUserPermissions}
            />
          </div>
        );
      },
    })({ dataIndex: "columnName" }),
    columnFn({ title: "Metrics", key: "totalMetricsCount" })({
      dataIndex: "totalMetricsCount",
      width: 120,
    }),
    incidentsColumnFn({
      dataIndex: "totalIncidentsCount",
      width: 120,
    }),
  ];
}

function commonTableHealthSummaryPanelMenuItems(args) {
  const {
    dataSource,
    schema,
    table,
    workspaceUserPermissions,
    onCreateCustomMetric,
    setProfilerAfterTreeNavigationAction,
  } = args;

  const canCreateMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ]);
  const canMonitorMetrics = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
  ]);

  const menuItems = [];

  if (canCreateMetric) {
    menuItems.push({
      label: "Create metric",
      icon: <PlusOutlined />,
      onClick: () => onCreateCustomMetric(dataSource, schema, table),
    });
  }

  if (canMonitorMetrics) {
    menuItems.push({
      label: "Monitor metrics",
      icon: <OrderedListOutlined />,
      onClick: () => {
        setProfilerAfterTreeNavigationAction({
          type: ProfilerAfterTreeNavigationActionType.TABLE_MONITOR_ALL_METRICS,
          dataSource,
          schemaInfo: schema,
          tableInfo: table,
        });
      },
    });
  }

  return menuItems;
}

export function TableHealthTableSummaryPanelMenu(props) {
  const {
    dataSource,
    schema,
    table,
    workspaceUserPermissions,
    onCreateCustomMetric,
    setProfilerAfterTreeNavigationAction,
  } = props;

  const menuItems = commonTableHealthSummaryPanelMenuItems({
    dataSource,
    schema,
    table,
    workspaceUserPermissions,
    onCreateCustomMetric,
    setProfilerAfterTreeNavigationAction,
  });

  return menuItems.length > 0 ? (
    <NgDropdownMenu
      trigger={
        <div
          className="table-health-summary-panel-menu-icon"
          data-testid="table-health-table-context-menu"
        >
          <ViewItemIcon />
        </div>
      }
      menuItems={menuItems}
      position="bottomRight"
    />
  ) : null;
}

export function TableHealthColumnSummaryPanelMenu(props) {
  const {
    dataSource,
    isViewColumn,
    schema,
    table,
    workspaceUserPermissions,
    onCreateCustomMetric,
    onViewByClick,
    setProfilerAfterTreeNavigationAction,
  } = props;

  const toggleViewByClick = () => {
    trackEvent(
      isViewColumn
        ? EVENT.VIEW_COLUMN_HEALTH_BY_METRIC
        : EVENT.VIEW_COLUMN_HEALTH_BY_COLUMN,
      {
        ...getTableDetailProps(table, schema, dataSource),
        page: PAGE.EXPLORER_TABLE_TABLE_HEALTH,
      }
    );

    onViewByClick(!isViewColumn);
  };

  const menuItems = [
    {
      label: isViewColumn ? "View by metric" : "View by column",
      icon: <ViewIcon />,
      onClick: toggleViewByClick,
    },
    ...commonTableHealthSummaryPanelMenuItems({
      dataSource,
      schema,
      table,
      workspaceUserPermissions,
      onCreateCustomMetric,
      setProfilerAfterTreeNavigationAction,
    }),
  ];

  return (
    <NgDropdownMenu
      trigger={
        <div
          className="table-health-summary-panel-menu-icon"
          data-testid="table-health-column-context-menu"
        >
          <ViewItemIcon />
        </div>
      }
      menuItems={menuItems}
      position="bottomRight"
    />
  );
}

function TableHealthOverallStatsValueCell(props) {
  const { type, value, entity = "metric" } = props;
  const displayValue = `${value} ${entity}${value !== 1 ? "s" : ""}`;
  return (
    <span style={{ display: "flex", alignItem: "center" }}>
      <DotIcon fillColor={TableMetricHealthyTypeToColorMapper[type]} />
      {displayValue}
    </span>
  );
}

export function TableHealthOverallStatsInfoPopOver(props) {
  const { data, entity } = props;
  const { healthyMetricsCount, unhealthyMetricsCount, unmonitoredMetricsCount } = data;
  const tooltipContent = (
    <TableHealthPopContentTemplate
      attributeListConfig={[
        {
          label: "Healthy",
          value: (
            <TableHealthOverallStatsValueCell
              value={healthyMetricsCount}
              type={TableMetricHealthyType.HEALTHY}
              entity={entity}
            />
          ),
        },
        {
          label: "Unhealthy",
          value: (
            <TableHealthOverallStatsValueCell
              value={unhealthyMetricsCount}
              type={TableMetricHealthyType.UNHEALTHY}
              entity={entity}
            />
          ),
        },
        {
          label: "Unmonitored",
          value: (
            <TableHealthOverallStatsValueCell
              value={unmonitoredMetricsCount}
              type={TableMetricHealthyType.UNMONITORED}
              entity={entity}
            />
          ),
        },
      ]}
    />
  );

  return (
    <Popover color={NextGenPalette.systemBlack} content={tooltipContent} title="">
      <InfoCircleOutlined />
    </Popover>
  );
}

export function TableHealthMetricsPanel(props) {
  const {
    data,
    children,
    isExpand,
    onIsExpandChange,
    icon,
    label,
    entity,
    contextMenu,
  } = props;

  const alwaysExpand = !onIsExpandChange;

  function toggleExpand() {
    onIsExpandChange(!isExpand);
  }

  const statsData = collectStats(data, {
    healthyMetricsCount: (item) => item.healthyType === TableMetricHealthyType.HEALTHY,
    unhealthyMetricsCount: (item) =>
      item.healthyType === TableMetricHealthyType.UNHEALTHY,
    unmonitoredMetricsCount: (item) =>
      item.healthyType === TableMetricHealthyType.UNMONITORED,
  });

  return (
    <div className="table-metrics-info-summary-container">
      <div className="table-metrics-info-summary-header-container">
        <div className="table-metrics-info-summary-header-title-container">
          {icon}
          {label}
          <span className="table-metrics-info-summary-header-title-count">{`(${data.length})`}</span>
          <TableHealthOverallStatsInfoPopOver data={statsData} entity={entity} />
        </div>
        <div className="table-metrics-info-summary-header-action-container">
          {contextMenu && (
            <>
              <div className="table-metrics-info-summary-header-context-control-container">
                {contextMenu}
              </div>
              {!alwaysExpand && <Divider type="vertical" style={{ height: 20 }} />}
            </>
          )}
          {!alwaysExpand && (
            <div
              className="table-metrics-info-summary-header-expand-control-container"
              onClick={toggleExpand}
            >
              {isExpand ? <CollapseIcon /> : <ExpandIcon />}
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

export function TableHealthMetricsSubPanel(props) {
  const { data, children, isExpand, onIsExpandChange, label, entity } = props;

  const toggleExpand = () => {
    onIsExpandChange(!isExpand);
  };

  const statsData = collectStats(data, {
    healthyMetricsCount: (item) => item.healthyType === TableMetricHealthyType.HEALTHY,
    unhealthyMetricsCount: (item) =>
      item.healthyType === TableMetricHealthyType.UNHEALTHY,
    unmonitoredMetricsCount: (item) =>
      item.healthyType === TableMetricHealthyType.UNMONITORED,
  });

  return (
    <div className="table-metrics-info-summary-sub-container">
      <div className="table-metrics-info-summary-sub-header-container">
        <div className="table-metrics-info-summary-sub-header-title-container">
          {label}
          <span className="table-metrics-info-summary-sub-header-title-count">{`(${data.length})`}</span>
          <TableHealthOverallStatsInfoPopOver data={statsData} entity={entity} />
        </div>
        <div className="table-metrics-info-summary-sub-header-action-container">
          <div
            className="table-metrics-info-summary-sub-header-expand-control-container"
            onClick={toggleExpand}
          >
            {isExpand ? <CollapseIcon /> : <ExpandIcon />}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export function TableHealthMetricsGridAndTable(props) {
  const {
    isExpand,
    focusedItem,
    onFocusedItemChange,
    columns,
    data,
    tooltipComponent,
  } = props;

  const [isFocusedItemClickHandled, setIsFocusedItemClickHandled] = useState(true);

  const configuredData = data.filter((data) => !data.configurableType);

  useEffect(() => {
    if (!isFocusedItemClickHandled) {
      setIsFocusedItemClickHandled(true);
      const selectedItems = document.querySelector(
        `[data-row-key="${focusedItem.key}"]`
      );
      selectedItems?.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
  }, [isFocusedItemClickHandled, focusedItem]);

  return (
    <>
      {configuredData.length > 0 && (
        <div className="table-metrics-info-summary-grid-container">
          {configuredData.map((dataItem) => (
            <TableMetricsInfoGridItem
              key={dataItem.key}
              data={dataItem}
              tooltipComponent={tooltipComponent}
              onClick={(data) => {
                if (focusedItem?.key !== data.key) {
                  onFocusedItemChange(data);
                  setIsFocusedItemClickHandled(false);
                } else {
                  onFocusedItemChange(null);
                }
              }}
            />
          ))}
        </div>
      )}
      {isExpand && data.length > 0 && (
        <div className="table-metrics-info-summary-table-container">
          <NgTable
            theme={NgTableTheme.LIGHT}
            dataSource={data}
            columns={columns}
            size={"middle"}
            rowClassName={(record) =>
              record.key === focusedItem?.key ? focusedItemClassName : ""
            }
            pagination={false}
            sortDirections={["ascend", "descend", "ascend"]}
            // Max width before scrolling will show 10 items.
            scroll={{ y: 420 }}
          />
        </div>
      )}
    </>
  );
}
