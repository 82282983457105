import React from "react";

export default function DeleteIcon({ onClick = null }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" onClick={onClick}>
      <path
        d="M5.54151 9.625C5.21935 9.625 4.95818 9.88617 4.95818 10.2083C4.95818 10.5305 5.21935 10.7917 5.54151 10.7917H8.45819C8.78035 10.7917 9.04152 10.5305 9.04152 10.2083C9.04152 9.88617 8.78035 9.625 8.45819 9.625H5.54151Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.72485 0.875C4.44071 0.875 4.19791 1.07972 4.1499 1.35977L3.883 2.91667H1.74984C1.42767 2.91667 1.1665 3.17783 1.1665 3.5C1.1665 3.82217 1.42767 4.08333 1.74984 4.08333H2.66126L3.20939 12.5792C3.22919 12.8862 3.48392 13.125 3.79151 13.125H10.2082C10.5158 13.125 10.7705 12.8862 10.7903 12.5792L11.3384 4.08333H12.2499C12.572 4.08333 12.8332 3.82217 12.8332 3.5C12.8332 3.17783 12.572 2.91667 12.2499 2.91667H10.1167L9.8498 1.35977C9.80179 1.07972 9.55899 0.875 9.27485 0.875H4.72485ZM8.93301 2.91667H5.06669L5.21669 2.04167H8.78301L8.93301 2.91667ZM4.33842 11.9583L3.83036 4.08333H10.1693L9.66128 11.9583H4.33842Z"
        fill="#121224"
      />
    </svg>
  );
}

export function AntdDeleteIcon({ onClick = null }) {
  return (
    <span role="img" className="anticon" style={{ cursor: "pointer" }}>
      <DeleteIcon onClick={onClick} />
    </span>
  );
}
