import React from "react";
import {
  SeasonalityConfigItem,
  QueryTimezoneConfigItem,
  AggregationWindowConfigItem,
  SynchronizationDelayConfigItem,
} from "../../fields/";
import { aggregationWindowOptions } from "../../utils";
import SummaryViewSectionTitle from "../summary-view-section-title";

function MetadataMetricConfigDataAssetSummaryCardContent(props) {
  const { configData } = props;

  return (
    <>
      <SummaryViewSectionTitle title="Metric Configuration" />
      <SynchronizationDelayConfigItem
        label="Polling delay"
        value={configData.config.pollingDelay}
        isEdit={false}
      />
      <QueryTimezoneConfigItem
        label="Polling timezone"
        value={configData.config.pollingTimezone}
        isEdit={false}
      />
      <AggregationWindowConfigItem
        label="Polling interval"
        value={configData.config.pollingWindow}
        options={aggregationWindowOptions}
        isEdit={false}
      />
      <SeasonalityConfigItem
        status={configData.status}
        value={configData.config.seasonality || null}
        isEdit={false}
      />
    </>
  );
}

export default MetadataMetricConfigDataAssetSummaryCardContent;
