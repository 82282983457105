import { getCollectionModeTypeDisplayName } from "../../../utils/enums";
import {
  getMetricStatusSearchOptions,
  getQueryScopeLabel,
  metricCreationTypeLabel,
} from "../../../utils/metric";
import { displayTableName, getColumnNames } from "../../entity-list/columns";
import { metricTypeNames } from "../../metric/utils";
import {
  getStringCompareFunction,
  stringListCompareFunction,
} from "../../../utils/search";

export const GENERAL_SEARCH_FIELD = "searchName";

const stringCompareFunction = getStringCompareFunction(false);
const strictStringCompareFunction = getStringCompareFunction(true);

export const metricFields = {
  DATA_SOURCE_NAME: "dataSourceName",
  METRIC_TYPE: "metricType",
  COLLECTION_SCHEDULE: "collectionSchedule",
  STATUS: "status",
  QUERY_SCOPE: "queryScope",
  DIMENSION: "dimension",
  CREATED_BY: "createdBy",
  LAST_MODIFY_BY: "lastModifyBy",
  CREATION_TYPE: "creationType",
  TABLE_NAME: "tableName",
  COLUMN_NAME: "columnName",
  METRIC_ID: "metricId",
  TAG_NAME: "tagName",
  KPI_NAME: "kpiName",
};

export const metricFieldOptionValueGetterFn = {
  [metricFields.METRIC_ID]: (metric) => metric.metadata.idSerial,
  [metricFields.KPI_NAME]: (metric) => metric.metadata.name,
  [metricFields.COLLECTION_SCHEDULE]: (metric) =>
    getCollectionModeTypeDisplayName(metric.config.collectionMode?.type),
  [metricFields.DIMENSION]: (metric) => metric.config.dimension,
  [metricFields.CREATED_BY]: (metric) => metric.metadata.ownedBy?.username,
  [metricFields.LAST_MODIFY_BY]: (metric) => metric.metadata.updatedBy?.username,
  [metricFields.QUERY_SCOPE]: getQueryScopeLabel,
  [metricFields.STATUS]: getMetricStatusSearchOptions,
  [metricFields.CREATION_TYPE]: metricCreationTypeLabel,
  [metricFields.TAG_NAME]: (metric) => metric.metadata.tags,
  [metricFields.TABLE_NAME]: displayTableName,
  [metricFields.COLUMN_NAME]: getColumnNames,
};

export const metricFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    const metricTagName = row.metricData.metadata.tags || [];
    return !stringListCompareFunction(
      [
        row.metricData.metadata.name,
        ...metricTagName,
        ...row.dataSourceNames,
        row.displayTableName,
      ],
      searchName,
      stringCompareFunction
    );
  },
  [metricFields.METRIC_ID]: (row, metricId) => {
    return !metricId.includes(row.metricData.metadata.idSerial.toString());
  },
  [metricFields.KPI_NAME]: (row, metricName) => {
    return !stringCompareFunction(row.metricData.metadata.name, metricName);
  },
  [metricFields.TABLE_NAME]: (row, tableName) => {
    return !stringCompareFunction(row.displayTableName, tableName);
  },
  [metricFields.TAG_NAME]: (row, tagName) => {
    const metricTagName = row.metricData.metadata.tags || [];
    return !stringListCompareFunction(metricTagName, tagName, stringCompareFunction);
  },
  [metricFields.DATA_SOURCE_NAME]: (row, dataSourceName) => {
    return !stringListCompareFunction(
      row.dataSourceNames,
      dataSourceName,
      stringCompareFunction
    );
  },
  [metricFields.METRIC_TYPE]: (row, metricType) => {
    return !stringCompareFunction(metricTypeNames[row.metricType], metricType);
  },
  [metricFields.COLUMN_NAME]: (row, columnName) => {
    return !row.metricData?.config?.valueColumns?.some((column) =>
      stringCompareFunction(column.columnName, columnName)
    );
  },
  [metricFields.COLLECTION_SCHEDULE]: (row, collectionSchedule) => {
    return !strictStringCompareFunction(
      getCollectionModeTypeDisplayName(row.metricData.config.collectionMode?.type),
      collectionSchedule
    );
  },
  [metricFields.DIMENSION]: (row, dimension) => {
    return !strictStringCompareFunction(row.metricData.config.dimension, dimension);
  },
  [metricFields.CREATED_BY]: (row, createdBy) => {
    return !stringCompareFunction(row.metricData.metadata.ownedBy?.username, createdBy);
  },
  [metricFields.LAST_MODIFY_BY]: (row, lastModifyBy) => {
    return !stringCompareFunction(
      row.metricData.metadata.updatedBy?.username,
      lastModifyBy
    );
  },
  [metricFields.STATUS]: (row, status) => {
    return !stringListCompareFunction(
      getMetricStatusSearchOptions(row.metricData),
      status,
      strictStringCompareFunction
    );
  },
  [metricFields.QUERY_SCOPE]: (row, queryScope) => {
    return !strictStringCompareFunction(getQueryScopeLabel(row.metricData), queryScope);
  },
  [metricFields.CREATION_TYPE]: (row, creationType) => {
    return !stringCompareFunction(
      metricCreationTypeLabel(row.metricData),
      creationType
    );
  },
};
