import React, { useState } from "react";
import IntegrationDialogFooter from "../integration-dialog-footer";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../components/modal-dialog/modal-field-group";
import {
  LabeledInput,
  LabeledSelect,
} from "../../../components/labeled-control/labeled-control";
import { TimezoneOptions } from "../../../utils/options";
import { getAppDomainName } from "../../../utils/general";
import TakeoverPanel from "../../../components/takeover/takeover-panel";
import NgButton from "../../../components/button/ng-button";
import {
  IntegrationMetadataEditor,
  MetadataAuthenticationButton,
  SubmitModeSelector,
  getMetadataEditStatus,
} from "../integration-metadata-editor";
import IntegrationMetadataTestTicketModal from "../integration-metadata-test-ticket-modal";
import { AlertingChannelType } from "../../../utils/alerting";
import { getBranchLeaf, isBranch } from "../integration-metadata";

import "./servicenow-integration-basic-dialog.scss";

export function getServiceNowAuthFields(value) {
  return {
    host: value.host,
    username: value.username,
    password: value.password,
  };
}

export function canAttemptAuthentication(value) {
  const { host, username, password } = value;
  return host && username && password;
}

function ServiceNowIntegrationBasicDialogBodyComponent(props) {
  const {
    value,
    authenticationStatus,
    currentMetadata,
    enablePreview,
    onChange,
    onPreviewClick,
    onAuthenticateClick,
  } = props;

  const { mode, title, host, username, password, timezone } = value;

  const { onlyEditMetadataAvailable } = getMetadataEditStatus(
    currentMetadata.data,
    value.metadata
  );

  return (
    <div className="service-now-integration-basic-dialog">
      <ModalFieldGroup>
        <ModalFieldRows>
          <SubmitModeSelector
            value={mode}
            onChange={(newMode) => onChange({ ...value, mode: newMode })}
          />
          <LabeledInput
            label="Name"
            value={title}
            onChange={(e) => onChange({ ...value, title: e.target.value })}
          />
          <LabeledInput
            label="Host"
            value={host}
            onChange={(e) => onChange({ ...value, host: e.target.value })}
          />
          <LabeledInput
            label="Username"
            value={username}
            onChange={(e) => onChange({ ...value, username: e.target.value })}
          />
          <LabeledInput
            label="Password"
            type="password"
            value={password}
            onChange={(e) => onChange({ ...value, password: e.target.value })}
          />
          <LabeledSelect
            showSearch
            label="Timezone"
            options={TimezoneOptions}
            value={timezone}
            onChange={(newTimezone) => onChange({ ...value, timezone: newTimezone })}
          />
          <MetadataAuthenticationButton
            authenticationStatus={authenticationStatus}
            onlyEditMetadataAvailable={onlyEditMetadataAvailable}
            onClick={onAuthenticateClick}
            disabled={!canAttemptAuthentication(value)}
          />
        </ModalFieldRows>
      </ModalFieldGroup>
      <IntegrationMetadataEditor
        type={AlertingChannelType.SERVICENOW}
        value={value}
        currentMetadata={currentMetadata}
        onChange={onChange}
      />
      <div className="servicenow-integration-basic-dialog-create-test-ticket">
        <NgButton
          primary
          size="large"
          disabled={!enablePreview || !canAttemptAuthentication(value)}
          onClick={onPreviewClick}
        >
          Create test ticket
        </NgButton>
      </div>
    </div>
  );
}

function ServiceNowIntegrationBasicDialog(props) {
  const {
    value,
    currentMetadata,
    dialogTitle = "",
    OKText,
    setIsOpen,
    enableSave,
    authButtonStatus,
    enablePreview,
    onChange,
    onOkClicked,
    onPreview,
    onAuthenticate,
  } = props;

  const [testTicketModalOpen, setTestTicketModalOpen] = useState(false);

  function getNewServiceNowIntegration(integration) {
    return {
      ...integration,
      type: AlertingChannelType.SERVICENOW,
      domainName: getAppDomainName(),
    };
  }

  function closePanel() {
    setIsOpen(false);
  }

  function onOkClick() {
    onOkClicked(getNewServiceNowIntegration(value));
    closePanel();
  }

  function onEditTestTicketClick() {
    if (
      !value.metadata?.type ||
      (isBranch(value.metadata) && getBranchLeaf(value.metadata).fields.length === 0)
    ) {
      onCreateTestTicket(value);
    } else {
      setTestTicketModalOpen(true);
    }
  }

  function onCreateTestTicket(integration) {
    onPreview(getNewServiceNowIntegration(integration)).then(() => {
      setTestTicketModalOpen(false);
    });
  }

  function onAuthenticateClick() {
    onAuthenticate(getNewServiceNowIntegration(value));
  }

  const cornerControls = (
    <IntegrationDialogFooter
      onCancelClick={closePanel}
      onOkClick={onOkClick}
      OkText={OKText}
      enableSave={enableSave}
      previewText={null}
    />
  );

  return (
    <>
      <TakeoverPanel title={dialogTitle} cornerControls={cornerControls}>
        <ServiceNowIntegrationBasicDialogBodyComponent
          value={value}
          authenticationStatus={authButtonStatus}
          currentMetadata={currentMetadata}
          onChange={onChange}
          enablePreview={enablePreview}
          onPreviewClick={onEditTestTicketClick}
          onAuthenticateClick={onAuthenticateClick}
        />
      </TakeoverPanel>
      <IntegrationMetadataTestTicketModal
        type={AlertingChannelType.SERVICENOW}
        key={testTicketModalOpen}
        value={value}
        visible={testTicketModalOpen}
        onCreateTestTicket={onCreateTestTicket}
        onCancel={() => setTestTicketModalOpen(false)}
      />
    </>
  );
}

export default ServiceNowIntegrationBasicDialog;
