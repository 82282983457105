import userInitialState from "./user-initial-state";
import {
  GET_CURRENT_USER,
  SET_CURRENT_USER,
  SET_USER_LIST,
  SET_USER_API_KEY_LIST,
  SET_CURRENT_ADDED_USER_API_KEY,
  SET_USER_PERMISSION_MAP,
} from "../../actions/user/user-action-types";

export default function userReducer(state = userInitialState, action = {}) {
  let newState;

  switch (action.type) {
    case GET_CURRENT_USER:
      return action;

    case SET_CURRENT_USER:
      const { currentUserInfo } = state;
      newState = {
        ...state,
        currentUserInfo: { ...currentUserInfo, ...action.userInfo },
      };
      return newState;

    case SET_USER_LIST:
      newState = { ...state, userList: action.userList };
      return newState;

    case SET_USER_API_KEY_LIST:
      newState = { ...state, userAPIKeyList: action.userAPIKeyList };
      return newState;

    case SET_CURRENT_ADDED_USER_API_KEY:
      newState = {
        ...state,
        currentAddedUserAPIKey: action.currentAddedUserAPIKey,
      };
      return newState;

    case SET_USER_PERMISSION_MAP:
      newState = {
        ...state,
        workspaceUserPermissionMap: action.workspaceUserPermissionMap,
      };
      return newState;

    default:
      return state;
  }
}
