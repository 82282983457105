import { organizationId } from "./analytics";

export function initializePendo(userInfo) {
  window.pendoSettings = {
    visitor: {
      id: userInfo.externalId, // Required if user is logged in
      email: userInfo.email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: `${userInfo.firstName} ${userInfo.lastName}`, // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },
    account: {
      id: organizationId(), // Highly recommended, required if using Pendo Feedback
      name: organizationId(), // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  };

  if (window.pendo) {
    window.pendo.initialize(window.pendoSettings);
  } else {
    console.warn("Attempted to initialize Pendo but the Pendo snippet was not found.");
  }
}
