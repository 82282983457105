import React from "react";
import StatusBadge from "../../components/status-badge";
import { LiveIcon, InactiveIcon } from "../../components/icons/status";
import { PauseIcon } from "../../views/profiler/menu-icons";

function DataSourceStatusBadge(props) {
  const { datasource, clickable } = props;

  if (!datasource.config.isLive) {
    return <StatusBadge title="Paused" Icon={PauseIcon} clickable={clickable} />;
  }

  const isEnabled = datasource.config.profiler.enabled;

  return isEnabled ? (
    <StatusBadge title="Enabled" Icon={LiveIcon} clickable={clickable} />
  ) : (
    <StatusBadge title="Disabled" Icon={InactiveIcon} clickable={clickable} />
  );
}

export default DataSourceStatusBadge;
