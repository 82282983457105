const OPERATOR_PRECEDENCE = { not: 3, and: 2, or: 1 };
const UNARY_OPERATORS = new Set(["not"]);
const BINARY_OPERATORS = new Set(["and", "or"]);
const OPERATORS = new Set([...UNARY_OPERATORS, ...BINARY_OPERATORS]);

function getInvalidCharacter(tokensStr, expressionStr) {
  if (tokensStr.length > expressionStr.length) {
    return "$";
  }
  for (let i = 0; i < tokensStr.length; i++) {
    if (tokensStr[i] !== expressionStr[i]) {
      return expressionStr[i];
    }
  }
  if (expressionStr.length > tokensStr.length) {
    return expressionStr[tokensStr.length];
  }
  return null;
}

function assertInfixExpressions(tokens) {
  if (OPERATORS.has(tokens[tokens.length - 1])) {
    throw new Error("Operator incomplete");
  }
  if (BINARY_OPERATORS.has(tokens[0])) {
    throw new Error("Operator incomplete");
  }
  for (let i = 1; i < tokens.length; i++) {
    if (BINARY_OPERATORS.has(tokens[i]) && BINARY_OPERATORS.has(tokens[i - 1])) {
      throw new Error("Missing operator");
    }
    if (UNARY_OPERATORS.has(tokens[i]) && UNARY_OPERATORS.has(tokens[i - 1])) {
      throw new Error("Missing operator");
    }
    if (/^\d+$/.test(tokens[i]) && /^\d+$/.test(tokens[i - 1])) {
      throw new Error("Operator incomplete");
    }
  }
}

export function tokenize(expression) {
  const normalizeExpression = expression?.toLowerCase().trim() ?? "";
  const pattern = /\b\d+\b|\b(?:or|and|not)\b|\(|\)/g;
  const tokens = normalizeExpression.match(pattern) ?? [];
  const invalidCharacter = getInvalidCharacter(
    tokens.join(""),
    normalizeExpression.replace(/\s+/g, "")
  );
  if (invalidCharacter !== null) {
    throw new Error(`Unrecognized input "${invalidCharacter}"`);
  }
  return tokens;
}

function buildPostfixQueue(tokens) {
  const postfixQueue = [];
  const operatorStack = [];

  // isn't an infix expression
  assertInfixExpressions(tokens);

  for (const token of tokens) {
    if (OPERATORS.has(token)) {
      while (
        operatorStack.length > 0 &&
        OPERATOR_PRECEDENCE[operatorStack[operatorStack.length - 1]] >=
          OPERATOR_PRECEDENCE[token]
      ) {
        postfixQueue.push(operatorStack.pop());
      }
      operatorStack.push(token);
    } else if (token === "(") {
      operatorStack.push(token);
    } else if (token === ")") {
      while (
        operatorStack.length > 0 &&
        operatorStack[operatorStack.length - 1] !== "("
      ) {
        postfixQueue.push(operatorStack.pop());
      }
      if (operatorStack.length === 0) {
        throw new Error("Mismatching parentheses");
      }
      operatorStack.pop();
    } else {
      postfixQueue.push(token);
    }
  }

  while (operatorStack.length > 0) {
    if (operatorStack[operatorStack.length - 1] === "(") {
      throw new Error("Mismatching parentheses");
    }
    postfixQueue.push(operatorStack.pop());
  }

  return postfixQueue;
}

function addImplicitBrackets(postfixQueue) {
  const stack = [];

  if (postfixQueue.length === 0) {
    return "";
  }

  const generateExpression = (expression, hadBracket) =>
    hadBracket ? `( ${expression} )` : expression;

  for (const [index, token] of postfixQueue.entries()) {
    // The last token doesn't require brackets
    const hasBracket = index !== postfixQueue.length - 1;

    if (UNARY_OPERATORS.has(token)) {
      if (stack.length < 1) {
        throw new Error("Missing operator");
      }
      const right = stack.pop();
      stack.push(generateExpression(`${token} ${right}`, hasBracket));
    } else if (BINARY_OPERATORS.has(token)) {
      if (stack.length < 2) {
        throw new Error("Missing operator");
      }
      const right = stack.pop();
      const left = stack.pop();
      stack.push(generateExpression(`${left} ${token} ${right}`, hasBracket));
    } else {
      stack.push(token);
    }
  }

  if (stack.length !== 1) {
    throw new Error("Operator incomplete");
  }

  return stack[0];
}

function parseBooleanExpression(expression) {
  const tokens = tokenize(expression);
  if (tokens.includes("not")) {
    throw new Error("Not operator is not allowed yet");
  }
  return buildPostfixQueue(tokens);
}

export function addImplicitBracketsToExpression(expression) {
  const postfixQueue = parseBooleanExpression(expression);
  return addImplicitBrackets(postfixQueue);
}

export function getSymbols(expression) {
  const postfixQueue = parseBooleanExpression(expression);
  return Array.from(
    new Set(
      postfixQueue
        .filter((token) => /^\d+$/.test(token))
        .sort((a, b) => Number(a) - Number(b))
    )
  );
}
