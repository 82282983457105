import {
  AggregationWindowConfigItem,
  PartitionsConfigItem,
  QueryTimezoneConfigItem,
  SeasonalityConfigItem,
  SliceByColumnsConfigItem,
  SynchronizationDelayConfigItem,
  TimestampColumnConfigItem,
  TimestampTimezoneConfigItem,
  QueryScopeConfigItem,
  DataCollectionScheduleConfigItem,
  BackfillDurationConfigItem,
  MissingValueFillingConfigItem,
  CustomScheduleCollectionConfigItem,
  CustomSqlConfigItem,
  AggregationTypeConfigItem,
  DataCollectionWindowConfigItem,
  WhereConditionsConfigItem,
  ColumnConditionsConfigItem,
  MetricComparesConfigItem,
  ColumnsToCompareConfigItem,
} from "../fields";
import {
  aggregationWindowOptions,
  getAggregationFunctionNameOptions,
  getMetricTypeFromConfigData,
} from "../utils";
import { metricQueryScopeOptions, queryScopeOptions } from "../../../utils/options";
import { extractDiffState, combineDiffStates } from "../../../utils/compare";
import SummaryViewSectionTitle from "./summary-view-section-title";
import { DataAssetDiffSummary } from "./data-asset-diff-summary";
import { MetricCategory, MetricConfigType, QueryScope } from "../../../utils/enums";

function ConfigureMetricDiffSummary(props) {
  const {
    configData,
    diffData = null,
    dataSourceList = [],
    kpiList,
    queryScopeFieldExpectChange = null,
  } = props;

  // Special case, seasonality should not be visible for activity and aggregation compare metrics
  const metricType = getMetricTypeFromConfigData(configData);
  const showSeasonality = ![
    MetricCategory.TABLE_ACTIVITY,
    MetricCategory.COLUMN_ACTIVITY,
    MetricCategory.CATEGORY_ACTIVITY,
    MetricCategory.AGGREGATION_COMPARE,
    MetricCategory.FULL_COMPARE,
  ].includes(metricType);

  // some distributtion metrics has the missing value filling configuration, but not needed
  const hideMissingValueFilling = metricType === MetricCategory.DISTRIBUTION;

  const isFullTableMetric =
    configData.config.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG;
  const isMetricConfigMetric =
    configData.config.configType === MetricConfigType.METRIC_CONFIG;
  const isFullCompareMetric =
    configData.config.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG;

  const isFullTableScope =
    isFullTableMetric ||
    (isFullCompareMetric && configData.config.queryScope === QueryScope.FULL_TABLE);
  const isIncrementalScope =
    isMetricConfigMetric ||
    (isFullCompareMetric && configData.config.queryScope === QueryScope.TIME_RANGE);

  return (
    <>
      {!diffData && <SummaryViewSectionTitle title="Data Asset" />}
      <DataAssetDiffSummary
        configData={configData}
        dataSourceList={dataSourceList}
        diffState={diffData}
      />
      {configData.config.compares?.length > 0 && (
        <>
          {!diffData && <SummaryViewSectionTitle title="Metrics to compare" />}
          <MetricComparesConfigItem
            kpiList={kpiList.data}
            value={configData.config.compares}
            isEdit={false}
            diffState={extractDiffState(diffData, "compares")}
          />
        </>
      )}
      {configData.config.sourceTable?.valueColumns?.length > 0 && (
        <>
          {!diffData && <SummaryViewSectionTitle title="Key columns to compare" />}
          <ColumnsToCompareConfigItem
            label="Key columns to compare"
            configData={configData}
            fieldName="valueColumns"
            diffState={combineDiffStates(
              extractDiffState(diffData, "sourceTable.valueColumns"),
              extractDiffState(diffData, "targetTable.valueColumns")
            )}
          />
        </>
      )}
      {configData.config.sourceTable?.attributeColumns?.length > 0 && (
        <>
          {!diffData && (
            <SummaryViewSectionTitle title="Attribute columns to compare" />
          )}
          <ColumnsToCompareConfigItem
            label="Attribute columns to compare"
            configData={configData}
            fieldName="attributeColumns"
            diffState={combineDiffStates(
              extractDiffState(diffData, "sourceTable.attributeColumns"),
              extractDiffState(diffData, "targetTable.attributeColumns")
            )}
          />
        </>
      )}
      {!diffData && <SummaryViewSectionTitle title="Metric Configuration" />}
      {configData.config.table?.sql !== undefined && (
        <CustomSqlConfigItem
          value={configData.config.table.sql}
          isEdit={false}
          diffState={extractDiffState(diffData, "table.sql")}
        />
      )}
      {configData.config.aggregation?.conditions !== undefined && (
        <ColumnConditionsConfigItem
          value={configData.config.aggregation.conditions}
          isEdit={false}
          diffState={extractDiffState(diffData, "aggregation.conditions")}
        />
      )}
      {configData.config.aggregation?.type !== undefined && (
        <AggregationTypeConfigItem
          options={getAggregationFunctionNameOptions(true)}
          value={configData.config?.aggregation?.type}
          extraLabel={
            typeof configData.config?.aggregation?.fraction === "number"
              ? configData.config?.aggregation?.fraction
              : ""
          }
          isEdit={false}
          diffState={extractDiffState(diffData, "aggregation.type")}
        />
      )}
      {configData.config.whereClause !== undefined && (
        <WhereConditionsConfigItem
          isEdit={false}
          value={configData.config.whereClause}
          diffState={extractDiffState(diffData, "whereClause")}
        />
      )}
      {configData.config.sourceTable?.whereClause !== undefined && (
        <WhereConditionsConfigItem
          label="Source Where Condition"
          isEdit={false}
          value={configData.config.sourceTable?.whereClause}
          diffState={extractDiffState(diffData, "sourceTable.whereClause")}
        />
      )}
      {configData.config.targetTable?.whereClause !== undefined && (
        <WhereConditionsConfigItem
          label="Target Where Condition"
          isEdit={false}
          value={configData.config.targetTable?.whereClause}
          diffState={extractDiffState(diffData, "targetTable.whereClause")}
        />
      )}
      {configData.config.targetTable?.queryTimezone !== undefined && (
        <QueryTimezoneConfigItem
          value={configData.config.targetTable?.queryTimezone}
          isEdit={false}
          diffState={extractDiffState(diffData, "targetTable.queryTimezone")}
        />
      )}
      {configData.config.configType !== undefined && (
        <QueryScopeConfigItem
          value={configData.config.configType}
          options={metricQueryScopeOptions}
          isEdit={false}
          diffState={extractDiffState(diffData, "configType")}
        />
      )}
      {configData.config.collectionMode !== undefined && (
        <DataCollectionScheduleConfigItem
          value={configData.config.collectionMode}
          isEdit={false}
          diffState={extractDiffState(diffData, "collectionMode")}
        />
      )}
      {configData.config.queryScope !== undefined && (
        <QueryScopeConfigItem
          value={configData.config.queryScope}
          options={queryScopeOptions}
          isEdit={false}
          diffState={extractDiffState(diffData, "queryScope")}
        />
      )}
      {isIncrementalScope &&
        configData.config.table?.aggregationWindow !== undefined && (
          <AggregationWindowConfigItem
            value={configData.config.table.aggregationWindow}
            options={aggregationWindowOptions}
            isEdit={false}
            diffState={extractDiffState(diffData, "table.aggregationWindow", {
              expectChange: queryScopeFieldExpectChange,
            })}
          />
        )}
      {isIncrementalScope &&
        configData.config.aggregation?.aggregationWindow !== undefined && (
          <AggregationWindowConfigItem
            value={configData.config.aggregation?.aggregationWindow}
            options={aggregationWindowOptions}
            isEdit={false}
            diffState={extractDiffState(diffData, "aggregation.aggregationWindow", {
              expectChange: queryScopeFieldExpectChange,
            })}
          />
        )}
      {isIncrementalScope && configData.config.queryTimezone !== undefined && (
        <QueryTimezoneConfigItem
          value={configData.config.queryTimezone}
          isEdit={false}
          diffState={extractDiffState(diffData, "queryTimezone", {
            expectChange: queryScopeFieldExpectChange,
          })}
        />
      )}
      {isIncrementalScope && configData.config.collectionWindow !== undefined && (
        <DataCollectionWindowConfigItem
          value={configData.config.collectionWindow}
          isEdit={false}
          diffState={extractDiffState(diffData, "collectionWindow", {
            expectChange: queryScopeFieldExpectChange,
          })}
        />
      )}
      {configData.config.collectionMode !== undefined && (
        <CustomScheduleCollectionConfigItem
          value={configData.config.collectionMode}
          isEdit={false}
          diffState={extractDiffState(diffData, "collectionMode")}
        />
      )}
      {isIncrementalScope && configData.config.synchronizationDelay !== undefined && (
        <SynchronizationDelayConfigItem
          value={configData.config.synchronizationDelay}
          isEdit={false}
          diffState={extractDiffState(diffData, "synchronizationDelay", {
            expectChange: queryScopeFieldExpectChange,
          })}
        />
      )}
      {isIncrementalScope && configData.config.timestampColumn !== undefined && (
        <TimestampColumnConfigItem
          value={configData.config.timestampColumn}
          columnList={[]}
          isEdit={false}
          diffState={extractDiffState(diffData, "timestampColumn", {
            expectChange: queryScopeFieldExpectChange,
          })}
        />
      )}
      {configData.config.dataTimezone !== undefined && (
        <TimestampTimezoneConfigItem
          value={configData.config.dataTimezone}
          isEdit={false}
          diffState={extractDiffState(diffData, "dataTimezone")}
        />
      )}
      {configData.config.sliceByColumns?.length > 0 && (
        <SliceByColumnsConfigItem
          label="Slice"
          value={configData.config.sliceByColumns}
          isEdit={false}
          diffState={extractDiffState(diffData, "sliceByColumns")}
        />
      )}
      {configData.config.sourceTable?.sliceByColumns?.length > 0 && (
        <SliceByColumnsConfigItem
          label="Source slice"
          value={configData.config.sourceTable?.sliceByColumns}
          isEdit={false}
          diffState={extractDiffState(diffData, "sourceTable.sliceByColumns")}
        />
      )}
      {configData.config.targetTable?.sliceByColumns?.length > 0 && (
        <SliceByColumnsConfigItem
          label="Target slice"
          value={configData.config.targetTable?.sliceByColumns}
          isEdit={false}
          diffState={extractDiffState(diffData, "targetTable.sliceByColumns")}
        />
      )}
      {isFullTableScope && configData.config.pollingDelay !== undefined && (
        <SynchronizationDelayConfigItem
          label="Polling delay"
          value={configData.config.pollingDelay}
          isEdit={false}
          diffState={extractDiffState(diffData, "pollingDelay", {
            expectChange: queryScopeFieldExpectChange,
          })}
        />
      )}
      {isFullTableScope && configData.config.pollingTimezone !== undefined && (
        <QueryTimezoneConfigItem
          label="Polling timezone"
          value={configData.config.pollingTimezone}
          isEdit={false}
          diffState={extractDiffState(diffData, "pollingTimezone", {
            expectChange: queryScopeFieldExpectChange,
          })}
        />
      )}
      {isFullTableScope && configData.config.pollingWindow !== undefined && (
        <AggregationWindowConfigItem
          label="Polling interval"
          value={configData.config.pollingWindow}
          options={aggregationWindowOptions}
          isEdit={false}
          diffState={extractDiffState(diffData, "pollingWindow", {
            expectChange: queryScopeFieldExpectChange,
          })}
        />
      )}
      {!hideMissingValueFilling &&
        configData.config.missingValueFilling !== undefined && (
          <MissingValueFillingConfigItem
            value={configData.config.missingValueFilling}
            isEdit={false}
            diffState={extractDiffState(diffData, "missingValueFilling")}
          />
        )}
      {configData.config.backfillDuration !== undefined && (
        <BackfillDurationConfigItem
          value={configData.config.backfillDuration}
          isEdit={false}
          diffState={extractDiffState(diffData, "backfillDuration")}
        />
      )}
      {showSeasonality && (
        <SeasonalityConfigItem
          status={configData.status}
          value={configData.config.seasonality || null}
          isEdit={false}
          diffState={combineDiffStates(
            extractDiffState(diffData, "seasonality.periodInSeconds", {
              takeLast: true,
            }),
            extractDiffState(diffData, "seasonality.seasonInSeconds", {
              takeLast: true,
            })
          )}
        />
      )}
      <PartitionsConfigItem
        partitionTimezone={configData.config.partitionTimezone}
        partitionOffsets={configData.config.partitionOffsets}
        value={configData.config.partitions}
        isEdit={false}
        diffState={diffData}
      />
    </>
  );
}

export default ConfigureMetricDiffSummary;
