import { URIPath } from "../../utils/uri-path";

export const SideBarNavigationAction = Object.freeze({
  DATA_SOURCE: "datasource",
  QUERY_GOVERNANCE: "queryGovernance",
  ALERT: "alert",
  MEMBER: "member",
  INTEGRATION: "integration",
  SCHEDULE: "schedule",
  EDIT_WORKSPACE: "editWorkspace",
});

export const WorkspaceItemKeyToUriMapper = {
  [SideBarNavigationAction.DATA_SOURCE]: URIPath.DATA_SOURCE_LIST,
  [SideBarNavigationAction.QUERY_GOVERNANCE]: URIPath.QUERY_GOVERNANCE,
  [SideBarNavigationAction.ALERT]: URIPath.ALERT_LIST,
  [SideBarNavigationAction.MEMBER]: URIPath.USER_LIST,
  [SideBarNavigationAction.INTEGRATION]: URIPath.INTEGRATION_LIST,
  [SideBarNavigationAction.SCHEDULE]: URIPath.SCHEDULE_LIST,
  [SideBarNavigationAction.EDIT_WORKSPACE]: URIPath.EDIT_WORKSPACE,
};

export const SideBarMenuItemNodeType = Object.freeze({
  WORKSPACE: "workspace",
  ADMIN: "admin",
  CREATE_WORKSPACE: "createWorkspace",
});

export const SidebarMenuItemNodeTypeToUriMapper = {
  [SideBarMenuItemNodeType.ADMIN]: URIPath.ADMIN,
  [SideBarMenuItemNodeType.CREATE_WORKSPACE]: URIPath.CREATE_WORKSPACE,
};
