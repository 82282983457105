import React from "react";
import { LabeledSelect } from "../../../components/labeled-control/labeled-control";
import "./query-governance.scss";
export default function QueryGovernanceDataSource({
  selectedDataSourceId,
  onDataSourceChange,
  dataSourceOptions = [],
  isLoading,
}) {
  return (
    <div className="query-governance-datasource-container">
      <LabeledSelect
        id="datasources"
        testId="datasources"
        label="Datasources"
        placeholder="Select a datasource"
        enableSorting={true}
        value={selectedDataSourceId}
        onChange={onDataSourceChange}
        options={dataSourceOptions}
        loading={isLoading}
        disabled={isLoading}
      />
    </div>
  );
}
