import {
  DataSourceConfigItem,
  PartitionsConfigItem,
  SchemaConfigItem,
  TableConfigItem,
  TimestampColumnConfigItem,
} from "../fields";
import { getMetricTypeFromConfigData } from "../utils";
import { MetricCategory } from "../../../utils/enums";
import { ConfigSummaryCardRow, ConfigSummaryCardRowDiff } from "../summary-card";
import { MetricSchemaIcon, MetricValueColumnsIcon } from "../fields/icons";
import { extractDiffState } from "../../../utils/compare";
import { default as Icon } from "@ant-design/icons/lib/components/Icon";
import SummaryViewSectionTitle from "./summary-view-section-title";

import "./data-asset-summary.scss";

function ValueAssetConfigDiffItem(props) {
  const { diffState = null, label = null, value = null, icon = null } = props;

  if (!diffState) {
    return <ConfigSummaryCardRow icon={icon} label={`${label} - ${value}`} />;
  }

  return (
    <ConfigSummaryCardRowDiff
      icon={<Icon component={MetricValueColumnsIcon} />}
      label={label}
      value={value}
      diffState={diffState}
    />
  );
}

export function CompareDataAssetDiffSummary(props) {
  const { configData = {}, dataSourceList, diffState = null } = props;
  const {
    config: { sourceTable, targetTable },
  } = configData;

  return (
    <>
      {sourceTable?.sourceUuid && (
        <>
          {!diffState && <SummaryViewSectionTitle title="Source" />}
          <DataSourceConfigItem
            label="Source Datasource"
            dataSourceList={dataSourceList}
            value={sourceTable.sourceUuid}
            isEdit={false}
            diffState={extractDiffState(diffState, "sourceTable.sourceUuid")}
          />
        </>
      )}
      {sourceTable?.table?.schemaName && (
        <ValueAssetConfigDiffItem
          icon={<Icon component={MetricSchemaIcon} />}
          label="Source Schema"
          value={sourceTable.table.schemaName}
          diffState={extractDiffState(diffState, "sourceTable.table.schemaName")}
        />
      )}
      {sourceTable?.table?.tableUuid && (
        <TableConfigItem
          label="Source Table"
          tableList={sourceTable.table ? [sourceTable.table] : []}
          value={sourceTable.table?.tableUuid}
          isEdit={false}
          diffState={extractDiffState(diffState, "sourceTable.table.tableUuid")}
        />
      )}
      {sourceTable?.timestampColumn && (
        <TimestampColumnConfigItem
          label="Source Timestamp"
          value={sourceTable.timestampColumn}
          isEdit={false}
          columnList={[]}
          diffState={extractDiffState(diffState, "sourceTable.timestampColumn")}
        />
      )}
      {sourceTable?.partitions?.length > 0 && (
        <PartitionsConfigItem
          label="Source Partitions"
          partitionTimezone={sourceTable.partitionTimezone}
          partitionOffsets={sourceTable.partitionOffsets}
          value={sourceTable.partitions}
          isEdit={false}
          diffState={extractDiffState(diffState, "sourceTable")}
        />
      )}
      {configData.config?.targetTable?.sourceUuid && (
        <>
          {!diffState && <SummaryViewSectionTitle title="Target" />}
          <DataSourceConfigItem
            label="Target Datasource"
            dataSourceList={dataSourceList}
            value={targetTable.sourceUuid}
            isEdit={false}
            diffState={extractDiffState(diffState, "targetTable.sourceUuid")}
          />
        </>
      )}
      {targetTable?.table?.schemaName && (
        <ValueAssetConfigDiffItem
          icon={<Icon component={MetricSchemaIcon} />}
          label="Target Schema"
          value={targetTable.table.schemaName}
          diffState={extractDiffState(diffState, "targetTable.table.schemaName")}
        />
      )}
      {targetTable?.table?.tableUuid && (
        <TableConfigItem
          label="Target Table"
          tableList={targetTable.table ? [targetTable.table] : []}
          value={targetTable.table?.tableUuid}
          isEdit={false}
          diffState={extractDiffState(diffState, "targetTable.table.tableUuid")}
        />
      )}
      {targetTable?.timestampColumn && (
        <TimestampColumnConfigItem
          label="Target Timestamp"
          value={targetTable.timestampColumn}
          isEdit={false}
          columnList={[]}
          diffState={extractDiffState(diffState, "targetTable.timestampColumn")}
        />
      )}
      {targetTable?.partitions?.length > 0 && (
        <PartitionsConfigItem
          label="Target Partitions"
          partitionTimezone={targetTable.partitionTimezone}
          partitionOffsets={targetTable.partitionOffsets}
          value={targetTable.partitions}
          isEdit={false}
          diffState={extractDiffState(diffState, "targetTable")}
        />
      )}
    </>
  );
}

function SingleSourceDataAssetDiffSummary(props) {
  const { configData, dataSourceList, diffState = null } = props;
  const metricType = getMetricTypeFromConfigData(configData);

  return (
    <>
      <DataSourceConfigItem
        dataSourceList={dataSourceList}
        value={configData.config.sources[0]}
        isEdit={false}
        diffState={extractDiffState(diffState, "sources")}
      />
      {configData.config.table?.schemaName && (
        <SchemaConfigItem
          schemaList={[]}
          value={configData.config.table.schemaName}
          isEdit={false}
          diffState={extractDiffState(diffState, "table.schemaName")}
        />
      )}
      {configData.config.table?.tableUuid && (
        <TableConfigItem
          tableList={[configData.config.table]}
          value={configData.config.table.tableUuid}
          isEdit={false}
          diffState={extractDiffState(diffState, "table.tableUuid")}
        />
      )}
      {metricType === MetricCategory.CUSTOM_SQL
        ? configData.config.table?.columnName && (
            <ValueAssetConfigDiffItem
              label="Column"
              icon={<Icon component={MetricValueColumnsIcon} />}
              value={configData.config.table?.columnName}
              diffState={extractDiffState(diffState, "table.columnName")}
            />
          )
        : configData.config.valueColumns?.length > 0 && (
            <ValueAssetConfigDiffItem
              label="Column"
              icon={<Icon component={MetricValueColumnsIcon} />}
              value={configData.config.valueColumns
                ?.map(({ columnName }) => columnName)
                .join(", ")}
              diffState={extractDiffState(diffState, "valueColumns")}
            />
          )}
    </>
  );
}

export function DataAssetDiffSummary(props) {
  const { configData } = props;
  const metricType = getMetricTypeFromConfigData(configData);

  if (
    [MetricCategory.FULL_COMPARE, MetricCategory.AGGREGATION_COMPARE].includes(
      metricType
    )
  ) {
    return <CompareDataAssetDiffSummary {...props} />;
  }

  return <SingleSourceDataAssetDiffSummary {...props} />;
}
