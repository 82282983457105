import React from "react";
import { SupportedFeature } from "../../../utils/enums";
import { isFeatureEnabled } from "../../../utils/general";
import {
  LabeledInput,
  LabeledInputNumber,
} from "../../labeled-control/labeled-control";
import NgToggleCheckbox from "../../toggle-checkbox/ng-toggle-checkbox";

function DataBricksView(props) {
  const { value, onChange, waffle } = props;

  const {
    workspaceUrl,
    httpPath,
    token,
    clusterId,
    apiPort,
    dbfsPrefix,
    catalog = "",
  } = value;
  const enableStreamingFeature = isFeatureEnabled(
    waffle,
    SupportedFeature.METRIC_STREAMING
  );
  const isStreamingEnabled = clusterId !== undefined || dbfsPrefix !== undefined;

  return (
    <>
      <LabeledInput
        label="Workspace url"
        staticLabel
        value={workspaceUrl}
        onChange={(e) => {
          const newWorkspaceUrl = e.target.value;
          onChange && onChange({ ...value, workspaceUrl: newWorkspaceUrl, token: "" });
        }}
      />
      <LabeledInput
        label="HTTP path"
        staticLabel
        value={httpPath}
        onChange={(e) => {
          const newHttpPath = e.target.value;
          onChange && onChange({ ...value, httpPath: newHttpPath });
        }}
      />
      <LabeledInput
        label="Token"
        staticLabel
        type="password"
        autoComplete="off"
        value={token}
        onChange={(e) => {
          const newToken = e.target.value;
          onChange && onChange({ ...value, token: newToken });
        }}
      />
      <LabeledInput
        label="Catalog (Optional)"
        staticLabel
        value={catalog}
        onChange={(e) => {
          const newCatalog = e.target.value;
          onChange && onChange({ ...value, catalog: newCatalog });
        }}
      />
      {enableStreamingFeature && (
        <NgToggleCheckbox
          value={isStreamingEnabled}
          label="Enable streaming"
          labelPosition="left"
          className="enable-streaming-toggle-box-container"
          onChange={(newIsStreamingEnabled) => {
            let newValue;
            if (newIsStreamingEnabled) {
              newValue = {
                ...value,
                clusterId: "",
                apiPort: 15001,
                dbfsPrefix: "",
              };
            } else {
              newValue = { ...value };
              delete newValue.clusterId;
              delete newValue.airPort;
              delete newValue.dbfsPrefix;
            }

            onChange && onChange(newValue);
          }}
        />
      )}
      {isStreamingEnabled && enableStreamingFeature && (
        <LabeledInput
          label="Cluster id"
          staticLabel
          value={clusterId}
          onChange={(e) => {
            const newClusterId = e.target.value;
            onChange && onChange({ ...value, clusterId: newClusterId });
          }}
        />
      )}
      {isStreamingEnabled && enableStreamingFeature && (
        <LabeledInputNumber
          label="API port"
          staticLabel
          value={apiPort}
          onChange={(newApiPort) =>
            onChange && onChange({ ...value, airPort: newApiPort })
          }
        />
      )}
      {isStreamingEnabled && enableStreamingFeature && (
        <LabeledInput
          label="DBFS prefix"
          staticLabel
          value={dbfsPrefix}
          onChange={(e) => {
            const newDbfsPrefix = e.target.value;
            onChange && onChange({ ...value, dbfsPrefix: newDbfsPrefix });
          }}
        />
      )}
    </>
  );
}

export default DataBricksView;
