import { useState } from "react";
import ModalConfirmationButtons from "../../modal-dialog/modal-confirmation-buttons";
import { LabeledTextArea } from "../../labeled-control/labeled-control";
import { validateDraftMetadata } from "./utils";
import { DraftState, DraftType } from "../../../utils/enums";
import { MetricDraftBanner } from "../metric-draft-banner";
import { MetricDraftAlertIcon } from "../icons";
import { DraftModalDialog } from "./draft-modal-dialog";

import "./draft-metric-modal.scss";

function getButtonMessage(isApproval, draftType) {
  if (!isApproval) {
    return "Reject and notify";
  }
  if (draftType === DraftType.DELETE) {
    return "Approve and delete";
  }
  return "Approve and publish";
}

function DraftMetricApproveModal(props) {
  const {
    visible = false,
    onConfirm,
    onCancel,
    configData,
    isInProgress,
    isApproving = true,
  } = props;

  const [currentConfigData, setCurrentConfigData] = useState(configData);
  const { approverNotes } = currentConfigData?.draftMetadata ?? {};
  const { draftMetadata } = configData;
  const showMonitorAlert = isApproving && DraftType.UPDATE === draftMetadata?.type;

  function onPreConfirm() {
    onConfirm({
      ...currentConfigData,
      draftMetadata: {
        ...currentConfigData.draftMetadata,
        state: isApproving ? DraftState.APPROVED : DraftState.REJECTED,
      },
    });
  }

  return (
    <DraftModalDialog
      visible={visible}
      title={isApproving ? "Approval Actions" : "Reject"}
      className="draft-metric-modal"
      onCancel={onCancel}
      footer={
        <ModalConfirmationButtons
          okText={getButtonMessage(
            isApproving,
            currentConfigData.draftMetadata?.draftType
          )}
          onCancelClick={onCancel}
          onOkClick={onPreConfirm}
          okButtonProps={{
            disabled:
              !validateDraftMetadata(currentConfigData.draftMetadata ?? {}, true) ||
              isInProgress,
          }}
        />
      }
    >
      <div className="draft-form draft-form-small">
        <LabeledTextArea
          label="Admin Notes"
          rows={3}
          value={approverNotes?.notes?.[0]?.note ?? ""}
          onChange={(e) => {
            setCurrentConfigData({
              ...currentConfigData,
              draftMetadata: {
                ...currentConfigData.draftMetadata,
                approverNotes: {
                  ...currentConfigData.draftMetadata.approverNotes,
                  notes: [
                    {
                      note: e.target.value,
                      noteAt: Date.now(),
                    },
                  ],
                },
              },
            });
          }}
          disabled={isInProgress}
          staticLabel
        />
      </div>
      {showMonitorAlert && (
        <MetricDraftBanner
          icon={<MetricDraftAlertIcon fill="white" />}
          message="The monitors based on this metric may be deleted if the metric configuration has changed."
        />
      )}
    </DraftModalDialog>
  );
}

export default DraftMetricApproveModal;
