import React, { useMemo } from "react";
import { Input } from "antd";
import ConfigCard from "../../config-card";
import {
  DimensionConfigItem,
  MetricNameConfigItem,
  MetricStatusConfigItem,
  MetricTypeConfigItem,
} from "../../fields/";
import MetricConfigTabPanel from "../../metric-config-tab-panel";
import MetricConfigSummaryView from "../../summary-view/";
import {
  canCreateMetricType,
  dimensionTypeOptions,
  getDefaultDataByMetricCategory,
  getMetricTypeFromConfigData,
  metricTypeOptions,
} from "../../utils";
import { getSettingFromParams, queryParamsRemoved } from "../../../../utils/uri-path";
import { MetricCategory } from "../../../../utils/enums";
import { withRouter } from "react-router-dom";
import {
  isAutoMetric,
  isMetadataMetric,
  isTableOrColumnActivityMetric,
} from "../../../../utils/metric";
import { dataSourceSupportsMetricType } from "../../../../utils/datasource";

import "./index.scss";

function removeDataAssetTargetQueryParams(location, history) {
  history.replace(
    queryParamsRemoved(location, [
      "dataSourceUuid",
      "dataSourceName",
      "schemaUuid",
      "schemaName",
      "tableUuid",
      "tableName",
      "columnUuid",
      "columnName",
    ])
  );
}

function MetricConfigBasicTabContentView(props) {
  const {
    dataSourceList,
    disabled,
    isEdit,
    configData,
    resetKPIStatus,
    onConfigDataChange,
    location,
    history,
    metricCategoryWhiteList = null,
  } = props;

  const normalizedMetricTypeOptions = useMemo(() => {
    const dataSourceUuid = getSettingFromParams("dataSourceUuid", location.search, "");
    const dataSource = dataSourceUuid
      ? dataSourceList.find((dataSource) => dataSource.metadata.uuid === dataSourceUuid)
      : null;
    const columnType = getSettingFromParams("columnType", location.search, null);

    return metricTypeOptions.filter((opt) => {
      const metricType = opt.value;
      const supportedByDataSource =
        dataSource === null ||
        dataSourceSupportsMetricType(dataSource, metricType, columnType);
      const isWhiteListed =
        metricCategoryWhiteList === null ||
        metricCategoryWhiteList.includes(metricType);
      return (
        (isEdit || canCreateMetricType(metricType)) &&
        supportedByDataSource &&
        isWhiteListed
      );
    });
  }, [dataSourceList, location, isEdit, metricCategoryWhiteList]);

  const normalizedMetricType = getMetricTypeFromConfigData(configData);

  let tabTitle = "Metric Info";
  if (isMetadataMetric(configData)) {
    tabTitle = "Metadata Metric Info";
  } else if (isAutoMetric(configData)) {
    tabTitle = "Auto Metric Info";
  }

  const isReadOnlyField = isTableOrColumnActivityMetric(configData);

  return (
    <div className="metric-config-basic-tab-content-container">
      <ConfigCard
        title={tabTitle}
        description={
          isEdit
            ? "Edit your metric by naming it, picking a data asset, and configuring the settings"
            : "Create your metric by selecting the type, naming it, picking a data asset, and configuring the settings"
        }
      >
        <div className="metric-config-basic-tab-metric-info">
          <div className="metric-config-field-row">
            <MetricTypeConfigItem
              options={normalizedMetricTypeOptions}
              value={normalizedMetricType}
              listHeight={300}
              onChange={(newMetricType) => {
                // The data asset target will not be used for compare metrics; clear
                // the query params out to avoid confusion.
                const isCompareMetric = [
                  MetricCategory.AGGREGATION_COMPARE,
                  MetricCategory.FULL_COMPARE,
                ].includes(newMetricType);
                if (isCompareMetric) {
                  removeDataAssetTargetQueryParams(location, history);
                }

                const defaultConfigData = getDefaultDataByMetricCategory(newMetricType);

                onConfigDataChange({
                  ...defaultConfigData,
                  metadata: {
                    ...defaultConfigData.metadata,
                    name: configData.metadata.name,
                    description: configData.metadata.description,
                  },
                });

                resetKPIStatus({
                  resetAll: false,
                  resetSchemaList: true,
                  resetSrcSchemaList: true,
                  resetTableList: true,
                  resetSrcTableList: true,
                  resetColumnList: true,
                  resetCustomSqlColumnList: true,
                  resetSrcColumnList: true,
                  resetColumnValueList: true,
                  resetFullCompareSrcColumnValueList: true,
                  resetFullCompareTargetColumnValueList: true,
                  resetPartitionSampleData: true,
                  resetSrcPartitionSampleData: true,
                  resetSampleDataTableSchemaList: true,
                  resetSampleDataSampleDataList: true,
                  resetPreviewInfo: true,
                });
              }}
              disabled={isEdit || disabled}
            />
            <MetricNameConfigItem
              disabled={disabled}
              value={configData.metadata.name}
              onChange={(newName) => {
                onConfigDataChange({
                  ...configData,
                  metadata: {
                    ...configData.metadata,
                    name: newName,
                  },
                });
              }}
            />
          </div>
          <div className="metric-config-field-row">
            <Input.TextArea
              disabled={disabled}
              placeholder="Description (optional)"
              size="large"
              rows="5"
              value={configData.metadata.description || ""}
              onChange={(e) => {
                if (e.target.value === (configData.metadata.description || "")) {
                  return;
                }

                onConfigDataChange({
                  ...configData,
                  metadata: {
                    ...configData.metadata,
                    description: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
      </ConfigCard>
      <ConfigCard
        title="Metric status"
        description="Metric status changes will be reflected upon save"
      >
        <div className="metric-config-field-row metric-config-basic-tab-field">
          <MetricStatusConfigItem
            value={configData.config.isLive}
            onChange={(isLive) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  isLive,
                },
              });
            }}
            disabled={isReadOnlyField || disabled}
          />
        </div>
      </ConfigCard>
      <ConfigCard
        title="Quality Dimension"
        description="Lightup sets these for autometrics and custom metrics. You can edit the custom metrics value"
      >
        <div className="metric-config-field-row metric-config-basic-tab-field">
          <DimensionConfigItem
            options={dimensionTypeOptions}
            value={configData.config.dimension}
            onChange={(newDimension) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  dimension: newDimension,
                },
              });
            }}
            disabled={isAutoMetric(configData) || disabled}
          />
        </div>
      </ConfigCard>
    </div>
  );
}

function MetricConfigBasicTabPanel(props) {
  const {
    isEdit,
    configData,
    onConfigDataChange,
    resetKPIStatus,
    history,
    location,
    dataSourceList,
    disabled,
    metricCategoryWhiteList = null,
  } = props;

  return (
    <MetricConfigTabPanel
      summarySection={<MetricConfigSummaryView {...props} />}
      contentSection={
        <MetricConfigBasicTabContentView
          isEdit={isEdit}
          disabled={disabled}
          configData={configData}
          onConfigDataChange={onConfigDataChange}
          resetKPIStatus={resetKPIStatus}
          history={history}
          location={location}
          dataSourceList={dataSourceList}
          metricCategoryWhiteList={metricCategoryWhiteList}
        />
      }
    />
  );
}

export default withRouter(MetricConfigBasicTabPanel);
