import React from "react";
import IncidentSummaryIcon from "./incident-summary-icon";
import { IncidentDirection } from "../../utils/enums";

import "./incident-total-summary.scss";

function IncidentTotalSummary({ incidentSummary, incidentScoreSummary }) {
  return (
    <div className="lightup-flex-container">
      {[IncidentDirection.UP, IncidentDirection.DOWN, IncidentDirection.BOTH].map(
        function (direction, index) {
          if (incidentSummary[direction] === 0) {
            return null;
          }

          return (
            <div className="incident-analysis-incident-summary-container" key={index}>
              <IncidentSummaryIcon
                direction={direction}
                score={incidentScoreSummary[direction]}
                number={incidentSummary[direction]}
              />
            </div>
          );
        }
      )}
    </div>
  );
}

export default IncidentTotalSummary;
