import { classesName } from "../../utils/css";
import { InfoCircleOutlined } from "@ant-design/icons";
import NgTooltip from "../tooltip/ng-tooltip";
import Switch from "../../atom/switch";

import "./ng-toggle-checkbox.scss";

function NgToggleCheckbox(props) {
  const {
    value,
    label,
    className: propsClassName,
    size = "regular",
    onChange,
    labelPosition = "right",
    helpTooltip = "",
    disabled = false,
  } = props;

  const switchSize = size === "mini" ? "small" : "default";

  const className = classesName(
    "ng-toggle-checkbox",
    propsClassName,
    `size-${size}`,
    `label-position-${labelPosition}`
  );

  return (
    <div className={className}>
      {label && <span className="ng-toggle-checkbox-label">{label}</span>}
      {helpTooltip && (
        <NgTooltip title={helpTooltip}>
          <span className="ng-toggle-checkbox-tooltip">
            <InfoCircleOutlined />
          </span>
        </NgTooltip>
      )}
      <Switch
        checked={value}
        size={switchSize}
        disabled={disabled}
        onChange={(checked, _event) => onChange(checked)}
      />
    </div>
  );
}

export default NgToggleCheckbox;
