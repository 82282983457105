import React from "react";
import PropTypes from "prop-types";
import NgDropdownMenu from "../ng-dropdown-menu";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import { QueryHistoryIcon, ViewInExplorerIcon } from "../../views/profiler/menu-icons";

import "./chart-controller-option-menu.scss";

function ControlThresholdDataIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4173 12.2503C13.4173 12.5725 13.1562 12.8336 12.834 12.8336H1.16732C0.845151 12.8336 0.583984 12.5725 0.583984 12.2503V8.60449V4.95867C0.583984 4.73086 0.716599 4.52389 0.923569 4.42872C1.13054 4.33352 1.37398 4.36753 1.54695 4.51578L3.09592 5.84347L4.33246 4.04486C4.49733 3.80505 4.81373 3.72344 5.07402 3.85358L6.43653 4.53484L8.61823 2.22481C8.82838 2.00228 9.17482 1.98004 9.41171 2.17387L10.6073 3.15208L12.4215 1.33786C12.5883 1.17103 12.8392 1.12112 13.0572 1.21141C13.2752 1.3017 13.4173 1.51441 13.4173 1.75034V12.2503ZM5.43513 6.44691C5.18555 6.36372 4.91121 6.45787 4.76529 6.67674L3.31528 8.85175L1.75065 7.97164V6.22697L2.82936 7.15157C2.955 7.25929 3.12049 7.30852 3.28458 7.28708C3.44868 7.26565 3.59591 7.17552 3.68968 7.03914L5.00628 5.12408L6.30228 5.77208C6.53235 5.88714 6.81063 5.83788 6.98724 5.65086L9.09338 3.42083L10.2771 4.38932C10.5091 4.5791 10.8471 4.56225 11.059 4.35032L12.2507 3.15863V4.15612L10.7283 5.89597L9.41577 4.8022C9.17246 4.59943 8.81187 4.62784 8.6033 4.86619L6.8171 6.90756L5.43513 6.44691ZM3.98601 9.94889C3.81679 10.2027 3.48056 10.2833 3.21467 10.1337L1.75065 9.31024V11.667H12.2522V5.92611L11.2313 7.09277C11.0228 7.33112 10.6622 7.35953 10.4189 7.15677L9.10635 6.063L7.43967 7.96777C7.28491 8.14464 7.03912 8.21137 6.81617 8.13706L5.48886 7.69462L3.98601 9.94889Z"
        fill="#121224"
      />
    </svg>
  );
}

function ChartControllerOptionMenu(props) {
  const {
    enableChartOption,
    showStatsData,
    showSignalData,
    incidentViewInExplorerConfig,
    removeChartConfig,
    viewQueryHistoryConfig,
  } = props;
  const menuItems = [];

  if (enableChartOption) {
    menuItems.push({
      label: showStatsData ? "Hide threshold" : "View threshold",
      icon: <ControlThresholdDataIcon />,
      onClick: () => {
        props.onChange({
          showStatsData: !showStatsData,
          showSignalData,
        });
      },
    });
  }

  if (incidentViewInExplorerConfig) {
    menuItems.push({
      label: "View metric in explorer",
      icon: <ViewInExplorerIcon />,
      onClick: incidentViewInExplorerConfig.onClick,
    });
  }

  if (removeChartConfig) {
    menuItems.push({
      label: "Hide chart",
      icon: <EyeInvisibleOutlined />,
      onClick: removeChartConfig.onClick,
    });
  }

  if (viewQueryHistoryConfig) {
    menuItems.push({
      label: "Query history",
      icon: <QueryHistoryIcon />,
      onClick: viewQueryHistoryConfig.onClick,
    });
  }

  if (!menuItems || menuItems.length === 0) {
    return null;
  }

  return (
    <NgDropdownMenu
      menuItems={menuItems}
      buttonProps={{ testId: "chart-actions-menu" }}
    />
  );
}

ChartControllerOptionMenu.propTypes = {
  showStatsData: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ChartControllerOptionMenu;
