import React from "react";
import { DAY_IN_SECONDS } from "../../../../../utils/time";
import { SystemIntegrationType } from "../../../../../utils/system-integration";
import AlationIntegrationBasicDialog from "./alation-integration-basic-dialog";

function AddAlationIntegrationDialog(props) {
  const { onAdd, onPreview, modalIsOpen, setIsOpen } = props;
  const defaultAlationInstance = {
    metadata: {
      uuid: "",
      name: "",
    },
    config: {
      baseUrl: "",
      type: SystemIntegrationType.ALATION,
      userId: "",
      refreshToken: "",
      incidentWindow: DAY_IN_SECONDS,
    },
  };

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  return (
    <AlationIntegrationBasicDialog
      defaultData={defaultAlationInstance}
      className={"add-alation-integration-dialog-container"}
      dialogTitle={"Add Alation Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      OKText={"Add"}
      enablePreview={true}
      enableSave={true}
    />
  );
}

export default AddAlationIntegrationDialog;
