import React from "react";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { PauseIcon, ResumeIcon } from "../../views/profiler/menu-icons";
import {
  getURIInstance,
  hasPermission,
  URIPathPermissions,
  URIPath,
} from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { LiveIcon, InactiveIcon } from "../../components/icons/status";
import { QueryGovernanceIcon, ViewInExplorerIcon } from "../profiler/menu-icons";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../utils/telemetry";

export const DataSourceActions = Object.freeze({
  VIEW_EDIT: "viewEdit",
  CLONE: "clone",
  PAUSE_CHANGE: "pauseChange",
  STATUS_CHANGE: "statusChange",
  DELETE: "delete",
  VIEW_IN_EXPLORER: "viewInExplorer",
  VIEW_QUERY_GOVERNANCE: "viewQueryGovernance",
  VIEW_DATASOURCES_LIST: "viewDatasourcesList",
});

function DataSourceActionsMenu(props) {
  const {
    trigger,
    workspaceUuid,
    workspaceUserPermissions,
    dataSources,
    history,
    onToggleDataSourcePauseClick,
    onToggleDataSourceProfilerStatusClick,
    onDeleteDataSourceClick,
    loading = false,
    actions = [],
    getPopupContainer,
  } = props;

  const viewDataSourcePermissions = [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
  ];

  const modifyDataSourcePermissions = [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
  ];

  const toggleProfilerConfigDataSourcePermissions = [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ];

  const viewDataGovernancePermissions = URIPathPermissions[URIPath.QUERY_GOVERNANCE];

  const canModifyDataSource = hasPermission(
    workspaceUserPermissions,
    modifyDataSourcePermissions
  );
  const canViewDataSource = hasPermission(
    workspaceUserPermissions,
    viewDataSourcePermissions
  );

  const menuConfig = [
    {
      action: DataSourceActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewDataSourcePermissions,
      item: {
        label: canModifyDataSource ? "Edit" : "View",
        icon: <EditOutlined />,
        onClick: () => {
          if (canModifyDataSource) {
            trackEvent(EVENT.EDIT_DATASOURCE, {
              ...getDatasourceDetailProps(dataSources[0]),
              page: PAGE.DATASOURCES,
            });
          }

          const nextUrl = getURIInstance(URIPath.EDIT_DATA_SOURCE, {
            workspaceUuid,
            id: dataSources[0].metadata.uuid,
          });
          history.push(nextUrl);
        },
        disabled: !canViewDataSource,
      },
    },
    {
      action: DataSourceActions.PAUSE_CHANGE,
      supportsMultiple: true,
      permissions: modifyDataSourcePermissions,
      validTarget: (dataSource) => dataSource.config.isLive,
      item: {
        label: "Pause",
        icon: <PauseIcon />,
        onClick: (targetDataSources) => {
          trackEvent(EVENT.PAUSE_DATASOURCE, {
            ...getDatasourceDetailProps(dataSources[0]),
            page: PAGE.DATASOURCES,
          });

          onToggleDataSourcePauseClick(workspaceUuid, targetDataSources, false);
        },
      },
    },
    {
      action: DataSourceActions.PAUSE_CHANGE,
      supportsMultiple: true,
      permissions: modifyDataSourcePermissions,
      validTarget: (dataSource) => !dataSource.config.isLive,
      item: {
        label: "Resume",
        icon: <ResumeIcon />,
        onClick: (targetDataSources) => {
          trackEvent(EVENT.RESUME_DATASOURCE, {
            ...getDatasourceDetailProps(dataSources[0]),
            page: PAGE.DATASOURCES,
          });

          onToggleDataSourcePauseClick(workspaceUuid, targetDataSources, true);
        },
      },
    },
    {
      action: DataSourceActions.STATUS_CHANGE,
      supportsMultiple: true,
      permissions: toggleProfilerConfigDataSourcePermissions,
      validTarget: (dataSource) => !dataSource.config.profiler.enabled,
      item: {
        label: "Enable",
        icon: <LiveIcon />,
        onClick: (targetDataSources) => {
          trackEvent(EVENT.ENABLE_DATASOURCE, {
            ...getDatasourceDetailProps(dataSources[0]),
            page: PAGE.DATASOURCES,
          });

          onToggleDataSourceProfilerStatusClick(workspaceUuid, targetDataSources, true);
        },
      },
    },
    {
      action: DataSourceActions.STATUS_CHANGE,
      supportsMultiple: true,
      permissions: toggleProfilerConfigDataSourcePermissions,
      validTarget: (dataSource) => dataSource.config.profiler.enabled,
      item: {
        label: "Disable",
        icon: <InactiveIcon />,
        onClick: (targetDataSources) => {
          onToggleDataSourceProfilerStatusClick(
            workspaceUuid,
            targetDataSources,
            false
          );
        },
      },
    },
    {
      action: DataSourceActions.VIEW_IN_EXPLORER,
      supportsMultiple: false,
      validTarget: (dataSource) => dataSource.config.profiler.enabled,
      item: {
        label: "View in explorer",
        icon: <ViewInExplorerIcon />,
        onClick: () => {
          trackEvent(EVENT.VIEW_DATASOURCE_IN_EXPLORER, {
            ...getDatasourceDetailProps(dataSources[0]),
            page: PAGE.DATASOURCES,
          });
          const queryString = `dataSourceUuid=${dataSources[0].metadata.uuid}`;
          const nextUrl = `${getURIInstance(URIPath.EXPLORER, {
            workspaceUuid,
          })}?${queryString}`;
          history.push(nextUrl);
        },
      },
    },
    {
      action: DataSourceActions.VIEW_QUERY_GOVERNANCE,
      supportsMultiple: false,
      permissions: viewDataGovernancePermissions,
      item: {
        label: "View query governance",
        icon: <QueryGovernanceIcon />,
        onClick: () => {
          trackEvent(EVENT.VIEW_QUERY_GOVERNANCE, {
            ...getDatasourceDetailProps(dataSources[0]),
            page: PAGE.DATASOURCES,
          });
          const queryString = `dataSourceUuid=${dataSources[0].metadata.uuid}`;
          const nextUrl = `${getURIInstance(URIPath.QUERY_GOVERNANCE, {
            workspaceUuid,
          })}?${queryString}`;
          history.push(nextUrl);
        },
      },
    },
    {
      action: DataSourceActions.DELETE,
      supportsMultiple: false,
      permissions: modifyDataSourcePermissions,
      item: {
        label: "Delete",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => onDeleteDataSourceClick(workspaceUuid, dataSources),
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    dataSources,
    actions,
    workspaceUserPermissions
  );

  return (
    <NgDropdownMenu
      trigger={trigger}
      menuItems={menuItems}
      buttonProps={{ disabled: loading }}
      getPopupContainer={getPopupContainer}
      position="bottomLeft"
    />
  );
}

export default DataSourceActionsMenu;
