import { classesName } from "../../../utils/css";
import { QueryRecordStatus } from "../../../utils/enums";
import { getQueryRecordStatusDisplayName } from "../../../utils/general";

import "./query-list-stats.scss";

function QueryListStats(props) {
  const { queryList, className = null } = props;

  const counter = queryList.data.reduce(
    (count, { status }) => {
      if (status in count) {
        count[status]++;
      } else {
        console.log(`Unknown status ${status}`);
      }
      return count;
    },
    Object.fromEntries(Object.values(QueryRecordStatus).map((status) => [status, 0]))
  );

  const summaryArray = [
    QueryRecordStatus.COMPLETED,
    QueryRecordStatus.FAILED,
    QueryRecordStatus.CANCELLED,
    QueryRecordStatus.PROCESSING,
  ].map((status) => ({
    label: getQueryRecordStatusDisplayName(status),
    value: counter[status],
  }));

  return (
    <div className={classesName("query-list-stats-container", className)}>
      {summaryArray.map(({ label, value }) => {
        return (
          <div className="query-list-stat-summary" key={label}>
            <div className="query-list-stat-summary-label">{label}</div>
            <div className="query-list-stat-summary-value">{value}</div>
          </div>
        );
      })}
    </div>
  );
}

export default QueryListStats;
