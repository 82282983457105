import React, { useState } from "react";
import IntegrationDialogFooter from "../integration-dialog-footer";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../components/modal-dialog/modal-field-group";
import { LabeledInput } from "../../../components/labeled-control/labeled-control";
import { getAppDomainName } from "../../../utils/general";
import TakeoverPanel from "../../../components/takeover/takeover-panel";
import NgButton from "../../../components/button/ng-button";
import {
  IntegrationMetadataEditor,
  MetadataAuthenticationButton,
  SubmitModeSelector,
  getMetadataEditStatus,
} from "../integration-metadata-editor";
import { getBranchLeaf, isBranch } from "../integration-metadata";
import IntegrationMetadataTestTicketModal from "../integration-metadata-test-ticket-modal";
import { AlertingChannelType } from "../../../utils/alerting";

import "./jira-integration-basic-dialog.scss";

export function getJiraAuthFields(value) {
  return {
    server: value.server,
    username: value.username,
    password: value.password,
    key: value.key,
    authType: value.authType,
    authToken: value.authToken,
  };
}

export function canAttemptAuthentication(value) {
  const { key, server, username, password, authToken } = value;
  return server && key && ((username && password) || authToken);
}

function JiraIntegrationBasicDialogBodyComponent(props) {
  const {
    value,
    authenticationStatus,
    currentMetadata,
    enablePreview,
    onChange,
    onPreviewClick,
    onAuthenticateClick,
    workspaceUuid,
  } = props;

  const { title, server, username, password, key, mode } = value;

  const { onlyEditMetadataAvailable, contextFieldsConfigured } = getMetadataEditStatus(
    currentMetadata.data,
    value.metadata
  );

  return (
    <div className="jira-integration-basic-dialog">
      <ModalFieldGroup>
        <ModalFieldRows>
          <SubmitModeSelector
            value={mode}
            onChange={(newMode) => onChange({ ...value, mode: newMode })}
          />
          <LabeledInput
            label="Name"
            staticLabel
            value={title}
            onChange={(e) => onChange({ ...value, title: e.target.value })}
          />
          <LabeledInput
            label="Jira server"
            staticLabel
            value={server}
            onChange={(e) => onChange({ ...value, server: e.target.value })}
          />
          <LabeledInput
            label="Username"
            staticLabel
            value={username}
            onChange={(e) => onChange({ ...value, username: e.target.value })}
          />
          <LabeledInput
            label="API token"
            staticLabel
            value={password}
            type="password"
            onChange={(e) => onChange({ ...value, password: e.target.value })}
          />
          <LabeledInput
            label="Project key"
            staticLabel
            value={key}
            onChange={(e) => onChange({ ...value, key: e.target.value })}
          />
          <MetadataAuthenticationButton
            authenticationStatus={authenticationStatus}
            onlyEditMetadataAvailable={onlyEditMetadataAvailable}
            onClick={onAuthenticateClick}
            disabled={!canAttemptAuthentication(value)}
          />
        </ModalFieldRows>
      </ModalFieldGroup>
      <IntegrationMetadataEditor
        type={AlertingChannelType.JIRA}
        value={value}
        currentMetadata={currentMetadata}
        onChange={onChange}
        workspaceUuid={workspaceUuid}
      />
      {contextFieldsConfigured && (
        <div className="jira-integration-basic-dialog-create-test-ticket">
          <NgButton
            primary
            size="large"
            disabled={!enablePreview}
            onClick={onPreviewClick}
          >
            Create test ticket
          </NgButton>
        </div>
      )}
    </div>
  );
}

function JiraIntegrationBasicDialog(props) {
  const {
    value,
    currentMetadata,
    dialogTitle = "",
    className = "",
    OKText,
    setIsOpen,
    enableSave,
    authButtonStatus,
    enablePreview,
    onChange,
    onOkClicked,
    onPreview,
    onAuthenticate,
    workspaceUuid,
  } = props;

  const [testTicketModalOpen, setTestTicketModalOpen] = useState(false);

  function getNewJiraIntegration(integration) {
    return {
      ...integration,
      type: AlertingChannelType.JIRA,
      domainName: getAppDomainName(),
    };
  }

  function closePanel() {
    setIsOpen(false);
  }

  function onOkClick() {
    onOkClicked(getNewJiraIntegration(value));
    closePanel();
  }

  function onEditTestTicketClick() {
    const noFieldsSelected =
      !value.metadata?.type ||
      (isBranch(value.metadata) && getBranchLeaf(value.metadata).fields.length === 0);
    if (noFieldsSelected) {
      onCreateTestTicket(value);
    } else {
      setTestTicketModalOpen(true);
    }
  }

  function onCreateTestTicket(integration) {
    onPreview(getNewJiraIntegration(integration)).then(() =>
      setTestTicketModalOpen(false)
    );
  }

  function onAuthenticateClick() {
    onAuthenticate(getNewJiraIntegration(value));
  }

  const cornerControls = (
    <IntegrationDialogFooter
      onCancelClick={closePanel}
      onOkClick={onOkClick}
      OkText={OKText}
      enableSave={enableSave}
      previewText={null}
    />
  );

  return (
    <>
      <TakeoverPanel title={dialogTitle} cornerControls={cornerControls}>
        <div className={`jira-integration-basic-dialog-container ${className}`}>
          <JiraIntegrationBasicDialogBodyComponent
            value={value}
            authenticationStatus={authButtonStatus}
            currentMetadata={currentMetadata}
            onChange={onChange}
            enablePreview={enablePreview}
            onPreviewClick={onEditTestTicketClick}
            onAuthenticateClick={onAuthenticateClick}
            workspaceUuid={workspaceUuid}
          />
        </div>
      </TakeoverPanel>
      <IntegrationMetadataTestTicketModal
        type={AlertingChannelType.JIRA}
        key={testTicketModalOpen}
        value={value}
        visible={testTicketModalOpen}
        onCreateTestTicket={onCreateTestTicket}
        onCancel={() => setTestTicketModalOpen(false)}
        workspaceUuid={workspaceUuid}
      />
    </>
  );
}

export default JiraIntegrationBasicDialog;
