import React, { useEffect } from "react";
import { DeleteOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import Select from "../../../select";
import Button from "../../../button/ng-button";
import {
  DAY_IN_SECONDS,
  getSeasonConfigFromSeason,
  getSeasonFromSeasonConfig,
  HOUR_IN_SECONDS,
  MONTH_IN_SECONDS,
  WEEK_IN_SECONDS,
  YEAR_IN_SECONDS,
} from "../../../../utils/time";
import { basicSeasonalityOptions } from "../../utils";
import fromUnixTime from "date-fns/fromUnixTime";
import getUnixTime from "date-fns/getUnixTime";
import TakeoverPanel from "../../../takeover/takeover-panel";
import {
  LabeledDatePicker,
  LabeledInput,
  LabeledSelect,
} from "../../../labeled-control/labeled-control";
import ButtonIcon from "../../../button/button-icon";
import moment from "moment";
import { FieldRow } from "../../fields";

import "./metric-custom-seasonality-takeover-view.scss";

const REPEAT_INTERVAL_IN_SECONDS_NO_REPEAT = 0;
const repeatIntervalOptions = [
  { label: "Does not repeat", value: REPEAT_INTERVAL_IN_SECONDS_NO_REPEAT },
  { label: "Repeat Daily", value: DAY_IN_SECONDS },
  { label: "Repeat Weekly", value: WEEK_IN_SECONDS },
  { label: "Repeat Monthly", value: MONTH_IN_SECONDS },
  { label: "Repeat Yearly", value: YEAR_IN_SECONDS },
];

function getDefaultOverrideSeasonPeriodTimeRange() {
  const startTimestamp = getUnixTime(new Date());
  const endTimestamp = startTimestamp + HOUR_IN_SECONDS;
  return {
    startTimestamp,
    endTimestamp,
  };
}

function getDefaultOverrideSeasonPeriod() {
  return {
    name: "Default Override Season Period",
    timeRanges: [getDefaultOverrideSeasonPeriodTimeRange()],
    repeatCount: 0,
    repeatIntervalInSeconds: REPEAT_INTERVAL_IN_SECONDS_NO_REPEAT,
  };
}

function MetricCustomSeasonPeriod(props) {
  const { value: customSeasonPeriod, onChange, onDelete } = props;

  const {
    name,
    timeRanges = [],
    repeatCount,
    repeatIntervalInSeconds,
  } = customSeasonPeriod;

  function onNameChange(newName) {
    if (newName !== name) {
      onChange({
        ...customSeasonPeriod,
        name: newName,
      });
    }
  }

  function onAddTimeRange() {
    timeRanges.push(getDefaultOverrideSeasonPeriodTimeRange());

    onChange({
      ...customSeasonPeriod,
      timeRanges,
    });
  }

  function onDeleteTimeRange(index) {
    timeRanges.splice(index, 1);
    onChange({
      ...customSeasonPeriod,
      timeRanges,
    });
  }

  function onEditTimeRange(index, newTimeRange) {
    timeRanges[index] = newTimeRange;
    onChange({
      ...customSeasonPeriod,
      timeRanges,
    });
  }

  function onRepeatIntervalInSecondsChange(repeatIntervalInSecondsOption) {
    if (repeatIntervalInSecondsOption !== repeatIntervalInSeconds) {
      const newCustomSeasonPeriod = {
        ...customSeasonPeriod,
        repeatIntervalInSeconds: repeatIntervalInSecondsOption,
      };

      if (repeatIntervalInSecondsOption === REPEAT_INTERVAL_IN_SECONDS_NO_REPEAT) {
        newCustomSeasonPeriod["repeatCount"] = 0;
      } else if (repeatIntervalInSeconds === REPEAT_INTERVAL_IN_SECONDS_NO_REPEAT) {
        newCustomSeasonPeriod["repeatCount"] = 1;
      }

      onChange(newCustomSeasonPeriod);
    }
  }

  function onRepeatCountChange(newRepeatCount) {
    if (repeatCount !== newRepeatCount) {
      onChange({
        ...customSeasonPeriod,
        repeatCount: newRepeatCount,
      });
    }
  }

  return (
    <div className="metric-custom-period-item-container">
      <div className="metric-custom-period-item-name-container">
        <LabeledInput
          label="Override season name"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
        />
        <ButtonIcon onClick={onDelete} icon={<DeleteOutlined />} />
      </div>
      <div className="metric-custom-period-item-time-ranges-container">
        <div className="metric-custom-period-item-time-ranges-header-container">
          <div className="metric-custom-period-item-time-ranges-header-title-container">
            are treated the same during {timeRanges.length} period(s)
          </div>
          <div className="metric-custom-period-item-time-ranges-header-add-button-container">
            <Button outline onClick={onAddTimeRange}>
              Add
              <PlusOutlined />
            </Button>
          </div>
        </div>
        <div className="metric-custom-period-item-time-ranges-list-container">
          {timeRanges.map(function (timeRange, index) {
            const { startTimestamp, endTimestamp } = timeRange;
            return (
              <FieldRow
                className="metric-custom-period-item-time-ranges-item-container"
                key={index}
              >
                <LabeledDatePicker
                  label="Starts"
                  showTime
                  value={moment(fromUnixTime(startTimestamp))}
                  onChange={(newStartTime) => {
                    const newStartTimestamp = getUnixTime(newStartTime);
                    if (newStartTimestamp !== startTimestamp) {
                      onEditTimeRange(index, {
                        startTimestamp: newStartTimestamp,
                        endTimestamp,
                      });
                    }
                  }}
                />
                <LabeledDatePicker
                  label="Ends"
                  showTime
                  value={moment(fromUnixTime(endTimestamp))}
                  onChange={(newEndTime) => {
                    const newEndTimestamp = getUnixTime(newEndTime);
                    if (newEndTimestamp !== endTimestamp) {
                      onEditTimeRange(index, {
                        startTimestamp,
                        endTimestamp: newEndTimestamp,
                      });
                    }
                  }}
                />
                <ButtonIcon icon={<DeleteOutlined />} onClick={onDeleteTimeRange} />
              </FieldRow>
            );
          })}
        </div>
      </div>
      <div className="metric-custom-period-item-extra-config-container">
        <div>and</div>
        <div className="metric-custom-period-item-repeat-interval-container">
          <Select
            size="large"
            value={repeatIntervalInSeconds}
            onChange={onRepeatIntervalInSecondsChange}
            options={repeatIntervalOptions}
          />
        </div>
        {repeatIntervalInSeconds !== REPEAT_INTERVAL_IN_SECONDS_NO_REPEAT && (
          <>
            <div>and end after</div>
            <div>
              <InputNumber value={repeatCount} onChange={onRepeatCountChange} />
            </div>
            <div>occurrences</div>
          </>
        )}
      </div>
    </div>
  );
}

function MetricCustomSeasonalityTakeoverViewContent(props) {
  const { value: seasonality, onChange } = props;

  const { basicSeasonality, customSeasonPeriods = [] } = seasonality;

  function onBasicSeasonalityChange(basicSeasonalityOption) {
    if (basicSeasonalityOption !== basicSeasonality) {
      onChange({
        basicSeasonality: basicSeasonalityOption,
        customSeasonPeriods,
      });
    }
  }

  function onAddCustomSeasonPeriod() {
    customSeasonPeriods.push(getDefaultOverrideSeasonPeriod());
    onChange({ basicSeasonality, customSeasonPeriods });
  }

  function onEditCustomSeasonPeriod(index, newCustomSeasonPeriod) {
    customSeasonPeriods[index] = newCustomSeasonPeriod;
    onChange({ basicSeasonality, customSeasonPeriods });
  }

  function onDeleteCustomSeasonPeriod(index) {
    customSeasonPeriods.splice(index, 1);
    onChange({ basicSeasonality, customSeasonPeriods });
  }

  return (
    <>
      <div className="metric-basic-seasonality-setting-container">
        <div className="metric-custom-season-period-header-title-container">
          Custom Override ({customSeasonPeriods.length})
        </div>
        <LabeledSelect
          label="Basic Seasonality"
          value={basicSeasonality}
          onChange={onBasicSeasonalityChange}
          options={basicSeasonalityOptions}
        />
      </div>
      <div className="metric-custom-season-period-container">
        <div className="metric-custom-season-period-header-container">
          <div className="metric-custom-season-period-header-indicator-container">
            A custom override period is one that can be treated as distinct or different
            from base seasonality.
          </div>
          <div className="metric-custom-season-period-header-add-button-container">
            <Button outline onClick={onAddCustomSeasonPeriod}>
              Add
              <PlusOutlined />
            </Button>
          </div>
        </div>
        <div className="metric-custom-season-period-list-container">
          {customSeasonPeriods.map(function (customSeasonPeriod, index) {
            return (
              <MetricCustomSeasonPeriod
                value={customSeasonPeriod}
                onChange={onEditCustomSeasonPeriod.bind(null, index)}
                onDelete={onDeleteCustomSeasonPeriod.bind(null, index)}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

function MetricCustomSeasonalityTakeoverView(props) {
  const { value: defaultValue, windowConfig, onSave, closeTakeover } = props;

  const [value, setValue] = React.useState({});

  useEffect(() => {
    setValue(getSeasonFromSeasonConfig(defaultValue));
  }, [defaultValue]);

  function onCancelClicked() {
    closeTakeover();
  }

  function onSaveClicked() {
    onSave(getSeasonConfigFromSeason(value));
    closeTakeover();
  }

  const cornerControls = (
    <div className="metric-custom-seasonality-panel-controls">
      <Button outline size="large" onClick={onCancelClicked}>
        Cancel
      </Button>
      <Button primary size="large" onClick={onSaveClicked}>
        Done
      </Button>
    </div>
  );

  return (
    <TakeoverPanel
      title="Custom Seasonality"
      titleIcon={<SettingOutlined />}
      cornerControls={cornerControls}
    >
      <div className="metric-custom-seasonality-takeover-view-content-container">
        <MetricCustomSeasonalityTakeoverViewContent
          windowConfig={windowConfig}
          value={value}
          onChange={setValue}
        />
      </div>
    </TakeoverPanel>
  );
}

export default MetricCustomSeasonalityTakeoverView;
