import { useEffect } from "react";
import Plotly from "plotly.js/lib/core";
import Bar from "plotly.js/lib/bar";
import Box from "plotly.js/lib/box";
import HeatMap from "plotly.js/lib/heatmap";
import Scatter from "plotly.js/lib/scatter";
import createPlotlyComponent from "react-plotly.js/factory";
import { classesName } from "./css";

Plotly.register([Scatter, HeatMap, Bar, Box]);

const Plot = createPlotlyComponent(Plotly);

// When a plot layout changes in plotly.js, it sometimes fails to resize to occupy the height of
// its parent. Having the window resize seems to jolt plotly.js to do the right thing. To work
// around this, we dispatch a window resize event after a delay whenever we render plotly charts.
// This is a hack, and is not ideal because the layout shifts, but it seems to work.
function DeferredInitWindowResizePlot(props) {
  const { enableSnapshot = false, className: propsClassName, ...restProps } = props;

  useEffect(() => {
    if (!window.plotlyResizeEventDeffered) {
      window.plotlyResizeEventDeffered = true;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
        window.plotlyResizeEventDeffered = false;
      }, 500);
    }
  });

  const className = classesName(!enableSnapshot && "chromatic-ignore", propsClassName);

  return <Plot className={className} {...restProps} />;
}

export default DeferredInitWindowResizePlot;
