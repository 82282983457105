import { TeradataAuthLogMech, TeradataAuthLogMechNameMap } from "../../../utils/enums";
import { LabeledSelect } from "../../labeled-control/labeled-control";

const logMechOptions = Object.values(TeradataAuthLogMech).map((value) => ({
  label: TeradataAuthLogMechNameMap[value],
  value,
}));

function TeradataAuthType(props) {
  const { value, onChange } = props;
  const { logMech = TeradataAuthLogMech.DATABASE } = value;

  return (
    <LabeledSelect
      label="Auth type"
      staticLabel
      options={logMechOptions}
      value={logMech}
      onChange={(newValue) => {
        onChange?.({ ...value, logMech: newValue });
      }}
    />
  );
}

export default TeradataAuthType;
