import React from "react";
import ChartEnableView from "../../components/chart-view/chart-enable-view";
import NgButton from "../../components/button/ng-button";
import { Spinner } from "../../atom/spinner";

import "./profiler-enable-view.scss";

export default function ProfilerEnableView(props) {
  const {
    icon,
    description,
    extraInfo,
    buttonContent,
    gridOnly = false,
    buttonEnabled = true,
    isInProgress = false,
    loading = false,
    fullHeight = false,
    onClick,
  } = props;

  return (
    <ChartEnableView
      gridOnly={gridOnly}
      fullHeight={fullHeight}
      loading={loading}
      content={
        <div className="profiler-enable-view-disable-container">
          {isInProgress ? (
            <div className="profiler-enable-view-in-progress">
              <Spinner size="large" />
            </div>
          ) : (
            icon
          )}
          {description && (
            <div className="profiler-enable-view-disable-description-container">
              {description}
            </div>
          )}
          {extraInfo && (
            <div className="profiler-enable-view-disable-extra-info-container">
              {extraInfo}
            </div>
          )}
          {buttonContent && (
            <NgButton outline onClick={onClick} disabled={!buttonEnabled}>
              {buttonContent}
            </NgButton>
          )}
        </div>
      }
    />
  );
}
