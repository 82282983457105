import { SET_DRAFT_LIST } from "../../actions/draft/draft-action-types";

const initialDraftState = {
  draftList: {
    loading: true,
    data: [],
  },
};

export default function draftReducer(state = initialDraftState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_DRAFT_LIST:
      newState = { ...state, draftList: action.draftList };
      return newState;

    default:
      return state;
  }
}
