import React from "react";
import { ConfigDirection, IncidentDirection } from "../../../utils/enums";

export function IncidentDirectionUpIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41107 1.9107C9.73651 1.58527 10.2641 1.58527 10.5896 1.9107L15.5896 6.9107C15.915 7.23614 15.915 7.76378 15.5896 8.08922C15.2641 8.41465 14.7365 8.41465 14.4111 8.08922L10.8337 4.5118V17.5C10.8337 17.9602 10.4606 18.3333 10.0003 18.3333C9.54009 18.3333 9.16699 17.9602 9.16699 17.5V4.5118L5.58958 8.08922C5.26414 8.41465 4.73651 8.41465 4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107L9.41107 1.9107Z"
        fill="#121224"
      />
    </svg>
  );
}

export function IncidentDirectionDownIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 1.66663C10.4606 1.66663 10.8337 2.03972 10.8337 2.49996V15.4881L14.4111 11.9107C14.7365 11.5853 15.2641 11.5853 15.5896 11.9107C15.915 12.2361 15.915 12.7638 15.5896 13.0892L10.5896 18.0892C10.2641 18.4147 9.73651 18.4147 9.41107 18.0892L4.41107 13.0892C4.08563 12.7638 4.08563 12.2361 4.41107 11.9107C4.73651 11.5853 5.26414 11.5853 5.58958 11.9107L9.16699 15.4881V2.49996C9.16699 2.03972 9.54009 1.66663 10.0003 1.66663Z"
        fill="#121224"
      />
    </svg>
  );
}

export function IncidentDirectionBothIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5056 1.86883C8.18016 1.5434 7.65252 1.5434 7.32709 1.86883L2.32709 6.86884C2.00165 7.19427 2.00165 7.72191 2.32709 8.04735C2.65252 8.37279 3.18016 8.37279 3.5056 8.04735L7.08301 4.46993V17.5C7.08301 17.9602 7.4561 18.3333 7.91634 18.3333C8.37658 18.3333 8.74967 17.9602 8.74967 17.5V2.49997C8.74967 2.26234 8.67647 2.0397 8.5056 1.86883ZM12.083 1.70851C12.5432 1.70851 12.9163 2.08161 12.9163 2.54185V15.53L16.4938 11.9526C16.8192 11.6271 17.3468 11.6271 17.6723 11.9526C17.9977 12.278 17.9977 12.8057 17.6723 13.1311L12.6723 18.1311C12.4339 18.3694 12.0755 18.4407 11.7641 18.3117C11.4527 18.1828 11.2497 17.8789 11.2497 17.5418V2.54185C11.2497 2.08161 11.6228 1.70851 12.083 1.70851Z"
        fill="#121224"
      />
    </svg>
  );
}

export function IncidentDirectionIconComponent(direction) {
  switch (direction) {
    case IncidentDirection.UP:
      return IncidentDirectionUpIcon;
    case IncidentDirection.DOWN:
      return IncidentDirectionDownIcon;
    case IncidentDirection.BOTH:
      return IncidentDirectionBothIcon;
    default:
      return null;
  }
}

export function ConfigDirectionIconComponent(configDirection) {
  switch (configDirection) {
    case ConfigDirection.UP:
      return IncidentDirectionUpIcon;
    case ConfigDirection.DOWN:
      return IncidentDirectionDownIcon;
    case ConfigDirection.BOTH:
      return IncidentDirectionBothIcon;
    default:
      return null;
  }
}
