import React, { useState } from "react";
import { Input } from "antd";
import { LabeledSelect } from "../../../../../components/labeled-control/labeled-control";
import Button from "../../../../../components/button/ng-button";
import { DataSourceType } from "../../../../../utils/enums";

import "./virtual-timestamp-column-form.scss";

const TextArea = Input.TextArea;

const helpTooltipMapper = {
  [DataSourceType.ATHENA]: {
    columnToTimestampFunctionDescription: "FROM_UNIXTIME({column_value} / 1000)",
    timestampToColumnFunctionDescription: "TO_UNIXTIME({timestamp_value}) * 1000",
  },
  [DataSourceType.BIGQUERY]: {
    columnToTimestampFunctionDescription: "TIMESTAMP_MILLIS({column_value})",
    timestampToColumnFunctionDescription: "UNIX_SECONDS({timestamp_value}) * 1000",
  },
  [DataSourceType.DATABRICKS]: {
    columnToTimestampFunctionDescription: "CAST(({column_value} / 1000) AS TIMESTAMP)",
    timestampToColumnFunctionDescription:
      "UNIX_TIMESTAMP({timestamp_value})::BIGINT * 1000",
  },
  [DataSourceType.GREENPLUM]: {
    columnToTimestampFunctionDescription: "TO_TIMESTAMP({column_value} / 1000)",
    timestampToColumnFunctionDescription:
      "EXTRACT(epoch FROM {timestamp_value}) * 1000",
  },
  [DataSourceType.HIVE]: {
    columnToTimestampFunctionDescription:
      "FROM_UNIXTIME(CAST({column_value} / 1000 AS BIGINT))",
    timestampToColumnFunctionDescription: "UNIX_TIMESTAMP({timestamp_value}) * 1000",
  },
  [DataSourceType.MICROSOFTSQL]: {
    columnToTimestampFunctionDescription:
      "DATEADD(s, {column_value} / 1000, '19700101')",
    timestampToColumnFunctionDescription:
      "CAST(DATEDIFF(s, '19700101', {timestamp_value}) AS BIGINT) * 1000",
  },
  [DataSourceType.ORACLE]: {
    columnToTimestampFunctionDescription:
      "(timestamp '1970-01-01 00:00:00') + numtodsinterval({column_value} / 1000, 'second')",
    timestampToColumnFunctionDescription:
      "(CAST(SYS_EXTRACT_UTC({timestamp_value}) AS DATE) - DATE '1970-01-01' ) * 86400 * 1000",
  },
  [DataSourceType.POSTGRES]: {
    columnToTimestampFunctionDescription: "TO_TIMESTAMP({column_value} / 1000)",
    timestampToColumnFunctionDescription:
      "EXTRACT(epoch FROM {timestamp_value}) * 1000",
  },
  [DataSourceType.REDSHIFT]: {
    columnToTimestampFunctionDescription:
      "TIMESTAMP 'epoch' + {column_value} / 1000 * interval '1 second'",
    timestampToColumnFunctionDescription:
      "EXTRACT(epoch FROM {timestamp_value})::bigint * 1000",
  },
  [DataSourceType.SNOWFLAKE]: {
    columnToTimestampFunctionDescription: "TO_TIMESTAMP({column_value} / 1000)",
    timestampToColumnFunctionDescription:
      "EXTRACT(epoch FROM {timestamp_value}) * 1000",
  },
  [DataSourceType.TERADATA]: {
    columnToTimestampFunctionDescription: "TO_TIMESTAMP({column_value} / 1000)",
    timestampToColumnFunctionDescription:
      "CAST(((CAST(CAST({timestamp_value} AS TIMESTAMP) AS DATE AT 'GMT') - DATE '1970-01-01') * 86400 + (EXTRACT(HOUR FROM CAST({timestamp_value} AS TIMESTAMP) AT 'GMT') * 3600) + (EXTRACT(MINUTE FROM CAST({timestamp_value} AS TIMESTAMP) AT 'GMT') * 60) + (EXTRACT(SECOND FROM CAST({timestamp_value} AS TIMESTAMP)))) * 1000 AS BIGINT)",
  },
};

function VirtualTimestampColumnForm(props) {
  const {
    isAdd,
    onDelete,
    onCancel,
    onSave,
    value: { timestampColumn: initialTimestampColumn = null, timestampColumnFunctions },
    dataSource,
    columnList,
  } = props;

  const {
    columnToTimestampFunction: initialColumnToTimestampFunction,
    timestampToColumnFunction: initialTimestampToColumnFunction,
  } = timestampColumnFunctions ?? {};

  const [timestampColumn, setTimestampColumn] = useState(initialTimestampColumn);
  const [columnToTimestampFunction, setColumnToTimestampFunction] = useState(
    initialColumnToTimestampFunction
  );
  const [timestampToColumnFunction, setTimestampToColumnFunction] = useState(
    initialTimestampToColumnFunction
  );

  const dataSourceType = dataSource?.config.connection.type || "";

  const columnOptions = columnList.map(({ columnName }) => ({
    label: columnName,
    value: columnName,
  }));

  function onSaveButtonClick() {
    onSave({
      timestampColumn,
      timestampColumnFunctions: {
        columnToTimestampFunction,
        timestampToColumnFunction,
      },
    });
  }

  const { columnToTimestampFunctionDescription, timestampToColumnFunctionDescription } =
    helpTooltipMapper[dataSourceType] ?? {};

  return (
    <div className="virtual-timestamp-column-form">
      <div className="virtual-timestamp-column-form-title">
        {isAdd ? "Create a virtual column" : "Edit virtual column"}
      </div>
      <div className="virtual-timestamp-column-form-description">
        Input SQL to define how to convert column to timestamp and timestamp to column.
      </div>
      <div className="virtual-timestamp-column-form-column-select">
        <LabeledSelect
          staticLabel
          showSearch
          label={"Select a column"}
          enableSorting={true}
          value={timestampColumn}
          onChange={setTimestampColumn}
          options={columnOptions}
        />
      </div>
      <div className="virtual-timestamp-column-form-functions">
        <div className="virtual-timestamp-column-form-function">
          <TextArea
            rows={6}
            value={columnToTimestampFunction}
            placeholder="Column to Timestamp SQL"
            onChange={(e) => {
              setColumnToTimestampFunction(e.target.value);
            }}
          />
          {columnToTimestampFunctionDescription && (
            <div className="virtual-timestamp-column-form-function-help">
              Example: {columnToTimestampFunctionDescription}
            </div>
          )}
        </div>
        <div className="virtual-timestamp-column-form-function">
          <TextArea
            rows={6}
            value={timestampToColumnFunction}
            placeholder="Timestamp to Column SQL"
            onChange={(e) => {
              setTimestampToColumnFunction(e.target.value);
            }}
          />
          {timestampToColumnFunctionDescription && (
            <div className="virtual-timestamp-column-form-function-help">
              Example: {timestampToColumnFunctionDescription}
            </div>
          )}
        </div>
      </div>
      <div className="virtual-timestamp-column-form-actions">
        {!isAdd && (
          <Button
            outline
            size="large"
            style={{ width: 140 }}
            onClick={() => onDelete()}
            testId="virtual-timestamp-column-add-delete"
          >
            Delete
          </Button>
        )}
        <div className="virtual-timestamp-column-form-actions-spacer" />
        <Button
          outline
          size="large"
          style={{ width: 140 }}
          onClick={() => onCancel()}
          testId="virtual-timestamp-column-cancel"
        >
          Cancel
        </Button>
        <Button
          primary
          size="large"
          style={{ width: 140 }}
          onClick={() => onSaveButtonClick()}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default VirtualTimestampColumnForm;
