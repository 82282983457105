import { EditOutlined } from "@ant-design/icons";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import { default as NgDropdownMenu } from "../../components/ng-dropdown-menu";
import { AppPermissions } from "../../utils/permissions";
import { EVENT, trackEvent, PAGE } from "../../utils/telemetry";
import { URIPath, getURIInstance, hasPermission } from "../../utils/uri-path";
import { DataSourceActions } from "../datasources/datasource-actions-menu";
import { MetricDataSourceTypeIcon } from "../../components/metric/fields/icons";

function NotificationDatasourceActionsMenu(props) {
  const {
    trigger,
    history,
    dataSourceUuid,
    workspaceUuid,
    workspaceUserPermissions,
    actions = [],
    loading = false,
    getPopupContainer,
  } = props;

  const viewDataSourcePermissions = [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
  ];
  const modifyDataSourcePermissions = [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
  ];
  const canViewDataSource = hasPermission(
    workspaceUserPermissions,
    viewDataSourcePermissions
  );
  const canModifyDataSource = hasPermission(
    workspaceUserPermissions,
    modifyDataSourcePermissions
  );
  const menuConfig = [
    {
      action: DataSourceActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewDataSourcePermissions,
      item: {
        label: canModifyDataSource ? "Edit Datasource" : "View",
        icon: <EditOutlined />,
        onClick: () => {
          if (canModifyDataSource) {
            trackEvent(EVENT.EDIT_DATASOURCE, {
              page: PAGE.DATASOURCES,
            });
          }

          const nextUrl = getURIInstance(URIPath.EDIT_DATA_SOURCE, {
            workspaceUuid,
            id: dataSourceUuid,
          });
          history.push(nextUrl);
        },
        disabled: !canViewDataSource,
      },
    },
    {
      action: DataSourceActions.VIEW_DATASOURCES_LIST,
      supportsMultiple: false,
      item: {
        label: "View in Datasource List",
        icon: <MetricDataSourceTypeIcon />,
        onClick: () => {
          const nextUrl = `${getURIInstance(URIPath.DATA_SOURCE_LIST, {
            workspaceUuid,
          })}`;
          history.push(nextUrl);
        },
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    [dataSourceUuid],
    actions,
    workspaceUserPermissions
  );

  return (
    <NgDropdownMenu
      trigger={trigger}
      menuItems={menuItems}
      buttonProps={{ disabled: loading }}
      getPopupContainer={getPopupContainer}
      position="bottomLeft"
    />
  );
}

export default NotificationDatasourceActionsMenu;
