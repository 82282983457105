import getUnixTime from "date-fns/getUnixTime";

export const ValidationMethodNameConst = Object.freeze({
  TIME_NOT_EMPTY: "TIME_NOT_EMPTY",
  TIME_NO_LATE_THAN_NOW: "TIME_NO_LATE_THAN_NOW",
  START_TIME_NO_LATE_THAN_END_TIME: "START_TIME_NO_LATE_THAN_END_TIME",
});

const validateTimeMapper = {};

function timeNotEmpty(errors, currentPath, errorPath, validateTimestamp) {
  if (typeof validateTimestamp !== "number") {
    errors[errorPath] = "Time can not be empty";
  } else {
    errors[errorPath] = "";
  }
}
validateTimeMapper[ValidationMethodNameConst.TIME_NOT_EMPTY] = timeNotEmpty;

function timeNoLateThanNow(errors, currentPath, errorPath, validateTimestamp) {
  if (validateTimestamp > getUnixTime(new Date())) {
    errors[errorPath] = "Time can not be future time";
  } else {
    errors[errorPath] = "";
  }
}
validateTimeMapper[ValidationMethodNameConst.TIME_NO_LATE_THAN_NOW] = timeNoLateThanNow;

function startTimeNoLateThanEndTime(
  errors,
  currentPath,
  errorPath,
  startTimestamp,
  endTimestamp
) {
  if (
    typeof startTimestamp === "number" &&
    typeof endTimestamp === "number" &&
    startTimestamp > endTimestamp
  ) {
    errors[errorPath] = "Start time can not be late than end time";
  } else {
    errors[errorPath] = "";
  }
}
validateTimeMapper[ValidationMethodNameConst.START_TIME_NO_LATE_THAN_END_TIME] =
  startTimeNoLateThanEndTime;

export function validateData(newValue, dataModal) {
  const errors = {};
  for (let key in newValue) {
    if (dataModal[key]) {
      let { validators } = dataModal[key];
      const currentValue = newValue[key];
      for (let validator of validators) {
        let { path, errorPath, method } = validator;
        let normalizedParams = [];
        if (!errorPath) {
          errorPath = path;
        }
        if (typeof path === "string") {
          normalizedParams = [currentValue[path]];
        } else if (Object.prototype.toString.call(path) === "[object Array]") {
          normalizedParams = path.map((pathKey) => currentValue[pathKey]);
        } else {
          normalizedParams = [currentValue];
        }

        let normalizedMethod = null;
        if (typeof method === "function") {
          normalizedMethod = method;
        } else if (
          typeof method === "string" &&
          typeof validateTimeMapper[method] === "function"
        ) {
          normalizedMethod = validateTimeMapper[method];
        } else {
          console.log(`Invalid validator config ${JSON.stringify(validator)}`);
          continue;
        }

        normalizedMethod.apply(null, [errors, path, errorPath, ...normalizedParams]);
      }
    }
  }

  return errors;
}

export function isValueError(errors) {
  for (let key in errors) {
    if (errors[key]) {
      return true;
    }
  }

  return false;
}
