// Profiler consumer page
export const SET_PROFILER_TREE_DATA = "SET_PROFILER_TREE_DATA";
export const SET_PROFILER_CUSTOM_METRIC_TREE_DATA =
  "SET_PROFILER_CUSTOM_METRIC_TREE_DATA";
export const SET_PROFILER_CURRENT_DATA_SOURCE_SUMMARY =
  "SET_PROFILER_CURRENT_DATA_SOURCE_SUMMARY";
export const SET_PROFILER_CURRENT_SCHEMA_SUMMARY =
  "SET_PROFILER_CURRENT_SCHEMA_SUMMARY";
export const SET_PROFILER_CURRENT_TABLE_AUTO_METRICS_DATA =
  "SET_PROFILER_CURRENT_TABLE_AUTO_METRICS_DATA";
export const SET_PROFILER_CURRENT_METRIC_METRICS_DATA =
  "SET_PROFILER_CURRENT_METRIC_METRICS_DATA";
export const SET_PROFILER_CURRENT_METRIC_SLICE_VALUES =
  "SET_PROFILER_CURRENT_METRIC_SLICE_VALUES";

// Profiler table consumer page
export const SET_PROFILER_CURRENT_TABLE_OVERVIEW_SUMMARY =
  "SET_PROFILER_CURRENT_TABLE_OVERVIEW_SUMMARY";

// Profiler Config home page
export const SET_PROFILER_CONFIG_DATA_SOURCE_LIST =
  "SET_PROFILER_CONFIG_DATA_SOURCE_LIST";
export const SET_PROFILER_CONFIG_TABLE_LIST = "SET_PROFILER_CONFIG_TABLE_LIST";

// Profiler Config Data Source Page
export const SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE =
  "SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE";
export const SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST =
  "SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST";
export const SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_COLUMN_LIST =
  "SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_COLUMN_LIST";
export const SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_COLUMN_LIST =
  "SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_COLUMN_LIST";
export const SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST_USAGE =
  "SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST_USAGE";

// Profiler Config Schema Page
export const SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST =
  "SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST";
export const SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST_USAGE =
  "SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST_USAGE";

// Profiler Config Table Page
export const SET_PROFILER_CONFIG_TABLE_CURRENT_DATA_SOURCE =
  "SET_PROFILER_CONFIG_TABLE_CURRENT_DATA_SOURCE";
export const SET_PROFILER_CONFIG_TABLE_CURRENT_TABLE =
  "SET_PROFILER_CONFIG_TABLE_CURRENT_TABLE";
export const SET_PROFILER_CONFIG_TABLE_BLOB_LIST =
  "SET_PROFILER_CONFIG_TABLE_BLOB_LIST";
export const SET_PROFILER_CONFIG_TABLE_COLUMN_LIST =
  "SET_PROFILER_CONFIG_TABLE_COLUMN_LIST";
export const SET_PROFILER_CONFIG_TABLE_CHANGE_LOG =
  "SET_PROFILER_CONFIG_TABLE_CHANGE_LOG";
export const SET_PROFILER_CONFIG_TABLE_COLUMN_LIST_USAGE =
  "SET_PROFILER_CONFIG_TABLE_COLUMN_LIST_USAGE";
export const SET_PROFILER_CONFIG_TABLE_COLUMN_METRICS =
  "SET_PROFILER_CONFIG_TABLE_COLUMN_METRICS";
export const SET_PROFILER_TABLE_DATA_PROFILE_DATA =
  "SET_PROFILER_TABLE_DATA_PROFILE_DATA";
export const SET_PROFILER_TABLE_METADATA_METRICS_DATA =
  "SET_PROFILER_TABLE_METADATA_METRICS_DATA";
export const SET_PROFILER_TABLE_HEALTH_DATA = "SET_PROFILER_TABLE_HEALTH_DATA";

// Profiler column consumer page
export const SET_PROFILER_COLUMN_CURRENT_METRICS_DATA =
  "SET_PROFILER_COLUMN_CURRENT_METRICS_DATA";
export const SET_PROFILER_COLUMN_DATA_PROFILE_DATA =
  "SET_PROFILER_COLUMN_DATA_PROFILE_DATA";

// Profiler incident list page
export const SET_PROFILER_CURRENT_INCIDENT_LIST = "SET_PROFILER_CURRENT_INCIDENT_LIST";
export const SET_PROFILER_CURRENT_INCIDENT_METRICS_DATA =
  "SET_PROFILER_CURRENT_INCIDENT_METRICS_DATA";

// Profiler current config object
export const SET_PROFILER_CURRENT_MONITOR_OBJECT =
  "SET_PROFILER_CURRENT_MONITOR_OBJECT";

// Profiler table data profile
export const SET_PROFILER_TABLE_DATA_PROFILE_TABLE =
  "SET_PROFILER_TABLE_DATA_PROFILE_TABLE";
export const SET_PROFILER_TABLE_DATA_PROFILE_TABLE_DATA_PROFILE =
  "SET_PROFILER_TABLE_DATA_PROFILE_TABLE_DATA_PROFILE";

// Profiler partition sample data
export const SET_PROFILER_PARTITION_SAMPLE_DATA = "SET_PROFILER_PARTITION_SAMPLE_DATA";
export const SET_PROFILER_TABLE_LIST_PARTITION_SAMPLE_DATA =
  "SET_PROFILER_TABLE_LIST_PARTITION_SAMPLE_DATA";

// Tree menu actions
export const SET_PROFILER_AFTER_TREE_NAVIGATION_ACTION =
  "SET_PROFILER_AFTER_TREE_NAVIGATION_ACTION";

// Tree virtual table sql column list
export const SET_PROFILER_VIRTUAL_TABLE_COLUMN_LIST =
  "SET_PROFILER_VIRTUAL_TABLE_COLUMN_LIST";

export const SET_PROFILER_VIRTUAL_TABLE_SCHEMA_LIST =
  "SET_PROFILER_VIRTUAL_TABLE_SCHEMA_LIST";
export const SET_PROFILER_VIRTUAL_TABLE_SAMPLE_DATA_LIST =
  "SET_PROFILER_VIRTUAL_TABLE_SAMPLE_DATA_LIST";
