import { getStringCompareFunction } from "../../../utils/search";

export const GENERAL_SEARCH_FIELD = "searchName";

const stringCompareFunction = getStringCompareFunction(false);

export const integrationFields = {
  INTEGRATION: "integration",
};

export const integrationFieldOptionValueGetterFn = {
  [integrationFields.INTEGRATION]: (integration) => integration.title,
};

export const integrationFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    return searchName.length && !stringCompareFunction(row.title, searchName);
  },
  [integrationFields.INTEGRATION]: (row, integrationName) => {
    return !stringCompareFunction(row.title, integrationName);
  },
};
