import React from "react";
import { DivWithClassName } from "../layout";

import "./modal-field-groups.scss";

export function ModalFieldGroup(props) {
  const { label, children } = props;
  return (
    <div className="modal-field-group">
      <div className="modal-field-group-label">{label}</div>
      {children}
    </div>
  );
}

export const ModalFieldRows = DivWithClassName("modal-field-rows", "ModalFieldRows");
