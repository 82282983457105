import React, { Component } from "react";
import { withRouter } from "react-router";
import AddKPIView from "../../components/add-kpi-view/";
import { getDefaultCloneTitle } from "../../utils/defaults";
import { MetricCategory, MetricConfigType, MetricType } from "../../utils/enums";
import {
  getCloneFromParams,
  getSettingFromParams,
  getURIInstance,
  URIPath,
} from "../../utils/uri-path";
import queryString from "query-string";
import { explorerMetricUrl } from "../profiler/utils";
import { goBackOrToDefault } from "../../utils/router";

class AddKPIWizard extends Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.exitRedirect = this.exitRedirect.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { workspaceUuid },
      },
    } = this.props;
    const cloneFrom = getCloneFromParams(this.props.location.search);
    if (cloneFrom) {
      console.log(`Cloning kpi from ${cloneFrom}`);
      this.props.getKpi(workspaceUuid, cloneFrom);
    }
  }

  exitRedirect(newKPI = null, forceBackTo = null) {
    const {
      history,
      match: {
        params: { workspaceUuid },
      },
      location,
    } = this.props;

    const queryParams = queryString.parse(location.search);
    const returnTo = queryParams.returnTo;

    if (typeof forceBackTo === "string") {
      history.replace(forceBackTo);
    } else if (returnTo === "profilerMetricNode" && newKPI) {
      history.push(explorerMetricUrl(newKPI));
    } else {
      goBackOrToDefault(
        history,
        getURIInstance(URIPath.NG_METRIC_LIST, { workspaceUuid })
      );
    }
  }

  onCancel() {
    this.exitRedirect();
  }

  onSave(workspaceUuid, newKPI, newRuleList, config = {}) {
    const { isDraft = false } = config;
    const backUrl = isDraft
      ? getURIInstance(URIPath.NG_DRAFT_LIST, { workspaceUuid })
      : null;
    return this.props.addKpi(workspaceUuid, newKPI, newRuleList).then((responseKpi) => {
      if (responseKpi) {
        setTimeout(() => {
          this.exitRedirect(responseKpi, backUrl);
        }, 0);
      }
    });
  }

  render() {
    const {
      match: {
        params: { workspaceUuid },
      },
      workspaceUserPermissions,
    } = this.props;

    const cloneFrom = getCloneFromParams(this.props.location.search);
    const isClone = !!cloneFrom;
    if (isClone && !this.props.currentCloneKpi) {
      console.log(`Loading cloned kpi ${cloneFrom}`);
      return null;
    }

    let defaultData;
    let isDataCompare = !!getSettingFromParams(
      "dataCompare",
      this.props.location.search,
      0
    );
    if (isClone) {
      defaultData = {
        apiVersion: this.props.currentCloneKpi.apiVersion,
        type: this.props.currentCloneKpi.type,
        config: this.props.currentCloneKpi.config,
        metadata: {
          name: getDefaultCloneTitle(this.props.currentCloneKpi.metadata.name),
          creationType: MetricType.CUSTOM,
          tags: this.props.currentCloneKpi.metadata.tags,
          description: this.props.currentCloneKpi.metadata.description,
        },
      };

      isDataCompare = [
        MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG,
        MetricConfigType.FULL_COMPARE_METRIC_CONFIG,
      ].includes(defaultData.config.configType);
    } else {
      defaultData = null;
    }
    const isVirtualTablePreSelected =
      getSettingFromParams("isUserDefinedTable", this.props.location.search) === "true";

    const metricCategoryWhiteList = isVirtualTablePreSelected
      ? [MetricCategory.FULL_COMPARE]
      : null;

    return (
      <AddKPIView
        onSave={this.onSave.bind(this, workspaceUuid)}
        onCancel={this.onCancel}
        isClone={isClone}
        isDataCompare={isDataCompare}
        defaultData={defaultData}
        workspaceUserPermissions={workspaceUserPermissions}
        metricCategoryWhiteList={metricCategoryWhiteList}
      />
    );
  }
}

export default withRouter(AddKPIWizard);
