import {
  SET_RULE_LIST,
  SET_RULE_LIST_PAGE_CONFIGURATION,
  SET_RULE_INCIDENT_LIST,
  SET_CURRENT_EDIT_RULE,
  SET_CURRENT_ADD_RULE,
  SET_CURRENT_CLONE_RULE,
  SET_CURRENT_DELETE_RULE,
  SET_CURRENT_SAVE_RULE,
  SET_CURRENT_RULE_STATUS,
  SET_NON_SLICE_RULE_TRAINING_DATA,
  SET_SLICE_RULE_TRAINING_DATA,
  SET_CURRENT_ADD_PREVIEW,
  SET_PREVIEW_LIST,
  SET_PREVIEW_INCIDENT_LIST,
  SET_CURRENT_PREVIEW_TRAINING_DATA,
  SET_CURRENT_PREVIEW_SUMMARY_DATA,
  SET_CURRENT_PREVIEW_METRICS_DATA,
  SET_CURRENT_PREVIEW_SLICE_VALUE,
} from "../../actions/rule/rule-action-types";

const initialRuleState = {
  ruleList: {
    loading: false,
    data: [],
  },
  ruleListPageConfiguration: {
    columns: [],
  },
  ruleIncidentList: [],
  currentAddRule: null,
  currentCloneRule: null,
  currentDeleteRule: "unknown",
  currentSaveRule: "unknown",
  currentRuleStatus: [],
  nonSliceRuleTrainingData: [],
  sliceRuleTrainingData: {},
  previewList: [],
  previewIncidentList: [],
  currentAddPreview: null,
  currentPreviewMetricsData: {
    loading: true,
    data: [],
  },
  currentPreviewTrainingData: {
    metricsData: {
      projectedData: [],
      anomalyData: [],
      normalData: [],
      upperBoundData: [],
      lowerBoundData: [],
      signalData: [],
    },
    range: {
      metricsRange: {
        min: undefined,
        max: undefined,
      },
      signalRange: {
        min: undefined,
        max: undefined,
      },
    },
  },
  currentPreviewSummaryData: {
    total: 0,
    unread: 0,
    accepted: 0,
    rejected: 0,
    falsePositiveRate: "N/A",
  },
  currentPreviewSliceValue: {
    loading: true,
    data: [],
  },
};

export default function ruleReducer(state = initialRuleState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_RULE_LIST:
      newState = { ...state, ruleList: action.ruleList };
      return newState;

    case SET_RULE_LIST_PAGE_CONFIGURATION:
      newState = {
        ...state,
        ruleListPageConfiguration: action.ruleListPageConfiguration,
      };
      return newState;

    case SET_RULE_INCIDENT_LIST:
      newState = { ...state, ruleIncidentList: action.ruleIncidentList };
      return newState;

    case SET_CURRENT_EDIT_RULE:
      newState = { ...state, currentEditRule: action.currentEditRule };
      return newState;

    case SET_CURRENT_ADD_RULE:
      newState = { ...state, currentAddRule: action.currentAddRule };
      return newState;

    case SET_CURRENT_CLONE_RULE:
      newState = { ...state, currentCloneRule: action.currentCloneRule };
      return newState;

    case SET_CURRENT_DELETE_RULE:
      newState = { ...state, currentDeleteRule: action.currentDeleteRule };
      return newState;

    case SET_CURRENT_SAVE_RULE:
      newState = { ...state, currentSaveRule: action.currentSaveRule };
      return newState;

    case SET_NON_SLICE_RULE_TRAINING_DATA:
      newState = {
        ...state,
        nonSliceRuleTrainingData: action.nonSliceRuleTrainingData,
      };
      return newState;

    case SET_SLICE_RULE_TRAINING_DATA:
      newState = {
        ...state,
        sliceRuleTrainingData: action.sliceRuleTrainingData,
      };
      return newState;

    case SET_PREVIEW_LIST:
      newState = { ...state, previewList: action.previewList };
      return newState;

    case SET_CURRENT_ADD_PREVIEW:
      newState = { ...state, currentAddPreview: action.currentAddPreview };
      return newState;

    case SET_PREVIEW_INCIDENT_LIST:
      newState = { ...state, previewIncidentList: action.previewIncidentList };
      return newState;

    case SET_CURRENT_PREVIEW_TRAINING_DATA:
      newState = {
        ...state,
        currentPreviewTrainingData: action.currentPreviewTrainingData,
      };
      return newState;

    case SET_CURRENT_PREVIEW_SUMMARY_DATA:
      newState = {
        ...state,
        currentPreviewSummaryData: action.currentPreviewSummaryData,
      };
      return newState;

    case SET_CURRENT_PREVIEW_METRICS_DATA:
      newState = {
        ...state,
        currentPreviewMetricsData: action.currentPreviewMetricsData,
      };
      return newState;

    case SET_CURRENT_PREVIEW_SLICE_VALUE:
      newState = {
        ...state,
        currentPreviewSliceValue: action.currentPreviewSliceValue,
      };
      return newState;

    case SET_CURRENT_RULE_STATUS:
      newState = { ...state, currentRuleStatus: action.currentRuleStatus };
      return newState;

    default:
      return state;
  }
}
