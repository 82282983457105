import React, { useState } from "react";
import { classesName } from "../../utils/css";
import CollapseToggle from "../button/collapse-toggle";

import "./index.scss";

export const AlertType = Object.freeze({
  SUCCESS: "success",
  FAILURE: "failure",
  INFO: "info",
});

function SuccessIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81456 2.81456C4.14091 1.48823 5.97516 0.666687 8 0.666687C10.0248 0.666687 11.8591 1.48823 13.1854 2.81457C14.5118 4.14092 15.3333 5.97517 15.3333 8.00002C15.3333 10.0249 14.5118 11.8591 13.1854 13.1855C11.8591 14.5118 10.0249 15.3334 8 15.3334C5.97516 15.3334 4.14091 14.5118 2.81456 13.1855C1.48822 11.8591 0.666672 10.0249 0.666672 8.00002C0.666672 5.97517 1.48822 4.14092 2.81456 2.81456ZM11.8047 6.47142C12.0651 6.21108 12.0651 5.78896 11.8047 5.52862C11.5444 5.26827 11.1223 5.26827 10.8619 5.52862L7.33334 9.05721L5.80474 7.52862C5.54439 7.26827 5.12228 7.26827 4.86193 7.52862C4.60158 7.78896 4.60158 8.21107 4.86193 8.47143L6.86193 10.4714C7.12228 10.7318 7.54439 10.7318 7.80474 10.4714L11.8047 6.47142Z"
        fill="#5CCAB4"
      />
    </svg>
  );
}

function FailureIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 0.666504C3.94926 0.666504 0.666016 3.94975 0.666016 7.99984C0.666016 12.0499 3.94926 15.3332 7.99935 15.3332C12.0494 15.3332 15.3327 12.0499 15.3327 7.99984C15.3327 3.94975 12.0494 0.666504 7.99935 0.666504ZM11.4708 4.52843C11.7311 4.78878 11.7311 5.21089 11.4708 5.47124L8.94216 7.99984L11.4708 10.5284C11.7311 10.7888 11.7311 11.2109 11.4708 11.4712C11.2104 11.7316 10.7883 11.7316 10.5279 11.4712L7.99935 8.94265L5.47075 11.4712C5.2104 11.7316 4.78829 11.7316 4.52794 11.4712C4.26759 11.2109 4.26759 10.7888 4.52794 10.5284L7.05654 7.99984L4.52794 5.47124C4.26759 5.21089 4.26759 4.78878 4.52794 4.52843C4.78829 4.26808 5.2104 4.26808 5.47075 4.52843L7.99935 7.05703L10.5279 4.52843C10.7883 4.26808 11.2104 4.26808 11.4708 4.52843Z"
        fill="#EE8B9E"
      />
    </svg>
  );
}

function AlertIcon(type) {
  switch (type) {
    case AlertType.SUCCESS:
      return SuccessIcon;
    case AlertType.FAILURE:
      return FailureIcon;
    default:
      return () => null;
  }
}

function Alert(props) {
  const {
    message,
    description,
    type,
    boldMessage = true,
    collapsible = false,
    initialCollapsed = false,
  } = props;

  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const Icon = AlertIcon(type);
  const className = classesName(
    "ng-alert",
    type === AlertType.SUCCESS && "success",
    type === AlertType.FAILURE && "failure",
    type === AlertType.INFO && "info"
  );

  const alertMessageClassName = classesName("ng-alert-message", boldMessage && "bold");
  return (
    <div className={className}>
      <div className={alertMessageClassName}>
        {Icon && <Icon />}
        {message}
        {collapsible && description && (
          <CollapseToggle collapsed={collapsed} onChange={setCollapsed} />
        )}
      </div>
      {description && !collapsed && (
        <div className="ng-alert-description">{description}</div>
      )}
    </div>
  );
}

export default Alert;
