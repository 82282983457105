import { connect } from "react-redux";
import {
  getUserAPIKeyList,
  getUserPermissionForAllWorkspace,
  addUserAPIKey,
  deleteUserAPIKey,
  updateUserAPIKeyActiveStatus,
  resetCurrentAddedUserAPIKey,
  logout,
} from "../../actions/user/user-action";
import { getUserWorkspaceList } from "../../actions/admin/admin-action";
import {
  closeTakeover,
  openWorkspaceTakeover,
} from "../../actions/takeover/takeover-action";

import Sidebar from "./sidebar";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  workspaceUserPermissionMap: state.userReducer.workspaceUserPermissionMap,
  workspaceList: state.adminReducer.userWorkspaceList.data,
  userAPIKeyList: state.userReducer.userAPIKeyList,
  currentAddedUserAPIKey: state.userReducer.currentAddedUserAPIKey,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAPIKeyList: () => dispatch(getUserAPIKeyList()),
  getWorkspaceList: () => dispatch(getUserWorkspaceList()),
  getUserPermissionForAllWorkspace: (workspaceList) =>
    dispatch(getUserPermissionForAllWorkspace(workspaceList)),
  openWorkspaceTakeover: (takeoverElement) =>
    dispatch(openWorkspaceTakeover(takeoverElement)),
  closeTakeover: () => dispatch(closeTakeover()),
  addUserAPIKey: () => dispatch(addUserAPIKey()),
  deleteUserAPIKey: (id) => dispatch(deleteUserAPIKey(id)),
  updateUserAPIKeyActiveStatus: (id, active) =>
    dispatch(updateUserAPIKeyActiveStatus(id, active)),
  resetCurrentAddedUserAPIKey: () => dispatch(resetCurrentAddedUserAPIKey()),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
