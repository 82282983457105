import React from "react";

import "./icon-text-toggle-button.scss";

function IconTextToggleButton(props) {
  const {
    text,
    alternateText,
    icon,
    showAlternate = false,
    disabled = false,
    onClick,
  } = props;

  let className = "icon-text-toggle-button";
  if (disabled) {
    className += " disabled";
  }

  return (
    <button className={className} onClick={onClick}>
      {icon}
      {showAlternate ? alternateText : text}
    </button>
  );
}

export default IconTextToggleButton;
