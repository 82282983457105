import React from "react";
import Tooltip from "../tooltip/ng-tooltip";
import NgTable from "../table/ng-table";
import ColumnMaskedIndicator from "../column/column-masked-indicator";

import "./table-schema-view.scss";

const IndexedIndicator = () => {
  return (
    <div className="table-schema-table-extra-meta-cell">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.08333 0.833374C2.54357 0.833374 2.91667 1.20647 2.91667 1.66671V3.33337H6.66667V2.50004C6.66667 2.0398 7.03976 1.66671 7.5 1.66671H13.3333C13.7936 1.66671 14.1667 2.0398 14.1667 2.50004V5.83337C14.1667 6.29361 13.7936 6.66671 13.3333 6.66671H7.5C7.03976 6.66671 6.66667 6.29361 6.66667 5.83337V5.00004H2.91667V9.16671H6.66667V8.33337C6.66667 7.87314 7.03976 7.50004 7.5 7.50004H15.8333C16.2936 7.50004 16.6667 7.87314 16.6667 8.33337V11.6667C16.6667 12.1269 16.2936 12.5 15.8333 12.5H7.5C7.03976 12.5 6.66667 12.1269 6.66667 11.6667V10.8334H2.91667V15H6.66667V14.1667C6.66667 13.7065 7.03976 13.3334 7.5 13.3334H18.3333C18.7936 13.3334 19.1667 13.7065 19.1667 14.1667V17.5C19.1667 17.9603 18.7936 18.3334 18.3333 18.3334H7.5C7.03976 18.3334 6.66667 17.9603 6.66667 17.5V16.6667H2.91667V18.3334C2.91667 18.7936 2.54357 19.1667 2.08333 19.1667C1.6231 19.1667 1.25 18.7936 1.25 18.3334V1.66671C1.25 1.20647 1.6231 0.833374 2.08333 0.833374ZM12.5 3.33337H8.33333V5.00004H12.5V3.33337ZM15 9.16671H8.33333V10.8334H15V9.16671ZM17.5 15H8.33333V16.6667H17.5V15Z"
          fill="#121224"
        />
      </svg>
      is_indexed
    </div>
  );
};

const PartitionIndicator = () => {
  return (
    <div className="table-schema-table-extra-meta-cell">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M2.49967 4.16671C2.49967 3.70647 2.87277 3.33337 3.33301 3.33337H4.99967C5.45991 3.33337 5.83301 3.70647 5.83301 4.16671C5.83301 4.62694 5.45991 5.00004 4.99967 5.00004H3.33301C2.87277 5.00004 2.49967 4.62694 2.49967 4.16671Z"
          fill="#121224"
        />
        <path
          d="M0.833008 10C0.833008 9.5398 1.2061 9.16671 1.66634 9.16671H18.333C18.7932 9.16671 19.1663 9.5398 19.1663 10C19.1663 10.4603 18.7932 10.8334 18.333 10.8334H1.66634C1.2061 10.8334 0.833008 10.4603 0.833008 10Z"
          fill="#121224"
        />
        <path
          d="M8.33301 3.33337C7.87277 3.33337 7.49967 3.70647 7.49967 4.16671C7.49967 4.62694 7.87277 5.00004 8.33301 5.00004H11.6663C12.1266 5.00004 12.4997 4.62694 12.4997 4.16671C12.4997 3.70647 12.1266 3.33337 11.6663 3.33337H8.33301Z"
          fill="#121224"
        />
        <path
          d="M14.1663 4.16671C14.1663 3.70647 14.5394 3.33337 14.9997 3.33337H16.6663C17.1266 3.33337 17.4997 3.70647 17.4997 4.16671C17.4997 4.62694 17.1266 5.00004 16.6663 5.00004H14.9997C14.5394 5.00004 14.1663 4.62694 14.1663 4.16671Z"
          fill="#121224"
        />
        <path
          d="M3.33301 15C2.87277 15 2.49967 15.3731 2.49967 15.8334C2.49967 16.2936 2.87277 16.6667 3.33301 16.6667H4.99967C5.45991 16.6667 5.83301 16.2936 5.83301 15.8334C5.83301 15.3731 5.45991 15 4.99967 15H3.33301Z"
          fill="#121224"
        />
        <path
          d="M7.49967 15.8334C7.49967 15.3731 7.87277 15 8.33301 15H11.6663C12.1266 15 12.4997 15.3731 12.4997 15.8334C12.4997 16.2936 12.1266 16.6667 11.6663 16.6667H8.33301C7.87277 16.6667 7.49967 16.2936 7.49967 15.8334Z"
          fill="#121224"
        />
        <path
          d="M14.9997 15C14.5394 15 14.1663 15.3731 14.1663 15.8334C14.1663 16.2936 14.5394 16.6667 14.9997 16.6667H16.6663C17.1266 16.6667 17.4997 16.2936 17.4997 15.8334C17.4997 15.3731 17.1266 15 16.6663 15H14.9997Z"
          fill="#121224"
        />
      </svg>
      is_partition
    </div>
  );
};

function TableSchemaView({ data, tableColumnList }) {
  const tableSchemaColumns = [
    {
      title: "Column name",
      dataIndex: "columnName",
    },
    {
      title: "Data type",
      dataIndex: "columnType",
      ellipsis: true,
    },
    {
      title: (
        <Tooltip title="Indicates if column is indexed or is a partition key">
          Metadata
        </Tooltip>
      ),
      width: 200,
      render: (row) => {
        const { extraMeta, columnUuid } = row;
        const { isIndexed = null, isPartitionKey = null } = extraMeta;

        const columnInfo = tableColumnList?.find(
          (column) => column.uuid === columnUuid
        );
        const isMasked = columnInfo?.profilerConfig?.masked;

        return (
          <div className="table-schema-table-extra-meta">
            {isMasked && <ColumnMaskedIndicator text="Column Masked" />}
            {isPartitionKey && <PartitionIndicator />}
            {isIndexed && <IndexedIndicator />}
          </div>
        );
      },
    },
  ];

  return (
    <div className="table-schema-view-container">
      <div className="table-schema-view-title">Table Schema</div>

      <NgTable
        columns={tableSchemaColumns}
        rowKey={(record) => record.uuid}
        dataSource={data?.data ?? []}
        loading={data?.loading}
        pagination={false}
        scroll={{ y: 250, x: true }}
      />
    </div>
  );
}

export default TableSchemaView;
