import React from "react";
import Tooltip from "../../components/tooltip/ng-tooltip";
import Plot from "../../utils/plotly";
import { NextGenPalette } from "../../utils/color";
import { getDisplayFloatStr, isRenderableString } from "../../utils/general";
import { TableColumnTypeCategory } from "../../utils/enums";
import ProfilerDataProfileInProgressView from "./profiler-data-profile-in-progress-view";
import { getIconFromColumn } from "./column-icons";
import { dataProfileChartBaseLayout } from "./profiler-data-profile-charts";
import { TimestampAnalysisChart } from "./table/profiler-data-source-table-timestamp-analysis";
import { useInterval } from "../../utils/hooks";
import { DATA_PROFILE_POLLING_INVERVAL } from "./profiler";
import { fnSorter } from "../../utils/sort";
import { ProfilerSummaryTabKey, getLabelFromName } from "./utils";
import { MaskedEyeIcon } from "./icons";
import { ArrowRight } from "../../components/icons/general";
import GridEmptyState from "../../components/empty-state/grid-empty-state";
import NgButton, { TextWithIcon } from "../../components/button/ng-button";
import ColumnMaskedIndicator from "../../components/column/column-masked-indicator";

import "./profiler-data-profile-column-view.scss";

const MAX_DISTRIBUTION_CHART_BARS = 20;

const typeDisplayNameMapper = Object.freeze({
  [TableColumnTypeCategory.STRING]: "String",
});

const MaskedColumnEnabledState = ({ actions }) => {
  return (
    <GridEmptyState
      title="The data profile is not available because the column is masked"
      icon={<MaskedEyeIcon />}
      actions={actions}
      background="dark"
    />
  );
};

function DataProfileColumnView(props) {
  const {
    name,
    type,
    total = 0,
    level,
    border = true,
    detailContent = null,
    schema,
    tableInfo,
    columnInfo,
    dataSource,
    isAdminUser,
    onCurrentSelectNodeChange,
  } = props;

  const TypeIcon = getIconFromColumn({ columnTypeCategory: type });
  const typeDisplayName = typeDisplayNameMapper[type] || type;
  const isMasked = columnInfo?.profilerConfig?.masked;

  const shouldShowMaskedColumnEnabledState = level === "column" && isMasked;

  return shouldShowMaskedColumnEnabledState ? (
    <MaskedColumnEnabledState
      actions={
        isAdminUser && (
          <NgButton
            type="outined"
            onClick={() => {
              onCurrentSelectNodeChange(
                {
                  tableInfo: tableInfo,
                  schemaInfo: schema,
                  dataSource: dataSource,
                },
                ProfilerSummaryTabKey.MANAGE_COLUMNS
              );
            }}
          >
            <TextWithIcon icon={<ArrowRight />}>Go To Manage Columns </TextWithIcon>
          </NgButton>
        )
      }
    />
  ) : (
    <div className={`data-profiler-column-view-container ${border ? "border" : ""}`}>
      <div className="data-profiler-column-view-header">
        <div className="data-profiler-column-view-header-container">
          <div className="data-profiler-column-view-icon-container">
            <TypeIcon width={24} height={24} fillColor={NextGenPalette.systemBlack} />
          </div>
          <div className="data-profiler-column-view-name-info-container">
            <div className="data-profiler-column-view-name-container">{name}</div>
            <div className="data-profiler-column-view-summary-container">{`${typeDisplayName} - ${total} Total cells`}</div>
          </div>
        </div>

        {isMasked && <ColumnMaskedIndicator text="Column Masked" />}
      </div>

      {!isMasked && detailContent && (
        <div className="data-profiler-column-view-content-container">
          {detailContent}
        </div>
      )}
    </div>
  );
}

function DataProfileColumnSummaryListView({ data }) {
  if (data.length === 0) {
    return null;
  }

  return (
    <div className="data-profile-column-summary-list-view-container">
      {data.map((currentData, index) => (
        <DataProfileColumnDetailContentColumnContainer data={currentData} key={index} />
      ))}
    </div>
  );
}

function DataProfileColumnDetailContentColumnContainer({ data }) {
  return (
    <div className="data-profile-column-detail-content-column-container">
      {data.map(({ label, value, labelTooltip, isSub = false }, index) => {
        const labelContent = labelTooltip ? (
          <Tooltip title={labelTooltip}>{label}</Tooltip>
        ) : (
          label
        );

        return (
          <div
            className={`data-profile-column-detail-content-column-row-container ${
              isSub ? "isSub" : ""
            }`}
            key={index}
          >
            <div className="data-profile-column-detail-content-column-row-label-container">
              {labelContent}
            </div>
            <div className="data-profile-column-detail-content-column-row-value-container">
              {value}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function formatSamplePercentage(samplesNumerator, samplesDenominator) {
  const percentage =
    samplesDenominator > 0 ? (100 * samplesNumerator) / samplesDenominator : 0;
  return getDisplayFloatStr(percentage, true);
}

function getBasicIntegrationSummary(dataProfile) {
  const { numSamples = 0, nullCount = 0, distinctCount = 0 } = dataProfile;

  return [
    {
      label: "Valid",
      value: formatSamplePercentage(numSamples - nullCount, numSamples),
    },
    {
      label: "Unique",
      value: formatSamplePercentage(distinctCount, numSamples),
      isSub: true,
    },
    {
      label: "Nonunique",
      value: formatSamplePercentage(numSamples - nullCount - distinctCount, numSamples),
      isSub: true,
    },
    {
      label: "Invalid",
      value: formatSamplePercentage(nullCount, numSamples),
    },
    {
      label: "Null",
      value: formatSamplePercentage(nullCount, numSamples),
      isSub: true,
    },
  ];
}

function StringColumnDistributionChart({
  data = [],
  xLabel = "Row Count",
  yLabel = null,
}) {
  if (data.length === 0) {
    return null;
  }

  const sortedData = [...data];
  sortedData.sort(fnSorter(({ count }) => count));
  const sortedDataSlice = sortedData.slice(0, MAX_DISTRIBUTION_CHART_BARS);

  const x = [];
  const y = [];
  const width = [];
  sortedDataSlice.forEach(({ value, count }) => {
    y.push(value);
    x.push(count);
    width.push(0.3);
  });

  const trace = {
    x,
    y,
    width,
    name: "",
    orientation: "h",
    type: "bar",
    marker: { color: NextGenPalette.purple },
  };

  const layout = dataProfileChartBaseLayout();
  layout.yaxis.autotypenumbers = "strict";

  if (isRenderableString(xLabel)) {
    layout.xaxis.title = xLabel;
    layout.margin.b = 38;
  }

  if (isRenderableString(yLabel)) {
    layout.yaxis.title = {
      text: yLabel,
      standoff: 15,
    };
  }

  const config = {
    displayModeBar: false,
    responsive: true,
  };

  return (
    <div className="data-profile-column-chart">
      <Plot
        data={[trace]}
        layout={layout}
        config={config}
        useResizeHandler
        style={{ width: "100%", height: 200 }}
      />
    </div>
  );
}

function NumericalColumnDistributionChart({
  data = [],
  xLabel = null,
  yLabel = "Row Count",
}) {
  if (data.length === 0) {
    return null;
  }

  const x = [];
  const y = [];
  data.slice(0, MAX_DISTRIBUTION_CHART_BARS).forEach(({ binLeftEdge, count }) => {
    x.push(binLeftEdge);
    y.push(count);
  });

  const trace = {
    x,
    y,
    name: "",
    type: "bar",
    marker: { color: NextGenPalette.purple },
  };

  const layout = dataProfileChartBaseLayout();

  if (isRenderableString(xLabel)) {
    layout.xaxis.title = xLabel;
    layout.margin.b = 38;
  }

  if (isRenderableString(yLabel)) {
    layout.yaxis.title = {
      text: yLabel,
      standoff: 15,
    };
  }

  const config = {
    displayModeBar: false,
    responsive: true,
  };

  return (
    <div className="data-profile-column-chart">
      <Plot
        data={[trace]}
        layout={layout}
        config={config}
        useResizeHandler
        style={{ width: "100%", height: 200 }}
      />
    </div>
  );
}

function DataProfileStringColumnView({ data, ...otherProps }) {
  const {
    name,
    dataProfile: {
      type,
      numSamples = 0,
      distinctCount = 0,
      minLength = 0,
      maxLength = 0,
      meanLength = 0,
      zeroLengthCount = 0,
      socialSecurityCount = 0,
      phoneNumberCount = 0,
      zipcodeCount = 0,
      urlCount = 0,
      uuidCount = 0,
      emailCount = 0,
      distribution = [],
    },
  } = data;

  let detectedPatterns = [];

  if (emailCount > 0) {
    detectedPatterns.push("Email");
  }
  if (phoneNumberCount > 0) {
    detectedPatterns.push("Phone number");
  }
  if (socialSecurityCount > 0) {
    detectedPatterns.push("SSN");
  }
  if (urlCount > 0) {
    detectedPatterns.push("URL");
  }
  if (uuidCount > 0) {
    detectedPatterns.push("UUID");
  }
  if (zipcodeCount > 0) {
    detectedPatterns.push("Zip code");
  }

  const detectedPattern =
    detectedPatterns.length > 0 ? detectedPatterns.join(", ") : "None";

  const integrationSummary = getBasicIntegrationSummary(data.dataProfile);

  const stringIntegrationSummary = [
    {
      label: "Max String Length",
      value: maxLength,
    },
    {
      label: "Min String Length",
      value: minLength,
    },
    {
      label: "Mean String Length",
      value: meanLength,
    },
    {
      label: "Zero Length Count",
      value: zeroLengthCount,
    },
    {
      label: "Patterns Detected",
      labelTooltip:
        "Detects UUIDs, phone numbers, email addresses, US ZIP codes, SSNs, and URLs",
      value: detectedPattern,
    },
    {
      label: "# of Distinct Values",
      value: distinctCount,
    },
  ];

  const detailContent = (
    <div className="data-profile-column-detail-content-container">
      <DataProfileColumnSummaryListView
        data={[integrationSummary, stringIntegrationSummary]}
      />
      {distribution?.length > 0 && (
        <StringColumnDistributionChart
          data={distribution}
          yLabel={getLabelFromName(name)}
        />
      )}
    </div>
  );

  return (
    <DataProfileColumnView
      type={type}
      name={name}
      total={numSamples}
      detailContent={detailContent}
      {...otherProps}
    />
  );
}

function DataProfileNumericalColumnView({ data, ...otherProps }) {
  const {
    name,
    dataProfile: {
      type,
      categorical = false,
      numSamples = 0,
      mean = 0,
      max = 0,
      min = 0,
      percentile25 = 0,
      percentile50 = 0,
      percentile75 = 0,
      zeroCount = 0,
      positiveNumCount = 0,
      negativeNumCount = 0,
      nanCount = 0,
      binCounts = [],
      distribution = [],
    },
  } = data;

  const integrationSummary = getBasicIntegrationSummary(data.dataProfile);

  const numericalStatsSummary = [
    {
      label: "Min",
      value: getDisplayFloatStr(min),
    },
    {
      label: "Lower Quartile (Q1)",
      value: getDisplayFloatStr(percentile25),
    },
    {
      label: "Median",
      value: getDisplayFloatStr(percentile50),
    },
    {
      label: "Mean",
      value: getDisplayFloatStr(mean),
    },
    {
      label: "Upper Quartile (Q3)",
      value: getDisplayFloatStr(percentile75),
    },
    {
      label: "Max",
      value: getDisplayFloatStr(max),
    },
  ];

  const numericalAdvancedSummary = [
    {
      label: "Zero %",
      value: formatSamplePercentage(zeroCount, numSamples),
    },
    {
      label: "Positive %",
      value: formatSamplePercentage(positiveNumCount, numSamples),
    },
    {
      label: "Negative %",
      value: formatSamplePercentage(negativeNumCount, numSamples),
    },
    {
      label: "Nan %",
      value: formatSamplePercentage(nanCount, numSamples),
    },
  ];

  const detailContent = (
    <div className="data-profile-column-detail-content-container">
      <DataProfileColumnSummaryListView
        data={[integrationSummary, numericalStatsSummary, numericalAdvancedSummary]}
      />
      {binCounts?.length > 0 && (
        <NumericalColumnDistributionChart
          data={binCounts}
          xLabel={getLabelFromName(name)}
        />
      )}
      {categorical && distribution?.length > 0 && (
        <StringColumnDistributionChart
          data={distribution}
          yLabel={getLabelFromName(name)}
        />
      )}
    </div>
  );

  return (
    <DataProfileColumnView
      type={type}
      name={name}
      total={numSamples}
      detailContent={detailContent}
      {...otherProps}
    />
  );
}

function DataProfileTimestampColumnView({ data, ...otherProps }) {
  const {
    name,
    dataProfile: {
      type,
      numSamples = 0,
      meanInterval = 0,
      minInterval = 0,
      maxInterval = 0,
      futureTimeCount = 0,
      minTimestamp = null,
      maxTimestamp = null,
      tableVolumePreview = [],
      dataDelay,
    },
  } = data;

  const integrationSummary = getBasicIntegrationSummary(data.dataProfile);

  const timestampStatsSummary = [
    {
      label: "Mean Interval",
      labelTooltip: "Mean time elapsed between two consecutive timestamps",
      value: `${meanInterval}s`,
    },
    {
      label: "Min Interval",
      labelTooltip: "Min time elapsed between two consecutive timestamps",
      value: `${minInterval}s`,
    },
    {
      label: "Max Interval",
      labelTooltip: "Max time elapsed between two consecutive timestamps",
      value: `${maxInterval}s`,
    },
    {
      label: "Future Date %",
      labelTooltip: "Percentage of timestamps that are in the future",
      value: formatSamplePercentage(futureTimeCount, numSamples),
    },
    {
      label: "Min Timestamp",
      labelTooltip: "Minimum timestamp",
      value: minTimestamp,
    },
    {
      label: "Max Timestamp",
      labelTooltip: "Maximum timestamp",
      value: maxTimestamp,
    },
  ];

  const detailContent = (
    <div className="data-profile-column-detail-content-container">
      <DataProfileColumnSummaryListView
        data={[integrationSummary, timestampStatsSummary]}
      />
      {tableVolumePreview?.length > 0 && (
        <div className="data-profile-column-chart">
          <TimestampAnalysisChart
            data={tableVolumePreview}
            delay={dataDelay}
            xLabel={getLabelFromName(name)}
            yLabel="Row Count"
          />
        </div>
      )}
    </div>
  );

  return (
    <DataProfileColumnView
      type={type}
      name={name}
      total={numSamples}
      detailContent={detailContent}
      {...otherProps}
    />
  );
}

function ProfilerDataProfileColumnViewContent({
  data: dataProps,
  onRefresh,
  ...otherProps
}) {
  const { loading, data } = dataProps;

  // It's important that we check for undefined here. When the profile is in progress
  // the backend should give us an empty object back, so data.dataProfile will be undefined.
  // But if generating the data profile failed, data.dataProfile should be a valid key with
  // value null.
  const isInProgress = !loading && typeof data?.dataProfile === "undefined";
  const noData = !isInProgress && !data?.dataProfile;

  if (isInProgress) {
    return <ProfilerDataProfileInProgressView />;
  }

  if (loading || noData) {
    return null;
  }

  const type = data.dataProfile.type;
  let ColumnViewComponent = null;
  if (type === TableColumnTypeCategory.STRING) {
    ColumnViewComponent = DataProfileStringColumnView;
  } else if (type === TableColumnTypeCategory.NUMERIC) {
    ColumnViewComponent = DataProfileNumericalColumnView;
  } else if (type === TableColumnTypeCategory.TIMESTAMP) {
    ColumnViewComponent = DataProfileTimestampColumnView;
  } else {
    console.log(`Unknown type ${type}`);
    return null;
  }

  return <ColumnViewComponent data={data} {...otherProps} />;
}

function PollingProfilerDataProfileColumnView(props) {
  const { data: dataProps, onRefresh, ...otherProps } = props;

  const { loading, data } = dataProps;

  useInterval(() => {
    if (!loading && data && !data.dataProfile && onRefresh) {
      onRefresh();
    }
  }, DATA_PROFILE_POLLING_INVERVAL);

  return <ProfilerDataProfileColumnView data={dataProps} {...otherProps} />;
}

export default function ProfilerDataProfileColumnView(props) {
  const { onRefresh } = props;

  return onRefresh ? (
    <PollingProfilerDataProfileColumnView {...props} />
  ) : (
    <ProfilerDataProfileColumnViewContent {...props} />
  );
}
