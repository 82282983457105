import * as types from "./integration-action-types";
import {
  getIntegrationListPromise,
  getIntegrationPromise,
  addIntegrationPromise,
  updateIntegrationPromise,
  deleteIntegrationPromise,
  previewIntegrationPromise,
  getIntegrationMetadataPromise,
} from "../../rest/integration";
import { NotificationTypeConst } from "../../utils/enums";
import { toast } from "../../components/toast/toast";
import { removeUnusedAllowedValues } from "../../views/integrations/integration-metadata";

function setIntegrationList(integrationList) {
  return { type: types.SET_INTEGRATION_LIST, integrationList };
}

function setCurrentEditIntegration(currentEditIntegration) {
  return { type: types.SET_CURRENT_EDIT_INTEGRATION, currentEditIntegration };
}

function setIntegrationPreviewResult(previewResult) {
  return { type: types.SET_INTEGRATION_PREVIEW_STATUS, previewResult };
}

function setIntegrationCurrentMetadata(metadata) {
  return { type: types.SET_INTEGRATION_CURRENT_METADATA, metadata };
}

export function getIntegrationList(workspaceUuid) {
  return (dispatch, getState) => {
    dispatch(setIntegrationList({ data: [], loading: true }));
    getIntegrationListPromise(workspaceUuid)
      .then(function (newIntegrationList) {
        dispatch(setIntegrationList({ data: newIntegrationList, loading: false }));
      })
      .catch(function (error) {
        dispatch(setIntegrationList({ data: [], loading: false }));
        console.log(`Fail to load integration list for ${error}`);
      });
  };
}

export function deleteIntegration(workspaceUuid, integration) {
  return (dispatch, getState) => {
    deleteIntegrationPromise(workspaceUuid, integration.id)
      .then(function (response) {
        dispatch(getIntegrationList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to delete integration for ${error}`);
      });
  };
}

export function addIntegration(workspaceUuid, newIntegration) {
  return (dispatch, getState) => {
    if (newIntegration.metadata) {
      newIntegration.metadata = removeUnusedAllowedValues(newIntegration.metadata);
    }
    addIntegrationPromise(workspaceUuid, newIntegration)
      .then(function (response) {
        dispatch(getIntegrationList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to create integration for ${error}`);
      });
  };
}

export function getIntegration(workspaceUuid, uuid) {
  return (dispatch, getState) => {
    getIntegrationPromise(workspaceUuid, uuid)
      .then(function (currentIntegration) {
        dispatch(setCurrentEditIntegration(currentIntegration));
      })
      .catch(function (error) {
        console.log(`Fail to get integration for ${error}`);
      });
  };
}

export function editIntegration(workspaceUuid, uuid, newIntegration) {
  return (dispatch, getState) => {
    delete newIntegration.id;
    if (newIntegration.metadata) {
      newIntegration.metadata = removeUnusedAllowedValues(newIntegration.metadata);
    }
    updateIntegrationPromise(workspaceUuid, uuid, newIntegration)
      .then(function (response) {
        dispatch(getIntegrationList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to edit integration for ${error}`);
      });
  };
}

export function previewIntegration(workspaceUuid, newIntegration) {
  return (dispatch) => {
    dispatch(
      setIntegrationPreviewResult({
        progress: true,
        data: undefined,
      })
    );

    if (newIntegration.metadata) {
      newIntegration.metadata = removeUnusedAllowedValues(newIntegration.metadata);
    }
    return previewIntegrationPromise(workspaceUuid, newIntegration)
      .then(function (response) {
        console.log("Preview integration is successful.");
        dispatch(
          setIntegrationPreviewResult({
            progress: false,
            data: true,
          })
        );
        toast("Successfully posted test message");
      })
      .catch(function (error) {
        console.log(`Fail to preview integration for ${error}`);
        dispatch(
          setIntegrationPreviewResult({
            progress: false,
            data: false,
          })
        );
        toast(
          error?.response?.data?.error || "Failed to post test message",
          NotificationTypeConst.FAILURE
        );
      });
  };
}

export function resetIntegrationPreviewStatus() {
  return (dispatch) => {
    dispatch(
      setIntegrationPreviewResult({
        progress: false,
        data: undefined,
      })
    );
  };
}

export function getIntegrationMetadata(workspaceUuid, integration) {
  return (dispatch) => {
    dispatch(setIntegrationCurrentMetadata({ loading: true, data: {} }));
    return getIntegrationMetadataPromise(workspaceUuid, integration)
      .then(function (metadata) {
        dispatch(
          setIntegrationCurrentMetadata({
            loading: false,
            data: metadata,
            lastRetrieved: Date.now(),
          })
        );
        return metadata;
      })
      .catch((error) => {
        console.log(`Fail to get integration metadata: ${error}`);
        dispatch(setIntegrationCurrentMetadata({ loading: false, data: {} }));
      });
  };
}

export function resetIntegrationMetadata() {
  return (dispatch) => {
    dispatch(setIntegrationCurrentMetadata({ loading: false, data: {} }));
  };
}
