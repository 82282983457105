import React from "react";
import StatusBadge from "../../components/status-badge";
import { NextGenPalette } from "../../utils/color";

export function MutedIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M3.23088 10.3285C3.18889 10.2681 3.1511 10.2121 3.11766 10.1614H1.74967C1.10535 10.1614 0.583008 9.6391 0.583008 8.99476V4.9615C0.583008 4.31716 1.10535 3.79484 1.74967 3.79484H3.12052C3.1539 3.74507 3.19161 3.69009 3.23348 3.63086C3.41966 3.36742 3.69183 3.0143 4.03505 2.65888C4.69957 1.97073 5.73403 1.16669 6.99967 1.16669C7.32184 1.16669 7.58301 1.42785 7.58301 1.75002V12.25C7.58301 12.5722 7.32184 12.8334 6.99967 12.8334C5.73021 12.8334 4.69485 12.0139 4.03172 11.3158C3.68876 10.9547 3.41684 10.5961 3.23088 10.3285Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M8.99338 5.50424C9.22118 5.27643 9.59053 5.27644 9.81834 5.50424L10.6433 6.32921L11.4683 5.50424C11.6961 5.27644 12.0654 5.27644 12.2932 5.50424C12.521 5.73205 12.521 6.10139 12.2932 6.3292L11.4682 7.15417L12.2932 7.97912C12.521 8.20693 12.521 8.57628 12.2932 8.80408C12.0654 9.03189 11.696 9.03188 11.4682 8.80408L10.6433 7.97913L9.81833 8.80408C9.59053 9.03188 9.22118 9.03188 8.99337 8.80408C8.76557 8.57627 8.76557 8.20693 8.99337 7.97912L9.81833 7.15416L8.99337 6.3292C8.76557 6.10139 8.76557 5.73204 8.99338 5.50424Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export function ActiveIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46241 2.46271C3.62297 1.30216 5.22793 0.583313 6.99967 0.583313C8.77141 0.583313 10.3764 1.30216 11.5369 2.46271C12.6975 3.62327 13.4163 5.22824 13.4163 6.99998C13.4163 8.77172 12.6975 10.3767 11.5369 11.5372C10.3764 12.6978 8.77142 13.4166 6.99967 13.4166C5.22793 13.4166 3.62297 12.6978 2.46241 11.5372C1.30186 10.3767 0.583008 8.77172 0.583008 6.99998C0.583008 5.22824 1.30187 3.62327 2.46241 2.46271ZM10.3288 5.66246C10.5566 5.43465 10.5566 5.06531 10.3288 4.8375C10.101 4.60969 9.73167 4.60969 9.50386 4.8375L6.41634 7.92502L5.07882 6.5875C4.85101 6.35969 4.48167 6.35969 4.25386 6.5875C4.02606 6.81531 4.02606 7.18465 4.25386 7.41246L6.00386 9.16246C6.23167 9.39027 6.60101 9.39027 6.82882 9.16246L10.3288 5.66246Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}

function AlertingStatusBadge(props) {
  const { monitor, clickable } = props;

  const alertConfig = monitor.config.alertConfig;
  const numChannels = alertConfig?.channels?.length ?? 0;
  const isMuted = alertConfig.isMuted;

  return isMuted ? (
    <StatusBadge title="Muted" Icon={MutedIcon} clickable={clickable} />
  ) : (
    <StatusBadge
      title={`${numChannels} Active`}
      Icon={ActiveIcon}
      clickable={clickable}
    />
  );
}

export default AlertingStatusBadge;
