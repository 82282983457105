import { DqDashboardDimensionType } from "../../utils/enums";

export const dqDimensionTypeTitles = {
  [DqDashboardDimensionType.CUSTOM]: "Custom",
  [DqDashboardDimensionType.ACCURACY]: "Accuracy",
  [DqDashboardDimensionType.COMPLETENESS]: "Completeness",
  [DqDashboardDimensionType.TIMELINESS]: "Timeliness",
  [DqDashboardDimensionType.OVERALL]: "Overall Score",
};

export const dqDimensionTypeDescriptions = {
  [DqDashboardDimensionType.CUSTOM]:
    "Custom metrics measure dimensions that you determine aren't accuracy, completeness, or timeliness. Score is percent of associated monitors that passed.",
  [DqDashboardDimensionType.ACCURACY]:
    "Accuracy metrics measure how well data represents what it's meant to model. Score is percent of associated monitors that passed.",
  [DqDashboardDimensionType.COMPLETENESS]:
    "Completeness metrics measure the presence of non-null values where data is expected. Score is percent of associated monitors that passed.",
  [DqDashboardDimensionType.TIMELINESS]:
    "Timeliness metrics measure the availability of data in terms of lag (the time elapsed since the last data timestamp). Score is percent of associated monitors that passed.",
  [DqDashboardDimensionType.OVERALL]:
    "Overall score is the percent of all monitors that passed.",
};
