import * as deepcopyModule from "deepcopy";

export function getPath(obj, path) {
  let cursor = obj;
  for (let segment of path) {
    if (cursor && cursor.hasOwnProperty(segment)) {
      cursor = cursor[segment];
    } else {
      return null;
    }
  }
  return cursor;
}

export function isNullOrUndefined(v) {
  return v === null || typeof v === "undefined";
}

// deepcopy has an unusual module structure that causes problems with our app.
// Depending on how you import it, it may not work in either Jest or create-react-app.
// Through looking at the module structure I was able to determine that doing an `import *` and
// then calling the "default" export seems to work in both places. This is just a wrapper
// to obviate the need to reiterate this weird invocation everywhere we use deepcopy.
export function deepcopy(obj) {
  return deepcopyModule.default(obj);
}
