import { connect } from "react-redux";
import { addKpi, getKpi } from "../../actions/kpi/kpi-action";

import AddKPIWizard from "./add-kpi";

const mapStateToProps = (state) => ({
  currentCloneKpi: state.kpiReducer.currentCloneKpi,
});

const mapDispatchToProps = (dispatch) => ({
  getKpi: (workspaceUuid, uuid) => dispatch(getKpi(workspaceUuid, uuid, true)),
  addKpi: (workspaceUuid, newKpi) => dispatch(addKpi(workspaceUuid, newKpi)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddKPIWizard);
