import React from "react";
import { NextGenPalette } from "../../../utils/color";

export function VerticalDots(props) {
  const { width = 14, height = 14, fillColor = NextGenPalette.systemBlack } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
      <path
        d="M7 4.375C7.48325 4.375 7.875 3.98325 7.875 3.5C7.875 3.01675 7.48325 2.625 7 2.625C6.51675 2.625 6.125 3.01675 6.125 3.5C6.125 3.98325 6.51675 4.375 7 4.375Z"
        fill={fillColor}
      />
      <path
        d="M7 7.875C7.48325 7.875 7.875 7.48325 7.875 7C7.875 6.51675 7.48325 6.125 7 6.125C6.51675 6.125 6.125 6.51675 6.125 7C6.125 7.48325 6.51675 7.875 7 7.875Z"
        fill={fillColor}
      />
      <path
        d="M7 11.0833C7.48325 11.0833 7.875 10.6916 7.875 10.2083C7.875 9.72508 7.48325 9.33333 7 9.33333C6.51675 9.33333 6.125 9.72508 6.125 10.2083C6.125 10.6916 6.51675 11.0833 7 11.0833Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function AddOne(props) {
  const { width = 14, height = 14, fillColor = NextGenPalette.systemBlack } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583374 6.99967C0.583374 3.45585 3.45621 0.583008 7.00004 0.583008C10.5439 0.583008 13.4167 3.45585 13.4167 6.99967C13.4167 10.5435 10.5439 13.4163 7.00004 13.4163C3.45621 13.4163 0.583374 10.5435 0.583374 6.99967ZM7.00004 1.74967C4.10054 1.74967 1.75004 4.10018 1.75004 6.99967C1.75004 9.89917 4.10054 12.2497 7.00004 12.2497C9.89954 12.2497 12.25 9.89917 12.25 6.99967C12.25 4.10018 9.89954 1.74967 7.00004 1.74967ZM7.00004 4.08301C7.32221 4.08301 7.58337 4.34417 7.58337 4.66634V6.41634H9.33337C9.65554 6.41634 9.91671 6.67751 9.91671 6.99967C9.91671 7.32184 9.65554 7.58301 9.33337 7.58301H7.58337V9.33301C7.58337 9.65517 7.32221 9.91634 7.00004 9.91634C6.67787 9.91634 6.41671 9.65517 6.41671 9.33301V7.58301H4.66671C4.34454 7.58301 4.08337 7.32184 4.08337 6.99967C4.08337 6.67751 4.34454 6.41634 4.66671 6.41634H6.41671V4.66634C6.41671 4.34417 6.67787 4.08301 7.00004 4.08301Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function CollapsePanelIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M2.33337 2.625C2.0112 2.625 1.75004 2.88617 1.75004 3.20833C1.75004 3.5305 2.0112 3.79167 2.33337 3.79167H11.6667C11.9889 3.79167 12.25 3.5305 12.25 3.20833C12.25 2.88617 11.9889 2.625 11.6667 2.625H2.33337Z"
        fill="#121224"
      />
      <path
        d="M1.75293 7.05847C1.73606 6.89032 1.79204 6.71625 1.92085 6.58743L3.57076 4.9375C3.79856 4.70969 4.16791 4.70969 4.39572 4.93749C4.62352 5.1653 4.62353 5.53464 4.39572 5.76245L3.74152 6.41667H11.6667C11.9889 6.41667 12.25 6.67783 12.25 7C12.25 7.32217 11.9889 7.58333 11.6667 7.58333H3.74171L4.39572 8.23736C4.62353 8.46517 4.62352 8.83451 4.39572 9.06232C4.16791 9.29012 3.79856 9.29012 3.57076 9.06231L1.92589 7.41742C1.90786 7.39981 1.89096 7.38104 1.87531 7.36124C1.84266 7.31995 1.81639 7.27528 1.7965 7.22854C1.77387 7.17543 1.75888 7.11828 1.75293 7.05847Z"
        fill="#121224"
      />
      <path
        d="M1.75004 10.7917C1.75004 10.4695 2.0112 10.2083 2.33337 10.2083H11.6667C11.9889 10.2083 12.25 10.4695 12.25 10.7917C12.25 11.1138 11.9889 11.375 11.6667 11.375H2.33337C2.0112 11.375 1.75004 11.1138 1.75004 10.7917Z"
        fill="#121224"
      />
    </svg>
  );
}
