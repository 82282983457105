import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { TakeoverWidth } from "../../utils/enums";

import "./index.scss";

function TakeoverViewContentWrap({ workspaceTakeoverElement, outsideClick }) {
  const location = useLocation();
  const [oldLocation] = useState(location);

  React.useEffect(() => {
    if (
      oldLocation.pathname !== location.pathname ||
      oldLocation.search !== location.search
    ) {
      outsideClick?.();
    }
  }, [location, oldLocation, outsideClick]);

  return workspaceTakeoverElement;
}

function TakeoverView(props) {
  const {
    workspaceTakeoverOpen,
    workspaceTakeoverKey,
    workspaceTakeoverElement,
    size,
    className = "",
    outsideClick = null,
  } = props;

  if (!workspaceTakeoverOpen) {
    return null;
  }

  let sizeClass;
  switch (size) {
    case TakeoverWidth.FULLSCREEN:
      sizeClass = "full";
      break;
    case TakeoverWidth.WIDE:
      sizeClass = "wide";
      break;
    case TakeoverWidth.NARROW:
      sizeClass = "narrow";
      break;
    case TakeoverWidth.NORMAL:
      sizeClass = "normal";
      break;
    case TakeoverWidth.MEDIUM:
      sizeClass = "medium";
      break;
    case TakeoverWidth.EXTENDED:
      sizeClass = "extended";
      break;
    default:
      sizeClass = "full";
      break;
  }

  return (
    <>
      <div
        className="lightup-app-takeover-mask-container"
        onClick={() => outsideClick?.()}
      />
      <div
        className={`lightup-app-takeover-container ${sizeClass} ${className}`}
        key={workspaceTakeoverKey}
      >
        <TakeoverViewContentWrap
          workspaceTakeoverElement={workspaceTakeoverElement}
          outsideClick={outsideClick}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  workspaceTakeoverOpen: state.takeoverReducer.workspaceTakeoverOpen,
  workspaceTakeoverKey: state.takeoverReducer.workspaceTakeoverKey,
  workspaceTakeoverElement: state.takeoverReducer.workspaceTakeoverElement,
  size: state.takeoverReducer.size,
  outsideClick: state.takeoverReducer.outsideClick,
  className: state.takeoverReducer.className,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TakeoverView);
