import { SearchIcon } from "../../components/icons/input";
import { LabeledInput } from "../../components/labeled-control/labeled-control";
import NgButton from "../../components/button/ng-button";
import { getStringFromTimeStamp } from "../../utils/time";
import {
  ProfilerScanStatus,
  ProfilerTableScanFailureReason,
} from "./profiler-scan-status";
import { columnFn, safeToLowerCase } from "../../components/entity-list/columns";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";
import { SettingOutlined } from "@ant-design/icons";
import NgTable, {
  NgTableClickableText,
  NgTableTheme,
} from "../../components/table/ng-table";
import Search from "../../components/search/";
import { useRef, useState } from "react";
import { useResizeObserver } from "../../utils/hooks";
import { ProfilerSummaryTabKey } from "./utils";

import "./manage-tabs.scss";

export function ManageTabHeader(props) {
  const {
    entityName,
    lastScannedTs,
    lastScannedStatus,
    showSearchControls = true,
    leftControls = null,
    searchText,
    setSearchText,
    advancedControls = null,
    rightControls = null,
    searchItem = {},
    searchOptions = [],
    onSearchItemChange = () => {},
  } = props;

  return (
    <div className="profiler-manage-header">
      <div className="profiler-manage-header-last-scanned">
        {leftControls}
        <div className="profiler-manage-last-scanned">
          {`Last Scanned ${
            typeof lastScannedTs === "number"
              ? getStringFromTimeStamp(lastScannedTs)
              : "N/A"
          }`}
          <ProfilerScanStatus
            lastScannedStatus={lastScannedStatus}
            lastScannedFailedReason={ProfilerTableScanFailureReason}
          />
        </div>
        {Boolean(rightControls) && (
          <div className="profiler-manage-header-buttons">{rightControls}</div>
        )}
      </div>
      {showSearchControls && (
        <div className="profiler-manage-header-search-container">
          <div className="profiler-manage-search">
            {setSearchText ? (
              <LabeledInput
                label=""
                placeholder={`Search ${entityName}s...`}
                suffix={<SearchIcon />}
                allowClear
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            ) : (
              <Search
                label="Search"
                size="middle"
                placeholder={`Search ${entityName}s...`}
                localFilterSetting={searchItem}
                selectionOptionList={searchOptions}
                onChange={(searchItem) => {
                  onSearchItemChange(searchItem);
                }}
              />
            )}
          </div>
          {Boolean(advancedControls) && advancedControls}
        </div>
      )}
    </div>
  );
}

function NotConfiguredButton(props) {
  const { onConfigureClick } = props;
  return (
    <NgButton
      outline
      onClick={() => onConfigureClick()}
      style={{ width: 32, height: 32 }}
    >
      <SettingOutlined />
    </NgButton>
  );
}

export const tableNameColumn = columnFn({
  title: "Tables",
  key: "tableName",
  getCompareVal: (indexedValue, _row) => safeToLowerCase(indexedValue),
  defaultSortOrder: "ascend",
  renderWithProps: (tableName, row, renderProps) => {
    const {
      availableNodeKeys,
      canConfigure,
      dataSource,
      schema,
      onConfigureClick,
      onCurrentSelectNodeChange,
      renderPrefix = null,
    } = renderProps;
    const isInTree = availableNodeKeys.has(row.uuid);
    return (
      <div className="profiler-manage-table-name-column">
        {renderPrefix?.(tableName, row, renderProps)}
        {canConfigure && !row.profilerConfig.enabled && (
          <NotConfiguredButton onConfigureClick={() => onConfigureClick(row)} />
        )}
        {isInTree ? (
          <NgTableClickableText
            onClick={() =>
              onCurrentSelectNodeChange(
                {
                  dataSource,
                  schemaInfo: schema,
                  tableInfo: row,
                },
                ProfilerSummaryTabKey.MANAGE_COLUMNS
              )
            }
          >
            {tableName}
          </NgTableClickableText>
        ) : (
          <NgTextTooltip>{tableName}</NgTextTooltip>
        )}
      </div>
    );
  },
});

export function ManageTabNgTable(props) {
  let {
    entityName,
    dataSource,
    rowSelection: propsRowSelection = {},
    pagination,
    onPaginationChange = null,
    theme = NgTableTheme.LIGHT,
    ...restProps
  } = props;

  const [defaultPagination, setDefaultPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const tableContainerRef = useRef();
  const [tableContainerHeight, setTableContainerHeight] = useState(0);
  const numSelected = (propsRowSelection?.selectedRowKeys ?? []).length;

  pagination = pagination || defaultPagination;
  onPaginationChange = onPaginationChange || setDefaultPagination;
  const isPaginationVisible = dataSource.length > pagination.pageSize;
  const isNumSelectedVisible = numSelected > 1;

  // The empirically observed height of things above the table.
  const headerHeight =
    tableContainerRef?.current?.getElementsByClassName("ant-table-header")?.[0]
      .clientHeight || 32;
  const numSelectedHeight = isNumSelectedVisible ? 32 : 0;
  const paginationHeight = isPaginationVisible ? 40 : 0;

  useResizeObserver(tableContainerRef, (container) => {
    setTableContainerHeight(container.clientHeight);
  });

  return (
    <>
      {isNumSelectedVisible && (
        <div className="profiler-manage-table-selected-count">
          {numSelected} {entityName}s are selected
        </div>
      )}
      <div className="profiler-manage-table-container" ref={tableContainerRef}>
        <NgTable
          className="profiler-manage-table"
          theme={theme}
          dataSource={dataSource}
          pagination={{
            ...pagination,
            hideOnSinglePage: true,
            onChange: (page, pageSize) => {
              onPaginationChange({
                ...pagination,
                page,
                pageSize,
              });
            },
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          scroll={{
            y:
              tableContainerHeight -
              headerHeight -
              numSelectedHeight -
              paginationHeight,
          }}
          rowSelection={{
            ...propsRowSelection,
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
              propsRowSelection.onChange(selectedRowKeys, selectedRows);
            },
          }}
          {...restProps}
        />
      </div>
    </>
  );
}
