import React from "react";
import { LabeledInput } from "../../labeled-control/labeled-control";

export function HostnameInput(props) {
  const { onChange, host, port, value, label = "Host" } = props;
  const displayHostname = port ? `${host}:${port}` : host;

  return (
    <LabeledInput
      label={label}
      staticLabel
      value={displayHostname}
      onChange={(e) => {
        const newDisplayHostName = e.target.value;
        const splitIndex = newDisplayHostName.indexOf(":");
        let hostInfo;
        if (splitIndex === -1 || splitIndex === newDisplayHostName.length - 1) {
          hostInfo = { host: newDisplayHostName };
        } else {
          hostInfo = {
            host: newDisplayHostName.substring(0, splitIndex),
            port: parseInt(newDisplayHostName.substring(splitIndex + 1)),
          };
        }
        onChange && onChange({ ...value, ...hostInfo });
      }}
    />
  );
}

export const DatabaseName = (props) => {
  const { onChange, dbname, value } = props;
  return (
    <LabeledInput
      label="Database name"
      staticLabel
      value={dbname}
      onChange={(e) => {
        const newDbname = e.target.value;
        onChange && onChange({ ...value, dbname: newDbname });
      }}
    />
  );
};

export const ServiceName = (props) => {
  const { onChange, service, value } = props;
  return (
    <LabeledInput
      label="Service name"
      staticLabel
      value={service}
      onChange={(e) => {
        const newService = e.target.value;
        onChange && onChange({ ...value, service: newService });
      }}
    />
  );
};

export const Username = (props) => {
  const { onChange, user, value } = props;
  return (
    <LabeledInput
      label="Username"
      staticLabel
      value={user}
      autoComplete="off"
      onChange={(e) => {
        const newUser = e.target.value;
        onChange && onChange({ ...value, user: newUser });
      }}
    />
  );
};

export const Password = (props) => {
  const { onChange, password, value } = props;
  return (
    <LabeledInput
      label="Password"
      staticLabel
      type="password"
      autoComplete="off"
      value={password}
      onChange={(e) => {
        const newPassword = e.target.value;
        onChange && onChange({ ...value, password: newPassword });
      }}
    />
  );
};

function BasicConfigView(props) {
  const {
    value,
    onChange,
    enableServiceConfig = false,
    enableDbNameConfig = true,
  } = props;
  const { host, port, dbname, user, password, service } = value;

  return (
    <>
      <HostnameInput
        host={host}
        port={port}
        value={value}
        onChange={(newValue) => onChange({ ...newValue, password: "" })}
      />
      {enableServiceConfig && (
        <ServiceName service={service} value={value} onChange={onChange} />
      )}
      {enableDbNameConfig && (
        <DatabaseName dbname={dbname} value={value} onChange={onChange} />
      )}
      <Username user={user} value={value} onChange={onChange} />
      <Password password={password} value={value} onChange={onChange} />
    </>
  );
}

export default BasicConfigView;
