import React, { Component } from "react";
import { Redirect } from "react-router";
import ChartView from "../../../components/chart-view/ng-index";
import ProfilerMonitorAllDialog from "../profiler-monitor-all-dialog";
import ProfilerColumnDataProfileTab from "./profiler-column-data-profile-tab";
import ProfilerSummaryTabView, {
  columnNodeSupportsTabKey,
  defaultTabKey,
} from "../profiler-summary-tab-view";
import { getColumnTypeCategory } from "../../../utils/column";
import {
  getDefaultRuleConfigForAutoMetrics,
  getKPIInterval,
} from "../../../utils/defaults";
import { getDefaultUserSetting } from "../../../utils/user-setting";
import {
  AutoMetricsType,
  EventMetricTypeName,
  MonitorStatus,
  QueryScope,
  TableColumnTypeCategory,
  TreePathLevel,
} from "../../../utils/enums";
import {
  ProfilerSummaryTabKey,
  buildNewRuleAndProfileConfigUpdateForColumn,
  getChartSymptomConfigForFocusedMonitor,
  isMetricInVolatileState,
  isMonitorInVolatileState,
} from "../utils";
import fromUnixTime from "date-fns/fromUnixTime";
import { AppPermissions } from "../../../utils/permissions";
import { getURIInstance, hasPermission, URIPath } from "../../../utils/uri-path";
import TreePath from "../../../components/explorer/tree-path";
import DeleteMonitorConfirmationDialog from "../../monitors/delete-monitor-confirmation-dialog";
import {
  dataSourceSupportsDataProfiling,
  getFullTableName,
} from "../../../utils/datasource";
import { ProfilerStatsCardView } from "../profiler-stats-card-view";
import { tableSupportsDataProfiling } from "../../../utils/table";
import { getMonitorStatusFromRule } from "../../../utils/monitor";
import { EVENT, getMonitorDetailProps, trackEvent } from "../../../utils/telemetry";
import { ProfilerAfterTreeNavigationActionType } from "../../../actions/profiler/profiler-action";
import { ProfilerTreeNodeType } from "../tree-data";

import "./profiler-column-overview.scss";

const columnTypeCategoriesAvailableDataProfile = [
  TableColumnTypeCategory.STRING,
  TableColumnTypeCategory.NUMERIC,
  TableColumnTypeCategory.TIMESTAMP,
];

export function ProfilerDataSourceColumnOverviewHeader(props) {
  const {
    datasourceType,
    datasourceName,
    table,
    schema,
    columnType,
    columnName,
    currentSelectNode,
    onCurrentSelectNodeChange,
    isTreeExpand,
    onTreeExpandChange,
  } = props;

  return (
    <div>
      <TreePath
        datasource={{ type: datasourceType, name: datasourceName }}
        schema={schema}
        table={table}
        column={{ type: columnType, name: columnName }}
        selectedLevel={TreePathLevel.COLUMN}
        currentSelectNode={currentSelectNode}
        onCurrentSelectNodeChange={onCurrentSelectNodeChange}
        isTreeExpand={isTreeExpand}
        onTreeExpandChange={onTreeExpandChange}
      />
    </div>
  );
}

class ProfilerColumnOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: null,
      schemaInfo: null,
      tableInfo: null,
      columnInfo: null,
      nextUrl: null,
      isMonitorAllDialogOpen: false,
      deleteMonitorConfirmationDialogTarget: null,
      refreshIntervalId: null,
      isSummaryEnabled: false,
      isEventChartEnabled: false,
      isDataChartEnabled: false,
      isDataProfileEnabled: false,
      activeTabs: [],
      chartMonitorSetting: {},
    };
    this.onEnableMonitorAllClicked = this.onEnableMonitorAllClicked.bind(this);
    this.onToggleMetricLiveStatusClick = this.onToggleMetricLiveStatusClick.bind(this);
    this.onToggleMonitorLiveStatusClick =
      this.onToggleMonitorLiveStatusClick.bind(this);
    this.onSwitchMonitorClick = this.onSwitchMonitorClick.bind(this);
    this.onDeleteMonitorConfirmed = this.onDeleteMonitorConfirmed.bind(this);
    this.updateColumnConfig = this.updateColumnConfig.bind(this);
    this.onMonitorAll = this.onMonitorAll.bind(this);
    this.performAfterTreeNavigationAction =
      this.performAfterTreeNavigationAction.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { dataSource, tableInfo, schemaInfo, columnInfo, workspaceUserPermissions } =
      props;

    if (state.columnInfo === null || state.columnInfo !== columnInfo) {
      const isSummaryEnabled = hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_COLUMNPROFILESUMMARYVIEW,
      ]);

      const isDataChartEnabled = hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
        AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
        AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
      ]);

      const isDataProfileEnabled =
        hasPermission(workspaceUserPermissions, [
          AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_COLUMNDATAPROFILEDETAILVIEW,
        ]) &&
        dataSourceSupportsDataProfiling(dataSource) &&
        tableSupportsDataProfiling(tableInfo);

      const isEventChartEnabled = hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_SOURCE_PROFILER_RESULT_VIEWS_VIEW_PROFILERCOLUMNEVENTLISTVIEW,
      ]);

      const activeTabs = [
        ProfilerSummaryTabKey.AUTO_METRICS,
        ProfilerSummaryTabKey.DATA_PROFILE,
      ].filter((tabKey) =>
        columnNodeSupportsTabKey(
          { workspaceUserPermissions, dataSource, tableInfo },
          tabKey
        )
      );

      if (isEventChartEnabled || isDataChartEnabled || isSummaryEnabled) {
        props.getProfilerColumnCurrentMetricsData(dataSource, tableInfo, columnInfo, {
          isEventChartEnabled:
            isEventChartEnabled &&
            columnInfo.profilerConfig.categoryListChange?.enabled,
          isDataChartEnabled,
          isSummaryEnabled,
        });
      }

      if (isDataProfileEnabled) {
        if (columnInfo?.profilerConfig?.dataProfiler?.enabled) {
          props.getDataProfileData(dataSource, tableInfo, columnInfo);
        }
      }

      return {
        ...state,
        dataSource,
        schemaInfo,
        tableInfo,
        columnInfo,
        isDataProfileEnabled,
        isEventChartEnabled,
        isDataChartEnabled,
        isSummaryEnabled,
        activeTabs,
      };
    }

    return null;
  }

  shouldRefreshMetrics() {
    if (
      this.props.profilerCurrentColumnMetricsData.loading ||
      !this.props.profilerCurrentColumnMetricsData.data?.chartInfo
    ) {
      return false;
    }
    const chartInfo = this.props.profilerCurrentColumnMetricsData.data.chartInfo;
    const chartMetrics = Object.values(chartInfo)
      .map((chartInfoItem) => chartInfoItem?.[0]?.metric)
      .filter(Boolean);
    const chartMonitors = Object.values(chartInfo).reduce(
      (allMonitors, chartInfoItem) =>
        allMonitors.concat(chartInfoItem?.[0]?.rules ?? []),
      []
    );
    return (
      chartMetrics.some(isMetricInVolatileState) ||
      chartMonitors.some(isMonitorInVolatileState)
    );
  }

  refreshMetrics(opts = {}) {
    const { isRefresh = true } = opts;
    const { dataSource, tableInfo, columnInfo, getProfilerColumnCurrentMetricsData } =
      this.props;

    const {
      isEventChartEnabled,
      isDataChartEnabled,
      isSummaryEnabled,
      chartMonitorSetting,
    } = this.state;
    getProfilerColumnCurrentMetricsData(dataSource, tableInfo, columnInfo, {
      isRefresh,
      isEventChartEnabled,
      isDataChartEnabled,
      isSummaryEnabled,
      viewedMonitorUuidByAutometricType: chartMonitorSetting,
    });
  }

  componentDidMount() {
    const { metricRefreshInterval } = this.props;

    const refreshIntervalId = setInterval(() => {
      if (this.shouldRefreshMetrics()) {
        this.refreshMetrics({ isRefresh: true });
      }
    }, metricRefreshInterval);
    this.setState({ refreshIntervalId });
  }

  componentDidUpdate() {
    const { profilerAfterTreeNavigationAction, setProfilerAfterTreeNavigationAction } =
      this.props;
    if (profilerAfterTreeNavigationAction) {
      this.performAfterTreeNavigationAction(profilerAfterTreeNavigationAction);
      setProfilerAfterTreeNavigationAction(null);
    }
  }

  performAfterTreeNavigationAction(action) {
    if (
      action.type === ProfilerAfterTreeNavigationActionType.COLUMN_MONITOR_ALL_METRICS
    ) {
      this.onMonitorAll();
    } else {
      console.warn("Don't know how to handle action", action);
    }
  }

  componentWillUnmount() {
    if (this.state.refreshIntervalId) {
      clearInterval(this.state.refreshIntervalId);
    }
    this.props.resetProfilerColumn();
  }

  onStartMonitor(
    autoMetricType,
    relatedMetric,
    relatedRule,
    lastEventTs,
    symtomTypeConfig
  ) {
    console.log(`Start monitoring for ${relatedMetric.metadata.uuid}`);
    const { dataSource, tableInfo, columnInfo } = this.state;
    if (lastEventTs === 0) {
      console.log("lastEventTs is 0 for current creating rule.");
    }

    const ruleConfig = getDefaultRuleConfigForAutoMetrics(
      symtomTypeConfig,
      relatedMetric,
      dataSource,
      tableInfo,
      columnInfo,
      lastEventTs
    );
    this.props.createColumnSystemProfilerRule(ruleConfig).then(() => {
      this.refreshMetrics({ isRefresh: true });
    });
  }

  onSwitchMonitorClick(autoMetricType, relatedMetric, newMonitorUuid) {
    const { chartMonitorSetting } = this.state;
    this.setState({
      chartMonitorSetting: {
        ...chartMonitorSetting,
        [autoMetricType]: newMonitorUuid,
      },
    });

    const { dataSource, tableInfo, columnInfo } = this.props;

    const opts = {
      dataSourceUuid: dataSource.metadata.uuid,
      tableUuid: tableInfo.uuid,
      columnUuid: columnInfo.uuid,
      filterUuid: newMonitorUuid,
      metricUuid: relatedMetric.metadata.uuid,
      metric: relatedMetric,
      autoMetricType,
    };
    this.props.updateProfilerColumnChartData(opts);
  }

  onGoToRule(workspaceUuid, relatedMetric, ruleUuid) {
    if (!ruleUuid) {
      console.log(`Monitor is empty for ${relatedMetric.metadata.uuid}`);
      return;
    }

    this.setState({
      nextUrl: getURIInstance(URIPath.EDIT_MONITOR, {
        workspaceUuid,
        id: ruleUuid,
      }),
    });
  }

  onCloneMonitor(workspaceUuid, relatedMetric, monitorUuid) {
    if (!monitorUuid) {
      console.log(`Monitor is empty for ${relatedMetric.metadata.uuid}`);
      return;
    }

    this.setState({
      nextUrl: `${getURIInstance(URIPath.ADD_MONITOR, {
        workspaceUuid,
      })}?cloneFrom=${monitorUuid}`,
    });
  }

  onGoToMetric(workspaceUuid, relatedMetric) {
    if (!relatedMetric) {
      console.log("Metric is empty.");
      return;
    }

    this.setState({
      nextUrl: getURIInstance(URIPath.EDIT_METRIC, {
        workspaceUuid,
        id: relatedMetric.metadata.uuid,
      }),
    });
  }

  onGoToIncidentList(autoMetricType, relatedMetric, relatedRules, relatedQueryPeriod) {
    const { dataSource, tableInfo, columnInfo } = this.state;

    const kpiName = relatedMetric.metadata.name;
    const userSetting = getDefaultUserSetting();
    userSetting.time = {
      currentInterval: "custom",
      startTime: fromUnixTime(relatedQueryPeriod.startTimestamp),
      endTime: fromUnixTime(relatedQueryPeriod.endTimestamp),
      current: fromUnixTime(relatedQueryPeriod.endTimestamp),
    };

    const filterName = relatedRules.map((currentRule) => {
      return currentRule.metadata.name;
    });
    userSetting.localFilterSetting = {
      filterName,
      severity: [],
      dataSourceName: [dataSource.metadata.name],
      tableName: [getFullTableName(tableInfo)],
      columnName: [columnInfo.columnName],
      kpiName: [kpiName],
      slice: [],
      tagName: [],
      direction: [],
      showMyRule: false,
      showProceededDataOnly: true,
    };

    const chartInfo = {
      autoMetricType,
      currentMetric: relatedMetric,
      dataSourceInfo: dataSource,
      tableInfo,
      columnInfo,
    };

    this.props.onGoToIncidentPage({
      userSetting,
      rules: relatedRules,
      chartInfo,
    });
  }

  onAlertChannelChange(relatedRule, newAlertingChannels) {
    // Not used now.
    this.props.updateProfilerColumnRuleAlertingChannel(
      relatedRule,
      newAlertingChannels
    );
  }

  updateCategoryListChange(newCategoryListChange, opts = {}) {
    const { isRefresh = true } = opts;
    const { dataSource, schemaInfo, tableInfo, columnInfo } = this.state;

    if (!dataSource || !schemaInfo || !tableInfo || !columnInfo) {
      console.log("Can not update an empty data source or schema or table or column.");
      return;
    }

    const { profilerConfig } = columnInfo;
    const newProfilerConfig = {
      ...profilerConfig,
      categoryListChange: newCategoryListChange,
    };

    this.props
      .updateProfilerColumnCategoryListChange(
        dataSource.metadata.uuid,
        schemaInfo.uuid,
        tableInfo.uuid,
        columnInfo.uuid,
        newProfilerConfig
      )
      .then(() => this.refreshMetrics({ isRefresh }));
  }

  updateColumnConfig(partialProfilerConfig) {
    const { columnInfo } = this.state;
    this.props.updateColumnConfig({
      ...columnInfo,
      profilerConfig: {
        ...columnInfo.profilerConfig,
        ...partialProfilerConfig,
      },
    });
  }

  onEnableMonitorAllClicked(groupedAutoMetricsMapper, alertChannelConfig) {
    const { profilerCurrentMonitorObject } = this.props;
    if (profilerCurrentMonitorObject.loading) {
      return;
    }

    const { dataSource, schemaInfo, tableInfo, columnInfo } = this.state;

    const { rules, profilerConfigUpdates } =
      buildNewRuleAndProfileConfigUpdateForColumn(
        alertChannelConfig,
        dataSource,
        schemaInfo,
        tableInfo,
        columnInfo,
        groupedAutoMetricsMapper,
        true
      );

    const promises = [];

    if (rules.length > 0) {
      promises.push(this.props.monitorAllForColumn({ rules }));
    }

    if (profilerConfigUpdates.length > 0) {
      const newProfilerConfig = profilerConfigUpdates[0].profilerConfig;
      promises.push(
        this.props.updateProfilerColumnCategoryListChange(
          dataSource.metadata.uuid,
          schemaInfo.uuid,
          tableInfo.uuid,
          columnInfo.uuid,
          newProfilerConfig
        )
      );
    }

    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        this.refreshMetrics({ isRefresh: true });
      });
    }
  }

  onToggleMetricLiveStatusClick(workspaceUuid, metric, isLive) {
    const {
      dataSource,
      tableInfo,
      columnInfo,
      toggleKpiLiveStatus,
      getProfilerColumnCurrentMetricsData,
    } = this.props;

    const { isEventChartEnabled, isDataChartEnabled, isSummaryEnabled } = this.state;
    toggleKpiLiveStatus(workspaceUuid, metric, isLive).then(() => {
      getProfilerColumnCurrentMetricsData(dataSource, tableInfo, columnInfo, {
        isRefresh: true,
        isEventChartEnabled:
          isEventChartEnabled && columnInfo.profilerConfig.categoryListChange?.enabled,
        isDataChartEnabled,
        isSummaryEnabled,
      });
    });
  }

  onTriggerMetricClick(metric) {
    const {
      dataSource,
      tableInfo,
      columnInfo,
      triggerProfilerMetrics,
      getProfilerColumnCurrentMetricsData,
    } = this.props;
    const { isEventChartEnabled, isDataChartEnabled, isSummaryEnabled } = this.state;

    triggerProfilerMetrics(dataSource.metadata.uuid, {
      metricUuids: [metric.metadata.uuid],
    }).then(() => {
      getProfilerColumnCurrentMetricsData(dataSource, tableInfo, columnInfo, {
        isRefresh: true,
        isEventChartEnabled:
          isEventChartEnabled && columnInfo.profilerConfig.categoryListChange?.enabled,
        isDataChartEnabled,
        isSummaryEnabled,
      });
    });
  }

  onToggleMonitorLiveStatusClick(monitorUuid, monitors) {
    const { toggleSystemProfilerRuleStatus } = this.props;
    const targetMonitor = monitors.find(
      (monitor) => monitor.metadata.uuid === monitorUuid
    );
    if (targetMonitor) {
      toggleSystemProfilerRuleStatus(targetMonitor).then(() => {
        this.refreshMetrics({ isRefresh: true });
      });
    }
  }

  onDeleteMonitorMenuClick(monitorUuid, monitors) {
    const targetMonitor = monitors.find(
      (monitor) => monitor.metadata.uuid === monitorUuid
    );
    if (targetMonitor) {
      this.setState({ deleteMonitorConfirmationDialogTarget: targetMonitor });
    }
  }

  onDeleteMonitorConfirmed(monitors) {
    const {
      workspaceUuid,
      dataSource,
      tableInfo,
      columnInfo,
      deleteRule,
      getProfilerColumnCurrentMetricsData,
    } = this.props;

    const { isEventChartEnabled, isDataChartEnabled, isSummaryEnabled } = this.state;
    const monitorUuid = monitors[0].metadata.uuid;

    trackEvent(EVENT.DELETE_MONITOR, {
      ...getMonitorDetailProps(monitors[0]),
    });

    deleteRule(workspaceUuid, monitorUuid).then(() => {
      getProfilerColumnCurrentMetricsData(dataSource, tableInfo, columnInfo, {
        isEventChartEnabled:
          isEventChartEnabled && columnInfo.profilerConfig.categoryListChange?.enabled,
        isDataChartEnabled,
        isSummaryEnabled,
      });
    });
  }

  onMonitorAll() {
    const { dataSource, tableInfo, schemaInfo, columnInfo } = this.state;
    this.props.getProfilerCurrentMonitorObject({
      sourceUuid: dataSource.metadata.uuid,
      schemaUuid: schemaInfo.uuid,
      tableUuid: tableInfo.uuid,
      columnUuid: columnInfo.uuid,
    });
    this.setState({ isMonitorAllDialogOpen: true });
  }

  render() {
    const {
      dataSource,
      tableInfo,
      schemaInfo,
      columnInfo,
      nextUrl,
      isMonitorAllDialogOpen,
      isEventChartEnabled,
      isDataChartEnabled,
      isSummaryEnabled,
      activeTabs,
      chartMonitorSetting,
    } = this.state;

    if (nextUrl) {
      return <Redirect push to={nextUrl} />;
    }

    if (!dataSource || !schemaInfo || !tableInfo || !columnInfo) {
      return null;
    }

    const {
      profilerCurrentColumnMetricsData,
      profilerCurrentMonitorObject,
      dataProfileData,
      workspaceUserPermissions,
      workspaceUuid,
      currentSelectNode,
      onCurrentSelectNodeChange,
      isTreeExpand,
      onTreeExpandChange,
    } = this.props;

    let tabKey = this.props.tabKey;
    if (!tabKey && activeTabs.length > 0) {
      if (activeTabs.indexOf(ProfilerSummaryTabKey.AUTO_METRICS) !== -1) {
        tabKey = ProfilerSummaryTabKey.AUTO_METRICS;
      } else {
        tabKey = activeTabs[0];
      }
    }

    const canModifySettings = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
      AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
    ]);

    // BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST is a hack to check if current user is app admin
    const isAdminUser = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST,
      AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_COLUMNPROFILERCONFIGDETAILVIEW,
    ]);
    const loading = profilerCurrentColumnMetricsData.loading;

    // Null percent chart related information.
    let missingValueData = [];
    let missingValueMetric = null;
    let missingValueRules = null;
    let missingValueIncidents = [];
    const missingValueEnabled =
      columnInfo &&
      columnInfo.profilerConfig &&
      columnInfo.profilerConfig.missingValue &&
      columnInfo.profilerConfig.missingValue.enabled;
    let missingValueQueryPeriod = null;

    // Data distribution chart related information.
    let dataDistributionData = null;
    let dataDistributionMetric = null;
    let dataDistributionRules = null;
    let dataDistributionIncidents = [];
    const dataDistributionEnabled =
      columnInfo &&
      columnInfo.profilerConfig &&
      getColumnTypeCategory(columnInfo) === TableColumnTypeCategory.STRING &&
      columnInfo.profilerConfig.categoricalDistribution &&
      columnInfo.profilerConfig.categoricalDistribution.enabled;
    let dataDistributionQueryPeriod = null;

    // Percentile chart related information.
    let percentileData = null;
    let percentileMetric = null;
    let percentileRules = null;
    let percentileIncidents = [];
    const percentileEnabled =
      columnInfo &&
      columnInfo.profilerConfig &&
      getColumnTypeCategory(columnInfo) === TableColumnTypeCategory.NUMERIC &&
      columnInfo.profilerConfig.numericalDistribution &&
      columnInfo.profilerConfig.numericalDistribution.enabled;
    let percentileQueryPeriod = null;

    // Data category change list information
    let categoryListChange = { enabled: false, monitoring: { enabled: false } };
    let categoryActivityData = null;
    let categoryActivityMetric = null;
    let categoryActivityRules = [];
    let categoryActivityQueryPeriod = { startTime: 0, endTime: 0 };
    let categoryActivityIncidents = [];

    if (
      columnInfo &&
      columnInfo.profilerConfig &&
      columnInfo.profilerConfig.categoryListChange
    ) {
      categoryListChange = columnInfo.profilerConfig.categoryListChange;
    }

    if (!loading && profilerCurrentColumnMetricsData.data?.chartInfo) {
      const chartInfo = profilerCurrentColumnMetricsData.data.chartInfo;
      const categoryActivityChartInfo =
        chartInfo &&
        chartInfo[AutoMetricsType.CATEGORY_ACTIVITY] &&
        chartInfo[AutoMetricsType.CATEGORY_ACTIVITY].length > 0
          ? chartInfo[AutoMetricsType.CATEGORY_ACTIVITY][0]
          : null;

      if (categoryActivityChartInfo) {
        categoryActivityData = categoryActivityChartInfo.data;
        categoryActivityMetric = categoryActivityChartInfo.metric || null;
        categoryActivityRules = categoryActivityChartInfo.rules || [];
        categoryActivityIncidents = categoryActivityChartInfo.incidents || [];
        categoryActivityQueryPeriod = categoryActivityChartInfo.queryPeriod || null;
      }

      const missingValueChartInfo =
        chartInfo &&
        chartInfo[AutoMetricsType.MISSING_VALUE] &&
        chartInfo[AutoMetricsType.MISSING_VALUE].length > 0
          ? chartInfo[AutoMetricsType.MISSING_VALUE][0]
          : null;

      if (missingValueChartInfo) {
        missingValueData = missingValueChartInfo.data;
        missingValueMetric = missingValueChartInfo.metric || null;
        missingValueRules = missingValueChartInfo.rules || [];
        missingValueIncidents = missingValueChartInfo.incidents || [];
        missingValueQueryPeriod = missingValueChartInfo.queryPeriod || null;
      }

      const percentileChartInfo =
        chartInfo &&
        chartInfo[AutoMetricsType.NUMERICAL_DISTRIBUTION] &&
        chartInfo[AutoMetricsType.NUMERICAL_DISTRIBUTION].length > 0
          ? chartInfo[AutoMetricsType.NUMERICAL_DISTRIBUTION][0]
          : null;

      if (percentileChartInfo) {
        percentileData = percentileChartInfo.data;
        percentileIncidents = percentileChartInfo.incidents || [];
        percentileMetric = percentileChartInfo.metric || null;
        percentileRules = percentileChartInfo.rules || [];
        percentileQueryPeriod = percentileChartInfo.queryPeriod || null;
      }

      const dataDistributionChartInfo =
        chartInfo &&
        chartInfo[AutoMetricsType.CATEGORICAL_DISTRIBUTION] &&
        chartInfo[AutoMetricsType.CATEGORICAL_DISTRIBUTION].length > 0
          ? chartInfo[AutoMetricsType.CATEGORICAL_DISTRIBUTION][0]
          : null;
      if (dataDistributionChartInfo) {
        dataDistributionData = dataDistributionChartInfo.data;
        dataDistributionIncidents = dataDistributionChartInfo.incidents || [];
        dataDistributionMetric = dataDistributionChartInfo.metric || null;
        dataDistributionRules = dataDistributionChartInfo.rules || [];
        dataDistributionQueryPeriod = dataDistributionChartInfo.queryPeriod || null;
      }
    }

    let metricCount = 0;
    let monitorCount = 0;
    let ongoingIncidentCount = 0;
    if (
      isSummaryEnabled &&
      !loading &&
      profilerCurrentColumnMetricsData.data?.summary
    ) {
      const summary = profilerCurrentColumnMetricsData.data.summary;
      metricCount = summary.metricCount;
      monitorCount = summary.monitorCount;
      ongoingIncidentCount = summary.ongoingIncidentCount;
    }

    const {
      metadata: { name: datasourceName },
      config: {
        connection: { type: datasourceType },
      },
    } = dataSource;

    const columnType = getColumnTypeCategory(columnInfo);
    const columnName = columnInfo?.columnName;
    const isFullTableQueryScope =
      tableInfo.profilerConfig?.queryScope === QueryScope.FULL_TABLE;

    const getDataProfileTab = () => {
      if (
        !columnTypeCategoriesAvailableDataProfile.includes(
          getColumnTypeCategory(columnInfo)
        )
      ) {
        return [];
      }

      return [
        {
          key: ProfilerSummaryTabKey.DATA_PROFILE,
          content: (
            <ProfilerColumnDataProfileTab
              data={dataProfileData}
              schema={schemaInfo}
              tableInfo={tableInfo}
              columnInfo={columnInfo}
              dataSource={dataSource}
              onGoToTable={this.props.onGoToTable}
              isAdminUser={isAdminUser}
              canModifySettings={canModifySettings}
              onCurrentSelectNodeChange={this.props.onCurrentSelectNodeChange}
              onRefresh={() => {
                this.props.getDataProfileData(dataSource, tableInfo, columnInfo, {
                  quiet: true,
                });
              }}
            />
          ),
        },
      ];
    };

    const configTabs = [
      ...getDataProfileTab(),
      {
        key: ProfilerSummaryTabKey.AUTO_METRICS,
        content: (
          <div className="profiler-column-view-chart-data-list-container">
            {isDataChartEnabled &&
              getColumnTypeCategory(columnInfo) === TableColumnTypeCategory.STRING &&
              (dataDistributionEnabled || canModifySettings) && (
                <ChartView
                  key={`data-distribution_${dataDistributionMetric?.metadata?.uuid}`}
                  dataSource={dataSource}
                  workspaceUserPermissions={workspaceUserPermissions}
                  enableConfig={{
                    enableConfigMode: true,
                    enabled: dataDistributionEnabled,
                    onClick: () => {
                      this.updateColumnConfig({
                        categoricalDistribution: {
                          ...columnInfo.profilerConfig.categoricalDistribution,
                          enabled: true,
                        },
                      });
                    },
                  }}
                  onDisableMetricClick={() =>
                    this.updateColumnConfig({
                      categoricalDistribution: {
                        ...columnInfo.profilerConfig.categoricalDistribution,
                        enabled: false,
                      },
                    })
                  }
                  monitorConfig={{
                    onStartMonitor: this.onStartMonitor.bind(
                      this,
                      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
                      dataDistributionMetric,
                      dataDistributionRules,
                      dataDistributionMetric?.status?.lastSampleTs || 0
                    ),
                    onStopMonitor: (monitorUuid) =>
                      this.onDeleteMonitorMenuClick(monitorUuid, dataDistributionRules),
                    onPauseMonitor: (monitorUuid) =>
                      this.onToggleMonitorLiveStatusClick(
                        monitorUuid,
                        dataDistributionRules
                      ),
                    onResumeMonitor: (monitorUuid) =>
                      this.onToggleMonitorLiveStatusClick(
                        monitorUuid,
                        dataDistributionRules
                      ),
                    onSwitchMonitor: this.onSwitchMonitorClick.bind(
                      this,
                      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
                      dataDistributionMetric
                    ),
                    monitorUuid:
                      chartMonitorSetting[AutoMetricsType.CATEGORICAL_DISTRIBUTION] ||
                      "",
                    enableSwitchMonitor: true,
                    enableChartOption: true,
                    relatedRules: dataDistributionRules,
                    onGoToRule: this.onGoToRule.bind(
                      this,
                      workspaceUuid,
                      dataDistributionMetric
                    ),
                    onCloneMonitor: this.onCloneMonitor.bind(
                      this,
                      workspaceUuid,
                      dataDistributionMetric
                    ),
                    enableMetric: !!dataDistributionMetric,
                    onGoToMetric: this.onGoToMetric.bind(
                      this,
                      workspaceUuid,
                      dataDistributionMetric
                    ),
                    monitorStatus: getMonitorStatusFromRule(dataDistributionRules),
                    alertingChannelList: this.props.alertingChannelList,
                    onAlertChannelChange:
                      this.props.updateProfilerColumnRuleAlertingChannel,
                  }}
                  incidentConfig={{
                    onGoToIncidentList: this.onGoToIncidentList.bind(
                      this,
                      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
                      dataDistributionMetric,
                      dataDistributionRules,
                      dataDistributionQueryPeriod
                    ),
                  }}
                  enableComparisonOption={true}
                  enableChartOption={false}
                  data={dataDistributionData}
                  loading={loading}
                  metric={dataDistributionMetric}
                  metricType={AutoMetricsType.CATEGORICAL_DISTRIBUTION}
                  metricUuid={
                    dataDistributionMetric ? dataDistributionMetric.metadata.uuid : ""
                  }
                  metricAggregationInterval={
                    dataDistributionMetric ? getKPIInterval(dataDistributionMetric) : ""
                  }
                  incidents={dataDistributionIncidents}
                  title={
                    dataDistributionMetric ? dataDistributionMetric.metadata.name : ""
                  }
                  titleDescription={dataDistributionMetric?.metadata.description || ""}
                  onToggleMetricLiveStatusClick={this.onToggleMetricLiveStatusClick}
                  onTriggerMetricClick={this.onTriggerMetricClick.bind(
                    this,
                    dataDistributionMetric
                  )}
                  onQueryHistoryClick={this.props.onOpenMetricQueryHistory}
                  showSubTitle
                  showMetricStatusBadge
                  config={getChartSymptomConfigForFocusedMonitor(
                    dataDistributionRules,
                    chartMonitorSetting[AutoMetricsType.CATEGORICAL_DISTRIBUTION] || ""
                  )}
                />
              )}
            {isEventChartEnabled &&
              !isFullTableQueryScope &&
              getColumnTypeCategory(columnInfo) === TableColumnTypeCategory.STRING &&
              (categoryListChange?.enabled || canModifySettings) && (
                <ChartView
                  key={`categorical-change-${columnInfo.uuid}`}
                  dataSource={dataSource}
                  workspaceUserPermissions={workspaceUserPermissions}
                  enableConfig={{
                    enableConfigMode: true,
                    enabled: categoryListChange?.enabled,
                    onClick: () => {
                      this.updateCategoryListChange(
                        {
                          ...categoryListChange,
                          enabled: true,
                        },
                        { isRefresh: false }
                      );
                    },
                  }}
                  onDisableMetricClick={() =>
                    this.updateColumnConfig({
                      categoryListChange: {
                        ...columnInfo.profilerConfig.categoryListChange,
                        enabled: false,
                      },
                    })
                  }
                  metric={categoryActivityMetric}
                  monitorConfig={{
                    singularMonitor: true,
                    onStartMonitor: (newConfig) => {
                      if (categoryListChange?.monitoring?.enabled) {
                        return;
                      }

                      const newCategoryListChange = {
                        ...categoryListChange,
                        monitoring: {
                          ...categoryListChange.monitoring,
                          enabled: true,
                          alertConfig: newConfig.alertChannelConfig,
                        },
                      };

                      this.updateCategoryListChange(newCategoryListChange);
                    },
                    metricActionsOpts: {
                      includeDisable: true,
                    },
                    onStopMonitor: () => {
                      if (!categoryListChange.monitoring.enabled) {
                        return;
                      }

                      const newCategoryListChange = {
                        ...categoryListChange,
                        monitoring: {
                          ...categoryListChange.monitoring,
                          enabled: false,
                        },
                      };
                      this.updateCategoryListChange(newCategoryListChange);
                    },
                    enableMetric: true,
                    monitorStatus: categoryListChange?.monitoring?.enabled
                      ? MonitorStatus.MONITORED
                      : MonitorStatus.NOT_MONITORED,
                    alertingChannelList: this.props.alertingChannelList,
                    currentAlertingChannels: categoryListChange?.monitoring
                      ?.alertConfig ?? {
                      isMuted: false,
                      channels: [],
                      mutingSchedules: [],
                    },
                    onAlertChannelChange: (_monitor, newAlertConfig) => {
                      const newCategoryListChange = {
                        ...categoryListChange,
                        monitoring: {
                          ...categoryListChange.monitoring,
                          alertConfig: newAlertConfig,
                        },
                      };
                      this.updateCategoryListChange(newCategoryListChange);
                    },
                  }}
                  title={EventMetricTypeName[AutoMetricsType.CATEGORY_ACTIVITY]}
                  enableChartOption={false}
                  data={categoryActivityData}
                  loading={loading}
                  metricType={AutoMetricsType.CATEGORY_ACTIVITY}
                  incidents={categoryActivityIncidents}
                  incidentConfig={{
                    onGoToIncidentList: this.onGoToIncidentList.bind(
                      this,
                      AutoMetricsType.CATEGORY_ACTIVITY,
                      categoryActivityMetric,
                      categoryActivityRules,
                      categoryActivityQueryPeriod
                    ),
                  }}
                  onTriggerMetricClick={this.onTriggerMetricClick.bind(
                    this,
                    categoryActivityMetric
                  )}
                  onToggleMetricLiveStatusClick={this.onToggleMetricLiveStatusClick}
                  onQueryHistoryClick={this.props.onOpenMetricQueryHistory}
                  showSubTitle
                />
              )}
            {isDataChartEnabled &&
              getColumnTypeCategory(columnInfo) === TableColumnTypeCategory.NUMERIC &&
              !columnInfo.profilerConfig.masked &&
              (percentileEnabled || canModifySettings) && (
                <ChartView
                  key={`percentile-${percentileMetric?.metadata?.uuid}`}
                  dataSource={dataSource}
                  workspaceUserPermissions={workspaceUserPermissions}
                  enableConfig={{
                    enableConfigMode: true,
                    enabled: percentileEnabled,
                    onClick: () =>
                      this.updateColumnConfig({
                        numericalDistribution: {
                          ...columnInfo.profilerConfig.numericalDistribution,
                          enabled: true,
                        },
                      }),
                  }}
                  onDisableMetricClick={() =>
                    this.updateColumnConfig({
                      numericalDistribution: {
                        ...columnInfo.profilerConfig.numericalDistribution,
                        enabled: false,
                      },
                    })
                  }
                  monitorConfig={{
                    onStartMonitor: this.onStartMonitor.bind(
                      this,
                      AutoMetricsType.NUMERICAL_DISTRIBUTION,
                      percentileMetric,
                      percentileRules,
                      percentileMetric?.status?.lastSampleTs || 0
                    ),
                    onStopMonitor: (monitorUuid) =>
                      this.onDeleteMonitorMenuClick(monitorUuid, percentileRules),
                    onPauseMonitor: (monitorUuid) =>
                      this.onToggleMonitorLiveStatusClick(monitorUuid, percentileRules),
                    onResumeMonitor: (monitorUuid) =>
                      this.onToggleMonitorLiveStatusClick(monitorUuid, percentileRules),
                    onSwitchMonitor: this.onSwitchMonitorClick.bind(
                      this,
                      AutoMetricsType.NUMERICAL_DISTRIBUTION,
                      percentileMetric
                    ),
                    monitorUuid:
                      chartMonitorSetting[AutoMetricsType.NUMERICAL_DISTRIBUTION] || "",
                    enableSwitchMonitor: true,
                    enableChartOption: true,
                    relatedRules: percentileRules,
                    onGoToRule: this.onGoToRule.bind(
                      this,
                      workspaceUuid,
                      percentileMetric
                    ),
                    onCloneMonitor: this.onCloneMonitor.bind(
                      this,
                      workspaceUuid,
                      percentileMetric
                    ),
                    enableMetric: !!percentileMetric,
                    onGoToMetric: this.onGoToMetric.bind(
                      this,
                      workspaceUuid,
                      percentileMetric
                    ),
                    monitorStatus: getMonitorStatusFromRule(percentileRules),
                    alertingChannelList: this.props.alertingChannelList,
                    onAlertChannelChange:
                      this.props.updateProfilerColumnRuleAlertingChannel,
                  }}
                  incidentConfig={{
                    onGoToIncidentList: this.onGoToIncidentList.bind(
                      this,
                      AutoMetricsType.NUMERICAL_DISTRIBUTION,
                      percentileMetric,
                      percentileRules,
                      percentileQueryPeriod
                    ),
                  }}
                  onTriggerMetricClick={this.onTriggerMetricClick.bind(
                    this,
                    percentileMetric
                  )}
                  enableChartOption={false}
                  data={percentileData}
                  loading={loading}
                  metric={percentileMetric}
                  metricType={AutoMetricsType.NUMERICAL_DISTRIBUTION}
                  metricUuid={percentileMetric ? percentileMetric.metadata.uuid : ""}
                  metricAggregationInterval={
                    percentileMetric ? getKPIInterval(percentileMetric) : ""
                  }
                  incidents={percentileIncidents}
                  title={percentileMetric ? percentileMetric.metadata.name : ""}
                  titleDescription={percentileMetric?.metadata.description || ""}
                  showSubTitle={true}
                  onToggleMetricLiveStatusClick={this.onToggleMetricLiveStatusClick}
                  onAlertChannelChange={
                    this.props.updateProfilerColumnRuleAlertingChannel
                  }
                  onQueryHistoryClick={this.props.onOpenMetricQueryHistory}
                  showMetricStatusBadge
                />
              )}
            {isDataChartEnabled && (missingValueEnabled || canModifySettings) && (
              <ChartView
                key={`missing-value-${missingValueMetric?.metadata?.uuid}`}
                dataSource={dataSource}
                workspaceUserPermissions={workspaceUserPermissions}
                enableConfig={{
                  enableConfigMode: true,
                  enabled: missingValueEnabled,
                  onClick: () =>
                    this.updateColumnConfig({
                      missingValue: {
                        ...columnInfo.profilerConfig.missingValue,
                        enabled: true,
                      },
                    }),
                }}
                onDisableMetricClick={() =>
                  this.updateColumnConfig({
                    missingValue: {
                      ...columnInfo.profilerConfig.missingValue,
                      enabled: false,
                    },
                  })
                }
                monitorConfig={{
                  onStartMonitor: this.onStartMonitor.bind(
                    this,
                    AutoMetricsType.MISSING_VALUE,
                    missingValueMetric,
                    missingValueRules,
                    missingValueMetric?.status?.lastSampleTs || 0
                  ),
                  onStopMonitor: (monitorUuid) =>
                    this.onDeleteMonitorMenuClick(monitorUuid, missingValueRules),
                  onPauseMonitor: (monitorUuid) =>
                    this.onToggleMonitorLiveStatusClick(monitorUuid, missingValueRules),
                  onResumeMonitor: (monitorUuid) =>
                    this.onToggleMonitorLiveStatusClick(monitorUuid, missingValueRules),
                  onGoToRule: this.onGoToRule.bind(
                    this,
                    workspaceUuid,
                    missingValueMetric
                  ),
                  onCloneMonitor: this.onCloneMonitor.bind(
                    this,
                    workspaceUuid,
                    missingValueMetric
                  ),
                  onSwitchMonitor: this.onSwitchMonitorClick.bind(
                    this,
                    AutoMetricsType.MISSING_VALUE,
                    missingValueMetric
                  ),
                  monitorUuid: chartMonitorSetting[AutoMetricsType.MISSING_VALUE] || "",
                  enableSwitchMonitor: true,
                  enableChartOption: true,
                  relatedRules: missingValueRules,
                  enableMetric: !!missingValueMetric,
                  onGoToMetric: this.onGoToMetric.bind(
                    this,
                    workspaceUuid,
                    missingValueMetric
                  ),
                  monitorStatus: getMonitorStatusFromRule(missingValueRules),
                  alertingChannelList: this.props.alertingChannelList,
                  onAlertChannelChange:
                    this.props.updateProfilerColumnRuleAlertingChannel,
                }}
                incidentConfig={{
                  onGoToIncidentList: this.onGoToIncidentList.bind(
                    this,
                    AutoMetricsType.MISSING_VALUE,
                    missingValueMetric,
                    missingValueRules,
                    missingValueQueryPeriod
                  ),
                }}
                onTriggerMetricClick={this.onTriggerMetricClick.bind(
                  this,
                  missingValueMetric
                )}
                enableChartOption={false}
                metric={missingValueMetric}
                metricType={AutoMetricsType.MISSING_VALUE}
                metricUuid={missingValueMetric ? missingValueMetric.metadata.uuid : ""}
                metricAggregationInterval={
                  missingValueMetric ? getKPIInterval(missingValueMetric) : ""
                }
                title={missingValueMetric ? missingValueMetric.metadata.name : ""}
                titleDescription={missingValueMetric?.metadata.description || ""}
                showSubTitle={true}
                data={missingValueData}
                loading={loading}
                incidents={missingValueIncidents}
                config={{
                  ...getChartSymptomConfigForFocusedMonitor(
                    missingValueRules,
                    chartMonitorSetting[AutoMetricsType.MISSING_VALUE] || ""
                  ),
                  height: 200,
                  marginTop: 25,
                  startTime: 0,
                  endTime: 0,
                  detectInterval: 0,
                  isIncidentOnly: false,
                  xTitle: "Event time",
                  y2Title: "Null %",
                  textHeightInPx: 1,
                  className: "",
                }}
                onToggleMetricLiveStatusClick={this.onToggleMetricLiveStatusClick}
                onQueryHistoryClick={this.props.onOpenMetricQueryHistory}
                showMetricStatusBadge
              />
            )}
          </div>
        ),
      },
    ].filter((currentTabInfo) => activeTabs.includes(currentTabInfo.key));

    return (
      <div className="profiler-column-overview-container">
        <ProfilerDataSourceColumnOverviewHeader
          datasourceType={datasourceType}
          datasourceName={datasourceName}
          schema={schemaInfo.name}
          table={tableInfo?.tableName}
          columnType={columnType}
          columnName={columnName}
          currentSelectNode={currentSelectNode}
          onCurrentSelectNodeChange={onCurrentSelectNodeChange}
          isTreeExpand={isTreeExpand}
          onTreeExpandChange={onTreeExpandChange}
        />
        {isMonitorAllDialogOpen && (
          <ProfilerMonitorAllDialog
            modalIsOpen={isMonitorAllDialogOpen}
            setModalIsOpen={(isMonitorAllDialogOpen) =>
              this.setState({ isMonitorAllDialogOpen })
            }
            alertingChannelList={this.props.alertingChannelList}
            data={profilerCurrentMonitorObject}
            okClicked={this.onEnableMonitorAllClicked}
          />
        )}
        <div className="profiler-column-overview-body-container">
          <ProfilerStatsCardView
            title="Column info"
            statsInfoList={[
              { label: "Active Incidents", value: ongoingIncidentCount },
              { label: "Metrics", value: metricCount },
              { label: "Monitors", value: monitorCount },
            ]}
            extraInfoList={[]}
          />
          <ProfilerSummaryTabView
            tabConfigs={configTabs}
            value={
              tabKey ||
              defaultTabKey(ProfilerTreeNodeType.COLUMN, {
                workspaceUserPermissions,
                dataSource,
                schemaInfo,
                tableInfo,
                columnInfo,
              })
            }
            onChange={this.props.onTabChange}
          />
        </div>
        {this.state.deleteMonitorConfirmationDialogTarget && (
          <DeleteMonitorConfirmationDialog
            isOpen={Boolean(this.state.deleteMonitorConfirmationDialogTarget)}
            setIsOpen={(isOpen) => {
              if (!isOpen) {
                this.setState({ deleteMonitorConfirmationDialogTarget: null });
              }
            }}
            monitors={[this.state.deleteMonitorConfirmationDialogTarget]}
            onDeleteMonitors={this.onDeleteMonitorConfirmed}
          />
        )}
      </div>
    );
  }
}

export default ProfilerColumnOverview;
