import React from "react";
import { classesName } from "../../utils/css";
import { Checkbox as AntCheckbox } from "antd";
import "./checkbox.scss";

export default function Checkbox(props) {
  const { className = "", testId, ...restProps } = props;
  return (
    <AntCheckbox
      data-testid={testId}
      className={classesName("lightup-checkbox", className)}
      {...restProps}
    />
  );
}

export function CheckboxGroup(props) {
  const { className = "", testId, ...restProps } = props;
  return (
    <AntCheckbox.Group
      data-testid={testId}
      className={classesName("lightup-checkbox", className)}
      {...restProps}
    />
  );
}
