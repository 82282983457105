export function LoadMoreIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M7.58301 1.16659C7.58301 0.844419 7.32184 0.583252 6.99967 0.583252C6.67751 0.583252 6.41634 0.844419 6.41634 1.16659V2.33325C6.41634 2.65542 6.67751 2.91659 6.99967 2.91659C7.32184 2.91659 7.58301 2.65542 7.58301 2.33325V1.16659Z"
        fill="#4832F3"
      />
      <path
        d="M10.4215 2.23983C10.5826 1.96083 10.487 1.60407 10.208 1.44298C9.92901 1.2819 9.57225 1.37749 9.41116 1.65649L8.82783 2.66684C8.66674 2.94585 8.76234 3.30261 9.04134 3.46369C9.32034 3.62478 9.6771 3.52918 9.83819 3.25018L10.4215 2.23983Z"
        fill="#4832F3"
      />
      <path
        d="M12.5567 3.79159C12.7178 4.07059 12.6222 4.42735 12.3432 4.58843L11.3328 5.17177C11.0538 5.33285 10.697 5.23726 10.536 4.95825C10.3749 4.67925 10.4705 4.32249 10.7495 4.1614L11.7598 3.57807C12.0388 3.41699 12.3956 3.51258 12.5567 3.79159Z"
        fill="#4832F3"
      />
      <path
        d="M11.6663 6.41659C11.3442 6.41659 11.083 6.67775 11.083 6.99992C11.083 7.32209 11.3442 7.58325 11.6663 7.58325H12.833C13.1552 7.58325 13.4163 7.32209 13.4163 6.99992C13.4163 6.67775 13.1552 6.41659 12.833 6.41659H11.6663Z"
        fill="#4832F3"
      />
      <path
        d="M10.536 9.04159C10.697 8.76258 11.0538 8.66699 11.3328 8.82807L12.3432 9.4114C12.6222 9.57249 12.7178 9.92925 12.5567 10.2083C12.3956 10.4873 12.0388 10.5829 11.7598 10.4218L10.7495 9.83843C10.4705 9.67735 10.3749 9.32059 10.536 9.04159Z"
        fill="#4832F3"
      />
      <path
        d="M9.83819 10.7497C9.67711 10.4707 9.32035 10.3751 9.04134 10.5362C8.76234 10.6973 8.66674 11.054 8.82783 11.333L9.41116 12.3434C9.57224 12.6224 9.929 12.718 10.208 12.5569C10.487 12.3958 10.5826 12.0391 10.4215 11.7601L9.83819 10.7497Z"
        fill="#4832F3"
      />
      <path
        d="M6.99967 11.0833C7.32184 11.0833 7.58301 11.3444 7.58301 11.6666V12.8333C7.58301 13.1554 7.32184 13.4166 6.99967 13.4166C6.67751 13.4166 6.41634 13.1554 6.41634 12.8333V11.6666C6.41634 11.3444 6.67751 11.0833 6.99967 11.0833Z"
        fill="#4832F3"
      />
      <path
        d="M5.17152 11.333C5.33261 11.054 5.23701 10.6973 4.95801 10.5362C4.679 10.3751 4.32224 10.4707 4.16116 10.7497L3.57783 11.7601C3.41674 12.0391 3.51234 12.3958 3.79134 12.5569C4.07035 12.718 4.42711 12.6224 4.58819 12.3434L5.17152 11.333Z"
        fill="#4832F3"
      />
      <path
        d="M3.46338 9.04158C3.62446 9.32059 3.52887 9.67735 3.24987 9.83843L2.23951 10.4218C1.96051 10.5829 1.60375 10.4873 1.44267 10.2083C1.28158 9.92925 1.37717 9.57249 1.65618 9.41141L2.66653 8.82807C2.94553 8.66699 3.30229 8.76258 3.46338 9.04158Z"
        fill="#4832F3"
      />
      <path
        d="M1.16634 6.41659C0.844175 6.41659 0.583008 6.67775 0.583008 6.99992C0.583008 7.32209 0.844175 7.58325 1.16634 7.58325H2.33301C2.65517 7.58325 2.91634 7.32209 2.91634 6.99992C2.91634 6.67775 2.65517 6.41659 2.33301 6.41659H1.16634Z"
        fill="#4832F3"
      />
      <path
        d="M1.44267 3.79158C1.60375 3.51258 1.96051 3.41699 2.23951 3.57807L3.24987 4.16141C3.52887 4.32249 3.62446 4.67925 3.46338 4.95825C3.30229 5.23726 2.94553 5.33285 2.66653 5.17177L1.65618 4.58843C1.37717 4.42735 1.28158 4.07059 1.44267 3.79158Z"
        fill="#4832F3"
      />
      <path
        d="M4.58819 1.65649C4.4271 1.37749 4.07034 1.2819 3.79134 1.44298C3.51234 1.60407 3.41674 1.96083 3.57783 2.23983L4.16116 3.25018C4.32225 3.52918 4.67901 3.62478 4.95801 3.46369C5.23701 3.30261 5.33261 2.94585 5.17152 2.66684L4.58819 1.65649Z"
        fill="#4832F3"
      />
    </svg>
  );
}
