import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { RequestStatus } from "../../utils/enums";
import { URIPath, getURIInstance } from "../../utils/uri-path";
import DataSourceWizard from "../../components/datasource/data-source-wizard";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../utils/telemetry";

class EditDataSourceWizard extends Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.goToSetting = this.goToSetting.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id !== "") {
      console.log(`Current id is ${this.props.match.params.id}`);
      this.props.getDataSource(
        this.props.match.params.workspaceUuid,
        this.props.match.params.id
      );
    }

    if (this.props.kpiList.length === 0) {
      this.props.getKpiList(this.props.match.params.workspaceUuid);
    }

    this.props.getDataSourceSystemIntegrationMetadata();
  }

  onCancel() {
    const { history } = this.props;

    if (history.length > 1) {
      history.goBack();
    } else {
      // We navigate to settings/datasource if the current route is the only one in the history
      this.goToSetting();
    }
  }

  goToSetting() {
    const {
      history,
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    history.push(getURIInstance(URIPath.DATA_SOURCE_LIST, { workspaceUuid }));
  }

  onSave(workspaceUuid, id, newDataSource) {
    console.log(`id is ${id}`);
    this.props
      .editDataSource(workspaceUuid, id, newDataSource)
      .then(() => this.goToSetting());

    trackEvent(EVENT.SAVE_DATASOURCE, {
      ...getDatasourceDetailProps(newDataSource),
      page: PAGE.DATASOURCE_DETAIL,
    });
  }

  onPreview(workspaceUuid, dataSource) {
    this.props.previewDataSource(workspaceUuid, dataSource);
  }

  onDelete(workspaceUuid, id) {
    console.log(`Deleting data source ${id}`);
    this.props.deleteDataSource(workspaceUuid, { id });
    this.goToSetting();
  }

  render() {
    const workspaceUuid = this.props.match.params.workspaceUuid;

    const defaultData = this.props.currentEditData ? this.props.currentEditData : null;
    const id = defaultData && defaultData.metadata ? defaultData.metadata.uuid : "";
    if (!id || id !== this.props.match.params.id) {
      return null;
    }

    const relatedKpiCountMapper = {};
    if (this.props.kpiList) {
      this.props.kpiList.forEach(function (kpiItem) {
        if (!relatedKpiCountMapper[kpiItem.dataSource]) {
          relatedKpiCountMapper[kpiItem.dataSource] = 0;
        }
        relatedKpiCountMapper[kpiItem.dataSource] += 1;
      });
    }

    return (
      <DataSourceWizard
        isEdit={true}
        isClone={false}
        isSaving={this.props.editDataSourceStatus === RequestStatus.DOING}
        lastPreviewStatus={this.props.lastPreviewStatus}
        lastPreviewResult={this.props.lastPreviewResult}
        lastPreviewTime={this.props.lastPreviewTime}
        previewDataSource={this.onPreview.bind(this, workspaceUuid)}
        resetDataSourceStatus={this.props.resetDataSourceStatus}
        waffle={this.props.waffle}
        alationDataSourceList={this.props.alationDataSourceList}
        atlanDataSourceList={this.props.atlanDataSourceList}
        dataSourceList={this.props.dataSourceList}
        relatedKpiNumber={relatedKpiCountMapper[id] || 0}
        onDelete={this.onDelete.bind(this, workspaceUuid, id)}
        onSave={this.onSave.bind(this, workspaceUuid, id)}
        onCancel={this.onCancel}
        defaultData={defaultData}
        workspaceUserPermissions={this.props.workspaceUserPermissions}
      />
    );
  }
}

export default withRouter(EditDataSourceWizard);
