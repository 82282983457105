import { getKeyForChart } from "./utils";
import { isSliceEqual, getSliceDisplayString } from "../../utils/general";
import { fnSorter } from "../../utils/sort";
import {
  INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
  INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
  KPI_CATEGORY_KEY_NAME,
  KPI_PERCENTILE_KEY_NAME,
} from "../../utils/constants";
import { getAutoMetricTypeFromKPI } from "../../utils/metric";

// Max number of charts the user is allowed to show in the right pane of the UDIA view.
// We limit this to avoid performance issues.
export const MAX_CHARTS_SHOWN = 10;

export function sliceLabel(slice) {
  return getSliceDisplayString(slice);
}

// Sorts in place.
export function sortConfigItems(configData = []) {
  for (let metric of configData) {
    metric.sliceList.sort(
      fnSorter((slice) => sliceLabel(slice.slice).toLocaleLowerCase())
    );
  }
  configData.sort(fnSorter((metric) => metric.metricName));
}

// Slices are represented as objects of the form { column: value }; this function checks
// for equality.
function configSliceEquals(slice1, slice2) {
  return isSliceEqual(slice1.slice ?? {}, slice2.slice ?? {});
}

// Slices from the annotatedData section of the incident config have an additional key
// indicating whether or not the slice has incidents (hasIncidents). This removes that.
function removeSliceAnnotations(slice) {
  return {
    slice: slice.slice,
    show: slice.show,
  };
}

function configMetricNumItemsShown(metricItem) {
  const countShow = (item) => (item.show ? 1 : 0);
  return metricItem.sliceList.length === 0
    ? countShow(metricItem)
    : metricItem.sliceList.reduce((acc, slice) => acc + countShow(slice), 0);
}

export function configNumItemsShown(configData) {
  return configData.reduce(
    (acc, metricItem) => acc + configMetricNumItemsShown(metricItem),
    0
  );
}

// Adds or updates a slice in list. Calls updateFn on the slice and removes
// annotations in either case.
function configUpdateMetricSlice(sliceList, slice, updateFn = (v) => v) {
  const newSliceList = [...sliceList];
  const index = sliceList.findIndex((s) => configSliceEquals(s, slice));
  if (index >= 0) {
    newSliceList[index] = updateFn(removeSliceAnnotations(slice));
  } else {
    newSliceList.push(updateFn(removeSliceAnnotations(slice)));
  }
  return newSliceList;
}

// Remove a slice from a list.
function configRemoveMetricSlice(sliceList, slice) {
  const newSliceList = [...sliceList];
  const index = sliceList.findIndex((s) => configSliceEquals(s, slice));
  if (index >= 0) {
    newSliceList.splice(index, 1);
  }
  return newSliceList;
}

// Adds or updates a metric or metric slice to the metrics of interest.
// In either case updateFn will get called on the metric or metric slice. By default,
// the new chart is will not be shown (you could override this with updateFn).
export function configUpdateMetric(configData, metricUuid, slice, updateFn = (v) => v) {
  const newConfigData = [...configData];
  const index = configData.findIndex((metric) => metric.metricUuid === metricUuid);
  if (index >= 0) {
    if (slice) {
      newConfigData[index] = { ...configData[index] };
      newConfigData[index].sliceList = configUpdateMetricSlice(
        configData[index].sliceList,
        slice,
        updateFn
      );
    } else {
      newConfigData[index] = updateFn(configData[index]);
    }
  } else {
    const sliceList = slice ? [updateFn(removeSliceAnnotations(slice))] : [];
    const newMetricItem = { metricUuid, sliceList, show: false };
    newConfigData.push(slice ? newMetricItem : updateFn(newMetricItem));
  }
  return newConfigData;
}

// Add a series of metric slices to the metric of interest. The new chart will be
// shown as long as this does not exceed the max number of charts allowed.
export function configAddSlices(configData, metricUuid, slices) {
  return slices.reduce((acc, slice) => {
    const showHideFn = (slice) => ({
      ...slice,
      show: configNumItemsShown(acc) < MAX_CHARTS_SHOWN,
    });
    return configUpdateMetric(acc, metricUuid, slice, showHideFn);
  }, configData);
}

// Remove a metric or metric slice from the metrics of interest.
export function configRemoveMetric(configData, metricUuid, slice) {
  const newConfigData = [...configData];
  const index = configData.findIndex((metric) => metric.metricUuid === metricUuid);
  if (index >= 0) {
    if (slice) {
      const newSliceList = configRemoveMetricSlice(configData[index].sliceList, slice);
      // If we've removed the last slice, remove the metric.
      if (newSliceList.length > 0) {
        newConfigData[index] = { ...configData[index], sliceList: newSliceList };
      } else {
        newConfigData.splice(index, 1);
      }
    } else {
      newConfigData.splice(index, 1);
    }
  }
  return newConfigData;
}

export function getCorrelationChartListFromConfig(
  config,
  metricList,
  monitorList,
  monitorSetting
) {
  if (config.loading) {
    return [];
  }

  if (!config.data?.data?.length) {
    return [];
  }

  const correlationChartList = [];

  const monitorInfoMapper = {};
  const metricInfoMapper = {};
  monitorList.forEach((currentMonitor) => {
    monitorInfoMapper[currentMonitor.metadata.uuid] = currentMonitor;
  });

  metricList.forEach((currentMetric) => {
    metricInfoMapper[currentMetric.metadata.uuid] = currentMetric;
  });

  for (let { metricUuid, sliceList, show } of config.data.data) {
    let dataSourceUuid = "";
    let tableUuid = "";
    let columnUuid = "";
    let metric = "";
    if (!metricUuid) {
      console.log("Metric uuid is empty. skipping");
      continue;
    }

    if (!metricInfoMapper[metricUuid]) {
      console.log(`Metric uuid ${metricUuid} is not valid. skipping.`);
      continue;
    }

    const normalizedSliceList = (
      sliceList.length === 0 ? [{ slice: {}, show }] : sliceList
    ).filter((currentSliceConfig) => currentSliceConfig.show);

    if (normalizedSliceList.length === 0) {
      continue;
    }

    metric = metricInfoMapper[metricUuid];
    const autoMetricType = getAutoMetricTypeFromKPI(metric);

    for (let { slice } of normalizedSliceList) {
      const sliceKeyValuePairs = [];
      const chartKey = getKeyForChart(metricUuid, slice);
      const monitorUuid = monitorSetting[chartKey] || "";
      for (const sliceKey in slice) {
        if (
          [
            INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
            INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
            KPI_CATEGORY_KEY_NAME,
            KPI_PERCENTILE_KEY_NAME,
          ].includes(sliceKey)
        ) {
          continue;
        }

        sliceKeyValuePairs.push({ key: sliceKey, value: slice[sliceKey] });
      }

      correlationChartList.push({
        filterUuid: monitorUuid,
        sliceKeyValuePairs,
        autoMetricType,
        dataSourceUuid,
        tableUuid,
        columnUuid,
        metricUuid,
        slice,
        metric,
      });
    }
  }

  return correlationChartList;
}
