import React, { useMemo } from "react";
import { getColumnTypeCategory } from "../../utils/column";
import { TableColumnTypeCategory } from "../../utils/enums";
import { fnSorter } from "../../utils/sort";
import { Select as AntSelect } from "antd";

export function selectFilterSort(optionA, optionB) {
  if (typeof optionA.label === "string" && typeof optionB.label === "string") {
    return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase());
  }

  if (typeof optionA.label === "number" && typeof optionB.label === "number") {
    return fnSorter((option) => option.label)(optionA, optionB);
  }

  return 0;
}

function Select(props) {
  const { enableSorting = false, ...otherProps } = props;
  if (enableSorting) {
    otherProps["filterSort"] = selectFilterSort;
  }

  return (
    <AntSelect
      filterOption={(input, option) =>
        (option.label || option.children || "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      dropdownStyle={{ boxShadow: "0 12px 20px #d7dee8" }}
      {...otherProps}
    />
  );
}

export function PartitionSelect(props) {
  const { columnList, ...otherProps } = props;

  const normalizedColumnList = useMemo(() => {
    return (columnList || []).sort((a, b) => {
      if (a.isPartitionKey === b.isPartitionKey && a.columnName === b.columnName) {
        return 0;
      }

      if (a.isPartitionKey !== b.isPartitionKey) {
        if (a.isPartitionKey) {
          return -1;
        } else {
          return 1;
        }
      }

      return a.columnName.localeCompare(b.columnName);
    });
  }, [columnList]);

  return (
    <AntSelect
      showSearch
      filterOption={(input, option) => {
        return option.label.toLowerCase().includes(input.toLowerCase());
      }}
      {...otherProps}
    >
      {normalizedColumnList.map((currentColumn) => {
        const { isPartitionKey, columnName, uuid, columnUuid } = currentColumn;
        return (
          <AntSelect.Option
            value={columnName}
            key={uuid || columnUuid || columnName}
            label={columnName}
          >
            {columnName}
            {isPartitionKey && (
              <span style={{ marginLeft: "3px", color: "#BFBFBF" }}>(partition)</span>
            )}
          </AntSelect.Option>
        );
      })}
    </AntSelect>
  );
}

export function TimestampSelect(props) {
  const { showAllColumns = false, columnList, ...otherProps } = props;
  const normalizedColumnList = useMemo(() => {
    return (columnList || [])
      .filter(
        (currentColumn) =>
          showAllColumns ||
          getColumnTypeCategory(currentColumn) === TableColumnTypeCategory.TIMESTAMP
      )
      .sort((a, b) => {
        if (a.isIndexed === b.isIndexed && a.columnName === b.columnName) {
          return 0;
        }

        if (a.isIndexed !== b.isIndexed) {
          if (a.isIndexed) {
            return -1;
          } else {
            return 1;
          }
        }

        return a.columnName.localeCompare(b.columnName);
      });
  }, [columnList, showAllColumns]);

  return (
    <AntSelect
      showSearch
      filterOption={(input, option) => {
        return option.label.toLowerCase().includes(input.toLowerCase());
      }}
      {...otherProps}
    >
      {normalizedColumnList.map((currentColumn) => {
        const { isIndexed, columnName } = currentColumn;
        return (
          <AntSelect.Option value={columnName} key={columnName} label={columnName}>
            {columnName}
            {isIndexed && (
              <span style={{ marginLeft: "3px", color: "#BFBFBF" }}>(index)</span>
            )}
          </AntSelect.Option>
        );
      })}
    </AntSelect>
  );
}

export default Select;
