import React, { useMemo } from "react";
import {
  MetricDataAssetType,
  MetricType,
  isFileSource,
} from "../../../../../utils/enums";
import MetricFailingRecordsQueryConfigItem from "../../../fields/metric-failing-records-query-config-item";
import DataAssetSelect from "../../../fields/data-asset-select";
import ConfigureMetric from "../../../fields/configure-metric";
import AddTags from "../../../fields/add-tags";
import { DataAssetCards } from "../../../fields";
import WhereClause from "../../../fields/where-clause";
import { isAutoCreationTypeMetric } from "../../../../../utils/metric";
import { tableSupportsDataDelay } from "../../../../../utils/table";

function DataDelayMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    onDataSourceChanged,
    schemaList,
    tableList,
    onTableChanged,
    columnList: { data: columnList },
    openWorkspaceTakeover,
    closeTakeover,
    partitionSampleData,
    getPartitionSampleData,
    tagList,
    waffle,
    resetKPIStatus,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult = { loading: false },
    currentDataSource,
    disabled = false,
  } = props;

  const isEditMode = !configData.config.inheritTableSettings;
  const isStreaming = configData.config.isStreaming;
  const allowOverride = configData.metadata.creationType !== MetricType.AUTO;
  const isFileDataSource = isFileSource(currentDataSource?.config?.connection?.type);

  const normalizeTableList = useMemo(() => {
    return tableList.filter((currentTable) => tableSupportsDataDelay(currentTable));
  }, [tableList]);

  return (
    <DataAssetCards>
      <DataAssetSelect
        dataAssetType={MetricDataAssetType.TABLE}
        configData={configData}
        dataSourceList={dataSourceList}
        schemaList={schemaList}
        tableList={normalizeTableList}
        isStreaming={isStreaming}
        onConfigDataChange={onConfigDataChange}
        onDataSourceChanged={onDataSourceChanged}
        onTableChanged={onTableChanged}
        disabled={disabled || isAutoCreationTypeMetric(configData)}
        resetKPIStatus={resetKPIStatus}
      />
      <ConfigureMetric
        enableAggregationWindow={false}
        enableBackfillDuration={false}
        enableDataCollectionSchedule={true}
        enableDataCollectionWindow={false}
        enableQueryScope={false}
        enableQueryTimezone={false}
        enableSeasonality={true}
        enableSlicing={!isAutoCreationTypeMetric(configData)}
        enableSynchronizationDelay={false}
        allowOverride={allowOverride}
        configData={configData}
        isValidConfig={isValidConfig}
        isStreaming={isStreaming}
        dataSourceList={dataSourceList}
        tableList={normalizeTableList}
        columnList={columnList}
        isEditMode={isEditMode}
        disabled={disabled}
        onConfigDataChange={onConfigDataChange}
        openWorkspaceTakeover={openWorkspaceTakeover}
        closeTakeover={closeTakeover}
        partitionSampleData={partitionSampleData}
        getPartitionSampleData={getPartitionSampleData}
        waffle={waffle}
      />
      {!isAutoCreationTypeMetric(configData) && !isFileDataSource && (
        <WhereClause
          configData={configData}
          columnList={columnList}
          onConfigDataChange={onConfigDataChange}
          schemaList={schemaList}
          tableList={tableList}
          disabled={disabled}
        />
      )}
      {!isFileDataSource && (
        <MetricFailingRecordsQueryConfigItem
          configData={configData}
          disabled={disabled}
          value={configData.config.failingRecordsSql}
          validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
          validateFailingRecordsSqlResult={validateFailingRecordsSqlResult}
          onChange={(newFailingRecordsSql) => {
            onConfigDataChange({
              ...configData,
              config: {
                ...configData.config,
                failingRecordsSql: newFailingRecordsSql,
              },
            });
          }}
        />
      )}
      <AddTags
        configData={configData}
        disabled={disabled}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
      />
    </DataAssetCards>
  );
}

DataDelayMetricDataAssetConfig.assetMeta = {
  title: "Select data asset",
  createDescription:
    "Create your metric by selecting the type, naming it, picking a data asset, and configuring the settings",
  editDescription:
    "Edit your metric by naming it, picking a data asset, and configuring the settings",
};

export default DataDelayMetricDataAssetConfig;
