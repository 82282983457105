import { combineReducers } from "redux";
import userReducer from "./user/user-reducer";
import dataSourceReducer from "./datasource/data-source-reducer";
import kpiReducer from "./kpi/kpi-reducer";
import ruleReducer from "./rule/rule-reducer";
import incidentReducer from "./incident/incident-reducer";
import integrationReducer from "./integration/integration-reducer";
import notificationReducer from "./notification/notification-reducer";
import tagReducer from "./tag/tag-reducer";
import profilerReducer from "./profiler/profiler-reducer";
import scheduleReducer from "./schedule/schedule-reducer";
import dashboardReducer from "./dashboard/dashboard-reducer";
import downloadFilesReducer from "./downloads/dowload-file-reducer";
import chartDataReducer from "./chart-data/chart-data-reducer";
import adminReducer from "./admin/admin-reducer";
import draftReducer from "./draft/draft-reducer";
import takeoverReducer from "./takeover/takeover-reducer";
import navigationReducer from "./navigation/navigation-reducer";

const rootReducer = combineReducers({
  userReducer,
  dataSourceReducer,
  kpiReducer,
  ruleReducer,
  incidentReducer,
  integrationReducer,
  notificationReducer,
  tagReducer,
  profilerReducer,
  scheduleReducer,
  dashboardReducer,
  downloadFilesReducer,
  chartDataReducer,
  adminReducer,
  takeoverReducer,
  navigationReducer,
  draftReducer,
});

export default rootReducer;
