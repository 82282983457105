import React from "react";
import { Tooltip } from "antd";
import { classesName } from "../../utils/css";

import "./ng-tooltip.scss";

function NgTooltip(props) {
  const { shadow = true } = props;
  const overlayClassName = classesName("ng-tooltip-overlay", shadow && "shadow");
  return <Tooltip overlayClassName={overlayClassName} {...props} />;
}

export default NgTooltip;
