import React from "react";
import { Spinner } from "../../atom/spinner";

import "./chart-spinner.scss";

function ChartSpinner(props) {
  const { title = "Data load in progress" } = props;
  return (
    <div className="chart-spinner">
      <Spinner size="large" />
      {title && <div>{title}</div>}
    </div>
  );
}

export default ChartSpinner;
