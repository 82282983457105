import React from "react";
import { classesName } from "../../utils/css";
import { Tabs as AntdTabs } from "antd";

import "./tabs.scss";

function Tabs(props) {
  const { className: propsClassName, ...restProps } = props;

  const className = classesName(propsClassName, "ng-tabs");

  return <AntdTabs className={className} {...restProps} />;
}

export const TabPane = AntdTabs.TabPane;

export default Tabs;
