module.exports = {
  PAGE_NOT_FOUND_MSG: "The page you were looking for cannot be found!",
  NOT_FOUND_CODE: "404 - Page Not Found",
  POLL_TIMEOUT: 2 * 60 * 1000,
  POLL_INTERVAL: 10 * 1000,
  KPI_DQI_COLUMN_KEY_NAME: "lightup__column_name",
  KPI_DQI_VALUE_KEY_NAME: "value",
  KPI_DQI_CONFORMITY_CHECK_KEY_NAME: "value",
  KPI_CATEGORY_KEY_NAME: "lightup__category",
  KPI_PERCENTILE_KEY_NAME: "lightup__percentile",
  KPI_AGGREGATION_SOURCE_UUID_KEY_NAME: "lightup__src_uuid",
  KPI_AGGREGATION_TARGET_UUID_KEY_NAME: "lightup__tgt_uuid",
  INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME: "lightup__key_col_index",
  INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME: "lightup__attr_col_index",
  MAX_BATCH_REQUEST_NUMBER: 900,
  DOUBLE_PRECISION_MAX: 1e308,
  DOUBLE_PRECISION_MIN: 1e-308,
};
