import React from "react";
import { DAY_IN_SECONDS } from "../../../../../utils/time";
import { SystemIntegrationType } from "../../../../../utils/system-integration";
import AtlanIntegrationBasicDialog from "./atlan-integration-basic-dialog";

function AddAtlanIntegrationDialog(props) {
  const { onAdd, onPreview, modalIsOpen, setIsOpen } = props;
  const defaultAtlanInstance = {
    metadata: {
      uuid: "",
      name: "",
    },
    config: {
      baseUrl: "",
      type: SystemIntegrationType.ATLAN,
      bearerToken: "",
      incidentWindow: DAY_IN_SECONDS,
      disableAnnouncements: false,
    },
  };

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  return (
    <AtlanIntegrationBasicDialog
      defaultData={defaultAtlanInstance}
      className={"add-atlan-integration-dialog-container"}
      dialogTitle={"Add Atlan Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      OKText={"Add"}
      enablePreview={true}
      enableSave={true}
    />
  );
}

export default AddAtlanIntegrationDialog;
