import React from "react";
import {
  ValueOutOfExpectationIcon,
  SharpChangeIcon,
  SlowBurnTrendChangeIcon,
} from "../icons/symptom";
import { SymptomTypeConst } from "../../utils/enums";
import { isChangeFeatureType } from "../../utils/monitor";

export const creationSymptomInfoArray = [
  {
    symptom: SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS,
    symptomTitle: "Value outside expectation",
    description:
      "A value outside expectation is when the metric value does not match the value predicted from past patterns.",
    imageIcon: <ValueOutOfExpectationIcon />,
  },
  {
    symptom: SymptomTypeConst.SLOW_BURN_TREND_CHANGE,
    symptomTitle: "Slow burn change",
    description:
      "A slow burn trend change is when the metric trend slowly drifts away from past trend.",
    imageIcon: <SlowBurnTrendChangeIcon />,
  },
  {
    symptom: SymptomTypeConst.SHARP_CHANGE,
    symptomTitle: "Sharp change",
    description:
      "A sharp changes is when the metrics suddenly moves more than it normally does.",
    imageIcon: <SharpChangeIcon />,
  },
];

export function enabledThresholdFeatureOptions(options, disableTrendMonitor) {
  return options.map(({ label, value }) => {
    const disabled = disableTrendMonitor && isChangeFeatureType(value);
    return {
      disabled,
      value,
      label: disabled ? `${label} (not supported)` : label,
    };
  });
}
