/**
 * Recursive function to remove specified keys from an object.
 *
 * @param {Object | Array} obj - The object or array from which keys are to be removed.
 * @param {Array[string]} keysTopRemove - An array of keys to be removed from the object.
 * @return {Object | Array} - The object or array with specified keys removed.
 */
export function removeKeys(obj, keysToRemove) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeys(item, keysToRemove));
  }
  const cleanObj = {};
  for (let key in obj) {
    if (!keysToRemove.includes(key)) {
      cleanObj[key] = removeKeys(obj[key], keysToRemove);
    }
  }
  return cleanObj;
}
