import { RequestStatus } from "../../utils/enums";

import {
  SET_DATA_SOURCE_ALATION_DATA_SOURCE_LIST,
  SET_DATA_SOURCE_ATLAN_DATA_SOURCE_LIST,
  SET_DATA_SOURCE_LIST,
  SET_LAST_PREVIEW_TIME,
  SET_LAST_PREVIEW_STATUS,
  SET_LAST_PREVIEW_RESULT,
  SET_ADD_DATA_SOURCE_REQUEST_STATUS,
  SET_EDIT_DATA_SOURCE_REQUEST_STATUS,
  SET_CURRENT_EDIT_DATA,
  SET_CURRENT_CLONE_DATA_SOURCE,
  SET_CURRENT_DATA_SOURCE_USAGE,
  SET_CURRENT_DATA_SOURCE_QUERY_LIST,
} from "../../actions/datasource/data-source-action-types";

const initialDataSourceState = {
  creatingStatus: RequestStatus.UNKNOWN,
  editingStatus: RequestStatus.UNKNOWN,
  dataSourceAlationDataSourceList: {
    loading: true,
    data: [],
  },
  dataSourceAtlanDataSourceList: {
    loading: true,
    data: [],
  },
  dataSourceList: {
    loading: true,
    data: [],
  },
  lastPreviewTime: "",
  lastPreviewStatus: RequestStatus.UNKNOWN,
  lastPreviewResult: [],
  currentEditData: {},
  currentCloneDataSource: null,
  currentDataSourceUsage: {
    loading: true,
    data: {},
  },
  currentDataSourceQueryList: {
    loading: true,
    data: [],
  },
};

export default function dataSourceReducer(state = initialDataSourceState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_DATA_SOURCE_LIST:
      newState = { ...state, dataSourceList: action.dataSourceList };
      return newState;

    case SET_DATA_SOURCE_ALATION_DATA_SOURCE_LIST:
      newState = {
        ...state,
        dataSourceAlationDataSourceList: action.dataSourceAlationDataSourceList,
      };
      return newState;

    case SET_DATA_SOURCE_ATLAN_DATA_SOURCE_LIST:
      newState = {
        ...state,
        dataSourceAtlanDataSourceList: action.dataSourceAtlanDataSourceList,
      };
      return newState;

    case SET_LAST_PREVIEW_TIME:
      newState = { ...state, lastPreviewTime: action.lastPreviewTime };
      return newState;

    case SET_LAST_PREVIEW_STATUS:
      newState = { ...state, lastPreviewStatus: action.lastPreviewStatus };
      return newState;

    case SET_LAST_PREVIEW_RESULT:
      newState = { ...state, lastPreviewResult: action.lastPreviewResult };
      return newState;

    case SET_ADD_DATA_SOURCE_REQUEST_STATUS:
      newState = { ...state, creatingStatus: action.creatingStatus };
      return newState;

    case SET_EDIT_DATA_SOURCE_REQUEST_STATUS:
      newState = { ...state, editingStatus: action.editingStatus };
      return newState;

    case SET_CURRENT_EDIT_DATA:
      newState = { ...state, currentEditData: action.currentEditData };
      return newState;

    case SET_CURRENT_CLONE_DATA_SOURCE:
      newState = {
        ...state,
        currentCloneDataSource: action.currentCloneDataSource,
      };
      return newState;

    case SET_CURRENT_DATA_SOURCE_USAGE:
      newState = {
        ...state,
        currentDataSourceUsage: action.currentDataSourceUsage,
      };
      return newState;

    case SET_CURRENT_DATA_SOURCE_QUERY_LIST:
      newState = {
        ...state,
        currentDataSourceQueryList: action.currentDataSourceQueryList,
      };
      return newState;

    default:
      return state;
  }
}
