import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { NgTableClickableText } from "../../components/table/ng-table";
import { fnSorter } from "../../utils/sort";
import {
  MetricStatus,
  ListPageColumnKey,
  getCollectionModeTypeDisplayName,
  DraftState,
  DraftType,
  MetricMode,
  MetricCategory,
} from "../../utils/enums";
import MetricStatusBadge from "../../components/status-badge/metric-status-badge";
import Tooltip from "../../components/tooltip/ng-tooltip";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { getTimePeriodFromString, getStringFromTimeStamp } from "../../utils/time";
import ProfilerMetricActionsMenu, {
  MetricActions,
} from "../profiler/profiler-metric-actions-menu";
import ProfilerConfirmationDialog from "../profiler/profiler-confirmation-dialog";
import {
  getURIInstance,
  hasPermission,
  URIPath,
  URIPathPermissions,
} from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import ConfirmationDialog from "../../components/confirmation-dialog/ng-index";
import EntityList from "../../components/entity-list/entity-list";
import {
  createdByColumn,
  createdOnColumn,
  dataSourceColumn,
  dimensionColumn,
  displayColumnName,
  displaySchemaName,
  displayTableName,
  metricCreationTypeColumn,
  metricTypeColumn,
  modifiedByColumn,
  modifiedAtColumn,
  schemaColumn,
  tableColumn,
  tagsColumn,
} from "../../components/entity-list/columns";
import { collectStats, countBy, indexBy } from "../../utils/iterables";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";
import {
  getMetricStatus,
  getSliceByColumns,
  metricCreationTypeLabel,
  metricDataSourceUuids,
  monitorCountByMetricUuid,
  getMetricQueryScopeLabel,
  isAutoMetric,
} from "../../utils/metric";
import { getUpdatedTags } from "../../components/tag-group/ng-tag-group";
import { EVENT, PAGE, trackEvent } from "../../utils/telemetry";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";
import DraftMetricSendApprovalModal from "../../components/metric/modals/draft-metric-send-approval-modal";
import { deepcopy } from "../../utils/objects";
import { getApprovalConfig } from "../../utils/approvals";
import { isRelatedToVirtualTable } from "../../utils/table";

import "./metric-list.scss";

function getTableRows(dataSourceList, ruleList, kpiListData, kpiIncidentList) {
  const dataSourcesByUuid = indexBy(
    dataSourceList.data,
    (dataSource) => dataSource.metadata.uuid
  );

  const monitorCounts = monitorCountByMetricUuid(ruleList.data);

  const ongoingIncidentCountByMonitorUuid = countBy(
    kpiIncidentList,
    (groupedIncident) => groupedIncident.id,
    (groupedIncident) =>
      groupedIncident.incidents.filter(({ ongoing }) => ongoing).length
  );

  const ongoingIncidentCountByMetricUuid = countBy(
    ruleList.data,
    (monitor) => monitor.config.metrics[0] ?? "",
    (monitor) => ongoingIncidentCountByMonitorUuid[monitor.metadata.uuid] || 0
  );

  return kpiListData.data.map((metric) => {
    return {
      metricData: metric,
      metricStatus: getMetricStatus(metric),
      metricType: getMetricTypeFromConfigData(metric),
      metricCreationType: metricCreationTypeLabel(metric),
      dataSourceNames: metricDataSourceUuids(metric)
        .map((sourceUuid) => dataSourcesByUuid[sourceUuid]?.metadata.name)
        .sort(),
      monitorCount: monitorCounts[metric.metadata.uuid] ?? 0,
      onGoingIncidentCount: ongoingIncidentCountByMetricUuid[metric.metadata.uuid] ?? 0,
      displayQueryScope: getMetricQueryScopeLabel(metric),
      displaySliceByColumns: getSliceByColumns(metric, { checkRowByRow: true }),
      displaySchemaName: displaySchemaName(metric),
      displayTableName: displayTableName(metric),
      displayColumnName: displayColumnName(metric),
    };
  });
}

function getRowStats(rows) {
  return collectStats(rows, {
    Total: (_row) => 1,
    Monitored: (row) => (row.monitorCount > 0 ? 1 : 0),
    "Active incidents": (row) => row.onGoingIncidentCount,
    Live: (row) =>
      [MetricStatus.EXCEPTION, MetricStatus.TIMEOUT, MetricStatus.OK].includes(
        row.metricStatus
      )
        ? 1
        : 0,
    Paused: (row) =>
      [
        MetricStatus.PAUSED_BY_USER,
        MetricStatus.PAUSED_SOURCE,
        MetricStatus.PAUSED_TIMEOUTS,
        MetricStatus.PAUSED_EXCESS_UNIQUE_CATEGORIES,
        MetricStatus.PAUSED_EXCESS_UNIQUE_SLICES,
        MetricStatus.PAUSED_FULL_COMPARE_DATA_GREATER_THAN_ALLOWED,
      ].includes(row.metricStatus)
        ? 1
        : 0,
    Error: (row) => (row.metricStatus === MetricStatus.PAUSED_EXCEPTION ? 1 : 0),
  });
}

function MetricsList(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    history,
    workspaceUserPermissions,
    kpiListData,
    kpiListPageConfiguration,
    kpiIncidentList,
    dataSourceList,
    ruleList,
    tagList,
    getDataSourceList,
    getKpiList,
    getRuleList,
    getTagList,
    getKpiIncidentList,
    getKpiListPageConfiguration,
    getIntegrationList,
    updateKpiListPageConfiguration,
    toggleKpisLiveStatus,
    triggerKpis,
    deleteKpis,
    updateKpiTags,
    workspaceList,
    integrationList,
    addKpi,
  } = props;

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.METRIC,
    metrics: kpiListData.data,
    dataSources: dataSourceList.data,
  });

  const canModifyMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
  ]);

  const currentWorkspace = workspaceList?.find(({ uuid }) => uuid === workspaceUuid);

  const { canCreateDraft = false, alertChannels: defaultNotificationChannels = null } =
    getApprovalConfig(currentWorkspace, props.workspaceUserPermissions) ?? {};

  const canViewMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
  ]);

  const canAddMetric =
    hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
    ]) || canCreateDraft;

  const canModifyMetricColumnList = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACECONFIGURATIONSPAGEVIEW,
  ]);

  const canAddDataSource = hasPermission(
    workspaceUserPermissions,
    URIPathPermissions[URIPath.ADD_DATA_SOURCE]
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalTargets, setDeleteModalTargets] = useState([]);
  const [addDatasourceModalOpen, setAddDataSourceModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [queryPeriod] = useState(getTimePeriodFromString("1h"));
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [metricToDeleteApproval, setMetricToDeleteApproval] = useState(null);

  useEffect(() => {
    getDataSourceList(workspaceUuid);
    getKpiList(workspaceUuid);
    getRuleList(workspaceUuid);
    getTagList(workspaceUuid);
    getKpiListPageConfiguration(workspaceUuid);
    getKpiIncidentList(workspaceUuid, {
      startTime: queryPeriod.startTimestamp,
      endTime: queryPeriod.endTimestamp,
    });
    getIntegrationList(workspaceUuid);
  }, [
    workspaceUuid,
    queryPeriod,
    getDataSourceList,
    getKpiList,
    getKpiListPageConfiguration,
    getKpiIncidentList,
    getRuleList,
    getTagList,
    getIntegrationList,
  ]);

  const tableRows = useMemo(() => {
    return getTableRows(dataSourceList, ruleList, kpiListData, kpiIncidentList);
  }, [dataSourceList, ruleList, kpiListData, kpiIncidentList]);
  const [isTagEditing, setIsTagEditing] = useState(false);
  const loading = kpiListData.loading || requestInProgress;
  const metricActionsSharedMenuProps = {
    workspaceUuid,
    workspaceUserPermissions,
    history: history,
    onToggleMetricLiveStatusClick: (workspaceUuid, metrics, newIsLive) =>
      toggleKpisLiveStatus(workspaceUuid, metrics, newIsLive),
    onDeleteMetricClick: deleteMetrics,
    onTriggerMetricClick: triggerKpis,
    loading: false,
  };

  // We need to keep the selectedRows content in sync with the tableRows
  useEffect(() => {
    if (selectedRows.length > 0) {
      const refreshedSelectedRows = selectedRows.map((currentRow) => {
        const newTableRowValue = tableRows.find(
          (metric) =>
            metric?.metricData?.metadata?.uuid ===
            currentRow?.metricData?.metadata?.uuid
        );

        return newTableRowValue ?? currentRow;
      });

      setSelectedRows(refreshedSelectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows]);

  const columns = [
    {
      title: "ID",
      key: ListPageColumnKey.METRIC_ID,
      dataIndex: ["metricData", "metadata", "idSerial"],
      defaultSortOrder: "descend",
      sorter: { compare: fnSorter((row) => row.metricData.metadata.idSerial) },
      width: 60,
      fixed: "left",
    },
    {
      title: "Name",
      key: ListPageColumnKey.METRIC_NAME,
      dataIndex: ["metricData", "metadata", "name"],
      sorter: {
        compare: fnSorter((row) => row.metricData.metadata.name.toLowerCase()),
      },
      render: (name, { metricData }) => {
        if (!canViewMetric) {
          return name;
        }
        const trigger = <NgTableClickableText>{name}</NgTableClickableText>;
        const metricTargets =
          selectedRows.length > 0
            ? selectedRows.map((row) => row.metricData)
            : [metricData];

        let hideClone = false;
        const isTableTypeVirtual = isRelatedToVirtualTable(metricData);
        const isRowByRowMetric =
          getMetricTypeFromConfigData(metricData) === MetricCategory.FULL_COMPARE;

        if (isTableTypeVirtual && !isRowByRowMetric) {
          hideClone = true;
        }
        const actionsToFilter = [
          MetricActions.STATUS_CHANGE,
          MetricActions.DISABLE,
          MetricActions.VIEW_QUERY_HISTORY,
        ];
        hideClone && actionsToFilter.push(MetricActions.CLONE);
        let finalMetricActions = Object.values(MetricActions);
        finalMetricActions = finalMetricActions.filter(
          (action) => !actionsToFilter.includes(action)
        );

        return (
          <ProfilerMetricActionsMenu
            trigger={trigger}
            actions={finalMetricActions}
            metrics={metricTargets}
            {...metricActionsSharedMenuProps}
          />
        );
      },
      width: 160,
      fixed: "left",
    },
  ];

  const configurableColumns = [
    {
      title: "Status",
      key: ListPageColumnKey.METRIC_STATUS,
      dataIndex: ["metricStatus"],
      sorter: { compare: fnSorter((row) => row.metricStatus) },
      render: (metricStatus, { metricData }) => {
        const trigger = (
          <MetricStatusBadge metric={metricData} clickable={canModifyMetric} />
        );

        const metricTargets =
          selectedRows.length > 0
            ? selectedRows.map((row) => row.metricData)
            : [metricData];

        return (
          <ProfilerMetricActionsMenu
            trigger={trigger}
            actions={[MetricActions.STATUS_CHANGE]}
            metrics={metricTargets}
            {...metricActionsSharedMenuProps}
          />
        );
      },
      width: 90,
    },
    metricTypeColumn({ dataIndex: ["metricType"] }),
    {
      title: "Query scope",
      key: ListPageColumnKey.METRIC_QUERY_SCOPE,
      dataIndex: ["displayQueryScope"],
      sorter: {
        compare: fnSorter((row) => row.displayQueryScope),
      },
      width: 130,
    },
    {
      title: "Schedule",
      key: ListPageColumnKey.METRIC_SCHEDULE,
      dataIndex: ["metricData", "config", "collectionMode", "type"],
      sorter: {
        compare: fnSorter((row) =>
          getCollectionModeTypeDisplayName(row.metricData.config.collectionMode.type)
        ),
      },
      render: (collectionModeType) =>
        getCollectionModeTypeDisplayName(collectionModeType),
      width: 130,
    },
    dataSourceColumn({
      dataIndex: ["dataSourceNames"],
    }),
    schemaColumn({
      dataIndex: ["displaySchemaName"],
    }),
    tableColumn({
      dataIndex: ["displayTableName"],
    }),
    {
      title: "Column",
      key: ListPageColumnKey.COLUMN_NAME,
      dataIndex: ["displayColumnName"],
      sorter: {
        compare: fnSorter((row) => row.displayColumnName.toLowerCase()),
      },
      render: (columnName) => {
        return <NgTextTooltip>{columnName}</NgTextTooltip>;
      },
      width: 160,
    },
    {
      title: "Sliced By",
      key: ListPageColumnKey.SLICE_COLUMN,
      dataIndex: ["displaySliceByColumns"],
      render: (displaySliceByColumns) => {
        if (displaySliceByColumns.length === 0) {
          return "Not sliced";
        }

        const thresholdNumber = 2;
        if (displaySliceByColumns.length <= thresholdNumber) {
          return displaySliceByColumns.join(", ");
        }

        const tooltipTitle = (
          <div className="metric-list-slice-by-column-tooltip-container">
            {displaySliceByColumns.map((displaySliceByColumn) => (
              <div key={displaySliceByColumn}>{displaySliceByColumn}</div>
            ))}
          </div>
        );

        return (
          <Tooltip title={tooltipTitle} placement="bottom" trigger="click">
            <span className="metric-list-slice-by-column-cell-container">
              {[
                ...displaySliceByColumns.slice(0, thresholdNumber),
                `+${displaySliceByColumns.length - thresholdNumber}`,
              ].join(", ")}
            </span>
          </Tooltip>
        );
      },
      sorter: { compare: fnSorter((row) => row.displaySliceByColumns.join("")) },
      width: 160,
    },
    {
      title: "Monitors",
      key: ListPageColumnKey.MONITOR_COUNT,
      dataIndex: ["monitorCount"],
      sorter: { compare: fnSorter((row) => row.monitorCount) },
      width: 110,
    },
    {
      title: "Date modified",
      key: ListPageColumnKey.UPDATED_AT,
      dataIndex: ["metricData", "status", "configUpdatedTs"],
      render: (configUpdatedTs) =>
        configUpdatedTs ? getStringFromTimeStamp(configUpdatedTs) : "",
      sorter: {
        compare: fnSorter((row) => row.metricData.status?.configUpdatedTs ?? 0),
      },
      width: 160,
    },
    modifiedByColumn({
      dataIndex: ["metricData", "metadata", "updatedBy", "username"],
    }),
    createdByColumn({
      dataIndex: ["metricData", "metadata", "ownedBy", "username"],
    }),
    dimensionColumn({
      dataIndex: ["metricData", "config", "dimension"],
    }),
    tagsColumn({
      dataIndex: ["metricData", "metadata", "tags"],
      renderProps: {
        editEnabled: canModifyMetric,
        disabled: isTagEditing,
        tagList,
        selectedRows,
        onChange: (_newTags, changeObject, row) => {
          setIsTagEditing(true);
          const targetRows = selectedRows.length > 0 ? selectedRows : [row];
          Promise.all(
            targetRows.map((selectedRow) => {
              return updateKpiTags(
                workspaceUuid,
                selectedRow.metricData,
                getUpdatedTags(selectedRow.metricData.metadata.tags, changeObject)
              );
            })
          ).finally(() => {
            setIsTagEditing(false);
          });
        },
      },
    }),
    createdOnColumn({
      dataIndex: ["metricData", "status", "createdTs"],
    }),
    modifiedAtColumn({
      dataIndex: ["metricData", "status", "configUpdatedTs"],
    }),
    metricCreationTypeColumn({
      dataIndex: ["metricCreationType"],
    }),
  ];

  function onColumnKeyListChange(columns) {
    updateKpiListPageConfiguration(workspaceUuid, {
      ...kpiListPageConfiguration,
      columns,
    });
  }

  function deleteMetrics(metrics) {
    if (canCreateDraft && metrics.length === 1 && !isAutoMetric(metrics[0])) {
      setMetricToDeleteApproval(metrics[0]);
      return;
    }
    setDeleteModalTargets(canCreateDraft ? metrics.filter(isAutoMetric) : metrics);
    setDeleteModalOpen(true);
    setSelectedRows([]);
  }

  function createDeleteDraft(draftMetricToDelete) {
    const deleteDraftMetric = deepcopy(draftMetricToDelete);
    deleteDraftMetric.draftMetadata = {
      ...deleteDraftMetric.draftMetadata,
      state: DraftState.APPROVAL_PENDING,
      type: DraftType.DELETE,
      targetUuid: metricToDeleteApproval.metadata.uuid,
    };
    deleteDraftMetric.metadata.uuid = null;
    deleteDraftMetric.mode = MetricMode.DRAFT;

    setRequestInProgress(true);
    addKpi(workspaceUuid, deleteDraftMetric).finally(() => {
      setRequestInProgress(false);
      setMetricToDeleteApproval(null);
    });
  }

  function onDeleteConfirmed() {
    trackEvent(EVENT.DELETE_METRIC, {
      workspace_id: workspaceUuid,
      page: PAGE.METRICS,
    });

    if (deleteModalTargets.length > 0) {
      setRequestInProgress(true);
      deleteKpis(workspaceUuid, deleteModalTargets)
        .then(() => getKpiList(workspaceUuid, { isForceRefresh: true }))
        .finally(() => setRequestInProgress(false));
    }
  }

  function onAdd() {
    trackEvent(EVENT.CREATE_METRIC, {
      workspace_id: workspaceUuid,
      page: PAGE.METRICS,
    });

    if (dataSourceList.data.length === 0) {
      setAddDataSourceModalOpen(true);
    } else {
      const nextUrlPath = getURIInstance(URIPath.ADD_METRIC, { workspaceUuid });
      const nextUrl = `${nextUrlPath}?returnTo=metricList`;
      history.push(nextUrl);
    }
  }

  const columnKeyOptions = canModifyMetricColumnList
    ? [
        { label: "Status", value: ListPageColumnKey.METRIC_STATUS },
        { label: "Type", value: ListPageColumnKey.METRIC_TYPE },
        { label: "Query scope", value: ListPageColumnKey.METRIC_QUERY_SCOPE },
        { label: "Schedule", value: ListPageColumnKey.METRIC_SCHEDULE },
        { label: "Datasource", value: ListPageColumnKey.DATASOURCE },
        { label: "Schema", value: ListPageColumnKey.SCHEMA },
        { label: "Table", value: ListPageColumnKey.TABLE },
        { label: "Column", value: ListPageColumnKey.COLUMN_NAME },
        { label: "Sliced by", value: ListPageColumnKey.SLICE_COLUMN },
        { label: "Monitors", value: ListPageColumnKey.MONITOR_COUNT },
        { label: "Dimension", value: ListPageColumnKey.DIMENSION },
        { label: "Created by", value: ListPageColumnKey.CREATED_BY },
        { label: "Created on", value: ListPageColumnKey.CREATED_AT },
        { label: "Last modified by", value: ListPageColumnKey.UPDATED_BY },
        { label: "Last modified", value: ListPageColumnKey.UPDATED_AT },
        { label: "Class", value: ListPageColumnKey.METRIC_CREATION_TYPE },
        { label: "Tags", value: ListPageColumnKey.TAGS },
      ]
    : [];

  return (
    <div className="metric-list">
      <EntityList
        addText="Create Metric"
        showAdd={canAddMetric}
        searchOptions={searchOptions}
        columns={columns}
        configurableColumns={configurableColumns}
        rows={tableRows}
        getRowKey={(row) => row.metricData.metadata.uuid}
        loading={loading}
        tableProps={{ scroll: { x: 1168 } }}
        getFilteredRows={filterRows}
        getRowStats={getRowStats}
        onAdd={onAdd}
        onSelectedRowsChange={setSelectedRows}
        columnKeyList={kpiListPageConfiguration?.columns || []}
        onColumnKeyListChange={onColumnKeyListChange}
        columnKeyOptions={columnKeyOptions}
      />
      <ProfilerConfirmationDialog
        modalIsOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        okClicked={onDeleteConfirmed}
        usage={{
          loading: false,
          data: {},
        }}
        title="Delete metrics"
        defaultConfirmationMsg={`You are about to delete ${deleteModalTargets.length} metrics and associated monitors. This is not reversible.`}
      />
      <ConfirmationDialog
        modalIsOpen={addDatasourceModalOpen}
        setModalIsOpen={setAddDataSourceModalOpen}
        title="No datasources"
        confirmationMsg="You do not have any datasources. Create a datasource in order to be able to create a metric."
        cancelText="Close"
        okText={canAddDataSource ? "Add a datasource" : "OK"}
        showCancelButton={canAddDataSource}
        okClicked={() => {
          if (!canAddDataSource) {
            return;
          }

          const nextUrl = getURIInstance(URIPath.ADD_DATA_SOURCE, { workspaceUuid });
          history.push(nextUrl);
        }}
      />
      {Boolean(metricToDeleteApproval) && (
        <DraftMetricSendApprovalModal
          title="Send Metric for Deletion"
          visible={Boolean(metricToDeleteApproval)}
          configData={metricToDeleteApproval}
          onConfirm={createDeleteDraft}
          onCancel={() => setMetricToDeleteApproval(null)}
          integrationList={integrationList}
          tagList={tagList}
          isInProgress={requestInProgress}
          defaultNotificationChannels={defaultNotificationChannels}
        />
      )}
    </div>
  );
}

export default withRouter(MetricsList);
