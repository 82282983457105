import React, { useMemo } from "react";
import { connect } from "react-redux";

import { SettingOutlined } from "@ant-design/icons";

import NgButton, { TextWithIcon } from "../../../components/button/ng-button";
import { aggregationWindowOptions } from "../../../components/metric/utils";

import {
  DbColumTypeOptions,
  TimezoneOptions,
  evalDelayOptions,
  queryScopeOptions,
  collectionWindowOptions,
  getDataCollectionOptions,
} from "../../../utils/options";
import { hasPermission } from "../../../utils/uri-path";
import { AppPermissions } from "../../../utils/permissions";
import { isPartitionConfigEnabled } from "../../../utils/datasource";
import {
  isFeatureEnabled,
  isTimestampTimezoneConfigEnabled,
} from "../../../utils/general";
import {
  QueryScope,
  isFileSource as getIsFileSource,
  SupportedFeature,
  CollectionModeType,
} from "../../../utils/enums";
import { TimeUnitInSeconds, largestWholeTimeUnitFactor } from "../../../utils/time";

import { getProfilerTreeData } from "../../../actions/profiler/profiler-action";

import { StampIcon } from "../../../components/icons/stamp-icon";
import { GlobeIcon } from "../../../components/icons/globe-icon";
import { KeyboardIcon } from "../../../components/icons/keyboard-icon";
import { HourglassIcon } from "../../../components/icons/hourglass-icon";
import { OffsetTimeIcon } from "../../../components/icons/offset-time-icon";
import { CycleArrowIcon } from "../../../components/icons/cycle-arrow-icon";
import { DividingLineIcon } from "../../../components/icons/dividing-line-icon";
import { DataSwitchingIcon } from "../../../components/icons/data-switching-icon";
import { UnderlineEditicon } from "../../../components/icons/underline-edit-icon";
import { ProfilerConfigureTableIcon } from "../../../components/icons/profiler-configure-table-icon";
import { StopWatchIcon } from "../../../components/icons/stop-watch/";
import ProfilerEnableView from "../profiler-enable-view";
import { Spinner } from "../../../atom/spinner";
import Divider from "../../../atom/divider";

import "./profiler-data-source-table-configure-tab.scss";

const fullTableWindowOptions = [
  { value: "day", label: "daily" },
  { value: "week", label: "weekly" },
];

const TableConfigItem = ({ icon, label, value }) => {
  return (
    <div className="table-configure-tab-content-row-item">
      <div className="table-configure-tab-content-row-item-label">{label}</div>
      <TextWithIcon icon={icon} iconPosition="left">
        {value}
      </TextWithIcon>
    </div>
  );
};

const getDuration = (value) => {
  const evalDelayTimeUnits = evalDelayOptions.map(({ value }) => value);
  const timeUnit = largestWholeTimeUnitFactor(value, evalDelayTimeUnits);

  const quantity =
    value / TimeUnitInSeconds[largestWholeTimeUnitFactor(value, evalDelayTimeUnits)];

  const selectedOption =
    evalDelayOptions.find((opt) => opt.value === timeUnit).label ?? "";

  return `${quantity} ${selectedOption}`;
};

function ProfilerDataSourceTableConfigureTab(props) {
  const {
    waffle,
    config,
    openTableConfig,
    tableColumnList,
    profilerTreeData,
    workspaceUserPermissions,
  } = props;

  const { table, dataSource } = config;
  const { profilerConfig } = table;

  const canEdit = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ]);

  const dataSourceType = dataSource?.config?.connection?.type ?? "";

  const isFileSource = getIsFileSource(dataSourceType);
  const isPartitionEnabled = isPartitionConfigEnabled(dataSourceType);

  const {
    window: aggregationWindow,
    timezone,
    syncDelay,
    queryScope,
    partitions,
    dataTimezone,
    collectionMode,
    timestampColumn,
    timestampColumnType,
    collectionWindow,
    partitionOffsets,
    partitionTimezone,
    pollingWindow,
    pollingTimezone,
    pollingDelay,
  } = profilerConfig;

  const isTimeRangeConfigMode = queryScope === QueryScope.TIME_RANGE;
  const { includeFutureSeconds = 0, includePastSeconds = 0 } = partitionOffsets;

  const isTableConfigured = table.profilerConfig?.enabled;
  const shouldShowPartitions =
    isPartitionEnabled && isTimeRangeConfigMode && partitions;

  const pollingIntervalOptions = useMemo(() => {
    return isFeatureEnabled(waffle, SupportedFeature.FULL_TABLE_METRIC_HOURLY)
      ? aggregationWindowOptions
      : fullTableWindowOptions;
  }, [waffle]);

  const queryScopeValue = (
    isFileSource
      ? queryScopeOptions.filter(({ value }) => value === QueryScope.TIME_RANGE)
      : queryScopeOptions
  ).find((item) => item.value === queryScope)?.label;

  const dataCollectionScheduleValue = getDataCollectionOptions({
    isBlobStorage: isFileSource,
  }).find(({ value }) => value === collectionMode?.type)?.label;

  const dataCollectionWindowValue = collectionWindowOptions.find(
    ({ value }) => value === collectionWindow
  )?.label;

  const aggregationIntervalValue = aggregationWindowOptions.find(
    ({ value }) => value === aggregationWindow
  )?.label;

  const aggregationTimezoneValue = (
    isFileSource
      ? TimezoneOptions.filter(({ value }) => value === "UTC")
      : TimezoneOptions
  ).find(({ value }) => value === timezone)?.label;

  const customScheduleTimezoneValue = TimezoneOptions.find(
    ({ value }) => value === collectionMode?.timezone
  )?.label;

  const timestampColumnInfo = tableColumnList?.data?.find(
    ({ columnName }) => timestampColumn === columnName
  );

  const isPartitionColumnUsedAsTimestampColumn =
    partitions.length === 1 && partitions[0].columnName === timestampColumn;

  const allowDataTimezoneConfig =
    isTimestampTimezoneConfigEnabled(timestampColumnInfo?.columnType) &&
    !isPartitionColumnUsedAsTimestampColumn;

  const dataTimezoneValue = allowDataTimezoneConfig
    ? (isFileSource
        ? TimezoneOptions.filter(({ value }) => value === "UTC")
        : TimezoneOptions
      ).find(({ value }) => value === dataTimezone)?.label
    : "Derived";

  const pollingIntervalValue = pollingIntervalOptions.find(
    ({ value }) => value === pollingWindow
  )?.label;

  const pollingTimezoneValue = TimezoneOptions.find(
    ({ value }) => value === pollingTimezone
  )?.label;

  const isFullTable = queryScope === QueryScope.FULL_TABLE;

  const shouldShowPollingInterval =
    isFullTable &&
    [CollectionModeType.CUSTOM_SCHEDULED, CollectionModeType.SCHEDULED].includes(
      collectionMode?.type
    );

  const shouldShowDataCollectionWindow = !isFullTable && dataCollectionWindowValue;

  const shouldShowAggegationDetail = !isFullTable && isTimeRangeConfigMode;
  const shouldShowCustomScheduleDetail =
    collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED;

  const isLoadingNewData = profilerTreeData.softLoading;

  if (isLoadingNewData) {
    return (
      <div className="table-configure-tab-loading-spinner">
        <Spinner size="large" />
        <div>Load in progress</div>
      </div>
    );
  }

  if (!isTableConfigured) {
    return (
      <ProfilerEnableView
        gridOnly
        fullHeight
        icon={<ProfilerConfigureTableIcon />}
        description="Configure your table to access configuration details"
        buttonContent={
          canEdit ? (
            <TextWithIcon icon={<SettingOutlined />}>Configure Table</TextWithIcon>
          ) : null
        }
        onClick={openTableConfig}
      />
    );
  }

  return (
    <div className="table-configure-tab">
      <div className="table-configure-tab-header">
        <div className="table-configure-tab-header-title">Table Configuration</div>

        {canEdit && (
          <NgButton outline onClick={openTableConfig}>
            <TextWithIcon icon={<UnderlineEditicon />}>Edit</TextWithIcon>
          </NgButton>
        )}
      </div>

      <div className="table-configure-tab-content">
        <div className="table-configure-tab-content-row">
          <TableConfigItem
            icon={<KeyboardIcon />}
            label="Query Scope"
            value={queryScopeValue}
          />

          <TableConfigItem
            icon={<DataSwitchingIcon />}
            label="Data Collection Schedule"
            value={dataCollectionScheduleValue}
          />

          {shouldShowDataCollectionWindow && (
            <TableConfigItem
              icon={<></>}
              label="Data Collection Window"
              value={dataCollectionWindowValue}
            />
          )}

          {/* Compensates spacing for missing Aggregation Window Option in UI */}
          {!shouldShowDataCollectionWindow && (
            <div className="table-configure-tab-content-row-item" />
          )}
        </div>

        {shouldShowCustomScheduleDetail && (
          <div className="table-configure-tab-content-row">
            <TableConfigItem
              icon={<></>}
              label="Cron Input"
              value={collectionMode?.crontabExpression}
            />

            <TableConfigItem
              icon={<></>}
              label="Cron Timezone"
              value={customScheduleTimezoneValue}
            />

            <div className="table-configure-tab-content-row-item" />
          </div>
        )}

        {shouldShowAggegationDetail && (
          <>
            <div className="table-configure-tab-content-row">
              <TableConfigItem
                icon={<CycleArrowIcon />}
                label="Aggregation Interval"
                value={aggregationIntervalValue}
              />

              <TableConfigItem
                icon={<GlobeIcon />}
                label="Aggregation Timezone"
                value={aggregationTimezoneValue}
              />

              <TableConfigItem
                icon={<HourglassIcon />}
                label="Evaluation Delay"
                value={getDuration(syncDelay || 0)}
              />
            </div>

            <div className="table-configure-tab-content-row">
              <TableConfigItem
                icon={<StampIcon />}
                label="Timestamp"
                value={timestampColumn}
              />
              {table.isUserDefined && (
                <TableConfigItem
                  icon={<StopWatchIcon />}
                  label="Timestamp Column Type"
                  value={
                    DbColumTypeOptions.find(
                      ({ value }) => value === timestampColumnType
                    )?.label || ""
                  }
                />
              )}
              <TableConfigItem
                icon={<GlobeIcon />}
                label="Timezone"
                value={dataTimezoneValue}
              />
              {!table.isUserDefined && (
                <div className="table-configure-tab-content-row-item" />
              )}
            </div>
          </>
        )}

        {shouldShowPollingInterval && (
          <div className="table-configure-tab-content-row">
            {collectionMode.type !== CollectionModeType.CUSTOM_SCHEDULED && (
              <>
                <TableConfigItem
                  icon={<CycleArrowIcon />}
                  label="Polling Interval"
                  value={pollingIntervalValue}
                />

                <TableConfigItem
                  icon={<GlobeIcon />}
                  label="Polling Timezone"
                  value={pollingTimezoneValue}
                />
              </>
            )}

            <TableConfigItem
              icon={<HourglassIcon />}
              label="Polling Delay"
              value={getDuration(pollingDelay || 0)}
            />
          </div>
        )}

        {shouldShowPartitions && (
          <>
            <Divider />

            {partitions.map((currentPartition, index) => {
              const { columnName = "", format = "" } = currentPartition;

              return (
                <div
                  key={currentPartition?.id ?? index}
                  className="table-configure-tab-content-row"
                >
                  <TableConfigItem
                    icon={<DividingLineIcon />}
                    label={`Partition ${index + 1}`}
                    value={`${columnName} - ${format}`}
                  />

                  <TableConfigItem
                    icon={<OffsetTimeIcon />}
                    label="Partition Offset Past"
                    value={getDuration(includePastSeconds)}
                  />

                  <TableConfigItem
                    icon={<OffsetTimeIcon />}
                    label="Partition Offset Future"
                    value={getDuration(includeFutureSeconds)}
                  />
                  <TableConfigItem
                    icon={<GlobeIcon />}
                    label={`Partition Timezone`}
                    value={partitionTimezone}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profilerTreeData: state.profilerReducer.profilerTreeData,
});

const mapDispatchToProps = (dispatch) => ({
  getProfilerTreeData: (workspaceUuid) => dispatch(getProfilerTreeData(workspaceUuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilerDataSourceTableConfigureTab);
