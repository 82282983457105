import React, { useState, useEffect } from "react";
import JiraIntegrationBasicDialog, {
  canAttemptAuthentication,
  getJiraAuthFields,
} from "./jira-integration-basic-dialog";
import {
  getReconciledEditMetadata,
  isBranch,
  isMetadata,
} from "../integration-metadata";
import { AlertingChannelType } from "../../../utils/alerting";
import { useOutsideClickConfirm } from "../../../hooks/useOutsideClickConfirm";
import { getIntegrationFormSaveStatus } from "../integration-metadata-editor";

function EditJiraIntegrationDialog(props) {
  const {
    data,
    workspaceUuid,
    currentMetadata,
    onEdit,
    onPreview,
    modalIsOpen,
    setIsOpen,
    outsideClickWrap,
    enableSave: propsEnableSave,
    enablePreview,
    getIntegrationMetadata,
    resetIntegrationMetadata,
  } = props;

  const [value, setValue] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [lastAuthAttemptData, setLastAuthAttemptData] = useState(null);
  const [lastSuccessfulAuthAttemptData, setLastSuccessfulAuthAttemptData] = useState(
    data.metadata ? getJiraAuthFields(data) : null
  );

  useEffect(() => {
    resetIntegrationMetadata();
  }, [resetIntegrationMetadata]);

  const reconciledMetadata = getReconciledEditMetadata(
    AlertingChannelType.JIRA,
    value,
    currentMetadata
  );
  const reconciledValue = { ...value, metadata: reconciledMetadata };

  useOutsideClickConfirm({
    outsideClickWrap,
    close: () => setIsOpen(false),
    originalValue: data,
    currentValue: reconciledValue,
  });

  const onOkClicked = (newData) => {
    onEdit(newData);
  };

  const onAuthenticate = (newData) => {
    setLastAuthAttemptData(getJiraAuthFields(newData));
    setIsLoading(true);
    getIntegrationMetadata(workspaceUuid, newData)
      .then((metadata) => {
        setLastSuccessfulAuthAttemptData(metadata ? getJiraAuthFields(newData) : {});
      })
      .finally(() => setIsLoading(false));
  };

  const { enableSave, authButtonStatus } = getIntegrationFormSaveStatus({
    isEdit: true,
    lastAuthAttemptData,
    lastSuccessfulAuthAttemptData,
    currentAuthFields: getJiraAuthFields(reconciledValue),
    canAttemptAuth: canAttemptAuthentication(reconciledValue),
    fieldsConfigured:
      isMetadata(reconciledValue.metadata) && isBranch(reconciledValue.metadata),
    isLoading,
  });

  return (
    <JiraIntegrationBasicDialog
      value={reconciledValue}
      currentMetadata={currentMetadata}
      className={"edit-jira-integration-dialog-container"}
      dialogTitle={"Edit Jira Integration"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enableSave={propsEnableSave && enableSave}
      authButtonStatus={authButtonStatus}
      enablePreview={enablePreview}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      onChange={setValue}
      onAuthenticate={onAuthenticate}
      workspaceUuid={workspaceUuid}
    />
  );
}

export default EditJiraIntegrationDialog;
