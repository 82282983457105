import React, { useState } from "react";
import { Radio, Space } from "antd";
import { getOptionByValue } from "../../utils/options";
import {
  DAY_IN_SECONDS,
  getStringFromTimeStamp,
  getTimeRangeFromScheduleConfig,
  getTimeRangeFromWeekdays,
  getWeekDaysFromTimeRange,
} from "../../utils/time";
import { ScheduleRecurringType, ScheduleType } from "../../utils/enums";
import moment from "moment";
import LegacyModalDialogAdapter from "../../components/legacy-adapter/legacy-modal-dialog-adapter";
import ModalConfirmationButtons from "../../components/modal-dialog/modal-confirmation-buttons";
import {
  LabeledDatePicker,
  LabeledInput,
  LabeledInputNumber,
  LabeledSelect,
  LabeledTimePicker,
} from "../../components/labeled-control/labeled-control";
import { ModalFieldRows } from "../../components/modal-dialog/modal-field-group";
import { TriggerButton } from "../../components/button/ng-button";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ButtonIcon from "../../components/button/button-icon";
import { getUnixTime } from "date-fns";
import ToggleButton from "../../components/toggle-button";

import "./schedule-basic-dialog.scss";

const scheduleTypeOptions = [
  { label: "Recurring Event", value: ScheduleType.RECURRING },
  { label: "Custom Event Series", value: ScheduleType.STATIC },
];

const scheduleRecurringTypeOptions = [
  { label: "Hour", value: ScheduleRecurringType.HOURLY },
  { label: "Day", value: ScheduleRecurringType.DAILY },
  { label: "Week", value: ScheduleRecurringType.WEEKLY },
];

function getDefaultTimeRange() {
  const currentTimestamp = moment().unix();
  return {
    startTimestamp: currentTimestamp,
    endTimestamp: currentTimestamp,
  };
}

function ScheduleCustomTimeRangesSetting(props) {
  const { timeRanges, onTimeRangesChange } = props;
  function onAddTimeRange() {
    timeRanges.push(getDefaultTimeRange());
    onTimeRangesChange && onTimeRangesChange([...timeRanges]);
  }

  function onDeleteTimeRange(index) {
    timeRanges.splice(index, 1);
    onTimeRangesChange && onTimeRangesChange([...timeRanges]);
  }

  function onEditTimeRange(index, newTimeRange) {
    timeRanges[index] = newTimeRange;
    onTimeRangesChange && onTimeRangesChange([...timeRanges]);
  }

  return (
    <div>
      <div className="schedule-custom-time-ranges-summary-container">
        <span className="schedule-custom-time-range-summary-total-number-container">
          Series ({timeRanges.length})
        </span>
        <TriggerButton onClick={onAddTimeRange}>
          <PlusOutlined />
        </TriggerButton>
      </div>
      <div className="schedule-custom-time-range-list-container">
        {timeRanges.map((timeRange, index) => {
          const { startTimestamp, endTimestamp } = timeRange;
          return (
            <div className="schedule-custom-time-range-item-wrapper" key={index}>
              <div className="schedule-custom-time-range-item-container">
                <div className="schedule-custom-time-range-item-date">
                  <LabeledDatePicker
                    label="Start"
                    showTime
                    value={moment.unix(startTimestamp)}
                    allowClear={false}
                    onChange={(newStartTime) => {
                      const newStartTimestamp = getUnixTime(newStartTime);
                      if (newStartTimestamp !== startTimestamp) {
                        onEditTimeRange(index, {
                          startTimestamp: newStartTimestamp,
                          endTimestamp,
                        });
                      }
                    }}
                  />
                </div>
                <div className="schedule-custom-time-range-item-date">
                  <LabeledDatePicker
                    label="End"
                    showTime
                    value={moment.unix(endTimestamp)}
                    allowClear={false}
                    onChange={(newEndTime) => {
                      const newEndTimestamp = getUnixTime(newEndTime);
                      if (newEndTimestamp !== endTimestamp) {
                        onEditTimeRange(index, {
                          startTimestamp,
                          endTimestamp: newEndTimestamp,
                        });
                      }
                    }}
                  />
                </div>
                <ButtonIcon
                  icon={<DeleteOutlined />}
                  onClick={() => onDeleteTimeRange(index)}
                />
              </div>

              {!isValidTimeRange({ startTimestamp, endTimestamp }) && (
                <p className="schedule-custom-time-range-error-message ">
                  Start time cannot be after the end time
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const weekDayLabels = ["S", "M", "T", "W", "T", "F", "S"];

function ScheduleRecurringWeekDay(props) {
  const { timeRanges, onTimeRangesChange } = props;
  const weekDays = getWeekDaysFromTimeRange(timeRanges);

  function onWeekDayClick(index) {
    weekDays[index] = !weekDays[index];
    const newTimeRanges = getTimeRangeFromWeekdays(timeRanges, weekDays);
    onTimeRangesChange(newTimeRanges);
  }

  const enabledCount = weekDays.filter(Boolean).length;

  return (
    <div className="schedule-recurring-week-day-list-container">
      {weekDays.map((weekDayEnable, index) => {
        const disabled = enabledCount === 1 && weekDayEnable;
        const buttonProps = disabled
          ? {
              title: "You must enable at least one weekday",
            }
          : {};
        return (
          <ToggleButton
            disabled={disabled}
            active={weekDayEnable}
            key={index}
            buttonProps={buttonProps}
            onChange={onWeekDayClick.bind(this, index)}
          >
            {weekDayLabels[index]}
          </ToggleButton>
        );
      })}
    </div>
  );
}

function getNormalizedTimeRange(newTimeOnlyRange) {
  let { startTimestamp, endTimestamp } = newTimeOnlyRange;
  if (endTimestamp < startTimestamp) {
    endTimestamp = endTimestamp + DAY_IN_SECONDS;
  } else if (endTimestamp - startTimestamp > DAY_IN_SECONDS) {
    endTimestamp = endTimestamp - DAY_IN_SECONDS;
  }

  return {
    startTimestamp,
    endTimestamp,
  };
}

function ScheduleRecurringSetting(props) {
  const {
    timeRanges,
    onTimeRangesChange,
    scheduleRecurringType,
    onScheduleRecurringTypeChange,
  } = props;

  const { startTimestamp: startTimestampOnly, endTimestamp: endTimestampOnly } =
    timeRanges[0];

  function onTimeOnlyChange(newTimeOnlyRange) {
    const { startTimestamp: newStartTimestamp, endTimestamp: newEndTimestamp } =
      getNormalizedTimeRange(newTimeOnlyRange);
    const { startTimestamp: oldStartTimestamp, endTimestamp: oldEndTimestamp } =
      timeRanges[0];
    const startTimestampDiff = newStartTimestamp - oldStartTimestamp;
    const endTimestampDiff = newEndTimestamp - oldEndTimestamp;
    const newTimeRanges = timeRanges.map(({ startTimestamp, endTimestamp }) => {
      return {
        startTimestamp: startTimestamp + startTimestampDiff,
        endTimestamp: endTimestamp + endTimestampDiff,
      };
    });
    onTimeRangesChange(newTimeRanges);
  }

  const format =
    scheduleRecurringType === ScheduleRecurringType.HOURLY ? "mm" : "hh:mm a";
  const minuteStep = scheduleRecurringType === ScheduleRecurringType.HOURLY ? 1 : 5;
  const use12Hours =
    scheduleRecurringType === ScheduleRecurringType.HOURLY ? false : true;
  const isPlusOneDay = moment
    .unix(endTimestampOnly)
    .isAfter(moment.unix(startTimestampOnly), "day");

  return (
    <div className="schedule-recurring-setting-container">
      <div className="schedule-recurring-setting-title-container">Event Settings</div>
      <LabeledSelect
        label="Recurring every"
        options={scheduleRecurringTypeOptions}
        value={getOptionByValue(scheduleRecurringTypeOptions, scheduleRecurringType)}
        onChange={onScheduleRecurringTypeChange}
      />
      <div className="schedule-recurring-setting-time-period">
        <div>
          <LabeledTimePicker
            label="Start time"
            staticLabel
            format={format}
            minuteStep={minuteStep}
            use12Hours={use12Hours}
            value={moment.unix(startTimestampOnly)}
            onChange={(newStartTime) => {
              const newStartTimestampOnly = getUnixTime(newStartTime);
              if (startTimestampOnly !== newStartTimestampOnly) {
                onTimeOnlyChange({
                  startTimestamp: newStartTimestampOnly,
                  endTimestamp: endTimestampOnly,
                });
              }
            }}
          />
          {scheduleRecurringType === ScheduleRecurringType.HOURLY && (
            <div className="schedule-recurring-setting-time-period-description-container">
              Minutes past the hour
            </div>
          )}
        </div>
        <div>
          <LabeledTimePicker
            label="End time"
            staticLabel
            format={format}
            minuteStep={minuteStep}
            use12Hours={use12Hours}
            value={moment.unix(endTimestampOnly)}
            onChange={(newEndTime) => {
              const newEndTimestampOnly = getUnixTime(newEndTime);
              if (endTimestampOnly !== newEndTimestampOnly) {
                onTimeOnlyChange({
                  startTimestamp: startTimestampOnly,
                  endTimestamp: newEndTimestampOnly,
                });
              }
            }}
          />
          {(scheduleRecurringType === ScheduleRecurringType.HOURLY || isPlusOneDay) && (
            <div className="schedule-recurring-setting-time-period-description-container">
              {scheduleRecurringType === ScheduleRecurringType.HOURLY
                ? "Minutes past the hour"
                : "+ 1 day"}
            </div>
          )}
        </div>
      </div>
      {scheduleRecurringType === ScheduleRecurringType.WEEKLY && (
        <>
          <div className="schedule-recurring-setting-subtitle">Repeats</div>
          <ScheduleRecurringWeekDay
            timeRanges={timeRanges}
            onTimeRangesChange={onTimeRangesChange}
          />
        </>
      )}
    </div>
  );
}

function ScheduleRepeatCountSetting(props) {
  const { value, onChange } = props;
  const [isNeverEnd, setIsNeverEnd] = useState(value === 0);

  function onIsNeverEndChange(e) {
    const newValue = e.target.value;
    setIsNeverEnd(newValue);
    if (newValue) {
      onChange(0);
    }
  }

  return (
    <div>
      <div className="schedule-recurring-setting-subtitle">
        When should the schedule end?
      </div>
      <div className="schedule-repeat-count-container">
        <Radio.Group onChange={onIsNeverEndChange} value={isNeverEnd}>
          <Space direction="vertical">
            <Radio value={true}>Never ends</Radio>
            <Radio value={false}>
              <div className="schedule-repeat-count-radio-occurrences-container">
                After
                <LabeledInputNumber
                  width={260}
                  disabled={isNeverEnd}
                  label="occurrences"
                  staticLabel
                  value={value}
                  onChange={onChange}
                  max={10000} // max occurrences from product team
                />
              </div>
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
}

function ScheduleRecurringSummary(props) {
  const { timeRanges = [], repeatCount, scheduleType, scheduleRecurringType } = props;
  if (scheduleType !== ScheduleType.RECURRING || timeRanges.length === 0) {
    return null;
  }

  const timeRangeInfo = getTimeRangeFromScheduleConfig(
    timeRanges,
    scheduleRecurringType,
    repeatCount
  );
  if (!timeRangeInfo) {
    return null;
  }

  const { startTimestamp, endTimestamp } = timeRangeInfo;
  return (
    <div className="schedule-recurring-summary-container">
      This schedule will start on {getStringFromTimeStamp(startTimestamp)} and end on{" "}
      {getStringFromTimeStamp(endTimestamp)}
    </div>
  );
}

function ScheduleBasicDialogBodyComponent(props) {
  const {
    title,
    onTitleChange,
    description,
    onDescriptionChange,
    scheduleType,
    onScheduleTypeChange,
    timeRanges,
    onTimeRangesChange,
    repeatCount,
    onRepeatCountChange,
    scheduleRecurringType,
    onScheduleRecurringTypeChange,
  } = props;

  return (
    <ModalFieldRows className="schedule-basic-dialog-container-content">
      <LabeledInput
        label="Name"
        staticLabel
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
      />
      <LabeledInput
        label="Description"
        staticLabel
        value={description}
        onChange={(e) => onDescriptionChange(e.target.value)}
      />
      <LabeledSelect
        label="Type"
        options={scheduleTypeOptions}
        value={getOptionByValue(scheduleTypeOptions, scheduleType)}
        onChange={onScheduleTypeChange}
      />
      {scheduleType === ScheduleType.STATIC && (
        <ScheduleCustomTimeRangesSetting
          timeRanges={timeRanges}
          onTimeRangesChange={onTimeRangesChange}
        />
      )}
      {scheduleType === ScheduleType.RECURRING && (
        <ScheduleRecurringSetting
          timeRanges={timeRanges}
          onTimeRangesChange={onTimeRangesChange}
          scheduleRecurringType={scheduleRecurringType}
          onScheduleRecurringTypeChange={onScheduleRecurringTypeChange}
        />
      )}
      {scheduleType === ScheduleType.RECURRING && (
        <ScheduleRepeatCountSetting
          value={repeatCount}
          onChange={onRepeatCountChange}
        />
      )}
      {scheduleType === ScheduleType.RECURRING && (
        <ScheduleRecurringSummary
          timeRanges={timeRanges}
          repeatCount={repeatCount}
          scheduleType={scheduleType}
          scheduleRecurringType={scheduleRecurringType}
        />
      )}
    </ModalFieldRows>
  );
}

function ScheduleBasicDialogFooterComponent(props) {
  const { onCancel, onOKClicked, enableSave } = props;

  return (
    <div className="schedule-basic-dialog-confirm-buttons">
      <ModalConfirmationButtons
        onCancelClick={onCancel}
        onOkClick={onOKClicked}
        okButtonProps={{ disabled: !enableSave }}
      />
    </div>
  );
}

function isValidTimeRange(timeRange) {
  const { startTimestamp, endTimestamp } = timeRange;
  return startTimestamp <= endTimestamp;
}

function isValidData(newSchedule) {
  const { title, scheduleType, timeRanges, repeatCount } = newSchedule;

  if (!title) {
    return false;
  }

  if (scheduleType === ScheduleType.STATIC && timeRanges.length === 0) {
    return false;
  }

  if (scheduleType === ScheduleType.STATIC) {
    for (const timeRange of timeRanges) {
      if (!isValidTimeRange(timeRange)) {
        return false;
      }
    }
  }

  if (
    scheduleType === ScheduleType.RECURRING &&
    (typeof repeatCount !== "number" || repeatCount < 0)
  ) {
    return false;
  }

  return true;
}

function ScheduleBasicDialog(props) {
  let {
    defaultData: {
      title: defaultTitle,
      description: defaultDescription,
      scheduleType: defaultScheduleType,
      scheduleRecurringType: defaultScheduleRecurringType,
      timeRanges: defaultTimeRanges,
      repeatCount: defaultRepeatCount,
    },
    dialogTitle = "Add new schedule",
    className = "",
    onOkClicked,
    modalIsOpen,
    setIsOpen,
    enableSave,
  } = props;

  const [title, setTitle] = React.useState(defaultTitle);
  const [description, setDescription] = React.useState(defaultDescription);
  const [scheduleType, setScheduleType] = React.useState(defaultScheduleType);
  const [scheduleRecurringType, setScheduleRecurringType] = React.useState(
    defaultScheduleRecurringType
  );
  const [timeRanges, setTimeRanges] = React.useState(defaultTimeRanges);
  const [repeatCount, setRepeatCount] = React.useState(defaultRepeatCount);

  function getNewSchedule() {
    return {
      title,
      description,
      scheduleType,
      scheduleRecurringType,
      timeRanges,
      repeatCount,
    };
  }

  function closeModal() {
    setTitle(defaultTitle);
    setDescription(defaultDescription);
    setScheduleType(defaultScheduleType);
    setScheduleRecurringType(defaultScheduleType);
    setTimeRanges(defaultTimeRanges);
    setRepeatCount(defaultRepeatCount);
    setIsOpen(false);
  }

  function onOKClicked() {
    if (onOkClicked) {
      const newSchedule = getNewSchedule();
      onOkClicked(newSchedule);
    }
    closeModal();
  }

  function onScheduleTypeChange(newScheduleType) {
    if (newScheduleType !== scheduleType) {
      setScheduleType(newScheduleType);
      setTimeRanges([getDefaultTimeRange()]);
      const defaultRecurringType =
        newScheduleType === ScheduleType.STATIC
          ? ScheduleRecurringType.NONE
          : ScheduleRecurringType.DAILY;
      setScheduleRecurringType(defaultRecurringType);
    }
  }

  function onScheduleRecurringTypeChange(newScheduleRecurringType) {
    if (newScheduleRecurringType !== scheduleRecurringType) {
      setScheduleRecurringType(newScheduleRecurringType);
      setTimeRanges([getDefaultTimeRange()]);
    }
  }

  return (
    <LegacyModalDialogAdapter
      title={dialogTitle}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={`schedule-basic-dialog-container ${className}`}
      triggerComponent={null}
      contentComponent={
        <ScheduleBasicDialogBodyComponent
          title={title}
          onTitleChange={setTitle}
          description={description}
          onDescriptionChange={setDescription}
          scheduleType={scheduleType}
          onScheduleTypeChange={onScheduleTypeChange}
          scheduleRecurringType={scheduleRecurringType}
          onScheduleRecurringTypeChange={onScheduleRecurringTypeChange}
          repeatCount={repeatCount}
          onRepeatCountChange={setRepeatCount}
          timeRanges={timeRanges}
          onTimeRangesChange={setTimeRanges}
        />
      }
      footerComponent={
        <ScheduleBasicDialogFooterComponent
          onCancel={closeModal}
          onOKClicked={onOKClicked}
          enableSave={enableSave && isValidData(getNewSchedule())}
        />
      }
    />
  );
}

export default ScheduleBasicDialog;
