import {
  SET_DASHBOARD_CHART_LIST,
  SET_DASHBOARD_CONFIG,
  SET_DASHBOARD_DQ_SUMMARY,
  SET_DASHBOARD_INCIDENT_LIST_SET_PER_CHART,
  SET_DASHBOARD_INCIDENT_SUMMARY,
} from "../../actions/dashboard/dashboard-action-types";

const initialDashboardState = {
  dashboardChartList: {
    loading: true,
    data: [],
  },
  dashboardIncidentListSetPerChart: {},
  dqSummary: {
    loading: true,
    data: {},
  },
  config: {
    loading: true,
    data: {},
  },
  incidentSummary: {
    loading: true,
    data: {},
  },
};

export default function dashboardReducer(state = initialDashboardState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_DASHBOARD_CHART_LIST:
      newState = { ...state, dashboardChartList: action.dashboardChartList };
      return newState;

    case SET_DASHBOARD_INCIDENT_LIST_SET_PER_CHART:
      newState = {
        ...state,
        dashboardIncidentListSetPerChart: action.dashboardIncidentListSetPerChart,
      };
      return newState;

    case SET_DASHBOARD_DQ_SUMMARY:
      newState = { ...state, dqSummary: action.dqSummary };
      return newState;

    case SET_DASHBOARD_CONFIG:
      newState = { ...state, config: action.config };
      return newState;

    case SET_DASHBOARD_INCIDENT_SUMMARY:
      newState = { ...state, incidentSummary: action.incidentSummary };
      return newState;

    default:
      return state;
  }
}
