import React from "react";
import MetricFailingRecordsQueryConfigItem from "../../../fields/metric-failing-records-query-config-item";
import { ColumnConditionsConfigItem, DataAssetCards } from "../../../fields/";
import { getColumnTypeCategory } from "../../../../../utils/column";
import {
  ConformityConditionType,
  MetricDataAssetType,
  QueryScope,
  TableColumnTypeCategory,
  TableType,
  isFileSource,
} from "../../../../../utils/enums";
import DataAssetSelect from "../../../fields/data-asset-select";
import ConfigureMetric from "../../../fields/configure-metric";
import AddTags from "../../../fields/add-tags";
import WhereClause from "../../../fields/where-clause";

function ConformityCountMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    onDataSourceChanged,
    schemaList,
    tableList,
    onTableChanged,
    columnList: { data: columnList },
    inColumnColumnList,
    onInColumnTableChanged,
    columnValueList,
    partitionSampleData,
    getPartitionSampleData,
    tagList,
    waffle,
    resetKPIStatus,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult = { loading: false },
    openWorkspaceTakeover,
    closeTakeover,
    currentDataSource,
    disabled = false,
  } = props;

  const isEditMode = !configData.config.inheritTableSettings;
  const isStreaming = configData.config.isStreaming;
  const valueColumns = configData.config.valueColumns || [];
  const configDataColumnUuid =
    valueColumns.length > 0 ? valueColumns[0].columnUuid : null;
  const isFileDataSource = isFileSource(currentDataSource?.config?.connection?.type);

  const currentSelectedColumn = configDataColumnUuid
    ? columnList.find((currentColumn) => currentColumn.uuid === configDataColumnUuid)
    : null;

  let columnTypeCategory;
  if (currentSelectedColumn) {
    columnTypeCategory = getColumnTypeCategory(currentSelectedColumn);
  } else {
    console.log(`Fail to find column uuid ${configDataColumnUuid}`);
    columnTypeCategory = TableColumnTypeCategory.STRING;
  }

  const isFullTable =
    tableList.find(
      (currentTable) => currentTable.tableUuid === configData?.config?.table?.tableUuid
    )?.profilerConfig.queryScope === QueryScope.FULL_TABLE;

  return (
    <DataAssetCards>
      <DataAssetSelect
        dataAssetType={MetricDataAssetType.COLUMN}
        configData={configData}
        isValidConfig={isValidConfig}
        dataSourceList={dataSourceList}
        schemaList={schemaList}
        tableList={tableList}
        columnList={columnList}
        isStreaming={isStreaming}
        resetKPIStatus={resetKPIStatus}
        onConfigDataChange={onConfigDataChange}
        onDataSourceChanged={onDataSourceChanged}
        onTableChanged={onTableChanged}
        isMultipleValueColumns={true}
        disabled={disabled}
        columnGetter={(configData) => valueColumns.map(({ columnUuid }) => columnUuid)}
        onDataSourceChange={(newDataSourceUuid) => {
          const aggregation = configData.config.aggregation;
          const conditions = aggregation.conditions || [];
          const newConditions = [];
          for (let conditionItem of conditions) {
            if (
              [
                ConformityConditionType.IN_COLUMN,
                ConformityConditionType.NOT_IN_COLUMN,
              ].includes(conditionItem.type)
            ) {
              newConditions.push({
                ...conditionItem,
                values: [{}],
              });
            } else {
              newConditions.push(conditionItem);
            }
          }

          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              sources: [newDataSourceUuid],
              aggregation: {
                ...aggregation,
                conditions: newConditions,
              },
              table: {
                type: TableType.TABLE,
                tableName: null,
                missingValueFilling: configData.config.missingValueFilling,
              },
              valueColumns: [],
              whereConditions: [],
              whereClause: null,
              sliceByColumns: [],
              sliceValueSelections: [],
              partitions: [],
            },
          });
          resetKPIStatus({
            resetAll: false,
            resetColumnList: true,
            resetColumnValueList: true,
            resetPreviewInfo: true,
          });
          onDataSourceChanged(newDataSourceUuid);
        }}
        onColumnChange={(_newColumnUuidList, newColumnList) => {
          let newAggregation = configData.config.aggregation;
          if (valueColumns.length === 1 && [0, 2].includes(newColumnList.length)) {
            newAggregation = {
              ...newAggregation,
              conditions: [],
            };
          }

          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              aggregation: newAggregation,
              valueColumns: newColumnList.map(({ columnName, uuid }) => {
                return {
                  columnName,
                  columnUuid: uuid,
                };
              }),
            },
          });

          resetKPIStatus({
            resetAll: false,
            resetPreviewInfo: true,
          });
        }}
      />
      <ColumnConditionsConfigItem
        isMultipleValueColumns={valueColumns.length > 1}
        value={configData.config.aggregation.conditions || []}
        columnType={columnTypeCategory}
        schemaList={schemaList}
        inColumnTableList={tableList}
        inColumnColumnList={inColumnColumnList}
        onInColumnTableChanged={(tableUuid) =>
          onInColumnTableChanged(configData.config.sources[0], tableUuid)
        }
        isBlobStorage={isFileDataSource}
        columnList={columnList}
        onChange={(newConditions) => {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              aggregation: {
                ...configData.config.aggregation,
                conditions: newConditions,
              },
            },
          });
        }}
        isFullTable={isFullTable}
        isAddDisabled={!configDataColumnUuid}
        configData={configData}
        disabled={disabled}
      />
      {!isFileDataSource && (
        <WhereClause
          configData={configData}
          columnList={columnList}
          onConfigDataChange={onConfigDataChange}
          schemaList={schemaList}
          tableList={tableList}
          disabled={disabled}
        />
      )}
      <ConfigureMetric
        enableSeasonality={true}
        enableSlicing={true}
        allowOverride={true}
        configData={configData}
        isValidConfig={isValidConfig}
        isStreaming={isStreaming}
        dataSourceList={dataSourceList}
        tableList={tableList}
        columnList={columnList}
        isEditMode={isEditMode}
        onConfigDataChange={onConfigDataChange}
        openWorkspaceTakeover={openWorkspaceTakeover}
        closeTakeover={closeTakeover}
        partitionSampleData={partitionSampleData}
        getPartitionSampleData={getPartitionSampleData}
        columnValueList={columnValueList}
        waffle={waffle}
        enableMissingValueFilling={true}
        disabled={disabled}
      />
      {!isFileDataSource && (
        <MetricFailingRecordsQueryConfigItem
          isSystemDefinedSql={true}
          configData={configData}
          value={configData.config.failingRecordsSql}
          validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
          validateFailingRecordsSqlResult={validateFailingRecordsSqlResult}
          onChange={(newFailingRecordsSql) => {
            onConfigDataChange({
              ...configData,
              config: {
                ...configData.config,
                failingRecordsSql: newFailingRecordsSql,
              },
            });
          }}
          disabled={disabled}
        />
      )}
      <AddTags
        configData={configData}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
        disabled={disabled}
      />
    </DataAssetCards>
  );
}

ConformityCountMetricDataAssetConfig.assetMeta = {
  title: "Select data asset",
  createDescription:
    "Create your metric by selecting the type, naming it, picking a data asset, and configuring the settings",
  editDescription:
    "Edit your metric by naming it, picking a data asset, and configuring the settings",
};

export default ConformityCountMetricDataAssetConfig;
