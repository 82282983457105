import React from "react";
import auth0 from "../../utils/auth0";
import { connect } from "react-redux";

import NgButton from "../button/ng-button";

import { logout } from "../../actions/user/user-action";

import { LogOutDoorIcon } from "../icons/log-out-door-icon";
import { InfoCircleOutlined } from "@ant-design/icons";

import LightupLogo from "../../assets/img/LightUpLogo_light.svg";

import "./expired.scss";

function Expired(props) {
  const { logout: logoutUser } = props;

  function onLogout() {
    try {
      logoutUser();
      auth0.logout();
    } catch (error) {
      console.error(`Failed to logout: ${error}`);
    }
  }

  return (
    <div className="trial-expired">
      <div className="trial-expired-topbar">
        <a href="https://www.lightup.ai">
          <img src={LightupLogo} alt="Lightup Logo" />
        </a>

        <div onClick={onLogout} className="trial-expired-topbar-logout">
          <LogOutDoorIcon />
          <span>LOGOUT</span>
        </div>
      </div>
      <div className="trial-expired-banner">
        <InfoCircleOutlined />
        Your free trial has ended
      </div>
      <div className="trial-expired-main">
        <div className="trial-expired-message">
          To extend your trial or to purchase Lightup please contact us
        </div>
        <NgButton
          primary
          size="large"
          onClick={() => {
            window.location.href = "mailto:sales@lightup.ai";
          }}
        >
          Email sales@lightup.ai
        </NgButton>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Expired);
