import React, { useState } from "react";
import { CollapseIcon, ExpandIcon } from "./icons";
import ButtonIcon from "../../components/button/button-icon";
import { classesName } from "../../utils/css";
import Divider from "../../atom/divider";
import "./profiler-stats-card-view.scss";

function ProfilerStatsCardItem(props) {
  const { label, value, expandable = false } = props;
  const className = classesName(
    "profiler-stats-card-view-info-list-item-container",
    expandable && "expandable"
  );
  return (
    <div className={className}>
      <div className="profiler-stats-card-view-info-list-item-label-container">
        {label}
      </div>
      <div className="profiler-stats-card-view-info-list-item-value-container">
        {value}
      </div>
    </div>
  );
}

function ProfilerStatsExpandableCardItem(props) {
  const { label, value, expandItems = [] } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  if (expandItems.length === 0) {
    return <ProfilerStatsCardItem label={label} value={value} />;
  }

  const IconComponent = isExpanded ? CollapseIcon : ExpandIcon;
  return (
    <div className="profiler-stats-card-view-info-list-expand-item-container">
      <div className="profiler-stats-card-view-info-list-expand-item-title-container">
        <ProfilerStatsCardItem label={label} value={value} />
        <ButtonIcon
          icon={<IconComponent />}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
      {isExpanded && (
        <React.Fragment>
          {expandItems.map(({ label, value }) => (
            <ProfilerStatsCardItem expandable label={label} value={value} key={label} />
          ))}
        </React.Fragment>
      )}
    </div>
  );
}

export function ProfilerStatsCardView(props) {
  const { title, statsInfoList = [], extraInfoList = [] } = props;

  return (
    <div className="profiler-stats-card-view-container">
      <div className="profiler-stats-card-view-title-container">{title}</div>
      <div className="profiler-stats-card-view-stats-info-list-container">
        {statsInfoList.map(({ label, value, expandItems = [] }) => {
          return (
            <ProfilerStatsExpandableCardItem
              label={label}
              value={value}
              key={label}
              expandItems={expandItems}
            />
          );
        })}
      </div>
      {extraInfoList.length > 0 && (
        <>
          <Divider style={{ margin: "4px 0px" }} />
          <div className="profiler-stats-card-view-extra-info-list-container">
            {extraInfoList.map(({ label, value }, index) => (
              <React.Fragment key={index}>
                <ProfilerStatsCardItem label={label} value={value} />
                {index !== extraInfoList.length - 1 && (
                  <Divider style={{ margin: 0 }} type="vertical" />
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
