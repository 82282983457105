import React from "react";
import DataLineChart from "../data-line-chart-view";
import LegacyModalDialog from "../../legacy-adapter/legacy-modal-dialog";
import { AutoMetricsType } from "../../../utils/enums";
import { getCategoryFromIncident } from "../../../utils/incident";
import { updateValueRange } from "../../../utils/general";

import "./data-distribution-metrics-dialog.scss";

function DataDistributionMetricsDialogBodyComponent(props) {
  let {
    data = [],
    duration,
    incidents = [],
    showStatsData = false,
    category: currentCategory,
    symptomType,
    feature,
  } = props;

  const projectedData = [];
  const anomalyData = [];
  const normalData = [];
  const upperBoundData = [];
  const lowerBoundData = [];
  const signalData = [];
  const signalRange = { min: undefined, max: undefined };
  const metricsRange = { min: undefined, max: undefined };
  for (let { timestamp, value } of data) {
    for (let {
      category,
      lowerBoundValue,
      upperBoundValue,
      signalValue,
      projectedValue,
      normalValue,
    } of value) {
      if (currentCategory === category) {
        signalData.push({ timestamp, value: signalValue });
        updateValueRange(signalRange, signalValue);
        if (showStatsData) {
          if (typeof lowerBoundValue === "number") {
            lowerBoundData.push({ timestamp, value: lowerBoundValue });
            updateValueRange(metricsRange, lowerBoundValue);
          }
          if (typeof upperBoundValue === "number") {
            upperBoundData.push({ timestamp, value: upperBoundValue });
            updateValueRange(metricsRange, upperBoundValue);
          }
          if (typeof projectedValue === "number") {
            projectedData.push({ timestamp, value: projectedValue });
            updateValueRange(metricsRange, projectedValue);
          }
          if (typeof normalValue === "number") {
            normalData.push({ timestamp, value: normalValue });
            updateValueRange(metricsRange, normalValue);
          }
        }
        break;
      }
    }
  }

  const currentIncidentList = incidents.filter((currentIncident) =>
    getCategoryFromIncident(currentIncident).includes(currentCategory)
  );

  const lineChartData = {
    autoMetricType: AutoMetricsType.NONE,
    duration,
    metricsData: {
      projectedData,
      anomalyData,
      normalData,
      upperBoundData,
      lowerBoundData,
      signalData,
    },
    range: {
      signalRange,
      metricsRange,
    },
  };

  return (
    <div className="data-distribution-metrics-dialog-body-container">
      <DataLineChart
        data={lineChartData}
        incidents={currentIncidentList}
        config={{
          symptomType,
          feature,
        }}
        chartOption={{
          showStatsData,
          showSignalData: true,
        }}
      />
    </div>
  );
}

function DataDistributionMetricsDialogFooterComponent(props) {
  const { onOKClicked } = props;

  return (
    <button className="ok-button" onClick={onOKClicked}>
      OK
    </button>
  );
}

function DataDistributionMetricsDialog(props) {
  let {
    data,
    incidents,
    showStatsData,
    category = "default",
    modalIsOpen,
    setIsOpen,
    symptomType,
    feature,
    duration,
  } = props;

  function closeModal() {
    setIsOpen(false);
  }

  function onOKClicked() {
    closeModal();
  }

  return (
    <LegacyModalDialog
      title={category}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"data-distribution-metrics-dialog-container"}
      triggerComponent={null}
      contentComponent={
        <DataDistributionMetricsDialogBodyComponent
          data={data}
          duration={duration}
          incidents={incidents}
          showStatsData={showStatsData}
          category={category}
          symptomType={symptomType}
          feature={feature}
        />
      }
      footerComponent={
        <DataDistributionMetricsDialogFooterComponent onOKClicked={onOKClicked} />
      }
    />
  );
}

export default DataDistributionMetricsDialog;
