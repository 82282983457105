import React, { useState } from "react";
import { Popover, Button, Input } from "antd";
import Select from "../../components/select/";
import { WorkspaceUserRole } from "../../utils/enums";
import { workspaceRoleOptions } from "../../utils/options";
import { AddOne } from "../../components/icons/menu";

import "./add-user-to-workspace-popover.scss";
import { EVENT, PAGE, trackEvent } from "../../utils/telemetry";

function AddUserToWorkspacePopover(props) {
  const { onAddWorkspace, appWorkspaceList } = props;
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [role, setRole] = useState(WorkspaceUserRole.ADMIN);

  const trimmedSearchValue = searchValue.toLowerCase().trim();
  const filteredWorkspaceList =
    trimmedSearchValue.length > 0
      ? appWorkspaceList.data.filter(({ name }) => {
          return name.toLowerCase().indexOf(trimmedSearchValue) !== -1;
        })
      : appWorkspaceList.data;

  function onVisibleChange(visible) {
    setVisible(visible);
    if (!visible) {
      setRole(WorkspaceUserRole.ADMIN);
      setSearchValue("");
    }
  }

  function onAddWorkspaceClicked(currentWorkspace) {
    onAddWorkspace && onAddWorkspace({ workspace: currentWorkspace, role });
    trackEvent(EVENT.ADD_WORKSPACE_TO_USER, {
      role,
      workspace_id: currentWorkspace.uuid,
      page: PAGE.ADMIN_USERS,
    });
    setVisible(false);
  }

  const popOverContent = (
    <div className="add-user-to-workspace-popover-content-container">
      <div className="add-user-to-workspace-popover-role-title-container">
        Workspace role
      </div>
      <Select
        style={{ width: "100%" }}
        placeholder="Select a role for user"
        options={workspaceRoleOptions}
        value={role}
        onChange={(newRole) => {
          setRole(newRole);
        }}
      />
      <Input.Search
        className="add-user-to-workspace-popover-workspace-search-container"
        placeholder={"Search workspace..."}
        onChange={(e) => {
          const currentSearchValue = e.target.value.trim();
          currentSearchValue !== searchValue && setSearchValue(currentSearchValue);
        }}
      />
      <div className="add-user-to-workspace-popover-workspace-list-container">
        {filteredWorkspaceList.map((currentWorkspace) => {
          return (
            <div
              className="add-user-to-workspace-popover-workspace-list-item-container"
              key={currentWorkspace.uuid}
            >
              <span
                className="add-user-to-workspace-popover-workspace-list-item-add-button"
                onClick={onAddWorkspaceClicked.bind(null, currentWorkspace)}
              >
                <AddOne />
              </span>
              <div className="app-workspace-list-item-name-container">
                {currentWorkspace.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName="add-user-to-workspace-popover-container"
      content={popOverContent}
      trigger="click"
      placement={"bottomLeft"}
      onVisibleChange={onVisibleChange}
      visible={visible}
    >
      <Button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          margin: 0,
          width: 32,
          height: 32,
        }}
      >
        <AddOne />
      </Button>
    </Popover>
  );
}

export default AddUserToWorkspacePopover;
