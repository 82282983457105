import { EditOutlined } from "@ant-design/icons";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import { default as NgDropdownMenu } from "../../components/ng-dropdown-menu";
import { AppPermissions } from "../../utils/permissions";
import { EVENT, trackEvent, getMonitorDetailProps, PAGE } from "../../utils/telemetry";
import { URIPath, getURIInstance, hasPermission } from "../../utils/uri-path";
import { MonitorActions } from "../monitors/monitor-actions-menu";

function NotificationMonitorActionsMenu(props) {
  const {
    trigger,
    history,
    monitor,
    workspaceUuid,
    workspaceUserPermissions,
    actions = [],
    loading = false,
    getPopupContainer,
  } = props;

  const modifyPermissions = [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERDETAIL,
  ];
  const viewPermissions = [AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERDETAIL];
  const canModify = hasPermission(workspaceUserPermissions, modifyPermissions);
  const canView = hasPermission(workspaceUserPermissions, viewPermissions);
  const menuConfig = [
    {
      action: MonitorActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewPermissions,
      item: {
        label: canModify ? "Edit Monitor" : "View",
        icon: <EditOutlined />,
        onClick: () => {
          const nextUrl = getURIInstance(URIPath.EDIT_MONITOR, {
            id: monitor.metadata.uuid,
            workspaceUuid,
          });
          trackEvent(canModify ? EVENT.EDIT_MONITOR : EVENT.VIEW_MONITOR, {
            ...getMonitorDetailProps(monitor),
            page: PAGE.MONITORS,
          });
          history.push(nextUrl);
        },
        disabled: !canView,
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    [monitor],
    actions,
    workspaceUserPermissions
  );

  return (
    <NgDropdownMenu
      trigger={trigger}
      menuItems={menuItems}
      buttonProps={{ disabled: loading }}
      getPopupContainer={getPopupContainer}
      position="bottomLeft"
    />
  );
}

export default NotificationMonitorActionsMenu;
