import React from "react";
import { getStringFromTimeStamp } from "../../../../utils/time";
import NgTable from "../../../table/ng-table";

import "./metric-config-preview-result-table-view.scss";

function MetricConfigPreviewResultTableView(props) {
  const {
    header,
    data,
    currentColumnKey,
    timestampColumnKey,
    sliceColumnKeyArray = [],
  } = props;

  const columns = header.map(({ key, name }) => {
    const colHeader = {
      title: name,
      key,
      dataIndex: key,
    };

    if (key === currentColumnKey) {
      colHeader.className = "metric-config-preview-result-table-value-column";
    }

    if (sliceColumnKeyArray.length > 0 && sliceColumnKeyArray.indexOf(key) !== -1) {
      colHeader.className = "metric-config-preview-result-table-slice-column";
    } else {
      colHeader.render = (content) => {
        if (typeof content === "object") {
          content = JSON.stringify(content);
        }

        return content;
      };
    }
    return colHeader;
  });

  const timestampParsedData = data.map((dataRow, index) => {
    const { slice = {}, ...otherDataRow } = dataRow;
    const rowWithKey = {
      ...otherDataRow,
      ...slice,
      key: index,
    };
    return rowWithKey.hasOwnProperty(timestampColumnKey)
      ? {
          ...rowWithKey,
          [timestampColumnKey]: getStringFromTimeStamp(dataRow[timestampColumnKey]),
        }
      : rowWithKey;
  });

  return <NgTable columns={columns} dataSource={timestampParsedData} />;
}

export default MetricConfigPreviewResultTableView;
