import * as types from "./download-action-types";
import { getKpiDownloadListPromise } from "../../rest/kpi";

export function setDownloadFilesList(data) {
  return { type: types.SET_DOWNLOAD_FILES_LIST, downloadFilesList: data };
}

export function getDownloadFilesList(workspaceUuid) {
  return (dispatch) => {
    getKpiDownloadListPromise(workspaceUuid)
      .then(function (downloadList) {
        dispatch(setDownloadFilesList(downloadList.data));
      })
      .catch(function (error) {
        console.log(`Fail to get download files list for ${error}`);
      });
  };
}
