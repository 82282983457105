import React, { useEffect, useState } from "react";
import TimeRangeSelector from "../../components/time-range-selector";
import DailySummary from "./daily-summary";
import IncidentDashboardChart from "./incident-dashboard-chart";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
  subDays,
  subWeeks,
} from "date-fns";
import { URIPath, getURIInstance } from "../../utils/uri-path";
import { getDashboardIncidentSummary } from "../../actions/dashboard/dashboard-action";
import {
  getDefaultUserSetting,
  getQueryStringFromUserSetting,
} from "../../utils/user-setting";

import "./index.scss";

function IncidentDashboardContainer(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    getDashboardIncidentSummary,
    incidentSummary,
    history,
  } = props;

  const currentTime = new Date();
  const [timePeriod, setTimePeriod] = useState({
    currentTime,
    startTime: subWeeks(currentTime, 2),
    endTime: currentTime,
  });

  useEffect(() => {
    getDashboardIncidentSummary(
      workspaceUuid,
      getUnixTime(timePeriod.startTime),
      getUnixTime(timePeriod.endTime)
    );
  }, [workspaceUuid, getDashboardIncidentSummary, timePeriod]);

  function onTimeRangeChange(newRange) {
    const twoWeeksBeforeEnd = subWeeks(newRange.endTime, 2);
    const quarterBeforeEnd = subDays(newRange.endTime, 90);
    let rangeStart = newRange.startTime;
    if (isAfter(rangeStart, twoWeeksBeforeEnd)) {
      // Ensure we request at least 2 weeks of data.
      rangeStart = twoWeeksBeforeEnd;
    } else if (isBefore(rangeStart, quarterBeforeEnd)) {
      // Ensure we request no more than 90 days of data.
      rangeStart = quarterBeforeEnd;
    }

    setTimePeriod({
      ...timePeriod,
      startTime: rangeStart,
      endTime: newRange.endTime,
    });
  }

  function goToIncidentListPage(time, partialLocalFilterSetting) {
    const userSetting = getDefaultUserSetting();
    if (!time) {
      time = incidentSummary.data.loading
        ? {
            startTime: timePeriod.startTime,
            endTime: timePeriod.endTime,
            current: timePeriod.endTime,
          }
        : {
            startTime: fromUnixTime(incidentSummary.data.startTs),
            endTime: fromUnixTime(incidentSummary.data.endTs),
            current: fromUnixTime(incidentSummary.data.endTs),
          };
    }

    userSetting.time = time;
    userSetting.localFilterSetting = {
      ...userSetting.localFilterSetting,
      ...partialLocalFilterSetting,
    };

    const queryString = getQueryStringFromUserSetting(userSetting);
    const baseUrl = getURIInstance(URIPath.NG_INCIDENT_LIST, { workspaceUuid });
    history.push(`${baseUrl}${queryString}`);
  }

  return (
    <div className="incident-dashboard-container">
      <div className="incident-dashboard-left-container">
        <DailySummary
          loading={incidentSummary.loading}
          data={incidentSummary.data.summary || {}}
          onClick={(partialLocalFilterSetting) =>
            goToIncidentListPage(null, partialLocalFilterSetting)
          }
        />
      </div>
      <div className="incident-dashboard-right-container">
        <div className="incident-dashboard-time-range-controls">
          <TimeRangeSelector
            showTime={false}
            currentTime={currentTime}
            value={{
              startTime: timePeriod.startTime,
              endTime: timePeriod.endTime,
              current: timePeriod.currentTime,
            }}
            onChange={onTimeRangeChange}
            allowClear={false}
            ranges={[]}
          />
        </div>
        <IncidentDashboardChart
          loading={incidentSummary.loading}
          data={incidentSummary.data.dailySummary || []}
          onClick={(time, partialLocalFilterSetting) =>
            goToIncidentListPage(time, partialLocalFilterSetting)
          }
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  incidentSummary: state.dashboardReducer.incidentSummary,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardIncidentSummary: (workspaceUuid, startTs, endTs) =>
    dispatch(getDashboardIncidentSummary(workspaceUuid, startTs, endTs)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IncidentDashboardContainer));
