import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import EntityList from "../../components/entity-list/entity-list";
import { EventObjectType } from "../../utils/enums";
import { TextWithIcon } from "../../components/button/ng-button";
import { capitalizeFirstLetter } from "../../utils/general";
import { getStringFromTimeStamp } from "../../utils/time";
import { fnSorter } from "../../utils/sort";
import { hasPermission } from "../../utils/uri-path";
import NotificationLevelCell from "./notification-level-cell";
import { AppPermissions } from "../../utils/permissions";
import { DetailIcon } from "./icons";
import TimeRangeLimitedSelector from "../../components/time-range-limited-selector";
import { EVENT, trackEvent, getSystemEventDetailProps } from "../../utils/telemetry";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";

import "./notification-main-tab.scss";

function NotificationMainTab(props) {
  const {
    timePeriod,
    currentTime,
    onTimeSelectorChanged,
    searchItem,
    onSearchItemChanged,
    onOpenDetailClick,
    history,
    location,
    notificationList,
    workspaceUserPermissions,
  } = props;

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.NOTIFICATION,
    notifications: notificationList.data,
  });

  const enableViewDetails = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERDETAIL,
  ]);

  const normalizeData = notificationList.data.map((currentNotificationItem) => {
    let displayName = "";
    if (currentNotificationItem.objectType === EventObjectType.MONITOR) {
      displayName = currentNotificationItem.filterName;
    } else if (currentNotificationItem.objectType === EventObjectType.METRIC) {
      displayName = currentNotificationItem.metricName;
    }

    return {
      eventUuid: currentNotificationItem.eventUuid,
      isRead: currentNotificationItem.isRead,
      eventTs: Math.floor(currentNotificationItem.eventTs),
      message: currentNotificationItem.message,
      monitorUuid: currentNotificationItem.filterUuid,
      monitorName: currentNotificationItem.filterName,
      metricUuid: currentNotificationItem.metricUuid,
      metricName: currentNotificationItem.metricName,
      dataSourceUuid: currentNotificationItem.sourceUuid,
      dataSourceName: currentNotificationItem.sourceName,
      level: currentNotificationItem.level,
      category: currentNotificationItem.category,
      displayName: displayName,
      objectType: currentNotificationItem.objectType,
    };
  });

  const columns = [
    {
      title: "Date & Time",
      key: "eventTs",
      dataIndex: "eventTs",
      width: 200,
      sorter: {
        compare: fnSorter((row) => row.eventTs),
      },
      render: (eventTs) => getStringFromTimeStamp(eventTs),
    },
    {
      title: "Level",
      key: "level",
      dataIndex: "level",
      width: 100,
      sorter: {
        compare: fnSorter((row) => row.level),
      },
      render: (level) => <NotificationLevelCell value={level} />,
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
      width: 100,
      sorter: {
        compare: fnSorter((row) => row.category),
      },
      render: (category) => {
        return (
          <div className={"notification-result-table-category-cell"}>{category}</div>
        );
      },
    },
    enableViewDetails && {
      title: "",
      key: "eventUuid",
      dataIndex: "eventUuid",
      width: 100,
      render: (_, record) => {
        return (
          <button
            onClick={() => {
              trackEvent(EVENT.VIEW_EVENT_DETAIL, {
                ...getSystemEventDetailProps(record),
              });
              onOpenDetailClick(record);
            }}
            className="notification-main-tab-detail-column"
          >
            <TextWithIcon icon={<DetailIcon />} iconPosition="left">
              DETAILS
            </TextWithIcon>
          </button>
        );
      },
    },
    {
      title: "Object type",
      key: "objectType",
      dataIndex: "objectType",
      width: 120,
      sorter: {
        compare: fnSorter((row) => row.objectType),
      },
      render: (objectType) => {
        if (!objectType) {
          return "";
        }
        return capitalizeFirstLetter(objectType);
      },
    },
    {
      title: "Name",
      key: "displayName",
      dataIndex: "displayName",
      sorter: {
        compare: fnSorter((row) => row.displayName),
      },
    },
    {
      title: "Datasource",
      key: "dataSourceName",
      dataIndex: "dataSourceName",
      sorter: {
        compare: fnSorter((row) => row.dataSourceName),
      },
    },
  ].filter(Boolean);

  const currentTimeInMoment = moment(currentTime);
  const presetRanges = {
    "1h": [currentTimeInMoment.clone().subtract(1, "h"), currentTimeInMoment.clone()],
    "1d": [currentTimeInMoment.clone().subtract(1, "d"), currentTimeInMoment.clone()],
    "1w": [currentTimeInMoment.clone().subtract(1, "w"), currentTimeInMoment.clone()],
  };
  return (
    <EntityList
      searchControls={
        <TimeRangeLimitedSelector
          initialValues={timePeriod}
          presetRanges={presetRanges}
          currentTime={currentTime}
          onChange={onTimeSelectorChanged}
          daysLimit={7}
          style={{ width: 340 }}
        />
      }
      showAdd={false}
      searchOptions={searchOptions}
      searchItem={searchItem}
      onSearchItemChange={onSearchItemChanged}
      columnKeyOptions={[]}
      columns={columns}
      loading={notificationList.loading}
      rows={normalizeData}
      getFilteredRows={filterRows}
      getRowKey={(record) => record.eventUuid}
      history={history}
      location={location}
    />
  );
}

export default withRouter(NotificationMainTab);
