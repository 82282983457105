import React from "react";
import IncidentEventTimelineLineView from "./incident-event-timeline-line-view";
import { EventItemType } from "../../utils/enums";
import getUnixTime from "date-fns/getUnixTime";

function EventTimelineChart(props) {
  let {
    eventTimelineDataList = [],
    getTooltip = null,
    startTimestamp = null,
    endTimestamp = null,
    config,
    ChartComponent = IncidentEventTimelineLineView,
  } = props;

  const currentTimestamp = getUnixTime(new Date());
  const normalizedEventTimeLineDataList = eventTimelineDataList.map(
    ({ timestamp, value, isIncident }, index) => {
      let status;
      if (timestamp > currentTimestamp) {
        status = EventItemType.UNPROCESSED;
      } else if (isIncident) {
        status = EventItemType.INCIDENT;
      } else {
        status = EventItemType.NORMAL;
      }

      return {
        startTime: timestamp,
        endTime: timestamp,
        value,
        status,
      };
    }
  );

  return (
    <div className="event-timeline-chart-container">
      <ChartComponent
        eventList={normalizedEventTimeLineDataList}
        eventListStartTime={startTimestamp}
        eventListEndTime={endTimestamp}
        getTooltip={getTooltip}
        config={config}
      />
    </div>
  );
}

export default EventTimelineChart;
