import NgToggleCheckbox from "../../toggle-checkbox/ng-toggle-checkbox";

function DremioOptionConfigView(props) {
  const { value, onChange } = props;

  const { useEncryption, disableCertificateVerification } = value;

  return (
    <>
      <NgToggleCheckbox
        labelPosition="left"
        label="Use encryption"
        value={useEncryption}
        onChange={(newUseEncryption) => {
          onChange?.({ ...value, useEncryption: newUseEncryption });
        }}
      />
      <NgToggleCheckbox
        labelPosition="left"
        label="Disable certificate verification"
        value={disableCertificateVerification}
        onChange={(newDisableCertificateVerification) => {
          onChange?.({
            ...value,
            disableCertificateVerification: newDisableCertificateVerification,
          });
        }}
      />
    </>
  );
}

export default DremioOptionConfigView;
