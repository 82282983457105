import React from "react";
import { DataSourceType, HiveAuthType, RequestStatus } from "../../utils/enums";
import { getDisplayTimeFromDuration } from "../../utils/time";
import Alert, { AlertType } from "../alert";

import "./data-source-test-connection-result.scss";

const snowFlakeSteps = [
  { name: "Host Connection", keyName: "hostConnectionStatus" },
  { name: "Credential Validation", keyName: "credentialStatus" },
  { name: "Permissions Check", keyName: "roleStatus" },
  { name: "Warehouse Check", keyName: "warehouseConnectionStatus" },
  { name: "Database Access", keyName: "databaseConnectionStatus" },
];

const redShiftSteps = [
  { name: "Host Connection", keyName: "hostConnectionStatus" },
  { name: "Credential Validation", keyName: "credentialStatus" },
  { name: "Database Access", keyName: "databaseConnectionStatus" },
];

const teradataSteps = [
  { name: "Host Connection", keyName: "hostConnectionStatus" },
  { name: "Credential Validation", keyName: "credentialStatus" },
];

const hiveNoAuthSteps = [{ name: "Host Connection", keyName: "hostConnectionStatus" }];

const hiveHiveKerberizedSteps = [
  { name: "Host Connection", keyName: "hostConnectionStatus" },
  { name: "Kerberos Authentication check", keyName: "kerberosAuthenticationStatus" },
  {
    name: "Hive Server Authentication check",
    keyName: "hiveServerAuthenticationStatus",
  },
];

const dremioSteps = teradataSteps;

const dataBricksSteps = [
  { name: "Host Connection", keyName: "hostConnectionStatus" },
  { name: "Endpoint Connection", keyName: "endpointConnectionStatus" },
  { name: "Catalog Test", keyName: "catalogStatus" },
];

const postgresSteps = [
  { name: "Host Connection", keyName: "hostConnectionStatus" },
  { name: "Credential Validation", keyName: "credentialStatus" },
  { name: "Database Access", keyName: "databaseConnectionStatus" },
];

const mysqlSteps = postgresSteps;
const incortaSteps = postgresSteps;

const mssqlSteps = [
  { name: "Host Connection", keyName: "hostConnectionStatus" },
  { name: "Database Access", keyName: "databaseConnectionStatus" },
  { name: "Credential Validation", keyName: "credentialStatus" },
];
const oracleSteps = mssqlSteps;

const bigQuerySteps = [
  { name: "Service account check", keyName: "serviceAccountInfoValidation" },
  { name: "Project ID check", keyName: "projectStatus" },
  { name: "Service account email check", keyName: "clientEmailStatus" },
  { name: "Private Key check", keyName: "privateKeyStatus" },
];

const dataSourceTypeToStepsMapper = Object.freeze({
  [DataSourceType.SNOWFLAKE]: snowFlakeSteps,
  [DataSourceType.REDSHIFT]: redShiftSteps,
  [DataSourceType.TERADATA]: teradataSteps,
  [DataSourceType.DATABRICKS]: dataBricksSteps,
  [DataSourceType.POSTGRES]: postgresSteps,
  [DataSourceType.DREMIO]: dremioSteps,
  [DataSourceType.MYSQL]: mysqlSteps,
  [DataSourceType.MICROSOFTSQL]: mssqlSteps,
  [DataSourceType.ORACLE]: oracleSteps,
  [DataSourceType.INCORTA]: incortaSteps,
  [DataSourceType.BIGQUERY]: bigQuerySteps,
});

function DataSourceStepTestResult(props) {
  const { name, keyName, lastPreviewResult } = props;
  const value = lastPreviewResult[keyName];
  let alertIcon;
  let nameClass = "";
  if (value === "success") {
    alertIcon = (
      <Alert message={"Success"} type={AlertType.SUCCESS} boldMessage={false} />
    );
  } else if (value === "failed") {
    alertIcon = (
      <Alert message={"Error"} type={AlertType.FAILURE} boldMessage={false} />
    );
  } else if (value === null) {
    nameClass = "info";
    alertIcon = <Alert message={"Ignored"} type={AlertType.INFO} boldMessage={false} />;
  }

  return (
    <div className="data-source-step-test-result">
      <div className={`data-source-step-name-container ${nameClass}`}>{name}</div>
      {alertIcon}
    </div>
  );
}

function DataSourceSchemaList(props) {
  const {
    lastPreviewResult: { schemaListStatus, schemas },
  } = props;
  if (schemaListStatus && schemaListStatus !== "success") {
    return null;
  }

  return (
    <div className="data-source-schema-list-result">
      <div className="data-source-schema-list-indicator-container">
        <div className="data-source-step-name-container">Schema Retrieval</div>
        <Alert message={"Success"} type={AlertType.SUCCESS} boldMessage={false} />
      </div>
      <div className="data-source-schema-list-data-number-container">
        {`Schemas Retrieved (${schemas.length})`}
      </div>
      <div className="data-source-schema-list-data-container">
        {schemas.map((currentDataItem, index) => (
          <div
            className="data-source-schema-list-data-item-container"
            key={currentDataItem}
          >
            {currentDataItem}
          </div>
        ))}
      </div>
    </div>
  );
}

function DataSourceStackTrackInfo(props) {
  const {
    lastPreviewResult: { stacktrace },
  } = props;
  if (!stacktrace) {
    return null;
  }

  return <div className="data-source-stack-track-result">{stacktrace}</div>;
}

function DataSourceTestData(props) {
  const { lastPreviewResult, configData } = props;
  const dataSourceType = configData.config.connection.type;
  const isSucceed = lastPreviewResult?.connectionStatus === "success";
  let steps = dataSourceTypeToStepsMapper[dataSourceType];
  if (steps === undefined && dataSourceType === DataSourceType.HIVE) {
    if (configData.config.connection.authType === HiveAuthType.NO_AUTH) {
      steps = hiveNoAuthSteps;
    } else if (configData.config.connection.authType === HiveAuthType.KERBEROS) {
      steps = hiveHiveKerberizedSteps;
    }
  }

  if (steps === undefined) {
    console.log(`Unsupported data source type ${dataSourceType}`);
    return null;
  }

  return (
    <div className="data-source-test-connection-data-container">
      {isSucceed && (
        <div className="data-source-test-connection-latency-container">
          <div className="data-source-test-connection-result-title-container">
            Connection latency
          </div>
          <div className="wizard-preview-latency-value-container">
            {typeof lastPreviewResult.latency === "number"
              ? getDisplayTimeFromDuration(lastPreviewResult.latency)
              : "-"}
          </div>
        </div>
      )}
      <div className="data-source-test-connection-general-result-container">
        <div className="data-source-test-connection-general-result-title-container">
          Connection test
        </div>
        <div className="data-source-test-connection-general-result-alert-container">
          <Alert
            message={
              isSucceed ? "All connection test passed!" : "Database connection failure"
            }
            type={isSucceed ? AlertType.SUCCESS : AlertType.FAILURE}
          />
        </div>
      </div>
      <div className="data-source-test-connection-step-list-result-container">
        {steps.map((currentStep) => (
          <DataSourceStepTestResult
            key={currentStep.name}
            {...currentStep}
            lastPreviewResult={lastPreviewResult}
          />
        ))}
        {isSucceed ? (
          <DataSourceSchemaList lastPreviewResult={lastPreviewResult} />
        ) : (
          <DataSourceStackTrackInfo lastPreviewResult={lastPreviewResult} />
        )}
      </div>
    </div>
  );
}

function DataSourceTestConnectionResult(props) {
  const { isPreviewed, lastPreviewStatus, lastPreviewResult, configData } = props;
  return (
    <div className="data-source-test-connection-container">
      <div className="data-source-test-connection-header-container">
        <div className="data-source-test-connection-header-title">Connector output</div>
      </div>
      <div className="data-source-test-connection-result">
        {isPreviewed && lastPreviewStatus === RequestStatus.DOING && (
          <div className="data-source-test-connection-result-checking">
            Checking connection...
          </div>
        )}
        {isPreviewed && lastPreviewStatus === RequestStatus.SUCCESS && (
          <DataSourceTestData
            lastPreviewResult={lastPreviewResult}
            configData={configData}
          />
        )}
      </div>
    </div>
  );
}

export default DataSourceTestConnectionResult;
