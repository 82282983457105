export const sortByOptions = [
  { value: "incidents", label: "Sort by: #incidents" },
  { value: "alphabetical", label: "Sort by: rule name" },
  { value: "metric", label: "Sort by: metric name" },
  { value: "slice", label: "Sort by: slice name" },
];

function sortIncidentByNumberDesc(a, b) {
  return b.incidents.length - a.incidents.length;
}

function sortIncidentByAlphabeticalDesc(a, b) {
  if (a.title > b.title) {
    return 1;
  }

  if (b.title > a.title) {
    return -1;
  }
  return 0;
}

function sortIncidentByKpiDesc(a, b) {
  if (a.kpiName > b.kpiName) {
    return -1;
  }

  if (b.kpiName > a.kpiName) {
    return 1;
  }
  return 0;
}

function sortIncidentBySliceDesc(a, b) {
  const aStr = JSON.stringify(a.slice);
  const bStr = JSON.stringify(b.slice);
  if (aStr > bStr) {
    return -1;
  }

  if (bStr > aStr) {
    return 1;
  }

  return sortIncidentByKpiDesc(a, b);
}

function sortByFunctionAscFromDesc(ascFunction) {
  return function (a, b) {
    if (ascFunction(a, b) === 0) {
      return 0;
    }

    return -1 * ascFunction(a, b);
  };
}

export function sortIncidentList(incidentList, sortBy, sortByAsc = false) {
  const sortByFunctionMap = {
    incidents: sortIncidentByNumberDesc,
    alphabetical: sortIncidentByAlphabeticalDesc,
    kpi: sortIncidentByKpiDesc,
    slice: sortIncidentBySliceDesc,
  };

  const sortByFunctionDesc = sortByFunctionMap[sortBy];
  let sortByFunction;
  if (!sortByAsc) {
    sortByFunction = sortByFunctionDesc;
  } else {
    sortByFunction = sortByFunctionAscFromDesc(sortByFunctionDesc);
  }

  if (typeof sortByFunction !== "function") {
    console.log(`Unknown sort by option ${sortBy}`);
    return;
  }
  incidentList.sort(sortByFunction);

  return incidentList;
}

export function sortByFn(fn, a, b, desc = false) {
  const fa = fn(a);
  const fb = fn(b);
  let ascResult;
  if (fa > fb) {
    ascResult = 1;
  } else if (fa < fb) {
    ascResult = -1;
  } else {
    ascResult = 0;
  }
  return desc ? ascResult * -1 : ascResult;
}

export function fnSorter(fn, desc = false) {
  return (a, b) => sortByFn(fn, a, b, desc);
}
