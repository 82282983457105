import React, { Component } from "react";
import PropTypes from "prop-types";

class TableIcon extends Component {
  render() {
    const className = `${this.props.icon}`;
    return <i className={className} style={this.props.style} align="right" />;
  }
}

TableIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export class SortIconBoth extends Component {
  render() {
    return <TableIcon icon="sort" style={this.props.style} />;
  }
}

export class SortIconAsc extends Component {
  render() {
    return <TableIcon icon="sort-asc" style={this.props.style} />;
  }
}

export class SortIconDesc extends Component {
  render() {
    return <TableIcon icon="sort-desc" style={this.props.style} />;
  }
}
