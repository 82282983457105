import React, { useRef, useState } from "react";
import { Skeleton } from "antd";
import Tooltip from "../tooltip/ng-tooltip";
import { classesName } from "../../utils/css";

import "./ng-card.scss";

function isHorizontalOverflowing(ref) {
  return ref.current && ref.current.scrollWidth > ref.current.clientWidth;
}

// WebKit (Safari) seems to have an issue with sizing boxes with hyphens in them
// in some cases. This can cause the content to overflow (and maybe get truncated
// with ellipses) inappropriately. This is a hack to work around this - we replace
// hyphens with UTF-8 non-breaking hyphens, which for some reason tricks Safari
// into doing the right thing.
function replaceHyphens(str) {
  return typeof str === "string" ? str.replace(/-/g, "‑") : str;
}

function Title(props) {
  const {
    title,
    titleIcon,
    titleDescription,
    titleExtraContent,
    titleNote,
    subtitle,
    subtitleExtraContent,
  } = props;

  const titleRef = useRef();
  const subtitleRef = useRef();

  const [showTitleOverflowTooltip, setShowTitleOverflowTooltip] = useState(false);
  const [showSubtitleOverflowTooltip, setShowSubtitleOverflowTooltip] = useState(false);

  const titleClasses = ["explorer-card-title-text"];
  if (titleDescription) {
    titleClasses.push("explorer-card-title-text-description");
  }

  const titleTooltipSegments = showTitleOverflowTooltip ? [title] : [];
  if (titleDescription) {
    titleTooltipSegments.push(titleDescription);
  }

  return (
    <>
      <div className="explorer-card-subtitle">
        <div className="explorer-card-subtitle-text">
          <h6
            ref={subtitleRef}
            onMouseEnter={() =>
              setShowSubtitleOverflowTooltip(isHorizontalOverflowing(subtitleRef))
            }
          >
            {showSubtitleOverflowTooltip ? (
              <Tooltip title={subtitle} placement="bottomLeft">
                {subtitle}
              </Tooltip>
            ) : (
              subtitle
            )}
          </h6>
        </div>
        {subtitleExtraContent}
      </div>
      <div className="explorer-card-title">
        <div className="explorer-card-title-and-note">
          {title && titleIcon && (
            <span className="explorer-card-title-icon">{titleIcon}</span>
          )}
          <h3
            ref={titleRef}
            className={titleClasses.join(" ")}
            onMouseEnter={() =>
              setShowTitleOverflowTooltip(isHorizontalOverflowing(titleRef))
            }
            onMouseLeave={() => setShowTitleOverflowTooltip(false)}
          >
            {titleDescription || showTitleOverflowTooltip ? (
              <Tooltip title={titleTooltipSegments.join(" - ")} placement="bottomLeft">
                {replaceHyphens(title)}
              </Tooltip>
            ) : (
              replaceHyphens(title)
            )}
          </h3>
          {titleNote && <div className="explorer-card-title-note">{titleNote}</div>}
        </div>
        <div className="explorer-card-title-extra-content">{titleExtraContent}</div>
      </div>
    </>
  );
}

function NgCardWithTitle(props) {
  const {
    title,
    titleIcon,
    titleDescription,
    titleNote,
    titleExtraContent,
    subtitle,
    subtitleExtraContent,
    cornerControls,
    children,
  } = props;
  return (
    <>
      <div className="explorer-card-header">
        <div className="explorer-card-split">
          <div className="explorer-card-left">
            <Title
              title={title}
              titleIcon={titleIcon}
              titleNote={titleNote}
              titleDescription={titleDescription}
              titleExtraContent={titleExtraContent}
              subtitle={subtitle}
              subtitleExtraContent={subtitleExtraContent}
            />
          </div>
          {cornerControls && (
            <div className="explorer-card-corner-controls">{cornerControls()}</div>
          )}
        </div>
      </div>
      <div className="explorer-card-content">{children}</div>
    </>
  );
}

function NgCardNoTitle(props) {
  const { children, cornerControls } = props;
  return (
    <>
      <div className="explorer-card-split">
        <div className="explorer-card-left">
          <div className="explorer-card-content">{children}</div>
        </div>
        {cornerControls && <div>{cornerControls()}</div>}
      </div>
    </>
  );
}

function NgCard(props) {
  const {
    title,
    titleIcon,
    titleNote,
    titleExtraContent,
    subtitle,
    subtitleExtraContent,
    footerContent = null,
    loading,
    cornerControls,
    children,
    borderless = false,
    style = {},
  } = props;

  const ngCardClassName = classesName("explorer-card", borderless && "borderless");
  if (loading) {
    return (
      <div className={ngCardClassName}>
        <Skeleton active />
      </div>
    );
  } else {
    const showTitle =
      title ||
      titleIcon ||
      titleNote ||
      titleExtraContent ||
      subtitle ||
      subtitleExtraContent;
    return (
      <div className={ngCardClassName} style={style}>
        {showTitle && <NgCardWithTitle {...props} />}
        {!showTitle && cornerControls && <NgCardNoTitle {...props} />}
        {!showTitle && !cornerControls && (
          <div className="explorer-card-content">{children}</div>
        )}
        {footerContent && (
          <div className="explorer-card-footer-content">{footerContent}</div>
        )}
      </div>
    );
  }
}

export default NgCard;
