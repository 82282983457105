import React, { useMemo } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ConfigCard from "../../../config-card";
import NgButton from "../../../../button/ng-button";
import { LabeledSelect } from "../../../../labeled-control/labeled-control";
import {
  AggregationWindowConfigItem,
  DataAssetCards,
  FieldColumn,
  FieldRow,
  FieldRowButton,
  FieldRowDivider,
  FieldSection,
  PartitionsConfigItem,
  QueryTimezoneConfigItem,
  SliceByColumnsConfigItem,
  SynchronizationDelayConfigItem,
  TimestampColumnConfigItem,
  TimestampTimezoneWithDerivedConfigItem,
  VerticalSplit,
  WhereConditionsConfigItem,
  QueryScopeConfigItem,
  CustomScheduleCollectionConfigItem,
  DataCollectionScheduleConfigItem,
} from "../../../fields/";
import {
  isFeatureEnabled,
  isTriggeredCollectionMode,
} from "../../../../../utils/general";
import {
  CollectionModeType,
  QueryScope as EnumQueryScope,
  SupportedFeature,
  TableType,
} from "../../../../../utils/enums";
import { queryScopeOptions } from "../../../../../utils/options";
import {
  betaFullTableMetricAggregationWindowOptions,
  fullCompareMetricAggregationWindowOptions,
  fullTableMetricAggregationWindowOptions,
} from "../../../utils";
import { removeCollectionAttributesFromConfig } from "../../../metric-utils";
import SourceTargetTables from "../../../fields/source-target-tables";
import AddTags from "../../../fields/add-tags";
import { isPartitionConfigEnabled } from "../../../../../utils/datasource";
import { ConfigureMetricTitle } from "../../../fields/configure-metric";

function FullCompareColumnsConfig(props) {
  const {
    value: { sourceColumns, targetColumns },
    onChange,
    sourceColumnOptions,
    targetColumnOptions,
    disabled = false,
  } = props;

  return (
    <FieldColumn>
      {sourceColumns.map((sourceColumn, index) => {
        const targetColumn = targetColumns[index];
        return (
          <FieldRow key={index}>
            <LabeledSelect
              label="source"
              placeholder="Select source"
              enableSorting={true}
              disabled={disabled}
              showSearch
              options={sourceColumnOptions}
              value={sourceColumn}
              onChange={(newSourceColumn) => {
                if (newSourceColumn === sourceColumn) {
                  return;
                }

                sourceColumns[index] = newSourceColumn;
                onChange({
                  sourceColumns: [...sourceColumns],
                  targetColumns,
                });
              }}
            />
            <LabeledSelect
              label="target"
              placeholder="Select target"
              enableSorting={true}
              disabled={disabled}
              showSearch
              options={targetColumnOptions}
              value={targetColumn}
              onChange={(newTargetColumn) => {
                if (newTargetColumn === targetColumn) {
                  return;
                }

                targetColumns[index] = newTargetColumn;
                onChange({
                  sourceColumns,
                  targetColumns: [...targetColumns],
                });
              }}
            />
            {!disabled && (
              <FieldRowButton
                icon={<DeleteOutlined />}
                onClick={() => {
                  sourceColumns.splice(index, 1);
                  targetColumns.splice(index, 1);
                  onChange({
                    sourceColumns: [...sourceColumns],
                    targetColumns: [...targetColumns],
                  });
                }}
              />
            )}
          </FieldRow>
        );
      })}
    </FieldColumn>
  );
}

function FullCompareMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    onDataSourceChanged,
    onSrcDataSourceChanged,
    srcSchemaList,
    schemaList,
    srcTableList,
    onSrcTableChanged,
    tableList,
    onTableChanged,
    srcColumnList,
    columnList: { data: columnList },
    tagList,
    partitionSampleData,
    getPartitionSampleData,
    srcPartitionSampleData,
    getSrcPartitionSampleData,
    resetKPIStatus,
    waffle,
    isEdit,
    disabled = false,
  } = props;

  const {
    config: {
      queryScope,
      collectionMode,
      sourceTable,
      targetTable,
      aggregationWindow,
      synchronizationDelay,
      pollingWindow,
      pollingTimezone,
      pollingDelay,
    },
  } = configData;

  const { valueColumns: sourceValueColumns, attributeColumns: sourceAttributeColumns } =
    sourceTable;

  const { valueColumns: targetValueColumns, attributeColumns: targetAttributeColumns } =
    targetTable;

  const sourceColumnOptions = useMemo(() => {
    return srcColumnList.map(({ profilerConfig, columnName }) => ({
      label: profilerConfig?.masked ? `${columnName} (column masked)` : columnName,
      value: columnName,
      disabled: profilerConfig?.masked,
    }));
  }, [srcColumnList]);

  const targetColumnOptions = useMemo(() => {
    return columnList.map(({ profilerConfig, columnName }) => ({
      label: profilerConfig?.masked ? `${columnName} (column masked)` : columnName,
      value: columnName,
      disabled: profilerConfig?.masked,
    }));
  }, [columnList]);

  const sourceDataSourceInfo = dataSourceList.find(
    (dataSource) => sourceTable?.sourceUuid === dataSource.metadata.uuid
  );
  const isSourcePartitionEnabled =
    sourceDataSourceInfo &&
    isPartitionConfigEnabled(sourceDataSourceInfo.config.connection.type);

  const targetDataSourceInfo = dataSourceList.find(
    (dataSource) => targetTable?.sourceUuid === dataSource.metadata.uuid
  );
  const isTargetPartitionEnabled =
    targetDataSourceInfo &&
    isPartitionConfigEnabled(targetDataSourceInfo.config.connection.type);

  function onSrcDataSourceChange(newDataSourceUuid) {
    if (newDataSourceUuid === sourceTable?.sourceUuid) {
      return;
    }

    const valueColumns = sourceTable.valueColumns.map((currentValue) => null);

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        sourceTable: {
          ...sourceTable,
          sourceUuid: newDataSourceUuid,
          table: null,
          timestampColumn: null,
          dataTimezone: null,
          valueColumns,
          sliceByColumns: [],
          attributeColumns: [],
          partitions: [],
          sliceValueSelections: [],
          whereConditions: [],
          whereClause: null,
        },
        targetTable: {
          ...targetTable,
          attributeColumns: [],
          sliceValueSelections: [],
        },
      },
    });
    onSrcDataSourceChanged(newDataSourceUuid);

    resetKPIStatus({
      resetAll: false,
      resetFullCompareSrcColumnValueList: true,
    });
  }

  function onSrcTableChange(_, newSourceTable) {
    const valueColumns = sourceTable.valueColumns.map((currentValue) => null);

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        sourceTable: {
          ...sourceTable,
          table: {
            type: TableType.TABLE,
            ...newSourceTable,
          },
          timestampColumn: null,
          dataTimezone: null,
          valueColumns,
          sliceByColumns: [],
          attributeColumns: [],
          partitions: [],
          sliceValueSelections: [],
          whereConditions: [],
          whereClause: null,
        },
        targetTable: {
          ...targetTable,
          attributeColumns: [],
          sliceValueSelections: [],
        },
      },
    });

    newSourceTable.schemaName &&
      newSourceTable.tableName &&
      onSrcTableChanged(sourceTable?.sourceUuid, newSourceTable.tableUuid);
  }

  function onTargetDataSourceChange(newDataSourceUuid) {
    if (newDataSourceUuid === targetTable?.sourceUuid) {
      return;
    }

    const valueColumns = targetTable.valueColumns.map((currentValue) => null);

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        sourceTable: {
          ...sourceTable,
          attributeColumns: [],
          sliceValueSelections: [],
        },
        targetTable: {
          ...targetTable,
          sourceUuid: newDataSourceUuid,
          table: null,
          timestampColumn: null,
          dataTimezone: null,
          valueColumns,
          sliceByColumns: [],
          attributeColumns: [],
          partitions: [],
          sliceValueSelections: [],
          whereConditions: [],
          whereClause: null,
        },
        sources: [newDataSourceUuid],
      },
    });

    resetKPIStatus({
      resetAll: false,
      resetFullCompareTargetColumnValueList: true,
    });
    onDataSourceChanged(newDataSourceUuid);
  }

  function onTargetTableChange(_, newTargetTable) {
    const valueColumns = targetTable.valueColumns.map((currentValue) => null);

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        sourceTable: {
          ...sourceTable,
          attributeColumns: [],
          sliceValueSelections: [],
        },
        targetTable: {
          ...targetTable,
          table: {
            type: TableType.TABLE,
            ...newTargetTable,
          },
          timestampColumn: null,
          dataTimezone: null,
          valueColumns,
          sliceByColumns: [],
          attributeColumns: [],
          partitions: [],
          sliceValueSelections: [],
          whereConditions: [],
          whereClause: null,
        },
      },
    });

    newTargetTable.schemaName &&
      newTargetTable.tableName &&
      onTableChanged(targetTable?.sourceUuid, newTargetTable.tableUuid);
  }

  function getPollingData(queryScope, collectionModeType) {
    return queryScope === EnumQueryScope.FULL_TABLE &&
      collectionModeType === CollectionModeType.SCHEDULED
      ? {
          pollingWindow: "",
          pollingTimezone: "",
          pollingDelay: 0,
        }
      : {
          pollingWindow: null,
          pollingTimezone: null,
          pollingDelay: null,
        };
  }

  const isTimeRangeScope = queryScope === EnumQueryScope.TIME_RANGE;
  const workspaceUuid = dataSourceList?.[0]?.metadata?.workspaceId;
  const configureMetricTitle = (
    <ConfigureMetricTitle
      configData={configData}
      isValidConfig={isValidConfig}
      workspaceUuid={workspaceUuid}
    />
  );

  return (
    <DataAssetCards>
      <SourceTargetTables
        disabled={disabled}
        isEdit={isEdit}
        dataSourceList={dataSourceList}
        srcSchemaList={srcSchemaList}
        schemaList={schemaList}
        srcTableList={srcTableList}
        tableList={tableList}
        sourceTable={sourceTable}
        targetTable={targetTable}
        onSrcDataSourceChange={onSrcDataSourceChange}
        onSrcTableChange={onSrcTableChange}
        onTargetDataSourceChange={onTargetDataSourceChange}
        onTargetTableChange={onTargetTableChange}
        allowVirtualTables={true}
      />
      <FieldSection>
        <VerticalSplit>
          <WhereConditionsConfigItem
            label="Where clause (Source)"
            value={sourceTable.whereClause}
            columnList={srcColumnList}
            onChange={(newWhereClause) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  sourceTable: {
                    ...sourceTable,
                    whereClause: newWhereClause,
                  },
                },
              });
            }}
            configData={configData}
            schemaList={srcSchemaList}
            tableList={srcTableList}
            dataSourceUuid={sourceTable.sourceUuid}
            verticalInputValue
            disabled={disabled}
          />
          <WhereConditionsConfigItem
            label="Where clause (Target)"
            value={targetTable.whereClause}
            columnList={columnList}
            onChange={(newWhereClause) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  targetTable: {
                    ...targetTable,
                    whereClause: newWhereClause,
                  },
                },
              });
            }}
            configData={configData}
            schemaList={schemaList}
            tableList={tableList}
            dataSourceUuid={targetTable.sourceUuid}
            verticalInputValue
            disabled={disabled}
          />
        </VerticalSplit>
      </FieldSection>
      <ConfigCard
        title="Key columns to compare"
        description="Select key columns from source and target tables. Key column match percentage will be tracked and attribute columns will be compared when key columns match."
        actionComponent={
          <NgButton
            outline
            disabled={disabled}
            onClick={() => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  sourceTable: {
                    ...sourceTable,
                    valueColumns: [...sourceValueColumns, null],
                  },
                  targetTable: {
                    ...targetTable,
                    valueColumns: [...targetValueColumns, null],
                  },
                },
              });
            }}
          >
            Add
            <EditOutlined />
          </NgButton>
        }
      >
        <FieldSection>
          <FullCompareColumnsConfig
            value={{
              sourceColumns: sourceValueColumns,
              targetColumns: targetValueColumns,
            }}
            sourceColumnOptions={sourceColumnOptions}
            targetColumnOptions={targetColumnOptions}
            disabled={disabled}
            onChange={({
              sourceColumns: sourceValueColumns,
              targetColumns: targetValueColumns,
            }) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  sourceTable: {
                    ...sourceTable,
                    valueColumns: sourceValueColumns,
                  },
                  targetTable: {
                    ...targetTable,
                    valueColumns: targetValueColumns,
                  },
                },
              });
            }}
          />
        </FieldSection>
      </ConfigCard>
      <ConfigCard
        title="Attribute columns to compare"
        description="Select additional columns to compare for each matching key (optional)"
        actionComponent={
          <NgButton
            outline
            disabled={disabled}
            onClick={() => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  sourceTable: {
                    ...sourceTable,
                    attributeColumns: [...sourceAttributeColumns, null],
                  },
                  targetTable: {
                    ...targetTable,
                    attributeColumns: [...targetAttributeColumns, null],
                  },
                },
              });
            }}
          >
            Add
            <EditOutlined />
          </NgButton>
        }
      >
        <FieldSection>
          <FullCompareColumnsConfig
            disabled={disabled}
            value={{
              sourceColumns: sourceAttributeColumns,
              targetColumns: targetAttributeColumns,
            }}
            sourceColumnOptions={sourceColumnOptions}
            targetColumnOptions={targetColumnOptions}
            onChange={({
              sourceColumns: sourceAttributeColumns,
              targetColumns: targetAttributeColumns,
            }) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  sourceTable: {
                    ...sourceTable,
                    attributeColumns: sourceAttributeColumns,
                  },
                  targetTable: {
                    ...targetTable,
                    attributeColumns: targetAttributeColumns,
                  },
                },
              });
            }}
          />
        </FieldSection>
      </ConfigCard>
      <ConfigCard title={configureMetricTitle}>
        <FieldSection>
          <FieldColumn>
            <FieldRow>
              <QueryScopeConfigItem
                value={queryScope}
                options={queryScopeOptions}
                disabled={disabled}
                onChange={(newQueryScope) => {
                  let newConfig;
                  const pollingData = getPollingData(
                    newQueryScope,
                    collectionMode?.type
                  );
                  if (newQueryScope === EnumQueryScope.FULL_TABLE) {
                    delete configData.config.aggregationWindow;
                    newConfig = {
                      ...configData.config,
                      queryScope: newQueryScope,
                      collectionMode:
                        collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED
                          ? { type: CollectionModeType.SCHEDULED }
                          : collectionMode,
                      synchronizationDelay: 0,
                      sourceTable: removeCollectionAttributesFromConfig(
                        sourceTable,
                        false
                      ),
                      targetTable: removeCollectionAttributesFromConfig(
                        targetTable,
                        false
                      ),
                      ...pollingData,
                    };
                  } else {
                    newConfig = {
                      ...removeCollectionAttributesFromConfig(configData.config, true),
                      queryScope: newQueryScope,
                      synchronizationDelay: 0,
                      sourceTable: {
                        ...sourceTable,
                        timestampColumn: null,
                        queryTimezone: "",
                        dataTimezone: "UTC",
                        partitions: [],
                      },
                      targetTable: {
                        ...targetTable,
                        timestampColumn: null,
                        queryTimezone: "",
                        dataTimezone: "UTC",
                        partitions: [],
                      },
                    };
                  }

                  onConfigDataChange({
                    ...configData,
                    config: newConfig,
                  });
                }}
              />
              <DataCollectionScheduleConfigItem
                value={collectionMode}
                isRowByRow={true}
                disabled={disabled}
                onChange={(newCollectionMode) => {
                  const pollingData = getPollingData(
                    queryScope,
                    newCollectionMode?.type
                  );
                  onConfigDataChange({
                    ...configData,
                    config: {
                      ...configData.config,
                      collectionMode: newCollectionMode,
                      ...pollingData,
                    },
                  });
                }}
              />
            </FieldRow>
            <CustomScheduleCollectionConfigItem
              workspaceUuid={workspaceUuid}
              value={collectionMode}
              disabled={disabled}
              onChange={(newCollectionMode) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    collectionMode: newCollectionMode,
                  },
                });
              }}
            />
            {isTimeRangeScope && (
              <FieldRow>
                <TimestampColumnConfigItem
                  disabled={disabled}
                  label={"Timestamp (source)"}
                  placeholder="Select source timestamp"
                  value={sourceTable.timestampColumn}
                  columnList={srcColumnList}
                  onChange={(newSourceTimestampColumn) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        sourceTable: {
                          ...sourceTable,
                          timestampColumn: newSourceTimestampColumn,
                        },
                      },
                    });
                  }}
                />
                <TimestampColumnConfigItem
                  disabled={disabled}
                  label="Timestamp (target)"
                  placeholder="Select target timestamp"
                  value={targetTable.timestampColumn}
                  columnList={columnList}
                  onChange={(newTargetTimestampColumn) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        targetTable: {
                          ...targetTable,
                          timestampColumn: newTargetTimestampColumn,
                        },
                      },
                    });
                  }}
                />
              </FieldRow>
            )}
            {isTimeRangeScope && (
              <FieldRow>
                <TimestampTimezoneWithDerivedConfigItem
                  disabled={disabled}
                  columnList={srcColumnList}
                  timestampColumn={sourceTable.timestampColumn}
                  label={"Timestamp timezone(source)"}
                  placeholder="Select source timestamp timezone"
                  value={sourceTable.dataTimezone}
                  onChange={(newSourceTimestampTimezone) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        sourceTable: {
                          ...sourceTable,
                          dataTimezone: newSourceTimestampTimezone,
                        },
                      },
                    });
                  }}
                />
                <TimestampTimezoneWithDerivedConfigItem
                  disabled={disabled}
                  label={"Timestamp timezone(target)"}
                  placeholder="Select target timestamp timezone"
                  columnList={columnList}
                  timestampColumn={targetTable.timestampColumn}
                  value={targetTable.dataTimezone}
                  onChange={(newTargetTimestampTimezone) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        targetTable: {
                          ...targetTable,
                          dataTimezone: newTargetTimestampTimezone,
                        },
                      },
                    });
                  }}
                />
              </FieldRow>
            )}
            {isTimeRangeScope && (
              <FieldRow>
                <AggregationWindowConfigItem
                  disabled={disabled}
                  value={aggregationWindow}
                  options={fullCompareMetricAggregationWindowOptions}
                  onChange={(newAggregationWindow) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        aggregationWindow: newAggregationWindow,
                      },
                    });
                  }}
                />
                <QueryTimezoneConfigItem
                  disabled={disabled}
                  value={targetTable.queryTimezone}
                  onChange={(newQueryTimezone) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        sourceTable: {
                          ...sourceTable,
                          queryTimezone: newQueryTimezone,
                        },
                        targetTable: {
                          ...targetTable,
                          queryTimezone: newQueryTimezone,
                        },
                      },
                    });
                  }}
                />
                <SynchronizationDelayConfigItem
                  disabled={disabled}
                  value={synchronizationDelay}
                  onChange={(newSynchronizationDelay) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        synchronizationDelay: newSynchronizationDelay,
                      },
                    });
                  }}
                />
              </FieldRow>
            )}
            {!isTimeRangeScope && !isTriggeredCollectionMode(collectionMode) && (
              <FieldRow>
                <AggregationWindowConfigItem
                  disabled={disabled}
                  label="Polling interval"
                  options={
                    isFeatureEnabled(waffle, SupportedFeature.FULL_TABLE_METRIC_HOURLY)
                      ? betaFullTableMetricAggregationWindowOptions
                      : fullTableMetricAggregationWindowOptions
                  }
                  value={pollingWindow}
                  onChange={(newPollingWindow) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        pollingWindow: newPollingWindow,
                      },
                    });
                  }}
                />
                <QueryTimezoneConfigItem
                  disabled={disabled}
                  label="Polling timezone"
                  value={pollingTimezone}
                  onChange={(newPollingTimezone) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        pollingTimezone: newPollingTimezone,
                      },
                    });
                  }}
                />
                <SynchronizationDelayConfigItem
                  disabled={disabled}
                  label="Polling delay"
                  value={pollingDelay || 0}
                  onChange={(newPollingDelay) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        pollingDelay: newPollingDelay,
                      },
                    });
                  }}
                />
              </FieldRow>
            )}
          </FieldColumn>
          {(isSourcePartitionEnabled || isTargetPartitionEnabled) &&
            isTimeRangeScope && (
              <VerticalSplit>
                {isSourcePartitionEnabled && (
                  <PartitionsConfigItem
                    disabled={disabled}
                    label={"Partitions (source)"}
                    direction={"vertical"}
                    value={sourceTable.partitions || []}
                    onChange={(partitions) => {
                      onConfigDataChange({
                        ...configData,
                        config: {
                          ...configData.config,
                          sourceTable: {
                            ...configData.config.sourceTable,
                            partitions,
                          },
                        },
                      });
                    }}
                    partitionTimezone={sourceTable.partitionTimezone}
                    onPartitionTimezoneChange={(partitionTimezone) => {
                      onConfigDataChange({
                        ...configData,
                        config: {
                          ...configData.config,
                          sourceTable: {
                            ...configData.config.sourceTable,
                            partitionTimezone,
                          },
                        },
                      });
                    }}
                    partitionOffsets={sourceTable.partitionOffsets}
                    onPartitionOffsetsChange={(partitionOffsets) => {
                      onConfigDataChange({
                        ...configData,
                        config: {
                          ...configData.config,
                          sourceTable: {
                            ...configData.config.sourceTable,
                            partitionOffsets,
                          },
                        },
                      });
                    }}
                    partitionSampleData={srcPartitionSampleData}
                    getPartitionSampleData={getSrcPartitionSampleData}
                    columnList={srcColumnList}
                    sources={[sourceTable.sourceUuid]}
                    table={sourceTable.table}
                  />
                )}
                {isTargetPartitionEnabled && (
                  <PartitionsConfigItem
                    disabled={disabled}
                    label={"Partitions (target)"}
                    direction={"vertical"}
                    value={targetTable.partitions || []}
                    onChange={(partitions) => {
                      onConfigDataChange({
                        ...configData,
                        config: {
                          ...configData.config,
                          targetTable: {
                            ...configData.config.targetTable,
                            partitions,
                          },
                        },
                      });
                    }}
                    partitionTimezone={targetTable.partitionTimezone}
                    onPartitionTimezoneChange={(partitionTimezone) => {
                      onConfigDataChange({
                        ...configData,
                        config: {
                          ...configData.config,
                          targetTable: {
                            ...configData.config.targetTable,
                            partitionTimezone,
                          },
                        },
                      });
                    }}
                    partitionOffsets={targetTable.partitionOffsets}
                    onPartitionOffsetsChange={(partitionOffsets) => {
                      onConfigDataChange({
                        ...configData,
                        config: {
                          ...configData.config,
                          targetTable: {
                            ...configData.config.targetTable,
                            partitionOffsets,
                          },
                        },
                      });
                    }}
                    partitionSampleData={partitionSampleData}
                    getPartitionSampleData={getPartitionSampleData}
                    columnList={columnList}
                    sources={[targetTable.sourceUuid]}
                    table={targetTable.table}
                  />
                )}
              </VerticalSplit>
            )}
          <FieldRowDivider />
          <FieldRow>
            <SliceByColumnsConfigItem
              disabled={disabled}
              value={sourceTable.sliceByColumns}
              columnList={srcColumnList}
              label={"Slice column (Source - Optional)"}
              mode="multiple"
              onChange={(newSourceSliceByColumns) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    sourceTable: {
                      ...sourceTable,
                      sliceByColumns: newSourceSliceByColumns,
                      sliceValueSelections: [],
                    },
                  },
                });
              }}
            />
            <SliceByColumnsConfigItem
              disabled={disabled}
              value={targetTable.sliceByColumns}
              columnList={columnList}
              label={"Slice column (Target - Optional)"}
              mode="multiple"
              onChange={(newSourceSliceByColumns) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    targetTable: {
                      ...targetTable,
                      sliceByColumns: newSourceSliceByColumns,
                      sliceValueSelections: [],
                    },
                  },
                });
              }}
            />
          </FieldRow>
        </FieldSection>
      </ConfigCard>
      <AddTags
        disabled={disabled}
        configData={configData}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
      />
    </DataAssetCards>
  );
}

FullCompareMetricDataAssetConfig.assetMeta = {
  title: "Source and target",
  createDescription: "Select source table and target table to compare",
  editDescription: "Select source table and target table to compare",
};

export default FullCompareMetricDataAssetConfig;
