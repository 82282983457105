import React, { useState, useEffect } from "react";
import Plot from "../../../utils/plotly";
import { isCurrentTimestampAnIncident } from "../../../utils/general";
import { EventItemType, IncidentScore } from "../../../utils/enums";
import { getCategoryFromIncident } from "../../../utils/incident";
import IncidentEventTimelineLineView from "../ng-incident-event-timeline-line-view-dense";
import ZeroStatusChart from "../zero-status-chart";
import DataDistributionMetricsDialog from "./data-distribution-metrics-dialog";
import { BrightColorSet, getRGBAColor, NextGenPalette } from "../../../utils/color";
import "./ng-index.scss";

const expectationOpacity = 0.8;
const expectationColor = getRGBAColor("#C0DFF0", expectationOpacity);

function DataDistributionChartView(props) {
  const {
    data = null,
    incidents = [],
    config = {},
    onCurrentPointChange = null,
    chartOption: { showStatsData = false },
    showComparisonData = false,
  } = props;

  const {
    height = 160,
    comparisonLabel = "Last Sample",
    symptomType,
    feature,
  } = config;

  const { showLastPoint = false } = config;

  const [isMetricsDialogOpen, setIsMetricsDialogOpen] = useState(false);
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState(null);
  const [currentPoint, setCurrentPoint] = useState({ timestamp: 0, value: [] });

  useEffect(() => {
    if (data?.metricsData?.signalData && data.metricsData.signalData.length > 0) {
      const currentPoint = {
        timestamp:
          data.metricsData.signalData[data.metricsData.signalData.length - 1].timestamp,
        value:
          data.metricsData.signalData[data.metricsData.signalData.length - 1].value,
      };
      setCurrentPoint(currentPoint);
      onCurrentPointChange && onCurrentPointChange(currentPoint);
    } else {
      setCurrentPoint({ timestamp: 0, value: [] });
      onCurrentPointChange && onCurrentPointChange(null);
    }
  }, [data, onCurrentPointChange]);

  const metricsData = data?.metricsData?.signalData || [];
  if (!Array.isArray(metricsData)) {
    return null;
  }

  let startTimestamp = null;
  let endTimestamp = null;
  if (
    data &&
    data.duration &&
    typeof data.duration.startTime === "number" &&
    typeof data.duration.endTime === "number"
  ) {
    startTimestamp = data.duration.startTime;
    endTimestamp = data.duration.endTime;
  }

  const xaxis = {
    showticklabels: true,
    zeroline: false,
    showgrid: false,
    showline: true,
    linewidth: 1,
    linecolor: NextGenPalette.darkGrey,
    automargin: true,
    fixedrange: true,
    tickfont: {
      size: 10,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      color: NextGenPalette.slate,
    },
    type: "category",
  };

  const yaxis = {
    title: {
      text: "Percentage",
      font: {
        color: NextGenPalette.slate,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        size: 10,
      },
      standoff: 10,
    },
    zeroline: false,
    showgrid: false,
    showline: false,
    linewidth: 1,
    tickfont: {
      size: 10,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      color: NextGenPalette.slate,
    },
    ticks: "outside",
    ticklen: 6,
    tickcolor: "rgb(255, 255, 255)",
    side: "left",
    fixedrange: true,
    automargin: true,
  };

  let metricsPlotlyData = [];
  let signalPlotlyData = [];
  let comparePlotlyData = [];
  let currentIncident = isCurrentTimestampAnIncident(incidents, currentPoint.timestamp);
  const incidentCategories = currentIncident
    ? getCategoryFromIncident(currentIncident)
    : [];

  let maxValue = 0;
  for (let { signalValue } of currentPoint.value.slice(0, 20)) {
    maxValue = Math.max(signalValue, maxValue);
  }

  for (let dataEntry of currentPoint.value.slice(0, 20)) {
    let { category, signalValue, lowerBoundValue, upperBoundValue } = dataEntry;

    if (
      showStatsData &&
      (typeof lowerBoundValue === "number" || typeof upperBoundValue === "number")
    ) {
      if (typeof lowerBoundValue !== "number") {
        lowerBoundValue = 0;
      }

      if (typeof upperBoundValue !== "number") {
        upperBoundValue = maxValue + 1;
      }

      metricsPlotlyData.push({
        x: [category, category],
        y: [lowerBoundValue, upperBoundValue],
        type: "scatter",
        mode: "markers+lines",
        boxpoints: false,
        showlegend: false,
        marker: {
          color: expectationColor,
          opacity: expectationOpacity,
          symbol: "line-ew-open",
          size: 24,
        },
        line: {
          color: expectationColor,
          opacity: expectationOpacity,
          width: 36,
        },
        name: "Upper limit",
      });

      // Hack for displaying tooltip. It seems for the same trace, it will only display one point for the same xAxis.
      metricsPlotlyData.push({
        x: [category],
        y: [lowerBoundValue],
        type: "scatter",
        mode: "markers",
        boxpoints: false,
        showlegend: false,
        marker: {
          color: expectationColor,
          opacity: expectationOpacity,
          symbol: "line-ew-open",
          size: 24,
        },
        name: "Lower limit",
      });
    }

    let color;
    let name;
    if (currentIncident && incidentCategories.includes(category)) {
      color =
        currentIncident.score === IncidentScore.HIGH
          ? BrightColorSet.HIGH_IMPACT_INCIDENT
          : BrightColorSet.INCIDENT;
      name = "Anomaly";
    } else {
      color = BrightColorSet.RAW;
      name = "Metric";
    }

    if (!showComparisonData) {
      signalPlotlyData.push({
        x: [category],
        y: [signalValue],
        orientation: "v",
        type: "bar",
        width: 0.11,
        boxpoints: false,
        showlegend: false,
        marker: {
          color,
          size: 5,
        },
        name,
      });
    } else {
      if (signalPlotlyData.length === 0) {
        signalPlotlyData.push({
          x: [category],
          y: [signalValue],
          orientation: "v",
          type: "bar",
          width: [0.11],
          boxpoints: false,
          showlegend: false,
          marker: {
            color: [color],
            size: 5,
          },
          name,
        });
      } else {
        signalPlotlyData[0].x.push(category);
        signalPlotlyData[0].y.push(signalValue);
        signalPlotlyData[0].width.push(0.11);
        signalPlotlyData[0].marker.color.push(color);
      }
    }
  }

  if (showComparisonData && currentPoint && signalPlotlyData.length > 0) {
    let i = 0;
    for (i = 0; i < metricsData.length; i++) {
      if (metricsData[i].timestamp === currentPoint.timestamp) {
        break;
      }
    }

    const previousIndex = i - 1;
    if (previousIndex >= 0) {
      const previousPoint = metricsData[previousIndex];
      const previousPointCategoryValueMapper = {};
      for (let currentCategoryValue of previousPoint.value) {
        previousPointCategoryValueMapper[currentCategoryValue.category] =
          currentCategoryValue.signalValue;
      }

      let x = [];
      let y = [];
      let width = [];
      for (let currentCategoryValue of signalPlotlyData[0].x) {
        const signalValue = previousPointCategoryValueMapper[currentCategoryValue] || 0;
        x.push(currentCategoryValue);
        y.push(signalValue);
        width.push(0.11);
      }

      comparePlotlyData.push({
        x,
        y,
        orientation: "v",
        type: "bar",
        width,
        boxpoints: false,
        showlegend: false,
        marker: {
          color: "#DDDDDD",
          size: 5,
        },
        name: comparisonLabel,
        offset: -0.17,
      });
    }
  }

  const plotlyData = [...comparePlotlyData, ...signalPlotlyData, ...metricsPlotlyData];
  const layout = {
    height,
    margin: {
      l: 15,
      r: 0,
      t: 0,
      b: 20,
    },
    xaxis,
    yaxis,
    barmode: "group",
    //bargap: 0,
    bargroupgap: 0,
  };

  const dataDistributionPlotConfig = {
    displayModeBar: false,
    responsive: true,
  };

  function onClick(evt) {
    if (!evt || !evt.points || evt.points.length === 0) {
      console.log(`Invaid data distribution point with ${JSON.stringify(evt)}`);
      return;
    }

    const { x } = evt.points[0];
    setCurrentSelectedCategory(x);
    setIsMetricsDialogOpen(true);
  }

  function onHeatMapClick(data) {
    if (data && data.value) {
      setCurrentPoint(data.value);
      onCurrentPointChange && onCurrentPointChange(data.value);
    }
  }

  const normalizedTimeLine = showLastPoint
    ? []
    : metricsData.map((currentPoint) => {
        const { timestamp } = currentPoint;
        let status;
        const currentIncident = isCurrentTimestampAnIncident(incidents, timestamp);
        if (!currentIncident) {
          status = EventItemType.NORMAL;
        } else if (currentIncident.score === IncidentScore.HIGH) {
          status = EventItemType.HIGH_IMPACT_INCIDENT;
        } else {
          status = EventItemType.INCIDENT;
        }

        return {
          startTime: timestamp,
          endTime: timestamp,
          value: currentPoint,
          status,
        };
      });

  return (
    <div className="profiler-column-data-distribution-chart-view-container">
      <div className="profiler-data-source-table-chart-data-container">
        {metricsData.length > 0 ? (
          <Plot
            data={plotlyData}
            layout={layout}
            config={dataDistributionPlotConfig}
            onClick={onClick}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <ZeroStatusChart height={160} />
        )}
      </div>
      {isMetricsDialogOpen && (
        <DataDistributionMetricsDialog
          duration={data.duration}
          data={metricsData}
          symptomType={symptomType}
          feature={feature}
          showStatsData={showStatsData}
          modalIsOpen={isMetricsDialogOpen}
          setIsOpen={(isMetricsDialogOpen) => {
            setIsMetricsDialogOpen(isMetricsDialogOpen);
          }}
          incidents={incidents}
          category={currentSelectedCategory}
        />
      )}
      {!showLastPoint && startTimestamp && endTimestamp && metricsData.length > 0 && (
        <>
          <div className="data-distribution-event-timeline-title-header">Timeline</div>
          <div className="data-distribution-event-timeline-container">
            <IncidentEventTimelineLineView
              eventList={normalizedTimeLine}
              eventListStartTime={startTimestamp}
              eventListEndTime={endTimestamp}
              currentPoint={currentPoint}
              onClick={onHeatMapClick}
              config={{
                height: 70,
                marginTop: 0,
                marginLeft: 25,
                marginRight: 0,
                marginBottom: 0,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default DataDistributionChartView;
