import React from "react";
import NgButton from "../button/ng-button";
import { classesName } from "../../utils/css";

import "./index.scss";

function ToggleButton(props) {
  const {
    active = false,
    onChange,
    disabled = false,
    preserveIconColor = false,
    buttonProps = {},
    children,
  } = props;

  const className = classesName(
    active ? "toggle-button-active" : "toggle-button-inactive",
    !preserveIconColor && "toggle-icon-color"
  );

  return (
    <NgButton
      className={className}
      outline
      {...{ disabled, ...buttonProps }}
      onClick={() => onChange(!active)}
    >
      {children}
    </NgButton>
  );
}

export default ToggleButton;
