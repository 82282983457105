import React from "react";
import { Table } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import {
  KPI_AGGREGATION_SOURCE_UUID_KEY_NAME,
  KPI_AGGREGATION_TARGET_UUID_KEY_NAME,
} from "../../../utils/constants";
import {
  getDisplayFloatStr,
  getSliceDisplayString,
  getRangeDisplayStr,
} from "../../../utils/general";
import { NextGenPalette } from "../../../utils/color";
import { getDisplayTableName, getFullTableName } from "../../../utils/datasource";

import "./compare-table-view.scss";

function getColumnDisplayName(metricUuidToInfoMapper, uuid) {
  const metricInfo = metricUuidToInfoMapper[uuid];
  if (!metricInfo) {
    return "N/A";
  }

  if (!metricInfo.config.valueColumns || !metricInfo.config.valueColumns.length) {
    return "N/A";
  }

  return metricInfo.config.valueColumns
    .map(({ columnName }) => {
      return columnName;
    })
    .join(",");
}

function getMetricName(metricUuidToInfoMapper, uuid) {
  return metricUuidToInfoMapper[uuid]
    ? metricUuidToInfoMapper[uuid].metadata.name
    : "N/A";
}

function AggregationCompareTableView(props) {
  const {
    metric,
    value,
    isPreview = false,
    isProfiler = false,
    metadata = {},
    incidents = [],
    showStatsData = false,
  } = props;

  const relatedMetricsInfo = metadata.relatedMetricsInfo || [];
  if (!value) {
    return null;
  }

  const metricUuidToInfoMapper = {};
  relatedMetricsInfo.map(
    (currentMetric) =>
      (metricUuidToInfoMapper[currentMetric.metadata.uuid] = currentMetric)
  );

  const metricIncidentUuidMapper = {};
  for (let currentIncident of incidents) {
    let incidentSlices = [];
    try {
      incidentSlices = JSON.parse(currentIncident.reason)["slice_values"];
    } catch (err) {
      console.log(
        `Fail to parse incident slice with ${JSON.stringify(currentIncident)}`
      );
      continue;
    }

    for (let currentIncidentSlice of incidentSlices) {
      const key = `${currentIncidentSlice[KPI_AGGREGATION_SOURCE_UUID_KEY_NAME]}-${currentIncidentSlice[KPI_AGGREGATION_TARGET_UUID_KEY_NAME]}`;
      metricIncidentUuidMapper[key] = true;
    }
  }

  function metricCellRender(metricUuid, data) {
    const slice = data.slice || {};
    const metricName = getMetricName(metricUuidToInfoMapper, metricUuid);
    const sliceDisplayValue = getSliceDisplayString(slice);
    if (!slice) {
      return metricName;
    }
    return (
      <>
        {metricName}
        <div style={{ fontSize: "12px", lineHeight: "14px" }}>{sliceDisplayValue}</div>
      </>
    );
  }

  let columns;
  let data;
  if (metric) {
    const { sourceMetricUuid, targetMetricUuid } = metric.config.compares[0];
    const sourceTableName = metricUuidToInfoMapper[sourceMetricUuid]
      ? getDisplayTableName(
          getFullTableName(metricUuidToInfoMapper[sourceMetricUuid].config.table)
        )
      : "N/A";

    const targetTableName = metricUuidToInfoMapper[targetMetricUuid]
      ? getDisplayTableName(
          getFullTableName(metricUuidToInfoMapper[targetMetricUuid].config.table)
        )
      : "N/A";

    columns = [
      {
        title: sourceTableName,
        children: [
          {
            title: "Metric",
            dataIndex: "sourceMetricUuid",
            className: "no-right-border-cell-container",
            render: metricCellRender,
            sorter: (a, b) =>
              getMetricName(metricUuidToInfoMapper, a.sourceMetricUuid).localeCompare(
                getMetricName(metricUuidToInfoMapper, b.sourceMetricUuid)
              ),
          },
          {
            title: "Column",
            dataIndex: "sourceMetricUuid",
            key: "sourceMetricColumn",
            className: "no-right-border-cell-container",
            render: (sourceMetricUuid) =>
              getColumnDisplayName(metricUuidToInfoMapper, sourceMetricUuid),
            sorter: (a, b) =>
              getColumnDisplayName(
                metricUuidToInfoMapper,
                a.sourceMetricUuid
              ).localeCompare(
                getColumnDisplayName(metricUuidToInfoMapper, b.sourceMetricUuid)
              ),
          },
        ],
      },
      {
        title: targetTableName,
        children: [
          {
            title: "Metric",
            dataIndex: "targetMetricUuid",
            className: "no-right-border-cell-container",
            render: metricCellRender,
            sorter: (a, b) =>
              getMetricName(metricUuidToInfoMapper, a.targetMetricUuid).localeCompare(
                getMetricName(metricUuidToInfoMapper, b.targetMetricUuid)
              ),
          },
          {
            title: "Column",
            dataIndex: "targetMetricUuid",
            className: "no-right-border-cell-container",
            key: "targetMetricColumn",
            render: (targetMetricUuid) =>
              getColumnDisplayName(metricUuidToInfoMapper, targetMetricUuid),
            sorter: (a, b) =>
              getColumnDisplayName(
                metricUuidToInfoMapper,
                a.targetMetricUuid
              ).localeCompare(
                getColumnDisplayName(metricUuidToInfoMapper, b.targetMetricUuid)
              ),
          },
        ],
      },
      {
        title: "Difference(%)",
        width: 120,
        dataIndex: "value",
        render: (value, data) => {
          const differenceDisplayValue = getDisplayFloatStr(value, true);
          const key = `${data.sourceMetricUuid}-${data.targetMetricUuid}`;
          const isIncident = metricIncidentUuidMapper[key];
          if (!isIncident) {
            return differenceDisplayValue;
          }

          return (
            <div
              style={{
                display: "flex",
                lineHeight: "14px",
                justifyContent: "space-between",
              }}
            >
              {differenceDisplayValue}
              <WarningTwoTone twoToneColor="#FAB800" />
            </div>
          );
        },
        sorter: (a, b) => {
          if (a.value === b.value) {
            return 0;
          }

          if (a.value < b.value) {
            return -1;
          }

          return 1;
        },
        onCell: () => {
          if (incidents.length > 0) {
            return {};
          }

          return {
            style: { background: NextGenPalette.lightYellow },
          };
        },
      },
    ];

    if (isProfiler || isPreview) {
      columns[0].children.push({
        title: "Value",
        dataIndex: "sourceValue",
        render: (value) => getDisplayFloatStr(value),
        sorter: (a, b) => {
          if (a.sourceValue === b.sourceValue) {
            return 0;
          }

          if (a.sourceValue < b.sourceValue) {
            return -1;
          }

          return 1;
        },
      });

      columns[1].children.push({
        title: "Value",
        dataIndex: "targetValue",
        render: (value) => getDisplayFloatStr(value),
        sorter: (a, b) => {
          if (a.targetValue === b.targetValue) {
            return 0;
          }

          if (a.targetValue < b.targetValue) {
            return -1;
          }

          return 1;
        },
      });
    } else {
      columns[0].children[1].className = "";
      columns[1].children[1].className = "";
    }

    if (showStatsData) {
      columns.push({
        title: "Threshold range",
        dataIndex: "lowerBoundValue",
        render: (lowerBoundValue, data) => {
          const upperBoundValue = data.upperBoundValue;
          return getRangeDisplayStr(lowerBoundValue, upperBoundValue);
        },
        sorter: (a, b) => {
          if (a.lowerBoundValue === b.lowerBoundValue) {
            return 0;
          }

          if (a.lowerBoundValue < b.lowerBoundValue) {
            return -1;
          }

          return 1;
        },
      });
    }

    data = value;
  } else {
    columns = [];
    data = [];
  }

  return (
    <div className="aggregation-compare-table-view-container">
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{ hideOnSinglePage: true }}
        sortDirections={["ascend", "descend", "ascend"]}
      />
    </div>
  );
}

export default AggregationCompareTableView;
