import React from "react";
import { PauseIcon, ResumeIcon } from "../profiler/menu-icons";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import { EVENT, PAGE, getMonitorDetailProps, trackEvent } from "../../utils/telemetry";

const MonitorStatusActions = Object.freeze({
  PAUSE: "pause",
  RESUME: "resume",
});

export function canPauseMonitor(monitor) {
  return monitor.config.isLive;
}

export function canResumeMonitor(monitor) {
  return !monitor.config.isLive;
}

function MonitorStatusMenu(props) {
  const { monitors, trigger, workspaceUserPermissions, onPause, onResume } = props;

  const menuConfig = [
    {
      action: MonitorStatusActions.PAUSE,
      supportsMultiple: true,
      validTarget: canPauseMonitor,
      item: {
        label: "Pause",
        icon: <PauseIcon />,
        onClick: (targetMonitors) => {
          trackEvent(EVENT.PAUSE_MONITOR, {
            ...getMonitorDetailProps(targetMonitors[0]),
            page: PAGE.MONITORS,
          });

          onPause(targetMonitors, false);
        },
      },
    },
    {
      action: MonitorStatusActions.RESUME,
      supportsMultiple: true,
      validTarget: canResumeMonitor,
      item: {
        label: "Resume",
        icon: <ResumeIcon />,
        onClick: (targetMonitors) => {
          trackEvent(EVENT.RESUME_MONITOR, {
            ...getMonitorDetailProps(targetMonitors[0]),
            page: PAGE.MONITORS,
          });

          onResume(targetMonitors, true);
        },
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    monitors,
    Object.values(MonitorStatusActions),
    workspaceUserPermissions
  );

  return <NgDropdownMenu trigger={trigger} menuItems={menuItems} />;
}

export default MonitorStatusMenu;
