import React from "react";

import "./admin-tab-content-header.scss";

function AdminTabContentHeader(props) {
  const { title, searchComponent, addComponent } = props;

  return (
    <div className="admin-tab-content-header-container">
      {title && <div className="admin-tab-content-header-title-container">{title}</div>}
      <div className="admin-tab-content-header-action-container">
        <div className="admin-tab-content-header-search-container">
          {searchComponent}
        </div>
        <div className="admin-tab-content-header-add-container">{addComponent}</div>
      </div>
    </div>
  );
}

export default AdminTabContentHeader;
