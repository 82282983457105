import React from "react";
import ScheduleBasicDialog from "./schedule-basic-dialog";
import { ScheduleType, ScheduleRecurringType } from "../../utils/enums";

function AddScheduleDialog(props) {
  const { onAdd, modalIsOpen, setIsOpen } = props;

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  const defaultScheduleInstance = {
    title: "",
    description: "",
    scheduleType: ScheduleType.STATIC,
    scheduleRecurringType: ScheduleRecurringType.NONE,
    repeatCount: 0,
    timeRanges: [],
  };

  return (
    <ScheduleBasicDialog
      defaultData={defaultScheduleInstance}
      className={"add-schedule-dialog-container"}
      dialogTitle={"Add new schedule"}
      onOkClicked={onOkClicked}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enableSave={true}
    />
  );
}

export default AddScheduleDialog;
