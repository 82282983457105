import React from "react";
import ConfigureMetricSummary from "../configure-metric-summary";
import WhereConditionsSummary from "../where-conditions-summary";

function AggregationMetricConfigDataAssetSummaryCardContent(props) {
  const { columnList, configData, dataSourceList } = props;

  return (
    <>
      <ConfigureMetricSummary
        configData={configData}
        columnList={columnList}
        dataSourceList={dataSourceList}
        enableAggregationWindow={false}
        enableBackfillDuration={false}
        enableDataCollectionWindow={false}
        enableQueryScope={false}
        enableQueryTimezone={false}
        enableSeasonality={true}
        enableSynchronizationDelay={false}
      />
      <WhereConditionsSummary configData={configData} />
    </>
  );
}

export default AggregationMetricConfigDataAssetSummaryCardContent;
