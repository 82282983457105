import { OpsGenieResponderType } from "../../../utils/enums";

/*
Remove empty responders and transform data to the payload format:
- From the state format: [type: "X", names: ["A", "B"]]
- To the payload format: [{type: "X", name: "A"}, {type: "X", name: "B"}]
*/
export function transformOpsgenieDataToPayload(data) {
  data.responders = data.responders
    .filter((responder) => responder.names.length > 0 && responder.type !== "")
    .flatMap((responder) =>
      responder.names.map((name) => ({ type: responder.type, name }))
    );

  return data;
}

/*
Transform responders
- From the payload format: [{type: "X", name: "A"}, {type: "X", name: "B"}]
- To the state format: [type: "X", names: ["A", "B"]]
*/
export function transformOpsgenieDataFromPayload(data) {
  const { responders = [] } = data;

  const teamNames = responders
    .filter((responder) => responder.type === OpsGenieResponderType.TEAM)
    .map((responder) => responder.name)
    .flat();

  const userNames = responders
    .filter((responder) => responder.type === OpsGenieResponderType.USER)
    .map((responder) => responder.name)
    .flat();

  const escalationNames = responders
    .filter((responder) => responder.type === OpsGenieResponderType.ESCALATION)
    .map((responder) => responder.name)
    .flat();

  const scheduleNames = responders
    .filter((responder) => responder.type === OpsGenieResponderType.SCHEDULE)
    .map((responder) => responder.name)
    .flat();

  const formattedResponders = [
    { type: OpsGenieResponderType.TEAM, names: teamNames },
    { type: OpsGenieResponderType.USER, names: userNames },
    { type: OpsGenieResponderType.ESCALATION, names: escalationNames },
    { type: OpsGenieResponderType.SCHEDULE, names: scheduleNames },
  ].filter((responder) => responder.names.length > 0);

  const newData = {
    ...data,
    responders:
      formattedResponders.length > 0 ? formattedResponders : [{ type: "", names: [] }],
  };

  return newData;
}
