import React from "react";
import { NextGenPalette } from "../../utils/color";
import { Avatar as AntdAvatar, Tooltip } from "antd";

// [Background, Text]
const palette = [
  [NextGenPalette.lightGrey, NextGenPalette.systemBlack],
  [NextGenPalette.blue, NextGenPalette.systemBlack],
  [NextGenPalette.brandYellow, NextGenPalette.systemBlack],
  [NextGenPalette.pink, NextGenPalette.systemBlack],
  [NextGenPalette.systemBlack, "#FFFFFF"],
  [NextGenPalette.lightYellow, NextGenPalette.systemBlack],
  [NextGenPalette.purple, "#FFFFFF"],
  [NextGenPalette.lightPurple, NextGenPalette.systemBlack],
];

// For assigning a hash code to a string; based off of
// Java's String#hashCode.
function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
}

function avatarColor(username) {
  return palette[hashCode(username) % palette.length];
}

export function Avatar(props) {
  const { username = "", size = 20, enableTooltip = false } = props;
  const trimmedUsername = username.trim();
  const avatarContent =
    trimmedUsername.length > 0 ? trimmedUsername[0].toUpperCase() : "U";
  const [bgColor, textColor] = avatarColor(username);
  const avatarComponent = (
    <AntdAvatar
      style={{
        backgroundColor: bgColor,
        verticalAlign: "middle",
        color: textColor,
      }}
      size={size}
    >
      {avatarContent}
    </AntdAvatar>
  );

  if (!enableTooltip) {
    return avatarComponent;
  }

  return (
    <Tooltip
      title={username}
      color="#FFFFFF"
      overlayInnerStyle={{
        color: "#121224",
      }}
    >
      {avatarComponent}
    </Tooltip>
  );
}

export function AvatarGroup(props) {
  const { userList } = props;
  if (userList.length === 0) {
    return null;
  }

  const maxCount = 4;

  return (
    <AntdAvatar.Group
      maxCount={maxCount}
      size="10"
      maxPopoverTrigger={[]}
      maxStyle={{
        color: "#FFFFFF",
        backgroundColor: "#121224",
      }}
    >
      {userList.map(({ username }) => (
        <Avatar
          key={username}
          username={username}
          size={"default"}
          enableTooltip={true}
        />
      ))}
    </AntdAvatar.Group>
  );
}
