import React, { useEffect, useState } from "react";
import TakeoverPanel from "../../../../components/takeover/takeover-panel";
import QueryHistoryTable from "../../../../components/query-history-table/query-history-table";
import TimeRangeSelector from "../../../../components/time-range-selector";
import {
  MAX_LOOKBACK_DAYS,
  timeRangeDefaultRange,
  timeRangeDisabledDate,
  timeRangeSelectorPresets,
} from "../../../settings/query-governance/query-governance";
import { getUnixTime } from "date-fns";
import Button from "../../../../components/button/ng-button";
import { QueryHistoryIcon } from "../../menu-icons";
import QueryListStats from "../../../settings/query-governance/query-list-stats";

import "./profiler-metric-query-history.scss";

function ProfilerMetricQueryHistory(props) {
  const {
    workspaceUuid,
    metric,
    closeTakeover,
    currentKpiQueryList,
    getCurrentKpiQueryList,
  } = props;
  const [queryTimeRange, setQueryTimeRange] = useState(timeRangeDefaultRange());
  const [detailComponent, setDetailComponent] = useState(null);

  const getQueryList = () => {
    getCurrentKpiQueryList(workspaceUuid, metric, {
      startTs: getUnixTime(queryTimeRange.startTime),
      endTs: getUnixTime(queryTimeRange.endTime),
    });
  };

  useEffect(() => {
    getQueryList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Conditionally renders the Detail Component */}
      {detailComponent && detailComponent}

      <TakeoverPanel
        title="Query history"
        cornerControls={
          <Button size="large" onClick={closeTakeover}>
            Close
          </Button>
        }
        titleIcon={<QueryHistoryIcon width="24" height="24" />}
        options={{ bodyStyles: { overflowY: "auto" } }}
        /*
          We do want to always render the Takeover Panel to avoid losing the internal state from the QueryHistoryTable,
          so we're using CSS to just hide the content when the detail component is active
        */
        style={{ display: detailComponent ? "none" : "flex" }}
      >
        <div className="profiler-metric-query-history-controls">
          <TimeRangeSelector
            size="large"
            value={queryTimeRange}
            disabledDate={timeRangeDisabledDate(MAX_LOOKBACK_DAYS)}
            allowClear={false}
            ranges={timeRangeSelectorPresets()}
            onChange={setQueryTimeRange}
          />
          <Button size="large" outline onClick={getQueryList}>
            Update
          </Button>
        </div>
        <QueryListStats
          queryList={currentKpiQueryList}
          className="profiler-metric-query-history-stats"
        />
        <div className="profiler-metric-query-history-table">
          <QueryHistoryTable
            queryList={currentKpiQueryList}
            onOpenQueryDetail={(detailComponent) => setDetailComponent(detailComponent)}
            onCloseQueryDetail={() => setDetailComponent(null)}
            showName={false}
            showDetail={true}
            detailOptions={{ closeText: "Back" }}
          />
        </div>
      </TakeoverPanel>
    </>
  );
}

export default ProfilerMetricQueryHistory;
