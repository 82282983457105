import React from "react";
import Plot from "../../../utils/plotly";
import { isCurrentTimestampAnIncident } from "../../../utils/general";
import { getStringFromTimeStamp } from "../../../utils/time";
import { plotConfig, yAxisConfig, timestampXAxisConfig } from "../utils";
import { NextGenPalette } from "../../../utils/color";

function EventBarChartView(props) {
  const { data, onClick, incidents = [] } = props;
  const x = [];
  const y = [];
  const color = [];

  let maxValue = 0;
  for (let { timestamp, value } of data) {
    x.push(getStringFromTimeStamp(timestamp, { graph: true }));
    let currentValue;
    if (typeof value === "number") {
      currentValue = value;
    } else if (typeof value.length === "number") {
      currentValue = value.length;
    } else {
      currentValue = 0;
    }

    y.push(currentValue);
    maxValue = Math.max(currentValue, maxValue);
    const currentIncident = isCurrentTimestampAnIncident(incidents, timestamp);

    if (currentIncident) {
      color.push("#FFCDA1");
    } else {
      color.push("#4832F3");
    }
  }

  const plotlyData = [
    {
      x,
      y,
      marker: {
        color,
        line: {
          color,
          width: 2,
        },
      },
      width: 20000000,
      type: "bar",
    },
  ];

  const xAxis = timestampXAxisConfig();
  const yAxis = yAxisConfig();
  yAxis.range = [0, Math.max(1, maxValue) + 0.1];

  yAxis.title = {
    text: "Change detected",
    font: {
      color: NextGenPalette.slate,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      size: 10,
    },
    standoff: 10,
  };

  const config = plotConfig();
  const layout = {
    xaxis: xAxis,
    yaxis: yAxis,
    height: 150,
    margin: {
      t: 0,
      r: 10,
      b: 20,
      l: 10,
    },
  };

  return (
    <Plot
      onClick={onClick}
      data={plotlyData}
      layout={layout}
      config={config}
      useResizeHandler
      style={{ width: "100%", height: "100%" }}
    />
  );
}

export default EventBarChartView;
