export function dataProfileChartBaseLayout() {
  return {
    plot_bgcolor: "#F7F7FB",
    paper_bgcolor: "#F7F7FB",
    clickmode: "none",
    automargin: true,
    margin: {
      l: 25,
      r: 25,
      t: 0,
      b: 20,
      pad: 5,
    },
    yaxis: {
      side: "left",
      fixedrange: true,
      automargin: true,
      zeroline: false,
    },
    xaxis: {
      zeroline: false,
      showline: false,
      showgrid: true,
      fixedrange: true,
    },
    font: {
      color: "#6A6A9F",
      family: "Neue-Haas-Unica-W1G",
      size: 10,
    },
  };
}
