import React, { Component } from "react";
import { Redirect } from "react-router";
import { toast } from "../../components/toast/toast";
import RuleWizard from "../../components/rule/rule-wizard";
import { RuleTabNameConst } from "../../utils/enums";
import { URIPath, getURIInstance } from "../../utils/uri-path";
import queryString from "query-string";

class EditRuleWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToRuleSetting: false,
      workspaceUuid: "",
      uuid: "",
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onActiveTabChanged = this.onActiveTabChanged.bind(this);

    this.props.resetCurrentEditRuleStatus();
  }

  static getParams(props) {
    const queryParams = queryString.parse(props.location.search);
    const activeTabKey = queryParams.tabName || RuleTabNameConst.CONFIG;
    return { activeTabKey };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: {
        params: { workspaceUuid, id },
      },
    } = props;
    let isChanged = false;
    if (workspaceUuid !== state.workspaceUuid) {
      props.getDataSourceList(workspaceUuid);
      props.getKpiList(workspaceUuid);
      props.getIntegrationList(workspaceUuid);
      props.getScheduleList(workspaceUuid);

      state = {
        ...state,
        workspaceUuid,
      };
      isChanged = true;
    }

    if (id !== state.uuid) {
      props.getRule(workspaceUuid, id);
      state = {
        ...state,
        uuid: id,
      };
      isChanged = true;
    }

    if (isChanged) {
      return state;
    }

    return null;
  }

  onCancel() {
    this.setState({ goToRuleSetting: true });
  }

  onSave(workspaceUuid, uuid, newRule) {
    return this.props.editRule(workspaceUuid, uuid, newRule);
  }

  onDelete(workspaceUuid, uuid) {
    this.props.deleteRule(workspaceUuid, uuid);
  }

  onActiveTabChanged(activeKey) {
    const { location, history } = this.props;
    let newLocation = `${location.pathname}?tabName=${activeKey}`;
    history.push(newLocation);
  }

  render() {
    const { goToRuleSetting, workspaceUuid } = this.state;
    if (goToRuleSetting) {
      if (this.props.history.length === 0) {
        return (
          <Redirect
            push
            to={getURIInstance(URIPath.NG_MONITOR_LIST, { workspaceUuid })}
          />
        );
      } else {
        this.props.history.goBack();
      }
    }

    const defaultData = this.props.currentEditRule ? this.props.currentEditRule : {};
    const id =
      defaultData.metadata && defaultData.metadata.uuid
        ? defaultData.metadata.uuid
        : "";

    if (!id || id !== this.props.match.params.id) {
      return null;
    }

    if (this.props.kpiList.length === 0) {
      return null;
    }

    if (this.props.currentDeleteRule === "successful") {
      toast(`Monitor ${defaultData.metadata.name} was deleted`);
      this.props.resetCurrentEditRuleStatus({ deleteStatus: true });
      return (
        <Redirect to={getURIInstance(URIPath.NG_MONITOR_LIST, { workspaceUuid })} />
      );
    }

    if (this.props.currentSaveRule === "successful") {
      this.props.resetCurrentEditRuleStatus({ saveStatus: true });
      toast(`Monitor ${defaultData.metadata.name} was saved`);
    }

    const { activeTabKey } = EditRuleWizard.getParams(this.props);
    return (
      <RuleWizard
        isEdit={true}
        isClone={false}
        activeTabKey={activeTabKey}
        dataSourceList={this.props.dataSourceList}
        kpiList={this.props.kpiList}
        scheduleList={this.props.scheduleList}
        integrationList={this.props.integrationList}
        currentDimensionList={this.props.currentDimensionList}
        currentRuleStatus={this.props.currentRuleStatus}
        onSave={this.onSave.bind(this, workspaceUuid, id)}
        onCancel={this.onCancel}
        onActiveTabChanged={this.onActiveTabChanged}
        defaultData={defaultData}
        getMetricsData={this.props.getMetricsData.bind(this.props, workspaceUuid)}
        nonSliceRuleTrainingData={this.props.nonSliceRuleTrainingData}
        sliceRuleTrainingData={this.props.sliceRuleTrainingData}
        getCurrentRuleStatus={this.props.getCurrentRuleStatus.bind(
          this.props,
          workspaceUuid
        )}
        addPreview={this.props.addPreview.bind(this.props, workspaceUuid)}
        deletePreview={this.props.deletePreview.bind(this.props, workspaceUuid)}
        previewList={this.props.previewList}
        getPreviewList={this.props.getPreviewList.bind(this.props, workspaceUuid)}
        previewIncidentList={this.props.previewIncidentList}
        getPreviewIncidentList={this.props.getPreviewIncidentList.bind(
          this.props,
          workspaceUuid
        )}
        currentPreviewTrainingData={this.props.currentPreviewTrainingData}
        getCurrentPreviewTrainingData={this.props.getCurrentPreviewTrainingData.bind(
          this.props,
          workspaceUuid
        )}
        getPreviewSummaryList={this.props.getPreviewSummaryList.bind(
          this.props,
          workspaceUuid
        )}
        currentPreviewSummaryData={this.props.currentPreviewSummaryData}
        currentPreviewMetricsData={this.props.currentPreviewMetricsData}
        getCurrentPreviewMetricsData={this.props.getCurrentPreviewMetricsData.bind(
          this.props,
          workspaceUuid
        )}
        currentPreviewSliceValue={this.props.currentPreviewSliceValue}
        getCurrentPreviewSliceValue={this.props.getCurrentPreviewSliceValue.bind(
          this.props,
          workspaceUuid
        )}
        startPollTrainingStatus={this.props.startPollTrainingStatus.bind(
          this.props,
          workspaceUuid
        )}
        startPollPreviewStatus={this.props.startPollPreviewStatus.bind(
          this.props,
          workspaceUuid
        )}
        updatePreviewIncidentStatus={this.props.updatePreviewIncidentStatus.bind(
          this.props,
          workspaceUuid
        )}
        resetCurrentPreviewMetricsData={this.props.resetCurrentPreviewMetricsData}
        resetRuleTrainingInfo={this.props.resetRuleTrainingInfo}
        resetRulePreviewInfo={this.props.resetRulePreviewInfo}
        workspaceUserPermissions={this.props.workspaceUserPermissions}
      />
    );
  }
}

export default EditRuleWizard;
