import React, { useState } from "react";
import NgButton from "../../components/button/ng-button";
import {
  LabeledSelect,
  LabeledTimestampSelect,
} from "../../components/labeled-control/labeled-control";
import TimeRangeSelector from "../../components/time-range-selector";
import { QueryScope } from "../../utils/enums";
import { getUnixTime, fromUnixTime } from "date-fns";
import { UpdateIcon } from "./icons";
import {
  DataProfileMode,
  defaultTimeRange,
  getModeFromTableConfig,
  isValidTableConfig,
  DataProfileModeOptions,
} from "../../utils/data-profiling";
import ProfilerTablePartitionEditor from "./takeover/config/table/profiler-table-partition-editor";
import { isPartitionConfigEnabled } from "../../utils/datasource";

import "./profiler-data-profile-config.scss";

function ProfilerDataProfileConfig(props) {
  const {
    dataSource,
    value,
    columnList = [],
    tablePartitions = [],
    partitionSampleData,
    onUpdateClick,
    onChange,
  } = props;

  const [isPartitionAdvancedSectionOpen, setIsPartitionAdvancedSectionOpen] =
    useState(false);

  const {
    timestampColumn,
    timeRange,
    partitions = [],
    partitionTimezone,
    partitionOffsets,
  } = value;

  const mode = getModeFromTableConfig(value);

  return (
    <div className="data-profiler-config">
      <div className="data-profiler-config-mode">
        <div className="data-profiler-config-mode-select">
          <LabeledSelect
            staticLabel
            label="Mode"
            value={mode}
            options={DataProfileModeOptions}
            onChange={(newMode) => {
              if (newMode === mode) {
                return;
              }

              if (newMode === DataProfileMode.RANDOM_SAMPLE) {
                onChange({
                  ...value,
                  randomSample: true,
                  queryScope: null,
                  timeRange: null,
                  timestampColumn: "",
                  partitions: [],
                });
              } else if (newMode === DataProfileMode.FULL_TABLE) {
                onChange({
                  ...value,
                  randomSample: false,
                  queryScope: QueryScope.FULL_TABLE,
                  timeRange: null,
                  timestampColumn: "",
                  partitions: [],
                });
              } else {
                onChange({
                  ...value,
                  randomSample: false,
                  queryScope: QueryScope.TIME_RANGE,
                  timeRange: defaultTimeRange(),
                  timestampColumn: "",
                  partitions: tablePartitions,
                });
              }
            }}
          />
        </div>
        <NgButton
          outline
          size="large"
          onClick={() => onUpdateClick()}
          disabled={!isValidTableConfig(value)}
        >
          <div className="data-profiler-config-regenerate-button">
            Update
            <UpdateIcon />
          </div>
        </NgButton>
      </div>
      {mode === DataProfileMode.TIME_RANGE && (
        <div className="data-profiler-config-time-range">
          <div className="data-profiler-config-time-range-select">
            <TimeRangeSelector
              value={{
                startTime: fromUnixTime(timeRange.startTime),
                endTime: fromUnixTime(timeRange.endTime),
              }}
              size="large"
              onChange={({ startTime, endTime }) => {
                onChange({
                  ...value,
                  timeRange: {
                    startTime: getUnixTime(startTime),
                    endTime: getUnixTime(endTime),
                  },
                });
              }}
            />
          </div>
          <div className="data-profiler-config-timestamp-select">
            <LabeledTimestampSelect
              label="Timestamp"
              staticLabel
              showSearch
              value={timestampColumn}
              columnList={columnList}
              onChange={(newTimestampColumn) => {
                if (newTimestampColumn === timestampColumn) {
                  return;
                }

                onChange({
                  ...value,
                  timestampColumn: newTimestampColumn,
                });
              }}
            />
          </div>
        </div>
      )}
      {isPartitionConfigEnabled(dataSource.config.connection.type) &&
        mode === DataProfileMode.TIME_RANGE && (
          <div className="data-profiler-config-partitions">
            <ProfilerTablePartitionEditor
              partitions={partitions ?? []}
              partitionTimezone={partitionTimezone}
              partitionOffsets={partitionOffsets}
              partitionColumnList={columnList}
              isAdvancedSectionOpen={isPartitionAdvancedSectionOpen}
              partitionSampleData={partitionSampleData}
              onAdvancedClick={() =>
                setIsPartitionAdvancedSectionOpen(!isPartitionAdvancedSectionOpen)
              }
              updateTableConfig={(newConfig) => {
                onChange({
                  ...value,
                  ...newConfig,
                });
              }}
            />
          </div>
        )}
    </div>
  );
}

export default ProfilerDataProfileConfig;
