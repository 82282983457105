import React from "react";
import {
  ToastContainer as ReactToastContainer,
  toast as reactToast,
} from "react-toastify";
import { CopyOutlined } from "@ant-design/icons";
import { NotificationTypeConst } from "../../utils/enums";

import "react-toastify/dist/ReactToastify.css";
import "./toast.scss";

function ToastRightPanel(props) {
  const { closeToast, content, notificationType } = props;
  const enableCopy = notificationType === NotificationTypeConst.FAILURE;

  return (
    <div className="toast-right-panel-container">
      <div onClick={closeToast} className="toast-close-button-container">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
          <g opacity="0.5" clipPath="url(#clip0_251_17033)">
            <path
              d="M8.50002 7.49844L12.0063 3.99219L13.0079 4.99377L9.5016 8.50002L13.0079 12.0063L12.0063 13.0079L8.50002 9.5016L4.99377 13.0079L3.99219 12.0063L7.49844 8.50002L3.99219 4.99377L4.99377 3.99219L8.50002 7.49844Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_251_17033">
              <rect width="17" height="17" fill="currentColor" />
            </clipPath>
          </defs>
        </svg>
      </div>
      {enableCopy && (
        <div
          className="toast-copy-container"
          onClick={() => navigator.clipboard.writeText(content)}
        >
          <CopyOutlined />
        </div>
      )}
    </div>
  );
}

function ToastMessage({ type, content }) {
  return (
    <>
      <span className="icon-container">{getToastIcon(type)}</span>
      <span className="content-container">{content}</span>
    </>
  );
}

function getToastIcon(type) {
  if (type === NotificationTypeConst.SUCCESS) {
    return (
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          d="M7.08333 10.7468L13.5943 4.23511L14.5966 5.23669L7.08333 12.75L2.5755 8.24215L3.57708 7.24057L7.08333 10.7468Z"
          fill="#054E3F"
        />
      </svg>
    );
  }

  if (type === NotificationTypeConst.WARNING) {
    return (
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          d="M9.11338 2.12501L15.861 13.8125C15.9231 13.9202 15.9559 14.0423 15.9559 14.1667C15.9559 14.291 15.9231 14.4132 15.861 14.5208C15.7988 14.6285 15.7094 14.7179 15.6017 14.7801C15.494 14.8423 15.3719 14.875 15.2475 14.875H1.75238C1.62805 14.875 1.5059 14.8423 1.39822 14.7801C1.29054 14.7179 1.20113 14.6285 1.13896 14.5208C1.07679 14.4132 1.04407 14.291 1.04407 14.1667C1.04407 14.0423 1.0768 13.9202 1.13897 13.8125L7.88655 2.12501C7.94872 2.01734 8.03814 1.92793 8.14581 1.86576C8.25349 1.8036 8.37563 1.77087 8.49997 1.77087C8.6243 1.77087 8.74644 1.8036 8.85412 1.86576C8.96179 1.92793 9.05121 2.01734 9.11338 2.12501ZM2.97922 13.4583H14.0207L8.49997 3.89584L2.97922 13.4583ZM7.79163 11.3333H9.2083V12.75H7.79163V11.3333ZM7.79163 6.37501H9.2083V9.91668H7.79163V6.37501Z"
          fill="white"
        />
      </svg>
    );
  }

  if (type === NotificationTypeConst.FAILURE) {
    return (
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          d="M8.49984 15.5832C4.58771 15.5832 1.4165 12.412 1.4165 8.49984C1.4165 4.58771 4.58771 1.4165 8.49984 1.4165C12.412 1.4165 15.5832 4.58771 15.5832 8.49984C15.5832 12.412 12.412 15.5832 8.49984 15.5832ZM8.49984 14.1665C10.0027 14.1665 11.4441 13.5695 12.5068 12.5068C13.5695 11.4441 14.1665 10.0027 14.1665 8.49984C14.1665 6.99694 13.5695 5.55561 12.5068 4.4929C11.4441 3.43019 10.0027 2.83317 8.49984 2.83317C6.99694 2.83317 5.55561 3.43019 4.4929 4.4929C3.43019 5.55561 2.83317 6.99694 2.83317 8.49984C2.83317 10.0027 3.43019 11.4441 4.4929 12.5068C5.55561 13.5695 6.99694 14.1665 8.49984 14.1665ZM7.7915 10.6248H9.20817V12.0415H7.7915V10.6248ZM7.7915 4.95817H9.20817V9.20817H7.7915V4.95817Z"
          fill="#B80739"
        />
      </svg>
    );
  }

  if (type === NotificationTypeConst.INFO) {
    return (
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          d="M8.50002 15.5833C4.5879 15.5833 1.41669 12.4121 1.41669 8.49996C1.41669 4.58783 4.5879 1.41663 8.50002 1.41663C12.4121 1.41663 15.5834 4.58783 15.5834 8.49996C15.5834 12.4121 12.4121 15.5833 8.50002 15.5833ZM8.50002 14.1666C10.0029 14.1666 11.4443 13.5696 12.507 12.5069C13.5697 11.4442 14.1667 10.0029 14.1667 8.49996C14.1667 6.99707 13.5697 5.55573 12.507 4.49302C11.4443 3.43032 10.0029 2.83329 8.50002 2.83329C6.99713 2.83329 5.55579 3.43032 4.49308 4.49302C3.43038 5.55573 2.83335 6.99707 2.83335 8.49996C2.83335 10.0029 3.43038 11.4442 4.49308 12.5069C5.55579 13.5696 6.99713 14.1666 8.50002 14.1666ZM7.79169 10.625H9.20835V12.0416H7.79169V10.625ZM9.20835 9.45975V9.91663H7.79169V8.85413C7.79169 8.66626 7.86632 8.4861 7.99915 8.35326C8.13199 8.22042 8.31216 8.14579 8.50002 8.14579C8.70124 8.14578 8.89832 8.08863 9.06833 7.98098C9.23834 7.87334 9.37429 7.71963 9.46036 7.53775C9.54643 7.35586 9.57907 7.15327 9.5545 6.95356C9.52993 6.75384 9.44916 6.56521 9.32157 6.40961C9.19398 6.254 9.02483 6.13783 8.8338 6.0746C8.64277 6.01138 8.43771 6.0037 8.24249 6.05246C8.04726 6.10122 7.8699 6.20441 7.73103 6.35004C7.59216 6.49566 7.4975 6.67772 7.45806 6.87504L6.06831 6.59667C6.15447 6.16606 6.35342 5.76606 6.64484 5.43754C6.93626 5.10903 7.30968 4.86379 7.72694 4.72689C8.1442 4.59 8.59032 4.56636 9.01971 4.6584C9.4491 4.75043 9.84635 4.95483 10.1709 5.25071C10.4954 5.54659 10.7355 5.92333 10.8666 6.34242C10.9978 6.76151 11.0154 7.20792 10.9175 7.63601C10.8196 8.0641 10.6098 8.45852 10.3095 8.77895C10.0092 9.09937 9.6292 9.33431 9.20835 9.45975Z"
          fill="white"
        />
      </svg>
    );
  }

  return null;
}

export function ToastContainer(_props) {
  return (
    <ReactToastContainer
      hideProgressBar={true}
      position={"top-center"}
      className={"lightup-toast-container"}
      toastClassName={"lightup-toast"}
      bodyClassName={"lightup-toast-body"}
      autoClose={false}
      closeOnClick={false}
      draggable={false}
    />
  );
}

const AUTO_CLOSE_LATENCY_IN_MS = 5000; // 5 seconds
const MAX_ACTIVE_TOAST_NUMBER = 2;

export class ToastManager {
  constructor() {
    this.activeToastNum = 0;
    this.dismissAllTimeout = null;
  }

  addOne() {
    this.activeToastNum = this.activeToastNum + 1;
    if (this.activeToastNum > MAX_ACTIVE_TOAST_NUMBER) {
      if (this.dismissAllTimeout) {
        clearTimeout(this.dismissAllTimeout);
      }

      this.dismissAllTimeout = setTimeout(() => {
        reactToast.dismiss();
        this.dismissAllTimeout = null;
      }, AUTO_CLOSE_LATENCY_IN_MS);
    }
  }

  removeOne() {
    this.activeToastNum = this.activeToastNum - 1;
    if (this.activeToastNum <= MAX_ACTIVE_TOAST_NUMBER) {
      if (this.dismissAllTimeout) {
        clearTimeout(this.dismissAllTimeout);
      }
      this.dismissAllTimeout = null;
    }
  }
}

const defaultToastManager = new ToastManager();
export function toast(content, type, toastManager = defaultToastManager) {
  type = type || NotificationTypeConst.SUCCESS;

  const currentToastMessage = <ToastMessage type={type} content={content} />;
  const onOpen = () => toastManager.addOne();
  const onClose = () => toastManager.removeOne();
  const closeButton = <ToastRightPanel notificationType={type} content={content} />;
  const opts = { onOpen, onClose, closeButton };
  if (type === NotificationTypeConst.SUCCESS) {
    reactToast.success(currentToastMessage, opts);
  } else if (type === NotificationTypeConst.WARNING) {
    reactToast.warn(currentToastMessage, opts);
  } else if (type === NotificationTypeConst.FAILURE) {
    reactToast.error(currentToastMessage, opts);
  } else if (type === NotificationTypeConst.INFO) {
    reactToast.info(currentToastMessage, opts);
  }
}
