import NgButton from "../button/ng-button";
import "./table-banner.scss";

const actionTypeEnum = {
  selectAll: "select-all",
  clearSelection: "clear-selection",
};

function pluralize(count, singular, plural) {
  return count === 1 ? singular : plural;
}

function optionalStr(condition, str) {
  return condition ? str : "";
}

const getAction = ({ selectionStats, isFiltered, totalRows }) => {
  const { total, totalOtherPage, totalCurrentPage, isCurrentPageSelected } =
    selectionStats;

  if (
    total === totalRows &&
    ((!isFiltered && isCurrentPageSelected) ||
      (total === totalCurrentPage && isCurrentPageSelected))
  ) {
    // if there is no filter and the whole table is selected
    // or if the whole selection is also the whole table (special case)
    return {
      message: pluralize(
        total,
        `All 1 object is selected.`,
        `All ${total} objects are selected.`
      ),
      actionMessage: "Clear selection",
      type: actionTypeEnum.clearSelection,
    };
  }

  if (total === totalCurrentPage && isCurrentPageSelected) {
    // only the whole current page is selected
    const isFilteredSuffix = optionalStr(isFiltered, " that match the search");
    return {
      message: pluralize(
        totalCurrentPage,
        `All 1 object on this page is selected.`,
        `All ${totalCurrentPage} objects on this page are selected.`
      ),
      actionMessage: `Select all ${totalRows} objects${isFilteredSuffix}`,
      type: actionTypeEnum.selectAll,
    };
  }

  const otherPagesSuffix = optionalStr(
    totalOtherPage > 0,
    pluralize(
      totalOtherPage,
      ` (1 is on other page)`,
      ` (${totalOtherPage} are on other pages)`
    )
  );
  return {
    message: pluralize(
      total,
      `1 object is selected${otherPagesSuffix}.`,
      `${total} objects are selected${otherPagesSuffix}.`
    ),
    actionMessage: "Clear selection",
    type: actionTypeEnum.clearSelection,
  };
};

export function TableBanner({
  selectionStats,
  isFiltered,
  totalRows,
  onClearAll,
  onSelectAll,
}) {
  const { total, totalOtherPage, totalCurrentPage, isCurrentPageSelected } =
    selectionStats ?? {};
  if (
    total === undefined ||
    totalOtherPage === undefined ||
    totalCurrentPage === undefined ||
    isCurrentPageSelected === undefined
  ) {
    return null;
  }

  if (total === 0 || total < totalCurrentPage + totalOtherPage) {
    // nothing selected or invalid selection
    return null;
  }

  if (total === totalCurrentPage && !isCurrentPageSelected) {
    // if the whole selection is in current page, don't show the banner, unless the whole page is selected
    return null;
  }

  const { message, actionMessage, type } = getAction({
    selectionStats,
    isFiltered,
    totalRows,
  });
  const handleActionClick = () => {
    if (type === actionTypeEnum.selectAll) {
      onSelectAll();
    } else if (type === actionTypeEnum.clearSelection) {
      onClearAll();
    }
  };
  return (
    <div className="table-banner">
      <span>{message}</span>
      <NgButton
        style={{ padding: 0, marginLeft: "4px", fontWeight: 500, fontSize: "12px" }}
        link
        onClick={handleActionClick}
      >
        {actionMessage}
      </NgButton>
    </div>
  );
}
