import React, { useEffect, useState } from "react";
import Button from "../button/ng-button";
import TimeRangeSelector from "../time-range-selector/";
import { MetricConfigType, QueryScope, TableType } from "../../utils/enums";
import subDays from "date-fns/subDays";
import getUnixTime from "date-fns/getUnixTime";
import { v4 as uuidv4 } from "uuid";
import { LabeledSelect } from "../labeled-control/labeled-control";
import TableSchemaView from "./table-schema-view";
import TableSampleDataView from "./table-sample-data-view";
import { HeaderTableIcon } from "./icons";
import { getFullTableName } from "../../utils/datasource";

import "./metric-sample-data-view.scss";

function getDefaultTimePeriod(currentTime) {
  return {
    startTime: subDays(currentTime, 1),
    endTime: currentTime,
    current: currentTime,
  };
}

function getCurrentKpiSampleDataSampleDataListPayload(
  sourceUuid,
  tableUuid,
  configData,
  previewPeriod,
  currentTableList
) {
  const { configType, queryScope = QueryScope.TIME_RANGE, table } = configData.config;
  let normalizedQueryScope = QueryScope.FULL_TABLE;
  let tableConfigPayload = {};
  if (
    configType === MetricConfigType.METRIC_CONFIG &&
    table?.type !== TableType.CUSTOM_SQL
  ) {
    const {
      dataTimezone,
      partitionTimezone,
      timestampColumn,
      timestampColumnFunctions,
      partitions,
    } = configData.config;
    tableConfigPayload = {
      dataTimezone,
      partitionTimezone,
      timestampColumn,
      timestampColumnFunctions,
      partitions,
    };
    normalizedQueryScope = QueryScope.TIME_RANGE;
  } else if (
    configType === MetricConfigType.METRIC_CONFIG &&
    table?.type === TableType.CUSTOM_SQL
  ) {
    const tableUuid = table.tableUuid;
    const tableInfo = currentTableList.find(
      (currentTable) => currentTable.tableUuid === tableUuid
    );

    if (tableInfo?.profilerConfig?.timestampColumn) {
      const {
        dataTimezone,
        partitionTimezone,
        timestampColumn,
        timestampColumnFunctions,
        partitions,
      } = tableInfo.profilerConfig;
      tableConfigPayload = {
        dataTimezone,
        partitionTimezone,
        timestampColumn,
        timestampColumnFunctions,
        partitions,
      };
      normalizedQueryScope = QueryScope.TIME_RANGE;
    } else {
      normalizedQueryScope = QueryScope.FULL_TABLE;
    }
  } else if (
    configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG &&
    queryScope === QueryScope.TIME_RANGE
  ) {
    const currentTable =
      tableUuid === configData.config.sourceTable.table.tableUuid
        ? configData.config.sourceTable
        : configData.config.targetTable;
    const {
      dataTimezone,
      partitionTimezone,
      timestampColumn,
      timestampColumnFunctions,
      partitions,
    } = currentTable;
    tableConfigPayload = {
      dataTimezone,
      partitionTimezone,
      timestampColumn,
      timestampColumnFunctions,
      partitions,
    };
    normalizedQueryScope = QueryScope.TIME_RANGE;
  }

  const normalizedPreviewPeriod =
    normalizedQueryScope === QueryScope.TIME_RANGE
      ? {
          previewStartTs: getUnixTime(previewPeriod.startTime),
          previewEndTs: getUnixTime(previewPeriod.endTime),
        }
      : {};

  return {
    sourceUuid,
    tableUuid,
    previewUuid: uuidv4(),
    queryScope: normalizedQueryScope,
    ...tableConfigPayload,
    ...normalizedPreviewPeriod,
  };
}

function MetricSampleDataView(props) {
  const {
    currentKpiSampleDataTableSchemaList,
    currentKpiSampleDataSampleDataList,
    getCurrentKpiSampleDataTableSchemaList,
    getCurrentKpiSampleDataSampleDataList,
    configData,
    currentTableList = [],
    currentTime = new Date(),
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [currentTimePeriod, setCurrentTimePeriod] = useState(
    getDefaultTimePeriod(currentTime)
  );
  const [currentSelectedTableUuid, setCurrentSelectedTableUuid] = useState("");

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      let defaultTable;
      let defaultSourceUuid;
      if (configData.config.table?.tableUuid) {
        defaultTable = configData.config.table;
        defaultSourceUuid = configData.config.sources[0];
      } else if (configData.config.sourceTable?.table) {
        defaultTable = configData.config.sourceTable.table;
        defaultSourceUuid = configData.config.sourceTable.sourceUuid;
      } else {
        return;
      }

      setCurrentSelectedTableUuid(defaultTable.tableUuid);
      getCurrentKpiSampleDataTableSchemaList(defaultSourceUuid, defaultTable.tableUuid);

      getCurrentKpiSampleDataSampleDataList(
        getCurrentKpiSampleDataSampleDataListPayload(
          defaultSourceUuid,
          defaultTable.tableUuid,
          configData,
          currentTimePeriod,
          currentTableList
        )
      );
    }
  }, [
    isLoaded,
    configData,
    getCurrentKpiSampleDataTableSchemaList,
    getCurrentKpiSampleDataSampleDataList,
    currentTimePeriod,
    currentTableList,
  ]);

  let tableList = [];
  let tableListOptions = [];
  let tableUuidToSourceUuidMapper = {};
  if (configData.config.table?.tableUuid) {
    tableList = [configData.config.table];
    tableListOptions = [
      {
        label: getFullTableName(configData.config.table, true),
        value: configData.config.table.tableUuid,
      },
    ];
    tableUuidToSourceUuidMapper[configData.config.table.tableUuid] =
      configData.config.sources[0];
  } else if (
    configData.config.sourceTable?.table &&
    configData.config.targetTable?.table
  ) {
    tableList = [
      configData.config.sourceTable.table,
      configData.config.targetTable.table,
    ];
    tableListOptions = [
      {
        label: getFullTableName(configData.config.sourceTable.table),
        value: configData.config.sourceTable.table.tableUuid,
      },
      {
        label: getFullTableName(configData.config.targetTable.table),
        value: configData.config.targetTable.table.tableUuid,
      },
    ];
    tableUuidToSourceUuidMapper[configData.config.sourceTable.table.tableUuid] =
      configData.config.sourceTable.sourceUuid;
    tableUuidToSourceUuidMapper[configData.config.targetTable.table.tableUuid] =
      configData.config.targetTable.sourceUuid;
  } else {
    return null;
  }

  const isTimestampConfigured =
    !!configData?.config?.timestampColumn ||
    !!configData?.config?.sourceTable?.timestampColumn;
  return (
    <div className="metric-sample-data-view-container">
      <div className="metric-sample-data-view-header-container">
        <div className="metric-sample-data-view-title-container">
          <HeaderTableIcon />
          {tableListOptions.length <= 1 ? (
            tableListOptions.find(({ value }) => value === currentSelectedTableUuid)
              ?.label || ""
          ) : (
            <LabeledSelect
              label="table"
              size="large"
              options={tableListOptions}
              value={currentSelectedTableUuid}
              enableSorting={true}
              onChange={(newTableUuid) => {
                if (newTableUuid === currentSelectedTableUuid) {
                  return;
                }
                const newTable = tableList.find(
                  ({ tableUuid }) => tableUuid === newTableUuid
                );

                const sourceUuid = tableUuidToSourceUuidMapper[newTableUuid];
                setCurrentSelectedTableUuid(newTableUuid);
                getCurrentKpiSampleDataTableSchemaList(sourceUuid, newTable.tableUuid);

                getCurrentKpiSampleDataSampleDataList(
                  getCurrentKpiSampleDataSampleDataListPayload(
                    sourceUuid,
                    newTableUuid,
                    configData,
                    currentTimePeriod,
                    currentTableList
                  )
                );
              }}
            />
          )}
        </div>
        {
          <div className="metric-sample-data-view-time-range-container">
            {isTimestampConfigured && (
              <>
                <TimeRangeSelector
                  style={{ minWidth: "300px", height: "40px" }}
                  currentTime={currentTimePeriod.current}
                  value={currentTimePeriod}
                  onChange={(newTimePeriod) =>
                    setCurrentTimePeriod({
                      ...currentTimePeriod,
                      ...newTimePeriod,
                    })
                  }
                />
                <Button
                  outline
                  size="large"
                  onClick={() => {
                    getCurrentKpiSampleDataSampleDataList(
                      getCurrentKpiSampleDataSampleDataListPayload(
                        tableUuidToSourceUuidMapper[currentSelectedTableUuid],
                        currentSelectedTableUuid,
                        configData,
                        currentTimePeriod,
                        currentTableList
                      )
                    );
                  }}
                >
                  View
                </Button>
              </>
            )}
          </div>
        }
      </div>
      <div className="metric-sample-data-table-schema-container">
        <TableSchemaView data={currentKpiSampleDataTableSchemaList} />
      </div>
      <div className="metric-sample-data-table-sample-data-container">
        <TableSampleDataView
          tableSchemaList={currentKpiSampleDataTableSchemaList}
          sampleData={currentKpiSampleDataSampleDataList}
        />
      </div>
    </div>
  );
}

export default MetricSampleDataView;
