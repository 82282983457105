import { LabeledInput, LabeledTextArea } from "../../labeled-control/labeled-control";

const defaultOptions = {
  textareaRows: 3,
};

export function MetricDraftInfoFields(props) {
  const {
    configData,
    onConfigDataChange,
    disabled = false,
    options: customOptions = null,
  } = props;

  const { name, description, approverNotes } = configData?.draftMetadata ?? {};
  const options = { ...defaultOptions, ...customOptions };

  return (
    <>
      <LabeledInput
        label="Draft Name"
        staticLabel={true}
        value={name}
        onChange={(e) =>
          onConfigDataChange({
            ...configData,
            draftMetadata: { ...configData.draftMetadata, name: e.target.value },
          })
        }
        disabled={disabled}
      />
      <LabeledTextArea
        label="Draft Description"
        staticLabel={true}
        placeholder="Add a draft description (optional) ..."
        rows={options.textareaRows}
        value={description}
        onChange={(e) =>
          onConfigDataChange({
            ...configData,
            draftMetadata: {
              ...configData.draftMetadata,
              description: e.target.value,
            },
          })
        }
        disabled={disabled}
      />
      <LabeledTextArea
        label="Draft Note"
        staticLabel={true}
        placeholder="Send a note to the approver (optional) ..."
        rows={options.textareaRows}
        value={approverNotes?.notes?.[0]?.note ?? ""}
        onChange={(e) => {
          onConfigDataChange({
            ...configData,
            draftMetadata: {
              ...configData.draftMetadata,
              approverNotes: {
                ...configData.draftMetadata.approverNotes,
                notes: [
                  {
                    note: e.target.value,
                    noteAt: Date.now(),
                  },
                ],
              },
            },
          });
        }}
        disabled={disabled}
      />
    </>
  );
}
