import { connect } from "react-redux";
import NotificationList from "./notification-list";
import { getIntegrationList } from "../../actions/integration/integration-action";
import {
  getNotificationList,
  getNotificationMonitorList,
  updateNotificationMonitorItem,
} from "../../actions/notification/notification-action";
import {
  openWorkspaceTakeover,
  closeTakeover,
} from "../../actions/takeover/takeover-action";

const mapStateToProps = (state) => ({
  integrationList: state.integrationReducer.integrationList,
  notificationList: state.notificationReducer.notificationList,
  notificationMonitorList: state.notificationReducer.notificationMonitorList,
});

const mapDispatchToProps = (dispatch) => ({
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  getNotificationList: (workspaceUuid, queryObject) =>
    dispatch(getNotificationList(workspaceUuid, queryObject)),
  updateNotificationMonitorItem: (workspaceUuid, uuid, newObject) =>
    dispatch(updateNotificationMonitorItem(workspaceUuid, uuid, newObject)),
  getNotificationMonitorList: (workspaceUuid) =>
    dispatch(getNotificationMonitorList(workspaceUuid)),
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick) =>
    dispatch(openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick)),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
