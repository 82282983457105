import React from "react";
import { withRouter } from "react-router-dom";
import { ConfigSummaryCard, ConfigSummaryCardRow } from "../summary-card";
import AggregationMetricConfigDataAssetSummaryCardContent from "./aggregation/";
import CustomSqlMetricConfigDataAssetSummaryCardContent from "./custom-sql/";
import DataDelayMetricConfigDataAssetSummaryCardContent from "./data-delay";
import DataVolumeMetricConfigDataAssetSummaryCardContent from "./data-volume";
import { FullCompareMetricConfigDataAssetSummaryCardContent } from "./full-compare";
import NullPercentMetricConfigDataAssetSummaryCardContent from "./null-percent";
import { AggregationCompareMetricConfigDataAssetSummaryCardContent } from "./aggregation-compare";
import ConformityCountMetricConfigDataAssetSummaryCardContent from "./conformity-count";
import { default as Icon } from "@ant-design/icons";
import {
  DimensionConfigItem,
  MetricNameConfigItem,
  MetricStatusConfigItem,
  MetricTypeConfigItem,
} from "../fields/";
import { MetricCategory } from "../../../utils/enums";
import {
  dimensionTypeOptions,
  getMetricTypeFromConfigData,
  MetricConfigStep,
  metricTypeOptions,
} from "../utils";
import DistributionMetricConfigDataAssetSummaryCardContent from "./distribution";
import { MetricDescriptionIcon, MetricStreamingIcon } from "../fields/icons";
import {
  DataAssetSummary,
  isEmptyDataAssetSummary,
  TargetDataAssetSummary,
} from "./data-asset-summary";
import { IDIcon } from "../../icons/summary";
import MetadataMetricConfigDataAssetSummaryCardContent from "./metadata";
import ActivityMetricConfigDataAssetSummaryCardContent from "./activity";

import "./index.scss";

function MetricConfigBasicStepSummaryCard({ configData, onEditClick, isEdit }) {
  return (
    <ConfigSummaryCard title="Metric Info" onEditClick={onEditClick}>
      <MetricTypeConfigItem
        value={getMetricTypeFromConfigData(configData)}
        options={metricTypeOptions}
        isEdit={false}
      />
      <MetricNameConfigItem value={configData?.metadata?.name} isEdit={false} />
      <MetricStatusConfigItem value={configData?.config?.isLive} isEdit={false} />
      {typeof configData.metadata.description === "string" &&
        configData.metadata.description !== "" && (
          <ConfigSummaryCardRow
            icon={<Icon component={MetricDescriptionIcon} />}
            label={configData.metadata.description}
          />
        )}
      {isEdit && (
        <ConfigSummaryCardRow
          icon={<Icon component={IDIcon} name="metricID" />}
          label={configData.metadata.idSerial}
        />
      )}
      {configData.config.isStreaming && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricStreamingIcon} />}
          label={"Streaming"}
        />
      )}
      <DimensionConfigItem
        value={configData.config?.dimension}
        options={dimensionTypeOptions}
        isEdit={false}
      />
    </ConfigSummaryCard>
  );
}

const summaryViewCardContentMapper = Object.freeze({
  [MetricCategory.AGGREGATION]: AggregationMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.CUSTOM_SQL]: CustomSqlMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.DATA_DELAY]: DataDelayMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.DATA_VOLUME]: DataVolumeMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.FULL_COMPARE]: FullCompareMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.NULL_PERCENT]: NullPercentMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.AGGREGATION_COMPARE]:
    AggregationCompareMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.CONFORMITY_COUNT]:
    ConformityCountMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.DISTRIBUTION]: DistributionMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.ROW_COUNT]: MetadataMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.BYTE_COUNT]: MetadataMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.UPDATE_DELAY]: MetadataMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.TABLE_ACTIVITY]: ActivityMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.COLUMN_ACTIVITY]: ActivityMetricConfigDataAssetSummaryCardContent,
  [MetricCategory.CATEGORY_ACTIVITY]: ActivityMetricConfigDataAssetSummaryCardContent,
});

export function MetricConfigDataAssetSummaryCard(props) {
  const { configData, columnList, ...restProps } = props;

  const metricType = getMetricTypeFromConfigData(configData);
  const MetricConfigDataAssetSummaryCardContent =
    summaryViewCardContentMapper[metricType] || null;
  if (!MetricConfigDataAssetSummaryCardContent) {
    console.log(`Unknown metric type ${metricType} with ${JSON.stringify(configData)}`);
  }

  return (
    MetricConfigDataAssetSummaryCardContent && (
      <MetricConfigDataAssetSummaryCardContent
        configData={configData}
        columnList={columnList.data}
        {...restProps}
      />
    )
  );
}

function MetricConfigSummaryView(props) {
  const {
    configData,
    step,
    isEdit,
    dataSourceList,
    schemaList,
    onStepChange,
    location,
  } = props;

  return (
    <div className="metric-config-summary-view-container">
      <div className="metric-config-summary-view-title-container">
        Review your metric
      </div>
      <div className="metric-config-summary-card-list-container">
        {step === MetricConfigStep.BASIC && (
          <TargetDataAssetSummary location={location} />
        )}
        {(isEdit || step > MetricConfigStep.BASIC) && (
          <MetricConfigBasicStepSummaryCard
            configData={configData}
            onEditClick={() => onStepChange && onStepChange(MetricConfigStep.BASIC)}
            isEdit={isEdit}
          />
        )}
        {!isEmptyDataAssetSummary(configData) && (
          <ConfigSummaryCard
            title="Data Asset"
            onEditClick={() =>
              onStepChange && onStepChange(MetricConfigStep.DATA_ASSET)
            }
          >
            <DataAssetSummary
              configData={configData}
              dataSourceList={dataSourceList}
              schemaList={schemaList}
            />
            {(isEdit || step > MetricConfigStep.DATA_ASSET) && (
              <MetricConfigDataAssetSummaryCard {...props} />
            )}
          </ConfigSummaryCard>
        )}
      </div>
    </div>
  );
}

export default withRouter(MetricConfigSummaryView);
