import {
  SET_APP_USER_LIST,
  SET_APP_WORKSPACE_LIST,
  SET_USER_WORKSPACE_LIST,
  SET_SYSTEM_INTEGRATION_LIST,
} from "../../actions/admin/admin-action-types";

const initialAdminState = {
  appUserList: {
    loading: false,
    data: [],
  },
  appWorkspaceList: {
    loading: false,
    data: [],
  },
  userWorkspaceList: {
    loading: false,
    data: [],
  },
  systemIntegrationList: {
    loading: false,
    data: [],
  },
};

export default function adminReducer(state = initialAdminState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_APP_USER_LIST:
      newState = { ...state, appUserList: action.appUserList };
      return newState;

    case SET_APP_WORKSPACE_LIST:
      newState = { ...state, appWorkspaceList: action.appWorkspaceList };
      return newState;

    case SET_USER_WORKSPACE_LIST:
      newState = { ...state, userWorkspaceList: action.userWorkspaceList };
      return newState;

    case SET_SYSTEM_INTEGRATION_LIST:
      newState = { ...state, systemIntegrationList: action.systemIntegrationList };
      return newState;

    default:
      return state;
  }
}
