import React from "react";

export function EyeIcon(props = {}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0044 2.5C12.5567 2.5 13.0044 2.94772 13.0044 3.5V5.55076C14.1376 5.66529 15.2103 5.96796 16.1919 6.39979L16.9997 5.27267C17.3214 4.82377 17.9461 4.72068 18.395 5.04241C18.8439 5.36413 18.947 5.98885 18.6253 6.43775L17.956 7.37166C18.3311 7.62372 18.6848 7.89348 19.0145 8.17592C20.7522 9.66445 22 11.667 22 13.5C22 15.333 20.7522 17.3355 19.0145 18.8241C17.2376 20.3462 14.7639 21.5 12 21.5C9.23586 21.5 6.76222 20.3453 4.98539 18.8229C3.24791 17.3342 2 15.3316 2 13.5C2 11.6684 3.24791 9.66581 4.98539 8.1771C5.43399 7.79273 5.927 7.43181 6.45788 7.10699L5.81904 6.21558C5.49732 5.76668 5.60042 5.14196 6.04933 4.82025C6.49823 4.49853 7.12295 4.60163 7.44466 5.05054L8.274 6.20774C9.12845 5.87733 10.0448 5.6461 11.0044 5.5499V3.5C11.0044 2.94772 11.4521 2.5 12.0044 2.5ZM12 7.50001C9.79359 7.50001 7.76723 8.4273 6.28669 9.69585C4.76682 10.9981 4 12.4955 4 13.5C4 14.5045 4.76682 16.0019 6.28669 17.3042C7.76723 18.5727 9.79359 19.5 12 19.5C14.2066 19.5 16.233 18.5733 17.7134 17.3052C19.2331 16.0034 20 14.506 20 13.5C20 12.494 19.233 10.9966 17.7134 9.69485C16.233 8.42673 14.2066 7.50001 12 7.50001ZM8 13.5C8 11.2909 9.79087 9.50001 12 9.50001C14.2091 9.50001 16 11.2909 16 13.5C16 15.7091 14.2091 17.5 12 17.5C9.79087 17.5 8 15.7091 8 13.5ZM12 11.5C10.8954 11.5 10 12.3954 10 13.5C10 14.6046 10.8954 15.5 12 15.5C13.1046 15.5 14 14.6046 14 13.5C14 12.3954 13.1046 11.5 12 11.5Z"
        fill="#121224"
        fillOpacity="0.85"
      />
    </svg>
  );
}
