import React, { useState } from "react";
import NgButton, { TextWithIcon } from "../../../../components/button/ng-button";
import { ManageAutoMetricsIcon } from "../../profiler-advanced-setting-menu";
import { getTableMetricsHealthData, getTableColumnHealthData } from "./utils";
import { MetricType } from "../../../../utils/enums";
import {
  getMetricColumnInfo,
  isAutoCreationTypeMetric,
  isAutoMetric,
} from "../../../../utils/metric";
import {
  TableMetricsInfoGridItemPopupContent,
  TableColumnHealthInfoGridItemPopupContent,
  getTableMetricsTableColumns,
  getTableColumnHealthTableColumns,
  TableHealthColumnSummaryPanelMenu,
  TableHealthMetricsGridAndTable,
  TableHealthMetricsPanel,
} from "./table-health-summary-panel-template";
import { hasPermission } from "../../../../utils/uri-path";
import { AppPermissions } from "../../../../utils/permissions";
import { getMetricTypeFromConfigData } from "../../../../components/metric/utils";
import { toggleColumnMetricType } from "../../../../utils/column";
import {
  EVENT,
  PAGE,
  getTableDetailProps,
  trackEvent,
} from "../../../../utils/telemetry";
import { ProfilerTabOptionOperation } from "../../utils";

const COLUMN_MODE_OPTION = "columnHealthMetricView";

function TableColumnHealthInfoSummary(props) {
  const {
    label,
    icon,
    metrics,
    metricInfoMapper = {},
    dataSource,
    schema,
    table,
    workspaceUserPermissions,
    onCreateCustomMetric,
    onSelectNodeChange,
    onManageAutoMetrics,
    onDeleteMetricClick,
    updateColumnConfig,
    refreshTableHealthData,
    setProfilerAfterTreeNavigationAction,
    tabOptions,
    onTabOptionsChange,
    onMonitorMetric,
  } = props;

  const [isExpand, setIsExpand] = useState(true);
  const [focusedItem, setFocusedItem] = useState(null);

  const isColumnMode = !tabOptions.includes(COLUMN_MODE_OPTION);

  const setIsColumnMode = (value) => {
    onTabOptionsChange({
      operation: value
        ? ProfilerTabOptionOperation.REMOVE
        : ProfilerTabOptionOperation.ADD,
      options: [COLUMN_MODE_OPTION],
    });
  };

  const onIsExpandChange = (newIsExpand) => {
    trackEvent(
      isExpand
        ? EVENT.COLLAPSE_COLUMN_HEALTH_METRICS
        : EVENT.EXPAND_COLUMN_HEALTH_METRICS,
      {
        ...getTableDetailProps(table, schema, dataSource),
        page: PAGE.EXPLORER_TABLE_TABLE_HEALTH,
      }
    );

    setIsExpand(newIsExpand);
  };

  const tableColumnList = table.columns || [];
  const actions = {
    onViewColumn: ({ columnUuid }) => {
      const columnInfo = tableColumnList?.find((column) => column.uuid === columnUuid);

      if (!columnInfo) {
        console.log(`${columnUuid} is not found.`);
        return;
      }

      onSelectNodeChange({
        dataSource,
        schemaInfo: schema,
        tableInfo: table,
        columnInfo,
        metric: null,
      });
    },
    onViewMetric: (metric) => {
      const { columnUuid } = getMetricColumnInfo(metric);
      const columnInfo = table.columns.find((column) => column.uuid === columnUuid);
      let tabKey = null;

      if (!columnInfo) {
        console.log(`${columnUuid} is not found.`);
        return;
      }

      const selectedNode = {
        dataSource,
        schemaInfo: schema,
        tableInfo: table,
        columnInfo,
        metric: {
          metricType: MetricType.CUSTOM,
          name: metric.metadata.name,
          uuid: metric.metadata.uuid,
        },
      };

      if (isAutoMetric(metric)) {
        selectedNode.metric = null;
        tabKey = "autoMetrics";
      }

      onSelectNodeChange(selectedNode, tabKey);
    },
    onMonitorMetric: (metric) => {
      onMonitorMetric(metric);
    },
    onToggleMetric: (_metricType, metric, columnUuid, enabled) => {
      // This should only be called for disabling metrics.
      if (enabled) {
        return;
      }
      if (isAutoCreationTypeMetric(metric)) {
        const tableColumn = table.columns.find((col) => col.uuid === columnUuid);
        updateColumnConfig(
          toggleColumnMetricType(
            tableColumn,
            getMetricTypeFromConfigData(metric),
            false
          )
        ).then(() => {
          refreshTableHealthData();
        });
      } else {
        onDeleteMetricClick(metric);
      }
    },
  };

  const data = isColumnMode
    ? getTableColumnHealthData(metrics || [], metricInfoMapper, tableColumnList)
    : getTableMetricsHealthData(metrics || [], metricInfoMapper, true);

  const columns = isColumnMode
    ? getTableColumnHealthTableColumns(
        workspaceUserPermissions,
        actions,
        tableColumnList
      )
    : getTableMetricsTableColumns(workspaceUserPermissions, table, actions, true);
  const tooltipComponent = isColumnMode
    ? TableColumnHealthInfoGridItemPopupContent
    : TableMetricsInfoGridItemPopupContent;

  const canManageColumns = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_COLUMNPROFILERCONFIGDETAILVIEW,
  ]);

  let contextMenu = null;
  if (data.length > 0) {
    contextMenu = (
      <TableHealthColumnSummaryPanelMenu
        isViewColumn={isColumnMode}
        onViewByClick={setIsColumnMode}
        dataSource={dataSource}
        schema={schema}
        table={table}
        onCreateCustomMetric={onCreateCustomMetric}
        setProfilerAfterTreeNavigationAction={setProfilerAfterTreeNavigationAction}
        workspaceUserPermissions={workspaceUserPermissions}
      />
    );
  } else if (canManageColumns) {
    contextMenu = (
      <NgButton outline onClick={onManageAutoMetrics}>
        <TextWithIcon icon={<ManageAutoMetricsIcon />}>Manage columns</TextWithIcon>
      </NgButton>
    );
  }

  return (
    <TableHealthMetricsPanel
      data={data}
      entity={isColumnMode ? "column" : "metric"}
      isExpand={isExpand}
      onIsExpandChange={data.length > 0 ? onIsExpandChange : null}
      icon={icon}
      label={label}
      contextMenu={contextMenu}
    >
      <TableHealthMetricsGridAndTable
        isExpand={isExpand}
        focusedItem={focusedItem}
        onFocusedItemChange={setFocusedItem}
        columns={columns}
        data={data}
        tooltipComponent={tooltipComponent}
      />
    </TableHealthMetricsPanel>
  );
}

export default TableColumnHealthInfoSummary;
