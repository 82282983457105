export function ProfilerConfigureTableIcon() {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none">
      <path
        d="M41.6 44H15.4C13.3 44 11.5 42.3 11.5 40.1V15.9C11.5 13.8 13.2 12 15.4 12H41.7C43.8 12 45.6 13.7 45.6 15.9V40.2C45.5 42.3 43.8 44 41.6 44Z"
        fill="#D5D4FF"
      />
      <path
        d="M41 32.6V36.5C41 38.7 39.2 40.5 37 40.5H12C9.8 40.5 8 38.7 8 36.5V20.5"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.7V11.5C8 9.3 9.8 7.5 12 7.5H37C39.2 7.5 41 9.3 41 11.5V25.6"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 34.5H14V13.5H35V34.5Z"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 13.5V34.5"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 13.5V34.5"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 20.5H35"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 27.5H35"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
