import { connect } from "react-redux";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import {
  getIncident,
  getIncidentData,
  resetCurrentIncident,
  getCurrentGroupIncidentList,
  getCurrentIncidentCommentList,
  addIncidentComment,
  deleteIncidentComment,
  editIncidentComment,
  endIncident,
  updateIncidentStatus,
  updateIncidentValidationStatus,
  getGroupIncidentListMetrics,
  getIncidentSliceValueList,
  addIncidentTicket,
  getIncidentCaseList,
  updateIncidentCase,
  startPollingIncidentValidationStatus,
  getIncidentConfig,
  updateIncidentConfig,
} from "../../actions/incident/incident-action";
import { getIntegrationList } from "../../actions/integration/integration-action";
import {
  closeTakeover,
  openWorkspaceTakeover,
} from "../../actions/takeover/takeover-action";

import IncidentView from "./incident";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  integrationList: state.integrationReducer.integrationList.data,
  currentIncident: state.incidentReducer.currentIncident,
  currentGroupIncidentList: state.incidentReducer.currentGroupIncidentList,
  currentGroupIncidentListMetrics:
    state.incidentReducer.currentGroupIncidentListMetrics,
  currentIncidentMainMetricsData: state.incidentReducer.currentIncidentMainMetricsData,
  currentIncidentCommentList: state.incidentReducer.currentIncidentCommentList,
  jiraTicketResult: state.incidentReducer.incidentJiraTicketResult,
  incidentCaseList: state.incidentReducer.incidentCaseList,
  dataSourceList: state.dataSourceReducer.dataSourceList,
  ruleList: state.incidentReducer.ruleList,
  kpiList: state.incidentReducer.kpiList,
  sliceValueList: state.incidentReducer.sliceValueList,
  config: state.incidentReducer.currentIncidentConfig,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getIncident: (workspaceUuid, uuid) => dispatch(getIncident(workspaceUuid, uuid)),
  getIncidentData: (workspaceUuid, uuid, queryObject) =>
    dispatch(getIncidentData(workspaceUuid, uuid, queryObject)),
  getCurrentIncidentConfig: (workspaceUuid, incidentUuid, opts = {}) =>
    dispatch(getIncidentConfig(workspaceUuid, incidentUuid, opts)),
  updateIncidentConfig: (workspaceUuid, incidentUuid, config) =>
    dispatch(updateIncidentConfig(workspaceUuid, incidentUuid, config)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  getCurrentGroupIncidentList: (workspaceUuid, queryObject) =>
    dispatch(getCurrentGroupIncidentList(workspaceUuid, queryObject)),
  endIncident: (workspaceUuid, uuid) => dispatch(endIncident(workspaceUuid, uuid)),
  updateIncidentStatus: (workspaceUuid, uuid, status) =>
    dispatch(updateIncidentStatus(workspaceUuid, uuid, status)),
  updateIncidentValidationStatus: (workspaceUuid, uuid, validation) =>
    dispatch(updateIncidentValidationStatus(workspaceUuid, uuid, validation)),
  getGroupIncidentListMetrics: (workspaceUuid, options) =>
    dispatch(getGroupIncidentListMetrics(workspaceUuid, options)),
  getIncidentSliceValueList: (workspaceUuid, ruleId, timePeriod) =>
    dispatch(getIncidentSliceValueList(workspaceUuid, ruleId, timePeriod)),
  getIncidentCommentList: (workspaceUuid, incidentUuid) =>
    dispatch(getCurrentIncidentCommentList(workspaceUuid, incidentUuid)),
  addIncidentComment: (workspaceUuid, incidentUuid, incidentComment) =>
    dispatch(addIncidentComment(workspaceUuid, incidentUuid, incidentComment)),
  deleteIncidentComment: (workspaceUuid, incidentUuid, incidentComment) =>
    dispatch(deleteIncidentComment(workspaceUuid, incidentUuid, incidentComment)),
  editIncidentComment: (workspaceUuid, incidentUuid, incidentComment) =>
    dispatch(editIncidentComment(workspaceUuid, incidentUuid, incidentComment)),
  addIncidentTicket: (workspaceUuid, incidentTicketOption) =>
    dispatch(addIncidentTicket(workspaceUuid, incidentTicketOption)),
  getIncidentCaseList: (workspaceUuid, incidentUuid) =>
    dispatch(getIncidentCaseList(workspaceUuid, incidentUuid)),
  updateIncidentCase: (workspaceUuid, newCase) =>
    dispatch(updateIncidentCase(workspaceUuid, newCase)),
  startPollingIncidentValidationStatus: (workspaceUuid, pollManager, uuid) =>
    dispatch(startPollingIncidentValidationStatus(workspaceUuid, pollManager, uuid)),
  resetCurrentIncident: (options) => dispatch(resetCurrentIncident(options)),
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick, className) =>
    dispatch(
      openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick, className)
    ),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentView);
