import { RequestStatus } from "../../utils/enums";
import {
  SET_KPI_LIST,
  SET_KPI_LIST_PAGE_CONFIGURATION,
  SET_KPI_INCIDENT_LIST,
  SET_KPI_LAST_PREVIEW_TIME,
  SET_KPI_LAST_PREVIEW_STATUS,
  SET_KPI_LAST_PREVIEW_RESULT,
  SET_CURRENT_CLONE_KPI,
  SET_CURRENT_EDIT_KPI,
  SET_CURRENT_KPI_TABLE_LIST,
  SET_CURRENT_KPI_SRC_TABLE_LIST,
  SET_CURRENT_KPI_TABLE_COLUMN_LIST,
  SET_CURRENT_KPI_SRC_TABLE_COLUMN_LIST,
  SET_CURRENT_KPI_IN_COLUMN_COLUMN_LIST,
  SET_CURRENT_CUSTOM_SQL_KPI_TABLE_COLUMN_LIST,
  SET_CURRENT_KPI_TABLE_COLUMN_VALUE_LIST,
  SET_CURRENT_KPI_FULL_COMPARE_SOURCE_TABLE_COLUMN_VALUE_LIST,
  SET_CURRENT_KPI_FULL_COMPARE_TARGET_TABLE_COLUMN_VALUE_LIST,
  SET_CURRENT_KPI_QUERY_LIST,
  SET_CURRENT_KPI_COLUMN_INFO,
  SET_CURRENT_KPI_PARTITION_SAMPLE_DATA,
  SET_CURRENT_KPI_SRC_PARTITION_SAMPLE_DATA,
  SET_CURRENT_KPI_SAMPLE_DATA_TABLE_SCHEMA_LIST,
  SET_CURRENT_KPI_SAMPLE_DATA_SAMPLE_DATA_LIST,
  SET_CURRENT_KPI_SCHEMA_LIST,
  SET_CURRENT_KPI_SRC_SCHEMA_LIST,
  SET_CURRENT_KPI_VALIDATE_FAILING_RECORDS_SQL,
} from "../../actions/kpi/kpi-action-types";

const initialKpiState = {
  lastPreviewTime: "",
  lastPreviewStatus: RequestStatus.UNKNOWN,
  lastPreviewResult: null,
  kpiList: {
    loading: false,
    data: [],
    workspaceUuid: null,
    updatedAt: null,
  },
  kpiListPageConfiguration: {
    columns: [],
  },
  kpiIncidentList: [],
  currentCloneKpi: null,
  currentEditKpi: {},
  currentKpiColumnInfo: null,
  currentKpiTableList: [],
  currentKpiSchemaList: [],
  currentKpiSrcTableList: [],
  currentKpiSrcSchemaList: [],
  currentKpiTableColumnList: {
    loading: false,
    data: [],
    isLoaded: false,
  },
  currentCustomSqlKpiColumnList: [],
  currentKpiSrcTableColumnList: [],
  currentKpiInColumnColumnList: [],
  currentKpiTableColumnValueList: [],
  currentKpiFullCompareSrcTableColumnValueList: [],
  currentKpiFullCompareTargetTableColumnValueList: [],
  currentKpiQueryList: {
    loading: true,
    data: [],
  },
  currentKpiPartitionSampleData: {
    loading: false,
    data: null,
  },
  currentKpiSrcPartitionSampleData: {
    loading: false,
    data: null,
  },
  currentKpiSampleDataTableSchemaList: {
    loading: false,
    data: [],
  },
  currentKpiSampleDataSampleDataList: {
    loading: false,
    data: [],
  },
  currentKpiValidateFailingRecordsSqlResult: {
    loading: false,
  },
};

export default function kpiReducer(state = initialKpiState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_KPI_LIST:
      newState = { ...state, kpiList: action.kpiList };
      return newState;

    case SET_KPI_LIST_PAGE_CONFIGURATION:
      newState = {
        ...state,
        kpiListPageConfiguration: action.kpiListPageConfiguration,
      };
      return newState;

    case SET_KPI_INCIDENT_LIST:
      newState = { ...state, kpiIncidentList: action.kpiIncidentList };
      return newState;

    case SET_KPI_LAST_PREVIEW_TIME:
      newState = { ...state, lastPreviewTime: action.lastPreviewTime };
      return newState;

    case SET_KPI_LAST_PREVIEW_STATUS:
      newState = { ...state, lastPreviewStatus: action.lastPreviewStatus };
      return newState;

    case SET_KPI_LAST_PREVIEW_RESULT:
      newState = { ...state, lastPreviewResult: action.lastPreviewResult };
      return newState;

    case SET_CURRENT_CLONE_KPI:
      newState = { ...state, currentCloneKpi: action.currentCloneKpi };
      return newState;

    case SET_CURRENT_EDIT_KPI:
      newState = { ...state, currentEditKpi: action.currentEditKpi };
      return newState;

    case SET_CURRENT_KPI_SCHEMA_LIST:
      newState = {
        ...state,
        currentKpiSchemaList: action.currentKpiSchemaList,
      };
      return newState;

    case SET_CURRENT_KPI_SRC_SCHEMA_LIST:
      newState = {
        ...state,
        currentKpiSrcSchemaList: action.currentKpiSrcSchemaList,
      };
      return newState;

    case SET_CURRENT_KPI_TABLE_LIST:
      newState = { ...state, currentKpiTableList: action.currentKpiTableList };
      return newState;

    case SET_CURRENT_KPI_SRC_TABLE_LIST:
      newState = {
        ...state,
        currentKpiSrcTableList: action.currentKpiSrcTableList,
      };
      return newState;

    case SET_CURRENT_KPI_TABLE_COLUMN_LIST:
      newState = {
        ...state,
        currentKpiTableColumnList: action.currentKpiTableColumnList,
      };
      return newState;

    case SET_CURRENT_CUSTOM_SQL_KPI_TABLE_COLUMN_LIST:
      newState = {
        ...state,
        currentCustomSqlKpiTableColumnList: action.currentCustomSqlKpiTableColumnList,
      };
      return newState;

    case SET_CURRENT_KPI_SRC_TABLE_COLUMN_LIST:
      newState = {
        ...state,
        currentKpiSrcTableColumnList: action.currentKpiSrcTableColumnList,
      };
      return newState;

    case SET_CURRENT_KPI_IN_COLUMN_COLUMN_LIST:
      newState = {
        ...state,
        currentKpiInColumnColumnList: action.currentKpiInColumnColumnList,
      };
      return newState;

    case SET_CURRENT_KPI_TABLE_COLUMN_VALUE_LIST:
      newState = {
        ...state,
        currentKpiTableColumnValueList: action.currentKpiTableColumnValueList,
      };
      return newState;

    case SET_CURRENT_KPI_FULL_COMPARE_SOURCE_TABLE_COLUMN_VALUE_LIST:
      newState = {
        ...state,
        currentKpiFullCompareSrcTableColumnValueList:
          action.currentKpiFullCompareSrcTableColumnValueList,
      };
      return newState;

    case SET_CURRENT_KPI_FULL_COMPARE_TARGET_TABLE_COLUMN_VALUE_LIST:
      newState = {
        ...state,
        currentKpiFullCompareTargetTableColumnValueList:
          action.currentKpiFullCompareTargetTableColumnValueList,
      };
      return newState;

    case SET_CURRENT_KPI_COLUMN_INFO:
      newState = {
        ...state,
        currentKpiColumnInfo: action.currentKpiColumnInfo,
      };
      return newState;

    case SET_CURRENT_KPI_QUERY_LIST:
      newState = { ...state, currentKpiQueryList: action.currentKpiQueryList };
      return newState;

    case SET_CURRENT_KPI_PARTITION_SAMPLE_DATA:
      newState = {
        ...state,
        currentKpiPartitionSampleData: action.currentKpiPartitionSampleData,
      };
      return newState;

    case SET_CURRENT_KPI_SRC_PARTITION_SAMPLE_DATA:
      newState = {
        ...state,
        currentKpiSrcPartitionSampleData: action.currentKpiSrcPartitionSampleData,
      };
      return newState;

    case SET_CURRENT_KPI_SAMPLE_DATA_TABLE_SCHEMA_LIST:
      newState = {
        ...state,
        currentKpiSampleDataTableSchemaList: action.currentKpiSampleDataTableSchemaList,
      };
      return newState;

    case SET_CURRENT_KPI_SAMPLE_DATA_SAMPLE_DATA_LIST:
      newState = {
        ...state,
        currentKpiSampleDataSampleDataList: action.currentKpiSampleDataSampleDataList,
      };
      return newState;

    case SET_CURRENT_KPI_VALIDATE_FAILING_RECORDS_SQL:
      newState = {
        ...state,
        currentKpiValidateFailingRecordsSqlResult:
          action.currentKpiValidateFailingRecordsSqlResult,
      };
      return newState;

    default:
      return state;
  }
}
