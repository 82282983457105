import MetricConfigTabPanel from "../../metric-config-tab-panel";
import MetricConfigSummaryView from "../../summary-view/";
import { TagsConfigItem } from "../../fields/add-tags";
import { MetricDraftInfoFields } from "../../fields/metric-draft-info-fields";
import { MetricDraftNotification } from "../../fields/metric-draft-notification";

import "./index.scss";

function DraftInfoConfig(props) {
  const {
    configData,
    onConfigDataChange,
    integrationList,
    tagList,
    disabled = false,
    defaultNotificationChannels = [],
  } = props;

  return (
    <div className="draft-info-config">
      <div className="draft-info-config-section">
        <div className="draft-info-config-section-title main-title">Draft Info</div>
        <MetricDraftInfoFields
          configData={configData}
          onConfigDataChange={onConfigDataChange}
          disabled={disabled}
          options={{ textareaRows: 6 }}
        />
      </div>
      <div className="draft-info-config-section">
        <div className="draft-info-config-section-title">Approval Notification</div>
        <MetricDraftNotification
          configData={configData}
          onConfigDataChange={onConfigDataChange}
          disabled={disabled}
          integrationList={integrationList}
          defaultNotificationChannels={defaultNotificationChannels}
        />
      </div>

      <div className="draft-info-config-section">
        <div className="draft-info-config-section-title">Tags</div>
        <TagsConfigItem
          value={configData?.metadata?.tags || []}
          tagList={tagList}
          onChange={(tags) => {
            onConfigDataChange({
              ...configData,
              metadata: {
                ...configData.metadata,
                tags,
              },
            });
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

function MetricConfigDraftInfoTabPanel(props) {
  const {
    configData,
    disabled,
    onConfigDataChange,
    integrationList,
    tagList,
    defaultNotificationChannels = [],
  } = props;

  return (
    <MetricConfigTabPanel
      summarySection={<MetricConfigSummaryView {...props} />}
      contentSection={
        <DraftInfoConfig
          configData={configData}
          onConfigDataChange={onConfigDataChange}
          disabled={disabled}
          integrationList={integrationList}
          tagList={tagList}
          defaultNotificationChannels={defaultNotificationChannels}
        />
      }
    />
  );
}

export default MetricConfigDraftInfoTabPanel;
