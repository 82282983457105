import { useRef, useEffect } from "react";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// Resize observer is somewhat new, and might not be supported in every browser.
export const resizeObserverSupported = typeof ResizeObserver !== "undefined";

export function useResizeObserver(ref, callback) {
  useEffect(() => {
    if (resizeObserverSupported) {
      const observer = new ResizeObserver((entries) => {
        callback(entries[0].target);
      });
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, callback]);
}
