import React from "react";
import SummaryViewSectionTitle from "./summary-view-section-title";
import {
  AggregationWindowConfigItem,
  PartitionsConfigItem,
  QueryTimezoneConfigItem,
  SeasonalityConfigItem,
  SliceByColumnsConfigItem,
  SynchronizationDelayConfigItem,
  TimestampColumnConfigItem,
  TimestampTimezoneConfigItem,
  QueryScopeConfigItem,
  DataCollectionScheduleConfigItem,
  BackfillDurationConfigItem,
  MissingValueFillingConfigItem,
  CustomScheduleCollectionConfigItem,
  DataCollectionWindowConfigItem,
} from "../fields";
import {
  isTimestampTimezoneConfigEnabled,
  isTriggeredCollectionMode,
} from "../../../utils/general";
import { aggregationWindowOptions } from "../utils";
import { metricQueryScopeOptions } from "../../../utils/options";
import { CollectionModeType, MetricConfigType } from "../../../utils/enums";
import { isPartitionConfigEnabled } from "../../../utils/datasource";
import { isDataCollectionWindowRequired } from "../metric-utils";
import { isTableOrColumnActivityMetric } from "../../../utils/metric";

function ConfigureMetricSummary(props) {
  const {
    configData,
    columnList = [],
    dataSourceList,
    enableAggregationWindow = true,
    enableBackfillDuration = true,
    enableDataCollectionSchedule = true,
    enableDataCollectionWindow = true,
    enableMissingValueFilling = false,
    enablePartitionConfig = true,
    enableQueryScope = true,
    enableQueryTimezone = true,
    enableSeasonality = false,
    enableSynchronizationDelay = true,
    enableTimestampColumn = true,
  } = props;

  const dataSourceInfo = dataSourceList.find(
    (dataSource) => configData.config?.sources[0] === dataSource.metadata.uuid
  );
  const isPartitionRequired =
    dataSourceInfo && isPartitionConfigEnabled(dataSourceInfo.config.connection.type);

  const isMetricConfigType =
    configData.config.configType === MetricConfigType.METRIC_CONFIG;
  const isFullTableMetricConfigType =
    configData.config.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG;
  const isCustomScheduled =
    configData.config.collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED;

  return (
    <>
      <SummaryViewSectionTitle title="Metric Configuration" />
      {enableAggregationWindow && isMetricConfigType && (
        <AggregationWindowConfigItem
          value={configData.config.aggregation?.aggregationWindow}
          options={aggregationWindowOptions}
          isEdit={false}
        />
      )}
      {enableQueryTimezone && isMetricConfigType && (
        <QueryTimezoneConfigItem
          value={configData.config.queryTimezone}
          isEdit={false}
        />
      )}
      {enableDataCollectionSchedule && (
        <DataCollectionScheduleConfigItem
          value={configData.config.collectionMode}
          isEdit={false}
        />
      )}
      {enableDataCollectionWindow && isDataCollectionWindowRequired(configData) && (
        <DataCollectionWindowConfigItem
          value={configData.config.collectionWindow}
          isEdit={false}
        />
      )}
      <CustomScheduleCollectionConfigItem
        value={configData.config.collectionMode}
        isEdit={false}
      />
      {enableSynchronizationDelay && isMetricConfigType && (
        <SynchronizationDelayConfigItem
          value={configData.config.synchronizationDelay}
          isEdit={false}
        />
      )}
      {enableQueryScope && (
        <QueryScopeConfigItem
          value={configData.config.configType}
          options={metricQueryScopeOptions}
          isEdit={false}
        />
      )}
      {isMetricConfigType && enableTimestampColumn && (
        <TimestampColumnConfigItem
          value={configData.config.timestampColumn}
          columnList={[]}
          isEdit={false}
        />
      )}
      {isTimestampTimezoneConfigEnabled(
        columnList.find(
          ({ columnName }) => columnName === configData.config.timestampColumn
        )?.columnType
      ) &&
        isMetricConfigType &&
        enableTimestampColumn && (
          <TimestampTimezoneConfigItem
            value={configData.config.dataTimezone}
            isEdit={false}
          />
        )}
      {enableMissingValueFilling && (
        <MissingValueFillingConfigItem
          value={configData.config.missingValueFilling}
          isEdit={false}
        />
      )}
      {isMetricConfigType && enableBackfillDuration && (
        <BackfillDurationConfigItem
          value={configData.config.backfillDuration}
          isEdit={false}
        />
      )}
      {enableSeasonality && (
        <SeasonalityConfigItem
          status={configData.status}
          value={configData.config.seasonality || null}
          isEdit={false}
        />
      )}
      {configData.config.sliceByColumns?.length > 0 && (
        <SliceByColumnsConfigItem
          label="Slice"
          value={configData.config.sliceByColumns}
          isEdit={false}
        />
      )}
      {enablePartitionConfig && isPartitionRequired && isMetricConfigType && (
        <PartitionsConfigItem
          partitionTimezone={configData.config.partitionTimezone}
          partitionOffsets={configData.config.partitionOffsets}
          value={configData.config.partitions}
          isEdit={false}
        />
      )}
      {isFullTableMetricConfigType &&
        !isTriggeredCollectionMode(configData.config.collectionMode) && (
          <>
            {!isTableOrColumnActivityMetric(configData) && (
              <SynchronizationDelayConfigItem
                label="Polling delay"
                value={configData.config.pollingDelay}
                isEdit={false}
              />
            )}
            {!isCustomScheduled && (
              <>
                <QueryTimezoneConfigItem
                  label="Polling timezone"
                  value={configData.config.pollingTimezone}
                  isEdit={false}
                />
                <AggregationWindowConfigItem
                  label="Polling interval"
                  value={configData.config.pollingWindow}
                  options={aggregationWindowOptions}
                  isEdit={false}
                />
              </>
            )}
          </>
        )}
    </>
  );
}

export default ConfigureMetricSummary;
