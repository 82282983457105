import React from "react";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { CommentIcon, SubmitTicketIcon, CloseIncidentIcon } from "./icons";
import { toast } from "../../components/toast/toast";

import "./incident-main-view-menu.scss";

export function CopyLinkIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M1.74967 5.54167C1.74967 4.57516 2.53317 3.79167 3.49967 3.79167H8.16634C9.13285 3.79167 9.91634 4.57516 9.91634 5.54167C9.91634 6.50817 9.13285 7.29167 8.16634 7.29167H5.24967C4.92751 7.29167 4.66634 7.55283 4.66634 7.875C4.66634 8.19717 4.92751 8.45833 5.24967 8.45833H8.16634C9.77718 8.45833 11.083 7.1525 11.083 5.54167C11.083 3.93083 9.77718 2.625 8.16634 2.625H3.49967C1.88884 2.625 0.583008 3.93083 0.583008 5.54167C0.583008 6.28097 0.858828 6.95728 1.31234 7.47107C1.52553 7.71261 1.89416 7.73558 2.1357 7.52239C2.37723 7.30919 2.40021 6.94056 2.18701 6.69903C1.91443 6.39021 1.74967 5.98589 1.74967 5.54167Z"
        fill="#121224"
      />
      <path
        d="M5.83301 4.95833C4.22217 4.95833 2.91634 6.26416 2.91634 7.875C2.91634 9.48584 4.22217 10.7917 5.83301 10.7917H10.4997C12.1105 10.7917 13.4163 9.48584 13.4163 7.875C13.4163 7.1357 13.1405 6.45939 12.687 5.9456C12.4738 5.70406 12.1052 5.68108 11.8637 5.89427C11.6221 6.10747 11.5991 6.4761 11.8123 6.71763C12.0849 7.02646 12.2497 7.43078 12.2497 7.875C12.2497 8.84151 11.4662 9.625 10.4997 9.625H5.83301C4.8665 9.625 4.08301 8.84151 4.08301 7.875C4.08301 6.9085 4.8665 6.125 5.83301 6.125H8.74968C9.07184 6.125 9.33301 5.86383 9.33301 5.54167C9.33301 5.2195 9.07184 4.95833 8.74968 4.95833H5.83301Z"
        fill="#121224"
      />
    </svg>
  );
}

function IncidentMainViewMenu(props) {
  const { mainViewMenuConfig = {} } = props;
  const menuItems = [];
  menuItems.push({
    label: "Copy link",
    icon: <CopyLinkIcon />,
    onClick: () => {
      navigator.clipboard.writeText(window.location.href);
      toast("Incident URL is copied to clipboard");
    },
  });

  mainViewMenuConfig.enableSubmitTicket &&
    menuItems.push({
      label: "Submit ticket",
      icon: <SubmitTicketIcon width={14} />,
      onClick: mainViewMenuConfig.onSubmitTicketClick,
    });

  mainViewMenuConfig.enableAddComment &&
    menuItems.push({
      label: "Add comment",
      icon: <CommentIcon width={14} />,
      onClick: mainViewMenuConfig.onAddCommentClick,
    });

  mainViewMenuConfig.enableCloseIncident &&
    menuItems.push({
      label: "End incident",
      icon: <CloseIncidentIcon width={14} />,
      onClick: mainViewMenuConfig.onCloseIncidentClick,
    });

  return (
    <NgDropdownMenu
      menuItems={menuItems}
      buttonProps={{ testId: "incident-main-actions-menu" }}
    />
  );
}

export default IncidentMainViewMenu;
