import React, { useState } from "react";
import { SliceTrainingStatus } from "../../../../utils/enums";
import LegacyModalDialogAdapter from "../../../legacy-adapter/legacy-modal-dialog-adapter";
import ModalConfirmationButtons from "../../../modal-dialog/modal-confirmation-buttons";
import { getSliceDisplayString, getUniqueSliceValue } from "../../../../utils/general";
import { fnSorter } from "../../../../utils/sort";
import NgTable from "../../../table/ng-table";
import Search from "../../../search";
import NgButton, { ButtonPaddingSize } from "../../../button/ng-button";
import Popover from "../../../popover";
import { getStringCompareFunction } from "../../../../utils/search";
import { getSliceByColumns } from "../../../../utils/metric";
import Switch from "../../../../atom/switch";

import "./rule-training-slice-info-summary-dialog.scss";

function getSearchOptions(sliceInfoSummary, metric, sliceByColumn) {
  return [
    {
      label: "Slice",
      type: "slice",
      items: sliceInfoSummary.map((sliceInfo) =>
        getSliceDisplayString(getUniqueSliceValue(sliceInfo.slice, sliceByColumn))
      ),
    },
  ];
}

function getFilteredRows(rows, searchItem, enabledTrainingStatuses, sliceByColumn) {
  const { slice = [], searchName = [] } = searchItem;

  const enabledStatusRows = rows.filter((row) =>
    enabledTrainingStatuses.has(row.trainingStatus)
  );

  if (slice.length === 0 && searchName.length === 0) {
    return enabledStatusRows;
  }

  const stringCompareFunction = getStringCompareFunction(false);
  return enabledStatusRows.filter(
    (row) =>
      stringCompareFunction(
        getSliceDisplayString(getUniqueSliceValue(row.slice, sliceByColumn)),
        slice
      ) &&
      stringCompareFunction(
        getSliceDisplayString(getUniqueSliceValue(row.slice, sliceByColumn)),
        searchName
      )
  );
}

function FilterIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.22007 2.38119C1.31529 2.1743 1.52221 2.04175 1.74997 2.04175H12.25C12.4777 2.04175 12.6846 2.1743 12.7799 2.38119C12.8751 2.58809 12.8412 2.83148 12.6931 3.00448L8.6333 7.74588V12.2501C8.6333 12.4517 8.52915 12.6391 8.35787 12.7455C8.18659 12.852 7.97249 12.8624 7.79168 12.7731L5.69168 11.7361C5.49263 11.6378 5.36663 11.435 5.36663 11.213V7.74588L1.30687 3.00448C1.15874 2.83148 1.12484 2.58809 1.22007 2.38119ZM3.01739 3.20841L6.39306 7.15087C6.48356 7.25656 6.5333 7.39112 6.5333 7.53027V10.8505L7.46663 11.3114V7.53027C7.46663 7.39112 7.51637 7.25656 7.60687 7.15087L10.9825 3.20841H3.01739Z"
        fill="#121224"
      />
    </svg>
  );
}

function FailedSliceIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M4.95837 6.41659C4.63621 6.41659 4.37504 6.67775 4.37504 6.99992C4.37504 7.32209 4.63621 7.58325 4.95837 7.58325H9.04171C9.36387 7.58325 9.62504 7.32209 9.62504 6.99992C9.62504 6.67775 9.36387 6.41659 9.04171 6.41659H4.95837Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41348 0.754106C7.18578 0.5264 6.81663 0.526286 6.58878 0.753851L5.22049 2.12045H2.7039C2.38173 2.12045 2.12056 2.38161 2.12056 2.70378V5.22013L0.754227 6.58648C0.526524 6.81418 0.526408 7.18333 0.753969 7.41118L2.12056 8.77949V11.2961C2.12056 11.6182 2.38173 11.8794 2.7039 11.8794H5.22049L6.58878 13.246C6.81663 13.4736 7.18578 13.4734 7.41348 13.2457L8.77983 11.8794H11.2962C11.6183 11.8794 11.8795 11.6182 11.8795 11.2961V8.77949L13.2461 7.41118C13.4737 7.18333 13.4736 6.81418 13.2459 6.58648L11.8795 5.22013V2.70378C11.8795 2.38161 11.6183 2.12045 11.2962 2.12045H8.77983L7.41348 0.754106ZM5.87413 3.11651L7.00075 1.99129L8.12573 3.11626C8.23512 3.22566 8.38349 3.28711 8.5382 3.28711H10.7128V5.46176C10.7128 5.61647 10.7743 5.76484 10.8837 5.87424L12.0087 6.99922L10.8834 8.12586C10.7742 8.23524 10.7128 8.3835 10.7128 8.53808V10.7127H8.5382C8.38349 10.7127 8.23512 10.7742 8.12573 10.8836L7.00075 12.0085L5.87413 10.8833C5.76476 10.7741 5.61649 10.7127 5.46191 10.7127H3.28723V8.53808C3.28723 8.3835 3.22587 8.23524 3.11663 8.12586L1.9914 6.99921L3.11638 5.87423C3.22577 5.76484 3.28723 5.61647 3.28723 5.46176V3.28711H5.46191C5.61649 3.28711 5.76476 3.22575 5.87413 3.11651Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00068 1.99121L5.87406 3.11644C5.76468 3.22568 5.61642 3.28704 5.46184 3.28704H3.28716V5.46168C3.28716 5.61639 3.2257 5.76476 3.11631 5.87416L1.99133 6.99914L3.11656 8.12579C3.2258 8.23516 3.28716 8.38342 3.28716 8.538V10.7126H5.46184C5.61642 10.7126 5.76469 10.774 5.87406 10.8832L7.00068 12.0085L8.12565 10.8835C8.23505 10.7741 8.38342 10.7126 8.53813 10.7126H10.7128V8.538C10.7128 8.38342 10.7741 8.23516 10.8834 8.12578L12.0086 6.99914L10.8836 5.87416C10.7742 5.76476 10.7128 5.61639 10.7128 5.46168V3.28704H8.53813C8.38342 3.28704 8.23505 3.22558 8.12565 3.11618L7.00068 1.99121ZM4.9583 6.41651C4.63614 6.41651 4.37497 6.67768 4.37497 6.99984C4.37497 7.32201 4.63614 7.58318 4.9583 7.58318H9.04164C9.3638 7.58318 9.62497 7.32201 9.62497 6.99984C9.62497 6.67768 9.3638 6.41651 9.04164 6.41651H4.9583Z"
        fill="#EA386A"
      />
    </svg>
  );
}

function TrainedSliceIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46277 2.46265C3.62334 1.3021 5.2283 0.583252 7.00004 0.583252C8.77178 0.583252 10.3767 1.3021 11.5373 2.46265C12.6978 3.62321 13.4167 5.22818 13.4167 6.99992C13.4167 8.77166 12.6979 10.3766 11.5373 11.5372C10.3767 12.6977 8.77178 13.4166 7.00004 13.4166C5.2283 13.4166 3.62333 12.6977 2.46277 11.5372C1.30223 10.3766 0.583374 8.77166 0.583374 6.99992C0.583374 5.22818 1.30223 3.62321 2.46277 2.46265ZM10.3292 5.6624C10.557 5.43459 10.557 5.06525 10.3292 4.83744C10.1014 4.60963 9.73203 4.60963 9.50423 4.83744L6.41671 7.92496L5.07919 6.58744C4.85138 6.35963 4.48203 6.35963 4.25423 6.58744C4.02642 6.81525 4.02642 7.18459 4.25423 7.4124L6.00423 9.1624C6.23203 9.3902 6.60138 9.3902 6.82919 9.1624L10.3292 5.6624Z"
        fill="#5CCAB4"
      />
    </svg>
  );
}

function UntrainedSliceIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99993 1.74993L2.33329 4.34251L2.33329 9.48938L6.99995 12.082L11.6666 9.48938L11.6666 4.34251L6.99993 1.74993ZM6.43339 0.730057C6.78576 0.534317 7.21416 0.534317 7.56653 0.730057L12.2332 3.32266C12.6036 3.52842 12.8333 3.91884 12.8333 4.34251V9.48938C12.8333 9.91305 12.6036 10.3035 12.2332 10.5092L7.56655 13.1018C7.21417 13.2976 6.78575 13.2976 6.43338 13.1018L1.76673 10.5092C1.76672 10.5092 1.76674 10.5092 1.76673 10.5092C1.39633 10.3035 1.16663 9.91303 1.16663 9.48938V4.34251C1.16663 3.91886 1.39631 3.52843 1.76671 3.32266C1.76672 3.32266 1.7667 3.32267 1.76671 3.32266L6.43339 0.730057Z"
        fill="#121224"
      />
    </svg>
  );
}

function SliceTrainingStatusCell(props) {
  const { trainingStatus } = props;
  switch (trainingStatus) {
    case SliceTrainingStatus.TRAINING_FAILED:
      return <FailedSliceIcon />;
    case SliceTrainingStatus.TRAINED:
      return <TrainedSliceIcon />;
    case SliceTrainingStatus.UNTRAINED:
      return <UntrainedSliceIcon />;
    default:
      return null;
  }
}

function FilterSwitch(props) {
  const { trainingStatus, enabledTrainingStatuses, onEnabledTrainingStatusesChange } =
    props;

  const onChange = (enabled) => {
    const newEnabledStatuses = new Set([...enabledTrainingStatuses]);
    if (enabled) {
      newEnabledStatuses.add(trainingStatus);
    } else {
      newEnabledStatuses.delete(trainingStatus);
    }
    onEnabledTrainingStatusesChange(newEnabledStatuses);
  };

  return (
    <Switch checked={enabledTrainingStatuses.has(trainingStatus)} onChange={onChange} />
  );
}

function RuleTrainingSliceInfoSummaryTable(props) {
  const { rows, initialEnabledTrainingStatuses, metric } = props;

  const [searchItem, setSearchItem] = useState({});
  const [enabledTrainingStatuses, setEnabledTrainingStatuses] = useState(
    initialEnabledTrainingStatuses
      ? new Set(initialEnabledTrainingStatuses)
      : new Set([
          SliceTrainingStatus.TRAINING_FAILED,
          SliceTrainingStatus.TRAINED,
          SliceTrainingStatus.UNTRAINED,
        ])
  );

  const columns = [
    {
      title: "Slice",
      key: "slice",
      dataIndex: ["slice"],
      defaultSortOrder: "ascend",
      render: function (slice) {
        return getSliceDisplayString(getUniqueSliceValue(slice, sliceByColumn));
      },
      sorter: {
        compare: fnSorter((row) =>
          getSliceDisplayString(
            getUniqueSliceValue(row.slice, sliceByColumn)
          ).toLowerCase()
        ),
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: ["trainingStatus"],
      sorter: { compare: fnSorter((row) => row.trainingStatus) },
      render: function (trainingStatus) {
        return <SliceTrainingStatusCell trainingStatus={trainingStatus} />;
      },
    },
    {
      title: "Observed On",
      key: "observedOn",
      dataIndex: ["firstObserveTsStr"],
    },
    {
      title: "Last Seen On",
      key: "lastSeenOn",
      dataIndex: ["lastObserveTsStr"],
    },
  ];

  const sliceByColumn = getSliceByColumns(metric);
  const searchOptions = getSearchOptions(rows, metric, sliceByColumn);
  const filteredRows = getFilteredRows(
    rows,
    searchItem,
    enabledTrainingStatuses,
    sliceByColumn
  );
  const filterPopover = (
    <div className="rule-training-slice-info-table-filters">
      <div className="rule-training-slice-info-table-filters-title">Filters</div>
      <div className="rule-training-slice-info-table-filters-row">
        <div className="rule-training-slice-info-table-filters-label">Trained</div>
        <FilterSwitch
          trainingStatus={SliceTrainingStatus.TRAINED}
          enabledTrainingStatuses={enabledTrainingStatuses}
          onEnabledTrainingStatusesChange={setEnabledTrainingStatuses}
        />
      </div>
      <div className="rule-training-slice-info-table-filters-row">
        <div className="rule-training-slice-info-table-filters-label">Untrained</div>
        <FilterSwitch
          trainingStatus={SliceTrainingStatus.UNTRAINED}
          enabledTrainingStatuses={enabledTrainingStatuses}
          onEnabledTrainingStatusesChange={setEnabledTrainingStatuses}
        />
      </div>
      <div className="rule-training-slice-info-table-filters-row">
        <div className="rule-training-slice-info-table-filters-label">Failed</div>
        <FilterSwitch
          trainingStatus={SliceTrainingStatus.TRAINING_FAILED}
          enabledTrainingStatuses={enabledTrainingStatuses}
          onEnabledTrainingStatusesChange={setEnabledTrainingStatuses}
        />
      </div>
    </div>
  );

  return (
    <div className="rule-training-slice-info-table">
      <div className="rule-training-slice-info-table-controls">
        <div className="rule-training-slice-info-table-controls-search">
          <Search
            label="Search"
            placeholder="Search..."
            localFilterSetting={searchItem}
            selectionOptionList={searchOptions}
            onChange={setSearchItem}
            className
          />
        </div>
        <Popover trigger="click" content={filterPopover} placement="bottomRight">
          <NgButton outline size="large" paddingSize={ButtonPaddingSize.SMALL}>
            <FilterIcon />
          </NgButton>
        </Popover>
      </div>
      {filteredRows.length === 0 && (
        <div className="rule-training-slice-info-table-controls-spacer" />
      )}
      <NgTable
        columns={columns}
        dataSource={filteredRows}
        rowKey={(row) =>
          getSliceDisplayString(getUniqueSliceValue(row.slice, sliceByColumn))
        }
        pagination={{
          position: ["topRight"],
          hideOnSinglePage: false,
          size: "default",
          showSizeChanger: true,
        }}
        sortDirections={["ascend", "descend", "ascend"]}
        scroll={{ y: 250 }}
      />
    </div>
  );
}

function RuleTrainingSliceInfoSummaryDialogBodyComponent(props) {
  const { rawSliceInfoSummary, sliceInfoSummary, enabledTrainingStatuses, metric } =
    props;

  const totalSlices = rawSliceInfoSummary.length;
  let trainedSlice = 0;
  let untrainedSlice = 0;
  let trainingFailedSlice = 0;
  rawSliceInfoSummary.forEach((rawSliceInfoItem) => {
    if (rawSliceInfoItem.trainingStatus === SliceTrainingStatus.TRAINED) {
      trainedSlice = trainedSlice + 1;
    } else if (rawSliceInfoItem.trainingStatus === SliceTrainingStatus.UNTRAINED) {
      untrainedSlice = untrainedSlice + 1;
    } else if (
      rawSliceInfoItem.trainingStatus === SliceTrainingStatus.TRAINING_FAILED
    ) {
      trainingFailedSlice = trainingFailedSlice + 1;
    }
  });

  return (
    <div className="rule-training-slice-info-summary-dialog-body-container">
      <div className="rule-training-slice-info-summary-number-container">
        <div className="rule-training-slice-info-summary-number-description">
          This is an inventory of all observed slices during live detection.
        </div>
        <div className="rule-training-slice-info-summary-number-counts">
          <div>
            Total observed slices:&nbsp;
            <span className="rule-training-slice-info-summary-number-count">
              {totalSlices}
            </span>
          </div>
          <div>
            Total trained slices:&nbsp;
            <span className="rule-training-slice-info-summary-number-count">
              {trainedSlice}
            </span>
          </div>
          <div>
            Total failed slices:&nbsp;
            <span className="rule-training-slice-info-summary-number-count">
              {trainingFailedSlice}
            </span>
          </div>
          <div>
            Total untrained slices:&nbsp;
            <span className="rule-training-slice-info-summary-number-count">
              {untrainedSlice}
            </span>
          </div>
        </div>
      </div>
      <RuleTrainingSliceInfoSummaryTable
        rows={sliceInfoSummary}
        initialEnabledTrainingStatuses={enabledTrainingStatuses}
        metric={metric}
      />
    </div>
  );
}

function RuleTrainingSliceInfoSummaryDialog(props) {
  const {
    sliceInfoSummary = [],
    trainingStatusOptionList,
    triggerComponent,
    modalIsOpen,
    setIsOpen,
    metric,
  } = props;

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <LegacyModalDialogAdapter
      title={"Slice Inventory"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"rule-training-slice-info-summary-dialog-container"}
      width={815}
      triggerComponent={triggerComponent}
      contentComponent={
        <RuleTrainingSliceInfoSummaryDialogBodyComponent
          rawSliceInfoSummary={sliceInfoSummary}
          sliceInfoSummary={sliceInfoSummary}
          enabledTrainingStatuses={trainingStatusOptionList}
          metric={metric}
        />
      }
      footerComponent={
        <ModalConfirmationButtons cancelText={null} onOkClick={() => closeModal()} />
      }
    />
  );
}

export default RuleTrainingSliceInfoSummaryDialog;
