export const SET_INCIDENT_LIST = "SET_INCIDENT_LIST";
export const SET_INCIDENT_LIST_PAGE_CONFIGURATION =
  "SET_INCIDENT_LIST_PAGE_CONFIGURATION";
export const SET_INCIDENT_PAGINATION_PARAMS = "SET_INCIDENT_PAGINATION_PARAMS";
export const SET_CURRENT_INCIDENT = "SET_CURRENT_INCIDENT";
export const SET_CURRENT_GROUP_INCIDENT_LIST = "SET_CURRENT_GROUP_INCIDENT_LIST";
export const SET_CURRENT_GROUP_INCIDENT_LIST_METRICS =
  "SET_CURRENT_GROUP_INCIDENT_LIST_METRICS";
export const SET_CURRENT_INCIDENT_METRICS_DATA = "SET_CURRENT_INCIDENT_METRICS_DATA";
export const SET_CURRENT_INCIDENT_COMMENT_LIST = "SET_CURRENT_INCIDENT_COMMENT_LIST";
export const SET_CURRENT_INCIDENT_CORRELATED_METRICS =
  "SET_CURRENT_INCIDENT_CORRELATED_METRICS";
export const SET_CURRENT_INCIDENT_USER_DEFINED_RELATED_METRICS =
  "SET_CURRENT_INCIDENT_USER_DEFINED_RELATED_METRICS";
export const SET_CURRENT_INCIDENT_CONFIG = "SET_CURRENT_INCIDENT_CONFIG";
export const SET_CURRENT_INCIDENT_INTEREST_METRIC_SLICE_VALUE_LIST =
  "SET_CURRENT_INCIDENT_INTEREST_METRIC_SLICE_VALUE_LIST";
export const SET_INCIDENT_KPI_LIST = "SET_INCIDENT_KPI_LIST";
export const SET_INCIDENT_RULE_LIST = "SET_INCIDENT_RULE_LIST";
export const SET_INCIDENT_SLICE_VALUE_LIST = "SET_INCIDENT_SLICE_VALUE_LIST";
export const SET_INCIDENT_FILE_TICKET_RESULT = "SET_INCIDENT_FILE_TICKET_RESULT";
export const SET_INCIDENT_CASE_LIST = "SET_INCIDENT_CASE_LIST";
export const SET_INCIDENT_LIST_FOR_SUMMARY = "SET_INCIDENT_LIST_FOR_SUMMARY";
export const SET_INCIDENT_SAMPLE_DATA = "SET_INCIDENT_SAMPLE_DATA";
export const SET_LEFT_PANEL_COLLAPSED = "SET_LEFT_PANEL_COLLAPSED";
export const SET_INCIDENT_LIST_USER_SETTING = "SET_INCIDENT_LIST_USER_SETTING";
