import NgButton from "../../components/button/ng-button";
import { EventObjectType } from "../../utils/enums";
import { MonitorActions } from "../monitors/monitor-actions-menu";
import { MetricActions } from "../profiler/profiler-metric-actions-menu";
import NotificationMetricActionsMenu from "./notification-metric-actions-menu";
import NotificationMonitorActionsMenu from "./notification-monitor-actions-menu";

function NotificationNameCell(props) {
  const {
    monitor,
    metric,
    integration,
    actionsMenuCommonProps,
    eventRecord: { objectType, metricName, monitorName },
  } = props;
  if (objectType === EventObjectType.MONITOR) {
    return (
      <NotificationMonitorActionsMenu
        {...actionsMenuCommonProps}
        actions={[MonitorActions.VIEW_EDIT]}
        monitor={monitor}
        trigger={
          <NgButton link className="notification-view-detailed-info-cell-link">
            {monitorName || monitor.metadata.name}
          </NgButton>
        }
      />
    );
  }
  if (objectType === EventObjectType.METRIC) {
    return (
      <NotificationMetricActionsMenu
        {...actionsMenuCommonProps}
        actions={[MetricActions.VIEW_EDIT, MetricActions.VIEW_IN_EXPLORER]}
        metric={metric}
        trigger={
          <NgButton link className="notification-view-detailed-info-cell-link">
            {metricName}
          </NgButton>
        }
      />
    );
  }
  if (objectType === EventObjectType.INTEGRATION) {
    return integration.title;
  }
  return null;
}

export default NotificationNameCell;
