import { useCallback, useMemo, useState, useRef, useEffect } from "react";

export const useTableCustomSelection = ({
  onSelectedRowsChange,
  getRowKeyFn,
  originalRows,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPageRowsKeys, setCurrentPageRowsKeys] = useState([]);
  const [currentDataSet, setCurrentDataSet] = useState({});
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const dataSet = useMemo(() => {
    return originalRows.reduce((map, row) => {
      map[getRowKeyFn(row)] = row;
      return map;
    }, {});
  }, [originalRows, getRowKeyFn]);

  useEffect(
    () => {
      setSelectedRowKeys(
        selectedRowKeys.filter((selectedRowKey) => !!dataSet[selectedRowKey])
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSet]
  );

  const selectionStats = useMemo(() => {
    const total = selectedRowKeys.length;
    let totalOtherPage = 0;
    let totalCurrentPage = 0;
    selectedRowKeys.forEach((key) => {
      if (currentPageRowsKeys.includes(key)) {
        totalCurrentPage += 1;
      } else {
        totalOtherPage += !!currentDataSet[key];
      }
    });
    return {
      total,
      totalCurrentPage,
      totalOtherPage,
      isCurrentPageSelected:
        totalCurrentPage > 0 && currentPageRowsKeys.length === totalCurrentPage,
    };
  }, [selectedRowKeys, currentPageRowsKeys, currentDataSet]);

  const onSelectionChange = useCallback(
    (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      if (onSelectedRowsChange) {
        onSelectedRowsChange(newSelectedRowKeys.map((key) => dataSet[key]));
      }
    },
    [dataSet, onSelectedRowsChange]
  );

  const onCurrentPageRowsKeysChange = useCallback(
    (newPageRowsKeys) => {
      if (!isMountedRef.current) {
        // avoid computing currentPageRowsKeys when the component is unmounted,
        // that can happen because onCurrentPageRowsKeysChange is called within a setTimeout
        return;
      }
      const currentPageRowsKeysMap = currentPageRowsKeys.reduce(
        (map, key) => ({ ...map, [key]: true }),
        {}
      );
      const sameKeys =
        Object.keys(currentPageRowsKeysMap).length === newPageRowsKeys.length &&
        newPageRowsKeys.every((key) => currentPageRowsKeysMap[key]);
      if (!sameKeys) {
        setCurrentPageRowsKeys(newPageRowsKeys);
      }
    },
    [currentPageRowsKeys]
  );

  const onDataSetChange = useCallback(
    (newDataSet) => {
      const dataSetByKey = newDataSet.reduce((acc, row) => {
        acc[getRowKeyFn(row)] = row;
        return acc;
      }, {});
      setCurrentDataSet(dataSetByKey);
    },
    [getRowKeyFn]
  );

  const clearAll = useCallback(() => {
    onSelectionChange([]);
  }, [onSelectionChange]);

  const selectAll = useCallback(() => {
    onSelectionChange(Object.keys(currentDataSet));
  }, [currentDataSet, onSelectionChange]);

  return {
    selectedRowKeys,
    selectionStats,
    onSelectionChange,
    onCurrentPageRowsKeysChange,
    onDataSetChange,
    selectAll,
    clearAll,
  };
};
