import * as types from "./schedule-action-types";
import {
  getScheduleListPromise,
  addSchedulePromise,
  updateSchedulePromise,
  deleteSchedulePromise,
} from "../../rest/schedule";

function setScheduleList(scheduleList) {
  return { type: types.SET_SCHEDULE_LIST, scheduleList };
}

export function getScheduleList(workspaceUuid) {
  return (dispatch, getState) => {
    dispatch(setScheduleList({ data: [], loading: true }));
    getScheduleListPromise(workspaceUuid)
      .then(function (scheduleList) {
        dispatch(setScheduleList({ data: scheduleList, loading: false }));
      })
      .catch(function (error) {
        dispatch(setScheduleList({ data: [], loading: false }));
        console.log(`Fail to load schedule list for ${error}`);
      });
  };
}

export function addSchedule(workspaceUuid, newSchedule) {
  return (dispatch, getState) => {
    addSchedulePromise(workspaceUuid, newSchedule)
      .then(function () {
        dispatch(getScheduleList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to create schedule for ${error}`);
      });
  };
}

export function editSchedule(workspaceUuid, uuid, newSchedule) {
  return (dispatch, getState) => {
    updateSchedulePromise(workspaceUuid, uuid, newSchedule)
      .then(function (response) {
        dispatch(getScheduleList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to edit schedule for ${error}`);
      });
  };
}

export function deleteSchedule(workspaceUuid, scheduleObj) {
  return (dispatch, getState) => {
    deleteSchedulePromise(workspaceUuid, scheduleObj.id)
      .then(function (response) {
        dispatch(getScheduleList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to delete schedule for ${error}`);
      });
  };
}
