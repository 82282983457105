import React from "react";
import EditSlackIntegrationDialog from "../slack/edit-slack-integration-dialog";
import { AlertingChannelType } from "../../../utils/alerting";

function EditMSTeamsIntegrationDialog(props) {
  return (
    <EditSlackIntegrationDialog
      {...props}
      alertChannelType={AlertingChannelType.MSTEAMS}
    />
  );
}

export default EditMSTeamsIntegrationDialog;
