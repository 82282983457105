import axios from "../utils/api";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";
import { AutoMetricsType } from "../utils/enums";
import queryString from "query-string";

export function getKpiListPromise(workspaceUuid, queryOptions = {}) {
  return new Promise(function (resolve, reject) {
    const {
      dataSourceUuids = [],
      schemaUuid = "",
      tableUuids = [],
      columnUuids = [],
      type = [],
      mode = [],
    } = queryOptions;
    const conditionArray = [];

    if (dataSourceUuids && dataSourceUuids.length > 0) {
      conditionArray.push(`source_uuids=${dataSourceUuids.join(",")}`);
    }

    if (schemaUuid) {
      conditionArray.push(`schema_uuid=${schemaUuid}`);
    }

    if (tableUuids && tableUuids.length > 0) {
      conditionArray.push(`table_uuids=${tableUuids.join(",")}`);
    }

    if (columnUuids && columnUuids.length > 0) {
      conditionArray.push(`column_uuids=${columnUuids.join(",")}`);
    }

    if (type && type.length > 0) {
      conditionArray.push(`type=${type.join(",")}`);
    }

    if (mode && mode.length > 0) {
      conditionArray.push(`mode=${mode.join(",")}`);
    }

    const queryString = conditionArray.length > 0 ? `?${conditionArray.join("&")}` : "";
    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.METRIC_LIST, {
          workspaceUuid,
        })}${queryString}`,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        const data = response.data.filter((kpi) => {
          return !(
            (
              kpi &&
              kpi.config &&
              kpi.config.aggregation &&
              kpi.config.aggregation.type === AutoMetricsType.CATEGORICAL_CARDINALITY
            ) // remove
          );
        });
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function addKpiPromise(workspaceUuid, newKpi) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_LIST, {
          workspaceUuid,
        }),
        newKpi,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getKpiPromise(workspaceUuid, uuid, opts = {}) {
  return new Promise(function (resolve, rejected) {
    const { disableToast = false } = opts;
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.METRIC, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/", disableToast }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function getKpiQueryListPromise(workspaceUuid, uuid, queryOptions = {}) {
  return new Promise(function (resolve, reject) {
    const { startTs, endTs } = queryOptions;

    const queryArray = [];
    if (typeof startTs === "number") {
      queryArray.push(`start_ts=${startTs}`);
    }

    if (typeof endTs === "number") {
      queryArray.push(`end_ts=${endTs}`);
    }

    let queryString = "";
    if (queryArray.length > 0) {
      queryString = `?${queryArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.METRIC_QUERY_HISTORY, {
          workspaceUuid,
          uuid,
        })}${queryString}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getKpiSchemaPromise(workspaceUuid, kpi) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_SCHEMA, {
          workspaceUuid,
        }),
        kpi,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data || []);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getKpiPreviewPromise(
  workspaceUuid,
  kpiPreviewObject,
  requestOptions = {}
) {
  return new Promise((resolve, reject) => {
    const axiosOptions = { baseURL: "/api/v1/" };
    if (requestOptions.hasOwnProperty("disableToast")) {
      axiosOptions.disableToast = requestOptions.disableToast;
    }

    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_PREVIEW, {
          workspaceUuid,
        }),
        kpiPreviewObject,
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data || []);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function terminateKpiPreviewPromise(workspaceUuid, kpi, requestOptions = {}) {
  return new Promise((resolve, reject) => {
    const axiosOptions = { baseURL: "/api/v1/" };
    if (requestOptions.hasOwnProperty("disableToast")) {
      axiosOptions.disableToast = requestOptions.disableToast;
    }

    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_PREVIEW_TERMINATION, {
          workspaceUuid,
        }),
        kpi,
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getKpiTableSchemaSamplesPreviewPromise(
  workspaceUuid,
  metricTableSamplesPreviewObject
) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_TABLE_SAMPLES_PREVIEW, {
          workspaceUuid,
        }),
        metricTableSamplesPreviewObject,
        { baseURL: "/api/v1/", disableToast: true }
      )
      .then(function (response) {
        resolve(response.data || []);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function validateMetricFailingRecordsSqlPromise(workspaceUuid, metricObject) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getAPIURIInstance(
          backendRelativeURIPath.METRIC_FAILING_RECORDS_SQL_VALIDATION,
          {
            workspaceUuid,
          }
        ),
        metricObject,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data || []);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateKpiPromise(workspaceUuid, uuid, newKpi, isForce = false) {
  return new Promise(function (resolve, reject) {
    const axiosOptions = { baseURL: "/api/v1/" };
    if (isForce) {
      axiosOptions.headers = { "X-Lup-Action-Type": "force" };
    }

    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.METRIC, {
          workspaceUuid,
          uuid,
        }),
        newKpi,
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function resumeKpiPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        getAPIURIInstance(backendRelativeURIPath.METRIC_RESUME, {
          workspaceUuid,
          uuid,
        }),
        {},
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteKpiPromise(workspaceUuid, uuid, isForce = true) {
  return new Promise(function (resolve, reject) {
    const axiosOptions = { baseURL: "/api/v1/" };
    if (isForce) {
      axiosOptions.headers = { "X-Lup-Action-Type": "force" };
    }

    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.METRIC, {
          workspaceUuid,
          uuid,
        }),
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getKpiSliceValueListPromise(workspaceUuid, payload, isRaw = false) {
  return new Promise(function (resolve, rejected) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_DISTINCT_VALUES, {
          workspaceUuid,
        }),
        payload,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        const normalizedData = isRaw
          ? response.data
          : response.data.map((currentData) => Object.values(currentData)[0]);
        resolve(normalizedData);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function getKpiCachedSliceValueListPromise(
  workspaceUuid,
  payload,
  isRaw = false,
  args = {}
) {
  const queryArgs = queryString.stringify(args);

  return new Promise(function (resolve, rejected) {
    axios
      .post(
        `${getAPIURIInstance(backendRelativeURIPath.METRIC_SLICE_VALUES, {
          workspaceUuid,
        })}?${queryArgs}`,
        payload,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        const normalizedData = [];
        for (let currentResponseData of response.data) {
          if (Object.keys(currentResponseData.slice).length === 0) {
            continue;
          }
          normalizedData.push(currentResponseData.slice);
        }
        resolve(normalizedData);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function getKpiFileDataPromise(
  workspaceUuid,
  uuid,
  fileUuid,
  isJsonFile = true
) {
  return new Promise(function (resolve, rejected) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.METRIC_FILE_DATA, {
          workspaceUuid,
          uuid,
          fileUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        const { data, ...result } = response.data;
        if (data?.content) {
          try {
            result.content = isJsonFile ? JSON.parse(data.content) : data.content;
          } catch (ex) {
            console.log(`Failed to parse result ${result}`);
          }
        }
        resolve(result);
      })
      .catch(function (err) {
        rejected(err);
      });
  });
}

export function getKpiDownloadListPromise(workspaceUuid) {
  return new Promise(function (resolve, rejected) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.METRIC_DOWNLOAD_LIST, {
          workspaceUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        rejected(err);
      });
  });
}
