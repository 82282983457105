import React from "react";

import "./metric-config-tab-panel.scss";

function MetricConfigTabPanel(props) {
  const { summarySection, contentSection } = props;
  return (
    <div className="metric-config-tab-panel">
      <div className="metric-config-tab-summary-section-panel">{summarySection}</div>
      <div className="metric-config-tab-content-section-panel">{contentSection}</div>
    </div>
  );
}

export default MetricConfigTabPanel;
