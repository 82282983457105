import React from "react";
import { ColumnConditionsConfigItem } from "../../fields";
import SummaryViewSectionTitle from "../summary-view-section-title";
import ConfigureMetricSummary from "../configure-metric-summary";
import WhereConditionsSummary from "../where-conditions-summary";

function ConformityCountMetricConfigDataAssetSummaryCardContent(props) {
  const { columnList, configData, dataSourceList } = props;

  return (
    <>
      <SummaryViewSectionTitle title="Conditions" />
      <ColumnConditionsConfigItem
        value={configData.config.aggregation.conditions}
        isEdit={false}
      />
      <WhereConditionsSummary configData={configData} />
      <ConfigureMetricSummary
        configData={configData}
        columnList={columnList}
        dataSourceList={dataSourceList}
        enableSeasonality={true}
        enableMissingValueFilling={true}
      />
    </>
  );
}

export default ConformityCountMetricConfigDataAssetSummaryCardContent;
