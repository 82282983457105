import React from "react";
import { EventLevel } from "../../utils/enums";
import { getColorFromEventLevel } from "./utils";

function NotificationLevelCell(props) {
  const { value } = props;
  const backgroundColor = getColorFromEventLevel(value);
  let text;
  if (value === EventLevel.ERROR) {
    text = "Error";
  } else if (value === EventLevel.INFO) {
    text = "Info";
  } else if (value === EventLevel.WARNING) {
    text = "Warning";
  } else {
    console.log(`Unknown level ${value}`);
    text = "";
  }

  return (
    <div
      style={{
        width: "fit-content",
        padding: 9,
        fontSize: 12,
        fontWeight: 500,
        background: backgroundColor,
        borderRadius: 6,
      }}
      className="notification-level-cell-container"
    >
      {text}
    </div>
  );
}

export default NotificationLevelCell;
