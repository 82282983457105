import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import DqDashboard from "./dq-dashboard";
import {
  closeTakeover,
  openWorkspaceTakeover,
} from "../../actions/takeover/takeover-action";
import { connect } from "react-redux";
import {
  getDashboardConfiguration,
  getDashboardDqSummary,
  updateDashboardConfiguration,
} from "../../actions/dashboard/dashboard-action";
import {
  dailySummaryData,
  heatmapDataByDimension,
  trendChartDataByDimension,
} from "./dq-dashboard-data";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { fromUnixTime, getUnixTime, isAfter, isBefore, subWeeks } from "date-fns";
import subDays from "date-fns/subDays";

function serverDashboardConfig(componentDashboardConfig) {
  const filters = [];
  if (componentDashboardConfig.statusFilters.length > 0) {
    filters.push({
      type: "status",
      value: {
        type: "intValues",
        intList: componentDashboardConfig.statusFilters,
      },
    });
  }
  if (componentDashboardConfig.dataSourceFilters.length > 0) {
    filters.push({
      type: "datasources",
      value: {
        type: "stringValues",
        strList: componentDashboardConfig.dataSourceFilters,
      },
    });
  }
  return {
    timezone: componentDashboardConfig.timezone,
    evaluationDelay: componentDashboardConfig.evaluationDelay,
    filters,
  };
}

function componentDashboardConfig(serverDashboardConfig) {
  const serverStatusFilters = serverDashboardConfig.filters.find(
    (filter) => filter.type === "status"
  );
  const serverDataSourceFilters = serverDashboardConfig.filters.find(
    (filter) => filter.type === "datasources"
  );
  const statusFilters = serverStatusFilters ? serverStatusFilters.value.intList : [];
  const dataSourceFilters = serverDataSourceFilters
    ? serverDataSourceFilters.value.strList
    : [];
  return {
    timezone: serverDashboardConfig.timezone,
    evaluationDelay: serverDashboardConfig.evaluationDelay,
    statusFilters,
    dataSourceFilters,
  };
}

function DqDashboardContainer(props) {
  const {
    dqSummary,
    workspaceUserPermissions,
    config: serverConfig,
    getDashboardDqSummary,
    getDashboardConfiguration,
    updateDashboardConfiguration,
    match: {
      params: { workspaceUuid },
    },
  } = props;

  const canUpdateConfiguration = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_DASHBOARD_CHART_VIEWS_EDIT_DASHBOARDCONFIGURATIONVIEW,
  ]);

  useEffect(() => {
    getDashboardConfiguration(workspaceUuid);
    getDashboardDqSummary(workspaceUuid);
  }, [workspaceUuid, getDashboardConfiguration, getDashboardDqSummary]);

  function onDashboardSettingsSave(settings) {
    const serverConfig = serverDashboardConfig(settings);
    updateDashboardConfiguration(workspaceUuid, serverConfig).then(() => {
      getDashboardDqSummary(workspaceUuid);
    });
  }

  function onTimeRangeChange(newRange) {
    const twoWeeksBeforeEnd = subWeeks(newRange.endTime, 2);
    const quarterBeforeEnd = subDays(newRange.endTime, 90);
    let rangeStart = newRange.startTime;
    if (isAfter(rangeStart, twoWeeksBeforeEnd)) {
      // Ensure we request at least 2 weeks of data.
      rangeStart = twoWeeksBeforeEnd;
    } else if (isBefore(rangeStart, quarterBeforeEnd)) {
      // Ensure we request no more than 90 days of data.
      rangeStart = quarterBeforeEnd;
    }
    getDashboardDqSummary(
      workspaceUuid,
      getUnixTime(rangeStart),
      getUnixTime(newRange.endTime)
    );
  }

  const config = !serverConfig.loading
    ? componentDashboardConfig(serverConfig.data)
    : {};
  const statusFilters = config.statusFilters ?? [];
  const dataSourceFilters = config.dataSourceFilters ?? [];
  const dailySummary = dailySummaryData(dqSummary.data);
  const trendDataByDim = trendChartDataByDimension(dqSummary.data);
  const heatmapDataByDim = heatmapDataByDimension(dqSummary.data);

  const currentTime = new Date();
  const startTime = dqSummary.data.startTs
    ? fromUnixTime(dqSummary.data.startTs)
    : currentTime;
  const endTime = dqSummary.data.endTs
    ? fromUnixTime(dqSummary.data.endTs)
    : subWeeks(currentTime, 2);

  return (
    <DqDashboard
      canUpdateConfiguration={canUpdateConfiguration}
      loading={dqSummary.loading || serverConfig.loading}
      workspaceUuid={workspaceUuid}
      dailySummary={dailySummary}
      statusFilters={statusFilters}
      dataSourceFilters={dataSourceFilters}
      trendDataByDimensionType={trendDataByDim}
      heatmapDataByDimensionType={heatmapDataByDim}
      currentTime={currentTime}
      startTime={startTime}
      endTime={endTime}
      onDashboardSettingsSave={onDashboardSettingsSave}
      onTimeRangeChange={onTimeRangeChange}
      {...props}
    />
  );
}

const mapStateToProps = (state) => ({
  dqSummary: state.dashboardReducer.dqSummary,
  config: state.dashboardReducer.config,
});

const mapDispatchToProps = (dispatch) => ({
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick) =>
    dispatch(openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick)),
  closeTakeover: () => dispatch(closeTakeover()),
  getDashboardDqSummary: (workspaceUuid, startTs, endTs) =>
    dispatch(getDashboardDqSummary(workspaceUuid, startTs, endTs)),
  getDashboardConfiguration: (workspaceUuid) =>
    dispatch(getDashboardConfiguration(workspaceUuid)),
  updateDashboardConfiguration: (workspaceUuid, dashboardConfig) =>
    dispatch(updateDashboardConfiguration(workspaceUuid, dashboardConfig)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DqDashboardContainer));
