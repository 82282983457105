import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import NgButton from "../button/ng-button";
import { NextGenPalette } from "../../utils/color";
import { DeleteOutlined } from "@ant-design/icons";

import "./file-uploader-zone.scss";

function FileUploaderInputComponent(props) {
  const { onChange } = props;

  function onNewFile(fileList) {
    if (fileList.length === 0) {
      console.log("Invalid drop file list");
      return;
    }

    const currentFile = fileList[0];
    const fileReader = new FileReader();
    fileReader.onabort = () => {
      console.log(`Fail to read file ${currentFile.name}. Aborting`);
    };

    fileReader.onerror = () => {
      console.log(`Fail to read file ${currentFile.name}. Aborting`);
    };

    fileReader.onload = () => {
      const fileText = fileReader.result;
      console.log(fileText);
      if (onChange) {
        onChange({
          fileName: currentFile.name,
          fileSize: currentFile.size,
          fileContent: fileText,
        });
      }
    };

    fileReader.readAsText(currentFile);
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: onNewFile,
  });

  return (
    <div {...getRootProps({ className: "file-uploader-input-container" })}>
      <input {...getInputProps()} data-testid="file-uploader-input" />
      <div className="file-uploader-icon-container">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
          <circle cx="40" cy="40" r="40" fill={NextGenPalette.darkGrey2} />
          <path
            d="M50.1719 38.6406C50.3594 38.125 50.5 37.6094 50.5 37C50.5 34.5156 48.4844 32.5 46 32.5C45.0625 32.5 44.1719 32.7812 43.4688 33.2969C42.2031 31.0469 39.7656 29.5 37 29.5C32.8281 29.5 29.5 32.875 29.5 37C29.5 37.1406 29.5 37.2812 29.5 37.4219C26.875 38.3125 25 40.8438 25 43.75C25 47.5 28 50.5 31.75 50.5H49C52.2812 50.5 55 47.8281 55 44.5C55 41.6406 52.9375 39.2031 50.1719 38.6406ZM43.4219 41.5H40.375V46.75C40.375 47.1719 40 47.5 39.625 47.5H37.375C36.9531 47.5 36.625 47.1719 36.625 46.75V41.5H33.5312C32.875 41.5 32.5469 40.7031 33.0156 40.2344L37.9375 35.3125C38.2188 35.0312 38.7344 35.0312 39.0156 35.3125L43.9375 40.2344C44.4062 40.7031 44.0781 41.5 43.4219 41.5Z"
            fill={NextGenPalette.systemBlack}
          />
        </svg>
      </div>
      <div className="file-uploader-drop-indicator-container">
        Drag and drop key here
      </div>
      <div className="file-uploader-or-container">Or</div>
      <div className="file-uploader-button-container">
        <NgButton size="large" outline onClick={() => open()}>
          Browse for file
        </NgButton>
      </div>
    </div>
  );
}

function FileUploaderInfoComponent({ fileInfo, onRemove }) {
  if (!fileInfo || !fileInfo.fileName || !fileInfo.fileSize) {
    return null;
  }

  const { fileName, fileSize } = fileInfo;
  const displaySize = `${fileSize}B`;
  return (
    <div className="file-uploader-info-container">
      <div className="file-uploader-info-icon-container">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
          <circle cx="40" cy="40" r="40" fill={NextGenPalette.mintGreen} />
          <path
            d="M49 33.7188C49 33.4844 48.8125 33.1094 48.625 32.9219L44.0781 28.375C43.8438 28.1406 43.5625 28 43.2812 28H43V34H49V33.7188ZM42.625 35.5C41.9688 35.5 41.5 35.0312 41.5 34.375V28H32.125C31.4688 28 31 28.5156 31 29.125V50.875C31 51.5312 31.4688 52 32.125 52H47.875C48.4844 52 49 51.5312 49 50.875V35.5H42.625ZM36.7656 46.7969C36.7188 46.8438 36.625 46.8906 36.5781 46.8906C36.5312 46.8906 36.4375 46.8438 36.3906 46.7969L33.3438 43.9375C33.2969 43.9375 33.2969 43.8438 33.2969 43.75C33.2969 43.7031 33.2969 43.6094 33.3438 43.6094L36.3906 40.75C36.4375 40.7031 36.5312 40.6562 36.5781 40.6562C36.625 40.6562 36.7188 40.7031 36.7656 40.75L37.6562 41.7344C37.7031 41.7812 37.75 41.8281 37.75 41.9219C37.75 41.9688 37.7031 42.0625 37.6562 42.1094L35.7344 43.75L37.6562 45.4375C37.7031 45.4844 37.75 45.5781 37.75 45.625C37.75 45.7188 37.7031 45.7656 37.6562 45.8125L36.7656 46.7969ZM39.1562 49.1406L37.8906 48.8125C37.75 48.7656 37.7031 48.6719 37.7031 48.5312V48.4844L40.5625 38.5469C40.6094 38.4531 40.7031 38.3594 40.7969 38.3594C40.8438 38.3594 40.8906 38.3594 40.8906 38.4062L42.1562 38.7344C42.2969 38.7812 42.3438 38.875 42.3438 39.0156V39.0625L39.4844 49C39.4375 49.0938 39.3438 49.1875 39.25 49.1875C39.2031 49.1875 39.1562 49.1875 39.1562 49.1406ZM46.7031 43.9375L43.6562 46.7969C43.6094 46.8438 43.5156 46.8906 43.4688 46.8906C43.4219 46.8906 43.3281 46.8438 43.2812 46.7969L42.3906 45.8125C42.3438 45.7656 42.2969 45.7188 42.2969 45.625C42.2969 45.5781 42.3438 45.4844 42.3906 45.4375L44.3125 43.75L42.3906 42.1094C42.3438 42.0625 42.2969 41.9688 42.2969 41.9219C42.2969 41.8281 42.3438 41.7812 42.3906 41.7344L43.2812 40.75C43.3281 40.7031 43.4219 40.6562 43.4688 40.6562C43.5156 40.6562 43.6094 40.7031 43.6562 40.75L46.7031 43.6094C46.75 43.6094 46.7969 43.7031 46.7969 43.75C46.7969 43.8438 46.75 43.9375 46.7031 43.9375Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="filter-uploader-info-name-container">{fileName}</div>
      <div className="filter-uploader-info-size-container">Size: {displaySize}</div>
      <div className="file-uploader-info-remove-button-container">
        <NgButton outline size="large" onClick={() => onRemove()}>
          Remove
          <DeleteOutlined />
        </NgButton>
      </div>
    </div>
  );
}

function FileUploaderZone(props) {
  const { onChange } = props;
  const [currentFile, setCurrentFile] = useState(null);
  function onFileUploaderChange(uploadedFile) {
    setCurrentFile(uploadedFile);
    if (onChange) {
      onChange(uploadedFile);
    }
  }

  function onFileRemove() {
    setCurrentFile(null);
    if (onChange) {
      onChange(null);
    }
  }

  return (
    <div className={`file-uploader-zone-container ${!currentFile ? "upload" : ""}`}>
      {!currentFile ? (
        <FileUploaderInputComponent onChange={onFileUploaderChange} />
      ) : (
        <FileUploaderInfoComponent fileInfo={currentFile} onRemove={onFileRemove} />
      )}
    </div>
  );
}

export default FileUploaderZone;
