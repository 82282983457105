import React, { useMemo } from "react";
import { LabeledSelect } from "../labeled-control/labeled-control";
import { Select } from "antd";
import { indexBy } from "../../utils/iterables";
import { briefDataAssetSummary } from "../metric/summary-view/data-asset-summary";
import { IDIcon } from "../icons/summary";

import "./metric-search.scss";

const { Option } = Select;

function MetricSearchOption(props) {
  const { metric, dataSourcesByUuid } = props;
  const shortSummary = briefDataAssetSummary(metric, dataSourcesByUuid);
  const sourceSegments = shortSummary.single
    ? [shortSummary.single]
    : [shortSummary.source, shortSummary.target];

  return (
    <>
      <div className="metric-search-item-title">
        <span className="metric-search-item-id">
          <IDIcon /> {metric.metadata.idSerial}
        </span>
        {metric.metadata.name}
      </div>
      {sourceSegments.map((sourceLine, index) => (
        <div key={`source_${index}`} className="metric-search-item-data-asset">
          {sourceLine.map(({ key, icon, value }) => (
            <span className="metric-search-item-data-asset-segment" key={key}>
              {icon} {value}
            </span>
          ))}
        </div>
      ))}
    </>
  );
}

const MetricSearchDropdownRender = (menu) => (
  <div className="metric-search-dropdown">{menu}</div>
);

function MetricSearch(props) {
  const { metrics = [], dataSourceList = [], value, ...restProps } = props;

  const dataSourcesByUuid = useMemo(
    () => indexBy(dataSourceList, (dataSource) => dataSource.metadata.uuid),
    [dataSourceList]
  );

  return (
    <LabeledSelect
      label="Metric"
      showSearch
      optionLabelProp="label"
      dropdownRender={MetricSearchDropdownRender}
      value={value}
      {...restProps}
    >
      {metrics.map((metric) => (
        <Option
          key={metric.metadata.uuid}
          value={metric.metadata.uuid}
          label={metric.metadata.name}
          className="metric-search-item"
        >
          <MetricSearchOption metric={metric} dataSourcesByUuid={dataSourcesByUuid} />
        </Option>
      ))}
    </LabeledSelect>
  );
}

export default MetricSearch;
