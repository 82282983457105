import React, { useEffect, useState } from "react";
import { JiraAuthType } from "../../../utils/enums";
import JiraIntegrationBasicDialog, {
  canAttemptAuthentication,
  getJiraAuthFields,
} from "./jira-integration-basic-dialog";
import {
  isBranch,
  isMetadata,
  minimalFields,
  SubmitType,
} from "../integration-metadata";
import { AlertingChannelType } from "../../../utils/alerting";
import { useOutsideClickConfirm } from "../../../hooks/useOutsideClickConfirm";
import { getIntegrationFormSaveStatus } from "../integration-metadata-editor";

const defaultJiraInstance = {
  title: "",
  server: "",
  username: "",
  password: "",
  key: "",
  authType: JiraAuthType.BASIC_AUTH,
  mode: SubmitType.MANUAL,
  metadata: null,
};

function AddJiraIntegrationDialog(props) {
  const {
    workspaceUuid,
    currentMetadata,
    onAdd,
    onPreview,
    modalIsOpen,
    outsideClickWrap,
    setIsOpen,
    getIntegrationMetadata,
    resetIntegrationMetadata,
  } = props;

  const [value, setValue] = useState(defaultJiraInstance);
  const [isLoading, setIsLoading] = useState(false);
  const [lastAuthAttemptData, setLastAuthAttemptData] = useState(null);
  const [lastSuccessfulAuthAttemptData, setLastSuccessfulAuthAttemptData] =
    useState(null);

  useEffect(() => {
    resetIntegrationMetadata();
  }, [resetIntegrationMetadata]);

  useOutsideClickConfirm({
    outsideClickWrap,
    close: () => setIsOpen(false),
    originalValue: defaultJiraInstance,
    currentValue: value,
  });

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  const onAuthenticate = (newData) => {
    setLastAuthAttemptData(getJiraAuthFields(newData));
    setIsLoading(true);
    getIntegrationMetadata(workspaceUuid, newData)
      .then((metadata) => {
        setLastSuccessfulAuthAttemptData(metadata ? getJiraAuthFields(newData) : {});
        setValue({
          ...value,
          metadata: minimalFields(AlertingChannelType.JIRA, metadata),
        });
      })
      .finally(() => setIsLoading(false));
  };

  const { enableSave, authButtonStatus } = getIntegrationFormSaveStatus({
    isEdit: false,
    lastAuthAttemptData,
    lastSuccessfulAuthAttemptData,
    currentAuthFields: getJiraAuthFields(value),
    canAttemptAuth: canAttemptAuthentication(value),
    fieldsConfigured: isMetadata(value.metadata) && isBranch(value.metadata),
    isLoading,
  });

  return (
    <JiraIntegrationBasicDialog
      value={value}
      currentMetadata={currentMetadata}
      className={"add-jira-integration-dialog-container"}
      dialogTitle={"Add Jira Integration"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      OKText={"Add"}
      enablePreview={true}
      enableSave={enableSave}
      authButtonStatus={authButtonStatus}
      onChange={setValue}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      onAuthenticate={onAuthenticate}
      workspaceUuid={workspaceUuid}
    />
  );
}

export default AddJiraIntegrationDialog;
