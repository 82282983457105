import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button as AntdButton } from "antd";
import { classesName } from "../../utils/css";
import Divider from "../../atom/divider";
import "./ng-button.scss";

function Button(props) {
  const {
    children,
    className: propsClassName,
    dropdown = false,
    ...otherProps
  } = props;
  const className = classesName(propsClassName, dropdown && "ng-button-dropdown");

  return (
    <AntdButton className={className} {...otherProps}>
      {children}
      {dropdown && (
        <div className="ng-button-dropdown-right-container">
          <Divider type="vertical" style={{ height: "100%" }} />
          <DownOutlined />
        </div>
      )}
    </AntdButton>
  );
}

function PrimaryButton(props) {
  const {
    testId,
    success = false,
    danger,
    className: propsClassName,
    ...restProps
  } = props;

  const className = classesName(
    propsClassName,
    danger && "ng-button-danger",
    success && "ng-button-success",
    !danger && !success && "ng-button-primary"
  );

  return (
    <div className={className} data-testid={props.testId}>
      <Button type="primary" {...restProps} />
    </div>
  );
}

function ColorButton(props) {
  const { testId, className: propsClassName, theme, ...restProps } = props;

  const className = classesName(propsClassName, `ng-button-${theme}`);

  return (
    <div className={className} data-testid={props.testId}>
      <Button type="primary" {...restProps} />
    </div>
  );
}

function OutlineButton(props) {
  const { danger, critical, testId, className: propsClassName, ...restProps } = props;
  const className = classesName(
    propsClassName,
    danger && "ng-button-outline-danger",
    critical && "ng-button-outline-critical",
    !danger && !critical && "ng-button-outline"
  );

  return (
    <div className={className} data-testid={testId}>
      <Button type="default" danger={danger} {...restProps} />
    </div>
  );
}

function LinkButton(props) {
  const { testId, className: propsClassName, ...restProps } = props;

  const className = classesName(propsClassName, "ng-button-link");
  return (
    <div className={className} data-testid={testId}>
      <Button type="link" {...restProps} />
    </div>
  );
}

function TextButton(props) {
  const { testId, className: propsClassName, ...restProps } = props;

  const className = classesName(propsClassName, "ng-button-text");
  return (
    <div className={className} data-testid={testId}>
      <Button type="text" {...restProps} />
    </div>
  );
}

export const ButtonPaddingSize = Object.freeze({
  TINY: "tiny",
  SMALL: "small",
  NORMAL: "normal",
});

function NgButton(props) {
  const {
    primary,
    color,
    outline,
    link,
    text,
    success = false,
    paddingSize = ButtonPaddingSize.NORMAL,
    className: propsClassName,
    ...restProps
  } = props;

  const className = classesName(
    propsClassName,
    paddingSize === ButtonPaddingSize.TINY && "padding-tiny",
    paddingSize === ButtonPaddingSize.SMALL && "padding-small"
  );

  if (primary) {
    return <PrimaryButton success={success} className={className} {...restProps} />;
  } else if (color) {
    return <ColorButton theme={color} className={className} {...restProps} />;
  } else if (outline) {
    return <OutlineButton className={className} {...restProps} />;
  } else if (link) {
    return <LinkButton className={className} {...restProps} />;
  } else if (text) {
    return <TextButton className={className} {...restProps} />;
  } else {
    return <PrimaryButton className={className} {...restProps} />;
  }
}

export function TriggerButton(props) {
  const { children, ...otherProps } = props;
  return (
    <NgButton
      outline
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
        width: 32,
        height: 32,
      }}
      {...otherProps}
    >
      {children}
    </NgButton>
  );
}

export function TextWithIcon(props) {
  const { children, icon, iconPosition = "right", inline = false } = props;
  return (
    <div
      className="ng-button-text-with-icon"
      style={inline ? { display: "inline-flex" } : {}}
    >
      {iconPosition === "left" && icon}
      {children}
      {iconPosition === "right" && icon}
    </div>
  );
}

export default NgButton;
