import React, { Component } from "react";
import { Redirect } from "react-router";
import RuleWizard from "../../components/rule/rule-wizard";
import { toast } from "../../components/toast/toast";
import { deepMerge } from "../../utils/deep-merge";
import { AutoMetricsType, SymptomTypeConst } from "../../utils/enums";
import { getDefaultCloneTitle, getDefaultRuleConfig } from "../../utils/defaults";
import {
  URIPath,
  getURIInstance,
  getMetricUuidFromParams,
  getMonitorSymptomFromParams,
  getCloneFromParams,
} from "../../utils/uri-path";
import { getAutoMetricTypeFromKPI } from "../../utils/metric";

class AddRuleWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToRuleSetting: false,
    };

    this.title = "Add Rule";
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { workspaceUuid },
      },
      location: { search },
    } = this.props;
    const cloneFrom = getCloneFromParams(search);
    if (cloneFrom) {
      console.log(`Cloning rule from ${cloneFrom}`);
      this.props.getCloneRule(workspaceUuid, cloneFrom);
    }

    this.props.getDataSourceList(workspaceUuid);
    this.props.getKpiList(workspaceUuid);
    this.props.getIntegrationList(workspaceUuid);
    this.props.getScheduleList(workspaceUuid);
  }

  componentWillUnmount() {
    this.props.resetCurrentAddRuleStatus();
  }

  onCancel() {
    this.setState({ goToRuleSetting: true });
  }

  onSave(workspaceUuid, newRule) {
    return this.props.addRule(workspaceUuid, newRule);
  }

  render() {
    const {
      match: {
        params: { workspaceUuid },
      },
      location: { search },
    } = this.props;
    if (this.state.goToRuleSetting) {
      if (this.props.history.length === 0) {
        return (
          <Redirect
            push
            to={getURIInstance(URIPath.NG_MONITOR_LIST, { workspaceUuid })}
          />
        );
      } else {
        this.props.history.goBack();
      }
    }

    const cloneFrom = getCloneFromParams(search);
    const isClone = !!cloneFrom;
    if (isClone && !this.props.currentCloneRule) {
      console.log(`Loading cloned rule ${cloneFrom}`);
      return null;
    }

    if (this.props.currentAddRule) {
      const {
        metadata: { uuid, name },
      } = this.props.currentAddRule;
      toast(`Monitor ${name} is created`);
      return (
        <Redirect
          to={`${getURIInstance(URIPath.EDIT_MONITOR, {
            workspaceUuid,
            id: uuid,
          })}?tabName=training`}
        />
      );
    }

    if (this.props.kpiList.length === 0) {
      return null;
    }

    let defaultData;
    if (isClone) {
      defaultData = deepMerge(this.props.currentCloneRule, {
        metadata: {
          name: getDefaultCloneTitle(this.props.currentCloneRule.metadata.name),
        },
      });
      delete defaultData.config.liveStartTs;
    } else {
      const defaultMetricUuid = getMetricUuidFromParams(this.props.location.search);
      let kpiInfo = this.props.kpiList[0];
      let defaultSymptom = getMonitorSymptomFromParams(
        this.props.location.search,
        SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS
      );
      if (defaultMetricUuid) {
        const filterKpiInfo = this.props.kpiList.filter(
          (currentKpi) => currentKpi.metadata.uuid === defaultMetricUuid
        );
        if (filterKpiInfo.length > 0) {
          kpiInfo = filterKpiInfo[0];
          if (
            [
              AutoMetricsType.FULL_COMPARE,
              AutoMetricsType.AGGREGATION_COMPARE,
            ].includes(getAutoMetricTypeFromKPI(kpiInfo))
          ) {
            defaultSymptom = SymptomTypeConst.MANUAL_THRESHOLD;
          }
        }
      }
      defaultData = getDefaultRuleConfig(defaultSymptom, kpiInfo);
    }

    return (
      <RuleWizard
        isEdit={false}
        isClone={isClone}
        dataSourceList={this.props.dataSourceList}
        kpiList={this.props.kpiList}
        scheduleList={this.props.scheduleList}
        defaultData={defaultData}
        integrationList={this.props.integrationList}
        onSave={this.onSave.bind(this, workspaceUuid)}
        onCancel={this.onCancel}
      />
    );
  }
}

export default AddRuleWizard;
