import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import EntityList from "../../components/entity-list/entity-list";
import { ListPageColumnKey } from "../../utils/enums";
import {
  columnColumn,
  dataSourceColumn,
  dimensionColumn,
  metricCreationTypeColumn,
  metricTypeColumn,
  monitorTypeColumn,
  schemaColumn,
  tableColumn,
} from "../../components/entity-list/columns";
import MetricLinkCell from "../../components/entity-list/metric-link-cell";
import { fnSorter } from "../../utils/sort";
import { indexBy } from "../../utils/iterables";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { NgTableEmptyState } from "../../components/table/ng-table";
import {
  getTableRows,
  incidentCreationTimeColumn,
  incidentDirectionColumn,
  incidentDurationColumn,
  incidentIdColumn,
  incidentProgressColumn,
  incidentSeverityColumn,
  incidentSliceColumn,
  incidentStartTimeColumn,
  incidentStatusColumn,
  incidentValidationStatusColumn,
} from "./columns";
import { EVENT, trackEvent } from "../../utils/telemetry";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";

export function NoIncidentsIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0625 6.25C15.2679 6.25 13.75 7.67197 13.75 9.50281C13.75 10.9983 14.6379 12.2582 15.5076 13.1111C16.3933 13.9797 17.4932 14.6579 18.3661 14.9396C18.6157 15.0201 18.8843 15.0201 19.1339 14.9396C20.0068 14.6579 21.1067 13.9797 21.9924 13.1111C22.8621 12.2582 23.75 10.9983 23.75 9.50281C23.75 7.67197 22.2321 6.25 20.4375 6.25C19.824 6.25 19.2461 6.41455 18.75 6.70332C18.2539 6.41455 17.676 6.25 17.0625 6.25ZM16.25 9.50281C16.25 9.1214 16.5789 8.75 17.0625 8.75C17.3519 8.75 17.5954 8.88811 17.738 9.08478C17.9732 9.40905 18.3495 9.601 18.75 9.601C19.1505 9.601 19.5268 9.40905 19.762 9.08478C19.9046 8.88811 20.1481 8.75 20.4375 8.75C20.9211 8.75 21.25 9.1214 21.25 9.50281C21.25 10.0102 20.9192 10.6621 20.242 11.3262C19.7455 11.8131 19.1847 12.1847 18.75 12.3997C18.3153 12.1847 17.7545 11.8131 17.258 11.3262C16.5808 10.6621 16.25 10.0102 16.25 9.50281Z"
        fill="#3DA18D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.625 2.5C3.89911 2.5 2.5 3.89911 2.5 5.625V24.375C2.5 26.1009 3.89913 27.5 5.625 27.5H24.375C26.1009 27.5 27.5 26.1009 27.5 24.375V5.625C27.5 3.89913 26.1009 2.5 24.375 2.5H5.625ZM5 5.625C5 5.27982 5.27982 5 5.625 5H24.375C24.7202 5 25 5.27981 25 5.625V24.375C25 24.7202 24.7202 25 24.375 25H5.625C5.27981 25 5 24.7202 5 24.375V5.625Z"
        fill="#3DA18D"
      />
    </svg>
  );
}

function IncidentListEntityTable(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    history,
    workspaceUserPermissions,
    searchItem,
    loading,
    paginationParams,
    searchControls,
    getRowStats,
    onPaginationChange,
    onSearchItemChange,
    onIncidentStatusChanged,
    onIncidentValidationStatusChanged,
    incidentListPageConfiguration,
    getIncidentListPageConfiguration,
    updateIncidentListPageConfiguration,
    incidentList,
    userId,
    dataSourceList,
  } = props;

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.INCIDENT,
    incidents: incidentList,
    dataSources: dataSourceList.data,
    userId,
  });

  const [selectedRowUuids, setSelectedRowUuids] = useState([]);
  useEffect(() => {
    getIncidentListPageConfiguration(workspaceUuid);
  }, [workspaceUuid, getIncidentListPageConfiguration]);

  const groupedIncidentList = useMemo(() => {
    return filterRows(incidentList, searchItem);
  }, [filterRows, incidentList, searchItem]);

  const tableRows = useMemo(
    () => getTableRows(groupedIncidentList ?? []),
    [groupedIncidentList]
  );

  const selectedRows = useMemo(() => {
    if (selectedRowUuids.length === 0) {
      return [];
    }

    const incidentUuidToTableRow = indexBy(
      tableRows,
      (tableRow) => tableRow.incidentData.uuid
    );
    const newSelectedRows = [];
    selectedRowUuids.forEach((selectedRowUuid) => {
      const updatedRow = incidentUuidToTableRow[selectedRowUuid] || null;
      if (!updatedRow) {
        console.log(`Failed to find selected incident row ${selectedRowUuid}`);
        return;
      }

      newSelectedRows.push(updatedRow);
    });

    return newSelectedRows;
  }, [selectedRowUuids, tableRows]);

  const columns = [
    incidentIdColumn({
      dataIndex: ["incidentData", "id"],
      renderProps: { workspaceUuid, workspaceUserPermissions },
    }),
  ];

  const configurableColumns = [
    {
      title: "Metric",
      key: ListPageColumnKey.METRIC_NAME,
      dataIndex: ["metricName"],
      sorter: { compare: fnSorter((row) => row.metricName?.toLowerCase()) },
      render: (metricName, { creatorInfo }) => {
        return (
          <MetricLinkCell
            metric={creatorInfo.kpiInfo}
            workspaceUserPermissions={workspaceUserPermissions}
            history={history}
          />
        );
      },
      width: 220,
    },
    metricTypeColumn({ dataIndex: "metricType", title: "Metric type", width: 140 }),
    dimensionColumn({ dataIndex: "metricDimension" }),
    metricCreationTypeColumn({
      dataIndex: "metricCreationType",
      title: "Metric class",
      width: 140,
    }),
    monitorTypeColumn({ dataIndex: "monitorType", title: "Monitor type", width: 140 }),
    {
      title: "Monitor",
      key: ListPageColumnKey.MONITOR_NAME,
      dataIndex: ["monitorName"],
      sorter: { compare: fnSorter((row) => row.monitorName) },
      width: 220,
    },
    incidentValidationStatusColumn({
      dataIndex: ["incidentData", "validationStatus"],
      renderProps: {
        selectedRows,
        workspaceUserPermissions,
        onChange: (incidentList, newValidation) => {
          const selectedIncidents = incidentList.map((row) => row.incidentData);
          onIncidentValidationStatusChanged(selectedIncidents, newValidation);
        },
      },
    }),
    incidentStartTimeColumn({
      dataIndex: ["incidentData", "startTime"],
    }),
    incidentDurationColumn({
      dataIndex: ["incidentData", "duration"],
    }),
    incidentDirectionColumn({
      dataIndex: ["incidentData", "direction"],
    }),
    incidentStatusColumn({
      dataIndex: ["incidentData", "status"],
      renderProps: {
        workspaceUserPermissions,
        onChange: (incidentData, newStatus) => {
          const selectedIncidents =
            selectedRows.length > 0
              ? selectedRows.map((row) => row.incidentData)
              : [incidentData];

          trackEvent(
            selectedIncidents.length > 1
              ? EVENT.INCIDENT_CHANGE_STATUS_BULK
              : EVENT.INCIDENT_CHANGE_STATUS,
            {
              status: newStatus,
              incident_id: incidentData?.id || "",
              workspace_id: workspaceUuid,
              incident_uuid: incidentData?.uuid || "",
            }
          );
          onIncidentStatusChanged(selectedIncidents, newStatus);
        },
      },
    }),
    incidentSeverityColumn({
      dataIndex: ["incidentData", "score"],
    }),
    dataSourceColumn({ dataIndex: ["dataSourceNames"] }),
    schemaColumn({ dataIndex: ["schemaName"] }),
    tableColumn({ dataIndex: ["tableName"] }),
    columnColumn({ dataIndex: ["columnName"] }),
    incidentSliceColumn({ dataIndex: ["sliceName"] }),
    incidentProgressColumn({ dataIndex: ["incidentData", "ongoing"] }),
    incidentCreationTimeColumn({ dataIndex: ["incidentData", "creationTime"] }),
  ];

  const canModifyIncidentColumnList = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACECONFIGURATIONSPAGEVIEW,
  ]);

  const columnKeyOptions = canModifyIncidentColumnList
    ? [
        { label: "Metric", value: ListPageColumnKey.METRIC_NAME },
        { label: "Metric type", value: ListPageColumnKey.METRIC_TYPE },
        { label: "Metric class", value: ListPageColumnKey.METRIC_CREATION_TYPE },
        { label: "Dimension", value: ListPageColumnKey.DIMENSION },
        { label: "Monitor", value: ListPageColumnKey.MONITOR_NAME },
        { label: "Monitor type", value: ListPageColumnKey.MONITOR_TYPE },
        { label: "Start time", value: ListPageColumnKey.INCIDENT_START_TIME },
        { label: "Duration", value: ListPageColumnKey.INCIDENT_DURATION },
        { label: "Direction", value: ListPageColumnKey.INCIDENT_DIRECTION },
        { label: "Status", value: ListPageColumnKey.INCIDENT_STATUS },
        { label: "Severity", value: ListPageColumnKey.INCIDENT_SEVERITY },
        { label: "Validation", value: ListPageColumnKey.INCIDENT_VALIDATION },
        { label: "Datasource", value: ListPageColumnKey.DATASOURCE },
        { label: "Schema", value: ListPageColumnKey.SCHEMA },
        { label: "Table", value: ListPageColumnKey.TABLE },
        { label: "Column", value: ListPageColumnKey.COLUMN_NAME },
        { label: "Slice", value: ListPageColumnKey.SLICE_NAME },
        { label: "Progress", value: ListPageColumnKey.INCIDENT_PROGRESS },
        { label: "Creation time", value: ListPageColumnKey.INCIDENT_CREATION_TIME },
      ]
    : [];

  function onColumnKeyListChange(columns) {
    updateIncidentListPageConfiguration(workspaceUuid, {
      ...incidentListPageConfiguration,
      columns,
    });
  }

  return (
    <EntityList
      searchControls={searchControls}
      showAdd={false}
      searchOptions={searchOptions}
      loading={loading}
      columns={columns}
      configurableColumns={configurableColumns}
      rows={tableRows}
      getRowKey={(row) => row.incidentData.id}
      getRowStats={getRowStats}
      tableProps={{
        scroll: { x: 1168 },
        onChange: (pagination, _filters, _sorter, { action }) => {
          if (action === "paginate") {
            onPaginationChange(pagination);
          }
        },
        pagination: paginationParams,
        emptyState: (
          <NgTableEmptyState title="No incidents detected" icon={<NoIncidentsIcon />} />
        ),
      }}
      searchItem={searchItem}
      onSelectedRowsChange={(selectedRows) => {
        setSelectedRowUuids(
          selectedRows.map((selectedRows) => selectedRows.incidentData.uuid)
        );
      }}
      onSearchItemChange={onSearchItemChange}
      columnKeyList={incidentListPageConfiguration?.columns || []}
      onColumnKeyListChange={onColumnKeyListChange}
      columnKeyOptions={columnKeyOptions}
    />
  );
}

export default withRouter(IncidentListEntityTable);
