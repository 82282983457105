import React from "react";
import {
  PausedGovernanceIcon,
  PausedScheduleIcon,
  PausedSystemIcon,
} from "../../views/profiler/metric-status-icons";
import { MetricStatus } from "../../utils/enums";
import { NextGenPalette } from "../../utils/color";
import StatusBadge from "./index";
import { getMetricStatus, isMetricPausedBySystem } from "../../utils/metric";
import { ErrorIcon, LiveIcon, PausedIcon } from "../icons/status";

export function metricBadgeConfig(metricStatus) {
  switch (metricStatus) {
    // EXCEPTION and TIMEOUT refer just to the last collection attempt, and will not necessarily
    // result in the metric going into a system paused state, so we don't bother showing
    // this to the user.
    case MetricStatus.OK:
    case MetricStatus.EXCEPTION:
    case MetricStatus.TIMEOUT:
      return { title: "Live", Icon: LiveIcon, description: null };
    case MetricStatus.PAUSED_EXCEPTION:
      return { title: "Error", Icon: ErrorIcon, description: null };
    case MetricStatus.PAUSED_BY_USER:
      return { title: "Paused", Icon: PausedIcon, description: "Paused by user" };
    case MetricStatus.PAUSED_SOURCE:
      return {
        title: "Paused",
        Icon: PausedScheduleIcon,
        description: "Paused by source or schedule",
      };
    case MetricStatus.PAUSED_TIMEOUTS:
      return {
        title: "Paused",
        Icon: PausedGovernanceIcon,
        description: "Paused by timeout",
      };
    case MetricStatus.PAUSED_EXCESS_UNIQUE_SLICES:
    case MetricStatus.PAUSED_EXCESS_UNIQUE_CATEGORIES:
    case MetricStatus.PAUSED_FULL_COMPARE_DATA_GREATER_THAN_ALLOWED:
      return {
        title: "Paused",
        Icon: PausedSystemIcon,
        description: "Paused by system",
      };
    default:
      return { title: "", Icon: null };
  }
}

function MetricStatusBadge(props) {
  const { metric, clickable, onClick, buttonStyle = false } = props;

  const metricStatus = getMetricStatus(metric);
  const { title, Icon, description } = metricBadgeConfig(metricStatus);
  const color =
    metricStatus === MetricStatus.PAUSED_EXCEPTION
      ? NextGenPalette.criticalRed
      : NextGenPalette.systemBlack;
  const tooltip =
    isMetricPausedBySystem(metric) && metric.status?.runStatusMessage
      ? metric.status.runStatusMessage
      : description;

  return (
    <span>
      <StatusBadge
        Icon={Icon}
        title={title}
        tooltip={tooltip}
        clickable={clickable}
        color={color}
        onClick={onClick}
        buttonStyle={buttonStyle}
      />
    </span>
  );
}

export default MetricStatusBadge;
