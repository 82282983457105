import React from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { URIPath, hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import Button from "../../components/button/ng-button";

import "./lobby.scss";

function AddWorkspaceCta(props) {
  return (
    <>
      <div className="lobby-cta-text">
        <h1>Add a new workspace</h1>
        <div className="lobby-cta-details">
          Looks like your team doesn't have any workspaces yet, click Add New Workspace
          to get started.
        </div>
      </div>
      <Link to={URIPath.CREATE_WORKSPACE}>
        <Button primary size="large" className="lobby-cta-action-button">
          Add New Workspace
          <PlusOutlined />
        </Button>
      </Link>
    </>
  );
}

function ContactAdminCta() {
  return (
    <div className="lobby-cta-text">
      <h1>No workspace found</h1>
      <div className="lobby-cta-details">
        Looks like your team doesn’t have any workspaces yet, contact your admin to get
        a workspace set up to begin using Lightup.
      </div>
    </div>
  );
}

function Lobby(props) {
  const { userPermissions } = props;

  const canAddWorkspaces = hasPermission(userPermissions, [
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACELIST,
  ]);

  return (
    <div className="lobby">
      {canAddWorkspaces ? <AddWorkspaceCta /> : <ContactAdminCta />}
    </div>
  );
}

export default withRouter(Lobby);
