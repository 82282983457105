import { connect } from "react-redux";
import {
  getDataSourceSystemIntegrationMetadata,
  deleteDataSource,
  getDataSource,
  editDataSource,
  previewDataSource,
  resetDataSourceStatus,
} from "../../actions/datasource/data-source-action";
import { getKpiList } from "../../actions/kpi/kpi-action";

import EditDataSourceWizard from "./edit-data-source";

const mapStateToProps = (state) => ({
  lastPreviewStatus: state.dataSourceReducer.lastPreviewStatus,
  lastPreviewTime: state.dataSourceReducer.lastPreviewTime,
  lastPreviewResult: state.dataSourceReducer.lastPreviewResult,
  currentEditData: state.dataSourceReducer.currentEditData,
  editDataSourceStatus: state.dataSourceReducer.editingStatus,
  alationDataSourceList: state.dataSourceReducer.dataSourceAlationDataSourceList.data,
  atlanDataSourceList: state.dataSourceReducer.dataSourceAtlanDataSourceList.data,
  kpiList: state.kpiReducer.kpiList.data,
  waffle: state.userReducer.currentUserInfo.waffle,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSource: (workspaceUuid, id, requestVersion) =>
    dispatch(getDataSource(workspaceUuid, id, requestVersion)),
  editDataSource: (workspaceUuid, id, newDataSource) =>
    dispatch(editDataSource(workspaceUuid, id, newDataSource)),
  deleteDataSource: (workspaceUuid, dataSource) =>
    dispatch(deleteDataSource(workspaceUuid, dataSource)),
  previewDataSource: (workspaceUuid, newDataSource) =>
    dispatch(previewDataSource(workspaceUuid, newDataSource)),
  resetDataSourceStatus: () => dispatch(resetDataSourceStatus()),
  getDataSourceSystemIntegrationMetadata: () =>
    dispatch(getDataSourceSystemIntegrationMetadata()),
  getKpiList: (workspaceUuid) => dispatch(getKpiList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDataSourceWizard);
