import { NextGenPalette } from "../../../../utils/color";
import {
  getMetricTypeFromConfigData,
  metricTypeNames,
} from "../../../../components/metric/utils";
import { getColumnTypeCategory } from "../../../../utils/column";
import { MetricType, TableType } from "../../../../utils/enums";
import { getMetricColumnInfo } from "../../../../utils/metric";

// This is used in some contexts where it is convenient to have a metric-like
// object to represent schema change.
export const columnActivityPseudoMetric = {
  metadata: {
    name: "Column activity",
    uuid: "<schema-change>",
    creationType: MetricType.AUTO,
  },
};

export const TableMetricHealthyType = Object.freeze({
  HEALTHY: 1,
  UNHEALTHY: 2,
  UNMONITORED: 3,
});

export const TableMetricHealthyTypeToColorMapper = Object.freeze({
  [TableMetricHealthyType.HEALTHY]: NextGenPalette.mintGreen,
  [TableMetricHealthyType.UNHEALTHY]: NextGenPalette.red,
  [TableMetricHealthyType.UNMONITORED]: NextGenPalette.veryLightGrey,
});

function getMetricHealthyTypeFromMetricInfo(data) {
  const { activeMonitorsCount, pausedIncidentsCount, activeIncidentsCount } = data;
  let healthyType;
  if (pausedIncidentsCount + activeIncidentsCount > 0) {
    healthyType = TableMetricHealthyType.UNHEALTHY;
  } else if (activeMonitorsCount > 0) {
    healthyType = TableMetricHealthyType.HEALTHY;
  } else {
    healthyType = TableMetricHealthyType.UNMONITORED;
  }

  return healthyType;
}

function healthDataSort({ healthyType: healthyType1 }, { healthyType: healthyType2 }) {
  return healthyType1 - healthyType2;
}

export function getTableMetricsHealthData(
  metrics,
  metricInfoMapper,
  includeColumnName = false
) {
  return metrics
    .map((currentData) => {
      let metric, metricName;
      if (currentData.configurableType) {
        metric = null;
        metricName = metricTypeNames[currentData.configurableType];
      } else {
        metric = metricInfoMapper[currentData.metricUuid];
        metricName = metric.metadata.name;
      }

      let columnName = "",
        columnUuid = "";
      if (includeColumnName) {
        const columnInfo = getMetricColumnInfo(metric);
        columnName = columnInfo.columnName;
        columnUuid = columnInfo.columnUuid ?? "";
      }

      return {
        ...currentData,
        key: currentData.metricUuid ?? currentData.configurableType,
        totalIncidentsCount:
          currentData.activeIncidentsCount + currentData.pausedIncidentsCount,
        metricName,
        metricType: currentData.configurableType ?? getMetricTypeFromConfigData(metric),
        columnName,
        columnUuid,
        healthyType: getMetricHealthyTypeFromMetricInfo(currentData),
        metric,
      };
    })
    .sort(healthDataSort);
}

function getColumnHealthyTypeFromMetricsInfo(metrics) {
  if (
    metrics.some(
      (metric) =>
        getMetricHealthyTypeFromMetricInfo(metric) === TableMetricHealthyType.UNHEALTHY
    )
  ) {
    return TableMetricHealthyType.UNHEALTHY;
  }

  if (
    metrics.some(
      (metric) =>
        getMetricHealthyTypeFromMetricInfo(metric) === TableMetricHealthyType.HEALTHY
    )
  ) {
    return TableMetricHealthyType.HEALTHY;
  }

  return TableMetricHealthyType.UNMONITORED;
}

export function getTableColumnHealthData(metrics, metricInfoMapper, columns) {
  const groupMetricByColumnUuid = {};
  columns.forEach((column) => {
    groupMetricByColumnUuid[column.uuid] = {
      columnUuid: column.uuid,
      columnName: column.columnName,
      columnTypeCategory: getColumnTypeCategory(column),
      metrics: [],
    };
  });

  metrics.forEach((currentMetricInfo) => {
    const { metricUuid } = currentMetricInfo;
    if (!metricInfoMapper[metricUuid]) {
      console.log(`${metricUuid} is not found.`);
      return;
    }

    const valueColumns = metricInfoMapper[metricUuid].config.valueColumns;
    if (valueColumns.length !== 1) {
      console.log(`Invalid column metric for ${metricUuid}`);
      return;
    }

    const { columnName, columnUuid } =
      metricInfoMapper[metricUuid].config.table.type === TableType.CUSTOM_SQL
        ? metricInfoMapper[metricUuid].config.table
        : valueColumns[0];
    if (!groupMetricByColumnUuid[columnUuid]) {
      console.log(`${columnUuid} with ${columnName} is not found in the column list`);
      groupMetricByColumnUuid[columnUuid] = {
        columnUuid,
        columnName,
        columnTypeCategory: "",
        metrics: [],
      };
    }

    groupMetricByColumnUuid[columnUuid].metrics.push({
      ...currentMetricInfo,
      metricConfig: metricInfoMapper[metricUuid],
    });
  });

  return Object.values(groupMetricByColumnUuid)
    .map((columnInfo) => {
      const { columnUuid, columnName, metrics } = columnInfo;
      const columnTypeCategory = getColumnTypeCategory(columnInfo);
      const totalMonitorsCount = metrics.reduce(
        (accumulator, currentMetric) => accumulator + currentMetric.totalMonitorsCount,
        0
      );
      const activeMonitorsCount = metrics.reduce(
        (accumulator, currentMetric) => accumulator + currentMetric.activeMonitorsCount,
        0
      );
      const pausedMonitorsCount = metrics.reduce(
        (accumulator, currentMetric) => accumulator + currentMetric.pausedMonitorsCount,
        0
      );
      const activeIncidentsCount = metrics.reduce(
        (accumulator, currentMetric) =>
          accumulator + currentMetric.activeIncidentsCount,
        0
      );
      const pausedIncidentsCount = metrics.reduce(
        (accumulator, currentMetric) =>
          accumulator + currentMetric.pausedIncidentsCount,
        0
      );

      return {
        key: columnUuid,
        columnUuid,
        columnName,
        columnTypeCategory,
        totalMetricsCount: metrics.length,
        totalMonitorsCount,
        activeMonitorsCount,
        pausedMonitorsCount,
        totalIncidentsCount: activeIncidentsCount + pausedIncidentsCount,
        activeIncidentsCount,
        pausedIncidentsCount,
        healthyType: getColumnHealthyTypeFromMetricsInfo(metrics),
      };
    })
    .sort(healthDataSort);
}
