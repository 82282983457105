import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import AdminTabContentHeader from "../../admin-tab-content-header";
import Select from "../../../../components/select/";
import Table, { NgTableClickableText } from "../../../../components/table/ng-table";
import NgDropdownMenu from "../../../../components/ng-dropdown-menu";
import AdminUserDeleteModal from "../../admin-user-delete-modal";
import AddUserPopOver from "../../add-user-popover";
import AdminUsersTableWorkspaceColumn from "../../admin-users-table-workspace-column";
import { AppUserRole } from "../../../../utils/enums";
import { appRoleOptions } from "../../../../utils/options";
import { fnSorter } from "../../../../utils/sort";
import { EVENT, PAGE, trackEvent } from "../../../../utils/telemetry";
import { getStringFromTimeStamp } from "../../../../utils/time";

import "./index.scss";

function AdminUserEmailCell(props) {
  const { user, onDeleteClick, userInfo } = props;
  if (user.email === userInfo?.email) {
    return <NgTableClickableText clickable={false}>{user.email}</NgTableClickableText>;
  }

  const adminUserActionMenuItems = [
    {
      label: "Delete User",
      onClick: () => onDeleteClick(user),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const adminUserActionMenuTrigger = (
    <NgTableClickableText>{user.email}</NgTableClickableText>
  );

  return (
    <NgDropdownMenu
      position={"bottomLeft"}
      trigger={adminUserActionMenuTrigger}
      menuItems={adminUserActionMenuItems}
    />
  );
}

function AdminUsersTab(props) {
  const {
    userInfo,
    appUserList,
    appWorkspaceList,
    addAppUser,
    updateAppUser,
    deleteAppUser,
    addAppUserToWorkspace,
    removeAppUserFromWorkspace,
  } = props;

  useEffect(() => {
    trackEvent(EVENT.SELECT_USERS_TAB, { page: PAGE.ADMIN });
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [deleteModalTarget, setDeleteModalTarget] = useState(null);

  const isDeleteModalVisible = deleteModalTarget !== null;
  function onDeleteUserClick(user) {
    setDeleteModalTarget(user);
  }

  function onDeleteCancelled() {
    setDeleteModalTarget(null);
  }

  function onDeleteConfirmed() {
    deleteAppUser(deleteModalTarget.id);
    setDeleteModalTarget(null);
    trackEvent(EVENT.DELETE_USER, {
      role: deleteModalTarget.role,
      page: PAGE.ADMIN_USERS,
    });
  }

  const normalizedAppUserList = appUserList.data.map((currentUserData) => {
    const { id, username, email, role, workspaces, hasLoggedIn, lastLogin } =
      currentUserData;
    return {
      id,
      username,
      email,
      workspaces,
      role,
      hasLoggedIn,
      lastLogin,
    };
  });

  const trimmedSearchValue = searchValue.toLowerCase().trim();
  const filterAppUserList =
    trimmedSearchValue.length > 0
      ? normalizedAppUserList.filter((currentUserData) => {
          const { email } = currentUserData;
          return email.toLowerCase().indexOf(trimmedSearchValue) !== -1;
        })
      : normalizedAppUserList;

  const userListColumns = [
    {
      title: "EMAIL",
      key: "email",
      dataIndex: "email",
      sorter: { compare: fnSorter((row) => row.email) },
      defaultSortOrder: "descend",
      render: (_, user) => (
        <AdminUserEmailCell
          user={user}
          onDeleteClick={onDeleteUserClick}
          userInfo={userInfo}
        />
      ),
      width: 355,
    },
    {
      title: "WORKSPACES",
      key: "workspaces",
      dataIndex: "workspaces",
      sorter: {
        compare: fnSorter((row) => row.workspaces.map(({ name }) => name).join(",")),
      },
      render: function (workspaces, currentUser) {
        return (
          <AdminUsersTableWorkspaceColumn
            appWorkspaceList={appWorkspaceList}
            workspaceList={workspaces}
            onAddWorkspace={({ workspace, role }) => {
              addAppUserToWorkspace(workspace.uuid, {
                email: currentUser.email,
                role,
              });
            }}
            onDeleteWorkspace={(workspaceUuid) => {
              removeAppUserFromWorkspace(workspaceUuid, currentUser);
              trackEvent(EVENT.DELETE_WORKSPACE_FROM_USER, { page: PAGE.ADMIN_USERS });
            }}
          />
        );
      },
    },
    {
      title: "Application role",
      key: "role",
      dataIndex: "role",
      width: 180,
      sorter: { compare: fnSorter((row) => row.role) },
      render: function (role, currentUser) {
        return (
          <Select
            disabled={currentUser.email === userInfo?.email}
            placeholder="Select a role for user"
            options={appRoleOptions}
            value={role}
            style={{ width: 120 }}
            onChange={(newRole) => {
              if (newRole !== role) {
                updateAppUser(currentUser.id, newRole);
                trackEvent(EVENT.CHANGE_USER_ROLE, {
                  role: newRole,
                  old_role: role,
                  page: PAGE.ADMIN_USERS,
                });
              }
            }}
          />
        );
      },
    },
    {
      title: "Last login",
      dataIndex: "lastLogin",
      render: function (_, { hasLoggedIn, lastLogin }) {
        if (!hasLoggedIn) {
          return "Never";
        }
        return getStringFromTimeStamp(lastLogin, { longLocalized: true });
      },
      sorter: { compare: fnSorter((row) => row.lastLogin) },
      width: 150,
    },
  ];

  return (
    <div className="admin-users-tab-container">
      <AdminTabContentHeader
        title={""}
        searchComponent={
          <Input.Search
            className="admin-users-search-container"
            value={searchValue}
            placeholder={"Search by email..."}
            onChange={(e) => {
              const currentSearchValue = e.target.value.trim();
              currentSearchValue !== searchValue && setSearchValue(currentSearchValue);
            }}
          />
        }
        addComponent={
          <AddUserPopOver
            roleOptions={appRoleOptions}
            initialValues={{ email: "", role: AppUserRole.ADMIN }}
            onAddUser={({ email, role }) => {
              trackEvent(EVENT.ADD_USER, { role, page: PAGE.ADMIN_USERS });
              addAppUser({ email, role });
            }}
          />
        }
      />
      <div className="admin-users-tab-body-container">
        <Table
          dataSource={filterAppUserList}
          columns={userListColumns}
          rowKey={"email"}
          size={"middle"}
          pagination={{
            position: ["bottomRight"],
            hideOnSinglePage: true,
          }}
          sortDirections={["ascend", "descend", "ascend"]}
        />
      </div>
      <AdminUserDeleteModal
        visible={isDeleteModalVisible}
        onDeleteConfirmed={onDeleteConfirmed}
        onDeleteCancelled={onDeleteCancelled}
        userName={deleteModalTarget?.username}
      />
    </div>
  );
}

export default AdminUsersTab;
