const { useEffect } = require("react");

const confirmationPrompt = "You have unsaved changes - discard?";

// Ensure that clicking outside a dialog only happens when a form it contains is
// dirty. Does a deep equality check on the original vs. current value.
// outsideClickWrap should have also been passed to openWorkspaceTakeover() as
// its outsideClick arg:
//
// const outsideClickWrap = {};
// openWorkspaceTakeover(
//   <Comp outsideClickWrap={outsideClickWrap} {...otherProps} />,
//   TakeoverWidth.WIDE,
//   () => outsideClickWrap?.fn()
// );
// <Comp /> would then call useOutsideClickConfirm() to configure the conditions
// for allowing the dialog to close.
export function useOutsideClickConfirm(opts) {
  const { outsideClickWrap, close, originalValue, currentValue } = opts;
  useEffect(() => {
    if (outsideClickWrap) {
      outsideClickWrap.fn = () => {
        if (
          JSON.stringify(originalValue) === JSON.stringify(currentValue) ||
          window.confirm(confirmationPrompt)
        ) {
          close();
        }
      };
    }
  }, [outsideClickWrap, close, originalValue, currentValue]);
}
