import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { classesName } from "../../utils/css";

import "./index.scss";

function Link(props) {
  const { className, ...restProps } = props;
  return (
    <ReactRouterLink className={classesName("ng-link", className)} {...restProps} />
  );
}

export default Link;
