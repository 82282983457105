import { connect } from "react-redux";
import {
  addAppUser,
  addAppUserToWorkspace,
  deleteAppUser,
  getAppUserList,
  getAppWorkspaceList,
  updateAppUser,
  removeAppUserFromWorkspace,
  deleteWorkspace,
  addSystemIntegration,
  deleteSystemIntegration,
  updateSystemIntegration,
  getSystemIntegrationList,
  previewSystemIntegration,
} from "../../actions/admin/admin-action";
import { openWorkspaceTakeover } from "../../actions/takeover/takeover-action";

import Admin from "./admin";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  appUserList: state.adminReducer.appUserList,
  appWorkspaceList: state.adminReducer.appWorkspaceList,
  systemIntegrationList: state.adminReducer.systemIntegrationList,
});

const mapDispatchToProps = (dispatch) => ({
  addAppUser: (newUser) => dispatch(addAppUser(newUser)),
  getAppUserList: () => dispatch(getAppUserList()),
  getAppWorkspaceList: () => dispatch(getAppWorkspaceList()),
  deleteAppUser: (username) => dispatch(deleteAppUser(username)),
  updateAppUser: (username, role) => dispatch(updateAppUser(username, role)),
  addAppUserToWorkspace: (workspaceUuid, user) =>
    dispatch(addAppUserToWorkspace(workspaceUuid, user)),
  removeAppUserFromWorkspace: (workspaceUuid, user) =>
    dispatch(removeAppUserFromWorkspace(workspaceUuid, user)),
  openWorkspaceTakeover: (takeoverElement) =>
    dispatch(openWorkspaceTakeover(takeoverElement)),
  deleteWorkspace: (workspaceUuid) => dispatch(deleteWorkspace(workspaceUuid, false)),
  addSystemIntegration: (systemIntegration) =>
    dispatch(addSystemIntegration(systemIntegration)),
  deleteSystemIntegration: (systemIntegration) =>
    dispatch(deleteSystemIntegration(systemIntegration)),
  updateSystemIntegration: (newSystemIntegration) =>
    dispatch(updateSystemIntegration(newSystemIntegration)),
  getSystemIntegrationList: () => dispatch(getSystemIntegrationList()),
  previewSystemIntegration: (systemIntegration) =>
    dispatch(previewSystemIntegration(systemIntegration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
