import React from "react";
import { Popover as AntdPopover } from "antd";
import { classesName } from "../../utils/css";

import "./index.scss";

function Popover(props) {
  const { overlayClassName, shadow = true, ...restProps } = props;
  return (
    <AntdPopover
      overlayClassName={classesName(
        "ng-popover-overlay",
        overlayClassName,
        !shadow && "no-shadow"
      )}
      {...restProps}
    />
  );
}

export default Popover;
