export function ExpirationIcon(props) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M7.93327 1.1333C7.62047 1.1333 7.3666 1.38717 7.3666 1.69997V2.3751C4.47036 2.91059 2.2666 5.45157 2.2666 8.49997C2.2666 11.9359 5.064 14.7333 8.49993 14.7333C11.9359 14.7333 14.7333 11.9359 14.7333 8.49997C14.7333 5.45157 12.5295 2.91059 9.63327 2.3751V1.69997C9.63327 1.38717 9.3794 1.1333 9.0666 1.1333H7.93327ZM13.0277 2.2611C12.915 2.26113 12.8048 2.29478 12.7113 2.35774C12.6178 2.42071 12.5452 2.51013 12.5028 2.61457C12.4604 2.71901 12.4501 2.83373 12.4732 2.94407C12.4963 3.0544 12.5518 3.15533 12.6326 3.23395L13.1993 3.80062C13.2515 3.855 13.314 3.89842 13.3832 3.92833C13.4525 3.95825 13.5269 3.97405 13.6023 3.97481C13.6777 3.97558 13.7525 3.9613 13.8223 3.9328C13.8921 3.9043 13.9555 3.86217 14.0088 3.80885C14.0621 3.75554 14.1043 3.69213 14.1328 3.62233C14.1613 3.55253 14.1755 3.47774 14.1748 3.40236C14.174 3.32697 14.1582 3.25249 14.1283 3.18328C14.0984 3.11407 14.055 3.05153 14.0006 2.99932L13.4339 2.43265C13.3811 2.37837 13.318 2.33522 13.2482 2.30575C13.1784 2.27629 13.1035 2.2611 13.0277 2.2611ZM8.49993 3.39997C11.3234 3.39997 13.5999 5.6765 13.5999 8.49997C13.5999 10.0723 12.8926 11.4736 11.7793 12.408L8.49993 8.49997V3.39997Z"
        fill="#121224"
      />
    </svg>
  );
}
