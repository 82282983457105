import React from "react";
import ConfigureMetricSummary from "../configure-metric-summary";
import { getMetricTypeFromConfigData } from "../../utils";
import { MetricCategory } from "../../../../utils/enums";

function ActivityMetricConfigDataAssetSummaryCardContent(props) {
  const { configData, columnList, dataSourceList } = props;
  const isCategoryActivity =
    MetricCategory.CATEGORY_ACTIVITY === getMetricTypeFromConfigData(configData);

  return (
    <ConfigureMetricSummary
      columnList={columnList}
      configData={configData}
      dataSourceList={dataSourceList}
      enableBackfillDuration={isCategoryActivity}
      enableDataCollectionSchedule={false}
      enableDataCollectionWindow={false}
      enablePartitionConfig={isCategoryActivity}
      enableSeasonality={false}
      enableSlicing={false}
      enableSynchronizationDelay={isCategoryActivity}
      enableTimestampColumn={isCategoryActivity}
      enableQueryScope={false}
      allowOverride={false}
      enableMissingValueFilling={false}
    />
  );
}

export default ActivityMetricConfigDataAssetSummaryCardContent;
