import {
  OPEN_WORKSPACE_TAKEOVER,
  CLOSE_TAKEOVER,
} from "../../actions/takeover/takeover-action-types";

const initialTakeoverState = {
  workspaceTakeoverKey: 0,
  workspaceTakeoverOpen: false,
  workspaceTakeoverElement: null,
  outsideClick: null,
  size: null,
  className: "",
};

export default function takeoverReducer(state = initialTakeoverState, action = {}) {
  switch (action.type) {
    case OPEN_WORKSPACE_TAKEOVER:
      // Set a new key when opening the takeover so that we reset its state
      // each time it is opened.
      const newKey =
        state.workspaceTakeoverOpen === false ? Date.now() : state.workspaceTakeoverKey;

      return {
        ...state,
        workspaceTakeoverOpen: true,
        workspaceTakeoverKey: newKey,
        workspaceTakeoverElement: action.takeoverElement,
        size: action.size,
        outsideClick: action.outsideClick,
        className: action.className,
      };

    case CLOSE_TAKEOVER:
      return { ...initialTakeoverState };

    default:
      return state;
  }
}
