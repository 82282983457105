import React from "react";
import SlackIntegrationBasicDialog from "./slack-integration-basic-dialog";
import { DefaultTimeZone } from "../../../utils/time";
import { AlertingChannelType } from "../../../utils/alerting";
import "./add-slack-integration-dialog.scss";

function AddSlackIntegrationDialog(props) {
  const {
    onAdd,
    onPreview,
    alertChannelType = AlertingChannelType.SLACK,
    modalIsOpen,
    setIsOpen,
  } = props;

  const defaultSlackInstance = {
    title: "",
    webHookUrl: "",
    description: "",
    timezone: DefaultTimeZone,
    muteResolvedAlerts: false,
    digestPeriodInSeconds: 0,
    enableRecurring: false,
  };

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  let dialogTitle = "";
  if (alertChannelType === AlertingChannelType.MSTEAMS) {
    dialogTitle = "Add Microsoft Teams Integration";
  } else {
    dialogTitle = "Add Slack Integration";
  }

  return (
    <SlackIntegrationBasicDialog
      defaultData={defaultSlackInstance}
      className={"add-integration-dialog-container"}
      dialogTitle={dialogTitle}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      OKText={"Add"}
      alertChannelType={alertChannelType}
      enablePreview={true}
      enableSave={true}
    />
  );
}

export default AddSlackIntegrationDialog;
