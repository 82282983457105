import React from "react";
import IconTextToggleButton from "../button/icon-text-toggle-button";

function ViewQueryHistoryIcon(props) {
  const { fillColor } = props;
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
      <g opacity="0.85">
        <path
          d="M5.24992 7.50002C5.24992 7.17785 5.51109 6.91669 5.83325 6.91669H10.4999C10.8221 6.91669 11.0833 7.17785 11.0833 7.50002C11.0833 7.82219 10.8221 8.08335 10.4999 8.08335H5.83325C5.51109 8.08335 5.24992 7.82219 5.24992 7.50002Z"
          fill={fillColor}
        />
        <path
          d="M5.83325 9.25002C5.51109 9.25002 5.24992 9.51119 5.24992 9.83335C5.24992 10.1555 5.51109 10.4167 5.83325 10.4167H10.4999C10.8221 10.4167 11.0833 10.1555 11.0833 9.83335C11.0833 9.51119 10.8221 9.25002 10.4999 9.25002H5.83325Z"
          fill={fillColor}
        />
        <path
          d="M2.91659 7.50002C2.91659 7.17785 3.17775 6.91669 3.49992 6.91669H4.08325C4.40542 6.91669 4.66658 7.17785 4.66658 7.50002C4.66658 7.82219 4.40542 8.08335 4.08325 8.08335H3.49992C3.17775 8.08335 2.91659 7.82219 2.91659 7.50002Z"
          fill={fillColor}
        />
        <path
          d="M3.49992 9.25002C3.17775 9.25002 2.91659 9.51119 2.91659 9.83335C2.91659 10.1555 3.17775 10.4167 3.49992 10.4167H4.08325C4.40542 10.4167 4.66658 10.1555 4.66658 9.83335C4.66658 9.51119 4.40542 9.25002 4.08325 9.25002H3.49992Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.583252 3.12502C0.583252 2.31961 1.23617 1.66669 2.04159 1.66669H11.9583C12.7637 1.66669 13.4166 2.31961 13.4166 3.12502V11.875C13.4166 12.6804 12.7637 13.3334 11.9583 13.3334H2.04159C1.23617 13.3334 0.583252 12.6804 0.583252 11.875V3.12502ZM1.74992 3.12502C1.74992 2.96394 1.8805 2.83335 2.04159 2.83335H11.9583C12.1193 2.83335 12.2499 2.96394 12.2499 3.12502V4.00002H1.74992V3.12502ZM12.2499 5.16669V11.875C12.2499 12.0361 12.1193 12.1667 11.9583 12.1667H2.04159C1.8805 12.1667 1.74992 12.0361 1.74992 11.875V5.16669H12.2499Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

function ViewQueryHistoryButton(props) {
  const { isViewing = false, disabled = false, onClick } = props;

  return (
    <IconTextToggleButton
      text="Query History"
      alternateText="Back to Preview"
      disabled={disabled}
      showAlternate={isViewing}
      icon={<ViewQueryHistoryIcon />}
      onClick={onClick}
    />
  );
}

export default ViewQueryHistoryButton;
