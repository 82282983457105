import React from "react";
import {
  EmailTypeIcon,
  JiraTypeIcon,
  MSTeamsTypeIcon,
  OpsGenieTypeIcon,
  PagerDutyTypeIcon,
  ServiceNowTypeIcon,
  SlackTypeIcon,
} from "./integration-icon";
import { AlertingChannelType } from "../../utils/alerting";
import { getIntegrationUserListPromise } from "../../rest/integration";

export function getAddDropdownMenuItems(onAdd, { isEmailEnabled }) {
  const dropdownItems = [
    {
      label: "Slack",
      icon: <SlackTypeIcon />,
      onClick: () => onAdd(AlertingChannelType.SLACK),
    },
    {
      label: "Email List",
      icon: <EmailTypeIcon />,
      disabled: !isEmailEnabled,
      onClick: () => onAdd(AlertingChannelType.EMAIL),
    },
    {
      label: "Pagerduty",
      icon: <PagerDutyTypeIcon />,
      onClick: () => onAdd(AlertingChannelType.PAGERDUTY),
    },
    {
      label: "Microsoft Teams",
      icon: <MSTeamsTypeIcon />,
      onClick: () => onAdd(AlertingChannelType.MSTEAMS),
    },
    {
      label: "Servicenow",
      icon: <ServiceNowTypeIcon />,
      onClick: () => onAdd(AlertingChannelType.SERVICENOW),
    },
    {
      label: "Jira",
      icon: <JiraTypeIcon />,
      onClick: () => onAdd(AlertingChannelType.JIRA),
    },
    {
      label: "OpsGenie",
      icon: <OpsGenieTypeIcon />,
      onClick: () => onAdd(AlertingChannelType.OPSGENIE),
    },
  ];

  dropdownItems.sort((a, b) => a.label.localeCompare(b.label));

  return dropdownItems;
}

export function createGetUserList(workspaceUuid, integrationConfig, type) {
  return async (query) => {
    const integrationData = {
      type: type.toUpperCase(),
      config: { type, domainName: "whatever", ...integrationConfig }, // type and domain value are required to pass backend validation
      metadata: { name: "whatever" }, // name is required to pass backend validation
    };

    if (integrationConfig.id) {
      integrationData.id = integrationConfig.id;
      integrationData.metadata.uuid = integrationConfig.id;
    }

    return getIntegrationUserListPromise(workspaceUuid, integrationData, query).then(
      (userList) =>
        userList.map(({ valueId, value }) => ({ label: value, value: valueId }))
    );
  };
}
