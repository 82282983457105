import { connect } from "react-redux";
import { getUserList } from "../../actions/user/user-action";

import WorkspaceHeader from "./workspace-header";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  userList: state.userReducer.userList.data,
  workspaceList: state.adminReducer.userWorkspaceList.data,
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: (workspaceUuid) => dispatch(getUserList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceHeader);
