import React, { Component } from "react";
import { Redirect } from "react-router";
import DisclaimerDialog from "./disclaimer-dialog";
import auth0 from "../../utils/auth0";

import "./disclaimer.scss";

class Disclaimer extends Component {
  constructor(props) {
    super(props);
    this.acceptDisclaimer = this.acceptDisclaimer.bind(this);
    this.rejectDisclaimer = this.rejectDisclaimer.bind(this);
  }

  acceptDisclaimer() {
    console.log("It is accepted");
    this.props.acceptDisclaimer();
  }

  async rejectDisclaimer() {
    console.log("It is rejected.");
    this.props.logout();
    const { auth0ClientId, auth0DomainName } = this.props.userInfo;
    if (!auth0ClientId || !auth0DomainName) {
      return;
    }

    try {
      await auth0.init(auth0ClientId, auth0DomainName);
      auth0.logout();
    } catch (err) {
      console.log("Catch exception while logging out from auth0");
    }
  }

  render() {
    const { disclaimerAccepted } = this.props.userInfo;

    if (disclaimerAccepted) {
      const { location } = this.props;
      const fromLocation =
        location.state && location.state.from ? location.state.from : "/";
      console.log(`Going to ${JSON.stringify(fromLocation)} from disclaimer page`);
      return <Redirect push to={fromLocation} />;
    }

    return (
      <div className="disclaimer-container">
        <DisclaimerDialog
          onAcceptDisclaimer={this.acceptDisclaimer}
          onRejectDisclaimer={this.rejectDisclaimer}
        />
      </div>
    );
  }
}

export default Disclaimer;
