import { useState, useMemo, useRef } from "react";
import { AutoComplete, DatePicker, Input, InputNumber, TimePicker } from "antd";
import debounce from "lodash/debounce";
import { default as Select, PartitionSelect, TimestampSelect } from "../select/";
import { v4 as uuidv4 } from "uuid";
import NgMultiSelect from "../multi-select/ng-multi-select";
import { NgTextTooltip } from "../text-tooltip/ng-text-tooltip";
import moment from "moment";
import { classesName } from "../../utils/css";
import { isTestEnv } from "../../utils/env";
import { Spinner } from "../../atom/spinner";

import "./labeled-control.scss";

// HOC to get a modified control that shows a label when the input is not empty.
// `opts` consists of:
// - className: CSS class name to wrap the component in. CSS customizations for the
//   component should go in ./labeled-control.css.
// - shouldShowLabel: Function of the form (control value) => boolean, used to determine
//   whether to show the label based on the value of the control.
// - valueFromEvent: The HOC will hook into the control's `onChange` callback. `valueFromEvent`
//   should be a function of the form (onChange event) => new control value. By default, this
//   is the identity function.
// - staticProps: Props that will get set on every instance of the WrappedComponent.
function withDynamicLabel(WrappedComponent, opts) {
  const { className, shouldShowLabel, valueFromEvent = (e) => e, staticProps } = opts;

  return function (props) {
    const {
      label,
      onChange,
      defaultValue,
      value,
      staticLabel = false,
      labelStyle = {},
      children,
      ...restProps
    } = props;

    const [showControlledLabel, setShowControlledLabel] = useState(
      shouldShowLabel(defaultValue)
    );

    // We want to make sure that there is some ID for the control, that the
    // <label> will be associated with the input component.
    const [id] = useState(`labeled-control-${uuidv4()}`);

    function onInputChange(event) {
      const v = valueFromEvent(event);
      setShowControlledLabel(shouldShowLabel(v));
      onChange && onChange(event);
    }

    const showLabel =
      typeof defaultValue !== "undefined"
        ? showControlledLabel
        : shouldShowLabel(value);

    const visibilityClass = staticLabel || showLabel ? "label-visible" : "label-hidden";
    const size = restProps.size || "large";
    return (
      <div className={`${className} ${size}`}>
        <WrappedComponent
          placeholder={staticLabel ? "" : label}
          id={id}
          defaultValue={defaultValue}
          value={value}
          size={size}
          onChange={onInputChange}
          {...staticProps}
          {...restProps}
        >
          {children}
        </WrappedComponent>
        <label
          htmlFor={id}
          className={`labeled-control-label ${visibilityClass}`}
          style={labelStyle}
        >
          <NgTextTooltip>{label}</NgTextTooltip>
        </label>
      </div>
    );
  };
}

export const LabeledInput = withDynamicLabel(Input, {
  className: "labeled-control-input",
  shouldShowLabel: (v) => v && v !== "",
  valueFromEvent: (e) => e.target.value,
});
LabeledInput.displayName = "LabeledInput";

export const LabeledAutoComplete = withDynamicLabel(AutoComplete, {
  className: "labeled-control-input",
  shouldShowLabel: (v) => v && v !== "",
  valueFromEvent: (v) => v,
});
LabeledAutoComplete.displayName = "LabeledAutoComplete";

export const LabeledPartitionSelect = withDynamicLabel(PartitionSelect, {
  className: "labeled-control-select",
  shouldShowLabel: (v) => typeof v !== "undefined" && v !== null,
  staticProps: {
    virtual: !isTestEnv(),
  },
});
LabeledPartitionSelect.displayName = "LabeledPartitionSelect";

export const LabeledTimestampSelect = withDynamicLabel(TimestampSelect, {
  className: "labeled-control-select",
  shouldShowLabel: (v) => typeof v !== "undefined" && v !== null,
  staticProps: {
    virtual: !isTestEnv(),
  },
});
LabeledTimestampSelect.displayName = "LabeledTimestampSelect";

export const LabeledSelect = withDynamicLabel(Select, {
  className: "labeled-control-select",
  shouldShowLabel: (v) => typeof v !== "undefined" && v !== null,
  // If we leave on virtual scrolling, it can be very hard to make assertions
  // about the contents of the select dropdown while testing.
  staticProps: {
    virtual: !isTestEnv(),
  },
});
LabeledSelect.displayName = "LabeledSelect";

const { TextArea } = Input;
export const LabeledTextArea = withDynamicLabel(TextArea, {
  className: "labeled-control-textarea",
  shouldShowLabel: (v) => v && v !== "",
});
LabeledTextArea.displayName = "LabeledTextArea";

export const LabeledInputNumber = withDynamicLabel(InputNumber, {
  className: "labeled-control-input-number",
  shouldShowLabel: (v) => typeof v === "number",
});
LabeledInputNumber.displayName = "LabeledInputNumber";

export const LabeledMultiSelect = withDynamicLabel(NgMultiSelect, {
  className: "labeled-control-multi-select",
  shouldShowLabel: (v) => v?.length > 0,
  staticProps: {
    virtual: !isTestEnv(),
  },
});
LabeledMultiSelect.displayName = "LabeledMultiSelect";

export const LabeledDatePicker = withDynamicLabel(
  (props) => {
    const {
      onChange,
      status,
      className: propsClassName,
      allowClear = false,
      ...restProps
    } = props;

    if (restProps.value) {
      restProps.value = moment(restProps.value);
    }

    // Note: Antd supports this prop as of 4.19.0, but upgrading Antd
    // at this juncture seems too risky. Once we upgrade it, we can
    // remove this code and the associated CSS.
    const className = classesName(
      propsClassName,
      status === "error" ? "labeled-date-picker-error" : null
    );

    return (
      <DatePicker
        onChange={(v) => onChange(v?.toDate())}
        className={className}
        allowClear={allowClear}
        {...restProps}
      />
    );
  },
  {
    className: "labeled-date-picker",
    shouldShowLabel: (v) => typeof v === "object",
  }
);
LabeledDatePicker.displayName = "LabeledDatePicker";

export const LabeledTimePicker = withDynamicLabel(
  (props) => {
    const { onChange, status, allowClear = false, ...restProps } = props;

    if (restProps.value) {
      restProps.value = moment(restProps.value);
    }

    return (
      <TimePicker
        allowClear={allowClear}
        onChange={(v) => onChange(v?.toDate())}
        {...restProps}
      />
    );
  },
  {
    className: "labeled-time-picker",
    shouldShowLabel: (v) => typeof v === "object",
  }
);
LabeledTimePicker.displayName = "LabeledTimePicker";

export function DebouncedLabeledSelect(props) {
  const {
    optionsFetcher = null,
    debounceTimeout = 800,
    ignoreEmptySearch = true,
    multiple = false,
    ...otherProps
  } = props;

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchRef = useRef(0);

  const debouncedSearch = useMemo(() => {
    const loadUsers = (value) => {
      if (!optionsFetcher || (ignoreEmptySearch && value.trim() === "")) {
        return;
      }

      fetchRef.current++;
      const fetchId = fetchRef.current;
      setOptions([]);
      setLoading(true);

      optionsFetcher(value).then((options) => {
        if (fetchId !== fetchRef.current) {
          // Discard past requests
          return;
        }

        setOptions(options);
        setLoading(false);
      });
    };
    return debounce(loadUsers, debounceTimeout);
  }, [optionsFetcher, debounceTimeout, ignoreEmptySearch]);

  const componentProps = multiple ? { mode: "multiple" } : {};
  const SelectComponent = multiple ? LabeledMultiSelect : LabeledSelect;

  return (
    <SelectComponent
      filterOption={false}
      notFoundContent={
        <div style={{ textAlign: "center" }}>
          {loading ? <Spinner /> : <span>Begin typing the name of the user</span>}
        </div>
      }
      allowClear
      {...componentProps}
      {...otherProps}
      showSearch
      onSearch={debouncedSearch}
      options={options}
    />
  );
}
