import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import NgTooltip from "../tooltip/ng-tooltip";

import "./label-item.scss";

function LabelItem(props) {
  const {
    label,
    tooltip,
    children,
    disabled,
    className,
    helpTooltip = "",
    html = false,
  } = props;

  if (React.Children.count(children) === 0) {
    return <div className="label-item-container" />;
  }

  const containerClassName = `label-item-container${disabled ? " disabled" : ""}${
    className ? " " + className : ""
  }`;
  return (
    <div className={containerClassName}>
      {label && (
        <div className="label-item-container-title">
          <div>{label}</div>
          {!helpTooltip ? null : (
            <NgTooltip title={helpTooltip || html} shadow={false}>
              <span className="label-item-tooltip-container">
                <InfoCircleOutlined />
              </span>
            </NgTooltip>
          )}
        </div>
      )}
      <div className="label-item-container-content">{children}</div>
      {tooltip ? <div className="label-item-container-tooltip">{tooltip}</div> : null}
    </div>
  );
}

export default LabelItem;
