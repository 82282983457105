import React from "react";

import ProfilerChartEnableView from "../profiler-chart-enable-view";
import ProfilerDataProfileTableView from "../profiler-data-profile-table-view";

import { EVENT, PAGE, getTableDetailProps, trackEvent } from "../../../utils/telemetry";

export default function ProfilerDataSourceTableDataProfileTab(props) {
  const { table, updateTableDataProfilerEnabled, canModifySettings, ...otherProps } =
    props;

  const handleChange = (newValue) => {
    trackEvent(EVENT.ENABLE_DATA_PROFILE, {
      ...getTableDetailProps(table, otherProps?.schema, otherProps?.dataSource),
      page: PAGE.EXPLORER_TABLE_DATA_PROFILE,
    });

    updateTableDataProfilerEnabled(newValue);
  };

  if (!table?.profilerConfig?.dataProfiler?.enabled) {
    return canModifySettings ? (
      <ProfilerChartEnableView
        value={table?.profilerConfig?.dataProfiler?.enabled}
        gridOnly={true}
        onChange={handleChange}
      />
    ) : null;
  }

  return <ProfilerDataProfileTableView table={table} {...otherProps} />;
}
