export function GlobeIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.433 0.742415C11.1987 0.521256 10.8295 0.531878 10.6084 0.766142C10.3872 1.00041 10.3978 1.3696 10.6321 1.59076C11.629 2.5319 12.2499 3.86398 12.2499 5.34203C12.2499 8.19066 9.94066 10.4999 7.09203 10.4999C5.61399 10.4999 4.28191 9.87899 3.34076 8.88208C3.1196 8.64782 2.7504 8.6372 2.51614 8.85836C2.28188 9.07952 2.27126 9.44872 2.49242 9.68298C3.49971 10.7499 4.87574 11.4672 6.41659 11.6309V12.2499H5.24992C4.92775 12.2499 4.66659 12.5111 4.66659 12.8333C4.66659 13.1554 4.92775 13.4166 5.24992 13.4166H8.74992C9.07209 13.4166 9.33325 13.1554 9.33325 12.8333C9.33325 12.5111 9.07209 12.2499 8.74992 12.2499H7.58325V11.6478C10.8467 11.3971 13.4166 8.66969 13.4166 5.34203C13.4166 3.52978 12.6536 1.89478 11.433 0.742415Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99992 0.583253C4.42259 0.583253 2.33325 2.67259 2.33325 5.24992C2.33325 7.82725 4.42259 9.91659 6.99992 9.91659C9.57725 9.91659 11.6666 7.82725 11.6666 5.24992C11.6666 2.67259 9.57725 0.583253 6.99992 0.583253ZM3.49992 5.24992C3.49992 3.31692 5.06692 1.74992 6.99992 1.74992C8.93292 1.74992 10.4999 3.31692 10.4999 5.24992C10.4999 7.18292 8.93292 8.74992 6.99992 8.74992C5.06692 8.74992 3.49992 7.18292 3.49992 5.24992Z"
        fill="#121224"
      />
    </svg>
  );
}
