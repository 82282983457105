import React from "react";
import { classesName } from "../../utils/css";

import "./button-reset.scss";
import "./button-icon.scss";

function ButtonIcon(props) {
  const { icon, onClick, className: propsClassName, ...restProps } = props;

  const className = classesName("button-reset", "button-icon", propsClassName);

  return (
    <button className={className} onClick={onClick} {...restProps}>
      {icon}
    </button>
  );
}

export default ButtonIcon;
