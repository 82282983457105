import React from "react";
import { capitalizeFirstLetter } from "../../utils/general";
import { getDisplayTimeFromDuration } from "../../utils/time";
import Alert, { AlertType } from "../alert";
import { RequestStatus } from "../../utils/enums";

import "./data-source-preview-result.scss";

function DataSourcePreviewResult(props) {
  const { isPreviewed, lastPreviewStatus, lastPreviewResult } = props;
  return (
    <div className="data-source-wizard-preview-container">
      <div className="wizard-preview-header-container">
        <div className="wizard-preview-header-title">Connector output</div>
      </div>
      <div className="wizard-preview-result">
        {isPreviewed && lastPreviewStatus === RequestStatus.DOING && (
          <div className="wizard-preview-result-checking">Checking connection...</div>
        )}
        {isPreviewed && lastPreviewStatus === RequestStatus.SUCCESS ? (
          <div className="wizard-preview-result-data">
            {lastPreviewResult.connectionStatus === "success" && (
              <>
                <Alert message="Successful!" type={AlertType.SUCCESS} />
                <div className="wizard-preview-latency-container">
                  <div className="wizard-preview-result-title-container">
                    Connection latency
                  </div>
                  <div className="wizard-preview-latency-value-container">
                    {getDisplayTimeFromDuration(lastPreviewResult.latency)}
                  </div>
                </div>
                <div className="wizard-preview-result-data-inner-container">
                  <div className="wizard-preview-result-title-container">
                    {capitalizeFirstLetter(lastPreviewResult.schemaType)}
                  </div>
                  <div>
                    {lastPreviewResult.schemas.map((currentDataItem, index) => (
                      <div
                        className="wizard-preview-schema-value-item-container"
                        key={currentDataItem}
                      >
                        {currentDataItem}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {lastPreviewResult.connectionStatus !== "success" && (
              <>
                <Alert
                  message="Failed!"
                  type={AlertType.FAILURE}
                  description={lastPreviewResult.stacktrace}
                  collapsible
                  initialCollapsed={false}
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DataSourcePreviewResult;
