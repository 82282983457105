import { useState } from "react";
import ModalConfirmationButtons from "../../modal-dialog/modal-confirmation-buttons";
import Tabs, { TabPane } from "../../tabs/tabs";
import { validateDraftMetadata } from "./utils";
import { TagsConfigItem } from "../fields/add-tags";
import { MetricDraftInfoFields } from "../fields/metric-draft-info-fields";
import { MetricDraftNotification } from "../fields/metric-draft-notification";
import { DraftModalDialog } from "./draft-modal-dialog";

import "./draft-metric-modal.scss";

function DraftMetricSendApprovalModal(props) {
  const {
    visible = false,
    onConfirm,
    onCancel,
    configData,
    integrationList,
    tagList,
    isInProgress,
    defaultNotificationChannels = [],
    title = "Send Metric for Approval",
  } = props;

  const [activeKey, setActiveKey] = useState("draft-info");
  const [currentConfigData, setCurrentConfigData] = useState(configData);

  return (
    <DraftModalDialog
      visible={visible}
      title={title}
      className="draft-metric-modal"
      onCancel={onCancel}
      footer={
        <ModalConfirmationButtons
          okText="Save"
          onCancelClick={onCancel}
          onOkClick={() => onConfirm(currentConfigData)}
          okButtonProps={{
            disabled:
              !validateDraftMetadata(currentConfigData?.draftMetadata ?? {}) ||
              isInProgress,
          }}
        />
      }
    >
      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        type="line"
        size="small"
        className="draft-metric-tabs"
      >
        <TabPane tab="Draft Info" key="draft-info">
          <div className="draft-form">
            <MetricDraftInfoFields
              configData={currentConfigData}
              onConfigDataChange={setCurrentConfigData}
              disabled={isInProgress}
            />
          </div>
        </TabPane>
        <TabPane tab="Approval Notification" key="notification" forceRender={true}>
          <div className="draft-form">
            <MetricDraftNotification
              configData={currentConfigData}
              onConfigDataChange={setCurrentConfigData}
              disabled={isInProgress}
              integrationList={integrationList}
              defaultNotificationChannels={defaultNotificationChannels}
            />
          </div>
        </TabPane>
        <TabPane tab="Tag(s)" key="tags">
          <div className="draft-form">
            <TagsConfigItem
              value={currentConfigData?.metadata?.tags || []}
              tagList={tagList}
              onChange={(tags) => {
                setCurrentConfigData({
                  ...currentConfigData,
                  metadata: {
                    ...currentConfigData.metadata,
                    tags,
                  },
                });
              }}
            />
          </div>
        </TabPane>
      </Tabs>
    </DraftModalDialog>
  );
}

export default DraftMetricSendApprovalModal;
