import * as types from "./rule-action-types";
import {
  getRuleListPromise,
  updateRulePromise,
  addRulePromise,
  deleteRulePromise,
  getRulePromise,
  getRuleTrainingJobStatusPromise,
  getRuleFilterStatusPromise,
  getRuleSliceValueListPromise,
  addRulePreviewPromise,
  deleteRulePreviewPromise,
  getRulePreviewListPromise,
  getRulePreviewJobStatusPromise,
} from "../../rest/rule.jsx";
import {
  getIncidentListPromise,
  updateIncidentStatusPromise,
} from "../../rest/incident";
import {
  getPageConfigurationPromise,
  updatePageConfigurationPromise,
} from "../../rest/workspace";
import { getFilterDataPromise } from "../../rest/metrics";
import { getTagList } from "../tag/tag-action";
import { getUniqueSliceValue, isSliceEqual, isJobRunning } from "../../utils/general";
import { ListPageName, PodJobStatus, SliceTrainingStatus } from "../../utils/enums";
import { getStringFromTimeStamp } from "../../utils/time";
import { metricsDataTemplate } from "../../utils/defaults";
import { getSliceByColumns } from "../../utils/metric";

function setRuleList(ruleList) {
  return { type: types.SET_RULE_LIST, ruleList };
}

function setRuleListPageConfiguration(ruleListPageConfiguration) {
  return { type: types.SET_RULE_LIST_PAGE_CONFIGURATION, ruleListPageConfiguration };
}

function setRuleIncidentList(data) {
  return { type: types.SET_RULE_INCIDENT_LIST, ruleIncidentList: data };
}

function setCurrentEditRule(data) {
  return { type: types.SET_CURRENT_EDIT_RULE, currentEditRule: data };
}

function setCurrentAddRule(data) {
  return { type: types.SET_CURRENT_ADD_RULE, currentAddRule: data };
}

function setCurrentCloneRule(data) {
  return { type: types.SET_CURRENT_CLONE_RULE, currentCloneRule: data };
}

function setCurrentDeleteRuleStatus(data) {
  return { type: types.SET_CURRENT_DELETE_RULE, currentDeleteRule: data };
}

function setCurrentSaveRuleStatus(data) {
  return { type: types.SET_CURRENT_SAVE_RULE, currentSaveRule: data };
}

function setNonSliceTrainingData(data) {
  return {
    type: types.SET_NON_SLICE_RULE_TRAINING_DATA,
    nonSliceRuleTrainingData: data,
  };
}

function setSliceTrainingData(data) {
  return {
    type: types.SET_SLICE_RULE_TRAINING_DATA,
    sliceRuleTrainingData: data,
  };
}

function setCurrentAddPreview(data) {
  return { type: types.SET_CURRENT_ADD_PREVIEW, currentAddPreview: data };
}

function setPreviewList(data) {
  return { type: types.SET_PREVIEW_LIST, previewList: data };
}

function setPreviewIncidentList(data) {
  return { type: types.SET_PREVIEW_INCIDENT_LIST, previewIncidentList: data };
}

function setCurrentPreviewTrainingData(data) {
  return {
    type: types.SET_CURRENT_PREVIEW_TRAINING_DATA,
    currentPreviewTrainingData: data,
  };
}

function setCurrentPreviewSummaryData(data) {
  return {
    type: types.SET_CURRENT_PREVIEW_SUMMARY_DATA,
    currentPreviewSummaryData: data,
  };
}

function setCurrentPreviewIncidentMetricsData(data) {
  return {
    type: types.SET_CURRENT_PREVIEW_METRICS_DATA,
    currentPreviewMetricsData: data,
  };
}

function setCurrentPreviewSliceValue(data) {
  return {
    type: types.SET_CURRENT_PREVIEW_SLICE_VALUE,
    currentPreviewSliceValue: data,
  };
}

function setCurrentRuleStatus(data) {
  return { type: types.SET_CURRENT_RULE_STATUS, currentRuleStatus: data };
}

export function getRuleList(workspaceUuid, options = { quiet: false }) {
  return (dispatch, getState) => {
    const { quiet = false } = options;
    !quiet && dispatch(setRuleList({ loading: true, data: [] }));
    getRuleListPromise(workspaceUuid)
      .then(function (ruleList) {
        dispatch(setRuleList({ loading: false, data: ruleList }));
      })
      .catch(function (error) {
        dispatch(setRuleList({ loading: false, data: [] }));
        console.log(`Fail to load filter lists for ${error}`);
      });
  };
}

export function getRuleListPageConfiguration(workspaceUuid) {
  return (dispatch, getState) => {
    getPageConfigurationPromise(workspaceUuid, ListPageName.MONITORS)
      .then(function (newConfiguration) {
        dispatch(setRuleListPageConfiguration(newConfiguration));
      })
      .catch(function (error) {
        // handle error
        console.log(`Fail to load rule list page configuration for ${error}`);
        dispatch(setRuleListPageConfiguration({ columns: [] }));
      });
  };
}

export function updateRuleListPageConfiguration(workspaceUuid, newPageConfiguration) {
  return (dispatch, getState) => {
    updatePageConfigurationPromise(workspaceUuid, newPageConfiguration)
      .then(function (updatedPageConfiguration) {
        dispatch(setRuleListPageConfiguration(updatedPageConfiguration));
      })
      .catch(function (error) {
        console.log(`Failed to update rule list page configuration for ${error}`);
      });
  };
}

export function getRuleIncidentList(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    getIncidentListPromise(workspaceUuid, queryObject, false)
      .then(function (incidentList) {
        dispatch(setRuleIncidentList(incidentList));
      })
      .catch(function (err) {
        console.log(`Fail to get preview incident list ${err}`);
      });
  };
}

function updateRuleList(updatedRuleList) {
  return (dispatch, getState) => {
    const { ruleList } = getState().ruleReducer;
    const updatedRuleMapper = {};
    updatedRuleList.forEach((currentRule) => {
      updatedRuleMapper[currentRule.metadata.uuid] = currentRule;
    });

    const newRuleList = [];
    for (let currentOldRule of ruleList.data) {
      const currentNewRule =
        updatedRuleMapper[currentOldRule.metadata.uuid] || currentOldRule;
      newRuleList.push(currentNewRule);
    }

    dispatch(setRuleList({ loading: false, data: newRuleList }));
  };
}

export function updateRulesConfig(workspaceUuid, newRuleConfigs) {
  return (dispatch, getState) => {
    const allUpdatePromises = newRuleConfigs.map((ruleConfig) =>
      updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig)
    );

    Promise.all(allUpdatePromises)
      .then((updatedRuleList) => {
        updateRuleList(updatedRuleList)(dispatch, getState);
      })
      .catch((err) => {
        console.log(`Fail to update all rule list for ${err}`);
      });
  };
}

export function updateRuleTags(workspaceUuid, ruleConfig, newTags) {
  return (dispatch, getState) => {
    const {
      metadata: { tags = [] },
    } = ruleConfig;
    if (JSON.stringify(tags) === JSON.stringify(newTags)) {
      return;
    }

    ruleConfig.metadata.tags = newTags;
    updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig)
      .then(function (updatedRule) {
        updateRuleList([updatedRule])(dispatch, getState);
        dispatch(getTagList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(
          `Fail to update rule ${ruleConfig.metadata.uuid} tags for ${error}`
        );
      });
  };
}

export function addRule(workspaceUuid, newRule) {
  return (dispatch, getState) => {
    return addRulePromise(workspaceUuid, newRule)
      .then(function (response) {
        dispatch(setCurrentAddRule(response));
        dispatch(getRuleList(workspaceUuid));
      })
      .catch(function (error) {
        console.log(`Fail to create rule for ${error}`);
      });
  };
}

export function getRule(workspaceUuid, uuid) {
  return (dispatch, getState) => {
    getRulePromise(workspaceUuid, uuid)
      .then(function (rule) {
        dispatch(setCurrentEditRule(rule));
      })
      .catch(function (error) {
        console.log(`Fail to get rule for ${error}`);
      });
  };
}

export function getCloneRule(workspaceUuid, uuid) {
  return (dispatch, getState) => {
    getRulePromise(workspaceUuid, uuid)
      .then(function (rule) {
        rule.metadata = {
          name: rule.metadata.name,
          tags: rule.metadata.tags,
        };
        rule.config.isLive = false;
        rule.status = {
          training: {
            isTrained: false,
          },
        };
        dispatch(setCurrentCloneRule(rule));
      })
      .catch(function (error) {
        console.log(`Fail to get clone rule for ${error}`);
      });
  };
}

export function editRule(workspaceUuid, uuid, rule) {
  return (dispatch, getState) => {
    return updateRulePromise(workspaceUuid, uuid, rule)
      .then(function (newRule) {
        dispatch(setCurrentEditRule(newRule));
        dispatch(setCurrentSaveRuleStatus("successful"));
      })
      .catch(function (error) {
        dispatch(setCurrentSaveRuleStatus("failure"));
        console.log(`Fail to edit ${uuid} rule for ${error}`);
      });
  };
}

export function deleteRule(workspaceUuid, uuid) {
  return (dispatch, getState) => {
    return deleteRulePromise(workspaceUuid, uuid)
      .then(function (response) {
        console.log(`Monitor ${uuid} is deleted successfully`);
        dispatch(getRuleList(workspaceUuid));
        dispatch(setCurrentDeleteRuleStatus("successful"));
      })
      .catch(function (error) {
        dispatch(setCurrentDeleteRuleStatus("failure"));
        console.log(`Fail to delete Monitor due to ${error}`);
      });
  };
}

export function deleteRules(workspaceUuid, uuids) {
  return (dispatch, getState) => {
    const deletePromises = uuids.map((ruleUuid) =>
      deleteRulePromise(workspaceUuid, ruleUuid)
    );
    return Promise.allSettled(deletePromises).then(() => {
      dispatch(getRuleList(workspaceUuid));
    });
  };
}

export function resetCurrentEditRuleStatus(option = {}) {
  return (dispatch) => {
    const { saveStatus = false, deleteStatus = false } = option;

    if (!saveStatus && !deleteStatus) {
      dispatch(setCurrentDeleteRuleStatus("unknown"));
      dispatch(setCurrentSaveRuleStatus("unknown"));
    } else {
      if (saveStatus) {
        dispatch(setCurrentSaveRuleStatus("unknown"));
      }

      if (deleteStatus) {
        dispatch(setCurrentDeleteRuleStatus("unknown"));
      }
    }
  };
}

export function resetCurrentAddRuleStatus() {
  return (dispatch) => {
    dispatch(setCurrentAddRule(null));
    dispatch(setCurrentCloneRule(null));
  };
}

// Todo: Move all the metrics related function to this file for both incident and filter metrics.
export function getMetricsData(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    const {
      trainingPeriods,
      filterUuid,
      isSlice = false,
      slice = {},
      autoMetricType,
      metric = null,
    } = queryObject;
    const sliceKeyValuePairs = [];
    if (isSlice && slice && Object.keys(slice).length > 0) {
      for (let currentSliceKey in slice) {
        sliceKeyValuePairs.push({
          key: currentSliceKey,
          value: slice[currentSliceKey],
        });
      }
    }

    const getAllTrainingPeriodFilterDataPromise = trainingPeriods.map(
      ({ startTs: startTime, endTs: endTime }) => {
        return getFilterDataPromise(workspaceUuid, {
          startTime,
          endTime,
          filterUuid,
          sliceKeyValuePairs,
          autoMetricType,
          metric,
          isTrain: true,
        });
      }
    );

    dispatch(setNonSliceTrainingData([]));
    Promise.all(getAllTrainingPeriodFilterDataPromise)
      .then(function (allTrainingPeriodMetricsDataResponse) {
        dispatch(setNonSliceTrainingData(allTrainingPeriodMetricsDataResponse));
      })
      .catch(function (err) {
        console.log(`Fail to get all metrics data for non slice rule ${err}`);
      });
  };
}

function addPreviewInternal(workspaceUuid, newPreviewDb) {
  return (dispatch, getState) => {
    const { startTimestamp, endTimestamp, filterUuid, name } = newPreviewDb;
    const preview = {
      name,
      start_ts: startTimestamp,
      end_ts: endTimestamp,
    };

    addRulePreviewPromise(workspaceUuid, filterUuid, preview)
      .then(function (newPreview) {
        dispatch(getPreviewList(workspaceUuid, { filterUuid }));
        dispatch(setCurrentAddPreview(newPreview));
      })
      .catch(function (error) {
        console.log(`Fail to create rule for ${error}`);
      });
  };
}

export function addPreview(workspaceUuid, newPreviewDb) {
  return (dispatch, getState) => {
    const { previewList } = getState().ruleReducer;
    const { filterUuid } = newPreviewDb;
    let deleteAllPromise;
    if (previewList.length === 0) {
      // Nothing to delete. Always return true;
      deleteAllPromise = [Promise.resolve(true)];
    } else {
      deleteAllPromise = previewList.map((previewItem) =>
        deleteRulePreviewPromise(workspaceUuid, filterUuid, previewItem.id)
      );
    }

    Promise.all(deleteAllPromise)
      .then(function (deleteAllResponse) {
        addPreviewInternal(workspaceUuid, newPreviewDb)(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Fail to delete all the preview for ${err}`);
      });
  };
}

export function deletePreview(workspaceUuid, currentPreview) {
  return (dispatch, getState) => {
    const { filterUuid, previewId } = currentPreview;

    deleteRulePreviewPromise(workspaceUuid, filterUuid, previewId)
      .then(function () {
        dispatch(getPreviewList(workspaceUuid, { filterUuid }));
      })
      .catch(function (err) {
        console.log(`Fail to delete all the preview for ${err}`);
      });
  };
}

export function getPreviewList(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    const { filterUuid } = queryObject;
    getRulePreviewListPromise(workspaceUuid, filterUuid)
      .then(function (previewList) {
        dispatch(setPreviewList(previewList));
      })
      .catch(function (error) {
        console.log(`Fail to get preview list for ${error}`);
        dispatch(setPreviewList([]));
      });
  };
}

export function getPreviewIncidentList(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    getIncidentListPromise(workspaceUuid, queryObject)
      .then(function (incidentList) {
        dispatch(setPreviewIncidentList(incidentList));
      })
      .catch(function (err) {
        console.log(`Fail to get preview incident list ${err}`);
      });
  };
}

export function updatePreviewIncidentStatus(workspaceUuid, uuid, status) {
  return (dispatch, getState) => {
    return updateIncidentStatusPromise(workspaceUuid, uuid, status)
      .then(function (newIncidentData) {
        const { previewIncidentList } = getState().ruleReducer;
        for (let previewIncidentPerSlice of previewIncidentList) {
          for (let previewIncident of previewIncidentPerSlice.incidents) {
            if (previewIncident.uuid === uuid) {
              previewIncident.status = status;
              break;
            }
          }
        }
        dispatch(setPreviewIncidentList(previewIncidentList));
      })
      .catch(function (error) {
        console.log(`Fail to update preview incident ${uuid} status to ${status}`);
      });
  };
}

export function getCurrentPreviewTrainingData(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    getFilterDataPromise(workspaceUuid, queryObject)
      .then(function (metricsData) {
        dispatch(setCurrentPreviewTrainingData(metricsData));
      })
      .catch(function (err) {
        console.log(`Fail to get all metrics data for non slice rule ${err}`);
      });
  };
}

function getPreviewSummaryListFromServer(rawData) {
  let { total, unread, accepted, rejected } = rawData;

  rejected = rejected !== undefined ? rejected : total - unread - accepted;

  let falsePositiveRate = "N/A";
  if (total > 0) {
    falsePositiveRate = (rejected / total).toFixed(4);
    falsePositiveRate = `${100 * falsePositiveRate}%`;
  }

  return {
    total,
    unread,
    accepted,
    rejected,
    falsePositiveRate,
  };
}

export function getPreviewSummaryList(workspaceUuid, queryObject) {
  return (dispatch, setState) => {
    getIncidentListPromise(workspaceUuid, { ...queryObject, limit: 0 }, false, {}, true)
      .then(function (previewSummaryListData) {
        dispatch(
          setCurrentPreviewSummaryData(
            getPreviewSummaryListFromServer(previewSummaryListData.metadata)
          )
        );
      })
      .catch(function (err) {
        console.log(`Fail to get preview summary for ${err}`);
      });
  };
}

export function startPollTrainingStatus(workspaceUuid, pollManager, filterUuid) {
  return (dispatch, getState) => {
    pollManager
      .start(
        getRuleTrainingJobStatusPromise.bind(null, workspaceUuid, filterUuid),
        (trainingStatus) => isJobRunning(trainingStatus.status)
      )
      .then(function (trainingStatus) {
        getRulePromise(workspaceUuid, filterUuid)
          .then(function (updatedRule) {
            dispatch(
              setCurrentEditRule({
                ...getState().ruleReducer.currentEditRule,
                status: updatedRule.status,
              })
            );
          })
          .catch(function (err) {
            console.log(`Fail to update rule status for ${filterUuid}`);
          });
      })
      .catch(function (err) {
        console.log(`Fail to poll the training status for ${err}`);
      });
  };
}

function getPreviewStatusPromise(
  workspaceUuid,
  filterUuid,
  previewId,
  startTime,
  endTime,
  dispatch,
  getState
) {
  return new Promise(function (resolve, reject) {
    getRulePreviewJobStatusPromise(workspaceUuid, filterUuid, previewId)
      .then(function (response) {
        resolve(response);
        getPreviewSummaryList(workspaceUuid, {
          startTime,
          endTime,
          filterUuids: [filterUuid],
          previewUuids: [previewId],
        })(dispatch, getState);
      })
      .catch(function (error) {
        console.log(`Fail to get preview ${previewId} for ${error}`);
        reject(error);
      });
  });
}

export function startPollPreviewStatus(
  workspaceUuid,
  pollManager,
  { filterUuid, previewId, startTimestamp, endTimestamp }
) {
  return (dispatch, getState) => {
    pollManager
      .start(
        getPreviewStatusPromise.bind(
          null,
          workspaceUuid,
          filterUuid,
          previewId,
          startTimestamp,
          endTimestamp,
          dispatch,
          getState
        ),
        function (previewStatus) {
          return isJobRunning(previewStatus.status);
        }
      )
      .then(function (previewStatus) {
        console.log(`Preview is done with ${previewId}`);
        if (previewStatus.status === PodJobStatus.COMPLETE) {
          dispatch(getPreviewList(workspaceUuid, { filterUuid }));
        } else {
          console.log(`Preview is failed with ${JSON.stringify(previewStatus)}`);
        }
      })
      .catch(function (err) {
        console.log(`Fail to poll the preview status for ${err}`);
      });
  };
}

export function getCurrentPreviewMetricsData(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    const { sliceValues = [], ...otherQueryOptions } = queryObject;
    let allRequests = [];
    if (!sliceValues || sliceValues.length === 0) {
      allRequests = [getFilterDataPromise(workspaceUuid, queryObject)];
    } else {
      allRequests = sliceValues.map((currentSliceValue) => {
        const sliceKeyValuePairs = [];
        for (let key in currentSliceValue) {
          sliceKeyValuePairs.push({
            key,
            value: currentSliceValue[key],
          });
        }

        return getFilterDataPromise(workspaceUuid, {
          ...otherQueryOptions,
          sliceKeyValuePairs,
        });
      });
    }

    dispatch(setCurrentPreviewIncidentMetricsData({ loading: true, data: [] }));
    Promise.all(allRequests)
      .then(function (allMetricsDataResponse) {
        const currentPreviewMetricsData = [];
        for (let i = 0; i < allMetricsDataResponse.length; i++) {
          const sliceValue = sliceValues[i] || null;
          currentPreviewMetricsData.push({
            data: allMetricsDataResponse[i],
            sliceValue,
          });
        }
        dispatch(
          setCurrentPreviewIncidentMetricsData({
            loading: false,
            data: currentPreviewMetricsData,
          })
        );
      })
      .catch(function (err) {
        dispatch(setCurrentPreviewIncidentMetricsData({ loading: false, data: [] }));
        console.log(`Fail to get preview filter metrics for ${err}`);
      });
  };
}

export function getCurrentPreviewSliceValue(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    setCurrentPreviewSliceValue({ loading: true, data: [] });
    const { metric, ruleId, startTimestamp, endTimestamp } = queryObject;
    const sliceByColumns = getSliceByColumns(metric);
    if (!sliceByColumns?.length) {
      setCurrentPreviewSliceValue({ loading: false, data: [] });
      console.log(`It is not a valid slice metric with ${JSON.stringify(metric)}`);
      return;
    }

    getRuleSliceValueListPromise(workspaceUuid, ruleId, {
      startTimestamp,
      endTimestamp,
    })
      .then((sliceValues) => {
        const normalizedSliceValues = [];
        for (let sliceValue of sliceValues) {
          const currentNormalizedSliceValue = getUniqueSliceValue(
            sliceValue,
            sliceByColumns
          );
          const isCurrentSliceNotIncluded =
            normalizedSliceValues.findIndex((normalizedSliceValue) =>
              isSliceEqual(normalizedSliceValue, currentNormalizedSliceValue)
            ) === -1;
          if (isCurrentSliceNotIncluded) {
            normalizedSliceValues.push(currentNormalizedSliceValue);
          }
        }
        dispatch(
          setCurrentPreviewSliceValue({ loading: false, data: normalizedSliceValues })
        );
      })
      .catch((err) => {
        console.log(`Fail to get slice values ${err}`);
        dispatch(setCurrentPreviewSliceValue({ loading: false, data: [] }));
      });
  };
}

function getRuleStatusFromServer(currentRuleStatus) {
  const normalizedRuleStatus = [];
  const allRuleTrainingStatus = Object.values(SliceTrainingStatus);
  for (let currentRuleStatusItem of currentRuleStatus) {
    const {
      train_completed_ts: trainingCompletedTs,
      first_sample_ts: firstSampleTs,
      last_sample_ts: lastSampleTs,
      training_status: trainingStatus,
      slice,
    } = currentRuleStatusItem;
    const isTrained = SliceTrainingStatus.TRAINED === trainingStatus;
    const firstObserveTsStr =
      typeof firstSampleTs === "number" ? getStringFromTimeStamp(firstSampleTs) : "";

    const lastObserveTsStr =
      typeof lastSampleTs === "number" ? getStringFromTimeStamp(lastSampleTs) : "";

    if (typeof slice !== "object") {
      console.log(
        `Skip ${JSON.stringify(currentRuleStatusItem)} for slice is not an object`
      );
      continue;
    }

    const sliceEntries = Object.entries(slice);
    if (sliceEntries.length === 0) {
      // Skip the parent slice {} for sliced rule.
      continue;
    }

    if (!allRuleTrainingStatus.includes(trainingStatus)) {
      console.log(
        `Skip ${JSON.stringify(currentRuleStatusItem)} for invalid training status`
      );
      continue;
    }

    normalizedRuleStatus.push({
      slice,
      isTrained,
      trainingStatus,
      trainingCompletedTs,
      firstObserveTsStr,
      lastObserveTsStr,
    });
  }

  return normalizedRuleStatus;
}

export function getCurrentRuleStatus(workspaceUuid, filterUuid) {
  return (dispatch, getState) => {
    getRuleFilterStatusPromise(workspaceUuid, filterUuid)
      .then(function (currentRuleStatus) {
        dispatch(setCurrentRuleStatus(getRuleStatusFromServer(currentRuleStatus)));
      })
      .catch(function (err) {
        console.log(`Fail to get filter status for ${err}`);
      });
  };
}

export function resetRuleTrainingInfo() {
  return (dispatch, setState) => {
    dispatch(setNonSliceTrainingData([]));
    dispatch(setSliceTrainingData({}));
    dispatch(setPreviewList([]));
    dispatch(setCurrentRuleStatus([]));
  };
}

export function resetCurrentPreviewMetricsData() {
  return (dispatch, setState) => {
    dispatch(setCurrentPreviewIncidentMetricsData({ loading: true, data: [] }));
  };
}

export function resetRulePreviewInfo() {
  return (dispatch, setState) => {
    dispatch(setPreviewList([]));
    dispatch(setPreviewIncidentList([]));
    dispatch(setCurrentPreviewIncidentMetricsData({ loading: true, data: [] }));
    dispatch(
      setCurrentPreviewSliceValue({
        loading: true,
        data: [],
      })
    );
    dispatch(
      setCurrentPreviewTrainingData({
        ...metricsDataTemplate,
      })
    );
    dispatch(
      setCurrentPreviewSummaryData({
        total: 0,
        unread: 0,
        accepted: 0,
        rejected: 0,
        falsePositiveRate: "N/A",
      })
    );
  };
}
