export function UnderlineEditicon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24421 1.16675C9.399 1.16672 9.54746 1.22821 9.65689 1.33769L11.7875 3.46922C12.0152 3.69701 12.0152 4.06621 11.7875 4.294L5.75501 10.3291C5.6456 10.4386 5.49719 10.5001 5.34244 10.5001H3.20825C2.88609 10.5001 2.62492 10.2389 2.62492 9.91675V7.79339C2.62492 7.6388 2.68628 7.49053 2.79553 7.38115L8.8316 1.33785C8.94099 1.22833 9.08942 1.16678 9.24421 1.16675ZM9.24448 2.57538L3.79159 8.03481V9.33341H5.10074L10.5501 3.88161L9.24448 2.57538ZM1.45825 12.2501C1.45825 11.9279 1.71942 11.6667 2.04159 11.6667H12.5416C12.8638 11.6667 13.1249 11.9279 13.1249 12.2501C13.1249 12.5722 12.8638 12.8334 12.5416 12.8334H2.04159C1.71942 12.8334 1.45825 12.5722 1.45825 12.2501Z"
        fill="#121224"
      />
    </svg>
  );
}
