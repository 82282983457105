import React, { useState } from "react";
import AlertConfigSettingDialog from "../alert-config-setting-view/alert-config-setting-dialog";
import AutoMonitorRuleCreationDialog from "../auto-rule-creation-dialog/auto-monitor-rule-creation-dialog";
import { MonitorRunStatus, MonitorStatus } from "../../utils/enums";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import NgDropdownMenu from "../ng-dropdown-menu";
import {
  BellOutlined,
  DeleteOutlined,
  EditOutlined,
  OrderedListOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CloneIcon, PauseIcon, ResumeIcon } from "../../views/profiler/menu-icons";
import MonitorStatusBadge, {
  getMonitorBadgeStatus,
  monitorStatusBadgeConfig,
} from "../../views/monitors/monitor-status-badge";
import { ButtonPaddingSize } from "../button/ng-button";
import {
  canPauseMonitor,
  canResumeMonitor,
} from "../../views/monitors/monitor-status-menu";
import { countBy } from "../../utils/iterables";
import { monitorHasNoData } from "../../utils/monitor";
import NgTooltip from "../tooltip/ng-tooltip";

import { EVENT, getMonitorDetailProps, trackEvent } from "../../utils/telemetry";

import "./ng-index.scss";

function hasErrorStatus(monitor) {
  return [
    MonitorRunStatus.EXCEPTION,
    MonitorRunStatus.PAUSED_TRAINING_FAILURE,
  ].includes(getMonitorBadgeStatus(monitor));
}

function MonitorIncidentsIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M13.0661 11.7031L7.37857 1.85938C7.2938 1.71309 7.14751 1.64062 6.99986 1.64062C6.8522 1.64062 6.70454 1.71309 6.62115 1.85938L0.933647 11.7031C0.765483 11.9957 0.97603 12.3594 1.31236 12.3594H12.6874C13.0237 12.3594 13.2342 11.9957 13.0661 11.7031ZM6.56236 5.6875C6.56236 5.62734 6.61158 5.57812 6.67173 5.57812H7.32798C7.38814 5.57812 7.43736 5.62734 7.43736 5.6875V8.20312C7.43736 8.26328 7.38814 8.3125 7.32798 8.3125H6.67173C6.61158 8.3125 6.56236 8.26328 6.56236 8.20312V5.6875ZM6.99986 10.5C6.82813 10.4965 6.66462 10.4258 6.54441 10.3031C6.4242 10.1804 6.35687 10.0155 6.35687 9.84375C6.35687 9.67199 6.4242 9.50706 6.54441 9.38438C6.66462 9.26169 6.82813 9.19101 6.99986 9.1875C7.17159 9.19101 7.3351 9.26169 7.45531 9.38438C7.57552 9.50706 7.64285 9.67199 7.64285 9.84375C7.64285 10.0155 7.57552 10.1804 7.45531 10.3031C7.3351 10.4258 7.17159 10.4965 6.99986 10.5Z"
        fill="#B80739"
      />
    </svg>
  );
}

function ControlThresholdDataIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4173 12.2503C13.4173 12.5725 13.1562 12.8336 12.834 12.8336H1.16732C0.845151 12.8336 0.583984 12.5725 0.583984 12.2503V8.60449V4.95867C0.583984 4.73086 0.716599 4.52389 0.923569 4.42872C1.13054 4.33352 1.37398 4.36753 1.54695 4.51578L3.09592 5.84347L4.33246 4.04486C4.49733 3.80505 4.81373 3.72344 5.07402 3.85358L6.43653 4.53484L8.61823 2.22481C8.82838 2.00228 9.17482 1.98004 9.41171 2.17387L10.6073 3.15208L12.4215 1.33786C12.5883 1.17103 12.8392 1.12112 13.0572 1.21141C13.2752 1.3017 13.4173 1.51441 13.4173 1.75034V12.2503ZM5.43513 6.44691C5.18555 6.36372 4.91121 6.45787 4.76529 6.67674L3.31528 8.85175L1.75065 7.97164V6.22697L2.82936 7.15157C2.955 7.25929 3.12049 7.30852 3.28458 7.28708C3.44868 7.26565 3.59591 7.17552 3.68968 7.03914L5.00628 5.12408L6.30228 5.77208C6.53235 5.88714 6.81063 5.83788 6.98724 5.65086L9.09338 3.42083L10.2771 4.38932C10.5091 4.5791 10.8471 4.56225 11.059 4.35032L12.2507 3.15863V4.15612L10.7283 5.89597L9.41577 4.8022C9.17246 4.59943 8.81187 4.62784 8.6033 4.86619L6.8171 6.90756L5.43513 6.44691ZM3.98601 9.94889C3.81679 10.2027 3.48056 10.2833 3.21467 10.1337L1.75065 9.31024V11.667H12.2522V5.92611L11.2313 7.09277C11.0228 7.33112 10.6622 7.35953 10.4189 7.15677L9.10635 6.063L7.43967 7.96777C7.28491 8.14464 7.03912 8.21137 6.81617 8.13706L5.48886 7.69462L3.98601 9.94889Z"
        fill="#121224"
      />
    </svg>
  );
}

function ViewMonitorIcon({ fillColor = "#121224" }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.50033C0 3.17816 0.261167 2.91699 0.583333 2.91699H13.4167C13.7388 2.91699 14 3.17816 14 3.50033V10.5003C14 10.8225 13.7388 11.0837 13.4167 11.0837H8.75C8.59529 11.0837 8.44692 11.0222 8.33752 10.9128L7 9.57528L5.66248 10.9128C5.55308 11.0222 5.40471 11.0837 5.25 11.0837H0.583333C0.261167 11.0837 0 10.8225 0 10.5003V3.50033ZM2.04167 7.00033C2.04167 6.03384 2.82518 5.25033 3.79167 5.25033C4.75815 5.25033 5.54167 6.03384 5.54167 7.00033C5.54167 7.96681 4.75815 8.75033 3.79167 8.75033C2.82518 8.75033 2.04167 7.96681 2.04167 7.00033ZM8.45833 7.00033C8.45833 6.03384 9.24185 5.25033 10.2083 5.25033C11.1748 5.25033 11.9583 6.03384 11.9583 7.00033C11.9583 7.96681 11.1748 8.75033 10.2083 8.75033C9.24185 8.75033 8.45833 7.96681 8.45833 7.00033Z"
        fill={fillColor}
      />
    </svg>
  );
}

function monitorSubMenu({
  monitors = [],
  monitor,
  numIncidents,
  canAddMonitor,
  canEditMonitors,
  enableViewMonitors,
  canEditNotifications,
  onGoToMonitor,
  onCloneMonitor,
  onResumeMonitor,
  onPauseMonitor,
  onStopMonitor,
  setAlertingDialogTarget,
  enableSwitchMonitor,
  onSwitchMonitor,
  monitorUuid,
  thresholdConfig = null,
}) {
  const subMenuItems = [];

  if (numIncidents > 0) {
    const labelText = numIncidents > 1 ? `${numIncidents} Incidents` : "1 Incident";
    subMenuItems.push({
      label: <div className="ng-chart-monitor-menu-num-incidents">{labelText}</div>,
      icon: <MonitorIncidentsIcon />,
    });
    subMenuItems.push({ divider: true });
  }

  if (enableViewMonitors) {
    subMenuItems.push({
      label: canEditMonitors ? "Edit" : "View",
      icon: <EditOutlined />,
      onClick: () => {
        trackEvent(canEditMonitors ? EVENT.EDIT_MONITOR : EVENT.VIEW_MONITOR, {
          ...getMonitorDetailProps(monitor),
        });
        onGoToMonitor(monitor.metadata.uuid);
      },
    });
  }

  if (canEditMonitors && canResumeMonitor(monitor)) {
    subMenuItems.push({
      label: "Resume",
      icon: <ResumeIcon />,
      onClick: () => {
        trackEvent(EVENT.RESUME_MONITOR, {
          ...getMonitorDetailProps(monitor),
        });
        onResumeMonitor(monitor.metadata.uuid);
      },
    });
  }

  if (canEditMonitors && canPauseMonitor(monitor)) {
    subMenuItems.push({
      label: "Pause",
      icon: <PauseIcon />,
      onClick: () => {
        trackEvent(EVENT.PAUSE_MONITOR, {
          ...getMonitorDetailProps(monitor),
        });
        onPauseMonitor(monitor.metadata.uuid);
      },
    });
  }

  if (canEditNotifications) {
    subMenuItems.push({
      label: "Manage alerts",
      icon: <BellOutlined />,
      onClick: () => {
        trackEvent(EVENT.MANAGE_ALERTS_FOR_MONITOR, {
          ...getMonitorDetailProps(monitor),
        });

        setAlertingDialogTarget({ uuid: monitor.metadata.uuid });
      },
    });
  }

  if (canAddMonitor) {
    subMenuItems.push({
      label: "Clone",
      icon: <CloneIcon />,
      onClick: () => {
        trackEvent(EVENT.CLONE_MONITOR, {
          ...getMonitorDetailProps(monitor),
        });

        onCloneMonitor(monitor.metadata.uuid);
      },
    });
  }

  if (canEditMonitors) {
    subMenuItems.push({
      label: "Delete",
      icon: <DeleteOutlined />,
      danger: true,
      onClick: () => onStopMonitor(monitor.metadata.uuid),
    });
  }

  // We only show this option if there are multiple monitors.
  if (enableSwitchMonitor && monitors?.length > 1) {
    subMenuItems.push({
      label:
        monitorUuid === monitor.metadata.uuid ? "Stop Viewing" : "View this monitor",
      icon: <ViewMonitorIcon />,
      disabled: monitorHasNoData(monitor),
      onClick: () => {
        trackEvent(
          monitorUuid === monitor.metadata.uuid
            ? EVENT.STOP_VIEWING_MONITOR
            : EVENT.VIEW_MONITOR,
          {
            ...getMonitorDetailProps(monitor),
          }
        );

        onSwitchMonitor(
          monitorUuid === monitor.metadata.uuid ? "" : monitor.metadata.uuid
        );
      },
    });
  }

  if (thresholdConfig) {
    subMenuItems.push({
      disabled:
        // We will enable view threshold option when current monitor has data and is focused or there is only one monitor.
        (monitor.metadata.uuid !== monitorUuid && monitors?.length > 1) ||
        monitorHasNoData(monitor),
      label: thresholdConfig.chartOption.showStatsData
        ? "Hide threshold"
        : "View threshold",
      icon: <ControlThresholdDataIcon />,
      onClick: () => {
        trackEvent(
          thresholdConfig.chartOption.showStatsData
            ? EVENT.HIDE_MONITOR_THRESHOLD
            : EVENT.VIEW_MONITOR_THRESHOLD,
          {
            ...getMonitorDetailProps(monitor),
          }
        );

        const newShowStatsData = !thresholdConfig.chartOption.showStatsData;
        /*
         * If there is only one monitor, when we turn it on, there is a chance the focused monitor is empty,
         * we need to let parent element know it and it will trigger data loading.
         */

        if (newShowStatsData && monitor.metadata.uuid !== monitorUuid) {
          onSwitchMonitor(monitor.metadata.uuid);
        }

        thresholdConfig.onChartOptionChange(monitor.metadata.uuid, {
          ...thresholdConfig.chartOption,
          showStatsData: newShowStatsData,
        });
      },
    });
  }

  return subMenuItems;
}

function eventChartMonitorPopupMenuConfig({
  monitorStatus,
  loading,
  canEditMonitors,
  canEditNotifications,
  canAddMonitor,
  onStopMonitor,
  setAlertingDialogTarget,
  setIsCreateMonitorDialogOpen,
}) {
  const menuItems = [];
  const isMonitored = monitorStatus !== MonitorStatus.NOT_MONITORED;

  if (canAddMonitor && !isMonitored) {
    menuItems.push({
      label: "Add",
      icon: <PlusOutlined />,
      onClick: () => {
        trackEvent(EVENT.ADD_MONITOR);
        setIsCreateMonitorDialogOpen(true);
      },
    });
  }

  if (isMonitored && canEditNotifications) {
    menuItems.push({
      label: "Manage alerts",
      icon: <BellOutlined />,
      onClick: () => {
        trackEvent(EVENT.MANAGE_ALERTS_FOR_MONITOR);
        setAlertingDialogTarget({ singularMonitor: true });
      },
    });
  }

  if (canEditMonitors && isMonitored) {
    menuItems.push({
      label: "Delete",
      icon: <DeleteOutlined />,
      danger: true,
      onClick: () => onStopMonitor(),
    });
  }

  let buttonText;
  let buttonProps = {};
  if (loading) {
    buttonText = "Loading...";
    buttonProps = { disabled: true };
  } else if (monitorStatus === MonitorStatus.MONITORED) {
    buttonText = "1 Monitor";
  } else {
    buttonText = "0 Monitors";
  }

  return {
    buttonText,
    buttonProps,
    menuItems,
  };
}

function metricChartMonitorPopupMenuConfig({
  monitors,
  incidents,
  loading,
  enableViewMonitors,
  canEditMonitors,
  canEditNotifications,
  canAddMonitor,
  onGoToMonitor,
  onCloneMonitor,
  onResumeMonitor,
  onPauseMonitor,
  onStopMonitor,
  setAlertingDialogTarget,
  setIsCreateMonitorDialogOpen,
  thresholdConfig,
  enableSwitchMonitor,
  onSwitchMonitor,
  monitorUuid,
}) {
  const menuItems = [];
  const monitorIncidentCounts = countBy(
    incidents,
    (incident) => incident.filterUuid,
    (_) => 1
  );

  for (let monitor of monitors) {
    const numIncidents = monitorIncidentCounts[monitor.metadata.uuid] ?? [];
    const MonitorStatusIcon = monitorStatusBadgeConfig(monitor).Icon;
    menuItems.push({
      label: (
        <div className="ng-chart-monitor-menu-monitor-name">
          {monitor.metadata.name}
        </div>
      ),
      icon: <MonitorStatusIcon />,
      subMenu: monitorSubMenu({
        monitors,
        monitor,
        numIncidents,
        enableViewMonitors,
        canAddMonitor,
        canEditMonitors,
        canEditNotifications,
        onGoToMonitor,
        onCloneMonitor,
        onResumeMonitor,
        onPauseMonitor,
        onStopMonitor,
        setAlertingDialogTarget,
        thresholdConfig,
        enableSwitchMonitor,
        onSwitchMonitor,
        monitorUuid,
      }),
    });

    if (
      enableSwitchMonitor &&
      monitor.metadata.uuid === monitorUuid &&
      monitors?.length > 1
    ) {
      menuItems.push({
        label: (
          <div className="ng-chart-monitor-menu-focused-monitor-status">
            <ViewMonitorIcon fillColor={"#4832F3"} />
            Viewing
          </div>
        ),
        icon: null,
        disabled: true,
      });
    }
  }

  if (canAddMonitor) {
    if (monitors.length > 0) {
      menuItems.push({ divider: true });
    }
    menuItems.push({
      label: "Add",
      icon: <PlusOutlined />,
      onClick: () => {
        setIsCreateMonitorDialogOpen(true);
      },
    });
  }

  let buttonText;
  let buttonProps = {};
  if (loading) {
    buttonText = "Loading...";
    buttonProps = { disabled: true };
  } else if (monitors.length === 1) {
    buttonText = (
      <div className="ng-chart-monitor-menu-btn-badge">
        <MonitorStatusBadge monitor={monitors[0]} enableSlicingPopover={false} />
      </div>
    );
    buttonProps = { paddingSize: ButtonPaddingSize.TINY };
    if (hasErrorStatus(monitors[0])) {
      buttonProps.critical = true;
    }
  } else if (monitors.length > 1 && monitors.some(hasErrorStatus)) {
    buttonText = "Monitor Error";
  } else {
    buttonText = `${monitors.length} Monitors`;
  }

  return {
    buttonText,
    buttonProps,
    menuItems,
  };
}

function ChartMonitorPopupMenu(props) {
  const {
    singularMonitor = false,
    monitorStatus,
    relatedRules: monitors = [],
    incidents = [],
    alertingChannelList = [],
    metric,
    metricType,
    // If workspaceUserPermissions not passed, allow full access
    workspaceUserPermissions = { isSuperuser: true, permissions: [] },
    onGoToRule: onGoToMonitor,
    onCloneMonitor,
    onPauseMonitor,
    onResumeMonitor,
    onSwitchMonitor,
    onAlertChannelChange,
    onStopMonitor,
    onStartMonitor,
    enableAddMonitors = true,
    enableViewMonitors = true,
    enableEditMonitors = true,
    enableEditNotifications = true,
    enableSwitchMonitor = false,
    monitorUuid = "",
    currentAlertingChannels,
    thresholdConfig,
    loading = false,
  } = props;

  const [isCreateMonitorDialogOpen, setIsCreateMonitorDialogOpen] = useState(false);
  const [alertingDialogTarget, setAlertingDialogTarget] = useState(null);

  function onCreateNewMonitor(newSymptomTypeConfig) {
    onStartMonitor && onStartMonitor(newSymptomTypeConfig);
  }

  const canEditMonitors =
    enableEditMonitors &&
    hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERDETAIL,
    ]);
  const canAddMonitor =
    enableAddMonitors &&
    hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
    ]);
  const canEditNotifications =
    enableEditNotifications &&
    hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_ALERTING_CHANNEL_VIEWS_EDIT_ALERTINGCHANNELLIST,
    ]);

  const { buttonText, buttonProps, menuItems } = singularMonitor
    ? eventChartMonitorPopupMenuConfig({
        monitorStatus,
        loading,
        canEditMonitors,
        canEditNotifications,
        canAddMonitor,
        onStopMonitor,
        setAlertingDialogTarget,
        setIsCreateMonitorDialogOpen,
      })
    : metricChartMonitorPopupMenuConfig({
        monitors,
        incidents,
        loading,
        canEditMonitors,
        enableViewMonitors,
        canEditNotifications,
        canAddMonitor,
        onCloneMonitor,
        onGoToMonitor,
        onResumeMonitor,
        onPauseMonitor,
        onStopMonitor,
        onSwitchMonitor,
        setAlertingDialogTarget,
        setIsCreateMonitorDialogOpen,
        enableSwitchMonitor,
        monitorUuid,
        thresholdConfig,
      });

  const buttonContent = (
    <div className="ng-chart-monitor-menu-btn-content">
      {buttonText}
      <OrderedListOutlined />
    </div>
  );

  const alertingDialogTargetMonitor = alertingDialogTarget?.uuid
    ? monitors.find((monitor) => monitor.metadata.uuid === alertingDialogTarget.uuid)
    : null;
  const alertingChannelsDialogOpen =
    alertingDialogTarget?.singularMonitor || Boolean(alertingDialogTargetMonitor);

  return (
    <>
      <NgTooltip title={!loading && "Monitor Menu"}>
        <span>
          <NgDropdownMenu
            buttonContent={buttonContent}
            buttonProps={{
              ...buttonProps,
              testId: "open-chart-monitor-context-menu",
            }}
            menuItems={menuItems}
            className="ng-chart-monitor-menu"
          />
        </span>
      </NgTooltip>

      {alertingChannelsDialogOpen && (
        <AlertConfigSettingDialog
          modalIsOpen={alertingChannelsDialogOpen}
          setModalIsOpen={(isOpen) => {
            if (!isOpen) {
              setAlertingDialogTarget(null);
            }
          }}
          currentAlertingChannels={
            alertingDialogTargetMonitor?.config?.alertConfig ?? currentAlertingChannels
          }
          alertingChannelList={alertingChannelList}
          okClicked={(newChannels) => {
            onAlertChannelChange(alertingDialogTargetMonitor, newChannels);
          }}
        />
      )}
      {isCreateMonitorDialogOpen && (
        <AutoMonitorRuleCreationDialog
          modalIsOpen={isCreateMonitorDialogOpen}
          setModalIsOpen={setIsCreateMonitorDialogOpen}
          alertingChannelList={alertingChannelList}
          okClicked={onCreateNewMonitor}
          metric={metric}
          metricType={metricType}
        />
      )}
    </>
  );
}

export default ChartMonitorPopupMenu;
