import { UserRole } from "./enums";
import { isAdminUser, isEditorUser } from "./roles";

export function getApprovalUserRole(workspaceUserPermissions) {
  if (isAdminUser(workspaceUserPermissions)) {
    return UserRole.ADMIN;
  }
  if (isEditorUser(workspaceUserPermissions)) {
    return UserRole.EDITOR;
  }
  return null;
}

export function getApprovalConfig(workspace, workspaceUserPermissions) {
  const enableApprovals = workspace?.config?.enableApprovals ?? null;
  const approvalRole = getApprovalUserRole(workspaceUserPermissions);
  if (!enableApprovals || !approvalRole) {
    return null;
  }
  return {
    ...enableApprovals,
    canCreateDraft:
      Boolean(enableApprovals.metricApproval) && approvalRole === UserRole.EDITOR,
    canApproveDraft:
      Boolean(enableApprovals.metricApproval) && approvalRole === UserRole.ADMIN,
  };
}
