import React, { useState } from "react";
import Button, { ButtonPaddingSize } from "../button/ng-button";
import { SettingOutlined } from "@ant-design/icons";
import Popover from "../popover";
import { CheckboxGroup } from "../../atom/checkbox";

import "./column-list-control.scss";

function ColumnListControl(props) {
  const { value, onChange, options } = props;
  const [visible, setVisible] = useState(false);

  function onVisibleChange(visible) {
    setVisible(visible);
  }

  const popOverContent = (
    <div className="column-list-controls-popover-content-container">
      <CheckboxGroup options={options} defaultValue={value} onChange={onChange} />
    </div>
  );

  // The extra wrapping div here seems necessary because <Popover> will clobber
  // the class on the div immediately within it when it gets opened.
  const trigger = (
    <div>
      <Button
        outline
        size="large"
        paddingSize={ButtonPaddingSize.SMALL}
        testId="column-list-controls"
        aria-label="column-list"
      >
        <SettingOutlined />
      </Button>
    </div>
  );

  return (
    <Popover
      overlayClassName="column-list-controls-popover-container"
      content={popOverContent}
      trigger="click"
      placement={"bottomLeft"}
      onVisibleChange={onVisibleChange}
      visible={visible}
    >
      {trigger}
    </Popover>
  );
}

export default ColumnListControl;
