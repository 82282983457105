import {
  SET_NOTIFICATION_LIST,
  SET_NOTIFICATION_MONITOR_LIST,
  SET_NOTIFICATION_DETAIL_INFO,
} from "../../actions/notification/notification-action-types";

const initialNotificationState = {
  notificationList: {
    total: 0,
    data: [],
  },
  notificationDetailInfo: {
    loading: false,
    data: null,
  },
  notificationMonitorList: {
    loading: true,
    data: [],
  },
};

export default function notificationReducer(
  state = initialNotificationState,
  action = {}
) {
  let newState;
  switch (action.type) {
    case SET_NOTIFICATION_LIST:
      newState = { ...state, notificationList: action.notificationList };
      return newState;

    case SET_NOTIFICATION_DETAIL_INFO:
      newState = { ...state, notificationDetailInfo: action.notificationDetailInfo };
      return newState;

    case SET_NOTIFICATION_MONITOR_LIST:
      newState = { ...state, notificationMonitorList: action.notificationMonitorList };
      return newState;

    default:
      return state;
  }
}
