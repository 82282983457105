import gridOnlyBackground from "../../assets/img/enable_container_grid_only.png";
import NgTooltip from "../../components/tooltip/ng-tooltip";

import "./profiler-exception-view.scss";

export function ExceptionIcon() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 1.25C4.86929 1.25 3.75 2.36929 3.75 3.75V26.25C3.75 27.6307 4.8693 28.75 6.25 28.75H13.75C14.4404 28.75 15 28.1904 15 27.5C15 26.8096 14.4404 26.25 13.75 26.25H6.25V3.75H17.5V8.75C17.5 9.44036 18.0596 10 18.75 10H23.75V14.375C23.75 15.0654 24.3096 15.625 25 15.625C25.6904 15.625 26.25 15.0654 26.25 14.375V8.75C26.25 8.42673 26.1273 8.13212 25.9259 7.91022L20.3041 1.66379C20.0671 1.4004 19.7294 1.25 19.375 1.25H6.25ZM20 5.063V7.5H22.1933L20 5.063Z"
        fill="#B80739"
      />
      <path
        d="M19.0089 17.8661C18.5207 17.378 17.7293 17.378 17.2411 17.8661C16.753 18.3543 16.753 19.1457 17.2411 19.6339L20.1072 22.5L17.2411 25.3661C16.753 25.8543 16.753 26.6457 17.2411 27.1339C17.7293 27.622 18.5207 27.622 19.0089 27.1339L21.875 24.2678L24.7411 27.1339C25.2293 27.622 26.0207 27.622 26.5089 27.1339C26.997 26.6457 26.997 25.8543 26.5089 25.3661L23.6428 22.5L26.5089 19.6339C26.997 19.1457 26.997 18.3543 26.5089 17.8661C26.0207 17.378 25.2293 17.378 24.7411 17.8661L21.875 20.7322L19.0089 17.8661Z"
        fill="#B80739"
      />
    </svg>
  );
}

const ProfilerExceptionView = (props) => {
  const { children, runStatusMessage } = props;
  const errorContent = (
    <div className="profiler-exception-view-container-content-error">
      {runStatusMessage}
    </div>
  );

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${gridOnlyBackground})`,
        }}
        className="profiler-exception-view-container"
      >
        <div className="profiler-exception-view-container-content">
          <ExceptionIcon />
          <NgTooltip title={errorContent}>
            <span className="profiler-exception-view-container-content-text">
              DATA PROFILE EXCEPTION
            </span>
          </NgTooltip>
        </div>
      </div>

      {children}
    </>
  );
};

export default ProfilerExceptionView;
