import React, { useState, useEffect } from "react";
import {
  EditOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Button, { ButtonPaddingSize } from "../../button/ng-button";
import ConfigCard from "../config-card";
import {
  AggregationWindowConfigItem,
  BackfillDurationConfigItem,
  CustomScheduleCollectionConfigItem,
  DataCollectionScheduleConfigItem,
  DataCollectionWindowConfigItem,
  FieldRow,
  MissingValueFillingConfigItem,
  PartitionsConfigItem,
  QueryScopeConfigItem,
  QueryTimezoneConfigItem,
  SeasonalityConfigItem,
  SliceByColumnsConfigItem,
  SynchronizationDelayConfigItem,
  TimestampColumnConfigItem,
  TimestampTimezoneConfigItem,
} from "./index";
import VirtualTimestampColumnForm from "../../../views/profiler/takeover/config/table/virtual-timestamp-column-form";
import {
  isDataCollectionWindowRequired,
  removeCollectionAttributesFromConfig,
} from "../metric-utils";
import {
  aggregationWindowOptions,
  betaFullTableMetricAggregationWindowOptions,
  fullTableMetricAggregationWindowOptions,
  getMetricInterval,
  getMetricTypeFromConfigData,
  getSeasonalityList,
  removeSecondAndMinuteOptions,
  streamingMetricAggregationWindowOptions,
} from "../utils";
import {
  isFeatureEnabled,
  isTimestampTimezoneConfigEnabled,
  isTriggeredCollectionMode,
} from "../../../utils/general";
import { metricQueryScopeOptions } from "../../../utils/options";
import MetricCustomSeasonalityTakeoverView from "../tabs/data-asset/metric-custom-seasonality-takeover-view";
import {
  AggregationWindowType,
  CollectionModeType,
  CollectionWindow,
  MetricCategory,
  MetricConfigType,
  SupportedFeature,
  TakeoverWidth,
  isFileSource as getIsFileSource,
} from "../../../utils/enums";
import {
  getDefaultBackfillDuration,
  getMetricConfigTypeFromTableQueryScope,
  isTableOrColumnActivityMetric,
} from "../../../utils/metric";
import { isPartitionConfigEnabled } from "../../../utils/datasource";
import MetricCollectionSummary from "../../metric-collection-summary";
import ButtonIcon from "../../button/button-icon";
import { classesName } from "../../../utils/css";

import "./configure-metric.scss";

function editModeOnChangeCallback(isEditMode, fn) {
  return (...args) => {
    if (isEditMode) {
      fn(...args);
    }
  };
}

export function ConfigureMetricTitle(props) {
  const { workspaceUuid, configData, isValidConfig } = props;
  return (
    <div className="metric-config-configure-metric-title">
      Configure metric
      <MetricCollectionSummary workspaceUuid={workspaceUuid} metric={configData}>
        <ButtonIcon icon={<QuestionCircleOutlined />} disabled={!isValidConfig} />
      </MetricCollectionSummary>
    </div>
  );
}

// non second and minute options
const nonSMAggregationIntervalOptions = removeSecondAndMinuteOptions(
  aggregationWindowOptions
);
const nonSMAggregationIntervalStreamingOptions = removeSecondAndMinuteOptions(
  streamingMetricAggregationWindowOptions
);

function ConfigureMetric(props) {
  const {
    enableAggregationWindow = true,
    enableBackfillDuration = true,
    enableDataCollectionSchedule = true,
    enableDataCollectionWindow = true,
    enableMissingValueFilling = false,
    enableQueryScope = true,
    enableQueryTimezone = true,
    enableSeasonality = true,
    enableSlicing = false,
    enableSynchronizationDelay = true,
    enableTimestampColumn = true,
    enablePartitionConfig = true,
    configData,
    isValidConfig,
    isStreaming,
    dataSourceList,
    tableList,
    columnList,
    isEditMode,
    allowOverride,
    onConfigDataChange,
    openWorkspaceTakeover,
    closeTakeover,
    partitionSampleData,
    getPartitionSampleData,
    sliceByColumnsGetter = (configData) => configData.config?.sliceByColumns,
    onSliceByColumnsChange,
    waffle,
    disabled,
  } = props;

  const isExistingMetric = !!configData.metadata?.uuid;

  const dataSourceInfo = dataSourceList.find(
    (dataSource) => configData.config.sources[0] === dataSource.metadata.uuid
  );

  const isFileSource = getIsFileSource(dataSourceInfo?.config.connection.type);

  const [isVirtualTimestampFormVisible, setIsVirtualTimestampFormVisible] =
    useState(false);

  const isPartitionRequired =
    dataSourceInfo && isPartitionConfigEnabled(dataSourceInfo.config.connection.type);

  const windowConfig = getMetricInterval(
    configData.config.table,
    configData.config.aggregation
  );
  const seasonalityList = getSeasonalityList(configData.config.aggregation);

  // used to avoid change the default missing value filling for conformity metric
  const isConformityMetric =
    getMetricTypeFromConfigData(configData) === MetricCategory.CONFORMITY_COUNT;

  const isTableActivity =
    getMetricTypeFromConfigData(configData) === MetricCategory.TABLE_ACTIVITY;

  const isColumnActivity =
    getMetricTypeFromConfigData(configData) === MetricCategory.COLUMN_ACTIVITY;

  const isReadOnlyField = isTableOrColumnActivityMetric(configData);

  const overrideButton = (
    <Button
      outline
      disabled={!configData.config.table?.tableUuid || disabled}
      onClick={() => {
        const newInheritTableSettings = !configData.config.inheritTableSettings;
        if (newInheritTableSettings) {
          const currentTable = tableList.find(
            ({ tableUuid }) => configData.config.table?.tableUuid === tableUuid
          );

          const {
            collectionMode,
            collectionWindow = null,
            dataTimezone,
            partitionTimezone,
            timestampColumn,
            timezone: queryTimezone,
            window: aggregationWindow,
            timestampColumnFunctions = null,
            partitions = [],
            partitionOffsets,
            syncDelay: synchronizationDelay,
            queryScope,
            pollingWindow,
            pollingTimezone,
            pollingDelay,
          } = currentTable.profilerConfig;

          const configType = getMetricConfigTypeFromTableQueryScope(queryScope);
          let newConfig = removeCollectionAttributesFromConfig(
            configData.config,
            configType === MetricConfigType.METRIC_CONFIG
          );
          if (configType === MetricConfigType.METRIC_CONFIG) {
            newConfig = {
              ...newConfig,
              configType,
              collectionMode,
              collectionWindow,
              aggregation: {
                ...configData.config.aggregation,
                aggregationWindow,
              },
              inheritTableSettings: newInheritTableSettings,
              dataTimezone,
              partitionTimezone,
              timestampColumn,
              queryTimezone,
              timestampColumnFunctions,
              partitions,
              partitionOffsets,
              synchronizationDelay,
            };
          } else {
            newConfig = {
              ...newConfig,
              configType,
              collectionMode,
              inheritTableSettings: newInheritTableSettings,
              pollingWindow,
              pollingTimezone,
              pollingDelay,
            };
          }
          onConfigDataChange({
            ...configData,
            config: newConfig,
          });
        } else {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              inheritTableSettings: newInheritTableSettings,
            },
          });
        }
        setIsVirtualTimestampFormVisible(false);
      }}
    >
      {isEditMode ? "Inherit Settings" : "Override Settings"}
      <EditOutlined />
    </Button>
  );
  const getColumnTableDescription = () => {
    const tableDescription =
      "The Table Activity chart is a daily chart that reports changes to tables. Its data is collected from events detected during schema scans, and chart results may vary based on the schema scan interval.";
    const columnDescription =
      "The Column Activity chart is a daily chart that reports changes to columns. Its data is collected from events detected during schema scans, and chart results may vary based on the schema scan interval.";

    return isColumnActivity ? columnDescription : tableDescription;
  };

  const editModeClass = isEditMode ? "edit-mode" : "";
  let description;
  if (!allowOverride) {
    description = isReadOnlyField
      ? getColumnTableDescription()
      : "Global table settings are inherited from the table configuration.";
  } else if (isEditMode) {
    description =
      "This metric isn't inheriting global settings. Change the settings below as needed, or click Inherit Settings to resume inheriting global settings for this metric.";
  } else {
    description =
      "Global table settings are inherited from the table configuration. These can be configured and overridden.";
  }

  function defaultOnSliceByColumnChange(sliceByColumns) {
    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        sliceValueSelections: [],
        sliceByColumns,
      },
    });
  }

  const { partitions, timestampColumn, timestampColumnFunctions } = configData.config;

  useEffect(() => setIsVirtualTimestampFormVisible(false), [timestampColumn]);

  const isMetricConfigType =
    configData.config.configType === MetricConfigType.METRIC_CONFIG;

  const workspaceUuid = dataSourceList?.[0]?.metadata?.workspaceId;
  const title = (
    <ConfigureMetricTitle
      configData={configData}
      isValidConfig={isValidConfig}
      workspaceUuid={workspaceUuid}
    />
  );

  const isSecondAndMinuteEnabled = isFeatureEnabled(
    waffle,
    SupportedFeature.ENABLE_SECOND_AND_MINUTE_AGGREGATION
  );
  const aggregationIntervalOptions = isSecondAndMinuteEnabled
    ? aggregationWindowOptions
    : nonSMAggregationIntervalOptions;
  const aggregationIntervalStreamingOptions = isSecondAndMinuteEnabled
    ? streamingMetricAggregationWindowOptions
    : nonSMAggregationIntervalStreamingOptions;

  const shouldShowBottomSection =
    enableSlicing ||
    enableSeasonality ||
    enableMissingValueFilling ||
    (enableBackfillDuration && isMetricConfigType);

  const isFullTableCustomScheduled =
    configData.config.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG &&
    configData.config.collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED;

  return (
    <ConfigCard
      title={title}
      description={description}
      actionComponent={allowOverride && overrideButton}
      testId="metric-wizard-configure-metric"
    >
      <div
        className={classesName(
          "metric-config-configure-metric-temporal",
          editModeClass,
          isDataCollectionWindowRequired(configData) &&
            isEditMode &&
            "with-collection-window"
        )}
      >
        {enableQueryScope && (
          <QueryScopeConfigItem
            isEdit={isEditMode}
            disabled={disabled}
            value={configData.config.configType}
            options={
              isFileSource
                ? metricQueryScopeOptions.filter(
                    ({ value }) => value === MetricConfigType.METRIC_CONFIG
                  )
                : metricQueryScopeOptions
            }
            onChange={editModeOnChangeCallback(isEditMode, (newConfigType) => {
              let newConfig = removeCollectionAttributesFromConfig(
                configData.config,
                newConfigType === MetricConfigType.METRIC_CONFIG
              );
              if (newConfigType === MetricConfigType.METRIC_CONFIG) {
                newConfig = {
                  ...newConfig,
                  configType: newConfigType,
                  timestampColumn: "",
                  timestampColumnFunctions: null,
                  queryTimezone: "UTC",
                  dataTimezone: "UTC",
                  partitionTimezone: "UTC",
                  pollingInterval: 300,
                  partitions: [],
                  synchronizationDelay: 0,
                  collectionWindow: CollectionWindow.COMPLETE,
                };
              } else {
                newConfig = {
                  ...newConfig,
                  collectionMode:
                    configData.config.collectionMode?.type ===
                    CollectionModeType.CUSTOM_SCHEDULED
                      ? { type: CollectionModeType.SCHEDULED }
                      : configData.config.collectionMode,
                  collectionWindow: null,
                  configType: newConfigType,
                  pollingWindow: AggregationWindowType.DAY,
                  pollingTimezone: "UTC",
                  pollingDelay: 0,
                };
              }

              onConfigDataChange({
                ...configData,
                config: newConfig,
              });
            })}
          />
        )}
        {enableDataCollectionSchedule && (
          <DataCollectionScheduleConfigItem
            disabled={disabled}
            isEdit={isEditMode}
            isBlobStorage={isFileSource}
            value={configData.config.collectionMode}
            onChange={editModeOnChangeCallback(isEditMode, (newCollectionMode) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  collectionMode: newCollectionMode,
                },
              });
            })}
          />
        )}
        {enableDataCollectionWindow && isDataCollectionWindowRequired(configData) && (
          <DataCollectionWindowConfigItem
            disabled={disabled}
            isEdit={isEditMode}
            value={configData.config.collectionWindow}
            onChange={editModeOnChangeCallback(isEditMode, (newCollectionWindow) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  collectionWindow: newCollectionWindow,
                },
              });
            })}
          />
        )}
        <CustomScheduleCollectionConfigItem
          workspaceUuid={workspaceUuid}
          disabled={disabled}
          isEdit={isEditMode}
          value={configData.config.collectionMode}
          onChange={editModeOnChangeCallback(isEditMode, (newCollectionMode) => {
            onConfigDataChange({
              ...configData,
              config: {
                ...configData.config,
                collectionMode: newCollectionMode,
              },
            });
          })}
        />
        {enableAggregationWindow && isMetricConfigType && (
          <AggregationWindowConfigItem
            isEdit={isEditMode}
            options={
              isStreaming
                ? aggregationIntervalStreamingOptions
                : aggregationIntervalOptions
            }
            disabled={disabled || isTableActivity || isColumnActivity}
            value={configData.config?.aggregation?.aggregationWindow}
            onChange={editModeOnChangeCallback(isEditMode, (newAggregationWindow) => {
              const oldAggregation = configData.config?.aggregation || {};
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  aggregation: {
                    ...oldAggregation,
                    aggregationWindow: newAggregationWindow,
                  },
                  // Don't adjust backfill duration on existing metrics. We expect the backend
                  // to show an error if the existing backfill duration winds up being invalid.
                  backfillDuration: isExistingMetric
                    ? configData.config.backfillDuration
                    : getDefaultBackfillDuration(newAggregationWindow, dataSourceInfo),
                },
              });
            })}
          />
        )}
        {enableQueryTimezone && isMetricConfigType && (
          <QueryTimezoneConfigItem
            isEdit={isEditMode}
            isUTCOnly={isFileSource}
            disabled={disabled || isTableActivity || isColumnActivity}
            value={configData.config.queryTimezone}
            onChange={editModeOnChangeCallback(isEditMode, (newQueryTimezone) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  queryTimezone: newQueryTimezone,
                },
              });
            })}
          />
        )}
        {enableSynchronizationDelay && isMetricConfigType && (
          <SynchronizationDelayConfigItem
            disabled={disabled}
            isEdit={isEditMode}
            value={configData.config.synchronizationDelay}
            onChange={editModeOnChangeCallback(
              isEditMode,
              (newSynchronizationDelay) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    synchronizationDelay: newSynchronizationDelay,
                  },
                });
              }
            )}
          />
        )}
        {isMetricConfigType && enableTimestampColumn && (
          <TimestampColumnConfigItem
            disabled={disabled}
            isEdit={isEditMode}
            supportVirtualTimestamp={true}
            enableAdd={!isFileSource}
            dataSource={dataSourceInfo}
            partitions={partitions}
            columnList={columnList}
            value={{ timestampColumn, timestampColumnFunctions }}
            onChange={editModeOnChangeCallback(
              isEditMode,
              ({
                timestampColumn: newTimestampColumn,
                timestampColumnFunctions: newTimestampColumnFunctions,
              }) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    timestampColumn: newTimestampColumn,
                    timestampColumnFunctions: newTimestampColumnFunctions || null,
                  },
                });
              }
            )}
            onAddVirtualTimestamp={() => {
              setIsVirtualTimestampFormVisible(true);
            }}
            onEditVirtualTimestamp={() => {
              setIsVirtualTimestampFormVisible(true);
            }}
          />
        )}
        {isVirtualTimestampFormVisible && (
          <div className="configure-metric-virtual-timezone-container configure-metric-full-row">
            <VirtualTimestampColumnForm
              isAdd={!timestampColumnFunctions}
              value={{ timestampColumn, timestampColumnFunctions }}
              dataSource={dataSourceInfo}
              columnList={columnList}
              onDelete={() => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    timestampColumn: null,
                    timestampColumnFunctions: null,
                  },
                });
                setIsVirtualTimestampFormVisible(false);
              }}
              onCancel={() => setIsVirtualTimestampFormVisible(false)}
              onSave={(newTimestampColumnInfo) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    timestampColumn: newTimestampColumnInfo.timestampColumn,
                    timestampColumnFunctions:
                      newTimestampColumnInfo.timestampColumnFunctions,
                  },
                });
                setIsVirtualTimestampFormVisible(false);
              }}
            />
          </div>
        )}
        {isTimestampTimezoneConfigEnabled(
          columnList.find(
            ({ columnName }) => columnName === configData.config.timestampColumn
          )?.columnType
        ) &&
          isMetricConfigType &&
          enableTimestampColumn && (
            <TimestampTimezoneConfigItem
              disabled={disabled}
              isEdit={isEditMode}
              isUTCOnly={isFileSource}
              value={configData.config.dataTimezone}
              onChange={editModeOnChangeCallback(isEditMode, (newDataTimezone) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    dataTimezone: newDataTimezone,
                  },
                });
              })}
            />
          )}
        {!isMetricConfigType &&
          !isTriggeredCollectionMode(configData.config.collectionMode) &&
          !isFullTableCustomScheduled && (
            <AggregationWindowConfigItem
              label="Polling interval"
              isEdit={isEditMode}
              options={
                isFeatureEnabled(waffle, SupportedFeature.FULL_TABLE_METRIC_HOURLY)
                  ? betaFullTableMetricAggregationWindowOptions
                  : fullTableMetricAggregationWindowOptions
              }
              value={configData.config?.pollingWindow}
              onChange={editModeOnChangeCallback(isEditMode, (newPollingWindow) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    pollingWindow: newPollingWindow,
                  },
                });
              })}
              disabled={disabled || isReadOnlyField}
            />
          )}
        {!isMetricConfigType &&
          !isTriggeredCollectionMode(configData.config.collectionMode) &&
          !isFullTableCustomScheduled && (
            <QueryTimezoneConfigItem
              label="Polling timezone"
              isEdit={isEditMode}
              value={configData.config.pollingTimezone}
              onChange={editModeOnChangeCallback(isEditMode, (newPollingTimezone) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    pollingTimezone: newPollingTimezone,
                  },
                });
              })}
              disabled={disabled || isReadOnlyField}
            />
          )}
        {!isMetricConfigType &&
          !isTriggeredCollectionMode(configData.config.collectionMode) &&
          !isTableOrColumnActivityMetric(configData) && (
            <SynchronizationDelayConfigItem
              label="Polling delay"
              disabled={disabled}
              isEdit={isEditMode}
              value={configData.config.pollingDelay || 0}
              onChange={editModeOnChangeCallback(isEditMode, (newPollingDelay) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    pollingDelay: newPollingDelay,
                  },
                });
              })}
            />
          )}
      </div>
      {isPartitionRequired && enablePartitionConfig && isMetricConfigType && (
        <PartitionsConfigItem
          value={configData.config.partitions || []}
          partitionSampleData={partitionSampleData}
          getPartitionSampleData={getPartitionSampleData}
          columnList={columnList}
          sources={configData.config.sources}
          table={configData.config.table}
          disabled={disabled}
          isEdit={isEditMode}
          onChange={editModeOnChangeCallback(isEditMode, (partitions) => {
            onConfigDataChange({
              ...configData,
              config: {
                ...configData.config,
                partitions,
              },
            });
          })}
          partitionTimezone={configData.config.partitionTimezone}
          onPartitionTimezoneChange={editModeOnChangeCallback(
            isEditMode,
            (partitionTimezone) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  partitionTimezone,
                },
              });
            }
          )}
          partitionOffsets={configData.config.partitionOffsets}
          onPartitionOffsetsChange={editModeOnChangeCallback(
            isEditMode,
            (partitionOffsets) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  partitionOffsets,
                },
              });
            }
          )}
        />
      )}

      {/* This section contais a divider, so we are just rendering it in case we need to */}
      {shouldShowBottomSection && (
        <div className="metric-config-configure-metric-non-heritable">
          {enableSlicing && (
            <div className="metric-config-configure-metric-seasonality">
              <SliceByColumnsConfigItem
                columnList={columnList}
                mode="multiple"
                disabled={disabled}
                value={sliceByColumnsGetter(configData)}
                onChange={onSliceByColumnsChange || defaultOnSliceByColumnChange}
              />

              {/* Slice value selection is disabled for now.
            {<Button
              outline
              paddingSize={ButtonPaddingSize.SMALL}
              size="large"
              disabled={
                !configData.config.sliceByColumns ||
                configData.config.sliceByColumns.length === 0
              }
              onClick={() => {
                updateSliceColumnValueList();
                openWorkspaceTakeover(
                  <MetricSliceOptionTakeOverView
                    sliceByColumns={configData.config?.sliceByColumns || []}
                    sliceValueSelections={
                      configData.config?.sliceValueSelections || []
                    }
                    onSave={(sliceValueSelections) => {
                      onConfigDataChange({
                        ...configData,
                        config: {
                          ...configData.config,
                          sliceValueSelections,
                        },
                      });
                    }}
                    closeTakeover={closeTakeover}
                  />,
                  TakeoverWidth.NARROW,
                  () => {
                    closeTakeover();
                  },
                  "metric-slice-option-takeover-view-container"
                );
              }}
            >
              <SettingOutlined />
            </Button>} */}
            </div>
          )}

          {enableSeasonality && (
            <div className="metric-config-configure-metric-seasonality">
              <SeasonalityConfigItem
                label="Seasonality"
                disabled={disabled}
                status={configData.status}
                value={configData.config.seasonality}
                seasonalityList={seasonalityList}
                onChange={(newSeasonality) => {
                  onConfigDataChange({
                    ...configData,
                    config: {
                      ...configData.config,
                      seasonality: newSeasonality,
                    },
                  });
                }}
              />
              <Button
                outline
                paddingSize={ButtonPaddingSize.SMALL}
                size="large"
                disabled={
                  disabled ||
                  !configData.config.seasonality ||
                  (configData.config.seasonality.seasonInSeconds === 0 &&
                    configData.config.seasonality.periodInSeconds === 0)
                }
                onClick={() => {
                  openWorkspaceTakeover(
                    <MetricCustomSeasonalityTakeoverView
                      value={configData.config.seasonality}
                      windowConfig={windowConfig}
                      onSave={(seasonality) => {
                        onConfigDataChange({
                          ...configData,
                          config: {
                            ...configData.config,
                            seasonality,
                          },
                        });
                      }}
                      closeTakeover={closeTakeover}
                    />,
                    TakeoverWidth.WIDE,
                    () => {
                      closeTakeover();
                    }
                  );
                }}
              >
                <SettingOutlined />
              </Button>
            </div>
          )}

          {enableBackfillDuration && isMetricConfigType && (
            <div className="metric-config-configure-metric-backfill">
              <BackfillDurationConfigItem
                // Recreate this input whenever the aggregation window changes, so that the underlying
                // duration input will reset its time unit to the highest granularity.
                key={configData.config?.aggregation?.aggregationWindow}
                disabled={disabled}
                value={
                  configData.config?.backfillDuration ??
                  getDefaultBackfillDuration(
                    configData.config?.aggregation?.aggregationWindow,
                    dataSourceInfo
                  )
                }
                onChange={(backfillDuration) => {
                  onConfigDataChange({
                    ...configData,
                    config: {
                      ...configData.config,
                      backfillDuration,
                    },
                  });
                }}
              />
            </div>
          )}

          {enableMissingValueFilling && (
            <FieldRow>
              <div>
                <MissingValueFillingConfigItem
                  value={configData.config.missingValueFilling}
                  onChange={(missingValueFilling) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        missingValueFilling,
                      },
                    });
                  }}
                  disabled={disabled || isConformityMetric}
                />
              </div>
              <div />
            </FieldRow>
          )}
        </div>
      )}
    </ConfigCard>
  );
}

export default ConfigureMetric;
