import React from "react";
import {
  DebouncedLabeledSelect,
  LabeledInput,
  LabeledInputNumber,
  LabeledMultiSelect,
  LabeledSelect,
} from "../../components/labeled-control/labeled-control";
import {
  decodedDefaultFieldValues,
  encodedFieldValue,
  FieldInputType,
  minimalFields,
  getFieldInputType,
  getNodeAtPath,
  isLeaf,
  longestBranchPath,
  replaceField,
  selectBranch,
  subPaths,
  isRequiredField,
  systemFieldIds,
  SubmitType,
  isBranch,
  NodeType,
  isMetadata,
  hiddenContextFieldIds,
} from "./integration-metadata";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../components/modal-dialog/modal-field-group";
import { difference, union } from "../../utils/set";
import { indexBy } from "../../utils/iterables";
import { CheckOneIcon } from "../../components/icons/success";
import NgButton from "../../components/button/ng-button";
import { deepcopy } from "../../utils/objects";
import { AlertingChannelType } from "../../utils/alerting";
import { createGetUserList } from "./integration-utils";

export function SubmitModeSelector(props) {
  const { value = SubmitType.MANUAL, onChange } = props;
  return (
    <LabeledSelect
      label="Mode"
      staticLabel
      value={value}
      options={[
        { label: "Manual", value: SubmitType.MANUAL },
        { label: "Automatic", value: SubmitType.AUTOMATIC },
      ]}
      onChange={onChange}
    />
  );
}

export const AuthenticationStatus = Object.freeze({
  NOT_ATTEMPTED: "notAttempted",
  IN_PROGRESS: "inProgress",
  SUCCESS: "success",
  FAILURE: "failure",
  AUTH_FIELDS_CHANGED: "authFieldsChanged",
});

export function getAuthenticationStatus(args) {
  const { currentAuthFields, lastAuthAttemptData, currentMetadata } = args;
  const authFieldsChanged =
    JSON.stringify(currentAuthFields) !== JSON.stringify(lastAuthAttemptData);
  if (lastAuthAttemptData === null) {
    return AuthenticationStatus.NOT_ATTEMPTED;
  } else if (currentMetadata.loading) {
    return AuthenticationStatus.IN_PROGRESS;
  } else if (isMetadata(currentMetadata.data) && !authFieldsChanged) {
    return AuthenticationStatus.SUCCESS;
  } else if (authFieldsChanged) {
    return AuthenticationStatus.AUTH_FIELDS_CHANGED;
  } else {
    return AuthenticationStatus.FAILURE;
  }
}

export function MetadataAuthenticationButton(props) {
  const { authenticationStatus, onlyEditMetadataAvailable, onClick, disabled } = props;
  const authButtonProps = {};
  let authButtonText = "Authenticate";
  if (authenticationStatus === AuthenticationStatus.IN_PROGRESS) {
    authButtonProps.disabled = true;
    authButtonText = "Authenticating";
  } else if (authenticationStatus === AuthenticationStatus.SUCCESS) {
    authButtonProps.success = true;
    authButtonText = (
      <div className="jira-integration-basic-dialog-authenticate-button">
        Successful Authentication
        <CheckOneIcon />
      </div>
    );
  } else if (authenticationStatus === AuthenticationStatus.AUTH_FIELDS_CHANGED) {
    authButtonText = "Re-authenticate";
  } else if (
    onlyEditMetadataAvailable &&
    authenticationStatus === AuthenticationStatus.NOT_ATTEMPTED
  ) {
    authButtonText = "Re-authenticate";
  }

  return (
    <NgButton
      primary
      block
      disabled={disabled}
      {...authButtonProps}
      size="large"
      onClick={onClick}
    >
      {authButtonText}
    </NgButton>
  );
}

function contextFieldOptions(node) {
  return node.fieldValues.map(({ value }) => ({
    label: value[0].value,
    value: value[0].valueId,
  }));
}

// Update the context path at a specified index, and remove any path segments
// that follow it, since they are likely invalid.
function updateContextPath(contextPath, newPathElementValue, newPathElementIndex) {
  const newContextPath = contextPath.slice(0, newPathElementIndex + 1);
  newContextPath[newPathElementIndex] = newPathElementValue;
  return newContextPath;
}

// Presents a list of select's that allows the user to choose the context
// path leading up to a leaf node.
export function IntegrationMetadataPathSelector(props) {
  const {
    type,
    metadata,
    editMetadata,
    onlyEditMetadataAvailable = false,
    onChange,
  } = props;

  if (!editMetadata) {
    return null;
  }

  // This context path that the user has already selected.
  const contextPath = longestBranchPath(editMetadata);

  return [...subPaths(contextPath)].map((subPath, subPathIndex) => {
    const node = getNodeAtPath(metadata, subPath);
    if (node && !isLeaf(node) && !hiddenContextFieldIds[type].has(node.fieldId)) {
      const options = contextFieldOptions(node);
      const value = contextPath?.[subPathIndex];
      const valueExistsInOptions = options.some((option) => option.value === value);

      return (
        <LabeledSelect
          key={subPath.join("-")}
          value={valueExistsInOptions ? value : null}
          label={node.fieldName}
          staticLabel
          options={options}
          enableSorting
          disabled={onlyEditMetadataAvailable}
          onChange={(contextFieldValueId) => {
            const newContextPath = updateContextPath(
              contextPath,
              contextFieldValueId,
              subPathIndex
            );
            const newMetadata = selectBranch(
              minimalFields(type, metadata),
              newContextPath
            );
            onChange(newMetadata);
          }}
        />
      );
    } else {
      return null;
    }
  });
}

function fieldOptions(type, node) {
  return node.fields
    .filter(({ fieldId }) => !systemFieldIds[type].has(fieldId))
    .map(({ fieldId, fieldName }) => ({
      label: fieldName,
      value: fieldId,
    }));
}

export function MetadataFieldInput(props) {
  // There are two different change callbacks: onChange, and onFieldChange.
  // onChange gets called with the raw input value, while onFieldChange gets
  // called with the updated field value. We want to keep onChange emitting
  // the raw value so that we can use this component with Antd Form's.
  const {
    field,
    disabled,
    onChange,
    onFieldChange,
    editDefault = true,
    getUserList = null,
  } = props;
  const { fieldName, allowedValues } = field;

  const inputType = getFieldInputType(field);
  const valueEncoder = (v) => encodedFieldValue(field, v);
  const options = allowedValues.map(({ value, valueId }) => ({
    label: value,
    value: valueId,
  }));

  const commonFieldProps = {
    label: fieldName,
    staticLabel: true,
    defaultValue: decodedDefaultFieldValues(field),
    disabled,
  };
  const updateKey = editDefault ? "defaultValues" : "value";

  if (inputType === FieldInputType.SINGLE_SELECT) {
    return (
      <LabeledSelect
        {...commonFieldProps}
        options={options}
        enableSorting
        onChange={(newDefaultValue) => {
          onFieldChange({
            ...field,
            [updateKey]: [valueEncoder(newDefaultValue)],
          });
          onChange && onChange(newDefaultValue);
        }}
      />
    );
  } else if (inputType === FieldInputType.NUMBER) {
    return (
      <LabeledInputNumber
        {...commonFieldProps}
        onChange={(newDefaultValue) => {
          onFieldChange({
            ...field,
            [updateKey]: [valueEncoder(newDefaultValue)],
          });
          onChange && onChange(newDefaultValue);
        }}
      />
    );
  } else if (inputType === FieldInputType.MULTI_SELECT) {
    return (
      <LabeledMultiSelect
        {...commonFieldProps}
        options={options}
        enableSorting
        onChange={(newDefaultValues) => {
          onFieldChange({
            ...field,
            [updateKey]: newDefaultValues.map(valueEncoder),
          });
          onChange && onChange(newDefaultValues);
        }}
      />
    );
  } else if (inputType === FieldInputType.USER_SINGLE_SELECT) {
    return (
      <DebouncedLabeledSelect
        {...commonFieldProps}
        enableSorting
        labelInValue
        onChange={(newDefaultValue) => {
          onFieldChange({
            ...field,
            [updateKey]: newDefaultValue ? [valueEncoder(newDefaultValue)] : [],
          });
          onChange && onChange(newDefaultValue);
        }}
        optionsFetcher={getUserList}
      />
    );
  } else if (inputType === FieldInputType.USER_MULTI_SELECT) {
    return (
      <DebouncedLabeledSelect
        {...commonFieldProps}
        enableSorting
        multiple
        labelInValue
        onChange={(newDefaultValues) => {
          onFieldChange({
            ...field,
            [updateKey]: newDefaultValues.map(valueEncoder),
          });
          onChange && onChange(newDefaultValues);
        }}
        optionsFetcher={getUserList}
      />
    );
  } else {
    return (
      <LabeledInput
        {...commonFieldProps}
        onChange={(e) => {
          const newDefaultValue = e.target.value;
          onFieldChange({
            ...field,
            [updateKey]: [valueEncoder(newDefaultValue)],
          });
          onChange && onChange(e);
        }}
      />
    );
  }
}

// mergeFields makes sure that all and only fields from selectedFieldIds are present in
// editLeaf, borrowing where necessary from metadataLeaf. It also ensures that
// any required fields from metadataLeaf are present in editLeaf. This mutates editLeaf.
function mergeFields(metadataLeaf, editLeaf, selectedFieldIds = new Set()) {
  const fieldIdFn = ({ fieldId }) => fieldId;
  const fieldsById = indexBy(metadataLeaf.fields, fieldIdFn);
  const requiredFieldIds = new Set(
    metadataLeaf.fields.filter(isRequiredField).map(fieldIdFn)
  );
  const selectedAndRequiredFieldIds = union(selectedFieldIds, requiredFieldIds);
  // Remove any unselected, optional fields from the edit metadata.
  editLeaf.fields = editLeaf.fields.filter(({ fieldId }) =>
    selectedAndRequiredFieldIds.has(fieldId)
  );
  const editLeafIds = new Set(editLeaf.fields.map(fieldIdFn));
  // Add any nodes that were not already in editLeaf.
  for (let missingFieldId of difference(selectedAndRequiredFieldIds, editLeafIds)) {
    editLeaf.fields.push(fieldsById[missingFieldId]);
  }
  return editLeaf;
}

export function IntegrationMetadataFieldsEditor(props) {
  const {
    type,
    metadata,
    editMetadata,
    onlyEditMetadataAvailable = false,
    mode = SubmitType.MANUAL,
    onChange,
    getUserList = null,
  } = props;

  if (!editMetadata) {
    return null;
  }

  const contextPath = longestBranchPath(editMetadata);
  const metadataNode = getNodeAtPath(metadata, contextPath);
  const editMetadataNode = getNodeAtPath(editMetadata, contextPath);

  if (metadataNode && isLeaf(metadataNode)) {
    return (
      <>
        <ModalFieldGroup
          label={
            contextPath.length === 0 && onlyEditMetadataAvailable
              ? "Re-authenticate to change your configuration"
              : "Select the field(s) to include"
          }
        >
          <LabeledMultiSelect
            label="Available Fields"
            staticLabel
            value={editMetadataNode.fields.map(({ fieldId }) => fieldId)}
            options={fieldOptions(type, metadataNode)}
            enableSorting
            disabled={onlyEditMetadataAvailable}
            onChange={(newSelectedFieldIds) => {
              const merged = deepcopy(editMetadata);
              mergeFields(
                metadataNode,
                getNodeAtPath(merged, contextPath),
                new Set(newSelectedFieldIds)
              );
              onChange(merged);
            }}
            mode="multiple"
          />
        </ModalFieldGroup>
        {editMetadataNode.fields.length > 0 && (
          <ModalFieldGroup label={mode === SubmitType.MANUAL ? "Defaults" : "Values"}>
            <ModalFieldRows>
              {editMetadataNode.fields.map((field) => (
                <MetadataFieldInput
                  key={field.fieldId}
                  field={field}
                  disabled={onlyEditMetadataAvailable}
                  getUserList={getUserList}
                  onFieldChange={(newField) => {
                    const merged = deepcopy(editMetadata);
                    replaceField(getNodeAtPath(merged, contextPath), newField);
                    onChange(merged);
                  }}
                />
              ))}
            </ModalFieldRows>
          </ModalFieldGroup>
        )}
      </>
    );
  }

  return null;
}

export function getMetadataEditStatus(remoteMetadata, editMetadata) {
  // Choose which metadata source we will use to populate the field
  // options. If we don't have remote metadata available, we'll use
  // the edit metadata (this would occur when the user is editing,
  // but hasn't re-authenticated to fetch the latest remote metadata)
  const configureOptionsMetadata = remoteMetadata?.type ? remoteMetadata : editMetadata;

  const remoteMetadataAvailable = Boolean(remoteMetadata?.type);
  const editMetadataAvailable = Boolean(editMetadata?.type);

  // Determine whether we have remote metadata available. If it's not
  // available (i.e. only the local edit metadata is at hand), we will
  // not allow the user to re-configure fields.
  const onlyEditMetadataAvailable = editMetadataAvailable && !remoteMetadataAvailable;

  // Do we have any metadata at all to work with?
  const someMetadataAvailable = editMetadataAvailable || remoteMetadataAvailable;

  // Has the user selected enough context fields to get to a node where
  // we can start picking leaf fields?
  const contextFieldsConfigured = editMetadataAvailable && isBranch(editMetadata);

  return {
    configureOptionsMetadata,
    onlyEditMetadataAvailable,
    someMetadataAvailable,
    contextFieldsConfigured,
  };
}

export function IntegrationMetadataEditor(props) {
  const { type, value, currentMetadata, onChange, workspaceUuid = null } = props;

  const {
    configureOptionsMetadata,
    onlyEditMetadataAvailable,
    someMetadataAvailable,
    contextFieldsConfigured,
  } = getMetadataEditStatus(currentMetadata.data, value.metadata);

  const getUserList =
    type === AlertingChannelType.JIRA && workspaceUuid
      ? createGetUserList(workspaceUuid, value, type)
      : null;

  return someMetadataAvailable ? (
    <>
      {configureOptionsMetadata.type === NodeType.NODE && (
        <ModalFieldGroup
          label={
            onlyEditMetadataAvailable && "Re-authenticate to change your configuration"
          }
        >
          <ModalFieldRows>
            <IntegrationMetadataPathSelector
              type={type}
              metadata={configureOptionsMetadata}
              editMetadata={value.metadata}
              onlyEditMetadataAvailable={onlyEditMetadataAvailable}
              onChange={(newMetadata) => {
                onChange({ ...value, metadata: newMetadata });
              }}
            />
          </ModalFieldRows>
        </ModalFieldGroup>
      )}
      {contextFieldsConfigured && (
        <IntegrationMetadataFieldsEditor
          key={currentMetadata.lastRetrieved}
          type={type}
          metadata={configureOptionsMetadata}
          editMetadata={value.metadata}
          onlyEditMetadataAvailable={onlyEditMetadataAvailable}
          mode={value.mode}
          getUserList={getUserList}
          onChange={(newMetadata) => {
            onChange({
              ...value,
              metadata: newMetadata,
            });
          }}
        />
      )}
    </>
  ) : null;
}

export function getIntegrationFormSaveStatus(args) {
  const {
    isEdit = false,
    lastAuthAttemptData,
    lastSuccessfulAuthAttemptData,
    currentAuthFields,
    canAttemptAuth,
    fieldsConfigured,
    isLoading = false,
  } = args;

  let enableSave = false;
  let authButtonStatus = AuthenticationStatus.NOT_ATTEMPTED;

  if (isLoading) {
    return { enableSave: false, authButtonStatus: AuthenticationStatus.IN_PROGRESS };
  }

  const lastAttemptSucceeded =
    lastAuthAttemptData &&
    JSON.stringify(lastAuthAttemptData) ===
      JSON.stringify(lastSuccessfulAuthAttemptData);
  const currentAuthFieldsWereSuccessful =
    lastSuccessfulAuthAttemptData &&
    JSON.stringify(currentAuthFields) === JSON.stringify(lastSuccessfulAuthAttemptData);
  const authFieldsChanged =
    JSON.stringify(currentAuthFields) !== JSON.stringify(lastAuthAttemptData);

  if (lastAttemptSucceeded && !authFieldsChanged) {
    authButtonStatus = AuthenticationStatus.SUCCESS;
  }

  if (isEdit) {
    enableSave = currentAuthFieldsWereSuccessful && fieldsConfigured;
  } else {
    enableSave =
      (!lastSuccessfulAuthAttemptData && canAttemptAuth) ||
      (lastAttemptSucceeded && fieldsConfigured && !authFieldsChanged);
  }

  return { enableSave, authButtonStatus };
}
