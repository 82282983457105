import { classesName } from "../../utils/css";

function NotificationDetailInfoCell(props) {
  const { label, children, isBold = false } = props;
  return (
    <div className="notification-view-detailed-info-cell-item-container" key={label}>
      <div className="notification-view-detailed-info-cell-label-item-container">
        {label}
      </div>
      <div
        className={classesName(
          "notification-view-detailed-info-cell-value-item-container",
          isBold && "bold"
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default NotificationDetailInfoCell;
