import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import ChartEnableView from "./chart-enable-view";
import PercentileChartView from "../chart/percentile-chart-view";
import DataDistributionChartView from "../chart/data-distribution-chart-view/ng-index";
import DataDistributionFullMetricsDialog from "../chart/data-distribution-chart-view/data-distribution-full-metrics-dialog";
import DataCategoryChartView from "../chart/data-category-chart-view/";
import DataLineChartView from "../chart/data-line-chart-view/";
import NgIncidentDetailLineView from "../chart/ng-incident-detail-line-view";
import EventChartView from "../chart/event-chart-view/";
import FullCompareChartView from "../chart/full-compare-chart-view";
import ProfilerMonitorPopupMenu from "../chart-monitor-popup-menu/ng-index";
import ChartControllerOptionMenu from "../incident-chart-view/chart-controller-option-menu";
import IncidentStatusOptionMenu from "../incident-status-option-menu/";
import IncidentValidationStatusView from "../../views/incident/incident-validation-status-view";
import {
  AggregationWindowType,
  AutoMetricsType,
  IncidentCreatorType,
  IncidentValidationStatus,
  MonitorStatus,
  TriggerRunStatus,
  autoMetricTypeToMetricCategoryMap,
} from "../../utils/enums";
import { getStringFromTimeStamp } from "../../utils/time";
import NgCard from "../ng-card/ng-card";
import { WarningOutlined } from "@ant-design/icons";
import Button from "../button/ng-button";
import ProfilerMetricActionsMenu from "../../views/profiler/profiler-metric-actions-menu";
import MetricStatusBadge from "../status-badge/metric-status-badge";
import ChartSpinner from "./chart-spinner";
import { profilerMetricActions } from "../../views/profiler/utils";
import { metricCategoryIconComponent } from "../metric/fields/icons";
import MetricConfigSummaryPopover from "./metric-config-summary-popover";
import { getMetricTypeFromConfigData } from "../metric/utils";
import {
  getMetricStatus,
  isMetricBackfilling,
  isMetricWaitingForFirstCollection,
  isMetricWaitingForFirstTrigger,
} from "../../utils/metric";
import { getKPIDisplayStr } from "../../utils/incident";
import WaitingForTrigger from "./waiting-for-trigger";
import NgTooltip from "../tooltip/ng-tooltip";
import { Spinner } from "../../atom/spinner";
import Switch from "../../atom/switch";

import "./ng-index.scss";

function NgDataLineChartView(props) {
  return (
    <DataLineChartView DetailLineViewComponent={NgIncidentDetailLineView} {...props} />
  );
}

const autoMetricTypeToChartMapper = {
  [AutoMetricsType.NUMERICAL_DISTRIBUTION]: PercentileChartView,
  [AutoMetricsType.CATEGORICAL_DISTRIBUTION]: DataDistributionChartView,
  [AutoMetricsType.CATEGORICAL_CARDINALITY]: DataCategoryChartView, // remove
  [AutoMetricsType.MISSING_VALUE]: NgDataLineChartView,
  [AutoMetricsType.CONFORMITY_COUNT]: NgDataLineChartView,
  [AutoMetricsType.DATA_DELAY]: NgDataLineChartView,
  [AutoMetricsType.VOLUME]: NgDataLineChartView,
  [AutoMetricsType.TABLE_ACTIVITY]: EventChartView,
  [AutoMetricsType.COLUMN_ACTIVITY]: EventChartView,
  [AutoMetricsType.CATEGORY_ACTIVITY]: EventChartView,
  [AutoMetricsType.FULL_COMPARE]: FullCompareChartView,
  [AutoMetricsType.AGGREGATION_COMPARE]: FullCompareChartView,
  [AutoMetricsType.BYTE_COUNT]: NgDataLineChartView,
  [AutoMetricsType.ROW_COUNT]: NgDataLineChartView,
  [AutoMetricsType.UPDATE_DELAY]: NgDataLineChartView,
  [AutoMetricsType.NONE]: NgDataLineChartView,
};

const autoMetricsTypeToSubTitleMapper = {
  [AutoMetricsType.NUMERICAL_DISTRIBUTION]: "Set of percentiles",
  [AutoMetricsType.CATEGORICAL_DISTRIBUTION]: "Distribution of category values",
  [AutoMetricsType.MISSING_VALUE]: "Percent of null values",
  [AutoMetricsType.CONFORMITY_COUNT]:
    "Data conformity reports the percentage of values that conform to the specified constraint",
  [AutoMetricsType.DATA_DELAY]: "Time since latest data timestamp",
  [AutoMetricsType.VOLUME]: "Row count",
  [AutoMetricsType.TABLE_ACTIVITY]: "Tables added or removed from schema",
  [AutoMetricsType.COLUMN_ACTIVITY]: "Columns added or removed from table",
  [AutoMetricsType.CATEGORY_ACTIVITY]: "Category values added or removed from column",
  [AutoMetricsType.FULL_COMPARE]: "Row by row comparison",
  [AutoMetricsType.BYTE_COUNT]: "Byte count",
  [AutoMetricsType.ROW_COUNT]: "Row count",
  [AutoMetricsType.UPDATE_DELAY]: "Update delay",
  [AutoMetricsType.NONE]: "",
};

const autoMetricsTypeToEnableLabelMapper = {
  [AutoMetricsType.NUMERICAL_DISTRIBUTION]: "Enable distribution",
  [AutoMetricsType.CATEGORICAL_DISTRIBUTION]: "Enable distribution",
  [AutoMetricsType.MISSING_VALUE]: "Enable null percent",
  [AutoMetricsType.DATA_DELAY]: "Enable data delay",
  [AutoMetricsType.VOLUME]: "Enable data volume",
  [AutoMetricsType.TABLE_ACTIVITY]: "Enable table activity",
  [AutoMetricsType.COLUMN_ACTIVITY]: "Enable column activity",
  [AutoMetricsType.CATEGORY_ACTIVITY]: "Enable category activity",
  [AutoMetricsType.BYTE_COUNT]: "Enable byte count",
  [AutoMetricsType.ROW_COUNT]: "Enable row count",
  [AutoMetricsType.UPDATE_DELAY]: "Enable update delay",
  [AutoMetricsType.NONE]: "",
};

const enabledChartMetricTypeOptions = [
  AutoMetricsType.MISSING_VALUE,
  AutoMetricsType.DATA_DELAY,
  AutoMetricsType.VOLUME,
  AutoMetricsType.CONFORMITY_COUNT,
  AutoMetricsType.NUMERICAL_DISTRIBUTION,
  AutoMetricsType.CATEGORICAL_DISTRIBUTION,
  AutoMetricsType.CATEGORICAL_CARDINALITY, // remove
  AutoMetricsType.FULL_COMPARE,
  AutoMetricsType.AGGREGATION_COMPARE,
  AutoMetricsType.BYTE_COUNT,
  AutoMetricsType.ROW_COUNT,
  AutoMetricsType.UPDATE_DELAY,
  AutoMetricsType.NONE,
];

const comparisonLabelOptions = {
  [AggregationWindowType.HOUR]: "Last Hour",
  [AggregationWindowType.DAY]: "Last Day",
  [AggregationWindowType.WEEK]: "Last Week",
};

function getDefaultSubTitle(metricType, _metricAggregationInterval) {
  return autoMetricsTypeToSubTitleMapper[metricType] || "";
}

export function IncidentsButton(props) {
  const { incidents = [], onClick, loading } = props;

  const numIncidents = incidents.length;
  let buttonText;
  if (loading) {
    buttonText = "Loading...";
  } else if (numIncidents === 1) {
    buttonText = "1 Incident";
  } else if (numIncidents > 1) {
    buttonText = `${numIncidents} Incidents`;
  } else {
    buttonText = "0 Incidents";
  }

  const hasUnvalidatedIncidents = incidents.some(
    (incident) => incident.validationStatus !== IncidentValidationStatus.RESOLVED
  );

  return (
    <NgTooltip title={!loading && "Expand Incidents"}>
      <Button
        outline
        critical={hasUnvalidatedIncidents}
        disabled={loading || !onClick}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {buttonText}
        <WarningOutlined />
      </Button>
    </NgTooltip>
  );
}

function ChartView(props) {
  const {
    data = null,
    loading = false,
    incidents = [],
    dataSource = null,
    metric = null,
    metricType = "",
    metricUuid = "",
    creatorInfo = "",
    metricAggregationInterval = "",
    config = {},
    enableConfig = null,
    monitorConfig = null,
    incidentConfig = null,
    incidentStatusConfig = null,
    incidentViewInExplorerConfig = null,
    incidentValidationStatusConfig = null,
    viewQueryHistoryConfig = null,
    removeChartConfig = null,
    showTitle = true,
    title = "Drift Timeline",
    titleDescription = "",
    showSubTitle = false,
    subTitle = "",
    defaultShowSignalData = true,
    defaultShowStatsData = false,
    enableComparisonOption = false,
    className = "",
    workspaceUserPermissions,
    match,
    history,
    noCardContainer = false,
    showMetricStatusBadge = false,
    onToggleMetricLiveStatusClick,
    onDeleteMetricClick,
    onDisableMetricClick,
    onTriggerMetricClick,
    onQueryHistoryClick,
    onCurrentPointChange = null,
  } = props;

  let { enableChartOption = true } = props;

  const [chartOption, setChartOption] = useState({
    showStatsData: defaultShowStatsData,
    showSignalData: defaultShowSignalData,
  });

  useEffect(() => {
    setChartOption({
      showStatsData: defaultShowStatsData,
      showSignalData: defaultShowSignalData,
    });
  }, [defaultShowSignalData, defaultShowStatsData]);

  const [showComparisonData, setShowComparisonData] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);
  const currentMonitorUuid = monitorConfig?.monitorUuid || "";

  useEffect(() => {
    onCurrentPointChange && onCurrentPointChange(currentPoint);
  }, [currentPoint, onCurrentPointChange]);

  const normalizedIncidents = useMemo(() => {
    if (monitorConfig && currentMonitorUuid) {
      return incidents.filter(({ filterUuid }) => filterUuid === currentMonitorUuid);
    } else {
      return incidents;
    }
  }, [monitorConfig, currentMonitorUuid, incidents]);

  if (enableConfig?.enableConfigMode && !enableConfig?.enabled) {
    const Icon = metricCategoryIconComponent(
      autoMetricTypeToMetricCategoryMap[metricType]
    );
    return (
      <ChartEnableView
        label={autoMetricsTypeToEnableLabelMapper[metricType] || ""}
        value={enableConfig?.enabled}
        onChange={enableConfig?.onClick}
        icon={<Icon width={48} height={48} />}
        disabled={enableConfig?.enableButtonDisabled}
      />
    );
  }

  if (data && data.autoMetricType && data.autoMetricType !== metricType) {
    console.log("Waiting for loading data.");
    return null;
  }

  let DataChartComponent = autoMetricTypeToChartMapper[metricType] || null;
  if (!DataChartComponent) {
    console.log(`Unknown metric type ${metricType} and use default line chart`);
    DataChartComponent = DataLineChartView;
  }

  enableChartOption =
    enableChartOption && enabledChartMetricTypeOptions.includes(metricType);
  if (
    enableChartOption &&
    monitorConfig &&
    monitorConfig.monitorStatus !== MonitorStatus.MONITORED
  ) {
    enableChartOption = false;
  }

  function onChartOptionChange(newOption) {
    setChartOption(newOption);
    if (props.onChartOptionChange) {
      props.onChartOptionChange(newOption);
    }
  }

  let comparisonLabel = "";
  if (
    enableComparisonOption &&
    currentPoint &&
    metricType === AutoMetricsType.CATEGORICAL_DISTRIBUTION
  ) {
    comparisonLabel = comparisonLabelOptions[metricAggregationInterval] || "Compare";
    config.comparisonLabel = comparisonLabel;
  }

  if (config.marginBottom === undefined) {
    config.marginBottom = 20;
  }

  let normalizedSubTitle = "";
  if (showSubTitle) {
    normalizedSubTitle =
      subTitle || getDefaultSubTitle(metricType, metricAggregationInterval) || "";
  }

  const metricStatus = getMetricStatus(metric);
  const statusBadge = showMetricStatusBadge && metric && metricStatus && (
    <MetricStatusBadge metric={metric} />
  );
  const showCurrentPointInfo =
    [
      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
      AutoMetricsType.FULL_COMPARE,
      AutoMetricsType.AGGREGATION_COMPARE,
    ].includes(metricType) && currentPoint;
  const currentPointTimestamp = showCurrentPointInfo && (
    <div className="chart-view-current-point-timestamp">
      {getStringFromTimeStamp(currentPoint.timestamp)}
    </div>
  );
  const categoryDialogButton = metricType ===
    AutoMetricsType.CATEGORICAL_DISTRIBUTION && (
    <DataDistributionFullMetricsDialog data={currentPoint} incidents={incidents} />
  );
  const triggerPendingSpinner = metric?.status?.triggered?.triggerRunStatus ===
    TriggerRunStatus.PENDING && (
    <div className="chart-view-trigger-pending">
      <Spinner size="small" />
      Trigger Pending
    </div>
  );

  const titleExtraContent = () => (
    <>
      {currentPointTimestamp}
      {categoryDialogButton}
      {triggerPendingSpinner}
    </>
  );

  const cornerControls = () => {
    return (
      showTitle && (
        <div className="ng-chart-view-header-container">
          {comparisonLabel && (
            <div className="chart-view-header-comparison-toggle">
              <Switch
                checkedChildren={comparisonLabel}
                unCheckedChildren={comparisonLabel}
                onChange={(enabled) => setShowComparisonData(enabled)}
                disabled={loading}
              />
            </div>
          )}

          {incidentConfig && (
            <IncidentsButton
              incidents={incidents}
              onClick={incidentConfig.onGoToIncidentList}
              loading={loading}
            />
          )}

          {monitorConfig && (
            <ProfilerMonitorPopupMenu
              singularMonitor={monitorConfig.singularMonitor}
              onStartMonitor={monitorConfig.onStartMonitor}
              onStopMonitor={monitorConfig.onStopMonitor}
              onPauseMonitor={monitorConfig.onPauseMonitor}
              onResumeMonitor={monitorConfig.onResumeMonitor}
              onCloneMonitor={monitorConfig.onCloneMonitor}
              relatedRules={monitorConfig.relatedRules || []}
              enableAddMonitors={monitorConfig.enableAddMonitors}
              enableEditMonitors={monitorConfig.enableEditMonitors}
              enableEditNotifications={monitorConfig.enableEditNotifications}
              enableViewMonitors={monitorConfig.enableViewMonitors}
              enableSwitchMonitor={monitorConfig.enableSwitchMonitor}
              incidents={incidents}
              onGoToRule={monitorConfig.onGoToRule}
              monitorStatus={monitorConfig.monitorStatus}
              onSwitchMonitor={(newMonitorUuid) => {
                if (monitorConfig.enableChartOption) {
                  const newDefaultChartOption = {
                    showStatsData: newMonitorUuid ? true : false,
                    showSignalData: true,
                  };
                  setChartOption(newDefaultChartOption);
                }
                monitorConfig?.onSwitchMonitor(newMonitorUuid);
              }}
              onGoToMetric={monitorConfig.onGoToMetric}
              currentAlertingChannels={monitorConfig.currentAlertingChannels}
              alertingChannelList={monitorConfig.alertingChannelList}
              onAlertChannelChange={monitorConfig.onAlertChannelChange}
              thresholdConfig={
                monitorConfig.enableChartOption
                  ? {
                      chartOption,
                      onChartOptionChange: (monitorUuid, newChartOption) => {
                        setChartOption(newChartOption);
                      },
                    }
                  : null
              }
              metric={metric}
              metricType={metricType}
              metricUuid={metricUuid}
              monitorUuid={currentMonitorUuid}
              workspaceUserPermissions={workspaceUserPermissions}
              loading={loading}
            />
          )}

          {monitorConfig?.enableMetric && (
            <ProfilerMetricActionsMenu
              tooltip="Metric Menu"
              actions={profilerMetricActions(
                metric,
                dataSource,
                monitorConfig.metricActionsOpts || {}
              )}
              workspaceUuid={match.params.workspaceUuid}
              workspaceUserPermissions={workspaceUserPermissions}
              metrics={[metric]}
              history={history}
              onToggleMetricLiveStatusClick={(workspaceUuid, metrics, newIsLive) =>
                onToggleMetricLiveStatusClick(workspaceUuid, metrics[0], newIsLive)
              }
              onTriggerMetricClick={(workspaceUuid, metrics) =>
                onTriggerMetricClick(workspaceUuid, metrics[0])
              }
              onDisableMetricClick={(workspaceUuid, metric) =>
                onDisableMetricClick(workspaceUuid, metric)
              }
              onDeleteMetricClick={(metrics) => onDeleteMetricClick(metrics[0])}
              onQueryHistoryClick={(metric) => onQueryHistoryClick(metric)}
              loading={loading}
            />
          )}

          {incidentStatusConfig && (
            <IncidentStatusOptionMenu {...incidentStatusConfig} />
          )}

          {incidentValidationStatusConfig && (
            <IncidentValidationStatusView
              {...incidentValidationStatusConfig}
              metricType={metricType}
              metric={metric}
            />
          )}

          {!loading && (
            <div className="chart-view-option-controller-container">
              <ChartControllerOptionMenu
                enableChartOption={enableChartOption}
                incidentViewInExplorerConfig={incidentViewInExplorerConfig}
                removeChartConfig={removeChartConfig}
                viewQueryHistoryConfig={viewQueryHistoryConfig}
                showStatsData={chartOption.showStatsData}
                showSignalData={chartOption.showSignalData}
                onChange={onChartOptionChange}
              />
            </div>
          )}
        </div>
      )
    );
  };

  const backfilling = !config.isPreview && isMetricBackfilling(metric);
  const waitingForTrigger = !config.isPreview && isMetricWaitingForFirstTrigger(metric);
  const waitingForCollection =
    !config.isPreview && isMetricWaitingForFirstCollection(metric);

  let statusContentComponent = null;
  if (loading) {
    statusContentComponent = <ChartSpinner title="Data load in progress" />;
  } else if (waitingForTrigger) {
    statusContentComponent = (
      <WaitingForTrigger
        onTriggerClick={
          onTriggerMetricClick
            ? () => onTriggerMetricClick(match.params.workspaceUuid, metric)
            : null
        }
      />
    );
  } else if (waitingForCollection) {
    statusContentComponent = <ChartSpinner title="In progress" />;
  } else if (backfilling) {
    statusContentComponent = <ChartSpinner title="Backfilling" />;
  }

  const chartDataContent = (
    <div className="chart-view-data-container">
      {statusContentComponent ?? (
        <DataChartComponent
          data={data}
          incidents={normalizedIncidents}
          isMonitored={
            monitorConfig &&
            monitorConfig.relatedRules &&
            monitorConfig.relatedRules.length > 0
          }
          dataSource={dataSource}
          metric={metric}
          metricType={metricType}
          config={config}
          showComparisonData={showComparisonData}
          chartOption={chartOption}
          onCurrentPointChange={setCurrentPoint}
        />
      )}
    </div>
  );

  let titleIcon = null;
  if (metric) {
    const TitleIcon = metricCategoryIconComponent(getMetricTypeFromConfigData(metric));
    titleIcon = (
      <MetricConfigSummaryPopover metric={metric}>
        <TitleIcon className="ng-chart-view-metric-type-icon" width={14} height={14} />
      </MetricConfigSummaryPopover>
    );
  }
  return noCardContainer ? (
    chartDataContent
  ) : (
    <NgCard
      title={showTitle && title}
      titleIcon={titleIcon}
      titleDescription={titleDescription}
      titleNote={statusBadge}
      subtitle={showSubTitle && normalizedSubTitle}
      titleExtraContent={titleExtraContent()}
      cornerControls={showTitle && cornerControls}
    >
      <div className={`ng-chart-view-container ${className}`}>
        {creatorInfo && (
          <div className="chart-view-related-path-container">
            {creatorInfo.type === IncidentCreatorType.FILTER
              ? getKPIDisplayStr(creatorInfo)
              : ""}
          </div>
        )}
        {chartDataContent}
      </div>
    </NgCard>
  );
}

export default withRouter(ChartView);
