import { connect } from "react-redux";

import NgTooltip from "../tooltip/ng-tooltip";

import "./column-masked-indicator.scss";

export function MaskedEyeFilledIcon(props = {}) {
  return (
    <svg fill="none" width="22" height="22" viewBox="0 0 22 22" {...props}>
      <rect width="22" height="22" rx="4" fill="#121224" />
      <path
        d="M11.0378 5.75C9.28408 5.75 7.68053 6.46176 6.47485 7.62219L7.08154 8.25281C8.13774 7.23624 9.51997 6.625 11.0378 6.625C12.5419 6.625 13.9118 7.22536 14.9642 8.22546L15.5667 7.59143C14.365 6.44953 12.7757 5.75 11.0378 5.75ZM16.1332 10.3566C15.0816 12.3295 13.186 13.625 11.0302 13.625C8.8787 13.625 6.98714 12.3358 5.93396 10.3694L5.16235 10.7821C5.50131 11.415 5.92434 11.9865 6.41077 12.4843L5.00684 13.8574L5.61865 14.4829L7.06104 13.0713C7.44195 13.3751 7.84973 13.6407 8.28381 13.854L7.38745 15.839L8.18469 16.1987L9.09473 14.1855C9.57385 14.3436 10.0746 14.4471 10.5918 14.4838V16.6875H11.4668V14.4829C11.979 14.4467 12.4744 14.3441 12.9493 14.189L13.7731 16.1859L14.5823 15.8518L13.7603 13.86C14.1867 13.652 14.5915 13.4003 14.9668 13.1046L16.3425 14.4795L16.9603 13.8608L15.6205 12.5201C16.1236 12.0109 16.5573 11.4204 16.9048 10.7684L16.1332 10.3566Z"
        fill="#DDE2EB"
      />
    </svg>
  );
}

function ColumnMaskedIndicator({ tooltip = null, text = "", waffle }) {
  const content = () => {
    return (
      <span
        className={`column-masked-indicator ${
          text ? "column-masked-indicator--with-text" : ""
        }`}
      >
        <MaskedEyeFilledIcon />
        {text}
      </span>
    );
  };

  return tooltip ? <NgTooltip title={tooltip}>{content()}</NgTooltip> : content();
}

const mapStateToProps = (state) => ({
  waffle: state.userReducer.currentUserInfo.waffle,
});

export default connect(mapStateToProps)(ColumnMaskedIndicator);
