import React from "react";

export function StopWatchIcon(props = {}) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M4.95831 0.583336C4.63615 0.583336 4.37498 0.844503 4.37498 1.16667C4.37498 1.48884 4.63615 1.75 4.95831 1.75H9.04165C9.36381 1.75 9.62498 1.48884 9.62498 1.16667C9.62498 0.844503 9.36381 0.583336 9.04165 0.583336H4.95831Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M7.58331 5.25C7.58331 4.92784 7.32215 4.66667 6.99998 4.66667C6.67781 4.66667 6.41665 4.92784 6.41665 5.25V7.875C6.41665 8.19717 6.67781 8.45834 6.99998 8.45834C7.32215 8.45834 7.58331 8.19717 7.58331 7.875V5.25Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99998 2.33334C3.93941 2.33334 1.45831 4.81444 1.45831 7.875C1.45831 10.9356 3.93941 13.4167 6.99998 13.4167C10.0605 13.4167 12.5416 10.9356 12.5416 7.875C12.5416 6.33199 11.911 4.93627 10.8934 3.93152L11.4958 3.32915C11.7236 3.10134 11.7236 2.732 11.4958 2.50419C11.268 2.27638 10.8986 2.27638 10.6708 2.50419L9.97576 3.19927C9.11611 2.65102 8.09514 2.33334 6.99998 2.33334ZM2.62498 7.875C2.62498 5.45877 4.58375 3.5 6.99998 3.5C9.41621 3.5 11.375 5.45877 11.375 7.875C11.375 10.2912 9.41621 12.25 6.99998 12.25C4.58375 12.25 2.62498 10.2912 2.62498 7.875Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
}
