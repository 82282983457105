import React, { useState } from "react";
import {
  getTableRows,
  incidentDirectionColumn,
  incidentDurationColumn,
  incidentIdColumn,
  incidentSeverityColumn,
  incidentSliceColumn,
  incidentStartTimeColumn,
  incidentStatusColumn,
} from "./columns";
import { withRouter } from "react-router-dom";
import { DynamicHeightTable } from "../../components/table/ng-table";

import "./monitor-incident-list.scss";

function getRowKey(row) {
  return row.incidentData.uuid;
}

function MonitorIncidentList(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    workspaceUserPermissions,
    loading,
    groupedIncidentList,
    onIncidentStatusChanged,
    filterUuid,
    previewUuid,
    disableIdLink = false,
  } = props;

  const [selectedRows, setSelectedRows] = useState([]);

  // This is a hack to force renders after status updates. I don't understand why
  // this is necessary - the Redux store appears to reflect the new statuses, but
  // the component is not rendering.
  const [, setLastStatusUpdate] = useState(null);

  function updateIncidentStatuses(incidentData, newStatus) {
    const selectedIncidents =
      selectedRows.length > 0
        ? selectedRows.map((row) => row.incidentData)
        : [incidentData];
    selectedIncidents.forEach((incident) => {
      onIncidentStatusChanged(incident.uuid, newStatus).then(() =>
        setLastStatusUpdate(Date.now())
      );
    });
  }

  const tableRows = getTableRows(groupedIncidentList);

  const columns = [
    incidentIdColumn({
      dataIndex: ["incidentData", "id"],
      renderProps: {
        workspaceUuid,
        workspaceUserPermissions,
        filterUuid,
        previewUuid,
        disableIdLink,
      },
    }),
    incidentSliceColumn({ dataIndex: ["sliceName"] }),
    incidentStartTimeColumn({ dataIndex: ["incidentData", "startTime"] }),
    incidentSeverityColumn({ dataIndex: ["incidentData", "score"] }),
    incidentDirectionColumn({ dataIndex: ["incidentData", "direction"] }),
    incidentDurationColumn({ dataIndex: ["incidentData", "duration"] }),
    incidentStatusColumn({
      dataIndex: ["incidentData", "status"],
      renderProps: {
        workspaceUserPermissions,
        onChange: updateIncidentStatuses,
      },
    }),
  ];

  const rowSelection = {
    onChange: (_newSelectedRowKeys, newSelectedRows) => {
      setSelectedRows(newSelectedRows);
    },
  };

  return (
    <div className="monitor-incident-list">
      <DynamicHeightTable
        columns={columns}
        dataSource={loading ? [] : tableRows}
        loading={loading}
        rowKey={getRowKey}
        rowSelection={rowSelection}
        tableLayout="fixed"
        pagination={{
          hideOnSinglePage: false,
          size: "default",
        }}
      />
    </div>
  );
}

export default withRouter(MonitorIncidentList);
