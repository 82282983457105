import { connect } from "react-redux";
import {
  openWorkspaceTakeover,
  closeTakeover,
} from "../../actions/takeover/takeover-action";
import Metric from "./metric";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick, className) =>
    dispatch(
      openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick, className)
    ),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Metric);
