import React from "react";
import { Popover } from "antd";
import Tag from "../../atom/tag";
import AddUserToWorkspacePopover from "./add-user-to-workspace-popover";
import "./admin-users-table-workspace-column.scss";

function FlattenWorkspaceListPopover(props) {
  const { workspaceList, onDeleteWorkspace } = props;
  const popOverContent = (
    <div className="flatten-workspace-list-popover-content-container">
      {workspaceList.map(({ name, uuid }) => {
        return (
          <Tag
            closable
            onClose={() => onDeleteWorkspace(uuid)}
            className="flatten-workspace-list-popover-workspace-item-container"
            key={uuid}
          >
            {name}
          </Tag>
        );
      })}
    </div>
  );

  return (
    <Popover content={popOverContent}>
      <div className="flatten-workspace-list-popover-trigger-container">
        +{workspaceList.length}
      </div>
    </Popover>
  );
}

function AdminUsersTableWorkspaceColumn(props) {
  const { onAddWorkspace, onDeleteWorkspace, appWorkspaceList, workspaceList } = props;
  const flattenWorkspaceList = workspaceList.slice(0, 2);
  const collapsedWorkspaceList = workspaceList.slice(2);
  const notAssignedWorkspaceList = {
    loading: appWorkspaceList.loading,
    data: appWorkspaceList.data.filter(
      (currentWorkspace) =>
        !workspaceList.find(
          (currentAssignedWorkspace) =>
            currentAssignedWorkspace.uuid === currentWorkspace.uuid
        )
    ),
  };

  return (
    <div className="admin-users-table-workspace-column-container">
      <AddUserToWorkspacePopover
        appWorkspaceList={notAssignedWorkspaceList}
        onAddWorkspace={onAddWorkspace}
      />
      {flattenWorkspaceList.map(({ uuid, name }) => (
        <Tag
          closable
          onClose={() => onDeleteWorkspace(uuid)}
          className="admin-users-table-workspace-column-workspace-container"
          key={uuid}
        >
          {name}
        </Tag>
      ))}
      {collapsedWorkspaceList.length > 0 && (
        <FlattenWorkspaceListPopover
          workspaceList={collapsedWorkspaceList}
          onDeleteWorkspace={onDeleteWorkspace}
        />
      )}
    </div>
  );
}

export default AdminUsersTableWorkspaceColumn;
