import React from "react";
import LegacyModalDialogAdapter from "../legacy-adapter/legacy-modal-dialog-adapter";
import Button from "../button/ng-button";

import "./user-support-dialog.scss";

function UserSupportDialog(props) {
  const { modalIsOpen, setIsOpen } = props;

  return (
    <LegacyModalDialogAdapter
      title={"Need product support?"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"user-support-dialog-container"}
      triggerComponent={null}
      width={530}
      contentComponent={
        <div className="user-support-dialog-body-container">
          If you need any assistance, please reach out to us at
          <Button link href="mailto:support@lightup.ai">
            support@lightup.ai
          </Button>
        </div>
      }
      footerComponent={null}
    />
  );
}

export default UserSupportDialog;
