import React, { Component } from "react";
import StepContainer from "../../../step/step";
import { LabelValueGroup } from "../../panels/template-panel/template-panel";
import { LabeledDatePicker } from "../../../labeled-control/labeled-control";
import RuleConfigDetectionSettingDisplayView from "../train/rule-config-detection-setting-display-view";
import RulePreviewConfigMenu from "./rule-preview-config-menu";
import { isJobRunning } from "../../../../utils/general";
import { getStringFromTimeStamp } from "../../../../utils/time";
import fromUnixTime from "date-fns/fromUnixTime";
import getUnixTime from "date-fns/getUnixTime";
import {
  isValueError,
  validateData,
  ValidationMethodNameConst,
} from "../../../../utils/validate";
import "./rule-preview-config-tab.scss";

const previewPeriodDataValidator = {
  previewPeriod: {
    validators: [
      {
        path: "startTimestamp",
        errorPath: "previewPeriod.startTimestamp.notEmpty",
        method: ValidationMethodNameConst.TIME_NOT_EMPTY,
      },
      {
        path: "endTimestamp",
        errorPath: "previewPeriod.endTimestamp.notEmpty",
        method: ValidationMethodNameConst.TIME_NOT_EMPTY,
      },
      {
        path: "startTimestamp",
        errorPath: "previewPeriod.startTimestamp.noLateThanNow",
        method: ValidationMethodNameConst.TIME_NO_LATE_THAN_NOW,
      },
      {
        path: ["startTimestamp", "endTimestamp"],
        errorPath: "previewPeriod.startTimestamp.endTimestamp",
        method: ValidationMethodNameConst.START_TIME_NO_LATE_THAN_END_TIME,
      },
      {
        path: "endTimestamp",
        errorPath: "previewPeriod.endTimestamp.noLateThanNow",
        method: ValidationMethodNameConst.TIME_NO_LATE_THAN_NOW,
      },
    ],
  },
};

class RulePreviewConfigTab extends Component {
  constructor(props) {
    super(props);

    this.onPreviewStartTimeChange = this.onPreviewStartTimeChange.bind(this);
    this.onPreviewEndTimeChange = this.onPreviewEndTimeChange.bind(this);
    this.onPreviewPeriodChange = this.onPreviewPeriodChange.bind(this);

    this.onPreviewDeleteClicked = this.onPreviewDeleteClicked.bind(this);

    this.state = {
      errors: {},
    };
  }

  onPreviewStartTimeChange(newStartTime) {
    const { previewPeriod } = this.props;
    const newStartTimestamp = getUnixTime(newStartTime);
    if (previewPeriod && previewPeriod.startTimestamp === newStartTimestamp) {
      return;
    }

    this.onPreviewPeriodChange({
      ...(previewPeriod ? previewPeriod : {}),
      startTimestamp: newStartTimestamp,
    });
  }

  onPreviewEndTimeChange(newEndTime) {
    const { previewPeriod } = this.props;
    const newEndTimestamp = getUnixTime(newEndTime);
    if (previewPeriod && previewPeriod.endTimestamp === newEndTimestamp) {
      return;
    }

    this.onPreviewPeriodChange({
      ...(previewPeriod ? previewPeriod : {}),
      endTimestamp: newEndTimestamp,
    });
  }

  onPreviewPeriodChange(newPreviewPeriod) {
    const newErrors = validateData(
      { previewPeriod: newPreviewPeriod },
      previewPeriodDataValidator
    );
    this.setState({ errors: newErrors }, () => {
      const isError = isValueError(newErrors);
      if (this.props.onPreviewPeriodChange) {
        this.props.onPreviewPeriodChange({ ...newPreviewPeriod, isError });
      }
    });
  }

  onPreviewDeleteClicked() {
    console.log("Deleting the current preview.");
    this.props.onPreviewDeleted();
  }

  getBasicConfigViewMode(currentPreview, currentPreviewSummaryData, defaultData) {
    const startTime = getStringFromTimeStamp(currentPreview.startTimestamp);
    const endTime = getStringFromTimeStamp(currentPreview.endTimestamp);
    const isPreviewDone = !isJobRunning(currentPreview.previewJobStatus);
    const displaySummaryData = [];
    const keyNames = ["total", "unread", "accepted", "rejected"];
    for (let keyName of keyNames) {
      if (
        currentPreviewSummaryData &&
        typeof (currentPreviewSummaryData[keyName] === "number")
      ) {
        displaySummaryData[keyName] = currentPreviewSummaryData[keyName];
      } else {
        displaySummaryData[keyName] = "N/A";
      }
    }

    const previewTimePeriodAttributeItems = [
      { label: "Start", value: startTime },
      { label: "End", value: endTime },
    ];

    const previewResultAttributeItems = [];
    if (isPreviewDone) {
      previewResultAttributeItems.push({
        label: "Incident detected",
        value: displaySummaryData.total,
      });
      previewResultAttributeItems.push({
        label: "Unread",
        value: displaySummaryData.unread,
      });
      previewResultAttributeItems.push({
        label: "Accepted",
        value: displaySummaryData.accepted,
      });
      previewResultAttributeItems.push({
        label: "Rejected",
        value: displaySummaryData.rejected,
      });
    }

    const {
      config: {
        driftDuration,
        recoveryDuration = null,
        symptom: { type, aggressiveness, direction },
      },
    } = defaultData;
    const { enableModifyFilter = true } = this.props;

    return (
      <>
        <div className="rule-preview-period-display-view-container">
          <LabelValueGroup attributeItems={previewTimePeriodAttributeItems} />
        </div>
        <div className="rule-preview-detection-setting-display-view-container">
          <RuleConfigDetectionSettingDisplayView
            symptomType={type}
            enableTooltip={false}
            driftDuration={driftDuration}
            recoveryDuration={recoveryDuration}
            aggressiveness={aggressiveness}
            direction={direction}
          />
        </div>
        {previewResultAttributeItems.length > 0 && (
          <div className="rule-preview-result-display-view-container">
            <LabelValueGroup attributeItems={previewResultAttributeItems} />
          </div>
        )}
        {enableModifyFilter ? (
          <RulePreviewConfigMenu
            onPreviewDeleteClicked={this.onPreviewDeleteClicked.bind(this)}
          />
        ) : null}
      </>
    );
  }

  onQuickSelectionClick(currentActionFunction) {
    const endTime = new Date();
    const startTime = currentActionFunction(endTime, 1);
    this.onPreviewPeriodChange({
      startTimestamp: getUnixTime(startTime),
      endTimestamp: getUnixTime(endTime),
    });
  }

  getBasicConfigComponent() {
    const {
      previewPeriod,
      currentPreviewSummaryData,
      previewList = [],
      defaultData,
    } = this.props;

    if (previewList.length > 0) {
      return this.getBasicConfigViewMode(
        previewList[0],
        currentPreviewSummaryData,
        defaultData
      );
    }

    const { errors } = this.state;

    const startTime =
      previewPeriod && typeof previewPeriod.startTimestamp === "number"
        ? fromUnixTime(previewPeriod.startTimestamp)
        : null;
    const endTime =
      previewPeriod && typeof previewPeriod.endTimestamp === "number"
        ? fromUnixTime(previewPeriod.endTimestamp)
        : null;

    const startTimeHasError =
      !!errors["previewPeriod.startTimestamp.notEmpty"] ||
      !!errors["previewPeriod.startTimestamp.noLateThanNow"] ||
      !!errors["previewPeriod.startTimestamp.endTimestamp"];

    const endTimeHasError =
      !!errors["previewPeriod.endTimestamp.notEmpty"] ||
      !!errors["previewPeriod.endTimestamp.noLateThanNow"] ||
      !!errors["previewPeriod.endTimestamp.endTimestamp"];
    return (
      <div className="rule-preview-period">
        <LabeledDatePicker
          label="Start Time"
          staticLabel
          showTime
          value={startTime}
          status={startTimeHasError ? "error" : null}
          onChange={this.onPreviewStartTimeChange}
        />
        <LabeledDatePicker
          label="End Time"
          staticLabel
          showTime
          value={endTime}
          status={endTimeHasError ? "error" : null}
          onChange={this.onPreviewEndTimeChange}
        />
      </div>
    );
  }

  render() {
    const { previewList = [] } = this.props;

    const description =
      previewList.length === 0
        ? "Set a start and end time for the preview and click generate preview. " +
          "Note that the preview will be truncated to the period for which data can " +
          "be collected."
        : "Set the training period and review training results before previewing " +
          "the monitor on past data";

    const steps = [
      {
        title: "Preview",
        component: this.getBasicConfigComponent(),
        className: "kpi-wizard-preview-setting-basic-step",
        skipIndex: true,
        description,
      },
    ];

    return (
      <div className="rule-preview-config-tab">
        <StepContainer steps={steps} className="rule-preview-tab-step-container" />
      </div>
    );
  }
}

export default RulePreviewConfigTab;
