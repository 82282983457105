import React from "react";

export function SearchIcon(props) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 8.375C0.75 4.43999 3.93999 1.25 7.875 1.25C11.81 1.25 15 4.43999 15 8.375C15 10.0724 14.4065 11.6311 13.4156 12.855L16.1705 15.6098C16.4634 15.9027 16.4634 16.3776 16.1705 16.6705C15.8776 16.9634 15.4027 16.9634 15.1098 16.6705L12.355 13.9156C11.1311 14.9065 9.57235 15.5 7.875 15.5C3.93999 15.5 0.75 12.31 0.75 8.375ZM7.875 2.75C4.76841 2.75 2.25 5.26841 2.25 8.375C2.25 11.4816 4.76841 14 7.875 14C10.9816 14 13.5 11.4816 13.5 8.375C13.5 5.26841 10.9816 2.75 7.875 2.75ZM5.22335 5.34831C5.90117 4.67054 6.83969 4.25 7.875 4.25C8.91031 4.25 9.84884 4.67054 10.5266 5.34833C10.8195 5.64123 10.8195 6.1161 10.5266 6.40899C10.2337 6.70189 9.75886 6.70189 9.46597 6.40899C9.05799 6.00101 8.49652 5.75 7.875 5.75C7.25349 5.75 6.692 6.00101 6.28397 6.40901C5.99107 6.70189 5.5162 6.70188 5.22331 6.40897C4.93043 6.11607 4.93045 5.6412 5.22335 5.34831Z"
        fill="#121224"
      />
    </svg>
  );
}
