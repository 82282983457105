import React from "react";
import PasscodeConfirmationModal from "../../components/passcode-confirmation-modal/passcode-confirmation-modal";
import ConfirmationDialog from "../../components/confirmation-dialog/ng-index";

import "./profiler-confirmation-dialog.scss";

function getDefaultConfirmationMessage(data, zeroMessage, actionType) {
  const { totalMetrics = 0, totalRules = 0 } = data;
  actionType = actionType || "delete";
  if (totalMetrics + totalRules === 0) {
    return zeroMessage;
  }

  let metricMsgInfo = "";
  if (totalMetrics === 1) {
    metricMsgInfo = "1 metric";
  } else if (totalMetrics > 1) {
    metricMsgInfo = `${totalMetrics} metrics`;
  }

  let monitorMsgInfo = "";
  if (totalRules > 0) {
    if (totalMetrics > 0) {
      monitorMsgInfo = ` and `;
    }

    if (totalRules === 1) {
      monitorMsgInfo = `${monitorMsgInfo} 1 monitor`;
    } else {
      monitorMsgInfo = `${monitorMsgInfo} ${totalRules} monitors`;
    }
  }

  return `You are about to ${actionType} ${metricMsgInfo}${monitorMsgInfo}. This action is not reversible.`;
}

function ProfilerConfirmationDialog(props) {
  const {
    modalIsOpen,
    setIsOpen,
    okClicked,
    enableUsage = true,
    usage,
    context = {},
    defaultConfirmationMsg = "",
    actionType = "delete",
    okText = "OK",
    showCancelButton = true,
    title = "Confirm changes?",
    getConfirmationMessage = null,
  } = props;

  if (enableUsage && usage && usage.loading) {
    return null;
  }

  const getMessage = getConfirmationMessage || getDefaultConfirmationMessage;
  const confirmationMsg = getMessage(usage.data, defaultConfirmationMsg, actionType);

  function handleCancel() {
    setIsOpen(false);
  }

  function handleOk() {
    okClicked(context);
    setIsOpen(false);
  }

  return enableUsage ? (
    <PasscodeConfirmationModal
      visible={modalIsOpen}
      okText={okText}
      cancelText={showCancelButton ? "Cancel" : null}
      title={title}
      targetPasscode="ok"
      onConfirm={handleOk}
      onCancel={handleCancel}
    >
      {confirmationMsg}
    </PasscodeConfirmationModal>
  ) : (
    <ConfirmationDialog
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setIsOpen}
      okClicked={handleOk}
      okText={okText}
      showCancelButton={showCancelButton}
      title={title}
      confirmationMsg={confirmationMsg}
    />
  );
}

export default ProfilerConfirmationDialog;
