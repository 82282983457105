import { connect } from "react-redux";

import Lobby from "./lobby";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);
