import * as types from "./chart-data-action-types";
import { getKpiFileDataPromise } from "../../rest/kpi";

function setChartDataFullCompareSampleData(fullCompareSampleData) {
  return {
    type: types.SET_CHART_DATA_FULL_COMPARE_SAMPLE_DATA,
    fullCompareSampleData,
  };
}

function getSampleDataPromise(workspaceUuid, metricUuid, fileUuid, isJsonFile) {
  return new Promise((resolve, rejected) => {
    if (!fileUuid) {
      resolve({});
      return;
    }

    getKpiFileDataPromise(workspaceUuid, metricUuid, fileUuid, isJsonFile)
      .then(function (fileData) {
        resolve(fileData);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function getFullCompareSampleData(workspaceUuid, metric, type, fileUuidObjects) {
  return (dispatch, getState) => {
    dispatch(setChartDataFullCompareSampleData({ loading: true, data: {} }));
    const metricUuid = metric.metadata.uuid;
    const fileUuidToNameMapper = {
      sourceRemainingSamplesFileUuid: {
        targetName: "sourceRemainingSampleData",
        isJsonFile: true,
      },
      targetRemainingSamplesFileUuid: {
        targetName: "targetRemainingSampleData",
        isJsonFile: true,
      },
      sourceDupSamplesFileUuid: { targetName: "sourceDupSampleData", isJsonFile: true },
      targetDupSamplesFileUuid: { targetName: "targetDupSampleData", isJsonFile: true },
      attributeDiffSamplesFileUuid: {
        targetName: "attributeDiffsSampleData",
        isJsonFile: true,
      },
      fullDiffSamplesFileUuid: { targetName: "fullDiffSampleData", isJsonFile: false },
    };

    const fileUuidKeys = Object.keys(fileUuidObjects);
    Promise.all(
      fileUuidKeys.map((fileUuidKey) =>
        getSampleDataPromise(
          workspaceUuid,
          metricUuid,
          fileUuidObjects[fileUuidKey],
          fileUuidToNameMapper[fileUuidKey].isJsonFile
        )
      )
    )
      .then((fileSampleDataResponses) => {
        const data = {};
        for (let i = 0; i < fileSampleDataResponses.length; i++) {
          data[fileUuidToNameMapper[fileUuidKeys[i]].targetName] =
            fileSampleDataResponses[i];
        }
        dispatch(
          setChartDataFullCompareSampleData({
            loading: false,
            data,
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to load sample data due to ${err}`);
        dispatch(setChartDataFullCompareSampleData({ loading: false, data: {} }));
      });
  };
}

export function resetFullCompareSampleData() {
  return (dispatch, getState) => {
    dispatch(setChartDataFullCompareSampleData({ loading: true, data: {} }));
  };
}
