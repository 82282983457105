import axios from "../utils/api";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

export function getTagListPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(getAPIURIInstance(backendRelativeURIPath.TAG_LIST, { workspaceUuid }))
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
