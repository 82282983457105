import React from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { NextGenPalette } from "../../utils/color";
import { dqDimensionTypeDescriptions } from "./util";

import "./dq-dimension-type-tooltip.scss";

function DqDimensionTypeTooltip(props) {
  const { dimensionType } = props;

  const tooltip = dqDimensionTypeDescriptions[dimensionType];

  return (
    <span className="dq-dim-type-tooltip">
      <Tooltip title={tooltip}>
        <InfoCircleOutlined color={NextGenPalette.systemBlack} />
      </Tooltip>
    </span>
  );
}

export default DqDimensionTypeTooltip;
