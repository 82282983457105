import React, { Component } from "react";
import { OracleConnectionType } from "../../../utils/enums";
import {
  DatabaseName,
  HostnameInput,
  Password,
  ServiceName,
  Username,
} from "./basic-config-view";
import { LabeledSelect } from "../../labeled-control/labeled-control";

const OracleConnectionTypeOptions = [
  { label: "Database", value: OracleConnectionType.DB_NAME },
  { label: "Service", value: OracleConnectionType.SERVICE_NAME },
];

class OracleConfigView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connType: OracleConnectionType.DB_NAME,
    };

    this.onConnTypeChanged = this.onConnTypeChanged.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      value: { service, dbname },
    } = props;
    if (service === "" && dbname !== "") {
      return {
        ...state,
        connType: OracleConnectionType.DB_NAME,
      };
    } else if (service !== "" && dbname === "") {
      return {
        ...state,
        connType: OracleConnectionType.SERVICE_NAME,
      };
    }

    return null;
  }

  onConnTypeChanged(newConnType) {
    const { value, onChange } = this.props;
    this.setState({ connType: newConnType });
    const newValue = {
      ...value,
      service: "",
      dbname: "",
    };
    onChange && onChange(newValue);
  }

  render() {
    const { value, onChange } = this.props;
    const { connType } = this.state;
    const { host, port, dbname, user, password, service } = value;

    return (
      <>
        <HostnameInput
          host={host}
          port={port}
          value={value}
          onChange={(newValue) => onChange({ ...newValue, password: "" })}
        />
        <LabeledSelect
          label="Connection type"
          staticLabel
          value={connType}
          onChange={this.onConnTypeChanged}
          options={OracleConnectionTypeOptions}
        />
        {connType === OracleConnectionType.DB_NAME && (
          <DatabaseName dbname={dbname} value={value} onChange={onChange} />
        )}
        {connType === OracleConnectionType.SERVICE_NAME && (
          <ServiceName service={service} value={value} onChange={onChange} />
        )}
        <Username user={user} value={value} onChange={onChange} />
        <Password password={password} value={value} onChange={onChange} />
      </>
    );
  }
}

export default OracleConfigView;
