import {
  getStringCompareFunction,
  stringListCompareFunction,
} from "../../../utils/search";

export const GENERAL_SEARCH_FIELD = "searchName";

const stringCompareFunction = getStringCompareFunction(false);

export const dataSourceFields = {
  DATA_SOURCE_ID: "dataSourceId",
  DATA_SOURCE_NAME: "dataSourceName",
  TAG_NAME: "tagName",
};

export const dataSourceFieldOptionValueGetterFn = {
  [dataSourceFields.DATA_SOURCE_ID]: (datasource) => datasource.metadata.idSerial,
  [dataSourceFields.TAG_NAME]: (datasource) => datasource.metadata.tags,
};

export const dataSourceFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    const dataSourceTagName = row.metadata.tags || [];
    return (
      searchName.length &&
      !stringListCompareFunction(
        [row.metadata.name, ...dataSourceTagName],
        searchName,
        stringCompareFunction
      )
    );
  },
  [dataSourceFields.DATA_SOURCE_ID]: (row, dataSourceId) => {
    return !dataSourceId.includes(row.metadata.idSerial.toString());
  },
  [dataSourceFields.DATA_SOURCE_NAME]: (row, dataSourceName) => {
    return !stringCompareFunction(row.metadata.name, dataSourceName);
  },
  [dataSourceFields.TAG_NAME]: (row, tagName) => {
    const dataSourceTagName = row.metadata.tags || [];
    return !stringListCompareFunction(
      dataSourceTagName,
      tagName,
      stringCompareFunction
    );
  },
};
