import { Form, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getIntegrationMetadata } from "../../actions/integration/integration-action";
import Button from "../../components/button/ng-button";
import { LabeledSelect } from "../../components/labeled-control/labeled-control";
import TakeoverPanel from "../../components/takeover/takeover-panel";
import { getAlertingChannelDisplayType } from "../../utils/alerting";
import { RequestStatus } from "../../utils/enums";
import {
  getBranchLeaf,
  isMetadata,
  reconcileMetadata,
} from "../integrations/integration-metadata";
import {
  TicketFieldsForm,
  copyFieldDefaultsToValues,
  initialFormValues,
} from "../integrations/integration-metadata-fields-form";
import { SubmitTicketIcon } from "./icons";
import { deepcopy } from "../../utils/objects";
import { getIntegrationUserListPromise } from "../../rest/integration";

import "./incident-submit-ticket-panel.scss";

function IncidentSubmitTicketContent(props) {
  const { type, incident, comment, submitInProgress, onCommentChange } = props;
  return (
    <div className="incident-submit-ticket-panel-content">
      <div className="incident-submit-jira-indicator-container">
        Submit a {getAlertingChannelDisplayType[type]} ticket for {incident.incidentId}
      </div>
      <Input.TextArea
        placeholder="Comment...(optional)"
        size="large"
        rows="5"
        value={comment}
        disabled={submitInProgress}
        onChange={(e) => {
          if (e.target.value === (comment || "")) {
            return;
          }

          onCommentChange(e.target.value);
        }}
      />
    </div>
  );
}

export function IncidentSubmitTicketPanel(props) {
  const {
    workspaceUuid,
    incident,
    integrationList,
    currentMetadata,
    incidentFileTicketResult,
    getIntegrationMetadata,
    onSubmit,
    closeTakeover,
  } = props;

  const [comment, setComment] = useState("");
  const [integrationId, setIntegrationId] = useState(null);
  const [ticketValue, setTicketValue] = useState(null);
  const getUserListPromise = useRef(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!integrationId) {
      return;
    }
    const newIntegration = integrationList.find(
      (integration) => integration.id === integrationId
    );

    getUserListPromise.current = (query) => {
      const integrationData = {
        id: newIntegration.id,
        type: newIntegration.type.toUpperCase(),
        config: newIntegration,
        metadata: {
          name: "whatever", // This value is just to pass the backend validation
          uuid: newIntegration.id,
        },
      };
      return getIntegrationUserListPromise(workspaceUuid, integrationData, query).then(
        (userList) =>
          userList.map(({ valueId, value }) => ({ label: value, value: valueId }))
      );
    };

    if (newIntegration.metadata) {
      getIntegrationMetadata(workspaceUuid, newIntegration).then((newMetadata) => {
        if (!isMetadata(newMetadata)) {
          setIntegrationId(null);
          setTicketValue(null);
          return;
        }
        const reconciled = reconcileMetadata(
          newIntegration.type,
          newIntegration.metadata,
          newMetadata
        );
        const newTicketValue = copyFieldDefaultsToValues({
          ...newIntegration,
          metadata: reconciled,
        });
        setTicketValue(newTicketValue);
        const editNode = getBranchLeaf(newTicketValue.metadata);
        form.resetFields(editNode.fields.map(({ fieldId }) => fieldId));
        form.setFieldsValue(initialFormValues(editNode.fields));
      });
    } else {
      setTicketValue(deepcopy(newIntegration));
    }
  }, [integrationId, form, getIntegrationMetadata, integrationList, workspaceUuid]);

  const submitInProgress = incidentFileTicketResult?.status === RequestStatus.DOING;

  const integrationOptions = integrationList.map((integration) => ({
    label: integration.title,
    value: integration.id,
  }));

  const cornerControls = (
    <div className="incident-submit-ticket-takeover-buttons">
      <Button outline size="large" onClick={() => closeTakeover()}>
        Cancel
      </Button>
      <Button
        primary
        size="large"
        disabled={submitInProgress || !ticketValue}
        onClick={() => {
          form
            .validateFields()
            .then(() => {
              onSubmit(ticketValue.id, ticketValue.type, comment, ticketValue.metadata);
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      >
        Submit
      </Button>
    </div>
  );

  return (
    <TakeoverPanel
      title="Submit ticket"
      titleIcon={<SubmitTicketIcon />}
      cornerControls={cornerControls}
    >
      <div className="incident-submit-ticket-integration-select">
        <LabeledSelect
          label="Integration"
          staticLabel
          loading={currentMetadata.loading}
          options={integrationOptions}
          value={integrationId}
          onChange={(newIntegrationId) => {
            setIntegrationId(newIntegrationId);
          }}
        />
      </div>
      {!currentMetadata.loading && (
        <>
          {ticketValue?.metadata && (
            <TicketFieldsForm
              form={form}
              value={ticketValue}
              onChange={setTicketValue}
              getUserList={getUserListPromise.current}
            />
          )}
          {ticketValue && (
            <IncidentSubmitTicketContent
              type={ticketValue.type}
              incident={incident}
              submitInProgress={submitInProgress}
              comment={comment}
              onCommentChange={setComment}
            />
          )}
        </>
      )}
    </TakeoverPanel>
  );
}

const mapStateToProps = (state) => ({
  currentMetadata: state.integrationReducer.currentMetadata,
  incidentFileTicketResult: state.incidentReducer.incidentFileTicketResult,
});

const mapDispatchToProps = (dispatch) => ({
  getIntegrationMetadata: (workspaceUuid, integration) =>
    dispatch(getIntegrationMetadata(workspaceUuid, integration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentSubmitTicketPanel);
