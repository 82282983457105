import React from "react";
import { indexBy } from "../../utils/iterables";
import { getRuleSymptomInfo } from "../../utils/icon";

import "./incident-analysis-metric-item-context.scss";

function DataAssetSectionItem(props) {
  const { type, value, icon, title } = props;
  return (
    <div className={`udia-metric-item-context-data-asset-item ${type}`}>
      <div className="udia-metric-item-context-data-asset-item-title">{title}</div>
      <div className="udia-metric-item-context-data-asset-item-value">
        {icon}
        {value}
      </div>
    </div>
  );
}

function DataAssetSection(props) {
  const { dataAssetSourceSummary, itemConfig } = props;
  const segmentsByKey = indexBy(dataAssetSourceSummary, (segment) => segment.key);

  return (
    <div className="udia-metric-item-context-data-asset">
      {itemConfig.map((item) => {
        const segment = segmentsByKey[item.key];
        return segment ? (
          <DataAssetSectionItem
            key={item.key}
            type={item.type}
            title={item.title}
            value={segment.value}
            icon={segment.icon}
          />
        ) : null;
      })}
    </div>
  );
}

function MonitorsSection(props) {
  const { monitors } = props;
  return (
    <div className="udia-metric-item-context-monitors">
      <div className="udia-metric-item-context-data-asset-item-title">Monitor(s)</div>
      <div className="udia-metric-item-context-monitors-items">
        {monitors.map((monitor) => {
          const symptomType = monitor.config.symptom.type;
          const SymptomIcon = getRuleSymptomInfo(symptomType).icon;
          return (
            <div
              key={monitor.metadata.uuid}
              className="udia-metric-item-context-data-asset-item-value"
            >
              <SymptomIcon width={14} height={14} />
              <span>{monitor.metadata.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function IncidentAnalysisMetricItemContext(props) {
  const { dataAssetSummary, monitors } = props;
  return (
    <div>
      {dataAssetSummary.single && (
        <div className="udia-metric-item-context-section">
          <DataAssetSection
            dataAssetSourceSummary={dataAssetSummary.single}
            itemConfig={[
              { key: "dataSource", type: "dataSource", title: "Datasource" },
              { key: "schema", type: "schema", title: "Schema" },
              { key: "table", type: "table", title: "Table" },
              { key: "valueColumns", type: "column", title: "Column" },
            ]}
          />
        </div>
      )}
      {dataAssetSummary.source && (
        <div className="udia-metric-item-context-section">
          <div className="udia-metric-item-context-section-title">Source</div>
          <DataAssetSection
            dataAssetSourceSummary={dataAssetSummary.source}
            itemConfig={[
              { key: "sourceDataSource", type: "dataSource", title: "Datasource" },
              { key: "sourceSchema", type: "schema", title: "Schema" },
              { key: "sourceTable", type: "table", title: "Table" },
              { key: "sourceValueColumns", type: "column", title: "Column" },
            ]}
          />
        </div>
      )}
      {dataAssetSummary.target && (
        <>
          <div className="udia-metric-item-context-divider" />
          <div className="udia-metric-item-context-section">
            <div className="udia-metric-item-context-section-title">Target</div>
            <DataAssetSection
              dataAssetSourceSummary={dataAssetSummary.target}
              itemConfig={[
                { key: "targetDataSource", type: "dataSource", title: "Datasource" },
                { key: "targetSchema", type: "schema", title: "Schema" },
                { key: "targetTable", type: "table", title: "Table" },
                { key: "targetValueColumns", type: "column", title: "Column" },
              ]}
            />
          </div>
        </>
      )}
      {monitors.length > 0 && (
        <>
          <div className="udia-metric-item-context-divider" />
          <div className="udia-metric-item-context-section">
            <MonitorsSection monitors={monitors} />
          </div>
        </>
      )}
    </div>
  );
}

export default IncidentAnalysisMetricItemContext;
