const userInitialState = {
  currentUserInfo: {
    disclaimAccepted: false,
    email: "",
    isConfigInitialized: false,
    isCurrentUserInitialized: false,
    isUserEmailUnverified: false,
    isSuperuser: false,
    loginSucceed: false,
    logoutSucceed: false,
    oauthClientId: "",
    oauthDomainName: "",
    permissions: [],
    roles: [],
    username: "Lightup Default",
  },
  workspaceUserPermissionMap: {},
  userList: {
    loading: true,
    data: [],
    updatedAt: 0,
  },
  userAPIKeyList: [],
  currentAddedUserAPIKey: null,
};

export default userInitialState;
