import React from "react";
import { InProgressIcon } from "../../icons";
import {
  RuleTabBasicIcon,
  RuleTabTrainingIcon,
  RuleTabPreviewIcon,
} from "../../rule-tab-icon";
import LoadingGif from "../../../../assets/img/ajax-loader.gif";

import "./rule-basic-config-content.scss";

function MonitorIcon(props) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
      <path
        d="M23.3333 31.5C23.3333 30.2113 24.378 29.1667 25.6667 29.1667H30.3333C31.622 29.1667 32.6667 30.2113 32.6667 31.5C32.6667 32.7887 31.622 33.8333 30.3333 33.8333H25.6667C24.378 33.8333 23.3333 32.7887 23.3333 31.5Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16667 7C5.58934 7 3.5 9.08934 3.5 11.6667V35C3.5 37.5773 5.58934 39.6667 8.16667 39.6667H19.8333V45.5H16.3333C15.0447 45.5 14 46.5447 14 47.8333C14 49.122 15.0447 50.1667 16.3333 50.1667H39.6667C40.9553 50.1667 42 49.122 42 47.8333C42 46.5447 40.9553 45.5 39.6667 45.5H36.1667V39.6667H47.8333C50.4107 39.6667 52.5 37.5773 52.5 35V11.6667C52.5 9.08934 50.4107 7 47.8333 7H8.16667ZM31.5 45.5V39.6667H24.5V45.5H31.5ZM47.8333 35V11.6667H8.16667V35H47.8333Z"
        fill="#121224"
      />
    </svg>
  );
}

function BasicConfigContentStep(props) {
  const { icon, label } = props;
  return (
    <div className="rule-basic-config-config-step-item-container">
      <div className="rule-basic-config-config-step-item-icon-container">{icon}</div>
      <div className="rule-basic-config-config-step-item-text-container">{label}</div>
    </div>
  );
}

const stepInfo = [
  { icon: <RuleTabBasicIcon size={56} />, label: "Define" },
  { icon: <RuleTabTrainingIcon size={56} />, label: "Train" },
  { icon: <RuleTabPreviewIcon size={56} />, label: "Preview" },
  { icon: <MonitorIcon />, label: "Live Monitor" },
];

function RuleBasicConfigContent({ isAutoSymptom = false }) {
  return (
    <div className="rule-basic-config-content lightup-vertical-flex-container">
      {isAutoSymptom ? (
        <div className="rule-auto-symptom-in-progress-container">
          <InProgressIcon />
          <div>Auto-rule creation in progress</div>
          <img src={LoadingGif} alt="Loading" />
        </div>
      ) : (
        <>
          <div className="rule-basic-config-content-indicator-container">
            Create your monitor
          </div>
          <div className="rule-basic-config-content-step-container">
            {stepInfo.map(function (stepItem, index) {
              const { icon, label } = stepItem;
              const isLast = index === stepInfo.length - 1;
              return (
                <BasicConfigContentStep
                  key={index}
                  isLast={isLast}
                  icon={icon}
                  label={label}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default RuleBasicConfigContent;
