import { CloseOutlined } from "@ant-design/icons";
import ButtonIcon from "../../button/button-icon";
import ModalDialog from "../../modal-dialog/modal-dialog";

import "./draft-metric-modal.scss";

export function DraftModalDialog(props) {
  const { title, children, onCancel, ...otherProps } = props;

  return (
    <ModalDialog
      title={
        <div className="draft-modal-dialog-title-container">
          <span>{title}</span>
          <ButtonIcon
            icon={<CloseOutlined className="draft-modal-dialog-title-close" />}
            onClick={onCancel}
            data-testid="draft-modal-dialog-close-button"
          />
        </div>
      }
      closable={false}
      mask={true}
      {...otherProps}
    >
      {children}
    </ModalDialog>
  );
}
