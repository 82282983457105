import {
  getStringCompareFunction,
  stringListCompareFunction,
} from "../../../utils/search";

export const GENERAL_SEARCH_FIELD = "searchName";

const stringCompareFunction = getStringCompareFunction(false);

export const notificationFields = {
  DATA_SOURCE_NAME: "dataSourceName",
  MONITORS: "monitors",
  LEVEL: "level",
  CATEGORY: "category",
  OBJECT_TYPE: "objectType",
};

export const notificationFieldOptionValueGetterFn = {
  [notificationFields.DATA_SOURCE_NAME]: (notification) => notification.sourceName,
  [notificationFields.MONITORS]: (notification) => notification.filterName,
  [notificationFields.LEVEL]: (notification) => notification.level,
  [notificationFields.CATEGORY]: (notification) => notification.category,
  [notificationFields.OBJECT_TYPE]: (notification) => notification.objectType,
};

export const notificationFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    return (
      searchName.length &&
      !stringListCompareFunction(
        [row.dataSourceName, row.monitorName, row.level, row.objectType, row.category],
        searchName,
        stringCompareFunction
      )
    );
  },
  [notificationFields.DATA_SOURCE_NAME]: (row, dataSourceName) => {
    return !stringCompareFunction(row.dataSourceName, dataSourceName);
  },
  [notificationFields.MONITORS]: (row, monitorName) => {
    return !stringCompareFunction(row.monitorName, monitorName);
  },
  [notificationFields.LEVEL]: (row, systemEventType) => {
    return !stringCompareFunction(row.level, systemEventType);
  },
  [notificationFields.CATEGORY]: (row, systemEventCategory) => {
    return !stringCompareFunction(row.category, systemEventCategory);
  },
  [notificationFields.OBJECT_TYPE]: (row, systemEventObjectType) => {
    return !stringCompareFunction(row.objectType, systemEventObjectType);
  },
};
