import React from "react";

export function DivWithClassName(className, displayName) {
  const StyledDiv = (props) => {
    const { children, className: propsClassName, ...restProps } = props;

    let fullClassName = className;
    if (typeof propsClassName === "string" && propsClassName !== "") {
      fullClassName += ` ${propsClassName}`;
    }

    return (
      <div className={fullClassName} {...restProps}>
        {children}
      </div>
    );
  };
  StyledDiv.displayName = displayName;
  return StyledDiv;
}
