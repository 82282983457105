import { connect } from "react-redux";
import {
  getProfilerConfigDataSourceTableColumnList,
  getProfilerTableListPartitionSampleData,
  getProfilerConfigTableListUsage,
} from "../../../../../actions/profiler/profiler-action";
import ProfilerSettingTableConfigTakeoverView from "./profiler-setting-table-config-takeover-view";

const mapStateToProps = (state) => ({
  waffle: state.userReducer.currentUserInfo.waffle,
  tableColumnList: state.profilerReducer.profilerConfigDataSourceTableColumnList,
  tableListPartitionSampleData:
    state.profilerReducer.profilerTableListPartitionSampleData,
  tableListUsage: state.profilerReducer.profilerConfigDataSourceTableListUsage,
});

const mapDispatchToProps = (dispatch) => ({
  getProfilerConfigColumnList: (workspaceUuid, dataSource, tableUuid) =>
    dispatch(
      getProfilerConfigDataSourceTableColumnList(workspaceUuid, dataSource, tableUuid)
    ),
  getProfilerTableListPartitionSampleData: (workspaceUuid, dataSource, tableList) =>
    dispatch(
      getProfilerTableListPartitionSampleData(workspaceUuid, dataSource, tableList)
    ),
  getProfilerConfigTableListUsage: (
    workspaceUuid,
    dataSource,
    tableUuids,
    tableProfilerConfigs
  ) =>
    dispatch(
      getProfilerConfigTableListUsage(
        workspaceUuid,
        dataSource,
        tableUuids,
        tableProfilerConfigs
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilerSettingTableConfigTakeoverView);
