import { SET_SCHEDULE_LIST } from "../../actions/schedule/schedule-action-types";

const initialScheduleState = {
  scheduleList: {
    loading: true,
    data: [],
  },
};

export default function scheduleReducer(state = initialScheduleState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_SCHEDULE_LIST:
      newState = { ...state, scheduleList: action.scheduleList };
      return newState;

    default:
      return state;
  }
}
