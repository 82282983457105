import React, { useState, useEffect } from "react";
import { LabeledInputNumber } from "../labeled-control/labeled-control";

import "./index.scss";

const UpperIcon = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path d="M0 0H68V68H0V0Z" fill="#F7F7FB" />
      <path
        d="M36.5 22C37.3284 22 38 22.6716 38 23.5V44.5C38 45.3284 37.3284 46 36.5 46C35.6716 46 35 45.3284 35 44.5V23.5C35 22.6716 35.6716 22 36.5 22Z"
        fill="#B80739"
      />
      <path
        d="M42.5 28C43.3284 28 44 28.6716 44 29.5V44.5C44 45.3284 43.3284 46 42.5 46C41.6716 46 41 45.3284 41 44.5V29.5C41 28.6716 41.6716 28 42.5 28Z"
        fill="#B80739"
      />
      <path
        d="M30.5 34C31.3284 34 32 34.6716 32 35.5V44.5C32 45.3284 31.3284 46 30.5 46C29.6716 46 29 45.3284 29 44.5V35.5C29 34.6716 29.6716 34 30.5 34Z"
        fill="#B80739"
      />
      <path
        d="M24.5 40C25.3284 40 26 40.6716 26 41.5V44.5C26 45.3284 25.3284 46 24.5 46C23.6716 46 23 45.3284 23 44.5V41.5C23 40.6716 23.6716 40 24.5 40Z"
        fill="#B80739"
      />
      <mask
        id="mask0_18270_81361"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="28"
        width="40"
        height="18"
      >
        <rect x="14" y="28" width="40" height="18" fill="#4832F3" />
      </mask>
      <g mask="url(#mask0_18270_81361)">
        <path
          d="M36.5 22C37.3284 22 38 22.6716 38 23.5V44.5C38 45.3284 37.3284 46 36.5 46C35.6716 46 35 45.3284 35 44.5V23.5C35 22.6716 35.6716 22 36.5 22Z"
          fill="#121224"
        />
        <path
          d="M42.5 28C43.3284 28 44 28.6716 44 29.5V44.5C44 45.3284 43.3284 46 42.5 46C41.6716 46 41 45.3284 41 44.5V29.5C41 28.6716 41.6716 28 42.5 28Z"
          fill="#121224"
        />
        <path
          d="M30.5 34C31.3284 34 32 34.6716 32 35.5V44.5C32 45.3284 31.3284 46 30.5 46C29.6716 46 29 45.3284 29 44.5V35.5C29 34.6716 29.6716 34 30.5 34Z"
          fill="#121224"
        />
        <path
          d="M24.5 40C25.3284 40 26 40.6716 26 41.5V44.5C26 45.3284 25.3284 46 24.5 46C23.6716 46 23 45.3284 23 44.5V41.5C23 40.6716 23.6716 40 24.5 40Z"
          fill="#121224"
        />
      </g>
      <rect opacity="0.1" x="14" y="28" width="40" height="18" fill="#4832F3" />
      <rect
        x="14.125"
        y="28.125"
        width="39.75"
        height="0.25"
        stroke="#4832F3"
        strokeWidth="0.25"
        strokeDasharray="4 4"
      />
    </svg>
  );
};

const LowerIcon = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path d="M0 0H68V68H0V0Z" fill="#F7F7FB" />
      <path
        d="M36.5 22C37.3284 22 38 22.6716 38 23.5V44.5C38 45.3284 37.3284 46 36.5 46C35.6716 46 35 45.3284 35 44.5V23.5C35 22.6716 35.6716 22 36.5 22Z"
        fill="#B80739"
      />
      <path
        d="M42.5 28C43.3284 28 44 28.6716 44 29.5V44.5C44 45.3284 43.3284 46 42.5 46C41.6716 46 41 45.3284 41 44.5V29.5C41 28.6716 41.6716 28 42.5 28Z"
        fill="#B80739"
      />
      <path
        d="M30.5 22C31.3284 22 32 22.6716 32 23.5V32.5C32 33.3284 31.3284 34 30.5 34C29.6716 34 29 33.3284 29 32.5V23.5C29 22.6716 29.6716 22 30.5 22Z"
        fill="#B80739"
      />
      <path
        d="M24.5 28C25.3284 28 26 28.6716 26 29.5V32.5C26 33.3284 25.3284 34 24.5 34C23.6716 34 23 33.3284 23 32.5V29.5C23 28.6716 23.6716 28 24.5 28Z"
        fill="#B80739"
      />
      <mask
        id="mask0_18270_81370"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="22"
        width="40"
        height="18"
      >
        <rect x="14" y="22" width="40" height="18" fill="#4832F3" />
      </mask>
      <g mask="url(#mask0_18270_81370)">
        <path
          d="M36.5 22C37.3284 22 38 22.6716 38 23.5V44.5C38 45.3284 37.3284 46 36.5 46C35.6716 46 35 45.3284 35 44.5V23.5C35 22.6716 35.6716 22 36.5 22Z"
          fill="#121224"
        />
        <path
          d="M42.5 28C43.3284 28 44 28.6716 44 29.5V44.5C44 45.3284 43.3284 46 42.5 46C41.6716 46 41 45.3284 41 44.5V29.5C41 28.6716 41.6716 28 42.5 28Z"
          fill="#121224"
        />
        <path
          d="M30.5 22C31.3284 22 32 22.6716 32 23.5V32.5C32 33.3284 31.3284 34 30.5 34C29.6716 34 29 33.3284 29 32.5V23.5C29 22.6716 29.6716 22 30.5 22Z"
          fill="#121224"
        />
        <path
          d="M24.5 28C25.3284 28 26 28.6716 26 29.5V32.5C26 33.3284 25.3284 34 24.5 34C23.6716 34 23 33.3284 23 32.5V29.5C23 28.6716 23.6716 28 24.5 28Z"
          fill="#121224"
        />
      </g>
      <rect opacity="0.1" x="14" y="22" width="40" height="18" fill="#4832F3" />
      <rect
        x="14.125"
        y="39.625"
        width="39.75"
        height="0.25"
        stroke="#4832F3"
        strokeWidth="0.25"
        strokeDasharray="4 4"
      />
    </svg>
  );
};

function scaleNumber(v, scale) {
  return typeof v === "number" ? v * scale : v;
}

function LabeledInputNumberWithError(props) {
  const { value: defaultValue, onChange, ...otherProps } = props;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <LabeledInputNumber
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      {...otherProps}
    />
  );
}

function BoundSettingView(props) {
  const {
    errors,
    value,
    onChange: unscaledOnChange,
    suffix = "",
    scale = 1,
    disabled = false,
  } = props;
  const { upper: unscaledUpper, lower: unscaledLower } = value;

  const upper = scaleNumber(unscaledUpper, 1 / scale);
  const lower = scaleNumber(unscaledLower, 1 / scale);

  function onChange(changedBound) {
    unscaledOnChange &&
      unscaledOnChange({
        lower: scaleNumber(changedBound.lower, scale),
        upper: scaleNumber(changedBound.upper, scale),
      });
  }

  return (
    <div className="bound-setting-view">
      <div className="bound-setting-view-item">
        <UpperIcon />
        <div className="bound-setting-view-item-input-container">
          <div className="bound-setting-view-item-input-container-content">
            <LabeledInputNumberWithError
              label="Upper threshold value"
              value={upper}
              onChange={(newUpper) => {
                onChange && onChange({ lower, upper: newUpper });
              }}
              disabled={disabled}
              staticLabel
            />
            {suffix}
          </div>
          {errors?.config?.bound?.upper ? (
            <span className="bound-setting-view-item-input-container-error-message">
              {errors?.config?.bound?.upper}
            </span>
          ) : (
            <span className="bound-setting-view-item-input-container-support-message">
              Leave blank to omit upper threshold
            </span>
          )}
        </div>
      </div>

      <div className="bound-setting-view-item">
        <LowerIcon />
        <div className="bound-setting-view-item-input-container">
          <div className="bound-setting-view-item-input-container-content">
            <LabeledInputNumberWithError
              staticLabel
              label="Lower threshold value"
              disabled={disabled}
              value={lower}
              onChange={(newLower) => {
                onChange && onChange({ lower: newLower, upper });
              }}
            />
            {suffix}
          </div>

          {errors?.config?.bound?.lower ? (
            <span className="bound-setting-view-item-input-container-error-message">
              {errors?.config?.bound?.lower}
            </span>
          ) : (
            <span className="bound-setting-view-item-input-container-support-message">
              Leave blank to omit lower threshold
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default BoundSettingView;
