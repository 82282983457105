import { DataSourceType, QueryScope } from "./enums";
import { getTimePeriodFromString } from "./time";

export function isDataProfileEnabled(dataSource) {
  return ![DataSourceType.AZUREBLOBSTORAGE, DataSourceType.S3BUCKET].includes(
    dataSource?.config.connection.type
  );
}

export const DataProfileMode = Object.freeze({
  RANDOM_SAMPLE: "randomSample",
  FULL_TABLE: "fullTable",
  TIME_RANGE: "timeRange",
});

export const DataProfileModeOptions = Object.freeze([
  { label: "Random sample", value: DataProfileMode.RANDOM_SAMPLE },
  { label: "Full table", value: DataProfileMode.FULL_TABLE },
  { label: "Time range", value: DataProfileMode.TIME_RANGE },
]);

export function getModeFromTableConfig(dataProfile) {
  if (dataProfile?.randomSample) {
    return DataProfileMode.RANDOM_SAMPLE;
  }

  if (dataProfile?.queryScope === QueryScope.FULL_TABLE) {
    return DataProfileMode.FULL_TABLE;
  }

  if (dataProfile?.timeRange) {
    return DataProfileMode.TIME_RANGE;
  }

  return null;
}

export function isValidTableConfig(dataProfile) {
  const mode = getModeFromTableConfig(dataProfile);
  if (!mode) {
    return false;
  } else if (mode === DataProfileMode.TIME_RANGE) {
    return Boolean(
      dataProfile.timeRange &&
        dataProfile.timestampColumn &&
        dataProfile.timestampColumn !== ""
    );
  } else {
    return true;
  }
}

export function isSamePartitionArray(partitions1, partitions2) {
  partitions1 = partitions1 || [];
  partitions2 = partitions2 || [];
  if (partitions1.length !== partitions2.length) {
    return false;
  }

  const partitionStringSet1 = new Set();
  partitions1.forEach((partitions1Item) =>
    partitionStringSet1.add(
      JSON.stringify({
        columnName: partitions1Item.columnName,
        format: partitions1Item.format,
      })
    )
  );

  const partitionStringSet2 = new Set();
  partitions2.forEach((partitions2Item) =>
    partitionStringSet2.add(
      JSON.stringify({
        columnName: partitions2Item.columnName,
        format: partitions2Item.format,
      })
    )
  );

  return [...partitionStringSet1].every((x) => partitionStringSet2.has(x));
}

export function defaultTimeRange() {
  const { startTimestamp, endTimestamp } = getTimePeriodFromString("1w");
  return { startTime: startTimestamp, endTime: endTimestamp };
}

const configItemNames = [
  "randomSample",
  "queryScope",
  "timestampColumn",
  "timestampColumnType",
  "timeRange",
  "partitions",
  "partitionTimezone",
  "partitionOffsets",
  "dataTimezone",
  "queryTimezone",
  "aggregationWindow",
  "numSamples",
];
export function getDefaultDataProfilerConfig(table) {
  const dataProfiler = table?.profilerConfig.dataProfiler || {};
  const runtimeConfig = table?.status?.dataProfilerStatus?.runtimeConfig || {};
  const result = {};
  configItemNames.forEach((configItemName) => {
    if (
      dataProfiler.hasOwnProperty(configItemName) &&
      dataProfiler[configItemName] !== null
    ) {
      result[configItemName] = dataProfiler[configItemName];
    } else if (
      runtimeConfig.hasOwnProperty(configItemName) &&
      runtimeConfig[configItemName] !== null
    ) {
      result[configItemName] = runtimeConfig[configItemName];
    } else {
      result[configItemName] = null;
    }
  });

  return result;
}

export function reconciledProfilerConfig(profilerConfig, status) {
  return !isValidTableConfig(profilerConfig.dataProfiler) &&
    isValidTableConfig(status?.dataProfilerStatus?.runtimeConfig)
    ? {
        ...profilerConfig,
        dataProfiler: {
          ...profilerConfig.dataProfiler,
          ...getDefaultDataProfilerConfig({ profilerConfig, status }),
        },
      }
    : profilerConfig;
}
