import React from "react";

import "./index.scss";

function AddButton(props) {
  const { onClick = null } = props;

  return (
    <div className="add-button-container" onClick={onClick}>
      <span className="add-button-icon-container">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 5H7V0.5C7 0.22375 6.77625 0 6.5 0H5.5C5.22375 0 5 0.22375 5 0.5V5H0.5C0.22375 5 0 5.22375 0 5.5V6.5C0 6.77625 0.22375 7 0.5 7H5V11.5C5 11.7762 5.22375 12 5.5 12H6.5C6.77625 12 7 11.7762 7 11.5V7H11.5C11.7762 7 12 6.77625 12 6.5V5.5C12 5.22375 11.7762 5 11.5 5Z"
            fill="#868786"
          />
        </svg>
      </span>
    </div>
  );
}

export default AddButton;
