import React from "react";
import { Tabs } from "antd";
import { IncidentMainTabKey } from "./utils";
import { default as Icon } from "@ant-design/icons";
import {
  ConformityConditionType,
  IncidentCreatorType,
  MetricCategory,
  TableType,
  isFileSource,
} from "../../utils/enums";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { URIPath, getURIInstance, hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import IncidentMainViewMenu from "./incident-main-view-menu";
import { withRouter } from "react-router";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";

import "./incident-main-header-view.scss";
import { ArrowClick } from "../../components/icons/arrow-click-icon";

function ChartTabIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M9.33398 1.75033C9.33398 1.42816 9.59515 1.16699 9.91732 1.16699H11.6673C11.9895 1.16699 12.2507 1.42816 12.2507 1.75033V11.667H12.834C13.1562 11.667 13.4173 11.9282 13.4173 12.2503C13.4173 12.5725 13.1562 12.8337 12.834 12.8337H1.16732C0.845152 12.8337 0.583984 12.5725 0.583984 12.2503C0.583984 11.9282 0.845152 11.667 1.16732 11.667H1.75065V8.16699C1.75065 7.84483 2.01182 7.58366 2.33398 7.58366H4.08398C4.40615 7.58366 4.66732 7.84483 4.66732 8.16699V11.667H5.54232V5.25033C5.54232 4.92816 5.80349 4.66699 6.12565 4.66699H7.87565C8.19782 4.66699 8.45898 4.92816 8.45898 5.25033V11.667H9.33398V1.75033Z"
        fill="#121224"
      />
    </svg>
  );
}

function SummaryTabIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04232 1.74967C1.88123 1.74967 1.75065 1.88026 1.75065 2.04134V11.958C1.75065 12.1191 1.88124 12.2497 2.04232 12.2497H11.959C12.1201 12.2497 12.2507 12.1191 12.2507 11.958V2.04134C12.2507 1.88026 12.1201 1.74967 11.959 1.74967H2.04232ZM0.583984 2.04134C0.583984 1.23593 1.2369 0.583008 2.04232 0.583008H11.959C12.7644 0.583008 13.4173 1.23593 13.4173 2.04134V11.958C13.4173 12.7634 12.7644 13.4163 11.959 13.4163H2.04232C1.2369 13.4163 0.583984 12.7634 0.583984 11.958V2.04134ZM2.91732 3.49967C2.91732 3.17751 3.17849 2.91634 3.50065 2.91634H5.83398C6.15615 2.91634 6.41732 3.17751 6.41732 3.49967V5.83301C6.41732 6.15517 6.15615 6.41634 5.83398 6.41634H3.50065C3.17849 6.41634 2.91732 6.15517 2.91732 5.83301V3.49967ZM4.08398 4.08301V5.24967H5.25065V4.08301H4.08398ZM7.58398 3.49967C7.58398 3.17751 7.84515 2.91634 8.16732 2.91634H10.5007C10.8228 2.91634 11.084 3.17751 11.084 3.49967C11.084 3.82184 10.8228 4.08301 10.5007 4.08301H8.16732C7.84515 4.08301 7.58398 3.82184 7.58398 3.49967ZM7.58398 5.83301C7.58398 5.51084 7.84515 5.24967 8.16732 5.24967H10.5007C10.8228 5.24967 11.084 5.51084 11.084 5.83301C11.084 6.15517 10.8228 6.41634 10.5007 6.41634H8.16732C7.84515 6.41634 7.58398 6.15517 7.58398 5.83301ZM2.91732 8.16634C2.91732 7.84418 3.17849 7.58301 3.50065 7.58301H10.5007C10.8228 7.58301 11.084 7.84418 11.084 8.16634C11.084 8.48851 10.8228 8.74967 10.5007 8.74967H3.50065C3.17849 8.74967 2.91732 8.48851 2.91732 8.16634ZM2.91732 10.4997C2.91732 10.1775 3.17849 9.91634 3.50065 9.91634H10.5007C10.8228 9.91634 11.084 10.1775 11.084 10.4997C11.084 10.8218 10.8228 11.083 10.5007 11.083H3.50065C3.17849 11.083 2.91732 10.8218 2.91732 10.4997Z"
        fill="#121224"
      />
    </svg>
  );
}

function SampleDataTabIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g opacity="0.85">
        <path
          d="M5.25065 7.00033C5.25065 6.67816 5.51182 6.41699 5.83398 6.41699H10.5007C10.8228 6.41699 11.084 6.67816 11.084 7.00033C11.084 7.32249 10.8228 7.58366 10.5007 7.58366H5.83398C5.51182 7.58366 5.25065 7.32249 5.25065 7.00033Z"
          fill="#121224"
        />
        <path
          d="M5.83398 8.75033C5.51182 8.75033 5.25065 9.01149 5.25065 9.33366C5.25065 9.65582 5.51182 9.91699 5.83398 9.91699H10.5007C10.8228 9.91699 11.084 9.65582 11.084 9.33366C11.084 9.01149 10.8228 8.75033 10.5007 8.75033H5.83398Z"
          fill="#121224"
        />
        <path
          d="M2.91732 7.00033C2.91732 6.67816 3.17849 6.41699 3.50065 6.41699H4.08398C4.40615 6.41699 4.66732 6.67816 4.66732 7.00033C4.66732 7.32249 4.40615 7.58366 4.08398 7.58366H3.50065C3.17849 7.58366 2.91732 7.32249 2.91732 7.00033Z"
          fill="#121224"
        />
        <path
          d="M3.50065 8.75033C3.17849 8.75033 2.91732 9.01149 2.91732 9.33366C2.91732 9.65582 3.17849 9.91699 3.50065 9.91699H4.08398C4.40615 9.91699 4.66732 9.65582 4.66732 9.33366C4.66732 9.01149 4.40615 8.75033 4.08398 8.75033H3.50065Z"
          fill="#121224"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.583984 2.62533C0.583984 1.81991 1.2369 1.16699 2.04232 1.16699H11.959C12.7644 1.16699 13.4173 1.81991 13.4173 2.62533V11.3753C13.4173 12.1807 12.7644 12.8337 11.959 12.8337H2.04232C1.2369 12.8337 0.583984 12.1807 0.583984 11.3753V2.62533ZM1.75065 2.62533C1.75065 2.46424 1.88123 2.33366 2.04232 2.33366H11.959C12.1201 2.33366 12.2507 2.46424 12.2507 2.62533V3.50033H1.75065V2.62533ZM12.2507 4.66699V11.3753C12.2507 11.5364 12.1201 11.667 11.959 11.667H2.04232C1.88123 11.667 1.75065 11.5364 1.75065 11.3753V4.66699H12.2507Z"
          fill="#121224"
        />
      </g>
    </svg>
  );
}

const BreadCrumb = withRouter(({ name, query = null, history }) => {
  const goToExplorer = () => {
    history.push(query);
  };
  return (
    <span style={{ overflow: "hidden" }} onClick={query ? goToExplorer : null}>
      <NgTextTooltip className={query ? "incident-breadcrumb-link" : null}>
        {name}
      </NgTextTooltip>
    </span>
  );
});

function getIncidentBreadCrumbs(creatorInfo) {
  const { type, dataSourceInfo, kpiInfo } = creatorInfo;
  if (type !== IncidentCreatorType.FILTER || !dataSourceInfo) {
    return [];
  }
  const workspaceUuid = dataSourceInfo.metadata.workspaceId;
  const getCurrentQuery = () => {
    const basePath = getURIInstance(URIPath.EXPLORER, { workspaceUuid });
    return `${basePath}?${queryList.join("&")}`;
  };

  const breadCrumbs = [];
  const queryList = [];

  queryList.push(`dataSourceUuid=${dataSourceInfo.metadata.uuid}`);
  breadCrumbs.push({ name: dataSourceInfo.metadata.name, query: getCurrentQuery() });

  if (!kpiInfo.config.table || kpiInfo.config.table.type !== TableType.TABLE) {
    return breadCrumbs;
  }

  const { tableName, tableUuid, schemaName, schemaUuid } = kpiInfo.config.table;

  if (!schemaName || !schemaUuid) {
    return breadCrumbs;
  }
  queryList.push(`schemaUuid=${schemaUuid}`);
  breadCrumbs.push({ name: schemaName, query: getCurrentQuery() });

  if (!tableName || !tableUuid) {
    return breadCrumbs;
  }
  queryList.push(`tableUuid=${tableUuid}`);
  breadCrumbs.push({ name: tableName, query: getCurrentQuery() });

  if (!kpiInfo.config.valueColumns || kpiInfo.config.valueColumns.length === 0) {
    return breadCrumbs;
  }
  queryList.push(`columnUuid=${kpiInfo.config.valueColumns[0].columnUuid}`);
  breadCrumbs.push({
    name: kpiInfo.config.valueColumns[0].columnName,
    query: getCurrentQuery(),
  });

  return breadCrumbs;
}

const sampleDataSupportedMetricTypes = [
  MetricCategory.NULL_PERCENT,
  MetricCategory.CONFORMITY_COUNT,
  MetricCategory.AGGREGATION_COMPARE,
];

const conformityNotAllowedConditions = [
  ConformityConditionType.IS_INCREASING,
  ConformityConditionType.IS_DECREASING,
];

function isSupportedMetric(metricType, metricInfo) {
  if (metricType === MetricCategory.CONFORMITY_COUNT) {
    // conformity metrics with isIncreasing/isDecreasing conditions don't support failing records
    const metricConditions =
      metricInfo?.config?.aggregation?.conditions?.map?.(({ type }) => type) ?? [];
    return metricConditions.every(
      (conditionType) => !conformityNotAllowedConditions.includes(conditionType)
    );
  }
  return true;
}

function isSampleDataSupported(creatorInfo) {
  let sampleDataSupported = false;
  if (
    creatorInfo.type === IncidentCreatorType.FILTER &&
    !isFileSource(creatorInfo.dataSourceInfo.config.connection.type)
  ) {
    const metricType = getMetricTypeFromConfigData(creatorInfo.kpiInfo);
    if (
      sampleDataSupportedMetricTypes.includes(metricType) &&
      isSupportedMetric(metricType, creatorInfo.kpiInfo)
    ) {
      sampleDataSupported = true;
    } else if (creatorInfo.kpiInfo.config.failingRecordsSql) {
      sampleDataSupported = true;
    }
  }
  return sampleDataSupported;
}

function IncidentMainHeaderView(props) {
  const {
    incident,
    incidentCaseList,
    incidentCommentList,
    incidentCreatorInfo,
    currentTab,
    workspaceUserPermissions,
    onCurrentTabChange,
    mainViewMenuConfig,
  } = props;

  const incidentId = incident?.incidentId || "";

  const canViewSampleData = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_TABLESAMPLESPREVIEW,
  ]);
  const showSampleDataTab =
    isSampleDataSupported(incidentCreatorInfo) && canViewSampleData;
  const activityCount =
    incidentCaseList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.config.reports.length,
      0
    ) + incidentCommentList.length;

  const tabList = [
    {
      label: "Chart",
      key: IncidentMainTabKey.CHART,
      icon: <Icon component={ChartTabIcon} />,
    },
    {
      label: "Summary",
      key: IncidentMainTabKey.SUMMARY,
      icon: <Icon component={SummaryTabIcon} />,
    },
    showSampleDataTab && {
      label: "Failing Records",
      key: IncidentMainTabKey.SAMPLE_DATA,
      icon: <Icon component={SampleDataTabIcon} />,
    },
    {
      label: `Activity (${activityCount})`,
      key: IncidentMainTabKey.ACTIVITY,
      icon: <Icon component={ArrowClick} />,
    },
  ].filter(Boolean);

  const incidentBreadCrumbs = [
    ...getIncidentBreadCrumbs(incidentCreatorInfo),
    { name: `Incident ${incidentId}` },
  ];

  return (
    <div className="incident-main-header-view-container">
      <div className="incident-main-header-view-info-breadcrumbs-container">
        {incidentBreadCrumbs.map(({ name, query }, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <span className="incident-main-header-view-breadcrumbs-separator">/</span>
            )}
            <BreadCrumb name={name} query={query} />
          </React.Fragment>
        ))}
        <div className="lightup-rest-flex-item-container" />
        <IncidentMainViewMenu mainViewMenuConfig={mainViewMenuConfig} />
      </div>
      <div className="incident-main-header-view-info-row-container">
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none">
          <path
            d="M10.7144 16.4484C10.7144 16.7894 10.8498 17.1164 11.0909 17.3575C11.332 17.5986 11.659 17.7341 12 17.7341C12.341 17.7341 12.668 17.5986 12.9091 17.3575C13.1502 17.1164 13.2856 16.7894 13.2856 16.4484C13.2856 16.1075 13.1502 15.7805 12.9091 15.5394C12.668 15.2983 12.341 15.1628 12 15.1628C11.659 15.1628 11.332 15.2983 11.0909 15.5394C10.8498 15.7805 10.7144 16.1075 10.7144 16.4484ZM11.1429 8.30605V13.2343C11.1429 13.3522 11.2393 13.4486 11.3572 13.4486H12.6428C12.7607 13.4486 12.8571 13.3522 12.8571 13.2343V8.30605C12.8571 8.1882 12.7607 8.09178 12.6428 8.09178H11.3572C11.2393 8.09178 11.1429 8.1882 11.1429 8.30605ZM23.8841 20.0911L12.7419 0.806477C12.5759 0.519886 12.2893 0.37793 12 0.37793C11.7107 0.37793 11.4215 0.519886 11.2581 0.806477L0.115851 20.0911C-0.213595 20.6643 0.198882 21.3767 0.857773 21.3767H23.1422C23.8011 21.3767 24.2136 20.6643 23.8841 20.0911ZM2.89873 19.3438L12 3.58935L21.1013 19.3438H2.89873Z"
            fill="#121224"
          />
        </svg>
        {`Incident ${incidentId}`}
      </div>
      <div className="incident-main-header-navigation-container">
        <Tabs
          className="incident-main-header-tabs"
          activeKey={currentTab}
          onTabClick={onCurrentTabChange}
        >
          {tabList.map(({ key, label, icon }) => (
            <Tabs.TabPane
              tab={
                <span>
                  {icon}
                  {label}
                </span>
              }
              key={key}
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
}

export default IncidentMainHeaderView;
