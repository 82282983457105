import Papa from "papaparse";

export function download(csvStr, filename) {
  const blob = new Blob([csvStr], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.click();
  a.remove();
}

export function toCsvString(dataRows = []) {
  return Papa.unparse(dataRows, { newline: "\n" });
}
