import { connect } from "react-redux";
import { login, logout, setCurrentUserInfo } from "../../actions/user/user-action";
import LoginView from "./login";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
});

const mapDispatchToProps = (dispatch) => ({
  login: (authInfo) => dispatch(login(authInfo)),
  logout: (authInfo) => dispatch(logout(authInfo)),
  setCurrentUserInfo: (userInfo) => dispatch(setCurrentUserInfo(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
