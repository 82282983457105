import { connect } from "react-redux";
import Notification from "./notification";
import { getNotificationDetailInfo } from "../../actions/notification/notification-action";

const mapStateToProps = (state) => ({
  notificationDetailInfo: state.notificationReducer.notificationDetailInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getNotificationDetailInfo: (workspaceUuid, errorRecord) =>
    dispatch(getNotificationDetailInfo(workspaceUuid, errorRecord)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
