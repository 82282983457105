import React, { useState } from "react";
import { Input } from "antd";
import TakeoverPanel from "../../components/takeover/takeover-panel";
import { CommentIcon } from "./icons";
import Button from "../../components/button/ng-button";

import "./incident-comment-panel.scss";

function IncidentCommentContent(props) {
  const { comment, onCommentChange, disabled = false } = props;
  return (
    <div className="incident-comment-panel-content">
      <Input.TextArea
        placeholder="Comment..."
        size="large"
        rows="5"
        value={comment}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.value === (comment || "")) {
            return;
          }

          onCommentChange(e.target.value);
        }}
      />
    </div>
  );
}

export function IncidentCommentPanel(props) {
  const { closeTakeover, onSubmit, defaultValue = "", isEdit = false } = props;

  const [comment, setComment] = useState(defaultValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const cornerControls = (
    <div className="incident-comment-takeover-buttons">
      <Button outline size="large" onClick={() => closeTakeover()}>
        Cancel
      </Button>
      <Button
        primary
        size="large"
        onClick={() => {
          setIsSubmitting(true);
          onSubmit(comment);
        }}
        disabled={!comment.trim() || isSubmitting}
      >
        Submit
      </Button>
    </div>
  );

  return (
    <TakeoverPanel
      title={`${isEdit ? "Edit" : "Add"} comment`}
      titleIcon={<CommentIcon />}
      cornerControls={cornerControls}
    >
      <IncidentCommentContent
        comment={comment}
        onCommentChange={setComment}
        disabled={isSubmitting}
      />
    </TakeoverPanel>
  );
}

export default IncidentCommentPanel;
