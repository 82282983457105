import mixpanel from "mixpanel-browser";
import { organizationId } from "./analytics";
import { DAY_IN_SECONDS, HOUR_IN_SECONDS, WEEK_IN_SECONDS } from "./time";

const isTestEnvironment = process.env.NODE_ENV === "test";
const isProdEnvironment = process.env.NODE_ENV === "production";

const isTelemetryEnabled = !!window.ENABLE_TELEMETRY;
const shouldTrackEvent = (isProdEnvironment || isTestEnvironment) && isTelemetryEnabled;

export const PAGE = {
  ADMIN: "Admin",
  ADMIN_USERS: "Admin-Users",
  ADMIN_WORKSPACES: "Admin-Workspaces",
  ADMIN_CATALOGS: "Admin-Catalogs",
  WORKSPACE_ZERO_STATE: "Workspace Zero State",
  DATASOURCES: "Datasources",
  DATASOURCE_DETAIL: "Datasource Detail",
  QUERY_GOVERNANCE: "Query Governance",
  EXPLORER: "Explorer",
  EXPLORER_DATASOURCE: "Explorer-Datasource",
  EXPLORER_SCHEMA: "Explorer-Schema",
  EXPLORER_TABLE: "Explorer-Table",
  EXPLORER_TABLE_TABLE_HEALTH: "Explorer-Table-Table Health",
  EXPLORER_TABLE_DATA_PROFILE: "Explorer-Table-Data Profile",
  EXPLORER_TABLE_METADATA_METRICS: "Explorer-Table-Metadata Metrics",
  EXPLORER_TABLE_AUTO_METRICS: "Explorer-Table-Auto Metrics",
  EXPLORER_TABLE_METRIC: "Explorer-Table-Metric",
  MANAGE_SCHEMAS: "Manage Schemas",
  MANAGE_TABLES: "Manage Tables",
  MANAGE_COLUMNS: "Manage Columns",
  TABLE_CONFIGURATION: "Table Configuration",
  METRIC_DETAIL: "Metric Detail",
  MONITOR_DETAIL: "Monitor Detail",
  MONITOR_METADATA_METRICS: "Monitor Metadata Metrics",
  MONITOR_DEEP_METRICS: "Monitor Deep Metrics",
  METRICS: "Metrics",
  MONITORS: "Monitors",
  WORKSPACE_DETAIL: "Workspace Detail",
  INCIDENTS: "Incidents",
  DASHBOARDS: "Dashboards",
  ALERTS: "Alerts",
  INTEGRATIONS: "Integrations",
  SCHEDULES: "Schedules",
};

/*
  The following getPageFromUrl method uses a pattern matching strategy to identify the page based on the
  URL path and query parameters.

  The page keys are defined in an object called PAGE_PATHS, where each key represents a specific page and its
  corresponding URL pattern. The function iterates over the PAGE_PATHS object and checks if the current URL
  matches any of the defined patterns. If a match is found, the corresponding page key is returned.

  Note that the order inside the PAGE_PATHS object matters, as the first matching pattern will be used.
*/

const getPageFromUrl = () => {
  const fullUrl = window.location.href;

  const PAGE_PATHS = {
    // Admin paths
    ADMIN_USERS: ["/admin", "tabName=users"],
    ADMIN_WORKSPACES: ["/admin", "tabName=workspaces"],
    ADMIN_CATALOGS: ["/admin", "tabName=catalogs"],
    ADMIN: ["/admin"],

    // Explorer paths
    EXPLORER_TABLE_METRIC: ["/explorer/table/:tableId/metric/:metricId"],
    EXPLORER_TABLE_METADATA_METRICS: [
      "/profiler",
      "tableUuid",
      "tabKey=metadataMetrics",
    ],
    EXPLORER_TABLE_AUTO_METRICS: ["/profiler", "tableUuid", "tabKey=autoMetrics"],
    EXPLORER_TABLE_DATA_PROFILE: ["/profiler", "tableUuid", "tabKey=dataProfile"],
    EXPLORER_TABLE_TABLE_HEALTH: ["/profiler", "tableUuid", "tabKey=health"],
    EXPLORER_TABLE: ["/profiler", "tableUuid"],
    EXPLORER_SCHEMA: ["/profiler", "schemaUuid"],
    EXPLORER_DATASOURCE: ["/profiler", "dataSourceUuid"],
    EXPLORER: ["/profiler"],

    // Details
    DATASOURCE_DETAIL: ["editDataSource"],
    METRIC_DETAIL: ["editKpi"],
    MONITOR_DETAIL: ["editRule"],

    // Explorer tabs
    METRICS: ["/metrics"],
    MONITORS: ["/monitors"],
    INCIDENTS: ["/incidents"],
    DASHBOARDS: ["/dashboard"],

    // Sidebar
    DATASOURCES: ["settings/datasource"],
    QUERY_GOVERNANCE: ["settings/queryGovernance"],
    ALERTS: ["/notification"],
    INTEGRATIONS: ["settings/integration"],
    SCHEDULES: ["settings/schedule"],
    WORKSPACE_DETAIL: ["editWorkspace"],
  };

  let pageKey = null;

  // Pattern matching strategy
  for (let key in PAGE_PATHS) {
    const found = PAGE_PATHS[key].every((item) => fullUrl.includes(item));

    if (found) {
      pageKey = key;
      break;
    }
  }

  if (pageKey) {
    return PAGE[pageKey];
  }

  return "";
};

export const EVENT = {
  ACTIVATE_BULK_SCHEMA: "Activate Bulk Schema",
  ACTIVATE_COLUMN: "Activate Column",
  ACTIVATE_COLUMN_BULK: "Activate Column Bulk",
  ACTIVATE_SCHEMA: "Activate Schema",
  ACTIVATE_SCHEMA_BULK: "Activate Schema Bulk",
  ADD_CATALOG: "Add Catalog",
  ADD_DATASOURCE: "Add Datasource",
  ADD_MEMBERS: "Add Members",
  ADD_MONITOR: "Add Monitor",
  ADD_TAG_TO_DATASOURCE: "Add Tag to Datasource",
  ADD_USER: "Add User",
  ADD_USER_TO_WORKSPACE: "Add User to Workspace",
  ADD_WORKSPACE: "Add Workspace",
  ADD_WORKSPACE_TO_USER: "Add Workspace to User",
  BACK_TEST_CONNECTION: "Back Test Connection",
  CANCEL_METRIC_CREATION: "Cancel Metric Creation",
  CANCEL_MONITOR_CREATION: "Cancel Monitor Creation",
  CHANGE_USER_ROLE: "Change User Role",
  CLONE_METRIC: "Clone Metric",
  CLONE_MONITOR: "Clone Monitor",
  COLLAPSE_COLUMN_HEALTH_METRICS: "Collapse Column Health Metrics",
  COLLAPSE_TABLE_LEVEL_METRICS: "Collapse Table Level Metrics",
  CONFIGURE_DATA_PROFILE: "Configure Data Profile",
  CONFIGURE_TABLE: "Configure Table",
  CONFIGURE_TABLE_BULK: "Configure Table Bulk",
  CREATE_DATASOURCE: "Create Datasource",
  CREATE_METRIC: "Create Metric",
  CREATE_MONITOR: "Create Monitor",
  CRETE_MONITOR: "Create Monitor",
  DEACTIVATE_COLUMN: "Deactivate Column",
  DEACTIVATE_COLUMN_BULK: "Deactivate Column Bulk",
  DEACTIVATE_SCHEMA: "Deactivate Schema",
  DEACTIVATE_SCHEMA_BULK: "Deactivate Schema Bulk",
  DELETE_CATALOG: "Delete Catalog",
  DELETE_DATASOURCE: "Delete Datasource",
  DELETE_METRIC: "Delete Metric",
  DELETE_MONITOR: "Delete Monitor",
  DELETE_USER: "Delete User",
  DELETE_WORKSPACE: "Delete Workspace",
  DELETE_WORKSPACE_FROM_USER: "Delete Workspace from User",
  DISABLE_ALL_DATA_PROFILES: "Disable All Data Profiles",
  DISABLE_ALL_DEEP_AUTOMETRICS: "Disable all Deep Autometrics",
  DISABLE_ALL_METADATA_METRICS: "Disable all Metadata Metrics",
  DISABLE_DATASOURCE: "Disable Datasource",
  DISABLE_DATA_DELAY_AUTOMETRIC: "Disable Data Delay Autometric",
  DISABLE_DATA_DELAY_AUTOMETRIC_BULK: "Disable Data Delay Autometric Bulk",
  DISABLE_DATA_PROFILE: "Disable Data Profile",
  DISABLE_DATA_PROFILES: "Disable Data Profiles",
  DISABLE_DATA_PROFILE_BULK: "Disable Data Profile Bulk",
  DISABLE_DATA_VOLUME_AUTOMETRIC: "Disable Data Volume Autometric",
  DISABLE_DATA_VOLUME_AUTOMETRIC_BULK: "Disable Data Volume Autometric Bulk",
  DISABLE_DISTRIBUTION_AUTOMETRIC: "Disable Distribution Autometric",
  DISABLE_DISTRIBUTION_AUTOMETRIC_BULK: "Disable Distribution Autometric Bulk",
  DISABLE_METADATA_METRICS: "Disable Metadata Metrics",
  DISABLE_METADATA_METRICS_BULK: "Disable Metadata Metrics Bulk",
  DISABLE_METRIC: "Disable Metric",
  DISABLE_NULL_PERCENT_AUTOMETRIC: "Disable Null Percent Autometric",
  DISABLE_NULL_PERCENT_AUTOMETRIC_BULK: "Disable Null Percent Autometric Bulk",
  DISABLE_SCHEMA_ACTIVITY: "Disable Schema Activity",
  DISABLE_SCHEMA_ACTIVITY_BULK: "Disable Schema Activity Bulk",
  EDIT_DATASOURCE: "Edit Datasource",
  EDIT_DATASOURCE_CONNECTION: "Edit Datasource Connection",
  EDIT_DATASOURCE_EXPLORER: "Edit Datasource - Explorer",
  EDIT_CATALOG: "Edit Catalog",
  EDIT_METRIC: "Edit Metric",
  EDIT_MONITOR: "Edit Monitor",
  ENABLE_ALL_DATA_PROFILES: "Enable all Data Profiles",
  ENABLE_ALL_DEEP_AUTOMETRICS: "Enable all Deep Autometrics",
  ENABLE_DATASOURCE: "Enable Datasource",
  ENABLE_ALL_DISTRIBUTION_AUTOMETRIC: "Enable all Distribution Autometric",
  ENABLE_ALL_METADATA_METRICS: "Enable all Metadata Metrics",
  ENABLE_ALL_NULL_PERCENT_AUTOMETRIC: "Enable all Null Percent Autometric",
  ENABLE_DATA_DELAY: "Enable Data Delay",
  ENABLE_DATA_DELAY_AUTOMETRIC: "Enable Data Delay Autometric",
  ENABLE_DATA_DELAY_AUTOMETRIC_BULK: "Enable Data Delay Autometric Bulk",
  ENABLE_DATA_PROFILE: "Enable Data Profile",
  ENABLE_DATA_PROFILES: "Enable Data Profiles",
  ENABLE_DATA_PROFILE_BULK: "Enable Data Profile Bulk",
  ENABLE_DATA_VOLUME: "Enable Data Volume",
  ENABLE_DATA_VOLUME_AUTOMETRIC: "Enable Data Volume Autometric",
  ENABLE_DATA_VOLUME_AUTOMETRIC_BULK: "Enable Data Volume Autometric Bulk",
  ENABLE_DISTRIBUTION_AUTOMETRIC: "Enable Distribution Autometric",
  ENABLE_DISTRIBUTION_AUTOMETRIC_BULK: "Enable Distribution Autometric Bulk",
  ENABLE_METADATA_METRICS: "Enable Metadata Metrics",
  ENABLE_METADATA_METRICS_BULK: "Enable Metadata Metrics Bulk",
  ENABLE_NULL_PERCENT_AUTOMETRIC: "Enable Null Percent Autometric",
  ENABLE_NULL_PERCENT_AUTOMETRIC_BULK: "Enable Null Percent Autometric Bulk",
  ENABLE_SCHEMA_ACTIVITY: "Enable Schema Activity",
  ENABLE_SCHEMA_ACTIVITY_BULK: "Enable Schema Activity Bulk",
  EXPAND_COLUMN_HEALTH_METRICS: "Expand Column Health Metrics",
  EXPAND_INCIDENTS: "Expand Incidents",
  EXPAND_TABLE_LEVEL_METRICS: "Expand Table Level Metrics",
  FINISH_METRIC_PREVIEW: "Finish Metric Preview",
  FINISH_DEFINE_MONITOR: "Finish Define Monitor",
  GET_API_CREDENTIALS: "Get API Credentials",
  GET_SUPPORT: "Get Support",
  HIDE_MONITOR_THRESHOLD: "Hide Monitor Threshold",
  INCIDENT_CHANGE_STATUS: "Incident Change Status",
  INCIDENT_CHANGE_STATUS_BULK: "Incident Change Status Bulk",
  LOGOUT: "Logout",
  MANAGE_ALERTS_FOR_MONITOR: "Manage Alerts For Monitor",
  MANAGE_COLUMNS: "Manage Columns",
  MANAGE_SCHEMAS: "Manage Schemas",
  MANAGE_SCHEMA_BREADCRUM: "Manage Schema Breadcrumb",
  MANAGE_TABLES: "Manage Tables",
  METRIC_FINISH_CONFIGURE_METRIC: "Metric Finish Configure Metric",
  METRIC_FINISH_PREVIEW: "Metric Finish Preview",
  METRIC_INFO_FINISH: "Metric Info Finish",
  METRIC_PREVIEW: "Metric Preview",
  METRIC_STOP_VIEWING_SAMPLE_DATA: "Metric Stop Viewing Sample Data",
  METRIC_VIEW_SAMPLE_DATA: "Metric View Sample Data",
  MONITOR_DEEP_METRICS: "Monitor Deep Metrics",
  MONITOR_GENERATE_PREVIEW: "Monitor Generate Preview",
  MONITOR_METADATA_METRICS: "Monitor Metadata Metrics",
  MONITOR_PREVIEW: "Monitor Preview",
  MUTE_CHANNEL: "Mute Channel",
  OPEN_DOCUMENTATION: "Open Documentation",
  DATASOURCE: "Pause Datasource",
  PAUSE_METRIC: "Pause Metric",
  PAUSE_MONITOR: "Pause Monitor",
  PREVIEW_MONITOR: "Preview Monitor",
  REMOVE_USER: "Remove User",
  RESUME_DATASOURCE: "Resume Datasource",
  RESUME_METRIC: "Resume Metric",
  RESUME_MONITOR: "Resume Monitor",
  RUN_MANUAL_SCAN: "Run Manual Scan",
  SAVE_AND_TRAIN_MONITOR: "Save and Train Monitor",
  SAVE_DATASOURCE: "Save Datasource",
  SAVE_METRIC: "Save Metric",
  SAVE_MONITOR: "Save Monitor",
  SAVE_MONITORS: "Save Monitors",
  SAVE_QUERY_GOVERNANCE: "Save Query Governance",
  SAVE_TABLE_CONFIGURATION: "Save Table Configuration",
  SAVE_TABLE_CONFIGURATION_BULK: "Save Table Configuration Bulk",
  SAVE_WORKSPACE: "Save Workspace",
  SELECT_ADMIN_TAB: "Select Admin Tab",
  SELECT_CONFIGURATION_TAB: "Select Configuration Tab",
  SELECT_SYSTEM_EVENTS_TAB: "Select System Events Tab",
  SELECT_AUTO_METRICS_TAB: "Select Auto Metrics Tab",
  SELECT_CATALOGS_TAB: "Select Catalogs Tab",
  SELECT_COLUMN: "Select Column",
  SELECT_DASHBOARD_TAB: "Select Dashboard Tab",
  SELECT_DATASOURCE: "Select Datasource",
  SELECT_DATASOURCES_TAB: "Select Datasources Tab",
  SELECT_DATASOURCE_BREADCRUMB: "Select Datasource Breadcrumb",
  SELECT_DATA_PROFILE_TAB: "Select Data Profile Tab",
  SELECT_EXPLORER_TAB: "Select Explorer Tab",
  SELECT_INCIDENTS_TAB: "Select Incidents Tab",
  SELECT_INTEGRATIONS_TAB: "Select Integrations Tab",
  SELECT_METADATA_METRICS_TAB: "Select Metadata Metrics Tab",
  SELECT_METRICS_TAB: "Select Metrics Tab",
  SELECT_METRIC_UNDER_COLUMN: "Select Metric Under Column",
  SELECT_METRIC_UNDER_DATASOURCE: "Select Metric Under Datasource",
  SELECT_METRIC_UNDER_SCHEMA: "Select Metric Under Schema",
  SELECT_METRIC_UNDER_TABLE: "Select Metric Under Table",
  SELECT_MONITORS_TAB: "Select Monitors Tab",
  SELECT_QUERY_GOVERNANCE_TAB: "Select Query Governance Tab",
  SELECT_SCHEDULES_TAB: "Select Schedules Tab",
  SELECT_SCHEMA: "Select Schema",
  SELECT_SCHEMA_BREADCRUMB: "Select Schema Breadcrumb",
  SELECT_SCHEMA_BULK: "Select Schema Bulk",
  SELECT_TABLE: "Select Table",
  SELECT_TABLE_BREADCRUMB: "Select Table Breadcrumb",
  SELECT_TABLE_HEALTH_TAB: "Select Table Health Tab",
  SELECT_TABLE_IN_EXPLORER: "Select Table In Explorer",
  SELECT_USERS_TAB: "Select Users Tab",
  SELECT_WORKSPACE: "Select Workspace",
  SELECT_WORKSPACES_TAB: "Select Workspaces Tab",
  SELECT_WORKSPACE_TAB: "Select Workspace Tab",
  STOP_PREVIEW_METRIC: "Stop Preview Metric",
  STOP_VIEWING_MONITOR: "Stop Viewing Monitor",
  TEST_CONNECTION: "Test Connection",
  TRIGGER_METRIC: "Trigger Metric",
  UPDATE_DATA_PROFILE: "Update Data Profile",
  USER_LOGIN: "User Login",
  UNMUTE_CHANNEL: "Unmute Channel",
  VIEW_INCIDENT: "View Incident",
  VIEW_COLUMN_HEALTH_BY_COLUMN: "View Column Health By Column",
  VIEW_COLUMN_HEALTH_BY_METRIC: "View Column Health By Metric",
  VIEW_DATASOURCE_IN_EXPLORER: "View Datasource in Explorer",
  VIEW_EVENT_DETAIL: "View Event Detail",
  VIEW_METRIC: "View Metric",
  VIEW_METRIC_IN_EXPLORER: "View Metric In Explorer",
  VIEW_METRIC_QUERY_HISTORY: "View Metric Query History",
  VIEW_MONITOR: "View Monitor",
  VIEW_MONITOR_THRESHOLD: "View Monitor Threshold",
  VIEW_QUERY_GOVERNANCE: "View Query Governance",
};

export const getSystemEventDetailProps = (systemEvent = {}) => {
  return {
    level: systemEvent?.level || "",
    object_type: systemEvent?.objectType || "",
    datasource_id: systemEvent?.dataSourceUuid || "",
  };
};

export const getMetricDetailProps = (metric = {}, datasource = {}) => {
  return {
    status: metric?.config?.isLive ? "live" : "paused",
    table_id: metric?.config?.table?.tableUuid || "",
    metric_id: metric?.metadata?.uuid || "",
    schema_id: metric?.config?.table?.schemaUuid || "",
    metric_type: metric?.config?.aggregation?.type || "",
    workspace_id:
      metric?.metadata?.workspaceId || datasource?.metadata?.workspaceId || "",
    datasource_id: datasource?.metadata?.uuid || "",
    connector_type: datasource?.config?.connection?.type || "",
  };
};

export const getMonitorDetailProps = (monitor = {}, metric = {}, datasource = {}) => {
  return {
    monitor_id: monitor?.metadata?.uuid || "",
    workspace_id: monitor?.metadata?.workspaceId || "",
    monitor_type: monitor?.config?.symptom?.type || "",
    metric_id: monitor?.config?.metrics?.join(", ") || "",
    metric_type: metric?.config?.aggregation?.type || "",
    connector_type: datasource?.config?.connection?.type || "",
    table_id: metric?.config?.table?.tableUuid || "",
    schema_id: metric?.config?.table?.schemaUuid || "",
    datasource_id: datasource?.metadata?.uuid || "",
  };
};

export const getColumnDetailProps = (column = {}, schema = {}, datasource = {}) => {
  return {
    column_id: column?.uuid || "",
    table_id: column?.tableUuid || "",
    schema_id: schema?.uuid || "",
    workspace_id: datasource?.metadata?.workspaceId || "",
    datasource_id: column?.sourceUuid || "",
    connector_type: datasource?.config?.connection?.type || "",
    distribution_enabled: column?.profilerConfig?.categoricalDistribution?.enabled
      ? "Y"
      : "N",
    null_percent_enabled: column?.profilerConfig?.nullPercent?.enabled ? "Y" : "N",
  };
};

export const getTableDetailProps = (table = {}, schema = {}, datasource = {}) => {
  return {
    table_id: table?.uuid || "",
    workspace_id: datasource?.metadata?.workspaceId || "",
    schema_id: schema?.uuid || "",
    datasource_id: table?.sourceUuid || "",
    connector_type: datasource?.config?.connection?.type || "",
    data_delay_enabled: table?.profilerConfig?.dataDelay?.enabled ? "Y" : "N",
    data_volume_enabled: table?.profilerConfig?.volume?.enabled ? "Y" : "N",
  };
};

export const getSchemaDetailProps = (schema = {}, datasource = {}) => {
  return {
    schema_id: schema?.uuid || "",
    workspace_id: datasource?.metadata?.workspaceId || "",
    datasource_id: datasource?.metadata?.uuid || "",
    connector_type: datasource?.config?.connection?.type || "",
  };
};

export const getDatasourceDetailProps = (datasource = {}) => {
  const getScanIntervalLabel = (scanInterval) => {
    switch (scanInterval) {
      case HOUR_IN_SECONDS:
        return "hourly";

      case DAY_IN_SECONDS:
        return "daily";

      case WEEK_IN_SECONDS:
        return "weekly";

      default:
        return "";
    }
  };

  return {
    workspace_id: datasource?.metadata?.workspaceId || "",
    datasource_id: datasource?.metadata?.uuid || "",
    connector_type: datasource?.config?.connection?.type || "",
    schema_scan_frequency: getScanIntervalLabel(
      datasource?.config?.profiler?.scanInterval || ""
    ),
  };
};

export const initializeTracking = (id) => {
  if (shouldTrackEvent)
    try {
      mixpanel.init("28e59cf16b70c5b130220587c6a67536", {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });

      if (id) {
        identifyTrackingUser(id);
      }

      const orgId = organizationId();

      if (orgId) mixpanel.register({ organizationId: orgId });
    } catch (error) {
      console.error("Error initializing the tracking tool.", error);
    }
};

export const identifyTrackingUser = (id) => {
  if (shouldTrackEvent)
    try {
      mixpanel.identify(id);
    } catch (error) {
      console.error("Error identifying the tracking user.", error);
    }
};

export const trackEvent = (name, props = {}) => {
  if (shouldTrackEvent)
    try {
      const hasPageProps = props?.page;
      const propsWithPage = hasPageProps ? props : { ...props, page: getPageFromUrl() };

      mixpanel.track(name, propsWithPage);
    } catch (error) {
      console.error("Error tracking the event.", error);
    }
};
