import React from "react";
import {
  isValueError,
  validateData,
  ValidationMethodNameConst,
} from "../../../../utils/validate";
import LegacyModalDialogAdapter from "../../../legacy-adapter/legacy-modal-dialog-adapter";
import ModalConfirmationButtons from "../../../modal-dialog/modal-confirmation-buttons";
import { LabeledDatePicker } from "../../../labeled-control/labeled-control";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import { ModalFieldRows } from "../../../modal-dialog/modal-field-group";

import "./rule-training-period-dialog.scss";

function RuleTrainingPeriodDialogBodyComponent(props) {
  const {
    value: { trainingPeriod = {} },
    errors,
    onChange,
  } = props;

  const { startTs, endTs } = trainingPeriod;
  function onTrainingStartTimeChange(newStartTime) {
    const newStartTimestamp = getUnixTime(newStartTime);
    if (newStartTimestamp !== startTs) {
      onChange({
        trainingPeriod: {
          startTs: newStartTimestamp,
          endTs,
        },
      });
    }
  }

  function onTrainingEndTimeChange(newEndTime) {
    const newEndTimestamp = getUnixTime(newEndTime);
    if (newEndTimestamp !== endTs) {
      onChange({
        trainingPeriod: {
          startTs,
          endTs: newEndTimestamp,
        },
      });
    }
  }

  const startTimeHasError =
    !!errors["learningPeriod.startTimestamp"] ||
    !!errors["learningPeriod.startTimestamp.endTimestamp"];

  const endTimeHasError = !!errors["learningPeriod.endTimestamp"];

  return (
    <div className="rule-training-period-dialog-content">
      <ModalFieldRows>
        <LabeledDatePicker
          label="Start Time"
          staticLabel
          showTime
          value={fromUnixTime(startTs)}
          status={startTimeHasError ? "error" : null}
          onChange={onTrainingStartTimeChange}
        />
        <LabeledDatePicker
          label="End Time"
          staticLabel
          showTime
          value={fromUnixTime(endTs)}
          status={endTimeHasError ? "error" : null}
          onChange={onTrainingEndTimeChange}
        />
      </ModalFieldRows>
    </div>
  );
}

function RuleTrainingPeriodDialogFooterComponent(props) {
  const { onCancelClicked, onApplyClicked, error } = props;

  return (
    <ModalConfirmationButtons
      onOkClick={onApplyClicked}
      onCancelClick={onCancelClicked}
      okButtonProps={{ disabled: Boolean(error) }}
    />
  );
}

function RuleTrainingPeriodDialogComponent(props) {
  const { value, isAdd = false, onApply, modalIsOpen, setIsOpen } = props;

  const [trainingTimeSettingValue, setTrainingTimeSettingValue] = React.useState(value);
  const [valueErrors, setValueErrors] = React.useState({});

  const dataValidationModal = {
    learningPeriod: {
      validators: [
        {
          path: "startTimestamp",
          errorPath: "learningPeriod.startTimestamp",
          method: ValidationMethodNameConst.TIME_NO_LATE_THAN_NOW,
        },
        {
          path: ["startTimestamp", "endTimestamp"],
          errorPath: "learningPeriod.startTimestamp.endTimestamp",
          method: ValidationMethodNameConst.START_TIME_NO_LATE_THAN_END_TIME,
        },
        {
          path: "endTimestamp",
          errorPath: "learningPeriod.endTimestamp",
          method: ValidationMethodNameConst.TIME_NO_LATE_THAN_NOW,
        },
      ],
    },
  };

  function onCancelClicked() {
    setTrainingTimeSettingValue(value);
    setValueErrors({});
    setIsOpen(false);
  }

  function onApplyClicked() {
    if (onApply) {
      onApply(trainingTimeSettingValue);
    }
    setIsOpen(false);
  }

  function updateTrainingTimeSettingValue(newValue) {
    setTrainingTimeSettingValue(newValue);
    const newErrors = validateData(newValue, dataValidationModal);
    setValueErrors({ ...valueErrors, ...newErrors });
  }

  return (
    <LegacyModalDialogAdapter
      title={`${isAdd ? "Add" : "Edit"} training period`}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"rule-training-period-dialog-container"}
      triggerComponent={null}
      contentComponent={
        <RuleTrainingPeriodDialogBodyComponent
          value={trainingTimeSettingValue}
          errors={valueErrors}
          onChange={updateTrainingTimeSettingValue}
        />
      }
      footerComponent={
        <RuleTrainingPeriodDialogFooterComponent
          onCancelClicked={onCancelClicked}
          onApplyClicked={onApplyClicked}
          error={isValueError(valueErrors)}
        />
      }
    />
  );
}

export default RuleTrainingPeriodDialogComponent;
