import axios from "axios";
import { toast } from "../components/toast/toast";
import { NotificationTypeConst } from "./enums";
import logger from "./logger";

const axiosInstance = axios.create({
  baseURL: "/api/v0/",
  responseType: "json",
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

axiosInstance.interceptors.request.use(function (request) {
  logger.request(request);
  return request;
});

axiosInstance.interceptors.response.use(
  function (response) {
    logger.response(response);
    return response;
  },
  function (error) {
    logger.error(error);
    let isAutoToastDisabled = false;
    if (error && error.config && error.config.hasOwnProperty("disableToast")) {
      if (typeof error.config.disableToast === "boolean") {
        isAutoToastDisabled = error.config.disableToast;
      } else if (typeof error.config.disableToast === "function") {
        isAutoToastDisabled = error.config.disableToast();
      }
    } else if (error.message === "canceled" || axios.isCancel(error)) {
      // If request is canceled intentionally, disable toast message.
      isAutoToastDisabled = true;
    }

    if (error && !isAutoToastDisabled) {
      let errorString;
      // Refer to https://axios-http.com/docs/handling_errors
      if (error.response) {
        if (error.response.data) {
          errorString =
            error.response.data?.error ?? JSON.stringify(error.response.data);
        } else if (error.response.status) {
          errorString = `Unknown error with ${error.response.status}`;
        } else {
          errorString = "Unknown response error";
        }
      } else if (error.request) {
        console.log(error.request);
        // Request is sent but no response is received.
        errorString = "No response is received";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorString = `Setting up request failed due to ${error.message}`;
      }

      toast(errorString, NotificationTypeConst.FAILURE);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

export function createCancelTokenSource() {
  return axios.CancelToken.source();
}
