import * as types from "./notification-action-types";
import {
  getEventPromise,
  getEventListPromise,
  getEventMonitorListPromise,
  updateEventMonitorItemPromise,
} from "../../rest/event";
import { getKpiPromise } from "../../rest/kpi";
import { getRulePromise } from "../../rest/rule";
import { getIntegrationPromise } from "../../rest/integration";

function setNotificationList(notificationList) {
  return { type: types.SET_NOTIFICATION_LIST, notificationList };
}

function setNotificationDetailInfo(notificationDetailInfo) {
  return { type: types.SET_NOTIFICATION_DETAIL_INFO, notificationDetailInfo };
}

function setNotificationMonitorList(notificationMonitorList) {
  return { type: types.SET_NOTIFICATION_MONITOR_LIST, notificationMonitorList };
}

export function getNotificationList(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    dispatch(
      setNotificationList({
        total: 0,
        loading: true,
        data: [],
      })
    );

    getEventListPromise(workspaceUuid, queryObject)
      .then(function (notificationList) {
        dispatch(
          setNotificationList({
            total: notificationList.metadata.total,
            loading: false,
            data: notificationList.data,
          })
        );
      })
      .catch(function (err) {
        dispatch(
          setNotificationList({
            total: 0,
            loading: false,
            data: [],
          })
        );
        console.log(`Fail to get notification items for ${err}`);
      });
  };
}

export function getNotificationDetailInfo(workspaceUuid, eventUuid) {
  return (dispatch, getState) => {
    dispatch(
      setNotificationDetailInfo({
        loading: true,
        data: null,
      })
    );

    getEventPromise(workspaceUuid, eventUuid)
      .then((eventRecord) => {
        const { metricUuid, filterUuid: monitorUuid, integrationUuid } = eventRecord;

        Promise.allSettled([
          metricUuid
            ? getKpiPromise(workspaceUuid, metricUuid, { disableToast: true })
            : Promise.resolve(null),
          monitorUuid
            ? getRulePromise(workspaceUuid, monitorUuid, { disableToast: true })
            : Promise.resolve(null),
          integrationUuid
            ? getIntegrationPromise(workspaceUuid, integrationUuid, {
                disableToast: true,
              })
            : Promise.resolve(null),
        ])
          .then(function (allResponse) {
            const [metricResponse, monitorResponse, integrationResponse] = allResponse;
            dispatch(
              setNotificationDetailInfo({
                loading: false,
                data: {
                  eventRecord,
                  metric:
                    metricResponse.status === "fulfilled" ? metricResponse.value : null,
                  monitor:
                    monitorResponse.status === "fulfilled"
                      ? monitorResponse.value
                      : null,
                  integration:
                    integrationResponse.status === "fulfilled"
                      ? integrationResponse.value
                      : null,
                },
              })
            );
          })
          .catch(function (err) {
            dispatch(
              setNotificationDetailInfo({
                loading: false,
                data: null,
              })
            );
            console.log(`Fail to get notification items for ${err}`);
          });
      })
      .catch(function (err) {
        dispatch(
          setNotificationDetailInfo({
            loading: false,
            data: null,
          })
        );
        console.log(`Fail to get notification item ${eventUuid} for ${err}`);
      });
  };
}

export function getNotificationMonitorList(workspaceUuid, opts = {}) {
  return (dispatch, getState) => {
    const { isRefresh = false } = opts;
    !isRefresh &&
      dispatch(
        setNotificationMonitorList({
          loading: true,
          data: [],
        })
      );

    getEventMonitorListPromise(workspaceUuid)
      .then(function (notificationMonitorList) {
        dispatch(
          setNotificationMonitorList({
            loading: false,
            data: notificationMonitorList.data || [],
          })
        );
      })
      .catch(function (err) {
        dispatch(
          !isRefresh &&
            setNotificationMonitorList({
              loading: false,
              data: [],
            })
        );
        console.log(`Fail to get notification monitor items for ${err}`);
      });
  };
}

export function updateNotificationMonitorItem(workspaceUuid, uuid, eventMonitorItem) {
  return (dispatch, getState) => {
    updateEventMonitorItemPromise(workspaceUuid, uuid, eventMonitorItem)
      .then(function () {
        dispatch(getNotificationMonitorList(workspaceUuid, { isRefresh: true }));
      })
      .catch(function (err) {
        console.log(`Fail to update notification monitor items for ${err}`);
      });
  };
}
