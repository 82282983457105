import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Routes from "./routes";
import { getUserConfig, getUserInfo } from "../actions/user/user-action";
import { ToastContainer } from "../components/toast/toast";
import { initializeTracking } from "../utils/telemetry";
import { initializeIntercom } from "../utils/intercom";
import { initializePendo } from "../utils/pendo";

export function postUserInfoLoadedEffects(userInfo) {
  if (window.ENABLE_TELEMETRY) {
    initializeTracking(userInfo.externalId);
  }

  if (window.ENABLE_INTERCOM) {
    initializeIntercom(userInfo);
  }

  if (window.ENABLE_PENDO) {
    initializePendo(userInfo);
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserInfoSent: false,
    };
  }

  componentDidMount() {
    const hostname = window.location.hostname;
    const hostNameInfoArray = hostname.split(".");
    if (hostNameInfoArray.length === 4) {
      const customName = hostNameInfoArray[1];
      console.log(`Updating app title to ${customName}`);
      document.title = `Lightup | ${customName}`;
    }
    console.log("Start to load user config information");
    this.props.getUserConfig();
  }

  static getDerivedStateFromProps(props, state) {
    const { isConfigInitialized, isCurrentUserInitialized } = props.userInfo;
    if (isConfigInitialized && !isCurrentUserInitialized && !state.isUserInfoSent) {
      props.getUserInfo().then((userInfo) => {
        if (userInfo) {
          postUserInfoLoadedEffects(userInfo);
        }
      });

      return {
        isUserInfoSent: true,
      };
    }

    return null;
  }

  render() {
    const { isConfigInitialized, isCurrentUserInitialized } = this.props.userInfo;
    if (!isConfigInitialized || !isCurrentUserInitialized) {
      return null;
    }

    console.log("User information is loaded");
    return (
      <>
        <Routes
          userInfo={this.props.userInfo}
          sidebarCollapsed={this.props.sidebarCollapsed}
        />
        <ToastContainer />
        <ReactTooltip clickable={true} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch(getUserInfo()),
  getUserConfig: () => dispatch(getUserConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
