import React from "react";
import ConfigCard from "../../../config-card";
import {
  AggregationTypeConfigItem,
  DataAssetCards,
  FieldRow,
  PercentileFractionConfigItem,
} from "../../../fields/";
import { getColumnTypeCategory } from "../../../../../utils/column";
import {
  AggregationType,
  DefaultAggregationMissingValueFilling,
  MetricDataAssetType,
  TableColumnTypeCategory,
  isFileSource,
} from "../../../../../utils/enums";
import { getAggregationFunctionNameOptions } from "../../../utils";
import MetricFailingRecordsQueryConfigItem from "../../../fields/metric-failing-records-query-config-item";
import DataAssetSelect from "../../../fields/data-asset-select";
import AddTags from "../../../fields/add-tags";
import ConfigureMetric from "../../../fields/configure-metric";
import WhereClause from "../../../fields/where-clause";

const defaultAggregationTypeParams = {
  [AggregationType.PERCENTILE]: {
    fraction: 0.8,
  },
};

function getMissingValueFilling(aggregationType) {
  const missingValueFilling = DefaultAggregationMissingValueFilling[aggregationType];
  return missingValueFilling ? { missingValueFilling } : {};
}

function AggregationMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    onDataSourceChanged,
    tableList,
    schemaList,
    onTableChanged,
    columnList: { data: columnList },
    columnValueList,
    openWorkspaceTakeover,
    closeTakeover,
    partitionSampleData,
    getPartitionSampleData,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult = { loading: false },
    resetKPIStatus,
    tagList,
    waffle,
    disabled,
    currentDataSource,
  } = props;

  const isEditMode = !configData.config.inheritTableSettings;
  const isStreaming = configData.config.isStreaming;
  const isNumericColumn =
    configData.config.valueColumns?.length > 0 &&
    columnList?.length > 0 &&
    getColumnTypeCategory(
      columnList.find(
        ({ uuid }) => configData.config.valueColumns[0].columnUuid === uuid
      )
    ) === TableColumnTypeCategory.NUMERIC;
  const isFileDataSource = isFileSource(currentDataSource?.config?.connection?.type);

  return (
    <DataAssetCards>
      <DataAssetSelect
        dataAssetType={MetricDataAssetType.COLUMN}
        configData={configData}
        dataSourceList={dataSourceList}
        schemaList={schemaList}
        tableList={tableList}
        columnList={columnList}
        isStreaming={isStreaming}
        resetKPIStatus={resetKPIStatus}
        onConfigDataChange={onConfigDataChange}
        onDataSourceChanged={onDataSourceChanged}
        onTableChanged={onTableChanged}
        onColumnChange={(_newColumnUuid, currentColumn) => {
          const newIsNumericalColumn =
            getColumnTypeCategory(currentColumn) === TableColumnTypeCategory.NUMERIC;
          const aggregation =
            newIsNumericalColumn === isNumericColumn
              ? configData.config.aggregation
              : { ...configData.config.aggregation, type: null };

          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              aggregation,
              valueColumns: [
                {
                  columnName: currentColumn.columnName,
                  columnUuid: currentColumn.uuid,
                },
              ],
            },
          });
        }}
        disabled={disabled}
      />
      <ConfigCard title="Aggregation function">
        <FieldRow>
          <AggregationTypeConfigItem
            disabled={disabled}
            options={getAggregationFunctionNameOptions(isNumericColumn)}
            value={configData.config?.aggregation?.type}
            onChange={(newType) => {
              const oldAggregation = configData.config?.aggregation || {};
              const extraConfig = defaultAggregationTypeParams[newType] || {};
              const missingValueFilling = getMissingValueFilling(newType);

              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  aggregation: {
                    ...oldAggregation,
                    type: newType,
                    ...extraConfig,
                  },
                  ...missingValueFilling,
                },
              });
            }}
          />
          {configData.config?.aggregation?.type === AggregationType.PERCENTILE && (
            <PercentileFractionConfigItem
              disabled={disabled}
              value={configData.config?.aggregation?.fraction}
              onChange={(newFraction) => {
                const oldAggregation = configData.config?.aggregation || {};
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    aggregation: {
                      ...oldAggregation,
                      fraction: newFraction,
                    },
                  },
                });
              }}
            />
          )}
        </FieldRow>
      </ConfigCard>
      {!isFileDataSource && (
        <WhereClause
          configData={configData}
          columnList={columnList}
          onConfigDataChange={onConfigDataChange}
          schemaList={schemaList}
          tableList={tableList}
          disabled={disabled}
        />
      )}
      <ConfigureMetric
        isValidConfig={isValidConfig}
        enableSeasonality={true}
        enableSlicing={true}
        allowOverride={true}
        configData={configData}
        isStreaming={isStreaming}
        dataSourceList={dataSourceList}
        tableList={tableList}
        columnList={columnList}
        columnValueList={columnValueList}
        isEditMode={isEditMode}
        onConfigDataChange={onConfigDataChange}
        openWorkspaceTakeover={openWorkspaceTakeover}
        closeTakeover={closeTakeover}
        partitionSampleData={partitionSampleData}
        getPartitionSampleData={getPartitionSampleData}
        waffle={waffle}
        enableMissingValueFilling={true}
        disabled={disabled}
      />
      {!isFileDataSource && (
        <MetricFailingRecordsQueryConfigItem
          disabled={disabled}
          configData={configData}
          value={configData.config.failingRecordsSql}
          validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
          validateFailingRecordsSqlResult={validateFailingRecordsSqlResult}
          onChange={(newFailingRecordsSql) => {
            onConfigDataChange({
              ...configData,
              config: {
                ...configData.config,
                failingRecordsSql: newFailingRecordsSql,
              },
            });
          }}
        />
      )}
      <AddTags
        disabled={disabled}
        configData={configData}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
      />
    </DataAssetCards>
  );
}

AggregationMetricDataAssetConfig.assetMeta = {
  title: "Select data asset",
  createDescription:
    "Create your metric by selecting the type, naming it, picking a data asset, and configuring the settings",
  editDescription:
    "Edit your metric by naming it, picking a data asset, and configuring the settings",
};

export default AggregationMetricDataAssetConfig;
