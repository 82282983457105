import React from "react";
import { AggregationTypeConfigItem } from "../../fields/";
import { getAggregationFunctionNameOptions } from "../../utils";
import SummaryViewSectionTitle from "../summary-view-section-title";
import ConfigureMetricSummary from "../configure-metric-summary";
import WhereConditionsSummary from "../where-conditions-summary";

function AggregationMetricConfigDataAssetSummaryCardContent(props) {
  const { columnList, configData, dataSourceList } = props;
  return (
    <>
      <SummaryViewSectionTitle title="Function" />
      <AggregationTypeConfigItem
        options={getAggregationFunctionNameOptions(true)}
        value={configData.config?.aggregation?.type}
        extraLabel={
          typeof configData.config?.aggregation?.fraction === "number"
            ? configData.config?.aggregation?.fraction
            : ""
        }
        isEdit={false}
      />

      <WhereConditionsSummary configData={configData} />

      <ConfigureMetricSummary
        columnList={columnList}
        configData={configData}
        dataSourceList={dataSourceList}
        enableSeasonality={true}
        enableMissingValueFilling={true}
      />
    </>
  );
}

export default AggregationMetricConfigDataAssetSummaryCardContent;
