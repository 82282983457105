import { EditOutlined } from "@ant-design/icons";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import { default as NgDropdownMenu } from "../../components/ng-dropdown-menu";
import { AppPermissions } from "../../utils/permissions";
import { EVENT, trackEvent, getMetricDetailProps } from "../../utils/telemetry";
import { URIPath, getURIInstance, hasPermission } from "../../utils/uri-path";
import { MetricActions } from "../profiler/profiler-metric-actions-menu";
import { ViewInExplorerIcon } from "../profiler/menu-icons";
import { explorerMetricUrl } from "../profiler/utils";

function NotificationMetricActionsMenu(props) {
  const {
    trigger,
    history,
    metric,
    workspaceUuid,
    workspaceUserPermissions,
    actions = [],
    loading = false,
    getPopupContainer,
  } = props;

  const viewMetricPermissions = [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
  ];
  const modifyMetricPermissions = [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
  ];
  const canModifyMetric = hasPermission(
    workspaceUserPermissions,
    modifyMetricPermissions
  );
  const viewMetricDataPermissions = [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
  ];
  const canViewMetric = hasPermission(workspaceUserPermissions, viewMetricPermissions);
  console.log({ metric });
  const menuConfig = [
    {
      action: MetricActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewMetricPermissions,
      item: {
        label: canModifyMetric ? "Edit Metric" : "View",
        icon: <EditOutlined />,
        onClick: () => {
          const nextUrl = getURIInstance(URIPath.EDIT_METRIC, {
            workspaceUuid,
            id: metric.metadata.uuid,
          });

          trackEvent(canModifyMetric ? EVENT.EDIT_METRIC : EVENT.VIEW_METRIC, {
            ...getMetricDetailProps(metric),
          });

          history.push(nextUrl);
        },
        disabled: !canViewMetric,
      },
    },
    {
      action: MetricActions.VIEW_IN_EXPLORER,
      supportsMultiple: false,
      permissions: viewMetricDataPermissions,
      item: {
        label: "View in explorer",
        icon: <ViewInExplorerIcon />,
        onClick: () => {
          trackEvent(EVENT.VIEW_METRIC_IN_EXPLORER, {
            ...getMetricDetailProps(metric),
          });

          const nextUrl = explorerMetricUrl(metric);
          history.push(nextUrl);
        },
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    [metric],
    actions,
    workspaceUserPermissions
  );

  return (
    <NgDropdownMenu
      trigger={trigger}
      menuItems={menuItems}
      buttonProps={{ disabled: loading }}
      getPopupContainer={getPopupContainer}
      position="bottomLeft"
    />
  );
}

export default NotificationMetricActionsMenu;
