import React, { useEffect, useState } from "react";
import { LabeledSelect, LabeledInput } from "../labeled-control/labeled-control";
import NgDropdownMenu from "../ng-dropdown-menu";
import NgButton from "../button/ng-button";
import { CollectionModeType } from "../../utils/enums";
import { TimezoneOptions } from "../../utils/options";
import { getStringFromTimeStamp } from "../../utils/time";
import { getMetricCustomScheduleUpcomingRunPromise } from "../../rest/metrics";

import "./index.scss";

function NextScheduleListDropdown({ value }) {
  const { data } = value;
  const displayedValues = data
    .slice(0, 5)
    .map((currentTimestamp) => getStringFromTimeStamp(currentTimestamp));

  const [triggerValue, ...popUpValues] = displayedValues;
  if (!triggerValue) {
    return null;
  }

  const baseElement = (
    <div className="next-schedule-list-container">
      <span className="next-schedule-list-title-container">next collection:</span>
      {triggerValue}
    </div>
  );

  if (!popUpValues) {
    return baseElement;
  }

  const menuItems = popUpValues.map((popupValue) => ({ label: popupValue }));
  return <NgDropdownMenu trigger={baseElement} menuItems={menuItems} on="hover" />;
}

export function CustomScheduleCollectionConfig({ workspaceUuid, value, onChange }) {
  const [nextScheduleList, setNextScheduleList] = useState({
    loading: false,
    data: [],
  });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      if (
        value.type === CollectionModeType.CUSTOM_SCHEDULED &&
        value.crontabExpression &&
        value.timezone
      ) {
        setNextScheduleList({ loading: true, data: [] });
        getMetricCustomScheduleUpcomingRunPromise(workspaceUuid, value)
          .then((scheduleList) =>
            setNextScheduleList({ loading: false, data: scheduleList })
          )
          .catch((err) => setNextScheduleList({ loading: false, data: [] }));
      }
    }

    return () => {
      setNextScheduleList({ loading: false, data: [] });
    };
  }, [workspaceUuid, value, isLoaded, setNextScheduleList]);

  if (value.type !== CollectionModeType.CUSTOM_SCHEDULED) {
    return null;
  }

  const { crontabExpression, timezone } = value;
  return (
    <div className="custom-schedule-collection-config-container">
      <div className="custom-schedule-collection-config-title-container">
        Custom Schedule
      </div>
      <div className="custom-schedule-collection-config-description-container">
        Specify a Cron expression and timezone that defines your schedule
      </div>
      <div className="custom-schedule-collection-content-container">
        <div>
          <LabeledInput
            label="Cron input"
            value={crontabExpression}
            onChange={(e) => {
              if (e.target.value !== crontabExpression) {
                onChange({ ...value, crontabExpression: e.target.value });
              }
            }}
          />
          <div className="custom-schedule-collection-content-cron-description-container">
            <a target="_blank" rel="noreferrer" href="https://crontab.guru/">
              How to use cron
            </a>
            <NextScheduleListDropdown value={nextScheduleList} />
          </div>
        </div>
        <LabeledSelect
          label="Timezone"
          showSearch
          staticLabel
          options={TimezoneOptions}
          value={timezone}
          onChange={(newTimezone) => {
            if (timezone !== newTimezone) {
              onChange({ ...value, timezone: newTimezone });
            }
          }}
        />
      </div>
      <div className="custom-schedule-collection-content-action-container">
        <NgButton
          primary
          size="large"
          disabled={!crontabExpression || !timezone}
          onClick={() => {
            setNextScheduleList({ loading: true, data: [] });
            getMetricCustomScheduleUpcomingRunPromise(workspaceUuid, value)
              .then((scheduleList) =>
                setNextScheduleList({ loading: false, data: scheduleList })
              )
              .catch((err) => setNextScheduleList({ loading: false, data: [] }));
          }}
          width={"140px"}
        >
          Validate
        </NgButton>
      </div>
    </div>
  );
}
