import { fnSorter } from "./sort";

export function getLastTimeMissingValueFromDataList(missingValueDataList) {
  let missingValue;
  if (missingValueDataList.length > 0) {
    missingValue = missingValueDataList[missingValueDataList.length - 1].value;
    if (missingValue > 0) {
      missingValue = +missingValue.toFixed(2);
    }
  } else {
    missingValue = "-";
  }
  return missingValue;
}

export function getLastTimePercentileFromDataList(numericalDistributionDataList) {
  let percentileData = [];
  if (
    numericalDistributionDataList &&
    numericalDistributionDataList.signalData &&
    numericalDistributionDataList.signalData.length > 0
  ) {
    const lastValue =
      numericalDistributionDataList.signalData[
        numericalDistributionDataList.signalData.length - 1
      ].value;
    percentileData = [
      { x: "P0", y: lastValue.min },
      { x: "P25", y: lastValue.q1 },
      { x: "P50", y: lastValue.mean },
      { x: "P75", y: lastValue.q3 },
      { x: "P100", y: lastValue.max },
    ];
  }

  return percentileData;
}

export function getLastTimeDataDistributionValueFromDataList(
  categoricalDistributionDataList
) {
  let categoryData = [];
  if (categoricalDistributionDataList.length > 0) {
    const lastIndex = categoricalDistributionDataList.length - 1;
    for (let { category, signalValue } of categoricalDistributionDataList[lastIndex]
      .value) {
      categoryData.push({
        key: category,
        value: signalValue,
      });
    }
  }

  return categoryData;
}

export function profilerEnabledFnSorter(sorterFn) {
  return fnSorter((row) => {
    if (row?.profilerConfig.enabled) {
      return sorterFn(row);
    } else {
      return -1;
    }
  });
}
