import React from "react";
import GroupPanelItem from "../../../group-panel-item/group-panel-item";
import LabelItem from "../../../label-item/label-item";
import RuleConfigAggressiveDisplayView from "./rule-config-aggressive-display-view";
import { SymptomTypeConst } from "../../../../utils/enums";
import { getDisplayTimeFromSecond } from "../../../../utils/time";

function RuleConfigDetectionSettingDisplayView(props) {
  const {
    symptomType,
    driftDuration,
    recoveryDuration,
    direction,
    aggressiveness,
    enableTooltip = false,
  } = props;

  return (
    <GroupPanelItem chunkSize={1}>
      {symptomType !== SymptomTypeConst.SLOW_BURN_TREND_CHANGE && (
        <LabelItem
          label="Drift Duration"
          helpTooltip={
            enableTooltip
              ? "Drift duration is the duration after which a drift will be considered an incident."
              : ""
          }
        >
          {driftDuration === undefined
            ? "Automatic"
            : getDisplayTimeFromSecond(driftDuration, true, [
                "s",
                "m",
                "h",
                "d",
                "w",
                "mo",
                "y",
              ])}
        </LabelItem>
      )}
      {symptomType !== SymptomTypeConst.SLOW_BURN_TREND_CHANGE && (
        <LabelItem
          label="Recovery Duration"
          helpTooltip={
            enableTooltip
              ? "Recovery duration is the duration within which all incidents detected will be coalesced into a single incident."
              : ""
          }
        >
          {recoveryDuration === undefined
            ? "Automatic"
            : getDisplayTimeFromSecond(recoveryDuration, true, [
                "s",
                "m",
                "h",
                "d",
                "w",
                "mo",
                "y",
              ])}
        </LabelItem>
      )}
      {symptomType !== SymptomTypeConst.MANUAL_THRESHOLD && (
        <RuleConfigAggressiveDisplayView
          aggressiveness={aggressiveness}
          enableTooltip={enableTooltip}
        />
      )}
      {symptomType !== SymptomTypeConst.MANUAL_THRESHOLD && (
        <LabelItem label="Drift direction">
          {direction === undefined && "Automatic"}
          {typeof direction === "string" && direction
            ? direction.charAt(0).toUpperCase() + direction.substr(1).toLowerCase()
            : ""}
        </LabelItem>
      )}
    </GroupPanelItem>
  );
}

export default RuleConfigDetectionSettingDisplayView;
