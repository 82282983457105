import React from "react";
import { connect } from "react-redux";
import { AlertingChannelType } from "../../utils/alerting";
import EditSlackIntegrationDialog from "./slack/edit-slack-integration-dialog";
import EditPagerDutyIntegrationDialog from "./pagerduty/edit-pagerduty-integration-dialog";
import EditMSTeamsIntegrationDialog from "./msteams/edit-msteams-integration-dialog";
import EditJiraIntegrationDialog from "./jira/edit-jira-integration-dialog";
import EditServiceNowIntegrationDialog from "./servicenow/edit-servicenow-integration-dialog";
import EditEmailIntegrationDialog from "./email/edit-email-integration-dialog";
import EditOpsgenieIntegrationDialog from "./opsgenie/edit-opsgenie-integration-dialog";
import {
  getIntegrationMetadata,
  resetIntegrationMetadata,
} from "../../actions/integration/integration-action";
import { SubmitType } from "./integration-metadata";

function EditIntegrationDialog({
  workspaceUuid,
  data,
  currentMetadata,
  onEdit,
  onPreview,
  modalIsOpen,
  outsideClickWrap,
  setIsOpen,
  enablePreview,
  enableSave,
  getIntegrationMetadata,
  resetIntegrationMetadata,
}) {
  let EditDialogComponent = null;
  if (data.type === AlertingChannelType.SLACK) {
    EditDialogComponent = EditSlackIntegrationDialog;
  } else if (data.type === AlertingChannelType.PAGERDUTY) {
    EditDialogComponent = EditPagerDutyIntegrationDialog;
  } else if (data.type === AlertingChannelType.MSTEAMS) {
    EditDialogComponent = EditMSTeamsIntegrationDialog;
  } else if (data.type === AlertingChannelType.JIRA) {
    EditDialogComponent = EditJiraIntegrationDialog;
  } else if (data.type === AlertingChannelType.SERVICENOW) {
    EditDialogComponent = EditServiceNowIntegrationDialog;
  } else if (data.type === AlertingChannelType.OPSGENIE) {
    EditDialogComponent = EditOpsgenieIntegrationDialog;
  } else {
    EditDialogComponent = EditEmailIntegrationDialog;
  }

  return (
    <EditDialogComponent
      workspaceUuid={workspaceUuid}
      currentMetadata={currentMetadata}
      data={data}
      outsideClickWrap={outsideClickWrap}
      onEdit={(newData) =>
        // Make sure we don't wipe out the mode field, which might not be set from the integration
        // component. In case it's not set for whatever reason, reset it to automatic.
        onEdit({ mode: data.mode ?? SubmitType.AUTOMATIC, ...newData })
      }
      onPreview={onPreview}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enablePreview={enablePreview}
      enableSave={enableSave}
      getIntegrationMetadata={getIntegrationMetadata}
      resetIntegrationMetadata={resetIntegrationMetadata}
    />
  );
}

const mapStateToProps = (state) => ({
  currentMetadata: state.integrationReducer.currentMetadata,
});

const mapDispatchToProps = (dispatch) => ({
  getIntegrationMetadata: (workspaceUuid, integration) =>
    dispatch(getIntegrationMetadata(workspaceUuid, integration)),
  resetIntegrationMetadata: () => dispatch(resetIntegrationMetadata()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditIntegrationDialog);
