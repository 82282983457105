import { NextGenPalette } from "../../../utils/color";

export function SchemaChangeIcon(_props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 1.75016C1.75 1.10583 2.27233 0.583496 2.91667 0.583496H9.04167C9.20703 0.583496 9.36463 0.653684 9.47525 0.7766L12.0988 3.6916C12.1927 3.79515 12.25 3.93264 12.25 4.0835V6.7085C12.25 7.03066 11.9888 7.29183 11.6667 7.29183C11.3445 7.29183 11.0833 7.03066 11.0833 6.7085V4.66683H8.75C8.42783 4.66683 8.16667 4.40566 8.16667 4.0835V1.75016H2.91667V12.2502H6.41667C6.73883 12.2502 7 12.5113 7 12.8335C7 13.1557 6.73883 13.4168 6.41667 13.4168H2.91667C2.27234 13.4168 1.75 12.8945 1.75 12.2502V1.75016ZM9.33333 2.3629V3.50016H10.3569L9.33333 2.3629Z"
        fill={NextGenPalette.systemBlack}
      />
      <path
        d="M12.3277 8.88204C12.5771 9.08605 12.6138 9.45356 12.4098 9.7029L9.78481 12.9112C9.68627 13.0317 9.54368 13.1078 9.38876 13.1225C9.23385 13.1373 9.07944 13.0896 8.95989 12.99L7.20989 11.5316C6.9624 11.3254 6.92896 10.9576 7.13521 10.7101C7.34145 10.4626 7.70928 10.4291 7.95678 10.6354L9.25459 11.7169L11.5069 8.96413C11.7109 8.71478 12.0784 8.67803 12.3277 8.88204Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}

export function ArrowRight(props = {}) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33763 3.08748C8.56543 2.85967 8.93478 2.85968 9.16258 3.08748L12.6626 6.58748C12.8904 6.81529 12.8904 7.18463 12.6626 7.41244L9.16258 10.9124C8.93478 11.1402 8.56543 11.1402 8.33763 10.9124C8.10982 10.6846 8.10982 10.3153 8.33762 10.0875L10.8418 7.58329H1.75008C1.42792 7.58329 1.16675 7.32213 1.16675 6.99996C1.16675 6.67779 1.42792 6.41663 1.75008 6.41663H10.8418L8.33762 3.91244C8.10982 3.68463 8.10982 3.31528 8.33763 3.08748Z"
        fill="#121224"
      />
    </svg>
  );
}
