import React from "react";
import {
  DataSourceConfigItem,
  TableConfigItem,
  MetricComparesConfigItem,
  BackfillDurationConfigItem,
} from "../../fields/";
import SummaryViewSectionTitle from "../summary-view-section-title";
import { ConfigSummaryCardRow } from "../../summary-card";
import { default as Icon } from "@ant-design/icons/lib/components/Icon";
import { MetricSchemaIcon } from "../../fields/icons";

export function isEmptyAggregationCompareDataAssetSummary(configData) {
  return (
    !configData.config?.sourceTable?.sourceUuid &&
    !configData.config?.targetTable?.sourceUuid
  );
}

export function AggregationCompareDataAssetSummary(props) {
  const { configData, dataSourceList } = props;

  const {
    config: { sourceTable, targetTable },
  } = configData;

  return (
    <>
      {sourceTable?.sourceUuid && (
        <>
          <SummaryViewSectionTitle title="Source" />
          <DataSourceConfigItem
            dataSourceList={dataSourceList}
            value={sourceTable.sourceUuid}
            isEdit={false}
          />
        </>
      )}
      {sourceTable?.table?.schemaName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricSchemaIcon} />}
          label={sourceTable.table.schemaName}
        />
      )}
      {sourceTable?.table?.tableUuid && (
        <TableConfigItem
          tableList={sourceTable.table ? [sourceTable.table] : []}
          value={sourceTable.table?.tableUuid}
          isEdit={false}
        />
      )}
      {targetTable?.sourceUuid && (
        <>
          <SummaryViewSectionTitle title="Target" />
          <DataSourceConfigItem
            dataSourceList={dataSourceList}
            value={targetTable.sourceUuid}
            isEdit={false}
          />
        </>
      )}
      {targetTable?.table?.schemaName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricSchemaIcon} />}
          label={targetTable.table.schemaName}
        />
      )}
      {targetTable?.table?.tableUuid && (
        <TableConfigItem
          tableList={targetTable.table ? [targetTable.table] : []}
          value={targetTable.table?.tableUuid}
          isEdit={false}
        />
      )}
      {configData.config.backfillDuration && (
        <BackfillDurationConfigItem
          value={configData.config.backfillDuration}
          isEdit={false}
        />
      )}
    </>
  );
}

export function AggregationCompareMetricConfigDataAssetSummaryCardContent(props) {
  const { configData, kpiList } = props;

  const {
    config: { compares },
  } = configData;

  return (
    <>
      <SummaryViewSectionTitle title="Metrics to compare" />
      <MetricComparesConfigItem
        value={compares}
        kpiList={kpiList.data}
        isEdit={false}
      />
    </>
  );
}
