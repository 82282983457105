import React from "react";
import TimeOption from "../../../time-option/time-option";
import {
  FeatureTypeConst,
  featureTypeDisplayNameMap,
  getFilterTypeDisplayName,
  getTypesAndFeaturesBySymptomType,
  SymptomTypeConst,
} from "../../../../utils/enums";
import {
  DAY_IN_SECONDS,
  getDisplayTimeFromSecond,
  getSecondFromDisplayTime,
  WEEK_IN_SECONDS,
} from "../../../../utils/time";
import { TriggerButton } from "../../../button/ng-button";
import { SettingOutlined } from "@ant-design/icons";
import LegacyModalDialogAdapter from "../../../legacy-adapter/legacy-modal-dialog-adapter";
import ModalConfirmationButtons from "../../../modal-dialog/modal-confirmation-buttons";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../modal-dialog/modal-field-group";
import { LabeledSelect } from "../../../labeled-control/labeled-control";

import "./rule-training-advanced-setting-dialog.scss";

function RuleTrainingAdvancedSettingDialogTriggerComponent({ onClick }) {
  return (
    <div className="rule-training-advanced-setting-dialog-trigger-component-container">
      <TriggerButton onClick={onClick}>
        <SettingOutlined />
      </TriggerButton>
    </div>
  );
}

const windowSizeSettingOptions = [
  { value: "s", label: "second" },
  { value: "m", label: "minute" },
  { value: "h", label: "hour" },
  { value: "d", label: "day" },
  { value: "w", label: "week" },
  { value: "mo", label: "month" },
  { value: "y", label: "year" },
];

const scaleOptions = [
  { value: "absolute", label: "Absolute" },
  { value: "percentage", label: "Percentage" },
];

function getNormalizedAbsoluteScale(absoluteScale) {
  if (absoluteScale === undefined) {
    return "";
  }

  return absoluteScale ? "absolute" : "percentage";
}

function getKPISmoothConfigComponent(
  type,
  featureConfig,
  lastSampleSize,
  windowSize,
  onWindowSizeChange
) {
  if (type === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND) {
    const windowSizeShortCutOptions = [
      { value: getDisplayTimeFromSecond(WEEK_IN_SECONDS), label: "Last week" },
      { value: getDisplayTimeFromSecond(DAY_IN_SECONDS), label: "Last day" },
    ];

    if (lastSampleSize !== DAY_IN_SECONDS && lastSampleSize !== WEEK_IN_SECONDS) {
      windowSizeShortCutOptions.push({
        value: getDisplayTimeFromSecond(lastSampleSize),
        label: "Last Sample",
      });
    }

    return (
      <LabeledSelect
        label="Compare against"
        staticLabel
        value={windowSize}
        onChange={(newWindowSize) => {
          if (newWindowSize !== windowSize) {
            onWindowSizeChange(newWindowSize);
          }
        }}
        options={windowSizeShortCutOptions}
      />
    );
  } else if (
    type !== SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA &&
    featureConfig.type !== FeatureTypeConst.VALUE
  ) {
    return (
      <TimeOption
        label="Metric smoothing"
        layout="split"
        value={windowSize}
        onChange={onWindowSizeChange}
        options={windowSizeSettingOptions}
      />
    );
  } else {
    return null;
  }
}

function RuleTrainingAdvancedSettingDialogBodyComponent(props) {
  const { symptom, runTimeSymptom, lastSampleSize, onChange } = props;

  let { type, featureConfig, detectionCriteria } = symptom;

  let windowSize = undefined;
  if (featureConfig.windowSize) {
    windowSize = featureConfig.windowSize;
  } else if (
    runTimeSymptom &&
    runTimeSymptom.featureConfig &&
    runTimeSymptom.featureConfig.windowSize
  ) {
    windowSize = runTimeSymptom.featureConfig.windowSize;
  }

  if (!detectionCriteria && runTimeSymptom && runTimeSymptom.detectionCriteria) {
    detectionCriteria = runTimeSymptom.detectionCriteria;
  }

  let forecastDuration = undefined;
  let fittingDuration = undefined;
  let absoluteScale = undefined;
  if (type === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA) {
    if (typeof featureConfig.forecastDuration === "number") {
      forecastDuration = featureConfig.forecastDuration;
    } else if (
      runTimeSymptom &&
      runTimeSymptom.featureConfig &&
      typeof runTimeSymptom.featureConfig.forecastDuration === "number"
    ) {
      forecastDuration = runTimeSymptom.featureConfig.forecastDuration;
    }

    if (typeof featureConfig.fittingDuration === "number") {
      fittingDuration = featureConfig.fittingDuration;
    } else if (
      runTimeSymptom &&
      runTimeSymptom.featureConfig &&
      typeof runTimeSymptom.featureConfig.fittingDuration === "number"
    ) {
      fittingDuration = runTimeSymptom.featureConfig.fittingDuration;
    }

    if (typeof featureConfig.absoluteScale === "boolean") {
      absoluteScale = featureConfig.absoluteScale;
    } else if (
      runTimeSymptom &&
      runTimeSymptom.absoluteScale &&
      typeof runTimeSymptom.featureConfig.absoluteScale === "boolean"
    ) {
      absoluteScale = runTimeSymptom.featureConfig.absoluteScale;
    }
  }

  function onFeatureTypeChange(newFeatureType) {
    onChange({
      ...symptom,
      featureConfig: {
        ...featureConfig,
        type: newFeatureType,
      },
    });
  }

  function onWindowSizeChange(newWindowSizeStr) {
    const newWindowSize = getSecondFromDisplayTime(newWindowSizeStr);
    if (newWindowSize !== windowSize) {
      onChange({
        ...symptom,
        featureConfig: { ...featureConfig, windowSize: newWindowSize },
      });
    }
  }

  function onFittingDurationChange(newFittingDurationStr) {
    const newFittingDuration = getSecondFromDisplayTime(newFittingDurationStr);
    if (newFittingDuration !== featureConfig.fittingDuration) {
      onChange({
        ...symptom,
        featureConfig: {
          ...featureConfig,
          fittingDuration: newFittingDuration,
        },
      });
    }
  }

  function onForecastDurationChange(newForecastDurationStr) {
    const newForecastDuration = getSecondFromDisplayTime(newForecastDurationStr);
    if (newForecastDuration !== featureConfig.forecastDuration) {
      onChange({
        ...symptom,
        featureConfig: {
          ...featureConfig,
          forecastDuration: newForecastDuration,
        },
      });
    }
  }

  function onDetectionCriteriaChange(newDetectionCriteria) {
    if (newDetectionCriteria !== detectionCriteria) {
      onChange({
        ...symptom,
        detectionCriteria: newDetectionCriteria,
      });
    }
  }

  function onScaleChange(newScale) {
    const newAbsoluteScale = newScale === "absolute";
    if (
      featureConfig.absoluteScale === undefined ||
      newAbsoluteScale !== featureConfig.absoluteScale
    ) {
      onChange({
        ...symptom,
        featureConfig: { ...featureConfig, absoluteScale: newAbsoluteScale },
      });
    }
  }

  const availableFeatureTypeOptions = getTypesAndFeaturesBySymptomType(
    type
  ).features.map((featureType) => ({
    label: featureTypeDisplayNameMap[featureType],
    value: featureType,
  }));

  const detectionCriteriaOptions = getTypesAndFeaturesBySymptomType(type).types.map(
    (detectionCriteria) => {
      return {
        label: getFilterTypeDisplayName(detectionCriteria),
        value: detectionCriteria,
      };
    }
  );

  return (
    <div className="rule-training-advanced-setting-dialog-body-container">
      <ModalFieldGroup label="Feature">
        <ModalFieldRows>
          <LabeledSelect
            label="Feature"
            staticLabel
            options={availableFeatureTypeOptions}
            value={featureConfig.type}
            onChange={onFeatureTypeChange}
          />
          {getKPISmoothConfigComponent(
            type,
            featureConfig,
            lastSampleSize,
            windowSize === undefined
              ? undefined
              : getDisplayTimeFromSecond(windowSize, true, [
                  "s",
                  "m",
                  "h",
                  "d",
                  "w",
                  "mo",
                  "y",
                ]),
            onWindowSizeChange
          )}
          {type === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA && (
            <TimeOption
              label="Fitting Duration"
              layout="split"
              value={
                fittingDuration === undefined
                  ? undefined
                  : getDisplayTimeFromSecond(fittingDuration, true, [
                      "s",
                      "m",
                      "h",
                      "d",
                      "w",
                      "mo",
                      "y",
                    ])
              }
              onChange={onFittingDurationChange}
              options={windowSizeSettingOptions}
            />
          )}
          {type === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA && (
            <TimeOption
              label="Forecast Duration"
              layout="split"
              value={
                forecastDuration === undefined
                  ? undefined
                  : getDisplayTimeFromSecond(forecastDuration, true, [
                      "s",
                      "m",
                      "h",
                      "d",
                      "w",
                      "mo",
                      "y",
                    ])
              }
              onChange={onForecastDurationChange}
              options={windowSizeSettingOptions}
            />
          )}
          {type === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA && (
            <LabeledSelect
              label="Scale"
              staticLabel
              value={getNormalizedAbsoluteScale(absoluteScale)}
              onChange={onScaleChange}
              options={scaleOptions}
            />
          )}
        </ModalFieldRows>
      </ModalFieldGroup>
      {detectionCriteriaOptions.length > 1 && (
        <ModalFieldGroup label="Bounds">
          <LabeledSelect
            label="Detection Criteria"
            staticLabel
            value={detectionCriteria}
            onChange={onDetectionCriteriaChange}
            options={detectionCriteriaOptions}
            placeholder={"Select a criteria"}
          />
        </ModalFieldGroup>
      )}
    </div>
  );
}

function RuleTrainingAdvancedSettingDialogFooterComponent(props) {
  const { onCancelClicked, onApplyClicked } = props;

  return (
    <ModalConfirmationButtons
      onOkClick={onApplyClicked}
      onCancelClick={onCancelClicked}
    />
  );
}

function RuleTrainingAdvancedSettingDialog(props) {
  const { symptom, runTimeSymptom, lastSampleSize, onApply } = props;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [advanceSettingValue, setAdvanceSettingValue] = React.useState(symptom);

  function closeModal() {
    setIsOpen(false);
  }

  function onApplyClicked() {
    if (onApply) {
      onApply(advanceSettingValue);
    }
    closeModal();
  }

  return (
    <LegacyModalDialogAdapter
      title={"Advanced settings"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"rule-training-advanced-settings-dialog-container"}
      triggerComponent={<RuleTrainingAdvancedSettingDialogTriggerComponent />}
      contentComponent={
        <RuleTrainingAdvancedSettingDialogBodyComponent
          symptom={advanceSettingValue}
          runTimeSymptom={runTimeSymptom}
          lastSampleSize={lastSampleSize}
          onChange={setAdvanceSettingValue}
        />
      }
      footerComponent={
        <RuleTrainingAdvancedSettingDialogFooterComponent
          onCancelClicked={closeModal}
          onApplyClicked={onApplyClicked}
        />
      }
    />
  );
}

export default RuleTrainingAdvancedSettingDialog;
