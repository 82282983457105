import React from "react";
import NgButton from "../button/ng-button";
import { classesName } from "../../utils/css";

import "./modal-confirmation-buttons.scss";

function ModalConfirmationButtons(props) {
  const {
    extraControls = null,
    okText = "OK",
    cancelText = "Cancel",
    className: propsClassName,
    okButtonProps = {},
    cancelButtonsProps = {},
    onOkClick,
    onCancelClick,
  } = props;

  const className = classesName("modal-confirmation-buttons", propsClassName);

  return (
    <div className={className}>
      {okText && (
        <NgButton primary size="large" onClick={onOkClick} {...okButtonProps}>
          {okText}
        </NgButton>
      )}
      {cancelText && (
        <NgButton outline size="large" onClick={onCancelClick} {...cancelButtonsProps}>
          {cancelText}
        </NgButton>
      )}
      {extraControls}
    </div>
  );
}

export default ModalConfirmationButtons;
