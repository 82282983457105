import React from "react";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  isActivityMetric,
  isMetadataMetric,
  isMetricPausable,
  isMetricResumable,
} from "../../utils/metric";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import { isTriggeredCollectionMode } from "../../utils/general";
import { AppPermissions } from "../../utils/permissions";
import queryString from "query-string";
import {
  CloneIcon,
  PauseIcon,
  ResumeIcon,
  ViewInExplorerIcon,
  TriggerIcon,
  QueryHistoryIcon,
} from "./menu-icons";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import { explorerMetricUrl } from "./utils";
import { EVENT, trackEvent, getMetricDetailProps } from "../../utils/telemetry";
import NgTooltip from "../../components/tooltip/ng-tooltip";

export const MetricActions = Object.freeze({
  VIEW_EDIT: "viewEdit",
  CLONE: "clone",
  STATUS_CHANGE: "statusChange",
  DELETE: "delete",
  VIEW_IN_EXPLORER: "viewInExplorer",
  TRIGGER: "trigger",
  VIEW_QUERY_HISTORY: "viewQueryHistory",
  DISABLE: "disable",
});

function ProfilerMetricActionsMenu(props) {
  const {
    title,
    trigger,
    workspaceUuid,
    workspaceUserPermissions,
    metrics,
    history,
    onTriggerMetricClick,
    onDisableMetricClick,
    onToggleMetricLiveStatusClick,
    onDeleteMetricClick,
    onQueryHistoryClick,
    loading = false,
    actions = Object.values(MetricActions),
    getPopupContainer,
    tooltip,
  } = props;

  const viewMetricPermissions = [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
  ];
  const cloneMetricPermissions = [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ];
  const modifyMetricPermissions = [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
  ];
  const viewMetricDataPermissions = [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
  ];
  const triggerMetricPermissions = [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCETRIGGERVIEW,
  ];
  const disableMetricPermissions = [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_COLUMNPROFILERCONFIGDETAILVIEW,
  ];

  const canModifyMetric = hasPermission(
    workspaceUserPermissions,
    modifyMetricPermissions
  );
  const canViewMetric = hasPermission(workspaceUserPermissions, viewMetricPermissions);
  const menuConfig = [
    {
      action: MetricActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewMetricPermissions,
      item: {
        label: canModifyMetric ? "Edit" : "View",
        icon: <EditOutlined />,
        onClick: () => {
          const nextUrl = getURIInstance(URIPath.EDIT_METRIC, {
            workspaceUuid,
            id: metrics[0].metadata.uuid,
          });

          trackEvent(canModifyMetric ? EVENT.EDIT_METRIC : EVENT.VIEW_METRIC, {
            ...getMetricDetailProps(metrics[0]),
          });

          history.push(nextUrl);
        },
        disabled: !canViewMetric,
      },
    },
    {
      action: MetricActions.CLONE,
      supportsMultiple: false,
      permissions: cloneMetricPermissions,
      validTarget: (metric) => !isMetadataMetric(metric) && !isActivityMetric(metric),
      item: {
        label: "Clone",
        icon: <CloneIcon />,
        onClick: () => {
          const queryParams = {
            cloneFrom: metrics[0].metadata.uuid,
            returnTo: "profilerMetricNode",
          };
          const nextUrl = `${getURIInstance(URIPath.ADD_METRIC, {
            workspaceUuid,
          })}?${queryString.stringify(queryParams)}`;

          trackEvent(EVENT.CLONE_METRIC, {
            ...getMetricDetailProps(metrics[0]),
          });
          history.push(nextUrl);
        },
      },
    },
    {
      action: MetricActions.STATUS_CHANGE,
      supportsMultiple: true,
      permissions: modifyMetricPermissions,
      validTarget: isMetricResumable,
      item: {
        label: "Resume",
        icon: <ResumeIcon />,
        onClick: (targetMetrics) => {
          trackEvent(EVENT.RESUME_METRIC, {
            ...getMetricDetailProps(metrics[0]),
          });
          onToggleMetricLiveStatusClick(workspaceUuid, targetMetrics, true);
        },
      },
    },
    {
      action: MetricActions.STATUS_CHANGE,
      supportsMultiple: true,
      permissions: modifyMetricPermissions,
      validTarget: isMetricPausable,
      item: {
        label: "Pause",
        icon: <PauseIcon />,
        onClick: (targetMetrics) => {
          trackEvent(EVENT.PAUSE_METRIC, {
            ...getMetricDetailProps(metrics[0]),
          });
          onToggleMetricLiveStatusClick(workspaceUuid, targetMetrics, false);
        },
      },
    },
    {
      action: MetricActions.VIEW_IN_EXPLORER,
      supportsMultiple: false,
      permissions: viewMetricDataPermissions,
      item: {
        label: "View in explorer",
        icon: <ViewInExplorerIcon />,
        onClick: () => {
          trackEvent(EVENT.VIEW_METRIC_IN_EXPLORER, {
            ...getMetricDetailProps(metrics[0]),
          });

          const nextUrl = explorerMetricUrl(metrics[0]);
          history.push(nextUrl);
        },
      },
    },
    {
      action: MetricActions.VIEW_QUERY_HISTORY,
      supportsMultiple: false,
      validTarget: false,
      permissions: viewMetricDataPermissions,
      item: {
        label: "Query history",
        icon: <QueryHistoryIcon />,
        onClick: () => {
          trackEvent(EVENT.VIEW_METRIC_QUERY_HISTORY, {
            ...getMetricDetailProps(metrics[0]),
          });
          onQueryHistoryClick?.(metrics[0]);
        },
      },
    },
    {
      action: MetricActions.DELETE,
      supportsMultiple: true,
      permissions: modifyMetricPermissions,
      item: {
        label: "Delete",
        icon: <DeleteOutlined />,
        onClick: () => {
          onDeleteMetricClick(metrics);
        },
        danger: true,
      },
    },
    {
      action: MetricActions.TRIGGER,
      supportsMultiple: true,
      validTarget: (metric) =>
        isTriggeredCollectionMode(metric?.config?.collectionMode),
      permissions: triggerMetricPermissions,
      item: {
        label: "Trigger metric",
        icon: <TriggerIcon />,
        onClick: (targetMetrics) => {
          trackEvent(EVENT.TRIGGER_METRIC, {
            ...getMetricDetailProps(metrics[0]),
          });

          onTriggerMetricClick(workspaceUuid, targetMetrics);
        },
      },
    },
    {
      action: MetricActions.DISABLE,
      supportsMultiple: false,
      permissions: disableMetricPermissions,
      item: {
        label: "Delete",
        icon: <DeleteOutlined />,
        onClick: () => {
          trackEvent(EVENT.DISABLE_METRIC, {
            ...getMetricDetailProps(metrics[0]),
          });
          onDisableMetricClick(workspaceUuid, metrics[0]);
        },
        danger: true,
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    metrics,
    actions,
    workspaceUserPermissions
  );

  const triggerProps = trigger ? { trigger } : { buttonText: title };

  return (
    <NgTooltip title={!loading && tooltip ? tooltip : null}>
      <span>
        <NgDropdownMenu
          menuItems={menuItems}
          buttonProps={{ disabled: loading }}
          getPopupContainer={getPopupContainer}
          position="bottomLeft"
          {...triggerProps}
        />
      </span>
    </NgTooltip>
  );
}

export default ProfilerMetricActionsMenu;
