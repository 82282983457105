import React from "react";

import "./dq-dimension-stat.scss";

function DqDimensionStat(props) {
  const { dimensionName, value } = props;
  return (
    <div className="dq-dim-stat">
      <div className="dq-dim-stat-title">{dimensionName}</div>
      <div className="dq-dim-stat-value">{value}</div>
    </div>
  );
}

export default DqDimensionStat;
