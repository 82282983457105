import React from "react";
import PasscodeConfirmationModal from "../../components/passcode-confirmation-modal/passcode-confirmation-modal";

function DeleteMonitorConfirmationDialog(props) {
  const { isOpen, setIsOpen, monitors, onDeleteMonitors } = props;

  return (
    <PasscodeConfirmationModal
      visible={isOpen}
      title="Delete monitors"
      onCancel={() => setIsOpen(false)}
      onConfirm={() => onDeleteMonitors(monitors)}
    >
      You are about to delete {monitors.length} monitors. This action is not reversible.
    </PasscodeConfirmationModal>
  );
}

export default DeleteMonitorConfirmationDialog;
