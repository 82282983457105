import React, { Component } from "react";
import WizardNavHeader from "../wizard-nav-header/wizard-nav-header";
import BasicConfigView from "./config/basic-config-view";
import BigQueryConfigView from "./config/big-query-config-view";
import AthenaConfigView from "./config/athena-config-view";
import DataBricksView from "./config/data-bricks-config-view";
import SnowflakeOptionConfigView from "./config/snowflake-option-config-view";
import DremioOptionConfigView from "./config/dremio-option-config-view";
import HiveConfigView from "./config/hive-config-view";
import OracleConfigView from "./config/oracle-config-view";
import AzureBlobStorageConfigView from "./config/azure-blob-storage-config-view";
import SapHanaOptionConfigView from "./config/sap-hana-config-view";
import S3BucketConfigView from "./config/s3-bucket-config-view";
import { getOptionByValue } from "../../utils/options";
import { objectIsNotEmpty, isFeatureEnabled } from "../../utils/general";
import DataSourcePreviewResult from "./data-source-preview-result";
import DataSourceTestConnectionResult from "./data-source-test-connection-result";
import { DAY_IN_SECONDS, HOUR_IN_SECONDS, WEEK_IN_SECONDS } from "../../utils/time";
import {
  DataSourceType,
  getDataSourceDisplayName,
  HiveAuthType,
  isFileSource,
  SupportedFeature,
  TeradataAuthLogMech,
} from "../../utils/enums";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import NgStepContainer from "../step/ng-step";
import NgButton from "../button/ng-button";
import {
  LabeledInput,
  LabeledMultiSelect,
  LabeledSelect,
} from "../labeled-control/labeled-control";
import NgToggleCheckbox from "../toggle-checkbox/ng-toggle-checkbox";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../utils/telemetry";
import { deepcopy } from "../../utils/objects";
import TeradataAuthType from "./config/teradata-auth-type";
import RedshiftConfigView from "./config/redshift-config-view";

import "./data-source.scss";

const alationCustomDbType = "customdb";
const atlanCustomDbType = "customdb";

const typeOptions = [
  DataSourceType.POSTGRES,
  DataSourceType.REDSHIFT,
  DataSourceType.SNOWFLAKE,
  DataSourceType.BIGQUERY,
  DataSourceType.MYSQL,
  DataSourceType.ATHENA,
  DataSourceType.DATABRICKS,
  DataSourceType.HIVE,
  DataSourceType.MICROSOFTSQL,
  DataSourceType.ORACLE,
  DataSourceType.INCORTA,
  DataSourceType.TERADATA,
  DataSourceType.GREENPLUM,
  DataSourceType.DREMIO,
  DataSourceType.SAPHANA,
].map((dataSourceType) => {
  return {
    value: dataSourceType,
    label: getDataSourceDisplayName(dataSourceType),
  };
});

const scanIntervalOptions = [
  { label: "Hourly", value: HOUR_IN_SECONDS },
  { label: "Daily", value: DAY_IN_SECONDS },
  { label: "Weekly", value: WEEK_IN_SECONDS },
];

const TeraDataSchemaChoice = Object.freeze({
  DATABASES: "Databases",
  USERS: "Users",
});

const teraDataSchemaOptions = [
  { label: "Databases", value: TeraDataSchemaChoice.DATABASES },
  { label: "Users", value: TeraDataSchemaChoice.USERS },
];

function getDefaultConnectionValue(type) {
  if (
    [
      DataSourceType.POSTGRES,
      DataSourceType.GREENPLUM,
      DataSourceType.MYSQL,
      DataSourceType.MICROSOFTSQL,
      DataSourceType.INCORTA,
    ].includes(type)
  ) {
    return {
      host: "",
      port: "",
      dbname: "",
      user: "",
      password: "",
    };
  } else if (type === DataSourceType.REDSHIFT) {
    return {
      host: "",
      port: "",
      dbname: "",
      user: "",
      password: "",
      serverless: false,
    };
  } else if (type === DataSourceType.TERADATA) {
    return {
      host: "",
      port: "",
      user: "",
      password: "",
      includeDatabases: true,
      includeUsers: false,
      logMech: TeradataAuthLogMech.DATABASE,
    };
  } else if (type === DataSourceType.SNOWFLAKE) {
    return {
      host: "",
      dbname: "",
      user: "",
      password: "",
    };
  } else if (type === DataSourceType.ORACLE) {
    return {
      host: "",
      port: "",
      dbname: "",
      service: "",
      user: "",
      password: "",
    };
  } else if (type === DataSourceType.BIGQUERY) {
    return {
      serviceAccountInfo: "",
    };
  } else if (type === DataSourceType.ATHENA) {
    return {
      regionName: "",
      awsAccessKeyId: "",
      awsSecretAccessKey: "",
      s3StagingDir: "",
    };
  } else if (type === DataSourceType.DATABRICKS) {
    return {
      workspaceUrl: "",
      token: "",
      dbname: "",
      httpPath: "",
      catalog: "",
    };
  } else if (type === DataSourceType.HIVE) {
    return {
      host: "",
      port: "",
      authType: HiveAuthType.NO_AUTH,
    };
  } else if (type === DataSourceType.AZUREBLOBSTORAGE) {
    return {
      accountName: "",
      accountKey: "",
    };
  } else if (type === DataSourceType.DREMIO) {
    return {
      host: "",
      port: "",
      user: "",
      password: "",
      useEncryption: true,
      disableCertificateVerification: false,
    };
  } else if (type === DataSourceType.S3BUCKET) {
    return {
      regionName: "",
      awsAccessKeyId: "",
      awsSecretAccessKey: "",
    };
  } else if (type === DataSourceType.SAPHANA) {
    return {
      host: "",
      port: "",
      user: "",
      password: "",
      sslValidateCertificate: true,
    };
  }
}

function isSupportTestConnection(configData) {
  const {
    config: {
      connection: { type },
    },
  } = configData;

  return [
    DataSourceType.SNOWFLAKE,
    DataSourceType.REDSHIFT,
    DataSourceType.TERADATA,
    DataSourceType.DATABRICKS,
    DataSourceType.POSTGRES,
    DataSourceType.DREMIO,
    DataSourceType.MYSQL,
    DataSourceType.MICROSOFTSQL,
    DataSourceType.ORACLE,
    DataSourceType.INCORTA,
    DataSourceType.BIGQUERY,
    DataSourceType.HIVE,
  ].includes(type);
}

class DataSourceWizard extends Component {
  constructor(props) {
    super();

    let configData;
    if (props.isEdit) {
      configData = props.defaultData;
    } else {
      configData = {
        apiVersion: "v1",
        metadata: {
          name: "",
        },
        config: {
          connection: {
            type: DataSourceType.POSTGRES,
            ...getDefaultConnectionValue(DataSourceType.POSTGRES),
          },
          profiler: {
            enabled: true,
            scanInterval: DAY_IN_SECONDS,
          },
        },
      };
    }

    this.state = {
      configData,
      isPreviewed: false,
    };

    this.onNameChanged = this.onNameChanged.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onScanIntervalChange = this.onScanIntervalChange.bind(this);
    this.onTeraDataSchemaChange = this.onTeraDataSchemaChange.bind(this);
    this.onConnectionSettingChange = this.onConnectionSettingChange.bind(this);
    this.onAlationDatasourceIdChange = this.onAlationDatasourceIdChange.bind(this);
    this.onAtlanDatasourceIdChange = this.onAtlanDatasourceIdChange.bind(this);

    this.onPreview = this.onPreview.bind(this);

    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillUnmount() {
    this.props.resetDataSourceStatus();
  }

  onNameChanged(name) {
    const { configData } = this.state;
    if (configData.metadata.name !== name) {
      configData.metadata.name = name;
      this.setState({ configData: { ...configData } });
    }
  }

  onTypeChange(newType) {
    const { configData } = this.state;
    if (configData.config.connection.type !== newType) {
      this.onConnectionSettingChange({
        type: newType,
        ...getDefaultConnectionValue(newType),
      });
    }
  }

  onConnectionSettingChange(connection) {
    const { configData } = this.state;
    configData.config.connection = connection;
    this.setState({ configData: { ...configData }, isPreviewed: false });
  }

  isIAMRole(connection) {
    const {
      regionName = null,
      awsAccessKeyId = null,
      awsSecretAccessKey = null,
    } = connection;
    return (
      regionName === null && awsAccessKeyId === null && awsSecretAccessKey === null
    );
  }

  getNewDataSource() {
    const configData = deepcopy(this.state.configData);
    const type = configData.config.connection.type;
    if (!configData.config.connection.port) {
      if (type === DataSourceType.POSTGRES || type === DataSourceType.GREENPLUM) {
        configData.config.connection.port = 5432;
      } else if (type === DataSourceType.REDSHIFT) {
        configData.config.connection.port = 5439;
      } else if (type === DataSourceType.MYSQL) {
        configData.config.connection.port = 3306;
      } else if (type === DataSourceType.HIVE) {
        configData.config.connection.port = 10000;
      } else if (type === DataSourceType.MICROSOFTSQL) {
        configData.config.connection.port = 1433;
      } else if (type === DataSourceType.ORACLE) {
        configData.config.connection.port = 1521;
      } else if (type === DataSourceType.INCORTA) {
        configData.config.connection.port = 6526;
      } else if (type === DataSourceType.TERADATA) {
        configData.config.connection.port = 1025;
      } else if (type === DataSourceType.DREMIO) {
        configData.config.connection.port = 32010;
      } else if (type === DataSourceType.SAPHANA) {
        configData.config.connection.port = 443;
      }
    }

    if (
      type === DataSourceType.AZUREBLOBSTORAGE &&
      configData.config.connection.accountKey === null
    ) {
      delete configData.config.connection.accountKey;
    }

    if (
      type === DataSourceType.S3BUCKET &&
      this.isIAMRole(configData.config.connection)
    ) {
      configData.config.connection = { type };
    }

    return configData;
  }

  onPreview() {
    const newDataSource = this.getNewDataSource();
    this.props.previewDataSource(newDataSource);
    this.setState({ isPreviewed: true });

    trackEvent(EVENT.TEST_CONNECTION, {
      ...getDatasourceDetailProps(newDataSource),
      page: PAGE.DATASOURCE_DETAIL,
    });
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();

      trackEvent(EVENT.BACK_TEST_CONNECTION, {
        ...getDatasourceDetailProps(this.state.configData),
        page: PAGE.DATASOURCE_DETAIL,
      });
    }
  }

  onSave() {
    const newDataSource = this.getNewDataSource();
    if (this.props.onSave) {
      this.props.onSave(newDataSource);
    }
  }

  onDelete() {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  getPostgresView(connection) {
    return (
      <BasicConfigView value={connection} onChange={this.onConnectionSettingChange} />
    );
  }

  getRedshiftView(connection) {
    return (
      <>
        <BasicConfigView value={connection} onChange={this.onConnectionSettingChange} />
        <RedshiftConfigView
          value={connection}
          onChange={this.onConnectionSettingChange}
        />
      </>
    );
  }

  getTeradataView(connection) {
    return (
      <>
        <TeradataAuthType
          value={connection}
          onChange={this.onConnectionSettingChange}
        />
        <BasicConfigView
          value={connection}
          onChange={this.onConnectionSettingChange}
          enableDbNameConfig={false}
        />
      </>
    );
  }

  getSnowflakeView(connection) {
    return (
      <>
        <BasicConfigView value={connection} onChange={this.onConnectionSettingChange} />
        <SnowflakeOptionConfigView
          value={connection}
          onChange={this.onConnectionSettingChange}
        />
      </>
    );
  }

  getHiveView(connection) {
    return (
      <HiveConfigView value={connection} onChange={this.onConnectionSettingChange} />
    );
  }

  getOracleView(connection) {
    return (
      <OracleConfigView value={connection} onChange={this.onConnectionSettingChange} />
    );
  }

  getDremioView(connection) {
    return (
      <>
        <BasicConfigView
          value={connection}
          onChange={this.onConnectionSettingChange}
          enableDbNameConfig={false}
        />
        <DremioOptionConfigView
          value={connection}
          onChange={this.onConnectionSettingChange}
        />
      </>
    );
  }

  getSaphanaView(connection) {
    return (
      <>
        <BasicConfigView
          value={connection}
          onChange={this.onConnectionSettingChange}
          enableDbNameConfig={false}
        />
        <SapHanaOptionConfigView
          value={connection}
          onChange={this.onConnectionSettingChange}
        />
      </>
    );
  }

  getConnectTypeComponent() {
    const {
      configData: {
        metadata: { name },
        config: {
          connection: { type },
        },
      },
    } = this.state;

    let normalizeTypeOptions = typeOptions;
    if (isFeatureEnabled(this.props.waffle, SupportedFeature.ENABLE_BLOB_STORE)) {
      normalizeTypeOptions = [
        ...normalizeTypeOptions,
        {
          value: DataSourceType.AZUREBLOBSTORAGE,
          label: getDataSourceDisplayName(DataSourceType.AZUREBLOBSTORAGE),
        },
        {
          value: DataSourceType.S3BUCKET,
          label: getDataSourceDisplayName(DataSourceType.S3BUCKET),
        },
      ];
    }

    return (
      <>
        <LabeledInput
          label="Datasource name"
          staticLabel
          value={name}
          onChange={(e) => this.onNameChanged(e.target.value)}
        />
        <LabeledSelect
          label="Connector type"
          staticLabel
          showSearch
          value={type}
          onChange={this.onTypeChange}
          options={normalizeTypeOptions}
          enableSorting={true}
        />
      </>
    );
  }

  getBigQueryView() {
    return (
      <BigQueryConfigView
        onChange={(serviceAccountInfo) => {
          this.onConnectionSettingChange({
            type: "bigquery",
            serviceAccountInfo,
          });
        }}
      />
    );
  }

  getAthenaView(connection) {
    return (
      <AthenaConfigView value={connection} onChange={this.onConnectionSettingChange} />
    );
  }

  getDataBricksComponent(connection) {
    return (
      <DataBricksView
        value={connection}
        onChange={this.onConnectionSettingChange}
        waffle={this.props.waffle}
        isEdit={this.props.isEdit}
      />
    );
  }

  getAzureBlobStorageView(connection) {
    return (
      <AzureBlobStorageConfigView
        value={connection}
        onChange={this.onConnectionSettingChange}
      />
    );
  }

  getS3BucketView(connection) {
    return (
      <S3BucketConfigView
        value={connection}
        onChange={this.onConnectionSettingChange}
        useIAMRole={this.isIAMRole(connection)}
      />
    );
  }

  getConfigDetailComponent() {
    const {
      configData: {
        config: { connection },
      },
    } = this.state;

    const { type } = connection;

    if (
      [
        DataSourceType.POSTGRES,
        DataSourceType.GREENPLUM,
        DataSourceType.MYSQL,
        DataSourceType.MICROSOFTSQL,
        DataSourceType.INCORTA,
      ].includes(type)
    ) {
      return this.getPostgresView(connection);
    } else if (type === DataSourceType.REDSHIFT) {
      return this.getRedshiftView(connection);
    } else if (type === DataSourceType.TERADATA) {
      return this.getTeradataView(connection);
    } else if (type === DataSourceType.SNOWFLAKE) {
      return this.getSnowflakeView(connection);
    } else if (type === DataSourceType.BIGQUERY) {
      return this.getBigQueryView(connection);
    } else if (type === DataSourceType.ATHENA) {
      return this.getAthenaView(connection);
    } else if (type === DataSourceType.DATABRICKS) {
      return this.getDataBricksComponent(connection);
    } else if (type === DataSourceType.HIVE) {
      return this.getHiveView(connection);
    } else if (type === DataSourceType.ORACLE) {
      return this.getOracleView(connection);
    } else if (type === DataSourceType.AZUREBLOBSTORAGE) {
      return this.getAzureBlobStorageView(connection);
    } else if (type === DataSourceType.DREMIO) {
      return this.getDremioView(connection);
    } else if (type === DataSourceType.S3BUCKET) {
      return this.getS3BucketView(connection);
    } else if (type === DataSourceType.SAPHANA) {
      return this.getSaphanaView(connection);
    } else {
      console.log(`Unsupported data source type ${type}`);
      return null;
    }
  }

  onAlationDatasourceIdChange(newAlationDatasourceId) {
    const { configData } = this.state;
    if (configData.config.alationDatasourceId !== newAlationDatasourceId) {
      configData.config = {
        ...configData.config,
        alationDatasourceId: newAlationDatasourceId,
      };
      this.setState({ configData: { ...configData } });
    }
  }

  getAlationIntegrationComponent(alationDataSourceList) {
    const { configData } = this.state;

    const isAlationEnabled = configData.config.alationDatasourceId !== undefined;
    const alationDataSourceOptions = alationDataSourceList.map(
      (currentAlationDataSource) => {
        return {
          label: currentAlationDataSource.name,
          value: currentAlationDataSource.datasourceId,
        };
      }
    );

    return (
      <>
        <NgToggleCheckbox
          labelPosition="left"
          value={isAlationEnabled}
          onChange={(newIsAlationEnabled) => {
            if (newIsAlationEnabled) {
              configData.config = {
                ...configData.config,
                alationDatasourceId: alationDataSourceList?.[0]?.datasourceId,
              };
            } else {
              delete configData.config.alationDatasourceId;
            }

            this.setState({ configData: { ...configData } });
          }}
        />
        {isAlationEnabled && (
          <LabeledSelect
            label="Choose a datasource"
            enableSorting={true}
            value={configData.config.alationDatasourceId}
            onChange={this.onAlationDatasourceIdChange}
            options={alationDataSourceOptions}
          />
        )}
      </>
    );
  }

  onAtlanDatasourceIdChange(newAtlanDatasourceId) {
    const { configData } = this.state;
    if (configData.config.atlanDatasourceId !== newAtlanDatasourceId) {
      configData.config = {
        ...configData.config,
        atlanDatasourceId: newAtlanDatasourceId,
      };
      this.setState({ configData: { ...configData } });
    }
  }

  getAtlanIntegrationComponent(atlanDataSourceList) {
    const { configData } = this.state;

    const isAtlanEnabled = configData.config.atlanDatasourceId !== undefined;
    const atlanDataSourceOptions = atlanDataSourceList.map((currentAtlanDataSource) => {
      return {
        label: currentAtlanDataSource.name,
        value: currentAtlanDataSource.datasourceId,
      };
    });

    return (
      <>
        <NgToggleCheckbox
          labelPosition="left"
          value={isAtlanEnabled}
          onChange={(newIsAtlanEnabled) => {
            if (newIsAtlanEnabled) {
              configData.config = {
                ...configData.config,
                atlanDatasourceId: atlanDataSourceList?.[0]?.datasourceId,
              };
            } else {
              delete configData.config.atlanDatasourceId;
            }

            this.setState({ configData: { ...configData } });
          }}
        />
        {isAtlanEnabled && (
          <LabeledSelect
            label="Choose a datasource"
            enableSorting={true}
            value={configData.config.atlanDatasourceId}
            onChange={this.onAtlanDatasourceIdChange}
            options={atlanDataSourceOptions}
          />
        )}
      </>
    );
  }

  onScanIntervalChange(newScanInterval) {
    const { configData } = this.state;
    const {
      config: { profiler = {} },
    } = configData;

    const { scanInterval } = profiler;
    if (newScanInterval === scanInterval) {
      return;
    }

    configData.config.profiler = {
      ...profiler,
      scanInterval: newScanInterval,
    };
    this.setState({ configData: { ...configData } });
  }

  onTeraDataSchemaChange(newOptions) {
    const { configData } = this.state;
    let includeDatabases = false;
    let includeUsers = false;
    for (let currentOption of newOptions || []) {
      if (currentOption === TeraDataSchemaChoice.DATABASES) {
        includeDatabases = true;
      } else if (currentOption === TeraDataSchemaChoice.USERS) {
        includeUsers = true;
      }
    }

    this.onConnectionSettingChange({
      ...configData.config.connection,
      includeDatabases,
      includeUsers,
    });
  }

  getTeraDataAdvancedComponent() {
    const {
      configData: {
        config: {
          connection: { includeDatabases = true, includeUsers = false },
        },
      },
    } = this.state;

    const normalizeSchemaValue = [];
    includeDatabases && normalizeSchemaValue.push(TeraDataSchemaChoice.DATABASES);
    includeUsers && normalizeSchemaValue.push(TeraDataSchemaChoice.USERS);

    const normalizeSchemaValueOptions = normalizeSchemaValue.map((value) =>
      getOptionByValue(teraDataSchemaOptions, value)
    );

    return (
      <LabeledMultiSelect
        label="Schema scan objects"
        value={normalizeSchemaValueOptions}
        onChange={this.onTeraDataSchemaChange}
        options={teraDataSchemaOptions}
      />
    );
  }

  getAdvancedComponent() {
    const {
      configData: {
        config: { profiler = {}, connection },
      },
    } = this.state;

    const type = connection.type;

    const { scanInterval } = profiler;
    return (
      <>
        <LabeledSelect
          label="Schema scan frequency"
          staticLabel
          value={scanInterval}
          onChange={this.onScanIntervalChange}
          options={scanIntervalOptions}
        />
        {type === DataSourceType.TERADATA && this.getTeraDataAdvancedComponent()}
      </>
    );
  }

  getConfigStepSwitchSetting() {
    const {
      configData: {
        config: { connection },
      },
    } = this.state;

    const { type } = connection;

    if (type === DataSourceType.AZUREBLOBSTORAGE) {
      const { accountKey } = connection;
      return {
        enabledTitle: "Managed Identity",
        enabled: accountKey === null,
        onEnabledChange: () => {
          this.onConnectionSettingChange({
            ...connection,
            accountKey: accountKey === null ? "" : null,
          });
        },
      };
    }

    if (type === DataSourceType.S3BUCKET) {
      const isIAMRoleValue = this.isIAMRole(connection);
      return {
        enabledTitle: "Managed by IAM Role",
        enabled: isIAMRoleValue,
        onEnabledChange: () => {
          const value = isIAMRoleValue ? "" : null;
          this.onConnectionSettingChange({
            ...connection,
            regionName: value,
            awsAccessKeyId: value,
            awsSecretAccessKey: value,
          });
        },
      };
    }

    return {};
  }

  getConfigView() {
    const steps = [
      {
        title: "Select a connector",
        component: this.getConnectTypeComponent(),
      },
      {
        title: "Configure connector",
        component: this.getConfigDetailComponent(),
        ...this.getConfigStepSwitchSetting(),
      },
    ];

    const connectionType = this.state.configData.config.connection?.type;
    if (connectionType && !isFileSource(connectionType)) {
      steps.push({
        title: "Advanced",
        component: this.getAdvancedComponent(),
      });
    }

    const alationDataSourceList = connectionType
      ? this.props.alationDataSourceList.filter(({ type }) =>
          [connectionType, alationCustomDbType].includes(type)
        )
      : [];

    if (alationDataSourceList.length > 0) {
      steps.push({
        title: "Alation integration",
        className: "datasource-alation-integration-component-container",
        component: this.getAlationIntegrationComponent(alationDataSourceList),
      });
    }

    const atlanDataSourceList = connectionType
      ? this.props.atlanDataSourceList.filter(({ type }) =>
          [connectionType, atlanCustomDbType].includes(type)
        )
      : [];

    if (atlanDataSourceList.length > 0) {
      steps.push({
        title: "Atlan integration",
        className: "datasource-atlan-integration-component-container",
        component: this.getAtlanIntegrationComponent(atlanDataSourceList),
      });
    }

    return (
      <NgStepContainer
        steps={steps}
        className="data-source-configuration-container lightup-rest-flex-item-container"
      />
    );
  }

  isValidData() {
    // Simple non empty validation.
    const newDataSource = this.getNewDataSource();
    const newDataSourceConfig = {
      name: newDataSource.metadata.name,
      config: { ...newDataSource.config.connection },
    };

    if (newDataSourceConfig.config.hasOwnProperty("warehouse")) {
      delete newDataSourceConfig.config["warehouse"];
    }

    if (newDataSourceConfig.config.hasOwnProperty("role")) {
      delete newDataSourceConfig.config["role"];
    }

    if (newDataSourceConfig.config?.type === DataSourceType.ORACLE) {
      if (
        newDataSourceConfig.config["dbname"] &&
        newDataSourceConfig.config["service"]
      ) {
        return false;
      }

      if (
        !newDataSourceConfig.config["dbname"] &&
        !newDataSourceConfig.config["service"]
      ) {
        return false;
      }

      delete newDataSourceConfig.config["dbname"];
      delete newDataSourceConfig.config["service"];
    }

    if (newDataSourceConfig.config?.type === DataSourceType.TERADATA) {
      if (
        newDataSourceConfig.config["includeUsers"] === false &&
        newDataSourceConfig.config["includeDatabases"] === false
      ) {
        return false;
      }

      delete newDataSourceConfig.config["includeUsers"];
      delete newDataSourceConfig.config["includeDatabases"];
    }

    if (
      [DataSourceType.DATABRICKS, DataSourceType.TERADATA].includes(
        newDataSourceConfig.config?.type
      )
    ) {
      delete newDataSourceConfig.config["dbname"];
    }

    if (DataSourceType.DATABRICKS === newDataSourceConfig.config?.type) {
      delete newDataSourceConfig.config["catalog"];
    }

    if (DataSourceType.ATHENA === newDataSourceConfig.config?.type) {
      delete newDataSourceConfig.config["workgroup"];
    }

    if (DataSourceType.DREMIO === newDataSourceConfig.config?.type) {
      delete newDataSourceConfig.config["useEncryption"];
      delete newDataSourceConfig.config["disableCertificateVerification"];
    }

    if (DataSourceType.SAPHANA === newDataSourceConfig.config?.type) {
      delete newDataSourceConfig.config["sslValidateCertificate"];
    }

    if (DataSourceType.REDSHIFT === newDataSourceConfig.config?.type) {
      delete newDataSourceConfig.config["serverless"];
    }

    return objectIsNotEmpty(newDataSourceConfig);
  }

  getTestConnectionView(isValidData, enableEditDataSource) {
    return (
      <div className="data-source-preview-button-container lightup-auto-flex-item-container">
        <NgButton
          outline
          size="large"
          block
          onClick={() => this.onPreview()}
          disabled={!isValidData || !enableEditDataSource}
        >
          Test Connection
        </NgButton>
        <NgButton
          primary
          size="large"
          block
          onClick={() => this.onSave()}
          disabled={!isValidData || !enableEditDataSource || this.props.isSaving}
        >
          Save
        </NgButton>
      </div>
    );
  }

  render() {
    const { workspaceUserPermissions, lastPreviewStatus, lastPreviewResult } =
      this.props;

    const { configData } = this.state;

    const isValidData = this.isValidData();
    const enableEditDataSource = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
    ]);

    const DataSourcePreviewComponent = isSupportTestConnection(configData)
      ? DataSourceTestConnectionResult
      : DataSourcePreviewResult;

    return (
      <div className="data-source-wizard-container">
        <WizardNavHeader
          className="data-source-wizard-header"
          onCancel={this.onCancel}
        />
        <div className="wizard-body-container">
          <div className="wizard-body-left-panel">
            <div className="wizard-body-basic-config-container">
              {this.getConfigView()}
            </div>
            {this.getTestConnectionView(isValidData, enableEditDataSource)}
          </div>
          <div className="wizard-preview-container">
            <DataSourcePreviewComponent
              isPreviewed={this.state.isPreviewed}
              lastPreviewStatus={lastPreviewStatus}
              lastPreviewResult={lastPreviewResult}
              configData={configData}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DataSourceWizard;
