import { useEffect } from "react";
import { getAlertingChannelDisplayType } from "../../../utils/alerting";
import { SubmitType } from "../../../views/integrations/integration-metadata";
import NgMultiSelect from "../../multi-select/ng-multi-select";

export function MetricDraftNotification(props) {
  const {
    configData,
    onConfigDataChange,
    disabled,
    integrationList,
    defaultNotificationChannels = [],
  } = props;
  const { notificationChannels } = configData?.draftMetadata ?? {};

  useEffect(() => {
    if (!notificationChannels) {
      // populate with default notification channels if required
      onConfigDataChange({
        ...configData,
        draftMetadata: {
          ...configData.draftMetadata,
          notificationChannels: defaultNotificationChannels,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultChannels =
    defaultNotificationChannels?.map(({ channelId }) => channelId) ?? [];

  const channelOptions = integrationList
    .filter(({ mode }) => mode !== SubmitType.MANUAL)
    .map(({ id, title, type }) => {
      return {
        label: `${getAlertingChannelDisplayType(type)}: ${title}`,
        value: id,
        disabled: defaultChannels.includes(id),
      };
    });

  return (
    <>
      <span>Select the channels to send notifications</span>
      <NgMultiSelect
        size="large"
        value={notificationChannels?.map(({ channelId }) => channelId)}
        placeholder={"Select"}
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
        }
        options={channelOptions}
        onChange={(newChannels) => {
          onConfigDataChange({
            ...configData,
            draftMetadata: {
              ...configData.draftMetadata,
              notificationChannels: newChannels?.map((channelId) => ({
                channelId,
              })),
            },
          });
        }}
        mode="multiple"
        disabled={disabled}
        allowClear={false}
      />
    </>
  );
}
