import React, { Component } from "react";
import IncidentLocalOptionFilter from "../../components/incident-new-local-option-filter";
import ChartView from "../../components/chart-view/ng-index";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";
import TimeRangeSelector from "../../components/time-range-selector/";
import {
  INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
  INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
  KPI_AGGREGATION_SOURCE_UUID_KEY_NAME,
  KPI_AGGREGATION_TARGET_UUID_KEY_NAME,
  KPI_CATEGORY_KEY_NAME,
  KPI_PERCENTILE_KEY_NAME,
} from "../../utils/constants";
import { getIconFromColumn } from "./column-icons";
import { getIconFromMetric } from "./metric-icons";
import {
  ProfilerPathSeparatorIcon,
  ProfileTableIcon,
  ProfileSchemaIcon,
  SliceIcon,
} from "./icons";
import { getSliceDisplayString } from "../../utils/general";
import { getKPIInterval } from "../../utils/defaults";
import { getUnixTime } from "date-fns";
import ProfilerIncidentList from "../incidents/profiler-incident-list";
import { getLocalFilteredIncidentList } from "../incidents/incident-search";
import { getFullTableName } from "../../utils/datasource";

import "./profiler-incident-list-page.scss";

class ProfilerIncidentListPage extends Component {
  constructor(props) {
    super(props);
    this.currentTime = new Date();
    const userSetting = props.config.userSetting;
    userSetting.customerOptions.rejected = false;
    this.state = {
      chartInfo: props.config.chartInfo,
      rules: props.config.rules,
      userSetting,
      currentIncidentList: {
        loading: true,
        data: [],
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { currentIncidentList } = props;
    if (currentIncidentList !== state.currentIncidentList) {
      return {
        ...state,
        currentIncidentList,
      };
    }

    return null;
  }

  static getSignalDataQueryObject(state) {
    const { startTime, endTime } = state.userSetting.time;
    const slice = state.userSetting.slice || {};
    const {
      chartInfo: {
        autoMetricType,
        currentMetric,
        dataSourceInfo = null,
        schemaInfo = null,
        tableInfo = null,
        columnInfo = null,
      },
    } = state;

    let sliceKeyValuePairs = [];
    const sliceKeys = slice ? Object.keys(slice) : [];
    if (sliceKeys.length > 0) {
      sliceKeyValuePairs = sliceKeys.map((sliceKey) => ({
        key: sliceKey,
        value: slice[sliceKey],
      }));
    }

    const queryObject = {
      autoMetricType,
      metric: currentMetric,
      metricUuid: currentMetric?.metadata.uuid || null,
      startTime: getUnixTime(startTime),
      endTime: getUnixTime(endTime),
      sliceKeyValuePairs,
    };

    dataSourceInfo && (queryObject.dataSourceUuid = dataSourceInfo.metadata.uuid);
    tableInfo && (queryObject.tableUuid = tableInfo.uuid);
    schemaInfo && (queryObject.schemaUuid = schemaInfo.uuid);
    columnInfo && (queryObject.columnUuid = columnInfo.uuid);
    return queryObject;
  }

  static getQueryObject(state, _props) {
    const { rules = [] } = state;
    const { startTime, endTime } = state.userSetting.time;
    const { customerOptions } = state.userSetting;
    const filterUuids = rules.map((currentRule) => currentRule.metadata.uuid);
    return {
      startTime: getUnixTime(startTime),
      endTime: getUnixTime(endTime),
      customerOptions,
      filterUuids,
    };
  }

  componentDidMount() {
    console.log("Start to load incident list....");
    this.updateIncidentList();
    this.updateIncidentMetricsData();
  }

  componentWillUnmount() {
    this.props.resetCurrentIncidentList();
  }

  updateIncidentMetricsData() {
    const queryObject = ProfilerIncidentListPage.getSignalDataQueryObject(this.state);
    this.props.getCurrentIncidentMetricData(queryObject);
  }

  updateIncidentList() {
    const queryObject = ProfilerIncidentListPage.getQueryObject(this.state);
    this.props.getCurrentIncidentList(queryObject);
  }

  onLocalFilterSaved(newPartialLocalFilter) {
    const { localFilterSetting } = this.state.userSetting;
    const newLocalFilterSetting = {
      ...localFilterSetting,
      ...newPartialLocalFilter,
    };
    this.updateUserSetting({ localFilterSetting: newLocalFilterSetting }, false);
  }

  onOptionsSaved(selectedOptions) {
    const customerOptions = { ...selectedOptions };
    this.updateUserSetting({
      customerOptions,
    });
  }

  updateUserSetting(newPartialUserSetting, isUpdate = true) {
    const { userSetting } = this.state;
    const newUserSetting = {
      ...userSetting,
      ...newPartialUserSetting,
    };
    this.setState({ userSetting: newUserSetting }, () => {
      if (!isUpdate) {
        return;
      }

      this.updateIncidentList();
      this.updateIncidentMetricsData();
    });
  }

  render() {
    const { onBack } = this.props;
    const { userSetting, chartInfo, currentIncidentList } = this.state;

    const { currentMetricsData } = this.props;

    const { time, localFilterSetting, customerOptions } = userSetting;

    const {
      autoMetricType,
      currentMetric,
      dataSourceInfo,
      tableInfo,
      schemaInfo = null,
      columnInfo = null,
    } = chartInfo;

    const { showMyRule, ...normalizedLocalFilterSetting } = localFilterSetting;

    normalizedLocalFilterSetting["ownedBy"] = showMyRule ? this.props.userInfo.id : "";

    const localFilteredIncidentList = getLocalFilteredIncidentList(
      currentIncidentList.data,
      normalizedLocalFilterSetting,
      true,
      false
    );

    const currentIncidents = [];
    for (let allIncidentsPerRule of localFilteredIncidentList) {
      const { incidents } = allIncidentsPerRule;
      for (let currentIncident of incidents) {
        currentIncidents.push({
          ...currentIncident,
          main: true,
          skipIndicatorText: true,
        });
      }
    }

    let TypeIcon;
    let name;
    if (columnInfo) {
      TypeIcon = getIconFromColumn(columnInfo);
      name = columnInfo.columnName;
    } else if (tableInfo) {
      TypeIcon = ProfileTableIcon;
      name = getFullTableName(tableInfo);
    } else if (schemaInfo) {
      TypeIcon = ProfileSchemaIcon;
      name = schemaInfo.name;
    } else if (currentMetric) {
      TypeIcon = getIconFromMetric(currentMetric);
      name = currentMetric.metadata.name;
    }

    const normalizedSlice = {};
    const slice = userSetting.slice || {};
    let isSliced = false;
    for (let sliceKey in slice) {
      if (
        [
          KPI_CATEGORY_KEY_NAME,
          KPI_PERCENTILE_KEY_NAME,
          KPI_AGGREGATION_SOURCE_UUID_KEY_NAME,
          KPI_AGGREGATION_TARGET_UUID_KEY_NAME,
          INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
          INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
        ].includes(sliceKey)
      ) {
        continue;
      }
      normalizedSlice[sliceKey] = slice[sliceKey];
      isSliced = true;
    }

    console.log(currentMetricsData.data);
    return (
      <div className="profiler-incident-list-page-container">
        <div className="profiler-incident-list-page-header-container">
          <div
            className="profiler-incident-list-page-back-button-container lightup-horizon-flex-container"
            onClick={onBack}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.60652 5.29289C9.99705 5.68341 9.99705 6.31658 9.60653 6.7071L5.31367 11H20.8995C21.4518 11 21.8995 11.4477 21.8995 12C21.8995 12.5523 21.4518 13 20.8995 13H5.31367L9.60653 17.2929C9.99705 17.6834 9.99705 18.3166 9.60652 18.7071C9.216 19.0976 8.58283 19.0976 8.19231 18.7071L2.19237 12.7071C1.80185 12.3166 1.80185 11.6834 2.19237 11.2929L8.19231 5.2929C8.58283 4.90237 9.216 4.90237 9.60652 5.29289Z"
                fill="black"
                fillOpacity="0.85"
              />
            </svg>
            Back
          </div>
        </div>
        <div className="profiler-incident-list-page-body-container">
          <div className="profiler-incident-list-controller-container">
            <div className="profiler-incident-list-page-chart-info-indicator-container lightup-horizon-flex-container">
              {TypeIcon && <TypeIcon size={30} />}
              <div className="profiler-incident-list-page-chart-info-indicator-name-container">
                <NgTextTooltip>{name}</NgTextTooltip>
              </div>
              <div className="lightup-rest-flex-item-container" />
            </div>
            {isSliced && (
              <div className="profiler-incident-list-page-chart-info-indicator-container lightup-horizon-flex-container">
                <SliceIcon />
                <div className="profiler-incident-list-page-chart-info-indicator-name-container">
                  {getSliceDisplayString(normalizedSlice, currentMetric)}
                </div>
                <div className="lightup-rest-flex-item-container" />
              </div>
            )}
            {dataSourceInfo && tableInfo && (
              <div className="profiler-incident-list-page-chart-info-path-container">
                {dataSourceInfo.metadata.name}
                <ProfilerPathSeparatorIcon />
                {getFullTableName(tableInfo)}
                {columnInfo && (
                  <>
                    <ProfilerPathSeparatorIcon />
                    {columnInfo.columnName}
                  </>
                )}
              </div>
            )}
            <div className="profiler-incident-list-page-filter-container lightup-horizon-flex-container">
              <TimeRangeSelector
                currentTime={this.currentTime}
                value={time}
                onChange={(newTime) => {
                  this.updateUserSetting({
                    time: newTime,
                  });
                }}
              />
              <div className="profiler-incident-list-page-select-container lightup-rest-flex-item-container" />
              <IncidentLocalOptionFilter
                iconOnly={true}
                small={true}
                localFilterSetting={localFilterSetting}
                onSave={this.onLocalFilterSaved.bind(this)}
                customerOptions={customerOptions}
                onCustomOptionsSave={this.onOptionsSaved.bind(this)}
              />
            </div>
          </div>
          <div className="profiler-incident-metric-data-chart-view-container">
            <ChartView
              metricAggregationInterval={
                currentMetric ? getKPIInterval(currentMetric) : ""
              }
              metricType={autoMetricType}
              metric={currentMetric}
              title={currentMetric?.metadata.name || ""}
              showSubTitle={true}
              data={currentMetricsData.data}
              loading={currentMetricsData.loading}
              incidents={currentIncidents}
              enableChartOption={false}
              config={{
                symptomType: "",
                feature: "",
                autosize: false,
                height: 200,
                marginTop: 25,
                startTime: 0,
                endTime: 0,
                detectInterval: 0,
                isIncidentOnly: false,
                textHeightInPx: 1,
                className: "",
                isProfiler: true,
              }}
              dataSource={dataSourceInfo}
            />
          </div>
          <div className="profiler-incident-list-data-container">
            <ProfilerIncidentList
              groupedIncidentList={localFilteredIncidentList}
              loading={currentIncidentList.loading}
              onIncidentStatusChanged={this.props.onIncidentStatusChanged}
              workspaceUserPermissions={this.props.workspaceUserPermissions}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilerIncidentListPage;
