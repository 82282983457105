import React from "react";
import { connect } from "react-redux";

import { AlertingChannelType } from "../../utils/alerting";
import AddSlackIntegrationDialog from "./slack/add-slack-integration-dialog";
import AddPagerDutyIntegrationDialog from "./pagerduty/add-pagerduty-integration-dialog";
import AddMSTeamsIntegrationDialog from "./msteams/add-msteams-integration-dialog";
import AddJiraIntegrationDialog from "./jira/add-jira-integration-dialog";
import AddServiceNowIntegrationDialog from "./servicenow/add-servicenow-integration-dialog";
import AddEmailIntegrationDialog from "./email/add-email-integration-dialog";
import AddOpsGenieIntegrationDialog from "./opsgenie/add-opsgenie-integration-dialog";
import {
  getIntegrationMetadata,
  resetIntegrationMetadata,
} from "../../actions/integration/integration-action";

function AddIntegrationDialog({
  workspaceUuid,
  type,
  currentMetadata,
  modalIsOpen,
  outsideClickWrap,
  setIsOpen,
  onAdd,
  onPreview,
  getIntegrationMetadata,
  resetIntegrationMetadata,
}) {
  let AddDialogComponent = null;

  if (type === AlertingChannelType.SLACK) {
    AddDialogComponent = AddSlackIntegrationDialog;
  } else if (type === AlertingChannelType.PAGERDUTY) {
    AddDialogComponent = AddPagerDutyIntegrationDialog;
  } else if (type === AlertingChannelType.MSTEAMS) {
    AddDialogComponent = AddMSTeamsIntegrationDialog;
  } else if (type === AlertingChannelType.JIRA) {
    AddDialogComponent = AddJiraIntegrationDialog;
  } else if (type === AlertingChannelType.SERVICENOW) {
    AddDialogComponent = AddServiceNowIntegrationDialog;
  } else if (type === AlertingChannelType.OPSGENIE) {
    AddDialogComponent = AddOpsGenieIntegrationDialog;
  } else {
    AddDialogComponent = AddEmailIntegrationDialog;
  }

  return (
    <AddDialogComponent
      workspaceUuid={workspaceUuid}
      currentMetadata={currentMetadata}
      outsideClickWrap={outsideClickWrap}
      onAdd={onAdd}
      onPreview={onPreview}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      getIntegrationMetadata={getIntegrationMetadata}
      resetIntegrationMetadata={resetIntegrationMetadata}
    />
  );
}

const mapStateToProps = (state) => ({
  currentMetadata: state.integrationReducer.currentMetadata,
});

const mapDispatchToProps = (dispatch) => ({
  getIntegrationMetadata: (workspaceUuid, integration) =>
    dispatch(getIntegrationMetadata(workspaceUuid, integration)),
  resetIntegrationMetadata: () => dispatch(resetIntegrationMetadata()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIntegrationDialog);
