import React, { useMemo } from "react";
import Button from "../../../button/ng-button";
import ChartView from "../../../chart-view/ng-index";
import MetricConfigPreviewResultTableView from "./metric-config-preview-result-table-view";
import {
  AggregationType,
  AutoMetricsType,
  MetricCategory,
  RequestStatus,
} from "../../../../utils/enums";
import { isSliceEqual, updateValueRange } from "../../../../utils/general";
import { getMetricTypeFromConfigData } from "../../utils";
import { CirclesSeven } from "../../../../components/icons/circles-seven";
import { getAutoMetricTypeFromKPI, getSliceByColumns } from "../../../../utils/metric";
import { isQueryTerminationSupported } from "../../../../utils/datasource";
import { Spinner } from "../../../../atom/spinner";

import "./metric-config-preview-result-view.scss";

function MetricConfigPreviewResultInitView() {
  return (
    <div className="metric-init-content-view-container">
      <div className="metric-init-content-view-inner-container">
        <div className="metric-init-content-icon-container">
          <CirclesSeven />
        </div>
        <div className="metric-init-content-title-container">
          Preview the metric profile
        </div>
        <div className="metric-init-content-content-container">
          Define your metric, configure its settings and run preview. You will see a
          data grid for the selected columns specified. After verifying the columns,
          save the metric.
        </div>
      </div>
    </div>
  );
}

function MetricConfigPreviewResultInProgressView(props) {
  const {
    configData: {
      config: { sources = [] },
    },
    dataSourceList = [],
    onTerminatePreview,
    previewStatus,
  } = props;

  const sourceUuid = sources.length > 0 ? sources[0] : "";
  let isTerminationSupported = false;
  if (sourceUuid && dataSourceList) {
    const sourceObject = dataSourceList.find(
      (sourceObject) => sourceObject.metadata.uuid === sourceUuid
    );
    if (sourceObject) {
      isTerminationSupported = isQueryTerminationSupported(sourceObject);
    }
  }

  return (
    <div className="metric-in-progress-view-container">
      <div className="metric-in-progress-content-view-inner-container">
        <Spinner size="large" />
        <div className="metric-in-progress-title-container">
          {previewStatus === RequestStatus.DOING ? "Generating preview" : "Canceling"}
        </div>
        <div className="metric-in-progress-cancel">
          {isTerminationSupported && previewStatus === RequestStatus.DOING && (
            <Button primary onClick={onTerminatePreview}>
              Cancel Preview
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

const noIncidents = [];

function MetricConfigFullCompareSucceedView({ configData }) {
  return (
    <>
      <div className="full-compare-preview-computing-match">
        <span className="full-compare-preview-computing-match-header">Note: </span>
        <span>
          In actual metric, Match % column will contain a percentage between 0% and
          100%, which reflects the percentage of matching values.
        </span>
      </div>
      <ChartView
        noCardContainer
        metricType={AutoMetricsType.FULL_COMPARE}
        metric={configData}
        title={""}
        subTitle={""}
        data={{
          metricsData: [],
        }}
        incidents={noIncidents}
        showTitle={false}
        config={{
          isPreview: true,
        }}
      />
    </>
  );
}

function getCurrentSlicePreviewData(previewData, sliceByColumns, sliceValue) {
  if (!sliceByColumns?.length || !sliceValue || !previewData?.metricsData?.signalData) {
    return previewData;
  }

  const signalDataPerSlice = [];
  const signalRangePerSlice = { min: undefined, max: undefined };
  for (let previewDataItem of previewData.metricsData.signalData) {
    const { slice = {}, value } = previewDataItem;
    if (isSliceEqual(sliceValue, slice, sliceByColumns)) {
      signalDataPerSlice.push(previewDataItem);
    }

    updateValueRange(signalRangePerSlice, value);
  }

  return {
    autoMetricType: previewData.autoMetricType,
    duration: previewData.duration,
    metricsData: {
      projectedData: [],
      normalData: [],
      lowerBoundData: [],
      upperBoundData: [],
      signalData: signalDataPerSlice,
    },
    range: {
      metricsRange: { min: undefined, max: undefined },
      signalRange: signalRangePerSlice,
    },
  };
}

function MetricConfigAggregationCompareSucceedView({
  configData,
  previewResult,
  onCurrentPointChange,
  _currentPreviewTime,
}) {
  return (
    <div className="metric-result-chart">
      <ChartView
        noCardContainer
        metricType={AutoMetricsType.AGGREGATION_COMPARE}
        metric={configData}
        title={""}
        subTitle={""}
        data={previewResult.data}
        incidents={noIncidents}
        showTitle={false}
        onCurrentPointChange={onCurrentPointChange}
        config={{
          isPreview: true,
        }}
      />
    </div>
  );
}

const aggregationPreviewChartConfig = {
  symptomType: "",
  feature: "",
  autosize: false,
  height: 300,
  startTime: 0,
  endTime: 0,
  detectInterval: 0,
  isIncidentOnly: false,
  xTitle: "",
  y2Title: "",
  className: "",
};

function MetricConfigAggregationSucceedView(props) {
  const { configData, previewResult, selectedSliceValue } = props;
  const previewResultData = useMemo(() => previewResult?.data || [], [previewResult]);

  const sliceByColumns = useMemo(() => getSliceByColumns(configData), [configData]);

  const currentPreviewData = useMemo(() => {
    return getCurrentSlicePreviewData(
      previewResultData,
      sliceByColumns || [],
      selectedSliceValue
    );
  }, [previewResultData, sliceByColumns, selectedSliceValue]);

  const tableData = currentPreviewData?.metricsData?.signalData || [];
  const tableHeader = [
    { name: "TIMESTAMP", key: "timestamp" },
    { name: "VALUE", key: "value" },
  ];

  if (sliceByColumns.length > 0) {
    tableHeader.push(
      ...sliceByColumns.map((sliceByColumn) => ({
        name: sliceByColumn,
        key: sliceByColumn,
      }))
    );
  }

  return (
    <>
      <div className="metric-result-chart">
        <ChartView
          noCardContainer
          metricType={getAutoMetricTypeFromKPI(configData)}
          title={""}
          subTitle={""}
          data={currentPreviewData}
          incidents={noIncidents}
          showTitle={false}
          config={aggregationPreviewChartConfig}
        />
      </div>
      <MetricConfigPreviewResultTableView
        header={tableHeader}
        data={tableData}
        currentColumnKey={"value"}
        timestampColumnKey={"timestamp"}
        sliceColumnKeyArray={sliceByColumns || []}
      />
    </>
  );
}

function MetricConfigCategoricalDistributionSucceedView(props) {
  const { configData, selectedSliceValue, previewResult } = props;

  const previewResultData = useMemo(() => previewResult?.data || [], [previewResult]);

  const sliceByColumns = useMemo(() => getSliceByColumns(configData), [configData]);

  const currentPreviewData = useMemo(
    () =>
      getCurrentSlicePreviewData(previewResultData, sliceByColumns, selectedSliceValue),
    [previewResultData, sliceByColumns, selectedSliceValue]
  );

  const tableHeader = [
    { name: "TIMESTAMP", key: "timestamp" },
    { name: "TOP1", key: "top1" },
    { name: "TOP2", key: "top2" },
    { name: "TOP3", key: "top3" },
    { name: "TOP4", key: "top4" },
    { name: "TOP5", key: "top5" },
  ];

  const tableDataMapper = ({ timestamp, value }) => {
    const top5Value = value.slice(0, 5);
    const normalizedTop5Value = top5Value.map(({ category, signalValue }) => {
      signalValue =
        typeof signalValue === "number" ? `${signalValue.toFixed(3)}%` : signalValue;
      return `${category}: ${signalValue}`;
    });

    return {
      timestamp,
      top1: normalizedTop5Value[0] || "",
      top2: normalizedTop5Value[1] || "",
      top3: normalizedTop5Value[2] || "",
      top4: normalizedTop5Value[3] || "",
      top5: normalizedTop5Value[4] || "",
    };
  };

  const tableData = (currentPreviewData?.metricsData?.signalData || []).map(
    tableDataMapper
  );
  return (
    <>
      <div className="metric-result-chart">
        <ChartView
          noCardContainer
          metricType={AutoMetricsType.CATEGORICAL_DISTRIBUTION}
          title={""}
          subTitle={""}
          data={currentPreviewData}
          incidents={noIncidents}
          showTitle={false}
          config={aggregationPreviewChartConfig}
        />
      </div>
      <MetricConfigPreviewResultTableView
        header={tableHeader}
        data={tableData}
        currentColumnKey={""}
        timestampColumnKey={"timestamp"}
        sliceColumnKeyArray={[]}
      />
    </>
  );
}

function MetricConfigNumericalDistributionSucceedView(props) {
  const { configData, selectedSliceValue, previewResult } = props;

  const previewResultData = useMemo(() => previewResult?.data || [], [previewResult]);

  const sliceByColumns = useMemo(() => getSliceByColumns(configData), [configData]);

  const currentPreviewData = useMemo(
    () =>
      getCurrentSlicePreviewData(previewResultData, sliceByColumns, selectedSliceValue),
    [previewResultData, sliceByColumns, selectedSliceValue]
  );

  const tableHeader = [
    { name: "TIMESTAMP", key: "timestamp" },
    { name: "P0", key: "min" },
    { name: "P25", key: "q1" },
    { name: "P50", key: "mean" },
    { name: "P75", key: "q3" },
    { name: "P100", key: "max" },
  ];
  const formatDisplayFloat = (f) =>
    typeof f === "number" ? f.toFixed(3).toString() : f;
  const tableDataMapper = (signalDataPoint) => {
    const {
      timestamp,
      value: { min, q1, mean, q3, max },
    } = signalDataPoint;
    return {
      timestamp,
      min: formatDisplayFloat(min),
      q1: formatDisplayFloat(q1),
      mean: formatDisplayFloat(mean),
      q3: formatDisplayFloat(q3),
      max: formatDisplayFloat(max),
    };
  };

  const tableData = (currentPreviewData?.metricsData?.signalData || []).map(
    tableDataMapper
  );
  return (
    <>
      <div className="metric-result-chart">
        <ChartView
          noCardContainer
          metricType={AutoMetricsType.NUMERICAL_DISTRIBUTION}
          title={""}
          subTitle={""}
          data={currentPreviewData}
          incidents={noIncidents}
          showTitle={false}
          config={aggregationPreviewChartConfig}
        />
      </div>
      <MetricConfigPreviewResultTableView
        header={tableHeader}
        data={tableData}
        currentColumnKey={""}
        timestampColumnKey={"timestamp"}
        sliceColumnKeyArray={[]}
      />
    </>
  );
}

function MetricConfigDistributionSucceedView(props) {
  const { configData } = props;
  if (configData.config.aggregation.type === AggregationType.MULTI_PERCENTILE) {
    return <MetricConfigNumericalDistributionSucceedView {...props} />;
  } else if (
    configData.config.aggregation.type === AggregationType.CATEGORICAL_DISTRIBUTION
  ) {
    return <MetricConfigCategoricalDistributionSucceedView {...props} />;
  }
}

function MetricConfigPreviewResultSucceedView(props) {
  const { configData, selectedSliceValue, previewResult, onCurrentPointChange } = props;
  const currentMetricCategory = getMetricTypeFromConfigData(configData);
  if ([MetricCategory.FULL_COMPARE].includes(currentMetricCategory)) {
    return (
      <MetricConfigFullCompareSucceedView
        configData={configData}
        previewResult={previewResult}
      />
    );
  }

  if ([MetricCategory.AGGREGATION_COMPARE].includes(currentMetricCategory)) {
    return (
      <MetricConfigAggregationCompareSucceedView
        configData={configData}
        previewResult={previewResult}
        onCurrentPointChange={onCurrentPointChange}
      />
    );
  }

  if ([MetricCategory.DISTRIBUTION].includes(currentMetricCategory)) {
    return (
      <MetricConfigDistributionSucceedView
        configData={configData}
        selectedSliceValue={selectedSliceValue}
        previewResult={previewResult}
      />
    );
  }

  return (
    <MetricConfigAggregationSucceedView
      configData={configData}
      selectedSliceValue={selectedSliceValue}
      previewResult={previewResult}
    />
  );
}

function MetricConfigPreviewResultView(props) {
  const {
    previewStatus,
    previewResult,
    isPreviewed,
    dataSourceList,
    configData,
    onTerminatePreview,
    sliceByColumns,
    selectedSliceValue,
    currentPreviewTime,
    onCurrentPointChange,
  } = props;

  return (
    <div className="metric-config-preview-result-view-container">
      {!isPreviewed && <MetricConfigPreviewResultInitView />}
      {isPreviewed &&
        [RequestStatus.CANCELING, RequestStatus.DOING].includes(previewStatus) && (
          <MetricConfigPreviewResultInProgressView
            dataSourceList={dataSourceList}
            configData={configData}
            onTerminatePreview={onTerminatePreview}
            previewStatus={previewStatus}
          />
        )}
      {isPreviewed && previewStatus === RequestStatus.SUCCESS && (
        <>
          <MetricConfigPreviewResultSucceedView
            previewResult={previewResult}
            sliceByColumns={sliceByColumns}
            selectedSliceValue={selectedSliceValue}
            configData={configData}
            currentPreviewTime={currentPreviewTime}
            onCurrentPointChange={onCurrentPointChange}
          />
        </>
      )}
    </div>
  );
}

export default MetricConfigPreviewResultView;
