import React from "react";
import { Spinner } from "../../atom/spinner";

import "./profiler-data-profile-in-progress-view.scss";

export default function ProfilerDataProfileInProgressView() {
  return (
    <div className="profiler-data-profile-in-progress-view-container">
      <Spinner size="large" />
      In progress...
    </div>
  );
}
