import axios from "../utils/api";
import {
  backendRelativeURIPath,
  backendURIPath,
  getAPIURIInstance,
} from "../utils/uri-path";

function getWorkspaceUserFromServer(user) {
  return {
    id: user["id"],
    username: user["username"],
    email: user["username"],
    hasLoggedIn: !!user["last_login"],
    role: user["role"],
    lastName: user["last_name"] || "",
    firstName: user["first_name"] || "",
  };
}

function getWorkspaceUserFromUI(user) {
  return {
    id: user["id"],
    username: user["username"],
    email: user["email"],
    role: user["role"],
  };
}

export function getWorkspaceUserListPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(getAPIURIInstance(backendRelativeURIPath.USER_LIST, { workspaceUuid }), {
        baseURL: "/api/v0/",
      })
      .then(function (response) {
        resolve(
          response.data.map((currentUser) => getWorkspaceUserFromServer(currentUser))
        );
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addWorkspaceUserPromise(workspaceUuid, newUser) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.USER_LIST, { workspaceUuid }),
        getWorkspaceUserFromUI(newUser),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(getWorkspaceUserFromServer(response.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function patchWorkspaceUserPromise(workspaceUuid, uuid, partialUser) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        getAPIURIInstance(backendRelativeURIPath.USER, { workspaceUuid, uuid }),
        partialUser,
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve();
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteWorkspaceUserPromise(workspaceUuid, user) {
  return new Promise(function (resolve, reject) {
    const { id } = getWorkspaceUserFromUI(user);
    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.USER, {
          workspaceUuid,
          uuid: id,
        }),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function batchWorkspaceUserOperationPromise(
  workspaceUuid,
  workspaceWorkspaceOperations
) {
  return new Promise(function (resolve, reject) {
    const {
      addedWorkspaceUsers = [],
      deletedWorkspaceUsers = [],
      updatedWorkspaceUsers = [],
    } = workspaceWorkspaceOperations;

    const allOperationPromises = [];
    addedWorkspaceUsers.forEach(({ email, role }) => {
      allOperationPromises.push(
        addWorkspaceUserPromise(workspaceUuid, { email, role })
      );
    });

    updatedWorkspaceUsers.forEach(({ id, role }) => {
      allOperationPromises.push(patchWorkspaceUserPromise(workspaceUuid, id, { role }));
    });

    deletedWorkspaceUsers.forEach(({ id }) => {
      allOperationPromises.push(deleteWorkspaceUserPromise(workspaceUuid, { id }));
    });

    if (allOperationPromises.length === 0) {
      resolve();
      return;
    }

    Promise.all(allOperationPromises)
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getUserWorkspacePermissionPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.USER_PERMISSION, {
          workspaceUuid,
        }),
        { baseURL: "/api/v0/", disableToast: true }
      )
      .then(function (response) {
        const { is_superuser: isSuperuser = false, permissions } = response.data;
        resolve({ isSuperuser, permissions });
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getAppConfigPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .get(backendURIPath.USER_CONFIG)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getAppHealthPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .get(backendURIPath.HEALTH)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getCurrentUserPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .get(backendURIPath.CURRENT_USER, { disableToast: true })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function loginUserPromise(authInfo) {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.USER_LOGIN, authInfo)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function logoutUserPromise(options = {}) {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.USER_LOGOUT, {}, options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function acceptUserDisclaimPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.USER_ACCEPT_DISCLAIMER)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getAppUserFromServer(user) {
  return {
    id: user["id"],
    username: user["username"],
    firstName: user["first_name"],
    lastName: user["last_name"],
    email: user["username"],
    hasLoggedIn: !!user["last_login"],
    role: user["app_role"] ? user["app_role"] : "",
    workspaces: user["workspaces"],
    lastLogin: user["last_login"],
  };
}

export function getAppUserListPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .get(backendURIPath.APP_USER_LIST)
      .then(function (response) {
        resolve(response.data.map((appUser) => getAppUserFromServer(appUser)));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

function getAppUserFromUI(user) {
  return {
    email: user["email"],
    app_role: user["role"],
  };
}

export function addAppUserPromise(newUser) {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.APP_USER_LIST, getAppUserFromUI(newUser))
      .then(function (response) {
        resolve(getAppUserFromServer(response.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteAppUserPromise(uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(getAPIURIInstance(backendURIPath.APP_USER, { uuid }))
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function patchAppUserPromise(uuid, partialUser) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(getAPIURIInstance(backendURIPath.APP_USER, { uuid }), partialUser, {
        baseURL: "/api/v0/",
      })
      .then(function (response) {
        resolve();
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getSystemIntegrationListPromise() {
  return new Promise(function (resolve, reject) {
    axios
      .get(backendURIPath.SYSTEM_INTEGRATION_LIST, { baseURL: "/api/v1/" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateSystemIntegrationPromise(uuid, data) {
  return new Promise(function (resolve, reject) {
    axios
      .put(getAPIURIInstance(backendURIPath.SYSTEM_INTEGRATION, { uuid }), data, {
        baseURL: "/api/v1/",
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addSystemIntegrationPromise(newSystemIntegration) {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.SYSTEM_INTEGRATION_LIST, newSystemIntegration, {
        baseURL: "/api/v1/",
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getSystemIntegrationMetadataPromise(uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(getAPIURIInstance(backendURIPath.SYSTEM_INTEGRATION_METADATA, { uuid }), {
        baseURL: "/api/v1/",
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteSystemIntegrationPromise(uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(getAPIURIInstance(backendURIPath.SYSTEM_INTEGRATION, { uuid }), {
        baseURL: "/api/v1/",
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function previewSystemIntegrationPromise(data) {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.SYSTEM_INTEGRATION_INSPECTION, data, { baseURL: "/api/v1/" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
