import React from "react";
import { IncidentMainTabKey } from "./utils";
import IncidentMainHeaderView from "./incident-main-header-view";
import IncidentMainViewChartTab from "./tabs/chart/chart-tab";
import IncidentMainViewSummaryTab from "./tabs/summary/";
import IncidentMainViewSampleDataTab from "./tabs/sample-data/";
import IncidentMainViewActivityTab from "./tabs/activity/activity-tab";
import { isQueryTerminationSupported } from "../../utils/datasource";

import "./incident-main-view.scss";

function IncidentMainView(props) {
  const {
    correlationChartList,
    incident,
    currentTabKey,
    timeRange,
    timeRangeQuickOptions,
    onTimeRangeChange,
    incidentCreatorInfo,
    incidentUuid,
    incidentPreviewUuid,
    incidentMainMetricsData,
    groupIncidentList,
    groupIncidentListMetrics,
    incidentCommentList,
    incidentCaseList,
    onCaseUpdated,
    onEditComment,
    onDeleteComment,
    dataSourceList,
    kpiList,
    ruleList,
    workspaceUserPermissions,
    workspaceUuid,
    history,
    setCurrentTabKey,
    onChangeIncidentStatusClick,
    onValidationStatusClick,
    onRemoveCorrelatedChart,
    mainViewMenuConfig,
    onNextUrlChange,
    correlationChartListMonitorSetting,
    onFocusedMonitorChange,
    onQueryHistoryView,
  } = props;

  const sampleDataTerminationSupported =
    incidentCreatorInfo.dataSourceInfo?.config &&
    isQueryTerminationSupported(incidentCreatorInfo.dataSourceInfo);

  return (
    <div className="incident-main-view-container">
      <div className="incident-main-view-header-outer-container">
        <IncidentMainHeaderView
          incidentCommentList={incidentCommentList}
          incidentCaseList={incidentCaseList}
          incident={incident}
          incidentCreatorInfo={incidentCreatorInfo}
          workspaceUserPermissions={workspaceUserPermissions}
          currentTab={currentTabKey}
          mainViewMenuConfig={mainViewMenuConfig}
          onCurrentTabChange={(newCurrentTabKey) => {
            if (currentTabKey === newCurrentTabKey) {
              return;
            }
            setCurrentTabKey(newCurrentTabKey);
          }}
        />
      </div>
      <div className="incident-main-view-content-outer-container">
        {currentTabKey === IncidentMainTabKey.CHART && (
          <IncidentMainViewChartTab
            timeRange={timeRange}
            timeRangeQuickOptions={timeRangeQuickOptions}
            onTimeRangeChange={onTimeRangeChange}
            onChangeIncidentStatusClick={onChangeIncidentStatusClick}
            onValidationStatusClick={onValidationStatusClick}
            incident={incident}
            incidentCreatorInfo={incidentCreatorInfo}
            incidentUuid={incidentUuid}
            incidentPreviewUuid={incidentPreviewUuid}
            incidentMainMetricsData={incidentMainMetricsData}
            groupIncidentList={groupIncidentList}
            groupIncidentListMetrics={groupIncidentListMetrics}
            incidentCommentList={incidentCommentList}
            workspaceUserPermissions={workspaceUserPermissions}
            correlationChartList={correlationChartList}
            workspaceUuid={workspaceUuid}
            kpiList={kpiList}
            ruleList={ruleList}
            onNextUrlChange={onNextUrlChange}
            onRemoveCorrelatedChart={onRemoveCorrelatedChart}
            correlationChartListMonitorSetting={correlationChartListMonitorSetting}
            onFocusedMonitorChange={onFocusedMonitorChange}
            onQueryHistoryView={onQueryHistoryView}
          />
        )}
        {currentTabKey === IncidentMainTabKey.SUMMARY && (
          <IncidentMainViewSummaryTab
            dataSourceList={dataSourceList}
            metricList={kpiList}
            incident={incident}
            incidentCreatorInfo={incidentCreatorInfo}
            history={history}
            workspaceUserPermissions={workspaceUserPermissions}
          />
        )}
        {currentTabKey === IncidentMainTabKey.SAMPLE_DATA && (
          <IncidentMainViewSampleDataTab
            workspaceUuid={workspaceUuid}
            incidentUuid={incidentUuid}
            incidentCreatorInfo={incidentCreatorInfo}
            terminationSupported={sampleDataTerminationSupported}
            onTerminateQuery={() => setCurrentTabKey(IncidentMainTabKey.CHART)}
          />
        )}
        {currentTabKey === IncidentMainTabKey.ACTIVITY && (
          <IncidentMainViewActivityTab
            incidentCommentList={incidentCommentList}
            incidentCaseList={incidentCaseList}
            onCaseUpdated={onCaseUpdated}
            onEditComment={onEditComment}
            onDeleteComment={onDeleteComment}
            enableEditDeleteComment={mainViewMenuConfig.enableEditDeleteComment}
          />
        )}
      </div>
    </div>
  );
}

export default IncidentMainView;
