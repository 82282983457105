import React from "react";
import "./dq-dimension-button.scss";
import DqDimensionTypeTooltip from "./dq-dimension-type-tooltip";
import { dqDimensionTypeTitles } from "./util";
import { getDisplayFloatStr } from "../../utils/general";

const statusRanges = [
  {
    className: "dq-dim-btn-healthy",
    range: [90, 101],
  },
  {
    className: "dq-dim-btn-warning",
    range: [60, 90],
  },
  {
    className: "dq-dim-btn-unhealthy",
    range: [0, 60],
  },
];

function statusClassName(isRatio, percent) {
  if (!isRatio) {
    return "dq-dim-btn-na";
  }
  const range = statusRanges.find((statusRange) => {
    const [low, high] = statusRange.range;
    return percent >= low && percent < high;
  });
  return range?.className || "";
}

function DqDimensionButton(props) {
  const {
    selected = false,
    dimensionType,
    numerator = 0,
    denominator = 0,
    onClick = () => {},
  } = props;

  const selectedClass = selected ? "dq-dim-btn-selected" : "";
  const isRatio = denominator !== 0;
  const percent = isRatio ? (numerator / denominator) * 100 : 0;
  const valueStr = isRatio ? getDisplayFloatStr(percent, true, 1) : "N/A";
  const ratioStr = isRatio ? `${numerator} / ${denominator}` : "N/A";
  const statusClass = statusClassName(isRatio, percent);
  const noDataClass = isRatio ? "" : "dq-dim-btn-no-data";

  function onButtonClick() {
    if (isRatio) {
      onClick();
    }
  }

  return (
    <button
      className={`dq-dim-btn ${selectedClass} ${noDataClass}`}
      onClick={onButtonClick}
    >
      <div className="dq-dim-btn-title">
        <span className="dq-dim-btn-title-text">
          {dqDimensionTypeTitles[dimensionType]}
        </span>
        <DqDimensionTypeTooltip dimensionType={dimensionType} />
      </div>
      <div className={`dq-dim-btn-percent ${statusClass}`}>{valueStr}</div>
      <div className={`dq-dim-btn-ratio ${statusClass}`}>{ratioStr}</div>
    </button>
  );
}

export default DqDimensionButton;
