import React from "react";
import TextInput from "../input/text-input";

import "./wizard-nav-header.scss";

export function BackIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.60652 5.29289C9.99705 5.68341 9.99705 6.31658 9.60653 6.7071L5.31367 11H20.8995C21.4518 11 21.8995 11.4477 21.8995 12C21.8995 12.5523 21.4518 13 20.8995 13H5.31367L9.60653 17.2929C9.99705 17.6834 9.99705 18.3166 9.60652 18.7071C9.216 19.0976 8.58283 19.0976 8.19231 18.7071L2.19237 12.7071C1.80185 12.3166 1.80185 11.6834 2.19237 11.2929L8.19231 5.2929C8.58283 4.90237 9.216 4.90237 9.60652 5.29289Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
}

function WizardNavHeader(props) {
  const {
    className = "",
    onCancel,
    defaultTitlePlaceHolder,
    onTitleChanged,
    title,
    showTitle,
  } = props;

  return (
    <div className={`wizard-nav-header-container ${className ? className : ""}`}>
      <div className="wizard-nav-back-button" onClick={onCancel}>
        <BackIcon />
        Back
      </div>
      <div className="wizard-nav-item wizard-nav-title">
        {showTitle ? (
          <TextInput
            value={title}
            onChange={onTitleChanged}
            placeholder={defaultTitlePlaceHolder}
          />
        ) : null}
      </div>
    </div>
  );
}

export default WizardNavHeader;
