import React from "react";
import { ErrorIcon, LiveIcon, PausedIcon } from "../../components/icons/status";
import StatusBadge from "../../components/status-badge";
import { MonitorRunStatus } from "../../utils/enums";
import { NextGenPalette } from "../../utils/color";
import Popover from "../../components/popover";
import { WarningOutlined } from "@ant-design/icons";

import "./monitor-status-badge.scss";

function OfflineIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M4.58605 1.45429C4.4227 1.1766 4.06518 1.08391 3.78749 1.24726C3.5098 1.4106 3.41711 1.76813 3.58046 2.04581L9.41379 11.9625C9.57713 12.2402 9.93466 12.3329 10.2123 12.1695C10.49 12.0062 10.5827 11.6486 10.4194 11.371L4.58605 1.45429Z"
        fill="#121224"
      />
      <path
        d="M0.583252 4.66663C0.583252 4.02228 1.10559 3.49996 1.74992 3.49996H3.20825C3.53042 3.49996 3.79159 3.76113 3.79159 4.08329C3.79159 4.40546 3.53042 4.66663 3.20825 4.66663H1.74992V8.74996H6.12492C6.44709 8.74996 6.70825 9.01113 6.70825 9.33329C6.70825 9.65546 6.44709 9.91663 6.12492 9.91663H1.74992C1.10559 9.91663 0.583252 9.39431 0.583252 8.74996V4.66663Z"
        fill="#121224"
      />
      <path
        d="M7.29159 4.08329C7.29159 3.76113 7.55275 3.49996 7.87492 3.49996H12.2499C12.8943 3.49996 13.4166 4.02228 13.4166 4.66663V8.74996C13.4166 9.3943 12.8943 9.91663 12.2499 9.91663H11.0833C10.7611 9.91663 10.4999 9.65546 10.4999 9.33329C10.4999 9.01113 10.7611 8.74996 11.0833 8.74996H12.2499V4.66663H7.87492C7.55275 4.66663 7.29159 4.40546 7.29159 4.08329Z"
        fill="#121224"
      />
      <path
        d="M8.74992 6.70829C8.74992 6.38613 9.01109 6.12496 9.33325 6.12496H10.4999C10.8221 6.12496 11.0833 6.38613 11.0833 6.70829C11.0833 7.03046 10.8221 7.29163 10.4999 7.29163H9.33325C9.01109 7.29163 8.74992 7.03046 8.74992 6.70829Z"
        fill="#121224"
      />
      <path
        d="M3.49992 6.12496C3.17775 6.12496 2.91659 6.38613 2.91659 6.70829C2.91659 7.03046 3.17775 7.29163 3.49992 7.29163H4.66659C4.98875 7.29163 5.24992 7.03046 5.24992 6.70829C5.24992 6.38613 4.98875 6.12496 4.66659 6.12496H3.49992Z"
        fill="#121224"
      />
    </svg>
  );
}

function TrainingIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16699 5.24996C1.16699 4.92779 1.42816 4.66663 1.75033 4.66663H12.2503C12.5725 4.66663 12.8337 4.92779 12.8337 5.24996V8.74996C12.8337 9.07213 12.5725 9.33329 12.2503 9.33329H1.75033C1.42816 9.33329 1.16699 9.07213 1.16699 8.74996V5.24996ZM2.33366 5.83329V8.16663H4.66699V5.83329H2.33366ZM5.83366 5.83329V8.16663H8.16699V5.83329H5.83366ZM9.33366 5.83329V8.16663H11.667V5.83329H9.33366Z"
        fill="#121224"
      />
      <path d="M2.33301 8.16671V5.83337H4.66634V8.16671H2.33301Z" fill="#F4AF47" />
      <path d="M5.83301 8.16671V5.83337H8.16634V8.16671H5.83301Z" fill="#F4AF47" />
      <path d="M9.33301 8.16671V5.83337H11.6663V8.16671H9.33301Z" fill="#F4AF47" />
    </svg>
  );
}

function PausedMetricIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M3.64 1.96009H2.485V0.980088C2.485 0.903088 2.422 0.840088 2.345 0.840088H1.435C1.358 0.840088 1.295 0.903088 1.295 0.980088V1.96009H0.14C0.063 1.96009 0 2.02309 0 2.10009V11.9001C0 11.9771 0.063 12.0401 0.14 12.0401H1.295V13.0201C1.295 13.0971 1.358 13.1601 1.435 13.1601H2.345C2.422 13.1601 2.485 13.0971 2.485 13.0201V12.0401H3.64C3.717 12.0401 3.78 11.9771 3.78 11.9001V2.10009C3.78 2.02309 3.717 1.96009 3.64 1.96009ZM2.59 10.8501H1.19V3.15009H2.59V10.8501ZM13.86 3.22009H12.705V1.54009C12.705 1.46309 12.642 1.40009 12.565 1.40009H11.655C11.578 1.40009 11.515 1.46309 11.515 1.54009V3.22009H10.36C10.283 3.22009 10.22 3.28309 10.22 3.36009V10.6401C10.22 10.7171 10.283 10.7801 10.36 10.7801H11.515V12.4601C11.515 12.5371 11.578 12.6001 11.655 12.6001H12.565C12.642 12.6001 12.705 12.5371 12.705 12.4601V10.7801H13.86C13.937 10.7801 14 10.7171 14 10.6401V3.36009C14 3.28309 13.937 3.22009 13.86 3.22009ZM12.81 9.59009H11.41V4.41009H12.81V9.59009ZM8.75 5.11009H7.595V2.10009C7.595 2.02309 7.532 1.96009 7.455 1.96009H6.545C6.468 1.96009 6.405 2.02309 6.405 2.10009V5.11009H5.25C5.173 5.11009 5.11 5.17309 5.11 5.25009V8.75009C5.11 8.82709 5.173 8.89009 5.25 8.89009H6.405V11.9001C6.405 11.9771 6.468 12.0401 6.545 12.0401H7.455C7.532 12.0401 7.595 11.9771 7.595 11.9001V8.89009H8.75C8.827 8.89009 8.89 8.82709 8.89 8.75009V5.25009C8.89 5.17309 8.827 5.11009 8.75 5.11009ZM7.7 7.64759C7.7 7.66151 7.69447 7.67487 7.68462 7.68471C7.67478 7.69456 7.66142 7.70009 7.6475 7.70009H6.3525C6.33858 7.70009 6.32522 7.69456 6.31538 7.68471C6.30553 7.67487 6.3 7.66151 6.3 7.64759V6.35259C6.3 6.33866 6.30553 6.32531 6.31538 6.31546C6.32522 6.30562 6.33858 6.30009 6.3525 6.30009H7.6475C7.66142 6.30009 7.67478 6.30562 7.68462 6.31546C7.69447 6.32531 7.7 6.33866 7.7 6.35259V7.64759Z"
        fill="#121224"
      />
    </svg>
  );
}

function PausedTrainingFailureIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16699 5.25008C1.16699 4.92792 1.42816 4.66675 1.75033 4.66675H12.2503C12.5725 4.66675 12.8337 4.92792 12.8337 5.25008V8.75008C12.8337 9.07225 12.5725 9.33341 12.2503 9.33341H1.75033C1.42816 9.33341 1.16699 9.07225 1.16699 8.75008V5.25008ZM2.33366 5.83341V8.16675H4.66699V5.83341H2.33366ZM5.83366 5.83341V8.16675H8.16699V5.83341H5.83366ZM9.33366 5.83341V8.16675H11.667V5.83341H9.33366Z"
        fill="#B80739"
      />
    </svg>
  );
}

function PausedSourceIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.021 1.1665C8.34316 1.1665 8.60433 1.42767 8.60433 1.74984V4.08317C8.60433 4.40534 8.34316 4.6665 8.021 4.6665C7.69883 4.6665 7.43766 4.40534 7.43766 4.08317V3.49984H1.60433C1.28216 3.49984 1.021 3.23867 1.021 2.9165C1.021 2.59434 1.28216 2.33317 1.60433 2.33317H7.43766V1.74984C7.43766 1.42767 7.69883 1.1665 8.021 1.1665ZM9.771 2.9165C9.771 2.59434 10.0322 2.33317 10.3543 2.33317H12.1043C12.4265 2.33317 12.6877 2.59434 12.6877 2.9165C12.6877 3.23867 12.4265 3.49984 12.1043 3.49984H10.3543C10.0322 3.49984 9.771 3.23867 9.771 2.9165ZM6.271 5.24984C6.59316 5.24984 6.85433 5.511 6.85433 5.83317V6.4165H12.6877C13.0098 6.4165 13.271 6.67767 13.271 6.99984C13.271 7.322 13.0098 7.58317 12.6877 7.58317H6.85433V8.1665C6.85433 8.48867 6.59316 8.74984 6.271 8.74984C5.94883 8.74984 5.68766 8.48867 5.68766 8.1665V5.83317C5.68766 5.511 5.94883 5.24984 6.271 5.24984ZM1.021 6.99984C1.021 6.67767 1.28216 6.4165 1.60433 6.4165H3.93766C4.25983 6.4165 4.521 6.67767 4.521 6.99984C4.521 7.322 4.25983 7.58317 3.93766 7.58317H1.60433C1.28216 7.58317 1.021 7.322 1.021 6.99984ZM8.021 9.33317C8.34316 9.33317 8.60433 9.59434 8.60433 9.9165V12.2498C8.60433 12.572 8.34316 12.8332 8.021 12.8332C7.69883 12.8332 7.43766 12.572 7.43766 12.2498V11.6665H1.60433C1.28216 11.6665 1.021 11.4053 1.021 11.0832C1.021 10.761 1.28216 10.4998 1.60433 10.4998H7.43766V9.9165C7.43766 9.59434 7.69883 9.33317 8.021 9.33317ZM9.771 11.0832C9.771 10.761 10.0322 10.4998 10.3543 10.4998H12.1043C12.4265 10.4998 12.6877 10.761 12.6877 11.0832C12.6877 11.4053 12.4265 11.6665 12.1043 11.6665H10.3543C10.0322 11.6665 9.771 11.4053 9.771 11.0832Z"
        fill="#121224"
      />
    </svg>
  );
}

function LoadingIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M7.58398 1.16666C7.58398 0.844495 7.32282 0.583328 7.00065 0.583328C6.67849 0.583328 6.41732 0.844495 6.41732 1.16666V2.33333C6.41732 2.65549 6.67849 2.91666 7.00065 2.91666C7.32282 2.91666 7.58398 2.65549 7.58398 2.33333V1.16666Z"
        fill="#121224"
      />
      <path
        d="M10.4225 2.23991C10.5836 1.9609 10.488 1.60414 10.209 1.44306C9.92998 1.28197 9.57322 1.37756 9.41214 1.65657L8.8288 2.66692C8.66772 2.94592 8.76331 3.30268 9.04232 3.46377C9.32132 3.62485 9.67808 3.52926 9.83917 3.25026L10.4225 2.23991Z"
        fill="#121224"
      />
      <path
        d="M12.5577 3.79166C12.7187 4.07067 12.6231 4.42743 12.3441 4.58851L11.3338 5.17184C11.0548 5.33293 10.698 5.23733 10.5369 4.95833C10.3759 4.67932 10.4714 4.32256 10.7504 4.16148L11.7608 3.57815C12.0398 3.41706 12.3966 3.51266 12.5577 3.79166Z"
        fill="#121224"
      />
      <path
        d="M11.6673 6.41666C11.3452 6.41666 11.084 6.67783 11.084 7C11.084 7.32216 11.3452 7.58333 11.6673 7.58333H12.834C13.1562 7.58333 13.4173 7.32216 13.4173 7C13.4173 6.67783 13.1562 6.41666 12.834 6.41666H11.6673Z"
        fill="#121224"
      />
      <path
        d="M10.5369 9.04166C10.698 8.76266 11.0548 8.66706 11.3338 8.82815L12.3441 9.41148C12.6231 9.57256 12.7187 9.92932 12.5577 10.2083C12.3966 10.4873 12.0398 10.5829 11.7608 10.4218L10.7504 9.83851C10.4714 9.67743 10.3759 9.32067 10.5369 9.04166Z"
        fill="#121224"
      />
      <path
        d="M9.83917 10.7498C9.67808 10.4708 9.32132 10.3752 9.04232 10.5363C8.76331 10.6974 8.66772 11.0541 8.8288 11.3331L9.41214 12.3435C9.57322 12.6225 9.92998 12.7181 10.209 12.557C10.488 12.3959 10.5836 12.0392 10.4225 11.7602L9.83917 10.7498Z"
        fill="#121224"
      />
      <path
        d="M7.00065 11.0833C7.32282 11.0833 7.58398 11.3445 7.58398 11.6667V12.8333C7.58398 13.1555 7.32282 13.4167 7.00065 13.4167C6.67849 13.4167 6.41732 13.1555 6.41732 12.8333V11.6667C6.41732 11.3445 6.67849 11.0833 7.00065 11.0833Z"
        fill="#121224"
      />
      <path
        d="M5.1725 11.3331C5.33358 11.0541 5.23799 10.6974 4.95898 10.5363C4.67998 10.3752 4.32322 10.4708 4.16214 10.7498L3.5788 11.7602C3.41772 12.0392 3.51331 12.3959 3.79232 12.557C4.07132 12.7181 4.42808 12.6225 4.58917 12.3435L5.1725 11.3331Z"
        fill="#121224"
      />
      <path
        d="M3.46435 9.04166C3.62544 9.32066 3.52985 9.67742 3.25084 9.83851L2.24049 10.4218C1.96149 10.5829 1.60473 10.4873 1.44364 10.2083C1.28256 9.92933 1.37815 9.57257 1.65715 9.41148L2.6675 8.82815C2.94651 8.66706 3.30327 8.76266 3.46435 9.04166Z"
        fill="#121224"
      />
      <path
        d="M1.16732 6.41666C0.845152 6.41666 0.583984 6.67783 0.583984 7C0.583984 7.32216 0.845152 7.58333 1.16732 7.58333H2.33398C2.65615 7.58333 2.91732 7.32216 2.91732 7C2.91732 6.67783 2.65615 6.41666 2.33398 6.41666H1.16732Z"
        fill="#121224"
      />
      <path
        d="M1.44364 3.79166C1.60473 3.51266 1.96149 3.41706 2.24049 3.57815L3.25084 4.16148C3.52985 4.32257 3.62544 4.67933 3.46435 4.95833C3.30327 5.23733 2.94651 5.33293 2.6675 5.17184L1.65715 4.58851C1.37815 4.42742 1.28256 4.07066 1.44364 3.79166Z"
        fill="#121224"
      />
      <path
        d="M4.58916 1.65657C4.42808 1.37756 4.07132 1.28197 3.79232 1.44306C3.51331 1.60414 3.41772 1.9609 3.5788 2.23991L4.16214 3.25026C4.32322 3.52926 4.67998 3.62485 4.95899 3.46377C5.23799 3.30268 5.33358 2.94592 5.1725 2.66692L4.58916 1.65657Z"
        fill="#121224"
      />
    </svg>
  );
}

// Statuses that are not represented in the run status.
export const MonitorBadgeStatus = {
  OFFLINE: "offline",
};

export function getMonitorBadgeStatus(monitor) {
  if (typeof monitor.status?.createdTs !== "number") {
    return MonitorBadgeStatus.OFFLINE;
  }
  return monitor.status.displayStatus;
}

export function monitorStatusBadgeConfig(monitor) {
  switch (getMonitorBadgeStatus(monitor)) {
    case MonitorBadgeStatus.OFFLINE:
      return {
        Icon: OfflineIcon,
        title: "Offline",
      };
    case MonitorRunStatus.OK:
    case MonitorRunStatus.LIVE:
      return {
        Icon: LiveIcon,
        title: "Live",
      };
    case MonitorRunStatus.RESUMING:
      return {
        Icon: LiveIcon,
        title: "Live",
        tooltip: "Resuming by user",
      };
    case MonitorRunStatus.PARTIALLY_LIVE:
      return {
        Icon: LiveIcon,
        title: "Live",
        tooltip: "Partially live",
      };
    case MonitorRunStatus.PAUSED_TRAINING_FAILURE:
      return {
        Icon: PausedTrainingFailureIcon,
        title: "Error",
        tooltip: monitor.status?.training?.trainingError
          ? `Training error: ${monitor.status.training.trainingError}`
          : "Training error",
        color: NextGenPalette.criticalRed,
      };
    case MonitorRunStatus.PAUSED_TRAINING_FAILURE_INSUFFICIENT_DATA:
      return {
        Icon: WarningOutlined,
        title: "Insufficient training data",
        tooltip: monitor.status?.training?.trainingError
          ? `Training error: ${monitor.status.training.trainingError}`
          : "Training error",
        color: NextGenPalette.brandYellow,
      };
    case MonitorRunStatus.TRAINING_IN_PROGRESS:
      return {
        Icon: TrainingIcon,
        title: "Training",
      };
    case MonitorRunStatus.EXCEPTION:
      return {
        Icon: ErrorIcon,
        title: "Error",
        tooltip: "Exception",
        color: NextGenPalette.criticalRed,
      };
    case MonitorRunStatus.PAUSED_BY_USER:
      return {
        Icon: PausedIcon,
        title: "Paused",
        tooltip: "Paused by user",
      };
    case MonitorRunStatus.PAUSED_METRIC_PAUSE:
      return {
        Icon: PausedMetricIcon,
        title: "Paused",
        tooltip: "Paused by metric",
      };
    case MonitorRunStatus.PAUSED_SOURCE:
      return {
        Icon: PausedSourceIcon,
        title: "Paused",
        tooltip: "Paused by governance",
      };
    case MonitorRunStatus.LOADING:
      return {
        Icon: LoadingIcon,
        title: "Loading",
      };
    default:
      return {
        Icon: () => null,
        title: "",
        tooltip: "",
      };
  }
}

function SliceTrainingTooltipContent(props) {
  const { monitor } = props;
  const { title, tooltip, ...badgeProps } = monitorStatusBadgeConfig(monitor);
  const { totalSlice = 0, trainedSlice = 0 } = monitor.status?.training ?? {};
  const details = {
    Trained: trainedSlice,
    Error: totalSlice - trainedSlice,
  };

  return (
    <div className="slice-training-tooltip">
      <StatusBadge title={tooltip ?? title} {...badgeProps} />
      <div className="slice-training-tooltip-summary">
        {trainedSlice} of {totalSlice} slices trained
      </div>
      <div className="slice-training-tooltip-details">
        {Object.entries(details).map(([label, value]) => (
          <div className="slice-training-tooltip-details-row" key={label}>
            <span>{label}</span>
            <span className="slice-training-tooltip-details-value">{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function MonitorStatusBadge(props) {
  const {
    monitor,
    clickable,
    enableSlicingPopover = true,
    onClick,
    buttonStyle = false,
  } = props;
  const { totalSlice = 0, isSlicing } = monitor.status?.training ?? {};
  const { tooltip, ...badgeProps } = monitorStatusBadgeConfig(monitor);

  const showSlicingPopover = isSlicing && totalSlice > 0;

  const badge = (
    <StatusBadge
      onClick={onClick}
      clickable={clickable}
      tooltip={!showSlicingPopover ? tooltip : null}
      closeTooltipOnClick
      buttonStyle={buttonStyle}
      {...badgeProps}
    />
  );

  return enableSlicingPopover && showSlicingPopover ? (
    <Popover
      content={<SliceTrainingTooltipContent monitor={monitor} />}
      placement="bottomLeft"
      trigger={["hover", "click"]}
    >
      <div>{badge}</div>
    </Popover>
  ) : (
    badge
  );
}

export default MonitorStatusBadge;
