export function HourglassIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 9.04159C4.92783 9.04159 4.66667 9.30275 4.66667 9.62492C4.66667 9.94709 4.92783 10.2083 5.25 10.2083H5.54167C5.86383 10.2083 6.125 9.94709 6.125 9.62492C6.125 9.30275 5.86383 9.04159 5.54167 9.04159H5.25Z"
        fill="#121224"
      />
      <path
        d="M8.08858 9.1099C8.31639 8.8821 8.68574 8.8821 8.91354 9.1099L9.11978 9.31614C9.34758 9.54394 9.34758 9.91329 9.11978 10.1411C8.89197 10.3689 8.52263 10.3689 8.29482 10.1411L8.08858 9.93486C7.86078 9.70705 7.86078 9.33771 8.08858 9.1099Z"
        fill="#121224"
      />
      <path
        d="M7 10.4999C6.67783 10.4999 6.41667 10.7611 6.41667 11.0833C6.41667 11.4054 6.67783 11.6666 7 11.6666H7.29167C7.61383 11.6666 7.875 11.4054 7.875 11.0833C7.875 10.7611 7.61383 10.4999 7.29167 10.4999H7Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 1.16659C1.75 0.844419 2.01117 0.583252 2.33333 0.583252H11.6667C11.9888 0.583252 12.25 0.844419 12.25 1.16659C12.25 1.48875 11.9888 1.74992 11.6667 1.74992H11.0833V4.66659C11.0833 4.80693 11.0327 4.94257 10.9408 5.04863L9.43034 6.79149L10.9005 8.18C11.0172 8.29019 11.0833 8.4436 11.0833 8.60409V12.2499H11.6667C11.9888 12.2499 12.25 12.5111 12.25 12.8333C12.25 13.1554 11.9888 13.4166 11.6667 13.4166H10.5016L10.5 13.4166L10.4984 13.4166H2.33333C2.01117 13.4166 1.75 13.1554 1.75 12.8333C1.75 12.5111 2.01117 12.2499 2.33333 12.2499H2.91667V8.74992C2.91667 8.59521 2.97812 8.44684 3.08752 8.33744L4.63039 6.79457L3.06641 5.05682C2.97001 4.9497 2.91667 4.81069 2.91667 4.66659V1.74992H2.33333C2.01117 1.74992 1.75 1.48875 1.75 1.16659ZM4.08333 1.74992H9.91667V4.44898L8.58139 5.98969L8.27553 5.70083C8.04131 5.47962 7.67211 5.49017 7.45091 5.72439C7.2297 5.95861 7.24025 6.3278 7.47447 6.54901L8.09045 7.13077C8.12307 7.19136 8.16704 7.24716 8.22212 7.2949C8.26395 7.33116 8.30947 7.3604 8.35725 7.38275L9.91667 8.85553V12.2499H4.08333V8.99154L5.41193 7.66295L5.69141 7.97348C5.90693 8.21295 6.27576 8.23236 6.51523 8.01684C6.75469 7.80132 6.77411 7.43249 6.55859 7.19302L4.08333 4.44274V1.74992Z"
        fill="#121224"
      />
    </svg>
  );
}
