import React, { useState } from "react";
import Popover from "../popover";
import { isTriggeredCollectionMode } from "../../utils/general";
import { dataCollectionOptions, getOptionByValue } from "../../utils/options";
import {
  aggregationWindowOptions,
  autoMetricAggregationWindowOptions,
  getMetricTypeFromConfigData,
} from "../metric/utils";
import { getDisplayTimeFromSecond, getStringFromTimeStamp } from "../../utils/time";
import { MetricCategory } from "../../utils/enums";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MetricCollectionSummary from "../metric-collection-summary";
import {
  isFullTableMetric,
  isTriggeredMetric,
  getMetricQueryScopeLabel,
} from "../../utils/metric";

import "./metric-config-summary-popover.scss";

function MetricConfigSummaryPopover(props) {
  const { children, metric } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);

  const metricType = getMetricTypeFromConfigData(metric);
  const isFullTable = isFullTableMetric(metric);
  const isDataDelay = metricType === MetricCategory.DATA_DELAY;
  const isFullCompare = metricType === MetricCategory.FULL_COMPARE;
  const isTriggered = isTriggeredMetric(metric);

  const metricUpdatedField = {
    label: "Last collected",
    value: metric.status.lastScannedTs
      ? getStringFromTimeStamp(metric.status.lastScannedTs)
      : "Never",
  };

  const fields = isDataDelay
    ? [metricUpdatedField]
    : [
        {
          label: "Query scope",
          value: getMetricQueryScopeLabel(metric),
        },
        {
          label: "Aggregation interval",
          value:
            !isFullTable &&
            getOptionByValue(
              aggregationWindowOptions,
              metric.config.aggregation?.aggregationWindow ||
                metric.config.aggregationWindow
            )?.label,
        },
        {
          label: "Polling interval",
          value:
            isFullTable &&
            !isTriggeredCollectionMode(metric.config.collectionMode) &&
            getOptionByValue(
              autoMetricAggregationWindowOptions,
              metric.config.pollingWindow
            )?.label,
        },
        {
          label: "Data collection",
          value: getOptionByValue(
            dataCollectionOptions,
            metric.config.collectionMode.type
          )?.label,
        },
        {
          label: "Evaluation delay",
          value:
            !isFullCompare &&
            !isFullTable &&
            getMetricTypeFromConfigData(metric) !== MetricCategory.DATA_DELAY &&
            getDisplayTimeFromSecond(metric.config.synchronizationDelay),
        },
        {
          label: "Polling delay",
          value:
            isFullTable &&
            !isTriggeredCollectionMode(metric.config.collectionMode) &&
            getDisplayTimeFromSecond(metric.config.pollingDelay),
        },
        metricUpdatedField,
      ];

  const triggerFields = [
    {
      label: "Last trigger received",
      value:
        isTriggered &&
        (metric.status.triggered?.maxTriggeredTs
          ? getStringFromTimeStamp(metric.status.triggered.maxTriggeredTs)
          : "N/A"),
    },
    {
      label: "Last trigger status",
      value: isTriggered && metric.status.triggered?.triggerRunStatus,
    },
  ];

  const nonEmptyFields = fields.filter(({ value }) => Boolean(value));
  const nonEmptyTriggerFields = triggerFields.filter(({ value }) => Boolean(value));

  const popoverContent = (
    <div className="metric-config-summary-popover">
      {nonEmptyFields.length > 0 && (
        <div>
          {nonEmptyFields.map(({ label, value }) => (
            <div key={label}>
              {label} - {value}
            </div>
          ))}
        </div>
      )}
      {nonEmptyTriggerFields.length > 0 && (
        <div className="metric-config-summary-popover-trigger-fields">
          {nonEmptyTriggerFields.map(({ label, value }) => (
            <div key={label}>
              {label} - {value}
            </div>
          ))}
        </div>
      )}
      <MetricCollectionSummary
        workspaceUuid={metric.metadata.workspaceId}
        metric={metric}
        placement="topLeft"
      >
        <div
          className="metric-config-summary-collection"
          onClick={() => setPopoverOpen(false)}
        >
          <QuestionCircleOutlined />
          Metric collection
        </div>
      </MetricCollectionSummary>
    </div>
  );
  return (
    <Popover
      trigger="hover"
      content={popoverContent}
      overlayClassName="metric-config-summary-popover-overlay"
      placement="bottomLeft"
      visible={popoverOpen}
      onVisibleChange={setPopoverOpen}
    >
      <div
        data-testid="metric-config-summary-popover"
        className="metric-config-summary-popover-trigger"
      >
        {children}
      </div>
    </Popover>
  );
}

export default MetricConfigSummaryPopover;
