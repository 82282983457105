import { EVENT, getTableDetailProps, trackEvent } from "../../utils/telemetry";

export const trackTreePathClick = (pathLevel, datasource, schema, table) => {
  if (pathLevel === "table") {
    trackEvent(
      EVENT.SELECT_TABLE_BREADCRUMB,
      getTableDetailProps(table, schema, datasource)
    );
  }

  if (pathLevel === "schema") {
    trackEvent(
      EVENT.SELECT_SCHEMA_BREADCRUMB,
      getTableDetailProps(table, schema, datasource)
    );
  }

  if (pathLevel === "datasource") {
    trackEvent(
      EVENT.SELECT_DATASOURCE_BREADCRUMB,
      getTableDetailProps(table, schema, datasource)
    );
  }
};
