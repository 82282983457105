import { MetricDataAssetType, MetricCategory } from "../../../../../utils/enums";
import { getMetricTypeFromConfigData } from "../../../utils";
import { DataAssetCards } from "../../../fields";
import ConfigureMetric from "../../../fields/configure-metric";
import DataAssetSelect from "../../../fields/data-asset-select";

function ActivityMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    onDataSourceChanged,
    tableList,
    schemaList,
    onTableChanged,
    columnList: { data: columnList },
    columnValueList,
    openWorkspaceTakeover,
    closeTakeover,
    partitionSampleData,
    getPartitionSampleData,
    resetKPIStatus,
    waffle,
  } = props;

  const isEditMode = !configData.config.inheritTableSettings;
  const isStreaming = configData.config.isStreaming;
  const isCategoryActivity =
    MetricCategory.CATEGORY_ACTIVITY === getMetricTypeFromConfigData(configData);

  return (
    <DataAssetCards>
      <DataAssetSelect
        dataAssetType={MetricDataAssetType.TABLE}
        configData={configData}
        dataSourceList={dataSourceList}
        schemaList={schemaList}
        tableList={tableList}
        isStreaming={isStreaming}
        resetKPIStatus={resetKPIStatus}
        onConfigDataChange={onConfigDataChange}
        onDataSourceChanged={onDataSourceChanged}
        onTableChanged={onTableChanged}
        disabled
      />
      <ConfigureMetric
        isValidConfig={isValidConfig}
        enableBackfillDuration={isCategoryActivity}
        enableDataCollectionSchedule={false}
        enableDataCollectionWindow={isCategoryActivity}
        enablePartitionConfig={isCategoryActivity}
        enableQueryScope={false}
        enableSeasonality={false}
        enableSlicing={false}
        enableSynchronizationDelay={isCategoryActivity}
        enableTimestampColumn={isCategoryActivity}
        allowOverride={false}
        configData={configData}
        isStreaming={isStreaming}
        dataSourceList={dataSourceList}
        tableList={tableList}
        columnList={columnList}
        columnValueList={columnValueList}
        isEditMode={isEditMode}
        onConfigDataChange={onConfigDataChange}
        openWorkspaceTakeover={openWorkspaceTakeover}
        closeTakeover={closeTakeover}
        partitionSampleData={partitionSampleData}
        getPartitionSampleData={getPartitionSampleData}
        waffle={waffle}
        enableMissingValueFilling={false}
      />
    </DataAssetCards>
  );
}

ActivityMetricDataAssetConfig.assetMeta = {
  title: "Select data asset",
  createDescription:
    "Create your metric by selecting the type, naming it, picking a data asset, and configuring the settings",
  editDescription:
    "Edit your metric by naming it, picking a data asset, and configuring the settings",
};

export default ActivityMetricDataAssetConfig;
