import * as types from "./draft-action-types";

import { getKpiListPromise, deleteKpiPromise, updateKpiPromise } from "../../rest/kpi";

function setDraftList(data) {
  return { type: types.SET_DRAFT_LIST, draftList: data };
}

export function getDraftList(workspaceUuid, options = {}) {
  const { quiet = false } = options;
  return (dispatch, getState) => {
    if (!quiet) {
      dispatch(setDraftList({ loading: true, data: [] }));
    }
    getKpiListPromise(workspaceUuid, { mode: ["draft"] })
      .then(function (newDraftList) {
        dispatch(
          setDraftList({
            loading: false,
            data: newDraftList,
          })
        );
      })
      .catch(function (error) {
        console.log(`Fail to load draft list for ${error}`);
        dispatch(setDraftList({ loading: false, data: [] }));
      });
  };
}

export function updateDraft(workspaceUuid, newDraft) {
  return (dispatch, getState) => {
    return updateKpiPromise(workspaceUuid, newDraft.metadata.uuid, newDraft)
      .then(function (newDraft) {
        dispatch(getDraftList(workspaceUuid, { quiet: true }));
      })
      .catch(function (error) {
        console.log(`Fail to update draft for ${error}`);
      });
  };
}

export function deleteDraft(workspaceUuid, draft) {
  return (dispatch, getState) => {
    return deleteKpiPromise(workspaceUuid, draft.metadata.uuid)
      .then(function () {
        dispatch(getDraftList(workspaceUuid, { quiet: true }));
      })
      .catch(function (error) {
        console.log(`Fail to delete draft for ${error}`);
      });
  };
}
