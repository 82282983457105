import { capitalizeFirstLetter } from "../../../utils/general";
import { decamelize } from "../../../utils/search";
import { fnSorter } from "../../../utils/sort";
import { dataSourceFields } from "./use-search-datasource-config";
import { incidentFields } from "./use-search-incidents-config";
import { integrationFields } from "./use-search-integrations-config";
import { metricFields } from "./use-search-metrics-config";
import { monitorFields } from "./use-search-monitors-config";
import { notificationFields } from "./use-search-notifications-config";
import { scheduleFields } from "./use-search-schedule-config";
import { tableFields } from "./use-search-table-config";
import { draftFields } from "./use-search-draft-config";

const sortFunctions = {
  [metricFields.METRIC_ID]: fnSorter((id) => parseInt(id)),
  [monitorFields.MONITOR_ID]: fnSorter((id) => parseInt(id)),
  [incidentFields.INCIDENT_ID]: fnSorter((id) => parseInt(id)),
  [draftFields.DRAFT_ID]: fnSorter((id) => parseInt(id)),
};

// Map of field names to their tag label when different from the field name
const tagLabels = {
  [metricFields.KPI_NAME]: "metric name",
  [metricFields.TAG_NAME]: "tag",
  [metricFields.TABLE_NAME]: "table",
  [metricFields.DATA_SOURCE_NAME]: "datasource",
  [metricFields.COLUMN_NAME]: "column",
  [metricFields.CREATION_TYPE]: "metric class",
  [monitorFields.MONITOR_STATUS]: "status",
  [incidentFields.INCIDENT_PROGRESS]: "progress",
  [draftFields.DRAFT_ID]: "id",
  [draftFields.DRAFT_NAME]: "name",
  [draftFields.DRAFT_TYPE]: "type",
  [draftFields.DRAFT_STATUS]: "status",
};

export const getSearchFieldConfig = (fieldNames) => {
  return fieldNames.map((fieldName) => {
    const displayName = tagLabels[fieldName] || decamelize(fieldName);
    const field = {
      prefix: `${fieldName}_`,
      fieldName: fieldName,
      tagLabel: `${displayName}:`,
      title: capitalizeFirstLetter(displayName),
    };
    const sortFn = sortFunctions[fieldName];
    if (sortFn) {
      field.sortFn = sortFn;
    }
    return field;
  });
};

export const searchFieldConfig = getSearchFieldConfig([
  ...new Set([
    ...Object.values(metricFields),
    ...Object.values(monitorFields),
    ...Object.values(incidentFields),
    ...Object.values(dataSourceFields),
    ...Object.values(notificationFields),
    ...Object.values(integrationFields),
    ...Object.values(scheduleFields),
    ...Object.values(tableFields),
    ...Object.values(draftFields),
  ]),
]);
