import React from "react";

function NoDataIcon(_props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 9.375C7.5 8.68464 8.05964 8.125 8.75 8.125H21.25C21.9404 8.125 22.5 8.68464 22.5 9.375C22.5 10.0654 21.9404 10.625 21.25 10.625H8.75C8.05964 10.625 7.5 10.0654 7.5 9.375Z"
        fill="#121224"
      />
      <path
        d="M8.75 13.125C8.05964 13.125 7.5 13.6846 7.5 14.375C7.5 15.0654 8.05964 15.625 8.75 15.625H13.75C14.4404 15.625 15 15.0654 15 14.375C15 13.6846 14.4404 13.125 13.75 13.125H8.75Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.625 2.5C3.89911 2.5 2.5 3.89911 2.5 5.625V24.375C2.5 26.1009 3.89913 27.5 5.625 27.5H24.375C26.1009 27.5 27.5 26.1009 27.5 24.375V5.625C27.5 3.89913 26.1009 2.5 24.375 2.5H5.625ZM5 5.625C5 5.27982 5.27982 5 5.625 5H24.375C24.7202 5 25 5.27981 25 5.625V24.375C25 24.7202 24.7202 25 24.375 25H5.625C5.27981 25 5 24.7202 5 24.375V5.625Z"
        fill="#121224"
      />
      <path
        d="M23.1232 17.5957C23.6557 17.0632 23.6557 16.1998 23.1232 15.6673C22.5907 15.1348 21.7273 15.1348 21.1947 15.6673L19.4317 17.4303L17.6687 15.6673C17.1362 15.1348 16.2727 15.1348 15.7402 15.6673C15.2077 16.1998 15.2077 17.0632 15.7402 17.5957L17.5032 19.3588L15.7402 21.1218C15.2077 21.6543 15.2077 22.5177 15.7402 23.0503C16.2727 23.5828 17.1362 23.5828 17.6687 23.0503L19.4317 21.2872L21.1947 23.0503C21.7273 23.5828 22.5907 23.5828 23.1232 23.0503C23.6557 22.5177 23.6557 21.6543 23.1232 21.1218L21.3602 19.3588L23.1232 17.5957Z"
        fill="#121224"
      />
    </svg>
  );
}

export default NoDataIcon;
