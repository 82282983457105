import React from "react";
import {
  IncidentDirectionUpIcon,
  IncidentDirectionDownIcon,
  IncidentDirectionBothIcon,
} from "../../components/icons/incident-direction-icon";

import { IncidentDirection, IncidentScore } from "../../utils/enums";

import "./incident-summary-icon.scss";

function IncidentSummaryIcon({
  direction = IncidentDirection.UP,
  number = 0,
  score = IncidentScore.NORMAL,
}) {
  if (number === 0) {
    return null;
  }

  let svgIcon = null;
  if (direction === IncidentDirection.UP) {
    svgIcon = <IncidentDirectionUpIcon />;
  } else if (direction === IncidentDirection.DOWN) {
    svgIcon = <IncidentDirectionDownIcon />;
  } else {
    svgIcon = <IncidentDirectionBothIcon />;
  }

  const extraClassName = score === IncidentScore.HIGH ? "high" : "normal";
  return (
    <div
      className={`incident-summary-icon-container lightup-horizon-flex-container ${extraClassName}`}
    >
      {number}
      {svgIcon}
    </div>
  );
}

export default IncidentSummaryIcon;
