import React from "react";
import NoDataIcon from "../icons/empty-state";

import "./zero-status-chart.scss";

function ZeroStatusChart(props) {
  const { height = "100%" } = props;

  return (
    <div
      className="zero-status-chart-container"
      style={{
        width: "100%",
        height,
      }}
    >
      <div className="zero-status-chart-inner">
        <NoDataIcon />
        <div>No data available</div>
      </div>
    </div>
  );
}

export default ZeroStatusChart;
