import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "../../atom/pagination";
import Search from "../../components/search/";
import IncidentTotalSummary from "./incident-total-summary";
import { sortIncidentList } from "../../utils/sort.jsx";
import {
  getSymptomTypeDisplayName,
  IncidentCategoryType,
  IncidentCreatorType,
  IncidentDirection,
  IncidentScore,
  MetricType,
  TakeoverWidth,
} from "../../utils/enums";
import { getKPIProfilerDisplayStr, getSliceDisplayString } from "../../utils/general";
import NgButton, { ButtonPaddingSize } from "../../components/button/ng-button";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import IncidentFindMetricPanel from "./incident-find-metric-panel";
import {
  closeTakeover,
  openWorkspaceTakeover,
} from "../../actions/takeover/takeover-action";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getIncidentSearchList,
  getLocalFilteredIncidentList,
} from "../incidents/incident-search";
import { getKPIDisplayStr } from "../../utils/incident";
import { getFullTableName } from "../../utils/datasource";

const defaultPageSize = 10;
const maxAnalysisListNumber = 10;

function SortDescendingIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M10.2589 1.86041C10.1353 1.86234 10.0175 1.91324 9.9314 2.00192C9.84527 2.09061 9.79785 2.20984 9.79954 2.33346V10.5402L8.72949 9.47017C8.686 9.42547 8.634 9.38994 8.57654 9.36567C8.51909 9.34141 8.45735 9.3289 8.39499 9.3289C8.30215 9.32892 8.21143 9.35663 8.13443 9.40849C8.05742 9.46034 7.99763 9.53398 7.9627 9.61999C7.92777 9.706 7.91928 9.80048 7.93832 9.89134C7.95736 9.9822 8.00307 10.0653 8.0696 10.1301L9.89525 11.9557C9.93886 12.0129 9.99509 12.0592 10.0596 12.0911C10.124 12.1229 10.195 12.1395 10.2669 12.1394C10.3388 12.1393 10.4097 12.1226 10.4741 12.0906C10.5385 12.0585 10.5946 12.0121 10.6381 11.9548L12.4628 10.1301C12.5076 10.0871 12.5434 10.0356 12.568 9.97857C12.5926 9.92158 12.6057 9.86024 12.6063 9.79815C12.6069 9.73607 12.5952 9.67448 12.5717 9.617C12.5482 9.55951 12.5135 9.50729 12.4696 9.46339C12.4257 9.41949 12.3735 9.38478 12.316 9.36132C12.2585 9.33785 12.1969 9.32608 12.1348 9.32672C12.0728 9.32735 12.0114 9.34036 11.9544 9.365C11.8974 9.38963 11.8459 9.42539 11.8029 9.47017L10.7329 10.5402V2.33346C10.7337 2.27102 10.7221 2.20905 10.6985 2.15121C10.675 2.09337 10.6401 2.04084 10.5959 1.99673C10.5517 1.95262 10.4991 1.91783 10.4412 1.89441C10.3833 1.871 10.3214 1.85943 10.2589 1.86041ZM3.27259 1.90234L2.23171 2.61054V3.5065L3.21882 2.84023H3.27259V5.99205H4.31257V1.90234H3.27259ZM3.65632 7.93346C2.70665 7.93346 2.07129 8.48313 2.07129 9.297V9.3134H3.02376V9.29335C3.02376 8.95595 3.26755 8.71822 3.61895 8.71822C3.95588 8.71822 4.17676 8.9199 4.17676 9.22043C4.17676 9.46124 4.02449 9.66771 3.42389 10.223L2.13145 11.4389V12.1335H5.23952V11.3232H3.51686V11.2694L4.19134 10.6487C4.91701 10.0024 5.18301 9.60021 5.18301 9.14661C5.18301 8.42421 4.57192 7.93346 3.65632 7.93346Z"
        fill="#121224"
      />
    </svg>
  );
}

function SortAscendingIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M3.75806 2.1875L3.69226 2.52844C3.69226 2.52844 3.6185 2.78226 3.44788 3.03601C3.27288 3.28976 3.05375 3.5 2.625 3.5V4.375C3.22875 4.375 3.64 4.07743 3.9375 3.75806V6.5625H4.8125V2.1875H3.75806ZM10.0625 2.40625L9.74719 2.70789L7.875 4.59375L8.49194 5.21069L9.625 4.07336V12.25H10.5V4.07336L11.6331 5.21069L12.25 4.59375L10.3778 2.70789L10.0625 2.40625ZM3.71875 7.4375C2.87875 7.4375 2.1875 8.12875 2.1875 8.96875V9.1875H3.0625V8.96875C3.0625 8.58375 3.33375 8.3125 3.71875 8.3125H4.15625C4.54125 8.3125 4.8125 8.58375 4.8125 8.96875C4.8125 9.17 4.65961 9.39743 4.40149 9.55493C3.86336 9.88743 3.39056 10.1018 2.99243 10.3206C2.79556 10.4343 2.61587 10.5397 2.45837 10.7059C2.30525 10.8722 2.1875 11.1213 2.1875 11.375V11.8125H5.6875V10.9375H3.69226C4.01164 10.7712 4.38337 10.6052 4.86462 10.3077C5.31087 10.0321 5.6875 9.555 5.6875 8.96875C5.6875 8.12875 4.99625 7.4375 4.15625 7.4375H3.71875Z"
        fill="#121224"
      />
    </svg>
  );
}

export function FindAnyMetricIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99982 0.551758C4.74466 0.551758 2.91648 2.37993 2.91648 4.6351C2.91648 4.95374 2.95298 5.26385 3.02202 5.56149C1.58627 6.19387 0.583008 7.62916 0.583008 9.30003C0.583008 11.5552 2.41118 13.3834 4.66634 13.3834C5.53368 13.3834 6.33786 13.1129 6.99917 12.6518C7.66063 13.1133 8.46573 13.3842 9.33315 13.3842C11.5883 13.3842 13.4165 11.5561 13.4165 9.30092C13.4165 7.63001 12.4132 6.19473 10.9774 5.56236C11.0466 5.26445 11.0831 4.95405 11.0831 4.6351C11.0831 2.37993 9.25497 0.551758 6.99982 0.551758ZM10.5521 6.65025C10.1197 7.41077 9.45016 8.01886 8.64404 8.37388C8.71318 8.67181 8.74967 8.98192 8.74967 9.30003C8.74967 10.2542 8.42241 11.1319 7.87401 11.8271C8.30314 12.0756 8.80121 12.2176 9.33315 12.2176C10.944 12.2176 12.2498 10.9117 12.2498 9.30092C12.2498 8.12597 11.5547 7.11209 10.5521 6.65025ZM7.51833 8.68582C7.34852 8.70734 7.17547 8.71843 6.99982 8.71843C5.47718 8.71843 4.14919 7.88502 3.44712 6.64949C2.44466 7.11136 1.74967 8.12518 1.74967 9.30003C1.74967 10.9109 3.05551 12.2167 4.66634 12.2167C6.27716 12.2167 7.583 10.9109 7.583 9.30003C7.583 9.08896 7.56067 8.88354 7.51833 8.68582ZM4.08315 4.6351C4.08315 3.02426 5.38899 1.71842 6.99982 1.71842C8.61064 1.71842 9.91648 3.02426 9.91648 4.6351C9.91648 6.24592 8.61064 7.55177 6.99982 7.55177C5.38899 7.55177 4.08315 6.24592 4.08315 4.6351Z"
        fill="#121224"
      />
    </svg>
  );
}

function CollapsePanelIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M2.33337 2.625C2.0112 2.625 1.75004 2.88617 1.75004 3.20833C1.75004 3.5305 2.0112 3.79167 2.33337 3.79167H11.6667C11.9889 3.79167 12.25 3.5305 12.25 3.20833C12.25 2.88617 11.9889 2.625 11.6667 2.625H2.33337Z"
        fill="#121224"
      />
      <path
        d="M1.75293 7.05847C1.73606 6.89032 1.79204 6.71625 1.92085 6.58743L3.57076 4.9375C3.79856 4.70969 4.16791 4.70969 4.39572 4.93749C4.62352 5.1653 4.62353 5.53464 4.39572 5.76245L3.74152 6.41667H11.6667C11.9889 6.41667 12.25 6.67783 12.25 7C12.25 7.32217 11.9889 7.58333 11.6667 7.58333H3.74171L4.39572 8.23736C4.62353 8.46517 4.62352 8.83451 4.39572 9.06232C4.16791 9.29012 3.79856 9.29012 3.57076 9.06231L1.92589 7.41742C1.90786 7.39981 1.89096 7.38104 1.87531 7.36124C1.84266 7.31995 1.81639 7.27528 1.7965 7.22854C1.77387 7.17543 1.75888 7.11828 1.75293 7.05847Z"
        fill="#121224"
      />
      <path
        d="M1.75004 10.7917C1.75004 10.4695 2.0112 10.2083 2.33337 10.2083H11.6667C11.9889 10.2083 12.25 10.4695 12.25 10.7917C12.25 11.1138 11.9889 11.375 11.6667 11.375H2.33337C2.0112 11.375 1.75004 11.1138 1.75004 10.7917Z"
        fill="#121224"
      />
    </svg>
  );
}

class IncidentAnalysisView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kpiFilterSetting: {
        sortBy: "incidents",
        asc: false,
        localFilterSetting: {
          filterName: [],
          kpiName: [],
          dataSourceName: [],
          tableName: [],
          slice: [],
          tagName: [],
          searchName: [],
          showMyRule: false,
          showProceededDataOnly: true,
        },
        currentPage: 1,
        currentPageSize: defaultPageSize,
      },
      ruleFilterSetting: {
        sortBy: "incidents",
        asc: false,
        localFilterSetting: {
          filterName: [],
          kpiName: [],
          slice: [],
          tagName: [],
          searchName: [],
          showMyRule: false,
          showProceededDataOnly: true,
        },
        currentPage: 1,
        currentPageSize: defaultPageSize,
      },
      categorySetting: {
        [IncidentCategoryType.BEFORE]: false,
        [IncidentCategoryType.OVERLAP]: true,
        [IncidentCategoryType.AFTER]: false,
      },
      currentGroupIncidentListForKPI: null,
      currentMetaDataForKPI: null,
    };

    this.onGoToRuleLevel = this.onGoToRuleLevel.bind(this);
    this.onApplyCategorySetting = this.onApplyCategorySetting.bind(this);
    this.onLeftPanelCollapsedChange = this.onLeftPanelCollapsedChange.bind(this);
  }

  static getIncidentListItems(currentGroupIncidentList) {
    const currentGroupIncidentListItems = [];
    const currentGroupHashMap = {};
    for (let incidentItem of currentGroupIncidentList) {
      const topLevelKey = JSON.stringify({
        creatorUuid: incidentItem.creatorInfo.creatorUuid,
        slice: incidentItem.slice,
      });

      if (!currentGroupHashMap[topLevelKey]) {
        currentGroupHashMap[topLevelKey] = {
          kpiName: incidentItem.kpiName,
          kpi: incidentItem.kpi,
          creatorInfo: incidentItem.creatorInfo,
          slice: incidentItem.slice,
          incidentTopSummary: {
            [IncidentDirection.UP]: 0,
            [IncidentDirection.DOWN]: 0,
            [IncidentDirection.BOTH]: 0,
          },
          incidentScoreTopSummary: {
            [IncidentDirection.UP]: IncidentScore.NORMAL,
            [IncidentDirection.DOWN]: IncidentScore.NORMAL,
            [IncidentDirection.BOTH]: IncidentScore.NORMAL,
          },
          incidentDetails: [],
        };
        currentGroupIncidentListItems.push(currentGroupHashMap[topLevelKey]);
      }

      const incidentLowSummary = {
        [IncidentDirection.UP]: 0,
        [IncidentDirection.DOWN]: 0,
        [IncidentDirection.BOTH]: 0,
      };

      const incidentScoreLowSummary = {
        [IncidentDirection.UP]: IncidentScore.NORMAL,
        [IncidentDirection.DOWN]: IncidentScore.NORMAL,
        [IncidentDirection.BOTH]: IncidentScore.NORMAL,
      };

      for (let { direction, score } of incidentItem.incidents) {
        // For there is no direction for event incident. Set it to both temporarily.
        if (
          ![
            IncidentDirection.UP,
            IncidentDirection.DOWN,
            IncidentDirection.BOTH,
          ].includes(direction)
        ) {
          direction = IncidentDirection.BOTH;
        }

        incidentLowSummary[direction] += 1;
        if (score === IncidentScore.HIGH) {
          incidentScoreLowSummary[direction] = IncidentScore.HIGH;
        }
      }

      const { incidentTopSummary, incidentScoreTopSummary, incidentDetails } =
        currentGroupHashMap[topLevelKey];

      incidentDetails.push({
        ...incidentItem,
        incidentLowSummary,
        incidentScoreLowSummary,
      });

      for (let directionKey in incidentLowSummary) {
        incidentTopSummary[directionKey] += incidentLowSummary[directionKey];
        if (incidentScoreLowSummary[directionKey] === IncidentScore.HIGH) {
          incidentScoreTopSummary[directionKey] = IncidentScore.HIGH;
        }
      }
    }

    return currentGroupIncidentListItems;
  }

  onAddNewRule(currentIncidentItem, isAdded) {
    if (this.props.onChanged) {
      const { slice, creatorInfo } = currentIncidentItem;
      const currentCreatorInfo =
        creatorInfo.type === IncidentCreatorType.EVENT ? creatorInfo : "";
      this.props.onChanged({
        isAdded,
        filterUuid: creatorInfo.creatorUuid,
        slice,
        creatorInfo: currentCreatorInfo,
      });
    }
  }

  onApplyCategorySetting(categorySetting) {
    this.setState({ categorySetting });
  }

  getSearchResultList() {
    const { currentGroupIncidentListForKPI } = this.state;
    let currentGroupIncidentList = null;
    if (!currentGroupIncidentListForKPI) {
      currentGroupIncidentList = this.props.currentGroupIncidentList;
    } else {
      currentGroupIncidentList = currentGroupIncidentListForKPI;
    }

    const skipKey = JSON.stringify({
      id: this.props.filterUuid,
      slice: this.props.slice,
    });

    let filteredCurrentGroupIncidentList = currentGroupIncidentList.filter(
      (incidentsPerRule) => {
        const { slice, creatorInfo } = incidentsPerRule;
        const currentKey = JSON.stringify({
          id: creatorInfo.creatorUuid,
          slice,
        });

        return skipKey !== currentKey;
      }
    );

    const options = {
      enableKpi: !currentGroupIncidentListForKPI,
      enableSlice: !currentGroupIncidentListForKPI,
      enableId: false,
    };

    return getIncidentSearchList(filteredCurrentGroupIncidentList, options);
  }

  onSearchItemChange(newFilterObject) {
    const {
      dataSourceName = [],
      tableName = [],
      columnName = [],
      filterName = [],
      kpiName = [],
      slice = [],
      tagName = [],
      searchName = [],
    } = newFilterObject;

    const { currentGroupIncidentListForKPI, kpiFilterSetting, ruleFilterSetting } =
      this.state;
    if (!currentGroupIncidentListForKPI) {
      const { localFilterSetting } = kpiFilterSetting;
      const newLocalFilterSetting = {
        ...localFilterSetting,
        dataSourceName,
        tableName,
        columnName,
        filterName,
        kpiName,
        slice,
        tagName,
        searchName,
      };
      this.setState({
        kpiFilterSetting: {
          ...kpiFilterSetting,
          localFilterSetting: newLocalFilterSetting,
        },
      });
    } else {
      const { localFilterSetting } = ruleFilterSetting;
      const newLocalFilterSetting = {
        ...localFilterSetting,
        dataSourceName,
        tableName,
        columnName,
        filterName,
        kpiName,
        slice,
        tagName,
        searchName,
      };
      this.setState({
        ruleFilterSetting: {
          ...ruleFilterSetting,
          localFilterSetting: newLocalFilterSetting,
        },
      });
    }
  }

  onLeftPanelCollapsedChange(newCollapsed) {
    this.props.setLeftPanelCollapsed(newCollapsed);
  }

  getIncidentAnalysisSummaryView() {
    const { currentGroupIncidentListForKPI, kpiFilterSetting, ruleFilterSetting } =
      this.state;
    const {
      metrics,
      monitors,
      openWorkspaceTakeover,
      closeTakeover,
      onAddAnalysisIncidentItem,
      updateSliceValueList,
      selectedAnalysisList,
    } = this.props;

    let data = null;
    let placeholder = "";

    if (!currentGroupIncidentListForKPI) {
      data = kpiFilterSetting;
      placeholder =
        "You can search across datasources, tables, metrics, monitors, slice name or tags";
    } else {
      data = ruleFilterSetting;
      placeholder = "You can search across monitors and tags";
    }

    const setSortDirection = (asc) => {
      const filterSettingKey = currentGroupIncidentListForKPI
        ? "ruleFilterSetting"
        : "kpiFilterSetting";
      this.setState({
        [filterSettingKey]: {
          ...this.state[filterSettingKey],
          asc,
        },
      });
    };

    const menuItems = [
      {
        label: "Find a metric",
        icon: <FindAnyMetricIcon />,
        onClick: () => {
          openWorkspaceTakeover(
            <IncidentFindMetricPanel
              metrics={metrics}
              monitors={monitors}
              closeTakeover={closeTakeover}
              onAdd={onAddAnalysisIncidentItem}
              updateSliceValueList={updateSliceValueList}
            />,
            TakeoverWidth.NORMAL,
            () => closeTakeover()
          );
        },
        disabled: selectedAnalysisList.length >= maxAnalysisListNumber,
      },
      {
        label: "Sort high to low",
        icon: <SortDescendingIcon />,
        onClick: () => setSortDirection(false),
      },
      {
        label: "Sort low to high",
        icon: <SortAscendingIcon />,
        onClick: () => setSortDirection(true),
      },
      {
        label: "Collapse panel",
        icon: <CollapsePanelIcon />,
        onClick: () => this.onLeftPanelCollapsedChange(true),
      },
    ];

    return (
      <div className="incident-analysis-summary-container">
        <div className="incident-analysis-search-container">
          <Search
            localFilterSetting={data.localFilterSetting}
            selectionOptionList={this.getSearchResultList()}
            onChange={this.onSearchItemChange.bind(this)}
            placeholder={placeholder}
          />
        </div>
        <NgDropdownMenu
          buttonText="Actions"
          buttonProps={{ size: "large", paddingSize: ButtonPaddingSize.SMALL }}
          menuItems={menuItems}
        />
      </div>
    );
  }

  onGoToRuleLevel(slice, creatorInfo) {
    let kpiName = [];
    let dataSourceName = [];
    let tableName = [];
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      kpiName = creatorInfo.kpiInfo.metadata.name;
      tableName = [getFullTableName(creatorInfo.kpiInfo.config.table)];
      dataSourceName = [creatorInfo.dataSourceInfo.metadata.name];
    }

    const { ruleFilterSetting } = this.state;
    const currentGroupIncidentListForKPI = getLocalFilteredIncidentList(
      this.props.currentGroupIncidentList,
      {
        filterName: [],
        kpiName: [kpiName],
        dataSourceName,
        tableName,
        slice: [getSliceDisplayString(slice)],
      },
      true,
      false
    );

    this.setState({
      currentGroupIncidentListForKPI,
      currentMetaDataForKPI: {
        slice,
        creatorInfo,
      },
      ruleFilterSetting: {
        ...ruleFilterSetting,
        currentPage: 1,
        currentPageSize: defaultPageSize,
      },
    });
  }

  getIncidentAnalysisItemView(currentIncidentItem, index) {
    const attributes = [];
    const { slice, creatorInfo } = currentIncidentItem;
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      const sliceInfo = getSliceDisplayString(slice);
      const { kpiInfo } = creatorInfo;
      attributes.push(
        {
          key: "",
          value: sliceInfo
            ? `${kpiInfo.metadata.name}:${sliceInfo}`
            : kpiInfo.metadata.name,
        },
        {
          key: "",
          value: getKPIDisplayStr(creatorInfo),
        }
      );
    }

    return (
      <div className="incident-analysis-result-row" key={index}>
        <div className="incident-analysis-result-cell incident-analysis-result-data">
          {attributes.map(function (attributeItem, attributeIndex) {
            return (
              <div
                className="incident-analysis-result-cell-container"
                key={attributeIndex}
              >
                {attributeItem.key && (
                  <span className="incident-analysis-result-cell-key">
                    {`${attributeItem.key}: `}
                  </span>
                )}
                <span className="incident-analysis-result-cell-value">
                  {attributeItem.value}
                </span>
              </div>
            );
          })}
          <div className="incident-analysis-result-cell-container lightup-horizon-flex-container">
            <IncidentTotalSummary
              incidentSummary={currentIncidentItem["incidentTopSummary"]}
              incidentScoreSummary={currentIncidentItem["incidentScoreTopSummary"]}
            />
            <div className="lightup-rest-flex-item-container" />
            <div
              className="incident-analysis-item-detail-selector"
              onClick={this.onGoToRuleLevel.bind(this, slice, creatorInfo)}
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M20.217 18.2657L16.5358 21.8907C16.3889 22.0375 16.1514 22.0375 16.0045 21.8907L15.7826 21.6688C15.6358 21.5219 15.6358 21.2844 15.7826 21.1375L18.9795 18L15.7858 14.8625C15.6389 14.7157 15.6389 14.4782 15.7858 14.3313L16.0076 14.1094C16.1545 13.9625 16.392 13.9625 16.5389 14.1094L20.2201 17.7344C20.3639 17.8813 20.3639 18.1188 20.217 18.2657Z"
                  fill="#868786"
                />
                <path
                  d="M4 1H32V-1H4V1ZM35 4V32H37V4H35ZM32 35H4V37H32V35ZM1 32V4H-1V32H1ZM4 35C2.34315 35 1 33.6569 1 32H-1C-1 34.7614 1.23858 37 4 37V35ZM35 32C35 33.6569 33.6569 35 32 35V37C34.7614 37 37 34.7614 37 32H35ZM32 1C33.6569 1 35 2.34315 35 4H37C37 1.23858 34.7614 -1 32 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
                  fill="#DDDDDD"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static getIncidentType(currentTargetIncident, incident) {
    if (
      typeof incident.endTime !== "number" ||
      typeof incident.startTime !== "number"
    ) {
      console.log(`Invalid incident data for ${JSON.stringify(incident)}`);
    }

    if (incident.endTime < currentTargetIncident.startTimestamp) {
      return IncidentCategoryType.BEFORE;
    }

    if (incident.startTime > currentTargetIncident.endTimestamp) {
      return IncidentCategoryType.AFTER;
    }

    return IncidentCategoryType.OVERLAP;
  }

  static getFilterIncidentListByIncidentType(
    currentGroupIncidentList,
    targetIncident,
    categorySetting
  ) {
    let filteredIncidentList = [];
    const { filterUuid, slice } = targetIncident;
    const skipKey = JSON.stringify({
      id: filterUuid,
      slice: slice,
    });
    let beforeTotalNumber = 0;
    let afterTotalNumber = 0;

    let beforeIncidentNumbers = 0;
    let overlapIncidentNumbers = 0;
    let afterIncidentNumbers = 0;
    for (let currentGroupIncidentItem of currentGroupIncidentList) {
      const currentKey = JSON.stringify({
        id: currentGroupIncidentItem.creatorInfo.creatorUuid,
        slice: currentGroupIncidentItem.slice,
      });

      if (skipKey === currentKey) {
        continue;
      }

      beforeTotalNumber += currentGroupIncidentItem.incidents.length;
      let filterIncidents = [];
      for (let currentIncident of currentGroupIncidentItem.incidents) {
        const incidentCategoryType = IncidentAnalysisView.getIncidentType(
          targetIncident,
          currentIncident
        );
        if (categorySetting[incidentCategoryType]) {
          filterIncidents.push(currentIncident);
        }

        if (incidentCategoryType === IncidentCategoryType.BEFORE) {
          beforeIncidentNumbers += 1;
        } else if (incidentCategoryType === IncidentCategoryType.OVERLAP) {
          overlapIncidentNumbers += 1;
        } else {
          afterIncidentNumbers += 1;
        }
      }

      if (filterIncidents.length > 0) {
        filteredIncidentList.push({
          ...currentGroupIncidentItem,
          incidents: filterIncidents,
        });
      }
      afterTotalNumber += filterIncidents.length;
    }

    return {
      filteredIncidentList,
      beforeTotalNumber,
      afterTotalNumber,
      beforeIncidentNumbers,
      overlapIncidentNumbers,
      afterIncidentNumbers,
    };
  }

  getIncidentAnalysisKPILevelResultView() {
    const { kpiFilterSetting, categorySetting } = this.state;

    const { sortBy, asc, localFilterSetting, currentPage, currentPageSize } =
      kpiFilterSetting;

    const { showMyRule, ...normalizedLocalFilterSetting } = localFilterSetting;

    const {
      currentGroupIncidentList,
      startTimestamp,
      endTimestamp,
      filterUuid,
      slice,
    } = this.props;

    let ownedBy = showMyRule ? this.props.userInfo.id : "";
    normalizedLocalFilterSetting["ownedBy"] = ownedBy;

    const displayIncidentListItems = sortIncidentList(
      getLocalFilteredIncidentList(
        currentGroupIncidentList,
        normalizedLocalFilterSetting
      ),
      sortBy,
      asc
    );

    const targetIncident = {
      startTimestamp,
      endTimestamp,
      filterUuid,
      slice,
    };

    const { filteredIncidentList, afterTotalNumber } =
      IncidentAnalysisView.getFilterIncidentListByIncidentType(
        displayIncidentListItems,
        targetIncident,
        categorySetting
      );

    const newCurrentGroupIncidentListItems =
      IncidentAnalysisView.getIncidentListItems(filteredIncidentList);

    return (
      <>
        <div className="incident-analysis-kpi-view">
          <div className="incident-analysis-kpi-view-controller lightup-horizon-flex-container">
            <div className="incident-number-container">
              <div>Incidents</div>
              <div>{afterTotalNumber}</div>
            </div>
            <div className="incident-number-container">
              <div>Metrics</div>
              <div>{newCurrentGroupIncidentListItems.length}</div>
            </div>
            <div />
          </div>
          <div className="incident-analysis-result-view">
            {newCurrentGroupIncidentListItems
              .slice((currentPage - 1) * currentPageSize, currentPage * currentPageSize)
              .map(
                function (itemValue, index) {
                  return this.getIncidentAnalysisItemView(itemValue, index);
                }.bind(this)
              )}
          </div>
        </div>
        {this.getPaginationView(
          newCurrentGroupIncidentListItems.length,
          currentPage,
          currentPageSize
        )}
      </>
    );
  }

  getIncidentAnalysisRuleItemView(currentIncidentItem, index) {
    const { selectedAnalysisList } = this.props;
    const ruleName =
      currentIncidentItem.creatorInfo.type === IncidentCreatorType.FILTER
        ? currentIncidentItem.creatorInfo.filterInfo.metadata.name
        : "";

    const symptomDisplayName =
      currentIncidentItem.creatorInfo.type === IncidentCreatorType.FILTER
        ? getSymptomTypeDisplayName(
            currentIncidentItem.creatorInfo.filterInfo.config.symptom.type
          )
        : "";

    const incidentLowSummary = currentIncidentItem.incidentLowSummary;
    const incidentScoreLowSummary = currentIncidentItem.incidentScoreLowSummary;

    const currentCreatorUuid = currentIncidentItem.creatorInfo.creatorUuid;
    const currentSlice = currentIncidentItem.slice || {};
    let isAdded = false;
    for (let { filterUuid, slice = {} } of selectedAnalysisList) {
      if (
        filterUuid === currentCreatorUuid &&
        JSON.stringify(currentSlice) === JSON.stringify(slice)
      ) {
        isAdded = true;
      }
    }

    const modifyCompareButtonText = !isAdded ? (
      <div className="incident-analysis-rule-add-remove-button">
        <PlusOutlined />
        Add to compare
      </div>
    ) : (
      <div className="incident-analysis-rule-add-remove-button">
        <MinusOutlined />
        Remove from compare
      </div>
    );

    return (
      <div className="incident-analysis-rule-result-row" key={index}>
        <div className="incident-analysis-rule-result-cell incident-analysis-item-rule-data">
          {ruleName && (
            <div className="incident-analysis-rule-result-title-container">
              {ruleName}
            </div>
          )}
          {symptomDisplayName && (
            <div className="incident-analysis-rule-result-symptom-container">
              {symptomDisplayName}
            </div>
          )}
          <div className="incident-analysis-rule-incident-summary-container lightup-horizon-flex-container">
            <IncidentTotalSummary
              incidentSummary={incidentLowSummary}
              incidentScoreSummary={incidentScoreLowSummary}
            />
            <div className="lightup-rest-flex-item-container" />
            <NgButton
              outline
              onClick={this.onAddNewRule.bind(this, currentIncidentItem, !isAdded)}
              disabled={
                !isAdded && selectedAnalysisList.length >= maxAnalysisListNumber
              }
            >
              {modifyCompareButtonText}
            </NgButton>
          </div>
        </div>
      </div>
    );
  }

  getIncidentAnalysisRuleLevelResultView() {
    const {
      ruleFilterSetting,
      currentGroupIncidentListForKPI,
      currentMetaDataForKPI: { slice, creatorInfo },
      categorySetting,
    } = this.state;

    const { sortBy, asc, localFilterSetting, currentPage, currentPageSize } =
      ruleFilterSetting;

    const { showMyRule, ...normalizedLocalFilterSetting } = localFilterSetting;

    const targetIncident = {
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      filterUuid: this.props.filterUuid,
      slice: this.props.slice,
    };

    let ownedBy = showMyRule ? this.props.userInfo.id : "";
    normalizedLocalFilterSetting["ownedBy"] = ownedBy;

    const displayIncidentListItemsForKPI = sortIncidentList(
      getLocalFilteredIncidentList(
        currentGroupIncidentListForKPI,
        normalizedLocalFilterSetting
      ),
      sortBy,
      asc
    );

    const { filteredIncidentList } =
      IncidentAnalysisView.getFilterIncidentListByIncidentType(
        displayIncidentListItemsForKPI,
        targetIncident,
        categorySetting
      );

    const newCurrentGroupIncidentListItems =
      IncidentAnalysisView.getIncidentListItems(filteredIncidentList);

    let incidentDetails;
    let totalIncidentNumber;
    let totalRuleNumber;
    if (newCurrentGroupIncidentListItems.length === 0) {
      incidentDetails = [];
      totalRuleNumber = 0;
      totalIncidentNumber = 0;
    } else {
      if (newCurrentGroupIncidentListItems.length > 1) {
        console.log(
          `Warning: Monitor level filter result is ${newCurrentGroupIncidentListItems.length}`
        );
      }
      incidentDetails = newCurrentGroupIncidentListItems[0].incidentDetails;
      totalRuleNumber = incidentDetails.length;
      totalIncidentNumber = [
        IncidentDirection.UP,
        IncidentDirection.DOWN,
        IncidentDirection.BOTH,
      ].reduce(
        (accumulator, directionValue) =>
          accumulator +
          newCurrentGroupIncidentListItems[0].incidentTopSummary[directionValue],
        0
      );
    }

    let displayKpiName = "";
    let displaySliceInfo = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      const { kpiInfo, dataSourceInfo } = creatorInfo;
      displayKpiName = kpiInfo.metadata.name;
      displaySliceInfo =
        kpiInfo.metadata.creationType === MetricType.AUTO
          ? getKPIProfilerDisplayStr(kpiInfo, dataSourceInfo)
          : getSliceDisplayString(slice);
    }

    return (
      <>
        <div className="incident-analysis-rule-view">
          <div className="incident-analysis-rule-control-panel lightup-horizon-flex-container">
            <div
              className="incident-analysis-rule-go-back-button-container"
              onClick={() => {
                this.setState({
                  currentGroupIncidentListForKPI: null,
                  currentMetaDataForKPI: null,
                  queryObjectList: [],
                  ruleFilterSetting: {
                    sortBy: "incidents",
                    asc: false,
                    localFilterSetting: {
                      filterName: [],
                      kpiName: [],
                      dataSourceName: [],
                      tableName: [],
                      slice: [],
                      tagName: [],
                      showMyRule: false,
                      direction: [],
                    },
                  },
                });
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M9.78301 11.7344L13.4643 8.10938C13.6111 7.9625 13.8486 7.9625 13.9955 8.10938L14.2174 8.33125C14.3643 8.47813 14.3643 8.71563 14.2174 8.8625L11.0205 12L14.2143 15.1375C14.3611 15.2844 14.3611 15.5219 14.2143 15.6688L13.9924 15.8906C13.8455 16.0375 13.608 16.0375 13.4611 15.8906L9.77989 12.2656C9.63614 12.1188 9.63614 11.8813 9.78301 11.7344Z"
                  fill="#868786"
                />
              </svg>
            </div>
            <div className="incident-analysis-rule-info-panel">
              <div className="incident-analysis-rule-kpi-info-container">
                {displayKpiName}
              </div>
              {displaySliceInfo && (
                <div className="incident-analysis-rule-slice-information-container">
                  {displaySliceInfo}
                </div>
              )}
            </div>
          </div>
          <div className="incident-analysis-rule-view-controller-container lightup-horizon-flex-container">
            <div className="incident-number-container">
              <div>Incidents</div>
              <div>{totalIncidentNumber}</div>
            </div>
            <div className="incident-number-container">
              <div>Monitors</div>
              <div>{totalRuleNumber}</div>
            </div>
            <div />
          </div>
          <div className="incident-analysis-rule-result-panel">
            {incidentDetails
              .slice((currentPage - 1) * currentPageSize, currentPage * currentPageSize)
              .map(this.getIncidentAnalysisRuleItemView.bind(this))}
          </div>
        </div>
        {this.getPaginationView(incidentDetails.length, currentPage, currentPageSize)}
      </>
    );
  }

  getPaginationView(totalRows, currentPage, currentPageSize) {
    if (totalRows <= 10) {
      return null;
    }

    return (
      <Pagination
        className="incident-analysis-view-pagination-container"
        current={currentPage}
        pageSize={currentPageSize}
        total={totalRows}
        simple={true}
        onChange={(currentPage, currentPageSize) => {
          const {
            currentGroupIncidentListForKPI,
            kpiFilterSetting,
            ruleFilterSetting,
          } = this.state;
          if (!currentGroupIncidentListForKPI) {
            this.setState({
              kpiFilterSetting: {
                ...kpiFilterSetting,
                currentPage,
                currentPageSize,
              },
            });
          } else {
            this.setState({
              ruleFilterSetting: {
                ...ruleFilterSetting,
                currentPage,
                currentPageSize,
              },
            });
          }
        }}
      />
    );
  }

  render() {
    const { currentGroupIncidentListForKPI } = this.state;

    return (
      <div className="incident-analysis-view-container">
        {this.getIncidentAnalysisSummaryView()}
        {currentGroupIncidentListForKPI
          ? this.getIncidentAnalysisRuleLevelResultView()
          : this.getIncidentAnalysisKPILevelResultView()}
      </div>
    );
  }
}

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => ({
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick, className) =>
    dispatch(
      openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick, className)
    ),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentAnalysisView);
