import React from "react";

import "./entity-stats.scss";

function EntityStatCell(props) {
  const { label, value } = props;

  return (
    <div className="entity-list-stat">
      <div role="columnheader" className="entity-list-stat-label">
        {label}
      </div>
      <div role="cell" className="entity-list-stat-value">
        {value}
      </div>
    </div>
  );
}

function EntityStats(props) {
  const { stats } = props;

  return (
    <div className="entity-list-stats">
      {Object.entries(stats).map(([label, value]) => (
        <EntityStatCell key={label} label={label} value={value} />
      ))}
    </div>
  );
}

export default EntityStats;
