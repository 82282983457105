import React from "react";
import ChartEnableView from "../../components/chart-view/chart-enable-view";

export function EnableProfilerIcon() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.5 45H13.5C11.843 45 10.5 43.657 10.5 42V14C10.5 12.343 11.843 11 13.5 11H43.5C45.157 11 46.5 12.343 46.5 14V42C46.5 43.657 45.157 45 43.5 45Z"
        fill="#D5D4FF"
      />
      <path
        d="M7 27.8V11.5C7 9.3 8.8 7.5 11 7.5H38C40.2 7.5 42 9.3 42 11.5V18.7"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 25.7002V36.5002C42 38.7002 40.2 40.5002 38 40.5002H11C8.8 40.5002 7 38.7002 7 36.5002V34.9002"
        stroke="#121224"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 13.5H42.5" stroke="#121224" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M35 7.5V13.5" stroke="#121224" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M28 7.5V13.5" stroke="#121224" strokeWidth="3" strokeMiterlimit="10" />
      <path
        d="M12.2231 32L20.5001 24L27.5001 31L36.5001 22"
        stroke="#121224"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 26.5C21.8807 26.5 23 25.3807 23 24C23 22.6193 21.8807 21.5 20.5 21.5C19.1193 21.5 18 22.6193 18 24C18 25.3807 19.1193 26.5 20.5 26.5Z"
        fill="#121224"
      />
      <path
        d="M27.5 33.5C28.8807 33.5 30 32.3807 30 31C30 29.6193 28.8807 28.5 27.5 28.5C26.1193 28.5 25 29.6193 25 31C25 32.3807 26.1193 33.5 27.5 33.5Z"
        fill="#121224"
      />
      <path
        d="M36.5 24.5C37.8807 24.5 39 23.3807 39 22C39 20.6193 37.8807 19.5 36.5 19.5C35.1193 19.5 34 20.6193 34 22C34 23.3807 35.1193 24.5 36.5 24.5Z"
        fill="#121224"
      />
      <path
        d="M12.5 34.5C13.8807 34.5 15 33.3807 15 32C15 30.6193 13.8807 29.5 12.5 29.5C11.1193 29.5 10 30.6193 10 32C10 33.3807 11.1193 34.5 12.5 34.5Z"
        fill="#121224"
      />
    </svg>
  );
}

export default function ProfilerChartEnableView(props) {
  const {
    value,
    onChange,
    label = "Enable data profile",
    icon = <EnableProfilerIcon />,
    gridOnly = false,
  } = props;
  return (
    <ChartEnableView
      value={value}
      label={label}
      icon={icon}
      onChange={onChange}
      gridOnly={gridOnly}
    />
  );
}
