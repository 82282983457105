import React, { Component } from "react";
import Plot from "../../utils/plotly";
import { BrightColorSet, getRGBColor } from "../../utils/color";
import { EventItemType } from "../../utils/enums";
import { getStringFromTimeStamp } from "../../utils/time";

class IncidentEventTimelineLineView extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(rawEventData) {
    const { eventList, onClick } = this.props;
    if (!onClick) {
      return;
    }

    const { points, event } = rawEventData;
    if (!points || points.length === 0) {
      return;
    }

    let currentValue = null;
    for (let currentPoint of points) {
      if (currentPoint && currentPoint.data && currentPoint.data.mode === "markers") {
        const targetTimestamp = currentPoint.data.customdata[0];
        for (let currentEvent of eventList) {
          const { startTime } = currentEvent;
          if (targetTimestamp === startTime) {
            currentValue = currentEvent;
            break;
          }
        }

        break;
      }
    }

    console.log(currentValue);
    onClick(currentValue, event);
  }

  getLineTrace(startTime, endTime) {
    return {
      type: "scatter",
      hoverinfo: "none",
      x: [
        getStringFromTimeStamp(startTime, { graph: true }),
        getStringFromTimeStamp(endTime, { graph: true }),
      ],
      y: [0, 0],
      mode: "lines",
      line: {
        color: "rgb(237, 237, 237)",
        width: 1,
      },
    };
  }

  getPointTrace(startTime, value, isCurrentPoint, eventType) {
    let color;
    if (eventType === EventItemType.NORMAL) {
      color = getRGBColor(BrightColorSet.RAW);
    } else if (eventType === EventItemType.INCIDENT) {
      color = getRGBColor(BrightColorSet.INCIDENT);
    } else if (eventType === EventItemType.UNPROCESSED) {
      color = getRGBColor(BrightColorSet.NOT_PROCESSED);
    } else if (eventType === EventItemType.HIGH_IMPACT_INCIDENT) {
      color = getRGBColor(BrightColorSet.HIGH_IMPACT_INCIDENT);
    } else {
      console.log(`Unknown event type ${eventType}`);
      color = "rgb(255, 255, 255)";
    }

    return {
      type: "scatter",
      name: "",
      x: [getStringFromTimeStamp(startTime, { graph: true })],
      y: [0],
      mode: "markers",
      marker: {
        color,
        size: isCurrentPoint ? 16 : 8,
      },
      customdata: [startTime],
    };
  }

  render() {
    const {
      eventListStartTime,
      eventListEndTime,
      eventList,
      getTooltip,
      config = {},
      currentPoint = null,
    } = this.props;

    const { height = 100, marginLeft = 0, marginTop = 0, marginRight = 0 } = config;

    let lineTraces = [];
    let pointTraces = [];
    for (let i = 0; i < eventList.length; i++) {
      const { startTime, status, value } = eventList[i];
      if (i > 0) {
        const { startTime: lastStartTime } = eventList[i - 1];
        lineTraces.push(this.getLineTrace(lastStartTime, startTime));
      }

      const isCurrentPoint = currentPoint && currentPoint.timestamp === startTime;
      const currentPointTrace = this.getPointTrace(
        startTime,
        value,
        isCurrentPoint,
        status
      );
      if (getTooltip) {
        const hoverTooltip = getTooltip(value, status);
        currentPointTrace.hoverinfo = "text";
        currentPointTrace.hovertext = [hoverTooltip];
      } else {
        currentPointTrace.hoverinfo = "none";
      }

      pointTraces.push(currentPointTrace);
    }

    const xaxis = {
      zeroline: false,
      showgrid: false,
      visible: true,
      fixedrange: false,
      ticks: "",
      tickfont: {
        size: 10,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        color: "#868686",
      },
    };

    if (
      typeof eventListStartTime === "number" &&
      typeof eventListEndTime === "number"
    ) {
      if (eventList.length === 0) {
        lineTraces.push(this.getLineTrace(eventListStartTime, eventListEndTime));
      } else {
        if (eventListStartTime < eventList[0].startTime) {
          lineTraces.push(
            this.getLineTrace(eventListStartTime, eventList[0].startTime)
          );
        }

        if (eventListEndTime > eventList[eventList.length - 1].startTime) {
          lineTraces.push(
            this.getLineTrace(
              eventList[eventList.length - 1].startTime,
              eventListEndTime
            )
          );
        }
      }
    }

    const allTraces = [...lineTraces, ...pointTraces];
    const layout = {
      hovermode: "closest",
      showlegend: false,
      xaxis,
      yaxis: {
        autorange: true,
        showgrid: false,
        zeroline: false,
        showline: false,
        autotick: true,
        ticks: "",
        showticklabels: false,
        fixedrange: true,
      },
      margin: {
        l: marginLeft,
        t: marginTop,
        r: marginRight,
      },
      autosize: true,
    };

    const layoutConfig = {
      displayModeBar: false,
      responsive: true,
    };

    return (
      <Plot
        data={allTraces}
        layout={layout}
        config={layoutConfig}
        useResizeHandler
        style={{ height: `${height}px` }}
        onClick={this.onClick}
        onHover={this.onHover}
        onUnhover={this.onUnhover}
        onClickAnnotation={this.onAnnotationClick}
      />
    );
  }
}

export default IncidentEventTimelineLineView;
