import React, { useEffect, useState } from "react";
import { SettingOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "../../button/ng-button";
import { LabeledSelect } from "../../labeled-control/labeled-control";
import DurationInput from "../../duration-input/duration-input";
import PartitionConfigItem from "../../ng-partition-config-item/";
import { TableType } from "../../../utils/enums";
import { TimezoneOptions } from "../../../utils/options";
import { NextGenPalette } from "../../../utils/color";
import { SubHeading } from "./index";
import { getNormalizedTable } from "../../../utils/datasource";
import { isAdvancedSettingsModified } from "../../../utils/partitions";

import "./metric-partitions-config-item.scss";

function MetricPartitionsConfigItem(props) {
  const {
    label = "PARTITIONS",
    className = "",
    value,
    onChange,
    columnList,
    dataSourceUuid,
    table,
    direction,
    getCurrentKpiPartitionSampleData,
    currentKpiPartitionSampleData,
    partitionOffsets = { includeFutureSeconds: 0, includePastSeconds: 0 },
    partitionTimezone = "",
    onPartitionTimezoneChange,
    onPartitionOffsetsChange,
    hideColumnName = false,
    isTableOptional = false,
  } = props;

  useEffect(() => {
    const normalizedTable = getNormalizedTable(table);
    if (
      dataSourceUuid &&
      normalizedTable &&
      normalizedTable.type === TableType.TABLE &&
      normalizedTable.schemaName &&
      normalizedTable.tableName &&
      columnList.some(({ isPartitionKey }) => isPartitionKey)
    ) {
      getCurrentKpiPartitionSampleData(dataSourceUuid, normalizedTable);
    }
  }, [dataSourceUuid, table, getCurrentKpiPartitionSampleData, columnList]);

  const disabled = isTableOptional ? !Boolean(dataSourceUuid) : !table?.tableUuid;

  const { includeFutureSeconds = 0, includePastSeconds = 0 } = partitionOffsets;
  const isAdvancedSectionModified = isAdvancedSettingsModified({
    partitionTimezone,
    partitionOffsets,
  });
  const [isAdvancedSectionOpen, setIsAdvancedSectionOpen] = useState(
    isAdvancedSectionModified
  );

  return (
    <div className={`partitions-config-item-container ${className}`}>
      <div className="partitions-config-item-header-container">
        <SubHeading>{label}</SubHeading>
        <Button
          outline
          onClick={() => setIsAdvancedSectionOpen(!isAdvancedSectionOpen)}
          style={
            isAdvancedSectionModified && !isAdvancedSectionOpen
              ? {
                  borderColor: NextGenPalette.brandYellow,
                }
              : {}
          }
        >
          Advanced
          <SettingOutlined />
        </Button>
        <Button
          outline
          onClick={() => {
            value.push({ columnName: "", format: "" });
            onChange([...value]);
          }}
          disabled={disabled}
        >
          Add
          <PlusOutlined />
        </Button>
      </div>
      {isAdvancedSectionOpen && (
        <div className={`partition-advance-config metric-config-bordered ${direction}`}>
          <div className="partition-advance-config-row">
            <LabeledSelect
              showSearch
              label="Partition timezone"
              options={TimezoneOptions}
              value={partitionTimezone}
              onChange={onPartitionTimezoneChange}
            />
          </div>
          <div className={`partition-advance-config-row ${direction}`}>
            <DurationInput
              label={"Partition offset past"}
              value={includePastSeconds}
              onChange={(newIncludePastSeconds) => {
                if (newIncludePastSeconds === includePastSeconds) {
                  return;
                }

                onPartitionOffsetsChange({
                  includePastSeconds: newIncludePastSeconds,
                  includeFutureSeconds,
                });
              }}
            />
            <DurationInput
              label={"Partition offset future"}
              value={includeFutureSeconds}
              onChange={(newIncludeFutureSeconds) => {
                if (newIncludeFutureSeconds === includeFutureSeconds) {
                  return;
                }

                onPartitionOffsetsChange({
                  includePastSeconds,
                  includeFutureSeconds: newIncludeFutureSeconds,
                });
              }}
            />
          </div>
        </div>
      )}
      <PartitionConfigItem
        value={value}
        onChange={onChange}
        columnList={columnList}
        partitionSampleData={currentKpiPartitionSampleData}
        direction={direction}
        disabled={disabled}
        hideColumnName={hideColumnName}
      />
    </div>
  );
}

export default MetricPartitionsConfigItem;
