import React from "react";
import ModalConfirmationButtons from "../../components/modal-dialog/modal-confirmation-buttons";
import NgButton from "../../components/button/ng-button";

export default function IntegrationDialogFooter(props) {
  const {
    previewText = "Send test message",
    enablePreview,
    onPreviewClick,
    OkText,
    onOkClick,
    onCancelClick,
    enableSave,
  } = props;

  return (
    <div style={{ marginTop: "8px" }}>
      <ModalConfirmationButtons
        extraControls={
          previewText && (
            <>
              <div style={{ flexGrow: 1 }} />
              <NgButton
                link
                size="large"
                disabled={!enablePreview}
                onClick={onPreviewClick}
                style={{
                  padding: "8px 0px",
                }}
              >
                {previewText}
              </NgButton>
            </>
          )
        }
        okText={OkText}
        onOkClick={onOkClick}
        onCancelClick={onCancelClick}
        okButtonProps={{
          disabled: !enableSave,
        }}
      />
    </div>
  );
}
