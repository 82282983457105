import { getStringCompareFunction } from "../../../utils/search";

export const GENERAL_SEARCH_FIELD = "searchName";
const stringCompareFunction = getStringCompareFunction(false);
const strictStringCompareFunction = getStringCompareFunction(true);

export const tableFields = {
  TABLE: "table",
};

export const tableFieldOptionValueGetterFn = {
  [tableFields.TABLE]: (table) => table.tableName,
};

export const tableFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    return searchName.length && !stringCompareFunction(row.tableName, searchName);
  },
  [tableFields.TABLE]: (row, tableName) => {
    return !strictStringCompareFunction(row.tableName, tableName);
  },
};
