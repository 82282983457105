import { fnSorter } from "../../utils/sort";
import { DqDashboardDimensionType } from "../../utils/enums";
import { WEEK_IN_SECONDS } from "../../utils/time";
import { deepcopy } from "../../utils/objects";

export function emptyDailySummary() {
  return {
    timezone: "America/Chicago",
    evaluationDelay: 0,
    configuredEvaluationDelay: 0,
    timestamp: 0,
    totalMonitoredTables: 0,
    totalMonitors: 0,
    dimensionScoresMap: {
      overall: {
        totalMonitorsWithIncidents: 0,
        totalMonitors: 0,
      },
      completeness: {
        totalMonitorsWithIncidents: 0,
        totalMonitors: 0,
      },
      accuracy: {
        totalMonitorsWithIncidents: 0,
        totalMonitors: 0,
      },
      timeliness: {
        totalMonitorsWithIncidents: 0,
        totalMonitors: 0,
      },
      custom: {
        totalMonitorsWithIncidents: 0,
        totalMonitors: 0,
      },
    },
  };
}

const timestampSorter = fnSorter((rec) => rec.timestamp, true);

export function dailySummaryData(dqDashboardData) {
  const records = deepcopy(dqDashboardData?.records);
  if (!Array.isArray(records) || records.length === 0) {
    return emptyDailySummary();
  }
  records.sort(timestampSorter);
  const mostRecentRecord = records[0];
  const dimensionScoresMap = mostRecentRecord.dimensionScores.reduce(
    (dimScoresMap, dimScore) => {
      dimScoresMap[dimScore.dimension] = {
        totalMonitors: dimScore.totalMonitors,
        totalMonitorsWithIncidents: dimScore.totalMonitorsWithIncidents,
      };
      return dimScoresMap;
    },
    {}
  );
  return {
    evaluationDelay: dqDashboardData.evaluationDelay,
    configuredEvaluationDelay: dqDashboardData.configuredEvaluationDelay,
    timezone: dqDashboardData.timezone,
    timestamp: mostRecentRecord.timestamp,
    totalMonitors: mostRecentRecord.totalMonitors,
    totalMonitoredTables: mostRecentRecord.totalMonitoredTables,
    dimensionScoresMap: {
      overall: {
        totalMonitors: mostRecentRecord.totalMonitors,
        totalMonitorsWithIncidents: mostRecentRecord.totalMonitorsWithIncidents,
      },
      ...dimensionScoresMap,
    },
  };
}

function emptyTrendData() {
  return {
    x: [],
    y: [],
  };
}

// Generator that produces dimension scores with associated timestamp as a flat sequence.
function* tsDimensionScores(dqDashboardData) {
  const records = deepcopy(dqDashboardData?.records ?? []);
  records.sort(timestampSorter);
  for (let record of records) {
    const { timestamp, totalMonitors, totalMonitorsWithIncidents } = record;
    yield {
      dimension: DqDashboardDimensionType.OVERALL,
      timestamp,
      totalMonitors,
      totalMonitorsWithIncidents,
    };
    for (let dimScore of record.dimensionScores) {
      yield {
        timestamp,
        ...dimScore,
      };
    }
  }
}

export function emptyTrendDataByDim() {
  return Object.values(DqDashboardDimensionType).reduce((trendDataByDim, dim) => {
    trendDataByDim[dim] = emptyTrendData();
    return trendDataByDim;
  }, {});
}

export function trendChartDataByDimension(dqDashboardData) {
  const trendDataByDim = emptyTrendDataByDim();
  for (let tsDimScore of tsDimensionScores(dqDashboardData)) {
    const { timestamp, dimension, totalMonitors, totalMonitorsWithIncidents } =
      tsDimScore;
    const totalMonitorsWithoutIncidents = totalMonitors - totalMonitorsWithIncidents;
    const ratioWithoutIncidents =
      totalMonitors > 0 ? totalMonitorsWithoutIncidents / totalMonitors : 0;
    trendDataByDim[dimension].x.push(new Date(timestamp * 1000));
    trendDataByDim[dimension].y.push(ratioWithoutIncidents);
  }
  return trendDataByDim;
}

const dayInSeconds = 86400;

export function dailyTimestamps(startingAt, numTimestamps) {
  const timestamps = [];
  for (let i = 0; i < numTimestamps; i++) {
    timestamps.push(startingAt + i * dayInSeconds);
  }
  return timestamps;
}

function monitorHeatmapData(timestamps, unhealthyTimestamps) {
  const unhealthyTimestampsSet = new Set(unhealthyTimestamps);
  return timestamps.map((ts) => (unhealthyTimestampsSet.has(ts) ? 1 : 0));
}

function dimensionHeatmapData(timestamps, dimensionMonitors) {
  const monitorRows = dimensionMonitors.map((dimMonitor) => ({
    monitorUuid: dimMonitor.uuid,
    monitorName: dimMonitor.name,
    metricName: dimMonitor.metadata?.metricName ?? "",
    sourceName: dimMonitor.metadata?.sourceName,
    schemaName: dimMonitor.metadata?.schemaName,
    tableName: dimMonitor.metadata?.tableName,
    columnName: dimMonitor.metadata?.columnName,
    heatmapData: monitorHeatmapData(timestamps, dimMonitor.unhealthyTimestamps),
  }));
  return {
    monitorRows,
    heatmapTimestamps: timestamps,
  };
}

export function emptyHeatmapDataByDimension() {
  return {
    overall: {
      monitorRows: [],
      heatmapTimestamps: [],
    },
    accuracy: {
      monitorRows: [],
      heatmapTimestamps: [],
    },
    completeness: {
      monitorRows: [],
      heatmapTimestamps: [],
    },
    timeliness: {
      monitorRows: [],
      heatmapTimestamps: [],
    },
    custom: {
      monitorRows: [],
      heatmapTimestamps: [],
    },
  };
}

export function heatmapDataByDimension(dqDashboardData) {
  if (!dqDashboardData?.topMonitors) {
    return emptyHeatmapDataByDimension();
  }
  // Assuming we have two weeks worth of daily data at the end of the time range.
  const timestamps = dailyTimestamps(dqDashboardData.endTs - 2 * WEEK_IN_SECONDS, 14);
  const dataByDimension = {};
  dataByDimension[DqDashboardDimensionType.OVERALL] = dimensionHeatmapData(
    timestamps,
    dqDashboardData.topMonitors.topMonitors
  );
  return dqDashboardData.topMonitors.dimensionTopMonitors.reduce(
    (dataByDim, dimTopMonitors) => {
      dataByDim[dimTopMonitors.dimension] = dimensionHeatmapData(
        timestamps,
        dimTopMonitors.topMonitors
      );
      return dataByDim;
    },
    dataByDimension
  );
}

export function summaryEvaluationDelay(dailySummary) {
  const isConfigured = typeof dailySummary.configuredEvaluationDelay === "number";
  return [
    isConfigured
      ? dailySummary.configuredEvaluationDelay
      : dailySummary.evaluationDelay,
    isConfigured,
  ];
}
