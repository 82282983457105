import {
  AtlanTypeIcon,
  AlationTypeIcon,
} from "../components/icons/system-integration/";

export const SystemIntegrationType = Object.freeze({
  ALATION: "alation",
  ATLAN: "atlan",
});

const systemIntegrationTypeDisplayNameMapper = {
  [SystemIntegrationType.ALATION]: "Alation",
  [SystemIntegrationType.ATLAN]: "Atlan",
};

export function getSystemIntegrationDisplayType(type) {
  return systemIntegrationTypeDisplayNameMapper[type] || type;
}

export function getSystemIntegrationIconComponent(systemIntegrationType) {
  if (systemIntegrationType === SystemIntegrationType.ALATION) {
    return AlationTypeIcon;
  } else if (systemIntegrationType === SystemIntegrationType.ATLAN) {
    return AtlanTypeIcon;
  }

  console.log(`Unknown system integration type ${systemIntegrationType}`);
  return null;
}
