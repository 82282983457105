import React from "react";
import ModalDialog from "../modal-dialog/modal-dialog";

function LegacyModalDialogAdapter(props) {
  const {
    containerClassName = "",
    triggerComponent,
    title,
    contentComponent,
    footerComponent,
    closeIcon,
    modalIsOpen,
    setIsOpen,
    width = 520,
    // onCloseIcon,
    // customStyle = {},
    shouldCloseOnOverlayClick = false,
  } = props;

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{triggerComponent}</div>
      <ModalDialog
        title={title}
        closable
        closeIcon={closeIcon}
        visible={modalIsOpen}
        maskClosable={shouldCloseOnOverlayClick}
        footer={null}
        wrapClassName={containerClassName}
        width={width}
        onCancel={() => setIsOpen(false)}
      >
        {contentComponent}
        {footerComponent}
      </ModalDialog>
    </>
  );
}

export default LegacyModalDialogAdapter;
