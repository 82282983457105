import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { RequestStatus } from "../../utils/enums";
import DataSourceWizard from "../../components/datasource/data-source-wizard";
import { getDefaultCloneTitle } from "../../utils/defaults";
import { URIPath, getURIInstance, getCloneFromParams } from "../../utils/uri-path";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../utils/telemetry";

class AddDataSourceWizard extends Component {
  constructor() {
    super();

    this.title = "Add Data Source";
    this.onSave = this.onSave.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.goToSetting = this.goToSetting.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { workspaceUuid },
      },
    } = this.props;
    const cloneFrom = getCloneFromParams(this.props.location.search);
    if (cloneFrom) {
      console.log(`Cloning data source from ${cloneFrom}`);
      this.props.getDataSource(workspaceUuid, cloneFrom, 0);
    }

    this.props.getDataSourceSystemIntegrationMetadata();
  }

  onCancel() {
    const { history } = this.props;

    if (history.length > 1) {
      history.goBack();
    } else {
      // We navigate to settings/datasource if the current route is the only one in the history
      this.goToSetting();
    }
  }

  goToSetting() {
    const {
      history,
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    history.push(getURIInstance(URIPath.DATA_SOURCE_LIST, { workspaceUuid }));
  }

  onSave(workspaceUuid, newDataSource) {
    this.props
      .addDataSource(workspaceUuid, newDataSource)
      .then(() => this.goToSetting());

    trackEvent(EVENT.SAVE_DATASOURCE, {
      ...getDatasourceDetailProps(newDataSource),
      page: PAGE.DATASOURCE_DETAIL,
    });
  }

  onPreview(workspaceUuid, dataSource) {
    this.props.previewDataSource(workspaceUuid, dataSource);
  }

  render() {
    const {
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    const cloneFrom = getCloneFromParams(this.props.location.search);
    const isClone = !!cloneFrom;
    if (isClone && !this.props.currentCloneDataSource) {
      console.log(`Loading cloned data source ${cloneFrom}`);
      return null;
    }

    const defaultData = isClone
      ? {
          ...this.props.currentCloneDataSource,
          id: "",
          title: getDefaultCloneTitle(this.props.currentCloneDataSource.title),
        }
      : {};

    return (
      <DataSourceWizard
        title={this.title}
        isSaving={this.props.addDataSourceStatus === RequestStatus.DOING}
        lastPreviewStatus={this.props.lastPreviewStatus}
        lastPreviewResult={this.props.lastPreviewResult}
        lastPreviewTime={this.props.lastPreviewTime}
        resetDataSourceStatus={this.props.resetDataSourceStatus}
        waffle={this.props.waffle}
        onSave={this.onSave.bind(this, workspaceUuid)}
        onCancel={this.onCancel}
        previewDataSource={this.onPreview.bind(this, workspaceUuid)}
        isEdit={false}
        isClone={isClone}
        defaultData={defaultData}
        workspaceUserPermissions={this.props.workspaceUserPermissions}
        alationDataSourceList={this.props.alationDataSourceList}
        atlanDataSourceList={this.props.atlanDataSourceList}
      />
    );
  }
}

export default withRouter(AddDataSourceWizard);
