import React, { useEffect, useState } from "react";
import { Alert, Skeleton } from "antd";
import TableHealthSummary from "./health/table-health-summary";
import TableInfoSummary from "./health/table-info-summary";
import TableMetricsInfoSummary from "./health/table-metrics-info-summary";
import TableColumnHealthInfoSummary from "./health/table-column-health-info-summary";
import { ColumnIcon } from "../icons";
import ProfilerChecklistView from "../profiler-checklist-view";
import { MetadataIcon } from "../../../components/metric/fields/icons";
import { indexBy } from "../../../utils/iterables";
import { getMetricTypeFromConfigData } from "../../../components/metric/utils";
import {
  getAutoMetricTypeFromKPI,
  isAutoCreationTypeMetric,
  metadataMetricTypes,
  tableAutoMetricTypes,
} from "../../../utils/metric";
import { difference } from "../../../utils/set";
import { tableSupportsMetricType } from "../../../utils/table";
import { dataSourceSupportsMetricType } from "../../../utils/datasource";
import { MetricCategory } from "../../../utils/enums";
import { canViewChecklist, canModifyTableProfilerConfig } from "../utils";
import AutoMonitorRuleCreationDialog from "../../../components/auto-rule-creation-dialog/auto-monitor-rule-creation-dialog";

import "./profiler-data-source-table-health-tab.scss";

function addConfigurableTableMetrics(
  tableMetrics,
  dataSource,
  table,
  metricInfoMapper
) {
  // MetricCategory.EVENT represents schema change.
  const availableMetricTypes = new Set(
    [
      MetricCategory.COLUMN_ACTIVITY,
      ...tableAutoMetricTypes,
      ...metadataMetricTypes,
    ].filter(
      (metricType) =>
        dataSourceSupportsMetricType(dataSource, metricType) &&
        tableSupportsMetricType(table, metricType)
    )
  );
  const enabledMetricTypes = new Set(
    tableMetrics
      .filter((metric) => isAutoCreationTypeMetric(metricInfoMapper[metric.metricUuid]))
      .map((metric) => getMetricTypeFromConfigData(metricInfoMapper[metric.metricUuid]))
  );

  const configurableMetricTypes = difference(availableMetricTypes, enabledMetricTypes);
  const withConfigurableMetrics = [
    ...tableMetrics,
    ...[...configurableMetricTypes].map((metricType) => ({
      configurableType: metricType,
      metricUuid: null,
      isLive: false,
      isMonitored: false,
      totalMonitorsCount: 0,
      activeMonitorsCount: 0,
      pausedMonitorsCount: 0,
      activeIncidentsCount: 0,
      pausedIncidentsCount: 0,
    })),
  ];
  return withConfigurableMetrics;
}

function ProfilerDataSourceTableHealthTab(props) {
  const {
    alertingChannelList,
    dataSource,
    schema,
    table,
    tableColumnList,
    tableHealthData,
    tabOptions,
    workspaceUserPermissions,
    workspaceUuid,
    getProfilerConfigTableColumnList,
    getProfilerCurrentTableHealthData,
    onConfigureTable,
    onCreateCustomMetric,
    onDeleteMetricClick,
    onManageAutoMetrics,
    onMonitorTableHealthMetric,
    onSelectNodeChange,
    onTabChange,
    onTabOptionsChange,
    setProfilerAfterTreeNavigationAction,
    updateColumnConfig,
    updateTableConfig,
    tableMonitorSummary,
    onMonitorAll,
  } = props;

  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [monitorMetricDialogTarget, setMonitorMetricDialogTarget] = useState(null);

  let monitorMetricDialogTargetAutoMetricType = null;
  if (monitorMetricDialogTarget) {
    monitorMetricDialogTargetAutoMetricType = getAutoMetricTypeFromKPI(
      monitorMetricDialogTarget
    );
  }

  function refreshTableHealthData() {
    const isRefresh = tableHealthData?.data?.tableUuid === table.uuid;
    getProfilerCurrentTableHealthData(dataSource, table, {
      isRefresh,
    }).then((data) => {
      if (!data) {
        return;
      }
      const latestTimestamp = Math.max(
        0,
        ...data.dailySummary.map(({ eventTs }) => eventTs)
      );
      setCurrentTimestamp(latestTimestamp);
    });
    getProfilerConfigTableColumnList(dataSource, table, false, { isRefresh });
  }

  useEffect(
    () => {
      refreshTableHealthData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource?.metadata.uuid, table]
  );

  if (tableHealthData?.loading || tableColumnList.loading) {
    return (
      <div className="profiler-datasource-table-health-tab-container">
        <Skeleton active={true} paragraph={{ rows: 10 }} />
      </div>
    );
  } else if (!tableHealthData.data) {
    return (
      <Alert
        message="Error"
        description="Failed to retrieve table health data."
        type="error"
        showIcon
      />
    );
  }

  const overallSummary = {
    ...(tableHealthData.data?.overallSummary ?? {}),
    eventTs: 0,
  };
  const dailySummary = tableHealthData.data?.dailySummary || [];
  const metrics = tableHealthData.data?.metrics || [];
  const tableInfo = tableHealthData.data?.tableInfo || {};

  let currentTableHealthData;
  if (currentTimestamp === 0) {
    currentTableHealthData = overallSummary;
  } else {
    currentTableHealthData = dailySummary.find(
      ({ eventTs }) => eventTs === currentTimestamp
    );
  }

  const metricInfoMapper = indexBy(metrics, (metric) => metric.metadata.uuid);

  const tableMetrics = canModifyTableProfilerConfig(workspaceUserPermissions)
    ? addConfigurableTableMetrics(
        currentTableHealthData?.metrics?.tableMetrics || [],
        dataSource,
        table,
        metricInfoMapper
      )
    : currentTableHealthData?.metrics?.tableMetrics;

  function onChangeCurrentTimestamp(timestamp) {
    currentTimestamp !== timestamp && setCurrentTimestamp(timestamp);
  }

  function onMonitorMetric(metric) {
    setMonitorMetricDialogTarget(metric);
  }

  const sharedProps = {
    workspaceUuid,
    dataSource,
    schema,
    table,
    workspaceUserPermissions,
    onTabChange,
    onCreateCustomMetric,
    onSelectNodeChange,
    onDeleteMetricClick,
    onMonitorMetric,
    setProfilerAfterTreeNavigationAction,
  };

  const monitorSummary = tableMonitorSummary.loading
    ? {
        monitorCount: 0,
        metricCount: 0,
      }
    : {
        monitorCount: tableMonitorSummary.data.monitorCount,
        metricCount: tableMonitorSummary.data.metricCount,
      };

  return (
    <div className="profiler-datasource-table-health-tab-container">
      <TableInfoSummary tableInfo={tableInfo} tableColumnList={tableColumnList.data} />
      <div className="profiler-datasource-table-health-tab-summary-container">
        <TableHealthSummary
          currentTimestamp={currentTimestamp}
          onCurrentTimestampChange={onChangeCurrentTimestamp}
          overallSummary={overallSummary}
          dailySummary={dailySummary}
          currentTableHealthData={currentTableHealthData}
        />
      </div>
      <div className="profiler-datasource-table-health-tab-content-container">
        <div className="profiler-datasource-table-health-tab-content-left-container">
          <TableMetricsInfoSummary
            label="Table Level Metrics"
            icon={<MetadataIcon width={20} height={20} />}
            metrics={tableMetrics}
            metricInfoMapper={metricInfoMapper}
            onConfigureTable={onConfigureTable}
            updateTableConfig={updateTableConfig}
            onCreateCustomMetric={onCreateCustomMetric}
            {...sharedProps}
          />
          <TableColumnHealthInfoSummary
            label="Column Health"
            icon={<ColumnIcon width={20} height={20} />}
            metrics={currentTableHealthData?.metrics?.columnMetrics || []}
            metricInfoMapper={metricInfoMapper}
            tableColumnList={tableColumnList.data}
            onManageAutoMetrics={onManageAutoMetrics}
            updateColumnConfig={updateColumnConfig}
            refreshTableHealthData={refreshTableHealthData}
            tabOptions={tabOptions}
            onTabOptionsChange={onTabOptionsChange}
            {...sharedProps}
          />
        </div>
        <div className="profiler-datasource-table-health-tab-content-right-container">
          {canViewChecklist(workspaceUserPermissions) && (
            <ProfilerChecklistView
              config={{
                workspaceUuid,
                dataSource,
                schema,
                table,
              }}
              monitorSummary={monitorSummary}
              onMonitorAll={onMonitorAll}
              className="right-container-empty"
            />
          )}
        </div>
      </div>
      {monitorMetricDialogTarget && (
        <AutoMonitorRuleCreationDialog
          modalIsOpen={Boolean(monitorMetricDialogTarget)}
          setModalIsOpen={(open) => {
            if (!open) {
              setMonitorMetricDialogTarget(null);
            }
          }}
          alertingChannelList={alertingChannelList}
          okClicked={(symptomTypeConfig) =>
            onMonitorTableHealthMetric(monitorMetricDialogTarget, symptomTypeConfig)
          }
          metric={monitorMetricDialogTarget}
          metricType={monitorMetricDialogTargetAutoMetricType}
        />
      )}
    </div>
  );
}

export default ProfilerDataSourceTableHealthTab;
