import React from "react";
import PagerDutyIntegrationBasicDialog from "./pagerduty-integration-basic-dialog";
import { EditOutlined } from "@ant-design/icons";

import "./edit-pagerduty-integration-dialog.scss";

function EditPagerDutyIntegrationDialog(props) {
  const { data, onEdit, onPreview, modalIsOpen, setIsOpen, enableSave, enablePreview } =
    props;

  const onOkClicked = (newData) => {
    onEdit(newData);
  };

  return (
    <PagerDutyIntegrationBasicDialog
      defaultData={data}
      className={"edit-pager-duty-integration-dialog-container"}
      dialogTitle={"Edit Pagerduty Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      triggerComponent={<EditOutlined />}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enableSave={enableSave}
      enablePreview={enablePreview}
    />
  );
}

export default EditPagerDutyIntegrationDialog;
