function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

function defaultCombinatorFn(valueA, valueB) {
  return valueB ?? valueA;
}

export function deepMerge(base, source, combinatorFn = defaultCombinatorFn) {
  if (isObject(base) && isObject(source)) {
    return [...new Set([...Object.keys(base), ...Object.keys(source)])].reduce(
      (acc, key) => {
        acc[key] = deepMerge(base[key], source[key], combinatorFn);
        return acc;
      },
      {}
    );
  }
  return combinatorFn(base, source);
}
