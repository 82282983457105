import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AlertConfigSettingView from "../alert-config-setting-view/";
import BoundSettingView from "../bound-setting-view/";
import LegacyModalDialogAdapter from "../legacy-adapter/legacy-modal-dialog-adapter";
import { getURIInstance, URIPath } from "../../utils/uri-path";
import { getOptionByValue } from "../../utils/options";
import {
  AutoMetricsType,
  FeatureTypeConst,
  SymptomTypeConst,
  featureTypeDisplayNameMap,
  getTypesAndFeaturesBySymptomType,
} from "../../utils/enums";
import { MINUTE_IN_SECONDS } from "../../utils/time";
import { LabeledSelect } from "../labeled-control/labeled-control";
import { classesName } from "../../utils/css";
import ModalConfirmationButtons from "../modal-dialog/modal-confirmation-buttons";
import Tabs, { TabPane } from "../tabs/tabs";
import { isTrendMonitorDisabled } from "../../utils/metric";
import { isChangeFeatureType } from "../../utils/monitor";
import { enabledThresholdFeatureOptions } from "./utils";
import { getConfigBoundErrors } from "../../utils/config-bound-validation";

import "./auto-monitor-rule-creation-dialog.scss";

const thresholdPeriodOptions = [
  { label: "Day over day", value: "day" },
  { label: "Week over week", value: "week" },
  { label: "Sample over sample", value: "sample" },
];

function ThresholdConfig(props) {
  const { errors, value, onChange, metricType, metric, workspaceUuid, metricUuid } =
    props;

  const {
    feature = "",
    period = "",
    bound = {
      lower: 0,
      upper: 0,
    },
  } = value;

  const isDataDelayMetric = [
    AutoMetricsType.DATA_DELAY,
    AutoMetricsType.UPDATE_DELAY,
  ].includes(metricType);
  const disableTrendMonitor = metric && isTrendMonitorDisabled(metric);
  function onThresholdFeatureChange(newFeature) {
    if (feature !== newFeature) {
      const period = isChangeFeatureType(newFeature) ? "day" : "";
      onChange({
        ...value,
        feature: newFeature,
        period,
      });
    }
  }

  function onThresholdBoundChange(newBound) {
    onChange({
      ...value,
      bound: newBound,
    });
  }

  function onThresholdPeriodChange(newPeriod) {
    if (period !== newPeriod) {
      onChange({
        ...value,
        period: newPeriod,
      });
    }
  }

  let suffix = "";
  if (feature === FeatureTypeConst.PERCENTAGE_CHANGE) {
    suffix = "%";
  } else if (isDataDelayMetric) {
    suffix = "minutes";
  }

  const availableFeatureTypeOptions = getTypesAndFeaturesBySymptomType(
    SymptomTypeConst.MANUAL_THRESHOLD
  ).features.map((featureType) => ({
    label: featureTypeDisplayNameMap[featureType],
    value: featureType,
  }));

  return (
    <div className="auto-monitor-threshold-rule-creation-config-container">
      <div>
        <ManualConfigureMonitorLink
          workspaceUuid={workspaceUuid}
          metricUuid={metricUuid}
          monitorSymptom={SymptomTypeConst.MANUAL_THRESHOLD}
        />
        <LabeledSelect
          label="Type"
          value={feature}
          options={enabledThresholdFeatureOptions(
            availableFeatureTypeOptions,
            disableTrendMonitor
          )}
          onChange={onThresholdFeatureChange}
        />
      </div>
      {isChangeFeatureType(feature) && (
        <div>
          <div className="auto-monitor-threshold-rule-creation-config-label">
            Period
          </div>
          <LabeledSelect
            label="Period"
            value={getOptionByValue(thresholdPeriodOptions, period)}
            onChange={onThresholdPeriodChange}
            options={thresholdPeriodOptions}
          />
        </div>
      )}
      <div>
        <div className="auto-monitor-threshold-rule-creation-config-label">
          Select the direction for your thresholds and specify values
        </div>
        <BoundSettingView
          errors={errors}
          value={bound}
          onChange={onThresholdBoundChange}
          suffix={suffix}
          scale={isDataDelayMetric ? MINUTE_IN_SECONDS : 1}
        />
      </div>
    </div>
  );
}

function ManualConfigureMonitorLink(props) {
  const { metricUuid, workspaceUuid, monitorSymptom } = props;

  const addRuleBasicLink = getURIInstance(URIPath.ADD_MONITOR, {
    workspaceUuid,
  });
  const addRuleLink = metricUuid
    ? `${addRuleBasicLink}?metricUuid=${metricUuid}&monitorSymptom=${monitorSymptom}`
    : addRuleBasicLink;
  return (
    <div className="auto-monitor-anomaly-detection-rule-creation-config-container">
      <Link to={addRuleLink}>Manually configure monitor</Link>
    </div>
  );
}

function AnomalyDetectionConfig(props) {
  const { metricType, metricUuid, workspaceUuid } = props;
  const isEventMetric = [
    AutoMetricsType.TABLE_ACTIVITY,
    AutoMetricsType.COLUMN_ACTIVITY,
    AutoMetricsType.CATEGORY_ACTIVITY,
  ].includes(metricType);

  if (isEventMetric) {
    return null;
  }

  return (
    <ManualConfigureMonitorLink
      workspaceUuid={workspaceUuid}
      metricUuid={metricUuid}
      monitorSymptom={SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS}
    />
  );
}

function AutoMonitorRuleCreationDialogBodyComponent(props) {
  const {
    value,
    metric,
    errors,
    onChange,
    metricType,
    workspaceUuid,
    alertingChannelList,
  } = props;

  const metricUuid = metric?.metadata.uuid || "";
  const { symptomType, config, alertChannelConfig } = value;

  function onSymptomTypeChange(symptomType) {
    const newValue = {
      ...value,
      symptomType,
    };
    onChange && onChange(newValue);
  }

  function onNewConfigChange(newConfig) {
    const newValue = {
      ...value,
      config: newConfig,
    };
    onChange && onChange(newValue);
  }

  function onAlertChannelConfigChange(newAlertChannelConfig) {
    const newValue = {
      ...value,
      alertChannelConfig: newAlertChannelConfig,
    };
    onChange && onChange(newValue);
  }

  const isShowAnomalyDetectionTab = ![
    AutoMetricsType.FULL_COMPARE,
    AutoMetricsType.TABLE_ACTIVITY,
    AutoMetricsType.COLUMN_ACTIVITY,
    AutoMetricsType.CATEGORY_ACTIVITY,
    AutoMetricsType.AGGREGATION_COMPARE,
  ].includes(metricType);

  const isShowThresholdTab = ![
    AutoMetricsType.TABLE_ACTIVITY,
    AutoMetricsType.COLUMN_ACTIVITY,
    AutoMetricsType.CATEGORY_ACTIVITY,
    AutoMetricsType.NUMERICAL_DISTRIBUTION,
    AutoMetricsType.CATEGORICAL_DISTRIBUTION,
  ].includes(metricType);

  const isShowTab = ![
    AutoMetricsType.TABLE_ACTIVITY,
    AutoMetricsType.COLUMN_ACTIVITY,
    AutoMetricsType.CATEGORY_ACTIVITY,
  ].includes(metricType);

  const className = classesName(
    "auto-monitor-rule-creation-dialog-body-container",
    isShowTab ? "tabs" : "no-tabs"
  );

  return (
    <div className={className}>
      {isShowTab && (
        <Tabs
          defaultActiveKey={isShowAnomalyDetectionTab ? "anomaly" : "threshold"}
          renderTabBar={(props, DefaultTabBar) => {
            if (!isShowThresholdTab || !isShowAnomalyDetectionTab) {
              return <div style={{ marginTop: 26 }} />;
            }

            return <DefaultTabBar {...props} />;
          }}
          onChange={(tabKey) => {
            if (tabKey === "threshold") {
              const newValue = {
                ...value,
                ...getDefaultValue(
                  SymptomTypeConst.MANUAL_THRESHOLD,
                  false,
                  metricType
                ),
              };
              onChange(newValue);
            } else {
              const newValue = {
                ...value,
                ...getDefaultValue("", false, metricType),
              };
              onChange(newValue);
            }
          }}
        >
          {isShowThresholdTab && (
            <TabPane tab="Threshold" key="threshold">
              <ThresholdConfig
                value={config}
                errors={errors}
                metric={metric}
                onChange={onNewConfigChange}
                metricType={metricType}
                metricUuid={metricUuid}
                workspaceUuid={workspaceUuid}
              />
            </TabPane>
          )}
          {isShowAnomalyDetectionTab && (
            <TabPane tab="Anomaly Detection" key="anomaly">
              <AnomalyDetectionConfig
                symptomType={symptomType}
                value={config}
                onChange={onNewConfigChange}
                onSymptomTypeChange={onSymptomTypeChange}
                metricType={metricType}
                metricUuid={metricUuid}
                workspaceUuid={workspaceUuid}
              />
            </TabPane>
          )}
        </Tabs>
      )}
      <div className="auto-monitor-rule-creation-notification-container">
        <AlertConfigSettingView
          value={alertChannelConfig}
          onChange={onAlertChannelConfigChange}
          alertingChannelList={alertingChannelList}
          enableSchedule={false}
        />
      </div>
    </div>
  );
}

function getDefaultValue(
  defaultSymptomType,
  includeAlert = true,
  metricType = AutoMetricsType.VOLUME
) {
  let defaultConfig;
  if (defaultSymptomType === undefined) {
    if (
      [
        AutoMetricsType.TABLE_ACTIVITY,
        AutoMetricsType.COLUMN_ACTIVITY,
        AutoMetricsType.CATEGORY_ACTIVITY,
        AutoMetricsType.FULL_COMPARE,
        AutoMetricsType.AGGREGATION_COMPARE,
      ].includes(metricType)
    ) {
      defaultSymptomType = SymptomTypeConst.MANUAL_THRESHOLD;
    } else {
      defaultSymptomType = "";
    }
  }

  if (defaultSymptomType === SymptomTypeConst.MANUAL_THRESHOLD) {
    defaultConfig = {
      feature: FeatureTypeConst.VALUE,
      bound: {
        lower: 0,
        upper: 0,
      },
    };
  } else {
    defaultConfig = {};
  }

  const defaultValue = {
    symptomType: defaultSymptomType,
    config: defaultConfig,
  };

  if (includeAlert) {
    defaultValue.alertChannelConfig = {
      isMuted: false,
      channels: [],
    };
  }

  return defaultValue;
}

function AutoMonitorRuleCreationDialogFooterComponent(props) {
  const { onCancelClicked, onOKClicked } = props;

  return (
    <ModalConfirmationButtons
      className="auto-monitor-rule-creation-dialog-footer"
      onOkClick={onOKClicked}
      onCancelClick={onCancelClicked}
    />
  );
}

function AutoMonitorRuleCreationDialog(props) {
  const [errors, setErrors] = useState({});

  const {
    modalIsOpen,
    setModalIsOpen,
    alertingChannelList = [],
    okClicked,
    match: {
      params: { workspaceUuid },
    },
    metric,
    metricType,
  } = props;

  const [value, setValue] = useState(getDefaultValue(undefined, true, metricType));
  useEffect(() => {
    if (!metricType) {
      return;
    }

    setValue(getDefaultValue(undefined, true, metricType));
  }, [metricType]);

  function closeModal() {
    setModalIsOpen(false);
  }

  function onOkClicked() {
    setErrors({});

    if (value.symptomType === SymptomTypeConst.MANUAL_THRESHOLD) {
      const { config } = value;

      const validationErros = getConfigBoundErrors({
        lower: config.bound.lower,
        upper: config.bound.upper,
      });

      if (Object.keys(validationErros).length > 0) {
        setErrors(validationErros);
        return;
      }
    }

    okClicked(value);
    setModalIsOpen(false);
  }

  return (
    <LegacyModalDialogAdapter
      title={"Add monitor"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      containerClassName={"auto-monitor-rule-creation-dialog"}
      triggerComponent={null}
      contentComponent={
        <AutoMonitorRuleCreationDialogBodyComponent
          value={value}
          onChange={setValue}
          alertingChannelList={alertingChannelList}
          errors={errors}
          metric={metric}
          metricType={metricType}
          workspaceUuid={workspaceUuid}
        />
      }
      footerComponent={
        <AutoMonitorRuleCreationDialogFooterComponent
          onCancelClicked={closeModal}
          onOKClicked={onOkClicked}
        />
      }
    />
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AutoMonitorRuleCreationDialog));
