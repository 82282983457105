import React, { Component } from "react";
import { Select } from "antd";
import NgMultiSelect from "../../../components/multi-select/ng-multi-select";
import { isSliceEqual, getSliceDisplayString } from "../../../utils/general";

class SliceValueSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchValue: "",
    };

    this.previousLoc = -1;
    this.isRender = true;
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(event) {
    const currentScrollTop = event.target.scrollTop;
    const currentScrollHeight = event.target.scrollHeight;
    const currentClientHeight = event.target.clientHeight;
    console.log(event.target.scrollY);

    const isDown = currentScrollTop > this.previousLoc;
    this.previousLoc = currentScrollTop;
    console.log(`Direction ${isDown ? "Down" : "UP"}`);
    // options has the same length of sliceValueList
    const { sliceValueList } = this.props;
    const { offset } = this.state;
    console.log(`Current offset is ${offset}`);

    if (isDown) {
      if (
        currentScrollTop + currentClientHeight + 50 > currentScrollHeight &&
        offset + 50 < sliceValueList.length
      ) {
        console.log("Fetching new data");
        this.isRender = true;
        event.stopPropagation();
        const newOffset = Math.min(sliceValueList.length - 50, offset + 50);
        console.log(`new offset: ${newOffset}`);
        this.setState({ offset: newOffset });
        return false;
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.loading !== this.props.loading) {
      return true;
    }

    if (nextProps.sliceValueList !== this.props.sliceValueList) {
      return true;
    }

    if (nextProps.value !== this.props.value) {
      return true;
    }

    if (nextState.offset !== this.state.offset) {
      return true;
    }

    if (nextState.searchValue !== this.state.searchValue) {
      return true;
    }

    return false;
  }

  render() {
    const { offset, searchValue } = this.state;
    const {
      loading,
      sliceValueList = [],
      value,
      onChange,
      style = { width: "400px" },
      placeholder = "Please select",
      metric,
    } = this.props;

    const options = sliceValueList.map((slice, index) => {
      return {
        label: getSliceDisplayString(slice, metric),
        value: index,
      };
    });

    const currentFilterOptions = searchValue.trim()
      ? options.filter((currentOption) =>
          `${currentOption.label}`
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())
        )
      : options;

    const currentDisplayedOptions = currentFilterOptions.slice(0, offset + 100);
    const normalizedValue = value.map((sliceValue) =>
      sliceValueList.findIndex((slice) => isSliceEqual(slice, sliceValue))
    );

    return (
      <NgMultiSelect
        onPopupScroll={(e) => this.handleScroll(e)}
        style={style}
        placeholder={placeholder}
        searchValue={searchValue}
        onSearch={(newSearchValue) => this.setState({ searchValue: newSearchValue })}
        value={normalizedValue}
        loading={loading}
        mode={"multiple"}
        transitionName={""} // Disable animation
        onChange={(newSelectSliceValues) => {
          this.setState({ searchValue: "" });
          const normalizedSelectSliceValue = newSelectSliceValues.map(
            (labelValue) => sliceValueList[labelValue]
          );
          onChange && onChange(normalizedSelectSliceValue);
        }}
        filterOption={false}
      >
        {currentDisplayedOptions.map((currentOption, i) => {
          return (
            <Select.Option key={i} value={currentOption.value}>
              {currentOption.label}
            </Select.Option>
          );
        })}
      </NgMultiSelect>
    );
  }
}

export default SliceValueSelect;
