import axios, { createCancelTokenSource } from "../utils/api";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

export function createGenericCancelToken() {
  return createCancelTokenSource();
}

export function getDataSourceListPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_LIST, {
          workspaceUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addDataSourcePromise(workspaceUuid, newDataSource) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_LIST, {
          workspaceUuid,
        }),
        newDataSource,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourcePromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function previewDataSourcePromise(workspaceUuid, dataSource) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_PREVIEW, {
          workspaceUuid,
        }),
        dataSource,
        { baseURL: "/api/v1/", disableToast: true }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function triggerDataSourceMetricPromise(
  workspaceUuid,
  dataSourceUuid,
  triggerPayload
) {
  return new Promise(function (resolve, reject) {
    const { metricUuids = [], tableUuids = [] } = triggerPayload;
    if (metricUuids.length === 0 && tableUuids.length === 0) {
      reject("Invalid params");
      return;
    }

    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TRIGGER, {
          workspaceUuid,
          uuid: dataSourceUuid,
        }),
        { tableUuids, metricUuids },
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceUsagePromise(workspaceUuid, uuid, type) {
  return new Promise(function (resolve, reject) {
    const options = {};
    type && (options.metricType = type);
    axios
      .post(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_USAGE, {
          workspaceUuid,
          uuid,
        })}`,
        options,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceSummaryPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_SUMMARY, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateDataSourceProfilerConfigPromise(
  workspaceUuid,
  uuid,
  newProfilerConfig
) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_PROFILER_CONFIG, {
          workspaceUuid,
          uuid,
        }),
        newProfilerConfig,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateDataSourcePromise(workspaceUuid, uuid, newDataSource) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE, {
          workspaceUuid,
          uuid,
        }),
        newDataSource,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteDataSourcePromise(workspaceUuid, uuid, isForce = true) {
  return new Promise(function (resolve, reject) {
    const axiosOptions = { baseURL: "/api/v1/" };
    if (isForce) {
      axiosOptions.headers = { "X-Lup-Action-Type": "force" };
    }

    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE, {
          workspaceUuid,
          uuid,
        }),
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourcePartitionSampleDataPromise(
  workspaceUuid,
  uuid,
  queryOptions = {}
) {
  return new Promise(function (resolve, reject) {
    const { schemaName, tableName } = queryOptions;
    const queryArray = [];
    if (schemaName) {
      queryArray.push(`schema_name=${schemaName}`);
    }

    if (tableName) {
      queryArray.push(`table_name=${tableName}`);
    }

    let queryString = "";
    if (queryArray.length > 0) {
      queryString = `?${queryArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_PARTITION_SAMPLE, {
          workspaceUuid,
          uuid,
        })}${queryString}`,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceQueryListPromise(workspaceUuid, uuid, queryOptions = {}) {
  return new Promise(function (resolve, reject) {
    const { startTs, endTs } = queryOptions;

    const queryArray = [];
    if (typeof startTs === "number") {
      queryArray.push(`start_ts=${startTs}`);
    }

    if (typeof endTs === "number") {
      queryArray.push(`end_ts=${endTs}`);
    }

    let queryString = "";
    if (queryArray.length > 0) {
      queryString = `?${queryArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_QUERY_HISTORY, {
          workspaceUuid,
          uuid,
        })}${queryString}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTreePromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TREE, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceSchemaListPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_SCHEMA_LIST, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceSchemaSummaryPromise(workspaceUuid, uuid, schemaUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_SCHEMA_SUMMARY, {
          workspaceUuid,
          uuid,
          schemaUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateDataSourceSchemaProfilerConfigPromise(
  workspaceUuid,
  uuid,
  schemaUuid,
  newProfilerConfig
) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_SCHEMA_PROFILER_CONFIG, {
          workspaceUuid,
          uuid,
          schemaUuid,
        }),
        newProfilerConfig,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableListPromise(workspaceUuid, uuid, queryOptions = {}) {
  return new Promise(function (resolve, reject) {
    const { limit } = queryOptions;

    const queryArray = [];
    if (typeof limit === "number") {
      queryArray.push(`limit=${limit}`);
    }

    let queryString = "";
    if (queryArray.length > 0) {
      queryString = `?${queryArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_LIST, {
          workspaceUuid,
          uuid,
        })}${queryString}`,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableBlobListPromise(
  workspaceUuid,
  uuid,
  payload = {},
  cancelToken = null
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_BLOB_LIST, {
          workspaceUuid,
          uuid,
        }),
        payload,
        { baseURL: "/api/v1/", cancelToken: cancelToken.token }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function createDataSourceTablePromise(workspaceUuid, uuid, payload = {}) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_LIST, {
          workspaceUuid,
          uuid,
        }),
        payload,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceSchemaSqlPromise(workspaceUuid, uuid, payload) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_SCHEMA_SQL, {
          workspaceUuid,
          uuid,
        })}`,
        payload,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteDataSourceTablePromise(
  workspaceUuid,
  uuid,
  tableUuid,
  isForce = false
) {
  return new Promise(function (resolve, reject) {
    const axiosOptions = { baseURL: "/api/v1/" };
    if (isForce) {
      axiosOptions.headers = { "X-Lup-Action-Type": "force" };
    }

    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableSummaryPromise(workspaceUuid, uuid, tableUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_SUMMARY, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTablePromise(workspaceUuid, uuid, tableUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableHealthPromise(workspaceUuid, uuid, tableUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_HEALTH, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve({ tableUuid, ...response.data });
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableUsagePromise(
  workspaceUuid,
  uuid,
  tableUuid,
  type = "",
  tableProfilerConfig = null
) {
  return new Promise(function (resolve, reject) {
    const options = {};
    type && (options.metricType = type);
    tableProfilerConfig && (options.tableProfilerConfig = tableProfilerConfig);

    axios
      .post(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_USAGE, {
          workspaceUuid,
          uuid,
          tableUuid,
        })}`,
        options,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceSchemaUsagePromise(workspaceUuid, uuid, schemaUuid, type) {
  return new Promise(function (resolve, reject) {
    const options = {};
    type && (options.metricType = type);
    axios
      .post(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_SCHEMA_USAGE, {
          workspaceUuid,
          uuid,
          schemaUuid,
        })}`,
        options,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateDataSourceTableProfilerConfigPromise(
  workspaceUuid,
  uuid,
  tableUuid,
  newProfilerConfig
) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_PROFILER_CONFIG, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        newProfilerConfig,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableColumnListPromise(workspaceUuid, uuid, tableUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN_LIST, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableColumnSummaryPromise(
  workspaceUuid,
  uuid,
  tableUuid,
  columnUuid
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN_SUMMARY, {
          workspaceUuid,
          uuid,
          tableUuid,
          columnUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableColumnPromise(
  workspaceUuid,
  uuid,
  tableUuid,
  columnUuid
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN, {
          workspaceUuid,
          uuid,
          tableUuid,
          columnUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableColumnUsagePromise(
  workspaceUuid,
  uuid,
  tableUuid,
  columnUuid,
  opts = {}
) {
  return new Promise(function (resolve, reject) {
    const { type = "", checkMasked = false, checkCast = false } = opts;
    const options = {};
    type && (options.metricType = type);
    checkMasked && (options.checkMasked = checkMasked);
    checkCast && (options.checkCast = checkCast);

    axios
      .post(
        `${getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN_USAGE, {
          workspaceUuid,
          uuid,
          tableUuid,
          columnUuid,
        })}`,
        options,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateDataSourceTableColumnProfilerConfigPromise(
  workspaceUuid,
  uuid,
  tableUuid,
  columnUuid,
  newProfilerConfig
) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(
          backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN_PROFILER_CONFIG,
          { workspaceUuid, uuid, tableUuid, columnUuid }
        ),
        newProfilerConfig,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableListLegacyPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_LIST_LEGACY, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableColumnListLegacyPromise(
  workspaceUuid,
  uuid,
  schemaName,
  tableName
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `${getAPIURIInstance(
          backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN_LIST_LEGACY,
          { workspaceUuid, uuid }
        )}?schema_name=${schemaName}&table_name=${tableName}`,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableDataProfilePromise(workspaceUuid, uuid, tableUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_DATA_PROFILE, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableColumnListDataProfilePromise(
  workspaceUuid,
  uuid,
  tableUuid
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(
          backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN_LIST_DATA_PROFILE,
          {
            workspaceUuid,
            uuid,
            tableUuid,
          }
        ),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableColumnDataProfilePromise(
  workspaceUuid,
  uuid,
  tableUuid,
  columnUuid
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(
          backendRelativeURIPath.DATA_SOURCE_TABLE_COLUMN_DATA_PROFILE,
          {
            workspaceUuid,
            uuid,
            tableUuid,
            columnUuid,
          }
        ),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDataSourceTableChangeLogPromise(workspaceUuid, uuid, tableUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DATA_SOURCE_TABLE_CHANGE_LOG, {
          workspaceUuid,
          uuid,
          tableUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
