import { Spinner } from "../../atom/spinner";

import "./loading-state-container.scss";

export default function LoadingStateContainer() {
  return (
    <div className="loading-state-container">
      <div className="loading-state-container-spinner">
        <Spinner size="large" />
      </div>
    </div>
  );
}
