import React from "react";
import NgTable, { NgTableEmptyState } from "../table/ng-table";
import { QueryHistoryIcon } from "./icons";
import MaskedColumnContent from "../column/masked-column-content";

import "./table-sample-data-view.scss";

function TableSampleDataView({ tableSchemaList, sampleData, tableColumnList }) {
  const { loading, data, error } = sampleData;
  const sampleDataColumns = tableSchemaList.data.map(({ columnUuid, columnName }) => ({
    title: columnName,
    dataIndex: columnName,
    render: (value) => {
      const columnInfo = tableColumnList?.find((column) => column.uuid === columnUuid);

      let formattedValue = value;

      if (typeof value === "object") {
        formattedValue = JSON.stringify(value);
      }

      if (typeof value === "boolean") {
        formattedValue = value ? "true" : "false";
      }

      if (columnInfo?.profilerConfig?.masked) {
        return <MaskedColumnContent value={formattedValue} />;
      }

      return formattedValue;
    },
  }));

  const sampleDataData = (data?.data || [])
    .slice(0, 5)
    .map((dataRow, index) => ({ key: index, ...dataRow }));

  let emptyState = null;
  if (error) {
    emptyState = (
      <NgTableEmptyState
        icon={<QueryHistoryIcon />}
        title={"Sample data cannot be displayed"}
        description={error}
      />
    );
  } else if (data.message) {
    emptyState = (
      <NgTableEmptyState
        icon={<QueryHistoryIcon />}
        title={""}
        description={data.message}
      />
    );
  }

  return (
    <div className="table-sample-data-view-container">
      <div className="table-sample-data-view-title">Sample Data</div>
      <NgTable
        columns={sampleDataColumns}
        dataSource={sampleDataData}
        loading={loading}
        pagination={false}
        scroll={{ x: true }}
        emptyState={emptyState}
      />
    </div>
  );
}

export default TableSampleDataView;
