import Button from "../../components/button/ng-button";
import React, { useEffect, useMemo, useState } from "react";
import TakeoverPanel from "../../components/takeover/takeover-panel";
import { FindAnyMetricIcon } from "./incident-analysis-view";
import {
  LabeledMultiSelect,
  LabeledSelect,
} from "../../components/labeled-control/labeled-control";
import { getSliceDisplayString } from "../../utils/general";
import { indexBy } from "../../utils/iterables";
import { connect } from "react-redux";
import { getSliceByColumns } from "../../utils/metric";
import {
  getCurrentIncidentInterestMetricSliceValueList,
  resetCurrentIncidentInterestMetricSliceValueList,
} from "../../actions/incident/incident-action";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { MetricCategory } from "../../utils/enums";

import "./incident-find-metric-panel.scss";

function canAddMetric(metric) {
  // It's not clear how to handle adding row-by-row metrics to incidents, so
  // we're excluding this for now.
  return getMetricTypeFromConfigData(metric) !== MetricCategory.FULL_COMPARE;
}

function IncidentFindMetricPanel(props) {
  const {
    workspaceUuid,
    closeTakeover,
    metrics,
    currentIncidentInterestMetricSliceValueList,
    onAdd,
    getInterestMetricSliceValueList,
    resetCurrentIncidentInterestmetricSliceValueList,
  } = props;

  const [selectedMetricUuid, setSelectedMetricUuid] = useState(null);
  const [selectedSlices, setSelectedSlices] = useState(null);

  useEffect(() => {
    resetCurrentIncidentInterestmetricSliceValueList();
  }, [resetCurrentIncidentInterestmetricSliceValueList]);

  const metricsByUuid = useMemo(() => {
    return indexBy(metrics, (metric) => metric.metadata.uuid);
  }, [metrics]);

  const selectedMetric = selectedMetricUuid ? metricsByUuid[selectedMetricUuid] : null;
  const isSelectedMetricSliced = selectedMetric
    ? getSliceByColumns(selectedMetric).length > 0
    : false;

  const metricOptions = useMemo(() => {
    return metrics.filter(canAddMetric).map((metric) => ({
      label: metric.metadata.name,
      value: metric.metadata.uuid,
    }));
  }, [metrics]);

  const sliceValueOptions = currentIncidentInterestMetricSliceValueList.data.map(
    (slice, index) => {
      return {
        label: getSliceDisplayString(slice),
        value: index,
      };
    }
  );

  function onAddClick() {
    const slices = selectedSlices.map((selectedSlice) => ({
      slice: currentIncidentInterestMetricSliceValueList.data[selectedSlice],
    }));
    onAdd(selectedMetricUuid, slices);
    closeTakeover();
  }

  const canAdd =
    selectedMetricUuid && (!isSelectedMetricSliced || selectedSlices.length > 0);

  const cornerControls = (
    <Button outline size="large" onClick={() => closeTakeover()}>
      Cancel
    </Button>
  );

  return (
    <TakeoverPanel
      title="Find any metric"
      titleIcon={<FindAnyMetricIcon width={22} height={22} />}
      cornerControls={cornerControls}
    >
      <div className="incident-find-metric-panel-content">
        <LabeledSelect
          label="Select metric"
          showSearch
          enableSorting={true}
          placeholder="Search metric name..."
          options={metricOptions}
          value={selectedMetricUuid}
          onChange={(newSelectedMetricUuid) => {
            setSelectedMetricUuid(newSelectedMetricUuid);
            setSelectedSlices([]);
            getInterestMetricSliceValueList(workspaceUuid, newSelectedMetricUuid);
          }}
        />
        {isSelectedMetricSliced && (
          <LabeledMultiSelect
            staticLabel
            mode="multiple"
            showSearch
            allowClear
            label="Select slice"
            enableSorting={true}
            options={sliceValueOptions}
            value={selectedSlices}
            disabled={!isSelectedMetricSliced}
            onChange={(newSelectedSlices) => {
              setSelectedSlices(newSelectedSlices);
            }}
            filterOption={(input, option) =>
              option.label?.toLowerCase().includes(input?.trim().toLowerCase())
            }
          />
        )}
        <Button
          primary
          block
          size="large"
          disabled={!canAdd}
          onClick={() => onAddClick()}
        >
          Add
        </Button>
      </div>
    </TakeoverPanel>
  );
}

const mapStateToProps = (state) => ({
  currentIncidentInterestMetricSliceValueList:
    state.incidentReducer.currentIncidentInterestMetricSliceValueList,
});

const mapDispatchToProps = (dispatch) => ({
  getInterestMetricSliceValueList: (workspaceUuid, metricUuid) =>
    dispatch(getCurrentIncidentInterestMetricSliceValueList(workspaceUuid, metricUuid)),
  resetCurrentIncidentInterestmetricSliceValueList: () =>
    dispatch(resetCurrentIncidentInterestMetricSliceValueList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentFindMetricPanel);
