import NgToggleCheckbox from "../../toggle-checkbox/ng-toggle-checkbox";

function SapHanaOptionConfigView(props) {
  const { value, onChange } = props;

  const { sslValidateCertificate } = value;

  return (
    <NgToggleCheckbox
      labelPosition="left"
      label="Validate SSL certificate"
      value={sslValidateCertificate}
      onChange={(newSslValidateCertificate) => {
        onChange?.({ ...value, sslValidateCertificate: newSslValidateCertificate });
      }}
    />
  );
}

export default SapHanaOptionConfigView;
