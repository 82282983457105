import React, { useEffect, useState } from "react";
import { DefaultTimeZone } from "../../../utils/time";
import ServiceNowIntegrationBasicDialog, {
  canAttemptAuthentication,
  getServiceNowAuthFields,
} from "./servicenow-integration-basic-dialog";
import {
  SubmitType,
  isBranch,
  isMetadata,
  minimalFields,
} from "../integration-metadata";
import { AlertingChannelType } from "../../../utils/alerting";
import { useOutsideClickConfirm } from "../../../hooks/useOutsideClickConfirm";
import { getIntegrationFormSaveStatus } from "../integration-metadata-editor";

const defaultServiceNowInstance = {
  title: "",
  host: "",
  timezone: DefaultTimeZone,
  username: "",
  password: "",
  mode: SubmitType.AUTOMATIC,
  metadata: null,
};

function AddServiceNowIntegrationDialog(props) {
  const {
    workspaceUuid,
    currentMetadata,
    onAdd,
    onPreview,
    modalIsOpen,
    outsideClickWrap,
    setIsOpen,
    getIntegrationMetadata,
    resetIntegrationMetadata,
  } = props;

  const [value, setValue] = useState(defaultServiceNowInstance);
  const [isLoading, setIsLoading] = useState(false);
  const [lastAuthAttemptData, setLastAuthAttemptData] = useState(null);
  const [lastSuccessfulAuthAttemptData, setLastSuccessfulAuthAttemptData] =
    useState(null);

  useEffect(() => {
    resetIntegrationMetadata();
  }, [resetIntegrationMetadata]);

  useOutsideClickConfirm({
    outsideClickWrap,
    close: () => setIsOpen(false),
    originalValue: defaultServiceNowInstance,
    currentValue: value,
  });

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  const onAuthenticate = (newData) => {
    setLastAuthAttemptData(getServiceNowAuthFields(newData));
    setIsLoading(true);
    getIntegrationMetadata(workspaceUuid, newData)
      .then((metadata) => {
        setLastSuccessfulAuthAttemptData(
          metadata ? getServiceNowAuthFields(newData) : {}
        );
        setValue({
          ...value,
          metadata: minimalFields(AlertingChannelType.SERVICENOW, metadata),
        });
      })
      .finally(() => setIsLoading(false));
  };

  const { enableSave, authButtonStatus } = getIntegrationFormSaveStatus({
    isEdit: false,
    lastAuthAttemptData,
    lastSuccessfulAuthAttemptData,
    currentAuthFields: getServiceNowAuthFields(value),
    canAttemptAuth: canAttemptAuthentication(value),
    fieldsConfigured: isMetadata(value.metadata) && isBranch(value.metadata),
    isLoading,
  });

  return (
    <ServiceNowIntegrationBasicDialog
      value={value}
      currentMetadata={currentMetadata}
      className={"add-service-now-integration-dialog-container"}
      dialogTitle={"Add ServiceNow Integration"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      OKText={"Add"}
      enablePreview={true}
      enableSave={enableSave}
      authButtonStatus={authButtonStatus}
      onChange={setValue}
      onAuthenticate={onAuthenticate}
      onPreview={onPreview}
      onOkClicked={onOkClicked}
    />
  );
}

export default AddServiceNowIntegrationDialog;
