import React from "react";
import NgButton, { TextWithIcon } from "../button/ng-button";
import { ProfilerActiveIcon } from "../icons/toggle-icon/";
import background from "../../assets/img/enable_container.png";
import gridOnlyBackground from "../../assets/img/enable_container_grid_only.png";
import { classesName } from "../../utils/css";
import { Spinner } from "../../atom/spinner";

import "./chart-enable-view.scss";

export default function ChartEnableView(props) {
  const {
    label,
    icon,
    onChange,
    value,
    gridOnly = false,
    content: propsContent = null,
    fullHeight = false,
    loading = false,
    disabled = false,
  } = props;

  let content;
  if (loading) {
    content = (
      <div className="chart-enable-view-loading">
        <Spinner size="large" />
      </div>
    );
  } else if (propsContent) {
    content = propsContent;
  } else {
    content = (
      <div className="chart-enable-view-default-content-container">
        {icon}
        <div className="chart-enable-view-switch-container">
          <NgButton outline onClick={() => onChange(!value)} disabled={disabled}>
            <TextWithIcon icon={<ProfilerActiveIcon />}>{label}</TextWithIcon>
          </NgButton>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${classesName(
        "chart-enable-view-container",
        fullHeight && "full-height"
      )}`}
    >
      <div
        style={{
          height: "100%",
          backgroundImage: `url(${gridOnly ? gridOnlyBackground : background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "12px",
        }}
      >
        <div className="chart-enable-view-content-container">{content}</div>
      </div>
    </div>
  );
}
