import React from "react";
import { IncidentDirection } from "../../utils/enums";
import ToggleButton from "../toggle-button";
import { ButtonPaddingSize } from "../button/ng-button";

import "./incident-direction-selector.scss";

function IncidentDirectionDownIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583374 7.00004C0.583374 3.45621 3.45621 0.583374 7.00004 0.583374C10.5439 0.583374 13.4167 3.45621 13.4167 7.00004C13.4167 10.5439 10.5439 13.4167 7.00004 13.4167C3.45621 13.4167 0.583374 10.5439 0.583374 7.00004ZM7.00004 3.79171C7.32221 3.79171 7.58337 4.05287 7.58337 4.37504V8.21675L9.21256 6.58756C9.44037 6.35976 9.80971 6.35976 10.0375 6.58756C10.2653 6.81537 10.2653 7.18471 10.0375 7.41252L7.41252 10.0375C7.29999 10.1501 7.15291 10.207 7.00543 10.2084C6.84921 10.2084 6.69841 10.1484 6.58756 10.0375L3.96256 7.41252C3.73476 7.18471 3.73476 6.81537 3.96256 6.58756C4.19037 6.35976 4.55971 6.35976 4.78752 6.58756L6.41671 8.21675V4.37504C6.41671 4.05287 6.67787 3.79171 7.00004 3.79171Z"
        fill="#121224"
      />
    </svg>
  );
}

function IncidentDirectionUpIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583374 7C0.583374 3.45617 3.45621 0.583332 7.00004 0.583332C10.5439 0.583332 13.4167 3.45617 13.4167 7C13.4167 10.5438 10.5439 13.4167 7.00004 13.4167C3.45621 13.4167 0.583374 10.5438 0.583374 7ZM6.41671 9.77083V5.92912L4.78752 7.55831C4.55971 7.78612 4.19037 7.78612 3.96256 7.55831C3.73476 7.33051 3.73476 6.96116 3.96256 6.73335L6.59139 4.10456C6.64433 4.05259 6.70714 4.01063 6.77675 3.98176C6.98819 3.89428 7.24102 3.93685 7.41289 4.10872L10.0375 6.73335C10.2653 6.96116 10.2653 7.33051 10.0375 7.55831C9.80971 7.78612 9.44037 7.78612 9.21256 7.55831L7.58337 5.92912V9.77083C7.58337 10.093 7.32221 10.3542 7.00004 10.3542C6.67787 10.3542 6.41671 10.093 6.41671 9.77083Z"
        fill="#121224"
      />
    </svg>
  );
}

function IncidentDirectionSelector(props) {
  const {
    value,
    onChange,
    disabled = false,
    UpValue = IncidentDirection.UP,
    DownValue = IncidentDirection.DOWN,
    UpIcon = IncidentDirectionUpIcon,
    DownIcon = IncidentDirectionDownIcon,
    preserveIconColor = false,
    layout = "horizontal",
  } = props;

  const setDirection = (direction) => {
    // If we click again on the selected direction, we consider it clears state.
    const directionSet = new Set(value);
    if (directionSet.has(direction)) {
      directionSet.delete(direction);
    } else {
      directionSet.add(direction);
    }

    onChange([...directionSet]);
  };

  const isDownOn = value.indexOf(DownValue) !== -1;
  const isUpOn = value.indexOf(UpValue) !== -1;

  const className = `incident-direction-selector-container ${layout}`;

  return (
    <div className={className}>
      <ToggleButton
        buttonProps={{ size: "large", paddingSize: ButtonPaddingSize.SMALL }}
        preserveIconColor={preserveIconColor}
        active={isUpOn}
        disabled={disabled}
        onChange={() => setDirection(UpValue)}
      >
        <UpIcon />
      </ToggleButton>
      <ToggleButton
        buttonProps={{ size: "large", paddingSize: ButtonPaddingSize.SMALL }}
        preserveIconColor={preserveIconColor}
        active={isDownOn}
        disabled={disabled}
        onChange={() => setDirection(DownValue)}
      >
        <DownIcon />
      </ToggleButton>
    </div>
  );
}

export default IncidentDirectionSelector;
