import React from "react";
import { NextGenPalette } from "../../../utils/color";
import { fromUnixTime } from "date-fns";
import Plot from "../../../utils/plotly";
import { getDisplayTimeFromDuration } from "../../../utils/time";
import { classesName } from "../../../utils/css";
import { dataProfileChartBaseLayout } from "../profiler-data-profile-charts";
import { isRenderableString } from "../../../utils/general";
import { getLabelFromName } from "../utils";

import "./profiler-data-source-table-timestamp-analysis.scss";

export function TimestampAnalysisChart(props) {
  const { data, xLabel = null, yLabel = null } = props;

  if (data.length === 0) {
    return null;
  }

  const x = [];
  const y = [];
  data.forEach(({ eventTs, value }) => {
    x.push(fromUnixTime(eventTs));
    y.push(value);
  });

  const trace = {
    x,
    y,
    name: "",
    type: "bar",
    marker: { color: NextGenPalette.purple },
  };

  const baseLayout = dataProfileChartBaseLayout();
  const layout = {
    ...baseLayout,
    yaxis: {
      ...baseLayout.yaxis,
      showticklabels: false,
    },
    margin: {
      ...baseLayout.margin,
      b: 45,
    },
  };

  if (isRenderableString(xLabel)) {
    layout.xaxis.title = xLabel;
  }

  if (isRenderableString(yLabel)) {
    layout.yaxis.title = yLabel;
  }

  const config = {
    displayModeBar: false,
    responsive: true,
  };

  return (
    <div className="data-profile-timestamp-analysis-plot">
      <Plot
        data={[trace]}
        layout={layout}
        config={config}
        useResizeHandler
        style={{ width: "100%", height: 165 }}
      />
    </div>
  );
}

function ProfilerDataSourceTableTimestampAnalysis(props) {
  const { data } = props;
  return (
    <div className="data-profile-timestamp-analysis">
      <div className="data-profile-timestamp-analysis-title">Timestamp Analysis</div>
      <div className="data-profile-timestamp-analysis-charts">
        {data.map((column, index) => {
          const dataDelayStr =
            typeof column.dataProfile.dataDelay === "number"
              ? getDisplayTimeFromDuration(column.dataProfile.dataDelay, {
                  allowNegative: true,
                })
              : "N/A";
          const isOnlyChartOnRow = index === data.length - 1 && data.length % 2 === 1;
          const className = classesName(
            "data-profile-timestamp-analysis-chart-container",
            isOnlyChartOnRow && "only-chart-on-row"
          );

          return (
            <div key={column.name} className={className}>
              <div className="data-profile-timestamp-analysis-chart-title">
                {getLabelFromName(column.name)}
              </div>
              <TimestampAnalysisChart
                data={column.dataProfile.tableVolumePreview}
                delay={column.dataProfile.dataDelay}
                xLabel={getLabelFromName(column.name)}
                yLabel="Row Count"
              />
              <div className="data-profile-timestamp-analysis-data-delay">
                <div className="data-profile-timestamp-analysis-chart-title">
                  {getLabelFromName(column.name)} data delay
                </div>
                <div className="data-profile-timestamp-analysis-data-delay-value">
                  {dataDelayStr}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProfilerDataSourceTableTimestampAnalysis;
