import React from "react";
import PasscodeConfirmationModal from "../passcode-confirmation-modal/passcode-confirmation-modal";

function WorkspaceDeleteModal(props) {
  const { workspaceName, visible, onDeleteConfirmed, onDeleteCancelled } = props;
  return (
    <PasscodeConfirmationModal
      visible={visible}
      title="Confirm workspace deletion"
      okText="Delete"
      cancelText="Cancel"
      onConfirm={onDeleteConfirmed}
      onCancel={onDeleteCancelled}
    >
      Are you sure you want to delete this workspace ({workspaceName})? All data
      associated with this workspace will also be deleted. This operation cannot be
      undone.
    </PasscodeConfirmationModal>
  );
}

export default WorkspaceDeleteModal;
