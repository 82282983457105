import React from "react";
import { Divider as AntDivider } from "antd";
import { classesName } from "../../utils/css";
import "./divider.scss";
export default function Divider(props) {
  const { testId = null, children = null, className = "", ...otherProps } = props;
  return (
    <AntDivider
      data-testid={testId}
      {...otherProps}
      className={classesName("lightup-divider", className)}
    >
      {children}
    </AntDivider>
  );
}
