export const DataTypes = Object.freeze({
  NULL: "null",
  NUMBER: "number",
  STRING: "string",
  DATETIME: "datetime",
  UNKNOWN: "unknown",
  INCONSISTENT: "inconsistent",
});

function isValidDate(str) {
  if (str.length < 2) {
    // empty or single character cannot be a datetime
    return false;
  }
  return !isNaN(new Date(str));
}

function getDataType(value) {
  if (value === null) {
    return DataTypes.NULL;
  }
  if (typeof value === "number") {
    return DataTypes.NUMBER;
  }
  if (typeof value === "string") {
    return isValidDate(value) ? DataTypes.DATETIME : DataTypes.STRING;
  }
  return DataTypes.UNKNOWN;
}

function resolveDataType(dataTypeList, config) {
  const { allowNull = false } = config;
  if (dataTypeList.length === 1) {
    return dataTypeList[0];
  }
  if (allowNull && dataTypeList.length === 2 && dataTypeList.includes(DataTypes.NULL)) {
    return dataTypeList[(dataTypeList.indexOf(DataTypes.NULL) + 1) % 2];
  }
  return DataTypes.INCONSISTENT;
}

export function detectDataTypes(objectList, config = {}) {
  const result = {};
  for (const obj of objectList) {
    for (const [key, value] of Object.entries(obj)) {
      if (!(key in result)) {
        result[key] = {};
      }
      result[key][getDataType(value)] = 1;
    }
  }
  for (const [key, objectValue] of Object.entries(result)) {
    result[key] = resolveDataType(Object.keys(objectValue), config);
  }
  return result;
}
