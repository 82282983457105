import React from "react";
import { DataAssetCards } from "../../../fields/";
import { getColumnTypeCategory } from "../../../../../utils/column";
import {
  MetricDataAssetType,
  TableColumnTypeCategory,
  MetricType,
  isFileSource,
} from "../../../../../utils/enums";
import DataAssetSelect from "../../../fields/data-asset-select";
import ConfigureMetric from "../../../fields/configure-metric";
import WhereClause from "../../../fields/where-clause";
import AddTags from "../../../fields/add-tags";
import MetricFailingRecordsQueryConfigItem from "../../../fields/metric-failing-records-query-config-item";
import { distributionAggregationConfigByColumnType } from "../../../utils";
import { isAutoCreationTypeMetric } from "../../../../../utils/metric";

const supportedColumnTypes = [
  TableColumnTypeCategory.STRING,
  TableColumnTypeCategory.NUMERIC,
];

function DistributionMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    onDataSourceChanged,
    schemaList,
    tableList,
    onTableChanged,
    columnList: { data: columnList },
    partitionSampleData,
    getPartitionSampleData,
    tagList,
    waffle,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult = { loading: false },
    resetKPIStatus,
    openWorkspaceTakeover,
    closeTakeover,
    currentDataSource,
    disabled,
  } = props;

  const isEditMode = !configData.config.inheritTableSettings;
  const isStreaming = configData.config.isStreaming;
  const allowOverride = configData.metadata.creationType !== MetricType.AUTO;
  const isFileDataSource = isFileSource(currentDataSource?.config?.connection?.type);

  const supportedColumnList = (columnList ?? []).filter((column) =>
    supportedColumnTypes.includes(getColumnTypeCategory(column))
  );

  const normalizedSlicedByColumns = [];
  const detectedSystemSliceByColumns = [];
  for (let currentSliceByColumn of configData?.config?.sliceByColumns || []) {
    if (currentSliceByColumn.indexOf("lightup__") !== -1) {
      detectedSystemSliceByColumns.push(currentSliceByColumn);
    } else {
      normalizedSlicedByColumns.push(currentSliceByColumn);
    }
  }

  return (
    <DataAssetCards>
      <DataAssetSelect
        dataAssetType={MetricDataAssetType.COLUMN}
        configData={configData}
        dataSourceList={dataSourceList}
        schemaList={schemaList}
        tableList={tableList}
        columnList={supportedColumnList}
        isStreaming={isStreaming}
        onConfigDataChange={onConfigDataChange}
        onDataSourceChanged={onDataSourceChanged}
        onTableChanged={onTableChanged}
        resetKPIStatus={resetKPIStatus}
        disabled={disabled || isAutoCreationTypeMetric(configData)}
        onColumnChange={(_newColumnUuid, currentColumn) => {
          const aggregation = {
            ...configData.config.aggregation,
            ...distributionAggregationConfigByColumnType[
              getColumnTypeCategory(currentColumn)
            ],
          };

          // FT-1573: When switching between numerical and string column types,
          // we also transition between multiPercentile and categoricalDistributions aggregations.
          // However, since the multiPercentile aggregation still includes missingValueFilling in its response,
          // it's necessary to remove this to prevent validation problems.
          const clearMissingValueFilling = configData.config.missingValueFilling
            ? { missingValueFilling: undefined }
            : {};

          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              ...clearMissingValueFilling,
              aggregation,
              valueColumns: [
                {
                  columnName: currentColumn.columnName,
                  columnUuid: currentColumn.uuid,
                },
              ],
            },
          });

          resetKPIStatus({
            resetAll: false,
            resetPreviewInfo: true,
          });
        }}
      />
      <ConfigureMetric
        enableSeasonality={true}
        enableSlicing={!isAutoCreationTypeMetric(configData)}
        allowOverride={allowOverride}
        configData={configData}
        isValidConfig={isValidConfig}
        isStreaming={isStreaming}
        dataSourceList={dataSourceList}
        tableList={tableList}
        columnList={columnList}
        isEditMode={isEditMode}
        onConfigDataChange={onConfigDataChange}
        openWorkspaceTakeover={openWorkspaceTakeover}
        closeTakeover={closeTakeover}
        partitionSampleData={partitionSampleData}
        getPartitionSampleData={getPartitionSampleData}
        sliceByColumnsGetter={() => normalizedSlicedByColumns}
        onSliceByColumnsChange={(sliceByColumns) => {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              sliceValueSelections: [],
              sliceByColumns: [...sliceByColumns, ...detectedSystemSliceByColumns],
            },
          });
        }}
        waffle={waffle}
        enableMissingValueFilling={false}
        disabled={disabled}
      />
      {!isAutoCreationTypeMetric(configData) && !isFileDataSource && (
        <WhereClause
          configData={configData}
          columnList={columnList}
          onConfigDataChange={onConfigDataChange}
          schemaList={schemaList}
          tableList={tableList}
          disabled={disabled}
        />
      )}
      {!isFileDataSource && (
        <MetricFailingRecordsQueryConfigItem
          configData={configData}
          value={configData.config.failingRecordsSql}
          validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
          validateFailingRecordsSqlResult={validateFailingRecordsSqlResult}
          onChange={(newFailingRecordsSql) => {
            onConfigDataChange({
              ...configData,
              config: {
                ...configData.config,
                failingRecordsSql: newFailingRecordsSql,
              },
            });
          }}
          disabled={disabled}
        />
      )}
      <AddTags
        configData={configData}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
        disabled={disabled}
      />
    </DataAssetCards>
  );
}

DistributionMetricDataAssetConfig.assetMeta = {
  title: "Select data asset",
  createDescription:
    "Create your metric by selecting the type, naming it, picking a data asset, and configuring the settings",
  editDescription:
    "Edit your metric by naming it, picking a data asset, and configuring the settings",
};

export default DistributionMetricDataAssetConfig;
