import React from "react";
import TakeoverPanel from "../../../takeover/takeover-panel";
import { SettingOutlined } from "@ant-design/icons";
import Button from "../../../button/ng-button";
import {
  formatCustomSql,
  getQueryRecordStatusDisplayName,
} from "../../../../utils/general";
import {
  getDisplayTimeFromSecond,
  getStringFromTimeStamp,
} from "../../../../utils/time";
import { isNullOrUndefined } from "../../../../utils/objects";

import "./metric-config-preview-query-history-query-details.scss";

function QueryDetailsQueryStats(props) {
  const { queryHistoryItem } = props;

  const { engineQueryId, queryUuid, startTs, endTs, status, queryType, queryStats } =
    queryHistoryItem;

  const statsRows = [
    { label: "Status", value: getQueryRecordStatusDisplayName(status) },
    { label: "Start Time", value: getStringFromTimeStamp(startTs) },
    {
      label: "Duration",
      value:
        typeof endTs === "number" ? getDisplayTimeFromSecond(endTs - startTs) : "-",
    },
    { label: "Row count", value: queryStats?.rowCount },
    { label: "Type", value: queryType },
    { label: "Query ID", value: engineQueryId || queryUuid },
    { label: "Total rows scanned", value: queryStats?.scannedTotalRows },
    {
      label: "Process time",
      value: queryStats?.processTime ? `${queryStats?.processTime}s` : null,
    },
    {
      label: "Total data scanned",
      value: queryStats?.scannedTotalBytes
        ? `${queryStats?.scannedTotalBytes} bytes`
        : null,
    },
  ];

  return (
    <div className="metric-config-query-details-panel-stats">
      <table>
        <tbody>
          {statsRows.map(
            ({ label, value }) =>
              !isNullOrUndefined(value) && (
                <tr key={label}>
                  <td className="metric-config-query-details-stats-heading">{label}</td>
                  <td>{value}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
}

function MetricConfigPreviewQueryHistoryQueryDetails(props) {
  const { closeTakeover, queryHistoryItem, options = null } = props;

  const { querySql } = queryHistoryItem;

  const cornerControls = (
    <Button primary size="large" onClick={closeTakeover}>
      {options?.closeText ?? "OK"}
    </Button>
  );

  return (
    <TakeoverPanel
      title="Query Details"
      titleIcon={<SettingOutlined />}
      cornerControls={cornerControls}
    >
      <div className="metric-config-query-details-panel-content">
        <QueryDetailsQueryStats queryHistoryItem={queryHistoryItem} />
        <div className="metric-config-query-details-panel-sql">
          <div className="metric-config-query-details-panel-sql-header">SQL</div>
          <div className="metric-config-query-details-panel-sql-query">
            {formatCustomSql(querySql)}
          </div>
        </div>
      </div>
    </TakeoverPanel>
  );
}

export default MetricConfigPreviewQueryHistoryQueryDetails;
