import React, { useState } from "react";
import Button, { TextWithIcon } from "../../../../components/button/ng-button";
import { CheckOneIcon, DataArrivalIcon } from "../../../../components/icons/success";
import { SchemaChangeIcon } from "../../../../components/icons/general";
import { LabeledInput } from "../../../../components/labeled-control/labeled-control";
import { Spinner } from "../../../../atom/spinner";

import "./profiler-setting-blob-storage-table-form.scss";

export default function ProfilerSettingBlobStorageTableForm(props) {
  const { onCreate, onCancel, tableBlobList, onUpdateBlobList } = props;
  const [value, setValue] = useState({ tableName: "", blobPathFormat: "" });
  let fileNameList = [];
  const { loading, data } = tableBlobList;
  if (data.length > 0) {
    fileNameList = data.slice(0, 5).map(({ path }) => path);
    if (data.length > 5) {
      fileNameList.push(` + ${data.length - 5} More`);
    }
  }

  return (
    <div className="profiler-setting-blob-storage-table-form-container">
      <div className="profiler-setting-blob-storage-table-form-header-container">
        <div className="profiler-setting-blob-storage-table-form-header-title-container">
          Configuration
        </div>
        <div style={{ flexGrow: 1 }} />
        <Button outline disabled={loading} onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          disabled={!value.tableName || !value.blobPathFormat || loading}
          onClick={() => onCreate(value)}
        >
          <TextWithIcon icon={<CheckOneIcon />}>Create Virtual Table</TextWithIcon>
        </Button>
      </div>
      <div className="profiler-setting-blob-storage-table-form-item-container">
        <LabeledInput
          label="Table name"
          staticLabel
          value={value.tableName}
          onChange={(e) =>
            e.target.value !== value.tableName &&
            setValue({ ...value, tableName: e.target.value })
          }
        />
      </div>
      <div className="profiler-setting-blob-storage-table-form-item-container">
        <LabeledInput
          label="File pattern"
          staticLabel
          value={value.blobPathFormat}
          onChange={(e) =>
            e.target.value !== value.blobPathFormat &&
            setValue({ ...value, blobPathFormat: e.target.value })
          }
        />
        <Button
          size="large"
          disabled={!value.blobPathFormat || loading}
          onClick={() => onUpdateBlobList(value)}
        >
          <TextWithIcon icon={<DataArrivalIcon />}>Find Matching files</TextWithIcon>
        </Button>
      </div>
      <div className="profiler-setting-blob-storage-table-form-item-description">
        All files found will be used in the creation of your table.
      </div>
      {loading ? (
        <Spinner size="large" className="profiler-setting-blob-storage-spinner" />
      ) : (
        <div className="profiler-setting-blob-storage-table-form-file-list-container">
          {fileNameList.map((fileName, index) => (
            <div
              className="profiler-setting-blob-storage-table-form-file-item-container"
              key={index}
            >
              <SchemaChangeIcon />
              {fileName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
