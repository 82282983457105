import React, { useMemo } from "react";
import {
  plotConfig,
  timestampXAxisConfig,
  yAxisConfig,
} from "../../components/chart/utils";
import Plot from "../../utils/plotly";
import NgCard from "../../components/ng-card/ng-card";
import NoData from "../dq-dashboard/no-data";
import ChartSpinner from "../../components/chart-view/chart-spinner";
import { NextGenPalette } from "../../utils/color";
import {
  IncidentStatus,
  IncidentScore,
  IncidentValidationStatus,
} from "../../utils/enums";
import { getStringFromTimeStamp } from "../../utils/time";
import fromUnixTime from "date-fns/fromUnixTime";
import addDays from "date-fns/addDays";

import "./incident-dashboard-chart.scss";

function DashboardLinePlot(props) {
  const { yTitle = "incidents", data, onClick } = props;

  const xAxis = timestampXAxisConfig();
  const yAxis = yAxisConfig();
  yAxis.rangemode = "nonnegative";

  yAxis.title = {
    text: yTitle,
    font: {
      color: NextGenPalette.slate,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      size: 10,
    },
    standoff: 10,
  };

  const config = plotConfig();

  const plotData = [
    {
      ...data,
      mode: "lines",
      line: {
        color: NextGenPalette.purple,
        width: 3,
      },
    },
  ];

  const layout = {
    xaxis: xAxis,
    yaxis: yAxis,
    height: 150,
    margin: {
      t: 0,
      r: 10,
      b: 20,
      l: 10,
    },
    hovermode: "closest",
  };

  function onChartClick(ev) {
    if (!onClick || !ev?.points?.[0]) {
      return;
    }

    const currentPoint = ev.points[0];
    onClick(currentPoint.pointIndex, currentPoint.curveNumber);
  }

  return (
    <Plot
      data={plotData}
      layout={layout}
      config={config}
      onClick={onChartClick}
      useResizeHandler
      style={{ width: "100%", height: "100%" }}
    />
  );
}

function IncidentsStackBarPlot(props) {
  const { yTitle = "incidents", data = [], config = [], onClick } = props;

  const xAxis = timestampXAxisConfig();
  const yAxis = yAxisConfig();
  yAxis.title = {
    text: yTitle,
    font: {
      color: NextGenPalette.slate,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      size: 10,
    },
    standoff: 10,
  };
  yAxis.rangemode = "nonnegative";

  let plotData = [];
  if (config.length > 0) {
    plotData = config.map(({ label, color }) => ({
      x: [],
      y: [],
      name: label,
      type: "bar",
      marker: {
        color,
      },
      standoff: 80,
    }));

    data.forEach((rawData) => {
      for (let i = 0; i < config.length; i++) {
        plotData[i].x.push(rawData.timestamp);
        plotData[i].y.push(config[i].getter(rawData));
      }
    });
  }

  const layout = {
    xaxis: xAxis,
    yaxis: yAxis,

    height: 150,
    margin: {
      t: 0,
      r: 10,
      b: 20,
      l: 10,
    },
    barmode: "stack",
    bargap: 0.1,
    showlegend: true,
    hovermode: "closest",
    legend: {
      orientation: "h",
      xanchor: "center",
      yanchor: "top",
      y: -0.3,
      x: 0.5,
      font: {
        color: NextGenPalette.systemBlack,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        size: 10,
      },
    },
  };

  function onChartClick(ev) {
    if (!onClick || !ev?.points?.[0]) {
      return;
    }

    const currentPoint = ev.points[0];
    onClick(currentPoint.pointIndex, config[currentPoint.curveNumber].category);
  }

  return (
    <Plot
      data={plotData}
      layout={layout}
      config={plotConfig()}
      onClick={onChartClick}
      useResizeHandler
      style={{ width: "100%", height: "100%" }}
    />
  );
}

function IncidentDashboardChart(props) {
  const { loading, data, onClick } = props;
  const {
    totalIncidentData,
    incidentStatsData,
    incidentSeverityData,
    resolvedIncidentData,
  } = useMemo(() => {
    const totalIncidentData = { x: [], y: [] };
    const incidentStatsData = [];
    const incidentSeverityData = [];
    const resolvedIncidentData = { x: [], y: [] };

    for (let currentData of data) {
      const {
        timestamp,
        total,
        viewed,
        unviewed,
        closed,
        submitted,
        rejected,
        priorityMedium,
        priorityHigh,
        resolved,
      } = currentData;
      const timestampStr = getStringFromTimeStamp(timestamp, { graph: true });
      totalIncidentData.x.push(timestampStr);
      totalIncidentData.y.push(total);

      incidentStatsData.push({
        timestamp: timestampStr,
        viewed,
        unviewed,
        closed,
        submitted,
        rejected,
      });

      incidentSeverityData.push({
        timestamp: timestampStr,
        priorityMedium,
        priorityHigh,
      });

      resolvedIncidentData.x.push(timestampStr);
      resolvedIncidentData.y.push(resolved);
    }

    return {
      totalIncidentData,
      incidentStatsData,
      incidentSeverityData,
      resolvedIncidentData,
    };
  }, [data]);

  function onChartClick(dataIndex, partialLocalFilterSetting = {}) {
    if (!onClick) {
      return;
    }

    const startTimestamp = data[dataIndex].timestamp;
    const startTime = fromUnixTime(startTimestamp);
    const endTime = addDays(startTime, 1);
    const time = {
      currentInterval: "1d",
      startTime,
      endTime,
      current: endTime,
    };

    onClick(time, partialLocalFilterSetting);
  }

  const NgCardArrays = [
    {
      title: "Incidents",
      component: DashboardLinePlot,
      componentProperties: {
        data: totalIncidentData,
        onClick: (dataIndex) => onChartClick(dataIndex, {}),
      },
    },
    {
      title: "Incidents by incident status",
      component: IncidentsStackBarPlot,
      componentProperties: {
        data: incidentStatsData,
        config: [
          {
            label: "Closed",
            category: IncidentStatus.CLOSED,
            color: "#FFCDA1",
            getter: (value) => value.closed,
          },
          {
            label: "Submitted",
            category: IncidentStatus.SUBMITTED,
            color: "#5CCAB4",
            getter: (value) => value.submitted,
          },
          {
            label: "Rejected",
            category: IncidentStatus.REJECTED,
            color: "#D36FF7",
            getter: (value) => value.rejected,
          },
          {
            label: "Viewed",
            category: IncidentStatus.VIEWED,
            color: "#9E9CF6",
            getter: (value) => value.viewed,
          },
          {
            label: "Unviewed",
            category: IncidentStatus.UNVIEWED,
            color: "#4FBCFF",
            getter: (value) => value.unviewed,
          },
        ],
        onClick: (dataIndex, category) =>
          onChartClick(dataIndex, { status: [category] }),
      },
    },
    {
      title: "Resolved incidents",
      component: DashboardLinePlot,
      componentProperties: {
        data: resolvedIncidentData,
        onClick: (dataIndex) =>
          onChartClick(dataIndex, {
            validationStatus: [IncidentValidationStatus.RESOLVED],
          }),
      },
    },
    {
      title: "Incidents by severity",
      component: IncidentsStackBarPlot,
      componentProperties: {
        data: incidentSeverityData,
        config: [
          {
            label: "High",
            category: IncidentScore.HIGH,
            color: "#EE8B9E",
            getter: (value) => value.priorityHigh,
          },
          {
            label: "Medium",
            category: IncidentScore.NORMAL,
            color: "#FFCDA1",
            getter: (value) => value.priorityMedium,
          },
        ],
        onClick: (dataIndex, category) =>
          onChartClick(dataIndex, { severity: [category] }),
      },
    },
  ];

  return (
    <div className="incident-dashboard-chart-view-container">
      {NgCardArrays.map(
        ({ title, component: PlotChartComponent, componentProperties }, index) => (
          <NgCard title={title} key={index}>
            {loading && <ChartSpinner />}
            {!loading && data.length === 0 && <NoData />}
            {!loading && data.length > 0 && (
              <PlotChartComponent {...componentProperties} />
            )}
          </NgCard>
        )
      )}
    </div>
  );
}

export default IncidentDashboardChart;
