import React from "react";
import { EditOutlined } from "@ant-design/icons";
import ConfigCard from "../../../config-card";
import NgButton from "../../../../button/ng-button";
import {
  BackfillDurationConfigItem,
  DataAssetCards,
  FieldSection,
  MetricComparesConfigItem,
} from "../../../fields/";
import AggregationCompareMetricTakeOverView from "./aggregation-compare-metric-takeover-view";
import { TakeoverWidth } from "../../../../../utils/enums";
import SourceTargetTables from "../../../fields/source-target-tables";
import AddTags from "../../../fields/add-tags";
import { getDefaultBackfillDuration } from "../../../../../utils/metric";
import { ConfigureMetricTitle } from "../../../fields/configure-metric";

import "./index.scss";

function AggregationCompareMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    kpiList,
    onDataSourceChanged,
    onSrcDataSourceChanged,
    schemaList,
    srcSchemaList,
    srcTableList,
    onSrcTableChanged,
    tableList,
    onTableChanged,
    tagList,
    openWorkspaceTakeover,
    closeTakeover,
    resetKPIStatus,
    disabled = false,
  } = props;

  const {
    config: { sourceTable, targetTable, compares },
  } = configData;

  function onSrcDataSourceChange(newDataSourceUuid) {
    if (newDataSourceUuid === sourceTable?.sourceUuid) {
      return;
    }

    const newCompares = compares.map(({ targetMetricUuid }) => {
      return {
        sourceMetricUuid: "",
        targetMetricUuid,
      };
    });

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        sourceTable: {
          table: null,
          sourceUuid: newDataSourceUuid,
        },
        compares: newCompares,
      },
    });
    onSrcDataSourceChanged(newDataSourceUuid);
  }

  function onSrcTableChange(_newTableUuid, newSourceTable) {
    const newCompares = compares.map(({ targetMetricUuid }) => {
      return {
        sourceMetricUuid: "",
        targetMetricUuid,
      };
    });

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        sourceTable: {
          ...sourceTable,
          table: newSourceTable,
        },
        compares: newCompares,
      },
    });

    newSourceTable.schemaName &&
      newSourceTable.tableName &&
      onSrcTableChanged(sourceTable?.sourceUuid, newSourceTable.tableUuid);
  }

  function onTargetDataSourceChange(newDataSourceUuid) {
    if (newDataSourceUuid === targetTable?.sourceUuid) {
      return;
    }

    const newCompares = compares.map(({ sourceMetricUuid }) => {
      return {
        sourceMetricUuid,
        targetMetricUuid: "",
      };
    });

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        targetTable: {
          table: null,
          sourceUuid: newDataSourceUuid,
        },
        compares: newCompares,
        sources: [newDataSourceUuid],
      },
    });
    onDataSourceChanged(newDataSourceUuid);
  }

  const dataSourceInfo = dataSourceList.find(
    (currentDataSource) => currentDataSource.metadata.uuid === targetTable?.sourceUuid
  );

  function onTargetTableChange(_newTableUuid, newTargetTable) {
    const newCompares = compares.map(({ sourceMetricUuid }) => {
      return {
        sourceMetricUuid,
        targetMetricUuid: "",
      };
    });

    onConfigDataChange({
      ...configData,
      config: {
        ...configData.config,
        targetTable: {
          ...targetTable,
          table: newTargetTable,
        },
        compares: newCompares,
        backfillDuration: getDefaultBackfillDuration(
          newTargetTable?.profilerConfig?.window,
          dataSourceInfo
        ),
      },
    });

    newTargetTable.schemaName &&
      newTargetTable.tableName &&
      onTableChanged(targetTable?.sourceUuid, newTargetTable.tableUuid);
  }

  const configureMetricTitle = (
    <ConfigureMetricTitle
      workspaceUuid={dataSourceList?.[0].metadata.workspaceId}
      configData={configData}
      isValidConfig={isValidConfig}
    />
  );

  return (
    <DataAssetCards>
      <SourceTargetTables
        dataSourceList={dataSourceList}
        srcSchemaList={srcSchemaList}
        schemaList={schemaList}
        srcTableList={srcTableList}
        tableList={tableList}
        sourceTable={sourceTable}
        targetTable={targetTable}
        onSrcDataSourceChange={onSrcDataSourceChange}
        onSrcTableChange={onSrcTableChange}
        onTargetDataSourceChange={onTargetDataSourceChange}
        onTargetTableChange={onTargetTableChange}
        disabled={disabled}
      />

      <ConfigCard
        title="Metrics to compare"
        description="Configure metric pairs, where each pair contains a metric from the source table and a metric from the target table to be compared"
        actionComponent={
          <NgButton
            outline
            disabled={
              disabled ||
              !sourceTable ||
              !targetTable ||
              !sourceTable.table ||
              !targetTable.table
            }
            onClick={() => {
              openWorkspaceTakeover(
                <AggregationCompareMetricTakeOverView
                  sourceTable={sourceTable}
                  targetTable={targetTable}
                  value={compares}
                  onSave={(value) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        compares: [...compares, value],
                      },
                    });
                  }}
                  closeTakeover={closeTakeover}
                />,
                TakeoverWidth.WIDE,
                () => {
                  closeTakeover();
                }
              );
            }}
          >
            Add
            <EditOutlined />
          </NgButton>
        }
      >
        <FieldSection>
          <MetricComparesConfigItem
            disabled={disabled}
            kpiList={kpiList.data}
            value={compares}
            onChange={(newCompares) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  compares: newCompares,
                },
              });
              resetKPIStatus({
                resetAll: false,
                resetPreviewInfo: true,
              });
            }}
          />
        </FieldSection>
      </ConfigCard>
      <ConfigCard title={configureMetricTitle}>
        <div className="metric-config-aggregation-compare-configure-metric">
          <BackfillDurationConfigItem
            // Recreate this input whenever the aggregation window changes, so that the underlying
            // duration input will reset its time unit to the highest granularity.
            key={configData.config?.targetTable?.table?.profilerConfig?.window}
            disabled={disabled}
            value={configData.config?.backfillDuration}
            onChange={(backfillDuration) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  backfillDuration,
                },
              });
            }}
          />
        </div>
      </ConfigCard>
      <AddTags
        configData={configData}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
        disabled={disabled}
      />
    </DataAssetCards>
  );
}

AggregationCompareMetricDataAssetConfig.assetMeta = {
  title: "Source and target",
  createDescription: "Select source table and target table to compare",
  editDescription: "Select source table and target table to compare",
};

export default AggregationCompareMetricDataAssetConfig;
