import React, { useEffect } from "react";
import NotificationView from "../notifications/notification-view";
import { getURIInstance, URIPath } from "../../utils/uri-path";

import "./notification.scss";

function Notification(props) {
  const {
    match: {
      params: { uuid, workspaceUuid },
    },
    workspaceUserPermissions,
    getNotificationDetailInfo,
    notificationDetailInfo,
    history,
  } = props;

  useEffect(() => {
    getNotificationDetailInfo(workspaceUuid, uuid);
  }, [workspaceUuid, uuid, getNotificationDetailInfo]);

  return (
    <div className="notification-container">
      <NotificationView
        value={notificationDetailInfo}
        workspaceUserPermissions={workspaceUserPermissions}
        enableDetailInfoRow={true}
        workspaceUuid={workspaceUuid}
        onClose={() => {
          history.push(
            getURIInstance(URIPath.ALERT_LIST, {
              workspaceUuid,
            })
          );
        }}
      />
    </div>
  );
}

export default Notification;
