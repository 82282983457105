import React from "react";
import { Progress } from "antd";
import { NextGenPalette } from "../../utils/color";

import "./progress-bar.scss";

function ProgressBar(props) {
  const { value = 0 } = props;
  return (
    <Progress
      percent={value * 100}
      showInfo={false}
      strokeColor={NextGenPalette.purple}
    />
  );
}

export default ProgressBar;
