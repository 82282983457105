import { connect } from "react-redux";
import IntegrationList from "./integration-list";
import {
  addIntegration,
  deleteIntegration,
  editIntegration,
  previewIntegration,
  getIntegrationList,
} from "../../actions/integration/integration-action";
import { getRuleList } from "../../actions/rule/rule-action";
import {
  closeTakeover,
  openWorkspaceTakeover,
} from "../../actions/takeover/takeover-action";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  ruleList: state.ruleReducer.ruleList.data,
  integrationList: state.integrationReducer.integrationList,
  currentMetadata: state.integrationReducer.currentMetadata,
});

const mapDispatchToProps = (dispatch) => ({
  addIntegration: (workspaceUuid, newSlackIntegration) =>
    dispatch(addIntegration(workspaceUuid, newSlackIntegration)),
  previewIntegration: (workspaceUuid, newSlackIntegration) =>
    dispatch(previewIntegration(workspaceUuid, newSlackIntegration)),
  deleteIntegration: (workspaceUuid, integration) =>
    dispatch(deleteIntegration(workspaceUuid, integration)),
  editIntegration: (workspaceUuid, uuid, newIntegration) =>
    dispatch(editIntegration(workspaceUuid, uuid, newIntegration)),
  getRuleList: (workspaceUuid) => dispatch(getRuleList(workspaceUuid)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  openWorkspaceTakeover: (takeoverElement, width, outsideClick) =>
    dispatch(openWorkspaceTakeover(takeoverElement, width, outsideClick)),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationList);
