import React from "react";
import LegacyModalDialogAdapter from "../../../components/legacy-adapter/legacy-modal-dialog-adapter";
import IntegrationDialogFooter from "../integration-dialog-footer";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../components/modal-dialog/modal-field-group";
import {
  LabeledInput,
  LabeledSelect,
} from "../../../components/labeled-control/labeled-control";
import IntegrationToggleGroupItem from "../integration-toggle-group-item";
import IntegrationDigestPeriodDropdown from "../integration-digest-period-dropdown";
import { getAppDomainName } from "../../../utils/general";
import { TimezoneOptions } from "../../../utils/options";
import { AlertingChannelType } from "../../../utils/alerting";

import "./slack-integration-basic-dialog.scss";

function SlackIntegrationBasicDialogBodyComponent(props) {
  const {
    title,
    onTitleChange,
    webHookUrl,
    onWebHookUrlChange,
    description,
    onChannelChange,
    timezone,
    onTimezoneChange,
    muteResolvedAlerts,
    onMuteResolvedAlertsChange,
    digestPeriodInSeconds,
    onDigestPeriodInSecondsChange,
    alertChannelType,
    enableRecurringAlerts,
    onEnableRecurringAlertsChange,
  } = props;

  let descriptionLabel = "";
  if (alertChannelType === AlertingChannelType.MSTEAMS) {
    descriptionLabel = "Microsoft Teams Channel";
  } else {
    descriptionLabel = "Slack Channel";
  }

  return (
    <>
      <ModalFieldGroup>
        <ModalFieldRows>
          <LabeledInput
            label="Name"
            value={title}
            onChange={(e) => onTitleChange(e.target.value)}
          />
          <LabeledInput
            label="URL"
            value={webHookUrl}
            onChange={(e) => onWebHookUrlChange(e.target.value)}
          />
          <LabeledInput
            label={descriptionLabel}
            value={description}
            onChange={(e) => onChannelChange(e.target.value)}
          />
          <LabeledSelect
            showSearch
            label="Timezone"
            value={timezone}
            options={TimezoneOptions}
            onChange={onTimezoneChange}
          />
        </ModalFieldRows>
      </ModalFieldGroup>
      <IntegrationDigestPeriodDropdown
        value={digestPeriodInSeconds}
        onChange={onDigestPeriodInSecondsChange}
      />
      <IntegrationToggleGroupItem
        value={muteResolvedAlerts}
        onChange={onMuteResolvedAlertsChange}
        label="Send resolved alerts"
        infoPanel="Posts an alert for resolved incidents"
        isReversed
      />
      <IntegrationToggleGroupItem
        value={enableRecurringAlerts}
        onChange={onEnableRecurringAlertsChange}
        label="Enable recurring alerts"
      />
    </>
  );
}

function SlackIntegrationBasicDialog(props) {
  let {
    defaultData: {
      title: defaultTitle,
      webHookUrl: defaultWebHookUrl,
      description: defaultDescription,
      timezone: defaultTimeZone,
      muteResolvedAlerts: defaultMuteResolvedAlerts,
      digestPeriodInSeconds: defaultDigestPeriodInSeconds,
      enableRecurring: defaultEnableRecurringAlerts,
      id,
    },
    alertChannelType,
    dialogTitle = "",
    className = "",
    triggerComponent,
    onOkClicked,
    OKText,
    onPreview,
    modalIsOpen,
    setIsOpen,
    enablePreview,
    enableSave,
  } = props;

  const [title, setTitle] = React.useState(defaultTitle);
  const [webHookUrl, setWebHookUrl] = React.useState(defaultWebHookUrl);
  const [description, setDescription] = React.useState(defaultDescription);
  const [muteResolvedAlerts, setMuteResolvedAlerts] = React.useState(
    defaultMuteResolvedAlerts
  );
  const [timezone, setTimezone] = React.useState(defaultTimeZone);
  const [digestPeriodInSeconds, setDigestPeriodInSeconds] = React.useState(
    defaultDigestPeriodInSeconds
  );
  const [enableRecurringAlerts, setEnableRecurringAlerts] = React.useState(
    defaultEnableRecurringAlerts
  );

  const [modalIsOpenDefault, setIsOpenDefault] = React.useState(false);
  if (modalIsOpen === undefined || setIsOpen === undefined) {
    modalIsOpen = modalIsOpenDefault;
    setIsOpen = setIsOpenDefault;
  } else {
    triggerComponent = null;
  }

  function getNewSlackIntegration() {
    return {
      title,
      type: alertChannelType,
      webHookUrl,
      description,
      timezone: timezone,
      muteResolvedAlerts: muteResolvedAlerts,
      digestPeriodInSeconds,
      domainName: getAppDomainName(),
      enableRecurring: enableRecurringAlerts,
      ...(id !== undefined ? { id } : {}),
    };
  }

  function closeModal() {
    setTitle(defaultTitle);
    setWebHookUrl(defaultWebHookUrl);
    setDescription(defaultDescription);
    setTimezone(defaultTimeZone);
    setMuteResolvedAlerts(defaultMuteResolvedAlerts);
    setDigestPeriodInSeconds(defaultDigestPeriodInSeconds);
    setEnableRecurringAlerts(defaultEnableRecurringAlerts);
    setIsOpen(false);
  }

  function onOkClick() {
    if (onOkClicked) {
      const newSlackIntegration = getNewSlackIntegration();
      onOkClicked(newSlackIntegration);
    }
    closeModal();
  }

  function onPreviewClick() {
    if (onPreview) {
      const newSlackIntegration = getNewSlackIntegration();
      onPreview(newSlackIntegration);
    }
  }

  return (
    <LegacyModalDialogAdapter
      title={dialogTitle}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={`integration-basic-dialog-container ${className}`}
      triggerComponent={triggerComponent}
      contentComponent={
        <SlackIntegrationBasicDialogBodyComponent
          title={title}
          alertChannelType={alertChannelType}
          onTitleChange={setTitle}
          webHookUrl={webHookUrl}
          onWebHookUrlChange={setWebHookUrl}
          description={description}
          onChannelChange={setDescription}
          timezone={timezone}
          onTimezoneChange={setTimezone}
          muteResolvedAlerts={muteResolvedAlerts}
          onMuteResolvedAlertsChange={setMuteResolvedAlerts}
          digestPeriodInSeconds={digestPeriodInSeconds}
          onDigestPeriodInSecondsChange={setDigestPeriodInSeconds}
          enableRecurringAlerts={enableRecurringAlerts}
          onEnableRecurringAlertsChange={setEnableRecurringAlerts}
        />
      }
      footerComponent={
        <IntegrationDialogFooter
          onPreviewClick={onPreviewClick}
          onCancelClick={closeModal}
          onOkClick={onOkClick}
          OkText={OKText}
          enablePreview={enablePreview}
          enableSave={enableSave}
        />
      }
    />
  );
}

export default SlackIntegrationBasicDialog;
