import { connect } from "react-redux";
import ScheduleList from "./schedule-list";
import {
  addSchedule,
  deleteSchedule,
  editSchedule,
  getScheduleList,
} from "../../actions/schedule/schedule-action";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  scheduleList: state.scheduleReducer.scheduleList,
});

const mapDispatchToProps = (dispatch) => ({
  addSchedule: (workspaceUuid, newSchedule) =>
    dispatch(addSchedule(workspaceUuid, newSchedule)),
  editSchedule: (workspaceUuid, uuid, newSchedule) =>
    dispatch(editSchedule(workspaceUuid, uuid, newSchedule)),
  deleteSchedule: (workspaceUuid, currentSchedule) =>
    dispatch(deleteSchedule(workspaceUuid, currentSchedule)),
  getScheduleList: (workspaceUuid) => dispatch(getScheduleList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleList);
