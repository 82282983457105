import fromUnixTime from "date-fns/fromUnixTime";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { capitalizeFirstLetter } from "./general";
import {
  DataSourceType,
  TableType,
  ConnectionHealth,
  isFileSource,
  MetricCategory,
  TableColumnTypeCategory,
  dataSourceTypeToMetadataMetricMapping,
  ConnectionStatusPauseTypes,
} from "./enums";
import { metadataMetricTypes } from "./metric";

export function isQueryTerminationSupported(dataSource) {
  return dataSource.config.connection.type === DataSourceType.REDSHIFT;
}

export function isPartitionConfigEnabled(dataSourceType) {
  return [
    DataSourceType.ATHENA,
    DataSourceType.DATABRICKS,
    DataSourceType.HIVE,
    DataSourceType.GREENPLUM,
  ].includes(dataSourceType);
}

export function isVirtualTableEnabled(dataSourceType) {
  return [
    DataSourceType.INCORTA,
    DataSourceType.ORACLE,
    DataSourceType.POSTGRES,
  ].includes(dataSourceType);
}

export function isDataSourceStreamingEnabled(dataSourceConnectionConfig) {
  return (
    dataSourceConnectionConfig.type === DataSourceType.DATABRICKS &&
    dataSourceConnectionConfig.clusterId &&
    dataSourceConnectionConfig.dbfsPrefix
  );
}

export function getFullTableName(table = {}, skipCustomSqlCheck = false) {
  const { tableName = "", schemaName = "", fullTableName, type } = table;
  if (type === TableType.CUSTOM_SQL && !skipCustomSqlCheck) {
    return "";
  }

  if (fullTableName !== undefined) {
    return fullTableName;
  }

  if (!tableName && !schemaName) {
    return "";
  }

  return schemaName ? `${schemaName}.${tableName}` : tableName || "";
}

export function getDisplayTableName(tableName) {
  tableName = tableName || "";
  return tableName.replace(/["`]/g, "");
}

export function getConnectionHealth(dataSource) {
  if (dataSource?.status?.lastScannedStatus === ConnectionHealth.SUCCESS) {
    return ConnectionHealth.SUCCESS;
  }

  if (dataSource?.status?.lastScannedStatus === ConnectionHealth.ERROR) {
    return ConnectionHealth.ERROR;
  }

  if (
    Object.values(ConnectionStatusPauseTypes).includes(
      dataSource?.status?.lastScannedStatus
    )
  ) {
    return ConnectionHealth.PAUSED;
  }

  return ConnectionHealth.UNKNOWN;
}

export function getLastScannedDisplayTime(lastScannedTs) {
  if (typeof lastScannedTs !== "number") {
    return "Never";
  }

  return capitalizeFirstLetter(
    `${formatDistanceToNow(fromUnixTime(lastScannedTs))} ago`
  );
}

export function getNormalizedTable(table) {
  if (
    !table ||
    table.type !== TableType.TABLE ||
    !table.tableName ||
    table.schemaName
  ) {
    return table;
  }

  const fullQualifiedTableName = getDisplayTableName(table.tableName);
  const [schemaName, tableName] = fullQualifiedTableName.split(".");
  return {
    type: TableType.TABLE,
    schemaName,
    tableName,
  };
}

export function isSchemaScanning(dataSource) {
  return (
    dataSource.config.profiler.forceScan ||
    dataSource.schemas?.some((currentSchema) => !currentSchema.lastTablesScannedTs)
  );
}

export const fileSourceUnsupportedMetricTypes = [
  MetricCategory.CUSTOM_SQL,
  MetricCategory.FULL_COMPARE,
  MetricCategory.COLUMN_ACTIVITY,
];

export function dataSourceSupportsMetricType(
  dataSource,
  metricType,
  columnType = null
) {
  if (!dataSource?.config?.connection?.type) {
    return true;
  }

  const dataSourceType = dataSource.config.connection.type;
  if (metadataMetricTypes.has(metricType)) {
    if (
      dataSourceType === DataSourceType.REDSHIFT &&
      dataSource?.config?.connection?.serverless &&
      metricType === MetricCategory.UPDATE_DELAY
    ) {
      return false;
    }

    return (
      Boolean(dataSourceTypeToMetadataMetricMapping[dataSourceType]) &&
      dataSourceTypeToMetadataMetricMapping[dataSourceType].includes(metricType)
    );
  } else if (isFileSource(dataSourceType)) {
    return !fileSourceUnsupportedMetricTypes.includes(metricType);
  } else if ([DataSourceType.MYSQL, DataSourceType.INCORTA].includes(dataSourceType)) {
    return !(
      metricType === MetricCategory.DISTRIBUTION &&
      columnType === TableColumnTypeCategory.NUMERIC
    );
  } else {
    return true;
  }
}

export function dataSourceSupportsDataProfiling(dataSource) {
  return !isFileSource(dataSource?.config?.connection?.type);
}
