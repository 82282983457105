import { SET_CHART_DATA_FULL_COMPARE_SAMPLE_DATA } from "../../actions/chart-data/chart-data-action-types";

const initialChartDataState = {
  fullCompareSampleData: {
    loading: true,
    data: {},
  },
};

export default function chartDataReducer(state = initialChartDataState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_CHART_DATA_FULL_COMPARE_SAMPLE_DATA:
      newState = {
        ...state,
        fullCompareSampleData: action.fullCompareSampleData,
      };
      return newState;

    default:
      return state;
  }
}
