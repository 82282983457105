import { getStringCompareFunction } from "../../../utils/search";

export const GENERAL_SEARCH_FIELD = "searchName";

const stringCompareFunction = getStringCompareFunction(false);

export const scheduleFields = {
  SCHEDULE: "schedule",
};

export const scheduleFieldOptionValueGetterFn = {
  [scheduleFields.SCHEDULE]: (integration) => integration.title,
};

export const scheduleFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    return searchName.length && !stringCompareFunction(row.title, searchName);
  },
  [scheduleFields.SCHEDULE]: (row, scheduleName) => {
    return !stringCompareFunction(row.title, scheduleName);
  },
};
