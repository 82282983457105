export const SET_KPI_LIST = "SET_KPI_LIST";
export const SET_KPI_LIST_PAGE_CONFIGURATION = "SET_KPI_LIST_PAGE_CONFIGURATION";
export const SET_KPI_INCIDENT_LIST = "SET_KPI_INCIDENT_LIST";
export const SET_KPI_LAST_PREVIEW_TIME = "SET_KPI_LAST_PREVIEW_TIME";
export const SET_KPI_LAST_PREVIEW_STATUS = "SET_KPI_LAST_PREVIEW_STATUS";
export const SET_KPI_LAST_PREVIEW_RESULT = "SET_KPI_LAST_PREVIEW_RESULT";
export const SET_CURRENT_EDIT_KPI = "SET_CURRENT_EDIT_KPI";
export const SET_CURRENT_KPI_SRC_SCHEMA_LIST = "SET_CURRENT_KPI_SRC_SCHEMA_LIST";
export const SET_CURRENT_KPI_SCHEMA_LIST = "SET_CURRENT_KPI_SCHEMA_LIST";
export const SET_CURRENT_KPI_TABLE_LIST = "SET_CURRENT_KPI_TABLE_LIST";
export const SET_CURRENT_KPI_SRC_TABLE_LIST = "SET_CURRENT_KPI_SRC_TABLE_LIST";
export const SET_CURRENT_CUSTOM_SQL_KPI_TABLE_COLUMN_LIST =
  "SET_CURRENT_CUSTOM_SQL_KPI_TABLE_COLUMN_LIST";
export const SET_CURRENT_KPI_TABLE_COLUMN_LIST = "SET_CURRENT_KPI_TABLE_COLUMN_LIST";
export const SET_CURRENT_KPI_SRC_TABLE_COLUMN_LIST =
  "SET_CURRENT_KPI_SRC_TABLE_COLUMN_LIST";
export const SET_CURRENT_KPI_IN_COLUMN_COLUMN_LIST =
  "SET_CURRENT_KPI_IN_COLUMN_COLUMN_LIST";
export const SET_CURRENT_KPI_TABLE_COLUMN_VALUE_LIST =
  "SET_CURRENT_KPI_TABLE_COLUMN_VALUE_LIST";
export const SET_CURRENT_KPI_FULL_COMPARE_SOURCE_TABLE_COLUMN_VALUE_LIST =
  "SET_CURRENT_KPI_FULL_COMPARE_SOURCE_TABLE_COLUMN_VALUE_LIST";
export const SET_CURRENT_KPI_FULL_COMPARE_TARGET_TABLE_COLUMN_VALUE_LIST =
  "SET_CURRENT_KPI_FULL_COMPARE_TARGET_TABLE_COLUMN_VALUE_LIST";
export const SET_CURRENT_KPI_COLUMN_INFO = "SET_CURRENT_KPI_COLUMN_INFO";
export const SET_CURRENT_KPI_QUERY_LIST = "SET_CURRENT_KPI_QUERY_LIST";
export const SET_CURRENT_CLONE_KPI = "SET_CURRENT_CLONE_KPI";
export const SET_CURRENT_KPI_SRC_PARTITION_SAMPLE_DATA =
  "SET_CURRENT_KPI_SRC_PARTITION_SAMPLE_DATA";
export const SET_CURRENT_KPI_PARTITION_SAMPLE_DATA =
  "SET_CURRENT_KPI_PARTITION_SAMPLE_DATA";
export const SET_CURRENT_KPI_SAMPLE_DATA_TABLE_SCHEMA_LIST =
  "SET_CURRENT_KPI_SAMPLE_DATA_TABLE_SCHEMA_LIST";
export const SET_CURRENT_KPI_SAMPLE_DATA_SAMPLE_DATA_LIST =
  "SET_CURRENT_KPI_SAMPLE_DATA_SAMPLE_DATA_LIST";
export const SET_CURRENT_KPI_VALIDATE_FAILING_RECORDS_SQL =
  "SET_CURRENT_KPI_VALIDATE_FAILING_RECORDS_SQL";
