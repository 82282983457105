import axios from "../utils/api";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

export function getIntegrationFromServer(rawData) {
  const { status } = rawData;
  return {
    ...rawData.config,
    title: rawData.metadata.name,
    id: rawData.metadata.uuid,
    type: rawData.config.type,
    status,
  };
}

function getIntegrationFromUI(rawData) {
  let { title, id, ...config } = rawData;

  return {
    metadata: {
      name: title,
      ...(id !== undefined ? { uuid: id } : {}),
    },
    config,
  };
}

function getUserFromUserSearchData(rawData) {
  if (!Array.isArray(rawData)) {
    return [];
  }
  return rawData.map((entry) => Object.fromEntries(entry));
}

export function getIntegrationListPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL_LIST, {
          workspaceUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(
          response.data.map((currentIntegration) =>
            getIntegrationFromServer(currentIntegration)
          )
        );
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addIntegrationPromise(workspaceUuid, newIntegration) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL_LIST, {
          workspaceUuid,
        }),
        getIntegrationFromUI(newIntegration),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getIntegrationPromise(workspaceUuid, uuid, opts = {}) {
  return new Promise(function (resolve, reject) {
    const { disableToast = false } = opts;
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/", disableToast }
      )
      .then(function (response) {
        resolve(getIntegrationFromServer(response.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function previewIntegrationPromise(workspaceUuid, newIntegration) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL_PREVIEW, {
          workspaceUuid,
        }),
        getIntegrationFromUI(newIntegration),
        { baseURL: "/api/v1/", disableToast: true }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateIntegrationPromise(workspaceUuid, uuid, newIntegration) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL, {
          workspaceUuid,
          uuid,
        }),
        getIntegrationFromUI(newIntegration),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteIntegrationPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getIntegrationMetadataPromise(workspaceUuid, integration) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL_METADATA, {
          workspaceUuid,
        }),
        getIntegrationFromUI(integration),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getIntegrationUserListPromise(
  workspaceUuid,
  integration,
  usernameQuery
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.ALERT_CHANNEL_SEARCH, {
          workspaceUuid,
        }),
        { alerting_channel: integration, usernameQuery },
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(getUserFromUserSearchData(response.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
