import React from "react";
import { classesName } from "../../utils/css";

import "./button-text.scss";

function ButtonText(props) {
  const {
    className: propsClassName,
    disabled = false,
    colored = false,
    ...restProps
  } = props;
  const className = classesName(
    "button-text",
    propsClassName,
    disabled && "disabled",
    colored && "colored"
  );
  return <button className={className} {...restProps} />;
}

export default ButtonText;
