import React, { useState } from "react";
import { Tree } from "antd";
import {
  filterTreeBySearchValue,
  getInitialExpandedAndSelectedNodes,
  getSelectedNodeTypeAndId,
  ProfilerTreeNodeType,
} from "../tree-data";
import { difference, union } from "../../../utils/set";
import { ViewItemIcon } from "../icons";
import { trackProfilerDataSourceTreeClick } from "./profiler-data-source-node.utils";
import { hasPermission } from "../../../utils/uri-path";
import {
  ColumnActionsMenu,
  DataSourceActionsMenu,
  SchemaActionsMenu,
  TableActionsMenu,
  treeMenuNodeTypes,
  treeMenuOpenPermissions,
} from "./profiler-data-source-node-menus";

import "./profiler-data-source-node.scss";

function getEntityMenu(args) {
  const { entity, ...restArgs } = args;

  const [nodeType] = getSelectedNodeTypeAndId(entity);

  switch (nodeType) {
    case ProfilerTreeNodeType.DATASOURCE:
      return <DataSourceActionsMenu {...entity} {...restArgs} />;
    case ProfilerTreeNodeType.SCHEMA:
      return <SchemaActionsMenu {...entity} {...restArgs} />;
    case ProfilerTreeNodeType.TABLE:
      return <TableActionsMenu {...entity} {...restArgs} />;
    case ProfilerTreeNodeType.COLUMN:
      return <ColumnActionsMenu {...entity} {...restArgs} />;
    default:
      console.warn("Unexpected entity type", entity);
      return null;
  }
}

function ProfilerDataSourceNodeMenu(props) {
  const {
    history,
    workspaceUuid,
    title,
    entity,
    workspaceUserPermissions,
    onCreateCustomMetric,
    onCreateTable,
    onEditTable,
    setProfilerAfterTreeNavigationAction,
    navigateToTreeNode,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const trigger = (
    <span className="profiler-data-source-node-menu-node-title-menu-icon-container">
      <ViewItemIcon
        className="profiler-data-source-node-menu-node-title-menu-icon"
        width={14}
        height={14}
        onClick={(e) => {
          onOpenMenu();
        }}
      />
    </span>
  );

  const menu = getEntityMenu({
    entity,
    trigger,
    workspaceUuid,
    workspaceUserPermissions,
    history,
    isMenuOpen,
    setIsMenuOpen,
    onCreateCustomMetric,
    onCreateTable,
    onEditTable,
    setProfilerAfterTreeNavigationAction,
  });

  function onOpenMenu() {
    setIsMenuOpen(true);
  }

  return (
    <div
      className="profiler-data-source-node-menu-node-title"
      onContextMenu={(e) => {
        e.preventDefault();
        navigateToTreeNode();
        onOpenMenu();
      }}
    >
      <span className="profiler-data-source-node-menu-node-title-text">{title}</span>
      {menu}
    </div>
  );
}

function ProfilerDataSourceNodeTitle(props) {
  const {
    node,
    idMapper,
    selectedKeys,
    onTreeSelect,
    workspaceUuid,
    workspaceUserPermissions,
    history,
    onCreateCustomMetric,
    onCreateTable,
    onEditTable,
    setProfilerAfterTreeNavigationAction,
  } = props;

  const { title, key } = node;
  const entity = idMapper[key];
  const nodeType = entity ? getSelectedNodeTypeAndId(entity)[0] : null;
  if (
    treeMenuNodeTypes.includes(nodeType) &&
    hasPermission(workspaceUserPermissions, treeMenuOpenPermissions[nodeType])
  ) {
    return (
      <ProfilerDataSourceNodeMenu
        title={title}
        entity={entity}
        history={history}
        workspaceUuid={workspaceUuid}
        workspaceUserPermissions={workspaceUserPermissions}
        onCreateCustomMetric={onCreateCustomMetric}
        onCreateTable={onCreateTable}
        onEditTable={onEditTable}
        navigateToTreeNode={() => {
          if (!selectedKeys.includes(key)) {
            onTreeSelect([key]);
          }
        }}
        setProfilerAfterTreeNavigationAction={setProfilerAfterTreeNavigationAction}
      />
    );
  } else {
    return title;
  }
}

function ProfilerDataSourceNode(props) {
  const {
    value: dataSource,
    dataSourceTreeData,
    searchValue,
    selectedNode,
    idMapper,
    workspaceUuid,
    workspaceUserPermissions,
    history,
    onSelect,
    onCreateCustomMetric,
    onCreateTable,
    onEditTable,
    setProfilerAfterTreeNavigationAction,
  } = props;

  const [, nodeId] = getSelectedNodeTypeAndId(selectedNode);
  const metricInTree = idMapper.hasOwnProperty(nodeId);

  const [filteredTreeNodes, matchedNodeKeys] = filterTreeBySearchValue(
    dataSourceTreeData,
    searchValue
  );

  const [initialExpandedKeys, initialSelectedKeys] = metricInTree
    ? getInitialExpandedAndSelectedNodes(selectedNode, dataSource?.metadata?.uuid)
    : [[], []];

  const [manuallyExpandedKeys, setManuallyExpandedKeys] = useState(
    new Set(initialExpandedKeys)
  );
  const [manuallyCollapsedKeys, setManuallyCollapsedKeys] = useState(new Set());

  const expandedKeys = [
    ...difference(
      union(initialExpandedKeys, manuallyExpandedKeys, matchedNodeKeys),
      manuallyCollapsedKeys
    ),
  ];

  const selectedKeys = [...initialSelectedKeys];

  function onTreeSelect(newSelectedKeys) {
    if (newSelectedKeys.length !== 1) {
      console.log(`Unexpected tree selected keys ${JSON.stringify(newSelectedKeys)}`);
      return;
    }
    const currentKeyUuid = newSelectedKeys[0];
    onSelect && idMapper[currentKeyUuid] && onSelect(idMapper[currentKeyUuid]);

    trackProfilerDataSourceTreeClick(idMapper[currentKeyUuid]);
  }

  function onTreeExpand(_newExpandedKeys, nodeInfo) {
    let toExpand = new Set(manuallyExpandedKeys);
    let toCollapse = new Set(manuallyCollapsedKeys);
    let nodeKey = nodeInfo.node.key;
    if (nodeInfo.expanded) {
      const expandedNode = idMapper[nodeKey];
      const [nodeType] = getSelectedNodeTypeAndId(expandedNode);
      const expandedNodeHasHeadingChildren = [
        ProfilerTreeNodeType.DATASOURCE,
        ProfilerTreeNodeType.SCHEMA,
        ProfilerTreeNodeType.TABLE,
        ProfilerTreeNodeType.COLUMN,
      ].includes(nodeType);
      let newExpandedKeys = new Set([nodeKey]);
      if (expandedNodeHasHeadingChildren) {
        for (let child of nodeInfo.node.children) {
          newExpandedKeys.add(child.key);
        }
      }
      toExpand = union(toExpand, newExpandedKeys);
      toCollapse = difference(toCollapse, newExpandedKeys);
    } else {
      toCollapse.add(nodeKey);
      toExpand = difference(toExpand, new Set(nodeKey));
    }
    setManuallyExpandedKeys(toExpand);
    setManuallyCollapsedKeys(toCollapse);
  }

  return (
    <div className="profiler-datasource-node-container">
      <Tree
        showIcon
        blockNode
        showLine={false}
        expandedKeys={expandedKeys}
        selectedKeys={selectedKeys}
        treeData={filteredTreeNodes}
        onSelect={onTreeSelect}
        onExpand={onTreeExpand}
        titleRender={(node) => (
          <ProfilerDataSourceNodeTitle
            node={node}
            idMapper={idMapper}
            selectedKeys={selectedKeys}
            onTreeSelect={onTreeSelect}
            workspaceUuid={workspaceUuid}
            workspaceUserPermissions={workspaceUserPermissions}
            history={history}
            onCreateCustomMetric={onCreateCustomMetric}
            onCreateTable={onCreateTable}
            onEditTable={onEditTable}
            setProfilerAfterTreeNavigationAction={setProfilerAfterTreeNavigationAction}
          />
        )}
      />
    </div>
  );
}

export default ProfilerDataSourceNode;
