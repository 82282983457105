import React from "react";
import ChartView from "../../../components/chart-view/ng-index";
import {
  MonitorStatus,
  AutoMetricsType,
  EventMetricTypeName,
} from "../../../utils/enums";

function ProfilerDataSourceTableSchemaSummary(props) {
  const {
    tableUuid,
    alertingChannelList,
    eventList,
    eventIncidents,
    metric,
    loading,
    tableSchemaChange,
    updateTableSchemaChange,
    workspaceUserPermissions,
    onGoToIncidentList,
    canModifySettings,
  } = props;

  const monitorStatus = tableSchemaChange?.monitoring?.enabled
    ? MonitorStatus.MONITORED
    : MonitorStatus.NOT_MONITORED;
  const alertConfig = tableSchemaChange?.monitoring?.alertConfig || {
    isMuted: false,
    channels: [],
    mutingSchedules: [],
  };

  function updateMonitorStatus(isEnabled, alertConfig) {
    const newTableSchemaChange = {
      ...tableSchemaChange,
      monitoring: {
        ...tableSchemaChange.monitoring,
        enabled: isEnabled,
      },
    };

    if (alertConfig) {
      newTableSchemaChange.monitoring.alertConfig = alertConfig;
    }

    updateTableSchemaChange(newTableSchemaChange);
  }

  function updateAlertChannel(_monitor, newAlertConfig) {
    const newTableSchemaChange = {
      ...tableSchemaChange,
      monitoring: {
        ...tableSchemaChange.monitoring,
        alertConfig: newAlertConfig,
      },
    };
    updateTableSchemaChange(newTableSchemaChange);
  }

  return tableSchemaChange.enabled || canModifySettings ? (
    <ChartView
      key={tableUuid}
      enableConfig={{
        enableConfigMode: true,
        enabled: tableSchemaChange.enabled,
        onClick: () =>
          updateTableSchemaChange(
            {
              ...tableSchemaChange,
              enabled: true,
            },
            { isRefresh: false }
          ),
      }}
      monitorConfig={{
        singularMonitor: true,
        onStartMonitor: (newConfig) => {
          if (tableSchemaChange.monitoring.enabled) {
            return;
          }

          updateMonitorStatus(true, newConfig.alertChannelConfig);
        },
        onStopMonitor: () => {
          if (!tableSchemaChange.monitoring.enabled) {
            return;
          }

          updateMonitorStatus(false);
        },
        enableMetric: true,
        metricActionsOpts: {
          includeDisable: true,
        },
        monitorStatus: monitorStatus,
        alertingChannelList: alertingChannelList,
        currentAlertingChannels: alertConfig,
        onAlertChannelChange: updateAlertChannel,
      }}
      onDisableMetricClick={() =>
        updateTableSchemaChange(
          {
            ...tableSchemaChange,
            enabled: false,
          },
          { isRefresh: false }
        )
      }
      incidentConfig={{ onGoToIncidentList }}
      metricType={AutoMetricsType.COLUMN_ACTIVITY}
      metric={metric}
      title={EventMetricTypeName[AutoMetricsType.COLUMN_ACTIVITY]}
      showSubTitle={true}
      incidents={eventIncidents}
      data={eventList}
      loading={loading}
      config={{}}
      workspaceUserPermissions={workspaceUserPermissions}
    />
  ) : null;
}

export default ProfilerDataSourceTableSchemaSummary;
