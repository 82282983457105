import axios from "../utils/api";
import batchAPIRequestPromise from "../utils/api-batch";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

function normalizeRule(newRule) {
  if (
    typeof newRule.config?.symptom?.featureConfig === "object" &&
    Object.keys(newRule.config?.symptom?.featureConfig).length === 0
  ) {
    delete newRule.config?.symptom?.featureConfig;
  }

  return newRule;
}

export function addRulePromise(workspaceUuid, newRule) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_LIST, {
          workspaceUuid,
        }),
        normalizeRule(newRule),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function addRulesPromise(workspaceUuid, newRules) {
  if (!newRules || newRules.length === 0) {
    return Promise.resolve({ data: [] });
  }

  const addRulePayloads = newRules.map((newRule) => {
    return {
      relative_url: `/api/v1/ws/${workspaceUuid}/monitors/`,
      method: "post",
      data: normalizeRule(newRule),
    };
  });

  return batchAPIRequestPromise(addRulePayloads);
}

export function getRuleListPromise(workspaceUuid, queryOptions = {}) {
  return new Promise(function (resolve, reject) {
    const { metricUuids = [] } = queryOptions;
    const queryArray = [];
    if (metricUuids && metricUuids.length > 0) {
      queryArray.push(`metric_uuids=${metricUuids.join(",")}`);
    }

    let queryString = "";
    if (queryArray.length > 0) {
      queryString = `?${queryArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.MONITOR_LIST, {
          workspaceUuid,
        })}${queryString}`,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data.data);
      })
      .catch(function (error) {
        console.log(`Fail to load filter lists for ${error}`);
        reject(error);
      });
  });
}

export function updateRulePromise(workspaceUuid, uuid, newRule) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.MONITOR, {
          workspaceUuid,
          uuid,
        }),
        newRule,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getRulePromise(workspaceUuid, uuid, opts = {}) {
  return new Promise(function (resolve, rejected) {
    const { disableToast = false } = opts;
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.MONITOR, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/", disableToast }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function getRuleTrainingJobStatusPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, rejected) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_TRAINING_JOB_STATUS, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function getRuleFilterStatusPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, rejected) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_FILTER_STATUS, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function deleteRulePromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    const axiosOptions = { baseURL: "/api/v1/" };
    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.MONITOR, {
          workspaceUuid,
          uuid,
        }),
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

function getPreviewFromServer(rawData) {
  return {
    startTimestamp: rawData["start_ts"],
    endTimestamp: rawData["end_ts"],
    name: rawData["name"],
    id: rawData["id"],
    isComplete: rawData["is_complete"],
    previewJobStatus: rawData["preview_job_state"],
  };
}

export function getRulePreviewListPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_PREVIEW_LIST, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/", disableToast: true }
      )
      .then(function (response) {
        resolve(
          response.data.data.map((currentPreview) =>
            getPreviewFromServer(currentPreview)
          )
        );
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getRulePreviewPromise(workspaceUuid, uuid, previewUuid) {
  return new Promise(function (resolve, rejected) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_PREVIEW, {
          workspaceUuid,
          uuid,
          previewUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(getPreviewFromServer(response.data));
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function addRulePreviewPromise(workspaceUuid, uuid, newRulePreview) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_PREVIEW_LIST, {
          workspaceUuid,
          uuid,
        }),
        newRulePreview,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(getPreviewFromServer(response.data));
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deleteRulePreviewPromise(workspaceUuid, uuid, previewUuid) {
  return new Promise(function (resolve, reject) {
    const axiosOptions = { baseURL: "/api/v1/" };
    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_PREVIEW, {
          workspaceUuid,
          uuid,
          previewUuid,
        }),
        axiosOptions
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getRulePreviewJobStatusPromise(workspaceUuid, uuid, previewUuid) {
  return new Promise(function (resolve, rejected) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.MONITOR_PREVIEW_JOB_STATUS, {
          workspaceUuid,
          uuid,
          previewUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}

export function getRuleSliceValueListPromise(
  workspaceUuid,
  uuid,
  { startTimestamp, endTimestamp }
) {
  return new Promise(function (resolve, rejected) {
    const queryArray = [];
    if (startTimestamp !== undefined) {
      queryArray.push(`start_ts=${startTimestamp}`);
    }

    if (endTimestamp !== undefined) {
      queryArray.push(`end_ts=${endTimestamp}`);
    }

    let queryString = "";
    if (queryArray.length > 0) {
      queryString = `?${queryArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.MONITOR_SLICE_LIST, {
          workspaceUuid,
          uuid,
        })}${queryString}`,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data.sliceList ?? []);
      })
      .catch(function (error) {
        rejected(error);
      });
  });
}
