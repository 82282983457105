import { connect } from "react-redux";
import { closeTakeover } from "../../actions/takeover/takeover-action";

import Workspace from "./workspace";

const mapStateToProps = (state) => ({
  addWorkspaceTakeoverOpen: state.takeoverReducer.addWorkspaceTakeoverOpen,
});

const mapDispatchToProps = (dispatch) => ({
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
