import { EditOutlined } from "@ant-design/icons";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import NgDropdownMenu from "../ng-dropdown-menu";
import { NgTableClickableText } from "../table/ng-table";
import { AppPermissions } from "../../utils/permissions";

function canModifyMonitors(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERDETAIL,
  ]);
}

function MonitorLinkMenu(props) {
  const { monitor, trigger, workspaceUserPermissions, history } = props;

  const menuItems = [];
  const canModify = canModifyMonitors(workspaceUserPermissions);

  menuItems.push({
    label: canModify ? "Edit monitor" : "View monitor",
    icon: <EditOutlined />,
    onClick: () =>
      history.push(
        getURIInstance(URIPath.EDIT_MONITOR, {
          workspaceUuid: monitor.metadata.workspaceId,
          id: monitor.metadata.uuid,
        })
      ),
  });

  return (
    <NgDropdownMenu trigger={trigger} menuItems={menuItems} position="bottomLeft" />
  );
}

function MonitorLinkCell(props) {
  const { monitor, workspaceUserPermissions, history } = props;

  const trigger = <NgTableClickableText>{monitor.metadata.name}</NgTableClickableText>;
  return (
    <MonitorLinkMenu
      monitor={monitor}
      trigger={trigger}
      workspaceUserPermissions={workspaceUserPermissions}
      history={history}
    />
  );
}

export default MonitorLinkCell;
