import React from "react";
import { Form } from "antd";
import { MetadataFieldInput } from "./integration-metadata-editor";
import {
  decodedDefaultFieldValues,
  getBranchLeaf,
  isLeaf,
  replaceField,
  walkTree,
} from "./integration-metadata";

import "./integration-metadata-fields-form.scss";

export function initialFormValues(fields) {
  return fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.fieldId]: decodedDefaultFieldValues(field),
    };
  }, {});
}

export function copyFieldDefaultsToValues(integration) {
  for (let node of walkTree(integration.metadata)) {
    if (isLeaf(node)) {
      for (let field of node.fields) {
        field.value = field.defaultValues;
      }
    }
  }
  return integration;
}

function IntegrationMetadataFields(props) {
  const { fields, onFieldChange, getUserList = null } = props;

  return (
    <div className="integration-metadata-fields-form">
      {fields.map((field) => {
        const { fieldId, isRequired } = field;
        return (
          <Form.Item
            key={fieldId}
            name={fieldId}
            rules={[
              {
                required: isRequired,
                message: isRequired ? "This field is required" : "",
              },
            ]}
          >
            <MetadataFieldInput
              editDefault={false}
              field={field}
              onFieldChange={onFieldChange}
              getUserList={getUserList}
            />
          </Form.Item>
        );
      })}
    </div>
  );
}

export function TicketFieldsForm(props) {
  const { value, form, onChange, getUserList = null } = props;

  const { metadata } = value;
  if (!metadata) {
    return null;
  }

  const editNode = getBranchLeaf(metadata);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialFormValues(editNode.fields)}
      onFieldsChange={() => null}
    >
      <IntegrationMetadataFields
        fields={editNode.fields}
        getUserList={getUserList}
        onFieldChange={(newField) => {
          replaceField(editNode, newField);
          onChange(value);
        }}
      />
    </Form>
  );
}

export default IntegrationMetadataFields;
