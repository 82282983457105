import axios from "../utils/api";
import { ScheduleType, ScheduleRecurringType } from "../utils/enums";
import { DAY_IN_SECONDS, WEEK_IN_SECONDS, HOUR_IN_SECONDS } from "../utils/time";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

function getScheduleFromServer(scheduleObj) {
  const {
    id,
    name: title,
    description,
    repeat_interval_in_seconds: repeatIntervalInSeconds = 0,
    repeat_count: repeatCount = 0,
    time_ranges: timeRanges = [],
  } = scheduleObj;

  let scheduleType = ScheduleType.STATIC;
  let scheduleRecurringType = ScheduleRecurringType.NONE;
  if (repeatIntervalInSeconds === 0) {
    scheduleType = ScheduleType.STATIC;
  } else {
    scheduleType = ScheduleType.RECURRING;
    if (repeatIntervalInSeconds === HOUR_IN_SECONDS) {
      scheduleRecurringType = ScheduleRecurringType.HOURLY;
    } else if (repeatIntervalInSeconds === DAY_IN_SECONDS) {
      scheduleRecurringType = ScheduleRecurringType.DAILY;
    } else if (repeatIntervalInSeconds === WEEK_IN_SECONDS) {
      scheduleRecurringType = ScheduleRecurringType.WEEKLY;
    } else {
      console.log(`Unknown repeatIntervalInSeconds ${repeatIntervalInSeconds}`);
    }
  }

  const normalizedTimeRangeForUI = timeRanges.map(([startTimestamp, endTimestamp]) => {
    return {
      startTimestamp,
      endTimestamp,
    };
  });

  return {
    id,
    title,
    description,
    repeatCount,
    scheduleType,
    scheduleRecurringType,
    timeRanges: normalizedTimeRangeForUI,
  };
}

function getScheduleFromUI(scheduleUI) {
  const {
    title,
    description,
    scheduleType,
    repeatCount = 0,
    scheduleRecurringType,
    timeRanges,
  } = scheduleUI;

  let repeatIntervalInSeconds = 0;
  if (scheduleType === ScheduleType.STATIC) {
    repeatIntervalInSeconds = 0;
  } else {
    if (scheduleRecurringType === ScheduleRecurringType.HOURLY) {
      repeatIntervalInSeconds = HOUR_IN_SECONDS;
    } else if (scheduleRecurringType === ScheduleRecurringType.DAILY) {
      repeatIntervalInSeconds = DAY_IN_SECONDS;
    } else if (scheduleRecurringType === ScheduleRecurringType.WEEKLY) {
      repeatIntervalInSeconds = WEEK_IN_SECONDS;
    }
  }

  let normalizedTimeRanges = timeRanges.map(({ startTimestamp, endTimestamp }) => [
    startTimestamp,
    endTimestamp,
  ]);

  return {
    name: title,
    description,
    repeat_interval_in_seconds: repeatIntervalInSeconds,
    repeat_count: repeatCount,
    time_ranges: normalizedTimeRanges,
  };
}

export function getScheduleListPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.SCHEDULE_LIST, {
          workspaceUuid,
        }),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(
          response.data.map((currentSchedule) => getScheduleFromServer(currentSchedule))
        );
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addSchedulePromise(workspaceUuid, newSchedule) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.SCHEDULE_LIST, {
          workspaceUuid,
        }),
        getScheduleFromUI(newSchedule),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(getScheduleFromServer(response.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateSchedulePromise(workspaceUuid, uuid, newSchedule) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.SCHEDULE, {
          workspaceUuid,
          uuid,
        }),
        getScheduleFromUI(newSchedule),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(getScheduleFromServer(response.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteSchedulePromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.SCHEDULE, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
