import React from "react";
import EventTimeLineChart from "../event-timeline-chart";
import ZeroStatusChart from "../zero-status-chart";
import { isCurrentTimestampAnIncident } from "../../../utils/general";

import "./index.scss";

function getTooltip({ category }) {
  return `Categories ${category}`;
}

function DataCategoryChartView(props) {
  const { data = null, incidents = [] } = props;

  const metricsData =
    data && data.metricsData && data.metricsData.signalData
      ? data.metricsData.signalData
      : [];
  let startTimestamp = 0;
  let endTimestamp = 0;
  if (data && data.duration && data.duration.startTime && data.duration.endTime) {
    startTimestamp = data.duration.startTime;
    endTimestamp = data.duration.endTime;
  }

  const eventTimelineDataList = [];
  for (let dataEntry of metricsData) {
    const { timestamp, value } = dataEntry;
    eventTimelineDataList.push({
      timestamp,
      value: { category: value },
      isIncident: !!isCurrentTimestampAnIncident(incidents, timestamp),
    });
  }

  return (
    <div className="data-category-chart-view-container">
      {eventTimelineDataList.length === 0 ? (
        <ZeroStatusChart height={100} />
      ) : (
        <EventTimeLineChart
          eventTimelineDataList={eventTimelineDataList}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          getTooltip={getTooltip}
          config={{
            height: 100,
            marginTop: 0,
            marginLeft: 30,
            marginRight: 60,
          }}
        />
      )}
    </div>
  );
}

export default DataCategoryChartView;
