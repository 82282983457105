import React, { useEffect, useState } from "react";
import { useInterval } from "../../../utils/hooks";
import ProfilerMonitorPopupMenu from "../../../components/chart-monitor-popup-menu/ng-index";
import TreePath from "../../../components/explorer/tree-path";
import { getMetricTypeFromConfigData } from "../../../components/metric/utils";
import { getColumnTypeCategory } from "../../../utils/column";
import { SlicesShowMode, TreePathLevel } from "../../../utils/enums";
import { Select } from "antd";
import Pagination from "../../../atom/pagination";
import {
  getSliceByColumns,
  getAutoMetricTypeFromKPI,
  isMetricWaitingForFirstTrigger,
  isTriggeredMetric,
} from "../../../utils/metric";
import { URIPath, getURIInstance } from "../../../utils/uri-path";
import { getMonitorStatusFromRule } from "../../../utils/monitor";
import ProfilerMetricActionsMenu from "../profiler-metric-actions-menu";
import {
  getChartSymptomConfigForFocusedMonitor,
  isMetricInVolatileState,
  isMonitorInVolatileState,
  profilerMetricActions,
} from "../utils";
import ChartView from "../../../components/chart-view/ng-index";
import SliceValueSelect from "./slice-value-select";
import {
  getDefaultRuleConfigForCustomMetric,
  getDefaultProfilerDisplayPeriod,
  getKPIInterval,
} from "../../../utils/defaults";
import { isSliceEqual, getSliceDisplayString } from "../../../utils/general";
import DeleteMonitorConfirmationDialog from "../../monitors/delete-monitor-confirmation-dialog";
import { fromUnixTime } from "date-fns";
import { getDefaultUserSetting } from "../../../utils/user-setting";
import { withRouter } from "react-router";
import { EVENT, getMonitorDetailProps, trackEvent } from "../../../utils/telemetry";

import GridEmptyState from "../../../components/empty-state/grid-empty-state";
import LoadingStateContainer from "../../../components/loading-state-container/loading-state-container";

import "./profiler-custom-metric-overview.scss";

const defaultPageSize = 5;

function PaginationSelect(props) {
  return <Select {...props} getPopupContainer={() => document.body} />;
}
PaginationSelect.Option = Select.Option;

function isSlicedMetric(metric) {
  const sliceByColumns = getSliceByColumns(metric);
  if (sliceByColumns.length > 0) {
    return true;
  }

  return metric?.config?.sourceTable?.sliceByColumns?.length > 0;
}

function getCurrentDisplaySliceValues(currentSelectSliceValues, sliceValues) {
  let filteredSliceValues = sliceValues.data;
  if (currentSelectSliceValues.length > 0) {
    filteredSliceValues = filteredSliceValues.filter((currentSlice) =>
      currentSelectSliceValues.some((currentSelectSliceValue) =>
        isSliceEqual(currentSelectSliceValue, currentSlice)
      )
    );
  }

  return filteredSliceValues;
}

function getSliceChartDataPoints(args) {
  const {
    isRefresh = false,
    workspaceUuid,
    metric,
    monitorUuid,
    sliceValues,
    currentSelectSliceValues,
    currentPage,
    currentPageSize,
    queryOptions,
    getProfilerCurrentMetricMetricsData,
  } = args;
  const currentSliceValues = getCurrentDisplaySliceValues(currentSelectSliceValues, {
    data: sliceValues,
  }).slice((currentPage - 1) * currentPageSize, currentPage * currentPageSize);
  getProfilerCurrentMetricMetricsData(
    workspaceUuid,
    metric,
    {
      ...queryOptions,
      monitorUuid,
      isRefresh,
      isSliced: true,
      sliceValues: currentSliceValues,
    },
    { discardIfNoTriggeredStatus: isTriggeredMetricWithoutTriggerRunStatus(metric) }
  );
}

function getMetricData(args) {
  const {
    isRefresh = false,
    useCachedSlices = false,
    workspaceUuid,
    metric,
    monitorUuid,
    currentSelectSliceValues,
    currentPage,
    currentPageSize,
    queryOptions,
    getProfilerCurrentMetricMetricsData,
    getProfilerCurrentMetricSliceValue,
  } = args;
  if (isSlicedMetric(metric)) {
    getProfilerCurrentMetricSliceValue(workspaceUuid, metric, {
      ...queryOptions,
      isRefresh,
      useCache: useCachedSlices,
    }).then((newSliceValues) => {
      getSliceChartDataPoints({
        isRefresh,
        workspaceUuid,
        metric,
        monitorUuid,
        sliceValues: newSliceValues,
        currentSelectSliceValues,
        currentPage,
        currentPageSize,
        queryOptions,
        getProfilerCurrentMetricMetricsData,
      });
    });
  } else {
    getProfilerCurrentMetricMetricsData(workspaceUuid, metric, {
      ...queryOptions,
      isRefresh,
      monitorUuid,
    });
  }
}

function shouldRefreshMetricsData(metricsData) {
  return (
    metricsData?.data?.rules?.some(isMonitorInVolatileState) ||
    isMetricInVolatileState(metricsData?.data?.metric)
  );
}

function isTriggeredMetricWithoutTriggerRunStatus(metric) {
  // This is a special case when a triggered metric seems like it was triggered but it
  // doesn't have the triggerRunStatus, that is happening because there is an optimization
  // that can avoid getting the triggerRunStatus from the metric list endpoint but it
  // should still be in the metric detail endpoint.
  return (
    isTriggeredMetric(metric) &&
    Boolean(metric?.status?.triggered) &&
    !metric?.status?.triggered?.triggerRunStatus
  );
}

function ProfilerCustomMetricOverview(props) {
  const {
    dataSource,
    schemaInfo,
    tableInfo,
    columnInfo,
    metric,
    alertingChannelList,
    createCustomMetricRule,
    workspaceUserPermissions,
    workspaceUuid,
    history,
    metricRefreshInterval,
    profilerCurrentMetricMetricsData: metricsData,
    profilerCurrentMetricSliceValues: sliceValues,
    toggleKpiLiveStatus,
    triggerProfilerMetrics,
    getProfilerCurrentMetricMetricsData,
    resetProfilerCurrentMetricMetricsData,
    onDeleteMetricClick: propsOnDeleteMetricClick,
    deleteRule,
    toggleSystemProfilerRuleStatus,
    updateCustomMetricRuleAlertingChannel,
    onGoToIncidentPage,
    getProfilerCurrentMetricSliceValue,
    resetProfilerCurrentMetricSliceValue,
    refreshProfilerTreeCustomMetric,
    currentSelectNode,
    onCurrentSelectNodeChange,
    onOpenMetricQueryHistory,
    isTreeExpand,
    onTreeExpandChange,
  } = props;

  const [currentShowMode, setCurrentShowMode] = useState(SlicesShowMode.ALL);
  const [currentSelectSliceValues, setCurrentSelectSliceValues] = useState([]);
  const [focusedMonitorUuid, setFocusedMonitorUuid] = useState(null);
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryOptions, setQueryOptions] = useState({
    ...getDefaultProfilerDisplayPeriod(metric, new Date()),
    withIncidents: false,
  });

  const getNewQueryOptions = (queryOptions) => {
    return {
      ...queryOptions,
      withIncidents: currentShowMode === SlicesShowMode.ONLY_INCIDENTS,
    };
  };

  const defaultChartOption = {
    showStatsData: false,
    showSignalData: true,
  };
  const [chartOption, setChartOption] = useState(defaultChartOption);
  const [
    deleteMonitorConfirmationDialogTarget,
    setDeleteMonitorConfirmationDialogTarget,
  ] = useState(null);

  const isSliced = isSlicedMetric(metric);

  const {
    metadata: { name: title, uuid },
  } = metric;

  const isSlicesLoading = sliceValues.loading || metricsData.loading;
  const isInVolatileState = isMetricInVolatileState(metric);
  const isInVolatileStateOrWaitingForTrigger =
    isInVolatileState || isMetricWaitingForFirstTrigger(metric);

  useEffect(() => {
    if (isTriggeredMetricWithoutTriggerRunStatus(metric)) {
      // This condition is a fixup to avoid rendering the trigger button when there is no
      // triggerRunStatus field in a triggered metric, this condition should be removed at some point.
      refreshProfilerTreeCustomMetric(workspaceUuid, uuid).then((refreshedMetric) => {
        refreshMetric({ metric: refreshedMetric });
      });
    }
    return () => {
      resetProfilerCurrentMetricMetricsData();
      resetProfilerCurrentMetricSliceValue();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Updates queryOptions and dispatch getMetricData with no cache when show mode is changed
  useEffect(() => {
    const newQueryOptions = getNewQueryOptions(queryOptions);

    setQueryOptions(newQueryOptions);

    getMetricData({
      queryOptions: newQueryOptions,
      useCachedSlices: false,
      workspaceUuid,
      monitorUuid: focusedMonitorUuid,
      metric,
      currentSelectSliceValues,
      currentPage,
      currentPageSize,
      getProfilerCurrentMetricMetricsData,
      getProfilerCurrentMetricSliceValue,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShowMode]);

  useEffect(() => {
    const newQueryOptions = getNewQueryOptions(queryOptions);

    getMetricData({
      queryOptions: newQueryOptions,
      useCachedSlices: true,
      workspaceUuid,
      monitorUuid: focusedMonitorUuid,
      metric,
      currentSelectSliceValues,
      currentPage,
      currentPageSize,
      getProfilerCurrentMetricMetricsData,
      getProfilerCurrentMetricSliceValue,
    });
    // This useEffect is intended to run on initial component mount, and when
    // the selected page changes. `metric` cannot be included in the list of
    // dependencies because it changes as a result of `refreshProfilerTreeCustomMetric`,
    // which is called when we are polling udpates.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    currentPageSize,
    currentSelectSliceValues,
    getProfilerCurrentMetricMetricsData,
    getProfilerCurrentMetricSliceValue,
    workspaceUuid,
  ]);

  useInterval(() => {
    if (shouldRefreshMetricsData(metricsData) && !metricsData.loading) {
      refreshProfilerTreeCustomMetric(workspaceUuid, uuid).then((refreshedMetric) => {
        const newQueryOptions = getNewQueryOptions({
          ...getDefaultProfilerDisplayPeriod(refreshedMetric, new Date()),
        });

        setQueryOptions(newQueryOptions);

        getMetricData({
          queryOptions: newQueryOptions,
          isRefresh: true,
          workspaceUuid,
          monitorUuid: focusedMonitorUuid,
          metric: refreshedMetric,
          currentSelectSliceValues,
          currentPage,
          currentPageSize,
          getProfilerCurrentMetricMetricsData,
          getProfilerCurrentMetricSliceValue,
        });
      });
    }
  }, metricRefreshInterval);

  let metricsChartData;
  let rules = [];
  const currentDisplaySliceValues = getCurrentDisplaySliceValues(
    currentSelectSliceValues,
    sliceValues
  );

  const getLoadingMetricChartData = (additional = {}) => ({
    loading: true,
    data: [],
    chartMetric: null,
    ...additional,
  });

  const getMetricsChartData = () => {
    if (
      isInVolatileStateOrWaitingForTrigger &&
      metricsData.data.metricsData.length === 0
    ) {
      return [{ loading: false, chartMetric: metric, data: [] }];
    }

    return metricsData.data.metricsData.map((data) => {
      return {
        loading: false,
        chartMetric: metricsData.data.metric,
        ...data,
      };
    });
  };

  if (metricsData.loading) {
    if (!isSliced) {
      metricsChartData = [getLoadingMetricChartData({ incidents: [] })];
    } else {
      metricsChartData = currentDisplaySliceValues
        .slice(0, currentPageSize)
        .map((sliceValue) => {
          return getLoadingMetricChartData({ sliceValue });
        });
    }
  } else {
    rules = metricsData.data.rules || [];
    metricsChartData = getMetricsChartData();
  }

  function refreshMetric(args = {}) {
    const newQueryOptions = getNewQueryOptions({
      ...getDefaultProfilerDisplayPeriod(args.metric ?? metric, new Date()),
    });

    setQueryOptions(newQueryOptions);

    getMetricData({
      isRefresh: true,
      useCachedSlices: true,
      queryOptions: newQueryOptions,
      workspaceUuid,
      metric,
      monitorUuid: focusedMonitorUuid,
      currentSelectSliceValues,
      currentPage,
      currentPageSize,
      getProfilerCurrentMetricMetricsData,
      getProfilerCurrentMetricSliceValue,
      ...args,
    });
  }

  function onGoToIncidentList(metric, rules, sliceValue) {
    const { startTimestamp, endTimestamp } = queryOptions;
    const kpiName = metric.metadata.name;
    const userSetting = getDefaultUserSetting();
    userSetting.time = {
      currentInterval: "1w",
      startTime: fromUnixTime(startTimestamp),
      endTime: fromUnixTime(endTimestamp),
      current: fromUnixTime(endTimestamp),
    };

    const slice = sliceValue ? [getSliceDisplayString(sliceValue)] : [];
    userSetting.localFilterSetting = {
      filterName: [],
      severity: [],
      kpiName: [kpiName],
      metricId: [metric.metadata.idSerial.toString()],
      slice,
      tagName: [],
      direction: [],
      showMyRule: false,
      showProceededDataOnly: true,
    };
    userSetting.slice = sliceValue;

    const chartInfo = {
      autoMetricType: getAutoMetricTypeFromKPI(metric),
      currentMetric: metric,
      dataSourceInfo: dataSource,
      tableInfo: null,
      columnInfo: null,
    };

    onGoToIncidentPage({ userSetting, rules, chartInfo });
  }

  function onToggleMetricLiveStatusClick(workspaceUuid, metric, isLive) {
    toggleKpiLiveStatus(workspaceUuid, metric, isLive).then(() => {
      refreshProfilerTreeCustomMetric(workspaceUuid, uuid).then((refreshedMetric) => {
        refreshMetric({ metric: refreshedMetric });
      });
    });
  }

  function onDeleteMetricClick(metric) {
    propsOnDeleteMetricClick(metric, rules.length);
  }

  function onDeleteMonitorConfirmed(monitors) {
    const monitorUuid = monitors[0].metadata.uuid;

    trackEvent(EVENT.DELETE_MONITOR, {
      ...getMonitorDetailProps(monitors[0], metric, dataSource),
    });

    deleteRule(workspaceUuid, monitorUuid).then(() => {
      const deletingFocusedMonitor = monitorUuid === focusedMonitorUuid;
      if (deletingFocusedMonitor) {
        setFocusedMonitorUuid(null);
      }
      refreshMetric({
        monitorUuid: deletingFocusedMonitor ? null : focusedMonitorUuid,
      });
    });
  }

  function onTriggerMetricClick() {
    triggerProfilerMetrics(
      dataSource.metadata.uuid,
      {
        metricUuids: [uuid],
      },
      false
    ).then(() => {
      refreshProfilerTreeCustomMetric(workspaceUuid, uuid).then((refreshedMetric) => {
        refreshMetric({
          queryOptions,
          isRefresh: true,
          useCachedSlices: false,
          metric: refreshedMetric,
        });
      });
    });
  }

  function onDeleteMonitorMenuClick(monitorUuid, monitors) {
    const targetMonitor = monitors.find(
      (monitor) => monitor.metadata.uuid === monitorUuid
    );
    if (targetMonitor) {
      setDeleteMonitorConfirmationDialogTarget(targetMonitor);
    }
  }

  function onToggleMonitorLiveStatusClick(monitorUuid, monitors) {
    const targetMonitor = monitors.find(
      (monitor) => monitor.metadata.uuid === monitorUuid
    );
    if (targetMonitor) {
      toggleSystemProfilerRuleStatus(targetMonitor).then(() => {
        refreshMetric();
      });
    }
  }

  function onSwitchMonitorClick(newMonitorUuid) {
    setFocusedMonitorUuid(newMonitorUuid);
    setChartOption({ ...defaultChartOption, showStatsData: Boolean(newMonitorUuid) });
    refreshMetric({
      queryOptions,
      monitorUuid: newMonitorUuid,
    });
  }

  let monitorConfig = {
    monitorUuid: focusedMonitorUuid,
    onStartMonitor: (symptomTypeConfig) => {
      const ruleConfig = getDefaultRuleConfigForCustomMetric(
        symptomTypeConfig,
        metric,
        0
      );
      createCustomMetricRule(ruleConfig);
    },
    onStopMonitor: (monitorUuid) => onDeleteMonitorMenuClick(monitorUuid, rules),
    onPauseMonitor: (monitorUuid) => onToggleMonitorLiveStatusClick(monitorUuid, rules),
    onResumeMonitor: (monitorUuid) =>
      onToggleMonitorLiveStatusClick(monitorUuid, rules),
    enableSwitchMonitor: true,
    onSwitchMonitor: onSwitchMonitorClick,
    relatedRules: rules,
    incidents: metricsData.data.incidents,
    onGoToRule: (ruleUuid) => {
      history.push(
        getURIInstance(URIPath.EDIT_MONITOR, {
          workspaceUuid,
          id: ruleUuid,
        })
      );
    },
    onCloneMonitor: (monitorUuid) => {
      history.push(
        `${getURIInstance(URIPath.ADD_MONITOR, {
          workspaceUuid,
        })}?cloneFrom=${monitorUuid}`
      );
    },
    enableMetric: true,
    onGoToMetric: () => {
      history.push(
        getURIInstance(URIPath.EDIT_METRIC, {
          workspaceUuid,
          id: uuid,
        })
      );
    },
    monitorStatus: getMonitorStatusFromRule(rules),
    alertingChannelList,
    onAlertChannelChange: updateCustomMetricRuleAlertingChannel,
  };

  if (isSliced) {
    monitorConfig = {
      ...monitorConfig,
      thresholdConfig: {
        chartOption,
        onChartOptionChange: (monitorUuid, newChartOption) =>
          setChartOption(newChartOption),
      },
    };
  } else {
    monitorConfig = {
      ...monitorConfig,
      enableChartOption: true,
    };
  }

  const autoMetricType = getAutoMetricTypeFromKPI(metric);
  const metricAggregationInterval = metric ? getKPIInterval(metric) : "";

  return (
    <div className="profiler-custom-metric-overview-container lightup-vertical-flex-container">
      <div className="profiler-custom-metric-overview-header">
        <div>
          <div className="profiler-custom-metric-overview-header-title-row">
            <TreePath
              datasource={{
                type: dataSource.config.connection.type,
                name: dataSource.metadata.name,
              }}
              schema={schemaInfo?.name}
              table={tableInfo?.tableName}
              column={{
                type: getColumnTypeCategory(columnInfo),
                name: columnInfo?.columnName,
              }}
              customMetric={{
                type: getMetricTypeFromConfigData(metric),
                name: title,
              }}
              selectedLevel={TreePathLevel.CUSTOM_METRIC}
              currentSelectNode={currentSelectNode}
              onCurrentSelectNodeChange={onCurrentSelectNodeChange}
              isTreeExpand={isTreeExpand}
              onTreeExpandChange={onTreeExpandChange}
            />

            {isSliced && (
              <div className="profiler-custom-metric-overview-header-metric-controls">
                <div className="profiler-custom-metric-over-header-monitor-popup">
                  <ProfilerMonitorPopupMenu
                    {...monitorConfig}
                    metric={metric}
                    metricType={autoMetricType}
                    metricUuid={uuid}
                    workspaceUserPermissions={workspaceUserPermissions}
                  />
                </div>
                {monitorConfig?.enableMetric && (
                  <ProfilerMetricActionsMenu
                    title="Actions"
                    tooltip="Metric Menu"
                    actions={profilerMetricActions(metric, dataSource)}
                    workspaceUuid={workspaceUuid}
                    workspaceUserPermissions={workspaceUserPermissions}
                    metrics={[metric]}
                    history={history}
                    onToggleMetricLiveStatusClick={(
                      workspaceUuid,
                      metrics,
                      newIsLive
                    ) =>
                      onToggleMetricLiveStatusClick(
                        workspaceUuid,
                        metrics[0],
                        newIsLive
                      )
                    }
                    onDeleteMetricClick={(metrics) => onDeleteMetricClick(metrics[0])}
                    onTriggerMetricClick={onTriggerMetricClick}
                    onAlertChannelChange={updateCustomMetricRuleAlertingChannel}
                    onQueryHistoryClick={(metric) => onOpenMetricQueryHistory(metric)}
                  />
                )}
              </div>
            )}
          </div>

          {isSliced && (
            <div className="profiler-custom-metric-overview-header-slice-controls">
              <div className="profiler-custom-metric-overview-header-slice-select-container">
                <SliceValueSelect
                  metric={metric}
                  value={currentSelectSliceValues}
                  sliceValueList={sliceValues.data}
                  loading={sliceValues.loading}
                  placeholder="Search slices..."
                  onChange={(newSelectSliceValues) => {
                    setCurrentSelectSliceValues(newSelectSliceValues);
                    setCurrentPage(1);
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </div>

              <div className="profiler-custom-metric-overview-header-slice-select-container">
                <div className="profiler-custom-metric-overview-header-slice-select-container-item">
                  <Select
                    value={currentShowMode}
                    style={{ minWidth: "200px" }}
                    loading={sliceValues.loading || metricsData.loading}
                    onChange={setCurrentShowMode}
                    disabled={sliceValues.loading || metricsData.loading}
                  >
                    <Select.Option value={SlicesShowMode.ALL}>Show All</Select.Option>
                    <Select.Option value={SlicesShowMode.ONLY_INCIDENTS}>
                      Only Show Incidents
                    </Select.Option>
                  </Select>
                </div>

                {sliceValues.data.length > defaultPageSize && (
                  <div className="profiler-custom-metric-overview-header-slice-select-container-item">
                    <Pagination
                      current={currentPage}
                      selectComponentClass={PaginationSelect}
                      pageSize={currentPageSize}
                      total={currentDisplaySliceValues.length}
                      onChange={(newCurrentPage, newPageSize) => {
                        setCurrentPage(newCurrentPage);
                        setCurrentPageSize(newPageSize);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="profiler-custom-metric-overview-body-container lightup-rest-flex-item-container">
        <div className="profiler-custom-metric-overview-profiler-activity-list-container">
          {metricsChartData.length === 0 && isSlicesLoading && (
            <LoadingStateContainer />
          )}

          {metricsChartData.length === 0 && !isSlicesLoading && (
            <GridEmptyState
              title={
                currentShowMode === SlicesShowMode.ONLY_INCIDENTS
                  ? "NO MATCHING SLICES WITH INCIDENTS"
                  : "NO MATCHING SLICES"
              }
            />
          )}

          {metricsChartData.map(
            ({ loading, data, chartMetric, incidents, sliceValue }, index) => {
              const chartTitle = sliceValue
                ? getSliceDisplayString(sliceValue, chartMetric)
                : title;
              const titleDescription = sliceValue
                ? ""
                : chartMetric?.metadata.description;

              return (
                <ChartView
                  metricAggregationInterval={metricAggregationInterval}
                  workspaceUserPermissions={workspaceUserPermissions}
                  key={index}
                  loading={loading}
                  dataSource={dataSource}
                  metric={chartMetric}
                  metricUuid={uuid}
                  monitorConfig={isSliced ? null : monitorConfig}
                  metricType={autoMetricType}
                  title={chartTitle}
                  titleDescription={titleDescription}
                  subTitle={""}
                  data={data}
                  incidents={incidents}
                  incidentConfig={{
                    onGoToIncidentList: () =>
                      onGoToIncidentList(chartMetric, rules, sliceValue),
                  }}
                  defaultShowSignalData={chartOption.showSignalData}
                  defaultShowStatsData={chartOption.showStatsData}
                  enableChartOption={false}
                  config={{
                    ...getChartSymptomConfigForFocusedMonitor(
                      rules,
                      focusedMonitorUuid
                    ),
                    autosize: false,
                    height: 200,
                    marginTop: 25,
                    startTime: 0,
                    endTime: 0,
                    detectInterval: 0,
                    isIncidentOnly: false,
                    xTitle: "Event time",
                    y2Title: "",
                    textHeightInPx: 1,
                    className: "",
                    isProfiler: true,
                  }}
                  onToggleMetricLiveStatusClick={onToggleMetricLiveStatusClick}
                  onDeleteMetricClick={onDeleteMetricClick}
                  onTriggerMetricClick={onTriggerMetricClick}
                  onQueryHistoryClick={onOpenMetricQueryHistory}
                  showMetricStatusBadge
                />
              );
            }
          )}
        </div>
      </div>

      {deleteMonitorConfirmationDialogTarget && (
        <DeleteMonitorConfirmationDialog
          isOpen={Boolean(deleteMonitorConfirmationDialogTarget)}
          setIsOpen={(isOpen) => {
            if (!isOpen) {
              setDeleteMonitorConfirmationDialogTarget(null);
            }
          }}
          monitors={[deleteMonitorConfirmationDialogTarget]}
          onDeleteMonitors={onDeleteMonitorConfirmed}
        />
      )}
    </div>
  );
}

export default withRouter(ProfilerCustomMetricOverview);
