import { ConnectionHealth } from "./enums";

export const CatalogsRunStatus = Object.freeze({
  OK: "ok",
  EXCEPTION: "exception",
});

export function getCatalogsConnectionHealth(integration) {
  const runStatus = integration?.status?.runStatus ?? CatalogsRunStatus.OK;

  if (runStatus === CatalogsRunStatus.OK) {
    return ConnectionHealth.SUCCESS;
  }

  if (runStatus === CatalogsRunStatus.EXCEPTION) {
    return ConnectionHealth.ERROR;
  }

  return ConnectionHealth.UNKNOWN;
}
