import React from "react";
import {
  URIPath,
  getURIInstance,
  URIPathPermissions,
  hasPermission,
} from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { EVENT, PAGE, trackEvent } from "../../utils/telemetry";
import ProfilerDataSourceStatusHelpItem from "./profiler-data-source-help-item";
import { AddDataSourceIcon, AddMemberIcon } from "./profiler-icons";

import "./profiler-data-source-zero-status-view.scss";

function ProfilerDataSourceZeroStatusView({ workspaceUuid, workspaceUserPermissions }) {
  const helpSteps = [];
  const canAddDataSource = hasPermission(
    workspaceUserPermissions,
    URIPathPermissions[URIPath.ADD_DATA_SOURCE]
  );
  const canAddMember = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST,
  ]);
  if (canAddDataSource) {
    helpSteps.push({
      icon: <AddDataSourceIcon />,
      title: "Hook up your first datasource by clicking the button below",
      buttonText: "Add datasource",
      redirectUri: getURIInstance(URIPath.ADD_DATA_SOURCE, { workspaceUuid }),
      description: "Any admin can do this",
      trackedEvent: EVENT.ADD_DATASOURCE,
    });
  }

  if (canAddMember) {
    helpSteps.push({
      icon: <AddMemberIcon />,
      title: "Add members to collaborate within this workspace",
      buttonText: "Add members",
      redirectUri: getURIInstance(URIPath.EDIT_WORKSPACE, { workspaceUuid }),
      description: "We’ll notify them to join",
      trackedEvent: EVENT.ADD_USER_TO_WORKSPACE,
    });
  }

  const canPerformActions = helpSteps.length > 0;
  const actionPrompt = canPerformActions
    ? "Here’s some helpful steps to get your workspace up and running"
    : "Once your workspace admin has added data assets, datasources, tables, and metrics will appear here";

  const trackClickAction = (event) => {
    trackEvent(event, {
      workspace_id: workspaceUuid,
      page: PAGE.WORKSPACE_ZERO_STATE,
    });
  };

  return (
    <div className="profiler-data-source-zero-status-view-container">
      <div className="profiler-data-source-zero-status-title-container">
        Welcome to your workspace!
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3806 1.70213C10.6308 1.89113 10.7778 2.1865 10.7778 2.50002L10.7778 8.85897C10.7777 9.30602 10.481 9.69876 10.051 9.82091L4.27322 11.462C3.97163 11.5476 3.64733 11.4869 3.39718 11.2979C3.14703 11.1089 3 10.8135 3 10.5L3.00001 4.14104C3.00001 3.69398 3.29674 3.30123 3.72679 3.17909L9.50458 1.53806C9.80617 1.4524 10.1305 1.51314 10.3806 1.70213ZM5.00001 4.89657L5 9.17642L8.77776 8.10343L8.77779 3.82359L5.00001 4.89657ZM18.8388 8.82379C19.0808 9.01333 19.2222 9.30368 19.2222 9.61111V21.0732C19.2222 21.5331 18.9085 21.9337 18.462 22.044L12.6843 23.4708C12.3858 23.5446 12.07 23.4769 11.828 23.2873C11.5859 23.0978 11.4445 22.8074 11.4445 22.5V11.038C11.4445 10.578 11.7582 10.1774 12.2047 10.0671L17.9825 8.64028C18.281 8.56657 18.5967 8.63424 18.8388 8.82379ZM13.4445 11.821V21.223L17.2222 20.2901V10.8881L13.4445 11.821ZM10.3806 10.8987C10.6308 11.0877 10.7778 11.3831 10.7778 11.6966L10.7778 18.0556C10.7777 18.5026 10.481 18.8954 10.051 19.0175L4.27322 20.6586C3.97163 20.7442 3.64733 20.6835 3.39718 20.4945C3.14703 20.3055 3 20.0101 3 19.6966L3.00001 13.3376C3.00001 12.8905 3.29675 12.4978 3.7268 12.3757L9.50459 10.7347C9.80617 10.649 10.1305 10.7097 10.3806 10.8987ZM5.00001 14.0931L5 18.373L8.77776 17.3L8.77779 13.0202L5.00001 14.0931Z"
            fill="#9E9CF6"
          />
        </svg>
      </div>
      <div className="profiler-data-source-zero-status-help-step-title-container">
        {actionPrompt}
      </div>
      <div className="profiler-data-source-zero-status-help-step-list-container">
        {helpSteps.map(({ title, trackedEvent, ...props }) => (
          <ProfilerDataSourceStatusHelpItem
            key={title}
            title={title}
            {...props}
            buttonProps={{ onClick: () => trackClickAction(trackedEvent) }}
          />
        ))}
      </div>
    </div>
  );
}

export default ProfilerDataSourceZeroStatusView;
