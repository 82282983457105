import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const momentFormat = "YYYY/MM/DD HH:mm:ss";
function TimeRangeSelector(props) {
  let {
    currentTime = new Date(),
    value,
    onChange,
    presetRanges,
    allowEmpty,
    ...restProps
  } = props;

  if (!presetRanges) {
    const currentTimeInMoment = moment(currentTime);
    presetRanges = {
      "1h": [currentTimeInMoment.clone().subtract(1, "h"), currentTimeInMoment.clone()],
      "1d": [currentTimeInMoment.clone().subtract(1, "d"), currentTimeInMoment.clone()],
      "1w": [currentTimeInMoment.clone().subtract(1, "w"), currentTimeInMoment.clone()],
      "1m": [currentTimeInMoment.clone().subtract(1, "M"), currentTimeInMoment.clone()],
      "3m": [currentTimeInMoment.clone().subtract(3, "M"), currentTimeInMoment.clone()],
    };
  }
  return (
    <DatePicker.RangePicker
      className="chromatic-ignore"
      ranges={presetRanges}
      showTime
      value={[
        moment(value.startTime),
        // if allowEmpty is true for endDate, then the second value can be undefined, otherwise it will
        value.endTime ? moment(value.endTime) : undefined,
      ]}
      allowEmpty={allowEmpty}
      format={momentFormat}
      onChange={(newTimeRange) => {
        onChange &&
          onChange({
            startTime: newTimeRange.length > 0 ? newTimeRange[0]?.toDate() : null,
            endTime: newTimeRange.length > 1 ? newTimeRange[1]?.toDate() : null,
          });
      }}
      {...restProps}
    />
  );
}

export default TimeRangeSelector;
