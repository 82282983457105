import { useHistory, useLocation } from "react-router-dom";

export function useAddQueryParams(isPush = true) {
  const history = useHistory();
  const location = useLocation();
  const addQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach((key) => {
      searchParams.set(key, params[key]);
    });

    (isPush ? history.push : history.replace)({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return addQueryParams;
}
