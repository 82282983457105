import { connect } from "react-redux";
import SettingsTakeover from "./settings-takeover";
import { getDataSourceList } from "../../../actions/datasource/data-source-action";

const mapStateToProps = (state) => ({
  dataSourceList: state.dataSourceReducer.dataSourceList,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTakeover);
