import React from "react";
import EmailIntegrationBasicDialog from "./email-integration-basic-dialog";
import { EditOutlined } from "@ant-design/icons";
import "./edit-email-integration-dialog.scss";

function EditEmailIntegrationDialog(props) {
  const { data, onEdit, onPreview, modalIsOpen, setIsOpen, enableSave, enablePreview } =
    props;

  const onOkClicked = (newData) => {
    onEdit(newData);
  };

  return (
    <EmailIntegrationBasicDialog
      defaultData={data}
      className={"edit-email-integration-dialog-container"}
      dialogTitle={"Edit Email Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      triggerComponent={<EditOutlined />}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enableSave={enableSave}
      enablePreview={enablePreview}
    />
  );
}

export default EditEmailIntegrationDialog;
