export function poll(fn, shouldContinueFn, timeout, interval, timeoutFn = setTimeout) {
  const startTime = Date.now();
  const endTime = startTime + timeout;

  const checkCondition = function (resolve, reject) {
    fn()
      .then(function (response) {
        const currentTime = Date.now();
        if (currentTime < endTime && shouldContinueFn(response)) {
          timeoutFn(checkCondition, interval, resolve, reject);
        } else if (currentTime > endTime) {
          reject(new Error("timed out for " + fn + ": " + arguments));
        } else {
          resolve(response);
        }
      })
      .catch(function (err) {
        reject(err);
      });
  };

  return new Promise(checkCondition);
}

const DEFAULT_POLL_TIME_OUT_IN_MS = 10 * 60 * 1000;
const DEFAULT_POLL_INTERVAL_IN_MS = 5 * 1000;

export class PollManager {
  constructor(interval, timeout) {
    this._timer = null;
    this._interval = interval || DEFAULT_POLL_INTERVAL_IN_MS;
    this._timeout = timeout || DEFAULT_POLL_TIME_OUT_IN_MS;
  }

  pollSetTimeout(checkCondition, interval, resolve, reject) {
    this._timer = setTimeout(checkCondition, interval, resolve, reject);
  }

  start(fn, shouldContinueFn) {
    return poll(
      fn,
      shouldContinueFn,
      this._timeout,
      this._interval,
      this.pollSetTimeout.bind(this)
    );
  }

  stop() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }
}
