import React from "react";
import NgButton from "../../../../../components/button/ng-button";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import DurationInput from "../../../../../components/duration-input/duration-input";
import PartitionConfigItem from "../../../../../components/ng-partition-config-item";
import { NextGenPalette } from "../../../../../utils/color";
import { LabeledSelect } from "../../../../../components/labeled-control/labeled-control";
import { TimezoneOptions } from "../../../../../utils/options";
import { isAdvancedSettingsModified } from "../../../../../utils/partitions";

import "./profiler-table-partition-editor.scss";

const defaultCustomizationOptions = {
  hideAdvancedButton: false,
  hideRecommended: false,
};

function ProfilerTablePartitionEditor(props) {
  const {
    partitions,
    partitionTimezone,
    partitionOffsets,
    partitionColumnList,
    partitionSampleData = null,
    isAdvancedSectionOpen,
    editEnabled = true,
    onAdvancedClick,
    updateTableConfig,
    customizationOptions = {},
  } = props;

  const { includePastSeconds, includeFutureSeconds } = partitionOffsets ?? {};
  const { hideAdvancedButton, hideRecommended } = {
    ...defaultCustomizationOptions,
    ...customizationOptions,
  };

  const isAdvancedSectionModified = isAdvancedSettingsModified({
    partitionTimezone,
    partitionOffsets,
  });

  return (
    <div className="profiler-table-partition-editor">
      <div className="profiler-setting-config-table-config-partition-config-header-container">
        <div className="profiler-setting-config-table-config-partition-config-title-container">
          PARTITIONS
          {(!partitions || !partitions.length) && !hideRecommended && (
            <span className="profiler-setting-config-table-config-partition-recommended-container">
              Recommended
            </span>
          )}
          <div className="profiler-setting-config-table-config-partition-config-sub-title-container">
            Partitions are recommended to optimize query performance
          </div>
        </div>
        {!hideAdvancedButton && (
          <NgButton
            outline
            disabled={!editEnabled}
            onClick={() => {
              onAdvancedClick();
            }}
            style={
              isAdvancedSectionModified && !isAdvancedSectionOpen
                ? {
                    borderColor: NextGenPalette.brandYellow,
                  }
                : {}
            }
          >
            Advanced
            <SettingOutlined />
          </NgButton>
        )}
        <NgButton
          outline
          disabled={!editEnabled}
          onClick={() => {
            updateTableConfig({
              partitions: [...partitions, { columnName: "", format: "" }],
            });
          }}
        >
          Add
          <PlusOutlined />
        </NgButton>
      </div>
      {isAdvancedSectionOpen && (
        <div className={"partition-advance-config metric-config-bordered"}>
          <div className="partition-advance-config-row">
            <LabeledSelect
              showSearch
              disabled={!editEnabled}
              label="Partition timezone"
              options={TimezoneOptions}
              value={partitionTimezone}
              onChange={(newPartitionTimezone) => {
                updateTableConfig({
                  partitionTimezone: newPartitionTimezone,
                });
              }}
            />
          </div>
          <div className="partition-advance-config-row">
            <DurationInput
              label={"Partition offset past"}
              disabled={!editEnabled}
              value={includePastSeconds}
              onChange={(newIncludePastSeconds) => {
                if (newIncludePastSeconds === includePastSeconds) {
                  return;
                }
                updateTableConfig({
                  partitionOffsets: {
                    includePastSeconds: newIncludePastSeconds,
                    includeFutureSeconds,
                  },
                });
              }}
            />
            <DurationInput
              label={"Partition offset future"}
              disabled={!editEnabled}
              value={includeFutureSeconds}
              onChange={(newIncludeFutureSeconds) => {
                if (newIncludeFutureSeconds === includeFutureSeconds) {
                  return;
                }
                updateTableConfig({
                  partitionOffsets: {
                    includePastSeconds,
                    includeFutureSeconds: newIncludeFutureSeconds,
                  },
                });
              }}
            />
          </div>
        </div>
      )}
      <PartitionConfigItem
        className="profiler-setting-config-table-config-partition-config-content-container"
        value={partitions}
        disabled={!editEnabled}
        onChange={(newPartitions) =>
          updateTableConfig({
            partitions: [...newPartitions],
          })
        }
        columnList={partitionColumnList}
        partitionSampleData={partitionSampleData}
      />
    </div>
  );
}

export default ProfilerTablePartitionEditor;
