import React from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";

import "./index.scss";

function ListContextMenuEditIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M10.6953 3.55469L0.921875 13.3281L0.5 17.3359C0.429688 17.8633 0.886719 18.3203 1.41406 18.25L5.42188 17.8281L15.1953 8.05469L10.6953 3.55469ZM17.9727 2.88672L15.8633 0.777344C15.2305 0.109375 14.1406 0.109375 13.4727 0.777344L11.5039 2.74609L16.0039 7.24609L17.9727 5.27734C18.6406 4.60938 18.6406 3.51953 17.9727 2.88672Z"
        fill="#525252"
      />
    </svg>
  );
}

function ListContextMenuDeleteIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M15.8125 1.375H11.5938L11.2422 0.742188C11.1016 0.460938 10.7852 0.25 10.5039 0.25H6.46094C6.17969 0.25 5.82812 0.460938 5.72266 0.742188L5.40625 1.375H1.1875C0.871094 1.375 0.625 1.65625 0.625 1.9375V3.0625C0.625 3.37891 0.871094 3.625 1.1875 3.625H15.8125C16.0938 3.625 16.375 3.37891 16.375 3.0625V1.9375C16.375 1.65625 16.0938 1.375 15.8125 1.375ZM2.48828 16.668C2.52344 17.5469 3.29688 18.25 4.17578 18.25H12.7891C13.668 18.25 14.4414 17.5469 14.4766 16.668L15.25 4.75H1.75L2.48828 16.668Z"
        fill="#CE484D"
      />
    </svg>
  );
}

function ListContextMenuCloneIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M16.8125 0.25H6.6875C5.73828 0.25 5 1.02344 5 1.9375V12.0625C5 13.0117 5.73828 13.75 6.6875 13.75H16.8125C17.7266 13.75 18.5 13.0117 18.5 12.0625V1.9375C18.5 1.02344 17.7266 0.25 16.8125 0.25ZM6.6875 14.875C5.10547 14.875 3.875 13.6445 3.875 12.0625V4.75H2.1875C1.23828 4.75 0.5 5.52344 0.5 6.4375V16.5625C0.5 17.5117 1.23828 18.25 2.1875 18.25H12.3125C13.2266 18.25 14 17.5117 14 16.5625V14.875H6.6875Z"
        fill="#525252"
      />
    </svg>
  );
}

function ListContextMenuViewTableIcon(props) {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width="18" height="2" rx="1" fill="#868786" />
      <rect y="3" width="11" height="2" rx="1" fill="#868786" />
      <rect y="6" width="11" height="2" rx="1" fill="#868786" />
      <rect y="9" width="11" height="2" rx="1" fill="#868786" />
      <rect y="12" width="11" height="2" rx="1" fill="#868786" />
      <rect x="12" y="3" width="6" height="2" rx="1" fill="#868786" />
      <rect x="12" y="6" width="6" height="2" rx="1" fill="#868786" />
      <rect x="12" y="9" width="6" height="2" rx="1" fill="#868786" />
      <rect x="12" y="12" width="6" height="2" rx="1" fill="#868786" />
    </svg>
  );
}

function ListContextMenuViewColumnIcon(props) {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width="11" height="2" rx="1" fill="#DDDDDD" />
      <rect y="3" width="11" height="2" rx="1" fill="#DDDDDD" />
      <rect y="6" width="11" height="2" rx="1" fill="#DDDDDD" />
      <rect y="9" width="11" height="2" rx="1" fill="#DDDDDD" />
      <rect y="12" width="11" height="2" rx="1" fill="#DDDDDD" />
      <rect x="12" y="3" width="6" height="2" rx="1" fill="#868786" />
      <rect x="12" width="6" height="2" rx="1" fill="#868786" />
      <rect x="12" y="6" width="6" height="2" rx="1" fill="#868786" />
      <rect x="12" y="9" width="6" height="2" rx="1" fill="#868786" />
      <rect x="12" y="12" width="6" height="2" rx="1" fill="#868786" />
    </svg>
  );
}

function ListContextMenuViewDashboardIcon(props) {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path d="M20 16H9V9H20V16ZM7 16H0V9H7V16ZM20 7H0V0H20V7Z" fill="#868786" />
    </svg>
  );
}

function ListContextMenu(props) {
  const {
    contextMenuId,
    onContextMenuClick,
    title = "",
    enableCloneMenuItem = false,
    enableEditMenuItem = true,
    enableDetailsForViewOnly = false,
    enableDeleteMenuItem = true,
    enableViewDashboardMenuItem = false,
    enableViewMenuItem = false,
    enableViewColumnMenuItem = false,
    enableSettingItem = false,
  } = props;

  const editLabel = enableDetailsForViewOnly ? "View" : "Edit";
  return (
    <ContextMenu
      id={contextMenuId}
      className="list-context-menu-container"
      style={{ zIndex: 100 }}
    >
      {title && <div className="list-context-menu-title-container">{title}</div>}
      {enableViewMenuItem && (
        <MenuItem onClick={onContextMenuClick} data={{ action: "View" }}>
          <ListContextMenuViewTableIcon />
          View tables
        </MenuItem>
      )}
      {enableEditMenuItem && (
        <MenuItem onClick={onContextMenuClick} data={{ action: "Edit" }}>
          <ListContextMenuEditIcon />
          {editLabel}
        </MenuItem>
      )}
      {enableViewDashboardMenuItem && (
        <MenuItem onClick={onContextMenuClick} data={{ action: "ViewDashboard" }}>
          <ListContextMenuViewDashboardIcon />
          View in Explorer
        </MenuItem>
      )}
      {enableCloneMenuItem && (
        <MenuItem onClick={onContextMenuClick} data={{ action: "Clone" }}>
          <ListContextMenuCloneIcon />
          Clone
        </MenuItem>
      )}
      {enableDeleteMenuItem && (
        <MenuItem
          className="delete-menu-item-container"
          onClick={onContextMenuClick}
          data={{ action: "Delete" }}
        >
          <ListContextMenuDeleteIcon />
          Delete
        </MenuItem>
      )}
      {enableViewColumnMenuItem && (
        <MenuItem onClick={onContextMenuClick} data={{ action: "ViewColumn" }}>
          <ListContextMenuViewColumnIcon />
          View Columns
        </MenuItem>
      )}
      {enableSettingItem && (
        <MenuItem onClick={onContextMenuClick} data={{ action: "Setting" }}>
          <ListContextMenuEditIcon />
          Edit
        </MenuItem>
      )}
    </ContextMenu>
  );
}

export default ListContextMenu;
