import React, { useState } from "react";
import { Menu, Dropdown, Modal, Button } from "antd";

function DeleteChartDialog(props) {
  const { onDeleteConfirmed } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleConfirm = () => {
    onDeleteConfirmed();
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div onClick={showModal}>Delete chart</div>
      <Modal
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" danger={true} onClick={handleConfirm}>
            Delete
          </Button>,
        ]}
        title="Delete chart"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        This operation will apply for everyone who views this dashboard.
      </Modal>
    </>
  );
}

function DashboardChartPopupMenu(props) {
  const { onDelete } = props;

  const menu = (
    <Menu
      className="lightup-popup-menu"
      style={{
        width: 146,
      }}
    >
      <Menu.Item>
        <DeleteChartDialog
          onDeleteConfirmed={() => {
            onDelete && onDelete();
          }}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <rect x="0.25" width="36" height="36" rx="4" fill="#F9F9F9" />
        <path
          d="M20.7812 18.25C20.7812 16.8789 19.6211 15.7188 18.25 15.7188C16.8438 15.7188 15.7188 16.8789 15.7188 18.25C15.7188 19.6562 16.8438 20.7812 18.25 20.7812C19.6211 20.7812 20.7812 19.6562 20.7812 18.25ZM24.4375 15.7188C23.0312 15.7188 21.9062 16.8789 21.9062 18.25C21.9062 19.6562 23.0312 20.7812 24.4375 20.7812C25.8086 20.7812 26.9688 19.6562 26.9688 18.25C26.9688 16.8789 25.8086 15.7188 24.4375 15.7188ZM12.0625 15.7188C10.6562 15.7188 9.53125 16.8789 9.53125 18.25C9.53125 19.6562 10.6562 20.7812 12.0625 20.7812C13.4336 20.7812 14.5938 19.6562 14.5938 18.25C14.5938 16.8789 13.4336 15.7188 12.0625 15.7188Z"
          fill="#525252"
        />
      </svg>
    </Dropdown>
  );
}

export default DashboardChartPopupMenu;
