import { SET_TAG_LIST } from "../../actions/tag/tag-action-types";

const initialTagState = {
  tagList: [],
};

export default function tagReducer(state = initialTagState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_TAG_LIST:
      newState = { ...state, tagList: action.tagList };
      return newState;

    default:
      return state;
  }
}
