import React, { useEffect } from "react";
import TimeOption from "../../../time-option/time-option";
import RuleConfigCustomAggressiveEditPopup from "./rule-config-custom-aggressive-edit-popup";
import ConfigDirectionSelector from "./config-direction-selector";
import {
  getDisplayTimeFromSecond,
  getSecondFromDisplayTime,
} from "../../../../utils/time";
import { SymptomTypeConst } from "../../../../utils/enums";
import LegacyModalDialogAdapter from "../../../legacy-adapter/legacy-modal-dialog-adapter";
import ModalConfirmationButtons from "../../../modal-dialog/modal-confirmation-buttons";
import { ModalFieldGroup } from "../../../modal-dialog/modal-field-group";
import { Progress } from "antd";
import { NextGenPalette } from "../../../../utils/color";
import NgButton, { TriggerButton, ButtonPaddingSize } from "../../../button/ng-button";
import { MinusOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";

import "./rule-training-detection-setting-dialog.scss";

const driftDurationOptions = [
  { value: "s", label: "second" },
  { value: "m", label: "minute" },
  { value: "h", label: "hour" },
  { value: "d", label: "day" },
  { value: "w", label: "week" },
];

const recoveryDurationOptions = [
  { value: "s", label: "second" },
  { value: "m", label: "minute" },
  { value: "h", label: "hour" },
  { value: "d", label: "day" },
  { value: "w", label: "week" },
  { value: "mo", label: "month" },
];

function RuleTrainingDetectionSettingDialogTriggerComponent({ onClick }) {
  return (
    <div className="rule-training-detection-setting-dialog-trigger-component-container">
      <TriggerButton onClick={onClick}>
        <EditOutlined />
      </TriggerButton>
    </div>
  );
}

function RuleConfigAggressiveEditView(props) {
  const { aggressiveness, onChange } = props;

  let aggressivenessDisplayValue = "";
  let aggressivenessLevel = 0;
  if (aggressiveness === undefined) {
    aggressivenessDisplayValue = "Automatic";
  } else if (typeof aggressiveness.level === "number") {
    aggressivenessLevel = aggressiveness.level;
    aggressivenessDisplayValue = `Level ${aggressiveness.level}`;
  } else {
    aggressivenessDisplayValue = `Override: ${aggressiveness.override || 0}`;
  }

  function onAggressiveChange(newLevel) {
    if (newLevel < 1 || newLevel > 10) {
      return;
    }

    onChange({ level: newLevel });
  }

  return (
    <div className="rule-aggressive-edit-view-container">
      <ModalFieldGroup label="Aggressiveness">
        <div className="rule-aggressive-edit-display-value-parent-container">
          <div className="rule-aggressive-edit-display-value-container">
            {aggressivenessDisplayValue}
          </div>
          <div className="rule-config-custom-aggressive-edit-popup-parent-container">
            <RuleConfigCustomAggressiveEditPopup
              aggressiveness={aggressiveness}
              onChange={(newAggressiveness) => {
                onChange(newAggressiveness);
              }}
            />
          </div>
        </div>
        <div className="rule-aggressive-edit-view-level">
          <NgButton
            outline
            paddingSize={ButtonPaddingSize.SMALL}
            onClick={() => {
              onAggressiveChange(aggressivenessLevel - 1);
            }}
          >
            <MinusOutlined />
          </NgButton>
          <Progress
            percent={(aggressivenessLevel / 10) * 100}
            showInfo={false}
            strokeColor={NextGenPalette.purple}
          />
          <NgButton
            outline
            paddingSize={ButtonPaddingSize.SMALL}
            onClick={() => {
              onAggressiveChange(aggressivenessLevel + 1);
            }}
          >
            <PlusOutlined />
          </NgButton>
        </div>
      </ModalFieldGroup>
    </div>
  );
}

function RuleTrainingDetectionSettingDialogBodyComponent(props) {
  const { value, symptomType, onChange } = props;

  const { driftDuration, recoveryDuration, aggressiveness, direction } = value;

  function onDriftDurationChange(newDriftDurationStr) {
    const newDriftDuration = getSecondFromDisplayTime(newDriftDurationStr);
    if (newDriftDuration !== driftDuration) {
      onChange({ ...value, driftDuration: newDriftDuration });
    }
  }

  function onRecoveryDurationChange(newRecoveryDurationStr) {
    const newRecoveryDuration = getSecondFromDisplayTime(newRecoveryDurationStr);
    if (newRecoveryDuration !== recoveryDuration) {
      onChange({ ...value, recoveryDuration: newRecoveryDuration });
    }
  }

  function onAggressivenessChange(newAggressiveness) {
    if (newAggressiveness !== aggressiveness) {
      onChange({ ...value, aggressiveness: newAggressiveness });
    }
  }

  function onDirectionChange(newDirection) {
    if (newDirection !== direction) {
      onChange({ ...value, direction: newDirection });
    }
  }

  return (
    <div className="rule-training-detection-setting-dialog-body-container">
      {symptomType !== SymptomTypeConst.SLOW_BURN_TREND_CHANGE && (
        <ModalFieldGroup label="Drift Duration">
          <TimeOption
            layout="split"
            value={
              driftDuration === undefined
                ? undefined
                : getDisplayTimeFromSecond(driftDuration, true, [
                    "s",
                    "m",
                    "h",
                    "d",
                    "w",
                  ])
            }
            onChange={onDriftDurationChange}
            options={driftDurationOptions}
          />
        </ModalFieldGroup>
      )}
      {symptomType !== SymptomTypeConst.SLOW_BURN_TREND_CHANGE && (
        <ModalFieldGroup label="Recovery Duration">
          <TimeOption
            layout="split"
            value={
              recoveryDuration === undefined
                ? undefined
                : getDisplayTimeFromSecond(recoveryDuration, true, [
                    "s",
                    "m",
                    "h",
                    "d",
                    "w",
                  ])
            }
            onChange={onRecoveryDurationChange}
            options={recoveryDurationOptions}
          />
        </ModalFieldGroup>
      )}
      {symptomType !== SymptomTypeConst.MANUAL_THRESHOLD && (
        <RuleConfigAggressiveEditView
          aggressiveness={aggressiveness}
          onChange={onAggressivenessChange}
        />
      )}
      {symptomType !== SymptomTypeConst.MANUAL_THRESHOLD && (
        <ModalFieldGroup
          label={`Set direction for drift detection${!direction ? ": Automatic" : ""}`}
        >
          <ConfigDirectionSelector value={direction} onChange={onDirectionChange} />
        </ModalFieldGroup>
      )}
    </div>
  );
}

function RuleTrainingDetectionSettingDialogFooterComponent(props) {
  const { onCancelClicked, onApplyClicked } = props;

  return (
    <ModalConfirmationButtons
      onOkClick={onApplyClicked}
      onCancelClick={onCancelClicked}
    />
  );
}

function RuleTrainingDetectionDialogComponent(props) {
  const { value: defaultValue, symptomType, onApply } = props;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [detectionSettingValue, setDetectionSettingValue] =
    React.useState(defaultValue);

  useEffect(() => {
    setDetectionSettingValue(defaultValue);
  }, [defaultValue]);

  function onCancelClicked() {
    setDetectionSettingValue(defaultValue);
    setIsOpen(false);
  }

  function onApplyClicked() {
    if (onApply) {
      const changedValue = {};
      const keys = ["driftDuration", "recoveryDuration", "aggressiveness", "direction"];
      for (let key of keys) {
        if (defaultValue[key] !== detectionSettingValue[key]) {
          if (
            key === "aggressiveness" &&
            typeof detectionSettingValue[key].override === "string"
          ) {
            changedValue[key] = {
              override: parseFloat(detectionSettingValue[key].override.trim()),
            };
          } else {
            changedValue[key] = detectionSettingValue[key];
          }
        }
      }
      onApply(changedValue);
    }
    setIsOpen(false);
  }

  return (
    <LegacyModalDialogAdapter
      title={"Detection Setting"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"rule-training-detection-setting-dialog-container"}
      triggerComponent={<RuleTrainingDetectionSettingDialogTriggerComponent />}
      contentComponent={
        <RuleTrainingDetectionSettingDialogBodyComponent
          symptomType={symptomType}
          value={detectionSettingValue}
          onChange={setDetectionSettingValue}
        />
      }
      footerComponent={
        <RuleTrainingDetectionSettingDialogFooterComponent
          onCancelClicked={onCancelClicked}
          onApplyClicked={onApplyClicked}
        />
      }
    />
  );
}

export default RuleTrainingDetectionDialogComponent;
