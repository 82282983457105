import React from "react";

export function IDIcon(props = {}) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 2.33333C1.75 2.01117 2.01117 1.75 2.33333 1.75H7.58333C7.73804 1.75 7.88642 1.81146 7.99581 1.92085L12.713 6.63806C13.3463 7.27512 13.3463 8.30402 12.713 8.94108L12.7118 8.9423L8.94779 12.7063C8.64145 13.0129 8.22557 13.1853 7.7922 13.1853C7.35868 13.1853 6.943 13.013 6.63663 12.7063L1.92108 7.99604C1.81155 7.88663 1.75 7.73816 1.75 7.58333V2.33333ZM2.91667 2.91667V7.34152L7.46201 11.8818C7.54957 11.9695 7.66833 12.0187 7.7922 12.0187C7.91608 12.0187 8.03485 11.9694 8.12235 11.8818L11.8856 8.11857L11.8861 8.11804C12.0664 7.93617 12.0664 7.64297 11.8861 7.4611L11.8856 7.46058L7.34171 2.91667H2.91667Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39583 6.125C5.79854 6.125 6.125 5.79854 6.125 5.39583C6.125 4.99313 5.79854 4.66667 5.39583 4.66667C4.99313 4.66667 4.66667 4.99313 4.66667 5.39583C4.66667 5.79854 4.99313 6.125 5.39583 6.125Z"
        fill="#121224"
      />
    </svg>
  );
}
