import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import queryString from "query-string";
import { subWeeks } from "date-fns";

export function getDefaultUserSetting(loadingTime = new Date()) {
  return {
    time: {
      currentInterval: "1w",
      startTime: subWeeks(loadingTime, 1),
      endTime: loadingTime,
      current: loadingTime,
    },
    sortBy: "incidents",
    sortByAsc: false,
    customerOptions: {
      rejected: true,
      showRuleWithIncidentsOnly: true,
    },
    currentPage: 0,
    pageSize: 20,
    localFilterSetting: {
      dataSourceName: [],
      filterName: [],
      severity: [],
      kpiName: [],
      tableName: [],
      columnName: [],
      slice: [],
      tagName: [],
      direction: [],
      status: [],
      validationStatus: [],
      showMyRule: false,
      showProceededDataOnly: true,
    },
  };
}

export function getQueryStringFromUserSetting(userSetting) {
  const flattedUserSetting = {};
  flattedUserSetting["interval"] = userSetting.time.currentInterval;
  flattedUserSetting["startTime"] = getUnixTime(userSetting.time.startTime);
  flattedUserSetting["endTime"] = getUnixTime(userSetting.time.endTime);
  flattedUserSetting["current"] = getUnixTime(userSetting.time.current);
  flattedUserSetting["sortBy"] = userSetting.sortBy;
  flattedUserSetting["sortByAsc"] = userSetting.sortByAsc;
  flattedUserSetting["rejected"] = userSetting.customerOptions.rejected;
  flattedUserSetting["showRuleWithIncidentsOnly"] =
    userSetting.customerOptions.showRuleWithIncidentsOnly;
  flattedUserSetting["currentPage"] = userSetting.currentPage;
  flattedUserSetting["pageSize"] = userSetting.pageSize;
  flattedUserSetting["localFilterSetting"] = encodeURIComponent(
    JSON.stringify(userSetting.localFilterSetting)
  );

  const keyValueArray = [];
  for (let key in flattedUserSetting) {
    keyValueArray.push(`${key}=${flattedUserSetting[key]}`);
  }

  const queryStr = keyValueArray.join("&");
  return `?${queryStr}`;
}

export function getUserSettingFromQueryString(baseUserSetting, queryStr, opts = {}) {
  const {
    interval,
    startTime,
    endTime,
    current,
    sortBy,
    sortByAsc,
    rejected,
    showRuleWithIncidentsOnly,
    currentPage,
    pageSize,
    localFilterSetting,
  } = queryString.parse(queryStr);

  const customUserSetting = {};
  // Todo: Add more user input parameters validation.
  // Time section
  const timeSection = { ...baseUserSetting.time };
  if (interval) {
    timeSection["currentInterval"] = interval;
  }

  if (startTime) {
    timeSection["startTime"] = fromUnixTime(parseInt(startTime));
  }

  if (endTime) {
    timeSection["endTime"] = fromUnixTime(parseInt(endTime));
  }

  if (current) {
    timeSection["current"] = fromUnixTime(parseInt(current));
  }

  if (Object.keys(timeSection).length > 0) {
    customUserSetting["time"] = timeSection;
  }

  // SortBy section
  if (sortBy) {
    customUserSetting["sortBy"] = sortBy;
  } else {
    customUserSetting["sortBy"] = baseUserSetting.sortBy;
  }

  // SortByAsc section
  customUserSetting["sortByAsc"] = sortByAsc === "true";

  // Customer section
  const customerOptionsSection = { ...baseUserSetting.customerOptions };
  if (rejected) {
    customerOptionsSection["rejected"] = rejected === "true";
  }

  if (showRuleWithIncidentsOnly) {
    customerOptionsSection["showRuleWithIncidentsOnly"] =
      showRuleWithIncidentsOnly === "true";
  }

  if (Object.keys(customerOptionsSection).length > 0) {
    customUserSetting["customerOptions"] = customerOptionsSection;
  }

  // pagination
  if (!isNaN(parseInt(currentPage))) {
    customUserSetting["currentPage"] = parseInt(currentPage);
  } else {
    customUserSetting["currentPage"] = baseUserSetting["currentPage"];
  }

  if (!isNaN(parseInt(pageSize))) {
    customUserSetting["pageSize"] = parseInt(pageSize);
  } else {
    customUserSetting["pageSize"] = baseUserSetting["pageSize"];
  }

  // localFilterSetting
  customUserSetting["localFilterSetting"] = baseUserSetting["localFilterSetting"];
  if (localFilterSetting) {
    try {
      customUserSetting["localFilterSetting"] = JSON.parse(localFilterSetting);
      if (opts.compatibilityLocalFilterSetting) {
        // This part section is used to implement backward compatibility if required
        for (const key in opts.compatibilityLocalFilterSetting) {
          if (key in customUserSetting["localFilterSetting"]) {
            customUserSetting["localFilterSetting"][key] =
              opts.compatibilityLocalFilterSetting[key](
                customUserSetting["localFilterSetting"][key]
              );
          }
        }
      }
    } catch (err) {
      console.log(`Fail to default local filter setting due to ${err}`);
    }
  }
  return customUserSetting;
}
