import React from "react";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import { treeNodeMetricPermissions } from "../../views/profiler/tree-data";
import { NgTableClickableText } from "../table/ng-table";
import NgDropdownMenu from "../ng-dropdown-menu";
import { ViewInExplorerIcon } from "../../views/profiler/menu-icons";
import { explorerMetricUrl } from "../../views/profiler/utils";
import { AppPermissions } from "../../utils/permissions";
import { EditOutlined } from "@ant-design/icons";
import { EVENT, getMetricDetailProps, trackEvent } from "../../utils/telemetry";

function canViewMetricData(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, treeNodeMetricPermissions);
}

function canModifyMetric(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
  ]);
}

function canViewMetric(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
  ]);
}

function MetricLinkMenu(props) {
  const { metric, trigger, workspaceUserPermissions, history } = props;

  const menuItems = [];

  const canModify = canModifyMetric(workspaceUserPermissions);
  const canView = canViewMetric(workspaceUserPermissions);

  if (canModify || canView) {
    menuItems.push({
      label: canModify ? "Edit metric" : "View metric",
      icon: <EditOutlined />,
      onClick: () => {
        trackEvent(canModify ? EVENT.EDIT_METRIC : EVENT.VIEW_METRIC, {
          ...getMetricDetailProps(metric),
        });

        history.push(
          getURIInstance(URIPath.EDIT_METRIC, {
            workspaceUuid: metric.metadata.workspaceId,
            id: metric.metadata.uuid,
          })
        );
      },
    });
  }

  if (canView) {
    menuItems.push({
      label: "View in explorer",
      icon: <ViewInExplorerIcon />,
      onClick: () => {
        trackEvent(EVENT.VIEW_METRIC_IN_EXPLORER, {
          ...getMetricDetailProps(metric),
        });

        history.push(explorerMetricUrl(metric));
      },
    });
  }

  return (
    <NgDropdownMenu trigger={trigger} menuItems={menuItems} position="bottomLeft" />
  );
}

function MetricLinkCell(props) {
  const { metric, workspaceUserPermissions, history } = props;
  if (
    canViewMetricData(workspaceUserPermissions) ||
    canModifyMetric(workspaceUserPermissions)
  ) {
    const trigger = <NgTableClickableText>{metric.metadata.name}</NgTableClickableText>;
    return (
      <MetricLinkMenu
        metric={metric}
        trigger={trigger}
        workspaceUserPermissions={workspaceUserPermissions}
        history={history}
      />
    );
  } else {
    return metric.metadata.name;
  }
}

export default MetricLinkCell;
