import React from "react";
import { ProfilerAfterTreeNavigationActionType } from "../../../actions/profiler/profiler-action";
import { isTriggeredCollectionMode } from "../../../utils/general";
import { isVirtualTableEnabled } from "../../../utils/datasource";
import { AppPermissions } from "../../../utils/permissions";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  getTableDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import { URIPath, getURIInstance, hasPermission } from "../../../utils/uri-path";
import ProfilerAdvancedSettingMenu from "../profiler-advanced-setting-menu";
import { ProfilerTreeNodeType } from "../tree-data";

export const treeMenuNodeTypes = [
  ProfilerTreeNodeType.DATASOURCE,
  ProfilerTreeNodeType.SCHEMA,
  ProfilerTreeNodeType.TABLE,
  ProfilerTreeNodeType.COLUMN,
];

// Permissions required to open the menu. Individual menus may have additional restrictions
// on actions.
export const treeMenuOpenPermissions = {
  [ProfilerTreeNodeType.SCHEMA]: [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
  ],
  [ProfilerTreeNodeType.TABLE]: [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
  ],
  [ProfilerTreeNodeType.COLUMN]: [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
  ],
};

function dropdownMenuProps(args) {
  const { isMenuOpen, setIsMenuOpen } = args;
  return {
    position: "bottomLeft",
    getPopupContainer: () => document.body,
    visible: isMenuOpen,
    onVisibleChange: setIsMenuOpen,
  };
}

export function DataSourceActionsMenu(props) {
  const {
    dataSource,
    trigger,
    workspaceUuid,
    workspaceUserPermissions,
    history,
    isMenuOpen,
    setIsMenuOpen,
    onCreateCustomMetric: propsOnCreateCustomMetric,
    setProfilerAfterTreeNavigationAction,
  } = props;

  const canEditDataSourceDetail = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
  ]);

  const canViewDataSourceDetail = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
  ]);

  const dataSourceDetailProps = {
    ...getDatasourceDetailProps(dataSource),
    page: PAGE.EXPLORER,
  };

  function onGoToSettingClicked() {
    if (!dataSource) {
      return;
    }
    trackEvent(EVENT.EDIT_DATASOURCE, dataSourceDetailProps);
    history.push(
      getURIInstance(URIPath.EDIT_DATA_SOURCE, {
        workspaceUuid,
        id: dataSource.metadata.uuid,
      })
    );
  }

  function onCreateCustomMetric() {
    trackEvent(EVENT.CREATE_METRIC, dataSourceDetailProps);
    propsOnCreateCustomMetric(dataSource, null, null, null);
  }

  function onMonitorAll() {
    setIsMenuOpen(false);
    setProfilerAfterTreeNavigationAction({
      type: ProfilerAfterTreeNavigationActionType.DATASOURCE_MONITOR_ALL_METRICS,
      dataSource,
    });
  }

  return (
    <ProfilerAdvancedSettingMenu
      trigger={trigger}
      editProfileMsg={`${canEditDataSourceDetail ? "Edit" : "View"} datasource`}
      onEditProfileMsg="Edit datasource profile"
      manageMetricsMsg="Manage schemas"
      onEditProfile={canViewDataSourceDetail ? onGoToSettingClicked : null}
      onMonitorAll={onMonitorAll}
      createCustomMetricMsg="Create metric"
      onCreateCustomMetric={onCreateCustomMetric}
      dropdownMenuProps={dropdownMenuProps({ isMenuOpen, setIsMenuOpen })}
      workspaceUserPermissions={workspaceUserPermissions}
    />
  );
}

export function SchemaActionsMenu(props) {
  const {
    dataSource,
    schemaInfo,
    trigger,
    workspaceUserPermissions,
    isMenuOpen,
    setIsMenuOpen,
    onCreateTable: propsOnCreateTable,
    onCreateCustomMetric: propsOnCreateCustomMetric,
    setProfilerAfterTreeNavigationAction,
  } = props;

  function onCreateCustomMetric() {
    propsOnCreateCustomMetric(dataSource, schemaInfo, null, null);
  }

  function onMonitorAll() {
    setIsMenuOpen(false);
    setProfilerAfterTreeNavigationAction({
      type: ProfilerAfterTreeNavigationActionType.SCHEMA_MONITOR_ALL_METRICS,
      dataSource,
      schemaInfo,
    });
  }

  const canCreateTable = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_TABLEPROFILELISTVIEW,
  ]);

  function onCreateTable(newVirtualTable) {
    setIsMenuOpen(false);
    propsOnCreateTable(dataSource, schemaInfo);
  }

  return (
    <ProfilerAdvancedSettingMenu
      trigger={trigger}
      manageMetricsMsg="Manage tables"
      onMonitorAll={onMonitorAll}
      onCreateTable={
        canCreateTable &&
        isVirtualTableEnabled(dataSource.config.connection.type) &&
        onCreateTable
      }
      onCreateCustomMetric={onCreateCustomMetric}
      dropdownMenuProps={dropdownMenuProps({ isMenuOpen, setIsMenuOpen })}
      workspaceUserPermissions={workspaceUserPermissions}
    />
  );
}

export function TableActionsMenu(props) {
  const {
    dataSource,
    schemaInfo,
    tableInfo,
    trigger,
    workspaceUserPermissions,
    isMenuOpen,
    setIsMenuOpen,
    onCreateCustomMetric: propsOnCreateCustomMetric,
    onEditTable: propsOnEditTable,
    setProfilerAfterTreeNavigationAction,
  } = props;

  function onCreateCustomMetric() {
    trackEvent(EVENT.CREATE_METRIC, {
      ...getTableDetailProps(tableInfo, schemaInfo, dataSource),
      page: PAGE.EXPLORER_TABLE,
    });
    propsOnCreateCustomMetric(dataSource, schemaInfo, tableInfo, null);
  }

  function onMonitorAll() {
    setIsMenuOpen(false);
    setProfilerAfterTreeNavigationAction({
      type: ProfilerAfterTreeNavigationActionType.TABLE_MONITOR_ALL_METRICS,
      dataSource,
      schemaInfo,
      tableInfo,
    });
  }

  function onOpenTableConfig() {
    setIsMenuOpen(false);
    setProfilerAfterTreeNavigationAction({
      type: ProfilerAfterTreeNavigationActionType.TABLE_OPEN_CONFIG,
      dataSource,
      schemaInfo,
      tableInfo,
    });
  }

  function onTriggerAll() {
    setIsMenuOpen(false);
    setProfilerAfterTreeNavigationAction({
      type: ProfilerAfterTreeNavigationActionType.TABLE_TRIGGER_ALL_METRICS,
      dataSource,
      schemaInfo,
      tableInfo,
    });
  }

  const canViewTable = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEPROFILEDETAILVIEW,
  ]);

  function onEditTable() {
    setIsMenuOpen(false);
    propsOnEditTable(dataSource, schemaInfo, tableInfo);
  }

  const metricsConfigured = tableInfo.profilerConfig?.enabled;
  const isUserDefinedTable = tableInfo.isUserDefined;

  return (
    <ProfilerAdvancedSettingMenu
      trigger={trigger}
      onMonitorAll={onMonitorAll}
      onTriggerAll={
        isTriggeredCollectionMode(tableInfo.profilerConfig?.collectionMode) &&
        onTriggerAll
      }
      configMsg="Configure table"
      manageMetricsMsg="Manage columns"
      onOpenConfig={onOpenTableConfig}
      createCustomMetricMsg="Create metric"
      onCreateCustomMetric={onCreateCustomMetric}
      onEditTable={canViewTable && isUserDefinedTable && onEditTable}
      workspaceUserPermissions={workspaceUserPermissions}
      disableCreateMetric={!metricsConfigured}
      dropdownMenuProps={dropdownMenuProps({ isMenuOpen, setIsMenuOpen })}
    />
  );
}

export function ColumnActionsMenu(props) {
  const {
    dataSource,
    schemaInfo,
    tableInfo,
    columnInfo,
    trigger,
    workspaceUserPermissions,
    isMenuOpen,
    setIsMenuOpen,
    onCreateCustomMetric: propsOnCreateCustomMetric,
    setProfilerAfterTreeNavigationAction,
  } = props;

  function onCreateCustomMetric() {
    propsOnCreateCustomMetric(dataSource, schemaInfo, tableInfo, columnInfo);
  }

  function onMonitorAll() {
    setIsMenuOpen(false);
    setProfilerAfterTreeNavigationAction({
      type: ProfilerAfterTreeNavigationActionType.COLUMN_MONITOR_ALL_METRICS,
      dataSource,
      schemaInfo,
      tableInfo,
      columnInfo,
    });
  }

  const metricsConfigured =
    tableInfo.profilerConfig?.enabled && columnInfo.profilerConfig?.enabled;

  return (
    <ProfilerAdvancedSettingMenu
      trigger={trigger}
      createCustomMetricMsg="Create metric"
      onMonitorAll={onMonitorAll}
      onCreateCustomMetric={onCreateCustomMetric}
      workspaceUserPermissions={workspaceUserPermissions}
      disableCreateMetric={!metricsConfigured}
      dropdownMenuProps={dropdownMenuProps({ isMenuOpen, setIsMenuOpen })}
    />
  );
}
