import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { removeKeys } from "../../utils/remove-keys";
import { diff as jsonDiff } from "json-diff";

function diffWithIgnoreKeys(objA, objB, ignoreKeys = []) {
  if (ignoreKeys.length === 0) {
    return jsonDiff(objA, objB);
  }
  const cleanObjA = removeKeys(objA, ignoreKeys);
  const cleanObjB = removeKeys(objB, ignoreKeys);
  return jsonDiff(cleanObjA, cleanObjB);
}

export default function useBatchUpdate({
  entityList,
  getEntityKey,
  entityUpdate,
  ignoreKeys = [],
}) {
  const [currentEntityList, setCurrentEntityList] = useState(entityList);
  const entityDiffMap = useRef({});

  useEffect(() => {
    entityDiffMap.current = {};
    setCurrentEntityList(entityList);
  }, [entityList]);

  const toggleAction = useCallback(
    (entityKeys, payload) => {
      setCurrentEntityList((prevEntityList) =>
        prevEntityList.map((entity, index) => {
          const entityKey = getEntityKey(entity);
          if (entityKeys.includes(entityKey)) {
            const updatedEntity = entityUpdate(entity, payload);
            if (diffWithIgnoreKeys(entityList[index], updatedEntity, ignoreKeys)) {
              entityDiffMap.current[entityKey] = index;
            } else {
              delete entityDiffMap.current[entityKey];
            }
            return updatedEntity;
          }
          return entity;
        })
      );
    },
    [setCurrentEntityList, getEntityKey, entityUpdate, entityList, ignoreKeys]
  );

  const batchList = useMemo(() => {
    const result = [];
    for (const entityIndex of Object.values(entityDiffMap.current)) {
      result.push(
        ignoreKeys.length > 0
          ? removeKeys(currentEntityList[entityIndex], ignoreKeys)
          : currentEntityList[entityIndex]
      );
    }
    return result;
  }, [currentEntityList, ignoreKeys]);

  const reset = useCallback(() => {
    entityDiffMap.current = {};
    setCurrentEntityList(entityList);
  }, [setCurrentEntityList, entityList]);
  const isDirty = batchList.length > 0;

  return {
    currentEntityList,
    batchList,
    toggleAction,
    reset,
    isDirty,
  };
}
