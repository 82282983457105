import React from "react";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { getDropdownItems } from "../../components/entity-list/menu-utils";

export const IntegrationActions = Object.freeze({
  VIEW_EDIT: "viewEdit",
  DELETE: "delete",
});

function IntegrationActionsMenu(props) {
  const {
    trigger,
    workspaceUuid,
    workspaceUserPermissions,
    integrations,
    onEditIntegrationClick,
    onDeleteIntegrationClick,
    loading = false,
    getPopupContainer,
  } = props;

  const viewIntegrationPermissions = [
    AppPermissions.BACKEND_APPS_ALERTING_CHANNEL_VIEWS_VIEW_ALERTINGCHANNELDETAIL,
  ];

  const modifyIntegrationPermissions = [
    AppPermissions.BACKEND_APPS_ALERTING_CHANNEL_VIEWS_EDIT_ALERTINGCHANNELDETAIL,
  ];

  const canModifyIntegration = hasPermission(
    workspaceUserPermissions,
    modifyIntegrationPermissions
  );

  const menuConfig = [
    {
      action: IntegrationActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewIntegrationPermissions,
      item: {
        label: canModifyIntegration ? "Edit" : "View",
        icon: <EditOutlined />,
        onClick: () => onEditIntegrationClick(workspaceUuid, integrations[0]),
      },
    },
    {
      action: IntegrationActions.DELETE,
      supportsMultiple: false,
      permissions: modifyIntegrationPermissions,
      item: {
        label: "Delete",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => onDeleteIntegrationClick(workspaceUuid, integrations[0]),
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    integrations,
    Object.values(IntegrationActions),
    workspaceUserPermissions
  );

  return (
    <NgDropdownMenu
      trigger={trigger}
      menuItems={menuItems}
      buttonProps={{ disabled: loading }}
      getPopupContainer={getPopupContainer}
      position="bottomLeft"
    />
  );
}

export default IntegrationActionsMenu;
