import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { NgTextTooltip } from "../text-tooltip/ng-text-tooltip";
import { classesName } from "../../utils/css";

import "./ng-tag.scss";

export function NgTag(props) {
  const { label, onRemoveClick, removable = true, disabled = false } = props;
  return (
    <div
      className={classesName(
        "ng-tag",
        removable && !disabled && "removable",
        disabled && "disabled"
      )}
    >
      {removable && (
        <button disabled={disabled}>
          <CloseCircleOutlined onClick={() => onRemoveClick(label)} />
        </button>
      )}
      <div className="ng-tag-label">
        <NgTextTooltip>{label}</NgTextTooltip>
      </div>
    </div>
  );
}
