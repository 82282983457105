import React from "react";
import PasscodeConfirmationModal from "../../components/passcode-confirmation-modal/passcode-confirmation-modal";

function AdminUserDeleteModal(props) {
  const { userName, visible, onDeleteConfirmed, onDeleteCancelled } = props;
  return (
    <PasscodeConfirmationModal
      visible={visible}
      title="Confirm user deletion"
      okText="Delete"
      cancelText="Cancel"
      onConfirm={onDeleteConfirmed}
      onCancel={onDeleteCancelled}
    >
      Are you sure you want to delete {userName} from the system?
    </PasscodeConfirmationModal>
  );
}

export default AdminUserDeleteModal;
