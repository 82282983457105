import { connect } from "react-redux";
import {
  addAppUser,
  addAppUserToWorkspace,
  getAppUserList,
  addWorkspace,
} from "../../actions/admin/admin-action";
import { closeTakeover } from "../../actions/takeover/takeover-action";

import AddWorkspace from "./add-workspace";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  appUserList: state.adminReducer.appUserList,
  workspaceList: state.adminReducer.userWorkspaceList.data,
});

const mapDispatchToProps = (dispatch) => ({
  addWorkspace: (workspace, newUserList) =>
    dispatch(addWorkspace(workspace, newUserList)),
  addAppUser: (newUser) => dispatch(addAppUser(newUser)),
  getAppUserList: () => dispatch(getAppUserList()),
  addAppUserToWorkspace: (workspaceUuid, user) =>
    dispatch(addAppUserToWorkspace(workspaceUuid, user)),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkspace);
