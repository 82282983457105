import React, { useCallback, useEffect, useMemo } from "react";
import {
  ColumnConfigItem,
  DataSourceConfigItem,
  FieldRow,
  SchemaConfigItem,
  TableConfigItem,
} from "./index";
import { getMetricTypeFromConfigData } from "../utils";
import { removeCollectionAttributesFromConfig } from "../metric-utils";
import { getColumnTypeCategory } from "../../../utils/column";
import {
  MetricDataAssetType,
  MetricConfigType,
  TableType,
  MetricCategory,
} from "../../../utils/enums";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { queryParamsRemoved } from "../../../utils/uri-path";
import {
  getDefaultBackfillDuration,
  getMetricConfigTypeFromTableQueryScope,
  isAutoMetric,
} from "../../../utils/metric";
import {
  dataSourceSupportsMetricType,
  isDataSourceStreamingEnabled,
} from "../../../utils/datasource";
import Switch from "../../../atom/switch";

import "./data-asset-select.scss";

function DataAssetSelect(props) {
  const {
    dataAssetType = MetricDataAssetType.TABLE,
    configData = {},
    dataSourceList = [],
    schemaList = [],
    tableList = [],
    columnList = [],
    onConfigDataChange,
    onDataSourceChange: propsOnDataSourceChange,
    onSchemaChange: propsOnSchemaChange,
    onTableChange: propsOnTableChange,
    onColumnChange: propsOnColumnChange,
    onDataSourceChanged,
    onTableChanged,
    columnGetter = null,
    disabled = false,
    allFieldsRequired = true,
    isMultipleValueColumns = false,
    resetKPIStatus,
    location,
    history,
  } = props;

  const dataSourceUuid = configData.config?.sources?.[0];
  const currentDataSource = useMemo(() => {
    if (!dataSourceUuid || dataSourceList.length === 0) {
      return null;
    }

    return dataSourceList.find(
      (currentDataSource) => currentDataSource.metadata.uuid === dataSourceUuid
    );
  }, [dataSourceUuid, dataSourceList]);

  const enableStreamingConfig = useMemo(() => {
    if (!currentDataSource) {
      return false;
    }

    return isDataSourceStreamingEnabled(currentDataSource.config.connection);
  }, [currentDataSource]);

  const defaultOnDataSourceChange = useCallback(
    (newDataSourceUuid) => {
      onConfigDataChange({
        ...configData,
        config: {
          ...configData.config,
          sources: [newDataSourceUuid],
          table: {
            type: TableType.TABLE,
            tableName: null,
          },
          valueColumns: [],
          whereConditions: [],
          whereClause: null,
          sliceByColumns: [],
          sliceValueSelections: [],
          partitions: [],
        },
      });
      resetKPIStatus({
        resetAll: false,
        resetColumnList: true,
        resetColumnValueList: true,
        resetPreviewInfo: true,
      });
      onDataSourceChanged(newDataSourceUuid);
    },
    [configData, onConfigDataChange, resetKPIStatus, onDataSourceChanged]
  );

  const onDataSourceChange = propsOnDataSourceChange ?? defaultOnDataSourceChange;

  const defaultOnSchemaChange = useCallback(
    (newSchemaName) => {
      onConfigDataChange({
        ...configData,
        config: {
          ...configData.config,
          table: {
            type: TableType.TABLE,
            tableUuid: undefined,
            schemaName: newSchemaName,
            tableName: undefined,
          },
          valueColumns: [],
          whereConditions: [],
          whereClause: null,
          sliceByColumns: [],
          sliceValueSelections: [],
          partitions: [],
        },
      });

      resetKPIStatus({
        resetAll: false,
        resetColumnList: true,
        resetColumnValueList: true,
        resetPreviewInfo: true,
      });
    },
    [configData, onConfigDataChange, resetKPIStatus]
  );

  const onSchemaChange = propsOnSchemaChange ?? defaultOnSchemaChange;

  const defaultOnTableChange = useCallback(
    (_newTableUuid, currentTable) => {
      const {
        dataTimezone,
        partitionTimezone,
        timestampColumn,
        timezone: queryTimezone,
        window: aggregationWindow,
        timestampColumnFunctions = null,
        partitions = [],
        partitionOffsets,
        syncDelay: synchronizationDelay, // Todo: figure out what is the right value.
        collectionMode,
        collectionWindow = null,
        queryScope,
        pollingWindow,
        pollingTimezone,
        pollingDelay,
      } = currentTable.profilerConfig;

      const configType = getMetricConfigTypeFromTableQueryScope(queryScope);

      let newConfig = removeCollectionAttributesFromConfig(
        configData.config,
        configType === MetricConfigType.METRIC_CONFIG
      );

      if (configType === MetricConfigType.METRIC_CONFIG) {
        newConfig = {
          ...newConfig,
          configType,
          collectionMode,
          collectionWindow,
          inheritTableSettings: true,
          table: {
            type: TableType.TABLE,
            tableUuid: currentTable.tableUuid,
            schemaName: currentTable.schemaName,
            tableName: currentTable.tableName,
          },
          valueColumns: [],
          whereConditions: [],
          whereClause: null,
          aggregation: {
            ...configData.config.aggregation,
            aggregationWindow,
          },
          backfillDuration: getDefaultBackfillDuration(
            aggregationWindow,
            currentDataSource
          ),
          dataTimezone,
          partitionTimezone,
          partitionOffsets,
          timestampColumn,
          queryTimezone,
          timestampColumnFunctions,
          partitions,
          synchronizationDelay,
        };
      } else {
        newConfig = {
          ...newConfig,
          configType,
          collectionMode,
          collectionWindow: null,
          valueColumns: [],
          inheritTableSettings: true,
          partitions,
          table: {
            type: TableType.TABLE,
            tableUuid: currentTable.tableUuid,
            schemaName: currentTable.schemaName,
            tableName: currentTable.tableName,
          },
          pollingWindow,
          pollingTimezone,
          pollingDelay,
        };
      }

      onConfigDataChange({
        ...configData,
        config: newConfig,
      });

      onTableChanged(configData.config.sources[0], currentTable.tableUuid);
      resetKPIStatus({
        resetAll: false,
        resetPreviewInfo: true,
      });
    },
    [currentDataSource, onConfigDataChange, configData, onTableChanged, resetKPIStatus]
  );

  const onTableChange = propsOnTableChange ?? defaultOnTableChange;

  const defaultOnColumnChange = useCallback(
    (_newColumnUuid, currentColumn) => {
      onConfigDataChange({
        ...configData,
        config: {
          ...configData.config,
          valueColumns: [
            {
              columnName: currentColumn.columnName,
              columnUuid: currentColumn.uuid,
            },
          ],
        },
      });

      resetKPIStatus({
        resetAll: false,
        resetPreviewInfo: true,
      });
    },
    [configData, onConfigDataChange, resetKPIStatus]
  );
  const onColumnChange = propsOnColumnChange ?? defaultOnColumnChange;

  const currentSchemaName = configData.config.table?.schemaName;
  const tableUuid = configData.config.table?.tableUuid;

  function defaultColumnGetter(configData) {
    return configData.config.valueColumns?.length > 0
      ? configData.config.valueColumns[0].columnUuid
      : null;
  }
  const configDataColumnUuid = columnGetter
    ? columnGetter(configData)
    : defaultColumnGetter(configData);
  const columnValueSet = isMultipleValueColumns
    ? configDataColumnUuid.length > 0
    : !!configDataColumnUuid;

  // Populate data asset from query params. The useEffect callback will get invoked
  // at least once per level of the data asset hierarchy. We return after altering the
  // URL so that the function will always have a fresh `location`.
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.dataSourceUuid && !dataSourceUuid) {
      onDataSourceChange(queryParams.dataSourceUuid);
      history.replace(
        queryParamsRemoved(location, ["dataSourceUuid", "dataSourceName"])
      );
      return;
    }
    if (queryParams.schemaUuid && !currentSchemaName) {
      const newSchema = schemaList.find(
        (schema) => schema.uuid === queryParams.schemaUuid
      );
      if (newSchema) {
        onSchemaChange(newSchema.name);
        history.replace(queryParamsRemoved(location, ["schemaUuid", "schemaName"]));
        return;
      }
    }
    if (queryParams.tableUuid && !tableUuid) {
      const newTable = tableList.find(
        (table) => table.tableUuid === queryParams.tableUuid
      );
      if (newTable) {
        onTableChange(queryParams.tableUuid, newTable);
        history.replace(queryParamsRemoved(location, ["tableUuid", "tableName"]));
        return;
      }
    }
    if (queryParams.columnUuid && dataAssetType === MetricDataAssetType.TABLE) {
      history.replace(queryParamsRemoved(location, ["columnUuid", "columnName"]));
    } else if (queryParams.columnUuid && !columnValueSet) {
      const newColumn = columnList.find(
        (column) => column.uuid === queryParams.columnUuid
      );
      if (newColumn) {
        const columnValue = isMultipleValueColumns ? [newColumn] : newColumn;
        onColumnChange(queryParams.columnUuid, columnValue);
        history.replace(queryParamsRemoved(location, ["columnUuid", "columnName"]));
      }
    }
  }, [
    dataAssetType,
    dataSourceUuid,
    currentSchemaName,
    tableUuid,
    configDataColumnUuid,
    schemaList,
    tableList,
    columnList,
    columnValueSet,
    isMultipleValueColumns,
    onDataSourceChange,
    onSchemaChange,
    onTableChange,
    onColumnChange,
    history,
    location,
  ]);

  const normalizedTableList = useMemo(() => {
    if (!currentSchemaName) {
      return [];
    }

    const isAutoMetricType = isAutoMetric(configData);

    return tableList.filter((currentTable) => {
      return (
        currentTable.schemaName === currentSchemaName &&
        (!currentTable.isUserDefined || isAutoMetricType) // allow virtual tables only for auto metrics
      );
    });
  }, [tableList, currentSchemaName, configData]);

  const normalizedColumnList = useMemo(() => {
    const metricType = getMetricTypeFromConfigData(configData);
    const dataSourceUuid = configData.config?.sources?.[0];
    const dataSource = dataSourceList.find(
      (dataSource) => dataSource.metadata.uuid === dataSourceUuid
    );
    return columnList.filter((column) =>
      dataSourceSupportsMetricType(
        dataSource,
        metricType,
        getColumnTypeCategory(column)
      )
    );
  }, [dataSourceList, columnList, configData]);

  const isTableOrColumnDataAsset =
    dataAssetType === MetricDataAssetType.TABLE ||
    dataAssetType === MetricDataAssetType.COLUMN;

  const isTableActivityMetric =
    getMetricTypeFromConfigData(configData) === MetricCategory.TABLE_ACTIVITY;

  const showSchemaSelect = isTableOrColumnDataAsset;
  const showTableSelect = isTableOrColumnDataAsset && !isTableActivityMetric;
  const showColumnSelect = dataAssetType === MetricDataAssetType.COLUMN;

  return (
    <div className="metric-config-data-asset-select">
      <FieldRow>
        <DataSourceConfigItem
          dataSourceList={dataSourceList}
          value={dataSourceUuid}
          onChange={onDataSourceChange ?? defaultOnDataSourceChange}
          disabled={disabled}
        />
        {showSchemaSelect && (
          <SchemaConfigItem
            schemaList={schemaList}
            value={currentSchemaName}
            onChange={onSchemaChange ?? defaultOnSchemaChange}
            disabled={disabled}
            optional={!allFieldsRequired}
          />
        )}
        {showTableSelect && (
          <TableConfigItem
            tableList={normalizedTableList}
            value={configData.config.table?.tableUuid}
            onChange={onTableChange ?? defaultOnTableChange}
            disabled={disabled}
            optional={!allFieldsRequired && !currentSchemaName}
          />
        )}
        {showColumnSelect && (
          <ColumnConfigItem
            columnList={normalizedColumnList}
            value={configDataColumnUuid}
            onChange={onColumnChange ?? defaultOnColumnChange}
            disabled={disabled}
            optional={!allFieldsRequired}
            multiple={isMultipleValueColumns}
            configData={configData}
          />
        )}
      </FieldRow>
      {enableStreamingConfig && (
        <div className="metric-config-data-asset-tab-streaming-container">
          <div className="metric-config-data-asset-tab-streaming-content-container">
            <span>Enable Streaming</span>
            <Switch
              checked={configData.config.isStreaming}
              onChange={() => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    isStreaming: !configData.config.isStreaming,
                  },
                });
              }}
              disabled={disabled}
            />
          </div>
          <div className="metric-config-data-asset-tab-streaming-description-container">
            If supported by metric and datasource, continuously evaluate metric using
            streaming operators as data arrives
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(DataAssetSelect);
