import axios from "../utils/api";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

export function getEventListPromise(workspaceUuid, queryOptions) {
  return new Promise(function (resolve, reject) {
    const { startTime, endTime, limit, offset } = queryOptions;
    let queryStringArray = [];
    if (startTime !== undefined) {
      queryStringArray.push(`start_ts=${startTime}`);
    }

    if (endTime !== undefined) {
      queryStringArray.push(`end_ts=${endTime}`);
    }

    typeof limit === "number" && queryStringArray.push(`limit=${limit}`);
    typeof offset === "number" && queryStringArray.push(`offset=${offset}`);

    let queryString = "";
    if (queryStringArray.length > 0) {
      queryString = `?${queryStringArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.EVENT_LIST, {
          workspaceUuid,
        })}${queryString}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getEventMonitorListPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.EVENT_MONITOR_LIST, { workspaceUuid })
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateEventMonitorItemPromise(
  workspaceUuid,
  uuid,
  newEventMonitorItem
) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.EVENT_MONITOR_ITEM, {
          workspaceUuid,
          uuid,
        }),
        newEventMonitorItem
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getEventPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.EVENT_ITEM, {
          workspaceUuid,
          uuid,
        })
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
