import React from "react";
import {
  AggregationWindowConfigItem,
  DataSourceConfigItem,
  QueryTimezoneConfigItem,
  SliceByColumnsConfigItem,
  SynchronizationDelayConfigItem,
  TableConfigItem,
  TimestampColumnConfigItem,
  WhereConditionsConfigItem,
  QueryScopeConfigItem,
  DataCollectionScheduleConfigItem,
  PartitionsConfigItem,
  ColumnsToCompareConfigItem,
} from "../../fields/";
import { QueryScope } from "../../../../utils/enums";
import SummaryViewSectionTitle from "../summary-view-section-title";
import { aggregationWindowOptions } from "../../utils";
import { ConfigSummaryCardRow } from "../../summary-card";
import { default as Icon } from "@ant-design/icons/lib/components/Icon";
import { MetricSchemaIcon } from "../../fields/icons";
import { queryScopeOptions } from "../../../../utils/options";
import { isTriggeredCollectionMode } from "../../../../utils/general";

export function isEmptyFullCompareDataAssetSummary(configData) {
  return (
    !configData.config?.sourceTable?.sourceUuid &&
    !configData.config?.targetTable?.sourceUuid
  );
}

export function FullCompareDataAssetSummary(props) {
  const { configData = {}, dataSourceList } = props;

  const {
    config: { sourceTable, targetTable },
  } = configData;

  return (
    <>
      {sourceTable?.sourceUuid && (
        <>
          <SummaryViewSectionTitle title="Source" />
          <DataSourceConfigItem
            dataSourceList={dataSourceList}
            value={sourceTable.sourceUuid}
            isEdit={false}
          />
        </>
      )}
      {sourceTable?.table?.schemaName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricSchemaIcon} />}
          label={sourceTable.table.schemaName}
        />
      )}
      {sourceTable?.table?.tableUuid && (
        <TableConfigItem
          tableList={sourceTable.table ? [sourceTable.table] : []}
          value={sourceTable.table?.tableUuid}
          isEdit={false}
        />
      )}
      {sourceTable?.timestampColumn && (
        <TimestampColumnConfigItem
          value={sourceTable.timestampColumn}
          isEdit={false}
          columnList={[]}
        />
      )}
      {sourceTable?.partitions?.length > 0 && (
        <PartitionsConfigItem
          partitionTimezone={sourceTable.partitionTimezone}
          partitionOffsets={sourceTable.partitionOffsets}
          value={sourceTable.partitions}
          isEdit={false}
        />
      )}
      {configData.config?.targetTable?.sourceUuid && (
        <>
          <SummaryViewSectionTitle title="Target" />
          <DataSourceConfigItem
            dataSourceList={dataSourceList}
            value={targetTable.sourceUuid}
            isEdit={false}
          />
        </>
      )}
      {targetTable?.table?.schemaName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricSchemaIcon} />}
          label={targetTable.table.schemaName}
        />
      )}
      {targetTable?.table?.tableUuid && (
        <TableConfigItem
          tableList={targetTable.table ? [targetTable.table] : []}
          value={targetTable.table?.tableUuid}
          isEdit={false}
        />
      )}
      {targetTable?.timestampColumn && (
        <TimestampColumnConfigItem
          value={targetTable.timestampColumn}
          isEdit={false}
          columnList={[]}
        />
      )}
      {targetTable?.partitions?.length > 0 && (
        <PartitionsConfigItem
          partitionTimezone={targetTable.partitionTimezone}
          partitionOffsets={targetTable.partitionOffsets}
          value={targetTable.partitions}
          isEdit={false}
        />
      )}
    </>
  );
}

export function FullCompareMetricConfigDataAssetSummaryCardContent(props) {
  const { configData } = props;

  const {
    config: {
      collectionMode,
      targetTable,
      aggregationWindow,
      synchronizationDelay,
      queryScope,
      pollingDelay,
      pollingTimezone,
      pollingWindow,
    },
  } = configData;

  const isTimeRange = queryScope === QueryScope.TIME_RANGE;
  return (
    <>
      {Boolean(configData.config.sourceTable?.whereClause) && (
        <>
          <SummaryViewSectionTitle title="Source Where clause" />
          <WhereConditionsConfigItem
            isEdit={false}
            value={configData.config.sourceTable?.whereClause}
          />
        </>
      )}
      {Boolean(configData.config.targetTable?.whereClause) && (
        <>
          <SummaryViewSectionTitle title="Target Where clause" />
          <WhereConditionsConfigItem
            isEdit={false}
            value={configData.config.targetTable?.whereClause}
          />
        </>
      )}
      <SummaryViewSectionTitle title="Key columns to compare" />
      {configData.config.sourceTable?.valueColumns?.length > 0 && (
        <ColumnsToCompareConfigItem configData={configData} fieldName="valueColumns" />
      )}
      {configData.config.sourceTable?.attributeColumns?.length > 0 && (
        <>
          <SummaryViewSectionTitle title="Attribute columns to compare" />
          <ColumnsToCompareConfigItem
            configData={configData}
            fieldName="attributeColumns"
          />
        </>
      )}
      <SummaryViewSectionTitle title="Metric configuration" />
      <DataCollectionScheduleConfigItem value={collectionMode} isEdit={false} />
      <QueryScopeConfigItem
        value={queryScope}
        options={queryScopeOptions}
        isEdit={false}
      />
      {isTimeRange && (
        <>
          <AggregationWindowConfigItem
            value={aggregationWindow}
            options={aggregationWindowOptions}
            isEdit={false}
          />
          <SynchronizationDelayConfigItem value={synchronizationDelay} isEdit={false} />
          <QueryTimezoneConfigItem value={targetTable.queryTimezone} isEdit={false} />
        </>
      )}
      {!isTimeRange && !isTriggeredCollectionMode(collectionMode) && (
        <>
          <SynchronizationDelayConfigItem
            label="Polling delay"
            value={pollingDelay}
            isEdit={false}
          />
          <QueryTimezoneConfigItem
            label="Polling timezone"
            value={pollingTimezone}
            isEdit={false}
          />
          <AggregationWindowConfigItem
            label="Polling interval"
            value={pollingWindow}
            options={aggregationWindowOptions}
            isEdit={false}
          />
        </>
      )}
      {configData.config.sourceTable?.sliceByColumns?.length > 0 && (
        <SliceByColumnsConfigItem
          label="Source slice"
          value={configData.config.sourceTable?.sliceByColumns}
          isEdit={false}
        />
      )}
      {configData.config.targetTable?.sliceByColumns?.length > 0 && (
        <SliceByColumnsConfigItem
          label="Target slice"
          value={configData.config.targetTable?.sliceByColumns}
          isEdit={false}
        />
      )}
    </>
  );
}
