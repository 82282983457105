import React from "react";
import { Popover } from "antd";
import { classesName } from "../../../../utils/css";
import { getDisplayFloatStr } from "../../../../utils/general";
import { NextGenPalette } from "../../../../utils/color";
import { getStringFromTimeStamp } from "../../../../utils/time";
import { TableHealthOverallStatsInfoPopOver } from "./table-health-summary-panel-template";
import { TableMetricHealthyType, TableMetricHealthyTypeToColorMapper } from "./utils";

import "./table-health-summary.scss";

function TableHealthSummeryPercentageDetailItemContainer(props) {
  const { value, type } = props;
  return (
    <div
      className="table-health-summary-percentage-detail-item-container"
      style={{
        width: value,
        height: "100%",
        backgroundColor: TableMetricHealthyTypeToColorMapper[type],
      }}
    />
  );
}

function TableHealthDetailedInfoItemPopoverContent(props) {
  const { totalOfHealthy, totalOfUnHealthy } = props;
  const total = totalOfHealthy + totalOfUnHealthy;
  return (
    <div className="table-health-detailed-info-item-popover-content-container">
      {[
        {
          type: TableMetricHealthyType.HEALTHY,
          label: "Healthy",
          value: getDisplayFloatStr((totalOfHealthy * 100) / total, true),
        },
        {
          type: TableMetricHealthyType.UNHEALTHY,
          label: "Unhealthy",
          value: getDisplayFloatStr((totalOfUnHealthy * 100) / total, true),
        },
      ].map(({ type, label, value }) => (
        <div
          key={type}
          className="table-health-detailed-info-item-popover-content-row-item-container"
        >
          <div
            className="table-health-detailed-info-item-popover-content-row-item-icon-container"
            style={{
              backgroundColor: TableMetricHealthyTypeToColorMapper[type],
              height: "14px",
              width: "14px",
            }}
          />
          <div className="table-health-detailed-info-item-popover-content-row-item-percentage-container">
            {value}
          </div>
          <div className="table-health-detailed-info-item-popover-content-row-item-label-container">
            {label}
          </div>
        </div>
      ))}
    </div>
  );
}

function TableHealthDetailedInfoItemContainer(props) {
  const {
    maxValue,
    totalOfHealthy,
    totalOfUnHealthy,
    title,
    timestamp,
    currentSelectedTimestamp,
    onClick = null,
  } = props;

  const containerClassName = classesName(
    currentSelectedTimestamp === timestamp && "focused",
    "table-health-detailed-info-item-container"
  );

  return (
    <Popover
      color={NextGenPalette.systemBlack}
      content={
        <TableHealthDetailedInfoItemPopoverContent
          totalOfHealthy={totalOfHealthy}
          totalOfUnHealthy={totalOfUnHealthy}
        />
      }
      title=""
    >
      <div className={containerClassName} onClick={() => onClick?.(timestamp)}>
        <div className="table-health-detailed-info-item-bar-chart-container">
          {[
            {
              type: TableMetricHealthyType.HEALTHY,
              value: getDisplayFloatStr((totalOfHealthy * 100) / maxValue, true),
            },
            {
              type: TableMetricHealthyType.UNHEALTHY,
              value: getDisplayFloatStr((totalOfUnHealthy * 100) / maxValue, true),
            },
          ].map(({ type, value }) => (
            <div
              className="table-health-detailed-info-item-bar-chart-item-container"
              key={type}
              style={{
                backgroundColor: TableMetricHealthyTypeToColorMapper[type],
                height: value,
                width: "7px",
              }}
            />
          ))}
        </div>
        <div className="table-health-detailed-info-item-title-container">
          <div className="table-health-detailed-info-item-title-inner">{title}</div>
        </div>
      </div>
    </Popover>
  );
}

function TableHealthSummary(props) {
  const {
    overallSummary,
    dailySummary,
    currentTableHealthData,
    currentTimestamp,
    onCurrentTimestampChange,
  } = props;
  if (!currentTableHealthData) {
    return null;
  }

  const isAnyMetricDefined = overallSummary.totalMetricsCount > 0;

  const isAnyMetricMonitored =
    isAnyMetricDefined &&
    overallSummary.healthyMetricsCount + overallSummary.unhealthyMetricsCount > 0;

  const { healthyMetricsCount, unhealthyMetricsCount, unmonitoredMetricsCount } =
    currentTableHealthData;
  const total = healthyMetricsCount + unhealthyMetricsCount;
  const healthyMetricsPercentage = getDisplayFloatStr(
    (healthyMetricsCount * 100) / total,
    true
  );
  const unhealthyMetricsPercentage = getDisplayFloatStr(
    (unhealthyMetricsCount * 100) / total,
    true
  );

  let maxValue = Math.max(
    overallSummary.healthyMetricsCount,
    overallSummary.unhealthyMetricsCount
  );
  dailySummary.forEach(({ healthyMetricsCount, unhealthyMetricsCount }) => {
    maxValue = Math.max(maxValue, healthyMetricsCount, unhealthyMetricsCount);
  });

  return (
    <div className="table-health-summary-container">
      <div className="table-health-summary-header-container">
        <div className="table-health-summary-header-left-container">
          <div className="table-health-summary-header-title-container">
            <span>Summary</span>
            <span className="table-health-summary-header-title-detail-container">
              <TableHealthOverallStatsInfoPopOver
                data={{
                  healthyMetricsCount,
                  unhealthyMetricsCount,
                  unmonitoredMetricsCount,
                }}
              />
            </span>
          </div>
          {isAnyMetricMonitored ? (
            <div className="table-health-summary-header-detail-percentage-container">
              {`${healthyMetricsPercentage} Healthy`}
            </div>
          ) : (
            <div className="table-health-summary-header-enable-description-container">
              {isAnyMetricDefined
                ? "Monitor metrics to see healthy vs unhealthy metrics"
                : "Enable Auto Metrics or create Table or Column Metrics to generate table health summary"}
            </div>
          )}
        </div>

        {isAnyMetricDefined && (
          <div className="table-health-summary-header-right-container">
            <div className="table-health-summary-header-percentage-container">
              {isAnyMetricMonitored && (
                <div className="table-health-summary-header-percentage-title-container">
                  {getStringFromTimeStamp(currentTimestamp, { monthDay: true })}
                </div>
              )}
              <div className="table-health-summary-header-percentage-detail-container">
                {isAnyMetricMonitored ? (
                  [
                    {
                      rawValue: healthyMetricsCount,
                      value: healthyMetricsPercentage,
                      type: TableMetricHealthyType.HEALTHY,
                    },
                    {
                      rawValue: unhealthyMetricsCount,
                      value: unhealthyMetricsPercentage,
                      type: TableMetricHealthyType.UNHEALTHY,
                    },
                  ].map(({ rawValue, value, type }) => {
                    if (rawValue === 0) {
                      return null;
                    }

                    return (
                      <TableHealthSummeryPercentageDetailItemContainer
                        value={value}
                        type={type}
                        key={type}
                      />
                    );
                  })
                ) : (
                  <TableHealthSummeryPercentageDetailItemContainer
                    value={"100%"}
                    type={TableMetricHealthyType.UNMONITORED}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {isAnyMetricMonitored && (
        <div className="table-health-summary-content-container">
          <div className="table-health-summary-content-detailed-item-info-list-container">
            {dailySummary.map(
              ({ healthyMetricsCount, unhealthyMetricsCount, eventTs }) => (
                <TableHealthDetailedInfoItemContainer
                  totalOfUnHealthy={unhealthyMetricsCount}
                  totalOfHealthy={healthyMetricsCount}
                  maxValue={maxValue}
                  timestamp={eventTs}
                  key={eventTs}
                  currentSelectedTimestamp={currentTimestamp}
                  onClick={onCurrentTimestampChange}
                  title={getStringFromTimeStamp(eventTs, { monthDay: true })}
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default TableHealthSummary;
