import React from "react";
import { classesName } from "../../utils/css";
import { Switch as AntSwitch } from "antd";
import "./switch.scss";

export default function Switch(props) {
  const { className = "", label, labelPosition = "left", testId, ...restProps } = props;
  const classLabelTop = labelPosition === "top" ? "label-position-top" : "";
  const classLabelLeft = labelPosition === "left" ? "label-position-left" : "";
  return (
    <div
      className={classesName(
        "lightup-switch",
        className,
        classLabelTop,
        classLabelLeft
      )}
      data-testid={testId}
    >
      <AntSwitch className={classesName(className)} {...restProps} />
      <SwitchLabel label={label} direction={labelPosition} />
    </div>
  );
}

function SwitchLabel({ direction = "left", label }) {
  const labelClassName = `label-${direction}`;
  return label ? (
    <span className={classesName("switch-label", labelClassName)}>{label}</span>
  ) : null;
}
