import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { ConfigProvider, Table, Tabs, Tooltip } from "antd";
import Button, { TextWithIcon } from "../../button/ng-button";
import CSVDownloadIcon from "../../icons/csv-download-icon";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  getFullCompareSampleData,
  resetFullCompareSampleData,
} from "../../../actions/chart-data/chart-data-action";
import * as csv from "../../../utils/csv";
import { getDisplayTableName, getFullTableName } from "../../../utils/datasource";
import { NgTableEmptyState } from "../../table/ng-table";
import { QueryHistoryIcon } from "../../metric/icons";

import "./full-compare-sample-data-takeover-view.scss";

function FullCompareTableHeader({ count, title }) {
  return (
    <div className="full-compare-sample-data-table-title-container">
      <span className="full-compare-sample-data-table-total-number-container">
        {`${title}(${count})`}
      </span>
      {count > 100 && (
        <>
          <Tooltip title="Max of 100 items can be viewed">
            <InfoCircleOutlined />
          </Tooltip>
          <span className="full-compare-sample-data-table-total-number-tooltip-container">
            100 of {count} available to view
          </span>
        </>
      )}
    </div>
  );
}

function SampleDataTable(props) {
  const { emptyState: emptyStateProp, loading, ...tableProps } = props;

  function renderEmpty() {
    const emptyState = emptyStateProp ?? <NgTableEmptyState />;
    return loading ? null : emptyState;
  }

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Table loading={loading} {...tableProps} />
    </ConfigProvider>
  );
}

function FullCompareKeyColumnSampleDataTable(props) {
  const {
    keyColumnTitle,
    valueColumns = [],
    attributeColumns = [],
    sampleData,
    loading = false,
    sliceByColumns = [],
    title,
    count,
  } = props;
  const columns = [
    {
      title: keyColumnTitle,
      dataIndex: "column",
    },
  ];

  attributeColumns.forEach((currentAttributeColumn) => {
    columns.push({
      title: currentAttributeColumn,
      dataIndex: currentAttributeColumn,
    });
  });

  const data = (sampleData.content ?? []).map((currentSampleData, index) => {
    let startIndex = sliceByColumns.length;
    const cellData = {
      key: index,
      column:
        currentSampleData
          .slice(startIndex, startIndex + valueColumns.length)
          .join(", ") || "null",
    };

    startIndex = startIndex + valueColumns.length;
    attributeColumns.forEach((currentAttributeColumn) => {
      cellData[currentAttributeColumn] =
        currentSampleData[startIndex] === null ? "null" : currentSampleData[startIndex];
      startIndex = startIndex + 1;
    });

    return cellData;
  });

  return (
    <>
      <FullCompareTableHeader title={title} count={count} />
      <SampleDataTable
        bordered
        columns={columns}
        dataSource={data}
        loading={loading}
        className="lightup"
        emptyState={
          <NgTableEmptyState
            icon={<QueryHistoryIcon />}
            description={sampleData.message ?? ""}
            title={!sampleData.message ? "No data" : ""}
          />
        }
      />
    </>
  );
}

function FullCompareKeyColumnSampleDataContent(props) {
  const { metricData, metric, sampleData, sliceByColumns, allowRecordStorage } = props;
  const {
    sourceCount,
    targetCount,
    sourceDupCount,
    targetDupCount,
    sourceRemainingCount,
    targetRemainingCount,
  } = metricData;

  const sourceTableName = getFullTableName(metric.config.sourceTable.table);
  const targetTableName = getFullTableName(metric.config.targetTable.table);
  const sourceValueColumns = metric.config.sourceTable.valueColumns;
  const targetValueColumns = metric.config.targetTable.valueColumns;
  const sourceColumnNames = sourceValueColumns.join(", ");
  const targetColumnNames = targetValueColumns.join(", ");
  const sourceAttributeColumns =
    metric.config.sourceTable.attributeColumns &&
    metric.config.sourceTable.attributeColumns.length > 0
      ? metric.config.sourceTable.attributeColumns
      : [];
  const targetAttributeColumns =
    metric.config.targetTable.attributeColumns &&
    metric.config.targetTable.attributeColumns.length > 0
      ? metric.config.targetTable.attributeColumns
      : [];

  const {
    sourceRemainingSampleData = {},
    targetRemainingSampleData = {},
    sourceDupSampleData = {},
    targetDupSampleData = {},
  } = sampleData.data;

  const summaryData = [
    {
      key: 1,
      column: `${sourceColumnNames} (Source)`,
      totalCount: sourceCount,
      remainingCount: sourceRemainingCount,
      dupCount: sourceDupCount,
      table: getDisplayTableName(sourceTableName),
    },
    {
      key: 2,
      column: `${targetColumnNames} (Target)`,
      totalCount: targetCount,
      remainingCount: targetRemainingCount,
      dupCount: targetDupCount,
      table: getDisplayTableName(targetTableName),
    },
  ];

  const summaryColumns = [
    {
      title: "Key column",
      dataIndex: "column",
      className: "lightup-focused-column",
    },
    {
      title: "Total entries",
      dataIndex: "totalCount",
    },
    {
      title: "Unmatched samples",
      dataIndex: "remainingCount",
      onCell: (data, index) => {
        return {
          style: { color: "#1890FF" },
        };
      },
    },
    {
      title: "Duplicate samples",
      dataIndex: "dupCount",
      onCell: (data, index) => {
        return {
          style: { color: "#1890FF" },
        };
      },
    },
    {
      title: "Table",
      dataIndex: "table",
    },
  ];

  return (
    <div className="full-compare-sample-data-body-container">
      <div className="full-compare-sample-data-key-summary-container">
        <div className="full-compare-sample-data-key-summary-title-container">
          Summary
        </div>
        <div className="full-compare-sample-data-key-summary-data-container">
          <SampleDataTable
            bordered
            className="lightup"
            columns={summaryColumns}
            dataSource={summaryData}
            pagination={false}
          />
        </div>
      </div>
      <div className="full-compare-sample-data-key-summary-container sample-table">
        <div className="full-compare-sample-data-key-summary-title-container">
          Sample
        </div>
        <div className="full-compare-sample-data-key-summary-data-container">
          {!allowRecordStorage ? (
            <div className="full-compare-sample-data-no-data-reason-container">
              No sample available because Enable data storage is off.
            </div>
          ) : (
            <Tabs defaultActiveKey="unmatched">
              <Tabs.TabPane tab={"Unmatched"} key="unmatched">
                <FullCompareKeyColumnSampleDataTable
                  keyColumnTitle={sourceColumnNames}
                  valueColumns={sourceValueColumns}
                  attributeColumns={sourceAttributeColumns}
                  title={"Source"}
                  count={sourceRemainingCount}
                  sampleData={sourceRemainingSampleData}
                  loading={sampleData.loading}
                  sliceByColumns={sliceByColumns}
                />
                <FullCompareKeyColumnSampleDataTable
                  keyColumnTitle={targetColumnNames}
                  valueColumns={targetValueColumns}
                  attributeColumns={targetAttributeColumns}
                  title={"Target"}
                  count={targetRemainingCount}
                  sampleData={targetRemainingSampleData}
                  loading={sampleData.loading}
                  sliceByColumns={sliceByColumns}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Duplicates"} key="duplicated">
                <FullCompareKeyColumnSampleDataTable
                  keyColumnTitle={sourceColumnNames}
                  title={"Source"}
                  count={sourceDupCount}
                  valueColumns={sourceValueColumns}
                  attributeColumns={sourceAttributeColumns}
                  sampleData={sourceDupSampleData}
                  loading={sampleData.loading}
                  sliceByColumns={sliceByColumns}
                />
                <FullCompareKeyColumnSampleDataTable
                  keyColumnTitle={targetColumnNames}
                  valueColumns={targetValueColumns}
                  attributeColumns={targetAttributeColumns}
                  title={"Target"}
                  count={targetDupCount}
                  sampleData={targetDupSampleData}
                  loading={sampleData.loading}
                  sliceByColumns={sliceByColumns}
                />
              </Tabs.TabPane>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
}

function FullCompareAttributeColumnSampleDataContent(props) {
  const { metric, sampleData, rowData, isSliced = false } = props;
  const sourceColumnNames = metric.config.sourceTable.valueColumns.join(", ");
  const targetColumnNames = metric.config.targetTable.valueColumns.join(", ");
  const sourceAttributeColumnName = rowData.source;
  const targetAttributeColumnName = rowData.target;

  const data = sampleData.data.attributeDiffsSampleData?.content ?? [];
  const notMatching = rowData.totalCount - rowData.matchingCount;
  const keyStartIndex = isSliced ? 1 : 0;
  const columns = [
    {
      title: `${sourceColumnNames}/${targetColumnNames}`,
      dataIndex: "key",
      render: (key) => {
        return key.slice(keyStartIndex).join(", ") || "null";
      },
    },
    {
      title: sourceAttributeColumnName,
      dataIndex: "sourceValue",
    },
    {
      title: targetAttributeColumnName,
      dataIndex: "targetValue",
    },
  ];

  return (
    <div className="full-compare-sample-data-body-container">
      <FullCompareTableHeader count={notMatching} title="Unmatched" />
      <SampleDataTable
        bordered
        dataSource={data}
        columns={columns}
        loading={sampleData.loading}
        className="lightup"
        emptyState={
          <NgTableEmptyState
            icon={<QueryHistoryIcon />}
            description={sampleData.data.attributeDiffsSampleData?.message ?? ""}
            title={!sampleData.data.attributeDiffsSampleData?.message ? "No data" : ""}
          />
        }
      />
    </div>
  );
}

function FullCompareSampleDataTakeoverView(props) {
  const {
    srcDataSource,
    dataSource,
    metric,
    type,
    rowData,
    metricData,
    timestamp,
    fullCompareSampleData: sampleData,
    match: {
      params: { workspaceUuid },
    },
    getFullCompareSampleData,
    closeTakeover,
  } = props;

  useEffect(() => {
    let fileUuidsObject;
    if (type === "attribute") {
      fileUuidsObject = {
        attributeDiffSamplesFileUuid:
          metricData.attributeDiffSamplesFileUuids[rowData.index] || null,
      };
    } else {
      fileUuidsObject = {
        sourceDupSamplesFileUuid: metricData.sourceDupSamplesFileUuid || null,
        targetDupSamplesFileUuid: metricData.targetDupSamplesFileUuid || null,
        sourceRemainingSamplesFileUuid:
          metricData.sourceRemainingSamplesFileUuid || null,
        targetRemainingSamplesFileUuid:
          metricData.targetRemainingSamplesFileUuid || null,
      };
    }

    fileUuidsObject.fullDiffSamplesFileUuid =
      metricData.fullDiffSamplesFileUuid || null;

    getFullCompareSampleData(workspaceUuid, metric, type, fileUuidsObject);

    return () => {
      resetFullCompareSampleData();
    };
  }, [workspaceUuid, metric, type, rowData, metricData, getFullCompareSampleData]);

  const sliceByColumns = metric?.config?.sourceTable?.sliceByColumns || [];
  const allowRecordStorage =
    !!dataSource?.config.governance.allowRecordStorage &&
    !!srcDataSource?.config.governance.allowRecordStorage;

  function downloadCsvData() {
    const filename = `${metric.metadata.name}_${timestamp}.csv`;
    csv.download(sampleData.data.fullDiffSampleData.content, filename);
  }

  return (
    <div className="full-compare-sample-data-takeover-view-container">
      <div className="full-compare-sample-data-takeover-view-header-container">
        <div className="full-compare-sample-data-takeover-view-header-title-container typo-h2">
          Failing record
        </div>
        {metricData.fullDiffSamplesFileUuid && (
          <Button
            disabled={
              !!sampleData.loading || !sampleData.data.fullDiffSampleData?.content
            }
            onClick={downloadCsvData}
            size="large"
            outline
          >
            <TextWithIcon icon={<CSVDownloadIcon />}>Download</TextWithIcon>
          </Button>
        )}
        <Button outline size="large" onClick={closeTakeover}>
          Close
        </Button>
      </div>
      <div className="full-compare-sample-data-takeover-content-container">
        {type === "key" && (
          <FullCompareKeyColumnSampleDataContent
            metric={metric}
            metricData={metricData}
            sampleData={sampleData}
            sliceByColumns={sliceByColumns}
            allowRecordStorage={allowRecordStorage}
          />
        )}
        {type === "attribute" && (
          <FullCompareAttributeColumnSampleDataContent
            metric={metric}
            metricData={metricData}
            sampleData={sampleData}
            rowData={rowData}
            sliceByColumns={sliceByColumns}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fullCompareSampleData: state.chartDataReducer.fullCompareSampleData,
});

const mapDispatchToProps = (dispatch) => ({
  getFullCompareSampleData: (workspaceUuid, metric, key, fileUuidsObject) =>
    dispatch(getFullCompareSampleData(workspaceUuid, metric, key, fileUuidsObject)),
  resetFullCompareSampleData: () => dispatch(resetFullCompareSampleData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FullCompareSampleDataTakeoverView));
