export const NextGenPalette = Object.freeze({
  purple: "#4832F3",
  lightPurple: "#9E9CF6",
  veryLightPurple: "#D5D4FF",
  brandYellow: "#F4AF47",
  yellow: "#F8D89C",
  lightYellow: "#FDF4E7",
  pink: "#D36FF7",
  red: "#EA386A",
  errorRed: "#EE8B9E",
  criticalRed: "#B80739",
  blue: "#4FBCFF",
  mintGreen: "#5CCAB4",
  slate: "rgba(23, 62, 103, 0.6)",
  systemBlack: "#121224",
  darkGrey: "#DDE2EB",
  darkGrey2: "#EAEAF4",
  lightGrey: "#F0F0F0",
  veryLightGrey: "#D9DBF2",
  orange: "#FFCDA1",
});

export const LightColorSet = Object.freeze({
  EXPECTATION: "#ADD4E1",
  BOUNDARY: "#F3F7F9",
  NORMAL: "#A0A6A9",
  INCIDENT: "#EEE1B7",
  RAW: "#DCD1E5",
  SEASON: "#F0F0F0",
});

export const BrightColorSet = Object.freeze({
  EXPECTATION: "#28A5CD",
  BOUNDARY: "#C0DFF0",
  NORMAL: "#5D5D5D",
  INCIDENT: "#F8D89C",
  HIGH_IMPACT_INCIDENT: "#DC7E82",
  RESOLVED_INCIDENT: "E0E0E0",
  RAW: "#4832F3",
  SEASON: "#F0F0F0",
  NOT_PROCESSED: "#DDDDDD",
});

export const IncidentColorSet = Object.freeze({
  NONE: "#BBDBBF",
  UNVIEWED: "#FAB800",
  VIEWED: "#F7C63B",
  REJECTED: "#FFF0C0",
  HIGH_IMPACT: "#DC7E82",
  NOT_PROCESSED: "#DDDDDD",
});

export const TrendChangePointColorSet = Object.freeze({
  STATS_BEFORE: "#3C4864",
  STATS_AFTER: "#71CCB9",
  RAW_BEFORE: "#D36DDD",
  RAW_AFTER: "#A068DC",
});

export function getRGBAColor(hexColValue, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColValue);
  if (!result) {
    return "";
  }

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getRGBColor(hexColValue) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColValue);
  if (!result) {
    return "";
  }

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return `rgb(${r}, ${g}, ${b})`;
}
