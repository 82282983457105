import {
  CollectionModeType,
  DimensionType,
  AggregationType,
  AggregationWindowType,
  SeasonalityTypeConst,
  getDimensionTypeDisplayName,
  getSeasonalityDisplayName,
  MetricConfigType,
  TableType,
  MetricType,
  MetricCategory,
  MissingValueFillingType,
  TableColumnTypeCategory,
  QueryScope,
  CollectionWindow,
  MetricCategoryGroup,
  VirtualTableType,
} from "../../utils/enums";
import { MINUTE_IN_SECONDS, HOUR_IN_SECONDS, DAY_IN_SECONDS } from "../../utils/time";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import subWeeks from "date-fns/subWeeks";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import subMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subQuarters from "date-fns/subQuarters";
import startOfQuarter from "date-fns/startOfQuarter";
import endOfQuarter from "date-fns/endOfQuarter";
import subYears from "date-fns/subYears";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import { deepMerge } from "../../utils/deep-merge";
import { deepcopy } from "../../utils/objects";

export const MetricConfigStep = Object.freeze({
  BASIC: 1,
  DATA_ASSET: 2,
  PREVIEW: 3,
  RELATED_METRICS: 4,
  DRAFT_INFO: 5,
});

export const metricTypeNames = {
  [MetricCategory.AGGREGATION]: "Aggregation",
  [MetricCategory.AGGREGATION_COMPARE]: "Compare aggregate metrics",
  [MetricCategory.CONFORMITY_COUNT]: "Conformity",
  [MetricCategory.DATA_DELAY]: "Data delay",
  [MetricCategory.DATA_VOLUME]: "Data volume",
  [MetricCategory.DISTRIBUTION]: "Distribution",
  [MetricCategory.NULL_PERCENT]: "Null percent",
  [MetricCategory.FULL_COMPARE]: "Row by row",
  [MetricCategory.CUSTOM_SQL]: "SQL",
  [MetricCategory.TABLE_ACTIVITY]: "Table activity",
  [MetricCategory.COLUMN_ACTIVITY]: "Column activity",
  [MetricCategory.CATEGORY_ACTIVITY]: "Category activity",
  [MetricCategory.BYTE_COUNT]: "Byte count",
  [MetricCategory.ROW_COUNT]: "Row count",
  [MetricCategory.UPDATE_DELAY]: "Update delay",
};

export const metricTypeGroups = {
  [MetricCategory.AGGREGATION]: MetricCategoryGroup.COLUMN_METRICS,
  [MetricCategory.AGGREGATION_COMPARE]: MetricCategoryGroup.COMPARISON_METRICS,
  [MetricCategory.CONFORMITY_COUNT]: MetricCategoryGroup.COLUMN_METRICS,
  [MetricCategory.DATA_DELAY]: MetricCategoryGroup.TABLE_METRICS,
  [MetricCategory.DATA_VOLUME]: MetricCategoryGroup.TABLE_METRICS,
  [MetricCategory.DISTRIBUTION]: MetricCategoryGroup.COLUMN_METRICS,
  [MetricCategory.NULL_PERCENT]: MetricCategoryGroup.COLUMN_METRICS,
  [MetricCategory.FULL_COMPARE]: MetricCategoryGroup.COMPARISON_METRICS,
  [MetricCategory.CUSTOM_SQL]: MetricCategoryGroup.SQL_METRICS,
  [MetricCategory.EVENT]: MetricCategoryGroup.TABLE_METRICS,
  [MetricCategory.TABLE_ACTIVITY]: MetricCategoryGroup.TABLE_METRICS,
  [MetricCategory.COLUMN_ACTIVITY]: MetricCategoryGroup.COLUMN_METRICS,
  [MetricCategory.CATEGORY_ACTIVITY]: MetricCategoryGroup.COLUMN_METRICS,
  [MetricCategory.BYTE_COUNT]: MetricCategoryGroup.TABLE_METRICS,
  [MetricCategory.ROW_COUNT]: MetricCategoryGroup.TABLE_METRICS,
  [MetricCategory.UPDATE_DELAY]: MetricCategoryGroup.TABLE_METRICS,
};

export const metricCategoryGroupNames = {
  [MetricCategoryGroup.TABLE_METRICS]: "Table metrics",
  [MetricCategoryGroup.COLUMN_METRICS]: "Column metrics",
  [MetricCategoryGroup.COMPARISON_METRICS]: "Comparison metrics",
  [MetricCategoryGroup.SQL_METRICS]: "SQL metrics",
};

export function canCreateMetricType(metricType) {
  return ![
    MetricCategory.BYTE_COUNT,
    MetricCategory.ROW_COUNT,
    MetricCategory.UPDATE_DELAY,
    MetricCategory.TABLE_ACTIVITY,
    MetricCategory.COLUMN_ACTIVITY,
    MetricCategory.CATEGORY_ACTIVITY,
    MetricCategory.EVENT,
  ].includes(metricType);
}

export const metricTypeOptions = Object.keys(metricTypeNames).map((type) => ({
  label: metricTypeNames[type],
  value: type,
}));

export const dimensionTypeOptions = [
  DimensionType.ACCURACY,
  DimensionType.COMPLETENESS,
  DimensionType.CUSTOM,
  DimensionType.TIMELINESS,
].map((currentDimensionType) => ({
  label: getDimensionTypeDisplayName(currentDimensionType),
  value: currentDimensionType,
}));

export const missingValueFillingOptions = [
  { label: "Null", value: MissingValueFillingType.NULL },
  { label: "Zero", value: MissingValueFillingType.ZERO },
];

const stringAggregationFunctionOptions = [
  { value: AggregationType.COUNT, label: "Count" },
  { value: AggregationType.COUNT_UNIQUE, label: "Count unique" },
];

const numericAggregationFunctionOptions = [
  ...stringAggregationFunctionOptions,
  { value: AggregationType.SUM, label: "Sum" },
  { value: AggregationType.MAX, label: "Max" },
  { value: AggregationType.MIN, label: "Min" },
  { value: AggregationType.AVG, label: "Avg" },
  { value: AggregationType.STDDEV, label: "Standard deviation" },
  { value: AggregationType.MEDIAN, label: "Median" },
  { value: AggregationType.PERCENTILE, label: "Percentile" },
];

export function getAggregationFunctionNameOptions(isNumeric) {
  return isNumeric
    ? numericAggregationFunctionOptions
    : stringAggregationFunctionOptions;
}

export const streamingMetricAggregationWindowOptions = [
  { value: AggregationWindowType.NONE, label: "-" },
  { value: AggregationWindowType.MINUTE, label: "Every Minute" },
  { value: AggregationWindowType.FIVE_MIN, label: "Every 5 Minutes" },
  { value: AggregationWindowType.TEN_MIN, label: "Every 10 Minutes" },
  { value: AggregationWindowType.FIFTEEN_MIN, label: "Every 15 Minutes" },
];

export const aggregationWindowOptions = [
  { value: AggregationWindowType.NONE, label: "-" },
  { value: AggregationWindowType.SECOND, label: "Every Second" },
  { value: AggregationWindowType.MINUTE, label: "Every Minute" },
  { value: AggregationWindowType.FIVE_MIN, label: "Every 5 Minutes" },
  { value: AggregationWindowType.TEN_MIN, label: "Every 10 Minutes" },
  { value: AggregationWindowType.FIFTEEN_MIN, label: "Every 15 Minutes" },
  { value: AggregationWindowType.HOUR, label: "Hourly" },
  { value: AggregationWindowType.DAY, label: "Daily" },
  { value: AggregationWindowType.WEEK, label: "Weekly" },
  { value: AggregationWindowType.MONTH, label: "Monthly" },
  { value: AggregationWindowType.QUARTER, label: "Quarterly" },
  { value: AggregationWindowType.YEAR, label: "Yearly" },
];

export function removeSecondAndMinuteOptions(options) {
  return options.filter(
    ({ value }) =>
      value !== AggregationWindowType.SECOND && value !== AggregationWindowType.MINUTE
  );
}

export const autoMetricAggregationWindowOptions = [
  { value: AggregationWindowType.HOUR, label: "Hourly" },
  { value: AggregationWindowType.DAY, label: "Daily" },
  { value: AggregationWindowType.WEEK, label: "Weekly" },
];

export const fullTableMetricAggregationWindowOptions = [
  { value: AggregationWindowType.DAY, label: "Daily" },
  { value: AggregationWindowType.WEEK, label: "Weekly" },
  { value: AggregationWindowType.MONTH, label: "Monthly" },
];

export const betaFullTableMetricAggregationWindowOptions = [
  { value: AggregationWindowType.HOUR, label: "Hourly" },
  ...fullTableMetricAggregationWindowOptions,
];

export const fullCompareMetricAggregationWindowOptions = [
  { value: AggregationWindowType.NONE, label: "-" },
  ...autoMetricAggregationWindowOptions,
];

export function getDefaultKPIPreviewPeriod(KPIInterval, currentTimestamp) {
  if (
    [
      AggregationWindowType.SECOND,
      AggregationWindowType.MINUTE,
      AggregationWindowType.FIVE_MIN,
      AggregationWindowType.TEN_MIN,
      AggregationWindowType.FIFTEEN_MIN,
      AggregationWindowType.HOUR,
    ].includes(KPIInterval)
  ) {
    const windowConfigMapping = {
      [AggregationWindowType.SECOND]: MINUTE_IN_SECONDS,
      [AggregationWindowType.MINUTE]: HOUR_IN_SECONDS,
      [AggregationWindowType.FIVE_MIN]: 3 * HOUR_IN_SECONDS,
      [AggregationWindowType.TEN_MIN]: 6 * HOUR_IN_SECONDS,
      [AggregationWindowType.FIFTEEN_MIN]: 9 * HOUR_IN_SECONDS,
      [AggregationWindowType.HOUR]: DAY_IN_SECONDS,
    };
    return {
      previewStartTs: currentTimestamp - windowConfigMapping[KPIInterval],
      previewEndTs: currentTimestamp,
    };
  } else {
    const currentTime = fromUnixTime(currentTimestamp);
    if ([AggregationWindowType.DAY, AggregationWindowType.WEEK].includes(KPIInterval)) {
      const previousWeek = subWeeks(currentTime, 1);
      const previousStartOfWeek = startOfWeek(previousWeek, {
        weekStartsOn: 1,
      });
      const previousEndOfWeek = endOfWeek(previousWeek, { weekStartsOn: 1 });
      return {
        previewStartTs: getUnixTime(previousStartOfWeek),
        previewEndTs: getUnixTime(previousEndOfWeek),
      };
    } else if ([AggregationWindowType.MONTH].includes(KPIInterval)) {
      const previousMonth = subMonths(currentTime, 1);
      const previousStartOfMonth = startOfMonth(previousMonth);
      const previousEndOfMonth = endOfMonth(previousMonth);
      return {
        previewStartTs: getUnixTime(previousStartOfMonth),
        previewEndTs: getUnixTime(previousEndOfMonth),
      };
    } else if ([AggregationWindowType.QUARTER].includes(KPIInterval)) {
      const previousQuarter = subQuarters(currentTime, 1);
      const previousStartOfQuarter = startOfQuarter(previousQuarter);
      const previousEndOfQuarter = endOfQuarter(previousQuarter);
      return {
        previewStartTs: getUnixTime(previousStartOfQuarter),
        previewEndTs: getUnixTime(previousEndOfQuarter),
      };
    } else if ([AggregationWindowType.YEAR].includes(KPIInterval)) {
      const previousYear = subYears(currentTime, 1);
      const previousStartOfYear = startOfYear(previousYear);
      const previousEndOfYear = endOfYear(previousYear);
      return {
        previewStartTs: getUnixTime(previousStartOfYear),
        previewEndTs: getUnixTime(previousEndOfYear),
      };
    } else {
      return {
        previewStartTs: currentTimestamp - DAY_IN_SECONDS,
        previewEndTs: currentTimestamp,
      };
    }
  }
}

export const basicSeasonalityOptions = [
  SeasonalityTypeConst.AUTO,
  SeasonalityTypeConst.NONE,
  SeasonalityTypeConst.FIFTEEN_MIN_OF_DAY,
  SeasonalityTypeConst.ONE_HOUR_OF_DAY,
  SeasonalityTypeConst.EIGHT_HOUR_OF_DAY,
  SeasonalityTypeConst.FIFTEEN_MIN_OF_WEEK,
  SeasonalityTypeConst.ONE_HOUR_OF_WEEK,
  SeasonalityTypeConst.EIGHT_HOUR_OF_WEEK,
  SeasonalityTypeConst.ONE_DAY_OF_WEEK,
].map(function (season) {
  return {
    value: season,
    label: getSeasonalityDisplayName(season),
  };
});

const seasonalityList = [
  SeasonalityTypeConst.AUTO,
  SeasonalityTypeConst.NONE,
  SeasonalityTypeConst.FIFTEEN_MIN_OF_DAY,
  SeasonalityTypeConst.ONE_HOUR_OF_DAY,
  SeasonalityTypeConst.EIGHT_HOUR_OF_DAY,
  SeasonalityTypeConst.FIFTEEN_MIN_OF_WEEK,
  SeasonalityTypeConst.ONE_HOUR_OF_WEEK,
  SeasonalityTypeConst.EIGHT_HOUR_OF_WEEK,
  SeasonalityTypeConst.ONE_DAY_OF_WEEK,
];

const monthlySeasonalityList = [SeasonalityTypeConst.NONE];

export function getSeasonalityList(kpiInterval) {
  if (
    [
      AggregationWindowType.MONTH,
      AggregationWindowType.QUARTER,
      AggregationWindowType.YEAR,
    ].includes(kpiInterval)
  ) {
    return monthlySeasonalityList;
  }

  return seasonalityList;
}

export function getMetricInterval(table, aggregation) {
  if (table && table.type === TableType.CUSTOM_SQL && table.aggregationWindow) {
    return table.aggregationWindow;
  }

  if (aggregation && aggregation.aggregationWindow) {
    return aggregation.aggregationWindow;
  }

  return "";
}

export function getMetricTypeFromConfigData(metricConfig) {
  if (metricConfig.config.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG) {
    return MetricCategory.FULL_COMPARE;
  }

  if (
    metricConfig.config.configType ===
    MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG
  ) {
    return MetricCategory.AGGREGATION_COMPARE;
  }

  const metricConfigTypes = [
    MetricConfigType.METRIC_CONFIG,
    MetricConfigType.FULL_TABLE_METRIC_CONFIG,
  ];

  if (
    metricConfigTypes.includes(metricConfig.config.configType) &&
    metricConfig.config?.aggregation?.type === AggregationType.DATA_DELAY
  ) {
    return MetricCategory.DATA_DELAY;
  }

  if (
    metricConfigTypes.includes(metricConfig.config.configType) &&
    metricConfig.config?.aggregation?.type === AggregationType.VOLUME
  ) {
    return MetricCategory.DATA_VOLUME;
  }

  if (
    metricConfigTypes.includes(metricConfig.config.configType) &&
    metricConfig.config?.aggregation?.type === AggregationType.NULL_FRACTION
  ) {
    return MetricCategory.NULL_PERCENT;
  }

  if (
    metricConfigTypes.includes(metricConfig.config.configType) &&
    metricConfig.config?.aggregation?.type === AggregationType.CONFORMITY_COUNT
  ) {
    return MetricCategory.CONFORMITY_COUNT;
  }

  if (
    metricConfigTypes.includes(metricConfig.config.configType) &&
    [
      AggregationType.MULTI_PERCENTILE,
      AggregationType.CATEGORICAL_DISTRIBUTION,
    ].includes(metricConfig.config?.aggregation?.type)
  ) {
    return MetricCategory.DISTRIBUTION;
  }

  if (metricConfig.config?.aggregation?.type === AggregationType.BYTE_COUNT) {
    return MetricCategory.BYTE_COUNT;
  }

  if (metricConfig.config?.aggregation?.type === AggregationType.ROW_COUNT) {
    return MetricCategory.ROW_COUNT;
  }

  if (metricConfig.config?.aggregation?.type === AggregationType.UPDATE_DELAY) {
    return MetricCategory.UPDATE_DELAY;
  }

  if (metricConfig.config?.aggregation?.type === AggregationType.TABLE_ACTIVITY) {
    return MetricCategory.TABLE_ACTIVITY;
  }

  if (metricConfig.config?.aggregation?.type === AggregationType.COLUMN_ACTIVITY) {
    return MetricCategory.COLUMN_ACTIVITY;
  }

  if (metricConfig.config?.aggregation?.type === AggregationType.CATEGORY_ACTIVITY) {
    return MetricCategory.CATEGORY_ACTIVITY;
  }

  if (
    metricConfigTypes.includes(metricConfig.config.configType) &&
    metricConfig.metadata.creationType === MetricType.CUSTOM &&
    [VirtualTableType.USER_DEFINED_VIEW, TableType.TABLE].includes(
      metricConfig.config?.table?.type
    )
  ) {
    return MetricCategory.AGGREGATION;
  }

  if (
    metricConfigTypes.includes(metricConfig.config.configType) &&
    metricConfig.metadata.creationType === MetricType.CUSTOM &&
    metricConfig.config?.table?.type === TableType.CUSTOM_SQL
  ) {
    return MetricCategory.CUSTOM_SQL;
  }

  return null;
}

export function getMetricDisplayTypeFromConfigData(configData) {
  return metricTypeNames[getMetricTypeFromConfigData(configData)] || "";
}

export function getMetricQueryScopeFromMetricData(metricConfig) {
  if (metricConfig.config.configType === MetricConfigType.METRIC_CONFIG) {
    return QueryScope.TIME_RANGE;
  }

  if (metricConfig.config.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG) {
    return QueryScope.FULL_TABLE;
  }

  if (metricConfig.config.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG) {
    return metricConfig.config.queryScope;
  }

  if (
    metricConfig.config.configType ===
    MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG
  ) {
    return QueryScope.TIME_RANGE;
  }

  console.log(`Unknown config type ${metricConfig.config.configType}`);
  return null;
}

const commonDefaultConfig = Object.freeze({
  isLive: true,
});

const defaultTableConfig = Object.freeze({
  ...commonDefaultConfig,
  configType: MetricConfigType.METRIC_CONFIG,
  sources: [],
  table: {
    type: TableType.TABLE,
    tableName: null,
  },
  seasonality: null,
  timestampColumn: null,
  timestampColumnFunctions: null,
  valueColumns: [],
  sliceValueSelections: [],
  synchronizationDelay: HOUR_IN_SECONDS,
  dimension: DimensionType.CUSTOM,
  collectionMode: { type: CollectionModeType.SCHEDULED },
  collectionWindow: CollectionWindow.COMPLETE,
  missingValueFilling: MissingValueFillingType.NULL,
});

const defaultCustomSqlConfig = Object.freeze({
  ...commonDefaultConfig,
  configType: MetricConfigType.METRIC_CONFIG,
  sources: [],
  table: {
    type: TableType.CUSTOM_SQL,
    sql: "",
    aggregationWindow: null,
  },
  seasonality: null,
  timestampColumn: null,
  timestampColumnFunctions: null,
  dataTimezone: "UTC",
  collectionMode: { type: CollectionModeType.SCHEDULED },
  collectionWindow: CollectionWindow.COMPLETE,
  valueColumns: [],
  sliceValueSelections: [],
  failingRecordsSql: "",
  synchronizationDelay: HOUR_IN_SECONDS,
  dimension: DimensionType.CUSTOM,
  missingValueFilling: MissingValueFillingType.NULL,
});

const defaultDataDelayConfig = Object.freeze({
  ...commonDefaultConfig,
  configType: MetricConfigType.METRIC_CONFIG,
  sources: [],
  inheritTableSettings: true,
  table: {
    type: TableType.TABLE,
    tableName: "",
    tableUuid: null,
    schemaName: null,
  },
  seasonality: {
    seasonInSeconds: 0,
    periodInSeconds: 0,
  },
  aggregation: {
    aggregationWindow: AggregationWindowType.HOUR,
    type: AggregationType.DATA_DELAY,
  },
  collectionMode: { type: CollectionModeType.SCHEDULED },
  timestampColumn: null,
  timestampColumnFunctions: null,
  valueColumns: [],
  dimension: DimensionType.TIMELINESS,
  missingValueFilling: MissingValueFillingType.NULL,
});

const defaultMetadataConfig = Object.freeze({
  configType: MetricConfigType.FULL_TABLE_METRIC_CONFIG,
  alignPollingTs: false,
  sources: [],
  triggered: false,
  inheritTableSettings: true,
  table: {
    type: TableType.TABLE,
    tableName: "",
    tableUuid: null,
    schemaName: null,
  },
  seasonality: {
    seasonInSeconds: 0,
    periodInSeconds: 0,
  },
  aggregation: {
    aggregationWindow: AggregationWindowType.HOUR,
    type: AggregationType.ROW_COUNT,
  },
  collectionMode: { type: CollectionModeType.SCHEDULED },
  timestampColumn: null,
  timestampColumnFunctions: null,
  valueColumns: [],
  dimension: DimensionType.COMPLETENESS,
});

const defaultRowConfig = defaultMetadataConfig;

const defaultByteCountConfig = deepMerge(defaultMetadataConfig, {
  aggregation: {
    type: AggregationType.BYTE_COUNT,
  },
});

const defaultUpdateDelayConfig = deepMerge(defaultMetadataConfig, {
  aggregation: {
    type: AggregationType.UPDATE_DELAY,
  },
  dimension: DimensionType.TIMELINESS,
});

const defaultDataVolumeConfig = Object.freeze({
  ...defaultDataDelayConfig,
  seasonality: null,
  aggregation: {
    ...defaultDataDelayConfig.aggregation,
    type: AggregationType.VOLUME,
  },
  dimension: DimensionType.COMPLETENESS,
  missingValueFilling: MissingValueFillingType.ZERO,
  collectionWindow: CollectionWindow.COMPLETE,
});

const defaultNullPercentConfig = Object.freeze({
  ...defaultDataDelayConfig,
  aggregation: {
    ...defaultDataDelayConfig.aggregation,
    type: AggregationType.NULL_FRACTION,
  },
  dimension: DimensionType.COMPLETENESS,
  missingValueFilling: MissingValueFillingType.NULL,
  collectionWindow: CollectionWindow.COMPLETE,
});

const defaultFullCompareConfig = Object.freeze({
  ...commonDefaultConfig,
  configType: MetricConfigType.FULL_COMPARE_METRIC_CONFIG,
  sources: [],
  queryScope: QueryScope.TIME_RANGE,
  collectionMode: { type: CollectionModeType.SCHEDULED },
  sourceTable: {
    sourceUuid: null,
    table: null,
    timestampColumn: null,
    valueColumns: [],
    sliceByColumns: [],
    attributeColumns: [],
    queryTimezone: null,
    sliceValueSelections: [],
  },
  targetTable: {
    sourceUuid: null,
    table: null,
    timestampColumn: null,
    valueColumns: [],
    sliceByColumns: [],
    attributeColumns: [],
    queryTimezone: null,
    sliceValueSelections: [],
  },
  synchronizationDelay: HOUR_IN_SECONDS,
  dimension: DimensionType.CUSTOM,
});

const defaultAggregationCompareConfig = Object.freeze({
  ...commonDefaultConfig,
  configType: MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG,
  sources: [],
  sourceTable: {},
  targetTable: {},
  compares: [],
  dimension: DimensionType.CUSTOM,
});

const defaultConformityCountCompareConfig = Object.freeze({
  ...defaultDataDelayConfig,
  aggregation: {
    ...defaultDataDelayConfig.aggregation,
    type: AggregationType.CONFORMITY_COUNT,
    conditions: [],
  },
  dimension: DimensionType.ACCURACY,
  missingValueFilling: MissingValueFillingType.ZERO,
  collectionWindow: CollectionWindow.COMPLETE,
});

export const distributionAggregationConfigByColumnType = {
  [TableColumnTypeCategory.NUMERIC]: {
    type: AggregationType.MULTI_PERCENTILE,
    fractions: [0, 0.25, 0.5, 0.75, 1],
  },
  [TableColumnTypeCategory.STRING]: {
    type: AggregationType.CATEGORICAL_DISTRIBUTION,
    fractions: undefined,
  },
};

const defaultDistributionConfig = Object.freeze({
  ...defaultDataDelayConfig,
  seasonality: null,
  aggregation: {
    ...defaultDataDelayConfig.aggregation,
    ...distributionAggregationConfigByColumnType[TableColumnTypeCategory.NUMERIC],
  },
  dimension: DimensionType.ACCURACY,
  collectionWindow: CollectionWindow.COMPLETE,
  missingValueFilling: null,
});

const defaultCreationType = MetricType.CUSTOM;

export function generalDefaultMetricData() {
  return {
    apiVersion: "v1",
    type: "metric",
    metadata: {
      name: "",
      creationType: defaultCreationType,
    },
    config: {
      isLive: true,
      dimension: null,
    },
  };
}

export function getDefaultDataByMetricCategory(metricCategory) {
  let defaultConfig = {};
  if (metricCategory === MetricCategory.AGGREGATION) {
    defaultConfig = defaultTableConfig;
  } else if (metricCategory === MetricCategory.CUSTOM_SQL) {
    defaultConfig = defaultCustomSqlConfig;
  } else if (metricCategory === MetricCategory.DATA_DELAY) {
    defaultConfig = defaultDataDelayConfig;
  } else if (metricCategory === MetricCategory.DATA_VOLUME) {
    defaultConfig = defaultDataVolumeConfig;
  } else if (metricCategory === MetricCategory.NULL_PERCENT) {
    defaultConfig = defaultNullPercentConfig;
  } else if (metricCategory === MetricCategory.FULL_COMPARE) {
    defaultConfig = defaultFullCompareConfig;
  } else if (metricCategory === MetricCategory.AGGREGATION_COMPARE) {
    defaultConfig = defaultAggregationCompareConfig;
  } else if (metricCategory === MetricCategory.CONFORMITY_COUNT) {
    defaultConfig = defaultConformityCountCompareConfig;
  } else if (metricCategory === MetricCategory.DISTRIBUTION) {
    defaultConfig = defaultDistributionConfig;
  } else if (metricCategory === MetricCategory.ROW_COUNT) {
    defaultConfig = defaultRowConfig;
  } else if (metricCategory === MetricCategory.BYTE_COUNT) {
    defaultConfig = defaultByteCountConfig;
  } else if (metricCategory === MetricCategory.UPDATE_DELAY) {
    defaultConfig = defaultUpdateDelayConfig;
  } else {
    console.log(`Unknown metric category ${metricCategory}`);
  }

  return {
    ...generalDefaultMetricData(),
    config: deepcopy(defaultConfig),
  };
}

export function isViewDataSampleEnabled(metricConfig) {
  const metricType = getMetricTypeFromConfigData(metricConfig);
  if (
    [
      MetricCategory.AGGREGATION_COMPARE,
      MetricCategory.TABLE_ACTIVITY,
      MetricCategory.COLUMN_ACTIVITY,
      MetricCategory.CATEGORY_ACTIVITY,
    ].includes(metricType)
  ) {
    return false;
  }

  if (metricType === MetricCategory.CUSTOM_SQL) {
    return metricConfig.config.table.schemaName && metricConfig.config.table.tableUuid;
  }

  if (metricType === MetricCategory.FULL_COMPARE) {
    return (
      metricConfig.config.sources?.length > 0 &&
      metricConfig.config?.sourceTable?.table?.tableUuid &&
      metricConfig.config?.targetTable?.table?.tableUuid
    );
  }

  return (
    metricConfig.config.sources?.length > 0 && metricConfig.config?.table?.tableUuid
  );
}
