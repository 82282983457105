import React, { PureComponent } from "react";
import ZeroStatusChart from "./zero-status-chart";
import Plot from "../../utils/plotly";
import {
  timestampDateTimeTickFormat,
  timestampDateTickFormat,
  getIncidentColorCode,
} from "./utils";
import {
  BrightColorSet,
  LightColorSet,
  TrendChangePointColorSet,
  getRGBAColor,
  NextGenPalette,
} from "../../utils/color";
import { getStringFromTimeStamp, getStringFromTimeObject } from "../../utils/time";

class IncidentDetailLineView extends PureComponent {
  getProjectTraceTemplate(projectedData, colorSet, name) {
    if (projectedData.x.length === 0 || projectedData.y.length === 0) {
      return null;
    }

    return {
      ...projectedData,
      mode: "lines",
      line: {
        color: colorSet.EXPECTATION,
        dash: "dash",
        width: 2,
      },
      showlegend: false,
      name,
    };
  }

  getNormalDataTraceTemplate(
    realNormalData,
    color,
    name,
    yaxis = "y",
    hoverinfo = "all"
  ) {
    return {
      ...realNormalData,
      mode: "lines+markers",
      line: {
        color,
        dash: "solid",
        width: 2,
      },
      marker: { size: 2 },
      showlegend: false,
      name,
      yaxis,
      hoverinfo,
    };
  }

  getAnomalyDataTraceTemplate(realAnomalyData, color, name, yaxis = "y") {
    return {
      ...realAnomalyData,
      mode: "lines+markers",
      line: {
        color,
        dash: "solid",
        width: 2,
      },
      marker: { size: 2 },
      showlegend: false,
      name,
      yaxis,
      hoverinfo: "skip",
    };
  }

  getUpperBoundDataTrace(upperBoundData, colorSet, isLowerBoundTrace) {
    if (upperBoundData.x.length === 0 || upperBoundData.y.length === 0) {
      return null;
    }

    return {
      ...upperBoundData,
      mode: "lines",
      line: {
        color: colorSet.BOUNDARY,
        dash: "dot",
        width: 1,
      },
      showlegend: false,
      name: "Upper Limit",
      fill: isLowerBoundTrace ? "tonexty" : "none",
      fillcolor: getRGBAColor(colorSet.BOUNDARY, 0.5),
    };
  }

  getLowerBoundDataTrace(lowerBoundData, colorSet) {
    if (lowerBoundData.x.length === 0 || lowerBoundData.y.length === 0) {
      return null;
    }

    return {
      ...lowerBoundData,
      mode: "lines",
      line: {
        color: colorSet.BOUNDARY,
        dash: "dot",
        width: 1,
      },
      showlegend: false,
      name: "Lower Limit",
    };
  }

  getSignalDataTraceTemplate(signalData, color, name, hoverinfo = "all") {
    if (signalData.x.length === 0 || signalData.y.length === 0) {
      return null;
    }

    const mode = signalData.x.length === 1 ? "markers" : "lines";
    const marker = signalData.x.length === 1 ? { size: 10 } : { size: 2 };
    return {
      ...signalData,
      mode,
      type: "scatter",
      line: {
        color,
        width: 3,
      },
      marker,
      showlegend: false,
      name,
      yaxis: "y2",
      hoverinfo,
    };
  }

  getIndicatorTrace(
    yAxisMin,
    yAxisMax,
    xAxisMin,
    xAxisMax,
    incidentTextHeight,
    color,
    indicatorText = "Drift Detected",
    isMain = false,
    isIncidentOnly = false
  ) {
    if (typeof yAxisMin !== "number" || typeof yAxisMax !== "number" || !xAxisMin) {
      if (xAxisMin) {
        console.log(
          `Invalid value range ${yAxisMin} and ${yAxisMax} for indicator trace`
        );
      }
      return { indicatorTrace: null, xMaxTrace: null };
    }

    if (yAxisMin === yAxisMax) {
      yAxisMin = yAxisMin - 1;
      yAxisMax = yAxisMax + 1;
    }

    const startTime = getStringFromTimeStamp(xAxisMin, { graph: true });
    const topIndicatorTopText = indicatorText || "";
    const lineColor = color;
    const fillcolor = getRGBAColor(lineColor, 0.2);
    const indicatorTrace = {
      x: [startTime, startTime],
      y: [yAxisMin, yAxisMax - incidentTextHeight],
      type: "scatter",
      mode: "lines+text",
      text: ["", topIndicatorTopText],
      textposition: `top ${isIncidentOnly ? "right" : "center"}`,
      textfont: {
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        size: 12,
        color: NextGenPalette.purple,
      },
      line: {
        color: lineColor,
        width: 1,
        dash: "solid",
      },
      showlegend: false,
      hoverinfo: "skip",
      yaxis: "y2",
    };

    const endTime = getStringFromTimeStamp(xAxisMax, { graph: true });
    const xMaxTrace = {
      x: [endTime, endTime],
      y: [yAxisMin, yAxisMax - incidentTextHeight],
      type: "scatter",
      mode: "lines",
      line: {
        color: fillcolor,
        width: 0,
        dash: "solid",
      },
      fill: "tonextx",
      fillcolor,
      showlegend: false,
      hoverinfo: "skip",
      yaxis: "y2",
    };

    return { indicatorTrace, xMaxTrace };
  }

  getPointMarkTemplate(anomalyStartPoint, color) {
    if (!anomalyStartPoint) {
      return null;
    }

    return {
      x: [getStringFromTimeStamp(anomalyStartPoint.x, { graph: true })],
      y: [anomalyStartPoint.y],
      type: "scatter",
      mode: "markers",
      marker: {
        color: "#FFFFFF",
        size: 5,
        line: {
          color,
          width: 3,
        },
      },
      showlegend: false,
      hoverinfo: "skip",
    };
  }

  normalizeData(dataSet = [], detectInterval = 0) {
    const processedData = {
      x: [],
      y: [],
    };

    let lastTimestamp = 0;
    for (let dataEntry of dataSet) {
      const currentTimestamp = dataEntry.timestamp;
      if (detectInterval) {
        if (lastTimestamp && currentTimestamp - lastTimestamp > detectInterval) {
          console.log(
            `Start to inject a discontinue point between ${lastTimestamp} and ${currentTimestamp}`
          );
          const injectedTimestamp = Math.floor((currentTimestamp + lastTimestamp) / 2);
          processedData.x.push(
            getStringFromTimeStamp(injectedTimestamp, { graph: true })
          );
          processedData.y.push(null);
        }
        lastTimestamp = currentTimestamp;
      }
      processedData.x.push(getStringFromTimeStamp(currentTimestamp, { graph: true }));
      processedData.y.push(dataEntry.value);
    }

    return processedData;
  }

  getRealDataTrace(
    normalDataSet,
    incidentList,
    detectInterval,
    colorSet,
    config,
    isIncidentOnly = false
  ) {
    const sortedIncidentList = [...incidentList].sort(
      (a, b) => a.startTime - b.startTime
    );

    let i = 0;
    const normalInfoSet = [];
    const anomalyInfoSet = [];
    const connectInfoSet = [];
    for (let currentSortedIncident of sortedIncidentList) {
      const { startTime, endTime, main } = currentSortedIncident;
      const newNormalDataSet = [];

      while (i < normalDataSet.length && normalDataSet[i].timestamp < startTime) {
        if (newNormalDataSet.length === 0 && i > 0) {
          const anomalyToNormalDataSet = [];
          // From anomaly to normal
          anomalyToNormalDataSet.push(normalDataSet[i - 1]);
          anomalyToNormalDataSet.push(normalDataSet[i]);
          connectInfoSet.push({
            data: anomalyToNormalDataSet,
          });
        }

        newNormalDataSet.push(normalDataSet[i]);
        i++;
      }

      if (newNormalDataSet.length > 0) {
        normalInfoSet.push({
          data: newNormalDataSet,
        });
      }

      if (i === normalDataSet.length) {
        break;
      }

      const newAnomalyDataSet = [];
      while (i < normalDataSet.length && normalDataSet[i].timestamp <= endTime) {
        if (newNormalDataSet.length > 0 && newAnomalyDataSet.length === 0 && i > 0) {
          // From normal to anomaly.
          const normalToAnomalyDataSet = [];
          normalToAnomalyDataSet.push(normalDataSet[i - 1]);
          normalToAnomalyDataSet.push(normalDataSet[i]);
          connectInfoSet.push({
            data: normalToAnomalyDataSet,
          });
        }
        newAnomalyDataSet.push(normalDataSet[i]);
        i++;
      }

      if (newAnomalyDataSet.length > 0) {
        anomalyInfoSet.push({
          startTime,
          endTime,
          main,
          data: newAnomalyDataSet,
        });
      }

      if (i === normalDataSet.length) {
        break;
      }
    }

    const newNormalDataSet = [];
    while (i < normalDataSet.length) {
      if (newNormalDataSet.length === 0 && i > 0) {
        const anomalyToNormalDataSet = [];
        // From anomaly to normal
        anomalyToNormalDataSet.push(normalDataSet[i - 1]);
        anomalyToNormalDataSet.push(normalDataSet[i]);
        connectInfoSet.push({
          data: anomalyToNormalDataSet,
        });
      }

      newNormalDataSet.push(normalDataSet[i]);
      i++;
    }

    if (newNormalDataSet.length > 0) {
      normalInfoSet.push({
        data: newNormalDataSet,
      });
    }

    const normalDataTraces = [];
    for (let normalInfo of normalInfoSet) {
      const processNormalDataSet = this.normalizeData(normalInfo.data, detectInterval);
      const normalDataTrace = this.getNormalDataTraceTemplate(
        processNormalDataSet,
        colorSet.NORMAL,
        "Normal",
        "y",
        "skip"
      );
      normalDataTraces.push(normalDataTrace);
    }

    const connectTraces = [];
    for (let connectInfo of connectInfoSet) {
      const processConnectDataSet = this.normalizeData(
        connectInfo.data,
        detectInterval
      );
      const connectDataTrace = this.getNormalDataTraceTemplate(
        processConnectDataSet,
        colorSet.NORMAL,
        "Normal",
        "y",
        "skip"
      );
      connectTraces.push(connectDataTrace);
    }

    const pointMarkTraces = [];
    const anomalyDataTraces = [];
    for (let anomalyInfo of anomalyInfoSet) {
      const processAnomalyDataSet = this.normalizeData(anomalyInfo.data);
      const currentAnomalyDataTrace = this.getAnomalyDataTraceTemplate(
        processAnomalyDataSet,
        colorSet.INCIDENT,
        "Anomaly",
        "y",
        "skip"
      );
      const currentPointMarkTrace = this.getPointMarkTemplate(
        { x: anomalyInfo.data[0].timestamp, y: anomalyInfo.data[0].value },
        colorSet.INCIDENT
      );
      pointMarkTraces.push(currentPointMarkTrace);
      anomalyDataTraces.push(currentAnomalyDataTrace);
    }

    return {
      normalDataTraces,
      connectTraces,
      pointMarkTraces,
      anomalyDataTraces,
    };
  }

  getIndicatorAndXmaxTraces(
    incidentList,
    colorSet,
    config,
    indicatorText = "Drift Detected",
    isIncidentOnly = false
  ) {
    const sortedIncidentList = [...incidentList].sort(
      (a, b) => a.startTime - b.startTime
    );
    const indicatorAndXmaxTraces = [];
    const { yAxisMin, yAxisMax, yAxis2Min, yAxis2Max, incidentTextHeight } = config;

    for (let incident of sortedIncidentList) {
      const { indicatorTrace: currentIndicatorTrace, xMaxTrace: currentXMaxTrace } =
        this.getIndicatorTrace(
          yAxis2Min || yAxisMin || 0,
          yAxis2Max || yAxisMax || 0,
          incident.startTime,
          incident.endTime,
          incidentTextHeight,
          getIncidentColorCode(incident),
          incident.skipIndicatorText !== true ? indicatorText : "",
          incident.main,
          isIncidentOnly
        );
      indicatorAndXmaxTraces.push(currentIndicatorTrace);
      if (!isIncidentOnly) {
        indicatorAndXmaxTraces.push(currentXMaxTrace);
      }
    }

    return indicatorAndXmaxTraces;
  }

  getBoundaryTraces(lowerBoundDataSet, upperBoundDataSet, layoutConfig, colorSet) {
    const processLowerBoundDataSet = this.normalizeData(lowerBoundDataSet);
    const processUpperBoundDataSet = this.normalizeData(upperBoundDataSet);
    if (
      processLowerBoundDataSet.x.length === 0 &&
      processUpperBoundDataSet.x.length === 0
    ) {
      return { lowerBoundDataTrace: null, upperBoundDataTrace: null };
    }

    const { yAxisMin, yAxisMax, metricTextHeight } = layoutConfig;
    if (processLowerBoundDataSet.x.length === 0) {
      const upperBoundxAxisMin = processUpperBoundDataSet.x[0];
      const upperBoundxAxisMax =
        processUpperBoundDataSet.x[processUpperBoundDataSet.x.length - 1];
      processLowerBoundDataSet.x = [upperBoundxAxisMin, upperBoundxAxisMax];
      processLowerBoundDataSet.y = [yAxisMin, yAxisMin];
    } else if (processUpperBoundDataSet.x.length === 0) {
      const lowerBoundxAxisMin = processLowerBoundDataSet.x[0];
      const lowerBoundxAxisMax =
        processLowerBoundDataSet.x[processLowerBoundDataSet.x.length - 1];
      processUpperBoundDataSet.x = [lowerBoundxAxisMin, lowerBoundxAxisMax];
      processUpperBoundDataSet.y = [
        yAxisMax - metricTextHeight,
        yAxisMax - metricTextHeight,
      ];
    }

    const lowerBoundDataTrace = this.getLowerBoundDataTrace(
      processLowerBoundDataSet,
      colorSet
    );
    const upperBoundDataTrace = this.getUpperBoundDataTrace(
      processUpperBoundDataSet,
      colorSet,
      lowerBoundDataTrace !== null
    );
    return { lowerBoundDataTrace, upperBoundDataTrace };
  }

  getNonCPTraces(data, incidentList, detectInterval, layoutConfig, isIncidentOnly) {
    const metricsColorSet = BrightColorSet;

    /*
    const projectedTrace = this.getProjectTraceTemplate(
      this.normalizeData(data.projectedData, detectInterval), metricsColorSet, 'Expectation'
    );
    */

    const { normalDataTraces, connectTraces, pointMarkTraces, anomalyDataTraces } =
      this.getRealDataTrace(
        data.normalData,
        incidentList,
        detectInterval,
        metricsColorSet,
        layoutConfig,
        isIncidentOnly
      );

    const { lowerBoundDataTrace, upperBoundDataTrace } = this.getBoundaryTraces(
      data.lowerBoundData,
      data.upperBoundData,
      this.props.layoutConfig,
      metricsColorSet
    );

    const signalDataTrace = this.getSignalDataTraceTemplate(
      this.normalizeData(data.signalData),
      metricsColorSet.RAW,
      "Metric"
    );

    let indicatorAndXmaxTraces = [];
    if (
      normalDataTraces.length > 0 ||
      anomalyDataTraces.length > 0 ||
      connectTraces.length > 0 ||
      signalDataTrace
    ) {
      indicatorAndXmaxTraces = this.getIndicatorAndXmaxTraces(
        incidentList,
        metricsColorSet,
        layoutConfig,
        "",
        isIncidentOnly
      );
    }

    // Order here is very important for it affects how it shows on the plot.
    return [
      ...indicatorAndXmaxTraces,

      // Boundary trace
      lowerBoundDataTrace,
      upperBoundDataTrace,

      // Project Trace
      // projectedTrace,

      // Real data trace
      ...normalDataTraces,
      ...anomalyDataTraces,
      ...connectTraces,

      // Signal Trace
      signalDataTrace,

      // Point Mark
      ...pointMarkTraces,
    ];
  }

  getCPTraces(data, incidentList, detectInterval, layoutConfig, isIncidentOnly) {
    const rawNormalData = data.normalData;

    const sortedIncidentList = [...incidentList].sort(
      (a, b) => a.startTime - b.startTime
    );
    let currentIncidentIndex = 0;
    let currentDataArray = [];

    let pointMarkTraces = [];
    let normalDataTraces = [];
    let anomalyDataTraces = [];
    for (let rawNormalDataPoint of rawNormalData) {
      currentDataArray.push(rawNormalDataPoint);
      if (currentIncidentIndex === incidentList.length) {
        continue;
      }

      if (
        rawNormalDataPoint.timestamp >=
        sortedIncidentList[currentIncidentIndex].startTime
      ) {
        const pointMarkTrace = this.getPointMarkTemplate(
          {
            x: rawNormalDataPoint.timestamp,
            y: rawNormalDataPoint.value,
          },
          sortedIncidentList[currentIncidentIndex].main
            ? BrightColorSet.INCIDENT
            : "#E0E0E0"
        );
        pointMarkTraces.push(pointMarkTrace);

        if (currentIncidentIndex % 2 === 0) {
          const processNormalDataSet = this.normalizeData(
            currentDataArray,
            detectInterval
          );
          const normalDataTrace = this.getNormalDataTraceTemplate(
            processNormalDataSet,
            TrendChangePointColorSet.STATS_BEFORE,
            "Stats before"
          );
          normalDataTraces.push(normalDataTrace);
        } else {
          const processAnomalyDataSet = this.normalizeData(currentDataArray);
          const anomalyDataTrace = this.getAnomalyDataTraceTemplate(
            processAnomalyDataSet,
            TrendChangePointColorSet.STATS_AFTER,
            "Stats after"
          );
          anomalyDataTraces.push(anomalyDataTrace);
        }

        // Reset the array buffer for next batch.
        currentIncidentIndex += 1;
        currentDataArray = [rawNormalDataPoint];
      }
    }

    if (currentDataArray.length > 1) {
      if (currentIncidentIndex % 2 === 0) {
        const processNormalDataSet = this.normalizeData(
          currentDataArray,
          detectInterval
        );
        const normalDataTrace = this.getNormalDataTraceTemplate(
          processNormalDataSet,
          TrendChangePointColorSet.STATS_BEFORE,
          "Stats before"
        );
        normalDataTraces.push(normalDataTrace);
      } else {
        const processAnomalyDataSet = this.normalizeData(currentDataArray);
        const anomalyDataTrace = this.getAnomalyDataTraceTemplate(
          processAnomalyDataSet,
          TrendChangePointColorSet.STATS_AFTER,
          "Stats after"
        );
        anomalyDataTraces.push(anomalyDataTrace);
      }
    }

    currentDataArray = [];
    currentIncidentIndex = 0;

    const rawSignalData = data.signalData;
    const signalNormalDataTraces = [];
    const signalAnomalyDataTraces = [];
    const signalConnectTraces = [];
    let signalConnectDataArray = [];
    let signalConnectTrace = null;

    for (let i = 0; i < rawSignalData.length; i++) {
      const rawSignalDataPoint = rawSignalData[i];
      currentDataArray.push(rawSignalDataPoint);
      if (currentIncidentIndex === incidentList.length) {
        continue;
      }

      if (
        rawSignalDataPoint.timestamp >=
        sortedIncidentList[currentIncidentIndex].startTime
      ) {
        if (currentIncidentIndex % 2 === 0) {
          const processSignalNormalDataSet = this.normalizeData(
            currentDataArray,
            detectInterval
          );
          const signalNormalDataTrace = this.getSignalDataTraceTemplate(
            processSignalNormalDataSet,
            TrendChangePointColorSet.RAW_BEFORE,
            "Metric before"
          );
          signalNormalDataTraces.push(signalNormalDataTrace);
        } else {
          const processSignalAnomalyDataSet = this.normalizeData(currentDataArray);
          const signalAnomalyDataTrace = this.getSignalDataTraceTemplate(
            processSignalAnomalyDataSet,
            TrendChangePointColorSet.RAW_AFTER,
            "Metric after"
          );
          signalAnomalyDataTraces.push(signalAnomalyDataTrace);
        }

        if (signalNormalDataTraces.length > 0 && i < rawSignalData.length - 1) {
          signalConnectDataArray = [rawSignalDataPoint, rawSignalData[i + 1]];
          signalConnectTrace = this.getSignalDataTraceTemplate(
            this.normalizeData(signalConnectDataArray),
            currentIncidentIndex % 2 === 0
              ? TrendChangePointColorSet.RAW_AFTER
              : TrendChangePointColorSet.RAW_BEFORE,
            "",
            "none"
          );
          signalConnectTraces.push(signalConnectTrace);
        }
        // Reset the array buffer for next batch.
        currentIncidentIndex += 1;
        currentDataArray = [];
      }
    }

    if (currentDataArray.length > 0) {
      if (currentIncidentIndex % 2 === 0) {
        const processSignalNormalDataSet = this.normalizeData(
          currentDataArray,
          detectInterval
        );
        const signalNormalDataTrace = this.getSignalDataTraceTemplate(
          processSignalNormalDataSet,
          TrendChangePointColorSet.RAW_BEFORE,
          "Metric before"
        );
        signalNormalDataTraces.push(signalNormalDataTrace);
      } else {
        const processSignalAnomalyDataSet = this.normalizeData(currentDataArray);
        const signalAnomalyDataTrace = this.getSignalDataTraceTemplate(
          processSignalAnomalyDataSet,
          TrendChangePointColorSet.RAW_AFTER,
          "Metric after"
        );
        signalAnomalyDataTraces.push(signalAnomalyDataTrace);
      }
    }

    let indicatorTraces = [];
    if (
      normalDataTraces.length > 0 ||
      anomalyDataTraces.length > 0 ||
      signalNormalDataTraces.length > 0 ||
      signalAnomalyDataTraces.length > 0 ||
      signalConnectTraces.length > 0
    ) {
      indicatorTraces = this.getIndicatorAndXmaxTraces(
        incidentList,
        BrightColorSet,
        layoutConfig,
        "",
        true
      );
    }

    return [
      ...indicatorTraces,
      ...normalDataTraces,
      ...anomalyDataTraces,
      ...signalNormalDataTraces,
      ...signalAnomalyDataTraces,
      ...signalConnectTraces,
      ...pointMarkTraces,
    ];
  }

  render() {
    const {
      data,
      incidentList = [],
      selectedSeason,
      detectInterval = 0,
      yTitle,
      y2Title,
      layoutConfig: {
        xAxisMin,
        xAxisMax,
        yAxisMin,
        yAxisMax,
        yAxis2Min,
        yAxis2Max,
        autosize,
        height = 350,
        marginLeft = 0,
        marginTop = 25,
        marginBottom = 20,
        marginRight = 0,
        incidentTextHeight = 0,
      },
      isCPIncident,
      isIncidentOnly = false,
    } = this.props;

    const isNormalEnabled =
      data.normalData &&
      data.normalData.length > 0 &&
      data.lowerBoundData &&
      data.lowerBoundData.length > 0 &&
      data.upperBoundData &&
      data.upperBoundData.length > 0;

    const isSignalEnabled = data.signalData && data.signalData.length > 0;

    if (!isNormalEnabled && !isSignalEnabled) {
      return <ZeroStatusChart height={autosize ? "100%" : height} />;
    }

    const xaxis = {
      zeroline: false,
      showgrid: false,
      showline: true,
      linewidth: 1,
      linecolor: NextGenPalette.darkGrey,
      tickfont: {
        size: 10,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        color: NextGenPalette.slate,
      },
      tickformat: timestampDateTickFormat,
      hoverformat: timestampDateTimeTickFormat,
      ticks: "outside",
      ticklen: 6,
      tickcolor: "rgb(255, 255, 255)",
      fixedrange: true,
    };

    if (typeof xAxisMin === "number" && typeof xAxisMax === "number" && xAxisMax > 0) {
      // Add a bit of breathing room on each end of the x-axis so that min / max data points, which have a bit
      // of width to them, do not appear cut off.
      const xAxisRangePadding = (xAxisMax - xAxisMin) * 0.01;
      Object.assign(xaxis, {
        range: [
          getStringFromTimeStamp(xAxisMin - xAxisRangePadding, { graph: true }),
          getStringFromTimeStamp(xAxisMax + xAxisRangePadding, { graph: true }),
        ],
      });
    }

    const yaxis = {
      zeroline: false,
      autorange: false,
      side: "right",
      showline: isNormalEnabled || !isSignalEnabled,
      linewidth: 1,
      linecolor: NextGenPalette.darkGrey,
      tickfont: {
        size: 10,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        color: NextGenPalette.slate,
      },
      showgrid: false,
      fixedrange: true,
      automargin: true,
    };

    if (yTitle) {
      Object.assign(yaxis, {
        title: {
          text: yTitle,
          font: {
            color: NextGenPalette.slate,
            family: "Neue-Haas-Unica-W1G, Sans-serif",
            size: 10,
          },
        },
      });
    }

    if (typeof yAxisMin === "number" && typeof yAxisMax === "number") {
      Object.assign(yaxis, { range: [yAxisMin, yAxisMax] });
    }

    const yaxis2 = {
      title: {
        text: y2Title || "Metric Data Scale",
        font: {
          color: NextGenPalette.slate,
          family: "Neue-Haas-Unica-W1G, Sans-serif",
          size: 10,
        },
        standoff: 10,
      },
      showgrid: false,
      zeroline: false,
      showline: false, //!isNormalEnabled && isSignalEnabled,
      linewidth: 1,
      linecolor: NextGenPalette.darkGrey,
      tickfont: {
        size: 10,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        color: NextGenPalette.slate,
      },
      ticks: "outside",
      ticklen: 2,
      tickcolor: "rgb(255, 255, 255)",
      titlefont: { color: NextGenPalette.slate },
      overlaying: "y",
      side: "left",
      fixedrange: true,
      automargin: true,
    };

    if (typeof yAxis2Min === "number" && typeof yAxis2Max === "number") {
      Object.assign(yaxis2, { range: [yAxis2Min, yAxis2Max] });
    }

    let traceCandidateArray = [];
    if (isCPIncident) {
      traceCandidateArray = this.getCPTraces(
        data,
        incidentList,
        detectInterval,
        this.props.layoutConfig,
        isIncidentOnly
      );
    } else {
      traceCandidateArray = this.getNonCPTraces(
        data,
        incidentList,
        detectInterval,
        this.props.layoutConfig,
        isIncidentOnly
      );
    }

    let plotlyData = [];
    traceCandidateArray.forEach(function (traceCandidate) {
      if (!traceCandidate) {
        return;
      }
      plotlyData.push(traceCandidate);
    });

    const shapes = [];
    if (selectedSeason) {
      const seasonRect = {
        type: "rect",
        x0: getStringFromTimeObject(selectedSeason.start, { graph: true }),
        y0: yAxisMin,
        x1: getStringFromTimeObject(selectedSeason.end, { graph: true }),
        y1: yAxisMax - incidentTextHeight,
        line: {
          color: LightColorSet.SEASON,
          width: 0,
        },
        fillcolor: getRGBAColor(LightColorSet.SEASON, 0.5),
      };
      shapes.push(seasonRect);
    }

    let annotations = [];
    const mainIncident = incidentList.find(({ main }) => main);
    if (mainIncident && !mainIncident.skipIndicatorText) {
      annotations = [
        {
          x: getStringFromTimeStamp(mainIncident.startTime, { graph: true }),
          y: yAxisMax,
          xref: "x",
          yref: "y",
          yshift: -4,
          text: "<b>&nbsp;" + mainIncident.id + "&nbsp;</b>",
          showarrow: false,
          font: {
            family: "Neue-Haas-Unica-W1G",
            size: 10,
            color: "#ffffff",
          },
          align: "center",
          arrowcolor: NextGenPalette.purple,
          bgcolor: NextGenPalette.purple,
          opacity: 0.8,
          borderpad: 1,
        },
      ];
    }

    const layout = {
      margin: {
        l: marginLeft,
        t: marginTop,
        b: marginBottom,
        r: marginRight,
      },
      xaxis,
      yaxis,
      yaxis2,
      shapes,
      annotations,
      hovermode: "x unified",
    };

    if (autosize) {
      layout.autosize = true;
    } else {
      layout.height = height;
    }

    const config = {
      displayModeBar: false,
      plot_bgcolor: "#F7F7F7",
      showlegend: false,
      responsive: true,
    };

    return (
      <Plot
        data={plotlyData}
        layout={layout}
        config={config}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
      />
    );
  }
}

export default IncidentDetailLineView;
