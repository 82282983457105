import React from "react";
import { Typography } from "antd";

import "./text-tooltip.scss";

function TextTooltip(props) {
  let { text, hoverText, className = "" } = props;
  hoverText = hoverText ? hoverText : text;
  return (
    <div className={`text-tooltip-container ${className}`}>
      <Typography.Paragraph
        ellipsis={{
          tooltip: <span>{hoverText}</span>,
        }}
      >
        {text}
      </Typography.Paragraph>
    </div>
  );
}

export default TextTooltip;
