import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import Button from "../../../components/button/ng-button";
import { SettingOutlined } from "@ant-design/icons";
import {
  LabeledSelect,
  LabeledMultiSelect,
} from "../../../components/labeled-control/labeled-control";
import TakeoverPanel from "../../../components/takeover/takeover-panel";
import { incidentStatusOptions, TimezoneOptions } from "../../../utils/options";
import DurationInput from "../../../components/duration-input/duration-input";

import "./takeovers.scss";

function SettingsTakeover(props) {
  const {
    canUpdateConfiguration,
    workspaceUuid,
    timezone: responseTimezone,
    evalDelay: responseEvalDelay,
    statusFilters: responseStatusFilters = [],
    dataSourceFilters: responseDataSourceFilters = [],
    dataSourceList,
    getDataSourceList,
    closeTakeover,
    onSave,
  } = props;

  const [selectedTimezone, setSelectedTimezone] = useState(responseTimezone);
  const [evalDelay, setEvalDelay] = useState(responseEvalDelay);
  const [dataSourceFilters, setDataSourceFilters] = useState(responseDataSourceFilters);
  const [statusFilters, setStatusFilters] = useState(responseStatusFilters);

  useEffect(() => {
    getDataSourceList(workspaceUuid);
  }, [workspaceUuid, getDataSourceList]);

  const timezoneOpt = TimezoneOptions.find((tzOpt) => tzOpt.value === responseTimezone);
  const dataSourceOpts = dataSourceList.data.map((source) => ({
    label: source.metadata.name,
    value: source.metadata.uuid,
  }));

  function onSaveClick() {
    onSave({
      timezone: selectedTimezone,
      evaluationDelay: evalDelay,
      statusFilters,
      dataSourceFilters,
    });
  }

  const cornerControls = (
    <div className="dq-dash-takeover-buttons">
      <Button outline size="large" onClick={() => closeTakeover()}>
        Cancel
      </Button>
      <Button
        primary
        size="large"
        onClick={() => onSaveClick()}
        disabled={!canUpdateConfiguration}
      >
        Save
      </Button>
    </div>
  );

  const loading = dataSourceList.loading;

  return (
    <TakeoverPanel
      title="Settings"
      titleIcon={<SettingOutlined />}
      cornerControls={cornerControls}
    >
      {loading ? (
        <div className="dq-dash-takeover-loading">
          <Skeleton active />
        </div>
      ) : (
        <div className="dq-dash-takeover-fields">
          <div className="dq-dash-takeover-label">
            DQ Settings
            <div className="dq-dash-takeover-label-sub">
              Saved settings and filters will be visible to all users
            </div>
          </div>
          <div className="dq-dash-takeover-field">
            <LabeledSelect
              id="timezone-select"
              label="Timezone"
              placeholder="Select timezone..."
              options={TimezoneOptions}
              defaultValue={timezoneOpt}
              onChange={setSelectedTimezone}
              showSearch
              notFoundContent="No matching timezone"
              staticLabel
              disabled={!canUpdateConfiguration}
            />
          </div>
          <div className="dq-dash-takeover-field">
            <DurationInput
              label="Evaluation Delay"
              value={evalDelay}
              onChange={setEvalDelay}
              disabled={!canUpdateConfiguration}
            />
          </div>
          <div className="dq-dash-takeover-label">Filters</div>
          <div className="dq-dash-takeover-field">
            <LabeledMultiSelect
              id="dq-dash-settings-filter-datasource-select"
              label="Datasource"
              placeholder="Select datasource..."
              options={dataSourceOpts}
              onChange={setDataSourceFilters}
              value={dataSourceFilters}
              maxTagCount={3}
              disabled={!canUpdateConfiguration}
            />
          </div>
          <div className="dq-dash-takeover-field">
            <LabeledMultiSelect
              id="dq-dash-settings-filter-status-select"
              label="Incident Status"
              placeholder="Select incident status..."
              options={incidentStatusOptions}
              onChange={setStatusFilters}
              value={statusFilters}
              maxTagCount={3}
              disabled={!canUpdateConfiguration}
            />
          </div>
        </div>
      )}
    </TakeoverPanel>
  );
}

export default SettingsTakeover;
