import { connect } from "react-redux";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import { getRuleList } from "../../actions/rule/rule-action";
import { getTagList } from "../../actions/tag/tag-action";
import {
  deleteKpis,
  getKpiList,
  getKpiIncidentList,
  updateKpiTags,
  toggleKpisLiveStatus,
  triggerKpis,
  getKpiListPageConfiguration,
  updateKpiListPageConfiguration,
  addKpi,
} from "../../actions/kpi/kpi-action";
import MetricsList from "./metric-list";
import { getIntegrationList } from "../../actions/integration/integration-action";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  kpiListData: state.kpiReducer.kpiList,
  kpiIncidentList: state.kpiReducer.kpiIncidentList,
  kpiListPageConfiguration: state.kpiReducer.kpiListPageConfiguration,
  dataSourceList: state.dataSourceReducer.dataSourceList,
  ruleList: state.ruleReducer.ruleList,
  tagList: state.tagReducer.tagList,
  workspaceList: state.adminReducer.userWorkspaceList.data,
  integrationList: state.integrationReducer.integrationList.data,
});

const mapDispatchToProps = (dispatch) => ({
  deleteKpis: (workspaceUuid, metrics) => dispatch(deleteKpis(workspaceUuid, metrics)),
  triggerKpis: (workspaceUuid, metrics) =>
    dispatch(triggerKpis(workspaceUuid, metrics)),
  updateKpiTags: (workspaceUuid, metric, newTags) =>
    dispatch(updateKpiTags(workspaceUuid, metric, newTags)),
  toggleKpisLiveStatus: (workspaceUuid, metrics, newIsLive) =>
    dispatch(toggleKpisLiveStatus(workspaceUuid, metrics, newIsLive)),
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getKpiList: (workspaceUuid, options = {}) =>
    dispatch(getKpiList(workspaceUuid, options)),
  getKpiListPageConfiguration: (workspaceUuid) =>
    dispatch(getKpiListPageConfiguration(workspaceUuid)),
  updateKpiListPageConfiguration: (workspaceUuid, pageConfiguration) =>
    dispatch(updateKpiListPageConfiguration(workspaceUuid, pageConfiguration)),
  getKpiIncidentList: (workspaceUuid, queryObject) =>
    dispatch(getKpiIncidentList(workspaceUuid, queryObject)),
  getRuleList: (workspaceUuid) => dispatch(getRuleList(workspaceUuid)),
  getTagList: (workspaceUuid) => dispatch(getTagList(workspaceUuid)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  addKpi: (workspaceUuid, newKpi) => dispatch(addKpi(workspaceUuid, newKpi)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MetricsList);
