import React from "react";
import { NextGenPalette } from "../../utils/color";

export function DataSourceIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M20.75 5.60156C20.75 4.16016 17.1992 3 12.875 3C8.51562 3 5 4.16016 5 5.60156V7.18359C5 8.625 8.51562 9.75 12.875 9.75C17.1992 9.75 20.75 8.625 20.75 7.18359V5.60156ZM20.75 9.1875C19.0273 10.3828 15.9336 10.9102 12.875 10.9102C9.78125 10.9102 6.6875 10.3828 5 9.1875V12.8086C5 14.25 8.51562 15.375 12.875 15.375C17.1992 15.375 20.75 14.25 20.75 12.8086V9.1875ZM20.75 14.8125C19.0273 16.0078 15.9336 16.5352 12.875 16.5352C9.78125 16.5352 6.6875 16.0078 5 14.8125V18.4336C5 19.875 8.51562 21 12.875 21C17.1992 21 20.75 19.875 20.75 18.4336V14.8125Z"
        fill="#868786"
      ></path>
    </svg>
  );
}

export function KPIIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M13.6719 17H15.043C15.2539 17 15.5 16.7656 15.5 16.5312V11.2578C15.5 11.0234 15.2539 10.75 15.043 10.75H13.6719C13.4609 10.75 13.25 11.0234 13.25 11.2578V16.5312C13.25 16.7656 13.4609 17 13.6719 17ZM17.0469 17H18.418C18.6289 17 18.875 16.7656 18.875 16.5312V7.50781C18.875 7.27344 18.6289 7 18.418 7H17.0469C16.8359 7 16.625 7.27344 16.625 7.50781V16.5312C16.625 16.7656 16.8359 17 17.0469 17ZM6.92188 17H8.29297C8.50391 17 8.75 16.7656 8.75 16.5312V13.7578C8.75 13.5234 8.50391 13.25 8.29297 13.25H6.92188C6.71094 13.25 6.5 13.5234 6.5 13.7578V16.5312C6.5 16.7656 6.71094 17 6.92188 17ZM10.2969 17H11.668C11.8789 17 12.125 16.7656 12.125 16.5312V8.75781C12.125 8.52344 11.8789 8.25 11.668 8.25H10.2969C10.0859 8.25 9.875 8.52344 9.875 8.75781V16.5312C9.875 16.7656 10.0859 17 10.2969 17ZM20.4375 18.5H4.25V4.625C4.25 4.3125 3.96875 4 3.6875 4H3.5625C3.24609 4 3 4.3125 3 4.625V18.75C3 19.4531 3.49219 20 4.125 20H20.4375C20.7188 20 21 19.7266 21 19.375V19.125C21 18.8125 20.7188 18.5 20.4375 18.5Z"
        fill="#868786"
      />
    </svg>
  );
}

export function SliceIcon(props) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12.1599 19.0543C11.9829 19.1565 11.7802 19.233 11.5643 19.2845L11.7985 19.6112C11.1995 19.7543 10.5222 19.7543 9.92323 19.6112L10.1573 19.2845C9.94151 19.233 9.73874 19.1565 9.5618 19.0543L8.69578 18.5543L8.26276 18.8043L6.53071 17.8043L6.96373 17.5543L5.23167 16.5543L4.79866 16.8043L3.06661 15.8043L3.49962 15.5543L2.6336 15.0543C2.45666 14.9522 2.32416 14.8351 2.23486 14.7105L1.66901 14.8457C1.42119 14.4998 1.42119 14.1088 1.66901 13.763L2.23486 13.8982C2.32416 13.7735 2.45666 13.6565 2.6336 13.5543L3.41026 13.1059L2.97725 12.8559L4.53058 11.9591L4.96359 12.2091L6.51692 11.3123L6.0839 11.0623L7.63723 10.1655L8.07024 10.4155L8.84691 9.96707C8.94075 9.91288 9.04353 9.86526 9.15319 9.8246L8.82228 9.52711C9.11811 9.41742 9.45157 9.34575 9.79961 9.31705L9.8862 9.66705C10.0152 9.65641 10.1469 9.65364 10.2793 9.6592L10.6648 9.6754L10.7092 9.32278L11.4802 9.35517L11.4358 9.7078L11.8213 9.72399C11.9309 9.7286 12.0388 9.73883 12.1439 9.75438L12.2959 9.4119C12.5785 9.4537 12.846 9.52434 13.0856 9.62044L12.7362 9.91079C12.8253 9.94655 12.9093 9.987 12.987 10.0319L13.7496 10.4722L14.1826 10.2222L15.7079 11.1028L15.2749 11.3528L16.8002 12.2334L17.2332 11.9834L18.7585 12.864L18.3254 13.114L19.0881 13.5543C19.265 13.6565 19.3975 13.7735 19.4868 13.8982L20.0527 13.763C20.3005 14.1088 20.3005 14.4998 20.0527 14.8457L19.4868 14.7105C19.3975 14.8351 19.265 14.9522 19.0881 15.0543L18.2221 15.5543L18.6551 15.8043L16.923 16.8043L16.49 16.5543L14.758 17.5543L15.191 17.8043L13.4589 18.8043L13.0259 18.5543L12.1599 19.0543Z"
        fill="white"
        stroke="#868786"
        strokeDasharray="2 2"
      />
      <path
        d="M12.1599 15.4022C11.9829 15.5043 11.7802 15.5808 11.5643 15.6324L11.7985 15.9591C11.1995 16.1021 10.5222 16.1021 9.92323 15.9591L10.1573 15.6324C9.94151 15.5808 9.73874 15.5043 9.5618 15.4022L8.69578 14.9022L8.26276 15.1522L6.53071 14.1522L6.96373 13.9022L5.23167 12.9022L4.79866 13.1522L3.06661 12.1522L3.49962 11.9022L2.6336 11.4022C2.45666 11.3 2.32416 11.1829 2.23486 11.0583L1.66901 11.1935C1.42119 10.8477 1.42119 10.4566 1.66901 10.1108L2.23486 10.246C2.32416 10.1214 2.45666 10.0043 2.6336 9.90216L3.41026 9.45375L2.97725 9.20375L4.53058 8.30694L4.96359 8.55694L6.51692 7.66013L6.0839 7.41013L7.63723 6.51331L8.07024 6.76331L8.84691 6.31491C8.94075 6.26072 9.04353 6.2131 9.15319 6.17244L8.82228 5.87495C9.11811 5.76526 9.45157 5.69359 9.79961 5.66489L9.8862 6.01489C10.0152 6.00425 10.1469 6.00148 10.2793 6.00704L10.6648 6.02324L10.7092 5.67062L11.4802 5.70301L11.4358 6.05564L11.8213 6.07183C11.9309 6.07644 12.0388 6.08667 12.1439 6.10222L12.2959 5.75974C12.5785 5.80154 12.846 5.87218 13.0856 5.96828L12.7362 6.25863C12.8253 6.29438 12.9093 6.33484 12.987 6.37969L13.7496 6.82L14.1826 6.57L15.7079 7.45062L15.2749 7.70062L16.8002 8.58123L17.2332 8.33123L18.7585 9.21185L18.3254 9.46185L19.0881 9.90216C19.265 10.0043 19.3975 10.1214 19.4868 10.246L20.0527 10.1108C20.3005 10.4566 20.3005 10.8477 20.0527 11.1935L19.4868 11.0583C19.3975 11.1829 19.265 11.3 19.0881 11.4022L18.2221 11.9022L18.6551 12.1522L16.923 13.1522L16.49 12.9022L14.758 13.9022L15.191 14.1522L13.4589 15.1522L13.0259 14.9022L12.1599 15.4022Z"
        fill="white"
        stroke="#868786"
        strokeDasharray="2 2"
      />
      <path
        d="M19.0881 6.75L12.1599 10.75C11.4424 11.1642 10.2792 11.1642 9.5618 10.75L2.6336 6.75C1.91616 6.33579 1.91616 5.66421 2.6336 5.25L9.34132 1.37729C9.48918 1.29193 9.6612 1.22176 9.84996 1.16982L9.99306 1.13044C10.5258 0.983837 11.1482 0.993946 11.6659 1.15762L12.1735 1.31812C12.3265 1.3665 12.4668 1.42721 12.5902 1.49842L19.0881 5.25C19.8055 5.66421 19.8055 6.33579 19.0881 6.75Z"
        fill="#868786"
        stroke="#868786"
      />
    </svg>
  );
}

export function RuleIcon(props) {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 4.70752L4.95672 0.5H12.722C14.6838 0.5 16.222 1.99008 16.222 3.76562V14.7728C16.222 16.5484 14.6838 18.0385 12.722 18.0385H4C2.03813 18.0385 0.5 16.5484 0.5 14.7728V4.70752Z"
        fill="white"
        stroke="#868786"
      />
      <path
        d="M4.30774 6.78686C4.30774 6.55806 4.50476 6.37259 4.74779 6.37259H12.5587C12.8017 6.37259 12.9988 6.55806 12.9988 6.78686C12.9988 7.01565 12.8017 7.20112 12.5587 7.20112H4.74779C4.50476 7.20112 4.30774 7.01565 4.30774 6.78686Z"
        fill="#868786"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5587 7.20112C12.8017 7.20112 12.9988 7.01565 12.9988 6.78686C12.9988 6.55806 12.8017 6.37259 12.5587 6.37259H4.74779C4.50476 6.37259 4.30774 6.55806 4.30774 6.78686C4.30774 7.01565 4.50476 7.20112 4.74779 7.20112C4.74779 7.20112 4.74779 7.20112 4.74779 7.20112H12.5587Z"
        fill="#868786"
      />
      <path
        d="M4.30774 10.101C4.30774 9.87217 4.50476 9.6867 4.74779 9.6867H12.5587C12.8017 9.6867 12.9988 9.87217 12.9988 10.101C12.9988 10.3298 12.8017 10.5152 12.5587 10.5152H4.74779C4.50476 10.5152 4.30774 10.3298 4.30774 10.101Z"
        fill="#868786"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5587 10.5152C12.8017 10.5152 12.9988 10.3298 12.9988 10.101C12.9988 9.87217 12.8017 9.6867 12.5587 9.6867H4.74779C4.50476 9.6867 4.30774 9.87217 4.30774 10.101C4.30774 10.3298 4.50476 10.5152 4.74779 10.5152C4.74779 10.5152 4.74779 10.5152 4.74779 10.5152H12.5587Z"
        fill="#868786"
      />
      <path
        d="M4.30774 13.4151C4.30774 13.1863 4.50476 13.0009 4.74779 13.0009H12.5587C12.8017 13.0009 12.9988 13.1863 12.9988 13.4151C12.9988 13.6439 12.8017 13.8294 12.5587 13.8294H4.74779C4.50476 13.8294 4.30774 13.6439 4.30774 13.4151Z"
        fill="#868786"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5587 13.8294C12.8017 13.8294 12.9988 13.6439 12.9988 13.4151C12.9988 13.1863 12.8017 13.0009 12.5587 13.0009H4.74779C4.50476 13.0009 4.30774 13.1863 4.30774 13.4151C4.30774 13.6439 4.50476 13.8294 4.74779 13.8294C4.74779 13.8294 4.74779 13.8294 4.74779 13.8294H12.5587Z"
        fill="#868786"
      />
    </svg>
  );
}

export function FilterSummaryIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M11.4429 9H12.5523C12.6585 9 12.7429 9.0875 12.7398 9.19375L12.5054 15.3188C12.5023 15.4188 12.4179 15.5 12.3179 15.5H11.6773C11.5773 15.5 11.4929 15.4219 11.4898 15.3188L11.2554 9.19375C11.2523 9.0875 11.3367 9 11.4429 9ZM11.9992 16.125C11.5148 16.125 11.1242 16.5156 11.1242 17C11.1242 17.4844 11.5148 17.875 11.9992 17.875C12.4835 17.875 12.8742 17.4844 12.8742 17C12.8742 16.5156 12.4835 16.125 11.9992 16.125ZM20.796 17.75L13.2992 4.75C12.7242 3.75 11.2773 3.75 10.6992 4.75L3.20228 17.75C2.62728 18.7469 3.34603 20 4.50228 20H19.4992C20.6492 20 21.3742 18.75 20.796 17.75ZM19.4992 19H4.49916C4.11478 19 3.87416 18.5844 4.06478 18.25L11.5648 5.25C11.7554 4.91875 12.2398 4.91562 12.4304 5.25L19.9304 18.25C20.1242 18.5813 19.8835 19 19.4992 19Z"
        fill="#525252"
      />
    </svg>
  );
}

export function EventIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12.0938 4C10.3851 4 9 5.38511 9 7.09378C9 8.62585 10.1163 9.88892 11.5782 10.1356V14.3818L11.9329 14.914C12.0093 15.0287 12.178 15.0287 12.2547 14.914L12.6094 14.3818V10.1356C14.0712 9.88892 15.1876 8.62585 15.1876 7.09378C15.1876 5.38511 13.8024 4 12.0938 4ZM12.0938 9.1563C10.9564 9.1563 10.0313 8.23096 10.0313 7.09378C10.0313 5.95639 10.9564 5.03126 12.0938 5.03126C13.2312 5.03126 14.1563 5.95639 14.1563 7.09378C14.1563 8.23096 13.2312 9.1563 12.0938 9.1563Z"
        fill="#868786"
      />
      <path
        d="M14 16C14 17.1042 13.1042 18 12 18C10.8958 18 10 17.1042 10 16C10 14.8958 10.8958 14 12 14C13.1042 14 14 14.8958 14 16ZM19 14C17.8958 14 17 14.8958 17 16C17 17.1042 17.8958 18 19 18C20.1042 18 21 17.1042 21 16C21 14.8958 20.1042 14 19 14ZM5 14C3.89583 14 3 14.8958 3 16C3 17.1042 3.89583 18 5 18C6.10417 18 7 17.1042 7 16C7 14.8958 6.10417 14 5 14Z"
        fill="#868786"
      />
    </svg>
  );
}

export function CommentIcon(props) {
  const { width = 24 } = props;
  return (
    <svg width={width} height={width} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3C1 2.44772 1.44772 2 2 2H22C22.5523 2 23 2.44772 23 3V18C23 18.5523 22.5523 19 22 19H11.7361L6.94721 21.3944C6.63723 21.5494 6.26909 21.5329 5.97427 21.3507C5.67945 21.1684 5.5 20.8466 5.5 20.5V19H2C1.44772 19 1 18.5523 1 18V3ZM3 4V17H6.5C7.05228 17 7.5 17.4477 7.5 18V18.882L11.0528 17.1056C11.1916 17.0361 11.3448 17 11.5 17H21V4H3ZM7 8.75C7.55228 8.75 8 9.19772 8 9.75V11.25C8 11.8023 7.55228 12.25 7 12.25C6.44772 12.25 6 11.8023 6 11.25V9.75C6 9.19772 6.44772 8.75 7 8.75ZM12 8.75C12.5523 8.75 13 9.19772 13 9.75V11.25C13 11.8023 12.5523 12.25 12 12.25C11.4477 12.25 11 11.8023 11 11.25V9.75C11 9.19772 11.4477 8.75 12 8.75ZM17 8.75C17.5523 8.75 18 9.19772 18 9.75V11.25C18 11.8023 17.5523 12.25 17 12.25C16.4477 12.25 16 11.8023 16 11.25V9.75C16 9.19772 16.4477 8.75 17 8.75Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}

export function SubmitTicketIcon(props) {
  const { width = 24 } = props;
  return (
    <svg width={width} height={width} viewBox="0 0 24 24">
      <path
        d="M5.5 3C5.97668 3 6.3871 3.33646 6.48058 3.80388L6.50227 3.89388C6.55681 4.09124 6.67903 4.38696 6.88173 4.67654C7.25132 5.20451 7.75364 5.5 8.5 5.5C9.24636 5.5 9.74868 5.20451 10.1183 4.67654C10.3548 4.3387 10.4817 3.99249 10.5194 3.80388L10.5492 3.68988C10.6818 3.28272 11.063 3 11.5 3H22C22.5523 3 23 3.44772 23 4V20C23 20.5523 22.5523 21 22 21H11.5C11.0233 21 10.6129 20.6635 10.5194 20.1961L10.4977 20.1061C10.4432 19.9088 10.321 19.613 10.1183 19.3235C9.74868 18.7955 9.24636 18.5 8.5 18.5C7.75364 18.5 7.25132 18.7955 6.88173 19.3235C6.64525 19.6613 6.5183 20.0075 6.48058 20.1961L6.45075 20.3101C6.31823 20.7173 5.93696 21 5.5 21H2C1.44772 21 1 20.5523 1 20V4C1 3.44772 1.44772 3 2 3H5.5ZM4.779 5H3V19H4.779L4.79741 18.9583C4.88203 18.7705 4.98557 18.5746 5.11076 18.3759L5.24327 18.1765C5.96743 17.142 7.05886 16.5 8.5 16.5C9.94114 16.5 11.0326 17.142 11.7567 18.1765C11.9433 18.4431 12.0898 18.7079 12.2026 18.9583L12.22 19H21V5H12.22L12.1116 5.23202C12.0465 5.36042 11.9727 5.49164 11.8892 5.62409L11.7567 5.82346C11.0326 6.85799 9.94114 7.5 8.5 7.5C7.05886 7.5 5.96743 6.85799 5.24327 5.82346C5.05671 5.55695 4.91024 5.29208 4.79741 5.04174L4.779 5ZM8.5 12.5C9.01284 12.5 9.43551 12.886 9.49327 13.3834L9.5 13.5V14.5C9.5 15.0523 9.05229 15.5 8.5 15.5C7.98716 15.5 7.56449 15.114 7.50673 14.6166L7.5 14.5V13.5C7.5 12.9477 7.94772 12.5 8.5 12.5ZM18 12.5C18.5523 12.5 19 12.9477 19 13.5C19 14.0128 18.614 14.4355 18.1166 14.4933L18 14.5H12.5C11.9477 14.5 11.5 14.0523 11.5 13.5C11.5 12.9872 11.886 12.5645 12.3834 12.5067L12.5 12.5H18ZM8.5 8.5C9.01284 8.5 9.43551 8.88604 9.49327 9.38338L9.5 9.5V10.5C9.5 11.0523 9.05229 11.5 8.5 11.5C7.98716 11.5 7.56449 11.114 7.50673 10.6166L7.5 10.5V9.5C7.5 8.94772 7.94772 8.5 8.5 8.5ZM18 9.5C18.5523 9.5 19 9.94772 19 10.5C19 11.0128 18.614 11.4355 18.1166 11.4933L18 11.5H12.5C11.9477 11.5 11.5 11.0523 11.5 10.5C11.5 9.98716 11.886 9.56449 12.3834 9.50673L12.5 9.5H18Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}

export function CloseIncidentIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33736 1.33753C1.56516 1.10972 1.93451 1.10972 2.16232 1.33753L4.78732 3.96253C5.01512 4.19033 5.01512 4.55968 4.78732 4.78748C4.55951 5.01529 4.19016 5.01529 3.96236 4.78748L1.33736 2.16248C1.10955 1.93468 1.10955 1.56533 1.33736 1.33753ZM12.6623 1.33753C12.8901 1.56533 12.8901 1.93468 12.6623 2.16248L10.0373 4.78748C9.80951 5.01529 9.44016 5.01529 9.21236 4.78748C8.98455 4.55968 8.98455 4.19033 9.21236 3.96253L11.8374 1.33753C12.0652 1.10972 12.4345 1.10972 12.6623 1.33753ZM6.99984 6.125C6.51659 6.125 6.12484 6.51676 6.12484 7C6.12484 7.48325 6.51659 7.875 6.99984 7.875C7.48309 7.875 7.87484 7.48325 7.87484 7C7.87484 6.51676 7.48309 6.125 6.99984 6.125ZM4.95817 7C4.95817 5.87242 5.87226 4.95834 6.99984 4.95834C8.12742 4.95834 9.0415 5.87242 9.0415 7C9.0415 8.12759 8.12742 9.04167 6.99984 9.04167C5.87226 9.04167 4.95817 8.12759 4.95817 7ZM4.78732 9.21253C5.01512 9.44033 5.01512 9.80968 4.78732 10.0375L2.16232 12.6625C1.93451 12.8903 1.56516 12.8903 1.33736 12.6625C1.10955 12.4347 1.10955 12.0653 1.33736 11.8375L3.96236 9.21253C4.19016 8.98472 4.55951 8.98472 4.78732 9.21253ZM9.21236 9.21253C9.44016 8.98472 9.80951 8.98472 10.0373 9.21253L12.6623 11.8375C12.8901 12.0653 12.8901 12.4347 12.6623 12.6625C12.4345 12.8903 12.0652 12.8903 11.8374 12.6625L9.21236 10.0375C8.98455 9.80968 8.98455 9.44033 9.21236 9.21253Z"
        fill="#121224"
      />
    </svg>
  );
}

export function DownloadIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24981 1.75033C5.24981 1.42816 5.51097 1.16699 5.83314 1.16699H8.16647C8.48864 1.16699 8.74981 1.42816 8.74981 1.75033V4.37533H10.4998C10.7357 4.37533 10.9484 4.51745 11.0387 4.73543C11.129 4.95341 11.0791 5.20431 10.9123 5.37114L7.41229 8.87114C7.30289 8.98054 7.15452 9.042 6.99981 9.04199C6.8451 9.04199 6.69672 8.98054 6.58733 8.87114L3.08736 5.37114C2.92052 5.20431 2.87062 4.9534 2.96091 4.73543C3.0512 4.51745 3.2639 4.37533 3.49984 4.37533H5.24981V1.75033Z"
        fill="#121224"
      />
      <path
        d="M1.1665 10.792C1.1665 10.4698 1.42767 10.2087 1.74984 10.2087H12.2498C12.572 10.2087 12.8332 10.4698 12.8332 10.792C12.8332 11.1142 12.572 11.3753 12.2498 11.3753H1.74984C1.42767 11.3753 1.1665 11.1142 1.1665 10.792Z"
        fill="#121224"
      />
      <path
        d="M4.08317 12.2503C3.761 12.2503 3.49984 12.5115 3.49984 12.8337C3.49984 13.1558 3.761 13.417 4.08317 13.417H9.9165C10.2387 13.417 10.4998 13.1558 10.4998 12.8337C10.4998 12.5115 10.2387 12.2503 9.9165 12.2503H4.08317Z"
        fill="#121224"
      />
    </svg>
  );
}

export function PlayIcon(props) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        d="M3.9375 2.75586V12.2441L4.60742 11.8066L11.3066 7.5L4.60742 3.19336L3.9375 2.75586ZM4.8125 4.35547L9.69336 7.5L4.8125 10.6445V4.35547Z"
        fill="#121224"
      />
    </svg>
  );
}
