import { SET_NAVIGATION_STATE } from "../../actions/navigation/navigation-action-types";

const initialNavigationState = {
  navigationState: {
    blocked: false,
    message: null,
  },
};

export default function navigationReducer(state = initialNavigationState, action = {}) {
  let newState;
  switch (action.type) {
    case SET_NAVIGATION_STATE:
      newState = { ...state, navigationState: action.navigationState };
      return newState;

    default:
      return state;
  }
}
