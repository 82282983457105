import React from "react";
import Button from "../../components/button/ng-button";
import { Skeleton, Tooltip } from "antd";
import { InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";
import DqDimensionStat from "../../components/dq-dashboard/dq-dimension-stat";
import DqDimensionButton from "../../components/dq-dashboard/dq-dimension-button";
import { DqDashboardDimensionType } from "../../utils/enums";
import { evalDelayOptions } from "../../utils/options";
import { NextGenPalette } from "../../utils/color";
import {
  getTimeZoneList,
  largestWholeTimeUnitFactor,
  TimeUnitInSeconds,
} from "../../utils/time";
import { summaryEvaluationDelay } from "./dq-dashboard-data";

import "./daily-summary.scss";

function DailySummaryIcon(props) {
  const { size = 20, fillColor = NextGenPalette.systemBlack } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M9.58329 8.33335C9.12306 8.33335 8.74996 8.70645 8.74996 9.16669C8.74996 9.62692 9.12306 10 9.58329 10H9.99996C10.4602 10 10.8333 9.62692 10.8333 9.16669C10.8333 8.70645 10.4602 8.33335 9.99996 8.33335H9.58329Z"
        fill="#121224"
      />
      <path
        d="M8.74996 12.5C8.74996 12.0398 9.12306 11.6667 9.58329 11.6667H9.99996C10.4602 11.6667 10.8333 12.0398 10.8333 12.5C10.8333 12.9603 10.4602 13.3334 9.99996 13.3334H9.58329C9.12306 13.3334 8.74996 12.9603 8.74996 12.5Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74996 1.66669C2.59937 1.66669 1.66663 2.59943 1.66663 3.75002V16.25C1.66663 17.4006 2.59937 18.3334 3.74996 18.3334H16.25C17.4006 18.3334 18.3333 17.4006 18.3333 16.25V3.75002C18.3333 2.59943 17.4006 1.66669 16.25 1.66669H3.74996ZM3.74996 3.33335C3.51984 3.33335 3.33329 3.5199 3.33329 3.75002V5.00002H4.99996V3.33335H3.74996ZM6.66663 3.33335V5.00002H16.6666V3.75002C16.6666 3.5199 16.4801 3.33335 16.25 3.33335H6.66663ZM4.99996 6.66669H3.33329V16.25C3.33329 16.4801 3.51984 16.6667 3.74996 16.6667H4.99996V6.66669ZM6.66663 16.6667V6.66669H16.6666V8.33335H12.5C12.0397 8.33335 11.6666 8.70645 11.6666 9.16669C11.6666 9.62692 12.0397 10 12.5 10H16.6666V11.6667H12.5C12.0397 11.6667 11.6666 12.0398 11.6666 12.5C11.6666 12.9603 12.0397 13.3334 12.5 13.3334H16.6666V16.25C16.6666 16.4801 16.4801 16.6667 16.25 16.6667H6.66663Z"
        fill={fillColor}
      />
    </svg>
  );
}

function DailySummary(props) {
  const {
    loading = false,
    dailySummary: {
      timezone: propsTimezone = "UTC",
      timestamp,
      totalMonitoredTables = 0,
      totalMonitors = 0,
      dimensionScoresMap: { overall, completeness, accuracy, timeliness, custom } = {},
    } = {},
    selectedDimensionType = DqDashboardDimensionType.OVERALL,
    onDimensionButtonClick,
    onSettingsClick,
  } = props;

  const dimensionTypeData = {
    [DqDashboardDimensionType.OVERALL]: overall,
    [DqDashboardDimensionType.COMPLETENESS]: completeness,
    [DqDashboardDimensionType.ACCURACY]: accuracy,
    [DqDashboardDimensionType.TIMELINESS]: timeliness,
    [DqDashboardDimensionType.CUSTOM]: custom,
  };

  function DailySummaryDimensionButton(buttonProps) {
    const { dimensionType } = buttonProps;
    const data = dimensionTypeData[dimensionType];
    const totalMonitors = data?.totalMonitors ?? 0;
    const totalMonitorsWithIncidents = data?.totalMonitorsWithIncidents ?? 0;
    const totalMonitorsWithoutIncidents = totalMonitors - totalMonitorsWithIncidents;
    return (
      <DqDimensionButton
        dimensionType={dimensionType}
        numerator={totalMonitorsWithoutIncidents}
        denominator={totalMonitors}
        selected={selectedDimensionType === dimensionType}
        onClick={() => onDimensionButtonClick(dimensionType)}
      />
    );
  }

  const validTimestamp = typeof timestamp === "number" && timestamp > 0;
  const summaryDate = new Date((timestamp ?? 0) * 1000);
  let timezone = propsTimezone;
  let timezoneObj = getTimeZoneList().find((tz) => tz.name === timezone);
  if (!timezoneObj) {
    timezone = "UTC";
    timezoneObj = getTimeZoneList().find((tz) => tz.name === timezone);
  }
  const timezoneOffsetStr = `(GMT${timezoneObj.utcOffsetStr})`;
  const summaryDateStr =
    `${summaryDate.toLocaleDateString("en-US", { timeZone: timezone })} ` +
    `${summaryDate.toLocaleTimeString("en-US", {
      timeZone: timezone,
    })} ${timezoneOffsetStr}`;

  const [evaluationDelay, isEvalDelayConfigured] = summaryEvaluationDelay(
    props.dailySummary
  );
  const evaluationDelayUnit = largestWholeTimeUnitFactor(
    evaluationDelay,
    evalDelayOptions.map(({ value }) => value)
  );
  const evaluationDelayQty = evaluationDelay / TimeUnitInSeconds[evaluationDelayUnit];

  function DimensionRows(_props) {
    return (
      <>
        <div className="dq-dash-daily-summary-top-row">
          <DqDimensionStat
            dimensionName="Total Monitored Tables"
            value={totalMonitoredTables}
          />
          <DqDimensionStat
            dimensionName="Total Number of Monitors"
            value={totalMonitors}
          />
          <DailySummaryDimensionButton
            dimensionType={DqDashboardDimensionType.OVERALL}
          />
          <div className="dq-dash-daily-summary-spacer" />
        </div>
        <div className="dq-dash-daily-summary-bottom-row">
          <DailySummaryDimensionButton
            dimensionType={DqDashboardDimensionType.COMPLETENESS}
          />
          <DailySummaryDimensionButton
            dimensionType={DqDashboardDimensionType.ACCURACY}
          />
          <DailySummaryDimensionButton
            dimensionType={DqDashboardDimensionType.TIMELINESS}
          />
          <DailySummaryDimensionButton
            dimensionType={DqDashboardDimensionType.CUSTOM}
          />
        </div>
      </>
    );
  }

  let reportingPeriodInfo =
    "Data quality summary for most recent 24 hour period (12AM - 11:59:59 PM), " +
    `ignoring data within the evaluation delay period. Evaluation delay is set to ${evaluationDelayQty} ${evaluationDelayUnit}`;

  if (isEvalDelayConfigured) {
    reportingPeriodInfo += ".";
  } else {
    reportingPeriodInfo +=
      "; this is the maximum evaluation delay of any metric in the workspace.";
  }

  return (
    <div className="dq-dash-daily-summary">
      <div className="dq-dash-daily-summary-header">
        <div>
          <div className="dq-dash-daily-summary-title">
            <h3>Summary</h3>
            <DailySummaryIcon />
          </div>
          <div className="dq-dash-daily-summary-period">
            {validTimestamp && (
              <>
                <span>For the 24 hour period starting {summaryDateStr}</span>
                <Tooltip title={reportingPeriodInfo}>
                  <InfoCircleOutlined color={NextGenPalette.systemBlack} />
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <div className="dq-dash-daily-summary-controls">
          <Button outline onClick={onSettingsClick}>
            <SettingOutlined />
          </Button>
        </div>
      </div>
      {loading ? (
        <div className="dq-dash-daily-summary-loading">
          <Skeleton active />
        </div>
      ) : (
        <DimensionRows />
      )}
    </div>
  );
}

export default DailySummary;
