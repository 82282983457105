import axios from "../utils/api";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

export function getCaseListPromise(workspaceUuid, queryOptions) {
  return new Promise(function (resolve, reject) {
    const { incidentUuid } = queryOptions;

    const queryArray = [];
    if (incidentUuid) {
      queryArray.push(`incident_id=${incidentUuid}`);
    }

    let queryString = "";
    if (queryArray.length > 0) {
      queryString = `?${queryArray.join("&")}`;
    }

    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.CASE_LIST, {
          workspaceUuid,
        })}${queryString}`,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addCasePromise(workspaceUuid, newCase) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.CASE_LIST, { workspaceUuid }),
        newCase,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateCasePromise(workspaceUuid, uuid, newCase) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.CASE, {
          workspaceUuid,
          uuid,
        }),
        newCase,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
