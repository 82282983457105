import {
  getMetricTypeFromConfigData,
  metricTypeNames,
} from "../../components/metric/utils";
import { metricFields } from "../../components/search/use-search/use-search-metrics-config";
import { monitorFields } from "../../components/search/use-search/use-search-monitors-config";
import { getFullTableName } from "../../utils/datasource";
import { IncidentCreatorType, TableType } from "../../utils/enums";
import { getSliceDisplayString } from "../../utils/general";
import { getTagsFromCreatorInfo } from "../../utils/incident";
import {
  getAutoMetricTypeFromKPI,
  isIncidentValidationSupported,
} from "../../utils/metric";
import { getMonitorTypeName } from "../../utils/monitor";
import {
  getStringCompareFunction,
  listOfStringListCompareFunction,
  normalizeFilterListOfTagListParams,
  stringListCompareFunction,
} from "../../utils/search";

export function getLocalFilteredIncidentList(
  incidentList,
  localFilterSetting,
  isMatchExactly = false,
  enableTrim = true
) {
  if (!localFilterSetting) {
    return incidentList;
  }

  const {
    filterName = [],
    monitorType = [],
    monitorId = [],
    dataSourceId = [],
    dataSourceName = [],
    tableName = [],
    columnName = [],
    metricType = [],
    metricId = [],
    kpiName = [],
    slice = [],
    searchName = [],
    direction = [],
    severity = [],
    status = [],
    validationStatus = [],
    incidentId = [],
    alertChannelId = [],
    incidentProgress = [],
    ownedBy = "",
    showProceededDataOnly = true,
  } = localFilterSetting;

  let { tagName = [] } = localFilterSetting;
  tagName = normalizeFilterListOfTagListParams(tagName, enableTrim);

  if (
    !incidentId.length &&
    !filterName.length &&
    !monitorType.length &&
    !monitorId.length &&
    !kpiName.length &&
    !metricType.length &&
    !metricId.length &&
    !dataSourceId.length &&
    !dataSourceName.length &&
    !tableName.length &&
    !columnName.length &&
    !slice.length &&
    !tagName.length &&
    !direction.length &&
    !severity.length &&
    !searchName.length &&
    !status.length &&
    !validationStatus.length &&
    !alertChannelId.length &&
    !showProceededDataOnly &&
    !incidentProgress.length &&
    ownedBy === ""
  ) {
    return incidentList;
  }

  const stringCompareFunction = getStringCompareFunction(isMatchExactly);

  // Need to filter the direction on the copied incident list in order not to modify the original list.
  const filterIncidentList = [];
  for (let incidentPerSlice of incidentList) {
    const { creatorInfo, slice: incidentSlice } = incidentPerSlice;
    /*
     * Todo: Enable it once we figure out how to set isTrainingDone of auto created rule.
     if (showProceededDataOnly && !incidentPerSlice.isTrainingDone) {
      continue;
     }
    */

    let incidentMonitorName,
      incidentMonitorType = "",
      incidentMonitorId = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      incidentMonitorName = creatorInfo.filterInfo.metadata.name;
      incidentMonitorType = getMonitorTypeName(creatorInfo.filterInfo);
      incidentMonitorId = creatorInfo.filterInfo.metadata.idSerial.toString();
    }

    if (!stringCompareFunction(incidentMonitorName, filterName)) {
      continue;
    }

    if (
      monitorType.length > 0 &&
      !stringCompareFunction(incidentMonitorType, monitorType)
    ) {
      continue;
    }

    if (
      monitorId.length > 0 &&
      (!incidentMonitorId || !monitorId.includes(incidentMonitorId))
    ) {
      continue;
    }

    let normalizedDataSourceName = "";
    let incidentDataSourceId = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      normalizedDataSourceName = creatorInfo.dataSourceInfo.metadata.name;
      incidentDataSourceId = creatorInfo.dataSourceInfo.metadata.idSerial.toString();
    }

    if (!stringCompareFunction(normalizedDataSourceName, dataSourceName)) {
      continue;
    }

    if (
      dataSourceId.length > 0 &&
      (!incidentDataSourceId || !dataSourceId.includes(incidentDataSourceId))
    ) {
      continue;
    }

    let normalizedTableName = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      if (
        creatorInfo.kpiInfo.config.table &&
        creatorInfo.kpiInfo.config.table.type === TableType.TABLE
      ) {
        normalizedTableName = getFullTableName(creatorInfo.kpiInfo.config.table);
      }
    }

    if (!stringCompareFunction(normalizedTableName, tableName)) {
      continue;
    }

    let normalizedColumnName = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      if (
        creatorInfo.kpiInfo.config.valueColumns &&
        creatorInfo.kpiInfo.config.valueColumns.length > 0
      ) {
        normalizedColumnName = creatorInfo.kpiInfo.config.valueColumns
          .map(({ columnName }) => columnName)
          .join(", ");
      }
    }

    if (!stringCompareFunction(normalizedColumnName, columnName)) {
      continue;
    }

    let normalizedKpiName, incidentMetricType, incidentMetricId;
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      normalizedKpiName = incidentPerSlice.creatorInfo.kpiInfo.metadata.name;
      incidentMetricType = getMetricTypeFromConfigData(creatorInfo.kpiInfo);
      incidentMetricId = creatorInfo.kpiInfo.metadata.idSerial.toString();
    }

    if (!stringCompareFunction(metricTypeNames[incidentMetricType], metricType)) {
      continue;
    }

    if (!stringCompareFunction(normalizedKpiName, kpiName)) {
      continue;
    }

    if (
      metricId.length > 0 &&
      (!incidentMetricId || !metricId.includes(incidentMetricId))
    ) {
      continue;
    }

    let normalizedSliceName = getSliceDisplayString(incidentSlice, null, true);
    if (!stringCompareFunction(normalizedSliceName, slice)) {
      continue;
    }

    let tags = [];
    let monitorAlertingChannelIds = [];
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      if (creatorInfo.filterInfo.metadata.tags) {
        creatorInfo.filterInfo.metadata.tags.forEach((tag) => tags.push(tag));
      }

      if (creatorInfo.kpiInfo.metadata.tags) {
        creatorInfo.kpiInfo.metadata.tags.forEach((tag) => tags.push(tag));
      }

      if (creatorInfo.dataSourceInfo.metadata.tags) {
        creatorInfo.dataSourceInfo.metadata.tags.forEach((tag) => tags.push(tag));
      }

      creatorInfo.filterInfo.config.alertConfig.channels.forEach(({ channelId }) =>
        monitorAlertingChannelIds.push(channelId)
      );
    }

    if (!listOfStringListCompareFunction(tagName, tags, stringCompareFunction)) {
      continue;
    }

    if (
      alertChannelId.length > 0 &&
      !monitorAlertingChannelIds.some((channelId) => alertChannelId.includes(channelId))
    ) {
      continue;
    }

    if (
      searchName.length &&
      !stringListCompareFunction(
        [
          incidentMonitorName,
          normalizedKpiName,
          normalizedDataSourceName,
          normalizedTableName,
          normalizedColumnName,
          normalizedSliceName,
          ...tags,
        ],
        searchName,
        getStringCompareFunction(false)
      )
    ) {
      continue;
    }

    if (ownedBy !== "" && incidentPerSlice.ownedBy !== ownedBy) {
      continue;
    }

    if (
      !direction.length &&
      !severity.length &&
      !status.length &&
      !validationStatus.length &&
      !incidentId.length &&
      !incidentProgress.length
    ) {
      filterIncidentList.push(incidentPerSlice);
    } else {
      const copiedIncident = { ...incidentPerSlice };
      copiedIncident.incidents = copiedIncident.incidents.filter((incident) => {
        if (severity.length > 0 && !severity.includes(incident.score)) {
          return false;
        }

        if (status.length > 0 && !status.includes(incident.status)) {
          return false;
        }

        if (
          validationStatus.length > 0 &&
          (!validationStatus.includes(incident.validationStatus) ||
            !isIncidentValidationSupported(
              getAutoMetricTypeFromKPI(creatorInfo?.kpiInfo),
              creatorInfo?.kpiInfo
            ))
        ) {
          return false;
        }

        if (direction.length > 0 && !direction.includes(incident.direction)) {
          return false;
        }

        if (incidentId.length > 0 && !incidentId.includes(incident.id.toString())) {
          return false;
        }

        if (
          incidentProgress.length > 0 &&
          !incidentProgress.includes(incident.ongoing ? "Active" : "Completed")
        ) {
          return false;
        }

        return true;
      });

      if (copiedIncident.incidents.length > 0) {
        filterIncidentList.push(copiedIncident);
      }
    }
  }

  return filterIncidentList;
}

export function getIncidentSearchList(
  incidentList,
  options = {
    enableSlice: true,
    enableKpi: true,
    enableId: true,
    enableProgress: true,
  }
) {
  const metricNameMapper = {};
  const metricTypeMapper = {};
  const metricIdMapper = {};
  const tableNameMapper = {};
  const columnNameMapper = {};
  const dataSourceNameMapper = {};
  const monitorNameMapper = {};
  const monitorTypeMapper = {};
  const monitorIdMapper = {};
  const sliceDisplayStrMapper = {};
  const tagNameMapper = {};
  const incidentIdMapper = {};
  const alertingChannelIdMapper = {};
  const { enableSlice, enableKpi, enableId, enableProgress } = options;

  for (let incidentsPerRule of incidentList) {
    const { slice, creatorInfo, incidents } = incidentsPerRule;
    if (enableId) {
      for (let incident of incidents) {
        incidentIdMapper[incident.id] = true;
      }
    }

    let monitorName, monitorType, monitorId;
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      for (let channel of creatorInfo.filterInfo.config.alertConfig?.channels || []) {
        alertingChannelIdMapper[channel.channelId] = true;
      }
      monitorName = creatorInfo.filterInfo.metadata.name;
      monitorType = getMonitorTypeName(creatorInfo.filterInfo);
      monitorId = creatorInfo.filterInfo.metadata.idSerial.toString();
    }
    monitorNameMapper[monitorName] = true;
    if (monitorType) {
      monitorTypeMapper[monitorType] = true;
    }
    if (enableId && monitorId) {
      monitorIdMapper[monitorId] = true;
    }

    let normalizedDataSourceName = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      normalizedDataSourceName = creatorInfo.dataSourceInfo.metadata.name;
    }
    dataSourceNameMapper[normalizedDataSourceName] = true;

    let normalizedTableName = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      if (
        creatorInfo.kpiInfo.config.table &&
        creatorInfo.kpiInfo.config.table.type === TableType.TABLE
      ) {
        normalizedTableName = getFullTableName(creatorInfo.kpiInfo.config.table);
      }
    }

    normalizedTableName && (tableNameMapper[normalizedTableName] = true);

    let normalizedColumnName = "";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      if (
        creatorInfo.kpiInfo.config.valueColumns &&
        creatorInfo.kpiInfo.config.valueColumns.length > 0
      ) {
        normalizedColumnName = creatorInfo.kpiInfo.config.valueColumns
          .map(({ columnName }) => columnName)
          .join(", ");
      }
    }
    normalizedColumnName && (columnNameMapper[normalizedColumnName] = true);

    let normalizedMetricName, metricType, metricId;
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      normalizedMetricName = creatorInfo.kpiInfo.metadata.name;
      metricType =
        metricTypeNames[getMetricTypeFromConfigData(creatorInfo.kpiInfo)] ?? "";
      metricId = creatorInfo.kpiInfo.metadata.idSerial.toString();
    }
    metricNameMapper[normalizedMetricName] = true;
    if (metricType) {
      metricTypeMapper[metricType] = true;
    }

    if (enableId && metricId) {
      metricIdMapper[metricId] = true;
    }

    let tagNames = getTagsFromCreatorInfo(creatorInfo);
    for (let currentTagName of tagNames) {
      tagNameMapper[currentTagName] = true;
    }

    if (enableSlice) {
      const sliceDisplayValue = getSliceDisplayString(slice);
      if (sliceDisplayValue && !sliceDisplayValue.includes("lightup__")) {
        sliceDisplayStrMapper[sliceDisplayValue] = true;
      }
    }
  }

  const incidentIdList = Object.keys(incidentIdMapper);
  const monitorNameList = Object.keys(monitorNameMapper);
  const monitorTypeList = Object.keys(monitorTypeMapper);
  const monitorIdList = Object.keys(monitorIdMapper);
  const dataSourceNameList = Object.keys(dataSourceNameMapper);
  const tableNameList = Object.keys(tableNameMapper);
  const metricNameList = enableKpi ? Object.keys(metricNameMapper) : [];
  const metricTypeList = enableKpi ? Object.keys(metricTypeMapper) : [];
  const metricIdList = enableKpi ? Object.keys(metricIdMapper) : [];
  const tagNameList = Object.keys(tagNameMapper);
  const sliceList = enableSlice ? Object.keys(sliceDisplayStrMapper) : [];
  const columnNameList = Object.keys(columnNameMapper);
  const alertingChannelIdList = Object.keys(alertingChannelIdMapper);
  const progressState = enableProgress ? ["Active", "Completed"] : [];

  return getIncidentSearchListFromAllList({
    incidentIdList,
    monitorNameList,
    monitorTypeList,
    monitorIdList,
    metricNameList,
    metricTypeList,
    metricIdList,
    sliceList,
    tagNameList,
    tableNameList,
    dataSourceNameList,
    columnNameList,
    alertingChannelIdList,
    progressState,
  });
}

export function getIncidentSearchListFromAllList({
  incidentIdList = [],
  monitorNameList = [],
  monitorTypeList = [],
  monitorIdList = [],
  metricNameList = [],
  metricTypeList = [],
  metricIdList = [],
  sliceList = [],
  tagNameList = [],
  tableNameList = [],
  dataSourceNameList = [],
  columnNameList = [],
  alertingChannelIdList = [],
  progressState = [],
}) {
  const searchResultList = [];

  if (dataSourceNameList.length > 0) {
    searchResultList.push({
      label: "Datasources",
      type: metricFields.DATA_SOURCE_NAME,
      items: dataSourceNameList,
    });
  }

  if (tableNameList.length > 0) {
    searchResultList.push({
      label: "Table",
      type: metricFields.TABLE_NAME,
      items: tableNameList,
    });
  }

  if (columnNameList.length > 0) {
    searchResultList.push({
      label: "Column",
      type: metricFields.COLUMN_NAME,
      items: columnNameList,
    });
  }

  if (metricTypeList.length > 0) {
    searchResultList.push({
      label: "Metric Type",
      type: metricFields.METRIC_TYPE,
      items: metricTypeList,
    });
  }

  if (metricNameList.length > 0) {
    searchResultList.push({
      label: "Metric Name",
      type: metricFields.KPI_NAME,
      items: metricNameList,
    });
  }

  if (monitorTypeList.length > 0) {
    searchResultList.push({
      label: "Monitor Type",
      type: monitorFields.MONITOR_TYPE,
      items: monitorTypeList,
    });
  }

  if (monitorNameList.length > 0) {
    searchResultList.push({
      label: "Monitor Name",
      type: monitorFields.MONITOR_NAME,
      items: monitorNameList,
    });
  }

  if (sliceList.length > 0) {
    const normalizedSliceList = sliceList.map((slice) => {
      if (typeof slice === "string") {
        const [key, value] = slice.split(":");
        return { [key]: value };
      } else {
        return slice;
      }
    });
    searchResultList.push({
      label: "Slice",
      type: "slice",
      items: normalizedSliceList,
      displayFunction: getSliceDisplayString,
    });
  }

  if (tagNameList.length > 0) {
    searchResultList.push({
      label: "Tag",
      type: metricFields.TAG_NAME,
      items: tagNameList,
    });
  }

  if (incidentIdList.length > 0) {
    searchResultList.push({
      label: "Incident ID",
      type: "incidentId",
      items: incidentIdList,
    });
  }

  if (monitorIdList.length > 0) {
    searchResultList.push({
      label: "Monitor ID",
      type: monitorFields.MONITOR_ID,
      items: monitorIdList,
    });
  }

  if (metricIdList.length > 0) {
    searchResultList.push({
      label: "Metric ID",
      type: metricFields.METRIC_ID,
      items: metricIdList,
    });
  }

  if (alertingChannelIdList.length > 0) {
    searchResultList.push({
      label: "Alert Channel ID",
      type: "alertChannelId",
      items: [...alertingChannelIdList],
    });
  }

  if (progressState.length > 0) {
    searchResultList.push({
      label: "Progress",
      type: "incidentProgress",
      items: [...progressState],
    });
  }

  return searchResultList;
}
