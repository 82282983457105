import React, { useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Input } from "antd";
import Button from "../../../../components/button/ng-button";
import Table, { NgTableClickableText } from "../../../../components/table/ng-table";
import { AvatarGroup } from "../../../../components/avatar";
import NgDropdownMenu from "../../../../components/ng-dropdown-menu";
import { AddOne } from "../../../../components/icons/menu";
import { workspaceRoleOptions } from "../../../../utils/options";
import AdminTabContentHeader from "../../admin-tab-content-header";
import AddUserPopOver from "../../add-user-popover";
import { WorkspaceUserRole } from "../../../../utils/enums";
import AddWorkspace from "../../../add-workspace";
import WorkspaceDeleteModal from "../../../../components/workspace/workspace-delete-modal";
import { workspaceIconNameToIconMapper } from "../../../../utils/icon";
import { getStringFromTimeStamp } from "../../../../utils/time";
import { fnSorter } from "../../../../utils/sort";

import "./index.scss";
import { EVENT, PAGE, trackEvent } from "../../../../utils/telemetry";

const { Search } = Input;

function WorkspaceNameCell(props) {
  const { workspace, onDeleteClick } = props;

  const { name, iconId, uuid } = workspace;
  const workspaceActionMenuItems = [
    {
      label: "Delete Workspace",
      onClick: () => onDeleteClick(uuid),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const WorkspaceIcon = workspaceIconNameToIconMapper[iconId];
  const workspaceActionMenuTrigger = (
    <NgTableClickableText>
      <span className="admin-workspace-list-table-name">
        <WorkspaceIcon size={48} />
        {name}
      </span>
    </NgTableClickableText>
  );

  return (
    <NgDropdownMenu
      position={"bottomLeft"}
      trigger={workspaceActionMenuTrigger}
      menuItems={workspaceActionMenuItems}
    />
  );
}

function UsersColumnCell(props) {
  const { workspaceUuid, appUserList, workspaceUserList, addAppUserToWorkspace } =
    props;

  const memberUsers = new Set(workspaceUserList.map((user) => user.id));
  const nonMemberUsers = appUserList.data.filter((user) => !memberUsers.has(user.id));

  const addUserPopoverTrigger = (
    <Button outline style={{ padding: "9px" }} disabled={nonMemberUsers.length === 0}>
      <AddOne />
    </Button>
  );

  return (
    <div className="admin-workspace-list-users-cell">
      <div>
        <AddUserPopOver
          trigger={addUserPopoverTrigger}
          roleOptions={workspaceRoleOptions}
          initialValues={{ email: "", role: WorkspaceUserRole.ADMIN }}
          userList={nonMemberUsers}
          enableAutoComplete={true}
          onAddUser={({ role, email }) => {
            trackEvent(EVENT.ADD_USER_TO_WORKSPACE, {
              role,
              page: PAGE.ADMIN_WORKSPACES,
            });
            addAppUserToWorkspace(workspaceUuid, {
              email,
              role,
            });
          }}
        />
      </div>
      <div>
        <AvatarGroup userList={workspaceUserList} />
      </div>
    </div>
  );
}

function AdminWorkspacesHeader(props) {
  const { workspacesCount, openWorkspaceTakeover, onSearchQueryChange } = props;

  const handleAddWorkspaceClick = () => {
    trackEvent(EVENT.ADD_WORKSPACE, { page: PAGE.ADMIN_WORKSPACES });
    openWorkspaceTakeover(<AddWorkspace goBackOnCancel={false} />);
  };

  return (
    <AdminTabContentHeader
      title={`Workspaces (${workspacesCount})`}
      searchComponent={
        <Search
          placeholder="Search workspace..."
          onChange={(e) => onSearchQueryChange(e.target.value)}
        />
      }
      addComponent={
        <Button outline onClick={handleAddWorkspaceClick}>
          Add workspace
          <PlusOutlined />
        </Button>
      }
    />
  );
}

function AdminWorkspacesTable(props) {
  const {
    appUserList,
    appWorkspaceList,
    addAppUserToWorkspace,
    searchQuery,
    onDeleteClick,
  } = props;

  const workspaceUsersMap = appUserList.data.reduce((wsUsersMap, user) => {
    for (let userWorkspace of user.workspaces) {
      if (!wsUsersMap.hasOwnProperty(userWorkspace.uuid)) {
        wsUsersMap[userWorkspace.uuid] = [];
      }
      wsUsersMap[userWorkspace.uuid].push(user);
    }
    return wsUsersMap;
  }, {});

  const searchQueryEmpty = !searchQuery || searchQuery === "";
  const filteredWorkspaces = appWorkspaceList.data.filter(
    (workspace) =>
      searchQueryEmpty ||
      workspace.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
  );

  const appWorkspaceListWithUsers = filteredWorkspaces.map((workspace) => {
    const users = workspaceUsersMap[workspace.uuid] || [];
    return {
      ...workspace,
      users,
      usersSortInfo: users.map(({ id }) => id).join(","),
    };
  });

  const columns = [
    {
      title: "Workspace",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: { compare: fnSorter((row) => row.name) },
      render: function (_names, row) {
        return <WorkspaceNameCell workspace={row} onDeleteClick={onDeleteClick} />;
      },
      width: 455,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: { compare: fnSorter((row) => row.description) },
    },
    {
      title: "Users",
      dataIndex: "users",
      render: function (users, row) {
        return (
          <UsersColumnCell
            workspaceUuid={row.uuid}
            appUserList={appUserList}
            appWorkspaceList={appWorkspaceList}
            workspaceUserList={users}
            addAppUserToWorkspace={addAppUserToWorkspace}
          />
        );
      },
      sorter: { compare: fnSorter((row) => row.usersSortInfo) },
      width: 255,
    },
    {
      title: "Creation Date",
      dataIndex: "createdAt",
      render: function (_, row) {
        return getStringFromTimeStamp(row.createdAt, { longLocalized: true });
      },
      sorter: { compare: fnSorter((row) => row.createdAt) },
      width: 150,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={appWorkspaceListWithUsers}
      rowKey={(row) => row.uuid}
    />
  );
}

function AdminWorkspacesList(props) {
  const {
    appUserList,
    appWorkspaceList,
    addAppUserToWorkspace,
    openWorkspaceTakeover,
    deleteWorkspace,
  } = props;

  const [searchQuery, setSearchQuery] = useState("");
  // deleteModalTarget is the UUID of the workspace the user has indicated they
  // would like to delete.
  const [deleteModalTarget, setDeleteModalTarget] = useState(null);

  const isDeleteModalVisible = deleteModalTarget !== null;
  const deleteModalTargetName = isDeleteModalVisible
    ? appWorkspaceList.data.find((ws) => ws.uuid === deleteModalTarget).name
    : "";

  function onDeleteWorkspaceClick(workspaceUuid) {
    setDeleteModalTarget(workspaceUuid);
  }

  function onDeleteCancelled() {
    setDeleteModalTarget(null);
  }

  function onDeleteConfirmed() {
    deleteWorkspace(deleteModalTarget);
    setDeleteModalTarget(null);
    trackEvent(EVENT.DELETE_WORKSPACE, {
      workspace_id: deleteModalTarget,
      page: PAGE.ADMIN_WORKSPACES,
    });
  }

  useEffect(() => {
    trackEvent(EVENT.SELECT_WORKSPACES_TAB, { page: PAGE.ADMIN });
  }, []);

  return (
    <>
      <div className="admin-workspace-list">
        <AdminWorkspacesHeader
          appWorkspaceList={appWorkspaceList}
          workspacesCount={appWorkspaceList.data.length}
          openWorkspaceTakeover={openWorkspaceTakeover}
          onSearchQueryChange={setSearchQuery}
        />
        <div className="admin-workspace-list-body-container">
          <AdminWorkspacesTable
            appUserList={appUserList}
            appWorkspaceList={appWorkspaceList}
            addAppUserToWorkspace={addAppUserToWorkspace}
            searchQuery={searchQuery}
            onDeleteClick={onDeleteWorkspaceClick}
          />
        </div>
      </div>
      <WorkspaceDeleteModal
        visible={isDeleteModalVisible}
        onDeleteConfirmed={onDeleteConfirmed}
        onDeleteCancelled={onDeleteCancelled}
        workspaceName={deleteModalTargetName}
      />
    </>
  );
}

export default AdminWorkspacesList;
