import React from "react";

export default function SettingConfigIcon({ className = "" }) {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      className={className}
    >
      <path
        fill="#121224"
        fillOpacity=".85"
        fillRule="evenodd"
        d="M8.02 1.167c.323 0 .584.261.584.583v2.334a.583.583 0 1 1-1.167 0V3.5H1.604a.583.583 0 1 1 0-1.166h5.833V1.75c0-.322.262-.583.584-.583Zm1.75 1.75c0-.322.262-.583.584-.583h1.75a.583.583 0 1 1 0 1.166h-1.75a.583.583 0 0 1-.583-.583ZM6.27 5.25c.323 0 .584.261.584.584v.583h5.833a.583.583 0 1 1 0 1.167H6.854v.583a.583.583 0 1 1-1.167 0V5.834c0-.323.262-.584.584-.584ZM1.02 7c0-.322.262-.583.584-.583h2.333a.583.583 0 0 1 0 1.167H1.604A.583.583 0 0 1 1.021 7Zm7 2.334c.323 0 .584.26.584.583v2.333a.583.583 0 1 1-1.167 0v-.583H1.604a.583.583 0 0 1 0-1.167h5.833v-.583c0-.322.262-.583.584-.583Zm1.75 1.75c0-.322.262-.584.584-.584h1.75a.583.583 0 0 1 0 1.167h-1.75a.583.583 0 0 1-.583-.583Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
