import React, { Component } from "react";
import PropTypes from "prop-types";

import "./text-input.scss";

class TextInput extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt) {
    const { value } = this.props;
    const newValue = evt.target.value;
    if (newValue !== value) {
      this.props.onChange(newValue);
    }
  }

  render() {
    const { disabled, value, type, className, placeholder, autoComplete } = this.props;
    return (
      <input
        className={className}
        disabled={disabled}
        type={type}
        value={value}
        onChange={this.onChange}
        autoComplete={autoComplete}
        placeholder={placeholder}
      />
    );
  }
}

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
};

TextInput.defaultProps = {
  className: "lightup-text-input",
  type: "text",
  placeholder: null,
  autoComplete: null,
};

export default TextInput;
