import {
  SET_INTEGRATION_LIST,
  SET_CURRENT_EDIT_INTEGRATION,
  SET_INTEGRATION_PREVIEW_STATUS,
  SET_INTEGRATION_CURRENT_METADATA,
} from "../../actions/integration/integration-action-types";

const initialRuleState = {
  integrationList: {
    data: [],
    loading: true,
  },
  currentEditIntegration: {},
  previewResult: {
    progress: false,
    data: undefined,
  },
  currentMetadata: {
    loading: false,
    lastRetrieved: null,
    data: {},
  },
};

export default function integrationReducer(state = initialRuleState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_INTEGRATION_LIST:
      newState = { ...state, integrationList: action.integrationList };
      return newState;

    case SET_CURRENT_EDIT_INTEGRATION:
      newState = {
        ...state,
        currentEditIntegration: action.currentEditIntegration,
      };
      return newState;

    case SET_INTEGRATION_PREVIEW_STATUS:
      newState = { ...state, previewResult: action.previewResult };
      return newState;

    case SET_INTEGRATION_CURRENT_METADATA:
      newState = {
        ...state,
        currentMetadata: { ...state.currentMetadata, ...action.metadata },
      };
      return newState;

    default:
      return state;
  }
}
