import React from "react";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import {
  EditOutlined,
  OrderedListOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { TriggerIcon } from "./menu-icons";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";

export function ManageAutoMetricsIcon(props) {
  const { size = 14, enableAntdWrap = false } = props;
  const svgImg = (
    <svg width={size} height={size} viewBox={`0 0 14 14`} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583496 3.50016C0.583496 1.88933 1.88933 0.583496 3.50016 0.583496H10.5002C12.111 0.583496 13.4168 1.88933 13.4168 3.50016C13.4168 5.11099 12.111 6.41683 10.5002 6.41683H3.50016C1.88933 6.41683 0.583496 5.11099 0.583496 3.50016ZM9.3335 3.50016C9.3335 2.85582 9.85582 2.3335 10.5002 2.3335C11.1445 2.3335 11.6668 2.85582 11.6668 3.50016C11.6668 4.1445 11.1445 4.66683 10.5002 4.66683C9.85582 4.66683 9.3335 4.1445 9.3335 3.50016Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583496 10.5002C0.583496 8.88933 1.88933 7.5835 3.50016 7.5835H10.5002C12.111 7.5835 13.4168 8.88933 13.4168 10.5002C13.4168 12.111 12.111 13.4168 10.5002 13.4168H3.50016C1.88933 13.4168 0.583496 12.111 0.583496 10.5002ZM2.3335 10.5002C2.3335 9.85582 2.85582 9.3335 3.50016 9.3335C4.1445 9.3335 4.66683 9.85582 4.66683 10.5002C4.66683 11.1445 4.1445 11.6668 3.50016 11.6668C2.85582 11.6668 2.3335 11.1445 2.3335 10.5002Z"
        fill="#121224"
      />
    </svg>
  );

  if (!enableAntdWrap) {
    return svgImg;
  }

  return (
    <span role="img" className="anticon">
      {svgImg}
    </span>
  );
}

function TriggerMetricsIcon(_props) {
  return (
    <span role="img" className="anticon">
      <TriggerIcon />
    </span>
  );
}

function ProfilerAdvancedSettingMenu(props) {
  const {
    trigger = null,
    configMsg,
    manageMetricsMsg = "Manage metrics",
    editProfileMsg,
    createCustomMetricMsg,
    disableCreateMetric = false,
    dropdownMenuProps = {},
    workspaceUserPermissions,
    onCreateCustomMetric,
    onCreateTable = null,
    onTriggerAll,
    onEditProfile,
    onEditTable = null,
    onMonitorAll,
    onManageAutoMetrics,
    onOpenConfig,
  } = props;

  const canMonitorMetrics = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST,
  ]);

  const canCreateMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ]);

  const canTriggerMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCETRIGGERVIEW,
  ]);

  const menuItems = [];

  onEditProfile &&
    menuItems.push({
      label: editProfileMsg,
      onClick: onEditProfile,
      icon: <EditOutlined />,
    });

  onCreateTable &&
    menuItems.push({
      label: "Create virtual table",
      onClick: onCreateTable,
      icon: <PlusOutlined />,
    });

  createCustomMetricMsg &&
    canCreateMetric &&
    menuItems.push({
      label: createCustomMetricMsg,
      onClick: onCreateCustomMetric,
      icon: <PlusOutlined />,
      disabled: disableCreateMetric,
    });

  configMsg &&
    menuItems.push({
      label: configMsg,
      onClick: onOpenConfig,
      icon: <SettingOutlined />,
    });

  onEditTable &&
    menuItems.push({
      label: "Edit table",
      onClick: onEditTable,
      icon: <EditOutlined />,
    });

  onManageAutoMetrics &&
    menuItems.push({
      label: manageMetricsMsg,
      onClick: onManageAutoMetrics,
      icon: <ManageAutoMetricsIcon />,
    });

  onMonitorAll &&
    canMonitorMetrics &&
    menuItems.push({
      label: "Monitor metrics",
      onClick: onMonitorAll,
      icon: <OrderedListOutlined />,
    });

  onTriggerAll &&
    canTriggerMetric &&
    menuItems.push({
      label: "Trigger all metrics",
      onClick: onTriggerAll,
      icon: <TriggerMetricsIcon />,
    });

  if (menuItems.length === 0) {
    return null;
  }

  const triggerProps = trigger ? { trigger } : { buttonText: "Actions" };

  return (
    <NgDropdownMenu {...triggerProps} {...dropdownMenuProps} menuItems={menuItems} />
  );
}

export default ProfilerAdvancedSettingMenu;
