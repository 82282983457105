import NgButton from "../button/ng-button";

import "./metric-draft-banner.scss";

export function MetricDraftBanner(props) {
  const { message, onCancel = null, onViewDraft = null, icon = null } = props;

  return (
    <div className="metric-draft-banner-container">
      {icon}
      <span className="metric-draft-banner-message">{message}</span>
      {Boolean(onCancel) && (
        <NgButton outline onClick={onCancel}>
          Cancel
        </NgButton>
      )}
      {Boolean(onViewDraft) && (
        <NgButton outline ghost onClick={onViewDraft}>
          View Draft
        </NgButton>
      )}
    </div>
  );
}
