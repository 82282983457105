import axios from "../utils/api";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";

export function getDashboardChartListPromise(workspaceUuid) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.DASHBOARD_CHART_LIST, {
          workspaceUuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addDashboardChartPromise(workspaceUuid, newDashboardChart) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.DASHBOARD_CHART_LIST, {
          workspaceUuid,
        }),
        newDashboardChart,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateDashboardChartPromise(workspaceUuid, uuid, newDashboardChart) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.DASHBOARD_CHART, {
          workspaceUuid,
          uuid,
        }),
        newDashboardChart,
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteDashboardPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.DASHBOARD_CHART, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v1/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDashboardDqSummaryPromise(workspaceUuid, startTs, endTs) {
  const queryParams = startTs && endTs ? `?start_ts=${startTs}&end_ts=${endTs}` : "";
  const uri = getAPIURIInstance(backendRelativeURIPath.DASHBOARD_DQ_SUMMARY, {
    workspaceUuid,
  });
  return new Promise(function (resolve, reject) {
    axios
      .get(`${uri}${queryParams}`, { baseURL: "/api/v1/" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateDashboardConfigPromise(workspaceUuid, dashboardConfig) {
  const uri = getAPIURIInstance(backendRelativeURIPath.DASHBOARD_CONFIGURATION, {
    workspaceUuid,
  });
  return new Promise(function (resolve, reject) {
    axios
      .put(uri, dashboardConfig, { baseURL: "/api/v1" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDashboardConfigPromise(workspaceUuid) {
  const uri = getAPIURIInstance(backendRelativeURIPath.DASHBOARD_CONFIGURATION, {
    workspaceUuid,
  });
  return new Promise(function (resolve, reject) {
    axios
      .get(uri, { baseURL: "/api/v1" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getDashboardIncidentSummaryPromise(workspaceUuid, startTs, endTs) {
  return new Promise(function (resolve, reject) {
    const queryParams = startTs && endTs ? `?start_ts=${startTs}&end_ts=${endTs}` : "";
    const uri = getAPIURIInstance(backendRelativeURIPath.DASHBOARD_INCIDENT_SUMMARY, {
      workspaceUuid,
    });

    axios
      .get(`${uri}${queryParams}`, { baseURL: "/api/v1/" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
