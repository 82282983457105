import React from "react";
import { LabeledInput } from "../../labeled-control/labeled-control";

function AzureBlobStorageConfigView(props) {
  const { value, onChange } = props;
  const { accountName, accountKey } = value;

  return (
    <>
      <LabeledInput
        label="Account Name"
        staticLabel
        value={accountName}
        onChange={(e) => {
          const newAccountName = e.target.value;
          newAccountName !== accountName &&
            onChange &&
            onChange({ ...value, accountName: newAccountName });
        }}
      />
      {accountKey !== null && (
        <LabeledInput
          label="Account Key"
          staticLabel
          value={accountKey}
          onChange={(e) => {
            const newAccountKey = e.target.value;
            newAccountKey !== accountKey &&
              onChange &&
              onChange({ ...value, accountKey: newAccountKey });
          }}
        />
      )}
    </>
  );
}

export default AzureBlobStorageConfigView;
