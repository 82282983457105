import React from "react";
import { LabeledInput } from "../../labeled-control/labeled-control";

function AthenaConfigView(props) {
  const { value, onChange } = props;
  const {
    regionName,
    awsAccessKeyId,
    awsSecretAccessKey,
    s3StagingDir,
    workgroup = "",
  } = value;
  return (
    <>
      <LabeledInput
        label="Region"
        staticLabel
        value={regionName}
        onChange={(e) => {
          const newRegionName = e.target.value;
          onChange && onChange({ ...value, regionName: newRegionName });
        }}
      />
      <LabeledInput
        label="Access Key ID"
        staticLabel
        autoComplete="off"
        value={awsAccessKeyId}
        onChange={(e) => {
          const newAwsAccessKeyId = e.target.value;
          onChange && onChange({ ...value, awsAccessKeyId: newAwsAccessKeyId });
        }}
      />
      <LabeledInput
        label="Secret Access Key"
        staticLabel
        type="password"
        autoComplete="off"
        value={awsSecretAccessKey}
        onChange={(e) => {
          const newAwsSecretAccessKey = e.target.value;
          onChange && onChange({ ...value, awsSecretAccessKey: newAwsSecretAccessKey });
        }}
      />
      <LabeledInput
        label="Staging Directory"
        staticLabel
        value={s3StagingDir}
        onChange={(e) => {
          const newS3StagingDir = e.target.value;
          onChange && onChange({ ...value, s3StagingDir: newS3StagingDir });
        }}
      />
      <LabeledInput
        label="Workgroup (optional)"
        staticLabel
        value={workgroup}
        onChange={(e) => {
          const newWorkgroup = e.target.value;
          onChange && onChange({ ...value, workgroup: newWorkgroup });
        }}
      />
    </>
  );
}

export default AthenaConfigView;
