import React, { useState } from "react";
import ConfigCard from "../config-card";
import { NgTag } from "../../tag-group/ng-tag";
import { PlusOutlined } from "@ant-design/icons";
import { LabeledSelect } from "../../labeled-control/labeled-control";

import "./add-tags.scss";

export function TagsConfigItem(props) {
  const { value, onChange, tagList, disabled = false } = props;

  const [availableTags, setAvailableTags] = useState(tagList);
  const [currentSearchInput, setCurrentSearchInput] = useState("");
  const [open, setOpen] = useState(false);

  const addedTagsSet = new Set(value);

  const tagOptions = availableTags
    .filter((tagLabel) => !addedTagsSet.has(tagLabel))
    .map((tagLabel) => ({ label: tagLabel, value: tagLabel }));

  function removeTag(tagLabelToRemove) {
    onChange(value.filter((tagLabel) => tagLabel !== tagLabelToRemove));
  }

  function addTag(tagLabelToAdd) {
    onChange([...value, tagLabelToAdd]);
  }

  function isValidTagLength(tagLabelToAdd) {
    return tagLabelToAdd.length <= 90 && tagLabelToAdd.length > 0;
  }

  function addAvailableTag(tagLabelToAdd) {
    setAvailableTags([...availableTags, tagLabelToAdd]);
    addTag(tagLabelToAdd);
    setOpen(false);
  }

  function addValidTag(currentSearchInput) {
    if (isValidTagLength(currentSearchInput)) {
      if (!availableTags.includes(currentSearchInput)) {
        addAvailableTag(currentSearchInput);
      } else if (!addedTagsSet.has(currentSearchInput)) {
        addTag(currentSearchInput);
      }
    }
  }

  function onSearchKeyDown(e) {
    const { keyCode } = e;
    if (keyCode === 13) {
      addValidTag(currentSearchInput);
    }
  }

  return (
    <div className="add-tags">
      <LabeledSelect
        label=""
        placeholder="Search for tags..."
        size="large"
        enableSorting={true}
        options={tagOptions}
        value={null}
        showSearch
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        onInputKeyDown={onSearchKeyDown}
        defaultActiveFirstOption={false}
        disabled={disabled}
        notFoundContent={
          <button
            className="add-tags-create-new-tag-button"
            disabled={disabled}
            onClick={() => addValidTag(currentSearchInput)}
          >
            <PlusOutlined />
            <span>Create new tag</span>
          </button>
        }
        onChange={addTag}
        onSearch={setCurrentSearchInput}
      />
      <div className="add-tags-added-tags-container">
        {value.map((tagLabel) => (
          <NgTag
            key={tagLabel}
            label={tagLabel}
            onRemoveClick={removeTag}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
}

function AddTags(props) {
  const { configData, tagList, onConfigDataChange, disabled = false } = props;

  return (
    <ConfigCard title="Add tags">
      <TagsConfigItem
        disabled={disabled}
        value={configData.metadata.tags || []}
        tagList={tagList}
        onChange={(tags) => {
          onConfigDataChange({
            ...configData,
            metadata: {
              ...configData.metadata,
              tags,
            },
          });
        }}
      />
    </ConfigCard>
  );
}

export default AddTags;
