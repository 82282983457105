import React from "react";
import IncidentDetailLineView from "../incident-detail-line-view";
import {
  SymptomTypeConst,
  AutoMetricsType,
  getSymptomTypeDisplayName,
} from "../../../utils/enums";
import { getRangeFromSubRanges, getYAxisRange } from "../../../utils/general";
import { isRawDataCorrelated as isRawDataCorrelatedFunc } from "../../../utils/monitor";

const defaultY2TitleMapper = {
  [AutoMetricsType.MISSING_VALUE]: "Null %",
  [AutoMetricsType.DATA_DELAY]: "Lag (minutes)",
  [AutoMetricsType.VOLUME]: "Row Count",
  [AutoMetricsType.CONFORMITY_COUNT]: "% conforming",
  [AutoMetricsType.ROW_COUNT]: "Row Count",
  [AutoMetricsType.NONE]: "Value",
};

function DataLineChartView(props) {
  const {
    data,
    incidents,
    config,
    chartOption = {
      showStatsData: false,
      showSignalData: true,
    },
    metricType,
    DetailLineViewComponent = IncidentDetailLineView,
  } = props;

  const { showStatsData, showSignalData } = chartOption;

  let {
    symptomType,
    feature,
    autosize = false,
    height = 350,
    marginTop = 25,
    marginBottom = 25,
    startTime,
    endTime,
    detectInterval,
    isIncidentOnly = false,
    xTitle = "",
    y2Title,
    textHeightInPx = 12,
  } = config;

  y2Title = y2Title || defaultY2TitleMapper[metricType] || "";

  if (!data) {
    return null;
  }

  const metricsData =
    data && data.metricsData
      ? data.metricsData
      : {
          projectedData: [],
          normalData: [],
          lowerBoundData: [],
          upperBoundData: [],
          signalData: [],
        };

  const duration = data && data.duration ? data.duration : {};
  const range =
    data && data.range
      ? data.range
      : {
          metricsRange: { min: undefined, max: undefined },
          signalRange: { min: undefined, max: undefined },
        };

  const { metricsRange, signalRange } = range;

  let incidentTextHeight = 10;
  let metricTextHeight = 0;
  let yAxisMin = 0;
  let yAxisMax = 100;
  let yAxis2Min = 0;
  let yAxis2Max = 100;
  let xAxisMin = undefined;
  let xAxisMax = undefined;
  let currentRange = null;
  const isRawDataCorrelated = isRawDataCorrelatedFunc(symptomType, feature);
  if (isRawDataCorrelated) {
    const dataRangeObject = showStatsData
      ? { metricsRange, signalRange }
      : { signalRange };
    currentRange = getRangeFromSubRanges(dataRangeObject);
    yAxisMin = yAxis2Min = currentRange.min;
    yAxisMax = yAxis2Max = currentRange.max;
  } else {
    currentRange = signalRange;
    yAxisMin = metricsRange.min;
    yAxisMax = metricsRange.max;
    yAxis2Min = signalRange.min;
    yAxis2Max = signalRange.max;

    if (yAxisMin === yAxisMax) {
      yAxisMax = yAxisMin + 1;
    }
  }

  if (
    currentRange &&
    typeof currentRange.min === "number" &&
    typeof currentRange.max === "number"
  ) {
    let heightInNumber = 100;
    if (typeof height === "number") {
      heightInNumber = height;
    } else if (typeof height === "string") {
      if (height.endsWith("px")) {
        heightInNumber = parseInt(height.replace(/px/, ""));
      } else if (height.endsWith("vh")) {
        const heightInVh = parseInt(height.replace(/vh/, ""));
        heightInNumber = Math.ceil((heightInVh * window.innerHeight) / 100);
      }
    }

    const textHeightFactor = (1.1 * textHeightInPx) / (heightInNumber - textHeightInPx);
    incidentTextHeight = textHeightFactor * (currentRange.max - currentRange.min);
    const alignedYAxisRange = getYAxisRange(
      currentRange.min,
      currentRange.max + incidentTextHeight
    );
    if (isRawDataCorrelated) {
      yAxisMin = yAxis2Min = alignedYAxisRange.yAxisMin;
      yAxisMax = yAxis2Max = alignedYAxisRange.yAxisMax;
      metricTextHeight = incidentTextHeight =
        textHeightFactor * (yAxis2Max - yAxis2Min);
    } else {
      yAxis2Min = alignedYAxisRange.yAxisMin;
      yAxis2Max = alignedYAxisRange.yAxisMax;
      if (typeof yAxisMax === "number" && typeof yAxisMin === "number") {
        metricTextHeight = textHeightFactor * (yAxisMax - yAxisMin);
        const alignedMetricYAxisRange = getYAxisRange(
          yAxisMin,
          yAxisMax + metricTextHeight
        );
        yAxisMin = alignedMetricYAxisRange.yAxisMin;
        yAxisMax = alignedMetricYAxisRange.yAxisMax;
      }

      incidentTextHeight = textHeightFactor * (yAxis2Max - yAxis2Min);
    }
  }

  if (
    duration &&
    typeof duration.startTime === "number" &&
    typeof duration.endTime === "number"
  ) {
    xAxisMin = duration.startTime;
    xAxisMax = duration.endTime;
  }

  const isCPIncident = symptomType === SymptomTypeConst.SLOW_BURN_TREND_CHANGE;

  const layoutConfig = {
    xAxisMin,
    xAxisMax,
    yAxisMin,
    yAxisMax,
    yAxis2Min,
    yAxis2Max,
    startTime,
    endTime, // Anomaly startTime
    metricTextHeight,
    incidentTextHeight,
    autosize,
    ...(height ? { height } : {}),
    marginTop,
    marginBottom,
  };

  let chartData = { ...metricsData };
  if (!showStatsData) {
    chartData.normalData = [];
    chartData.lowerBoundData = [];
    chartData.upperBoundData = [];
    chartData.projectedData = [];
  }

  if (!showSignalData) {
    chartData.signalData = [];
  }

  return (
    <div className="data-line-chart-content" style={{ minHeight: "20vh" }}>
      <DetailLineViewComponent
        data={chartData}
        incidentList={incidents}
        selectedSeason={null}
        yTitle={symptomType && getSymptomTypeDisplayName(symptomType, feature)}
        layoutConfig={layoutConfig}
        detectInterval={detectInterval}
        isCPIncident={isCPIncident}
        isIncidentOnly={isIncidentOnly}
        xTitle={xTitle}
        y2Title={y2Title}
        height={height}
      />
    </div>
  );
}

export default DataLineChartView;
