import { KPI_CATEGORY_KEY_NAME } from "./constants";
import {
  IncidentCreatorType,
  IncidentStatus,
  IncidentValidationStatus,
  TableType,
} from "./enums";
import { getFullTableName } from "./datasource";

export function getTagsFromCreatorInfo(creatorInfo, returnEmptyTags = true) {
  let tags = [];
  if (!creatorInfo) {
    return tags;
  }

  if (creatorInfo.type === IncidentCreatorType.FILTER) {
    if (creatorInfo.filterInfo.metadata.tags) {
      tags = [...tags, ...creatorInfo.filterInfo.metadata.tags];
    }

    if (creatorInfo.kpiInfo.metadata.tags) {
      tags = [...tags, ...creatorInfo.kpiInfo.metadata.tags];
    }
  }

  if (creatorInfo.dataSourceInfo && creatorInfo.dataSourceInfo.metadata.tags) {
    tags = [...tags, ...creatorInfo.dataSourceInfo.metadata.tags];
  }

  const tagNames = [];
  const tagNameMapper = {};
  for (let currentTag of tags) {
    if (tagNameMapper[currentTag]) {
      continue;
    }

    tagNameMapper[currentTag] = true;
    tagNames.push(currentTag);
  }

  if (tagNames.length === 0 && !returnEmptyTags) {
    return ["Untagged"];
  }

  return tagNames;
}

export function getKPIDisplayStr(incidentCreator) {
  const { type, dataSourceInfo, kpiInfo } = incidentCreator;
  if (type !== IncidentCreatorType.FILTER) {
    return "";
  }

  const validValueArray = [];
  dataSourceInfo && validValueArray.push(dataSourceInfo.metadata.name);
  if (kpiInfo.config.table && kpiInfo.config.table.type === TableType.TABLE) {
    validValueArray.push(getFullTableName(kpiInfo.config.table));
  }

  if (kpiInfo.config.valueColumns && kpiInfo.config.valueColumns.length > 0) {
    validValueArray.push(kpiInfo.config.valueColumns[0].columnName);
  }

  return validValueArray.join(" \u203A ");
}

const incidentStatusDisplayValueMapper = {
  [IncidentStatus.UNVIEWED]: "Unviewed",
  [IncidentStatus.VIEWED]: "Viewed",
  [IncidentStatus.REJECTED]: "Rejected",
  [IncidentStatus.SUBMITTED]: "Submitted",
  [IncidentStatus.CLOSED]: "Closed",
};

export function getIncidentStatusDisplayStr(incidentStatus) {
  return incidentStatusDisplayValueMapper[incidentStatus] || "";
}

const incidentValidationStatusDisplayValueMapper = {
  [IncidentValidationStatus.NOT_RUNNING]: "Not run",
  [IncidentValidationStatus.RUNNING]: "Running",
  [IncidentValidationStatus.CANCELING]: "Canceling",
  [IncidentValidationStatus.RESOLVED]: "Resolved",
  [IncidentValidationStatus.UNRESOLVED]: "Unresolved",
  [IncidentValidationStatus.ERROR]: "Error",
};

export function getIncidentValidationStatusDisplayStr(incidentValidationStatus) {
  return incidentValidationStatusDisplayValueMapper[incidentValidationStatus] || "";
}

export function getCategoryFromIncident(currentIncident) {
  try {
    return JSON.parse(currentIncident.reason)["slice_values"].map(
      (currentValue) => currentValue[KPI_CATEGORY_KEY_NAME]
    );
  } catch (err) {
    console.log(
      `Fail to parse incident reason with ${JSON.stringify(currentIncident)}`
    );
    return [];
  }
}
