import React from "react";
import "./config-card.scss";

function ConfigCard({
  className,
  title,
  actionComponent = null,
  description,
  children,
  testId,
}) {
  return (
    <div className={`config-card-container ${className ?? ""}`} data-testid={testId}>
      <div className="config-card-header-container">
        <div className="config-card-title-container">{title}</div>
        {actionComponent}
      </div>
      {description && (
        <div className="config-card-description-container">{description}</div>
      )}
      {children && <div className="config-card-content-container">{children}</div>}
    </div>
  );
}

export default ConfigCard;
