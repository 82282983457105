import React, { useEffect } from "react";
import { connect } from "react-redux";

import NotificationView from "./notification-view";

import { getNotificationDetailInfo } from "../../actions/notification/notification-action";
import { Spinner } from "../../atom/spinner";

import "./notification-detail-panel.scss";

function NotificationDetailPanel(props) {
  const {
    workspaceUuid,
    workspaceUserPermissions,
    eventUuid,
    getNotificationDetailInfo,
    notificationDetailInfo,
    closeTakeover,
  } = props;

  useEffect(() => {
    getNotificationDetailInfo(workspaceUuid, eventUuid);
  }, [workspaceUuid, eventUuid, getNotificationDetailInfo]);

  if (notificationDetailInfo?.loading) {
    return (
      <div className="notification-detail-panel-takeover-view-container">
        <div className="notification-detail-panel-takeover-view-container-spinner">
          <Spinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="notification-detail-panel-takeover-view-container">
      <NotificationView
        value={notificationDetailInfo}
        onClose={closeTakeover}
        workspaceUuid={workspaceUuid}
        workspaceUserPermissions={workspaceUserPermissions}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  notificationDetailInfo: state.notificationReducer.notificationDetailInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getNotificationDetailInfo: (workspaceUuid, errorRecord) =>
    dispatch(getNotificationDetailInfo(workspaceUuid, errorRecord)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetailPanel);
