import React from "react";
import LegacyModalDialog from "../../components/legacy-adapter/legacy-modal-dialog";

import "./disclaimer-dialog.scss";

function DisclaimerDialogBodyComponent(props) {
  const { isAccepted, onIsAcceptChange } = props;

  return (
    <div className="disclaimer-dialog-body-container">
      <div className="disclaimer-dialog-review-header-container">
        Please review the Terms of use. You must accept the following to continue.
      </div>
      <div className="disclaimer-dialog-review-list-container">
        <div className="disclaimer-dialog-review-item-container">
          <a
            href="https://www.lightup.ai/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <span> </span>- This document explains your rights and obligations
        </div>
        <div className="disclaimer-dialog-review-item-container">
          <a
            href="https://www.lightup.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <span> </span>- This document explains your privacy
        </div>
      </div>
      <div className="disclaimer-dialog-accept-container">
        <input type="checkbox" checked={isAccepted} onChange={onIsAcceptChange} />
        <label>I accept the terms of service and privacy policy</label>
      </div>
    </div>
  );
}

function DisclaimerDialogFooterComponent(props) {
  const { onCancelClicked, onOKClicked, isAccepted } = props;

  return (
    <>
      <button
        className={`ok-button ${isAccepted ? "" : "disabled"}`}
        onClick={onOKClicked}
      >
        Accept
      </button>
      <button className="cancel-button" onClick={onCancelClicked}>
        Cancel
      </button>
    </>
  );
}

function DisclaimerDialog(props) {
  const { onAcceptDisclaimer, onRejectDisclaimer } = props;

  const [isAccepted, setIsAccepted] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  function onOKClicked() {
    if (onAcceptDisclaimer) {
      onAcceptDisclaimer();
    }
    closeModal();
  }

  function onCancelClicked() {
    if (onRejectDisclaimer) {
      onRejectDisclaimer();
    }
    closeModal();
  }

  function onIsAcceptChange() {
    setIsAccepted(!isAccepted);
  }

  return (
    <LegacyModalDialog
      title={"Terms of Use"}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"disclaimer-dialog-container"}
      triggerComponent={null}
      onCloseIcon={onCancelClicked}
      contentComponent={
        <DisclaimerDialogBodyComponent
          isAccepted={isAccepted}
          onIsAcceptChange={onIsAcceptChange}
        />
      }
      footerComponent={
        <DisclaimerDialogFooterComponent
          isAccepted={isAccepted}
          onOKClicked={onOKClicked}
          onCancelClicked={onCancelClicked}
        />
      }
    />
  );
}

export default DisclaimerDialog;
