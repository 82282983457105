import React from "react";
import IncidentDirectionSelector from "../incident-direction-selector/incident-direction-selector";
import { IncidentScore } from "../../utils/enums";

function IncidentScoreNormalIcon({ isSelected }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.6875C3.28906 0.6875 0.6875 3.28906 0.6875 6.5C0.6875 9.71094 3.28906 12.3125 6.5 12.3125C9.71094 12.3125 12.3125 9.71094 12.3125 6.5C12.3125 3.28906 9.71094 0.6875 6.5 0.6875Z"
        fill="#FAB800"
      />
    </svg>
  );
}

function IncidentScoreHighIcon({ isSelected }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.6875C3.28906 0.6875 0.6875 3.28906 0.6875 6.5C0.6875 9.71094 3.28906 12.3125 6.5 12.3125C9.71094 12.3125 12.3125 9.71094 12.3125 6.5C12.3125 3.28906 9.71094 0.6875 6.5 0.6875Z"
        fill="#CE484D"
      />
    </svg>
  );
}

function IncidentSeveritySelector(props) {
  const { value, onChange } = props;

  return (
    <IncidentDirectionSelector
      preserveIconColor
      value={value}
      onChange={onChange}
      DownValue={IncidentScore.NORMAL}
      UpValue={IncidentScore.HIGH}
      DownIcon={IncidentScoreNormalIcon}
      UpIcon={IncidentScoreHighIcon}
    />
  );
}

export default IncidentSeveritySelector;
