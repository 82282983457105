export function ApprovalIcon() {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2993 1.29289C17.6484 0.902369 18.2143 0.902369 18.5634 1.29289L20.7981 3.79289C21.1472 4.18342 21.1472 4.81658 20.7981 5.20711L14.094 12.7071C13.9264 12.8946 13.699 13 13.4619 13H11.2272C10.7336 13 10.3334 12.5523 10.3334 12V9.5C10.3334 9.23478 10.4275 8.98043 10.5952 8.79289L17.2993 1.29289ZM12.1211 9.91421V11H13.0917L18.9019 4.5L17.9314 3.41421L12.1211 9.91421ZM1.39453 14.5C1.39453 12.567 2.79525 11 4.52312 11H7.65171C8.14539 11 8.54559 11.4477 8.54559 12C8.54559 12.5523 8.14539 13 7.65171 13H4.52312C3.78261 13 3.1823 13.6716 3.1823 14.5C3.1823 15.3284 3.78261 16 4.52312 16H17.9314C19.6592 16 21.0599 17.567 21.0599 19.5C21.0599 21.433 19.6592 23 17.9314 23H8.54559C8.05192 23 7.65171 22.5523 7.65171 22C7.65171 21.4477 8.05192 21 8.54559 21H17.9314C18.6719 21 19.2722 20.3284 19.2722 19.5C19.2722 18.6716 18.6719 18 17.9314 18H4.52312C2.79525 18 1.39453 16.433 1.39453 14.5Z"
        fill="#121224"
      />
    </svg>
  );
}
