import { v4 as uuidv4 } from "uuid";
import createAuth0Client from "@auth0/auth0-spa-js";

const UNVERIFIED_EMAIL_ERROR_PREFIX = "unverifiedEmail";
export const UNVERIFIED_EMAIL_CODE = 999;

class Auth0 {
  constructor() {
    this._auth0 = null;
    console.log("Creating auth0 object");
  }

  async init(clientId, hostedDomain) {
    if (this._auth0) {
      return false;
    }

    try {
      this._auth0 = await createAuth0Client({
        domain: hostedDomain,
        client_id: clientId,
        redirect_uri: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`,
        useRefreshTokens: true,
        cacheLocation: "localstorage",
      });
    } catch (err) {
      console.log(`Fail to create client with ${err}`);
      // clear local storage keys set by auth0spa.js
      for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key) && key.startsWith("@@auth0spajs@@")) {
          console.log(
            `Removed ${key}: ${localStorage.getItem(key)} from local storage`
          );
          localStorage.removeItem(key);
        }
      }
      console.log("Trying to create client again with cleared local store");
      try {
        // We don't want to cache the client in this callback, so we don't add the cacheLocation
        this._auth0 = await createAuth0Client({
          domain: hostedDomain,
          client_id: clientId,
          redirect_uri: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`,
          useRefreshTokens: true,
        });
      } catch (err) {
        console.log(`Fail to create client again with ${err.error_description}`);
        if (err.error_description.includes(UNVERIFIED_EMAIL_ERROR_PREFIX)) {
          const unverifiedError = new Error();
          unverifiedError.code = UNVERIFIED_EMAIL_CODE;
          unverifiedError.message = "Unverified email";
          throw unverifiedError;
        }
        return false;
      }
      return true;
    }

    return true;
  }

  async refreshAccessToken() {
    try {
      const token = await this._auth0.getTokenSilently({ ignoreCache: true });
      console.log(token);
      return true;
    } catch (err) {
      console.log(`Fail to get access token with ${err.error_description}`);
      return false;
    }
  }

  async tryLoginWithDirect(fromLocation = "/") {
    let idToken = "";
    let nextUrl = null;
    if (!this._auth0) {
      console.log("Auth0 is not initialized before tryLogin");
      return idToken;
    }

    if (
      window.location.search.includes("error=") &&
      window.location.search.includes("state=")
    ) {
      const error = new Error("Auth0 Login Error");
      if (
        window.location.search.includes(
          `error_description=${UNVERIFIED_EMAIL_ERROR_PREFIX}`
        )
      ) {
        error.code = UNVERIFIED_EMAIL_CODE;
        error.message = "Unverified email";
      } else {
        error.code = 403;
        error.message = "Auth0 Unauthorized user";
      }
      throw error;
    }

    let needRedirect = false;
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      try {
        const { appState: appStateUuid } = await this._auth0.handleRedirectCallback();
        console.log(`App state is ${appStateUuid}`);
        nextUrl = "/";
        if (appStateUuid) {
          nextUrl = window.localStorage.getItem(appStateUuid) || nextUrl;
          window.localStorage.removeItem(appStateUuid);
          console.log(`previousPath is ${nextUrl}`);
        }

        const idTokenClaims = await this._auth0.getIdTokenClaims();

        // Remove the code and state in the url bar.
        window.history.pushState({}, document.title, window.location.pathname);

        idToken = idTokenClaims.__raw;
      } catch (err) {
        console.log("Fail to login with auth0 automatically");
        needRedirect = true;
      }
    } else {
      needRedirect = true;
    }

    if (needRedirect) {
      console.log("Need redirect to oauth login page");
      const appStateUuid = uuidv4();
      window.localStorage.setItem(appStateUuid, fromLocation);
      this._auth0.loginWithRedirect({ appState: appStateUuid });
    }

    return {
      idToken,
      nextUrl,
    };
  }

  logout() {
    if (!this._auth0) {
      console.log("Auth0 is not initialized before logout");
      return;
    }

    const returnTo = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`;
    this._auth0.logout({
      returnTo,
    });
  }
}

class MockAuth0 {
  async init(_clientId, _hostedDomain) {
    return true;
  }

  async refreshAccessToken() {
    return true;
  }

  async tryLoginWithDirect(fromLocation = "/") {
    return {
      idToken: "DUMMY_TOKEN",
      nextUrl: fromLocation,
    };
  }

  logout() {}
}

export default process.env.NODE_ENV === "test" ||
process.env.IS_STORYBOOK === "true" ||
process.env.REACT_APP_USE_MOCK_SERVICE_WORKER === "true"
  ? new MockAuth0()
  : new Auth0();
