import React, { Component } from "react";
import IncidentMainView from "./incident-main-view";
import { withRouter, Redirect } from "react-router";
import { AppPermissions } from "../../utils/permissions";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import { PollManager } from "../../utils/poll";
import {
  INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
  INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
  KPI_CATEGORY_KEY_NAME,
  KPI_PERCENTILE_KEY_NAME,
} from "../../utils/constants";
import {
  IncidentCreatorType,
  IncidentStatus,
  IncidentValidationStatus,
  TakeoverWidth,
} from "../../utils/enums";
import { AlertingChannelType } from "../../utils/alerting";
import { getIncidentQuickTimeOptions } from "../../utils/time";
import { getIncidentDetailSettingFromQueryString } from "../../utils/incident-detail-setting";
import getUnixTime from "date-fns/getUnixTime";
import IncidentCommentPanel from "./incident-comment-panel";
import IncidentSubmitTicketPanel from "./incident-submit-ticket-panel";
import { getAutoMetricTypeFromKPI } from "../../utils/metric";
import { TriggerButton } from "../../components/button/ng-button";
import { SubmitType } from "../integrations/integration-metadata";
import { IncidentMainTabKey, getKeyForChart } from "./utils";
import {
  getCorrelationChartListFromConfig,
  configUpdateMetric,
} from "./incident-config";
import IncidentAnalysisMetricsSidebar from "./incident-analysis-metrics-sidebar";
import ProfilerMetricQueryHistory from "../profiler/takeover/query-history";
import { Spinner } from "../../atom/spinner";

import "./incident.scss";

function ExpandSidebarIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M2.33333 2.625C2.01117 2.625 1.75 2.88617 1.75 3.20833C1.75 3.5305 2.01117 3.79167 2.33333 3.79167H11.6667C11.9888 3.79167 12.25 3.5305 12.25 3.20833C12.25 2.88617 11.9888 2.625 11.6667 2.625H2.33333Z"
        fill="#121224"
      />
      <path
        d="M2.33333 6.41667C2.01117 6.41667 1.75 6.67783 1.75 7C1.75 7.32217 2.01117 7.58333 2.33333 7.58333H10.8416L10.1876 8.23736C9.95983 8.46517 9.95983 8.83451 10.1876 9.06232C10.4155 9.29012 10.7848 9.29012 11.0126 9.06231L12.6568 7.41809C12.7052 7.37097 12.7455 7.31552 12.7753 7.25405C12.8075 7.1875 12.8275 7.11389 12.8322 7.03617C12.8342 7.00369 12.8335 6.97108 12.8301 6.93869C12.8266 6.90441 12.82 6.87101 12.8108 6.83876C12.7844 6.74673 12.735 6.65991 12.6625 6.58743L11.0126 4.9375C10.7848 4.70969 10.4155 4.70969 10.1876 4.93749C9.95983 5.1653 9.95983 5.53464 10.1876 5.76245L10.8418 6.41667H2.33333Z"
        fill="#121224"
      />
      <path
        d="M1.75 10.7917C1.75 10.4695 2.01117 10.2083 2.33333 10.2083H11.6667C11.9888 10.2083 12.25 10.4695 12.25 10.7917C12.25 11.1138 11.9888 11.375 11.6667 11.375H2.33333C2.01117 11.375 1.75 11.1138 1.75 10.7917Z"
        fill="#121224"
      />
    </svg>
  );
}

const ticketIntegrationTypes = [
  AlertingChannelType.JIRA,
  AlertingChannelType.SERVICENOW,
];

class IncidentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workspaceUuid: "",
      basicInfo: {
        incidentId: 0,
        startTime: 0,
        endTime: 0,
        reason: "",
        displayStartTime: 0,
        displayEndTime: 0,
        slice: {},
      },
      currentTabKey: IncidentMainTabKey.CHART,
      timeRange: {
        startTime: getUnixTime(new Date()),
        endTime: getUnixTime(new Date()),
      },
      config: null,
      uuid: "",
      filterUuid: "",
      previewUuid: "",
      currentIncident: this.props.currentIncident,
      filterInfo: null,
      kpiInfo: null,
      creatorInfo: null,
      goToRuleSetup: false,
      goToKpiSetup: false,
      isOnMainPage: null,
      quickOptions: [],
      nextUrl: null,
      ticketSubmitting: false,
      leftPanelCollapsed: true,
      correlationChartList: [],
      correlationChartListMonitorSetting: {},
    };

    this.optionMapping = {};

    this.setCurrentTabKey = this.setCurrentTabKey.bind(this);
    this.onChangeIncidentStatusClick = this.onChangeIncidentStatusClick.bind(this);
    this.onValidationStatusClick = this.onValidationStatusClick.bind(this);
    this.onRemoveCorrelatedChart = this.onRemoveCorrelatedChart.bind(this);
    this.onDisplayTimeRangeChange = this.onDisplayTimeRangeChange.bind(this);
    this.onGoToRuleSetup = this.onGoToRuleSetup.bind(this);
    this.onMainPageChange = this.onMainPageChange.bind(this);
    this.onViewAllActivities = this.onViewAllActivities.bind(this);
    this.updateSliceValueList = this.updateSliceValueList.bind(this);
    this.onSubmitTicketClick = this.onSubmitTicketClick.bind(this);
    this.onAddCommentClick = this.onAddCommentClick.bind(this);
    this.onEditCommentClick = this.onEditCommentClick.bind(this);
    this.onCloseIncidentClick = this.onCloseIncidentClick.bind(this);
    this.onFocusedMonitorChange = this.onFocusedMonitorChange.bind(this);
    this.onQueryHistoryView = this.onQueryHistoryView.bind(this);
    this._pollManager = null;
  }

  componentWillUnmount() {
    this.stopPollManager();
    this.props.resetCurrentIncident();
  }

  stopPollManager() {
    if (this._pollManager) {
      this._pollManager.stop();
      this._pollManager = null;
      console.log("Incident validation status polling is stopped");
    }
  }

  componentDidUpdate() {
    const {
      basicInfo: { validation },
    } = this.state;

    if (
      !this._pollManager &&
      validation &&
      [IncidentValidationStatus.RUNNING, IncidentValidationStatus.CANCELING].includes(
        validation.status
      )
    ) {
      const {
        match: {
          params: { uuid, workspaceUuid },
        },
      } = this.props;
      this._pollManager = new PollManager(60 * 1000, 24 * 3600 * 1000);
      this.props.startPollingIncidentValidationStatus(
        workspaceUuid,
        this._pollManager,
        uuid
      );
      console.log("Start incident validation status polling");
    } else if (
      this._pollManager &&
      (!validation ||
        ![
          IncidentValidationStatus.RUNNING,
          IncidentValidationStatus.CANCELING,
        ].includes(validation.status))
    ) {
      console.log("Stopping incident validation status polling");
      this.stopPollManager();
    }
  }

  static getIncidentData(props, state) {
    const { uuid, previewUuid, timeRange } = state;
    const {
      currentIncident: { basicInfo, creatorInfo },
      match: {
        params: { workspaceUuid },
      },
    } = props;
    if (!basicInfo || !creatorInfo) {
      console.log(
        "Skip fetching incident data for basic info or filter or kpi is empty."
      );
      return;
    }

    const queryObject = {};
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      let sliceKeyValuePairs = [];
      queryObject.autoMetricType = getAutoMetricTypeFromKPI(creatorInfo.kpiInfo);
      for (const sliceKey in basicInfo.slice) {
        if (
          [
            INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
            INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
            KPI_PERCENTILE_KEY_NAME,
            KPI_CATEGORY_KEY_NAME,
          ].includes(sliceKey)
        ) {
          continue;
        }

        sliceKeyValuePairs.push({
          key: sliceKey,
          value: basicInfo.slice[sliceKey],
        });
      }

      queryObject.filterUuid = creatorInfo.filterInfo.metadata.uuid;
      queryObject.sliceKeyValuePairs = sliceKeyValuePairs;
      queryObject.previewUuid = previewUuid;
      queryObject.metric = creatorInfo.kpiInfo;
      queryObject.metricUuid = creatorInfo.kpiInfo.metadata.uuid;
    }

    queryObject.startTime = timeRange.startTime;
    queryObject.endTime = timeRange.endTime;

    props.getIncidentData(workspaceUuid, uuid, queryObject);
  }

  static updateIncidentStatus(props, state, targetStatus) {
    // If userPermissions not passed, allow full access
    const {
      workspaceUserPermissions,
      match: {
        params: { workspaceUuid },
      },
    } = props;

    const enableModifyIncident = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTDETAIL,
    ]);
    const { uuid } = state;
    const { status } = props.currentIncident.basicInfo;
    if (status === targetStatus) {
      return;
    }

    if (targetStatus === IncidentStatus.NONE && status !== IncidentStatus.UNVIEWED) {
      return;
    }

    if (targetStatus !== IncidentStatus.NONE && enableModifyIncident) {
      console.log(
        `Set incident ${uuid} status to ${targetStatus} from url action params.`
      );
      props.updateIncidentStatus(workspaceUuid, uuid, targetStatus);
      return;
    }

    // Since only the admin, editor, and viewer have access to this page, it's fine
    // to update the incident view status without additional restrictions.
    if (status === IncidentStatus.UNVIEWED) {
      // If user load the page, we set default READ status to VIEWED.
      console.log(`Due to loading the page, set incident ${uuid} status to accepted.`);
      props.updateIncidentStatus(workspaceUuid, uuid, IncidentStatus.VIEWED);
    }
  }

  static getIncidentCommentList(props, state) {
    const {
      match: {
        params: { workspaceUuid },
      },
    } = props;
    const { uuid } = state;
    props.getIncidentCommentList(workspaceUuid, uuid);
  }

  static getDerivedStateFromProps(props, state) {
    const uuid = props.match.params.uuid;
    const workspaceUuid = props.match.params.workspaceUuid;

    const config = props.config;
    const { filterUuid, previewUuid, action } = getIncidentDetailSettingFromQueryString(
      props.location.search
    );

    const { timeRange } = props.currentIncident.userSetting || {};
    let isChanged = false;
    let updateCorrelatedChartData = false;
    if (state.workspaceUuid !== workspaceUuid) {
      state = {
        ...state,
        workspaceUuid,
      };
      props.getDataSourceList(workspaceUuid);
      isChanged = true;
    }

    if (state.currentIncident !== props.currentIncident) {
      if (
        !state.currentIncident.basicInfo ||
        !props.currentIncident.basicInfo ||
        state.currentIncident.basicInfo.uuid !== props.currentIncident.basicInfo.uuid
      ) {
        state.timeRange = timeRange || {
          startTime: props.currentIncident.basicInfo.displayStartTime,
          endTime: props.currentIncident.basicInfo.displayEndTime,
        };

        IncidentView.getIncidentData(props, state, false);
        IncidentView.updateCurrentGroupIncidentList(props, state);
        let targetStatus = IncidentStatus.NONE;
        if (action === "accept") {
          targetStatus = IncidentStatus.VIEWED;
        } else if (action === "reject") {
          targetStatus = IncidentStatus.REJECTED;
        }

        IncidentView.updateIncidentStatus(props, state, targetStatus);

        const isOnMainPage =
          state.isOnMainPage === null ? !!previewUuid : state.isOnMainPage;
        // Fetch incident comment list.
        IncidentView.getIncidentCommentList(props, state);

        // Fetch incident case list.
        props.getIncidentCaseList(workspaceUuid, uuid);

        // Fetch integration list
        props.getIntegrationList(workspaceUuid);

        // Fetch current config
        props.getCurrentIncidentConfig(workspaceUuid, uuid, state.timeRange);

        updateCorrelatedChartData = true;
        state = {
          ...state,
          isOnMainPage,
          currentIncident: props.currentIncident,
          quickOptions: getIncidentQuickTimeOptions({
            startTimestamp: props.currentIncident.basicInfo.startTime,
            endTimestamp: props.currentIncident.basicInfo.startTime,
            displayStartTimestamp: props.currentIncident.basicInfo.displayStartTime,
            displayEndTimestamp: props.currentIncident.basicInfo.displayEndTime,
          }),
          basicInfo: props.currentIncident.basicInfo,
          filterInfo: props.currentIncident.filterInfo,
          kpiInfo: props.currentIncident.kpiInfo,
          creatorInfo: props.currentIncident.creatorInfo,
        };
      } else {
        state = {
          ...state,
          currentIncident: props.currentIncident,
          basicInfo: props.currentIncident.basicInfo,
        };
      }
      isChanged = true;
    }

    if (config !== state.config) {
      console.log(`Config is updated`);
      state = {
        ...state,
        config,
      };

      updateCorrelatedChartData = true;
      isChanged = true;
    }

    if (updateCorrelatedChartData) {
      const correlationChartList = getCorrelationChartListFromConfig(
        config,
        props.kpiList,
        props.ruleList,
        state.correlationChartListMonitorSetting
      );

      state = {
        ...state,
        correlationChartList,
      };
      IncidentView.updateCorrelatedChartListMetricsData(props, state);
    }

    if (
      state.uuid !== uuid ||
      state.filterUuid !== filterUuid ||
      state.previewUuid !== previewUuid
    ) {
      props.getIncident(workspaceUuid, { uuid, filterUuid, previewUuid });
      state = {
        ...state,
        uuid,
        filterUuid,
        previewUuid,
      };
      isChanged = true;
    }

    if (isChanged) {
      return state;
    }

    return null;
  }

  setCurrentTabKey(newTab) {
    this.setState({ currentTabKey: newTab });
  }

  onMainPageChange(isOnMainPage) {
    this.setState({
      isOnMainPage,
    });
  }

  onUpdateStatusCallback = () => {
    const { uuid } = this.state;

    const {
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    this.props.getIncidentCommentList(workspaceUuid, uuid);
    this.props.getIncidentCaseList(workspaceUuid, uuid);
  };

  onChangeIncidentStatusClick(newStatus) {
    const { uuid, currentIncident } = this.state;
    const {
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    console.log(`Incident is changed to ${newStatus}.`);
    const { status } = currentIncident.basicInfo;
    if (status !== newStatus) {
      this.props
        .updateIncidentStatus(workspaceUuid, uuid, newStatus)
        .then(() => this.onUpdateStatusCallback());
    } else {
      console.log(`Incident is already set to ${newStatus}.`);
    }
  }

  onValidationStatusClick(status) {
    const { uuid } = this.state;
    const {
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    this.props
      .updateIncidentValidationStatus(workspaceUuid, uuid, {
        validation: {
          status,
        },
      })
      .then(() => this.onUpdateStatusCallback());
  }

  onRemoveCorrelatedChart(chartItem) {
    let { metricUuid, slice } = chartItem;
    slice = Object.keys(slice).length === 0 ? null : slice;
    const { uuid, timeRange } = this.state;
    const {
      match: {
        params: { workspaceUuid },
      },
      config,
      getCurrentIncidentConfig,
      updateIncidentConfig,
    } = this.props;
    const newConfigData = configUpdateMetric(
      config.data.data,
      metricUuid,
      slice ? { slice } : null,
      (item) => ({ ...item, show: false })
    );
    updateIncidentConfig(workspaceUuid, uuid, { timeRange, data: newConfigData }).then(
      () => {
        getCurrentIncidentConfig(workspaceUuid, uuid, {
          startTime: timeRange.startTime,
          endTime: timeRange.endTime,
          quiet: true,
        });
      }
    );
  }

  static updateCorrelatedChartListMetricsData(props, state) {
    const {
      currentIncident: { basicInfo },
      match: {
        params: { workspaceUuid },
      },
    } = props;

    if (!basicInfo) {
      console.log("Skip fetching incident data for basic info or filter is empty.");
      return;
    }

    const { timeRange, correlationChartList } = state;
    if (!correlationChartList || correlationChartList.length === 0) {
      return;
    }

    props.getGroupIncidentListMetrics(workspaceUuid, {
      startTime: timeRange.startTime,
      endTime: timeRange.endTime,
      filterInfoList: correlationChartList,
    });
  }

  onGoToRuleSetup() {
    this.setState({ goToRuleSetup: true });
  }

  static updateCurrentGroupIncidentList(props, state) {
    const { uuid, filterUuid, previewUuid, timeRange } = state;
    const {
      match: {
        params: { workspaceUuid },
      },
    } = props;

    const queryObject = {
      uuid,
      filterUuid,
      previewUuid,
      queryStartTime: timeRange.startTime,
      queryEndTime: timeRange.endTime,
    };
    props.getCurrentGroupIncidentList(workspaceUuid, queryObject);
  }

  static getIncidentChartCommentList(commentList, currentFilterId, currentSlice) {
    return commentList.filter((commentItem) => {
      const { filterId, slice } = commentItem;

      return (
        filterId === currentFilterId &&
        JSON.stringify(currentSlice) === JSON.stringify(slice)
      );
    });
  }

  onDisplayTimeRangeChange(newTimeRange) {
    const timeRange = {
      startTime: getUnixTime(newTimeRange.startTime),
      endTime: getUnixTime(newTimeRange.endTime),
    };

    console.log(`New display time range is ${JSON.stringify(timeRange)}`);

    this.setState(
      {
        timeRange,
      },
      () => {
        const {
          match: {
            params: { workspaceUuid },
          },
          config = {},
        } = this.props;
        const { uuid } = this.state;
        IncidentView.getIncidentData(this.props, this.state, false);
        IncidentView.updateCurrentGroupIncidentList(this.props, this.state);
        IncidentView.updateCorrelatedChartListMetricsData(this.props, this.state);
        this.props.updateIncidentConfig(workspaceUuid, uuid, {
          data: config?.data?.data ? config.data.data : [],
          timeRange: timeRange,
        });
      }
    );
  }

  updateSliceValueList(workspaceUuid, ruleId) {
    const { timeRange } = this.state;
    this.props.getIncidentSliceValueList(workspaceUuid, ruleId, {
      startTimestamp: timeRange.startTime,
      endTimestamp: timeRange.endTime,
    });
  }

  onConfigObjectContextMenuClick(ev, { data, action, objectType }) {
    const { isRuleInfoDialogOpen, isKpiInfoDialogOpen } = this.state;
    if (action === "Edit") {
      if (objectType === "rule") {
        this.setState({ goToRuleSetup: true });
      } else if (objectType === "kpi") {
        this.setState({ goToKpiSetup: true });
      }
    } else if (action === "Info") {
      if (objectType === "rule") {
        this.setState({ isRuleInfoDialogOpen: !isRuleInfoDialogOpen });
      } else if (objectType === "kpi") {
        this.setState({ isKpiInfoDialogOpen: !isKpiInfoDialogOpen });
      }
    }
  }

  onViewAllActivities() {
    const { isOnMainPage } = this.state;
    if (!isOnMainPage) {
      this.setState({ isOnMainPage: true });
    }
  }

  onSubmitTicketClick() {
    const {
      openWorkspaceTakeover,
      closeTakeover,
      match: {
        params: { workspaceUuid },
      },
    } = this.props;
    const { uuid, basicInfo } = this.state;

    const jiraIntegrationList = this.props.integrationList.filter(
      (integration) =>
        ticketIntegrationTypes.includes(integration.type) &&
        integration.mode !== SubmitType.AUTOMATIC
    );

    openWorkspaceTakeover(
      <IncidentSubmitTicketPanel
        closeTakeover={closeTakeover}
        workspaceUuid={workspaceUuid}
        incident={basicInfo}
        integrationList={jiraIntegrationList}
        onSubmit={(integrationId, integrationType, comment, metadata) => {
          this.props
            .addIncidentTicket(workspaceUuid, {
              incidentId: basicInfo.incidentId,
              incidentUuid: uuid,
              id: integrationId,
              type: integrationType,
              comment,
              config: { metadata },
            })
            .finally(() => {
              this.setCurrentTabKey(IncidentMainTabKey.ACTIVITY);
              closeTakeover();
            });
        }}
      />,
      TakeoverWidth.NORMAL,
      () => closeTakeover()
    );
  }

  onAddCommentClick() {
    const {
      openWorkspaceTakeover,
      closeTakeover,
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    const { uuid } = this.state;

    openWorkspaceTakeover(
      <IncidentCommentPanel
        closeTakeover={closeTakeover}
        onSubmit={(comment) => {
          const newComment = {
            comment,
          };

          this.props.addIncidentComment(workspaceUuid, uuid, newComment).finally(() => {
            this.setCurrentTabKey(IncidentMainTabKey.ACTIVITY);
            closeTakeover();
          });
        }}
      />,
      TakeoverWidth.NORMAL,
      () => closeTakeover()
    );
  }

  onEditCommentClick(currentComment) {
    const {
      openWorkspaceTakeover,
      closeTakeover,
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    const { uuid } = this.state;

    openWorkspaceTakeover(
      <IncidentCommentPanel
        closeTakeover={closeTakeover}
        defaultValue={currentComment.comment}
        isEdit={true}
        onSubmit={(comment) => {
          this.props.editIncidentComment(workspaceUuid, uuid, {
            ...currentComment,
            comment,
          });
          closeTakeover();
        }}
      />,
      TakeoverWidth.NORMAL,
      () => closeTakeover()
    );
  }

  onCloseIncidentClick() {
    const {
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    const { uuid } = this.state;
    this.props.endIncident(workspaceUuid, uuid);
  }

  onFocusedMonitorChange(metricUuid, slice, newMonitorUuid) {
    const { correlationChartListMonitorSetting } = this.state;
    const { config } = this.props;
    const chartKey = getKeyForChart(metricUuid, slice);

    const newCorrelationChartListMonitorSetting = {
      ...correlationChartListMonitorSetting,
      [chartKey]: newMonitorUuid,
    };

    const correlationChartList = getCorrelationChartListFromConfig(
      config,
      this.props.kpiList,
      this.props.ruleList,
      newCorrelationChartListMonitorSetting
    );

    this.setState(
      {
        correlationChartListMonitorSetting: newCorrelationChartListMonitorSetting,
        correlationChartList,
      },
      () => {
        IncidentView.updateCorrelatedChartListMetricsData(this.props, this.state);
      }
    );
  }

  onQueryHistoryView(workspaceUuid, metric) {
    const { openWorkspaceTakeover, closeTakeover } = this.props;

    openWorkspaceTakeover(
      <ProfilerMetricQueryHistory
        workspaceUuid={workspaceUuid}
        metric={metric}
        closeTakeover={closeTakeover}
      />,
      TakeoverWidth.EXTENDED,
      () => closeTakeover()
    );
  }

  render() {
    const {
      uuid,
      previewUuid,
      basicInfo,
      creatorInfo,
      correlationChartList,
      correlationChartListMonitorSetting,
      timeRange,
      goToRuleSetup,
      goToKpiSetup,
      nextUrl,
      leftPanelCollapsed,
    } = this.state;

    // If userPermissions not passed, allow full access
    const {
      workspaceUserPermissions,
      history,
      match: {
        params: { workspaceUuid },
      },
      openWorkspaceTakeover,
      closeTakeover,
    } = this.props;

    if (!creatorInfo) {
      return (
        <div className="incident-view-loading">
          <Spinner size="large" />
        </div>
      );
    }

    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      if (goToRuleSetup) {
        return (
          <Redirect
            push
            to={getURIInstance(URIPath.EDIT_RULE, {
              workspaceUuid,
              uuid: creatorInfo.filterInfo.metadata.uuid,
            })}
          />
        );
      }

      if (goToKpiSetup) {
        return (
          <Redirect
            push
            to={getURIInstance(URIPath.EDIT_KPI, {
              workspaceUuid,
              uuid: creatorInfo.kpiInfo.metadata.uuid,
            })}
          />
        );
      }
    }

    if (nextUrl) {
      return <Redirect push to={nextUrl} />;
    }

    const { startTime, endTime, ongoing } = basicInfo;

    const enableSubmitTicket =
      hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_CASE_VIEWS_EDIT_CASELIST,
      ]) &&
      this.props.integrationList.filter(
        (integration) =>
          ticketIntegrationTypes.includes(integration.type) &&
          integration.mode === SubmitType.MANUAL
      ).length > 0;

    const enableAddComment = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTCOMMENTSVIEW,
    ]);

    const enableEditDeleteComment = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTCOMMENTSDETAILVIEW,
    ]);

    const enableCloseIncident =
      ongoing &&
      hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTDETAIL,
      ]);

    return (
      <div className="incident-view lightup-vertical-flex-container">
        <div className="incident-view-body">
          <div className="incident-view-body-inner-container">
            {leftPanelCollapsed ? (
              <div className="incident-detail-sidebar-collapsed">
                <TriggerButton
                  onClick={() => this.setState({ leftPanelCollapsed: false })}
                  testId="expand-incident-sidebar-button"
                >
                  <ExpandSidebarIcon />
                </TriggerButton>
              </div>
            ) : (
              <IncidentAnalysisMetricsSidebar
                workspaceUserPermissions={workspaceUserPermissions}
                config={this.props.config}
                history={history}
                workspaceUuid={workspaceUuid}
                incidentUuid={uuid}
                metrics={this.props.kpiList}
                monitors={this.props.ruleList}
                dataSources={this.props.dataSourceList.data}
                startTime={startTime}
                endTime={endTime}
                getCurrentIncidentConfig={this.props.getCurrentIncidentConfig}
                updateIncidentConfig={this.props.updateIncidentConfig}
                setLeftPanelCollapsed={() =>
                  this.setState({ leftPanelCollapsed: true })
                }
              />
            )}
            <IncidentMainView
              incident={basicInfo}
              incidentCreatorInfo={creatorInfo}
              incidentUuid={uuid}
              incidentPreviewUuid={previewUuid}
              incidentMainMetricsData={this.props.currentIncidentMainMetricsData}
              groupIncidentList={this.props.currentGroupIncidentList}
              groupIncidentListMetrics={this.props.currentGroupIncidentListMetrics}
              incidentCommentList={this.props.currentIncidentCommentList}
              incidentCaseList={this.props.incidentCaseList}
              workspaceUserPermissions={workspaceUserPermissions}
              workspaceUuid={workspaceUuid}
              dataSourceList={this.props.dataSourceList}
              kpiList={this.props.kpiList}
              ruleList={this.props.ruleList}
              currentTabKey={this.state.currentTabKey}
              setCurrentTabKey={this.setCurrentTabKey}
              timeRange={timeRange}
              correlationChartList={correlationChartList}
              timeRangeQuickOptions={this.state.quickOptions}
              history={this.props.history}
              onTimeRangeChange={this.onDisplayTimeRangeChange}
              mainViewMenuConfig={{
                onSubmitTicketClick: this.onSubmitTicketClick,
                onAddCommentClick: this.onAddCommentClick,
                onCloseIncidentClick: this.onCloseIncidentClick,
                enableSubmitTicket,
                enableAddComment,
                enableEditDeleteComment,
                enableCloseIncident,
              }}
              onDeleteComment={this.props.deleteIncidentComment.bind(
                this.props,
                workspaceUuid,
                uuid
              )}
              onEditComment={this.onEditCommentClick}
              onCaseUpdated={this.props.updateIncidentCase.bind(
                this.props,
                workspaceUuid
              )}
              onRemoveCorrelatedChart={this.onRemoveCorrelatedChart}
              onChangeIncidentStatusClick={this.onChangeIncidentStatusClick}
              onValidationStatusClick={this.onValidationStatusClick}
              onNextUrlChange={(nextUrl) => this.setState({ nextUrl })}
              openWorkspaceTakeover={openWorkspaceTakeover}
              closeTakeover={closeTakeover}
              correlationChartListMonitorSetting={correlationChartListMonitorSetting}
              onFocusedMonitorChange={this.onFocusedMonitorChange}
              onQueryHistoryView={this.onQueryHistoryView}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(IncidentView);
