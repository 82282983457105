import { EventCategory, EventLevel } from "../../utils/enums";
import { capitalizeFirstLetter } from "../../utils/general";

const EventLevelToColorMapper = Object.freeze({
  [EventLevel.ERROR]: "#F9C3D2",
  [EventLevel.INFO]: "#CAEBFF",
  [EventLevel.WARNING]: "#FFF0E3",
});

const EventCategoryDescription = Object.freeze({
  [EventCategory.ME001]: "Metric collection failed due to missing value filling error.",
  [EventCategory.ME002]: "Metric collection failed due to SQL error.",
  [EventCategory.ME003]: "Metric collection failed due to timeout.",
  [EventCategory.ME004]: "Metric collection failed due to configuration error.",
  [EventCategory.ME006]: "Metric is paused due to excess unique categories count.",
  [EventCategory.ME007]: "Metric is paused due to configuration error.",
  [EventCategory.ME008]: "Metric is paused due to SQL timeouts.",
  [EventCategory.MO001]: "New slices detected and require monitor training.",
  [EventCategory.MO002]: "Monitor training completed successfully.",
  [EventCategory.MO003]: "Monitor preview completed successfully.",
  [EventCategory.MO005]: "Monitor configuration error.",
  [EventCategory.MO006]: "Monitor training failed.",
  [EventCategory.MO007]: "Monitor had a runtime error.",
  [EventCategory.IN001]: "Integration error.",
  [EventCategory.SO001]: "Datasource connection error.",
  [EventCategory.SO002]: "Datasource schema scan failed.",
});

export function getCategoryDescription(eventCategory) {
  return EventCategoryDescription[eventCategory] || "";
}

export function getColorFromEventLevel(eventLevel) {
  return EventLevelToColorMapper[eventLevel] || "";
}

export function getAlertChannelDisplayName(alertChannel) {
  return `${capitalizeFirstLetter(alertChannel.type)}: ${alertChannel.title}`;
}
