export const getConfigBoundErrors = ({ upper, lower }) => {
  const hasNoValues = typeof upper !== "number" && typeof lower !== "number";
  const hasBothValues = typeof upper === "number" && typeof lower === "number";

  if (hasNoValues) {
    return {
      config: {
        bound: {
          lower: "Threshold value is required, please enter a valid number.",
          upper: "Threshold value is required, please enter a valid number.",
        },
      },
    };
  }

  if (hasBothValues && lower > upper) {
    return {
      config: {
        bound: {
          lower: "Lower threshold must be equal to or less than upper threshold.",
          upper: "",
        },
      },
    };
  }

  return {};
};
