import axios from "../utils/api";
import {
  backendRelativeURIPath,
  backendURIPath,
  getAPIURIInstance,
} from "../utils/uri-path";

function getWorkspaceFromServer(rowWorkspaceData) {
  const {
    uuid,
    name,
    created_at: createdAt,
    description,
    icon_id: iconId,
    config,
  } = rowWorkspaceData;
  return {
    uuid,
    name,
    createdAt,
    description,
    iconId,
    config,
  };
}

export function getWorkspaceListPromise(options = {}) {
  return new Promise(function (resolve, reject) {
    const { memberOnly } = options;

    let queryStringArr = [];
    if (typeof memberOnly === "boolean") {
      queryStringArr.push(`member_only=${memberOnly}`);
    }

    const queryString = queryStringArr.length > 0 ? `?${queryStringArr.join("&")}` : "";
    axios
      .get(`${backendURIPath.APP_WORKSPACE_LIST}${queryString}`, {
        baseURL: "/api/v1/",
      })
      .then(function (response) {
        resolve(
          response.data.data.map((rowWorkspaceData) =>
            getWorkspaceFromServer(rowWorkspaceData)
          )
        );
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addWorkspacePromise(workspace) {
  return new Promise(function (resolve, reject) {
    axios
      .post(backendURIPath.APP_WORKSPACE_LIST, getWorkspaceFromUI(workspace), {
        baseURL: "/api/v1",
      })
      .then(function (response) {
        resolve(getWorkspaceFromServer(response.data.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

function getWorkspaceFromUI(workspace) {
  const { name, description, iconId, config } = workspace;
  return {
    ...(name !== undefined ? { name } : {}),
    ...(description !== undefined ? { description } : {}),
    ...(iconId ? { icon_id: iconId } : {}),
    ...(config ? { config } : {}),
  };
}

export function patchWorkspacePromise(uuid, partialWorkspace = {}, isAppLevel = true) {
  return new Promise(function (resolve, reject) {
    const patchWorkspaceURI = isAppLevel
      ? getAPIURIInstance(backendURIPath.APP_WORKSPACE, { uuid })
      : getAPIURIInstance(backendRelativeURIPath.WORKSPACE, {
          workspaceUuid: uuid,
        });

    axios
      .patch(patchWorkspaceURI, getWorkspaceFromUI(partialWorkspace), {
        baseURL: "/api/v1",
      })
      .then(function (response) {
        resolve(getWorkspaceFromServer(response.data.data));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteWorkspacePromise(uuid, isAppLevel = true) {
  return new Promise(function (resolve, reject) {
    const deleteWorkspaceURI = isAppLevel
      ? getAPIURIInstance(backendURIPath.APP_WORKSPACE, { uuid })
      : getAPIURIInstance(backendRelativeURIPath.WORKSPACE, {
          workspaceUuid: uuid,
        });

    axios
      .delete(deleteWorkspaceURI, { baseURL: "/api/v1" })
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getPageConfigurationPromise(uuid, pageName) {
  return new Promise(function (resolve, reject) {
    const uri = getAPIURIInstance(backendRelativeURIPath.CONFIGURATIONS_PAGE, {
      workspaceUuid: uuid,
    });
    axios
      .get(`${uri}?name=${pageName}`, { baseURL: "/api/v1" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updatePageConfigurationPromise(uuid, pageConfiguration) {
  return new Promise(function (resolve, reject) {
    const uri = getAPIURIInstance(backendRelativeURIPath.CONFIGURATIONS_PAGE, {
      workspaceUuid: uuid,
    });
    axios
      .put(uri, pageConfiguration, { baseURL: "/api/v1" })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
