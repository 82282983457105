import { useState } from "react";
import { getDataSourceTableColumnListPromise } from "../../../rest/datasource";

function getMapKey(workspaceUuid, dataSourceUuid, tableUuid) {
  return `${workspaceUuid}|${dataSourceUuid}|${tableUuid}`;
}

function useTableColumnCache({ workspaceUuid, dataSourceUuid }) {
  const [cacheData, setCacheData] = useState({});

  const loadTableColumns = (tableUuid) => {
    if (!tableUuid) {
      return;
    }
    const cacheKey = getMapKey(workspaceUuid, dataSourceUuid, tableUuid);
    if (cacheData[cacheKey]) {
      return;
    }
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid).then(
      (columnList) => {
        setCacheData((prevCache) => ({
          ...prevCache,
          [cacheKey]: columnList,
        }));
      }
    );
  };

  const getTableColumns = (tableUuid) => {
    return cacheData[getMapKey(workspaceUuid, dataSourceUuid, tableUuid)] || [];
  };

  return {
    loadTableColumns,
    getTableColumns,
  };
}

export default useTableColumnCache;
