import React from "react";

export function ValueOutOfExpectationIcon(props) {
  const { size = 40, ...restProps } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...restProps}>
      <path
        d="M5.83307 0.833252C8.10462 0.833252 9.36545 2.75439 10.1242 5.97552L10.1865 6.25652L10.2908 6.77735L10.439 7.58147L10.5856 8.42317L10.8175 9.83794L10.9564 10.5189L11.0898 11.139C11.1118 11.2381 11.1338 11.3353 11.1559 11.4309L11.2897 11.9877C11.4258 12.5302 11.5709 13.0373 11.7457 13.5761C12.5567 16.0756 13.5653 17.4999 14.5831 17.4999C15.3134 17.4999 16.1303 16.1928 16.7483 13.9456C16.9899 13.0673 17.1841 12.1199 17.3342 11.1717L17.4062 10.692L17.4856 10.0818L17.5039 9.917C17.5497 9.45904 17.958 9.12492 18.416 9.17072C18.8739 9.21652 19.2081 9.62488 19.1623 10.0828L19.1185 10.4614L19.0565 10.9252L18.9804 11.4323C18.8213 12.4372 18.6151 13.443 18.3553 14.3875C17.541 17.3487 16.4048 19.1666 14.5831 19.1666C12.5442 19.1666 11.1843 17.2463 10.1604 14.0904C9.88059 13.228 9.6742 12.4507 9.46662 11.5187L9.32723 10.8719L9.17968 10.143L8.87441 8.30857L8.73918 7.5473L8.63 6.96692L8.56251 6.63223L8.50194 6.35765C7.90583 3.82702 7.03486 2.49992 5.83307 2.49992C4.8895 2.49992 3.97996 3.79926 3.29793 6.07271C3.03566 6.94692 2.8281 7.8904 2.67069 8.83483C2.64309 9.00042 2.61807 9.16001 2.59553 9.31257L2.52364 9.83994L2.4956 10.0828C2.44981 10.5408 2.04144 10.8749 1.58348 10.8291C1.12553 10.7833 0.79141 10.375 0.837206 9.917L0.882191 9.53558L0.946631 9.06983C0.970679 8.90704 0.997332 8.73704 1.0267 8.56084C1.19482 7.55214 1.41694 6.54249 1.70155 5.5938C2.58201 2.65891 3.85998 0.833252 5.83307 0.833252ZM7.08307 9.16658C7.54331 9.16658 7.9164 9.53968 7.9164 9.99992C7.9164 10.4273 7.5947 10.7795 7.18025 10.8276L7.08307 10.8333H4.58307C4.12283 10.8333 3.74974 10.4602 3.74974 9.99992C3.74974 9.57255 4.07144 9.22033 4.48589 9.17219L4.58307 9.16658H7.08307ZM15.4164 9.16658C15.8766 9.16658 16.2497 9.53968 16.2497 9.99992C16.2497 10.4273 15.928 10.7795 15.5136 10.8276L15.4164 10.8333H12.9164C12.4562 10.8333 12.0831 10.4602 12.0831 9.99992C12.0831 9.57255 12.4048 9.22033 12.8192 9.17219L12.9164 9.16658H15.4164Z"
        fill="#121224"
      />
    </svg>
  );
}

export function SharpChangeIcon(props) {
  const { size = 40, ...restProps } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...restProps}>
      <path
        d="M14.9364 2.81907C14.8958 2.91736 14.8355 3.00944 14.7556 3.08934L3.5056 14.3393C3.18016 14.6648 2.65252 14.6648 2.32709 14.3393C2.00165 14.0139 2.00165 13.4863 2.32709 13.1608L12.1545 3.33341H9.79134C9.3311 3.33341 8.95801 2.96032 8.95801 2.50008C8.95801 2.03984 9.3311 1.66675 9.79134 1.66675H14.1662C14.2792 1.66675 14.3871 1.68924 14.4853 1.72998C14.5836 1.77065 14.6757 1.83093 14.7556 1.91083C14.9177 2.07291 14.999 2.28515 14.9997 2.49758L14.9997 2.50008L14.9997 2.50258C14.9993 2.61466 14.9769 2.72154 14.9364 2.81907Z"
        fill="#121224"
      />
      <path
        d="M17.9163 4.57121C17.9163 4.11097 17.5432 3.73787 17.083 3.73787C16.6228 3.73787 16.2497 4.11097 16.2497 4.57121V17.5348C16.2497 17.995 16.6228 18.3681 17.083 18.3681C17.5432 18.3681 17.9163 17.995 17.9163 17.5348V4.57121Z"
        fill="#121224"
      />
      <path
        d="M13.7497 7.06714C14.2099 7.06714 14.583 7.44024 14.583 7.90047V17.5365C14.583 17.9967 14.2099 18.3698 13.7497 18.3698C13.2894 18.3698 12.9163 17.9967 12.9163 17.5365V7.90047C12.9163 7.44024 13.2894 7.06714 13.7497 7.06714Z"
        fill="#121224"
      />
      <path
        d="M11.2497 11.2501C11.2497 10.7898 10.8766 10.4167 10.4163 10.4167C9.9561 10.4167 9.58301 10.7898 9.58301 11.2501V17.5298C9.58301 17.9901 9.9561 18.3632 10.4163 18.3632C10.8766 18.3632 11.2497 17.9901 11.2497 17.5298V11.2501Z"
        fill="#121224"
      />
      <path
        d="M7.08301 13.3127C7.54324 13.3127 7.91634 13.6858 7.91634 14.146V17.546C7.91634 18.0062 7.54324 18.3793 7.08301 18.3793C6.62277 18.3793 6.24967 18.0062 6.24967 17.546V14.146C6.24967 13.6858 6.62277 13.3127 7.08301 13.3127Z"
        fill="#121224"
      />
      <path
        d="M3.74967 15.8334C4.20991 15.8334 4.58301 16.2065 4.58301 16.6667V17.5232C4.58301 17.9835 4.20991 18.3566 3.74967 18.3566C3.28944 18.3566 2.91634 17.9835 2.91634 17.5232V16.6667C2.91634 16.2065 3.28944 15.8334 3.74967 15.8334Z"
        fill="#121224"
      />
    </svg>
  );
}

export function SlowBurnTrendChangeIcon(props) {
  const { size = 40, ...restProps } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3332 1.66659C13.3332 1.20635 13.7063 0.833252 14.1665 0.833252H18.3332C18.7197 0.833252 19.0555 1.09898 19.1442 1.47512C19.233 1.85127 19.0516 2.2391 18.7059 2.41194L5.37255 9.07861C5.19462 9.16757 4.99075 9.18995 4.79776 9.1417L1.46443 8.30837C1.01793 8.19675 0.746462 7.7443 0.858086 7.29781C0.96971 6.85131 1.42216 6.57984 1.86865 6.69147L4.90292 7.45003L14.8031 2.49992H14.1665C13.7063 2.49992 13.3332 2.12682 13.3332 1.66659ZM18.7713 4.29104C19.017 4.44288 19.1665 4.7111 19.1665 4.99992V15.8333C19.1665 16.2935 18.7934 16.6666 18.3332 16.6666H14.9999C14.5396 16.6666 14.1665 16.2935 14.1665 15.8333V6.66658C14.1665 6.35094 14.3449 6.06239 14.6272 5.92123L17.9605 4.25456C18.2188 4.1254 18.5256 4.1392 18.7713 4.29104ZM15.8332 7.18161V14.9999H17.4999V6.34828L15.8332 7.18161ZM12.1046 7.62438C12.3503 7.77621 12.4999 8.04444 12.4999 8.33325V15.8333C12.4999 16.2935 12.1268 16.6666 11.6665 16.6666H8.3332C7.87297 16.6666 7.49987 16.2935 7.49987 15.8333V9.99992C7.49987 9.68427 7.67821 9.39572 7.96053 9.25456L11.2939 7.5879C11.5522 7.45873 11.859 7.47254 12.1046 7.62438ZM9.16654 10.5149V14.9999H10.8332V9.68161L9.16654 10.5149ZM1.15369 10.1764C1.35596 10.0185 1.6197 9.96256 1.86865 10.0248L5.20198 10.8581C5.57296 10.9509 5.8332 11.2842 5.8332 11.6666V15.8333C5.8332 16.2935 5.46011 16.6666 4.99987 16.6666H1.66654C1.2063 16.6666 0.833205 16.2935 0.833205 15.8333V10.8333C0.833205 10.5766 0.951431 10.3343 1.15369 10.1764ZM2.49987 11.9006V14.9999H4.16654V12.3172L2.49987 11.9006ZM0.833205 18.3333C0.833205 17.873 1.2063 17.4999 1.66654 17.4999H18.3332C18.7934 17.4999 19.1665 17.873 19.1665 18.3333C19.1665 18.7935 18.7934 19.1666 18.3332 19.1666H1.66654C1.2063 19.1666 0.833205 18.7935 0.833205 18.3333Z"
        fill="#121224"
      />
    </svg>
  );
}

export function ManualThresholdIcon(props) {
  const { size = 40, ...restProps } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1663 17.5001C19.1663 17.9603 18.7933 18.3334 18.333 18.3334H1.66634C1.2061 18.3334 0.833008 17.9603 0.833008 17.5001V12.2918V7.08344C0.833008 6.75799 1.02246 6.46232 1.31813 6.32636C1.6138 6.19036 1.96157 6.23894 2.20867 6.45074L4.42148 8.34743L6.18797 5.77799C6.42351 5.4354 6.87551 5.31882 7.24734 5.50473L9.19379 6.47796L12.3105 3.17792C12.6107 2.86002 13.1056 2.82825 13.444 3.10514L15.152 4.50259L17.7438 1.91085C17.9821 1.67252 18.3405 1.60122 18.6519 1.73021C18.9633 1.85919 19.1663 2.16306 19.1663 2.50011V17.5001ZM7.76322 9.20949C7.40667 9.09065 7.01476 9.22515 6.8063 9.53782L4.73486 12.645L2.49967 11.3877V8.89529L4.04068 10.2162C4.22017 10.37 4.45659 10.4404 4.69101 10.4097C4.92542 10.3791 5.13576 10.2504 5.26972 10.0555L7.15058 7.31973L9.00201 8.24544C9.33067 8.40982 9.72822 8.33944 9.98051 8.07228L12.9893 4.88651L14.6803 6.27007C15.0117 6.54119 15.4945 6.51711 15.7973 6.21436L17.4997 4.51195V5.93694L15.3249 8.42243L13.4498 6.8599C13.1023 6.57024 12.5871 6.61082 12.2892 6.95132L9.73746 9.86757L7.76322 9.20949ZM5.69305 14.2123C5.4513 14.5749 4.97097 14.69 4.59113 14.4764L2.49967 13.3V16.6667H17.5018V8.46548L16.0435 10.1322C15.7455 10.4727 15.2304 10.5132 14.8828 10.2236L13.0078 8.66104L10.6268 11.3822C10.4058 11.6348 10.0546 11.7302 9.73613 11.624L7.83997 10.9919L5.69305 14.2123Z"
        fill="#121224"
      />
    </svg>
  );
}
