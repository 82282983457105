import * as types from "./tag-action-types";
import { getTagListPromise } from "../../rest/tag";

export function setTagList(data) {
  return { type: types.SET_TAG_LIST, tagList: data };
}

export function getTagList(workspaceUuid) {
  return (dispatch, getState) => {
    return getTagListPromise(workspaceUuid)
      .then(function (tags) {
        dispatch(setTagList(tags));
      })
      .catch(function (error) {
        console.log(`Fail to get tag list for ${error}`);
      });
  };
}
