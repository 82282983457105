import React, { useEffect, useMemo, useState } from "react";
import MetricConfigSummaryView from "../../summary-view/";
import MetricConfigTabPanel from "../../metric-config-tab-panel";
import MetricConfigPreviewPreviewContentTab from "./metric-config-preview-preview-content-tab";
import MetricConfigPreviewQueryHistoryTab from "./metric-config-preview-query-history-tab";
import { isSliceEqual } from "../../../../utils/general";
import { getQueryListParams } from "../../../../utils/time";
import {
  MetricCategory,
  RequestStatus,
  isFileSource as getIsFileSource,
} from "../../../../utils/enums";
import {
  getDefaultKPIPreviewPeriod,
  getMetricTypeFromConfigData,
  isViewDataSampleEnabled,
} from "../../utils";
import getUnixTime from "date-fns/getUnixTime";
import ViewSampleDataButton from "../../view-sample-data-button";
import ViewQueryHistoryButton from "../../view-query-history-button";
import MetricConfigPreviewScanStatsView from "./metric-config-preview-scan-stats-view";
import MetricSampleDataView from "../../metric-sample-data-view";
import {
  getSliceByColumns,
  isTableOrColumnActivityMetric,
  metricTypeSupportsQueryHistory,
} from "../../../../utils/metric";

import "./index.scss";
import {
  EVENT,
  PAGE,
  getMetricDetailProps,
  trackEvent,
} from "../../../../utils/telemetry";

const PreviewTabKey = Object.freeze({
  MAIN: "main",
  QUERY_HISTORY: "queryHistory",
  SAMPLE_DATA: "sampleData",
});

const TabTitles = Object.freeze({
  [PreviewTabKey.MAIN]: "Preview Metric",
  [PreviewTabKey.QUERY_HISTORY]: "Query History",
  [PreviewTabKey.SAMPLE_DATA]: "Viewing Sample Data",
});

function MetricConfigDataPreviewTabContentView(props) {
  const {
    isEdit,
    configData,
    dataSourceList,
    onPreview,
    onTerminatePreview,
    selectedSliceValue,
    sliceValueList,
    onSelectedSliceValueChange,
    currentTime,
    currentPreviewTime,
    onCurrentPreviewTimeChange,
    sliceByColumns,
    previewResult,
    previewStatus,
    isPreviewed,
    currentPreviewTab,
    onCurrentPreviewTabChange,
    tableList,
    currentKpiQueryList,
    getCurrentKpiQueryList,
    currentKpiSampleDataTableSchemaList,
    getCurrentKpiSampleDataTableSchemaList,
    currentKpiSampleDataSampleDataList,
    getCurrentKpiSampleDataSampleDataList,
    enablePreviewMetric,
    enableViewSampleData,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const metricType = getMetricTypeFromConfigData(configData);
  const tabTitle = TabTitles[currentPreviewTab];
  const isFileSource = getIsFileSource(
    dataSourceList.find(
      (dataSource) => configData.config.sources[0] === dataSource.metadata.uuid
    )?.config.connection.type
  );

  const currentDataSource = dataSourceList?.find(
    (dataSource) =>
      dataSource.metadata.uuid === configData?.config?.sources?.at(0) || ""
  );

  const metricProps = getMetricDetailProps(configData, currentDataSource || {});

  return (
    <div className="metric-config-data-preview-container">
      <div className="metric-config-data-preview-header-container">
        <div className="metric-config-data-preview-header-title-container">
          {tabTitle}
        </div>
        <div className="metric-config-data-preview-header-action-list-container">
          {isEdit && !isFileSource && metricTypeSupportsQueryHistory(metricType) && (
            <ViewQueryHistoryButton
              isViewing={currentPreviewTab === PreviewTabKey.QUERY_HISTORY}
              onClick={() => {
                currentPreviewTab !== PreviewTabKey.QUERY_HISTORY &&
                  getCurrentKpiQueryList(configData, getQueryListParams());
                onCurrentPreviewTabChange(
                  currentPreviewTab !== PreviewTabKey.QUERY_HISTORY
                    ? PreviewTabKey.QUERY_HISTORY
                    : PreviewTabKey.MAIN
                );
              }}
            />
          )}
          {![MetricCategory.AGGREGATION_COMPARE].includes(metricType) && (
            <ViewSampleDataButton
              isViewing={currentPreviewTab === PreviewTabKey.SAMPLE_DATA}
              disabled={!isViewDataSampleEnabled(configData) || !enableViewSampleData}
              onClick={() => {
                trackEvent(
                  currentPreviewTab !== PreviewTabKey.SAMPLE_DATA
                    ? EVENT.METRIC_VIEW_SAMPLE_DATA
                    : EVENT.METRIC_STOP_VIEWING_SAMPLE_DATA,
                  {
                    ...metricProps,
                    page: PAGE.METRIC_DETAIL,
                  }
                );

                onCurrentPreviewTabChange(
                  currentPreviewTab !== PreviewTabKey.SAMPLE_DATA
                    ? PreviewTabKey.SAMPLE_DATA
                    : PreviewTabKey.MAIN
                );
              }}
            />
          )}
        </div>
      </div>
      <div className="metric-config-data-preview-content-container">
        {currentPreviewTab === PreviewTabKey.MAIN && (
          <>
            {previewResult?.queryStats && (
              <MetricConfigPreviewScanStatsView
                queryStats={previewResult.queryStats}
                rowCount={previewResult.rowData?.length || 0}
              />
            )}
            <MetricConfigPreviewPreviewContentTab
              isPreviewed={isPreviewed}
              sliceByColumns={sliceByColumns}
              previewStatus={previewStatus}
              sliceValueList={sliceValueList}
              selectedSliceValue={selectedSliceValue}
              onSelectedSliceValueChange={onSelectedSliceValueChange}
              currentTime={currentTime}
              currentPreviewTime={currentPreviewTime}
              onCurrentPreviewTimeChange={onCurrentPreviewTimeChange}
              onPreview={onPreview}
              previewResult={previewResult}
              onTerminatePreview={onTerminatePreview}
              configData={configData}
              dataSourceList={dataSourceList}
              enablePreviewMetric={enablePreviewMetric}
              isPreviewSupported={
                !isFileSource && !isTableOrColumnActivityMetric(configData)
              }
            />
          </>
        )}
        {currentPreviewTab === PreviewTabKey.QUERY_HISTORY && (
          <MetricConfigPreviewQueryHistoryTab
            configData={configData}
            currentKpiQueryList={currentKpiQueryList}
            dataSourceList={dataSourceList}
            openWorkspaceTakeover={openWorkspaceTakeover}
            closeTakeover={closeTakeover}
            getCurrentKpiQueryList={getCurrentKpiQueryList}
          />
        )}
        {currentPreviewTab === PreviewTabKey.SAMPLE_DATA && (
          <MetricSampleDataView
            configData={configData}
            currentTableList={tableList}
            currentKpiSampleDataTableSchemaList={currentKpiSampleDataTableSchemaList}
            currentKpiSampleDataSampleDataList={currentKpiSampleDataSampleDataList}
            getCurrentKpiSampleDataTableSchemaList={
              getCurrentKpiSampleDataTableSchemaList
            }
            getCurrentKpiSampleDataSampleDataList={
              getCurrentKpiSampleDataSampleDataList
            }
          />
        )}
      </div>
    </div>
  );
}

function getUniqueSliceValues(previewData, sliceByColumns) {
  if (!sliceByColumns?.length) {
    return [];
  }

  const uniqueValueArray = [];
  const currentRawData = previewData?.metricsData?.signalData || [];
  for (let { slice = {} } of currentRawData) {
    const isSliceNotIncluded =
      uniqueValueArray.findIndex((currentSlice) =>
        isSliceEqual(currentSlice, slice, sliceByColumns)
      ) === -1;
    if (isSliceNotIncluded) {
      uniqueValueArray.push(slice);
    }
  }

  return uniqueValueArray;
}

function MetricConfigPreviewTabPanel(props) {
  const {
    configData,
    dataSourceList,
    onPreview,
    onTerminatePreview,
    previewStatus,
    previewResult,
    isEdit,
    getCurrentKpiQueryList,
    currentKpiQueryList,
    getCurrentKpiSampleDataTableSchemaList,
    currentKpiSampleDataTableSchemaList,
    getCurrentKpiSampleDataSampleDataList,
    currentKpiSampleDataSampleDataList,
    enablePreviewMetric,
    enableViewSampleData,
    currentTime: propsCurrentTime,
    tableList,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const [currentTime] = useState(propsCurrentTime);
  const [currentPreviewTime, setCurrentPreviewTime] = useState(
    getDefaultKPIPreviewPeriod(configData, getUnixTime(currentTime))
  );
  const [currentPreviewTab, setCurrentPreviewTab] = useState(PreviewTabKey.MAIN);
  const [isPreviewed, setIsPreviewed] = useState(false);
  const [sliceValues, setSliceValues] = useState([]);
  const [selectedSliceValue, setCurrentSliceValue] = useState(undefined);
  const sliceByColumns = useMemo(() => {
    return getSliceByColumns(configData);
  }, [configData]);

  useEffect(() => {
    setIsPreviewed(false);
    setCurrentPreviewTab(PreviewTabKey.MAIN);
  }, [configData]);

  useEffect(() => {
    if (!sliceByColumns || previewStatus !== RequestStatus.SUCCESS) {
      return;
    }

    const sliceValues = getUniqueSliceValues(
      previewResult?.data || [],
      sliceByColumns ?? []
    );

    setSliceValues(sliceValues);
    setCurrentSliceValue(sliceValues.length > 0 ? 0 : undefined);
  }, [previewResult, previewStatus, sliceByColumns]);

  return (
    <MetricConfigTabPanel
      summarySection={<MetricConfigSummaryView {...props} />}
      contentSection={
        <MetricConfigDataPreviewTabContentView
          isEdit={isEdit}
          configData={configData}
          dataSourceList={dataSourceList}
          sliceValueList={sliceValues}
          selectedSliceValue={selectedSliceValue}
          onSelectedSliceValueChange={setCurrentSliceValue}
          currentPreviewTime={currentPreviewTime}
          onCurrentPreviewTimeChange={setCurrentPreviewTime}
          currentTime={currentTime}
          sliceByColumns={sliceByColumns}
          isPreviewed={isPreviewed}
          onPreview={(previewPeriod) => {
            !isPreviewed && setIsPreviewed(true);
            onPreview(previewPeriod);
          }}
          onTerminatePreview={onTerminatePreview}
          previewStatus={previewStatus}
          previewResult={previewResult}
          currentPreviewTab={currentPreviewTab}
          onCurrentPreviewTabChange={setCurrentPreviewTab}
          currentKpiQueryList={currentKpiQueryList}
          getCurrentKpiQueryList={getCurrentKpiQueryList}
          currentKpiSampleDataTableSchemaList={currentKpiSampleDataTableSchemaList}
          getCurrentKpiSampleDataTableSchemaList={
            getCurrentKpiSampleDataTableSchemaList
          }
          getCurrentKpiSampleDataSampleDataList={getCurrentKpiSampleDataSampleDataList}
          currentKpiSampleDataSampleDataList={currentKpiSampleDataSampleDataList}
          tableList={tableList}
          enablePreviewMetric={enablePreviewMetric}
          enableViewSampleData={enableViewSampleData}
          openWorkspaceTakeover={openWorkspaceTakeover}
          closeTakeover={closeTakeover}
        />
      }
    />
  );
}

export default MetricConfigPreviewTabPanel;
