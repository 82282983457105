import React, { useMemo } from "react";
import { IDIcon } from "../../../../components/icons/summary";
import {
  IncidentDurationIcon,
  IncidentProgressIcon,
  IncidentSeverityIcon,
  IncidentStatusIcon,
  IncidentValidationStatusIcon,
} from "../../../../components/icons/summary/incident";
import { getSliceDisplayString } from "../../../../utils/general";
import {
  DAY_IN_SECONDS,
  getDisplayTimeFromSecond,
  getSeasonFromSeasonConfig,
  getStringFromTimeStamp,
  WEEK_IN_SECONDS,
} from "../../../../utils/time";
import {
  getOptionByValue,
  incidentDirectionOptions,
  incidentSeverityOptions,
  incidentStatusOptions,
} from "../../../../utils/options";
import {
  ConfigDirectionIconComponent,
  IncidentDirectionIconComponent,
} from "../../../../components/icons/incident-direction-icon";
import {
  getMetricTypeFromConfigData,
  metricTypeOptions,
} from "../../../../components/metric/utils";
import {
  metricCategoryIconComponent,
  MetricCompareConfigItemIcon,
  MetricDataSourceTypeIcon,
  MetricDimensionTypeIcon,
  MetricSchemaIcon,
  MetricSeasonalityIcon,
  MetricSliceByColumnsIcon,
  MetricSynchronizationDelayIcon,
  MetricTableIcon,
  MetricTimestampColumnIcon,
  MetricValueColumnsIcon,
} from "../../../../components/metric/fields/icons";
import { NgTextTooltip } from "../../../../components/text-tooltip/ng-text-tooltip";
import {
  ConfigDirection,
  FeatureTypeConst,
  getSeasonalityDisplayName,
  getSymptomTypeDisplayName,
  MetricCategory,
  MetricConfigType,
  SymptomTypeConst,
  getIncidentValidationStatus,
} from "../../../../utils/enums";
import { indexBy } from "../../../../utils/iterables";
import {
  AggressivenessIcon,
  DriftDurationIcon,
  RecoveryDurationIcon,
} from "../../../../components/icons/summary/monitor";
import { getRuleSymptomInfo } from "../../../../utils/icon";
import MetricLinkCell from "../../../../components/entity-list/metric-link-cell";
import { classesName } from "../../../../utils/css";
import MonitorLinkCell from "../../../../components/entity-list/monitor-link-cell";
import { getIncidentValidationStatusDisplayStr } from "../../../../utils/incident";
import { getDisplayTableName } from "../../../../utils/datasource";

import "./index.scss";

function SummarySectionItem(props) {
  const {
    Icon,
    fieldName,
    children,
    truncateEllipsis = false,
    clickable = false,
  } = props;

  const valueText = truncateEllipsis ? (
    <NgTextTooltip>{children}</NgTextTooltip>
  ) : (
    children
  );

  return (
    <div className="incident-summary-section-item">
      <div className="incident-summary-section-item-field">{fieldName}</div>
      <div
        className={classesName(
          "incident-summary-section-item-content",
          clickable && "clickable"
        )}
      >
        {Icon && (
          <div className="incident-summary-section-item-content-icon">
            <Icon width={14} height={14} />
          </div>
        )}
        {valueText}
      </div>
    </div>
  );
}

function SummarySection(props) {
  const { title, children } = props;
  return (
    <div className="incident-summary-tab-section">
      {title && <div className="incident-summary-tab-section-title">{title}</div>}
      {children}
    </div>
  );
}

function IncidentSummarySection(props) {
  const {
    metric,
    incident: {
      incidentId,
      startTime,
      endTime,
      score,
      direction,
      slice,
      status,
      ongoing,
      validation,
      creationTime,
    },
  } = props;

  const duration = endTime - startTime + 1;
  const durationStr = getDisplayTimeFromSecond(duration, false, [], false, true);

  return (
    <SummarySection title="Incident Info">
      <SummarySectionItem fieldName="ID" Icon={IDIcon}>
        {incidentId}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Duration" Icon={IncidentDurationIcon}>
        {durationStr}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Severity" Icon={IncidentSeverityIcon}>
        {getOptionByValue(incidentSeverityOptions, score)?.label}
      </SummarySectionItem>
      {direction && (
        <SummarySectionItem
          fieldName="Direction"
          Icon={IncidentDirectionIconComponent(direction)}
        >
          {getOptionByValue(incidentDirectionOptions, direction)?.label}
        </SummarySectionItem>
      )}
      {Object.keys(slice).length > 0 && (
        <SummarySectionItem fieldName="Slice" Icon={MetricSliceByColumnsIcon}>
          {getSliceDisplayString(slice, metric)}
        </SummarySectionItem>
      )}
      <SummarySectionItem fieldName="Status" Icon={IncidentStatusIcon}>
        {getOptionByValue(incidentStatusOptions, status)?.label}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Progress" Icon={IncidentProgressIcon}>
        {ongoing ? "Active" : "Completed"}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Validation" Icon={IncidentValidationStatusIcon}>
        {getIncidentValidationStatusDisplayStr(getIncidentValidationStatus(validation))}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Start Time">
        {getStringFromTimeStamp(startTime)}
      </SummarySectionItem>
      {!ongoing && (
        <SummarySectionItem fieldName="End Time">
          {getStringFromTimeStamp(endTime)}
        </SummarySectionItem>
      )}
      <SummarySectionItem fieldName="Creation Time">
        {getStringFromTimeStamp(creationTime)}
      </SummarySectionItem>
    </SummarySection>
  );
}

export function MetricSummarySection(props) {
  const { metric, workspaceUserPermissions, history, title = "Metric Info" } = props;

  const {
    metadata: { idSerial, ownedBy, updatedBy, tags },
    config: {
      configType,
      dimension,
      timestampColumn,
      seasonality,
      synchronizationDelay,
      sliceByColumns = [],
    },
  } = metric;
  const metricType = getMetricTypeFromConfigData(metric);
  const MetricTypeIcon = metricCategoryIconComponent(metricType);
  const season = getSeasonFromSeasonConfig(seasonality);
  const { basicSeasonality, customSeasonPeriods = [] } = season;
  const seasonDisplay =
    customSeasonPeriods.length === 0
      ? getSeasonalityDisplayName(basicSeasonality)
      : `${getSeasonalityDisplayName(basicSeasonality)} (Custom)`;

  return (
    <SummarySection title={title}>
      <SummarySectionItem fieldName="ID" Icon={IDIcon}>
        {idSerial}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Metric Name" truncateEllipsis clickable>
        <MetricLinkCell
          metric={metric}
          workspaceUserPermissions={workspaceUserPermissions}
          history={history}
        />
      </SummarySectionItem>
      <SummarySectionItem fieldName="Metric Type" Icon={MetricTypeIcon}>
        {getOptionByValue(metricTypeOptions, metricType)?.label}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Dimension" Icon={MetricDimensionTypeIcon}>
        {dimension}
      </SummarySectionItem>
      <SummarySectionItem
        fieldName="Evaluation Delay"
        Icon={MetricSynchronizationDelayIcon}
      >
        {synchronizationDelay ? getDisplayTimeFromSecond(synchronizationDelay) : "N/A"}
      </SummarySectionItem>
      {sliceByColumns.length > 0 && (
        <SummarySectionItem
          fieldName="Slice"
          Icon={MetricSliceByColumnsIcon}
          truncateEllipsis
        >
          {sliceByColumns.join(", ")}
        </SummarySectionItem>
      )}
      {configType === MetricConfigType.METRIC_CONFIG && (
        <>
          <SummarySectionItem fieldName="Timestamp" Icon={MetricTimestampColumnIcon}>
            {timestampColumn}
          </SummarySectionItem>
          <SummarySectionItem fieldName="Seasonality" Icon={MetricSeasonalityIcon}>
            {seasonDisplay}
          </SummarySectionItem>
        </>
      )}
      <SummarySectionItem fieldName="Created by" truncateEllipsis>
        {ownedBy?.email ?? "N/A"}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Updated by" truncateEllipsis>
        {updatedBy?.email ?? "N/A"}
      </SummarySectionItem>
      {tags?.length > 0 && (
        <SummarySectionItem fieldName="Tags">{tags.join(", ")}</SummarySectionItem>
      )}
    </SummarySection>
  );
}

function SingleSourceDataAssetSummaryContent(props) {
  const { dataSource, metric } = props;
  const {
    config: { table },
  } = metric;
  const {
    metadata: { name: dataSourceName },
  } = dataSource;

  const metricType = getMetricTypeFromConfigData(metric);
  const selectedColumn =
    metricType === MetricCategory.CUSTOM_SQL
      ? metric.config?.table?.columnName
      : metric.config?.valueColumns?.[0]?.columnName;

  return (
    <SummarySection title="Data Asset Info">
      <SummarySectionItem
        fieldName="Datasource"
        Icon={MetricDataSourceTypeIcon}
        truncateEllipsis
      >
        {dataSourceName}
      </SummarySectionItem>
      {table.schemaName && (
        <SummarySectionItem fieldName="Schema" Icon={MetricSchemaIcon} truncateEllipsis>
          {table.schemaName}
        </SummarySectionItem>
      )}
      {table.tableUuid && (
        <SummarySectionItem fieldName="Table" Icon={MetricTableIcon} truncateEllipsis>
          {getDisplayTableName(table.tableName)}
        </SummarySectionItem>
      )}
      {selectedColumn && (
        <SummarySectionItem
          fieldName="Column"
          Icon={MetricValueColumnsIcon}
          truncateEllipsis
        >
          {selectedColumn}
        </SummarySectionItem>
      )}
    </SummarySection>
  );
}

function AggregationCompareDataAssetSummaryContent(props) {
  const {
    metric: {
      config: { compares, sourceTable, targetTable },
    },
    dataSourceList,
    metricList,
  } = props;

  const dataSourcesByUuid = indexBy(
    dataSourceList.data,
    (dataSource) => dataSource.metadata.uuid
  );
  const metricsByUuid = useMemo(() => {
    return indexBy(metricList, (metric) => metric.metadata.uuid);
  }, [metricList]);

  return (
    <>
      <SummarySection title="Source Data Asset Info">
        <SummarySectionItem
          fieldName="Source Datasource"
          Icon={MetricDataSourceTypeIcon}
          truncateEllipsis
        >
          {dataSourcesByUuid[sourceTable.sourceUuid]?.metadata.name}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Source Schema"
          Icon={MetricSchemaIcon}
          truncateEllipsis
        >
          {sourceTable.table.schemaName}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Source Table"
          Icon={MetricTableIcon}
          truncateEllipsis
        >
          {sourceTable.table.tableName}
        </SummarySectionItem>
        {compares.map(({ sourceMetricUuid }) => (
          <SummarySectionItem
            key={sourceMetricUuid}
            fieldName="Source Metric"
            Icon={MetricCompareConfigItemIcon}
            truncateEllipsis
          >
            {metricsByUuid[sourceMetricUuid].metadata.name}
          </SummarySectionItem>
        ))}
      </SummarySection>
      <SummarySection title="Target Data Asset Info">
        <SummarySectionItem
          fieldName="Target Datasource"
          Icon={MetricDataSourceTypeIcon}
          truncateEllipsis
        >
          {dataSourcesByUuid[targetTable.sourceUuid]?.metadata.name}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Target Schema"
          Icon={MetricSchemaIcon}
          truncateEllipsis
        >
          {targetTable.table.schemaName}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Target Table"
          Icon={MetricTableIcon}
          truncateEllipsis
        >
          {targetTable.table.tableName}
        </SummarySectionItem>
        {compares.map(({ targetMetricUuid, index }) => (
          <SummarySectionItem
            key={targetMetricUuid}
            fieldName="Target Metric"
            Icon={MetricCompareConfigItemIcon}
            truncateEllipsis
          >
            {metricsByUuid[targetMetricUuid].metadata.name}
          </SummarySectionItem>
        ))}
      </SummarySection>
    </>
  );
}

function RowByRowDataAssetSummaryContent(props) {
  const {
    metric: {
      config: { sourceTable, targetTable },
    },
    dataSourceList,
  } = props;

  const dataSourcesByUuid = indexBy(
    dataSourceList.data,
    (dataSource) => dataSource.metadata.uuid
  );

  return (
    <>
      <SummarySection title="Source Data Asset Info">
        <SummarySectionItem
          fieldName="Source Datasource"
          Icon={MetricDataSourceTypeIcon}
          truncateEllipsis
        >
          {dataSourcesByUuid[sourceTable.sourceUuid]?.metadata.name}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Source Schema"
          Icon={MetricSchemaIcon}
          truncateEllipsis
        >
          {sourceTable.table.schemaName}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Source Table"
          Icon={MetricTableIcon}
          truncateEllipsis
        >
          {sourceTable.table.tableName}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Source Timestamp"
          Icon={MetricTimestampColumnIcon}
          truncateEllipsis
        >
          {sourceTable.timestampColumn}
        </SummarySectionItem>
        {sourceTable.valueColumns.map((keyCol) => (
          <SummarySectionItem
            key={keyCol}
            fieldName="Source Key Column"
            Icon={MetricCompareConfigItemIcon}
            truncateEllipsis
          >
            {keyCol}
          </SummarySectionItem>
        ))}
        {sourceTable.attributeColumns.map((attrCol) => (
          <SummarySectionItem
            key={attrCol}
            fieldName="Source Attribute Column"
            Icon={MetricCompareConfigItemIcon}
            truncateEllipsis
          >
            {attrCol}
          </SummarySectionItem>
        ))}
      </SummarySection>
      <SummarySection title="Target Data Asset Info">
        <SummarySectionItem
          fieldName="Target Datasource"
          Icon={MetricDataSourceTypeIcon}
          truncateEllipsis
        >
          {dataSourcesByUuid[targetTable.sourceUuid]?.metadata.name}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Target Schema"
          Icon={MetricSchemaIcon}
          truncateEllipsis
        >
          {targetTable.table.schemaName}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Target Table"
          Icon={MetricTableIcon}
          truncateEllipsis
        >
          {targetTable.table.tableName}
        </SummarySectionItem>
        <SummarySectionItem
          fieldName="Target Timestamp"
          Icon={MetricTimestampColumnIcon}
          truncateEllipsis
        >
          {targetTable.timestampColumn}
        </SummarySectionItem>
        {targetTable.valueColumns.map((keyCol) => (
          <SummarySectionItem
            key={keyCol}
            fieldName="Target Key Column"
            Icon={MetricCompareConfigItemIcon}
            truncateEllipsis
          >
            {keyCol}
          </SummarySectionItem>
        ))}
        {targetTable.attributeColumns.map((attrCol) => (
          <SummarySectionItem
            key={attrCol}
            fieldName="Target Attribute Column"
            Icon={MetricCompareConfigItemIcon}
            truncateEllipsis
          >
            {attrCol}
          </SummarySectionItem>
        ))}
      </SummarySection>
    </>
  );
}

function MetricDataAssetSummarySection(props) {
  const { metric, dataSource, dataSourceList, metricList } = props;

  const metricType = getMetricTypeFromConfigData(metric);

  switch (metricType) {
    case MetricCategory.FULL_COMPARE:
      return (
        <RowByRowDataAssetSummaryContent
          metric={metric}
          dataSourceList={dataSourceList}
        />
      );
    case MetricCategory.AGGREGATION_COMPARE:
      return (
        <AggregationCompareDataAssetSummaryContent
          metric={metric}
          metricList={metricList}
          dataSourceList={dataSourceList}
        />
      );
    default:
      return (
        <SingleSourceDataAssetSummaryContent dataSource={dataSource} metric={metric} />
      );
  }
}

export function MonitorSummarySection(props) {
  const { monitor, workspaceUserPermissions, history, title = "Monitor Info" } = props;
  let {
    metadata: { idSerial, ownedBy, updatedBy, tags },
    config: {
      driftDuration,
      recoveryDuration,
      symptom: {
        type,
        direction,
        aggressiveness,
        trainingPeriods = [],
        windowSize,
        bound,
        featureConfig,
      },
    },
  } = monitor;

  let aggressivenessDisplayValue = "";
  if (aggressiveness) {
    if (typeof aggressiveness.level === "number") {
      aggressivenessDisplayValue = `Level ${aggressiveness.level}`;
    } else {
      aggressivenessDisplayValue = `Override: ${aggressiveness.override || 0}`;
    }
  }

  let symptomDisplayName;
  const SymptomIcon = getRuleSymptomInfo(type).icon;
  if (type === SymptomTypeConst.MANUAL_THRESHOLD) {
    if (typeof bound.upper === "number" && typeof bound.lower === "number") {
      direction = ConfigDirection.BOTH;
    } else if (typeof bound.lower === "number") {
      direction = ConfigDirection.DOWN;
    } else if (typeof bound.upper === "number") {
      direction = ConfigDirection.UP;
    }

    if (featureConfig) {
      let basicDisplayString;
      let suffixString;
      if (featureConfig.type === FeatureTypeConst.VALUE) {
        basicDisplayString = "Value threshold";
        suffixString = "";
      } else {
        if (featureConfig.windowSize === DAY_IN_SECONDS) {
          basicDisplayString = "Day over day percentage threshold";
        } else if (featureConfig.windowSize === WEEK_IN_SECONDS) {
          basicDisplayString = "Week over week percentage threshold";
        } else {
          basicDisplayString = "Sample over sample percentage threshold";
        }
        suffixString = "%";
      }

      if (direction === ConfigDirection.BOTH) {
        symptomDisplayName = `${basicDisplayString} between ${bound.lower}${suffixString} and ${bound.upper}${suffixString}`;
      } else if (direction === ConfigDirection.DOWN) {
        symptomDisplayName = `${basicDisplayString} of ${bound.lower}${suffixString} low`;
      } else {
        symptomDisplayName = `${basicDisplayString} of ${bound.upper}${suffixString} high`;
      }
    }
  } else {
    symptomDisplayName = getSymptomTypeDisplayName(type);
  }

  const directionDisplayValue =
    direction.length > 0
      ? direction.charAt(0).toUpperCase() + direction.substr(1).toLowerCase()
      : "";

  return (
    <SummarySection title={title}>
      <SummarySectionItem fieldName="Monitor ID" Icon={IDIcon}>
        {idSerial}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Monitor Name" truncateEllipsis clickable>
        <MonitorLinkCell
          monitor={monitor}
          workspaceUserPermissions={workspaceUserPermissions}
          history={history}
        />
      </SummarySectionItem>
      <SummarySectionItem fieldName="Symptom" Icon={SymptomIcon}>
        {symptomDisplayName}
      </SummarySectionItem>
      {typeof windowSize === "number" && (
        <SummarySectionItem fieldName="Smoothing">
          {getDisplayTimeFromSecond(windowSize)}
        </SummarySectionItem>
      )}
      {trainingPeriods &&
        trainingPeriods.length > 0 &&
        trainingPeriods.map(({ startTs, endTs }, index) => {
          return (
            <SummarySectionItem key={index} fieldName="Training Period">
              {getStringFromTimeStamp(startTs)} to {getStringFromTimeStamp(endTs)}
            </SummarySectionItem>
          );
        })}
      {typeof driftDuration === "number" && (
        <SummarySectionItem fieldName="Drift Duration" Icon={DriftDurationIcon}>
          {getDisplayTimeFromSecond(driftDuration)}
        </SummarySectionItem>
      )}
      {typeof recoveryDuration === "number" && (
        <SummarySectionItem fieldName="Recovery Duration" Icon={RecoveryDurationIcon}>
          {getDisplayTimeFromSecond(recoveryDuration)}
        </SummarySectionItem>
      )}
      {aggressivenessDisplayValue && (
        <SummarySectionItem fieldName="Drift Aggressiveness" Icon={AggressivenessIcon}>
          {aggressivenessDisplayValue}
        </SummarySectionItem>
      )}
      {directionDisplayValue && (
        <SummarySectionItem
          fieldName="Direction of Detection"
          Icon={ConfigDirectionIconComponent(direction)}
        >
          {directionDisplayValue}
        </SummarySectionItem>
      )}
      {type === SymptomTypeConst.MANUAL_THRESHOLD &&
        [ConfigDirection.DOWN, ConfigDirection.BOTH].includes(direction) && (
          <SummarySectionItem fieldName="Lower Threshold Value">
            {bound.lower}
          </SummarySectionItem>
        )}
      {type === SymptomTypeConst.MANUAL_THRESHOLD &&
        [ConfigDirection.UP, ConfigDirection.BOTH].includes(direction) && (
          <SummarySectionItem fieldName="Upper Threshold Value">
            {bound.upper}
          </SummarySectionItem>
        )}
      <SummarySectionItem fieldName="Created by" truncateEllipsis>
        {ownedBy?.email ?? "N/A"}
      </SummarySectionItem>
      <SummarySectionItem fieldName="Updated by" truncateEllipsis>
        {updatedBy?.email ?? "N/A"}
      </SummarySectionItem>
      {tags?.length > 0 && (
        <SummarySectionItem fieldName="Tags">{tags.join(", ")}</SummarySectionItem>
      )}
    </SummarySection>
  );
}

function SummaryTab(props) {
  const {
    incident,
    incidentCreatorInfo,
    dataSourceList,
    metricList,
    history,
    workspaceUserPermissions,
  } = props;

  const metric = incidentCreatorInfo.kpiInfo;
  const dataSource = incidentCreatorInfo.dataSourceInfo;
  const monitor = incidentCreatorInfo.filterInfo;

  return (
    <div className="incident-summary-tab">
      <IncidentSummarySection incident={incident} metric={metric} />
      <MetricSummarySection
        metric={metric}
        workspaceUserPermissions={workspaceUserPermissions}
        history={history}
      />
      <MonitorSummarySection
        incident={incident}
        monitor={monitor}
        workspaceUserPermissions={workspaceUserPermissions}
        history={history}
      />
      <MetricDataAssetSummarySection
        metric={metric}
        metricList={metricList}
        dataSource={dataSource}
        dataSourceList={dataSourceList}
      />
    </div>
  );
}

export default SummaryTab;
