export function TableConfigIcon() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        d="M2.80003 1.8667C2.2853 1.8667 1.8667 2.2853 1.8667 2.80003V11.2C1.8667 11.7148 2.2853 12.1334 2.80003 12.1334H6.77764C6.62224 11.6947 6.53337 11.2252 6.53337 10.7334V11.2H5.13337V9.80003H6.53337V10.7334C6.53337 9.73377 6.88383 8.81752 7.4667 8.09652V7.4667H8.09652C8.81752 6.88383 9.73423 6.53337 10.7334 6.53337H9.80003V5.13337H11.2009V6.53337H10.7334C11.2252 6.53337 11.6947 6.62224 12.1334 6.77764V2.80003C12.1334 2.2853 11.7148 1.8667 11.2 1.8667H2.80003ZM2.80003 2.80003H4.20003V4.20003H2.80003V2.80003ZM5.13337 2.80003H6.53337V4.20003H5.13337V2.80003ZM7.4667 2.80003H8.8667V4.20003H7.4667V2.80003ZM9.80003 2.80003H11.2V4.20003H9.80003V2.80003ZM2.80003 5.13337H4.20003V6.53337H2.80003V5.13337ZM5.13337 5.13337H6.53337V6.53337H5.13337V5.13337ZM7.4667 5.13337H8.8667V6.53337H7.4667V5.13337ZM2.80003 7.4667H4.20003V8.8667H2.80003V7.4667ZM5.13337 7.4667H6.53337V8.8667H5.13337V7.4667ZM2.80003 9.80003H4.20003V11.2H2.80003V9.80003Z"
        fill="#121224"
      />
      <path
        d="M10.1112 8.11353C10.0503 8.11353 9.99938 8.15912 9.9923 8.21953L9.93699 8.69704C9.70856 8.77633 9.50194 8.89713 9.32306 9.05193L8.88059 8.8602C8.8249 8.83613 8.76054 8.85851 8.73034 8.9109L8.28786 9.676C8.25766 9.72839 8.27151 9.79545 8.32013 9.83179L8.70084 10.1148C8.67818 10.2314 8.66489 10.3507 8.66489 10.4734C8.66489 10.5961 8.67818 10.7154 8.70084 10.832L8.32013 11.115C8.27151 11.1513 8.25719 11.2184 8.28786 11.2707L8.73034 12.0359C8.76054 12.0887 8.8249 12.1097 8.88059 12.0856L9.32306 11.8948C9.50194 12.0501 9.70856 12.1704 9.93699 12.2497L9.9923 12.7272C9.99938 12.7876 10.0503 12.8332 10.1112 12.8332H10.9943C11.0552 12.8332 11.1062 12.7876 11.1132 12.7272L11.1685 12.2497C11.397 12.1704 11.6036 12.0496 11.7825 11.8948L12.2249 12.0866C12.2806 12.1106 12.345 12.0882 12.3752 12.0359L12.8177 11.2707C12.8479 11.2179 12.834 11.1508 12.7854 11.115L12.4047 10.832C12.4273 10.7154 12.4406 10.5961 12.4406 10.4734C12.4406 10.3507 12.4273 10.2314 12.4047 10.1148L12.7854 9.83179C12.834 9.79545 12.8483 9.72839 12.8177 9.676L12.3752 8.9109C12.345 8.85851 12.2806 8.83613 12.2249 8.8602L11.7825 9.05193C11.6036 8.89713 11.397 8.77633 11.1685 8.69704L11.1132 8.21953C11.1062 8.15912 11.0552 8.11353 10.9943 8.11353H10.1112ZM10.5528 9.52943C11.0738 9.52943 11.4967 9.95185 11.4967 10.4734C11.4967 10.9944 11.0738 11.4173 10.5528 11.4173C10.0317 11.4173 9.60883 10.9944 9.60883 10.4734C9.60883 9.95185 10.0317 9.52943 10.5528 9.52943Z"
        fill="#121224"
      />
    </svg>
  );
}
