import React from "react";
import ButtonText from "../../components/button/button-text";
import CollapseToggle from "../../components/button/collapse-toggle";
import { TextWithIcon } from "../../components/button/ng-button";
import { DownArrowIcon, RightArrowIcon } from "../../components/icons/arrow";
import { LoadMoreIcon } from "../../components/icons/lists";
import {
  MetricSliceByColumnsIcon,
  metricCategoryIconComponent,
} from "../../components/metric/fields/icons";
import { briefDataAssetSummary } from "../../components/metric/summary-view/data-asset-summary";
import { metricTypeNames } from "../../components/metric/utils";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import Popover from "../../components/popover";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";
import NgTooltip from "../../components/tooltip/ng-tooltip";
import { classesName } from "../../utils/css";
import { MetricCategory } from "../../utils/enums";
import IncidentAnalysisMetricItemContext from "./incident-analysis-metric-item-context";
import { sliceLabel } from "./incident-config";
import { metricSliceCollHas, metricSliceCollHasAll } from "./utils";

import "./incident-analysis-metrics-sidebar-section.scss";

export const SLICE_PAGE_SIZE = 10;

function SidebarCollapseToggle(props) {
  return (
    <CollapseToggle
      CollapsedIcon={RightArrowIcon}
      ExpandedIcon={DownArrowIcon}
      {...props}
    />
  );
}

export function CollapsibleMetricsSection(props) {
  const {
    title,
    metrics = [],
    expandedMetrics = new Set(),
    disabledMetricSliceColl = {},
    selectedMetricSliceColl = {},
    metricSliceCursors,
    dataSourcesByUuid,
    metricsByUuid,
    monitorsByMetricUuid,
    isSearched = false,
    onLoadMoreSlicesClick,
    sectionCollapsed,
    onSectionCollapsedChange,
    onExpandedMetricsChange,
    getItemActions,
  } = props;

  return (
    <div className="incident-analysis-sidebar-section">
      <div
        className="incident-analysis-sidebar-section-header"
        onClick={() => onSectionCollapsedChange(!sectionCollapsed)}
      >
        <SidebarCollapseToggle
          collapsed={sectionCollapsed}
          onChange={onSectionCollapsedChange}
        />
        <span className="incident-analysis-sidebar-header-text">{title}</span>
      </div>
      {!sectionCollapsed && (
        <MetricList
          isSearched={isSearched}
          metrics={metrics}
          expandedSlicedMetrics={expandedMetrics}
          disabledSliceColl={disabledMetricSliceColl}
          selectedSliceColl={selectedMetricSliceColl}
          metricSliceCursors={metricSliceCursors}
          dataSourcesByUuid={dataSourcesByUuid}
          metricsByUuid={metricsByUuid}
          monitorsByMetricUuid={monitorsByMetricUuid}
          onSlicedMetricCollapseChange={onExpandedMetricsChange}
          onLoadMoreSlicesClick={onLoadMoreSlicesClick}
          getItemActions={getItemActions}
        />
      )}
    </div>
  );
}

function IncidentsIndicatorIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M13.0666 11.7031L7.37906 1.85938C7.29429 1.71309 7.148 1.64062 7.00035 1.64062C6.85269 1.64062 6.70503 1.71309 6.62163 1.85938L0.934136 11.7031C0.765971 11.9957 0.976518 12.3594 1.31285 12.3594H12.6878C13.0242 12.3594 13.2347 11.9957 13.0666 11.7031ZM6.56285 5.6875C6.56285 5.62734 6.61206 5.57812 6.67222 5.57812H7.32847C7.38863 5.57812 7.43784 5.62734 7.43784 5.6875V8.20312C7.43784 8.26328 7.38863 8.3125 7.32847 8.3125H6.67222C6.61206 8.3125 6.56285 8.26328 6.56285 8.20312V5.6875ZM7.00035 10.5C6.82862 10.4965 6.6651 10.4258 6.54489 10.3031C6.42468 10.1804 6.35735 10.0155 6.35735 9.84375C6.35735 9.67199 6.42468 9.50706 6.54489 9.38438C6.6651 9.26169 6.82862 9.19101 7.00035 9.1875C7.17207 9.19101 7.33559 9.26169 7.4558 9.38438C7.57601 9.50706 7.64334 9.67199 7.64334 9.84375C7.64334 10.0155 7.57601 10.1804 7.4558 10.3031C7.33559 10.4258 7.17207 10.4965 7.00035 10.5Z"
        fill="#B80739"
      />
    </svg>
  );
}

function MetricItemSliceList(props) {
  const {
    metric,
    isSearched,
    selectedSliceColl = {},
    disabledSliceColl = {},
    maxVisibleSlices,
    getItemActions,
    onLoadMoreSlicesClick,
  } = props;

  const { metricUuid, sliceList } = metric;

  const visibleSlices = isSearched ? sliceList : sliceList.slice(0, maxVisibleSlices);
  const canShowMoreSlices = !isSearched && sliceList.length > maxVisibleSlices;

  return (
    <div className="incident-analysis-sidebar-slice-list">
      <div className="incident-analysis-sidebar-slice-list-header">
        <span className="incident-analysis-sidebar-header-text">Slice</span>
        <span className="incident-analysis-sidebar-header-text">Incidents</span>
      </div>
      {visibleSlices.map((slice) => {
        const { labelHighlighted, hasIncidents } = slice;
        const label = labelHighlighted ?? sliceLabel(slice.slice);
        const selected = metricSliceCollHas(selectedSliceColl, metricUuid, slice);
        const disabled = metricSliceCollHas(disabledSliceColl, metricUuid, slice);
        const className = classesName(
          "incident-analysis-sidebar-slice-list-slice",
          "incident-analysis-metric-item-interactions",
          selected && "selected",
          disabled && "disabled"
        );
        return (
          <div className={className} key={label}>
            <MetricSliceByColumnsIcon />
            <div className="incident-analysis-sidebar-slice-list-slice-name">
              {disabled ? (
                label
              ) : (
                <NgDropdownMenu
                  trigger={<NgTextTooltip>{label}</NgTextTooltip>}
                  menuItems={getItemActions(metric, slice)}
                />
              )}
            </div>
            {hasIncidents && <IncidentsIndicatorIcon />}
          </div>
        );
      })}
      {canShowMoreSlices && (
        <span className="incident-analysis-metric-item-load-more-slices-container">
          <ButtonText
            className="incident-analysis-metric-item-load-more-slices-button"
            onClick={() => {
              onLoadMoreSlicesClick(metricUuid);
              document.activeElement.blur();
            }}
          >
            <TextWithIcon icon={<LoadMoreIcon />} iconPosition="left">
              Load more...
            </TextWithIcon>
          </ButtonText>
        </span>
      )}
    </div>
  );
}

function MetricItem(props) {
  const {
    metric,
    collapsed,
    isSearched,
    selectedSliceColl = {},
    disabledSliceColl = {},
    dataSourcesByUuid,
    metricsByUuid,
    monitorsByMetricUuid,
    maxVisibleSlices,
    getItemActions = (_metric, _slice) => [],
    onCollapseChange,
    onLoadMoreSlicesClick,
  } = props;

  const {
    metricUuid,
    metricName,
    nameHighlighted,
    userMetricType,
    totalSliceCount,
    totalSliceCountWithIncidents,
    incidentCount,
    sliceList = [],
  } = metric;

  // Work around a backend issue where it will sometimes return 0 for totalSliceCount
  // even though there are items in sliceList.
  const effectiveTotalSliceCount = Math.max(totalSliceCount, sliceList.length);

  const isSliced = effectiveTotalSliceCount > 0;

  // We need to unify the backend UserMetricType with MetricCategory; this is a
  // temporary hack map between the values.
  const type = Object.values(MetricCategory).find(
    (t) => metricTypeNames[t] === userMetricType
  );
  const Icon = metricCategoryIconComponent(type);

  const selected = metricSliceCollHasAll(selectedSliceColl, metricUuid, sliceList);
  const disabled = metricSliceCollHasAll(disabledSliceColl, metricUuid, sliceList);
  const headerClassName = classesName(
    "incident-analysis-sidebar-metric-item-header",
    "incident-analysis-metric-item-interactions",
    isSliced ? "sliced" : "unsliced",
    selected && "selected",
    disabled && "disabled"
  );

  function onHeaderClicked() {
    if (isSliced) {
      onCollapseChange(metricUuid, !collapsed);
    }
  }

  const metricConfig = metricsByUuid[metricUuid];

  const contextPopoverContent = metricConfig ? (
    <IncidentAnalysisMetricItemContext
      dataAssetSummary={briefDataAssetSummary(metricConfig, dataSourcesByUuid)}
      monitors={monitorsByMetricUuid[metricUuid] ?? []}
    />
  ) : null;

  const iconSvg = Icon ? <Icon width={14} height={14} /> : null;
  const icon = contextPopoverContent ? (
    <Popover
      overlayClassName="incident-analysis-sidebar-metric-item-context-popover"
      trigger="hover"
      content={contextPopoverContent}
      placement="bottomLeft"
    >
      {iconSvg}
    </Popover>
  ) : (
    iconSvg
  );

  const label = (
    <div className="incident-analysis-sidebar-metric-item-label">
      {icon}
      <NgTextTooltip>{nameHighlighted ?? metricName}</NgTextTooltip>
    </div>
  );

  return (
    <div className={"incident-analysis-sidebar-metric-item"}>
      <div className={headerClassName} onClick={onHeaderClicked}>
        {isSliced ? (
          <>
            <SidebarCollapseToggle
              collapsed={collapsed}
              onChange={(newCollapsed) => onCollapseChange(metricUuid, newCollapsed)}
            />
            {label}
            <NgTooltip title="Number of slices with incidents / Total number of slices">
              <span className="incident-analysis-sidebar-metric-item-slice-count">
                ({totalSliceCountWithIncidents}/{effectiveTotalSliceCount})
              </span>
            </NgTooltip>
          </>
        ) : (
          <>
            <div className="incident-analysis-sidebar-unsliced-item-container">
              {disabled ? (
                label
              ) : (
                <NgDropdownMenu
                  trigger={label}
                  menuItems={getItemActions(metric, null)}
                />
              )}
            </div>
            {incidentCount > 0 && <IncidentsIndicatorIcon />}
          </>
        )}
      </div>
      {isSliced && !collapsed && (
        <MetricItemSliceList
          metric={metric}
          isSearched={isSearched}
          selectedSliceColl={selectedSliceColl}
          disabledSliceColl={disabledSliceColl}
          maxVisibleSlices={maxVisibleSlices}
          onLoadMoreSlicesClick={onLoadMoreSlicesClick}
          getItemActions={getItemActions}
        />
      )}
    </div>
  );
}

function MetricList(props) {
  const {
    metrics,
    isSearched,
    expandedSlicedMetrics,
    selectedSliceColl = {},
    disabledSliceColl = {},
    metricSliceCursors = {},
    dataSourcesByUuid,
    metricsByUuid,
    monitorsByMetricUuid,
    getItemActions,
    onChartAdded,
    onChartRemoved,
    onSlicedMetricCollapseChange,
    onLoadMoreSlicesClick,
  } = props;

  return (
    <div className="incident-analysis-sidebar-metrics-list">
      {metrics.map((metric) => {
        return (
          <MetricItem
            key={metric.metricUuid}
            isSearched={isSearched}
            collapsed={!expandedSlicedMetrics.has(metric.metricUuid)}
            metric={metric}
            selectedSliceColl={selectedSliceColl}
            disabledSliceColl={disabledSliceColl}
            maxVisibleSlices={metricSliceCursors[metric.metricUuid] ?? SLICE_PAGE_SIZE}
            dataSourcesByUuid={dataSourcesByUuid}
            metricsByUuid={metricsByUuid}
            monitorsByMetricUuid={monitorsByMetricUuid}
            onLoadMoreSlicesClick={onLoadMoreSlicesClick}
            getItemActions={getItemActions}
            onChartAdded={onChartAdded}
            onChartRemoved={onChartRemoved}
            onCollapseChange={onSlicedMetricCollapseChange}
          />
        );
      })}
    </div>
  );
}
