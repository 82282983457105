export const LogOutDoorIcon = ({ className = "" }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={className}>
      <path
        d="M1.16663 1.74999C1.16663 1.42782 1.42779 1.16666 1.74996 1.16666H6.99754C7.3197 1.16666 7.58087 1.42782 7.58087 1.74999C7.58087 2.07216 7.3197 2.33332 6.99754 2.33332H2.33329V11.6667H6.99996C7.32213 11.6667 7.58329 11.9278 7.58329 12.25C7.58329 12.5722 7.32213 12.8333 6.99996 12.8333H1.74996C1.42779 12.8333 1.16663 12.5722 1.16663 12.25V1.74999Z"
        fill="white"
      ></path>
      <path
        d="M9.21248 3.96251C9.44029 3.73471 9.80963 3.73471 10.0374 3.96251L12.6306 6.55564C12.7547 6.66262 12.8333 6.82099 12.8333 6.99771L12.8333 6.99878C12.8336 7.14847 12.7766 7.29826 12.6624 7.41247L10.0374 10.0375C9.80963 10.2653 9.44029 10.2653 9.21248 10.0375C8.98467 9.80966 8.98467 9.44032 9.21248 9.21251L10.8439 7.58104H4.66663C4.34446 7.58104 4.08329 7.31988 4.08329 6.99771C4.08329 6.67554 4.34446 6.41438 4.66663 6.41438H10.8394L9.21248 4.78747C8.98467 4.55966 8.98467 4.19032 9.21248 3.96251Z"
        fill="white"
      ></path>
    </svg>
  );
};
