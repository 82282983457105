import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { classesName } from "../../utils/css";
import { DIFF_STATES } from "../../utils/compare";

import "./summary-card.scss";

const DiffClassMap = {
  [DIFF_STATES.ADDED]: "added",
  [DIFF_STATES.DELETED]: "deleted",
};

export function ConfigSummaryCard(props) {
  const {
    className,
    title,
    children,
    onEditClick = null,
    showEditButton = true,
  } = props;

  return (
    <div className={`config-summary-container ${className || ""}`}>
      <div className="config-summary-card-title-container">
        {title}
        {showEditButton && <EditOutlined onClick={onEditClick} />}
      </div>
      <div className="config-card-content-container">{children}</div>
    </div>
  );
}

export function ConfigSummaryCardRow({ icon, label, className }) {
  return (
    <div className={`config-summary-row-container ${className || ""}`}>
      <span className="config-summary-row-icon-container">{icon}</span>
      <span className="config-summary-row-label-container">{label}</span>
    </div>
  );
}

export function ConfigSummaryCardRowDiff(props) {
  const { icon = null, label = null, value = null, diffState } = props;
  return (
    <div className="config-summary-row-diff-container">
      {label !== null && (
        <div className="config-summary-row-diff-label-container">{label}</div>
      )}
      {value !== null && (
        <div
          className={classesName(
            "config-summary-row-diff-value-container",
            DiffClassMap[diffState]
          )}
        >
          {icon !== null && <span>{icon}</span>}
          <span>{value}</span>
        </div>
      )}
    </div>
  );
}
