import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

export function configureSentry(dsn, env, release) {
  Sentry.init({
    dsn: dsn,
    environment: env,
    release: release,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
