import React from "react";
import ButtonIcon from "./button-icon";
import { DownArrowIcon, UpArrowIcon } from "../icons/arrow";

function CollapseToggle(props) {
  const {
    collapsed = true,
    CollapsedIcon = DownArrowIcon,
    ExpandedIcon = UpArrowIcon,
    onChange,
  } = props;

  const icon = collapsed ? <CollapsedIcon /> : <ExpandedIcon />;

  return <ButtonIcon icon={icon} onClick={() => onChange(!collapsed)} />;
}

export default CollapseToggle;
