import React from "react";
import LabelItem from "../../../label-item/label-item";
import ProgressBar from "../../../progress-bar/progress-bar";

import "./rule-config-aggressive-display-view.scss";

function RuleConfigAggressiveDisplayView(props) {
  const { aggressiveness, enableTooltip } = props;

  let level;
  let override;
  let aggressivenessDisplayValue = "";
  if (aggressiveness === undefined) {
    aggressivenessDisplayValue = "Automatic";
  } else {
    level = aggressiveness.level;
    override = aggressiveness.override;

    if (typeof level === "number") {
      aggressivenessDisplayValue = `Level ${level}`;
    } else {
      aggressivenessDisplayValue = `Override: ${override}`;
    }
  }

  return (
    <div className="rule-aggressive-display-view-container">
      <LabelItem
        label="Aggressiveness"
        helpTooltip={
          enableTooltip
            ? "Aggressiveness determines the sensitivity to drifts. A low aggressiveness level corresponds to low sensitivity and in turn will lead to fewer incidents."
            : ""
        }
      >
        <div className="rule-aggressive-display-view-progress">
          <span className="rule-aggressive-display-view-progress-value">
            {aggressivenessDisplayValue}
          </span>
          {typeof level === "number" && <ProgressBar value={level / 10} />}
        </div>
      </LabelItem>
    </div>
  );
}

export default RuleConfigAggressiveDisplayView;
