import React from "react";
import { Table } from "antd";
import ListContextMenu from "../../components/list-context-menu";
import Search from "../../components/search/";
import AddButton from "../../components/button/add-button";

import "./setting-table.scss";

function SettingTable(props) {
  const {
    dataList = [],
    selectionOptionList = [],
    localFilterSetting = {},
    columns,
    addComponent,
    tabComponent = null,
    onAdd,
    enableContextMenu = false,
    contextMenuId = "SettingTableMenuId",
    onSearchItemChange = () => {},
    onContextMenuClick = () => {},
    menuTitle = "MENU",
    enableViewDashboardMenuItem = false,
    enableCloneMenuItem = false,
    enableEditMenuItem = true,
    enableDetailsForViewOnly = false,
    enableViewMenuItem = false,
    enableDeleteMenuItem = true,
    placeholder = "Search title",
    rowKey = "id",
    loading = false,
    enableSearch = true,
  } = props;

  return (
    <div className="setting-table-body-container">
      {tabComponent}
      <div className="setting-table-controller-container lightup-flex-container">
        <div className="setting-table-action-container">
          {onAdd ? <AddButton onClick={onAdd} /> : addComponent}
        </div>
        {enableSearch ? (
          <div className="setting-table-search-container">
            <Search
              localFilterSetting={localFilterSetting}
              selectionOptionList={selectionOptionList}
              onChange={onSearchItemChange}
              placeholder={placeholder}
            />
          </div>
        ) : null}
      </div>
      <div className="setting-table-container">
        {!loading && dataList.length === 0 ? (
          <div className="setting-table-no-result">No data is found.</div>
        ) : (
          <>
            <Table
              dataSource={dataList}
              columns={columns}
              rowKey={rowKey}
              size={"middle"}
              pagination={{ position: ["bottomRight"], hideOnSinglePage: true }}
              loading={loading}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            {enableContextMenu && (
              <ListContextMenu
                title={menuTitle}
                contextMenuId={contextMenuId}
                onContextMenuClick={onContextMenuClick}
                enableCloneMenuItem={enableCloneMenuItem}
                enableDeleteMenuItem={enableDeleteMenuItem}
                enableEditMenuItem={enableEditMenuItem}
                enableDetailsForViewOnly={enableDetailsForViewOnly}
                enableViewMenuItem={enableViewMenuItem}
                enableViewDashboardMenuItem={enableViewDashboardMenuItem}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SettingTable;
