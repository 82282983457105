import React, { useState, useEffect } from "react";
import MetricConfigSummaryView from "../../summary-view/";
import ConfigCard from "../../config-card";
import MetricConfigTabPanel from "../../metric-config-tab-panel";
import MetricSampleDataView from "../../metric-sample-data-view";
import AggregationMetricDataAssetConfig from "./aggregation/";
import CustomSqlMetricDataAssetConfig from "./custom-sql";
import DataDelayMetricDataAssetConfig from "./data-delay";
import DataVolumeMetricDataAssetConfig from "./data-volume";
import FullCompareMetricDataAssetConfig from "./full-compare";
import NullPercentMetricDataAssetConfig from "./null-percent";
import AggregationCompareMetricDataAssetConfig from "./aggregation-compare";
import ConformityCountMetricDataAssetConfig from "./conformity-count";
import DistributionMetricDataAssetConfig from "./distribution";
import ActivityMetricDataAssetConfig from "./activity";
import { MetricCategory } from "../../../../utils/enums";
import { getMetricTypeFromConfigData, isViewDataSampleEnabled } from "../../utils";
import ViewSampleDataButton from "../../view-sample-data-button";
import MetadataMetricDataAssetConfig from "./metadata";

const dataAssetConfigMapper = Object.freeze({
  [MetricCategory.AGGREGATION]: AggregationMetricDataAssetConfig,
  [MetricCategory.CUSTOM_SQL]: CustomSqlMetricDataAssetConfig,
  [MetricCategory.DATA_DELAY]: DataDelayMetricDataAssetConfig,
  [MetricCategory.DATA_VOLUME]: DataVolumeMetricDataAssetConfig,
  [MetricCategory.FULL_COMPARE]: FullCompareMetricDataAssetConfig,
  [MetricCategory.NULL_PERCENT]: NullPercentMetricDataAssetConfig,
  [MetricCategory.AGGREGATION_COMPARE]: AggregationCompareMetricDataAssetConfig,
  [MetricCategory.CONFORMITY_COUNT]: ConformityCountMetricDataAssetConfig,
  [MetricCategory.DISTRIBUTION]: DistributionMetricDataAssetConfig,
  [MetricCategory.BYTE_COUNT]: MetadataMetricDataAssetConfig,
  [MetricCategory.ROW_COUNT]: MetadataMetricDataAssetConfig,
  [MetricCategory.UPDATE_DELAY]: MetadataMetricDataAssetConfig,
  [MetricCategory.TABLE_ACTIVITY]: ActivityMetricDataAssetConfig,
  [MetricCategory.COLUMN_ACTIVITY]: ActivityMetricDataAssetConfig,
  [MetricCategory.CATEGORY_ACTIVITY]: ActivityMetricDataAssetConfig,
});

function MetricConfigDataAssetTabContentView(props) {
  const {
    getCurrentKpiSampleDataTableSchemaList,
    getCurrentKpiSampleDataSampleDataList,
    currentKpiSampleDataTableSchemaList,
    currentKpiSampleDataSampleDataList,
    enableViewSampleData,
    configData,
    ...otherProps
  } = props;

  const [isViewSampleData, setIsViewSampleData] = useState(false);
  const metricType = getMetricTypeFromConfigData(configData);
  useEffect(() => {
    setIsViewSampleData(false);
  }, [metricType]);

  const MetricConfigDataAssetTabContentViewConfigTab =
    dataAssetConfigMapper[metricType] || null;

  let tabMeta;
  if (isViewSampleData) {
    tabMeta = { title: "Viewing Sample Data", description: "" };
  } else {
    tabMeta = {
      title: MetricConfigDataAssetTabContentViewConfigTab.assetMeta.title,
      description: props.isEdit
        ? MetricConfigDataAssetTabContentViewConfigTab.assetMeta.editDescription
        : MetricConfigDataAssetTabContentViewConfigTab.assetMeta.createDescription,
    };
  }

  return (
    <div className="metric-config-data-asset-tab-content-view-tab-content-container">
      {
        <ConfigCard
          title={tabMeta.title}
          description={tabMeta.description}
          actionComponent={
            ![MetricCategory.AGGREGATION_COMPARE].includes(metricType) && (
              <ViewSampleDataButton
                isViewing={isViewSampleData}
                disabled={!isViewDataSampleEnabled(configData) || !enableViewSampleData}
                onClick={() => setIsViewSampleData(!isViewSampleData)}
              />
            )
          }
        >
          {!isViewSampleData && (
            <MetricConfigDataAssetTabContentViewConfigTab
              configData={configData}
              {...otherProps}
            />
          )}
          {isViewSampleData && (
            <MetricSampleDataView
              configData={configData}
              currentTableList={props.tableList}
              currentKpiSampleDataTableSchemaList={currentKpiSampleDataTableSchemaList}
              currentKpiSampleDataSampleDataList={currentKpiSampleDataSampleDataList}
              getCurrentKpiSampleDataTableSchemaList={
                getCurrentKpiSampleDataTableSchemaList
              }
              getCurrentKpiSampleDataSampleDataList={
                getCurrentKpiSampleDataSampleDataList
              }
            />
          )}
        </ConfigCard>
      }
    </div>
  );
}

function MetricConfigDataAssetTabPanel(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    kpiList,
    onDataSourceChanged,
    onSchemaChanged,
    onSrcDataSourceChanged,
    srcSchemaList,
    schemaList,
    srcTableList,
    onSrcTableChanged,
    srcColumnList,
    tableList,
    onTableChanged,
    onCustomSqlTableChanged,
    inColumnColumnList,
    onInColumnTableChanged,
    columnList,
    customSqlColumnList,
    columnValueList,
    partitionSampleData,
    getPartitionSampleData,
    srcPartitionSampleData,
    getSrcPartitionSampleData,
    getCurrentKpiSampleDataTableSchemaList,
    getCurrentKpiSampleDataSampleDataList,
    currentKpiSampleDataTableSchemaList,
    currentKpiSampleDataSampleDataList,
    openWorkspaceTakeover,
    closeTakeover,
    tagList,
    waffle,
    verifyKpiCustomSql,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult,
    enableViewSampleData,
    resetKPIStatus,
    isEdit,
    disabled,
  } = props;

  return (
    <MetricConfigTabPanel
      summarySection={<MetricConfigSummaryView {...props} />}
      contentSection={
        <MetricConfigDataAssetTabContentView
          configData={configData}
          isValidConfig={isValidConfig}
          onConfigDataChange={onConfigDataChange}
          dataSourceList={dataSourceList}
          kpiList={kpiList}
          onSrcDataSourceChanged={onSrcDataSourceChanged}
          onDataSourceChanged={onDataSourceChanged}
          onSchemaChanged={onSchemaChanged}
          onCustomSqlTableChanged={onCustomSqlTableChanged}
          srcTableList={srcTableList}
          onSrcTableChanged={onSrcTableChanged}
          srcColumnList={srcColumnList}
          srcSchemaList={srcSchemaList}
          schemaList={schemaList}
          tableList={tableList}
          onTableChanged={onTableChanged}
          columnList={columnList}
          inColumnColumnList={inColumnColumnList}
          onInColumnTableChanged={onInColumnTableChanged}
          customSqlColumnList={customSqlColumnList}
          columnValueList={columnValueList}
          partitionSampleData={partitionSampleData}
          getPartitionSampleData={getPartitionSampleData}
          srcPartitionSampleData={srcPartitionSampleData}
          getSrcPartitionSampleData={getSrcPartitionSampleData}
          openWorkspaceTakeover={openWorkspaceTakeover}
          closeTakeover={closeTakeover}
          tagList={tagList}
          waffle={waffle}
          getCurrentKpiSampleDataTableSchemaList={
            getCurrentKpiSampleDataTableSchemaList
          }
          getCurrentKpiSampleDataSampleDataList={getCurrentKpiSampleDataSampleDataList}
          currentKpiSampleDataTableSchemaList={currentKpiSampleDataTableSchemaList}
          currentKpiSampleDataSampleDataList={currentKpiSampleDataSampleDataList}
          verifyKpiCustomSql={verifyKpiCustomSql}
          enableViewSampleData={enableViewSampleData}
          validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
          validateFailingRecordsSqlResult={validateFailingRecordsSqlResult}
          resetKPIStatus={resetKPIStatus}
          isEdit={isEdit}
          disabled={disabled}
          currentDataSource={dataSourceList.find(
            (dataSource) =>
              dataSource?.metadata?.uuid === configData?.config?.sources?.[0]
          )}
        />
      }
    />
  );
}

export default MetricConfigDataAssetTabPanel;
