import React, { Component } from "react";
import PropTypes from "prop-types";
import TableHeader from "./table-header";
import TableBody from "./table-body";

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortings: this.getDefaultSortings(props),
    };
  }

  getDefaultSortings(props) {
    return props.columns.map((column) => {
      let sorting = "both";
      if (column.defaultSorting) {
        const defaultSorting = column.defaultSorting.toLowerCase();

        if (defaultSorting === "desc") {
          sorting = "desc";
        } else if (defaultSorting === "asc") {
          sorting = "asc";
        } else {
          sorting = "both";
        }
      }
      return sorting;
    });
  }

  sortData(data, sortings) {
    let sortedData = this.props.data;
    for (var i in sortings) {
      const sorting = sortings[i];
      const column = this.props.columns[i];
      const key = this.props.columns[i].key;
      switch (sorting) {
        case "desc":
          if (column.descSortFunction && typeof column.descSortFunction == "function") {
            sortedData = column.descSortFunction(sortedData, key);
          } else {
            sortedData = this.descSortData(sortedData, key);
          }
          break;

        case "asc":
          if (column.ascSortFunction && typeof column.ascSortFunction == "function") {
            sortedData = column.ascSortFunction(sortedData, key);
          } else {
            sortedData = this.ascSortData(sortedData, key);
          }
          break;

        default:
          break;
      }
    }
    return sortedData;
  }

  ascSortData(data, key) {
    return this.sortDataByKey(data, key, (a, b) => {
      if (this.parseFloatable(a) && this.parseFloatable(b)) {
        a = this.parseIfFloat(a);
        b = this.parseIfFloat(b);
      }
      if (a >= b) {
        return 1;
      } else if (a < b) {
        return -1;
      }
    });
  }

  descSortData(data, key) {
    return this.sortDataByKey(data, key, (a, b) => {
      if (this.parseFloatable(a) && this.parseFloatable(b)) {
        a = this.parseIfFloat(a);
        b = this.parseIfFloat(b);
      }
      if (a <= b) {
        return 1;
      } else if (a > b) {
        return -1;
      }
    });
  }

  parseFloatable(value) {
    return typeof value === "string" &&
      (/^\d+$/.test(value) || /^\d+$/.test(value.replace(/[,.%$]/g, "")))
      ? true
      : false;
  }

  parseIfFloat(value) {
    return parseFloat(value.replace(/,/g, ""));
  }

  sortDataByKey(data, key, fn) {
    const clone = Array.apply(null, data);

    return clone.sort((a, b) => {
      return fn(a[key], b[key]);
    });
  }

  onStateChange(index) {
    const sortings = this.state.sortings.map((sorting, i) => {
      if (i === index) {
        sorting = this.nextSortingState(sorting);
      }
      return sorting;
    });

    this.setState({
      sortings,
    });
  }

  nextSortingState(state) {
    let next;
    switch (state) {
      case "both":
        next = "desc";
        break;

      case "desc":
        next = "asc";
        break;

      case "asc":
        next = "both";
        break;

      default:
        next = "both";
        break;
    }
    return next;
  }

  render() {
    const sortedData = this.props.enableSorting
      ? this.sortData(this.props.data, this.state.sortings)
      : this.props.data;

    return (
      <table className="table" style={this.props.style}>
        <TableHeader
          columns={this.props.columns}
          sortings={this.state.sortings}
          onStateChange={this.onStateChange.bind(this)}
          iconStyle={this.props.iconStyle}
          iconDesc={this.props.iconDesc}
          iconAsc={this.props.iconAsc}
          iconBoth={this.props.iconBoth}
        />
        <TableBody
          columns={this.props.columns}
          rowConfig={this.props.rowConfig}
          data={sortedData}
          sortings={this.state.sortings}
        />
      </table>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowConfig: PropTypes.object,
  enableSorting: PropTypes.bool,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  iconDesc: PropTypes.node,
  iconAsc: PropTypes.node,
  iconBoth: PropTypes.node,
};

Table.defaultProps = {
  enableSorting: false,
  rowConfig: {},
};
