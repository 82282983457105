import * as types from "./takeover-action-types";
import { TakeoverWidth } from "../../utils/enums";

export function openWorkspaceTakeover(
  takeoverElement = null,
  size = TakeoverWidth.FULLSCREEN,
  outsideClick = null,
  className = ""
) {
  return {
    type: types.OPEN_WORKSPACE_TAKEOVER,
    takeoverElement,
    size,
    outsideClick,
    className,
  };
}

export function closeTakeover() {
  return { type: types.CLOSE_TAKEOVER };
}
