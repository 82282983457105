import React from "react";
import queryString from "query-string";

export function decamelize(str) {
  return str
    .replace(/([a-z\d])([A-Z])/g, "$1 $2") // Add space between lower followed by upper case
    .toLowerCase(); // Convert the entire string to lowercase
}

export function stringListCompareFunction(
  tagList,
  searchTagName,
  stringCompareFunction
) {
  tagList = tagList || [];
  if (searchTagName.length === 0) {
    return true;
  }

  for (let tag of tagList) {
    if (stringCompareFunction(tag, searchTagName)) {
      return true;
    }
  }

  return false;
}

export function listOfStringListCompareFunction(
  listOfStringList,
  searchName,
  stringCompareFunction
) {
  if (listOfStringList.length > 0 && searchName.length === 0) {
    return false;
  }

  for (let stringList of listOfStringList) {
    if (!stringListCompareFunction(stringList, searchName, stringCompareFunction)) {
      return false;
    }
  }

  return true;
}

export function normalizeFilterStringListParams(stringList, enableTrim = true) {
  if (typeof stringList === "string") {
    stringList = [stringList];
  }

  const normalizedStringList = [];
  for (let stringItem of stringList) {
    const trimmedStringItem = enableTrim ? stringItem.trim() : stringItem;
    if (trimmedStringItem) {
      normalizedStringList.push(trimmedStringItem);
    }
  }

  return normalizedStringList;
}

export function normalizeFilterListOfTagListParams(listOfTagList, enableTrim = true) {
  if (typeof listOfTagList === "string") {
    listOfTagList = [[listOfTagList]];
  }

  if (
    Array.isArray(listOfTagList) &&
    listOfTagList.length > 0 &&
    typeof listOfTagList[0] === "string"
  ) {
    listOfTagList = [listOfTagList];
  }

  const normalizedListOfTagList = [];
  for (let tagList of listOfTagList) {
    const normalizedTagList = [];
    for (let tag of tagList) {
      const trimmedTag = enableTrim ? tag.trim() : tag;
      if (trimmedTag) {
        normalizedTagList.push(trimmedTag);
      }
    }
    normalizedTagList.length > 0 && normalizedListOfTagList.push(normalizedTagList);
  }

  return normalizedListOfTagList;
}

export function getStringCompareFunction(isMatchExactly) {
  if (isMatchExactly) {
    return function (src, targets) {
      if (targets.length === 0) {
        return true;
      }
      for (let targetItem of targets) {
        if (src === targetItem) {
          return true;
        }
      }
      return false;
    };
  } else {
    return function (src, targets) {
      if (targets.length === 0) {
        return true;
      }

      for (let targetItem of targets) {
        if (targetItem && src && src.toLowerCase().includes(targetItem.toLowerCase())) {
          return true;
        }
      }
      return false;
    };
  }
}

const searchAttributeNameList = [
  "columnName",
  "dataSourceName",
  "filterName",
  "integrationName",
  "kpiName",
  "scheduleName",
  "searchName",
  "slice",
  "tableName",
  "tagName",
  "userNameList",
  "monitorId",
  "metricId",
  "dataSourceId",
  "incidentId",
  "metricType",
  "monitorType",
  "alertChannelId",
];

export function encodeEntityListSearchObject(searchObj) {
  const nonEmptySearchObject = {};
  for (let searchAttributeName of searchAttributeNameList) {
    if (searchObj[searchAttributeName]?.length > 0) {
      nonEmptySearchObject[searchAttributeName] = searchObj[searchAttributeName];
    }
  }

  return `search=${encodeURIComponent(JSON.stringify(nonEmptySearchObject))}`;
}

export function decodeEntityListSearchObject(locationSearchString) {
  let searchObj = {};
  if (!locationSearchString) {
    return searchObj;
  }

  try {
    const queryParams = queryString.parse(locationSearchString);
    if (!queryParams.search) {
      return searchObj;
    }

    const searchObjectFromURI = JSON.parse(decodeURIComponent(queryParams.search));
    return {
      ...searchObj,
      ...searchObjectFromURI,
    };
  } catch (e) {
    console.log(`Fail to decode search string ${locationSearchString}`);
    return searchObj;
  }
}

export function highlightQueryString(query = "", target = "", highlightClassName = "") {
  const matchStartIndex = target.toLowerCase().indexOf(query.toLowerCase());
  if (matchStartIndex === -1) {
    return { match: false, highlighted: target };
  }
  const beforeStr = target.substring(0, matchStartIndex);
  const queryStr = target.substring(matchStartIndex, matchStartIndex + query.length);
  const afterStr = target.substring(matchStartIndex + query.length);
  const highlightedTarget = (
    <span>
      {beforeStr}
      <span className={highlightClassName}>{queryStr}</span>
      {afterStr}
    </span>
  );
  return { match: true, highlighted: highlightedTarget };
}
