import React from "react";
import { NgTextTooltip } from "../text-tooltip/ng-text-tooltip";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { getDataSourceIcon } from "../../utils/icon";
import { TreePathLevel } from "../../utils/enums";
import { ProfileSchemaIcon, ProfileTableIcon } from "../../views/profiler/icons";
import { NextGenPalette } from "../../utils/color";
import { getIconFromColumn } from "../../views/profiler/column-icons";
import { metricCategoryIconComponent } from "../metric/fields/icons";
import { useLocation } from "react-router-dom";
import { queryParamsRemoved } from "../../utils/uri-path";
import { trackTreePathClick } from "./tree-path.utils";

import "./tree-path.scss";

const UrlToSelectNodeMap = {
  schemaUuid: "schemaInfo",
  tableUuid: "tableInfo",
  columnUuid: "columnInfo",
  metricUuid: "metric",
};

function TreePathNode(props) {
  const {
    pathLevel,
    selectedPathLevel,
    children,
    className = "",
    onNavigate = null,
    removeUrlParams,
  } = props;

  const location = useLocation();
  const isSelected = pathLevel === selectedPathLevel;
  const selectedClass = isSelected ? "selected" : "";

  const onHrefClick = (event) => {
    event.preventDefault();
    const selectNodePatch = Object.fromEntries(
      removeUrlParams.map((key) => [UrlToSelectNodeMap[key], null])
    );

    onNavigate?.(selectNodePatch);
  };

  return (
    <div
      className={`explorer-tree-path-node ${className} ${selectedClass} ${pathLevel}`}
    >
      {isSelected ? (
        children
      ) : (
        <a
          href={`/#${queryParamsRemoved(location, removeUrlParams)}`}
          onClick={onHrefClick}
        >
          {children}
        </a>
      )}
    </div>
  );
}

function TreePath(props) {
  const {
    datasource,
    schema,
    table,
    column: { type: columnType, name: columnName } = {},
    customMetric: { type: metricType, name: metricName } = {},
    selectedLevel,
    statusComponent = null,
    currentSelectNode,
    onCurrentSelectNodeChange,
    enableTreeExpand = true,
    isTreeExpand = true,
    onTreeExpandChange,
  } = props;

  const datasourceType = datasource?.type;
  const datasourceName = datasource?.name;

  const DatasourceTypeIcon = getDataSourceIcon(datasourceType);
  const ColumnTypeIcon = getIconFromColumn({ columnTypeCategory: columnType });
  const MetricTypeIcon = metricCategoryIconComponent(metricType);

  const navigateRemovingParam = (objectPatch, pathLevel) => {
    trackTreePathClick(
      pathLevel,
      currentSelectNode.dataSource,
      currentSelectNode.schemaInfo,
      currentSelectNode.tableInfo
    );

    onCurrentSelectNodeChange?.({ ...currentSelectNode, ...objectPatch });
  };

  return (
    <div className="explorer-tree-path">
      {enableTreeExpand && (
        <div
          className="explorer-tree-expand-container"
          onClick={() => onTreeExpandChange?.(!isTreeExpand)}
        >
          {isTreeExpand ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </div>
      )}

      {datasourceType && (
        <TreePathNode
          pathLevel={TreePathLevel.DATASOURCE}
          selectedPathLevel={selectedLevel}
          removeUrlParams={["schemaUuid", "tableUuid", "columnUuid", "metricUuid"]}
          onNavigate={(objectPatch) =>
            navigateRemovingParam(objectPatch, TreePathLevel.DATASOURCE)
          }
        >
          {DatasourceTypeIcon && <DatasourceTypeIcon />}
          <NgTextTooltip>{datasourceName}</NgTextTooltip>
        </TreePathNode>
      )}
      {schema && (
        <TreePathNode
          pathLevel={TreePathLevel.SCHEMA}
          selectedPathLevel={selectedLevel}
          removeUrlParams={["tableUuid", "columnUuid", "metricUuid"]}
          onNavigate={(objectPatch) =>
            navigateRemovingParam(objectPatch, TreePathLevel.SCHEMA)
          }
        >
          <ProfileSchemaIcon size={14} />
          <NgTextTooltip>{schema}</NgTextTooltip>
        </TreePathNode>
      )}
      {table && (
        <TreePathNode
          pathLevel={TreePathLevel.TABLE}
          selectedPathLevel={selectedLevel}
          removeUrlParams={["columnUuid", "metricUuid"]}
          onNavigate={(objectPatch) =>
            navigateRemovingParam(objectPatch, TreePathLevel.TABLE)
          }
        >
          <ProfileTableIcon width={14} height={14} />
          <NgTextTooltip>{table}</NgTextTooltip>
        </TreePathNode>
      )}
      {columnName && (
        <TreePathNode
          pathLevel={TreePathLevel.COLUMN}
          selectedPathLevel={selectedLevel}
          removeUrlParams={["metricUuid"]}
          onNavigate={(objectPatch) =>
            navigateRemovingParam(objectPatch, TreePathLevel.COLUMN)
          }
        >
          <ColumnTypeIcon fillColor={NextGenPalette.systemBlack} />
          <NgTextTooltip>{columnName}</NgTextTooltip>
        </TreePathNode>
      )}
      {metricType && (
        <TreePathNode
          pathLevel={TreePathLevel.CUSTOM_METRIC}
          selectedPathLevel={selectedLevel}
        >
          <MetricTypeIcon size={14} fill={NextGenPalette.systemBlack} />
          <NgTextTooltip>{metricName}</NgTextTooltip>
        </TreePathNode>
      )}
      {statusComponent && (
        <div className="metric-type-tree-path-status-node-container">
          {statusComponent}
        </div>
      )}
    </div>
  );
}

export default TreePath;
