const LOGGER_LOCAL_STORAGE_KEY = "logger";

export class Logger {
  constructor() {
    this.loggerEnabled =
      window.localStorage.getItem(LOGGER_LOCAL_STORAGE_KEY) === "true";
  }

  toggle(enable) {
    const prevState = this.loggerEnabled;
    window.localStorage.setItem(LOGGER_LOCAL_STORAGE_KEY, enable);
    this.loggerEnabled = enable;
    if (prevState !== this.loggerEnabled) {
      console.warn(`Logger has been ${this.loggerEnabled ? "enabled" : "disabled"}`);
    }
  }

  request(request) {
    if (!this.loggerEnabled) return;
    const { method, url, headers, data } = request;
    const label = `Request: ${method.toUpperCase()} ${url}`;
    console.groupCollapsed(label);
    console.log({
      headers,
      data,
      meta: request,
    });
    console.groupEnd();
  }

  response(response) {
    if (!this.loggerEnabled) return;
    const { status, statusText, headers, data, config } = response;
    const label = `Response: ${config.url} ${status} ${statusText}`;
    console.groupCollapsed(label);
    console.log({ headers, data, response });
    console.groupEnd();
  }

  error(error) {
    if (!this.loggerEnabled) return;
    const { response, config } = error;
    const label = `Response Error: ${config.url} ${response ? response.status : ""} ${
      response ? response.statusText : ""
    }`;
    const message = response.data.error;
    console.group(label);
    console.error({ message, error });
    console.groupEnd();
  }
}

export default new Logger();
