import React, { Component } from "react";
import { withRouter, Redirect, matchPath } from "react-router";
import { Dropdown, Menu } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar } from "../avatar/";
import UserAPIKeyDialog from "../header/user-api-key-dialog";
import UserSupportDialog from "../header/user-support-dialog";
import WorkspaceNode from "./workspace-node";
import SidebarMenuItemNode from "./sidebar-menu-item-node";
import {
  URIPathPermissions,
  hasPermission,
  wsUuidParamName,
  URIPath,
  getURIInstance,
} from "../../utils/uri-path";
import {
  WorkspaceItemKeyToUriMapper,
  SidebarMenuItemNodeTypeToUriMapper,
  SideBarMenuItemNodeType,
} from "./util";
import { NextGenPalette } from "../../utils/color";

import auth0 from "../../utils/auth0";

import LightupLogo from "../../assets/img/LightUpLogo_light.svg";
import LightupIconOnlyLogo from "../../assets/img/LightUpEmblem_Light.svg";
import { EVENT, trackEvent } from "../../utils/telemetry";
import { canNavigate } from "../../hooks/useNavigationBlocker";

import "./sidebar.scss";

const SideBarSettingAction = Object.freeze({
  API_CREDENTIAL: "API credentials",
  HELP: "help",
  SUPPORT: "support",
  LOGOUT: "logout",
  VERSION: "version",
});

const MIN_SIDEBAR_WIDTH = 48;
const MAX_SIDEBAR_WIDTH = 312;
const DEFAULT_SIDEBAR_WIDTH = 208;

const MEDIUM_SIDEBAR_BREAKPOINT = 190;
const MINIMUM_SIDEBAR_BREAKPOINT = 100;

const UriToWorkspaceItemKeyMapper = {};
for (const [key, value] of Object.entries(WorkspaceItemKeyToUriMapper)) {
  UriToWorkspaceItemKeyMapper[value] = key;
}

const UriToSidebarMenuItemTypeMapper = {};
for (const [key, value] of Object.entries(SidebarMenuItemNodeTypeToUriMapper)) {
  UriToSidebarMenuItemTypeMapper[value] = key;
}

function AdminIcon(props) {
  const { size = 14 } = props;
  return (
    <span role="img" className="anticon">
      <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
        <path
          d="M4.08333 7.58366C4.40549 7.58366 4.66666 7.32249 4.66666 7.00033C4.66666 6.67816 4.40549 6.41699 4.08333 6.41699C3.76116 6.41699 3.49999 6.67816 3.49999 7.00033C3.49999 7.32249 3.76116 7.58366 4.08333 7.58366Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M5.24999 8.75033C5.24999 9.07249 4.98883 9.33366 4.66666 9.33366C4.3445 9.33366 4.08333 9.07249 4.08333 8.75033C4.08333 8.42816 4.3445 8.16699 4.66666 8.16699C4.98883 8.16699 5.24999 8.42816 5.24999 8.75033Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M2.91666 9.33366C3.23883 9.33366 3.49999 9.07249 3.49999 8.75033C3.49999 8.42816 3.23883 8.16699 2.91666 8.16699C2.5945 8.16699 2.33333 8.42816 2.33333 8.75033C2.33333 9.07249 2.5945 9.33366 2.91666 9.33366Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M6.41666 7.00033C6.41666 7.32249 6.15549 7.58366 5.83333 7.58366C5.51116 7.58366 5.24999 7.32249 5.24999 7.00033C5.24999 6.67816 5.51116 6.41699 5.83333 6.41699C6.15549 6.41699 6.41666 6.67816 6.41666 7.00033Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M6.41666 9.33366C6.73883 9.33366 6.99999 9.07249 6.99999 8.75033C6.99999 8.42816 6.73883 8.16699 6.41666 8.16699C6.09449 8.16699 5.83333 8.42816 5.83333 8.75033C5.83333 9.07249 6.09449 9.33366 6.41666 9.33366Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M8.16666 7.00033C8.16666 7.32249 7.90549 7.58366 7.58333 7.58366C7.26116 7.58366 6.99999 7.32249 6.99999 7.00033C6.99999 6.67816 7.26116 6.41699 7.58333 6.41699C7.90549 6.41699 8.16666 6.67816 8.16666 7.00033Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M8.16666 9.33366C8.48883 9.33366 8.74999 9.07249 8.74999 8.75033C8.74999 8.42816 8.48883 8.16699 8.16666 8.16699C7.84449 8.16699 7.58333 8.42816 7.58333 8.75033C7.58333 9.07249 7.84449 9.33366 8.16666 9.33366Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M9.91666 7.00033C9.91666 7.32249 9.65549 7.58366 9.33333 7.58366C9.01116 7.58366 8.74999 7.32249 8.74999 7.00033C8.74999 6.67816 9.01116 6.41699 9.33333 6.41699C9.65549 6.41699 9.91666 6.67816 9.91666 7.00033Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M9.91666 9.33366C10.2388 9.33366 10.5 9.07249 10.5 8.75033C10.5 8.42816 10.2388 8.16699 9.91666 8.16699C9.59449 8.16699 9.33333 8.42816 9.33333 8.75033C9.33333 9.07249 9.59449 9.33366 9.91666 9.33366Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M11.6667 7.00033C11.6667 7.32249 11.4055 7.58366 11.0833 7.58366C10.7612 7.58366 10.5 7.32249 10.5 7.00033C10.5 6.67816 10.7612 6.41699 11.0833 6.41699C11.4055 6.41699 11.6667 6.67816 11.6667 7.00033Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          d="M4.37499 10.5003C4.37499 10.1782 4.63616 9.91699 4.95833 9.91699H9.04166C9.36383 9.91699 9.62499 10.1782 9.62499 10.5003C9.62499 10.8225 9.36383 11.0837 9.04166 11.0837H4.95833C4.63616 11.0837 4.37499 10.8225 4.37499 10.5003Z"
          fill={NextGenPalette.systemBlack}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 1.75033C12.25 1.42816 11.9888 1.16699 11.6667 1.16699C11.3445 1.16699 11.0833 1.42816 11.0833 1.75033V2.95345H9.91666C9.43341 2.95345 9.04166 3.3452 9.04166 3.82845V4.66699H1.74999C1.10566 4.66699 0.583328 5.18933 0.583328 5.83366V11.667C0.583328 12.3113 1.10566 12.8337 1.74999 12.8337H12.25C12.8943 12.8337 13.4167 12.3113 13.4167 11.667V5.83366C13.4167 5.18933 12.8943 4.66699 12.25 4.66699H10.2083V4.12012H11.375C11.8582 4.12012 12.25 3.72837 12.25 3.24512V1.75033ZM1.74999 5.83366V11.667H12.25V5.83366H1.74999Z"
          fill={NextGenPalette.systemBlack}
        />
      </svg>
    </span>
  );
}

function AddWorkspaceIcon(props) {
  const { size = 14 } = props;
  return (
    <span role="img" className="anticon">
      <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
        <path
          d="M2.33334 1.16602C1.68901 1.16602 1.16667 1.68835 1.16667 2.33268V4.08268C1.16667 4.40485 1.42784 4.66602 1.75001 4.66602C2.07217 4.66602 2.33334 4.40485 2.33334 4.08268V2.33268H4.08334C4.4055 2.33268 4.66667 2.07152 4.66667 1.74935C4.66667 1.42718 4.4055 1.16602 4.08334 1.16602H2.33334Z"
          fill="white"
        />
        <path
          d="M7.58334 4.66602C7.58334 4.34385 7.32217 4.08268 7.00001 4.08268C6.67784 4.08268 6.41667 4.34385 6.41667 4.66602V6.41602H4.66667C4.34451 6.41602 4.08334 6.67718 4.08334 6.99935C4.08334 7.32152 4.34451 7.58268 4.66667 7.58268H6.41667V9.33268C6.41667 9.65485 6.67784 9.91602 7.00001 9.91602C7.32217 9.91602 7.58334 9.65485 7.58334 9.33268V7.58268H9.33334C9.65551 7.58268 9.91667 7.32152 9.91667 6.99935C9.91667 6.67718 9.65551 6.41602 9.33334 6.41602H7.58334V4.66602Z"
          fill="white"
        />
        <path
          d="M12.8333 6.12435C12.8333 5.80218 12.5722 5.54102 12.25 5.54102C11.9278 5.54102 11.6667 5.80218 11.6667 6.12435V7.87435C11.6667 8.19652 11.9278 8.45768 12.25 8.45768C12.5722 8.45768 12.8333 8.19652 12.8333 7.87435V6.12435Z"
          fill="white"
        />
        <path
          d="M1.75001 5.54102C2.07217 5.54102 2.33334 5.80218 2.33334 6.12435V7.87435C2.33334 8.19652 2.07217 8.45768 1.75001 8.45768C1.42784 8.45768 1.16667 8.19652 1.16667 7.87435V6.12435C1.16667 5.80218 1.42784 5.54102 1.75001 5.54102Z"
          fill="white"
        />
        <path
          d="M9.33334 1.74935C9.33334 1.42718 9.59451 1.16602 9.91667 1.16602H11.6667C12.311 1.16602 12.8333 1.68836 12.8333 2.33268V4.08268C12.8333 4.40485 12.5722 4.66602 12.25 4.66602C11.9278 4.66602 11.6667 4.40485 11.6667 4.08268V2.33268H9.91667C9.59451 2.33268 9.33334 2.07152 9.33334 1.74935Z"
          fill="white"
        />
        <path
          d="M12.8333 9.91602C12.8333 9.59385 12.5722 9.33268 12.25 9.33268C11.9278 9.33268 11.6667 9.59385 11.6667 9.91602V11.666H9.91667C9.59451 11.666 9.33334 11.9272 9.33334 12.2493C9.33334 12.5715 9.59451 12.8327 9.91667 12.8327H11.6667C12.311 12.8327 12.8333 12.3104 12.8333 11.666V9.91602Z"
          fill="white"
        />
        <path
          d="M1.75001 9.33268C2.07217 9.33268 2.33334 9.59385 2.33334 9.91602V11.666H4.08334C4.4055 11.666 4.66667 11.9272 4.66667 12.2493C4.66667 12.5715 4.4055 12.8327 4.08334 12.8327H2.33334C1.68901 12.8327 1.16667 12.3104 1.16667 11.666V9.91602C1.16667 9.59385 1.42784 9.33268 1.75001 9.33268Z"
          fill="white"
        />
        <path
          d="M6.12501 1.16602C5.80284 1.16602 5.54167 1.42718 5.54167 1.74935C5.54167 2.07152 5.80284 2.33268 6.12501 2.33268H7.87501C8.19717 2.33268 8.45834 2.07152 8.45834 1.74935C8.45834 1.42718 8.19717 1.16602 7.87501 1.16602H6.12501Z"
          fill="white"
        />
        <path
          d="M6.12501 11.666C5.80284 11.666 5.54167 11.9272 5.54167 12.2493C5.54167 12.5715 5.80284 12.8327 6.12501 12.8327H7.87501C8.19717 12.8327 8.45834 12.5715 8.45834 12.2493C8.45834 11.9272 8.19717 11.666 7.87501 11.666H6.12501Z"
          fill="white"
        />
      </svg>
    </span>
  );
}

function AddWorkspaceItem(props) {
  const { collapsed, onClick } = props;
  const collapsedClass = collapsed ? "collapsed" : "";
  return (
    <div className={`sidebar-add-workspace-item ${collapsedClass}`} onClick={onClick}>
      <span className="sidebar-add-workspace-item-label">Add New Workspace</span>
      <AddWorkspaceIcon />
    </div>
  );
}

const localSidebarSize = localStorage.getItem("@sidebar-size");

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: "",

      currentSelectNode: null,
      expandWorkspaceMap: {},
      isApiKeyDialogOpen: false,
      isSupportDialogOpen: false,
      workspaceList: [],
      sidebarWidth: localSidebarSize || DEFAULT_SIDEBAR_WIDTH,
    };

    this.onDragSidebar = this.onDragSidebar.bind(this);
    this.onDragEndSidebar = this.onDragEndSidebar.bind(this);

    this.onToggleCollapsed = this.onToggleCollapsed.bind(this);
    this.onOpenSupportDialog = this.onOpenSupportDialog.bind(this);
    this.onOpenAPIKeyDialog = this.onOpenAPIKeyDialog.bind(this);
    this.onUserSettingMenuClick = this.onUserSettingMenuClick.bind(this);
    this.onAddWorkspaceClick = this.onAddWorkspaceClick.bind(this);
    this.onWorkspaceNodeClick = this.onWorkspaceNodeClick.bind(this);
    this.onWorkspaceNodeExpandClick = this.onWorkspaceNodeExpandClick.bind(this);
    this.onSideBarMenuItemClick = this.onSideBarMenuItemClick.bind(this);
  }

  componentDidMount() {
    console.log("SideBar is initialized");
    // @Todo (Sergey, Ming): Move to app level permission check
    this.props.getUserAPIKeyList();
    this.props.getWorkspaceList();
  }

  onDragEndSidebar(e) {
    e.preventDefault();
    localStorage.setItem("@sidebar-size", this.state.sidebarWidth);
  }

  onDragSidebar = (event) => {
    const newWidth = event.clientX;

    if (newWidth >= MIN_SIDEBAR_WIDTH && newWidth <= MAX_SIDEBAR_WIDTH) {
      this.setState({ sidebarWidth: newWidth });
    }
  };

  static getDerivedStateFromProps(props, state) {
    const {
      location: { pathname },
      match: { params },
      workspaceList,
    } = props;
    let isChanged = false;
    if (workspaceList !== state.workspaceList) {
      state = { ...state, workspaceList };
      props.getUserPermissionForAllWorkspace(workspaceList);
      isChanged = true;
    }

    if (pathname !== state.pathname) {
      state = { ...state, pathname };
      isChanged = true;
    }

    if (isChanged) {
      let expandWorkspaceMap = state.expandWorkspaceMap;
      const workspaceUuid = params[wsUuidParamName] || "";
      let currentWorkspace = null;
      if (
        workspaceUuid &&
        workspaceList &&
        workspaceList.length > 0 &&
        workspaceList.filter(({ uuid }) => uuid === workspaceUuid).length > 0
      ) {
        currentWorkspace = workspaceList.filter(
          ({ uuid }) => uuid === workspaceUuid
        )[0];
      }

      let currentSelectNode = null;
      let currentSelectedKey = "";
      for (let currentUri in UriToWorkspaceItemKeyMapper) {
        if (
          UriToWorkspaceItemKeyMapper.hasOwnProperty(currentUri) &&
          matchPath(pathname, { path: currentUri, exact: false, strict: false })
        ) {
          currentSelectedKey = UriToWorkspaceItemKeyMapper[currentUri];
          break;
        }
      }

      if (currentWorkspace) {
        currentSelectNode = {
          type: SideBarMenuItemNodeType.WORKSPACE,
          value: {
            uuid: currentWorkspace.uuid,
            key: currentSelectedKey,
          },
        };
      }

      if (!currentWorkspace || currentSelectedKey === "") {
        expandWorkspaceMap = {};
      } else if (
        currentWorkspace &&
        currentSelectedKey &&
        !expandWorkspaceMap[currentWorkspace.uuid]
      ) {
        expandWorkspaceMap = { [currentWorkspace.uuid]: true };
      }

      if (!currentSelectNode) {
        for (let currentUri in UriToSidebarMenuItemTypeMapper) {
          if (
            UriToSidebarMenuItemTypeMapper.hasOwnProperty(currentUri) &&
            matchPath(pathname, {
              path: currentUri,
              exact: false,
              strict: false,
            })
          ) {
            currentSelectNode = {
              type: UriToSidebarMenuItemTypeMapper[currentUri],
              value: null,
            };
            break;
          }
        }
      }

      return {
        ...state,
        currentSelectNode,
        expandWorkspaceMap,
      };
    }

    return null;
  }

  onToggleCollapsed() {
    const isSidebarWidthUnderMinimumBreakpoint =
      this.state.sidebarWidth <= MINIMUM_SIDEBAR_BREAKPOINT;

    const newWidth = isSidebarWidthUnderMinimumBreakpoint
      ? DEFAULT_SIDEBAR_WIDTH
      : MIN_SIDEBAR_WIDTH;

    this.setState({
      sidebarWidth: newWidth,
    });

    localStorage.setItem("@sidebar-size", newWidth);
  }

  onAddWorkspaceClick() {
    this.onSideBarMenuItemClick({
      type: SideBarMenuItemNodeType.CREATE_WORKSPACE,
    });
  }

  onWorkspaceNodeClick(currentSelectNode) {
    if (!canNavigate()) {
      return;
    }
    this.props.closeTakeover();
    let nextUrl = null;
    if (!currentSelectNode.value.key) {
      nextUrl = getURIInstance(URIPath.EXPLORER, {
        workspaceUuid: currentSelectNode.value.uuid,
      });
    } else {
      let nextUrlTemplate =
        WorkspaceItemKeyToUriMapper[currentSelectNode.value.key] || "";
      if (!nextUrlTemplate) {
        console.log(`unknown key ${currentSelectNode.value.key}`);
        return;
      }
      nextUrl = getURIInstance(nextUrlTemplate, {
        workspaceUuid: currentSelectNode.value.uuid,
      });
    }
    this.props.history.push(nextUrl);
  }

  onSideBarMenuItemClick(currentSelectNode) {
    const type = currentSelectNode.type;
    if (!type) {
      return;
    }

    if (type === "admin") trackEvent(EVENT.SELECT_ADMIN_TAB);

    this.props.closeTakeover();
    const nextUrl = SidebarMenuItemNodeTypeToUriMapper[type];
    this.props.history.push(nextUrl);
  }

  onWorkspaceNodeExpandClick(workspaceUuid, expand) {
    this.setState({
      expandWorkspaceMap: {
        [workspaceUuid]: expand,
      },
    });
  }

  didComponentMount() {
    console.log("Start to loading workspaceList");
  }

  async onLogout() {
    this.props.logout();
    const { auth0ClientId, auth0DomainName } = this.props.userInfo;
    if (!auth0ClientId || !auth0DomainName) {
      return;
    }

    try {
      await auth0.init(auth0ClientId, auth0DomainName);
      auth0.logout();
    } catch (err) {
      console.log("Catch exception while logging out from auth0");
    }
  }

  onOpenHelpPage() {
    window.open("https://docs.lightup.ai", "_blank", "noopener");
  }

  onOpenAPIKeyDialog() {
    const { isApiKeyDialogOpen } = this.state;
    if (!isApiKeyDialogOpen) {
      this.setState({ isApiKeyDialogOpen: true });
    }
  }

  onOpenSupportDialog() {
    const { isSupportDialogOpen } = this.state;
    if (!isSupportDialogOpen) {
      this.setState({ isSupportDialogOpen: true });
    }
  }

  async onUserSettingMenuClick(e) {
    const { key } = e;
    if (key === SideBarSettingAction.LOGOUT) {
      trackEvent(EVENT.LOGOUT);
      await this.onLogout();
    } else if (key === SideBarSettingAction.HELP) {
      trackEvent(EVENT.OPEN_DOCUMENTATION);
      this.onOpenHelpPage();
    } else if (key === SideBarSettingAction.API_CREDENTIAL) {
      trackEvent(EVENT.GET_API_CREDENTIALS);
      this.onOpenAPIKeyDialog();
    } else if (key === SideBarSettingAction.SUPPORT) {
      trackEvent(EVENT.GET_SUPPORT);
      this.onOpenSupportDialog();
    }
  }

  render() {
    const { currentSelectNode, expandWorkspaceMap } = this.state;
    const {
      className,
      userInfo,
      workspaceUserPermissionMap,
      userPermissions,
      workspaceList,
    } = this.props;
    const { username, logoutSucceed } = userInfo;

    const isCollapsed = this.state.sidebarWidth <= MINIMUM_SIDEBAR_BREAKPOINT;

    const isSidebarUnderMediumBreakpoint =
      this.state.sidebarWidth <= MEDIUM_SIDEBAR_BREAKPOINT;

    if (logoutSucceed) {
      return <Redirect push to={URIPath.LOGIN} />;
    }

    const settingMenu = (
      <Menu
        className="lightup-popup-menu"
        onClick={this.onUserSettingMenuClick}
        theme="dark"
        style={{
          width: this.state.sidebarWidth,
        }}
      >
        <Menu.Item key={SideBarSettingAction.API_CREDENTIAL}>API Credentials</Menu.Item>
        <Menu.Item key={SideBarSettingAction.HELP}>Help</Menu.Item>
        <Menu.Item key={SideBarSettingAction.SUPPORT}>Support</Menu.Item>
        <Menu.Item key={SideBarSettingAction.LOGOUT}>Logout</Menu.Item>
        <Menu.Item
          disabled={true}
          key={SideBarSettingAction.VERSION}
          style={{ pointerEvents: "none" }}
        >
          {userInfo.version}
        </Menu.Item>
      </Menu>
    );

    const CollapsedComponent = isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined;

    return (
      <div
        style={{ width: `${this.state.sidebarWidth}px` }}
        className={`sidebar-container ${className}${isCollapsed ? " collapsed" : ""}`}
      >
        <div
          className="sidebar-drag-handle"
          onDrag={this.onDragSidebar}
          draggable
          onDragEnd={this.onDragEndSidebar}
        />
        <div className="sidebar-logo-container">
          <img
            src={isSidebarUnderMediumBreakpoint ? LightupIconOnlyLogo : LightupLogo}
            alt="fireSpot"
          />
        </div>
        {hasPermission(userPermissions, URIPathPermissions[URIPath.ADMIN]) && (
          <SidebarMenuItemNode
            type={SideBarMenuItemNodeType.ADMIN}
            icon={AdminIcon}
            label={"Admin"}
            currentSelectNode={currentSelectNode}
            onClick={this.onSideBarMenuItemClick}
            collapsed={isCollapsed}
          />
        )}

        {!isCollapsed && <div className="sidebar-workspaces-header">Workspaces</div>}

        {hasPermission(
          userPermissions,
          URIPathPermissions[URIPath.CREATE_WORKSPACE]
        ) && (
          <AddWorkspaceItem
            collapsed={isCollapsed || isSidebarUnderMediumBreakpoint}
            onClick={this.onAddWorkspaceClick}
          />
        )}
        <div className="sidebar-workspace-list-container">
          {workspaceList.map((currentWorkspace) => (
            <WorkspaceNode
              workspaceUserPermissions={
                workspaceUserPermissionMap[currentWorkspace.uuid] &&
                workspaceUserPermissionMap[currentWorkspace.uuid].data
                  ? workspaceUserPermissionMap[currentWorkspace.uuid].data
                  : { isSuperuser: false, permissions: [] }
              }
              type={SideBarMenuItemNodeType.WORKSPACE}
              key={currentWorkspace.uuid}
              workspace={currentWorkspace}
              expand={!!expandWorkspaceMap[currentWorkspace.uuid]}
              currentSelectNode={currentSelectNode}
              onClick={this.onWorkspaceNodeClick}
              onExpandClick={this.onWorkspaceNodeExpandClick.bind(
                this,
                currentWorkspace.uuid
              )}
              collapsed={isCollapsed}
            />
          ))}
        </div>

        <div
          className={`sidebar-username-container  ${
            isCollapsed ? " sidebar-username-container--collapsed" : ""
          }`}
        >
          <Avatar username={username} />
          {!isCollapsed && (
            <span className="sidebar-username-info-container">{username}</span>
          )}
        </div>
        <div className="sidebar-setting-container">
          <CollapsedComponent
            style={{ color: "#FFFFFF" }}
            onClick={this.onToggleCollapsed}
          />
          <Dropdown
            overlay={settingMenu}
            trigger={["click"]}
            placement={"topLeft"}
            overlayClassName="sidebar-dropdown-overlay-container"
            align={
              isCollapsed
                ? undefined
                : {
                    offset: [-170, -5],
                  }
            }
          >
            <SettingOutlined style={{ color: "#FFFFFF" }} />
          </Dropdown>
        </div>
        {this.state.isApiKeyDialogOpen && (
          <UserAPIKeyDialog
            userAPIKeyList={this.props.userAPIKeyList}
            modalIsOpen={this.state.isApiKeyDialogOpen}
            setIsOpen={(isApiKeyDialogOpen) => {
              this.setState({ isApiKeyDialogOpen });
              this.props.resetCurrentAddedUserAPIKey();
            }}
            currentAddedUserAPIKey={this.props.currentAddedUserAPIKey}
            addUserAPIKey={this.props.addUserAPIKey}
            deleteUserAPIKey={this.props.deleteUserAPIKey}
            updateUserAPIKeyActiveStatus={this.props.updateUserAPIKeyActiveStatus}
            resetCurrentAddedUserAPIKey={this.props.resetCurrentAddedUserAPIKey}
          />
        )}
        {this.state.isSupportDialogOpen && (
          <UserSupportDialog
            modalIsOpen={this.state.isSupportDialogOpen}
            setIsOpen={(isSupportDialogOpen) => {
              this.setState({ isSupportDialogOpen });
            }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(SideBar);
