import * as types from "./dashboard-action-types";
import { getIncidentListPromise } from "../../rest/incident";
import {
  getDashboardChartListPromise,
  addDashboardChartPromise,
  updateDashboardChartPromise,
  deleteDashboardPromise,
  getDashboardDqSummaryPromise,
  updateDashboardConfigPromise,
  getDashboardConfigPromise,
  getDashboardIncidentSummaryPromise,
} from "../../rest/dashboard-chart";

function setDashboardChartList(dashboardChartList) {
  return { type: types.SET_DASHBOARD_CHART_LIST, dashboardChartList };
}

function setDashboardIncidentListSetPerChart(dashboardIncidentListSetPerChart) {
  return {
    type: types.SET_DASHBOARD_INCIDENT_LIST_SET_PER_CHART,
    dashboardIncidentListSetPerChart,
  };
}

function setDashboardDqSummary(dqSummary) {
  return { type: types.SET_DASHBOARD_DQ_SUMMARY, dqSummary };
}

function setDashboardConfig(config) {
  return { type: types.SET_DASHBOARD_CONFIG, config };
}

function setDashboardIncidentSummary(incidentSummary) {
  return { type: types.SET_DASHBOARD_INCIDENT_SUMMARY, incidentSummary };
}

export function getDashBoardChartList(workspaceUuid) {
  return (dispatch, _getState) => {
    dispatch(setDashboardChartList({ loading: true, data: [] }));
    getDashboardChartListPromise(workspaceUuid)
      .then(function (data) {
        dispatch(setDashboardChartList({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setDashboardChartList({ loading: false, data: [] }));
        console.log(`Fail to get dashboard-chart for ${err}`);
      });
  };
}

export function addDashboardChartConfig(workspaceUuid, dashboardChartConfig) {
  return (dispatch, getState) => {
    addDashboardChartPromise(workspaceUuid, dashboardChartConfig)
      .then(function (newDashboardChart) {
        const { dashboardChartList } = getState().dashboardReducer;
        dashboardChartList.data.push(newDashboardChart);
        dispatch(
          setDashboardChartList({
            loading: false,
            data: dashboardChartList.data,
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to create dashboard-chart for ${err}`);
      });
  };
}

export function updateDashboardChartConfig(workspaceUuid, dashboardChartConfig) {
  return (dispatch, getState) => {
    updateDashboardChartPromise(
      workspaceUuid,
      dashboardChartConfig.metadata.uuid,
      dashboardChartConfig
    )
      .then(function (updatedDashboardChart) {
        const { dashboardChartList } = getState().dashboardReducer;
        const newDashboardList = dashboardChartList.data.map(
          (currentDashboardChartConfig) => {
            return currentDashboardChartConfig.metadata.uuid ===
              dashboardChartConfig.metadata.uuid
              ? updatedDashboardChart
              : currentDashboardChartConfig;
          }
        );
        dispatch(setDashboardChartList({ loading: false, data: newDashboardList }));
      })
      .catch(function (error) {
        console.log(`Fail to delete dashboard chart for ${error}`);
      });
  };
}

export function deleteDashboardChartConfig(workspaceUuid, dashboardChartConfig) {
  return (dispatch, getState) => {
    deleteDashboardPromise(workspaceUuid, dashboardChartConfig.metadata.uuid)
      .then(function (_response) {
        const { dashboardChartList } = getState().dashboardReducer;
        const newDashboardList = dashboardChartList.data.filter(
          (currentDashboardChartConfig) => {
            return (
              currentDashboardChartConfig.metadata.uuid !==
              dashboardChartConfig.metadata.uuid
            );
          }
        );
        dispatch(setDashboardChartList({ loading: false, data: newDashboardList }));
      })
      .catch(function (error) {
        console.log(`Fail to delete dashboard chart for ${error}`);
      });
  };
}

function updateDashboardIncidentListPerChart(dispatch, getState, chartUuid, result) {
  const { dashboardIncidentListSetPerChart } = getState().dashboardReducer;
  dispatch(
    setDashboardIncidentListSetPerChart({
      ...dashboardIncidentListSetPerChart,
      [chartUuid]: result,
    })
  );
}

export function getDashboardIncidentListPerChart(
  workspaceUuid,
  chartUuid,
  queryObject
) {
  return (dispatch, getState) => {
    const { dashboardIncidentListSetPerChart } = getState().dashboardReducer;
    if (
      dashboardIncidentListSetPerChart &&
      dashboardIncidentListSetPerChart[chartUuid] &&
      dashboardIncidentListSetPerChart[chartUuid].abortController
    ) {
      dashboardIncidentListSetPerChart[chartUuid].abortController.abort();
      dashboardIncidentListSetPerChart[chartUuid].abortController = null;
    }

    const abortController = new AbortController();
    updateDashboardIncidentListPerChart(dispatch, getState, chartUuid, {
      loading: true,
      data: [],
      abortController,
    });
    getIncidentListPromise(workspaceUuid, queryObject, true, {
      signal: abortController.signal,
    })
      .then(function (incidentList) {
        updateDashboardIncidentListPerChart(dispatch, getState, chartUuid, {
          loading: false,
          data: incidentList,
          abortController: null,
        });
      })
      .catch(function (err) {
        if (err.message === "canceled") {
          console.log(`Previous request for ${chartUuid} is canceled`);
          return;
        }
        updateDashboardIncidentListPerChart(dispatch, getState, chartUuid, {
          loading: false,
          data: [],
          abortController: null,
        });
        console.log(`Fail to get incident list for chart ${chartUuid} ${err}`);
      });
  };
}

export function getDashboardDqSummary(workspaceUuid, startTs, endTs) {
  return (dispatch, _getState) => {
    dispatch(setDashboardDqSummary({ loading: true, data: {} }));
    getDashboardDqSummaryPromise(workspaceUuid, startTs, endTs)
      .then(function (data) {
        dispatch(setDashboardDqSummary({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setDashboardDqSummary({ loading: false, data: {} }));
        console.log(`Failed to get DQ summary: ${err}`);
      });
  };
}

export function getDashboardConfiguration(workspaceUuid) {
  return (dispatch, _getState) => {
    dispatch(setDashboardConfig({ loading: true, data: {} }));
    getDashboardConfigPromise(workspaceUuid)
      .then(function (data) {
        dispatch(setDashboardConfig({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setDashboardConfig({ loading: false, data: {} }));
        console.log(`Failed to get dashboard configuration: ${err}`);
      });
  };
}

export function updateDashboardConfiguration(workspaceUuid, dashboardConfig) {
  return (dispatch, _getState) => {
    return updateDashboardConfigPromise(workspaceUuid, dashboardConfig)
      .then(function (data) {
        dispatch(setDashboardConfig({ loading: false, data }));
      })
      .catch(function (err) {
        console.log(`Failed to update dashboard configuration: ${err}`);
      });
  };
}

export function getDashboardIncidentSummary(workspaceUuid, startTs, endTs) {
  return (dispatch, _getState) => {
    dispatch(setDashboardIncidentSummary({ loading: true, data: {} }));
    getDashboardIncidentSummaryPromise(workspaceUuid, startTs, endTs)
      .then(function (data) {
        dispatch(setDashboardIncidentSummary({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setDashboardIncidentSummary({ loading: false, data: {} }));
        console.log(`Failed to get incident summary: ${err}`);
      });
  };
}
