import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import ProfilerEnableView from "../profiler-enable-view";
import { ProfileTableIcon } from "../icons";
import ProfilerDataProfileColumnView from "../profiler-data-profile-column-view";
import {
  EVENT,
  PAGE,
  getColumnDetailProps,
  trackEvent,
} from "../../../utils/telemetry";

export default function ProfilerColumnDataProfileTab(props) {
  const { columnInfo, onGoToTable, canModifySettings, ...otherProperties } = props;

  const handleClick = () => {
    trackEvent(EVENT.SELECT_TABLE_IN_EXPLORER, {
      ...getColumnDetailProps(
        columnInfo,
        otherProperties.schema,
        otherProperties.dataSource
      ),
      page: PAGE.EXPLORER_TABLE_AUTO_METRICS,
    });

    if (onGoToTable) onGoToTable();
  };

  if (!columnInfo?.profilerConfig?.dataProfiler?.enabled) {
    return canModifySettings ? (
      <ProfilerEnableView
        icon={<ProfileTableIcon width={48} height={48} />}
        description={
          "enable data profile at the table level to access it on this column"
        }
        onClick={handleClick}
        buttonContent={
          <>
            Go To Table <ArrowRightOutlined />
          </>
        }
      />
    ) : null;
  }

  return (
    <ProfilerDataProfileColumnView
      level="column"
      columnInfo={columnInfo}
      {...otherProperties}
    />
  );
}
