import React from "react";
import { ErrorIcon } from "../../components/icons/status";
import NgTooltip from "../../components/tooltip/ng-tooltip";
import { ScannedStatus } from "../../utils/enums";
import { NextGenPalette } from "../../utils/color";

import "./profiler-scan-status.scss";

export const ProfilerTableScanFailureReason =
  "Table scan error - please check source credentials and table permissions";

export function ProfilerScanStatus(props) {
  const { lastScannedStatus, lastScannedFailedReason } = props;
  if (lastScannedStatus !== ScannedStatus.FAILED) {
    return null;
  }

  const scannedStatusTitleComponent = (
    <div className="profiler-scan-status-container">
      <ErrorIcon />
      Error
    </div>
  );

  if (!lastScannedFailedReason) {
    return scannedStatusTitleComponent;
  }

  return (
    <NgTooltip
      title={lastScannedFailedReason}
      shadow={false}
      placement={"bottomLeft"}
      overlayInnerStyle={{
        fontSize: "12px",
        lineHeight: "18px",
        color: NextGenPalette.criticalRed,
      }}
    >
      {scannedStatusTitleComponent}
    </NgTooltip>
  );
}
