import React, { Component } from "react";

// TODO (sergey): Improve UI
class Forbidden extends Component {
  render() {
    return <div>Access to this URL is forbidden. Contact your administrator.</div>;
  }
}

export default Forbidden;
