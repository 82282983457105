import { connect } from "react-redux";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import {
  getIncidentList,
  updateIncidentPaginationParams,
  updateHomeIncidentStatus,
  updateHomeIncidentValidationStatus,
  updateIncidentListUserSetting,
  getIncidentListPageConfiguration,
  updateIncidentListPageConfiguration,
} from "../../actions/incident/incident-action";
import IncidentList from "./incident-list";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  loading: state.incidentReducer.incidentList.loading,
  incidentList: state.incidentReducer.incidentList.data,
  paginationParams: state.incidentReducer.incidentPaginationParams,
  incidentListUserSetting: state.incidentReducer.incidentListUserSetting,
  incidentListPageConfiguration: state.incidentReducer.incidentListPageConfiguration,
  dataSourceList: state.dataSourceReducer.dataSourceList,
});

const mapDispatchToProps = (dispatch) => ({
  getIncidentList: (workspaceUuid, queryObject) =>
    dispatch(getIncidentList(workspaceUuid, queryObject)),
  updatePaginationParams: (paginationParams) =>
    dispatch(updateIncidentPaginationParams(paginationParams)),
  updateHomeIncidentStatus: (workspaceUuid, incidentList, status) =>
    dispatch(updateHomeIncidentStatus(workspaceUuid, incidentList, status)),
  updateHomeIncidentValidationStatus: (workspaceUuid, incidentList, validation) =>
    dispatch(
      updateHomeIncidentValidationStatus(workspaceUuid, incidentList, validation)
    ),
  updateIncidentListUserSetting: (incidentListUserSetting) =>
    dispatch(updateIncidentListUserSetting(incidentListUserSetting)),
  getIncidentListPageConfiguration: (workspaceUuid) =>
    dispatch(getIncidentListPageConfiguration(workspaceUuid)),
  updateIncidentListPageConfiguration: (workspaceUuid, pageConfiguration) =>
    dispatch(updateIncidentListPageConfiguration(workspaceUuid, pageConfiguration)),
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentList);
