import React from "react";

export function PausedGovernanceIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <g opacity="0.85">
        <path
          d="M1.1665 2.62508C1.1665 1.81967 1.81942 1.16675 2.62484 1.16675H11.3748C12.1803 1.16675 12.8332 1.81967 12.8332 2.62508V7.00008C12.8332 7.32225 12.572 7.58341 12.2498 7.58341C11.9277 7.58341 11.6665 7.32225 11.6665 7.00008V2.62508C11.6665 2.46399 11.5359 2.33341 11.3748 2.33341H2.62484C2.46376 2.33341 2.33317 2.464 2.33317 2.62508V11.3751C2.33317 11.5362 2.46375 11.6667 2.62484 11.6667H6.99984C7.322 11.6667 7.58317 11.9279 7.58317 12.2501C7.58317 12.5722 7.322 12.8334 6.99984 12.8334H2.62484C1.81943 12.8334 1.1665 12.1805 1.1665 11.3751V2.62508Z"
          fill="#121224"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99984 9.33341C6.99984 8.04475 8.04451 7.00008 9.33317 7.00008C10.6218 7.00008 11.6665 8.04475 11.6665 9.33341C11.6665 9.67414 11.5935 9.99782 11.4622 10.2896L12.6142 11.2113C12.8658 11.4125 12.9066 11.7796 12.7053 12.0312C12.5041 12.2827 12.137 12.3235 11.8854 12.1223L10.733 11.2004C10.3431 11.4932 9.85839 11.6667 9.33317 11.6667C8.04451 11.6667 6.99984 10.6221 6.99984 9.33341Z"
          fill="#121224"
        />
        <path
          d="M3.49984 4.66675C3.49984 4.34458 3.761 4.08341 4.08317 4.08341H9.9165C10.2387 4.08341 10.4998 4.34458 10.4998 4.66675C10.4998 4.98891 10.2387 5.25008 9.9165 5.25008H4.08317C3.761 5.25008 3.49984 4.98891 3.49984 4.66675Z"
          fill="#121224"
        />
        <path
          d="M4.08317 6.41675C3.761 6.41675 3.49984 6.67791 3.49984 7.00008C3.49984 7.32225 3.761 7.58341 4.08317 7.58341H6.4165C6.73867 7.58341 6.99984 7.32225 6.99984 7.00008C6.99984 6.67791 6.73867 6.41675 6.4165 6.41675H4.08317Z"
          fill="#121224"
        />
      </g>
    </svg>
  );
}

export function PausedSystemIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.021 1.16675C8.34316 1.16675 8.60433 1.42792 8.60433 1.75008V4.08341C8.60433 4.40558 8.34316 4.66675 8.021 4.66675C7.69883 4.66675 7.43766 4.40558 7.43766 4.08341V3.50008H1.60433C1.28216 3.50008 1.021 3.23891 1.021 2.91675C1.021 2.59458 1.28216 2.33341 1.60433 2.33341H7.43766V1.75008C7.43766 1.42792 7.69883 1.16675 8.021 1.16675ZM9.771 2.91675C9.771 2.59458 10.0322 2.33341 10.3543 2.33341H12.1043C12.4265 2.33341 12.6877 2.59458 12.6877 2.91675C12.6877 3.23891 12.4265 3.50008 12.1043 3.50008H10.3543C10.0322 3.50008 9.771 3.23891 9.771 2.91675ZM6.271 5.25008C6.59316 5.25008 6.85433 5.51125 6.85433 5.83341V6.41675H12.6877C13.0098 6.41675 13.271 6.67791 13.271 7.00008C13.271 7.32225 13.0098 7.58341 12.6877 7.58341H6.85433V8.16675C6.85433 8.48891 6.59316 8.75008 6.271 8.75008C5.94883 8.75008 5.68766 8.48891 5.68766 8.16675V5.83341C5.68766 5.51125 5.94883 5.25008 6.271 5.25008ZM1.021 7.00008C1.021 6.67791 1.28216 6.41675 1.60433 6.41675H3.93766C4.25983 6.41675 4.521 6.67791 4.521 7.00008C4.521 7.32225 4.25983 7.58341 3.93766 7.58341H1.60433C1.28216 7.58341 1.021 7.32225 1.021 7.00008ZM8.021 9.33341C8.34316 9.33341 8.60433 9.59458 8.60433 9.91675V12.2501C8.60433 12.5722 8.34316 12.8334 8.021 12.8334C7.69883 12.8334 7.43766 12.5722 7.43766 12.2501V11.6667H1.60433C1.28216 11.6667 1.021 11.4056 1.021 11.0834C1.021 10.7612 1.28216 10.5001 1.60433 10.5001H7.43766V9.91675C7.43766 9.59458 7.69883 9.33341 8.021 9.33341ZM9.771 11.0834C9.771 10.7612 10.0322 10.5001 10.3543 10.5001H12.1043C12.4265 10.5001 12.6877 10.7612 12.6877 11.0834C12.6877 11.4056 12.4265 11.6667 12.1043 11.6667H10.3543C10.0322 11.6667 9.771 11.4056 9.771 11.0834Z"
        fill="#121224"
      />
    </svg>
  );
}

export function PausedScheduleIcon(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9585 0.583252C5.28066 0.583252 5.54183 0.844419 5.54183 1.16659V1.74992H8.4585V1.16659C8.4585 0.844419 8.71966 0.583252 9.04183 0.583252C9.364 0.583252 9.62516 0.844419 9.62516 1.16659V1.74992H12.8335C13.1557 1.74992 13.4168 2.01109 13.4168 2.33325V12.8333C13.4168 13.1554 13.1557 13.4166 12.8335 13.4166H1.16683C0.844663 13.4166 0.583496 13.1554 0.583496 12.8333V2.33325C0.583496 2.01109 0.844663 1.74992 1.16683 1.74992H4.37516V1.16659C4.37516 0.844419 4.63633 0.583252 4.9585 0.583252ZM4.37516 2.91659H1.75016V12.2499H12.2502V2.91659H9.62516V3.49992C9.62516 3.82208 9.364 4.08325 9.04183 4.08325C8.71966 4.08325 8.4585 3.82208 8.4585 3.49992V2.91659H5.54183V3.49992C5.54183 3.82208 5.28066 4.08325 4.9585 4.08325C4.63633 4.08325 4.37516 3.82208 4.37516 3.49992V2.91659ZM2.91683 5.83325C2.91683 5.51109 3.178 5.24992 3.50016 5.24992H5.8335C6.15566 5.24992 6.41683 5.51109 6.41683 5.83325V9.91659C6.41683 10.2388 6.15566 10.4999 5.8335 10.4999H3.50016C3.178 10.4999 2.91683 10.2388 2.91683 9.91659C2.91683 9.59442 3.178 9.33325 3.50016 9.33325H5.25016V8.45825H4.0835C3.76133 8.45825 3.50016 8.19709 3.50016 7.87492C3.50016 7.55275 3.76133 7.29159 4.0835 7.29159H5.25016V6.41659H3.50016C3.178 6.41659 2.91683 6.15542 2.91683 5.83325ZM7.5835 5.83325C7.5835 5.51109 7.84466 5.24992 8.16683 5.24992H10.5002C10.8223 5.24992 11.0835 5.51109 11.0835 5.83325V9.91659C11.0835 10.2388 10.8223 10.4999 10.5002 10.4999H8.16683C7.84466 10.4999 7.5835 10.2388 7.5835 9.91659V5.83325ZM8.75016 6.41659V9.33325H9.91683V6.41659H8.75016Z"
        fill="#121224"
      />
    </svg>
  );
}
