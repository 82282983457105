import React from "react";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { NextGenPalette } from "../../utils/color";
import { getDropdownItems } from "../../components/entity-list/menu-utils";

export const ScheduleActions = Object.freeze({
  VIEW_EDIT: "viewEdit",
  DELETE: "delete",
});

function ScheduleActionsMenu(props) {
  const {
    trigger,
    workspaceUuid,
    workspaceUserPermissions,
    schedules,
    onEditScheduleClick,
    onDeleteScheduleClick,
    loading = false,
    getPopupContainer,
  } = props;

  const viewSchedulePermissions = [
    AppPermissions.BACKEND_APPS_SCHEDULE_VIEWS_VIEW_SCHEDULEDETAIL,
  ];

  const modifySchedulePermissions = [
    AppPermissions.BACKEND_APPS_SCHEDULE_VIEWS_EDIT_SCHEDULEDETAIL,
  ];

  const canModifySchedule = hasPermission(
    workspaceUserPermissions,
    modifySchedulePermissions
  );

  const menuConfig = [
    {
      action: ScheduleActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewSchedulePermissions,
      item: {
        label: canModifySchedule ? "Edit" : "View",
        icon: <EditOutlined />,
        onClick: () => onEditScheduleClick(workspaceUuid, schedules[0]),
      },
    },
    {
      action: ScheduleActions.DELETE,
      supportsMultiple: false,
      permissions: modifySchedulePermissions,
      item: {
        label: <span style={{ color: NextGenPalette.criticalRed }}>Delete</span>,
        icon: <DeleteOutlined style={{ color: NextGenPalette.criticalRed }} />,
        onClick: () => onDeleteScheduleClick(workspaceUuid, schedules[0]),
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    schedules,
    Object.values(ScheduleActions),
    workspaceUserPermissions
  );

  return (
    <NgDropdownMenu
      trigger={trigger}
      menuItems={menuItems}
      position="bottomLeft"
      buttonProps={{ disabled: loading }}
      getPopupContainer={getPopupContainer}
    />
  );
}

export default ScheduleActionsMenu;
