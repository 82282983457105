import { MetricDataAssetType } from "../../../../../utils/enums";
import { DataAssetCards } from "../../../fields";
import AddTags from "../../../fields/add-tags";
import ConfigureMetric from "../../../fields/configure-metric";
import DataAssetSelect from "../../../fields/data-asset-select";
import MetricFailingRecordsQueryConfigItem from "../../../fields/metric-failing-records-query-config-item";

function MetadataMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    onDataSourceChanged,
    tableList,
    schemaList,
    onTableChanged,
    columnList: { data: columnList },
    columnValueList,
    openWorkspaceTakeover,
    closeTakeover,
    partitionSampleData,
    getPartitionSampleData,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult = { loading: false },
    resetKPIStatus,
    tagList,
    waffle,
    disabled = false,
  } = props;

  const isEditMode = !configData.config.inheritTableSettings;
  const isStreaming = configData.config.isStreaming;

  return (
    <DataAssetCards>
      <DataAssetSelect
        dataAssetType={MetricDataAssetType.TABLE}
        configData={configData}
        dataSourceList={dataSourceList}
        schemaList={schemaList}
        tableList={tableList}
        isStreaming={isStreaming}
        resetKPIStatus={resetKPIStatus}
        onConfigDataChange={onConfigDataChange}
        onDataSourceChanged={onDataSourceChanged}
        onTableChanged={onTableChanged}
        disabled
      />
      <ConfigureMetric
        isValidConfig={isValidConfig}
        enableSeasonality={true}
        enableSlicing={false}
        enableQueryScope={false}
        enableDataCollectionSchedule={false}
        enablePartitionConfig={false}
        allowOverride={false}
        configData={configData}
        isStreaming={isStreaming}
        dataSourceList={dataSourceList}
        tableList={tableList}
        columnList={columnList}
        columnValueList={columnValueList}
        isEditMode={isEditMode}
        onConfigDataChange={onConfigDataChange}
        openWorkspaceTakeover={openWorkspaceTakeover}
        closeTakeover={closeTakeover}
        partitionSampleData={partitionSampleData}
        getPartitionSampleData={getPartitionSampleData}
        waffle={waffle}
        enableMissingValueFilling={false}
        disabled={disabled}
      />
      <MetricFailingRecordsQueryConfigItem
        configData={configData}
        value={configData.config.failingRecordsSql}
        validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
        validateFailingRecordsSqlResult={validateFailingRecordsSqlResult}
        onChange={(newFailingRecordsSql) => {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              failingRecordsSql: newFailingRecordsSql,
            },
          });
        }}
        disabled={disabled}
      />
      <AddTags
        configData={configData}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
        disabled={disabled}
      />
    </DataAssetCards>
  );
}

MetadataMetricDataAssetConfig.assetMeta = {
  title: "Select data asset",
  createDescription:
    "Create your metric by selecting the type, naming it, picking a data asset, and configuring the settings",
  editDescription:
    "Edit your metric by naming it, picking a data asset, and configuring the settings",
};

export default MetadataMetricDataAssetConfig;
