import { hasPermission } from "../../utils/uri-path";

import "./menu-utils.scss";

export function getDropdownItems(
  menuConfig,
  entities,
  actions,
  workspaceUserPermissions
) {
  const multipleEntities = entities.length > 1;
  return menuConfig
    .filter((configItem) => {
      const actionEnabled = !configItem.action || actions.includes(configItem.action);
      const canPerform =
        !configItem.permissions ||
        hasPermission(workspaceUserPermissions, configItem.permissions);
      const multipleSupported = !multipleEntities || configItem.supportsMultiple;
      const validEntityTarget =
        !configItem.validTarget || entities.some(configItem.validTarget);
      return actionEnabled && canPerform && multipleSupported && validEntityTarget;
    })
    .map((configItem) => {
      const validEntityTargets = configItem.validTarget
        ? entities.filter(configItem.validTarget)
        : entities;
      return {
        ...configItem.item,
        label: multipleEntities ? (
          <div className="menu-item-multiple-targets-label">
            <div>{configItem.item.label}</div>
            <div>({validEntityTargets.length})</div>
          </div>
        ) : (
          configItem.item.label
        ),
        onClick: () => configItem.item.onClick(validEntityTargets),
      };
    });
}
