import React, { Component } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import PropTypes from "prop-types";

class TableRow extends Component {
  render() {
    let rowProps = {};

    if (this.props.rowConfig && this.props.rowConfig.getProps) {
      rowProps = this.props.rowConfig.getProps(this.props.data, this.props.rowIndex);
    }

    if (this.props.onMouseEnter) {
      rowProps.onMouseEnter = this.props.onMouseEnter;
    }

    if (this.props.onMouseLeave) {
      rowProps.onMouseLeave = this.props.onMouseLeave;
    }

    var tds = this.props.columns.map(
      function (item, index) {
        var value = this.props.data[item.key];
        if (item.render) {
          value = item.render(value, this.props.rowIndex, this.props.data);
        }
        return (
          <td key={index} style={item.dataStyle} {...(item.dataProps || {})}>
            {value}
          </td>
        );
      }.bind(this)
    );

    const {
      enableContextMenu = false,
      contextMenuId = "",
      ...rowAttributes
    } = rowProps;
    if (enableContextMenu && contextMenuId) {
      return (
        <ContextMenuTrigger
          renderTag="tr"
          attributes={rowAttributes}
          data={this.props.data}
          id={rowProps.contextMenuId}
          holdToDisplay={1000}
          collect={(props) => {
            return {
              data: props.data,
            };
          }}
        >
          {tds}
        </ContextMenuTrigger>
      );
    } else {
      return <tr {...rowAttributes}>{tds}</tr>;
    }
  }
}

export default class TableBody extends Component {
  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter(rowIndex, ev) {
    const { rowConfig } = this.props;
    if (rowConfig && rowConfig.onMouseEnter) {
      rowConfig.onMouseEnter(rowIndex, ev);
    }
  }

  onMouseLeave(rowIndex, ev) {
    const { rowConfig } = this.props;
    if (rowConfig && rowConfig.onMouseLeave) {
      rowConfig.onMouseLeave(rowIndex, ev);
    }
  }

  render() {
    var bodies = this.props.data.map((item, index) => {
      let mouseEventsHandles = {};
      if (this.props.rowConfig.onMouseEnter) {
        mouseEventsHandles.onMouseEnter = this.onMouseEnter.bind(this, index);
      }

      if (this.props.rowConfig.onMouseLeave) {
        mouseEventsHandles.onMouseLeave = this.onMouseLeave.bind(this, index);
      }

      return (
        <TableRow
          key={index}
          rowIndex={index}
          data={item}
          columns={this.props.columns}
          rowConfig={this.props.rowConfig}
          {...mouseEventsHandles}
        />
      );
    });

    return <tbody>{bodies}</tbody>;
  }
}

TableBody.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  sortings: PropTypes.array.isRequired,
  rowConfig: PropTypes.object.isRequired,
};
