import React, { useState } from "react";
import { getTableMetricsHealthData } from "./utils";
import { ProfilerSummaryTabKey } from "../../utils";
import { MetricType } from "../../../../utils/enums";
import {
  isActivityMetric,
  isAutoCreationTypeMetric,
  isAutoMetric,
  isMetadataMetric,
} from "../../../../utils/metric";
import {
  TableHealthMetricsGridAndTable,
  TableHealthMetricsPanel,
  TableHealthMetricsSubPanel,
  TableHealthTableSummaryPanelMenu,
  TableMetricsInfoGridItemPopupContent,
  getTableMetricsTableColumns,
} from "./table-health-summary-panel-template";
import { toggleTableMetricType } from "../../../../utils/table";
import {
  EVENT,
  PAGE,
  getTableDetailProps,
  trackEvent,
} from "../../../../utils/telemetry";
import { classesName } from "../../../../utils/css";

import "./table-metrics-info-summary.scss";

function TableMetricsInfoSummary(props) {
  const {
    label,
    icon,
    metrics,
    metricInfoMapper = {},
    dataSource,
    schema,
    table,
    workspaceUserPermissions,
    onTabChange,
    onSelectNodeChange,
    onConfigureTable,
    updateTableConfig,
    onCreateCustomMetric,
    onDeleteMetricClick,
    onMonitorMetric,
    setProfilerAfterTreeNavigationAction,
  } = props;

  const [isAutoMetricExpand, setIsAutoMetricExpand] = useState(true);
  const [isCustomMetricExpand, setIsCustomMetricExpand] = useState(true);
  const [focusedItem, setFocusedItem] = useState(null);

  const onIsAutoMetricExpandChange = (newIsExpand) => {
    trackEvent(
      newIsExpand
        ? EVENT.COLLAPSE_TABLE_LEVEL_METRICS
        : EVENT.EXPAND_TABLE_LEVEL_METRICS,
      {
        ...getTableDetailProps(table, schema, dataSource),
        page: PAGE.EXPLORER_TABLE_TABLE_HEALTH,
      }
    );
    setIsAutoMetricExpand(newIsExpand);
  };

  const onIsCustomMetricExpandChange = (newIsExpand) => {
    trackEvent(
      newIsExpand
        ? EVENT.COLLAPSE_TABLE_LEVEL_METRICS
        : EVENT.EXPAND_TABLE_LEVEL_METRICS,
      {
        ...getTableDetailProps(table, schema, dataSource),
        page: PAGE.EXPLORER_TABLE_TABLE_HEALTH,
      }
    );
    setIsCustomMetricExpand(newIsExpand);
  };

  const actions = {
    onViewMetric: (metric) => {
      if (
        isActivityMetric(metric) ||
        isAutoMetric(metric) ||
        isMetadataMetric(metric)
      ) {
        onTabChange(ProfilerSummaryTabKey.AUTO_METRICS);
      } else {
        onSelectNodeChange({
          dataSource,
          schemaInfo: schema,
          tableInfo: table,
          columnInfo: null,
          metric: {
            metricType: MetricType.CUSTOM,
            name: metric.metadata.name,
            uuid: metric.metadata.uuid,
          },
        });
      }
    },
    onConfigureTable,
    onMonitorMetric: (metric) => {
      onMonitorMetric(metric);
    },
    onToggleMetric: (metricType, metric, _columnUuid, enabled) => {
      if (metric && !isActivityMetric(metric) && !isAutoCreationTypeMetric(metric)) {
        onDeleteMetricClick(metric);
      } else {
        updateTableConfig(toggleTableMetricType(table, metricType, enabled));
      }
    },
  };

  const columns = getTableMetricsTableColumns(
    workspaceUserPermissions,
    table,
    actions,
    false
  );

  function rowBelongsInAutoMetricsSubPanel(row) {
    return isAutoCreationTypeMetric(row.metric) || row.configurableType;
  }

  const normalizedData = getTableMetricsHealthData(metrics || [], metricInfoMapper);
  const configuredData = normalizedData.filter((row) => !row.configurableType);
  const autoMetricsData = normalizedData.filter(rowBelongsInAutoMetricsSubPanel);
  const configuredAutoMetricsData = autoMetricsData.filter(
    (row) => !row.configurableType
  );
  const customMetricsData = normalizedData.filter(
    (row) => !rowBelongsInAutoMetricsSubPanel(row)
  );

  const dividerClassName = classesName(
    "table-info-summary-subpanel-divider",
    isAutoMetricExpand && "auto-metrics-expanded"
  );

  const contextMenu = (
    <TableHealthTableSummaryPanelMenu
      dataSource={dataSource}
      schema={schema}
      table={table}
      workspaceUserPermissions={workspaceUserPermissions}
      onCreateCustomMetric={onCreateCustomMetric}
      setProfilerAfterTreeNavigationAction={setProfilerAfterTreeNavigationAction}
    />
  );

  return (
    <TableHealthMetricsPanel
      data={configuredData}
      entity="table"
      label={label}
      icon={icon}
      contextMenu={contextMenu}
    >
      <div className="table-info-summary-subpanel">
        <TableHealthMetricsSubPanel
          data={configuredAutoMetricsData}
          entity="metric"
          label="Auto Metrics"
          isExpand={isAutoMetricExpand}
          onIsExpandChange={
            autoMetricsData.length > 0 ? onIsAutoMetricExpandChange : null
          }
        >
          <TableHealthMetricsGridAndTable
            isExpand={isAutoMetricExpand}
            focusedItem={focusedItem}
            onFocusedItemChange={setFocusedItem}
            columns={columns}
            data={autoMetricsData}
            tooltipComponent={TableMetricsInfoGridItemPopupContent}
          />
        </TableHealthMetricsSubPanel>
        <div className={dividerClassName} />
        <TableHealthMetricsSubPanel
          data={customMetricsData}
          entity="metric"
          label="User Defined Metrics"
          isExpand={isCustomMetricExpand}
          onIsExpandChange={
            customMetricsData.length > 0 ? onIsCustomMetricExpandChange : null
          }
        >
          <TableHealthMetricsGridAndTable
            isExpand={isCustomMetricExpand}
            focusedItem={focusedItem}
            onFocusedItemChange={setFocusedItem}
            columns={columns}
            data={customMetricsData}
            tooltipComponent={TableMetricsInfoGridItemPopupContent}
          />
        </TableHealthMetricsSubPanel>
      </div>
    </TableHealthMetricsPanel>
  );
}

export default TableMetricsInfoSummary;
