import React from "react";
import ScheduleBasicDialog from "./schedule-basic-dialog";

function EditScheduleDialog(props) {
  const { onEdit, data, modalIsOpen, setIsOpen, enableSave } = props;

  const onOkClicked = (newData) => {
    onEdit(newData);
  };

  return (
    <ScheduleBasicDialog
      defaultData={data}
      className={"edit-schedule-dialog-container"}
      dialogTitle={"Edit schedule"}
      onOkClicked={onOkClicked}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enableSave={enableSave}
    />
  );
}

export default EditScheduleDialog;
