import React from "react";

export function UpArrowIcon(props) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43907 2.81445C4.82815 2.55047 5.36203 2.59086 5.7068 2.93563L8.53523 5.76406C8.92576 6.15458 8.92576 6.78775 8.53523 7.17827C8.14471 7.5688 7.51154 7.5688 7.12102 7.17827L4.9997 5.05695L2.87835 7.1783C2.48782 7.56882 1.85466 7.56882 1.46413 7.1783C1.07361 6.78777 1.07361 6.15461 1.46413 5.76408L4.29256 2.93566C4.29745 2.93077 4.30237 2.92594 4.30734 2.92118C4.34884 2.88132 4.39294 2.84575 4.43907 2.81445Z"
        fill="#121224"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export function DownArrowIcon(props) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56093 7.18555C5.17185 7.44953 4.63797 7.40914 4.2932 7.06437L1.46477 4.23594C1.07424 3.84542 1.07424 3.21225 1.46477 2.82173C1.85529 2.4312 2.48846 2.4312 2.87898 2.82173L5.0003 4.94305L7.12165 2.8217C7.51218 2.43118 8.14534 2.43118 8.53587 2.8217C8.92639 3.21223 8.92639 3.84539 8.53587 4.23592L5.70744 7.06434C5.70255 7.06923 5.69763 7.07406 5.69266 7.07882C5.65116 7.11868 5.60706 7.15425 5.56093 7.18555Z"
        fill="#121224"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export function RightArrowIcon(props) {
  return (
    <svg width="10" height="10" viewBox="0 0 9 10" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68506 4.43943C6.94904 4.82852 6.90865 5.3624 6.56388 5.70717L3.73545 8.5356C3.34493 8.92612 2.71176 8.92612 2.32124 8.5356C1.93072 8.14507 1.93072 7.51191 2.32124 7.12138L4.44256 5.00006L2.32121 2.87871C1.93069 2.48819 1.93069 1.85502 2.32121 1.4645C2.71174 1.07397 3.3449 1.07397 3.73543 1.4645L6.56385 4.29292C6.56874 4.29781 6.57357 4.30274 6.57833 4.3077C6.61819 4.34921 6.65376 4.3933 6.68506 4.43943Z"
        fill="#121224"
        fillOpacity="0.85"
      />
    </svg>
  );
}
