import React from "react";

import "./step.scss";

function StepItem(props) {
  const {
    index,
    title,
    children,
    description = "",
    className = "",
    skipIndex = false,
  } = props;

  return (
    <div className={`step-item-container  ${className}`}>
      <div className="step-item-title-container">
        {skipIndex ? null : (
          <span className="step-item-index-container">
            <div className="step-item-index-inner-container">{index}</div>
          </span>
        )}
        <span className="step-item-title">{title}</span>
      </div>
      {!description ? null : (
        <div className="step-description-container">{description}</div>
      )}
      <div className="step-item-context-container">{children}</div>
    </div>
  );
}

function StepContainer(props) {
  const { steps, className = "" } = props;
  let indexCounter = 1;
  return (
    <div className={`step-container ${className}`}>
      {steps.map(function (step, index) {
        const {
          title,
          component: childComponent,
          className = "",
          description = "",
          skipIndex = false,
        } = step;

        let currentIndex;
        if (skipIndex) {
          currentIndex = 0;
        } else {
          currentIndex = indexCounter;
          indexCounter += 1;
        }

        return (
          <StepItem
            title={title}
            index={currentIndex}
            key={index}
            className={className}
            skipIndex={skipIndex}
            description={description}
          >
            {childComponent}
          </StepItem>
        );
      })}
    </div>
  );
}

export default StepContainer;
