import { getUnixTime, subDays, subSeconds, fromUnixTime } from "date-fns";
import {
  AutoMetricsType,
  AggregationWindowType,
  FeatureTypeConst,
  MetricType,
  MetricConfigType,
  SymptomTypeConst,
  TableType,
} from "./enums";

import {
  MINUTE_IN_SECONDS,
  HOUR_IN_SECONDS,
  DAY_IN_SECONDS,
  WEEK_IN_SECONDS,
  MONTH_IN_SECONDS,
  QUARTER_IN_SECONDS,
  YEAR_IN_SECONDS,
  getDisplayTimeFromSecond,
} from "./time";
import { isChangeFeatureType } from "./monitor";

const intervalToSecondsMap = {
  [AggregationWindowType.SECOND]: 1,
  [AggregationWindowType.MINUTE]: MINUTE_IN_SECONDS,
  [AggregationWindowType.FIVE_MIN]: 5 * MINUTE_IN_SECONDS,
  [AggregationWindowType.TEN_MIN]: 10 * MINUTE_IN_SECONDS,
  [AggregationWindowType.FIFTEEN_MIN]: 15 * MINUTE_IN_SECONDS,
  [AggregationWindowType.HOUR]: HOUR_IN_SECONDS,
  [AggregationWindowType.DAY]: DAY_IN_SECONDS,
  [AggregationWindowType.WEEK]: WEEK_IN_SECONDS,
  [AggregationWindowType.MONTH]: MONTH_IN_SECONDS,
  [AggregationWindowType.QUARTER]: QUARTER_IN_SECONDS,
  [AggregationWindowType.YEAR]: YEAR_IN_SECONDS,
};

export function getKPIInterval(kpiInfo) {
  if (!kpiInfo) {
    return "";
  }

  const {
    config: { table, aggregation, configType, aggregationWindow },
  } = kpiInfo;

  if (
    [
      MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG,
      MetricConfigType.FULL_COMPARE_METRIC_CONFIG,
    ].includes(configType)
  ) {
    return aggregationWindow || "";
  }

  if (table && table.type === TableType.CUSTOM_SQL && table.aggregationWindow) {
    return table.aggregationWindow;
  }

  if (aggregation && aggregation.aggregationWindow) {
    return aggregation.aggregationWindow;
  }

  return "";
}

export function getKPIGranularity(kpiInfo) {
  const interval = getKPIInterval(kpiInfo);
  return intervalToSecondsMap[interval] || MINUTE_IN_SECONDS;
}

export function getDefaultCoalescingInterval(kpiInfo) {
  if (!kpiInfo) {
    console.log("Metric info is null");
    return 0;
  }

  return getKPIGranularity(kpiInfo);
}

export function getDefaultTitle(symptomType, metricName, featureConfigType = null) {
  let defaultTitleSuffix = "Auto";
  if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS) {
    defaultTitleSuffix = "ValuesOutOfExpectations";
  } else if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA) {
    defaultTitleSuffix = "ValuesOutOfExpectations_Beta";
  } else if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND) {
    defaultTitleSuffix = "ValueOutOfExpectation_Trend";
  } else if (symptomType === SymptomTypeConst.SHARP_CHANGE) {
    defaultTitleSuffix = "SharpChange";
  } else if (symptomType === SymptomTypeConst.SLOW_BURN_TREND_CHANGE) {
    defaultTitleSuffix = "TrendChange";
  } else if (symptomType === SymptomTypeConst.MANUAL_THRESHOLD) {
    defaultTitleSuffix = "ManualThreshold";
    if (featureConfigType === FeatureTypeConst.PERCENTAGE_CHANGE) {
      defaultTitleSuffix += "_%Change";
    } else if (featureConfigType === FeatureTypeConst.CHANGE) {
      defaultTitleSuffix += "_Change";
    }
  }

  return `${metricName}_${defaultTitleSuffix}`;
}

export function getDefaultCloneTitle(ruleTitle) {
  return `${ruleTitle}_Clone`;
}

const defaultLearningPeriodInSecondMapper = {
  [AggregationWindowType.SECOND]: WEEK_IN_SECONDS,
  [AggregationWindowType.MINUTE]: WEEK_IN_SECONDS,
  [AggregationWindowType.HOUR]: WEEK_IN_SECONDS,
  [AggregationWindowType.DAY]: MONTH_IN_SECONDS,
  [AggregationWindowType.WEEK]: 4 * MONTH_IN_SECONDS,
  [AggregationWindowType.MONTH]: 15 * MONTH_IN_SECONDS,
  [AggregationWindowType.QUARTER]: 15 * QUARTER_IN_SECONDS,
  [AggregationWindowType.YEAR]: 15 * YEAR_IN_SECONDS,
};

function getDefaultLearningDurationInSeconds(kpiInfo) {
  let periodInSeconds = 0;
  if (!kpiInfo) {
    periodInSeconds = DAY_IN_SECONDS;
  } else {
    if (
      !kpiInfo.config.seasonality ||
      Object.keys(kpiInfo.config.seasonality).length === 0 ||
      !kpiInfo.config.seasonality.periodInSeconds
    ) {
      const interval = getKPIInterval(kpiInfo);
      periodInSeconds = defaultLearningPeriodInSecondMapper[interval] || DAY_IN_SECONDS;
    } else {
      periodInSeconds =
        15 * (kpiInfo.config.seasonality.periodInSeconds || DAY_IN_SECONDS);
    }
  }

  return periodInSeconds;
}

export function getDefaultLearningDuration(kpiInfo) {
  let periodInSecond = getDefaultLearningDurationInSeconds(kpiInfo);
  return getDisplayTimeFromSecond(periodInSecond, true);
}

export function getDefaultTrainingPeriod(kpiInfo) {
  let periodInSecond = getDefaultLearningDurationInSeconds(kpiInfo);
  // For autometrics.
  let lastEventTs = 0;
  if (kpiInfo.lastEventTs) {
    lastEventTs = kpiInfo.lastEventTs;
  } else if (kpiInfo.status && kpiInfo.status.lastSampleTs) {
    lastEventTs = kpiInfo.status.lastSampleTs;
  }

  if (lastEventTs > 0 && kpiInfo.metadata.creationType === MetricType.AUTO) {
    let endTimestamp = lastEventTs - WEEK_IN_SECONDS;
    if (kpiInfo.config.aggregation.type === AutoMetricsType.DATA_DELAY) {
      // see explanation for the logic below in PR 4258
      endTimestamp = lastEventTs - 2 * HOUR_IN_SECONDS;
    }
    return {
      startTs: endTimestamp - periodInSecond,
      endTs: endTimestamp,
    };
  }

  let endTime = new Date();
  let kpiDelayInSeconds = kpiInfo.config.synchronizationDelay || 0;
  if (kpiDelayInSeconds > 0) {
    endTime = subSeconds(endTime, kpiDelayInSeconds);
  }

  const interval = getKPIInterval(kpiInfo);
  if (interval) {
    const intervalInSeconds = intervalToSecondsMap[interval] || 0;
    // see explanation for the logic below in PR 4258
    endTime = subSeconds(endTime, 2 * intervalInSeconds);
  }

  const startTime = subSeconds(endTime, periodInSecond);
  const startTimestamp = getUnixTime(startTime);
  const endTimestamp = getUnixTime(endTime);

  return {
    startTs: startTimestamp,
    endTs: endTimestamp,
  };
}

export function getDefaultRuleConfig(symptomType, kpiInfo) {
  const basicSymptomConfig = symptomType
    ? getDefaultSymptomConfig(symptomType, kpiInfo)
    : {};

  return {
    apiVersion: "v1",
    type: "rule",
    metadata: {
      name: getDefaultTitle(symptomType, kpiInfo.metadata.name),
    },
    config: {
      metrics: [kpiInfo.metadata.uuid],
      ...basicSymptomConfig,
      isLive: false,
      alertConfig: {
        isMuted: false,
        mutingSchedules: [],
        channels: [],
      },
    },
    status: {
      training: {
        isTrained: false,
      },
    },
  };
}

export function getDefaultSymptomConfig(symptomType, _kpiInfo) {
  if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS) {
    return {
      symptom: {
        type: symptomType,
        featureConfig: {
          type: FeatureTypeConst.VALUE,
        },
      },
    };
  } else if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA) {
    return {
      symptom: {
        type: symptomType,
        featureConfig: {
          type: FeatureTypeConst.FORECAST_ERROR,
        },
      },
    };
  } else if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND) {
    return {
      symptom: {
        type: symptomType,
        featureConfig: {
          type: FeatureTypeConst.PERCENTAGE_CHANGE,
        },
      },
    };
  } else if (symptomType === SymptomTypeConst.SHARP_CHANGE) {
    return {
      symptom: {
        type: symptomType,
        featureConfig: {
          type: FeatureTypeConst.DOUBLE_DERIVATIVE,
        },
      },
    };
  } else if (symptomType === SymptomTypeConst.SLOW_BURN_TREND_CHANGE) {
    return {
      symptom: {
        type: symptomType,
        featureConfig: {
          type: FeatureTypeConst.TREND_COEFFICIENT,
        },
      },
    };
  } else if (symptomType === SymptomTypeConst.MANUAL_THRESHOLD) {
    return {
      symptom: {
        type: symptomType,
        bound: {
          lower: 0,
          upper: 0,
        },
        featureConfig: {
          type: FeatureTypeConst.VALUE,
        },
      },
    };
  }

  console.log(`Unknown symptomType ${symptomType}. Panic now...`);
  return null;
}

export function getDefaultProfilerDisplayPeriod(kpiInfo, endTime = new Date()) {
  const KPIInterval = getKPIInterval(kpiInfo);
  let intervalInDays;
  if (
    KPIInterval === AggregationWindowType.DAY ||
    KPIInterval === AggregationWindowType.HOUR
  ) {
    intervalInDays = 7;
  } else if (KPIInterval === AggregationWindowType.WEEK) {
    intervalInDays = 7 * 7;
  } else {
    console.log(`Unknown metric interval ${KPIInterval}`);
    intervalInDays = 7;
  }

  let diffInMillSeconds = 0;
  if (kpiInfo.status && typeof kpiInfo.status.lastSampleTs === "number") {
    // fromUnixTime will discard decimal values.
    endTime = fromUnixTime(kpiInfo.status.lastSampleTs);
    diffInMillSeconds =
      kpiInfo.status.lastSampleTs - Math.floor(kpiInfo.status.lastSampleTs);
  } else if (
    typeof kpiInfo.config.synchronizationDelay === "number" &&
    kpiInfo.config.synchronizationDelay > 0
  ) {
    endTime = subSeconds(endTime, kpiInfo.config.synchronizationDelay);
  }

  const startTime = subDays(endTime, intervalInDays);
  return {
    startTimestamp: getUnixTime(startTime) + 1,
    endTimestamp: getUnixTime(endTime) + diffInMillSeconds,
  };
}

function getDefaultRuleForProfilerMetrics(symptomTypeConfig, kpiInfo, nameTemplate) {
  const { symptomType, config, alertChannelConfig } = symptomTypeConfig;
  const ruleConfig = getDefaultRuleConfig(symptomType, kpiInfo);
  ruleConfig.metadata.name = getDefaultTitle(symptomType, nameTemplate);
  ruleConfig.config.isLive = true;
  if (symptomType === SymptomTypeConst.MANUAL_THRESHOLD) {
    ruleConfig.config.symptom.featureConfig.type = config.feature;
    ruleConfig.config.symptom.bound = config.bound;
    if (isChangeFeatureType(config.feature)) {
      let windowSize;
      if (config.period === "day") {
        windowSize = DAY_IN_SECONDS;
      } else if (config.period === "week") {
        windowSize = WEEK_IN_SECONDS;
      } else {
        windowSize = getKPIGranularity(kpiInfo);
      }
      ruleConfig.config.symptom.featureConfig.windowSize = windowSize;
    }
  }

  if (alertChannelConfig && alertChannelConfig.channels.length > 0) {
    ruleConfig.config.alertConfig = alertChannelConfig;
  }
  return ruleConfig;
}

export function getDefaultRuleConfigForAutoMetrics(
  symptomTypeConfig,
  autoMetric,
  dataSource,
  tableInfo,
  columnInfo,
  lastEventTs = 0
) {
  const autoMetricType = autoMetric.config.aggregation.type;
  const defaultNameMapper = {
    [AutoMetricsType.MISSING_VALUE]: "Null percent",
    [AutoMetricsType.NUMERICAL_DISTRIBUTION]: "Numerical Distribution",
  };

  const kpiInfo = { ...autoMetric, lastEventTs };
  return getDefaultRuleForProfilerMetrics(
    symptomTypeConfig,
    kpiInfo,
    defaultNameMapper[autoMetricType] || autoMetricType
  );
}

export function getDefaultRuleConfigForCustomMetric(
  symptomTypeConfig,
  kpiInfo,
  lastEventTs
) {
  return getDefaultRuleForProfilerMetrics(
    symptomTypeConfig,
    kpiInfo,
    kpiInfo.metadata.name
  );
}

export function getDefaultConformityConfigForAutoMetric(
  currentDataSourceObject,
  currentTableObject,
  currentColumnObject
) {
  return {
    apiVersion: "v1",
    type: "metric",
    metadata: {
      name: "Conformity Check",
      tags: [],
      creationType: MetricType.CUSTOM,
    },
    config: {
      isLive: true,
      sources: [currentDataSourceObject.metadata.uuid],
      table: {
        type: TableType.TABLE,
        tableName: currentTableObject.tableName,
        schemaName: currentTableObject.schemaName,
        tableUuid: currentTableObject.uuid,
      },
      aggregation: {
        type: AutoMetricsType.CONFORMITY_COUNT,
        conditions: [],
        whereConditions: [],
        whereClause: null,
        aggregationWindow: currentTableObject.profilerConfig.window,
      },
      seasonality: {
        seasonInSeconds: 0,
        periodInSeconds: 0,
      },
      valueColumns: [
        {
          type: "column",
          columnName: currentColumnObject.columnName,
          columnUuid: currentColumnObject.uuid,
        },
      ],
      timestampColumn: currentTableObject.profilerConfig.timestampColumn,
      timestampColumnFunctions:
        currentTableObject.profilerConfig.timestampColumnFunctions || null,
      sliceByColumns: [],
      queryTimezone: currentTableObject.profilerConfig.timezone,
      dataTimezone: currentTableObject.profilerConfig.dataTimezone,
      synchronizationDelay: currentTableObject.profilerConfig.syncDelay || 3600,
      ...(currentTableObject.profilerConfig.partitions
        ? { partitions: currentTableObject.profilerConfig.partitions }
        : {}),
    },
  };
}

export const metricsDataTemplate = {
  duration: {
    startTime: 0,
    endTime: 0,
  },
  loading: false,
  metricsData: null,
  range: {
    metricsRange: {
      min: undefined,
      max: undefined,
    },
    signalRange: {
      min: undefined,
      max: undefined,
    },
  },
};
