import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import Button from "../button/ng-button";

import "./profiler-data-source-help-item.scss";

function ProfilerDataSourceStatusHelpItem({
  icon,
  title,
  redirectUri,
  buttonText,
  description,
  buttonProps = {},
}) {
  return (
    <div className="profiler-data-source-help-item-container">
      <div className="profiler-data-source-help-item-icon-container">{icon}</div>
      <div className="profiler-data-source-help-item-title-container">{title}</div>
      <div className="profiler-data-source-help-item-redirect-container">
        <Link to={redirectUri}>
          <Button primary size="large" {...buttonProps}>
            {buttonText}
            <PlusOutlined />
          </Button>
        </Link>
      </div>
      <div className="profiler-data-source-help-item-description-container">
        {description}
      </div>
    </div>
  );
}

export default ProfilerDataSourceStatusHelpItem;
