import axios from "../utils/api";
import {
  KPI_CATEGORY_KEY_NAME,
  KPI_PERCENTILE_KEY_NAME,
  KPI_AGGREGATION_SOURCE_UUID_KEY_NAME,
  KPI_AGGREGATION_TARGET_UUID_KEY_NAME,
  INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
  INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
} from "../utils/constants";
import { getUniqueSliceValue, updateValueRange } from "../utils/general";
import { AutoMetricsType } from "../utils/enums";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";
import { getKpiPromise } from "./kpi";
import { MINUTE_IN_SECONDS, browserIANALocaleTimezone } from "../utils/time";
import { getAutoMetricTypeFromKPI, getSliceByColumns } from "../utils/metric";

export function getFilterMetricsDataFromServer(responseData) {
  const projectedData = [];
  const normalData = [];
  const lowerBoundData = [];
  const upperBoundData = [];
  const metricsRange = { min: undefined, max: undefined };

  const signalData = [];
  const signalRange = { min: undefined, max: undefined };

  let dataEntry = null;
  for (dataEntry of responseData) {
    const timestamp = Math.floor(dataEntry["time"]);
    const keyMaps = [
      { key: "filtered_obs_val", target: normalData, range: metricsRange },
      { key: "filtered_exp_val", target: projectedData, range: metricsRange },
      { key: "lower_exp_limit", target: lowerBoundData, range: metricsRange },
      { key: "upper_exp_limit", target: upperBoundData, range: metricsRange },
      { key: "value", target: signalData, range: signalRange },
    ];

    for (const { key, target, range } of keyMaps) {
      const value = dataEntry[key];
      const slice = dataEntry["slice"];
      const metadata = dataEntry["metadata"];
      if (typeof value !== "number") {
        console.log(`Invalid incident ${key} data ${JSON.stringify(dataEntry)}`);
      } else {
        target.push({
          timestamp,
          value,
          slice,
          metadata,
        });

        updateValueRange(range, value);
      }
    }
  }

  return {
    data: {
      projectedData,
      normalData,
      lowerBoundData,
      upperBoundData,
      signalData,
    },
    range: { metricsRange, signalRange },
  };
}

function sortDataDistributionValue(dataDistributionValue) {
  dataDistributionValue.sort((a, b) => {
    if (a.signalValue > b.signalValue) {
      return -1;
    }

    if (a.signalValue < b.signalValue) {
      return 1;
    }

    if (a.category > b.category) {
      return 1;
    }

    if (a.category < b.category) {
      return -1;
    }

    return 0;
  });
  return dataDistributionValue;
}

function getDataDistributionValuePerTimestamp(
  data,
  timestamp,
  valueKey,
  sliceByColumns
) {
  const resultPerSliceMapper = {};
  for (let dataEntry of data) {
    const currentSliceUniqueValue = getUniqueSliceValue(
      dataEntry.slice,
      sliceByColumns
    );
    const currentSliceUniqueValueStr = JSON.stringify(currentSliceUniqueValue);
    if (!resultPerSliceMapper[currentSliceUniqueValueStr]) {
      resultPerSliceMapper[currentSliceUniqueValueStr] = [];
    }

    const category = dataEntry.slice[KPI_CATEGORY_KEY_NAME];
    resultPerSliceMapper[currentSliceUniqueValueStr].push({
      category: category === undefined ? "null" : category,
      projectedValue: dataEntry["filtered_exp_val"],
      normalValue: dataEntry["filtered_obs_val"],
      lowerBoundValue: dataEntry["lower_exp_limit"],
      upperBoundValue: dataEntry["upper_exp_limit"],
      signalValue: dataEntry[valueKey],
    });
  }

  const result = [];
  for (let currentSliceUniqueValueStr in resultPerSliceMapper) {
    const slice = JSON.parse(currentSliceUniqueValueStr);
    result.push({
      timestamp,
      slice,
      value: sortDataDistributionValue(
        resultPerSliceMapper[currentSliceUniqueValueStr]
      ),
    });
  }
  return result;
}

function getDataDistributionFromServer(
  responseData,
  workspaceUuid,
  metric,
  timeKey = "time",
  valueKey = "value"
) {
  let lastTimestamp = null;
  let normalizedResult = [];
  const sliceByColumns = getSliceByColumns(metric);
  let currentDataEntriesGroupByTimestamp = [];
  for (let dataEntry of responseData) {
    const timestamp = Math.floor(dataEntry[timeKey]);
    if (lastTimestamp === null || timestamp !== lastTimestamp) {
      if (lastTimestamp !== null && currentDataEntriesGroupByTimestamp.length > 0) {
        normalizedResult.push(
          ...getDataDistributionValuePerTimestamp(
            currentDataEntriesGroupByTimestamp,
            lastTimestamp,
            valueKey,
            sliceByColumns
          )
        );
      }

      currentDataEntriesGroupByTimestamp = [];
      lastTimestamp = timestamp;
    }

    currentDataEntriesGroupByTimestamp.push(dataEntry);
  }

  if (lastTimestamp !== null && currentDataEntriesGroupByTimestamp.length > 0) {
    normalizedResult.push(
      ...getDataDistributionValuePerTimestamp(
        currentDataEntriesGroupByTimestamp,
        lastTimestamp,
        valueKey,
        sliceByColumns
      )
    );
  }

  return {
    data: { signalData: normalizedResult },
    range: {},
  };
}

const percentileValueConfig = {
  min: "0",
  q1: "25",
  mean: "50",
  q3: "75",
  max: "100",
};

function getNumericDistributionValuePerTimestamp(
  data,
  timestamp,
  projectValueKey,
  sliceByColumns
) {
  const resultPerSliceMapper = {};
  for (let dataEntry of data) {
    const currentSliceUniqueValue = getUniqueSliceValue(
      dataEntry.slice,
      sliceByColumns
    );
    const currentSliceUniqueValueStr = JSON.stringify(currentSliceUniqueValue);
    if (!resultPerSliceMapper[currentSliceUniqueValueStr]) {
      resultPerSliceMapper[currentSliceUniqueValueStr] = {
        signal: {},
        project: {},
      };
    }

    const currentCurrentResultPerSlice =
      resultPerSliceMapper[currentSliceUniqueValueStr];
    const currentPercentile = dataEntry.slice[KPI_PERCENTILE_KEY_NAME];
    const currentValue = dataEntry.value;
    const currentProjectValue = projectValueKey ? dataEntry[projectValueKey] : "";
    if (currentPercentile === percentileValueConfig.min) {
      currentCurrentResultPerSlice.signal.min = currentValue;
      projectValueKey &&
        (currentCurrentResultPerSlice.project.min = currentProjectValue);
    } else if (currentPercentile === percentileValueConfig.q1) {
      currentCurrentResultPerSlice.signal.q1 = currentValue;
      projectValueKey &&
        (currentCurrentResultPerSlice.project.q1 = currentProjectValue);
    } else if (currentPercentile === percentileValueConfig.mean) {
      currentCurrentResultPerSlice.signal.mean = currentValue;
      projectValueKey &&
        (currentCurrentResultPerSlice.project.mean = currentProjectValue);
    } else if (currentPercentile === percentileValueConfig.q3) {
      currentCurrentResultPerSlice.signal.q3 = currentValue;
      projectValueKey &&
        (currentCurrentResultPerSlice.project.q3 = currentProjectValue);
    } else if (currentPercentile === percentileValueConfig.max) {
      currentCurrentResultPerSlice.signal.max = currentValue;
      projectValueKey &&
        (currentCurrentResultPerSlice.project.max = currentProjectValue);
    } else {
      console.log(`Invalid data for ${JSON.stringify(dataEntry)}`);
    }
  }

  const signalData = [];
  const projectedData = [];
  for (let currentSliceUniqueValueStr in resultPerSliceMapper) {
    const slice = JSON.parse(currentSliceUniqueValueStr);

    if (
      Object.keys(resultPerSliceMapper[currentSliceUniqueValueStr].signal).length > 0
    ) {
      signalData.push({
        timestamp,
        slice,
        value: resultPerSliceMapper[currentSliceUniqueValueStr].signal,
      });
    } else {
      console.log(
        `Signal value for ${timestamp} for slice ${currentSliceUniqueValueStr} is empty`
      );
    }

    if (
      Object.keys(resultPerSliceMapper[currentSliceUniqueValueStr].project).length > 0
    ) {
      projectValueKey &&
        projectedData.push({
          timestamp,
          slice,
          value: resultPerSliceMapper[currentSliceUniqueValueStr].project,
        });
    } else {
      console.log(
        `Project value for ${timestamp} for slice ${currentSliceUniqueValueStr} is empty`
      );
    }
  }

  return {
    signalData,
    projectedData,
  };
}

function getNumericDistributionFromServer(
  responseData,
  workspaceUuid,
  metric,
  timeKey = "time",
  projectValueKey = "filtered_exp_val"
) {
  let lastTimestamp = null;
  const projectedData = [];
  const signalData = [];

  const sliceByColumns = getSliceByColumns(metric);
  let currentDataEntriesGroupByTimestamp = [];
  for (let dataEntry of responseData) {
    const timestamp = Math.floor(dataEntry[timeKey]);
    if (lastTimestamp === null || timestamp !== lastTimestamp) {
      if (lastTimestamp !== null && currentDataEntriesGroupByTimestamp.length > 0) {
        const currentNormalizedValuePerTimestamp =
          getNumericDistributionValuePerTimestamp(
            currentDataEntriesGroupByTimestamp,
            lastTimestamp,
            projectValueKey,
            sliceByColumns
          );

        projectedData.push(...currentNormalizedValuePerTimestamp.projectedData);
        signalData.push(...currentNormalizedValuePerTimestamp.signalData);
      }

      currentDataEntriesGroupByTimestamp = [];
      lastTimestamp = timestamp;
    }

    currentDataEntriesGroupByTimestamp.push(dataEntry);
  }

  if (lastTimestamp !== null && currentDataEntriesGroupByTimestamp.length > 0) {
    const currentNormalizedValuePerTimestamp = getNumericDistributionValuePerTimestamp(
      currentDataEntriesGroupByTimestamp,
      lastTimestamp,
      projectValueKey,
      sliceByColumns
    );

    projectedData.push(...currentNormalizedValuePerTimestamp.projectedData);
    signalData.push(...currentNormalizedValuePerTimestamp.signalData);
  }

  return {
    data: {
      projectedData,
      signalData,
    },
    range: {},
  };
}

export function getFullCompareFilterDataFromServer(
  responseData,
  workspaceUuid,
  metric
) {
  const normalizedResult = [];
  const attributeColumnLength = metric.config.sourceTable.attributeColumns.length;
  // Need to group all the slice values by timestamp.
  const timestampValueMapper = {};
  for (let currentResponseData of responseData) {
    const { value, slice, time, lower_exp_limit, upper_exp_limit } =
      currentResponseData;
    const {
      [INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME]: keyColIndex,
      [INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME]: attrColIndex,
      ...otherSliceValues
    } = slice;

    if (!timestampValueMapper[time]) {
      normalizedResult.push({
        timestamp: time,
        slice: otherSliceValues,
        value: {
          matchingPerc: null,
          lowerBoundMatchingPercValue: null,
          upperBoundMatchingPercValue: null,
          attributeMatchingPercs: new Array(attributeColumnLength),
          lowerBoundAttributeMatchingPercValue: new Array(attributeColumnLength),
          upperBoundAttributeMatchingPercValue: new Array(attributeColumnLength),
        },
      });
      timestampValueMapper[time] = normalizedResult[normalizedResult.length - 1];
    }

    const currentValue = timestampValueMapper[time].value;
    if (keyColIndex !== undefined) {
      currentValue.matchingPerc = value;
      currentValue.lowerBoundMatchingPercValue = lower_exp_limit;
      currentValue.upperBoundMatchingPercValue = upper_exp_limit;
    } else if (attrColIndex !== undefined) {
      currentValue.attributeMatchingPercs[attrColIndex] = value;
      currentValue.lowerBoundAttributeMatchingPercValue[attrColIndex] = lower_exp_limit;
      currentValue.upperBoundAttributeMatchingPercValue[attrColIndex] = upper_exp_limit;
    }
  }

  return {
    data: {
      signalData: normalizedResult,
    },
    range: {},
  };
}

export function getFullCompareFromServer(
  responseData,
  workspaceUuid,
  metric,
  timestampKey = "eventTs"
) {
  const normalizedResult = [];
  for (let currentResponseData of responseData) {
    const timestamp = currentResponseData[timestampKey];
    const { metadata, slice } = currentResponseData;
    normalizedResult.push({
      timestamp,
      value: metadata,
      slice,
    });
  }

  return {
    data: {
      signalData: normalizedResult,
    },
    range: {},
  };
}

export function getAggregationCompareFromServer(
  responseData,
  workspaceUuid,
  metric,
  timestampKey = "time"
) {
  const normalizedResult = [];
  const timestampValueMapper = {};
  for (let currentResponseData of responseData) {
    const timestamp = currentResponseData[timestampKey];
    if (!timestampValueMapper.hasOwnProperty(timestamp)) {
      timestampValueMapper[timestamp] = [];
      normalizedResult.push({
        timestamp,
        value: timestampValueMapper[timestamp],
      });
    }

    const currentTimestampValue = timestampValueMapper[timestamp];
    const {
      value,
      slice,
      metadata = {
        sourceValue: "---",
        targetValue: "---",
      },
    } = currentResponseData;

    const { sourceValue, targetValue } = metadata;

    const {
      [KPI_AGGREGATION_SOURCE_UUID_KEY_NAME]: sourceMetricUuid,
      [KPI_AGGREGATION_TARGET_UUID_KEY_NAME]: targetMetricUuid,
      ...otherSliceValues
    } = slice;

    currentTimestampValue.push({
      key: currentTimestampValue.length + 1,
      sourceMetricUuid,
      targetMetricUuid,
      slice: otherSliceValues,
      sourceValue,
      targetValue,
      value,
      lowerBoundValue: currentResponseData["lower_exp_limit"],
      upperBoundValue: currentResponseData["upper_exp_limit"],
    });
  }

  let relatedMetricUuidMapper = {};
  let relatedMetricUuids = [];
  metric.config.compares.forEach(({ sourceMetricUuid, targetMetricUuid }) => {
    if (!relatedMetricUuidMapper[sourceMetricUuid]) {
      relatedMetricUuidMapper[sourceMetricUuid] = true;
      relatedMetricUuids.push(sourceMetricUuid);
    }

    if (!relatedMetricUuidMapper[targetMetricUuid]) {
      relatedMetricUuidMapper[targetMetricUuid] = true;
      relatedMetricUuids.push(targetMetricUuid);
    }
  });

  return new Promise(function (resolve, reject) {
    Promise.all(
      relatedMetricUuids.map((metricUuid) => getKpiPromise(workspaceUuid, metricUuid))
    )
      .then(function (relatedMetricsInfo) {
        resolve({
          data: {
            signalData: normalizedResult,
            metadata: {
              relatedMetricsInfo,
            },
          },
          range: {},
        });
      })
      .catch(function (err) {
        console.log(`Fail to get related metric info due to ${err}`);
        resolve({
          data: {
            signalData: normalizedResult,
            metadata: {
              relatedMetricsInfo: [],
            },
          },
          range: {},
        });
      });
  });
}

function convertSecondsToMinutes(v) {
  return typeof v === "number" ? v / MINUTE_IN_SECONDS : v;
}

function getDataDelayFromServer(responseData) {
  return getFilterMetricsDataFromServer(
    responseData.map((dataEntry) => ({
      ...dataEntry,
      value: dataEntry.value / MINUTE_IN_SECONDS,
      feature_obs_val: convertSecondsToMinutes(dataEntry.feature_obs_val),
      filtered_exp_val: convertSecondsToMinutes(dataEntry.filtered_exp_val),
      filtered_obs_val: convertSecondsToMinutes(dataEntry.filtered_obs_val),
      lower_exp_limit: convertSecondsToMinutes(dataEntry.lower_exp_limit),
      upper_exp_limit: convertSecondsToMinutes(dataEntry.upper_exp_limit),
    }))
  );
}

const autoMetricTypeToParserMapper = {
  [AutoMetricsType.CATEGORICAL_DISTRIBUTION]: getDataDistributionFromServer,
  [AutoMetricsType.NUMERICAL_DISTRIBUTION]: getNumericDistributionFromServer,
  [AutoMetricsType.FULL_COMPARE]: getFullCompareFilterDataFromServer,
  [AutoMetricsType.AGGREGATION_COMPARE]: getAggregationCompareFromServer,
  [AutoMetricsType.DATA_DELAY]: getDataDelayFromServer,
  [AutoMetricsType.UPDATE_DELAY]: getDataDelayFromServer,
};

const skipLttbMetricsTypes = [
  AutoMetricsType.CATEGORICAL_DISTRIBUTION,
  AutoMetricsType.NUMERICAL_DISTRIBUTION,
  AutoMetricsType.FULL_COMPARE,
  AutoMetricsType.AGGREGATION_COMPARE,
];

function getFilterMetricsDataPromise(workspaceUuid, queryObject) {
  return new Promise(function (resolve, reject) {
    const {
      startTime,
      endTime,
      filterUuid,
      previewUuid,
      sliceKeyValuePairs,
      isTrain = false,
      autoMetricType = "",
      metric = null,
    } = queryObject;

    let queryString = "";
    if (!filterUuid) {
      console.log(`Invalid query object ${JSON.stringify(filterUuid)}`);
      return;
    }

    const skipLttb = !!skipLttbMetricsTypes.includes(autoMetricType);
    let baseURI;
    // there are 2 set of endpoints
    //   training-metrics, training-metrics-lttb
    //   metrics, metrics-lttb
    if (isTrain) {
      baseURI = skipLttb
        ? backendRelativeURIPath.MONITOR_TRAINING_DATA_POINT
        : backendRelativeURIPath.MONITOR_TRAINING_DATA_POINT_WITH_LTTB;
    } else {
      baseURI = skipLttb
        ? backendRelativeURIPath.MONITOR_DATA_POINT
        : backendRelativeURIPath.MONITOR_DATA_POINT_WITH_LTTB;
    }

    const conditionArray = [];
    if (startTime > 0) {
      conditionArray.push(`start_ts=${startTime}`);
    }

    if (endTime > 0) {
      conditionArray.push(`end_ts=${endTime}`);
    }

    if (sliceKeyValuePairs && sliceKeyValuePairs.length > 0) {
      let sliceObj = {};
      for (let { key, value } of sliceKeyValuePairs) {
        if (key !== undefined) {
          sliceObj[key] = value;
        }
      }

      let encodedSliceValue = encodeURIComponent(JSON.stringify(sliceObj));
      conditionArray.push(`slice=${encodedSliceValue}`);
    }

    if (conditionArray.length > 0) {
      queryString += `?${conditionArray.join("&")}`;
    }

    const realFilterUuid = previewUuid ? previewUuid : filterUuid;
    axios
      .get(
        `${getAPIURIInstance(baseURI, {
          workspaceUuid,
          uuid: realFilterUuid,
        })}${queryString}`
      )
      .then(function (response) {
        const responseParser =
          autoMetricTypeToParserMapper[autoMetricType] ||
          getFilterMetricsDataFromServer;
        Promise.resolve(responseParser(response.data, workspaceUuid, metric)).then(
          (filterData) => {
            resolve(filterData);
          }
        );
      })
      .catch(function (err) {
        console.log(`Fail to get filter data due to ${err}`);
        reject(err);
      });
  });
}

export function getFilterDataPromise(workspaceUuid, queryObject) {
  return new Promise(function (resolve, reject) {
    const { startTime, endTime, autoMetricType } = queryObject;
    const getMetricsDataPromise = getFilterMetricsDataPromise;

    getMetricsDataPromise(workspaceUuid, queryObject)
      .then(function (metricsData) {
        resolve({
          autoMetricType,
          metricsData: metricsData.data,
          range: metricsData.range,
          duration: { startTime, endTime },
        });
      })
      .catch(function (err) {
        console.log(`Fail to get all filter metrics for ${err}`);
        reject(err);
      });
  });
}

function getProfilerSignalDataFromServer(responseData) {
  const signalData = [];
  const signalRange = { min: undefined, max: undefined };

  const projectedData = [];
  const normalData = [];
  const lowerBoundData = [];
  const upperBoundData = [];
  const metricsRange = { min: undefined, max: undefined };

  for (let dataEntry of responseData) {
    const { eventTs, value, slice, metadata = {} } = dataEntry;
    if (typeof value !== "number") {
      console.log(`Invalid profile value metric data ${JSON.stringify(dataEntry)}`);
      continue;
    }
    signalData.push({
      timestamp: eventTs,
      value,
      slice,
      metadata,
    });
    updateValueRange(signalRange, value);
  }

  return {
    data: {
      projectedData,
      normalData,
      lowerBoundData,
      upperBoundData,
      signalData,
    },
    range: {
      metricsRange,
      signalRange,
    },
  };
}

function getDataDistributionSignalDataFromServer(responseData, workspaceUuid, metric) {
  return getDataDistributionFromServer(
    responseData,
    workspaceUuid,
    metric,
    "eventTs",
    "value"
  );
}

function getNumericDistributionSignalDataFromServer(
  responseData,
  workspaceUuid,
  metric
) {
  return getNumericDistributionFromServer(
    responseData,
    workspaceUuid,
    metric,
    "eventTs",
    ""
  );
}

function getAggregationCompareSignalDataFromServer(
  responseData,
  workspaceUuid,
  metric
) {
  return getAggregationCompareFromServer(
    responseData,
    workspaceUuid,
    metric,
    "eventTs"
  );
}

function getDataDelaySignalDataFromServer(responseData, _workspaceUuid, _metric) {
  return getProfilerSignalDataFromServer(
    responseData.map((dataEntry) => {
      return {
        ...dataEntry,
        value: convertSecondsToMinutes(dataEntry.value),
      };
    })
  );
}

const autoMetricTypeToSignalParserMapper = {
  [AutoMetricsType.CATEGORICAL_DISTRIBUTION]: getDataDistributionSignalDataFromServer,
  [AutoMetricsType.NUMERICAL_DISTRIBUTION]: getNumericDistributionSignalDataFromServer,
  [AutoMetricsType.FULL_COMPARE]: getFullCompareFromServer,
  [AutoMetricsType.AGGREGATION_COMPARE]: getAggregationCompareSignalDataFromServer,
  [AutoMetricsType.DATA_DELAY]: getDataDelaySignalDataFromServer,
  [AutoMetricsType.UPDATE_DELAY]: getDataDelaySignalDataFromServer,
};

function getMetricSignalDataPromise(workspaceUuid, queryObject) {
  return new Promise(function (resolve, reject) {
    const {
      startTime,
      endTime,
      autoMetricType,
      metricUuid = "",
      sliceKeyValuePairs = [],
      metric = null,
    } = queryObject;

    const conditionArray = [];
    if (typeof startTime === "number") {
      conditionArray.push(`start_ts=${startTime}`);
    }

    if (typeof endTime === "number") {
      conditionArray.push(`end_ts=${endTime}`);
    }

    let baseUri = getAPIURIInstance(backendRelativeURIPath.METRIC_DATA_POINT, {
      workspaceUuid,
      uuid: metricUuid,
    });
    if (sliceKeyValuePairs && sliceKeyValuePairs.length > 0) {
      let sliceObj = {};
      sliceKeyValuePairs.forEach((sliceKeyValue) => {
        sliceObj[sliceKeyValue.key] = sliceKeyValue.value;
      });
      let encodedSliceValue = encodeURIComponent(JSON.stringify(sliceObj));
      conditionArray.push(`slice=${encodedSliceValue}`);
    }
    const queryString = conditionArray.length > 0 ? `?${conditionArray.join("&")}` : "";

    axios
      .get(`${baseUri}${queryString}`, { baseURL: "/api/v1/" })
      .then((metricsDataResponse) => {
        let parseFunction = getProfilerSignalDataFromServer;
        if (autoMetricTypeToSignalParserMapper[autoMetricType]) {
          parseFunction = autoMetricTypeToSignalParserMapper[autoMetricType];
        }

        resolve(parseFunction(metricsDataResponse.data, workspaceUuid, metric));
      })
      .catch((err) => {
        reject(err);
        console.log(`Fail to get profiler metrics due to ${err}`);
      });
  });
}

export function getSignalDataPromise(workspaceUuid, queryObject) {
  return new Promise(function (resolve, reject) {
    const { startTime, endTime, autoMetricType } = queryObject;
    const getCurrentSignalDataPromise = getMetricSignalDataPromise;

    getCurrentSignalDataPromise(workspaceUuid, queryObject)
      .then(function (metricsData) {
        resolve({
          autoMetricType,
          metricsData: metricsData.data,
          range: metricsData.range,
          duration: { startTime, endTime },
        });
      })
      .catch(function (err) {
        console.log(`Fail to get all filter metrics for ${err}`);
        reject(err);
      });
  });
}

export function getMetricPreviewDataPromise(
  workspaceUuid,
  kpiPreviewObject,
  requestOptions = {}
) {
  return new Promise(function (resolve, reject) {
    const axiosOptions = { baseURL: "/api/v1/" };
    if (requestOptions.hasOwnProperty("disableToast")) {
      axiosOptions.disableToast = requestOptions.disableToast;
    }

    if (requestOptions.hasOwnProperty("signal")) {
      axiosOptions.signal = requestOptions.signal;
    }

    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_PREVIEW, {
          workspaceUuid,
        }),
        kpiPreviewObject,
        axiosOptions
      )
      .then(function (metricsDataResponse) {
        const metric = kpiPreviewObject.metric;
        const autoMetricType = getAutoMetricTypeFromKPI(metric);
        let parseFunction = getProfilerSignalDataFromServer;
        if (autoMetricTypeToSignalParserMapper[autoMetricType]) {
          parseFunction = autoMetricTypeToSignalParserMapper[autoMetricType];
        }

        Promise.resolve(
          parseFunction(metricsDataResponse.data.data, workspaceUuid, metric)
        ).then((parsedData) => {
          resolve({
            data: {
              autoMetricType,
              metricsData: parsedData.data,
              range: parsedData.range,
              duration: {
                startTime: kpiPreviewObject.previewStartTs,
                endTime: kpiPreviewObject.previewEndTs,
              },
            },
            rowData: metricsDataResponse.data.data,
            queryStats: metricsDataResponse.queryStats,
          });
        });
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getMetricCollectionSummaryPromise(workspaceUuid, metric) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_COLLECTION_SUMMARY, {
          workspaceUuid,
        }),
        { metric, browserTimezone: browserIANALocaleTimezone() },
        { baseURL: "/api/v0/" }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(`Failed to get metric collection summary due to ${err}`);
        reject(err);
      });
  });
}

export function getMetricCustomScheduleUpcomingRunPromise(
  workspaceUuid,
  { crontabExpression, timezone }
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.METRIC_CUSTOM_SCHEDULE_UPCOMING_RUNS, {
          workspaceUuid,
        }),
        { crontabExpression, timezone },
        { baseURL: "/api/v0/" }
      )
      .then((response) => {
        resolve(response.data?.customScheduleUpcomingRuns ?? []);
      })
      .catch((err) => {
        console.log(`Failed to get upcoming runs for custom schedule due to ${err}`);
        reject(err);
      });
  });
}
