export const validateDraftMetadata = (
  draftMetadata,
  isApprovingOrRejecting = false
) => {
  const { name, description } = draftMetadata;
  if (typeof name !== "string" || name.trim() === "" || name.trim().length > 400) {
    return false;
  }
  if (typeof description === "string" && description.length > 2000) {
    return false;
  }
  if (isApprovingOrRejecting) {
    const note = draftMetadata?.approverNotes?.notes?.[0]?.note;
    if (typeof note !== "string" || note.trim() === "") {
      return false;
    }
  }
  return true;
};
