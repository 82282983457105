import { deepcopy, isNullOrUndefined } from "../../utils/objects";
import { AlertingChannelType } from "../../utils/alerting";

// Should match values in lux/lux/util/jira/jira_constant.py
export const DUMMY_JIRA_PROJECT_ID = "dummy-id";
export const DUMMY_JIRA_PROJECT_VALUE = "dummy-value";

export const SubmitType = Object.freeze({
  MANUAL: "manual",
  AUTOMATIC: "automatic",
});

export const NodeType = Object.freeze({
  NODE: "node",
  LEAF: "leaf",
});

export const ValueType = Object.freeze({
  ID: "id",
  VALUE: "value",
  USER: "user",
  STRING: "string",
  ISSUE_LINK: "issuelink",
  NUMBER: "number",
});

export const FieldInputType = Object.freeze({
  TEXT: "text",
  SINGLE_SELECT: "single-select",
  MULTI_SELECT: "multi-select",
  NUMBER: "number",
  USER_SINGLE_SELECT: "user-single-select",
  USER_MULTI_SELECT: "user-multi-select",
});

// An integration metadata object represents a hierarchy of objects supported by an
// alerting integration. It is a tree where the leaves that represent tickets that
// will be created (e.g. Jira tasks or bugs) and the internal nodes represent
// context for those tickets (e.g. Jira projects). Each leaf node has a set of fields
// that can be filled when submitting a ticket. To get a feel for the data structure,
// you can look at the data in ui/src/test/data/alerting-channels.js.

// Is obj an integration metadata object?
export function isMetadata(obj) {
  return Object.values(NodeType).includes(obj?.type);
}

// Is node a leaf node?
export function isLeaf(node) {
  return node?.type === NodeType.LEAF;
}

// Iterate through the nodes of a tree, depth-first.
export function* walkTree(metadata) {
  if (!metadata) {
    return;
  }
  if (isLeaf(metadata)) {
    yield metadata;
  } else {
    yield metadata;
    for (let fieldValue of metadata.fieldValues) {
      yield* walkTree(fieldValue);
    }
  }
}

// A context path is an array of strings, representing the path in the metadata
// tree to a node. The values inside a context path are called context fields.

// Enumerate all of the subpaths of a path.
export function* subPaths(contextPath) {
  yield [];
  for (let i = 0; i < contextPath.length; i++) {
    yield contextPath.slice(0, i + 1);
  }
}

// Return the tree node at the end of a path.
export function getNodeAtPath(metadata = null, path = []) {
  if (path.length === 0 || isNullOrUndefined(metadata)) {
    return metadata;
  } else {
    // path.length > 0
    const children = metadata.fieldValues;
    const nextNode = children.find(
      (fieldValue) => fieldValue.value[0].valueId === path[0]
    );
    return getNodeAtPath(nextNode, path.slice(1));
  }
}

// Is there a single path through the metadata tree?
export function isBranch(metadata) {
  for (let node of walkTree(metadata)) {
    if (!isLeaf(node) && node.fieldValues.length !== 1) {
      return false;
    }
  }
  return true;
}

// Select the branch consisting just of the nodes in contextPath.
export function selectBranch(metadata, contextPath) {
  const branch = deepcopy(metadata);

  const currentPath = [...contextPath];
  let currentNode = branch;
  while (currentPath.length > 0 && currentNode) {
    const nextPathSegment = currentPath.shift();
    const child = currentNode.fieldValues.find(
      ({ value }) => value[0].valueId === nextPathSegment
    );
    if (child) {
      currentNode.fieldValues = [child];
      currentNode = child;
    } else {
      currentNode = null;
    }
  }

  return branch;
}

// What is the longest path in metadata, starting from the root, that does not
// branch? Or in other words, what is the farthest you can travel down the tree
// from the root before you have to make a choice?
export function longestBranchPath(metadata, path = []) {
  if (metadata.type === NodeType.LEAF || metadata.fieldValues.length !== 1) {
    return path;
  } else {
    return longestBranchPath(metadata.fieldValues[0], [
      ...path,
      metadata.fieldValues[0].value[0].valueId,
    ]);
  }
}

// Return the leaf node at the end of the longest non-branching path, or null
// if no such leaf node exists.
export function getBranchLeaf(metadata) {
  const longestBranch = longestBranchPath(metadata);
  const editNode = getNodeAtPath(metadata, longestBranch);
  return isLeaf(editNode) ? editNode : null;
}

// These fields will get overwritten by the backend at ticket submissions time,
// so we won't allow the user to configure them.
export const systemFieldIds = {
  [AlertingChannelType.JIRA]: new Set(["summary", "reporter", "description"]),
  [AlertingChannelType.SERVICENOW]: new Set(),
};

// Context fields to hide from the user, presumably because they are always fixed
// ahead of time and so do not need to be selected. For Jira, the user must specify
// the project key before authenticating.
export const hiddenContextFieldIds = {
  [AlertingChannelType.JIRA]: new Set(["project"]),
  [AlertingChannelType.SERVICENOW]: new Set(),
};

// Does a field need to be filled out in order to submit a ticket?
export function isRequiredField(type, field) {
  return field.isRequired && !systemFieldIds[type].has(field.fieldId);
}

// Retain only the required fields in every leaf node.
export function minimalFields(type, metadata) {
  const cleared = deepcopy(metadata);
  for (let node of walkTree(cleared)) {
    if (isLeaf(node)) {
      node.fields = node.fields.filter((field) => isRequiredField(type, field));
    }
  }
  return cleared;
}

// When we save an integration, we keep store a branch of the integration metadata tree.
// But the integration may change over time - for example, a Jira issue type could get removed.
// In those cases, we need to reconcile the updated full integration metadata tree with the branch
// that we have saved. The way this works is that we start from the root, and walk down the stored
// branch (editMetadata). At each node, if it exists in the full (up-to-date) metadata tree, we keep it.
// If it doesn't exist, replace it with the node from the full metadata tree.
export function reconcileMetadata(type, editMetadata, metadata) {
  // If the edit metadata is talking about a different root field, then none
  // of the edit metadata is valid; start fresh.
  if (editMetadata.fieldId !== metadata.fieldId) {
    return minimalFields(type, metadata);
  }
  if (!isLeaf(editMetadata)) {
    const validFieldValueIds = new Set(
      metadata.fieldValues.map(({ value }) => value[0].valueId)
    );
    // API changes with JIRA mean that for on-prem instances, we now return dummy-id / dummy-value as the value
    // for project fieldValues. We may have saved integrations before this change though. For these
    // cases, we want to replace the project ID from the saved value with the dummy value, so that we
    // can proceed with reconciliation.
    if (
      type === AlertingChannelType.JIRA &&
      editMetadata.fieldId === "project" &&
      validFieldValueIds.size === 1 &&
      validFieldValueIds.has(DUMMY_JIRA_PROJECT_ID)
    ) {
      editMetadata.fieldValues[0].value = metadata.fieldValues[0].value;
    }
    const selectedEditFieldId = editMetadata.fieldValues[0].value[0].valueId;
    if (!validFieldValueIds.has(selectedEditFieldId)) {
      // The context field value that was selected no longer exists. Replace this
      // node with a fresh one.
      return minimalFields(type, metadata);
    } else {
      editMetadata.fieldValues[0] = reconcileMetadata(
        type,
        editMetadata.fieldValues[0],
        metadata.fieldValues.find(
          ({ value }) => value[0].valueId === selectedEditFieldId
        )
      );
      return editMetadata;
    }
  } else {
    // Leaf node.
    const validFieldValueIds = new Set(metadata.fields.map(({ fieldId }) => fieldId));
    editMetadata.fields = editMetadata.fields
      // Remove fields that have been removed.
      .filter(({ fieldId }) => validFieldValueIds.has(fieldId))
      // Replace allowed values, since we don't save these to the backend.
      .map((field) => {
        const metadataField = metadata.fields.find(
          ({ fieldId }) => fieldId === field.fieldId
        );
        field.allowedValues = metadataField.allowedValues;
        field.valueType = metadataField.valueType;
        return field;
      });
    return editMetadata;
  }
}

// Translate a value from metadata into a raw or labeled value that could be used in a form.
export function decodedFieldValue(field, v) {
  if (field.valueType === ValueType.USER) {
    return { label: v?.value, value: v?.valueId };
  }
  return field.valueType === ValueType.ID ? v?.valueId : v?.value;
}

export function decodedDefaultFieldValues(field) {
  const decodedDefaultValues = field.defaultValues.map((v) =>
    decodedFieldValue(field, v)
  );
  if (
    [FieldInputType.MULTI_SELECT, FieldInputType.USER_MULTI_SELECT].includes(
      getFieldInputType(field)
    )
  ) {
    return decodedDefaultValues;
  } else {
    return decodedDefaultValues?.[0] ?? null;
  }
}

// Translate a raw or labeled value from a form into a value that could be used in metadata.
export function encodedFieldValue(field, v) {
  if (field.valueType === ValueType.USER) {
    return { value: v.label, valueId: v.value };
  }
  return field.valueType === ValueType.ID ? { valueId: v } : { value: v };
}

export function getFieldInputType(field) {
  const { isMultiSelect, allowedValues, valueType } = field;
  if (valueType === ValueType.NUMBER) {
    return FieldInputType.NUMBER;
  } else if (valueType === ValueType.USER) {
    return isMultiSelect
      ? FieldInputType.USER_MULTI_SELECT
      : FieldInputType.USER_SINGLE_SELECT;
  } else if (allowedValues.length > 0 && !isMultiSelect) {
    return FieldInputType.SINGLE_SELECT;
  } else if (isMultiSelect) {
    return FieldInputType.MULTI_SELECT;
  } else {
    return FieldInputType.TEXT;
  }
}

// Replace a field in editLeaf. Mutates editLeaf.
export function replaceField(editLeaf, newField) {
  const fieldIndex = editLeaf.fields.findIndex(
    ({ fieldId }) => fieldId === newField.fieldId
  );
  editLeaf.fields[fieldIndex] = newField;
  return editLeaf;
}

// Get the metadata object to use for editing, based on the current value's
// metadata and remote metadata. May mutate value.
export function getReconciledEditMetadata(type, value, currentMetadata) {
  let reconciledMetadata;
  const editMedataAvailable = isMetadata(value.metadata);
  const remoteMetadataAvailable = isMetadata(currentMetadata.data);
  if (!editMedataAvailable && remoteMetadataAvailable) {
    // Legacy integration with no metadata.
    reconciledMetadata = minimalFields(type, currentMetadata.data);
  } else if (editMedataAvailable && remoteMetadataAvailable) {
    // If the configuration options have changed since the integration was
    // saved, we may need to remove invalid parts of the saved config.
    reconciledMetadata = reconcileMetadata(type, value.metadata, currentMetadata.data);
  } else {
    reconciledMetadata = value.metadata;
  }
  return reconciledMetadata;
}

// We don't save allowed values in an integration. This function removes all allowed values,
// except for the ones that are set as default values.
export function removeUnusedAllowedValues(metadata) {
  const noAllowedValues = deepcopy(metadata);
  for (let node of walkTree(noAllowedValues)) {
    if (isLeaf(node)) {
      for (let field of node.fields) {
        if (field.allowedValues.length > 0 && field.defaultValues.length > 0) {
          const defaultValueIds = new Set(field.defaultValues.map((v) => v.valueId));
          field.allowedValues = field.allowedValues.filter((allowedValue) =>
            defaultValueIds.has(allowedValue.valueId)
          );
        }
      }
    }
  }
  return noAllowedValues;
}
