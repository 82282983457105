import React from "react";
import { Select } from "antd";
import { LabeledMultiSelect } from "../labeled-control/labeled-control";
import { MultiSelectTag } from "../multi-select/ng-multi-select";
import { indexBy } from "../../utils/iterables";
import { searchFieldConfig } from "./use-search/use-search-config";
import { GENERAL_SEARCH_FIELD } from "./use-search/use-search-metrics-config";
import Popover from "../popover";

function getSelectionChildren(selectionOptionList) {
  const selectionChildren = [];
  const searchFieldLookup = indexBy(searchFieldConfig, (field) => field.fieldName);
  for (let { label, type, items, displayFunction } of selectionOptionList) {
    const sortFn = searchFieldLookup[type]?.sortFn;
    if (sortFn) {
      items.sort(sortFn);
    } else {
      items.sort();
    }
    if (items.length > 0) {
      selectionChildren.push(
        <Select.OptGroup key={type} label={label}>
          {items.map((currentItem, index) => {
            const displayItemValue = displayFunction
              ? displayFunction(currentItem)
              : currentItem;
            return (
              <Select.Option
                key={`${type}_${index}`}
                value={`${type}_${displayItemValue}`}
              >
                {displayItemValue}
              </Select.Option>
            );
          })}
        </Select.OptGroup>
      );
    }
  }

  return selectionChildren;
}

function getFieldsFromSelectionValues(selectionValues = []) {
  const fields = searchFieldConfig.reduce((emptyFields, fieldConfig) => {
    emptyFields[fieldConfig.fieldName] = [];
    return emptyFields;
  }, {});
  fields[GENERAL_SEARCH_FIELD] = [];

  for (let selectionValue of selectionValues) {
    const selectionValueField = searchFieldConfig.find((fieldConfig) =>
      selectionValue.startsWith(fieldConfig.prefix)
    );
    if (selectionValueField) {
      fields[selectionValueField.fieldName].push(
        selectionValue.substring(selectionValueField.prefix.length).trim()
      );
    } else {
      fields[GENERAL_SEARCH_FIELD].push(selectionValue.trim());
    }
  }

  return fields;
}

function getSelectionValuesFromLocalSetting(fieldValues) {
  const selectionValues = [];

  for (let searchField of searchFieldConfig) {
    const fieldItems = fieldValues[searchField.fieldName] ?? [];
    for (let fieldItem of fieldItems) {
      selectionValues.push(`${searchField.prefix}${fieldItem}`);
    }
  }

  const searchNameItems = fieldValues[GENERAL_SEARCH_FIELD] ?? [];
  for (let searchNameItem of searchNameItems) {
    selectionValues.push(`${searchNameItem}`);
  }

  return selectionValues;
}

function getValuePrefix(value) {
  if (!value) {
    return "";
  }
  const searchField = searchFieldConfig.find((searchField) =>
    value.startsWith(searchField.prefix)
  );
  return searchField ? searchField.tagLabel : "search:";
}

function AntdSearch(props) {
  const {
    localFilterSetting = {},
    onChange,
    selectionOptionList = [],
    label = "Search",
    placeholder = "You can search across datasources, tables, columns, metrics, monitors, slice name or tags",
    ...restProps
  } = props;
  const selectionValues = getSelectionValuesFromLocalSetting(localFilterSetting);
  return (
    <LabeledMultiSelect
      label={label}
      mode="tags"
      maxTagCount="responsive"
      style={{ width: "100%" }}
      value={selectionValues}
      placeholder={placeholder}
      filterOption={true}
      tagRender={(props) => {
        const { label, value = "", onClose } = props;
        const prefix = getValuePrefix(value);
        return (
          <MultiSelectTag label={`${prefix} ${label}`} onClose={onClose} closable />
        );
      }}
      onChange={(newSelectionValues) =>
        onChange?.(getFieldsFromSelectionValues(newSelectionValues))
      }
      maxTagPlaceholder={(omittedVals) => {
        const numOmitted = omittedVals.length;
        const placeholder = `+ ${numOmitted}`;
        const popoverContent = (
          <>
            {omittedVals.map((omittedVal) => {
              const { label, value = "" } = omittedVal;
              const prefix = getValuePrefix(value);
              const removeCurrentItem = () => {
                onChange?.(
                  getFieldsFromSelectionValues(
                    selectionValues.filter((itemValue) => itemValue !== value)
                  )
                );
              };
              return (
                <MultiSelectTag
                  key={value}
                  label={`${prefix} ${label}`}
                  onClose={removeCurrentItem}
                  closable
                />
              );
            })}
          </>
        );
        return (
          <Popover
            content={popoverContent}
            trigger="hover"
            placement="bottomRight"
            overlayClassName="ng-multi-select-max-tag-placeholder-popover"
          >
            {placeholder}
          </Popover>
        );
      }}
      {...restProps}
    >
      {getSelectionChildren(selectionOptionList)}
    </LabeledMultiSelect>
  );
}

export default AntdSearch;
