import downloadFilesInitialState from "./download-initial-state";
import { SET_DOWNLOAD_FILES_LIST } from "../../actions/downloads/download-action-types";

export default function downloadFilesReducer(
  state = downloadFilesInitialState,
  action = {}
) {
  let newState;

  switch (action.type) {
    case SET_DOWNLOAD_FILES_LIST:
      newState = { ...state, downloadFilesList: action.downloadFilesList };
      return newState;

    default:
      return state;
  }
}
