import React from "react";
import Modal from "react-modal";

import "./legacy-modal-dialog.scss";

const defaultStyles = {
  overlay: {
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    transform: "translate(-50%, -50%)",
  },
};

const defaultCloseIcon = (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M9.50781 6.25L13.0234 2.73438C13.4805 2.3125 13.4805 1.60938 13.0234 1.1875L12.25 0.414062C11.8281 -0.0429688 11.125 -0.0429688 10.7031 0.414062L7.1875 3.92969L3.63672 0.414062C3.21484 -0.0429688 2.51172 -0.0429688 2.08984 0.414062L1.31641 1.1875C0.859375 1.60938 0.859375 2.3125 1.31641 2.73438L4.83203 6.25L1.31641 9.80078C0.859375 10.2227 0.859375 10.9258 1.31641 11.3477L2.08984 12.1211C2.51172 12.5781 3.21484 12.5781 3.63672 12.1211L7.1875 8.60547L10.7031 12.1211C11.125 12.5781 11.8281 12.5781 12.25 12.1211L13.0234 11.3477C13.4805 10.9258 13.4805 10.2227 13.0234 9.80078L9.50781 6.25Z"
      fill="black"
    />
  </svg>
);

function LegacyModalDialog(props) {
  const {
    containerClassName = "",
    triggerComponent,
    title,
    contentComponent,
    footerComponent,
    closeIcon = defaultCloseIcon,
    modalIsOpen,
    setIsOpen,
    onCloseIcon,
    customStyle = {},
    shouldCloseOnOverlayClick = false,
    width = 520,
  } = props;

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function onCloseIconClicked() {
    onCloseIcon ? onCloseIcon() : closeModal();
  }

  const style = { ...defaultStyles, ...customStyle };
  return (
    <div className={`base-dialog-trigger-container ${containerClassName}`}>
      <div className="base-dialog-trigger-inner-container" onClick={openModal}>
        {triggerComponent}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        style={style}
        width={width}
      >
        <div className={`base-dialog-container ${containerClassName}`}>
          <div className="base-dialog-header-container">
            <div className="base-dialog-header-title-container">{title}</div>
            <span
              className="base-dialog-header-close-icon-container"
              onClick={onCloseIconClicked}
            >
              {closeIcon}
            </span>
          </div>
          <div className="base-dialog-content-container">{contentComponent}</div>
          <div className="base-dialog-footer-container">{footerComponent}</div>
        </div>
      </Modal>
    </div>
  );
}

export default LegacyModalDialog;
