import CrossHatchSVGPattern from "../../assets/img/CrossHatch.svg";

import "./grid-empty-state.scss";

export default function GridEmptyState({
  icon,
  title,
  message,
  actions,
  maxHeight = "auto",
  background = "light",
}) {
  return (
    <div className="grid-empty-state">
      <div
        style={{
          maxHeight: maxHeight,
          backgroundImage: `url(${CrossHatchSVGPattern})`,
        }}
        className="grid-empty-state-wrapper"
      >
        <div
          className={`grid-empty-state-wrapper-overlay grid-empty-state-wrapper-overlay--${background}`}
        >
          <div className="grid-empty-state-wrapper-content">
            {icon && (
              <span className="grid-empty-state-wrapper-content-icon">{icon}</span>
            )}
            {title && (
              <span className="grid-empty-state-wrapper-content-title">{title}</span>
            )}

            {message && (
              <span className="grid-empty-state-wrapper-content-message">
                {message}
              </span>
            )}

            {actions && (
              <span className="grid-empty-state-wrapper-content-actions">
                {actions}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
