import React from "react";

import "./entity-list-page.scss";

function EntityListPage(props) {
  const { title, children } = props;

  return (
    <div className="entity-list-page">
      <div className="entity-list-page-title">{title}</div>
      <div className="entity-list-page-table">{children}</div>
    </div>
  );
}

export default EntityListPage;
