import {
  SET_CURRENT_INCIDENT,
  SET_INCIDENT_LIST_PAGE_CONFIGURATION,
  SET_CURRENT_GROUP_INCIDENT_LIST,
  SET_CURRENT_GROUP_INCIDENT_LIST_METRICS,
  SET_CURRENT_INCIDENT_METRICS_DATA,
  SET_CURRENT_INCIDENT_COMMENT_LIST,
  SET_CURRENT_INCIDENT_CORRELATED_METRICS,
  SET_CURRENT_INCIDENT_CONFIG,
  SET_INCIDENT_LIST,
  SET_INCIDENT_LIST_USER_SETTING,
  SET_INCIDENT_PAGINATION_PARAMS,
  SET_INCIDENT_KPI_LIST,
  SET_INCIDENT_RULE_LIST,
  SET_INCIDENT_SLICE_VALUE_LIST,
  SET_INCIDENT_FILE_TICKET_RESULT,
  SET_INCIDENT_CASE_LIST,
  SET_INCIDENT_LIST_FOR_SUMMARY,
  SET_INCIDENT_SAMPLE_DATA,
  SET_CURRENT_INCIDENT_INTEREST_METRIC_SLICE_VALUE_LIST,
  SET_CURRENT_INCIDENT_USER_DEFINED_RELATED_METRICS,
} from "../../actions/incident/incident-action-types";
import { RequestStatus } from "../../utils/enums";

const dataTemplate = {
  currentIncidentMetricsData: null,
};

const initialIncidentState = {
  currentIncident: {},
  currentGroupIncidentList: [],
  currentGroupIncidentListMetrics: [],
  currentIncidentMainMetricsData: { ...dataTemplate },
  currentIncidentTrainingData: { ...dataTemplate },
  currentIncidentCommentList: [],
  currentIncidentSampleData: {
    loading: false,
    previewUuid: null,
    abortController: null,
    data: {},
  },
  currentIncidentCorrelatedMetrics: {
    loading: false,
    data: {},
  },
  currentIncidentUserDefinedRelatedMetrics: {
    loading: false,
    data: {},
  },
  currentIncidentConfig: {
    loading: false,
    data: {},
  },
  currentIncidentInterestMetricSliceValueList: {
    loading: false,
    data: [],
  },
  incidentList: {
    loading: false,
    queryObject: {},
    data: [],
  },
  incidentListPageConfiguration: {
    columns: [],
  },
  incidentPaginationParams: {
    page: 1,
    pageSize: 10,
  },
  incidentListForSummary: {
    loading: false,
    data: [],
  },
  incidentFileTicketResult: {
    status: RequestStatus.UNKNOWN,
    value: "",
  },
  incidentCaseList: [],
  incidentListUserSetting: {},
  kpiList: [],
  ruleList: [],
  sliceValueList: [],
};

export default function incidentReducer(state = initialIncidentState, action) {
  let newState;
  let name;

  switch (action.type) {
    case SET_CURRENT_INCIDENT:
      newState = { ...state, currentIncident: action.currentIncident };
      return newState;

    case SET_CURRENT_GROUP_INCIDENT_LIST_METRICS:
      newState = {
        ...state,
        currentGroupIncidentListMetrics: action.currentGroupIncidentListMetrics,
      };
      return newState;

    case SET_CURRENT_INCIDENT_METRICS_DATA:
      name = action.keyName;
      const { currentIncidentMetricsData } = state[name];
      const newCurrentIncidentMetricsData = {
        ...currentIncidentMetricsData,
        ...action.currentIncidentMetricsData,
      };

      newState = {
        ...state,
        [name]: {
          ...state[name],
          currentIncidentMetricsData: newCurrentIncidentMetricsData,
        },
      };
      return newState;

    case SET_CURRENT_INCIDENT_CORRELATED_METRICS:
      newState = {
        ...state,
        currentIncidentCorrelatedMetrics: action.currentIncidentCorrelatedMetrics,
      };
      return newState;

    case SET_CURRENT_INCIDENT_USER_DEFINED_RELATED_METRICS:
      newState = {
        ...state,
        currentIncidentUserDefinedRelatedMetrics:
          action.currentIncidentUserDefinedRelatedMetrics,
      };
      return newState;

    case SET_CURRENT_INCIDENT_CONFIG:
      newState = {
        ...state,
        currentIncidentConfig: action.currentIncidentConfig,
      };
      return newState;

    case SET_CURRENT_INCIDENT_INTEREST_METRIC_SLICE_VALUE_LIST:
      newState = {
        ...state,
        currentIncidentInterestMetricSliceValueList: action.sliceValueList,
      };
      return newState;

    case SET_INCIDENT_LIST:
      newState = { ...state, incidentList: action.incidentList };
      return newState;

    case SET_INCIDENT_LIST_PAGE_CONFIGURATION:
      newState = {
        ...state,
        incidentListPageConfiguration: action.incidentListPageConfiguration,
      };
      return newState;

    case SET_INCIDENT_PAGINATION_PARAMS:
      newState = {
        ...state,
        incidentPaginationParams: action.incidentPaginationParams,
      };
      return newState;

    case SET_CURRENT_GROUP_INCIDENT_LIST:
      newState = {
        ...state,
        currentGroupIncidentList: action.currentGroupIncidentList,
      };
      return newState;

    case SET_INCIDENT_LIST_USER_SETTING:
      newState = {
        ...state,
        incidentListUserSetting: action.incidentListUserSetting,
      };
      return newState;

    case SET_CURRENT_INCIDENT_COMMENT_LIST:
      newState = {
        ...state,
        currentIncidentCommentList: action.currentIncidentCommentList,
      };
      return newState;

    case SET_INCIDENT_KPI_LIST:
      newState = { ...state, kpiList: action.kpiList };
      return newState;

    case SET_INCIDENT_RULE_LIST:
      newState = { ...state, ruleList: action.ruleList };
      return newState;

    case SET_INCIDENT_SLICE_VALUE_LIST:
      newState = { ...state, sliceValueList: action.sliceValueList };
      return newState;

    case SET_INCIDENT_FILE_TICKET_RESULT:
      newState = {
        ...state,
        incidentFileTicketResult: action.incidentFileTicketResult,
      };
      return newState;

    case SET_INCIDENT_CASE_LIST:
      newState = { ...state, incidentCaseList: action.incidentCaseList };
      return newState;

    case SET_INCIDENT_LIST_FOR_SUMMARY:
      newState = {
        ...state,
        incidentListForSummary: action.incidentListForSummary,
      };
      return newState;

    case SET_INCIDENT_SAMPLE_DATA:
      newState = {
        ...state,
        currentIncidentSampleData: action.incidentSampleData,
      };
      return newState;

    default:
      return state;
  }
}
