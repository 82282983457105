import React from "react";
import { Modal } from "antd";
import { classesName } from "../../utils/css";

import "./modal-dialog.scss";

function WrappedModal(modal, opts = {}) {
  const { mask } = opts;
  const className = classesName("ng-modal-dialog", mask && "mask");
  return <div className={className}>{modal}</div>;
}

function ModalDialog(props) {
  const { children, mask = false, ...restProps } = props;

  return (
    <Modal
      mask={mask}
      modalRender={(modal) => WrappedModal(modal, { mask })}
      {...restProps}
    >
      {children}
    </Modal>
  );
}

export default ModalDialog;
