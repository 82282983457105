import React from "react";

export function LiveIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23905 0.583772C5.49353 0.593138 5.71253 0.766499 5.78005 1.01204L8.52375 10.9891L10.2487 6.59855C10.3364 6.37533 10.5518 6.22853 10.7917 6.22853H13.4167C13.7388 6.22853 14 6.48969 14 6.81186C14 7.13403 13.7388 7.39519 13.4167 7.39519H11.1892L8.96886 13.0467C8.87702 13.2805 8.64585 13.4292 8.39503 13.4159C8.14421 13.4026 7.93008 13.2302 7.86348 12.9881L5.14618 3.10698L3.75789 7.00746C3.67515 7.23993 3.45509 7.39519 3.20833 7.39519H0.583333C0.261167 7.39519 0 7.13403 0 6.81186C0 6.48969 0.261167 6.22853 0.583333 6.22853H2.79678L4.66804 0.971107C4.75343 0.731198 4.98457 0.574405 5.23905 0.583772Z"
        fill="#121224"
      />
    </svg>
  );
}

export function PausedIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66659 2.91666C4.98875 2.91666 5.24992 3.17782 5.24992 3.49999V10.5C5.24992 10.8222 4.98875 11.0833 4.66659 11.0833C4.34442 11.0833 4.08325 10.8222 4.08325 10.5V3.49999C4.08325 3.17782 4.34442 2.91666 4.66659 2.91666ZM9.33325 2.91666C9.65542 2.91666 9.91659 3.17782 9.91659 3.49999V10.5C9.91659 10.8222 9.65542 11.0833 9.33325 11.0833C9.01109 11.0833 8.74992 10.8222 8.74992 10.5V3.49999C8.74992 3.17782 9.01109 2.91666 9.33325 2.91666Z"
        fill="#121224"
      />
    </svg>
  );
}

export function InactiveIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M4.78748 3.96256C4.55968 3.73476 4.19033 3.73476 3.96252 3.96256C3.73472 4.19037 3.73472 4.55971 3.96252 4.78752L9.21252 10.0375C9.44033 10.2653 9.80968 10.2653 10.0375 10.0375C10.2653 9.80971 10.2653 9.44037 10.0375 9.21256L4.78748 3.96256Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0.583374C3.45617 0.583374 0.583336 3.45621 0.583336 7.00004C0.583336 10.5439 3.45617 13.4167 7 13.4167C10.5438 13.4167 13.4167 10.5439 13.4167 7.00004C13.4167 3.45621 10.5438 0.583374 7 0.583374ZM1.75 7.00004C1.75 4.10054 4.10051 1.75004 7 1.75004C9.8995 1.75004 12.25 4.10054 12.25 7.00004C12.25 9.89954 9.8995 12.25 7 12.25C4.10051 12.25 1.75 9.89954 1.75 7.00004Z"
        fill="#121224"
      />
    </svg>
  );
}

export function ErrorIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91667 0.583252C2.27233 0.583252 1.75 1.10559 1.75 1.74992V12.2499C1.75 12.8943 2.27234 13.4166 2.91667 13.4166H6.41667C6.73883 13.4166 7 13.1554 7 12.8333C7 12.5111 6.73883 12.2499 6.41667 12.2499H2.91667V1.74992H8.16667V4.08325C8.16667 4.40542 8.42783 4.66659 8.75 4.66659H11.0833V6.70825C11.0833 7.03042 11.3445 7.29159 11.6667 7.29159C11.9888 7.29159 12.25 7.03042 12.25 6.70825V4.08325C12.25 3.93239 12.1927 3.79491 12.0988 3.69135L9.47526 0.776356C9.36463 0.653439 9.20703 0.583252 9.04167 0.583252H2.91667ZM9.33333 2.36265V3.49992H10.3569L9.33333 2.36265Z"
        fill="#B80739"
      />
      <path
        d="M8.87081 8.33744C8.64301 8.10963 8.27366 8.10963 8.04586 8.33744C7.81805 8.56525 7.81805 8.93459 8.04586 9.1624L9.38338 10.4999L8.04586 11.8374C7.81805 12.0652 7.81805 12.4346 8.04586 12.6624C8.27366 12.8902 8.64301 12.8902 8.87081 12.6624L10.2083 11.3249L11.5459 12.6624C11.7737 12.8902 12.143 12.8902 12.3708 12.6624C12.5986 12.4346 12.5986 12.0652 12.3708 11.8374L11.0333 10.4999L12.3708 9.1624C12.5986 8.93459 12.5986 8.56525 12.3708 8.33744C12.143 8.10963 11.7737 8.10963 11.5459 8.33744L10.2083 9.67496L8.87081 8.33744Z"
        fill="#B80739"
      />
    </svg>
  );
}
