import { ConnectionHealth } from "./enums";

export const AlertingChannelType = Object.freeze({
  SLACK: "slack",
  EMAIL: "email",
  PAGERDUTY: "pagerduty",
  MSTEAMS: "msteams",
  JIRA: "jira",
  SERVICENOW: "servicenow",
  OPSGENIE: "opsgenie",
});

export const AlertingRunStatus = Object.freeze({
  OK: "ok",
  PARTIAL: "partial",
  FAILED: "failed",
});

const alertingChannelDisplayNameMapper = {
  [AlertingChannelType.SLACK]: "Slack",
  [AlertingChannelType.EMAIL]: "Email",
  [AlertingChannelType.PAGERDUTY]: "Pagerduty",
  [AlertingChannelType.MSTEAMS]: "Msteams",
  [AlertingChannelType.JIRA]: "Jira",
  [AlertingChannelType.SERVICENOW]: "ServiceNow",
  [AlertingChannelType.OPSGENIE]: "Opsgenie",
};

export function getAlertingChannelDisplayType(type) {
  return alertingChannelDisplayNameMapper[type] || type;
}

export function getAlertingConnectionHealth(integration) {
  const runStatus = integration?.status?.runStatus ?? AlertingRunStatus.OK;

  if (runStatus === AlertingRunStatus.OK) {
    return ConnectionHealth.SUCCESS;
  }

  if ([AlertingRunStatus.FAILED, AlertingRunStatus.PARTIAL].includes(runStatus)) {
    return ConnectionHealth.ERROR;
  }

  return ConnectionHealth.UNKNOWN;
}
