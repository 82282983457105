import { Component } from "react";
import auth0 from "../../utils/auth0";

class UniversalAuth0Login extends Component {
  async componentDidMount() {
    const { clientId, hostedDomain, onSuccess, onFailure, fromLocation } = this.props;
    try {
      await auth0.init(clientId, hostedDomain);
      const auth0Response = await auth0.tryLoginWithDirect(fromLocation);
      auth0Response.idToken && onSuccess && onSuccess(auth0Response);
    } catch (err) {
      console.log(`Catch unknown exception.`);
      onFailure && onFailure(err);
    }
  }

  render() {
    return null;
  }
}

export default UniversalAuth0Login;
