import { Children, useEffect, useState } from "react";
import { CloseOutlined, UpOutlined } from "@ant-design/icons";
import ButtonIcon from "../../../../components/button/button-icon";
import TakeoverPanel from "../../../../components/takeover/takeover-panel";
import {
  collectionWindowOptions,
  DbColumTypeOptions,
  getDataCollectionOptions,
  getOptionByValue,
} from "../../../../utils/options";
import { aggregationWindowOptions } from "../../../../components/metric/utils";
import {
  getDisplayTimeFromSecond,
  getStringFromTimeStamp,
} from "../../../../utils/time";
import {
  DIFF_STATES,
  extractDiffState,
  sideBySide,
  combineDiffStates,
} from "../../../../utils/compare";
import { classesName } from "../../../../utils/css";
import { DataProfileModeOptions } from "../../../../utils/data-profiling";
import { QueryScope } from "../../../../utils/enums";

import "./table-changelog.scss";

const DiffClassMap = {
  [DIFF_STATES.ADDED]: "added",
  [DIFF_STATES.DELETED]: "deleted",
};

const ChangeStates = [DIFF_STATES.ADDED, DIFF_STATES.DELETED];

function ChangelogConfigCollapse(props) {
  const { label, children, labelClassName = null, expanded = false } = props;
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    if (!isExpanded) {
      const hasChanges = Children.toArray(children).some(
        (child) =>
          child.type === ChangelogConfigField &&
          ChangeStates.includes(child.props.diffState)
      );
      hasChanges && setIsExpanded(hasChanges);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="simple-collapse-container">
      <div
        className={classesName("simple-collapse-label", labelClassName)}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {label} <UpOutlined rotate={isExpanded ? 0 : -180} />
      </div>
      {isExpanded && <div className="simple-collapse-content">{children}</div>}
    </div>
  );
}

function ChangelogConfigField(props) {
  const { label, value, diffState = null } = props;

  return (
    <div>
      {label && <span>{label} - </span>}
      <span className={classesName(DiffClassMap[diffState])}>{value}</span>
    </div>
  );
}

function getDataCollectionRepr(collectionModeType) {
  const options = getDataCollectionOptions();
  return getOptionByValue(options, collectionModeType)?.label;
}

function getAggregationIntervalRepr(window) {
  return getOptionByValue(aggregationWindowOptions, window)?.label;
}

function getTimestampTypeRepr(timestampColumnType) {
  return getOptionByValue(DbColumTypeOptions, timestampColumnType)?.label;
}

function getDataCollectionWindowRepr(collectionWindow) {
  return getOptionByValue(collectionWindowOptions, collectionWindow)?.label;
}

function getBooleanRepr(state) {
  return state ? "Enabled" : "Disabled";
}

function getActiveRepr(state) {
  return state ? "Active" : "Inactive";
}

function getDataProfileModeRepr(queryScope) {
  return getOptionByValue(DataProfileModeOptions, queryScope)?.label;
}

function getTimeRangeRepr(timeRange) {
  const startTimeRepr = getStringFromTimeStamp(timeRange.startTime);
  const endTimeRepr = getStringFromTimeStamp(timeRange.endTime);
  return `${startTimeRepr} to ${endTimeRepr}`;
}

function ChangelogConfigDetails(props) {
  const { config, title, diffState = null, isCreated = false } = props;
  const sqlDiffState = extractDiffState(diffState, "sql");
  const isFullTable = config.queryScope === QueryScope.FULL_TABLE;
  const isIncremental = config.queryScope === QueryScope.TIME_RANGE;

  return (
    <div className="table-changelog-config-details">
      <div className="table-changelog-config-details-title">{title}</div>
      <div className="table-changelog-config-details-config">
        <ChangelogConfigCollapse label="Table settings" expanded={isCreated}>
          <ChangelogConfigField
            label="Table"
            value={getActiveRepr(config.enabled)}
            diffState={extractDiffState(diffState, "enabled")}
          />
          <ChangelogConfigField
            label="Data collection schedule"
            value={getDataCollectionRepr(config.collectionMode.type)}
            diffState={extractDiffState(diffState, "collectionMode.type")}
          />
          {config.collectionWindow !== undefined && (
            <ChangelogConfigField
              label="Data collection window"
              value={getDataCollectionWindowRepr(config.collectionWindow)}
              diffState={extractDiffState(diffState, "collectionWindow")}
            />
          )}
          {config.collectionMode.crontabExpression !== undefined &&
            config.collectionMode.timezone !== undefined && (
              <ChangelogConfigField
                label="Cron input"
                value={`[ ${config.collectionMode.crontabExpression} ] ${config.collectionMode.timezone}`}
                diffState={combineDiffStates(
                  extractDiffState(diffState, "collectionMode.crontabExpression"),
                  extractDiffState(diffState, "collectionMode.timezone")
                )}
              />
            )}
          {isIncremental && config.window !== undefined && (
            <ChangelogConfigField
              label="Aggregation interval"
              value={getAggregationIntervalRepr(config.window)}
              diffState={extractDiffState(diffState, "window")}
            />
          )}
          {isIncremental && config.timezone !== undefined && (
            <ChangelogConfigField
              label="Aggregation timezone"
              value={config.timezone}
              diffState={extractDiffState(diffState, "timezone")}
            />
          )}
          {isIncremental && config.syncDelay !== undefined && (
            <ChangelogConfigField
              label="Eval delay"
              value={getDisplayTimeFromSecond(config.syncDelay)}
              diffState={extractDiffState(diffState, "syncDelay")}
            />
          )}
          {isIncremental && Boolean(config.timestampColumn) && (
            <ChangelogConfigField
              label="Timestamp"
              value={config.timestampColumn}
              diffState={extractDiffState(diffState, "timestampColumn")}
            />
          )}
          {isIncremental && config.timestampColumnType !== undefined && (
            <ChangelogConfigField
              label="Timestamp type"
              value={getTimestampTypeRepr(config.timestampColumnType)}
              diffState={extractDiffState(diffState, "timestampColumnType")}
            />
          )}
          {isIncremental && config.dataTimezone !== undefined && (
            <ChangelogConfigField
              label="Timezone"
              value={config.dataTimezone}
              diffState={extractDiffState(diffState, "dataTimezone")}
            />
          )}
          {isFullTable && config.pollingWindow !== undefined && (
            <ChangelogConfigField
              label="Polling interval"
              value={getAggregationIntervalRepr(config.pollingWindow)}
              diffState={extractDiffState(diffState, "pollingWindow")}
            />
          )}
          {isFullTable && config.pollingTimezone !== undefined && (
            <ChangelogConfigField
              label="Polling timezone"
              value={config.pollingTimezone}
              diffState={extractDiffState(diffState, "pollingTimezone")}
            />
          )}
          {isFullTable && config.pollingTimezone !== undefined && (
            <ChangelogConfigField
              label="Polling delay"
              value={getDisplayTimeFromSecond(config.pollingDelay)}
              diffState={extractDiffState(diffState, "pollingDelay")}
            />
          )}
        </ChangelogConfigCollapse>
        <ChangelogConfigCollapse label="Data profile" expanded={isCreated}>
          <ChangelogConfigField
            label="Data profile"
            value={getBooleanRepr(config.dataProfiler.enabled)}
            diffState={extractDiffState(diffState, "dataProfiler.enabled")}
          />
          {config.dataProfiler.queryScope !== undefined && (
            <ChangelogConfigField
              label="Mode"
              value={getDataProfileModeRepr(config.dataProfiler.queryScope)}
              diffState={extractDiffState(diffState, "dataProfiler.queryScope")}
            />
          )}
          {config.dataProfiler.timeRange !== undefined && (
            <ChangelogConfigField
              label="Time range"
              value={getTimeRangeRepr(config.dataProfiler.timeRange)}
              diffState={combineDiffStates(
                extractDiffState(diffState, "dataProfiler.timeRange.startTime", {
                  takeLast: true,
                }),
                extractDiffState(diffState, "dataProfiler.timeRange.endTime", {
                  takeLast: true,
                })
              )}
            />
          )}
          {Boolean(config.dataProfiler.timestampColumn) && (
            <ChangelogConfigField
              label="Timestamp column"
              value={config.dataProfiler.timestampColumn}
              diffState={extractDiffState(diffState, "dataProfiler.timestampColumn")}
            />
          )}
        </ChangelogConfigCollapse>
        <ChangelogConfigCollapse label="Auto metrics" expanded={isCreated}>
          <ChangelogConfigField
            label="Data delay"
            value={getBooleanRepr(config.dataDelay.enabled)}
            diffState={extractDiffState(diffState, "dataDelay.enabled")}
          />
          <ChangelogConfigField
            label="Data volume"
            value={getBooleanRepr(config.volume.enabled)}
            diffState={extractDiffState(diffState, "volume.enabled")}
          />
          <ChangelogConfigField
            label="Byte count"
            value={getBooleanRepr(config.metadataMetrics.byteCount)}
            diffState={extractDiffState(diffState, "metadataMetrics.byteCount")}
          />
          <ChangelogConfigField
            label="Row count"
            value={getBooleanRepr(config.metadataMetrics.rowCount)}
            diffState={extractDiffState(diffState, "metadataMetrics.rowCount")}
          />
          <ChangelogConfigField
            label="Update delay"
            value={getBooleanRepr(config.metadataMetrics.updateDelay)}
            diffState={extractDiffState(diffState, "metadataMetrics.updateDelay")}
          />
          <ChangelogConfigField
            label="Column activity"
            value={getBooleanRepr(config.metadataMetrics.schemaChange.enabled)}
            diffState={extractDiffState(
              diffState,
              "metadataMetrics.schemaChange.enabled"
            )}
          />
        </ChangelogConfigCollapse>
        <ChangelogConfigCollapse
          label="SQL"
          labelClassName={DiffClassMap[sqlDiffState]}
          expanded={isCreated}
        >
          <ChangelogConfigField value={config.sql} diffState={sqlDiffState} />
        </ChangelogConfigCollapse>
      </div>
    </div>
  );
}

export function TableChangelogTakeoverView(props) {
  const { changelog, onClose } = props;
  const { before, after, action } = changelog;
  const diffState = before && after ? sideBySide(before, after) : null;

  return (
    <TakeoverPanel
      title="Table settings"
      cornerControls={
        <ButtonIcon
          icon={<CloseOutlined />}
          onClick={onClose}
          data-testid="draft-modal-dialog-close-button"
        />
      }
      className="table-changelog-container"
    >
      {Boolean(before) && (
        <>
          <ChangelogConfigDetails
            config={before}
            title="OLD TABLE SETTINGS"
            diffState={diffState?.left}
          />
          <span className="table-changelog-divider" />
        </>
      )}
      <ChangelogConfigDetails
        config={after}
        title="NEW TABLE SETTINGS"
        diffState={diffState?.right}
        isCreated={action === "create"}
      />
    </TakeoverPanel>
  );
}
