import React from "react";
import {
  AggregationWindowConfigItem,
  CustomSqlConfigItem,
  DataCollectionScheduleConfigItem,
  MissingValueFillingConfigItem,
  PartitionsConfigItem,
  QueryTimezoneConfigItem,
  QueryScopeConfigItem,
  SeasonalityConfigItem,
  SliceByColumnsConfigItem,
  SynchronizationDelayConfigItem,
  TimestampColumnConfigItem,
  ValueColumnsConfigItem,
  CustomScheduleCollectionConfigItem,
  BackfillDurationConfigItem,
  DataCollectionWindowConfigItem,
  TimestampTimezoneConfigItem,
} from "../../fields/";
import { MetricConfigType } from "../../../../utils/enums";
import { metricQueryScopeOptions } from "../../../../utils/options";
import { aggregationWindowOptions } from "../../utils";
import SummaryViewSectionTitle from "../summary-view-section-title";
import { isPartitionConfigEnabled } from "../../../../utils/datasource";
import { isTriggeredCollectionMode } from "../../../../utils/general";
import { isDataCollectionWindowRequired } from "../../metric-utils";

function CustomSqlMetricConfigDataAssetSummaryCardContent(props) {
  const { configData, dataSourceList } = props;

  const dataSourceInfo = dataSourceList.find(
    (dataSource) => configData.config?.sources[0] === dataSource.metadata.uuid
  );
  const isPartitionRequired =
    dataSourceInfo && isPartitionConfigEnabled(dataSourceInfo.config.connection.type);
  const isMetricConfigType =
    configData.config.configType === MetricConfigType.METRIC_CONFIG;
  const isFullTableMetricConfigType =
    configData.config.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG;

  return (
    <>
      <CustomSqlConfigItem value={configData.config.table.sql} isEdit={false} />
      <SummaryViewSectionTitle title="Metric Configuration" />
      {isMetricConfigType && (
        <AggregationWindowConfigItem
          value={configData.config?.table.aggregationWindow}
          options={aggregationWindowOptions}
          isEdit={false}
        />
      )}
      {isMetricConfigType && (
        <QueryTimezoneConfigItem
          value={configData.config.queryTimezone}
          isEdit={false}
        />
      )}
      <ValueColumnsConfigItem
        label="Column - "
        value={configData.config.valueColumns}
        columnList={[]}
        isEdit={false}
      />
      <DataCollectionScheduleConfigItem
        value={configData.config.collectionMode}
        isEdit={false}
      />
      {isDataCollectionWindowRequired(configData) && (
        <DataCollectionWindowConfigItem
          value={configData.config.collectionWindow}
          isEdit={false}
        />
      )}
      <CustomScheduleCollectionConfigItem
        value={configData.config.collectionMode}
        isEdit={false}
      />
      {isMetricConfigType && (
        <>
          <TimestampColumnConfigItem
            value={configData.config.timestampColumn}
            columnList={[]}
            isEdit={false}
          />
          <TimestampTimezoneConfigItem
            value={configData.config.dataTimezone}
            isEdit={false}
          />
        </>
      )}
      <MissingValueFillingConfigItem
        value={configData.config.missingValueFilling}
        isEdit={false}
      />
      {isMetricConfigType && (
        <SynchronizationDelayConfigItem
          value={configData.config.synchronizationDelay}
          isEdit={false}
        />
      )}
      <SeasonalityConfigItem
        status={configData.status}
        value={configData.config.seasonality || null}
        isEdit={false}
      />
      {configData.config.sliceByColumns?.length > 0 && (
        <SliceByColumnsConfigItem
          label="Slice"
          value={configData.config.sliceByColumns}
          isEdit={false}
        />
      )}
      {isMetricConfigType && isPartitionRequired && (
        <PartitionsConfigItem
          value={configData.config.partitions}
          partitionTimezone={configData.config.partitionTimezone}
          partitionOffsets={configData.config.partitionOffsets}
          isEdit={false}
        />
      )}
      <QueryScopeConfigItem
        value={configData.config.configType}
        options={metricQueryScopeOptions}
        isEdit={false}
      />
      {isFullTableMetricConfigType &&
        !isTriggeredCollectionMode(configData.config.collectionMode) && (
          <>
            <SynchronizationDelayConfigItem
              label="Polling delay"
              value={configData.config.pollingDelay}
              isEdit={false}
            />
            <QueryTimezoneConfigItem
              label="Polling timezone"
              value={configData.config.pollingTimezone}
              isEdit={false}
            />
            <AggregationWindowConfigItem
              label="Polling interval"
              value={configData.config.pollingWindow}
              options={aggregationWindowOptions}
              isEdit={false}
            />
          </>
        )}
      <BackfillDurationConfigItem
        value={configData.config.backfillDuration}
        isEdit={false}
      />
    </>
  );
}

export default CustomSqlMetricConfigDataAssetSummaryCardContent;
