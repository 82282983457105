import React from "react";
import LegacyModalDialogAdapter from "../../../components/legacy-adapter/legacy-modal-dialog-adapter";
import IntegrationDialogFooter from "../integration-dialog-footer";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../components/modal-dialog/modal-field-group";
import {
  LabeledInput,
  LabeledSelect,
} from "../../../components/labeled-control/labeled-control";
import { getAppDomainName } from "../../../utils/general";
import { TimezoneOptions } from "../../../utils/options";
import { AlertingChannelType } from "../../../utils/alerting";

import "./pagerduty-integration-basic-dialog.scss";

const SeverityOptions = [
  { label: "Info", value: "info" },
  { label: "Warning", value: "warning" },
  { label: "Error", value: "error" },
  { label: "Critical", value: "critical" },
];

function PagerDutyIntegrationBasicDialogBodyComponent(props) {
  const {
    title,
    onTitleChange,
    routingKey,
    onRoutingKeyChange,
    timezone,
    onTimezoneChange,
    severity,
    onSeverityChange,
    description,
    onDescriptionChange,
  } = props;

  return (
    <ModalFieldGroup>
      <ModalFieldRows>
        <LabeledInput
          label="Name"
          value={title}
          onChange={(e) => onTitleChange(e.target.value)}
        />
        <LabeledInput
          label="Integration Key"
          value={routingKey}
          onChange={(e) => onRoutingKeyChange(e.target.value)}
        />
        <LabeledSelect
          label="Severity"
          options={SeverityOptions}
          value={severity}
          onChange={onSeverityChange}
        />
        <LabeledInput
          label="Description"
          value={description}
          onChange={(e) => onDescriptionChange(e.target.value)}
        />
        <LabeledSelect
          showSearch
          label="Timezone"
          options={TimezoneOptions}
          value={timezone}
          onChange={onTimezoneChange}
        />
      </ModalFieldRows>
    </ModalFieldGroup>
  );
}

function PagerDutyIntegrationBasicDialog(props) {
  let {
    defaultData: {
      title: defaultTitle,
      routingKey: defaultRoutingKey,
      timezone: defaultTimeZone,
      description: defaultDescription = "",
      severity: defaultSeverity,
      id,
    },
    dialogTitle = "",
    className = "",
    triggerComponent,
    onOkClicked,
    onPreview,
    OKText,
    modalIsOpen,
    setIsOpen,
    enableSave,
    enablePreview,
  } = props;

  const [title, setTitle] = React.useState(defaultTitle);
  const [routingKey, setRoutingKey] = React.useState(defaultRoutingKey);
  const [timezone, setTimezone] = React.useState(defaultTimeZone);
  const [severity, setSeverity] = React.useState(defaultSeverity);
  const [description, setDescription] = React.useState(defaultDescription);

  const [modalIsOpenDefault, setIsOpenDefault] = React.useState(false);
  if (modalIsOpen === undefined || setIsOpen === undefined) {
    modalIsOpen = modalIsOpenDefault;
    setIsOpen = setIsOpenDefault;
  } else {
    triggerComponent = null;
  }

  function getNewPagerDutyIntegration() {
    return {
      title,
      type: AlertingChannelType.PAGERDUTY,
      routingKey,
      severity: severity,
      timezone: timezone,
      description: description,
      domainName: getAppDomainName(),
      ...(id !== undefined ? { id } : {}),
    };
  }

  function closeModal() {
    setTitle(defaultTitle);
    setRoutingKey(defaultRoutingKey);
    setTimezone(defaultTimeZone);
    setSeverity(defaultSeverity);
    setDescription(defaultDescription);
    setIsOpen(false);
  }

  function onOkClick() {
    if (onOkClicked) {
      const newPagerDutyIntegration = getNewPagerDutyIntegration();
      onOkClicked(newPagerDutyIntegration);
    }
    closeModal();
  }

  function onPreviewClick() {
    if (onPreview) {
      const newPagerDutyIntegration = getNewPagerDutyIntegration();
      onPreview(newPagerDutyIntegration);
    }
  }

  return (
    <LegacyModalDialogAdapter
      title={dialogTitle}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={`pager-duty-integration-basic-dialog-container ${className}`}
      triggerComponent={triggerComponent}
      contentComponent={
        <PagerDutyIntegrationBasicDialogBodyComponent
          title={title}
          onTitleChange={setTitle}
          routingKey={routingKey}
          onRoutingKeyChange={setRoutingKey}
          timezone={timezone}
          onTimezoneChange={setTimezone}
          severity={severity}
          onSeverityChange={setSeverity}
          description={description}
          onDescriptionChange={setDescription}
        />
      }
      footerComponent={
        <IntegrationDialogFooter
          onPreviewClick={onPreviewClick}
          onCancelClick={closeModal}
          onOkClick={onOkClick}
          OkText={OKText}
          enablePreview={enablePreview}
          enableSave={enableSave}
        />
      }
    />
  );
}

export default PagerDutyIntegrationBasicDialog;
