import queryString from "query-string";

export function getIncidentDetailSettingFromQueryString(queryStr) {
  const {
    filterUuid = "",
    previewUuid = "",
    action = "",
  } = queryString.parse(queryStr);

  return {
    filterUuid,
    previewUuid,
    action,
  };
}
