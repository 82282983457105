export function indexBy(iterable, keyFn, valueFn = (x) => x) {
  const indexed = {};
  for (let item of iterable) {
    indexed[keyFn(item)] = valueFn(item);
  }
  return indexed;
}

export function countBy(iterable, keyFn, countFn) {
  const counts = {};
  for (let item of iterable) {
    const key = keyFn(item);
    const count = countFn(item);
    if (!counts.hasOwnProperty(key)) {
      counts[key] = 0;
    }
    counts[key] += count;
  }
  return counts;
}

export function collectStats(iterable, statsFnMap) {
  const stats = {};
  for (let statLabel of Object.keys(statsFnMap)) {
    stats[statLabel] = 0;
  }
  for (let item of iterable) {
    for (let [statLabel, statFn] of Object.entries(statsFnMap)) {
      stats[statLabel] += statFn(item);
    }
  }
  return stats;
}
