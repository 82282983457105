import React, { Component } from "react";
import { Tabs } from "antd";
import { default as Icon } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { TakeoverWidth } from "../../utils/enums";
import { capitalizeFirstLetter } from "../../utils/general";
import { getTimePeriodFromString } from "../../utils/time";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import NotificationMainTab from "./notification-main-tab";
import NotificationConfigurationTab from "./notification-configuration-tab";
import NotificationDetailPanel from "./notification-detail-panel";
import queryString from "query-string";
import { EVENT, trackEvent } from "../../utils/telemetry";

import "./notification-list.scss";

const NotificationTabKey = Object.freeze({
  MAIN: "main",
  CONFIGURATION: "configuration",
});

function NotificationMainTabIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <g clipPath="url(#clip0_859_7221)">
        <path
          d="M6.99989 0.592407C6.77056 0.592407 6.54123 0.675933 6.3744 0.843058L0.842984 6.37447C0.497651 6.7198 0.497651 7.2807 0.842984 7.62545L6.3744 13.158C6.54123 13.3248 6.76364 13.4166 6.99989 13.4166C7.23614 13.4166 7.45796 13.3248 7.62537 13.158L13.1579 7.62545C13.5033 7.28011 13.5033 6.71922 13.1579 6.37447L7.62537 0.843058C7.45825 0.675933 7.22921 0.592407 6.99989 0.592407ZM6.99989 1.86617L12.1337 6.99996L6.99989 12.1337L1.8661 6.99996L6.99989 1.86617ZM6.41655 4.08329V7.58329H7.58322V4.08329H6.41655ZM6.41655 8.74996V9.91663H7.58322V8.74996H6.41655Z"
          fill="#121224"
        />
      </g>
      <defs>
        <clipPath id="clip0_859_7221">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function NotificationConfigurationTabIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <g clipPath="url(#clip0_859_8508)">
        <path
          d="M11.0833 0C9.47917 0 8.16667 1.3125 8.16667 2.91667C8.16667 4.52083 9.47917 5.83333 11.0833 5.83333C12.6875 5.83333 14 4.52083 14 2.91667C14 1.3125 12.6875 0 11.0833 0ZM10.6481 1.16667H11.5197L11.4229 3.49202H10.7438L10.6481 1.16667ZM2.33333 2.33333C1.68992 2.33333 1.16667 2.85658 1.16667 3.5V9.33333C1.16667 9.97675 1.68992 10.5 2.33333 10.5H0V11.6667H14V10.5H11.6667C12.3101 10.5 12.8333 9.97675 12.8333 9.33333V6.60124C12.4705 6.7739 12.0796 6.89437 11.6678 6.95329V9.33333H2.33333V3.5H7.04671C7.0193 3.30925 7 3.115 7 2.91667C7 2.71833 7.0193 2.52408 7.04671 2.33333H2.33333ZM11.0833 3.92839C11.144 3.92839 11.1995 3.93815 11.2485 3.95915C11.2981 3.98015 11.34 4.00901 11.375 4.0446C11.4094 4.08018 11.4361 4.12206 11.4548 4.17106C11.4734 4.21948 11.4832 4.27282 11.4832 4.33057C11.4827 4.38657 11.474 4.43765 11.4548 4.48665C11.4361 4.53565 11.4094 4.57754 11.375 4.61312C11.34 4.6487 11.2981 4.6776 11.2485 4.69743C11.1995 4.71726 11.144 4.72705 11.0833 4.72705C11.0221 4.72705 10.9677 4.71785 10.9181 4.69743C10.8697 4.67818 10.8272 4.6487 10.7928 4.61312C10.7584 4.57754 10.7312 4.53565 10.7119 4.48665C10.6927 4.43765 10.6834 4.38598 10.6834 4.33057C10.6834 4.27282 10.6932 4.21948 10.7119 4.17106C10.7312 4.12206 10.7584 4.08018 10.7928 4.0446C10.8272 4.00901 10.8697 3.98015 10.9181 3.95915C10.9677 3.93815 11.0227 3.92839 11.0833 3.92839Z"
          fill="#121224"
        />
      </g>
      <defs>
        <clipPath id="clip0_859_8508">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabKey: "",
      timePeriod: {
        startTime: null,
        endTime: null,
      },
      localFilterSetting: {
        searchName: [],
        filterName: [],
        systemEventType: [],
      },
      queryObjectList: [],
      workspaceUuid: "",
    };

    this.currentTime = new Date();
    this.onTimeSelectorChanged = this.onTimeSelectorChanged.bind(this);
    this.onSearchItemChanged = this.onSearchItemChanged.bind(this);
    this.onOpenDetailClick = this.onOpenDetailClick.bind(this);
    this.updateNotificationList = this.updateNotificationList.bind(this);
    this.updateNotificationMonitorItem = this.updateNotificationMonitorItem.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
  }

  static getQueryObjectFromState(state) {
    const {
      timePeriod: { startTime, endTime },
    } = state;

    return {
      startTime: getUnixTime(startTime),
      endTime: getUnixTime(endTime),
    };
  }

  static getParamsFromUrl(locationSearchString) {
    const queryParams = queryString.parse(locationSearchString);
    const tabKey = queryParams.tabKey || NotificationTabKey.MAIN;
    const parsedStartTimestamp = parseInt(queryParams.startTime);
    const parsedEndTimestamp = parseInt(queryParams.endTime);
    let startTimestamp;
    let endTimestamp;
    if (isNaN(parsedStartTimestamp) || isNaN(parsedEndTimestamp)) {
      const defaultTimePeriod = getTimePeriodFromString("1d");
      startTimestamp = defaultTimePeriod.startTimestamp;
      endTimestamp = defaultTimePeriod.endTimestamp;
    } else {
      startTimestamp = parsedStartTimestamp;
      endTimestamp = parsedEndTimestamp;
    }

    const timePeriod = {
      startTime: fromUnixTime(startTimestamp),
      endTime: fromUnixTime(endTimestamp),
    };

    const parsedSystemEventType = (queryParams.levels || "")
      .split(",")
      .map((level) => capitalizeFirstLetter(level.trim()))
      .filter(Boolean);

    return {
      tabKey,
      timePeriod,
      localFilterSetting: {
        searchName: [],
        filterName: [],
        systemEventType: parsedSystemEventType,
      },
    };
  }

  static updateLocationUrl(props, state) {
    const {
      tabKey,
      timePeriod,
      localFilterSetting: { systemEventType = [] },
    } = state;

    const { location, history } = props;
    const queryStringObject = { tabKey };
    if (tabKey === NotificationTabKey.MAIN) {
      if (timePeriod.startTime && timePeriod.endTime) {
        queryStringObject.startTime = getUnixTime(timePeriod.startTime);
        queryStringObject.endTime = getUnixTime(timePeriod.endTime);
      }

      if (systemEventType?.length > 0) {
        queryStringObject.levels = systemEventType
          .map((item) => item.toLowerCase())
          .join(",");
      }
    }
    const newLocation = `${location.pathname}?${queryString.stringify(
      queryStringObject
    )}`;
    history.push(newLocation);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: {
        params: { workspaceUuid },
      },
      location: { search: locationSearchString },
    } = props;
    if (workspaceUuid && workspaceUuid !== state.workspaceUuid) {
      const { tabKey, timePeriod, localFilterSetting } =
        Notification.getParamsFromUrl(locationSearchString);
      const newState = {
        ...state,
        tabKey,
        timePeriod,
        localFilterSetting,
        workspaceUuid,
      };

      props.getNotificationList(
        workspaceUuid,
        Notification.getQueryObjectFromState(newState)
      );

      props.getIntegrationList(workspaceUuid);
      props.getNotificationMonitorList(workspaceUuid);

      return newState;
    }

    return null;
  }

  updateNotificationList() {
    const queryObject = Notification.getQueryObjectFromState(this.state);
    this.props.getNotificationList(this.props.match.params.workspaceUuid, queryObject);
  }

  updateNotificationMonitorItem(uuid, newNotificationMonitorItem) {
    this.props.updateNotificationMonitorItem(
      this.props.match.params.workspaceUuid,
      uuid,
      newNotificationMonitorItem
    );
  }

  onTimeSelectorChanged(timePeriod) {
    this.setState(
      {
        timePeriod,
        queryObjectList: [],
      },
      () => {
        Notification.updateLocationUrl(this.props, this.state);
        this.updateNotificationList();
      }
    );
  }

  onSearchItemChanged(newLocalFilterSetting) {
    this.setState({ localFilterSetting: newLocalFilterSetting }, () => {
      Notification.updateLocationUrl(this.props, this.state);
    });
  }

  onTabChanged(newTabKey) {
    const { tabKey } = this.state;

    if (newTabKey !== tabKey) {
      trackEvent(
        newTabKey === "main"
          ? EVENT.SELECT_SYSTEM_EVENTS_TAB
          : EVENT.SELECT_CONFIGURATION_TAB,
        {
          worspace_id: this.state.workspaceUuid,
        }
      );

      this.setState({ tabKey: newTabKey }, () => {
        Notification.updateLocationUrl(this.props, this.state);
      });
    }
  }

  onOpenDetailClick(eventRecord) {
    const { workspaceUserPermissions, history, closeTakeover } = this.props;
    const { workspaceUuid } = this.state;
    this.props.openWorkspaceTakeover(
      <NotificationDetailPanel
        eventUuid={eventRecord.eventUuid}
        workspaceUserPermissions={workspaceUserPermissions}
        history={history}
        workspaceUuid={workspaceUuid}
        closeTakeover={closeTakeover}
      />,
      TakeoverWidth.NORMAL,
      () => closeTakeover()
    );
  }

  render() {
    const { tabKey } = this.state;
    const {
      workspaceUserPermissions,
      integrationList,
      notificationList,
      notificationMonitorList,
    } = this.props;
    const { workspaceUuid, timePeriod, localFilterSetting } = this.state;
    const { history, location } = this.props;

    const tabList = [
      {
        label: `System Events (${notificationList.data.length})`,
        key: NotificationTabKey.MAIN,
        icon: <Icon component={NotificationMainTabIcon} />,
      },
      {
        label: "Configuration",
        key: NotificationTabKey.CONFIGURATION,
        icon: <Icon component={NotificationConfigurationTabIcon} />,
      },
    ];

    return (
      <div className="notification-body-view">
        <div className="notification-tab-header-view-container">
          <Tabs className="lightup" activeKey={tabKey} onTabClick={this.onTabChanged}>
            {tabList.map(({ key, label, icon }) => (
              <Tabs.TabPane
                tab={
                  <span>
                    {icon}
                    {label}
                  </span>
                }
                key={key}
              />
            ))}
          </Tabs>
        </div>
        <div className="notification-tab-content-view-container">
          {tabKey === NotificationTabKey.MAIN && (
            <NotificationMainTab
              workspaceUuid={workspaceUuid}
              workspaceUserPermissions={workspaceUserPermissions}
              timePeriod={timePeriod}
              currentTime={this.currentTime}
              onTimeSelectorChanged={this.onTimeSelectorChanged}
              searchItem={localFilterSetting}
              onSearchItemChanged={this.onSearchItemChanged}
              history={history}
              location={location}
              notificationList={notificationList}
              onOpenDetailClick={this.onOpenDetailClick}
            />
          )}
          {tabKey === NotificationTabKey.CONFIGURATION && (
            <NotificationConfigurationTab
              workspaceUserPermissions={workspaceUserPermissions}
              workspaceUuid={workspaceUuid}
              integrationList={integrationList}
              notificationMonitorList={notificationMonitorList}
              updateNotificationMonitorItem={this.updateNotificationMonitorItem}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Notification);
