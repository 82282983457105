import { connect } from "react-redux";
import {
  addAppUser,
  addAppUserToWorkspace,
  deleteWorkspace,
  getAppUserList,
  updateWorkspace,
} from "../../actions/admin/admin-action";
import EditWorkspace from "./edit-workspace";
import { getUserList } from "../../actions/user/user-action";
import { getIntegrationList } from "../../actions/integration/integration-action";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  userList: state.userReducer.userList.data,
  userListLoading: state.userReducer.userList.loading,
  userListUpdatedAt: state.userReducer.userList.updatedAt,
  appUserList: state.adminReducer.appUserList,
  workspaceList: state.adminReducer.userWorkspaceList.data,
  integrationList: state.integrationReducer.integrationList.data,
});

const mapDispatchToProps = (dispatch) => ({
  addAppUser: (newUser) => dispatch(addAppUser(newUser)),
  getAppUserList: () => dispatch(getAppUserList()),
  getUserList: (workspaceUuid) => dispatch(getUserList(workspaceUuid)),
  addAppUserToWorkspace: (workspaceUuid, user) =>
    dispatch(addAppUserToWorkspace(workspaceUuid, user)),
  deleteWorkspace: (workspaceUuid) => dispatch(deleteWorkspace(workspaceUuid, false)),
  updateWorkspace: (uuid, workspace, updateUserOperations) =>
    dispatch(updateWorkspace(uuid, workspace, updateUserOperations, false)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkspace);
