export function KeyboardIcon() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        d="M2.91671 5.54158C2.91671 5.21942 3.17787 4.95825 3.50004 4.95825H4.08337C4.40554 4.95825 4.66671 5.21942 4.66671 5.54158C4.66671 5.86375 4.40554 6.12492 4.08337 6.12492H3.50004C3.17787 6.12492 2.91671 5.86375 2.91671 5.54158Z"
        fill="#121224"
      />
      <path
        d="M6.12504 4.95825C5.80287 4.95825 5.54171 5.21942 5.54171 5.54158C5.54171 5.86375 5.80287 6.12492 6.12504 6.12492H6.70837C7.03054 6.12492 7.29171 5.86375 7.29171 5.54158C7.29171 5.21942 7.03054 4.95825 6.70837 4.95825H6.12504Z"
        fill="#121224"
      />
      <path
        d="M7.87504 5.54158C7.87504 5.21942 8.13621 4.95825 8.45837 4.95825H10.5C10.8222 4.95825 11.0834 5.21942 11.0834 5.54158C11.0834 5.86375 10.8222 6.12492 10.5 6.12492H8.45837C8.13621 6.12492 7.87504 5.86375 7.87504 5.54158Z"
        fill="#121224"
      />
      <path
        d="M3.50004 7.58325C3.17787 7.58325 2.91671 7.84442 2.91671 8.16658C2.91671 8.48875 3.17787 8.74992 3.50004 8.74992H10.5C10.8222 8.74992 11.0834 8.48875 11.0834 8.16658C11.0834 7.84442 10.8222 7.58325 10.5 7.58325H3.50004Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583374 2.04159C0.583374 1.71942 0.844541 1.45825 1.16671 1.45825H12.8334C13.1555 1.45825 13.4167 1.71942 13.4167 2.04159V11.3749C13.4167 11.6971 13.1555 11.9582 12.8334 11.9582H1.16671C0.844541 11.9582 0.583374 11.6971 0.583374 11.3749V2.04159ZM1.75004 2.62492V10.7916H12.25V2.62492H1.75004Z"
        fill="#121224"
      />
    </svg>
  );
}
