import React, { useState } from "react";
import { Form } from "antd";
import ModalDialog from "../../components/modal-dialog/modal-dialog";
import {
  copyFieldDefaultsToValues,
  TicketFieldsForm,
} from "./integration-metadata-fields-form";
import ModalConfirmationButtons from "../../components/modal-dialog/modal-confirmation-buttons";
import { deepcopy } from "../../utils/objects";
import { AlertingChannelType } from "../../utils/alerting";
import { createGetUserList } from "./integration-utils";

import "./integration-metadata-test-ticket-modal.scss";

function IntegrationMetadataTestTicketModal(props) {
  const {
    type,
    visible,
    onCreateTestTicket,
    onCancel,
    value,
    workspaceUuid = null,
  } = props;

  const [testTicketValue, setTestTicketValue] = useState(
    copyFieldDefaultsToValues(deepcopy(value))
  );
  const [form] = Form.useForm();
  const getUserList =
    type === AlertingChannelType.JIRA && workspaceUuid
      ? createGetUserList(workspaceUuid, value, type)
      : null;

  function onSubmitClick() {
    form
      .validateFields()
      .then(() => onCreateTestTicket(testTicketValue))
      .catch((e) => console.log(e));
  }

  return (
    <ModalDialog
      title="Create Test Ticket"
      visible={visible}
      closable
      mask
      maskClosable={true}
      footer={null}
      onCancel={onCancel}
    >
      <div className="integration-metadata-test-ticket-modal">
        <TicketFieldsForm
          form={form}
          value={testTicketValue}
          onChange={setTestTicketValue}
          getUserList={getUserList}
        />
      </div>
      <ModalConfirmationButtons onOkClick={onSubmitClick} onCancelClick={onCancel} />
    </ModalDialog>
  );
}

export default IntegrationMetadataTestTicketModal;
