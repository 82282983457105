import React from "react";
import { NextGenPalette } from "../../utils/color";

export function StaticScheduleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M22 0.75H2C1.30964 0.75 0.75 1.30964 0.75 2V22C0.75 22.6904 1.30964 23.25 2 23.25H22C22.6904 23.25 23.25 22.6904 23.25 22V2C23.25 1.30964 22.6904 0.75 22 0.75ZM0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H22C22.9665 0.25 23.75 1.0335 23.75 2V22C23.75 22.9665 22.9665 23.75 22 23.75H2C1.0335 23.75 0.25 22.9665 0.25 22V2Z"
        fill="white"
        stroke="#B9BBB9"
        strokeWidth="0.5"
      />
      <path
        d="M12 20C16.4183 20 20 16.4183 20 12H21C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C13.4368 3 15.295 3.53247 16.5 4.13129L16 5C14.9428 4.48482 13.2552 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
        fill="#868786"
      />
      <path d="M12 7V12.5L15 15" stroke="#868786" strokeLinecap="round" />
      <path
        d="M17.2835 5.31293C17.4508 5.23608 17.5321 5.04762 17.4679 4.88576L16.4965 2.4398C16.425 2.25996 16.2366 2.17864 16.0693 2.25549L15.6611 2.4072C15.4938 2.48405 15.4125 2.67251 15.4767 2.83437L16.1696 4.57891L14.4305 5.25919C14.2686 5.32347 14.1819 5.52449 14.2461 5.68636L14.4104 6.10001C14.4747 6.26188 14.6632 6.34319 14.8376 6.28433L17.2835 5.31293Z"
        fill="#868786"
      />
      <path
        d="M12 6C8.68548 6 6 8.68548 6 12C6 15.3145 8.68548 18 12 18C15.3145 18 18 15.3145 18 12C18 8.68548 15.3145 6 12 6Z"
        fill="#868786"
      />
      <path
        d="M10.2915 14.388H11.6775V9.9915C11.6775 9.8595 11.682 9.726 11.691 9.591L10.539 10.5765C10.509 10.6005 10.479 10.6185 10.449 10.6305C10.419 10.6395 10.3905 10.644 10.3635 10.644C10.3185 10.644 10.278 10.635 10.242 10.617C10.206 10.596 10.179 10.572 10.161 10.545L9.909 10.1985L11.826 8.538H12.4785V14.388H13.7475V15H10.2915V14.388Z"
        fill="white"
      />
    </svg>
  );
}

export function RecurringScheduleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M22 0.75H2C1.30964 0.75 0.75 1.30964 0.75 2V22C0.75 22.6904 1.30964 23.25 2 23.25H22C22.6904 23.25 23.25 22.6904 23.25 22V2C23.25 1.30964 22.6904 0.75 22 0.75ZM0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H22C22.9665 0.25 23.75 1.0335 23.75 2V22C23.75 22.9665 22.9665 23.75 22 23.75H2C1.0335 23.75 0.25 22.9665 0.25 22V2Z"
        fill="white"
        stroke="#B9BBB9"
        strokeWidth="0.5"
      />
      <path
        d="M12 20C16.4183 20 20 16.4183 20 12H21C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C13.4368 3 15.295 3.53247 16.5 4.13129L16 5C14.9428 4.48482 13.2552 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
        fill="#868786"
      />
      <path d="M12 7V12.5L15 15" stroke="#868786" strokeLinecap="round" />
      <path
        d="M17.2835 5.31293C17.4508 5.23608 17.5321 5.04762 17.4679 4.88576L16.4965 2.4398C16.425 2.25996 16.2366 2.17864 16.0693 2.25549L15.6611 2.4072C15.4938 2.48405 15.4125 2.67251 15.4767 2.83437L16.1696 4.57891L14.4305 5.25919C14.2686 5.32347 14.1819 5.52449 14.2461 5.68636L14.4104 6.10001C14.4747 6.26188 14.6632 6.34319 14.8376 6.28433L17.2835 5.31293Z"
        fill="#868786"
      />
    </svg>
  );
}

export function NgStaticScheduleIcon(props) {
  const { size = 14, fillColor = NextGenPalette.systemBlack } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        d="M11.4408 2.16432L11.4957 2.21285L13.2457 3.96285C13.2947 4.01811 13.2975 4.01462 13.2457 3.96285C13.3542 4.07129 13.4166 4.22201 13.4166 4.37533C13.4143 4.43015 13.416 4.41298 13.4166 4.37533C13.4166 4.53578 13.3506 4.67084 13.2457 4.7878L11.4957 6.53781C11.2679 6.76561 10.8986 6.76561 10.6708 6.53781C10.4605 6.32752 10.4443 5.99664 10.6222 5.7678L10.6708 5.71285L11.4245 4.95866H4.66658C3.05767 4.95866 1.74992 6.26295 1.74992 7.87533C1.74992 9.45033 2.98691 10.7234 4.53999 10.7893L4.66658 10.792H8.74992C9.83458 10.792 10.8364 10.2962 11.4968 9.46132C11.7477 9.14408 11.9421 8.78595 12.0704 8.40196C12.1303 8.22265 12.1756 8.03832 12.2057 7.85035C12.2566 7.53224 12.5558 7.31563 12.8739 7.36656C13.192 7.41748 13.4086 7.71664 13.3577 8.03476C13.3175 8.28579 13.257 8.53212 13.1769 8.77167C13.0057 9.28443 12.7462 9.7623 12.4118 10.1851C11.5676 11.2523 10.3014 11.905 8.9228 11.9555L8.74992 11.9587H4.66658C2.41148 11.9587 0.583252 10.1384 0.583252 7.87533C0.583252 5.66495 2.33854 3.86826 4.52636 3.79436L4.66658 3.79199H11.4245L10.6708 3.0378C10.4605 2.82752 10.4443 2.49664 10.6222 2.2678L10.6708 2.21285C10.8811 2.00256 11.2119 1.98639 11.4408 2.16432Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function NgRecurringScheduleIcon(props) {
  const { size = 14, fillColor = NextGenPalette.systemBlack } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6708 1.62886C10.8986 1.40106 11.2679 1.40106 11.4957 1.62886L13.2457 3.37886C13.4735 3.60667 13.4735 3.97601 13.2457 4.20382L11.4957 5.95382C11.2679 6.18163 10.8986 6.18163 10.6708 5.95382C10.443 5.72601 10.443 5.35667 10.6708 5.12886L11.425 4.37467H4.66658C3.0612 4.37467 1.74992 5.67544 1.74992 7.29134C1.74992 8.91468 3.06047 10.208 4.66658 10.208H8.74992C9.86313 10.208 10.8548 9.68896 11.4967 8.87735L11.4968 8.87733C11.7454 8.56299 11.941 8.20541 12.0704 7.81804L12.0704 7.81798C12.1298 7.64011 12.1754 7.45582 12.2057 7.26637C12.2566 6.94825 12.5558 6.73165 12.8739 6.78257C13.192 6.83349 13.4086 7.13266 13.3577 7.45077C13.3172 7.70372 13.2564 7.94999 13.1769 8.18775L12.6237 8.00286L13.1769 8.18768C13.1769 8.1877 13.1769 8.18773 13.1769 8.18775C13.0039 8.70564 12.7428 9.18266 12.4118 9.60114L11.9543 9.23924L12.4118 9.60114C11.558 10.6805 10.2349 11.3747 8.74992 11.3747H4.66658C2.42093 11.3747 0.583252 9.56379 0.583252 7.29134C0.583252 5.02819 2.41978 3.20801 4.66658 3.20801H11.425L12.0083 3.79134M12.8333 3.79134V3.20801H11.425L10.6708 2.45382C10.443 2.22601 10.443 1.85667 10.6708 1.62886M7.22315 5.00241C7.44113 5.0927 7.58325 5.30541 7.58325 5.54134V9.04134C7.58325 9.36351 7.32208 9.62467 6.99992 9.62467C6.67775 9.62467 6.41658 9.36351 6.41658 9.04134V6.94963L6.0999 7.26632C5.87209 7.49413 5.50275 7.49413 5.27494 7.26632C5.04713 7.03851 5.04713 6.66917 5.27494 6.44136L6.58744 5.12886C6.75427 4.96203 7.00517 4.91212 7.22315 5.00241Z"
        fill={fillColor}
      />
    </svg>
  );
}
