import { connect } from "react-redux";
import { getCurrentKpiQueryList } from "../../../../actions/kpi/kpi-action";
import ProfilerMetricQueryHistory from "./profiler-metric-query-history";

const mapStateToProps = (state) => ({
  currentKpiQueryList: state.kpiReducer.currentKpiQueryList,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentKpiQueryList: (workspaceUuid, kpi, options) =>
    dispatch(getCurrentKpiQueryList(workspaceUuid, kpi, options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilerMetricQueryHistory);
