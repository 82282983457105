import React from "react";
import { classesName } from "../../utils/css";

import "./takeover-panel.scss";

function TakeoverPanel(props) {
  const {
    title,
    titleIcon,
    cornerControls,
    headerExtraContent,
    children,
    options = {},
    className = null,
    ...restProps
  } = props;

  return (
    <div className={classesName("takeover-panel", className)} {...restProps}>
      <div className="takeover-panel-header">
        <div className="takeover-panel-header-top-row">
          <div className="takeover-panel-title">
            <span className="takeover-panel-title-icon">{titleIcon}</span>
            {title}
          </div>
          <div className="takeover-panel-corner-controls">{cornerControls}</div>
        </div>
        {headerExtraContent && (
          <div className="takeover-panel-header-extra-content">
            {headerExtraContent}
          </div>
        )}
      </div>
      <div className="takeover-panel-body" style={options.bodyStyles ?? {}}>
        {children}
      </div>
    </div>
  );
}

export default TakeoverPanel;
