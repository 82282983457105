import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import NgDropdownMenu from "../../../../components/ng-dropdown-menu";
import Button, { TextWithIcon } from "../../../../components/button/ng-button";
import Tooltip from "../../../../components/tooltip/ng-tooltip";
import { Input } from "antd";
import AdminTabContentHeader from "../../admin-tab-content-header";
import PasscodeConfirmationModal from "../../../../components/passcode-confirmation-modal/passcode-confirmation-modal";
import Table, { NgTableClickableText } from "../../../../components/table/ng-table";
import {
  SystemIntegrationType,
  getSystemIntegrationDisplayType,
  getSystemIntegrationIconComponent,
} from "../../../../utils/system-integration";
import { fnSorter } from "../../../../utils/sort";
import AddAlationIntegrationDialog from "./alation/add-alation-integration-dialog";
import EditAlationIntegrationDialog from "./alation/edit-alation-integration-dialog";
import AddAtlanIntegrationDialog from "./atlan/add-atlan-integration-dialog";
import EditAtlanIntegrationDialog from "./atlan/edit-atlan-integration-dialog";
import { trackEvent, EVENT, PAGE } from "../../../../utils/telemetry";
import { getConnectionHealthIcon } from "../../../../utils/icon";
import {
  ConnectionHealth,
  getConnectionHealthDisplayName,
} from "../../../../utils/enums";
import { getCatalogsConnectionHealth } from "../../../../utils/catalogs";

function getSystemIntegrationIcon(systemIntegrationType) {
  const SystemIntegrationIconComponent =
    getSystemIntegrationIconComponent(systemIntegrationType);
  return SystemIntegrationIconComponent ? <SystemIntegrationIconComponent /> : null;
}

function AddIntegrationButton(props) {
  const { onAddClicked, systemIntegrationList } = props;
  const dropdownItems = [
    SystemIntegrationType.ALATION,
    SystemIntegrationType.ATLAN,
  ].map((systemIntegrationType) => {
    return {
      label: getSystemIntegrationDisplayType(systemIntegrationType),
      icon: getSystemIntegrationIcon(systemIntegrationType),
      onClick: () => onAddClicked(systemIntegrationType),
      disabled: systemIntegrationList.find(
        (systemIntegrationItem) =>
          systemIntegrationItem.config.type === systemIntegrationType
      ),
    };
  });

  dropdownItems.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <NgDropdownMenu
      trigger={
        <Button outline>
          Add catalog
          <PlusOutlined />
        </Button>
      }
      menuItems={dropdownItems}
    />
  );
}

function SystemIntegrationHeader(props) {
  const { systemIntegrationList, onAdd, onSearchQueryChange } = props;

  return (
    <AdminTabContentHeader
      title={`Catalogs (${systemIntegrationList.length})`}
      searchComponent={
        <Input.Search
          placeholder="Search catalog..."
          onChange={(e) => onSearchQueryChange(e.target.value)}
        />
      }
      addComponent={
        <AddIntegrationButton
          onAddClicked={onAdd}
          systemIntegrationList={systemIntegrationList}
        />
      }
    />
  );
}

function getTableRows(integrationList) {
  return integrationList.map((integration) => {
    let detail = "";
    const {
      config: { type },
    } = integration;
    if ([SystemIntegrationType.ALATION, SystemIntegrationType.ATLAN].includes(type)) {
      detail = integration.config.baseUrl || "";
    }

    return {
      ...integration,
      detail,
      connectionHealth: getCatalogsConnectionHealth(integration),
    };
  });
}

function AddSystemIntegrationDialog(props) {
  const { type, ...otherProps } = props;
  let SystemIntegrationDialog = null;
  if (type === SystemIntegrationType.ALATION) {
    SystemIntegrationDialog = AddAlationIntegrationDialog;
  } else if (type === SystemIntegrationType.ATLAN) {
    SystemIntegrationDialog = AddAtlanIntegrationDialog;
  }

  return <SystemIntegrationDialog {...otherProps} />;
}

function EditSystemIntegrationDialog(props) {
  const { type, ...otherProps } = props;
  let SystemIntegrationDialog = null;
  if (type === SystemIntegrationType.ALATION) {
    SystemIntegrationDialog = EditAlationIntegrationDialog;
  } else if (type === SystemIntegrationType.ATLAN) {
    SystemIntegrationDialog = EditAtlanIntegrationDialog;
  }
  return <SystemIntegrationDialog {...otherProps} />;
}

function SystemIntegrationTable(props) {
  const { loading, data, onEditIntegration, onDeleteIntegration } = props;

  const columns = [
    {
      title: "TITLE",
      key: "title",
      dataIndex: ["metadata", "name"],
      width: 160,
      sorter: { compare: fnSorter((row) => row.metadata.name.toLowerCase()) },
      defaultSortOrder: "ascend",
      render: (name, integrationData) => {
        const trigger = <NgTableClickableText>{name}</NgTableClickableText>;
        const systemIntegrationActionMenuItems = [
          {
            label: "Edit",
            onClick: () => {
              trackEvent(EVENT.EDIT_CATALOG, {
                catalog_type: integrationData?.config?.type || "",
                page: PAGE.ADMIN_CATALOGS,
              });

              onEditIntegration(integrationData);
            },
            icon: <EditOutlined />,
          },
          {
            label: "Delete",
            onClick: () => onDeleteIntegration(integrationData),
            icon: <DeleteOutlined />,
            danger: true,
          },
        ];

        return (
          <NgDropdownMenu
            trigger={trigger}
            menuItems={systemIntegrationActionMenuItems}
            buttonProps={{ disabled: loading }}
            position="bottomLeft"
          />
        );
      },
    },
    {
      title: "TYPE",
      key: "type",
      dataIndex: ["config", "type"],
      width: 70,
      sorter: { compare: fnSorter((row) => row.config.type) },
      render: (type) => {
        const icon = getSystemIntegrationIcon(type);
        const tooltip = getSystemIntegrationDisplayType(type);
        if (!icon) {
          return tooltip ?? type;
        }

        return (
          <Tooltip title={tooltip}>
            <span>{icon}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Connection health",
      key: "connectionHealth",
      dataIndex: "connectionHealth",
      sorter: { compare: fnSorter((row) => row.connectionHealth) },
      width: 185,
      render: (connectionHealth, row) => {
        const IconComponent = getConnectionHealthIcon(connectionHealth);
        const connectionHealthDisplayName =
          getConnectionHealthDisplayName(connectionHealth);

        const content = (
          <TextWithIcon icon={<IconComponent />} iconPosition="left" inline={true}>
            <span>{connectionHealthDisplayName}</span>
          </TextWithIcon>
        );

        if (connectionHealth === ConnectionHealth.SUCCESS) {
          return content;
        }

        const runStatusMessage = row.status?.runStatusMessage ?? "";
        const errorContent = (
          <div style={{ alignItems: "left", margin: "10px" }}>{runStatusMessage}</div>
        );
        return (
          <Tooltip title={errorContent}>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "detail",
      key: "detail",
      dataIndex: "detail",
      sorter: { compare: fnSorter((row) => row.detail) },
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={getTableRows(data)}
      rowKey={(row) => row.metadata.uuid}
    />
  );
}

function SystemIntegrationTab(props) {
  const {
    systemIntegrationList,
    addSystemIntegration,
    deleteSystemIntegration,
    updateSystemIntegration,
    previewSystemIntegration,
  } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [currentAddIntegrationType, setCurrentAddIntegrationType] = useState(null);
  const [currentEditIntegrationContextItem, setCurrentEditIntegrationContextItem] =
    useState(null);
  const [currentDeleteIntegrationContextItem, setCurrentDeleteIntegrationContextItem] =
    useState(null);

  const normalizedSearchQuery = searchQuery.trim().toLowerCase();
  const data = systemIntegrationList.data.filter(
    (systemIntegrationItem) =>
      !normalizedSearchQuery ||
      systemIntegrationItem.metadata.name
        .toLowerCase()
        .indexOf(normalizedSearchQuery) !== -1
  );

  function onAdd(currentIntegrationItem) {
    trackEvent(EVENT.ADD_CATALOG, {
      catalog_type: currentIntegrationItem?.config?.type || "",
      page: PAGE.ADMIN_CATALOGS,
    });
    addSystemIntegration(currentIntegrationItem);
  }

  function onPreview(currentIntegrationItem) {
    previewSystemIntegration(currentIntegrationItem);
  }

  function onEdit(currentIntegrationItem) {
    updateSystemIntegration(currentIntegrationItem);
  }

  function onDelete(currentIntegrationItem) {
    deleteSystemIntegration(currentIntegrationItem);
  }

  useEffect(() => {
    trackEvent(EVENT.SELECT_CATALOGS_TAB, { page: PAGE.ADMIN });
  }, []);

  return (
    <>
      <div className="admin-catalog-list">
        <SystemIntegrationHeader
          systemIntegrationList={systemIntegrationList.data}
          onAdd={(type) => setCurrentAddIntegrationType(type)}
          onSearchQueryChange={setSearchQuery}
        />
        <div className="admin-workspace-list-body-container">
          <SystemIntegrationTable
            loading={systemIntegrationList.loading}
            data={data}
            onEditIntegration={(integrationItem) =>
              setCurrentEditIntegrationContextItem(integrationItem)
            }
            onDeleteIntegration={(integrationItem) =>
              setCurrentDeleteIntegrationContextItem(integrationItem)
            }
          />
        </div>
      </div>
      {currentAddIntegrationType && (
        <AddSystemIntegrationDialog
          type={currentAddIntegrationType}
          onAdd={onAdd}
          onPreview={onPreview}
          modalIsOpen={!!currentAddIntegrationType}
          setIsOpen={(isOpen) => {
            !isOpen && setCurrentAddIntegrationType(null);
          }}
        />
      )}
      {currentEditIntegrationContextItem && (
        <EditSystemIntegrationDialog
          type={currentEditIntegrationContextItem.config.type}
          data={currentEditIntegrationContextItem}
          onPreview={onPreview}
          onEdit={onEdit}
          modalIsOpen={!!currentEditIntegrationContextItem}
          setIsOpen={(isOpen) => {
            !isOpen && setCurrentEditIntegrationContextItem(null);
          }}
        />
      )}
      {currentDeleteIntegrationContextItem && (
        <PasscodeConfirmationModal
          title="Delete catalog"
          visible={!!currentDeleteIntegrationContextItem}
          okText="OK"
          cancelText="Cancel"
          onConfirm={() => {
            trackEvent(EVENT.DELETE_CATALOG, {
              catalog_type: currentDeleteIntegrationContextItem?.config?.type,
              page: PAGE.ADMIN_CATALOGS,
            });
            onDelete(currentDeleteIntegrationContextItem);
            setCurrentDeleteIntegrationContextItem(null);
          }}
          onCancel={() => setCurrentDeleteIntegrationContextItem(null)}
        >
          <div>
            Are you sure you want to delete this catalog "
            <b>{currentDeleteIntegrationContextItem.metadata.name}</b>"?
          </div>
        </PasscodeConfirmationModal>
      )}
    </>
  );
}

export default SystemIntegrationTab;
