import React, { useState } from "react";
import { Tabs } from "antd";
import CustomDashboard from "../custom-dashboard";
import DqDashboard from "../dq-dashboard";
import IncidentDashboard from "../incident-dashboard";
import { getSettingFromParams } from "../../utils/uri-path";

import "./dashboards.scss";

const DashboardTabItemKey = Object.freeze({
  CUSTOM: "custom",
  DATA_QUALITY: "dataQuality",
  INCIDENT: "incident",
});

const tabList = [
  {
    label: "Data Quality",
    key: DashboardTabItemKey.DATA_QUALITY,
    DashComponent: DqDashboard,
  },
  {
    label: "Incident",
    key: DashboardTabItemKey.INCIDENT,
    DashComponent: IncidentDashboard,
  },
  {
    label: "Custom",
    key: DashboardTabItemKey.CUSTOM,
    DashComponent: CustomDashboard,
  },
];

function TabLabel(props) {
  const { children } = props;

  return <span className="dashboards-tab-label">{children}</span>;
}

function Dashboards(props) {
  const { location, history, workspaceUserPermissions } = props;

  const initialSelectedTab = getSettingFromParams(
    "tabName",
    location.search,
    DashboardTabItemKey.DATA_QUALITY
  );

  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);

  function onTabClick(newTabKey) {
    setSelectedTab(newTabKey);
    const newUrl = `${location.pathname}?tabName=${newTabKey}`;
    history.push(newUrl);
  }

  return (
    <div className="dashboards">
      <Tabs activeKey={selectedTab} onTabClick={onTabClick}>
        {tabList.map(({ key, label, DashComponent }) => (
          <Tabs.TabPane key={key} tab={<TabLabel>{label}</TabLabel>}>
            <DashComponent workspaceUserPermissions={workspaceUserPermissions} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default Dashboards;
