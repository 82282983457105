import React from "react";
import { fnSorter } from "../../utils/sort";
import { NgTextTooltip } from "../text-tooltip/ng-text-tooltip";
import TagGroup from "../tag-group/ng-tag-group";
import { getPath } from "../../utils/objects";
import { metricTypeNames } from "../metric/utils";
import Tooltip from "../tooltip/ng-tooltip";
import { metricCategoryIconComponent } from "../metric/fields/icons";
import { ListPageColumnKey } from "../../utils/enums";
import { getStringFromTimeStamp } from "../../utils/time";
import { getRuleSymptomInfo } from "../../utils/icon";
import MetricLinkCell from "./metric-link-cell";
import { getMonitorTypeName } from "../../utils/monitor";

// columnFn provides a way to create a column template that can be customized and reused
// across tables. It takes one argument, `columnConfig`, which can contain any Antd column
// fields, with a few differences:
// - Do not pass `dataIndex` (this will be customized by the caller)
// - Instead of passing a `sorter`, pass `getCompareVal`. This provides an alternative way
//   to define sort comparisons. This value should be of the form (indexedData, row) =>
//   compareValue.
// - Instead of passing `render`, you may pass `renderWithProps`, which has an extra parameter.
//   Its form is (columnValue, row, renderProps) => columnCell.
// The return value is a function of the form ({ dataIndex, renderProps }) => Antd column config.
// This allows callers to customize the template dataIndex and renderProps.

export function columnFn(columnConfig) {
  const {
    getCompareVal = (indexedValue, _row) => indexedValue,
    renderWithProps,
    ...antdColumnConfig
  } = columnConfig;

  return function (customProps) {
    const { dataIndex, renderProps = {}, ...otherCustomProps } = customProps;
    // As in the Antd table component, dataIndex may be a string or an array. If it's a string,
    // promote it to an array before passing it into `getPath`.
    const dataIndexArray = typeof dataIndex === "string" ? [dataIndex] : dataIndex;
    const columnSorter = {
      compare: fnSorter((row) => getCompareVal(getPath(row, dataIndexArray), row)),
    };
    const columnRender = (columnValue, row) => {
      if (renderWithProps) {
        return renderWithProps(columnValue, row, renderProps);
      } else if (antdColumnConfig.render) {
        return antdColumnConfig.render(columnValue, row);
      } else {
        return columnValue;
      }
    };

    return {
      dataIndex,
      render: columnRender,
      sorter: columnSorter,
      ...antdColumnConfig,
      ...otherCustomProps,
    };
  };
}

export function safeToLowerCase(str) {
  return (str ?? "").toLowerCase();
}

export const dataSourceColumn = columnFn({
  title: "Datasource",
  key: ListPageColumnKey.DATASOURCE,
  getCompareVal: (indexedValue, _row) => safeToLowerCase(indexedValue.join("")),
  render: (names, _row) => <NgTextTooltip>{names.join(", ")}</NgTextTooltip>,
  width: 160,
});

export const schemaColumn = columnFn({
  title: "Schema",
  key: ListPageColumnKey.SCHEMA,
  getCompareVal: (indexedValue, _row) => safeToLowerCase(indexedValue),
  render: (name) => <NgTextTooltip>{name}</NgTextTooltip>,
  width: 160,
});

export const tableColumn = columnFn({
  title: "Table",
  key: ListPageColumnKey.TABLE,
  getCompareVal: (indexedValue, _row) => safeToLowerCase(indexedValue),
  render: (name) => <NgTextTooltip>{name}</NgTextTooltip>,
  width: 160,
});

export const columnColumn = columnFn({
  title: "Column",
  key: ListPageColumnKey.COLUMN_NAME,
  getCompareVal: (indexedValue, _row) => safeToLowerCase(indexedValue),
  render: (name) => <NgTextTooltip>{name}</NgTextTooltip>,
  width: 160,
});

export const createdByColumn = columnFn({
  title: "Created by",
  key: ListPageColumnKey.CREATED_BY,
  getCompareVal: (indexedData, _row) => safeToLowerCase(indexedData),
  render: (name) => <NgTextTooltip>{name}</NgTextTooltip>,
  width: 160,
});

export const createdOnColumn = columnFn({
  title: "Created on",
  key: ListPageColumnKey.CREATED_AT,
  render: (createdOn) =>
    typeof createdOn === "number" ? getStringFromTimeStamp(createdOn) : createdOn,
  width: 160,
});

export const modifiedByColumn = columnFn({
  title: "Last modified by",
  key: ListPageColumnKey.UPDATED_BY,
  getCompareVal: (indexedData, _row) => safeToLowerCase(indexedData),
  render: (name) => <NgTextTooltip>{name}</NgTextTooltip>,
  width: 160,
});

export const modifiedAtColumn = columnFn({
  title: "Last modified",
  key: ListPageColumnKey.UPDATED_AT,
  render: (createdOn) =>
    typeof createdOn === "number" ? getStringFromTimeStamp(createdOn) : createdOn,
  width: 160,
});

export const dimensionColumn = columnFn({
  title: "Dimension",
  key: ListPageColumnKey.DIMENSION,
  width: 110,
});

export const tagsColumn = columnFn({
  title: "Tags",
  key: ListPageColumnKey.TAGS,
  renderWithProps: (tags, row, renderProps) => {
    const { editEnabled, disabled, tagList, onChange } = renderProps;
    return (
      <TagGroup
        editEnabled={editEnabled}
        disabled={disabled}
        value={tags}
        tagList={tagList}
        onChange={(newTags, changeObject) => onChange(newTags, changeObject, row)}
      />
    );
  },
  getCompareVal: (indexedData, _row) => indexedData.join(", "),
  width: 570,
});

export const metricCreationTypeColumn = columnFn({
  title: "Class",
  key: ListPageColumnKey.METRIC_CREATION_TYPE,
  width: 100,
});

export const metricTypeColumn = columnFn({
  title: "Type",
  key: ListPageColumnKey.METRIC_TYPE,
  getCompareVal: (indexedData, _row) => {
    if (!indexedData) {
      return "";
    }
    return metricTypeNames[indexedData] || "";
  },
  render: (metricType) => {
    if (!metricType) {
      return null;
    }
    const Icon = metricCategoryIconComponent(metricType);
    return (
      <Tooltip title={metricTypeNames[metricType]}>
        <Icon width={20} height={20} />
      </Tooltip>
    );
  },
  width: 70,
});

export const metricLinkColumn = columnFn({
  title: "Metric",
  key: ListPageColumnKey.METRIC_NAME,
  getCompareVal: (indexedData, _row) =>
    (indexedData?.metadata?.name || "").toLowerCase(),
  renderWithProps: (metric, _row, renderProps) => {
    if (!metric) {
      return;
    }
    const { workspaceUserPermissions, history } = renderProps;
    return (
      <MetricLinkCell
        metric={metric}
        workspaceUserPermissions={workspaceUserPermissions}
        history={history}
      />
    );
  },
  width: 160,
});

export const monitorTypeColumn = columnFn({
  title: "Type",
  key: ListPageColumnKey.MONITOR_TYPE,
  render: (symptomType, { monitorData }) => {
    const Icon = getRuleSymptomInfo(symptomType)?.icon;
    const tooltipTitle = getMonitorTypeName(monitorData);

    return (
      Icon && (
        <Tooltip title={tooltipTitle}>
          <span>
            <Icon size={20} />
          </span>
        </Tooltip>
      )
    );
  },
  width: 70,
});

export function displaySchemaName(metric) {
  return (
    metric?.config?.table?.schemaName ??
    metric?.config?.targetTable?.table?.schemaName ??
    ""
  );
}

export function displayTableName(metric) {
  return (
    metric?.config?.table?.tableName ??
    metric?.config?.targetTable?.table?.tableName ??
    ""
  );
}

export function getColumnNames(metric) {
  return (metric?.config?.valueColumns ?? []).map((col) => col.columnName);
}

export function displayColumnName(metric) {
  return getColumnNames(metric).join(", ");
}
