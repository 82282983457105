import React from "react";
import LabelItem from "../label-item/label-item";
import "./group-panel-item.scss";

function GroupPanelRowItem(props) {
  return <div className="group-panel-item-row-container">{props.children}</div>;
}

function GroupPanelItem(props) {
  const { chunkSize = 3, className = "", children } = props;
  const originalChildrenArray = React.Children.toArray(children);
  if (originalChildrenArray.length === 0) {
    return <div className={`group-panel-item-container ${className}`} />;
  }

  const groupElementArray = [];
  let i = 0;
  let len = originalChildrenArray.length;
  while (i < len) {
    groupElementArray.push(originalChildrenArray.slice(i, i + chunkSize));
    i += chunkSize;
  }

  // Add empty element in the last group if last group is not fully filled.
  if (len % chunkSize > 0) {
    if (groupElementArray.length === 0) {
      groupElementArray.push([]);
    }

    const lastGroupElement = groupElementArray[groupElementArray.length - 1];
    const extraElement = chunkSize - (len % chunkSize);
    i = 0;
    while (i < extraElement) {
      lastGroupElement.push(<LabelItem key={len + i} />);
      i++;
    }
  }

  return (
    <div className={`group-panel-item-container ${className}`}>
      {groupElementArray.map(function (rowElement, index) {
        return <GroupPanelRowItem key={index}>{rowElement}</GroupPanelRowItem>;
      })}
    </div>
  );
}

export default GroupPanelItem;
