import { connect } from "react-redux";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import {
  addRule,
  getCloneRule,
  resetCurrentAddRuleStatus,
} from "../../actions/rule/rule-action";
import { getScheduleList } from "../../actions/schedule/schedule-action";
import { getKpiList } from "../../actions/kpi/kpi-action";
import { getIntegrationList } from "../../actions/integration/integration-action";
import AddRuleWizard from "./add-rule";

const mapStateToProps = (state) => ({
  dataSourceList: state.dataSourceReducer.dataSourceList.data,
  kpiList: state.kpiReducer.kpiList.data,
  integrationList: state.integrationReducer.integrationList.data,
  scheduleList: state.scheduleReducer.scheduleList.data,
  currentAddRule: state.ruleReducer.currentAddRule,
  currentCloneRule: state.ruleReducer.currentCloneRule,
  currentDimensionList: state.ruleReducer.currentDimensionList,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getKpiList: (workspaceUuid) => dispatch(getKpiList(workspaceUuid)),
  getScheduleList: (workspaceUuid) => dispatch(getScheduleList(workspaceUuid)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  addRule: (workspaceUuid, newRule) => dispatch(addRule(workspaceUuid, newRule)),
  getCloneRule: (workspaceUuid, uuid) => dispatch(getCloneRule(workspaceUuid, uuid)),
  resetCurrentAddRuleStatus: () => dispatch(resetCurrentAddRuleStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRuleWizard);
