import React from "react";
import ZeroStatusChart from "../zero-status-chart";
import Plot from "../../../utils/plotly";
import { timestampDateTimeTickFormat, timestampDateTickFormat } from "../utils";
import { isCurrentTimestampAnIncident } from "../../../utils/general";
import { IncidentScore } from "../../../utils/enums";
import { BrightColorSet, NextGenPalette } from "../../../utils/color";
import { getStringFromTimeStamp } from "../../../utils/time";

import "./index.scss";

function getNormalizedData(dataEntry) {
  const {
    timestamp,
    value: { min, q1, mean, q3, max },
  } = dataEntry;
  const currentTimeStr = getStringFromTimeStamp(timestamp, { graph: true });
  const xAxisData = [
    currentTimeStr,
    currentTimeStr,
    currentTimeStr,
    currentTimeStr,
    currentTimeStr,
    currentTimeStr,
    currentTimeStr,
  ];
  const yAxisData = [min, q1, q1, mean, q3, q3, max];

  return {
    currentTimeStr,
    xAxisData,
    yAxisData,
  };
}

function getSingleTrace(dataEntry, offsetgroup, color) {
  const { xAxisData, yAxisData } = getNormalizedData(dataEntry);
  return {
    x: xAxisData,
    y: yAxisData,
    type: "box",
    name: "",
    marker: { color },
    offsetgroup,
    boxpoints: false,
    showlegend: false,
  };
}

function PercentileChartView(props) {
  const {
    data = null,
    incidents = [],
    chartOption: { showStatsData = false },
  } = props;

  let signalData =
    data && data.metricsData && data.metricsData.signalData
      ? data.metricsData.signalData
      : [];
  let projectedData =
    data && data.metricsData && data.metricsData.projectedData
      ? data.metricsData.projectedData
      : [];
  if (!Array.isArray(signalData) || !Array.isArray(projectedData)) {
    return null;
  }

  const xaxis = {
    showticklabels: true,
    type: "date",
    tickfont: {
      size: 10,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      color: NextGenPalette.slate,
    },
    showgrid: false,
    showline: true,
    linewidth: 1,
    linecolor: NextGenPalette.darkGrey,
    tickformat: timestampDateTickFormat,
    hoverformat: timestampDateTimeTickFormat,
    ticks: "outside",
    ticklen: 6,
    tickcolor: "rgb(255, 255, 255)",
  };

  const yaxis = {
    title: {
      text: "Value",
      font: {
        color: NextGenPalette.slate,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        size: 10,
      },
      standoff: 2,
    },
    showgrid: false,
    showline: false,
    zeroline: false,
    linewidth: 1,
    linecolor: NextGenPalette.darkGrey,
    tickfont: {
      size: 10,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      color: NextGenPalette.slate,
    },
    side: "left",
    fixedrange: false,
    automargin: true,
    ticks: "outside",
    ticklen: 6,
    tickcolor: "rgb(255, 255, 255)",
  };

  let allTraces = [];
  for (let dataEntry of signalData) {
    const currentIncident = isCurrentTimestampAnIncident(
      incidents,
      dataEntry.timestamp
    );
    let color;
    if (!currentIncident) {
      color = BrightColorSet.RAW;
    } else if (currentIncident.score === IncidentScore.HIGH) {
      color = BrightColorSet.HIGH_IMPACT_INCIDENT;
    } else {
      color = BrightColorSet.INCIDENT;
    }
    allTraces.push(getSingleTrace(dataEntry, "metric", color));
  }

  if (showStatsData) {
    for (let dataEntry of projectedData) {
      allTraces.push(getSingleTrace(dataEntry, "feature", "#B8E0F2"));
    }
  }

  const layout = {
    hovermode: "closest",
    height: 200,
    margin: {
      l: 0,
      t: 0,
      r: 0,
      b: 20,
    },
    xaxis,
    yaxis,
    clickmode: "none",
    boxmode: "group",
  };

  const config = {
    displayModeBar: false,
    plot_bgcolor: "#F7F7F7",
    responsive: true,
  };

  return (
    <div className="percentile-chart-view-container">
      {signalData.length > 0 || projectedData.length > 0 ? (
        <Plot
          data={allTraces}
          layout={layout}
          config={config}
          useResizeHandler
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <ZeroStatusChart height={160} />
      )}
    </div>
  );
}

export default PercentileChartView;
