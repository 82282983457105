import { EVENT, trackEvent } from "../../../../utils/telemetry";

export const profilerConfigChangeEventTracking = (
  enabled,
  actionType,
  currentBulkTableUuids,
  eventProps = {}
) => {
  const isEnabling =
    enabled?.schemaChange?.enabled !== undefined
      ? enabled?.schemaChange?.enabled
      : enabled;

  if (actionType === "dataProfiler") {
    if (currentBulkTableUuids.length > 1) {
      trackEvent(
        isEnabling ? EVENT.ENABLE_DATA_PROFILE_BULK : EVENT.DISABLE_DATA_PROFILE_BULK,
        eventProps
      );
    }

    if (currentBulkTableUuids.length === 1) {
      trackEvent(
        isEnabling ? EVENT.ENABLE_DATA_PROFILE : EVENT.DISABLE_DATA_PROFILE,
        eventProps
      );
    }
  }

  if (actionType === "metadataMetrics") {
    if (currentBulkTableUuids.length > 1) {
      trackEvent(
        isEnabling
          ? EVENT.ENABLE_METADATA_METRICS_BULK
          : EVENT.DISABLE_METADATA_METRICS_BULK,
        eventProps
      );
    }

    if (currentBulkTableUuids.length === 1) {
      trackEvent(
        isEnabling ? EVENT.ENABLE_METADATA_METRICS : EVENT.DISABLE_METADATA_METRICS,
        eventProps
      );
    }
  }

  if (actionType === "dataDelay") {
    if (currentBulkTableUuids.length > 1) {
      trackEvent(
        isEnabling
          ? EVENT.ENABLE_DATA_DELAY_AUTOMETRIC_BULK
          : EVENT.DISABLE_DATA_DELAY_AUTOMETRIC_BULK,
        eventProps
      );
    }

    if (currentBulkTableUuids.length === 1) {
      trackEvent(
        isEnabling
          ? EVENT.ENABLE_DATA_DELAY_AUTOMETRIC
          : EVENT.DISABLE_DATA_DELAY_AUTOMETRIC,
        eventProps
      );
    }
  }

  if (actionType === "dataVolume") {
    if (currentBulkTableUuids.length > 1) {
      trackEvent(
        isEnabling
          ? EVENT.ENABLE_DATA_VOLUME_AUTOMETRIC_BULK
          : EVENT.DISABLE_DATA_VOLUME_AUTOMETRIC_BULK,
        eventProps
      );
    }

    if (currentBulkTableUuids.length === 1) {
      trackEvent(
        isEnabling
          ? EVENT.ENABLE_DATA_VOLUME_AUTOMETRIC
          : EVENT.DISABLE_DATA_VOLUME_AUTOMETRIC,
        eventProps
      );
    }
  }

  if (actionType === "status" && enabled) {
    if (currentBulkTableUuids.length > 1) {
      trackEvent(EVENT.CONFIGURE_TABLE_BULK, eventProps);
    }

    if (currentBulkTableUuids.length === 1) {
      trackEvent(EVENT.CONFIGURE_TABLE, eventProps);
    }
  }
};
