import React from "react";
import { URIPathPermissions, hasPermission } from "../../utils/uri-path";
import { getWorkspaceIconFromName } from "../../utils/icon";
import { SideBarNavigationAction, WorkspaceItemKeyToUriMapper } from "./util";
import { NgTextTooltip } from "../text-tooltip/ng-text-tooltip";

import "./workspace-node.scss";
import { EVENT, trackEvent } from "../../utils/telemetry";
import { trackWorkspaceNodeClick } from "./workspace-node.utils";

const menuList = [
  {
    key: SideBarNavigationAction.DATA_SOURCE,
    label: "Datasources",
  },
  {
    key: SideBarNavigationAction.QUERY_GOVERNANCE,
    label: "Query Governance",
  },
  {
    key: SideBarNavigationAction.ALERT,
    label: "System Events",
  },
  {
    key: SideBarNavigationAction.INTEGRATION,
    label: "Integrations",
  },
  {
    key: SideBarNavigationAction.SCHEDULE,
    label: "Schedules",
  },
  {
    key: SideBarNavigationAction.EDIT_WORKSPACE,
    label: "Workspace",
  },
];

function WorkspaceNode(props) {
  const {
    workspaceUserPermissions,
    expand,
    collapsed,
    workspace: { uuid, name, iconId },
    type,
    currentSelectNode,
    onClick,
    onExpandClick,
  } = props;

  function onWorkspaceNodeMenuItemClick(currentKey) {
    if (
      currentSelectNode &&
      currentSelectNode.type === type &&
      currentSelectNode.value.uuid === uuid &&
      currentSelectNode.value.key === currentKey
    ) {
      return;
    }
    if (onClick) {
      onClick({ type, value: { uuid, key: currentKey } });

      trackWorkspaceNodeClick(currentKey, {
        workspace_id: uuid,
      });
    }
  }

  function onWorkspaceNodeClick() {
    if (
      currentSelectNode &&
      currentSelectNode.type === type &&
      currentSelectNode.value.uuid === uuid &&
      !currentSelectNode.value.key
    ) {
      return;
    }
    if (onClick) {
      onClick({ type, value: { uuid, key: "" } });
      trackEvent(EVENT.SELECT_WORKSPACE, {
        workspace_id: uuid,
      });
    }
  }

  const currentMenuList = menuList.filter((currentItem) =>
    hasPermission(
      workspaceUserPermissions,
      URIPathPermissions[WorkspaceItemKeyToUriMapper[currentItem.key]]
    )
  );

  const isCurrentNodeSelected =
    currentSelectNode &&
    currentSelectNode.type === type &&
    currentSelectNode.value.uuid === uuid;

  const WorkspaceIcon = getWorkspaceIconFromName(iconId);

  const MenuListArrow = ({ menuList }) => {
    return (
      <>
        {menuList?.length > 0 && !collapsed && isCurrentNodeSelected && (
          <div
            className="workspace-node-click-target"
            onClick={() => {
              onExpandClick && onExpandClick(!expand);
            }}
          >
            <i
              className={`workspace-node-expand-icon-container ${
                expand ? " expand" : ""
              }`}
            >
              <i className="workspace-node-expand-inner-icon-container"></i>
            </i>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="workspace-node-outer-container">
      <div
        className={`workspace-node-container${
          isCurrentNodeSelected ? " selected" : ""
        }${collapsed ? " collapsed" : ""}`}
      >
        <div
          className={`workspace-node-info-container ${
            collapsed && "workspace-node-info-container--reduced-size"
          }`}
          onClick={onWorkspaceNodeClick}
        >
          <WorkspaceIcon drawBackground />
          {!collapsed && (
            <NgTextTooltip
              hoverText={name}
              className="workspace-node-name-container workspace-node-name-flex-grow"
              shadow={false}
            >
              {name}
            </NgTextTooltip>
          )}
          <MenuListArrow menuList={currentMenuList} />
        </div>
      </div>

      {!collapsed && expand && (
        <div className="workspace-node-menu-container">
          {currentMenuList.map(({ label, key }, index) => {
            const isCurrentNodeMenuSelected =
              currentSelectNode &&
              currentSelectNode.type === type &&
              currentSelectNode.value.uuid === uuid &&
              currentSelectNode.value.key === key;

            return (
              <div
                className={`workspace-node-menu-item-container${
                  isCurrentNodeMenuSelected ? " selected" : ""
                }`}
                onClick={onWorkspaceNodeMenuItemClick.bind(null, key)}
                key={index}
              >
                {label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default WorkspaceNode;
