import React from "react";
import {
  plotConfig,
  timestampDateTickFormat,
  timestampXAxisConfig,
  yAxisConfig,
} from "../../components/chart/utils";
import Plot from "../../utils/plotly";
import NgCard from "../../components/ng-card/ng-card";
import { dqDimensionTypeTitles } from "../../components/dq-dashboard/util";
import { NextGenPalette } from "../../utils/color";
import { Tooltip } from "antd";
import NoData from "./no-data";
import ChartSpinner from "../../components/chart-view/chart-spinner";
import TimeRangeSelector from "../../components/time-range-selector";
import subSeconds from "date-fns/subSeconds";
import { isAfter } from "date-fns";

import "./dimension-trend-chart.scss";

function DimensionTrendPlot(props) {
  const { data } = props;
  const xAxis = timestampXAxisConfig();
  xAxis.hoverformat = timestampDateTickFormat;

  // We need to add a bit of padding to the axis range to ensure that the thick line
  // that we plot will not get cut off.
  const lineWidthYRoom = 0.02;

  const yAxis = yAxisConfig();
  yAxis.range = [
    Math.max(Math.min(...data.y) - 0.1, -lineWidthYRoom),
    Math.min(Math.max(...data.y) + 0.1, 1 + lineWidthYRoom),
  ];
  yAxis.tickformat = ".0%";

  const layout = {
    xaxis: xAxis,
    yaxis: yAxis,
    height: 150,
    margin: {
      t: 0,
      r: 0,
      b: 20,
      l: 0,
    },
  };

  const config = plotConfig();

  const plotData = [
    {
      ...data,
      mode: "lines",
      line: {
        color: NextGenPalette.purple,
        width: 3,
      },
    },
  ];

  return (
    <Plot
      data={plotData}
      layout={layout}
      config={config}
      useResizeHandler
      style={{ width: "100%", height: "100%" }}
    />
  );
}

function DimensionTrendChart(props) {
  const {
    dimensionType,
    trendData,
    currentTime,
    startTime,
    endTime,
    loading,
    evaluationDelay,
    onTimeRangeChange,
  } = props;

  const noData = !Array.isArray(trendData?.x) || trendData.x.length === 0;

  const tooltip =
    "Line graph showing two weeks' history of the currently selected score. A monitor passes if it logs no incidents; a monitor has failed as soon as it logs an incident.";
  const title = (
    <Tooltip title={tooltip}>Percentage of Passing Monitors by Day</Tooltip>
  );

  function TimeRangeControls(_props) {
    const disabledDate = (momentDate) => {
      const earliestSelectableDate = subSeconds(currentTime, evaluationDelay);
      return isAfter(momentDate.toDate(), earliestSelectableDate);
    };

    return (
      !loading && (
        <div className="dq-dash-trend-chart-time-range-controls">
          <TimeRangeSelector
            showTime={false}
            currentTime={currentTime}
            disabledDate={disabledDate}
            value={{
              startTime: startTime,
              endTime: endTime,
              current: currentTime,
            }}
            onChange={onTimeRangeChange}
            allowClear={false}
            ranges={[]}
          />
        </div>
      )
    );
  }

  return (
    <NgCard
      subtitle={dqDimensionTypeTitles[dimensionType]}
      title={title}
      cornerControls={(_props) => <TimeRangeControls />}
    >
      {loading && <ChartSpinner />}
      {!loading && noData && <NoData />}
      {!loading && !noData && <DimensionTrendPlot data={trendData} />}
    </NgCard>
  );
}

export default DimensionTrendChart;
