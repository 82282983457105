import React from "react";

export default function CSVDownloadIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79167 8.16671C3.79167 7.84454 4.05283 7.58337 4.375 7.58337H5.54167V6.70837C5.54167 6.38621 5.80283 6.12504 6.125 6.12504H7.29167V5.25004C7.29167 4.92787 7.55283 4.66671 7.875 4.66671H9.625C9.94717 4.66671 10.2083 4.92787 10.2083 5.25004V9.70308C10.3827 9.80394 10.5 9.99246 10.5 10.2084C10.5 10.5305 10.2388 10.7917 9.91667 10.7917H4.08333C3.76117 10.7917 3.5 10.5305 3.5 10.2084C3.5 9.99246 3.61731 9.80394 3.79167 9.70308V8.16671ZM7.29167 9.62504V7.29171H6.70833V9.62504H7.29167ZM9.04167 9.62504V5.83337H8.45833V9.62504H9.04167ZM4.95833 8.75004H5.54167V9.62504H4.95833V8.75004Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 1.16671C1.75 0.844541 2.01117 0.583374 2.33333 0.583374H9.04167C9.21196 0.583374 9.37374 0.657786 9.48457 0.787079L12.1096 3.84958C12.2002 3.9553 12.25 4.08996 12.25 4.22921V12.8334C12.25 13.1555 11.9888 13.4167 11.6667 13.4167H2.33333C2.01117 13.4167 1.75 13.1555 1.75 12.8334V1.16671ZM2.91667 1.75004V12.25H11.0833V4.445L8.77337 1.75004H2.91667Z"
        fill="#121224"
      />
    </svg>
  );
}
