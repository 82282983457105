import React from "react";

import "./sidebar-menu-item-node.scss";

function SidebarMenuItemNode(props) {
  const {
    label,
    icon: IconComponent,
    onClick,
    collapsed,
    currentSelectNode,
    type,
  } = props;

  const isCurrentNodeSelected = currentSelectNode && currentSelectNode.type === type;
  function onSidebarMenuItemNodeClick() {
    if (isCurrentNodeSelected) {
      return;
    }
    onClick && onClick({ type, value: null });
  }

  return (
    <div
      className={`sidebar-menu-item-node-container ${
        isCurrentNodeSelected ? " selected" : ""
      }${collapsed ? " collapsed" : ""}`}
      onClick={onSidebarMenuItemNodeClick}
    >
      <IconComponent />
      {!collapsed && (
        <span className="sidebar-menu-item-node-label-container">{label}</span>
      )}
    </div>
  );
}

export default SidebarMenuItemNode;
