import React, { useState, useEffect, useMemo } from "react";
import { SettingOutlined } from "@ant-design/icons";
import Button from "../../../../../components/button/ng-button";
import { LabeledSelect } from "../../../../../components/labeled-control/labeled-control";
import { CustomScheduleCollectionConfig } from "../../../../../components/custom-schedule";
import TimestampColumnSelect from "../../../../../components/timestamp-column-select/";
import DurationInput from "../../../../../components/duration-input/duration-input";
import { ProfileTableIcon } from "../../../icons";
import VirtualTimestampColumnForm from "./virtual-timestamp-column-form";
import ProfilerConfirmationDialog from "../../../profiler-confirmation-dialog";
import { getColumnTypeCategory } from "../../../../../utils/column";
import {
  CollectionModeType,
  TableColumnTypeCategory,
  DataSourceType,
  SupportedFeature,
  QueryScope,
  isFileSource as getIsFileSource,
  CollectionWindow,
} from "../../../../../utils/enums";
import {
  DbColumTypeOptions,
  TimezoneOptions,
  collectionWindowOptions,
  getDataCollectionOptions,
} from "../../../../../utils/options";
import {
  isFeatureEnabled,
  isTimestampTimezoneConfigEnabled,
} from "../../../../../utils/general";
import { getDataSourceIcon } from "../../../../../utils/icon";
import {
  getFullTableName,
  isPartitionConfigEnabled,
} from "../../../../../utils/datasource";
import ProfilerTablePartitionEditor from "./profiler-table-partition-editor";

import {
  EVENT,
  PAGE,
  getSchemaDetailProps,
  trackEvent,
} from "../../../../../utils/telemetry";
import Checkbox from "../../../../../atom/checkbox";
import { deepcopy } from "../../../../../utils/objects";
import "./profiler-setting-table-config-takeover-view.scss";

const confirmExitPrompt = "You have unsaved changes - discard?";

const aggregationWindowOptions = [
  { value: "hour", label: "hourly" },
  { value: "day", label: "daily" },
  { value: "week", label: "weekly" },
];

const fullTableWindowOptions = [
  { value: "day", label: "daily" },
  { value: "week", label: "weekly" },
];

const queryScopeOptions = [
  { value: QueryScope.TIME_RANGE, label: "Incremental" },
  { value: QueryScope.FULL_TABLE, label: "Full Table" },
];

const allowedPartitionColumnTypeList = [
  TableColumnTypeCategory.STRING,
  TableColumnTypeCategory.NUMERIC,
  TableColumnTypeCategory.TIMESTAMP,
];

function getEditConfirmationMessage(data, zeroMessage) {
  const { totalMetrics = 0, totalRules = 0 } = data;
  if (totalMetrics + totalRules === 0) {
    return zeroMessage;
  }

  let metricMsgInfo = "";
  if (totalMetrics === 1) {
    metricMsgInfo = " update 1 metric";
  } else if (totalMetrics > 1) {
    metricMsgInfo = ` update ${totalMetrics} metrics`;
  }

  let monitorMsgInfo = "";
  if (totalRules > 0) {
    if (totalMetrics > 0) {
      monitorMsgInfo = ` and delete `;
    }

    if (totalRules === 1) {
      monitorMsgInfo = `${monitorMsgInfo} 1 monitor`;
    } else {
      monitorMsgInfo = `${monitorMsgInfo} ${totalRules} monitors`;
    }
  }

  return `You are about to${metricMsgInfo}${monitorMsgInfo}. This action is not reversible.`;
}

function ProfilerSettingTableConfigTakeoverView(props) {
  const {
    workspaceUuid,
    config: {
      dataSource,
      tableList: defaultTableList,
      isVirtualTimestampVisible: defaultIsVirtualTimestampVisible = false,
      isPartitionSectionOpenForTableList:
        defaultIsPartitionSectionOpenForTableList = {},
      isModifiedTableList: defaultTableListIsModified = false,
    },
    closeTakeover,
    tableColumnList,
    tableListUsage,
    getProfilerConfigColumnList,
    tableListPartitionSampleData,
    getProfilerTableListPartitionSampleData,
    getProfilerConfigTableListUsage,
    isEdit = true,
    enableEdit = true,
    waffle,
    onSave,
    outsideClickWrap,
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [isPartitionSectionOpenForTableList, setIsPartitionSectionOpenForTableList] =
    useState(defaultIsPartitionSectionOpenForTableList);

  const [selectedTableUuidList, setSelectedTableUuidList] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isVirtualTimestampFormVisible, setIsVirtualTimestampFormVisible] = useState(
    defaultIsVirtualTimestampVisible
  );

  const pollingIntervalOptions = useMemo(() => {
    return isFeatureEnabled(waffle, SupportedFeature.FULL_TABLE_METRIC_HOURLY)
      ? aggregationWindowOptions
      : fullTableWindowOptions;
  }, [waffle]);

  useEffect(() => {
    setIsPartitionSectionOpenForTableList((isPartitionSectionOpenForTableList) => {
      const newIsPartitionSectionOpenForTableList = deepcopy(
        isPartitionSectionOpenForTableList
      );
      tableList.forEach((currentTable) => {
        const {
          uuid,
          profilerConfig: {
            partitionOffsets = { includeFutureSeconds: 0, includePastSeconds: 0 },
            partitionTimezone,
          },
        } = currentTable;
        newIsPartitionSectionOpenForTableList[uuid] =
          newIsPartitionSectionOpenForTableList[uuid] ||
          partitionTimezone !== "UTC" ||
          partitionOffsets.includeFutureSeconds !== 0 ||
          partitionOffsets.includePastSeconds !== 0;
      });
      return newIsPartitionSectionOpenForTableList;
    });
  }, [tableList]);

  useEffect(() => {
    if (!tableColumnList.loading) {
      let currentTableList;
      if (!isEdit) {
        // Fill in the default value for profiler table setting
        currentTableList = [];
        for (let currentTable of defaultTableList) {
          const timeStampColumnList = (
            tableColumnList.data[currentTable.uuid] || []
          ).filter(
            (currentColumn) =>
              getColumnTypeCategory(currentColumn) ===
                TableColumnTypeCategory.TIMESTAMP && currentColumn.removedTs === null
          );

          const newTable = deepcopy(currentTable);
          const profilerConfig = newTable.profilerConfig;
          if (profilerConfig.timezone === null) {
            profilerConfig.timezone = "UTC";
          }

          const forceIndexedTimestampColumns =
            dataSource && dataSource.config.governance.indexedTimestampColumnsOnly;

          if (!profilerConfig.timestampColumn && timeStampColumnList.length > 0) {
            // Try to get a indexed column at first
            for (let currentTimestampColumn of timeStampColumnList) {
              if (currentTimestampColumn.isIndexed) {
                profilerConfig.timestampColumn = currentTimestampColumn.columnName;
                break;
              }
            }

            // When indexed timestamp is not enforced fallback to the first one in the list if there is no indexed column
            if (
              !forceIndexedTimestampColumns &&
              profilerConfig.timestampColumn === null
            ) {
              profilerConfig.timestampColumn = timeStampColumnList[0].columnName;
            }
          }

          const isPartitionEnabled =
            dataSource && isPartitionConfigEnabled(dataSource.config.connection.type);
          if (isPartitionEnabled && !profilerConfig.partitions) {
            profilerConfig.partitions = [];
          }

          const filteredTimestampColumnInfos = timeStampColumnList.filter(
            (timestampColumn) =>
              timestampColumn.columnName === profilerConfig.timestampColumn
          );
          const timestampColumnInfo =
            filteredTimestampColumnInfos && filteredTimestampColumnInfos.length > 0
              ? filteredTimestampColumnInfos[0]
              : null;

          const isTimestampColumnEnabled =
            dataSource &&
            timestampColumnInfo &&
            isTimestampTimezoneConfigEnabled(timestampColumnInfo.columnType);

          if (isTimestampColumnEnabled && !profilerConfig.dataTimezone) {
            profilerConfig.dataTimezone = "UTC";
          }

          if (profilerConfig.pollingDelay === null) {
            profilerConfig.pollingDelay = 0;
          }

          if (profilerConfig.pollingWindow === null) {
            profilerConfig.pollingWindow = pollingIntervalOptions[0].value;
          }

          if (profilerConfig.pollingTimezone === null) {
            profilerConfig.pollingTimezone = "UTC";
          }

          currentTableList.push(newTable);
        }
      } else {
        currentTableList = deepcopy(defaultTableList);
      }
      setTableList(currentTableList);
      setSelectedTableUuidList([]);
    }
  }, [defaultTableList, dataSource, isEdit, tableColumnList, pollingIntervalOptions]);

  useEffect(() => {
    getProfilerConfigColumnList(
      workspaceUuid,
      dataSource,
      defaultTableList.map(({ uuid }) => uuid)
    );
  }, [workspaceUuid, dataSource, defaultTableList, getProfilerConfigColumnList]);

  const dataSourceType =
    dataSource && dataSource.config.connection.type
      ? dataSource.config.connection.type
      : "";
  const isPartitionEnabled = isPartitionConfigEnabled(dataSourceType);

  useEffect(() => {
    if (isPartitionEnabled && !tableColumnList.loading) {
      const partitionTableList = [];
      for (let currentTable of defaultTableList) {
        const columnList = tableColumnList.data[currentTable.uuid] || [];
        if (columnList.some(({ isPartitionKey }) => isPartitionKey)) {
          partitionTableList.push(currentTable);
        }
      }
      getProfilerTableListPartitionSampleData(
        workspaceUuid,
        dataSource.metadata.uuid,
        partitionTableList
      );
    }
  }, [
    isPartitionEnabled,
    workspaceUuid,
    dataSource,
    defaultTableList,
    tableColumnList,
    getProfilerTableListPartitionSampleData,
  ]);

  const tableUuidToSampleDataMapper = useMemo(() => {
    const result = {};
    const { loading, data: dataMapper } = tableListPartitionSampleData;
    for (let { uuid } of defaultTableList) {
      result[uuid] = dataMapper.hasOwnProperty(uuid)
        ? { loading, data: dataMapper[uuid] }
        : null;
    }
    return result;
  }, [defaultTableList, tableListPartitionSampleData]);

  if (outsideClickWrap) {
    outsideClickWrap.fn = () => {
      if (
        JSON.stringify(tableList) === JSON.stringify(defaultTableList) ||
        window.confirm(confirmExitPrompt)
      ) {
        closeTakeover();
      }
    };
  }

  function onCancelClick() {
    closeTakeover();
  }

  async function handleSave(data) {
    try {
      setIsSaving(true);
      await onSave(data);
      closeTakeover();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  }

  async function onSaveClick() {
    const schema = { uuid: tableList?.at(0)?.schemaUuid || "" };

    trackEvent(
      tableList.length > 1
        ? EVENT.SAVE_TABLE_CONFIGURATION_BULK
        : EVENT.SAVE_TABLE_CONFIGURATION,
      {
        ...getSchemaDetailProps(schema, dataSource),
        page: PAGE.TABLE_CONFIGURATION,
      }
    );

    if (!isEdit) {
      !isEdit &&
        tableList.forEach((currentTable) => {
          currentTable.profilerConfig.enabled = true;
        });

      if (onSave) {
        await handleSave(tableList);
      }
    } else {
      if (
        !defaultTableListIsModified &&
        JSON.stringify(tableList) === JSON.stringify(defaultTableList)
      ) {
        closeTakeover();
        return;
      }

      getProfilerConfigTableListUsage(
        workspaceUuid,
        dataSource,
        tableList.map(({ uuid }) => uuid),
        tableList.map(({ profilerConfig }) => profilerConfig)
      );
      setTimeout(() => setIsConfirmationDialogOpen(true), 0);
    }
  }

  async function onUpdateSettingClick() {
    // enable metric profiler as long as user save it.
    tableList.forEach((currentTable) => {
      currentTable.profilerConfig.enabled = true;
    });

    if (onSave) {
      await handleSave(tableList);
    }
  }

  if (tableColumnList.loading) {
    return null;
  }

  const isDataBrick = dataSourceType === DataSourceType.DATABRICKS;
  const isFileSource = getIsFileSource(dataSourceType);
  const isDataGovernanceEnabled =
    dataSource && dataSource.config.governance.indexedTimestampColumnsOnly;
  const TypeIcon = getDataSourceIcon(dataSourceType);

  function updateTableProfilerConfig(currentTableUuids, partialConfig) {
    const newTableList = [];
    for (let table of tableList) {
      if (!currentTableUuids.includes(table.uuid)) {
        newTableList.push(table);
      } else {
        newTableList.push({
          ...table,
          profilerConfig: {
            ...table.profilerConfig,
            ...partialConfig,
          },
        });
      }
    }
    setTableList(newTableList);
  }

  function onCheckAllChange(e) {
    setSelectedTableUuidList(e.target.checked ? tableList.map(({ uuid }) => uuid) : []);
  }

  function onCheckOneTableChange(currentTable, e) {
    if (e.target.checked) {
      if (
        selectedTableUuidList.filter((uuid) => currentTable.uuid === uuid).length === 0
      ) {
        setSelectedTableUuidList([...selectedTableUuidList, currentTable.uuid]);
      }
    } else {
      const removedSelectedTableUuidList = selectedTableUuidList.filter(
        (uuid) => currentTable.uuid !== uuid
      );
      removedSelectedTableUuidList.length !== selectedTableUuidList.length &&
        setSelectedTableUuidList(removedSelectedTableUuidList);
    }
  }

  function getNormalizedTableUuids(currentTableUuid, isVirtualTableOnly = false) {
    if (selectedTableUuidList.length === 0) {
      return [currentTableUuid];
    }

    const tableUuidToQueryScopeMapper = {};
    const tableUuidToVirtualTableMapper = {};
    tableList.forEach((currentTable) => {
      tableUuidToQueryScopeMapper[currentTable.uuid] =
        currentTable.profilerConfig.queryScope;
      tableUuidToVirtualTableMapper[currentTable.uuid] = currentTable.isUserDefined;
    });
    const selectedTableQueryScope = tableUuidToQueryScopeMapper[currentTableUuid];
    const tableUuids = [];
    for (let currentSelectedTableUuid of selectedTableUuidList) {
      if (
        tableUuidToQueryScopeMapper[currentSelectedTableUuid] ===
          selectedTableQueryScope &&
        (!isVirtualTableOnly || tableUuidToVirtualTableMapper[currentSelectedTableUuid])
      ) {
        tableUuids.push(currentSelectedTableUuid);
      }
    }

    return tableUuids;
  }

  const isBulkConfigEditEnabled = selectedTableUuidList.length > 1;
  return (
    <div className="profiler-setting-table-config-takeover-view-container">
      <div className="profiler-setting-table-config-takeover-view-header-container">
        <div className="profiler-setting-table-config-takeover-view-header-title-container typo-h2">
          <SettingOutlined />
          Configure Table
        </div>
        <Button outline size="large" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          primary
          size="large"
          onClick={onSaveClick}
          disabled={!enableEdit || isVirtualTimestampFormVisible}
          loading={isSaving}
        >
          Confirm and Save
        </Button>
      </div>
      <div className="profiler-setting-table-config-takeover-content-container">
        <div className="profiler-setting-table-config-takeover-datasource-info-container typo-h2">
          <TypeIcon size={24} />
          {dataSource.metadata.name}
        </div>
        <div className="profiler-setting-config-takeover-table-list-controller-container">
          <Checkbox
            indeterminate={
              selectedTableUuidList.length > 0 &&
              selectedTableUuidList.length !== tableList.length
            }
            onChange={onCheckAllChange}
            checked={
              tableList.length > 0 && selectedTableUuidList.length === tableList.length
            }
          >
            SELECT ALL
          </Checkbox>
        </div>
        <div className="profiler-setting-config-takeover-table-list-container">
          {tableList.map((currentTable) => {
            const { uuid, isUserDefined, profilerConfig } = currentTable;
            const {
              enabled,
              queryScope,
              collectionMode,
              collectionWindow = null,
              window: aggregationWindow,
              timezone,
              syncDelay,
              timestampColumn,
              timestampColumnType,
              timestampColumnFunctions,
              dataTimezone,
              partitions,
              partitionTimezone,
              partitionOffsets = { includeFutureSeconds: 0, includePastSeconds: 0 },
              pollingWindow,
              pollingTimezone,
              pollingDelay,
              blobPathFormat = "",
            } = profilerConfig;

            const columnList = tableColumnList.data[uuid] || [];
            const timestampColumnList = [];
            const partitionColumnList = [];
            const isPartitionColumnUsedAsTimestampColumn =
              partitions.length === 1 && partitions[0].columnName === timestampColumn;
            for (let currentColumn of columnList) {
              if (isDataGovernanceEnabled && !currentColumn.isIndexed) {
                continue;
              }

              if (
                allowedPartitionColumnTypeList.includes(
                  getColumnTypeCategory(currentColumn)
                )
              ) {
                partitionColumnList.push(currentColumn);
              }

              if (
                getColumnTypeCategory(currentColumn) ===
                TableColumnTypeCategory.TIMESTAMP
              ) {
                timestampColumnList.push(currentColumn);
              } else if (
                isDataBrick &&
                partitions &&
                partitions.length === 1 &&
                currentColumn.columnName === partitions[0].columnName
              ) {
                timestampColumnList.push(currentColumn);
              } else if (
                currentColumn.columnName === timestampColumn &&
                timestampColumnFunctions
              ) {
                timestampColumnList.push(currentColumn);
              }
            }

            const timestampColumnInfo = columnList.find(
              ({ columnName }) => timestampColumn === columnName
            );

            const allowDataTimezoneConfig =
              isTimestampTimezoneConfigEnabled(timestampColumnInfo?.columnType) &&
              !isPartitionColumnUsedAsTimestampColumn;

            const isTimeRangeConfigMode = queryScope === QueryScope.TIME_RANGE;

            function timestampColumnConfigUpdate(newTimestampColumnInfo) {
              const timestampColumn = newTimestampColumnInfo.timestampColumn;
              const timestampColumnInfo = columnList.find(
                ({ columnName }) => timestampColumn === columnName
              );
              const isPartitionColumnUsedAsTimestampColumn =
                partitions.length === 1 && partitions[0].columnName === timestampColumn;

              const allowDataTimezoneConfig =
                isTimestampTimezoneConfigEnabled(timestampColumnInfo?.columnType) &&
                !isPartitionColumnUsedAsTimestampColumn;

              let partialObject = newTimestampColumnInfo;
              if (!allowDataTimezoneConfig) {
                partialObject = {
                  ...newTimestampColumnInfo,
                  dataTimezone: null,
                };
              }
              return partialObject;
            }

            function onTimestampColumnChange(newTimestampColumnInfo) {
              updateTableProfilerConfig(
                [currentTable.uuid],
                timestampColumnConfigUpdate(newTimestampColumnInfo)
              );
            }

            const { includeFutureSeconds = 0, includePastSeconds = 0 } =
              partitionOffsets;
            const isAdvancedSectionOpen = !!isPartitionSectionOpenForTableList[uuid];

            const includeCollectionWindow =
              queryScope === QueryScope.TIME_RANGE && collectionWindow !== null;

            return (
              <div
                className="profiler-setting-config-takeover-table-item-container"
                key={uuid}
              >
                <div className="profiler-setting-config-takeover-table-name-container typo-h3">
                  <Checkbox
                    onChange={onCheckOneTableChange.bind(null, currentTable)}
                    checked={selectedTableUuidList.some(
                      (currentUuid) => currentUuid === uuid
                    )}
                  />
                  <ProfileTableIcon />
                  {getFullTableName(currentTable)}
                </div>
                {isFileSource && (
                  <div className="profiler-setting-config-takeover-table-name-sub-title-container">
                    {`File pattern: ${blobPathFormat}`}
                  </div>
                )}
                <div className="profiler-setting-config-table-config-panel-container">
                  <div className="profiler-setting-config-table-config-data-collection-config-title-container">
                    DATA COLLECTION
                    <div className="profiler-setting-config-table-config-data-collection-config-sub-title-container">
                      Query scope cannot be changed once it is set for a table.
                    </div>
                  </div>
                  <div className="profiler-setting-config-table-config-row-container basic-config-row">
                    <LabeledSelect
                      disabled={enabled}
                      label="Query scope"
                      showSearch
                      staticLabel
                      options={
                        isFileSource
                          ? queryScopeOptions.filter(
                              ({ value }) => value === QueryScope.TIME_RANGE
                            )
                          : queryScopeOptions
                      }
                      value={queryScope}
                      onChange={(newQueryScope) => {
                        if (newQueryScope === queryScope) {
                          return;
                        }

                        let configUpdate = { queryScope: newQueryScope };

                        if (
                          newQueryScope === QueryScope.FULL_TABLE &&
                          collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED
                        ) {
                          configUpdate = {
                            ...configUpdate,
                            collectionMode: {
                              type: CollectionModeType.SCHEDULED,
                            },
                          };
                        }

                        if (newQueryScope === QueryScope.FULL_TABLE) {
                          if (isVirtualTimestampFormVisible) {
                            configUpdate = {
                              ...configUpdate,
                              ...timestampColumnConfigUpdate({
                                timestampColumn,
                                timestampColumnFunctions: null,
                              }),
                            };
                            setIsVirtualTimestampFormVisible(false);
                          }
                          configUpdate.collectionWindow = null;
                        } else if (collectionWindow === null) {
                          configUpdate.collectionWindow = CollectionWindow.COMPLETE;
                        }

                        updateTableProfilerConfig(
                          getNormalizedTableUuids(currentTable.uuid),
                          configUpdate
                        );
                      }}
                    />
                    <LabeledSelect
                      label="Data collection schedule"
                      showSearch
                      staticLabel
                      options={getDataCollectionOptions({
                        isBlobStorage: isFileSource,
                      })}
                      value={collectionMode?.type}
                      onChange={(newCollectionModeType) => {
                        if (collectionMode?.type === newCollectionModeType) {
                          return;
                        }

                        let newCollectionMode;
                        if (
                          newCollectionModeType === CollectionModeType.CUSTOM_SCHEDULED
                        ) {
                          newCollectionMode = {
                            type: newCollectionModeType,
                            timezone: "UTC",
                            crontabExpression: "0 */12 * * *",
                          };
                        } else {
                          newCollectionMode = {
                            type: newCollectionModeType,
                          };
                        }
                        updateTableProfilerConfig(
                          getNormalizedTableUuids(currentTable.uuid),
                          { collectionMode: newCollectionMode }
                        );
                      }}
                    />
                    {includeCollectionWindow && (
                      <LabeledSelect
                        label="Data collection window"
                        showSearch
                        staticLabel
                        options={collectionWindowOptions}
                        value={collectionWindow}
                        onChange={(newCollectionWindow) => {
                          updateTableProfilerConfig(
                            getNormalizedTableUuids(currentTable.uuid),
                            { collectionWindow: newCollectionWindow }
                          );
                        }}
                      />
                    )}
                  </div>
                  {collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED && (
                    <div className="profiler-setting-config-table-config-row-container optional-config-row">
                      <CustomScheduleCollectionConfig
                        workspaceUuid={workspaceUuid}
                        value={collectionMode}
                        onChange={(newCollectionMode) =>
                          updateTableProfilerConfig(
                            getNormalizedTableUuids(currentTable.uuid),
                            { collectionMode: newCollectionMode }
                          )
                        }
                      />
                    </div>
                  )}
                  {isTimeRangeConfigMode && (
                    <div className="profiler-setting-config-table-config-row-container time-range-config-row optional-config-row">
                      <LabeledSelect
                        label="Agg. interval"
                        showSearch
                        staticLabel
                        options={aggregationWindowOptions}
                        value={aggregationWindow}
                        onChange={(newAggregationWindow) => {
                          if (newAggregationWindow === aggregationWindow) {
                            return;
                          }

                          updateTableProfilerConfig(
                            getNormalizedTableUuids(currentTable.uuid),
                            { window: newAggregationWindow }
                          );
                        }}
                      />
                      <LabeledSelect
                        label="Agg. timezone"
                        showSearch
                        staticLabel
                        options={
                          isFileSource
                            ? TimezoneOptions.filter(({ value }) => value === "UTC")
                            : TimezoneOptions
                        }
                        value={timezone}
                        onChange={(newTimezone) => {
                          if (newTimezone === timezone) {
                            return;
                          }

                          updateTableProfilerConfig(
                            getNormalizedTableUuids(currentTable.uuid),
                            { timezone: newTimezone }
                          );
                        }}
                      />
                      <DurationInput
                        label={"Eval. delay"}
                        staticLabel
                        value={syncDelay || 0}
                        onChange={(newSyncDelay) => {
                          if (newSyncDelay === syncDelay) {
                            return;
                          }

                          updateTableProfilerConfig(
                            getNormalizedTableUuids(currentTable.uuid),
                            {
                              syncDelay: newSyncDelay,
                            }
                          );
                        }}
                      />
                    </div>
                  )}
                  {isTimeRangeConfigMode && (
                    <div className="profiler-setting-config-table-config-row-container time-range-config-row optional-config-row">
                      <LabeledSelect
                        label="Timestamp column type"
                        staticLabel
                        options={DbColumTypeOptions}
                        value={isUserDefined ? timestampColumnType : "Auto"}
                        disabled={!isUserDefined}
                        onChange={(newTimestampColumnType) => {
                          if (newTimestampColumnType === timestampColumnType) {
                            return;
                          }

                          updateTableProfilerConfig(
                            getNormalizedTableUuids(currentTable.uuid, true),
                            { timestampColumnType: newTimestampColumnType }
                          );
                        }}
                      />
                      <TimestampColumnSelect
                        label={"Timestamp"}
                        staticLabel
                        style={{ width: "100%" }}
                        value={{ timestampColumn, timestampColumnFunctions }}
                        disabled={isVirtualTimestampFormVisible}
                        timestampColumnList={timestampColumnList}
                        enableAdd={!isFileSource}
                        enableClear={isFileSource}
                        onChange={onTimestampColumnChange}
                        size={"large"}
                        onAddVirtualTimestamp={() => {
                          setIsVirtualTimestampFormVisible(true);
                        }}
                        onEditVirtualTimestamp={() => {
                          setIsVirtualTimestampFormVisible(true);
                        }}
                      />
                      <LabeledSelect
                        label="Timezone"
                        showSearch
                        staticLabel
                        options={
                          isFileSource
                            ? TimezoneOptions.filter(({ value }) => value === "UTC")
                            : TimezoneOptions
                        }
                        value={allowDataTimezoneConfig ? dataTimezone : "Derived"}
                        disabled={!allowDataTimezoneConfig}
                        onChange={(newDataTimezone) => {
                          if (newDataTimezone === dataTimezone) {
                            return;
                          }

                          updateTableProfilerConfig(
                            getNormalizedTableUuids(currentTable.uuid),
                            { dataTimezone: newDataTimezone }
                          );
                        }}
                      />
                    </div>
                  )}
                  {isVirtualTimestampFormVisible && (
                    <VirtualTimestampColumnForm
                      isAdd={!timestampColumnFunctions}
                      value={{ timestampColumn, timestampColumnFunctions }}
                      dataSource={dataSource}
                      columnList={columnList}
                      onDelete={() => {
                        onTimestampColumnChange({
                          timestampColumn: null,
                          timestampColumnFunctions: null,
                        });
                        setIsVirtualTimestampFormVisible(false);
                      }}
                      onCancel={() => setIsVirtualTimestampFormVisible(false)}
                      onSave={(newTimestampColumnInfo) => {
                        onTimestampColumnChange(newTimestampColumnInfo);
                        setIsVirtualTimestampFormVisible(false);
                      }}
                    />
                  )}
                  {isPartitionEnabled && isTimeRangeConfigMode && (
                    <div className="profiler-setting-config-table-config-row-container partition-config-row">
                      <ProfilerTablePartitionEditor
                        partitions={partitions}
                        partitionTimezone={partitionTimezone}
                        partitionOffsets={{ includePastSeconds, includeFutureSeconds }}
                        partitionColumnList={partitionColumnList}
                        partitionSampleData={tableUuidToSampleDataMapper[uuid]}
                        isAdvancedSectionOpen={isAdvancedSectionOpen}
                        editEnabled={!isBulkConfigEditEnabled}
                        onAdvancedClick={() => {
                          setIsPartitionSectionOpenForTableList({
                            ...isPartitionSectionOpenForTableList,
                            [uuid]: !isAdvancedSectionOpen,
                          });
                        }}
                        updateTableConfig={(newConfig) => {
                          updateTableProfilerConfig([currentTable.uuid], newConfig);
                        }}
                      />
                    </div>
                  )}
                  {!isTimeRangeConfigMode &&
                    collectionMode.type !== CollectionModeType.TRIGGERED && (
                      <div className="profiler-setting-config-table-config-row-container full-table-config-row">
                        {collectionMode.type !==
                          CollectionModeType.CUSTOM_SCHEDULED && (
                          <>
                            <LabeledSelect
                              label="Polling interval"
                              showSearch
                              staticLabel
                              options={pollingIntervalOptions}
                              value={pollingWindow}
                              onChange={(newPollingWindow) => {
                                if (newPollingWindow === pollingWindow) {
                                  return;
                                }

                                updateTableProfilerConfig(
                                  getNormalizedTableUuids(currentTable.uuid),
                                  { pollingWindow: newPollingWindow }
                                );
                              }}
                            />
                            <LabeledSelect
                              label="Polling timezone"
                              showSearch
                              staticLabel
                              options={TimezoneOptions}
                              value={pollingTimezone}
                              onChange={(newPollingTimezone) => {
                                if (newPollingTimezone === pollingTimezone) {
                                  return;
                                }

                                updateTableProfilerConfig(
                                  getNormalizedTableUuids(currentTable.uuid),
                                  { pollingTimezone: newPollingTimezone }
                                );
                              }}
                            />
                          </>
                        )}
                        <DurationInput
                          staticLabel
                          label={"Polling delay"}
                          value={pollingDelay || 0}
                          onChange={(newPollingDelay) => {
                            if (newPollingDelay === pollingDelay) {
                              return;
                            }

                            updateTableProfilerConfig(
                              getNormalizedTableUuids(currentTable.uuid),
                              {
                                pollingDelay: newPollingDelay,
                              }
                            );
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ProfilerConfirmationDialog
        modalIsOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        okClicked={onUpdateSettingClick}
        enableUsage={true}
        usage={tableListUsage}
        getConfirmationMessage={getEditConfirmationMessage}
        title="Save table settings"
        defaultConfirmationMsg={
          "You are about to save table setting. This is not reversible."
        }
      />
    </div>
  );
}

export default ProfilerSettingTableConfigTakeoverView;
