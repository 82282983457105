import { connect } from "react-redux";
import { getDownloadFilesList } from "../../actions/downloads/downloads-actions";

import Downloads from "./downloads";

const mapStateToProps = (state) => ({
  downloadFilesList: state.downloadFilesReducer.downloadFilesList,
});

const mapDispatchToProps = (dispatch) => ({
  getDownloadFilesList: (workspaceUuid) =>
    dispatch(getDownloadFilesList(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
