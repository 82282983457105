import { LabeledInput } from "../../labeled-control/labeled-control";

function S3BucketConfigView(props) {
  const { value, onChange, useIAMRole } = props;
  const { regionName, awsAccessKeyId, awsSecretAccessKey } = value;

  if (useIAMRole) return null;

  return (
    <>
      <LabeledInput
        label="Region"
        staticLabel
        value={regionName}
        onChange={(e) => {
          const newRegionName = e.target.value;
          onChange?.({ ...value, regionName: newRegionName });
        }}
      />
      <LabeledInput
        label="Access Key ID"
        staticLabel
        autoComplete="off"
        value={awsAccessKeyId}
        onChange={(e) => {
          const newAwsAccessKeyId = e.target.value;
          onChange?.({ ...value, awsAccessKeyId: newAwsAccessKeyId });
        }}
      />
      <LabeledInput
        label="Secret Access Key"
        staticLabel
        type="password"
        autoComplete="off"
        value={awsSecretAccessKey}
        onChange={(e) => {
          const newAwsSecretAccessKey = e.target.value;
          onChange?.({ ...value, awsSecretAccessKey: newAwsSecretAccessKey });
        }}
      />
    </>
  );
}

export default S3BucketConfigView;
