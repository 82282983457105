import React, { forwardRef, useRef, useState } from "react";
import Tooltip from "../tooltip/ng-tooltip";

export function withEllipsisTooltip(Container, tooltipStaticProps) {
  return function (props) {
    const {
      children,
      hoverText,
      hideTooltipOnClick = false,
      shadow = true,
      ...restProps
    } = props;
    const containerRef = useRef();
    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <Container
        style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        ref={containerRef}
        onMouseEnter={() => {
          const isOverflowing =
            containerRef.current &&
            containerRef.current.scrollWidth > containerRef.current.clientWidth;
          setShowTooltip(isOverflowing);
        }}
        onMouseLeave={() => setShowTooltip(false)}
        {...restProps}
      >
        {showTooltip ? (
          <Tooltip
            title={hoverText ?? children}
            shadow={shadow}
            {...tooltipStaticProps}
            placement="topLeft"
            onClick={() => {
              hideTooltipOnClick && setShowTooltip(false);
            }}
          >
            {children}
          </Tooltip>
        ) : (
          children
        )}
      </Container>
    );
  };
}

export const NgTextTooltip = withEllipsisTooltip(
  forwardRef((props, ref) => <div ref={ref} {...props} />)
);
