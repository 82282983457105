import { connect } from "react-redux";
import Disclaimer from "./disclaimer";
import { logout, updateUserDisclaimAccepted } from "../../actions/user/user-action";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
});

const mapDispatchToProps = (dispatch) => ({
  acceptDisclaimer: () => dispatch(updateUserDisclaimAccepted()),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer);
