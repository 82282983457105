import { metricCreationTypeLabel } from "../../../utils/metric";
import {
  getStringCompareFunction,
  stringListCompareFunction,
} from "../../../utils/search";
import { displayTableName, getColumnNames } from "../../entity-list/columns";
import { getMetricTypeFromConfigData, metricTypeNames } from "../../metric/utils";

import { getMonitorRunStatusOptions, getMonitorTypeName } from "../../../utils/monitor";
import { GENERAL_SEARCH_FIELD, metricFields } from "./use-search-metrics-config";

const stringCompareFunction = getStringCompareFunction(false);
const strictStringCompareFunction = getStringCompareFunction(true);

export const monitorFields = {
  MONITOR_TYPE: "monitorType",
  MONITOR_STATUS: "monitorStatus",
  KPI_NAME: metricFields.KPI_NAME,
  METRIC_ID: metricFields.METRIC_ID,
  DATA_SOURCE_NAME: metricFields.DATA_SOURCE_NAME,
  CREATED_BY: metricFields.CREATED_BY,
  LAST_MODIFY_BY: metricFields.LAST_MODIFY_BY,
  DIMENSION: metricFields.DIMENSION,
  METRIC_TYPE: metricFields.METRIC_TYPE,
  CREATION_TYPE: metricFields.CREATION_TYPE,
  TABLE_NAME: metricFields.TABLE_NAME,
  COLUMN_NAME: metricFields.COLUMN_NAME,
  MONITOR_ID: "monitorId",
  TAG_NAME: metricFields.TAG_NAME,
  MONITOR_NAME: "monitorName",
};

export const monitorFieldOptionValueGetterFn = {
  [monitorFields.MONITOR_ID]: (monitor) => monitor.metadata.idSerial,
  [monitorFields.MONITOR_NAME]: (monitor) => monitor.metadata.name,
  [monitorFields.TAG_NAME]: (monitor, metric) => [
    ...monitor.metadata.tags,
    ...(metric?.metadata.tags ?? []),
  ],
  [monitorFields.MONITOR_TYPE]: getMonitorTypeName,
  [monitorFields.MONITOR_STATUS]: (monitor, metric) =>
    getMonitorRunStatusOptions(monitor),
  [monitorFields.KPI_NAME]: (monitor, metric) => metric?.metadata.name,
  [monitorFields.TABLE_NAME]: (monitor, metric) => metric && displayTableName(metric),
  [monitorFields.METRIC_ID]: (monitor, metric) => metric?.metadata.idSerial,
  [monitorFields.COLUMN_NAME]: (monitor, metric) => metric && getColumnNames(metric),
  [monitorFields.CREATED_BY]: (monitor, metric) => monitor?.metadata.ownedBy?.username,
  [monitorFields.LAST_MODIFY_BY]: (monitor, metric) =>
    monitor?.metadata.updatedBy?.username,
  [monitorFields.DIMENSION]: (monitor, metric) => metric?.config.dimension,
  [monitorFields.CREATION_TYPE]: (monitor, metric) =>
    metric && metricCreationTypeLabel(metric),
};

export const monitorFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, metric, searchName) => {
    const tagsNames = [
      ...(row.monitorData.metadata.tags ?? []),
      ...(metric?.metadata.tags ?? []),
    ];
    return (
      searchName.length &&
      !stringListCompareFunction(
        [
          row.monitorData.metadata.name,
          metric?.metadata.name,
          ...row.metricDataSourceNames,
          ...tagsNames,
          row.metricTableName,
        ],
        searchName,
        stringCompareFunction
      )
    );
  },
  [monitorFields.MONITOR_ID]: (row, metric, monitorId) => {
    return !monitorId.includes(row.monitorData.metadata.idSerial.toString());
  },
  [monitorFields.MONITOR_NAME]: (row, metric, monitorName) => {
    return !stringCompareFunction(row.monitorData.metadata.name, monitorName);
  },
  [monitorFields.TABLE_NAME]: (row, metric, tableName) => {
    return !stringCompareFunction(row.metricTableName, tableName);
  },
  [monitorFields.KPI_NAME]: (row, metric, metricName) => {
    return !stringCompareFunction(metric?.metadata.name, metricName);
  },
  [monitorFields.METRIC_ID]: (row, metric, metricId) => {
    return !metricId.includes(metric.metadata.idSerial.toString());
  },
  [monitorFields.METRIC_TYPE]: (row, metric, metricType) => {
    return (
      !metric ||
      !stringCompareFunction(
        metricTypeNames[getMetricTypeFromConfigData(metric)],
        metricType
      )
    );
  },
  [monitorFields.DATA_SOURCE_NAME]: (row, metric, dataSourceName) => {
    return !stringListCompareFunction(
      row.metricDataSourceNames,
      dataSourceName,
      stringCompareFunction
    );
  },
  [monitorFields.TAG_NAME]: (row, metric, tagName) => {
    const tagsNames = [
      ...(row.monitorData.metadata.tags ?? []),
      ...(metric?.metadata.tags ?? []),
    ];
    return !stringListCompareFunction(tagsNames, tagName, stringCompareFunction);
  },
  [monitorFields.COLUMN_NAME]: (row, metric, columnName) => {
    return !metric?.config?.valueColumns?.some((column) =>
      stringCompareFunction(column.columnName, columnName)
    );
  },
  [monitorFields.CREATED_BY]: (row, metric, createdBy) => {
    return !stringCompareFunction(
      row.monitorData.metadata.ownedBy?.username,
      createdBy
    );
  },
  [monitorFields.LAST_MODIFY_BY]: (row, metric, lastModifyBy) => {
    return !stringCompareFunction(
      row.monitorData.metadata.updatedBy?.username,
      lastModifyBy
    );
  },
  [monitorFields.DIMENSION]: (row, metric, dimension) => {
    return !stringCompareFunction(metric?.config.dimension, dimension);
  },
  [monitorFields.CREATION_TYPE]: (row, metric, creationType) => {
    return !stringCompareFunction(metricCreationTypeLabel(metric), creationType);
  },
  [monitorFields.MONITOR_TYPE]: (row, metric, monitorType) => {
    return !strictStringCompareFunction(
      getMonitorTypeName(row.monitorData),
      monitorType
    );
  },
  [monitorFields.MONITOR_STATUS]: (row, metric, status) => {
    return !stringListCompareFunction(
      getMonitorRunStatusOptions(row.monitorData),
      status,
      strictStringCompareFunction
    );
  },
};
