import React, { useState, useEffect } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "../../button/ng-button";
import ConfigCard from "../config-card";
import { FailingRecordsSqlConfigItem } from "./index";
import { MetricConfigType } from "../../../utils/enums";
import { CheckOneIcon, DataArrivalIcon } from "../../icons/success";

import "./metric-failing-records-query-config-item.scss";

function MetricFailingRecordsQueryConfigItem(props) {
  const {
    isSystemDefinedSql = false,
    configData,
    value,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult,
    onChange,
    disabled = false,
  } = props;

  const {
    config: { configType, sources },
  } = configData;

  const [isShowAddButton, setIsShowAddButton] = useState(!value);
  const [validationStarted, setValidationStarted] = useState(false);
  useEffect(() => {
    setValidationStarted(false);
  }, [value]);

  const validationInProgress = validateFailingRecordsSqlResult.loading;
  const isValidateButtonEnabled =
    !validateFailingRecordsSqlResult.loading &&
    value &&
    [
      MetricConfigType.METRIC_CONFIG,
      MetricConfigType.FULL_TABLE_METRIC_CONFIG,
    ].includes(configType) &&
    sources?.length > 0;

  const showValidationSuccess =
    validationStarted &&
    !validationInProgress &&
    validateFailingRecordsSqlResult?.isSuccess;
  let validateSqlButtonText = "Validate Query";
  let validateSqlButtonIcon = <DataArrivalIcon />;
  if (validationInProgress) {
    validateSqlButtonText = "Validating";
    validateSqlButtonIcon = null;
  } else if (showValidationSuccess) {
    validateSqlButtonText = "Successful Validation";
    validateSqlButtonIcon = <CheckOneIcon />;
  }

  const buttonComponent = isShowAddButton ? (
    <Button outline disabled={disabled} onClick={() => setIsShowAddButton(false)}>
      Add
      <PlusOutlined />
    </Button>
  ) : (
    <Button
      primary
      success={showValidationSuccess}
      block
      disabled={!isValidateButtonEnabled}
      onClick={() => {
        setValidationStarted(true);
        validateKpiFailingRecordsSql(configData);
      }}
    >
      <div className="metric-config-custom-sql-validate-query-button">
        <span>{validateSqlButtonText}</span>
        {validateSqlButtonIcon}
      </div>
    </Button>
  );

  return (
    <ConfigCard
      title="Failing records query"
      description={
        <span>
          Create a query that represents the set of records that you want to see when
          analyzing an incident.
          {isSystemDefinedSql &&
            " This query will replace the system query that is automatically generated for this metric."}
        </span>
      }
      actionComponent={buttonComponent}
    >
      {!isShowAddButton && (
        <div className="metric-failing-records-query-config-item-container">
          <FailingRecordsSqlConfigItem
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
          <DeleteOutlined
            disabled={disabled || validationInProgress}
            onClick={() => {
              setIsShowAddButton(true);
              onChange("");
            }}
          />
        </div>
      )}
    </ConfigCard>
  );
}

export default MetricFailingRecordsQueryConfigItem;
