import React from "react";
import ConfigureMetricSummary from "../configure-metric-summary";
import WhereConditionsSummary from "../where-conditions-summary";

function DistributionMetricConfigDataAssetSummaryCardContent(props) {
  const { columnList, configData, dataSourceList } = props;

  return (
    <>
      <ConfigureMetricSummary
        configData={configData}
        columnList={columnList}
        dataSourceList={dataSourceList}
        enableSeasonality={true}
        enableMissingValueFilling={false}
      />
      <WhereConditionsSummary configData={configData} />
    </>
  );
}

export default DistributionMetricConfigDataAssetSummaryCardContent;
