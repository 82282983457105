import React, { useState } from "react";
import { SubmitType } from "../integration-metadata";
import { useOutsideClickConfirm } from "../../../hooks/useOutsideClickConfirm";
import OpsGenieIntegrationBasicDialog from "./opsgenie-integration-basic-dialog";
import { AlertingChannelType } from "../../../utils/alerting";
import { OpsGeniePriorityType } from "../../../utils/enums";
import { transformOpsgenieDataToPayload } from "./opsgenie-integration-helper";

const defaultOpsGenieInstance = {
  mode: SubmitType.AUTOMATIC,
  type: AlertingChannelType.OPSGENIE,
  name: "",
  apiKey: "",
  priority: OpsGeniePriorityType.P3,
  responders: [
    {
      type: "",
      names: [],
    },
  ],
  tags: [],
  instanceUrl: "https://api.opsgenie.com",
};

function AddOpsGenieIntegrationDialog(props) {
  const {
    currentMetadata,
    onAdd,
    onPreview,
    modalIsOpen,
    outsideClickWrap,
    setIsOpen,
  } = props;

  const [value, setValue] = useState(defaultOpsGenieInstance);

  useOutsideClickConfirm({
    outsideClickWrap,
    close: () => setIsOpen(false),
    originalValue: defaultOpsGenieInstance,
    currentValue: value,
  });

  const onOkClicked = (newData) => {
    const payloaData = transformOpsgenieDataToPayload(newData);

    onAdd(payloaData);
  };

  const onPreviewClicked = async (newData) => {
    try {
      const payloaData = transformOpsgenieDataToPayload(newData);

      await onPreview(payloaData);
    } catch (error) {
      console.error(error);
    }
  };

  const hasFilledRequiredFields =
    value?.title?.trim() !== "" && value?.apiKey?.trim() !== "" && value?.priority;

  return (
    <OpsGenieIntegrationBasicDialog
      value={value}
      OKText="Add"
      onChange={setValue}
      onPreview={onPreviewClicked}
      setIsOpen={setIsOpen}
      enableSave={hasFilledRequiredFields}
      modalIsOpen={modalIsOpen}
      onOkClicked={onOkClicked}
      dialogTitle="Add Opsgenie Integration"
      enablePreview={hasFilledRequiredFields}
      currentMetadata={currentMetadata}
    />
  );
}

export default AddOpsGenieIntegrationDialog;
