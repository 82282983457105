import React from "react";
import { LabeledInput } from "../../labeled-control/labeled-control";

import "./snowflake-option-config-view.scss";

function SnowflakeOptionConfigView(props) {
  const { value, onChange } = props;

  const { warehouse = "", role = "" } = value;

  return (
    <div className="datasource-option-config-view-container">
      <div className="datasource-option-config-view-title-container">
        Optional settings
      </div>
      <div className="datasource-option-config-view-description-container">
        Provide a warehouse and/or role name for your Snowflake database
      </div>
      <div className="datasource-option-config-view-fields">
        <LabeledInput
          label="Warehouse name"
          staticLabel
          value={warehouse}
          onChange={(e) => {
            const newWarehouse = e.target.value;
            onChange && onChange({ ...value, warehouse: newWarehouse });
          }}
        />
        <LabeledInput
          label="Role"
          staticLabel
          value={role}
          onChange={(e) => {
            const newRole = e.target.value;
            onChange && onChange({ ...value, role: newRole });
          }}
        />
      </div>
    </div>
  );
}

export default SnowflakeOptionConfigView;
