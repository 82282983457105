import React from "react";
import LegacyModalDialogAdapter from "../../../../../components/legacy-adapter/legacy-modal-dialog-adapter";
import IntegrationDialogFooter from "../../../../integrations/integration-dialog-footer";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../../../components/modal-dialog/modal-field-group";
import {
  LabeledInput,
  LabeledInputNumber,
} from "../../../../../components/labeled-control/labeled-control";
import TimeOption from "../../../../../components/time-option/time-option";
import {
  getDisplayTimeFromSecond,
  getSecondFromDisplayTime,
} from "../../../../../utils/time";

const incidentWindowOptions = [
  { value: "h", label: "Hour" },
  { value: "d", label: "Day" },
  { value: "w", label: "Week" },
];

function AlationIntegrationBasicDialogBodyComponent(props) {
  const {
    title,
    onTitleChange,
    baseUrl,
    onBaseUrlChange,
    userId,
    onUserIdChange,
    userRefreshToken,
    onUserRefreshTokenChange,
    incidentWindow,
    onIncidentWindowChange,
  } = props;

  return (
    <ModalFieldGroup>
      <ModalFieldRows>
        <LabeledInput
          label="Title"
          value={title}
          onChange={(e) => onTitleChange(e.target.value)}
        />
        <LabeledInput
          label="Host"
          value={baseUrl}
          onChange={(e) => onBaseUrlChange(e.target.value)}
        />
        <LabeledInputNumber label="User ID" value={userId} onChange={onUserIdChange} />
        <LabeledInput
          label="User Refresh Token"
          type="password"
          value={userRefreshToken}
          onChange={(e) => onUserRefreshTokenChange(e.target.value)}
        />
        <TimeOption
          label="Incident Window"
          value={
            incidentWindow === undefined
              ? undefined
              : getDisplayTimeFromSecond(incidentWindow, true, ["h", "d", "w"])
          }
          onChange={(incidentWindowStr) => {
            const newIncidentWindow = getSecondFromDisplayTime(incidentWindowStr);
            if (incidentWindow !== newIncidentWindow) {
              onIncidentWindowChange(newIncidentWindow);
            }
          }}
          options={incidentWindowOptions}
        />
      </ModalFieldRows>
    </ModalFieldGroup>
  );
}

function AlationIntegrationBasicDialog(props) {
  let {
    defaultData: {
      metadata: { name: defaultTitle, uuid },
      config: {
        type,
        baseUrl: defaultBaseUrl,
        userId: defaultUserId,
        userRefreshToken: defaultUserRefreshToken,
        incidentWindow: defaultIncidentWindow,
      },
    },
    dialogTitle = "",
    className = "",
    triggerComponent,
    onOkClicked,
    onPreview,
    OKText,
    modalIsOpen,
    setIsOpen,
    enableSave,
    enablePreview,
  } = props;

  const [title, setTitle] = React.useState(defaultTitle);
  const [baseUrl, setBaseUrl] = React.useState(defaultBaseUrl);
  const [userId, setUserId] = React.useState(defaultUserId);
  const [userRefreshToken, setUserRefreshToken] = React.useState(
    defaultUserRefreshToken
  );
  const [incidentWindow, setIncidentWindow] = React.useState(defaultIncidentWindow);

  const [modalIsOpenDefault, setIsOpenDefault] = React.useState(false);
  if (modalIsOpen === undefined || setIsOpen === undefined) {
    modalIsOpen = modalIsOpenDefault;
    setIsOpen = setIsOpenDefault;
  } else {
    triggerComponent = null;
  }

  const isValidData =
    title &&
    baseUrl &&
    typeof userId === "number" &&
    userRefreshToken &&
    incidentWindow;
  function getNewAlationIntegration() {
    return {
      metadata: {
        name: title,
        ...(uuid ? { uuid } : {}),
      },
      config: {
        type,
        baseUrl,
        userId,
        userRefreshToken,
        incidentWindow,
      },
    };
  }

  function closeModal() {
    setTitle(defaultTitle);
    setBaseUrl(defaultBaseUrl);
    setUserId(defaultUserId);
    setUserRefreshToken(defaultUserRefreshToken);
    setIncidentWindow(defaultIncidentWindow);
    setIsOpen(false);
  }

  function onOkClick() {
    if (onOkClicked) {
      const newAlationIntegration = getNewAlationIntegration();
      onOkClicked(newAlationIntegration);
    }
    closeModal();
  }

  function onPreviewClick() {
    if (onPreview) {
      const newAlationIntegration = getNewAlationIntegration();
      onPreview(newAlationIntegration);
    }
  }

  return (
    <LegacyModalDialogAdapter
      title={dialogTitle}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={`alation-integration-basic-dialog-container ${className}`}
      triggerComponent={triggerComponent}
      contentComponent={
        <AlationIntegrationBasicDialogBodyComponent
          title={title}
          onTitleChange={setTitle}
          baseUrl={baseUrl}
          onBaseUrlChange={setBaseUrl}
          userId={userId}
          onUserIdChange={setUserId}
          userRefreshToken={userRefreshToken}
          onUserRefreshTokenChange={setUserRefreshToken}
          incidentWindow={incidentWindow}
          onIncidentWindowChange={setIncidentWindow}
        />
      }
      footerComponent={
        <IntegrationDialogFooter
          onPreviewClick={onPreviewClick}
          onCancelClick={closeModal}
          onOkClick={onOkClick}
          OkText={OKText}
          enablePreview={enablePreview}
          enableSave={enableSave && isValidData}
          previewText={"Test connection"}
        />
      }
    />
  );
}

export default AlationIntegrationBasicDialog;
