import { useState } from "react";
import { Input } from "antd";
import { classesName } from "../../../utils/css";
import { tokenize } from "../../../utils/boolean-expressions";

import "./where-clause-logic.scss";

function getExpressionHtml(expression) {
  try {
    const tokensToRender = tokenize(expression);
    const result = [];
    for (const token of tokensToRender) {
      if (token === "and") {
        result.push('<span class="and">AND</span>');
      } else if (token === "or") {
        result.push('<span class="or">OR</span>');
      } else if (token === "not") {
        result.push('<span class="not">NOT</span>');
      } else if (["(", ")"].includes(token)) {
        result.push(`<span>${token}</span>`);
      } else {
        result.push(token);
      }
    }
    return result.join("&nbsp;");
  } catch (err) {
    return "";
  }
}

export default function WhereClauseLogic(props) {
  const {
    disabled = false,
    value = "",
    validatedValue = "",
    onChange = null,
    error = null,
  } = props;
  const [focused, setFocused] = useState(false);

  const onValueChange = (event) => {
    onChange(event.target.value);
  };

  const focusedOrError = focused || error;

  return (
    <div className="where-clause-logic">
      <Input
        disabled={disabled}
        placeholder="Logic expression"
        value={value}
        onChange={onValueChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        size="large"
        className={classesName(
          "where-clause-logic__expression",
          !focusedOrError && "where-clause-logic__expression--non-focused"
        )}
      />
      {!focusedOrError && (
        <div
          className="where-clause-logic__validated-expression"
          dangerouslySetInnerHTML={{ __html: getExpressionHtml(validatedValue) }}
        />
      )}
      {error && <span className="where-clause-logic__error">{error}</span>}
    </div>
  );
}
