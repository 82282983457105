import React, { useState } from "react";
import Button from "../button/ng-button";
import { VerticalDots } from "../icons/menu";
import Popover from "../popover";
import { classesName } from "../../utils/css";

import "./index.scss";

function RightArrow(_props) {
  return (
    <svg width="9" height="10" viewBox="0 0 9 10" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68506 4.43956C6.94904 4.82864 6.90865 5.36252 6.56388 5.70729L3.73545 8.53572C3.34493 8.92624 2.71176 8.92624 2.32124 8.53572C1.93072 8.1452 1.93072 7.51203 2.32124 7.12151L4.44256 5.00018L2.32121 2.87883C1.93069 2.48831 1.93069 1.85514 2.32121 1.46462C2.71174 1.0741 3.3449 1.0741 3.73543 1.46462L6.56385 4.29305C6.56874 4.29793 6.57357 4.30286 6.57833 4.30782C6.61819 4.34933 6.65376 4.39342 6.68506 4.43956Z"
        fill="#121224"
      />
    </svg>
  );
}

function NgDropdownMenuActionItem(props) {
  const {
    icon,
    label,
    onClick,
    closeMenu,
    disabled,
    defaultValue,
    danger,
    className: itemClassName,
  } = props;

  const className = classesName(
    "ng-dropdown-menu-item",
    !onClick && "ng-dropdown-menu-item-noaction",
    defaultValue && "ng-dropdown-menu-item-default-value",
    disabled && "ng-dropdown-menu-item-disabled",
    danger && "ng-dropdown-menu-item-danger",
    itemClassName
  );

  return (
    <button
      className={className}
      onClick={(e) => {
        if (!disabled) {
          closeMenu();
          onClick && onClick();
        }
      }}
    >
      {icon && <div className="ng-dropdown-menu-item-icon">{icon}</div>}
      {label}
    </button>
  );
}

function NgDropdownMenuSubMenuItem(props) {
  const { icon, label, subMenu, closeParent, getPopupContainer } = props;

  const trigger = (
    <button className="ng-dropdown-menu-item">
      {icon && <div className="ng-dropdown-menu-item-icon">{icon}</div>}
      {label}
      <div className="ng-dropdown-menu-item-arrow">
        <RightArrow />
      </div>
    </button>
  );

  return (
    <NgDropDownMenuPopup
      on="hover"
      position="leftTop"
      trigger={trigger}
      menuItems={subMenu}
      closeParent={closeParent}
      offsetX={-16}
      getPopupContainer={getPopupContainer}
    />
  );
}

function NgDropDownMenuPopup(props) {
  const {
    trigger,
    menuItems,
    on,
    position,
    offsetY = 0,
    offsetX = 0,
    className,
    closeParent,
    getPopupContainer,
    extraPopupContent = null,
    popoverProps = {},
  } = props;

  const [open, setOpen] = useState(false);
  const closeMenu = () => {
    setOpen(false);
  };

  function menuContent() {
    const closeMenuAndParent = closeParent
      ? () => {
          closeMenu();
          closeParent();
        }
      : closeMenu;
    return (
      <div className={classesName("ng-dropdown-menu-content", className)}>
        {menuItems.map((item, index) => {
          if (item.subMenu) {
            return (
              <NgDropdownMenuSubMenuItem
                key={`${item.label}_${index}`}
                closeParent={closeMenuAndParent}
                getPopupContainer={getPopupContainer}
                {...item}
              />
            );
          } else if (item.divider) {
            return (
              <div key={`divider_${index}`} className="ng-dropdown-menu-divider" />
            );
          } else {
            return (
              <NgDropdownMenuActionItem
                key={`${item.label}_${index}`}
                closeMenu={closeMenuAndParent}
                {...item}
              />
            );
          }
        })}
        {extraPopupContent}
      </div>
    );
  }

  const wrappedTrigger =
    on === "click" ? <div onClick={() => setOpen(!open)}>{trigger}</div> : trigger;

  return (
    <Popover
      trigger={on}
      placement={position}
      content={menuContent}
      overlayClassName="ng-dropdown-menu-overlay"
      // These are not documented Antd props. The Antd component uses rc-tooltip
      // under the hood, and these props are passed through to the rc-tooltip component.
      onVisibleChange={setOpen}
      align={{ offset: [offsetX, offsetY] }}
      getPopupContainer={getPopupContainer}
      visible={open}
      {...popoverProps}
    >
      {wrappedTrigger}
    </Popover>
  );
}

function NgDropdownMenu(props) {
  const {
    buttonContent,
    buttonText,
    buttonProps = {},
    menuItems = [],
    position = "bottomRight",
    className,
    getPopupContainer,
    extraPopupContent = null,
    on = "click",
    visible,
    onVisibleChange,
  } = props;

  let trigger = props.trigger;

  if (!trigger) {
    const triggerContent = buttonContent ?? (
      <div className="ng-dropdown-menu-button-content">
        {buttonText && (
          <span className="ng-dropdown-menu-button-text">{buttonText}</span>
        )}
        <VerticalDots />
      </div>
    );

    trigger = (
      <div>
        <Button outline {...buttonProps}>
          {triggerContent}
        </Button>
      </div>
    );
  }

  // The visible state of the menu can be managed externally, but this messes with the
  // behavior of submenu items closing their parent when they are clicked. This is a
  // limitation that I don't see a way around right now: you can only externally manage
  // the visible state of single-level menus.
  const popoverProps = onVisibleChange ? { visible, onVisibleChange } : {};

  return (
    <div className="ng-dropdown-menu">
      {menuItems.length > 0 || extraPopupContent ? (
        <NgDropDownMenuPopup
          on={on}
          position={position}
          trigger={trigger}
          menuItems={menuItems}
          className={className}
          getPopupContainer={getPopupContainer}
          extraPopupContent={extraPopupContent}
          popoverProps={popoverProps}
        />
      ) : (
        trigger
      )}
    </div>
  );
}

export default NgDropdownMenu;
