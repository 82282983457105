import React from "react";
import AtlanIntegrationBasicDialog from "./atlan-integration-basic-dialog";
import { EditOutlined } from "@ant-design/icons";

function EditAtlanIntegrationDialog(props) {
  const {
    data,
    onEdit,
    onPreview,
    modalIsOpen,
    setIsOpen,
    enableSave = true,
    enablePreview = true,
  } = props;

  const onOkClicked = (newData) => {
    onEdit(newData);
  };

  return (
    <AtlanIntegrationBasicDialog
      defaultData={data}
      className={"edit-atlan-integration-dialog-container"}
      dialogTitle={"Edit Atlan Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      triggerComponent={<EditOutlined />}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enableSave={enableSave}
      enablePreview={enablePreview}
    />
  );
}

export default EditAtlanIntegrationDialog;
