import {
  SET_PROFILER_TREE_DATA,
  SET_PROFILER_CUSTOM_METRIC_TREE_DATA,
  SET_PROFILER_CURRENT_DATA_SOURCE_SUMMARY,
  SET_PROFILER_CURRENT_SCHEMA_SUMMARY,
  SET_PROFILER_CURRENT_TABLE_AUTO_METRICS_DATA,
  SET_PROFILER_CURRENT_TABLE_OVERVIEW_SUMMARY,
  SET_PROFILER_CURRENT_METRIC_METRICS_DATA,
  SET_PROFILER_CURRENT_METRIC_SLICE_VALUES,
  SET_PROFILER_CONFIG_DATA_SOURCE_LIST,
  SET_PROFILER_CONFIG_TABLE_LIST,
  SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE,
  SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST,
  SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST_USAGE,
  SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_COLUMN_LIST,
  SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_COLUMN_LIST,
  SET_PROFILER_CONFIG_TABLE_CURRENT_DATA_SOURCE,
  SET_PROFILER_CONFIG_TABLE_CURRENT_TABLE,
  SET_PROFILER_CONFIG_TABLE_BLOB_LIST,
  SET_PROFILER_CONFIG_TABLE_COLUMN_LIST,
  SET_PROFILER_CONFIG_TABLE_COLUMN_LIST_USAGE,
  SET_PROFILER_CONFIG_TABLE_COLUMN_METRICS,
  SET_PROFILER_COLUMN_CURRENT_METRICS_DATA,
  SET_PROFILER_CURRENT_INCIDENT_LIST,
  SET_PROFILER_CURRENT_INCIDENT_METRICS_DATA,
  SET_PROFILER_CURRENT_MONITOR_OBJECT,
  SET_PROFILER_PARTITION_SAMPLE_DATA,
  SET_PROFILER_TABLE_LIST_PARTITION_SAMPLE_DATA,
  SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST,
  SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST_USAGE,
  SET_PROFILER_TABLE_DATA_PROFILE_DATA,
  SET_PROFILER_TABLE_DATA_PROFILE_TABLE,
  SET_PROFILER_COLUMN_DATA_PROFILE_DATA,
  SET_PROFILER_TABLE_DATA_PROFILE_TABLE_DATA_PROFILE,
  SET_PROFILER_TABLE_METADATA_METRICS_DATA,
  SET_PROFILER_TABLE_HEALTH_DATA,
  SET_PROFILER_AFTER_TREE_NAVIGATION_ACTION,
  SET_PROFILER_VIRTUAL_TABLE_COLUMN_LIST,
  SET_PROFILER_VIRTUAL_TABLE_SCHEMA_LIST,
  SET_PROFILER_VIRTUAL_TABLE_SAMPLE_DATA_LIST,
  SET_PROFILER_CONFIG_TABLE_CHANGE_LOG,
} from "../../actions/profiler/profiler-action-types";

export const initialProfilerState = {
  profilerTreeData: {
    workspaceUuid: null,
    loading: true,
    data: [],
    dataSourceList: [],
    type: "",
  },
  profilerCustomMetricTreeData: {
    workspaceUuid: null,
    loading: true,
    data: [],
    dataSourceList: [],
  },
  profilerCurrentDataSourceSummary: null,
  profilerCurrentTableSummary: {
    loading: true,
    data: null,
  },
  profilerCurrentTableHealthData: {
    loading: true,
    error: false,
    data: null,
  },
  profilerCurrentTableOverviewSummary: {
    loading: true,
    data: null,
  },
  profilerCurrentSchemaSummary: {
    loading: true,
    data: null,
  },
  profilerCurrentTableColumnSummary: {
    loading: true,
    data: null,
  },
  profilerCurrentMetricMetricsData: {
    loading: false,
    data: {
      rule: null,
      metric: null,
      metricsData: [],
      incidents: [],
    },
  },
  profilerCurrentMetricSliceValues: {
    loading: true,
    data: [],
    updatedAt: 0,
  },
  profilerConfigDataSourceList: {
    loading: true,
    data: [],
  },
  profilerConfigTableList: {
    loading: true,
    data: [],
  },
  profilerConfigCurrentDataSource: null,
  profilerConfigDataSourceTableList: {
    loading: true,
    data: [],
  },
  profilerConfigDataSourceColumnList: [],
  profilerConfigDataSourceTableColumnList: {
    loading: true,
    data: {},
  },
  profilerConfigDataSourceTableListUsage: {
    loading: true,
    data: {},
  },
  profilerConfigTableCurrentDataSource: null,
  profilerConfigTableCurrentTable: null,
  profilerConfigTableBlobList: {
    loading: false,
    data: [],
    cancelToken: null,
  },
  profilerConfigTableColumnList: {
    loading: true,
    data: [],
  },
  profilerConfigTableChangeLog: {
    loading: true,
    data: [],
  },
  profilerConfigTableColumnListUsage: {
    loading: true,
    data: {},
  },
  profilerConfigTableMetrics: [],
  profilerColumnCurrentMetricsData: {
    loading: true,
    data: null,
  },
  profilerCurrentIncidentList: {
    loading: true,
    data: [],
  },
  profilerCurrentIncidentMetricsData: {
    loading: true,
    data: null,
  },
  profilerCurrentMonitorObject: {
    loading: true,
    data: null,
  },
  profilerPartitionSampleData: {
    loading: false,
    data: null,
  },
  profilerTableListPartitionSampleData: {
    loading: true,
    data: {},
  },
  profilerConfigDataSourceSchemaList: {
    loading: true,
    data: [],
  },
  profilerConfigDataSourceSchemaListUsage: {
    loading: true,
    data: {},
  },
  profilerColumnDataProfileData: {
    loading: true,
    data: null,
  },
  profilerTableDataProfileData: {
    loading: true,
    data: null,
  },
  profilerTableDataProfileTable: {
    loading: true,
    data: null,
  },
  profilerTableDataProfileTableDataProfile: {
    loading: true,
    data: null,
  },
  profilerCurrentTableAutoMetricsData: {
    loading: true,
    data: null,
  },
  profilerTableMetadataMetricsData: {
    loading: true,
    data: null,
  },
  profilerAfterTreeNavigationAction: null,
  profilerVirtualTableColumnList: {
    loading: false,
    data: [],
    isLoaded: false,
  },
  profilerVirtualTableSchemaList: {
    loading: false,
    data: [],
  },
  profilerVirtualTableSampleDataList: {
    loading: false,
    data: [],
    error: null,
  },
};

export default function profilerReducer(state = initialProfilerState, action = {}) {
  let newState;

  switch (action.type) {
    case SET_PROFILER_TREE_DATA:
      newState = { ...state, profilerTreeData: action.profilerTreeData };
      return newState;

    case SET_PROFILER_CUSTOM_METRIC_TREE_DATA:
      newState = {
        ...state,
        profilerCustomMetricTreeData: action.profilerCustomMetricTreeData,
      };
      return newState;

    case SET_PROFILER_CURRENT_DATA_SOURCE_SUMMARY:
      newState = {
        ...state,
        profilerCurrentDataSourceSummary: action.profilerCurrentDataSourceSummary,
      };
      return newState;

    case SET_PROFILER_CURRENT_SCHEMA_SUMMARY:
      newState = {
        ...state,
        profilerCurrentSchemaSummary: action.profilerCurrentSchemaSummary,
      };
      return newState;

    case SET_PROFILER_CURRENT_TABLE_AUTO_METRICS_DATA:
      newState = {
        ...state,
        profilerCurrentTableAutoMetricsData: action.profilerCurrentTableAutoMetricsData,
      };
      return newState;

    case SET_PROFILER_CURRENT_TABLE_OVERVIEW_SUMMARY:
      newState = {
        ...state,
        profilerCurrentTableOverviewSummary: action.profilerCurrentTableOverviewSummary,
      };
      return newState;

    case SET_PROFILER_TABLE_HEALTH_DATA:
      newState = {
        ...state,
        profilerCurrentTableHealthData: action.profilerCurrentTableHealthData,
      };
      return newState;

    case SET_PROFILER_CURRENT_METRIC_METRICS_DATA:
      newState = {
        ...state,
        profilerCurrentMetricMetricsData: action.profilerCurrentMetricMetricsData,
      };
      return newState;

    case SET_PROFILER_CURRENT_METRIC_SLICE_VALUES:
      newState = {
        ...state,
        profilerCurrentMetricSliceValues: action.profilerCurrentMetricSliceValues,
      };
      return newState;

    case SET_PROFILER_CONFIG_DATA_SOURCE_LIST:
      newState = {
        ...state,
        profilerConfigDataSourceList: action.profilerConfigDataSourceList,
      };
      return newState;

    case SET_PROFILER_CONFIG_TABLE_LIST:
      newState = {
        ...state,
        profilerConfigTableList: action.profilerConfigTableList,
      };
      return newState;

    case SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE:
      newState = {
        ...state,
        profilerConfigCurrentDataSource: action.profilerConfigCurrentDataSource,
      };
      return newState;

    case SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST:
      newState = {
        ...state,
        profilerConfigDataSourceTableList: action.profilerConfigDataSourceTableList,
      };
      return newState;

    case SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST_USAGE:
      newState = {
        ...state,
        profilerConfigDataSourceTableListUsage:
          action.profilerConfigDataSourceTableListUsage,
      };
      return newState;

    case SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_COLUMN_LIST:
      newState = {
        ...state,
        profilerConfigDataSourceColumnList: action.profilerConfigDataSourceColumnList,
      };
      return newState;

    case SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_COLUMN_LIST:
      newState = {
        ...state,
        profilerConfigDataSourceTableColumnList:
          action.profilerConfigDataSourceTableColumnList,
      };
      return newState;

    case SET_PROFILER_CONFIG_TABLE_CURRENT_DATA_SOURCE:
      newState = {
        ...state,
        profilerConfigTableCurrentDataSource:
          action.profilerConfigTableCurrentDataSource,
      };
      return newState;

    case SET_PROFILER_CONFIG_TABLE_CURRENT_TABLE:
      newState = {
        ...state,
        profilerConfigTableCurrentTable: action.profilerConfigTableCurrentTable,
      };
      return newState;

    case SET_PROFILER_CONFIG_TABLE_BLOB_LIST:
      newState = {
        ...state,
        profilerConfigTableBlobList: action.profilerConfigTableBlobList,
      };
      return newState;

    case SET_PROFILER_CONFIG_TABLE_COLUMN_LIST:
      newState = {
        ...state,
        profilerConfigTableColumnList: action.profilerConfigTableColumnList,
      };
      return newState;
    case SET_PROFILER_CONFIG_TABLE_CHANGE_LOG:
      newState = {
        ...state,
        profilerConfigTableChangeLog: action.profilerConfigTableChangeLog,
      };
      return newState;

    case SET_PROFILER_CONFIG_TABLE_COLUMN_LIST_USAGE:
      newState = {
        ...state,
        profilerConfigTableColumnListUsage: action.profilerConfigTableColumnListUsage,
      };
      return newState;

    case SET_PROFILER_COLUMN_CURRENT_METRICS_DATA:
      newState = {
        ...state,
        profilerColumnCurrentMetricsData: action.profilerColumnCurrentMetricsData,
      };
      return newState;

    case SET_PROFILER_CURRENT_INCIDENT_LIST:
      newState = {
        ...state,
        profilerCurrentIncidentList: action.profilerCurrentIncidentList,
      };
      return newState;

    case SET_PROFILER_CURRENT_INCIDENT_METRICS_DATA:
      newState = {
        ...state,
        profilerCurrentIncidentMetricsData: action.profilerCurrentIncidentMetricsData,
      };
      return newState;

    case SET_PROFILER_CURRENT_MONITOR_OBJECT:
      newState = {
        ...state,
        profilerCurrentMonitorObject: action.profilerCurrentMonitorObject,
      };
      return newState;

    case SET_PROFILER_CONFIG_TABLE_COLUMN_METRICS:
      newState = {
        ...state,
        profilerConfigTableMetrics: action.profilerConfigTableMetrics,
      };
      return newState;

    case SET_PROFILER_PARTITION_SAMPLE_DATA:
      newState = {
        ...state,
        profilerPartitionSampleData: action.profilerPartitionSampleData,
      };
      return newState;

    case SET_PROFILER_TABLE_LIST_PARTITION_SAMPLE_DATA:
      newState = {
        ...state,
        profilerTableListPartitionSampleData:
          action.profilerTableListPartitionSampleData,
      };
      return newState;

    case SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST:
      newState = {
        ...state,
        profilerConfigDataSourceSchemaList: action.profilerConfigDataSourceSchemaList,
      };
      return newState;

    case SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST_USAGE:
      newState = {
        ...state,
        profilerConfigDataSourceSchemaListUsage:
          action.profilerConfigDataSourceSchemaListUsage,
      };
      return newState;

    case SET_PROFILER_COLUMN_DATA_PROFILE_DATA:
      newState = {
        ...state,
        profilerColumnDataProfileData: action.profilerColumnDataProfileData,
      };
      return newState;

    case SET_PROFILER_TABLE_DATA_PROFILE_DATA:
      newState = {
        ...state,
        profilerTableDataProfileData: action.profilerTableDataProfileData,
      };
      return newState;

    case SET_PROFILER_TABLE_DATA_PROFILE_TABLE:
      newState = {
        ...state,
        profilerTableDataProfileTable: action.profilerTableDataProfileTable,
      };
      return newState;

    case SET_PROFILER_TABLE_DATA_PROFILE_TABLE_DATA_PROFILE:
      newState = {
        ...state,
        profilerTableDataProfileTableDataProfile:
          action.profilerTableDataProfileTableDataProfile,
      };
      return newState;

    case SET_PROFILER_TABLE_METADATA_METRICS_DATA:
      newState = {
        ...state,
        profilerTableMetadataMetricsData: action.profilerTableMetadataMetricsData,
      };
      return newState;

    case SET_PROFILER_AFTER_TREE_NAVIGATION_ACTION:
      newState = {
        ...state,
        profilerAfterTreeNavigationAction: action.action,
      };
      return newState;

    case SET_PROFILER_VIRTUAL_TABLE_COLUMN_LIST:
      newState = {
        ...state,
        profilerVirtualTableColumnList: action.profilerVirtualTableColumnList,
      };
      return newState;

    case SET_PROFILER_VIRTUAL_TABLE_SCHEMA_LIST:
      newState = {
        ...state,
        profilerVirtualTableSchemaList: action.profilerVirtualTableSchemaList,
      };
      return newState;

    case SET_PROFILER_VIRTUAL_TABLE_SAMPLE_DATA_LIST:
      newState = {
        ...state,
        profilerVirtualTableSampleDataList: action.profilerVirtualTableSampleDataList,
      };
      return newState;

    default:
      return state;
  }
}
