import React, { useMemo } from "react";
import { TableType } from "../../../utils/enums";
import {
  DataSourceConfigItem,
  SchemaConfigItem,
  FieldColumn,
  FieldSection,
  SubHeading,
  TableConfigItem,
  VerticalSplit,
} from "./index";

function SourceTargetTables(props) {
  const {
    dataSourceList,
    srcSchemaList,
    schemaList,
    srcTableList,
    tableList,
    sourceTable,
    targetTable,
    onSrcDataSourceChange,
    onSrcTableChange,
    onTargetDataSourceChange,
    onTargetTableChange,
    isEdit = false,
    disabled = false,
    allowVirtualTables = false,
  } = props;

  const currentSourceSchemaName = sourceTable?.table?.schemaName;
  const normalizedSrcTableList = useMemo(() => {
    if (!currentSourceSchemaName) {
      return [];
    }

    return srcTableList.filter(
      (currentSrcTable) =>
        currentSrcTable.schemaName === currentSourceSchemaName &&
        (!currentSrcTable.isUserDefined || allowVirtualTables)
    );
  }, [srcTableList, currentSourceSchemaName, allowVirtualTables]);

  const currentTargetSchemaName = targetTable?.table?.schemaName;
  const normalizedTargetTableList = useMemo(() => {
    if (!currentTargetSchemaName) {
      return [];
    }

    return tableList.filter(
      (currentTargetTable) =>
        currentTargetTable.schemaName === currentTargetSchemaName &&
        (!currentTargetSchemaName.isUserDefined || allowVirtualTables)
    );
  }, [tableList, currentTargetSchemaName, allowVirtualTables]);

  return (
    <FieldSection>
      <VerticalSplit>
        <FieldColumn>
          <SubHeading>Source</SubHeading>
          <DataSourceConfigItem
            dataSourceList={dataSourceList}
            value={sourceTable?.sourceUuid}
            onChange={onSrcDataSourceChange}
            disabled={disabled || isEdit}
          />
          <SchemaConfigItem
            schemaList={srcSchemaList}
            value={currentSourceSchemaName}
            onChange={(newSchemaName) => {
              onSrcTableChange("", {
                type: TableType.TABLE,
                tableUuid: undefined,
                schemaName: newSchemaName,
                tableName: undefined,
              });
            }}
            disabled={disabled}
          />
          <TableConfigItem
            tableList={normalizedSrcTableList}
            value={sourceTable?.table?.tableUuid}
            onChange={onSrcTableChange}
            disabled={disabled}
          />
        </FieldColumn>
        <FieldColumn>
          <SubHeading>Target</SubHeading>
          <DataSourceConfigItem
            dataSourceList={dataSourceList}
            value={targetTable?.sourceUuid}
            onChange={onTargetDataSourceChange}
            disabled={disabled || isEdit}
          />
          <SchemaConfigItem
            schemaList={schemaList}
            value={currentTargetSchemaName}
            onChange={(newSchemaName) => {
              onTargetTableChange("", {
                type: TableType.TABLE,
                tableUuid: undefined,
                schemaName: newSchemaName,
                tableName: undefined,
              });
            }}
            disabled={disabled}
          />
          <TableConfigItem
            tableList={normalizedTargetTableList}
            value={targetTable?.table?.tableUuid}
            onChange={onTargetTableChange}
            disabled={disabled}
          />
        </FieldColumn>
      </VerticalSplit>
    </FieldSection>
  );
}

export default SourceTargetTables;
