import React from "react";
import { TriggerIcon } from "../../views/profiler/menu-icons";
import NgButton, { TextWithIcon } from "../button/ng-button";

import "./waiting-for-trigger.scss";

function WaitingForTrigger(props) {
  const { onTriggerClick } = props;
  return (
    <div className="waiting-for-trigger">
      <div className="waiting-for-trigger-title">
        Trigger your metric to generate a datapoint
      </div>
      {onTriggerClick && (
        <NgButton outline size="large" onClick={onTriggerClick}>
          <TextWithIcon icon={<TriggerIcon />}>Trigger Metric</TextWithIcon>
        </NgButton>
      )}
    </div>
  );
}

export default WaitingForTrigger;
