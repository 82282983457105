import React from "react";
import { hasPermission } from "../../utils/uri-path";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CloneIcon } from "../profiler/menu-icons";
import { isActivityMetric } from "../../utils/metric";
import { AppPermissions } from "../../utils/permissions";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import { EVENT, PAGE, getMonitorDetailProps, trackEvent } from "../../utils/telemetry";

export const MonitorActions = Object.freeze({
  VIEW_EDIT: "viewEdit",
  CLONE: "clone",
  DELETE: "delete",
});

function MonitorActionsMenu(props) {
  const {
    trigger,
    monitors,
    metric = null,
    workspaceUserPermissions,
    onEdit,
    onClone,
    onDelete,
  } = props;

  const modifyPermissions = [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERDETAIL,
  ];
  const addPermissions = [AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERLIST];
  const viewPermissions = [AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERDETAIL];

  const canModify = hasPermission(workspaceUserPermissions, modifyPermissions);
  const canView = hasPermission(workspaceUserPermissions, viewPermissions);

  const menuConfig = [
    {
      action: MonitorActions.VIEW_EDIT,
      supportsMultiple: false,
      permissions: viewPermissions,
      item: {
        label: canModify ? "Edit" : "View",
        icon: <EditOutlined />,
        onClick: () => {
          trackEvent(canModify ? EVENT.EDIT_MONITOR : EVENT.VIEW_MONITOR, {
            ...getMonitorDetailProps(monitors[0]),
            page: PAGE.MONITORS,
          });
          onEdit(monitors[0]);
        },
        disabled: !canView,
      },
    },
    {
      action: MonitorActions.CLONE,
      supportsMultiple: false,
      permissions: addPermissions,
      validTarget: () => !isActivityMetric(metric),
      item: {
        label: "Clone",
        icon: <CloneIcon />,
        onClick: () => {
          trackEvent(EVENT.CLONE_MONITOR, {
            ...getMonitorDetailProps(monitors[0]),
            page: PAGE.MONITORS,
          });

          onClone(monitors[0]);
        },
      },
    },
    {
      action: MonitorActions.DELETE,
      supportsMultiple: true,
      permissions: modifyPermissions,
      item: {
        label: "Delete",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => onDelete(monitors),
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    monitors,
    Object.values(MonitorActions),
    workspaceUserPermissions
  );

  return (
    <NgDropdownMenu menuItems={menuItems} trigger={trigger} position="bottomLeft" />
  );
}

export default MonitorActionsMenu;
