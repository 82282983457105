import React, { useState, useEffect } from "react";
import { SettingOutlined } from "@ant-design/icons";
import NgChartView from "../../../components/chart-view/ng-index";
import ProfilerEnableView from "../profiler-enable-view";
import { getKPIProfilerConfigInfo } from "../../../utils/general";
import { useInterval } from "../../../utils/hooks";
import { AutoMetricsType, MetricCategory } from "../../../utils/enums";
import {
  getDefaultRuleConfigForAutoMetrics,
  getKPIInterval,
} from "../../../utils/defaults";
import { getDefaultUserSetting } from "../../../utils/user-setting";
import fromUnixTime from "date-fns/fromUnixTime";
import { getMonitorStatusFromRule } from "../../../utils/monitor";
import {
  isSomeMetadataMetricEnabled,
  isTableMetricTypeEnabled,
  tableSupportsMetricType,
  toggleTableMetricType,
} from "../../../utils/table";
import {
  profilerTableChartViewConfig,
  shouldChartRefreshMetrics,
  getChartSymptomConfigForFocusedMonitor,
} from "../utils";
import { isMetadataMetric, metadataMetricTypes } from "../../../utils/metric";
import {
  getMetricTypeFromConfigData,
  metricTypeNames,
} from "../../../components/metric/utils";
import ProfilerDataSourceTableSchemaSummary from "./profiler-data-source-table-schema-summary";
import { dataSourceSupportsMetricType } from "../../../utils/datasource";
import { metricCategoryIconComponent } from "../../../components/metric/fields/icons";
import { TextWithIcon } from "../../../components/button/ng-button";

import "./profiler-data-source-table-main-tab.scss";

const chartDisplayOrder = [
  // Schema Change is 1st, but there's no corresponding metric object
  // so it's just hard-coded to the top of the page.
  MetricCategory.UPDATE_DELAY,
  MetricCategory.ROW_COUNT,
  MetricCategory.BYTE_COUNT,
  MetricCategory.DATA_DELAY,
  MetricCategory.DATA_VOLUME,
];

function isTableMetricsDefined(tableInfo) {
  return (
    tableInfo.profilerConfig?.enabled &&
    (tableInfo.profilerConfig?.dataDelay?.enabled ||
      tableInfo.profilerConfig?.volume?.enabled)
  );
}

function ConfigureTableCard(props) {
  const { openTableConfig, metricType } = props;
  const Icon = metricCategoryIconComponent(metricType);
  return (
    <ProfilerEnableView
      gridOnly={true}
      icon={<Icon width={48} height={48} />}
      onClick={openTableConfig}
      buttonContent={
        <TextWithIcon icon={<SettingOutlined />}>
          <span className="configure-card-button-text">
            Configure table to enable {metricTypeNames[metricType]}
          </span>
        </TextWithIcon>
      }
    />
  );
}

function ProfilerDataSourceTableMainTab(props) {
  const {
    profilerCurrentTableAutoMetricsData,
    metadataMetricsData,
    currentDataSource,
    currentSchema,
    currentTable,
    createSystemProfilerRule,
    toggleSystemProfilerRuleStatus,
    updateProfilerTableAutoMetricsChartData,
    updateProfilerTableMetadataMetricsChartData,
    updateSystemProfileTableRuleAlertingChannel,
    updateSystemProfileDataSourceTableRuleAlertingChannel,
    triggerProfilerMetrics,
    alertingChannelList,
    onGoToIncidentPage,
    toggleKpiLiveStatus,
    workspaceUserPermissions,
    onDeleteMonitorClick,
    openTableConfig,
    updateTableConfig,
    isEventChartEnabled,
    isDataChartEnabled,
    canModifySettings,
    getProfilerCurrentTableAutoMetricsData,
    getProfilerCurrentTableMetadataMetricsData,
    getProfilerCurrentTableHealthData,
    metricRefreshInterval,
    onGoToRule,
    onGoToMetric,
    onCloneMonitor,
    onOpenMetricQueryHistory,
  } = props;

  const [chartMonitorSetting, setChartMonitorSetting] = useState({});
  const [metricTypeUpdating, setMetricTypeUpdating] = useState(null);

  useEffect(() => {
    if (isTableMetricsDefined(currentTable)) {
      getProfilerCurrentTableAutoMetricsData(currentDataSource, currentTable, {
        isEventChartEnabled,
        isDataChartEnabled,
        viewedMonitorUuidByAutometricType: chartMonitorSetting,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSource, currentTable, isEventChartEnabled, isDataChartEnabled]);

  useEffect(() => {
    if (isSomeMetadataMetricEnabled(currentTable)) {
      getProfilerCurrentTableMetadataMetricsData(currentDataSource, currentTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSource, currentTable]);

  function refreshMetadataMetricsData(isRefresh = true) {
    return getProfilerCurrentTableMetadataMetricsData(currentDataSource, currentTable, {
      isRefresh,
      viewedMonitorUuidByAutometricType: chartMonitorSetting,
    });
  }

  function refreshAutoMetricsData(isRefresh = true) {
    return getProfilerCurrentTableAutoMetricsData(currentDataSource, currentTable, {
      isEventChartEnabled,
      isDataChartEnabled,
      isRefresh,
      viewedMonitorUuidByAutometricType: chartMonitorSetting,
    });
  }

  function refreshMetricsData(modifiedType, isRefresh = true) {
    getProfilerCurrentTableHealthData(currentDataSource, currentTable, {
      isRefresh: true,
    });
    if (
      metadataMetricTypes.has(modifiedType) ||
      modifiedType === MetricCategory.EVENT
    ) {
      return refreshMetadataMetricsData(isRefresh);
    } else {
      return refreshAutoMetricsData(isRefresh);
    }
  }

  useInterval(() => {
    let didRefresh = false;

    if (
      shouldChartRefreshMetrics(profilerCurrentTableAutoMetricsData) &&
      isTableMetricsDefined(currentTable) &&
      !profilerCurrentTableAutoMetricsData.loading
    ) {
      refreshAutoMetricsData();
      didRefresh = true;
    }

    if (
      !metadataMetricsData.loading &&
      isSomeMetadataMetricEnabled(currentTable) &&
      shouldChartRefreshMetrics(metadataMetricsData)
    ) {
      refreshMetadataMetricsData();
      didRefresh = true;
    }

    if (didRefresh) {
      getProfilerCurrentTableHealthData(currentDataSource, currentTable, {
        isRefresh: true,
      });
    }
  }, metricRefreshInterval);

  let dataDelayData = [];
  let dataDelayMetric = "";
  let dataDelayRules = null;
  let dataDelayIncidents = [];
  let dataDelayConfig = null;
  let dataDelayQueryPeriod = null;
  let dataDelayFocusedMonitorUuid =
    chartMonitorSetting[AutoMetricsType.DATA_DELAY] || "";

  let volumeData = [];
  let dataVolumeMetric = "";
  let dataVolumeRules = null;
  let dataVolumeIncidents = [];
  let dataVolumeConfig = null;
  let dataVolumeQueryPeriod = null;
  let dataVolumeFocusedMonitorUuid = chartMonitorSetting[AutoMetricsType.VOLUME] || "";

  const { rowCount, updateDelay, byteCount } =
    currentTable.profilerConfig?.metadataMetrics ?? {};

  const schemaChange = currentTable.profilerConfig.metadataMetrics?.schemaChange
    ? currentTable.profilerConfig.metadataMetrics.schemaChange
    : {
        enabled: false,
        monitoring: {
          enabled: false,
          alertConfig: {
            isMuted: false,
            channels: [],
            mutingSchedules: [],
          },
        },
      };

  let columnActivityData = [];
  let columnActivityMetric = null;
  let columnActivityRules = [];
  let columnActivityIncidents = [];
  let columnActivityQueryPeriod = null;
  let rowCountData = [];
  let rowCountMetric = "";
  let rowCountRules = null;
  let rowCountIncidents = [];
  let rowCountConfig = null;
  let rowCountQueryPeriod = null;
  let rowCountFocusedMonitorUuid = chartMonitorSetting[AutoMetricsType.ROW_COUNT] || "";

  let byteCountData = [];
  let byteCountMetric = "";
  let byteCountRules = null;
  let byteCountIncidents = [];
  let byteCountConfig = null;
  let byteCountQueryPeriod = null;
  let byteCountFocusedMonitorUuid =
    chartMonitorSetting[AutoMetricsType.BYTE_COUNT] || "";

  let updateDelayData = [];
  let updateDelayMetric = "";
  let updateDelayRules = null;
  let updateDelayIncidents = [];
  let updateDelayConfig = null;
  let updateDelayQueryPeriod = null;
  let updateDelayFocusedMonitorUuid =
    chartMonitorSetting[AutoMetricsType.UPDATE_DELAY] || "";

  const autoMetricsLoading = profilerCurrentTableAutoMetricsData.loading;
  if (!autoMetricsLoading) {
    const chartInfo = profilerCurrentTableAutoMetricsData.data?.chartInfo ?? {};
    const dataDelayChartInfo =
      chartInfo[AutoMetricsType.DATA_DELAY] &&
      chartInfo[AutoMetricsType.DATA_DELAY].length > 0
        ? chartInfo[AutoMetricsType.DATA_DELAY][0]
        : null;

    if (dataDelayChartInfo) {
      dataDelayData = dataDelayChartInfo.data || {};
      dataDelayMetric = dataDelayChartInfo.metric || null;
      dataDelayRules = dataDelayChartInfo.rules || [];
      dataDelayIncidents = dataDelayChartInfo.incidents || [];
      dataDelayQueryPeriod = dataDelayChartInfo.queryPeriod || null;
    }

    const dataVolumeChartInfo =
      chartInfo[AutoMetricsType.VOLUME] && chartInfo[AutoMetricsType.VOLUME].length > 0
        ? chartInfo[AutoMetricsType.VOLUME][0]
        : null;

    if (dataVolumeChartInfo) {
      volumeData = dataVolumeChartInfo.data || {};
      dataVolumeMetric = dataVolumeChartInfo.metric || null;
      dataVolumeRules = dataVolumeChartInfo.rules || [];
      dataVolumeIncidents = dataVolumeChartInfo.incidents || [];
      dataVolumeQueryPeriod = dataVolumeChartInfo.queryPeriod || null;
    }
  }

  const profilerConfig =
    currentTable && currentTable.profilerConfig ? currentTable.profilerConfig : null;
  if (profilerConfig) {
    if (profilerConfig.dataDelay) {
      dataDelayConfig = profilerConfig.dataDelay;
    }

    if (profilerConfig.volume) {
      dataVolumeConfig = profilerConfig.volume;
    }
  }

  const metadataMetricsLoading = metadataMetricsData.loading;
  if (!metadataMetricsLoading) {
    const chartInfo = metadataMetricsData.data?.chartInfo ?? {};
    const columnActivityChartInfo =
      chartInfo[AutoMetricsType.COLUMN_ACTIVITY] &&
      chartInfo[AutoMetricsType.COLUMN_ACTIVITY].length > 0
        ? chartInfo[AutoMetricsType.COLUMN_ACTIVITY][0]
        : null;

    if (columnActivityChartInfo) {
      columnActivityData = columnActivityChartInfo.data || {};
      columnActivityMetric = columnActivityChartInfo.metric || null;
      columnActivityRules = columnActivityChartInfo.rules || [];
      columnActivityIncidents = columnActivityChartInfo.incidents || [];
      columnActivityQueryPeriod = columnActivityChartInfo.queryPeriod || null;
    }

    const rowCountChartInfo =
      chartInfo[AutoMetricsType.ROW_COUNT] &&
      chartInfo[AutoMetricsType.ROW_COUNT].length > 0
        ? chartInfo[AutoMetricsType.ROW_COUNT][0]
        : null;

    if (rowCountChartInfo) {
      rowCountData = rowCountChartInfo.data || {};
      rowCountMetric = rowCountChartInfo.metric || null;
      rowCountRules = rowCountChartInfo.rules || [];
      rowCountIncidents = rowCountChartInfo.incidents || [];
      rowCountQueryPeriod = rowCountChartInfo.queryPeriod || null;
    }

    const byteCountChartInfo =
      chartInfo[AutoMetricsType.BYTE_COUNT] &&
      chartInfo[AutoMetricsType.BYTE_COUNT].length > 0
        ? chartInfo[AutoMetricsType.BYTE_COUNT][0]
        : null;

    if (byteCountChartInfo) {
      byteCountData = byteCountChartInfo.data || {};
      byteCountMetric = byteCountChartInfo.metric || null;
      byteCountRules = byteCountChartInfo.rules || [];
      byteCountIncidents = byteCountChartInfo.incidents || [];
      byteCountQueryPeriod = byteCountChartInfo.queryPeriod || null;
    }

    const updateDelayChartInfo =
      chartInfo[AutoMetricsType.UPDATE_DELAY] &&
      chartInfo[AutoMetricsType.UPDATE_DELAY].length > 0
        ? chartInfo[AutoMetricsType.UPDATE_DELAY][0]
        : null;

    if (updateDelayChartInfo) {
      updateDelayData = updateDelayChartInfo.data || {};
      updateDelayMetric = updateDelayChartInfo.metric || null;
      updateDelayRules = updateDelayChartInfo.rules || [];
      updateDelayIncidents = updateDelayChartInfo.incidents || [];
      updateDelayQueryPeriod = updateDelayChartInfo.queryPeriod || null;
    }
  }

  function onStartMonitor(
    _autoMetricType,
    relatedMetric,
    _relatedRule,
    _dqiConfig,
    lastEventTs,
    symptomTypeConfig
  ) {
    console.log(
      `Start monitoring for ${relatedMetric ? relatedMetric.metadata.uuid : ""}`
    );
    if (lastEventTs === 0) {
      console.log("lastEventTs is 0 for current creating rule.");
    }

    const ruleConfig = getDefaultRuleConfigForAutoMetrics(
      symptomTypeConfig,
      relatedMetric,
      currentDataSource,
      currentTable,
      null,
      lastEventTs
    );
    createSystemProfilerRule(ruleConfig).then(() =>
      refreshMetricsData(getMetricTypeFromConfigData(relatedMetric))
    );
  }

  function updateSchemaChange(newSchemaChange, opts = {}) {
    const { isRefresh = true } = opts;
    const newProfilerConfig = {
      ...currentTable.profilerConfig,
      metadataMetrics: {
        ...currentTable.profilerConfig.metadataMetrics,
        schemaChange: newSchemaChange,
      },
    };

    updateSystemProfileDataSourceTableRuleAlertingChannel(
      currentDataSource.metadata.uuid,
      currentSchema.uuid,
      currentTable.uuid,
      newProfilerConfig,
      currentTable.status
    ).then(() => {
      refreshMetricsData(MetricCategory.EVENT, isRefresh);
    });
  }

  function onGoToIncidentList(
    autoMetricType,
    relatedDataSource,
    relatedMetric,
    relatedRules,
    queryPeriod
  ) {
    const { dataSourceName, fullTableName } = getKPIProfilerConfigInfo(
      relatedMetric,
      relatedDataSource
    );
    const kpiName = relatedMetric.metadata.name;
    const userSetting = getDefaultUserSetting();
    userSetting.time = {
      currentInterval: "custom",
      startTime: fromUnixTime(queryPeriod.startTimestamp),
      endTime: fromUnixTime(queryPeriod.endTimestamp),
      current: fromUnixTime(queryPeriod.endTimestamp),
    };

    userSetting.localFilterSetting = {
      filterName: [],
      severity: [],
      dataSourceName: [dataSourceName],
      tableName: [fullTableName],
      kpiName: [kpiName],
      slice: [],
      tagName: [],
      direction: [],
      showMyRule: false,
      showProceededDataOnly: true,
    };

    const chartInfo = {
      autoMetricType,
      currentMetric: relatedMetric,
      dataSourceInfo: currentDataSource,
      tableInfo: currentTable,
      columnInfo: null,
    };
    onGoToIncidentPage({ userSetting, rules: relatedRules, chartInfo });
  }

  function onAlertChannelChange(metric, relatedRule, newAlertingChannels) {
    updateSystemProfileTableRuleAlertingChannel(relatedRule, newAlertingChannels).then(
      () => refreshMetricsData(getMetricTypeFromConfigData(metric))
    );
  }

  function onTriggerMetric(currentMetric) {
    triggerProfilerMetrics(currentDataSource.metadata.uuid, {
      metricUuids: [currentMetric.metadata.uuid],
    });
  }

  function onToggleLiveStatusClick(workspaceUuid, metric, isLive) {
    toggleKpiLiveStatus(workspaceUuid, metric, isLive, { refreshList: false }).then(
      () => refreshMetricsData(getMetricTypeFromConfigData(metric))
    );
  }

  function onToggleMonitorLiveStatusClick(metric, monitorUuid, monitors) {
    const targetMonitor = monitors.find(
      (monitor) => monitor.metadata.uuid === monitorUuid
    );
    if (targetMonitor) {
      toggleSystemProfilerRuleStatus(targetMonitor).then(() => {
        refreshMetricsData(getMetricTypeFromConfigData(metric));
      });
    }
  }

  function onDeleteMonitorMenuClick(monitorUuid, monitors) {
    const targetMonitor = monitors.find(
      (monitor) => monitor.metadata.uuid === monitorUuid
    );
    if (targetMonitor) {
      onDeleteMonitorClick(targetMonitor);
    }
  }

  function onSwitchMonitorClick(metricType, relatedMetric, newMonitorUuid) {
    setChartMonitorSetting({
      ...chartMonitorSetting,
      [metricType]: newMonitorUuid,
    });

    const opts = {
      dataSourceUuid: currentDataSource.metadata.uuid,
      tableUuid: currentTable.uuid,
      filterUuid: newMonitorUuid,
      metricUuid: relatedMetric.metadata.uuid,
      metric: relatedMetric,
      autoMetricType: metricType,
    };

    if (isMetadataMetric(relatedMetric)) {
      updateProfilerTableMetadataMetricsChartData(opts);
    } else {
      updateProfilerTableAutoMetricsChartData(opts);
    }
  }

  function onUpdateChartConfigClick(metricType, enabled) {
    setMetricTypeUpdating(metricType);
    updateTableConfig(
      toggleTableMetricType(currentTable, metricType, enabled),
      false
    ).then(() => {
      refreshMetricsData(metricType).then(() => {
        setMetricTypeUpdating(null);
      });
    });
  }

  function canEnableMetric(metricType) {
    return (
      canModifySettings &&
      dataSourceSupportsMetricType(currentDataSource, metricType) &&
      tableSupportsMetricType(currentTable, metricType)
    );
  }

  const isTableConfigured = profilerConfig?.enabled;

  const chartConfigs = {};

  if (
    isDataChartEnabled &&
    !isTableConfigured &&
    canEnableMetric(MetricCategory.DATA_DELAY)
  ) {
    chartConfigs[MetricCategory.DATA_DELAY] = {
      showConfigureTable: true,
    };
  } else if (
    isDataChartEnabled &&
    isTableConfigured &&
    (isTableMetricTypeEnabled(currentTable, MetricCategory.DATA_DELAY) ||
      canEnableMetric(MetricCategory.DATA_DELAY))
  ) {
    chartConfigs[MetricCategory.DATA_DELAY] = {
      showChart: {
        key: `data-delay-${dataDelayMetric?.metadata?.uuid}`,
        chartTitle: dataDelayMetric ? dataDelayMetric.metadata.name : "",
        chartDescription: dataDelayMetric ? dataDelayMetric.metadata.description : "",
        kpiName: "data delay",
        xTitle: "Recorded Time",
        yTitle: "Lag (minute)",
        data: dataDelayData,
        incidents: dataDelayIncidents,
        onStartMonitor: onStartMonitor.bind(
          null,
          AutoMetricsType.DATA_DELAY,
          dataDelayMetric,
          dataDelayRules,
          dataDelayConfig,
          dataDelayMetric?.status?.lastSampleTs || 0
        ),
        onChartEnable: () => onUpdateChartConfigClick(MetricCategory.DATA_DELAY, true),
        onChartDisable: () =>
          onUpdateChartConfigClick(MetricCategory.DATA_DELAY, false),
        onStopMonitor: (monitorUuid) =>
          onDeleteMonitorMenuClick(monitorUuid, dataDelayRules),
        onPauseMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, dataDelayRules),
        onResumeMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, dataDelayRules),
        onSwitchMonitor: onSwitchMonitorClick.bind(
          null,
          AutoMetricsType.DATA_DELAY,
          dataDelayMetric
        ),
        focusedMonitorUuid: dataDelayFocusedMonitorUuid,
        relatedMetric: dataDelayMetric,
        onGoToMetric: onGoToMetric.bind(null, dataDelayMetric),
        relatedRules: dataDelayRules,
        onGoToRule: onGoToRule.bind(null, dataDelayMetric),
        onCloneMonitor: onCloneMonitor.bind(null, dataDelayMetric),
        enableChart: dataDelayConfig?.enabled,
        onAlertChannelChange,
        onGoToIncidentList: onGoToIncidentList.bind(
          null,
          AutoMetricsType.DATA_DELAY,
          currentDataSource,
          dataDelayMetric,
          dataDelayRules,
          dataDelayQueryPeriod
        ),
        onTriggerMetric: onTriggerMetric.bind(null, dataDelayMetric),
        autoMetricType: AutoMetricsType.DATA_DELAY,
      },
    };
  }

  if (
    isDataChartEnabled &&
    !isTableConfigured &&
    canEnableMetric(MetricCategory.DATA_VOLUME)
  ) {
    chartConfigs[MetricCategory.DATA_VOLUME] = {
      showConfigureTable: true,
    };
  } else if (
    isDataChartEnabled &&
    isTableConfigured &&
    (isTableMetricTypeEnabled(currentTable, MetricCategory.DATA_VOLUME) ||
      canEnableMetric(MetricCategory.DATA_VOLUME))
  ) {
    chartConfigs[MetricCategory.DATA_VOLUME] = {
      showChart: {
        key: `data-volume-${dataVolumeMetric?.metadata?.uuid}`,
        chartTitle: dataVolumeMetric ? dataVolumeMetric.metadata.name : "",
        chartDescription: dataVolumeMetric ? dataVolumeMetric.metadata.description : "",
        kpiName: "data volume",
        xTitle: "Event Time",
        yTitle: "",
        data: volumeData,
        incidents: dataVolumeIncidents,
        onStartMonitor: onStartMonitor.bind(
          null,
          AutoMetricsType.VOLUME,
          dataVolumeMetric,
          dataVolumeRules,
          dataVolumeConfig,
          dataVolumeMetric?.status?.lastSampleTs || 0
        ),
        onChartEnable: () => onUpdateChartConfigClick(MetricCategory.DATA_VOLUME, true),
        onChartDisable: () =>
          onUpdateChartConfigClick(MetricCategory.DATA_VOLUME, false),
        onStopMonitor: (monitorUuid) =>
          onDeleteMonitorMenuClick(monitorUuid, dataVolumeRules),
        onPauseMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, dataVolumeRules),
        onResumeMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, dataVolumeRules),
        onSwitchMonitor: onSwitchMonitorClick.bind(
          null,
          AutoMetricsType.VOLUME,
          dataVolumeMetric
        ),
        focusedMonitorUuid: dataVolumeFocusedMonitorUuid,
        onTriggerMetric: onTriggerMetric.bind(null, dataVolumeMetric),
        relatedMetric: dataVolumeMetric,
        onGoToMetric: onGoToMetric.bind(null, dataVolumeMetric),
        relatedRules: dataVolumeRules,
        onGoToRule: onGoToRule.bind(null, dataVolumeMetric),
        onCloneMonitor: onCloneMonitor.bind(null, dataVolumeMetric),
        enableChart: dataVolumeConfig?.enabled,
        onAlertChannelChange,
        onGoToIncidentList: onGoToIncidentList.bind(
          null,
          AutoMetricsType.VOLUME,
          currentDataSource,
          dataVolumeMetric,
          dataVolumeRules,
          dataVolumeQueryPeriod
        ),
        autoMetricType: AutoMetricsType.VOLUME,
      },
    };
  }

  if (rowCount || canEnableMetric(MetricCategory.ROW_COUNT)) {
    chartConfigs[MetricCategory.ROW_COUNT] = {
      showChart: {
        key: `row-count-${rowCountMetric?.metadata?.uuid}`,
        chartTitle: rowCountMetric ? rowCountMetric.metadata.name : "",
        chartDescription: rowCountMetric ? rowCountMetric.metadata.description : "",
        kpiName: "row count",
        xTitle: "Recorded Time",
        yTitle: "Row Count",
        data: rowCountData,
        incidents: rowCountIncidents,
        onStartMonitor: onStartMonitor.bind(
          null,
          AutoMetricsType.ROW_COUNT,
          rowCountMetric,
          rowCountRules,
          rowCountConfig,
          rowCountMetric?.status?.lastSampleTs || 0
        ),
        onStopMonitor: (monitorUuid) =>
          onDeleteMonitorMenuClick(monitorUuid, rowCountRules),
        onPauseMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, rowCountRules),
        onResumeMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, rowCountRules),
        onChartEnable: () => onUpdateChartConfigClick(MetricCategory.ROW_COUNT, true),
        onChartDisable: () => onUpdateChartConfigClick(MetricCategory.ROW_COUNT, false),
        onSwitchMonitor: onSwitchMonitorClick.bind(
          null,
          AutoMetricsType.ROW_COUNT,
          rowCountMetric
        ),
        focusedMonitorUuid: rowCountFocusedMonitorUuid,
        relatedMetric: rowCountMetric,
        onGoToMetric: onGoToMetric.bind(null, rowCountMetric),
        relatedRules: rowCountRules,
        onGoToRule: onGoToRule.bind(null, rowCountMetric),
        onCloneMonitor: onCloneMonitor.bind(null, rowCountMetric),
        enableChart: rowCount,
        onAlertChannelChange,
        onGoToIncidentList: onGoToIncidentList.bind(
          null,
          AutoMetricsType.ROW_COUNT,
          currentDataSource,
          rowCountMetric,
          rowCountRules,
          rowCountQueryPeriod
        ),
        onTriggerMetric: onTriggerMetric.bind(null, rowCountMetric),
        autoMetricType: AutoMetricsType.ROW_COUNT,
      },
    };
  }

  if (byteCount || canEnableMetric(MetricCategory.BYTE_COUNT)) {
    chartConfigs[MetricCategory.BYTE_COUNT] = {
      showChart: {
        key: `byte-count-${byteCountMetric?.metadata?.uuid}`,
        chartTitle: byteCountMetric ? byteCountMetric.metadata.name : "",
        chartDescription: byteCountMetric ? byteCountMetric.metadata.description : "",
        kpiName: "byte count",
        xTitle: "Recorded Time",
        yTitle: "Value",
        data: byteCountData,
        incidents: byteCountIncidents,
        onStartMonitor: onStartMonitor.bind(
          null,
          AutoMetricsType.BYTE_COUNT,
          byteCountMetric,
          byteCountRules,
          byteCountConfig,
          byteCountMetric?.status?.lastSampleTs || 0
        ),
        onStopMonitor: (monitorUuid) =>
          onDeleteMonitorMenuClick(monitorUuid, byteCountRules),
        onPauseMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, byteCountRules),
        onResumeMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, byteCountRules),
        onChartEnable: () => onUpdateChartConfigClick(MetricCategory.BYTE_COUNT, true),
        onChartDisable: () =>
          onUpdateChartConfigClick(MetricCategory.BYTE_COUNT, false),
        onSwitchMonitor: onSwitchMonitorClick.bind(
          null,
          AutoMetricsType.BYTE_COUNT,
          byteCountMetric
        ),
        focusedMonitorUuid: byteCountFocusedMonitorUuid,
        relatedMetric: byteCountMetric,
        onGoToMetric: onGoToMetric.bind(null, byteCountMetric),
        relatedRules: byteCountRules,
        onGoToRule: onGoToRule.bind(null, byteCountMetric),
        onCloneMonitor: onCloneMonitor.bind(null, byteCountMetric),
        enableChart: byteCount,
        onAlertChannelChange,
        onGoToIncidentList: onGoToIncidentList.bind(
          null,
          AutoMetricsType.BYTE_COUNT,
          currentDataSource,
          byteCountMetric,
          byteCountRules,
          byteCountQueryPeriod
        ),
        onTriggerMetric: onTriggerMetric.bind(null, byteCountMetric),
        autoMetricType: AutoMetricsType.BYTE_COUNT,
      },
    };
  }

  if (updateDelay || canEnableMetric(MetricCategory.UPDATE_DELAY)) {
    chartConfigs[MetricCategory.UPDATE_DELAY] = {
      showChart: {
        key: `update-delay-${updateDelayMetric?.metadata?.uuid}`,
        chartTitle: updateDelayMetric ? updateDelayMetric.metadata.name : "",
        chartDescription: updateDelayMetric
          ? updateDelayMetric.metadata.description
          : "",
        kpiName: "update delay",
        xTitle: "Recorded Time",
        yTitle: "Lag (minute)",
        data: updateDelayData,
        incidents: updateDelayIncidents,
        onStartMonitor: onStartMonitor.bind(
          null,
          AutoMetricsType.UPDATE_DELAY,
          updateDelayMetric,
          updateDelayRules,
          updateDelayConfig,
          updateDelayMetric?.status?.lastSampleTs || 0
        ),
        onStopMonitor: (monitorUuid) =>
          onDeleteMonitorMenuClick(monitorUuid, updateDelayRules),
        onPauseMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, updateDelayRules),
        onResumeMonitor: (metric, monitorUuid) =>
          onToggleMonitorLiveStatusClick(metric, monitorUuid, updateDelayRules),
        onChartEnable: () =>
          onUpdateChartConfigClick(MetricCategory.UPDATE_DELAY, true),
        onChartDisable: () =>
          onUpdateChartConfigClick(MetricCategory.UPDATE_DELAY, false),
        onSwitchMonitor: onSwitchMonitorClick.bind(
          null,
          AutoMetricsType.UPDATE_DELAY,
          updateDelayMetric
        ),
        focusedMonitorUuid: updateDelayFocusedMonitorUuid,
        relatedMetric: updateDelayMetric,
        onGoToMetric: onGoToMetric.bind(null, updateDelayMetric),
        relatedRules: updateDelayRules,
        onGoToRule: onGoToRule.bind(null, updateDelayMetric),
        onCloneMonitor: onCloneMonitor.bind(null, updateDelayMetric),
        enableChart: updateDelay,
        onAlertChannelChange,
        onGoToIncidentList: onGoToIncidentList.bind(
          null,
          AutoMetricsType.UPDATE_DELAY,
          currentDataSource,
          updateDelayMetric,
          updateDelayRules,
          updateDelayQueryPeriod
        ),
        onTriggerMetric: onTriggerMetric.bind(null, updateDelayMetric),
        autoMetricType: AutoMetricsType.UPDATE_DELAY,
      },
    };
  }

  return (
    <div className="profiler-data-source-table-main-tab-container">
      {isEventChartEnabled && canEnableMetric(MetricCategory.COLUMN_ACTIVITY) && (
        <ProfilerDataSourceTableSchemaSummary
          tableUuid={currentTable.uuid}
          tableSchemaChange={schemaChange}
          alertingChannelList={alertingChannelList}
          updateTableSchemaChange={updateSchemaChange}
          eventList={columnActivityData}
          metric={columnActivityMetric}
          eventIncidents={columnActivityIncidents}
          loading={metadataMetricsLoading}
          workspaceUserPermissions={workspaceUserPermissions}
          onGoToIncidentList={onGoToIncidentList.bind(
            null,
            AutoMetricsType.COLUMN_ACTIVITY,
            currentDataSource,
            columnActivityMetric,
            columnActivityRules,
            columnActivityQueryPeriod
          )}
          canModifySettings={canModifySettings}
        />
      )}
      {Object.keys(chartConfigs).length > 0 && (
        <div className="profiler-data-source-table-chart-container">
          <div className="profiler-data-source-table-chart-list-container">
            {chartDisplayOrder.map((metricType) => {
              if (chartConfigs[metricType]?.showChart) {
                const {
                  chartTitle,
                  chartDescription,
                  xTitle,
                  yTitle,
                  data,
                  incidents,
                  focusedMonitorUuid,
                  onStartMonitor,
                  onStopMonitor,
                  onPauseMonitor,
                  onResumeMonitor,
                  onSwitchMonitor,
                  onCloneMonitor,
                  onChartEnable,
                  onChartDisable,
                  autoMetricType,
                  relatedRules,
                  onGoToRule,
                  relatedMetric,
                  onGoToMetric,
                  onTriggerMetric,
                  enableChart,
                  onGoToIncidentList,
                  key,
                } = chartConfigs[metricType].showChart;

                const metricUuid = relatedMetric ? relatedMetric.metadata.uuid : "";
                const isMetadataMetric = metadataMetricTypes.has(metricType);
                const loading = isMetadataMetric
                  ? metadataMetricsLoading
                  : autoMetricsLoading;
                const updating = metricTypeUpdating === metricType;

                return (
                  <NgChartView
                    key={key}
                    enableConfig={{
                      enableConfigMode: true,
                      enabled: enableChart,
                      onClick: onChartEnable,
                      enableButtonDisabled: updating,
                    }}
                    noCardContainer={!enableChart}
                    monitorConfig={{
                      monitorUuid: focusedMonitorUuid,
                      onStartMonitor,
                      onStopMonitor,
                      onPauseMonitor: (monitorUuid) =>
                        onPauseMonitor(relatedMetric, monitorUuid),
                      onResumeMonitor: (monitorUuid) =>
                        onResumeMonitor(relatedMetric, monitorUuid),
                      onSwitchMonitor,
                      onCloneMonitor,
                      relatedRules,
                      onGoToRule,
                      enableMetric: !!relatedMetric,
                      enableSwitchMonitor: true,
                      enableChartOption: true,
                      onGoToMetric,
                      monitorStatus: getMonitorStatusFromRule(relatedRules),
                      onAlertChannelChange: (...args) =>
                        onAlertChannelChange(relatedMetric, ...args),
                      alertingChannelList,
                    }}
                    incidentConfig={{
                      onGoToIncidentList,
                    }}
                    metricAggregationInterval={
                      relatedMetric ? getKPIInterval(relatedMetric) : ""
                    }
                    metric={relatedMetric}
                    metricType={autoMetricType}
                    metricUuid={metricUuid}
                    title={chartTitle}
                    titleDescription={chartDescription}
                    showSubTitle={true}
                    data={data}
                    loading={loading || updating}
                    incidents={incidents}
                    enableChartOption={false}
                    config={profilerTableChartViewConfig(
                      xTitle,
                      yTitle,
                      getChartSymptomConfigForFocusedMonitor(
                        relatedRules,
                        focusedMonitorUuid
                      )
                    )}
                    workspaceUserPermissions={workspaceUserPermissions}
                    onToggleMetricLiveStatusClick={onToggleLiveStatusClick}
                    onTriggerMetricClick={onTriggerMetric}
                    onDisableMetricClick={onChartDisable}
                    onQueryHistoryClick={onOpenMetricQueryHistory}
                    dataSource={currentDataSource}
                    showMetricStatusBadge
                  />
                );
              } else if (chartConfigs[metricType]?.showConfigureTable) {
                return (
                  <ConfigureTableCard
                    key={`configure-table-${metricType}`}
                    metricType={metricType}
                    openTableConfig={openTableConfig}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfilerDataSourceTableMainTab;
