import React, { useState } from "react";
import QueryHistoryTable from "../../../query-history-table/query-history-table";
import TimeRangeSelector from "../../../time-range-selector";
import {
  MAX_LOOKBACK_DAYS,
  timeRangeDefaultRange,
  timeRangeDisabledDate,
  timeRangeSelectorPresets,
} from "../../../../views/settings/query-governance/query-governance";
import NgButton from "../../../button/ng-button";
import { getUnixTime } from "date-fns";
import { TakeoverWidth } from "../../../../utils/enums";

import "./metric-config-preview-query-history-tab.scss";

function MetricConfigPreviewQueryHistoryTab(props) {
  const {
    configData,
    currentKpiQueryList,
    dataSourceList,
    openWorkspaceTakeover,
    closeTakeover,
    getCurrentKpiQueryList,
  } = props;

  const [timeRange, setTimeRange] = useState(timeRangeDefaultRange);

  const dataSourceInfo = {};
  dataSourceList.forEach((currentDataSource) => {
    dataSourceInfo[currentDataSource.metadata.uuid] = currentDataSource.metadata.name;
  });

  function getQueryList() {
    getCurrentKpiQueryList(configData, {
      startTs: getUnixTime(timeRange.startTime),
      endTs: getUnixTime(timeRange.endTime),
    });
  }

  return (
    <div className="metric-config-preview-query-history-tab-container">
      <div className="metric-config-preview-query-history-tab-top-controls">
        <div className="metric-config-preview-query-history-tab-top-controls-timerange">
          <TimeRangeSelector
            size="large"
            value={timeRange}
            disabledDate={timeRangeDisabledDate(MAX_LOOKBACK_DAYS)}
            allowClear={false}
            ranges={timeRangeSelectorPresets()}
            onChange={setTimeRange}
          />
        </div>
        <NgButton outline size="large" onClick={() => getQueryList()}>
          Update
        </NgButton>
      </div>
      <div className="metric-config-preview-query-history-tab-table">
        <QueryHistoryTable
          queryList={currentKpiQueryList}
          dataSourceInfo={dataSourceInfo}
          onOpenQueryDetail={(detailComponent) =>
            openWorkspaceTakeover(detailComponent, TakeoverWidth.WIDE, () =>
              closeTakeover()
            )
          }
          onCloseQueryDetail={closeTakeover}
        />
      </div>
    </div>
  );
}

export default MetricConfigPreviewQueryHistoryTab;
