import { NextGenPalette } from "../../../utils/color";

export function CheckOneIcon(_props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4629 2.46289C3.62346 1.30235 5.22842 0.583496 7.00016 0.583496C8.7719 0.583496 10.3769 1.30235 11.5374 2.46289C12.698 3.62345 13.4168 5.22842 13.4168 7.00016C13.4168 8.7719 12.698 10.3769 11.5374 11.5374C10.3769 12.698 8.7719 13.4168 7.00016 13.4168C5.22842 13.4168 3.62346 12.698 2.4629 11.5374C1.30235 10.3769 0.583496 8.7719 0.583496 7.00016C0.583496 5.22842 1.30235 3.62345 2.4629 2.46289ZM10.3293 5.66264C10.5571 5.43484 10.5571 5.06549 10.3293 4.83768C10.1015 4.60988 9.73216 4.60988 9.50435 4.83768L6.41683 7.92521L5.07931 6.58768C4.8515 6.35988 4.48216 6.35988 4.25435 6.58768C4.02654 6.81549 4.02654 7.18484 4.25435 7.41264L6.00435 9.16264C6.23216 9.39045 6.6015 9.39045 6.82931 9.16264L10.3293 5.66264Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}

export function DataArrivalIcon(_props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M9.03756 4.66244C9.26537 4.43463 9.26537 4.06529 9.03756 3.83748C8.80975 3.60967 8.44041 3.60967 8.2126 3.83748L5.12508 6.925L3.78756 5.58748C3.55975 5.35967 3.19041 5.35967 2.9626 5.58748C2.7348 5.81529 2.7348 6.18463 2.9626 6.41244L4.7126 8.16244C4.94041 8.39024 5.30975 8.39024 5.53756 8.16244L9.03756 4.66244Z"
        fill={NextGenPalette.systemBlack}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.750081 0.166626C0.427915 0.166626 0.166748 0.427793 0.166748 0.749959V11.25C0.166748 11.5721 0.427915 11.8333 0.750081 11.8333H11.2501C11.5722 11.8333 11.8334 11.5721 11.8334 11.25V0.749959C11.8334 0.427793 11.5722 0.166626 11.2501 0.166626H7.75008C7.51505 0.166626 7.30298 0.307671 7.21211 0.524419C7.04458 0.92403 6.85341 1.17693 6.66141 1.32854C6.47811 1.47328 6.26617 1.54848 6.00008 1.54848C5.73399 1.54848 5.52205 1.47328 5.33875 1.32854C5.14675 1.17693 4.95558 0.92403 4.78805 0.524419C4.69718 0.307671 4.48511 0.166626 4.25008 0.166626H0.750081ZM1.33341 10.6666V1.33329H3.88139C4.07617 1.69842 4.3176 2.00874 4.61575 2.24417C5.01578 2.56005 5.48573 2.71515 6.00008 2.71515C6.51443 2.71515 6.98438 2.56005 7.38442 2.24417C7.68257 2.00874 7.92399 1.69842 8.11878 1.33329H10.6667V10.6666H1.33341Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}
