import React from "react";
import AddSlackIntegrationDialog from "../slack/add-slack-integration-dialog";
import { AlertingChannelType } from "../../../utils/alerting";

function AddMSTeamsIntegrationDialog(props) {
  return (
    <AddSlackIntegrationDialog
      {...props}
      alertChannelType={AlertingChannelType.MSTEAMS}
    />
  );
}

export default AddMSTeamsIntegrationDialog;
