import React, { useMemo, useState } from "react";
import { Input, Select } from "antd";
import { default as Icon, DeleteOutlined } from "@ant-design/icons";
import {
  LabeledInput,
  LabeledInputNumber,
  LabeledSelect,
  LabeledMultiSelect,
  LabeledTimestampSelect,
} from "../../labeled-control/labeled-control";
import { CustomScheduleCollectionConfig } from "../../custom-schedule";
import TimestampColumnSelect from "../../timestamp-column-select/";
import DurationInput from "../../duration-input/duration-input";
import ConfirmationDialog from "../../confirmation-dialog/ng-index";
import { ConfigSummaryCardRow, ConfigSummaryCardRowDiff } from "../summary-card";
import MetricPartitionsConfigItem from "./metric-partitions-config-item";
import {
  MetricConditionsSetting,
  MetricWhereConditionsSetting,
} from "./metric-conditions-config-item";
import {
  CustomSqlIcon as MetricCustomSqlIconComponent,
  MetricAggregationIntervalIcon,
  MetricAggregationTimezoneIcon,
  MetricAggregationTypeIcon,
  metricCategoryIconComponent,
  MetricColumnIcon,
  MetricCompareConfigItemIcon,
  MetricConditionsConfigItemIcon,
  MetricDataSourceTypeIcon,
  MetricDimensionTypeIcon,
  MetricNameIcon,
  MetricPartitionColumnIcon,
  MetricPartitionOffsetIcon,
  MetricSchemaIcon,
  MetricSeasonalityIcon,
  MetricSliceByColumnsIcon,
  MetricSynchronizationDelayIcon,
  MetricTableIcon,
  MetricTimestampColumnIcon,
  MetricValueColumnsIcon,
  MetricWhereClauseIcon,
  MetricQueryScopeIcon,
  MetricDataCollectionScheduleIcon,
  MetricBackfillIcon,
  MetricCronInputIcon,
  MetricDataCollectionWindowIcon,
} from "./icons";
import {
  getMetricTypeFromConfigData,
  metricCategoryGroupNames,
  metricTypeGroups,
  missingValueFillingOptions,
} from "../utils";
import {
  collectionWindowOptions,
  getDataCollectionOptions,
  getGroupedOptions,
  getOptionByValue,
  TimezoneOptions,
  NO_SELECTION_OPTION_LABEL,
} from "../../../utils/options";
import {
  getDisplayTimeFromSecond,
  getSeasonConfigFromSeason,
  getSeasonFromSeasonConfig,
} from "../../../utils/time";
import { getColumnTypeCategory } from "../../../utils/column";
import {
  CollectionModeType,
  getConformityConditionTypeDisplayName,
  getSeasonalityDisplayName,
  SeasonalityTypeConst,
  ConformityConditionType,
  DataSourceType,
  TableColumnTypeCategory,
  MetricCategory,
} from "../../../utils/enums";
import { DivWithClassName } from "../../layout";
import { isTimestampTimezoneConfigEnabled } from "../../../utils/general";
import { getDisplayTableName } from "../../../utils/datasource";
import { PauseIcon, ResumeIcon } from "../../../views/profiler/menu-icons";
import NgMultiSelect from "../../multi-select/ng-multi-select";
import NgSelect from "../../select";
import { classesName } from "../../../utils/css";
import { DIFF_STATES, extractDiffState } from "../../../utils/compare";
import { Spinner } from "../../../atom/spinner";

import "./index.scss";

function getColumnOptions(currentTableColumnList, expand = false) {
  return currentTableColumnList.map(function (columnItem) {
    const expandItem = expand ? columnItem : {};
    return {
      label: columnItem.columnName,
      value: columnItem.columnName,
      ...expandItem,
    };
  });
}

function getSliceOptions(currentTableColumnList, isMultiple = false) {
  if (currentTableColumnList.length === 0) {
    return [];
  }

  const ret = [];
  if (!isMultiple) {
    ret.push({ label: NO_SELECTION_OPTION_LABEL, value: "" });
  }

  ret.push(...getColumnOptions(currentTableColumnList, true));

  return ret;
}

export function ComponentWithExtraProps({ component, ...props }) {
  if (!component) {
    return null;
  }
  return React.cloneElement(component, props);
}

export function MetricTypeConfigItem(props) {
  const {
    value,
    options,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;

  const groupedOptions = useMemo(() => {
    return getGroupedOptions(options, metricTypeGroups);
  }, [options]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={metricCategoryIconComponent(value)} />}
        label="Metric type"
        value={getOptionByValue(options, value)?.label}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    const metricTypeDisplayName = getOptionByValue(options, value)?.label;
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={metricCategoryIconComponent(value)} />}
        label={metricTypeDisplayName}
      />
    );
  }

  return (
    <LabeledSelect
      label="Metric type"
      showSearch
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      dropdownClassName="metric-type-options-container" // newer versions of antd uses popupClassName prop
      {...otherProperties}
    >
      {groupedOptions.map(({ label: groupLabel, options: groupOptions }) => (
        <Select.OptGroup label={metricCategoryGroupNames[groupLabel]} key={groupLabel}>
          {groupOptions.map((option) => (
            <Select.Option
              value={option.value}
              label={option.label}
              key={option.value}
              className="metric-type-option"
            >
              <Icon component={metricCategoryIconComponent(option.value)} />{" "}
              {option.label}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </LabeledSelect>
  );
}

export function MetricNameConfigItem(props) {
  const {
    value,
    options,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricNameIcon} />}
        label="Metric name"
        value={value}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow icon={<Icon component={MetricNameIcon} />} label={value} />
    );
  }

  return (
    <LabeledInput
      label="Metric name"
      autoComplete="off"
      value={value}
      onChange={(e) => {
        const newValue = e.target.value;
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function DimensionConfigItem(props) {
  const {
    value,
    options,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricDimensionTypeIcon} />}
        label="Quality dimension"
        value={getOptionByValue(options, value)?.label}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricDimensionTypeIcon} />}
        label={getOptionByValue(options, value)?.label}
      />
    );
  }

  return (
    <LabeledSelect
      label="Quality dimension"
      showSearch
      options={options}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function MetricStatusConfigItem(props) {
  const {
    value,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;

  const options = [
    { label: "Live", value: true },
    { label: "Paused", value: false },
  ];

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={value ? ResumeIcon : PauseIcon} />}
        label="Status"
        value={getOptionByValue(options, value)?.label}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={value ? ResumeIcon : PauseIcon} />}
        label={`Status - ${getOptionByValue(options, value)?.label}`}
      />
    );
  }

  return (
    <LabeledSelect
      label="Status"
      showSearch
      options={options}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function DataSourceConfigItem(props) {
  const {
    dataSourceList,
    value,
    onChange,
    isEdit = true,
    diffState = null,
    label = "Datasource",
    ...otherProps
  } = props;
  const dataSourceOptions = useMemo(() => {
    return dataSourceList.map((currentDataSource) => {
      return {
        label: currentDataSource.metadata.name.trim(),
        value: currentDataSource.metadata.uuid,
      };
    });
  }, [dataSourceList]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricDataSourceTypeIcon} />}
        label={label}
        value={getOptionByValue(dataSourceOptions, value)?.label}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricDataSourceTypeIcon} />}
        label={getOptionByValue(dataSourceOptions, value)?.label}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      value={value}
      options={dataSourceOptions}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange?.(newValue);
      }}
      enableSorting={true}
      {...otherProps}
    />
  );
}

export function SchemaConfigItem(props) {
  const {
    schemaList,
    value,
    onChange,
    isEdit = true,
    optional = false,
    isLabeled = true,
    diffState = null,
    ...otherProps
  } = props;

  const schemaOptions = useMemo(() => {
    return schemaList.map(({ name }) => {
      return {
        label: name,
        value: name,
      };
    });
  }, [schemaList]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricSchemaIcon} />}
        label="Schema"
        value={value}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricSchemaIcon} />}
        label={value}
      />
    );
  }

  let label = "Schema";
  if (optional) {
    label += " (optional)";
  }

  return (
    <ComponentWithExtraProps
      component={
        isLabeled ? <LabeledSelect label={label} /> : <NgSelect placeholder={label} />
      }
      showSearch
      options={schemaOptions}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      enableSorting={true}
      allowClear={optional}
      {...otherProps}
    />
  );
}

export function TableConfigItem(props) {
  const {
    tableList,
    value,
    onChange,
    isEdit = true,
    optional = false,
    isLabeled = true,
    diffState = null,
    label = "Table",
    ...otherProps
  } = props;

  const tableOptions = useMemo(() => {
    if (!isEdit) {
      return tableList.map((currentTable) => {
        return {
          label: getDisplayTableName(currentTable.tableName).trim(),
          value: currentTable.tableUuid,
        };
      });
    }

    return (
      tableList
        // It's possible for metrics to be created outside of the wizard on tables that have not been
        // configured for metrics (e.g. metadata metrics). When editing these we want to keep the selected table
        // in the options list.
        .filter(
          (currentTable) =>
            currentTable?.profilerConfig?.enabled || value === currentTable.tableUuid
        )
        .map((currentTable) => {
          return {
            label: getDisplayTableName(currentTable.tableName).trim(),
            value: currentTable.tableUuid,
          };
        })
    );
  }, [tableList, isEdit, value]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricTableIcon} />}
        label={label}
        value={getOptionByValue(tableOptions, value)?.label}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricTableIcon} />}
        label={getOptionByValue(tableOptions, value)?.label}
      />
    );
  }

  let tableLabel = "Table";
  if (optional) {
    tableLabel += " (optional)";
  }

  return (
    <ComponentWithExtraProps
      component={
        isLabeled ? (
          <LabeledSelect label={tableLabel} />
        ) : (
          <NgSelect placeholder={tableLabel} />
        )
      }
      showSearch
      options={tableOptions}
      value={value}
      allowClear={optional}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        const currentTable = tableList.find(({ tableUuid }) => newValue === tableUuid);
        onChange(newValue, currentTable);
      }}
      enableSorting={true}
      {...otherProps}
    />
  );
}

const ColumnConfigMultiSelectComponent = ({ isLabeled, label, ...restProps }) => (
  <ComponentWithExtraProps
    component={
      isLabeled ? (
        <LabeledMultiSelect label={label} />
      ) : (
        <NgMultiSelect placeholder={label} />
      )
    }
    mode="multiple"
    optionFilterProp="label"
    maxTagCount={1}
    {...restProps}
  />
);

const ColumnConfigSingleSelectComponent = ({ isLabeled, label, ...restProps }) => (
  <ComponentWithExtraProps
    component={
      isLabeled ? <LabeledSelect label={label} /> : <NgSelect placeholder={label} />
    }
    enableSorting
    {...restProps}
  />
);

export function ColumnConfigItem(props) {
  const {
    columnList,
    value,
    onChange,
    isEdit = true,
    optional = false,
    multiple = false,
    isLabeled = true,
    configData,
    diffState = null,
    ...otherProps
  } = props;

  const isDistributionOrFullCompareMetric =
    configData &&
    (getMetricTypeFromConfigData(configData) === MetricCategory.DISTRIBUTION ||
      getMetricTypeFromConfigData(configData) === MetricCategory.FULL_COMPARE);

  const getColumnLabel = (columnName, masked) => {
    return masked ? `${columnName} (column masked)` : columnName.trim();
  };

  const columnOptions = useMemo(() => {
    return columnList.map((currentColumn) => {
      return {
        label: getColumnLabel(
          currentColumn.columnName,
          currentColumn.profilerConfig?.masked
        ),
        value: currentColumn.uuid,
        disabled: !!(
          isDistributionOrFullCompareMetric && currentColumn.profilerConfig?.masked
        ),
      };
    });
  }, [columnList, isDistributionOrFullCompareMetric]);

  const columnUuidToColumn = useMemo(() => {
    const result = {};
    columnList.forEach((currentColumn) => (result[currentColumn.uuid] = currentColumn));
    return result;
  }, [columnList]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricColumnIcon} />}
        label="Column"
        value={
          multiple
            ? value.map((val) => columnUuidToColumn[val]?.columnName).join(", ")
            : columnUuidToColumn[value]?.columnName
        }
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    let normalizedValue = multiple ? value : [value];
    const currentLabelList = [];
    for (let currentColumnUuid of normalizedValue) {
      if (columnUuidToColumn[currentColumnUuid]?.columnName) {
        currentLabelList.push(columnUuidToColumn[currentColumnUuid]?.columnName);
      }
    }
    const normalizedLabel = currentLabelList.join(", ");
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricColumnIcon} />}
        label={normalizedLabel}
      />
    );
  }

  let label = "Column";
  if (optional) {
    label += " (optional)";
  }

  const SelectComponent = multiple
    ? ColumnConfigMultiSelectComponent
    : ColumnConfigSingleSelectComponent;

  return (
    <SelectComponent
      label={label}
      showSearch
      options={columnOptions}
      value={value}
      allowClear={optional}
      onChange={(newValue) => {
        if (newValue === value && !multiple) {
          return;
        }
        if (!multiple) {
          onChange(newValue, columnUuidToColumn[newValue] || null);
          return;
        }

        const newColumnList = [];
        for (let currentColumnUuid of newValue) {
          if (columnUuidToColumn[currentColumnUuid]) {
            newColumnList.push(columnUuidToColumn[currentColumnUuid]);
          }
        }

        onChange(newValue, newColumnList);
      }}
      isLabeled={isLabeled}
      {...otherProps}
    />
  );
}

export function AggregationTypeConfigItem(props) {
  const {
    value,
    options,
    onChange,
    isEdit = true,
    extraLabel = "",
    diffState = null,
    ...otherProps
  } = props;

  if (diffState && !isEdit) {
    const valueLabel = getOptionByValue(options, value)?.label;
    if (!valueLabel) {
      // Only valid values should be visible in the diff
      return null;
    }
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricAggregationTypeIcon} />}
        label="Function"
        value={valueLabel}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    const typeLabel = getOptionByValue(options, value)?.label;
    if (!typeLabel) {
      return null;
    }
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricAggregationTypeIcon} />}
        label={extraLabel ? `${typeLabel} - ${extraLabel}` : typeLabel}
      />
    );
  }

  return (
    <LabeledSelect
      label="Function"
      showSearch
      enableSorting
      options={options}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProps}
    />
  );
}

export function AggregationWindowConfigItem(props) {
  const {
    label = "Aggregation interval",
    value,
    onChange,
    options,
    isEdit = true,
    diffState = null,
    ...otherProps
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricAggregationIntervalIcon} />}
        label={label}
        value={getOptionByValue(options, value)?.label}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricAggregationIntervalIcon} />}
        label={`${label} - ${getOptionByValue(options, value)?.label || ""}`}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      options={options}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProps}
    />
  );
}

export function QueryTimezoneConfigItem(props) {
  const {
    value,
    options = TimezoneOptions,
    onChange,
    isEdit = true,
    isUTCOnly = false,
    label = "Aggregation timezone",
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricAggregationTimezoneIcon} />}
        label={label}
        value={value}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricAggregationTimezoneIcon} />}
        label={`${label} - ${value || ""}`}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      options={isUTCOnly ? options.filter(({ value }) => value === "UTC") : options}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

function LabeledSelectWithLoadingOption(props) {
  const {
    loadOptionConfig = {
      isOptionLoading: false,
      enableLoadOption: false,
      isOptionLoaded: true,
      onClickLoad: null,
    },
    selectComponent: SelectComponent = LabeledSelect,
    ...otherProperties
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const {
    enableLoadOption = false,
    isOptionLoaded = true,
    isOptionLoading = false,
    onClickLoad = null,
  } = loadOptionConfig;

  function onDropdownVisibleChange(open) {
    if (enableLoadOption && !isOptionLoaded && open) {
      setIsConfirmationDialogOpen(true);
      return;
    }
    setIsDropdownOpen(open);
  }

  return (
    <>
      <SelectComponent
        loading={isOptionLoading}
        open={isDropdownOpen}
        onDropdownVisibleChange={onDropdownVisibleChange}
        {...otherProperties}
      />
      <ConfirmationDialog
        modalIsOpen={isConfirmationDialogOpen}
        setModalIsOpen={setIsConfirmationDialogOpen}
        okClicked={() => {
          onClickLoad();
        }}
        title={"Validate SQL to view columns"}
        confirmationMsg={"Validate the SQL query to view the column list."}
        okText={"Validate"}
        isOKEnabled={true}
      />
    </>
  );
}

export function ValueColumnsConfigItem(props) {
  const {
    value,
    onChange,
    columnList,
    isEdit = true,
    label = "Column",
    diffState = null,
    ...otherProperties
  } = props;
  const columnOptions = useMemo(() => {
    return getColumnOptions(columnList);
  }, [columnList]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricValueColumnsIcon} />}
        label={label}
        value={value?.[0]?.columnName}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricValueColumnsIcon} />}
        label={`${label}${value?.[0]?.columnName}`}
      />
    );
  }

  return (
    <LabeledSelectWithLoadingOption
      label={label}
      showSearch
      options={columnOptions}
      value={value?.[0]?.columnName}
      enableSorting={true}
      onChange={(newValueColumnName) => {
        if (newValueColumnName === value?.[0]?.columnName) {
          return;
        }

        onChange([{ columnName: newValueColumnName }]);
      }}
      {...otherProperties}
    />
  );
}

export function TimestampColumnConfigItem(props) {
  const {
    value,
    onChange,
    columnList,
    isEdit = true,
    label = "Timestamp",
    showAllColumns = false,
    supportVirtualTimestamp = false,
    dataSource = null,
    partitions = [],
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricTimestampColumnIcon} />}
        label={label}
        value={supportVirtualTimestamp ? value.timestampColumn : value}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricTimestampColumnIcon} />}
        label={`${label} - ${
          (supportVirtualTimestamp ? value.timestampColumn : value) || ""
        }`}
      />
    );
  }

  if (!supportVirtualTimestamp) {
    return (
      <LabeledSelectWithLoadingOption
        selectComponent={LabeledTimestampSelect}
        label={label}
        showSearch
        showAllColumns={showAllColumns}
        columnList={columnList}
        value={value}
        onChange={(newValue) => {
          if (newValue === value) {
            return;
          }

          onChange(newValue);
        }}
        {...otherProperties}
      />
    );
  }

  let timestampColumnList;
  if (showAllColumns) {
    timestampColumnList = columnList;
  } else {
    timestampColumnList = [];
    const isDataBrick =
      dataSource?.config.connection.type === DataSourceType.DATABRICKS;
    for (let currentColumn of columnList) {
      if (
        dataSource?.config?.governance?.indexedTimestampColumnsOnly &&
        !currentColumn.isIndexed
      ) {
        continue;
      }

      if (getColumnTypeCategory(currentColumn) === TableColumnTypeCategory.TIMESTAMP) {
        timestampColumnList.push(currentColumn);
      } else if (
        isDataBrick &&
        partitions &&
        partitions.length === 1 &&
        currentColumn.columnName === partitions[0].columnName
      ) {
        timestampColumnList.push(currentColumn);
      } else if (
        currentColumn.columnName === value.timestampColumn &&
        value.timestampColumnFunctions
      ) {
        timestampColumnList.push(currentColumn);
      }
    }
  }

  return (
    <TimestampColumnSelect
      size={"large"}
      label={label}
      value={value}
      showSearch
      timestampColumnList={timestampColumnList}
      onChange={onChange}
      {...otherProperties}
    />
  );
}

export function TimestampTimezoneWithDerivedConfigItem(props) {
  const {
    timestampColumn = "",
    columnList = [],
    value,
    disabled = false,
    ...otherProps
  } = props;
  const allowDataTimezoneConfig =
    !timestampColumn ||
    !columnList?.length ||
    isTimestampTimezoneConfigEnabled(
      columnList.find(({ columnName }) => columnName === timestampColumn)?.columnType
    );

  return (
    <TimestampTimezoneConfigItem
      {...otherProps}
      value={allowDataTimezoneConfig ? value : "Derived"}
      disabled={disabled || !allowDataTimezoneConfig}
    />
  );
}

export function TimestampTimezoneConfigItem(props) {
  const {
    value,
    options = TimezoneOptions,
    onChange,
    isEdit = true,
    isUTCOnly = false,
    label = "Timestamp Timezone",
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricAggregationTimezoneIcon} />}
        label={label}
        value={value}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      // @todo replace with correct icon
      <ConfigSummaryCardRow
        icon={<Icon component={MetricAggregationTimezoneIcon} />}
        label={`${label} - ${value}`}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      staticLabel
      options={isUTCOnly ? options.filter(({ value }) => value === "UTC") : options}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function SeasonalityConfigItem(props) {
  const {
    value,
    onChange,
    seasonalityList = [],
    isEdit = true,
    status,
    diffState = null,
    ...otherProperties
  } = props;
  const normalizedSeasonality = getSeasonFromSeasonConfig(value);
  const { basicSeasonality, customSeasonPeriods = [] } = normalizedSeasonality;
  const currentSeasonalityLabel =
    customSeasonPeriods.length === 0
      ? getSeasonalityDisplayName(basicSeasonality)
      : `${getSeasonalityDisplayName(basicSeasonality)} (Custom)`;
  const label = "Seasonality";

  const options = useMemo(() => {
    if (!isEdit) {
      return [];
    }

    const result = seasonalityList.map((basicSeasonality, index) => ({
      label: getSeasonalityDisplayName(basicSeasonality),
      value: index,
    }));

    if (customSeasonPeriods.length > 0) {
      result.push({ label: currentSeasonalityLabel, value: result.length });
    }

    return result;
  }, [seasonalityList, customSeasonPeriods, isEdit, currentSeasonalityLabel]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricSeasonalityIcon} />}
        label={label}
        value={currentSeasonalityLabel}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricSeasonalityIcon} />}
        label={`${label} - ${currentSeasonalityLabel}`}
      />
    );
  }

  const normalizedValue =
    customSeasonPeriods.length > 0
      ? seasonalityList.length
      : seasonalityList.findIndex(
          (currentBasicSeasonality) => currentBasicSeasonality === basicSeasonality
        );
  let detectedSeasonalityMessage = null;
  if (isEdit && basicSeasonality === SeasonalityTypeConst.AUTO && status) {
    if (status?.runtimeConfig?.seasonality) {
      const normalizedDetectedAutoSeasonality = getSeasonFromSeasonConfig(
        status.runtimeConfig.seasonality
      );
      detectedSeasonalityMessage = `Detected seasonality: ${getSeasonalityDisplayName(
        normalizedDetectedAutoSeasonality.basicSeasonality
      )}`;
    } else {
      detectedSeasonalityMessage = "Autoseasonality detection in progress";
    }
  }

  return (
    <div className="seasonality-select-container">
      <LabeledSelect
        label={label}
        showSearch
        staticLabel
        options={options}
        value={normalizedValue}
        optionLabelProp={"label"}
        onChange={(newValue) => {
          if (newValue === normalizedValue) {
            return;
          }

          onChange(
            getSeasonConfigFromSeason({
              basicSeasonality: seasonalityList[newValue],
              customSeasonPeriods: [],
            })
          );
        }}
        {...otherProperties}
      />
      {detectedSeasonalityMessage && (
        <div className="seasonality-detected-message">{detectedSeasonalityMessage}</div>
      )}
    </div>
  );
}

export function SliceByColumnsConfigItem(props) {
  const {
    value,
    onChange,
    columnList = [],
    isEdit = true,
    mode = "multiple",
    label = "Slice column (optional)",
    diffState = null,
    ...otherProperties
  } = props;

  const isMultiple = useMemo(() => mode === "multiple", [mode]);

  const sliceColumnOptions = useMemo(() => {
    const originalSliceOptions = getSliceOptions(columnList, isMultiple);

    return originalSliceOptions.map((option) => ({
      ...option,
      label: option.profilerConfig?.masked
        ? `${option.label} (column masked)`
        : option.label,
      disabled: option.profilerConfig?.masked,
    }));
  }, [columnList, isMultiple]);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricSliceByColumnsIcon} />}
        label={label}
        value={value?.join(", ")}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    const valueString = value?.join(", ");
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricSliceByColumnsIcon} />}
        label={`${label} - ${valueString}`}
      />
    );
  }

  return (
    <LabeledSelectWithLoadingOption
      label={label}
      allowClear
      showSearch
      mode={mode}
      value={isMultiple ? value : value?.[0]}
      options={sliceColumnOptions}
      enableSorting={true}
      onChange={(newSliceColumn) => {
        if (isMultiple) {
          onChange(newSliceColumn);
          return;
        }

        if (newSliceColumn === value?.[0]) {
          return;
        }

        onChange(newSliceColumn ? [newSliceColumn] : []);
      }}
      {...otherProperties}
    />
  );
}

export function SynchronizationDelayConfigItem(props) {
  let {
    label = "Evaluation delay",
    value,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricSynchronizationDelayIcon} />}
        label={label}
        value={getDisplayTimeFromSecond(value)}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    if (typeof value === "number") {
      label += ` - ${getDisplayTimeFromSecond(value)}`;
    }
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricSynchronizationDelayIcon} />}
        label={label}
      />
    );
  }

  return (
    <DurationInput
      label={label}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function PartitionsConfigItem(props) {
  const {
    label = "Partitions",
    value = [],
    onChange,
    columnList,
    partitionSampleData,
    getPartitionSampleData,
    sources,
    table,
    partitionTimezone = "",
    onPartitionTimezoneChange,
    isEdit = true,
    direction = "horizontal",
    hideColumnName = false,
    partitionOffsets,
    onPartitionOffsetsChange,
    isTableOptional = false,
    diffState = null,
  } = props;

  if (diffState && !isEdit) {
    if (!partitionTimezone && !partitionOffsets && value?.length === 0) {
      return null;
    }
    return (
      <>
        <ConfigSummaryCardRowDiff label={label} />
        {partitionTimezone !== undefined && (
          <ConfigSummaryCardRowDiff
            icon={<Icon component={MetricAggregationTimezoneIcon} />}
            value={`Timezone - ${partitionTimezone}`}
            diffState={diffState.partitionTimezone}
          />
        )}
        {partitionOffsets?.includePastSeconds !== undefined && (
          <ConfigSummaryCardRowDiff
            icon={<Icon component={MetricPartitionOffsetIcon} />}
            value={`Partition Offset Past - ${
              typeof partitionOffsets?.includePastSeconds === "number"
                ? getDisplayTimeFromSecond(partitionOffsets?.includePastSeconds)
                : "N/A"
            }`}
            diffState={extractDiffState(
              diffState,
              "partitionOffsets.includePastSeconds",
              { takeLast: true }
            )}
          />
        )}
        {partitionOffsets?.includeFutureSeconds !== undefined && (
          <ConfigSummaryCardRowDiff
            icon={<Icon component={MetricPartitionOffsetIcon} />}
            value={`Partition Offset Future - ${
              typeof partitionOffsets?.includeFutureSeconds === "number"
                ? getDisplayTimeFromSecond(partitionOffsets?.includeFutureSeconds)
                : "N/A"
            }`}
            diffState={extractDiffState(
              diffState,
              "partitionOffsets.includeFutureSeconds",
              { takeLast: true }
            )}
          />
        )}
        {value.map((currentPartition, index) => (
          <ConfigSummaryCardRowDiff
            key={index}
            icon={<Icon component={MetricPartitionColumnIcon} />}
            value={`${currentPartition.columnName} - ${currentPartition.format}`}
            diffState={diffState.partitions}
          />
        ))}
      </>
    );
  }

  if (!isEdit) {
    return (
      <div className="partitions-config-item-view-list-container">
        <div className="partitions-config-item-view-list-title-container">{label}</div>
        <div className="partitions-config-item-view-list-content-container">
          <div className="partitions-config-item-view-list-content-item-container">
            <ConfigSummaryCardRow
              icon={<Icon component={MetricAggregationTimezoneIcon} />}
              label={`Timezone - ${partitionTimezone}`}
            />
            <ConfigSummaryCardRow
              icon={<Icon component={MetricPartitionOffsetIcon} />}
              label={`Partition Offset Past - ${
                typeof partitionOffsets?.includePastSeconds === "number"
                  ? getDisplayTimeFromSecond(partitionOffsets?.includePastSeconds)
                  : "N/A"
              }`}
            />
            <ConfigSummaryCardRow
              icon={<Icon component={MetricPartitionOffsetIcon} />}
              label={`Partition Offset Future - ${
                typeof partitionOffsets?.includeFutureSeconds === "number"
                  ? getDisplayTimeFromSecond(partitionOffsets?.includeFutureSeconds)
                  : "N/A"
              }`}
            />
          </div>
          {value.map((currentPartition, index) => (
            <div
              key={`${currentPartition.columnName}${index}`}
              className="partitions-config-item-view-list-content-item-container"
            >
              <ConfigSummaryCardRow
                icon={<Icon component={MetricPartitionColumnIcon} />}
                label={`${currentPartition.columnName} - ${currentPartition.format}`}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <MetricPartitionsConfigItem
      label={label}
      value={value}
      onChange={onChange}
      columnList={columnList}
      currentKpiPartitionSampleData={partitionSampleData}
      getCurrentKpiPartitionSampleData={getPartitionSampleData}
      dataSourceUuid={sources[0] || ""}
      table={table}
      direction={direction}
      hideColumnName={hideColumnName}
      partitionTimezone={partitionTimezone}
      onPartitionTimezoneChange={onPartitionTimezoneChange}
      partitionOffsets={partitionOffsets}
      onPartitionOffsetsChange={onPartitionOffsetsChange}
      isTableOptional={isTableOptional}
    />
  );
}

export function WhereConditionsConfigItem(props) {
  const {
    isEdit = true,
    value: whereClause,
    diffState = null,
    label = "Where Condition",
  } = props;

  const getWhereConditionsRepr = () =>
    whereClause.whereConditions.map((clause) => {
      const { type, values } = clause.conditions[0];
      const valueRep = values
        .map((value) => {
          if (
            [
              ConformityConditionType.IN_COLUMN,
              ConformityConditionType.NOT_IN_COLUMN,
            ].includes(type)
          ) {
            const { table, columnName } = value;
            return `[${table?.schemaName} - ${table?.tableName} - ${columnName}]`;
          }
          return value;
        })
        .join(", ");
      return `${clause.column.columnName} - ${type} - ${valueRep}`;
    });

  if (diffState && !isEdit) {
    return (
      <>
        <ConfigSummaryCardRowDiff label={label} />
        <ConfigSummaryCardRowDiff
          value={whereClause.clauseLogic}
          diffState={extractDiffState(diffState, "clauseLogic", { takeLast: true })}
        />
        {getWhereConditionsRepr().map((condition, index) => (
          <ConfigSummaryCardRowDiff
            key={index}
            icon={<Icon component={MetricWhereClauseIcon} />}
            value={`${index + 1}: ${condition}`}
            diffState={extractDiffState(diffState, "whereConditions", {
              takeLast: true,
            })}
          />
        ))}
      </>
    );
  }

  if (!isEdit) {
    return getWhereConditionsRepr().map((condition, index) => (
      <ConfigSummaryCardRow
        key={index}
        icon={<Icon component={MetricWhereClauseIcon} />}
        label={condition}
      />
    ));
  }

  return <MetricWhereConditionsSetting {...props} />;
}

export function CustomSqlConfigItem(props) {
  const {
    value,
    onChange,
    isEdit = true,
    validating,
    disabled = false,
    className = null,
    diffState = null,
    description = (
      <>
        Lightup queries are different from traditional queries in that they need a start
        and end time to be specified in order for Lightup to be able to run your queries
        in an incremental manner. For full documentation,&nbsp;
        <a
          href="https://docs.lightup.ai/docs/sql-metrics"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
        .
      </>
    ),
  } = props;

  const minTextAreaHeight = 200;
  const [textareaHeight, setTextareaHeight] = useState(minTextAreaHeight);

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricCustomSqlIconComponent} />}
        label="SQL"
        value="- SQL Expression -"
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricCustomSqlIconComponent} />}
        label="SQL"
      />
    );
  }

  return (
    <div className={classesName("metric-custom-sql-config-item-container", className)}>
      <div style={{ marginBottom: "8px" }}>{description}</div>
      <div className="metric-custom-sql-query">
        <Input.TextArea
          disabled={disabled || validating}
          style={{ minHeight: minTextAreaHeight }}
          value={value}
          onChange={(e) => {
            if (e.target.value === value) {
              return;
            }

            onChange(e.target.value);
          }}
          placeholder={"SQL"}
          autoSize={false}
          onResize={({ height }) => setTextareaHeight(height)}
        />
        {validating && (
          <div
            style={{
              height: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                top: -(textareaHeight / 2 + 20),
              }}
            >
              <Spinner size="large" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function FailingRecordsSqlConfigItem(props) {
  const {
    value,
    onChange,
    isEdit = true,
    validating = false,
    disabled = false,
    diffState = null,
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricCustomSqlIconComponent} />}
        label="Failing records SQL"
        value="- SQL Expression -"
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricCustomSqlIconComponent} />}
        label="Failing records SQL"
      />
    );
  }

  return (
    <Input.TextArea
      className="metric-custom-sql-failing-records-query"
      disabled={disabled || validating}
      style={{ minHeight: 200 }}
      value={value}
      onChange={(e) => {
        if (e.target.value === value) {
          return;
        }

        onChange(e.target.value);
      }}
      placeholder={"SQL"}
      autoSize={false}
    />
  );
}

export function MissingValueFillingConfigItem(props) {
  const {
    value,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;
  const label = "Missing value filling";

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricSynchronizationDelayIcon} />}
        label={label}
        value={value ?? "No missing value filling"}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricSynchronizationDelayIcon} />}
        label={`${label} - ${value ?? "No missing value filling"}`}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      staticLabel
      value={value}
      options={missingValueFillingOptions}
      onChange={(newMissingValueFilling) => {
        if (newMissingValueFilling === value) {
          return;
        }

        onChange(newMissingValueFilling);
      }}
      {...otherProperties}
    />
  );
}

export function MetricComparesConfigItem(props) {
  const {
    value,
    onChange,
    isEdit = true,
    kpiList = [],
    disabled = false,
    diffState = null,
  } = props;

  const metricUuidMapper = useMemo(() => {
    const metricUuidMapper = {};
    kpiList.forEach((currentMetric) => {
      metricUuidMapper[currentMetric.metadata.uuid] = currentMetric;
    });

    return metricUuidMapper;
  }, [kpiList]);

  const metricCompareValues = value.map(
    ({ sourceMetricUuid, targetMetricUuid }, index) => {
      const sourceMetric = metricUuidMapper[sourceMetricUuid] || null;
      const targetMetric = metricUuidMapper[targetMetricUuid] || null;

      const sourceMetricName = sourceMetric ? sourceMetric.metadata.name : "N/A";
      const targetMetricName = targetMetric ? targetMetric.metadata.name : "N/A";

      const sourceValueColumn =
        sourceMetric &&
        sourceMetric.config.valueColumns &&
        sourceMetric.config.valueColumns.length > 0
          ? sourceMetric.config.valueColumns
              .map((currentValueColumn) => currentValueColumn.columnName)
              .join(",")
          : "N/A";

      const targetValueColumn =
        targetMetric &&
        targetMetric.config.valueColumns &&
        targetMetric.config.valueColumns.length > 0
          ? targetMetric.config.valueColumns
              .map((currentValueColumn) => currentValueColumn.columnName)
              .join(",")
          : "N/A";

      return {
        sourceMetricName,
        sourceValueColumn,
        targetMetricName,
        targetValueColumn,
      };
    }
  );

  if (diffState && !isEdit) {
    return (
      <>
        <ConfigSummaryCardRowDiff label="Metrics to compare" />
        {metricCompareValues.map(
          (
            {
              sourceMetricName,
              sourceValueColumn,
              targetMetricName,
              targetValueColumn,
            },
            index
          ) => (
            <ConfigSummaryCardRowDiff
              icon={<Icon component={MetricCompareConfigItemIcon} />}
              value={`${sourceMetricName} (${sourceValueColumn}) - ${targetMetricName} (${targetValueColumn})`}
              key={index}
              diffState={diffState}
            />
          )
        )}
      </>
    );
  }

  const metricCompareContent = metricCompareValues.map(
    (
      { sourceMetricName, sourceValueColumn, targetMetricName, targetValueColumn },
      index
    ) => {
      if (!isEdit) {
        return (
          <div key={index}>
            {`${sourceMetricName} (${sourceValueColumn}) - ${targetMetricName} (${targetValueColumn})`}
          </div>
        );
      }

      return (
        <FieldRow key={index}>
          <LabeledInput
            label="source"
            staticLabel
            value={`${sourceMetricName} (${sourceValueColumn})`}
            disabled={disabled}
          />
          <LabeledInput
            label="target"
            staticLabel
            value={`${targetMetricName} (${targetValueColumn})`}
            disabled={disabled}
          />
          {!disabled && (
            <FieldRowButton
              onClick={() => {
                value.splice(index, 1);
                onChange([...value]);
              }}
              icon={<DeleteOutlined />}
            />
          )}
        </FieldRow>
      );
    }
  );

  if (isEdit) {
    return metricCompareContent;
  }

  return (
    <ConfigSummaryCardRow
      icon={<Icon component={MetricCompareConfigItemIcon} />}
      label={metricCompareContent}
    />
  );
}

export function ColumnConditionsConfigItem(props) {
  const {
    isEdit = true,
    value = [],
    isAddDisabled = false,
    isMultipleValueColumns = false,
    disabled = false,
    diffState = null,
    ...otherProps
  } = props;

  if (diffState && !isEdit) {
    return (
      <>
        <ConfigSummaryCardRowDiff label="Condition" />
        {value.map(({ type, values }, index) => {
          const displayLabelArray = [
            `${getConformityConditionTypeDisplayName(type) || type}`,
          ];
          for (let currentValue of values) {
            if (
              [
                ConformityConditionType.IN_COLUMN,
                ConformityConditionType.NOT_IN_COLUMN,
              ].includes(type)
            ) {
              const { table = { tableName: "", schemaName: "" }, columnName = "" } =
                currentValue;

              const { tableName = "", schemaName = "" } = table;
              displayLabelArray.push(tableName);
              displayLabelArray.push(schemaName);
              displayLabelArray.push(columnName);
            } else {
              displayLabelArray.push(currentValue);
            }
          }

          return (
            <ConfigSummaryCardRowDiff
              key={index}
              icon={<Icon component={MetricConditionsConfigItemIcon} />}
              value={`${displayLabelArray.join(" - ")}`}
              diffState={diffState}
            />
          );
        })}
      </>
    );
  }

  if (!isEdit) {
    return value.map(({ type, values }, index) => {
      const displayLabelArray = [
        `${getConformityConditionTypeDisplayName(type) || type}`,
      ];
      for (let currentValue of values) {
        if (
          [
            ConformityConditionType.IN_COLUMN,
            ConformityConditionType.NOT_IN_COLUMN,
          ].includes(type)
        ) {
          const { table = { tableName: "", schemaName: "" }, columnName = "" } =
            currentValue;

          const { tableName = "", schemaName = "" } = table;
          displayLabelArray.push(tableName);
          displayLabelArray.push(schemaName);
          displayLabelArray.push(columnName);
        } else {
          displayLabelArray.push(currentValue);
        }
      }

      return (
        <ConfigSummaryCardRow
          key={index}
          icon={<Icon component={MetricConditionsConfigItemIcon} />}
          label={`${displayLabelArray.join(" - ")}`}
        />
      );
    });
  }

  return (
    <MetricConditionsSetting
      value={value}
      isAddDisabled={isAddDisabled}
      isMultipleValueColumns={isMultipleValueColumns}
      disabled={disabled}
      {...otherProps}
    />
  );
}

export function PercentileFractionConfigItem(props) {
  const { value = 0, onChange, ...otherProperties } = props;
  const label = "Fraction";
  return (
    <LabeledInputNumber
      staticLabel
      value={value}
      label={label}
      min="0"
      max="1"
      controls={false}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function QueryScopeConfigItem(props) {
  const {
    label = "Query scope",
    value,
    onChange,
    isEdit = true,
    options = [],
    diffState = null,
    ...otherProperties
  } = props;

  const labelValue = getOptionByValue(options, value)?.label;

  if (!isEdit && !labelValue) {
    // avoid render undefined scope in non edit mode
    return null;
  }

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricQueryScopeIcon} />}
        label={label}
        value={labelValue}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricQueryScopeIcon} />}
        label={`${label} - ${labelValue}`}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      options={options}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function DataCollectionScheduleConfigItem(props) {
  const {
    label = "Data collection schedule",
    value,
    onChange,
    isEdit = true,
    isBlobStorage = false,
    isRowByRow = false,
    diffState = null,
    ...otherProperties
  } = props;
  const collectionModeType = value?.type ?? "";
  const options = getDataCollectionOptions({ isBlobStorage, isRowByRow });

  if (diffState?.type && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricDataCollectionScheduleIcon} />}
        label={label}
        value={getOptionByValue(options, collectionModeType)?.label}
        diffState={diffState?.type}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricDataCollectionScheduleIcon} />}
        label={`${label} - ${getOptionByValue(options, collectionModeType)?.label}`}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      options={options}
      value={collectionModeType}
      onChange={(newCollectionModeType) => {
        if (newCollectionModeType === value.type) {
          return;
        }

        const newCollectionMode = { type: newCollectionModeType };
        if (newCollectionModeType === CollectionModeType.CUSTOM_SCHEDULED) {
          Object.assign(newCollectionMode, {
            timezone: "UTC",
            crontabExpression: "0 */12 * * *",
          });
        }
        onChange(newCollectionMode);
      }}
      {...otherProperties}
    />
  );
}

export function DataCollectionWindowConfigItem(props) {
  const {
    label = "Data collection window",
    value,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricDataCollectionWindowIcon} />}
        label={label}
        value={getOptionByValue(collectionWindowOptions, value)?.label}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricDataCollectionWindowIcon} />}
        label={`${label} - ${getOptionByValue(collectionWindowOptions, value)?.label}`}
      />
    );
  }

  return (
    <LabeledSelect
      label={label}
      showSearch
      options={collectionWindowOptions}
      value={value}
      onChange={onChange}
      {...otherProperties}
    />
  );
}

export function BackfillDurationConfigItem(props) {
  let {
    label = "Backfill duration",
    value,
    onChange,
    isEdit = true,
    diffState = null,
    ...otherProperties
  } = props;

  if (diffState && !isEdit) {
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricBackfillIcon} />}
        label={label}
        value={typeof value === "number" ? getDisplayTimeFromSecond(value) : ""}
        diffState={diffState}
      />
    );
  }

  if (!isEdit) {
    if (typeof value === "number") {
      label += ` - ${getDisplayTimeFromSecond(value)}`;
    }
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricBackfillIcon} />}
        label={label}
      />
    );
  }

  return (
    <DurationInput
      label={label}
      value={value}
      onChange={(newValue) => {
        if (newValue === value) {
          return;
        }

        onChange(newValue);
      }}
      {...otherProperties}
    />
  );
}

export function CustomScheduleCollectionConfigItem(props) {
  const { value, onChange, workspaceUuid, isEdit = true, diffState = null } = props;

  if (value?.type !== CollectionModeType.CUSTOM_SCHEDULED) {
    return null;
  }

  if (diffState && !isEdit) {
    const diffStateSingle =
      typeof diffState === "string" ||
      Object.values(diffState).reduce((acc, state) => {
        if (acc === null) {
          return state;
        }
        return acc !== state ? DIFF_STATES.UNKNOWN : acc;
      }, null);
    return (
      <ConfigSummaryCardRowDiff
        icon={<Icon component={MetricCronInputIcon} />}
        label="Cron Input"
        value={`[ ${value.crontabExpression} ] ${value.timezone}`}
        diffState={diffStateSingle}
      />
    );
  }

  if (!isEdit) {
    return (
      <ConfigSummaryCardRow
        icon={<Icon component={MetricCronInputIcon} />}
        label={`Cron Input - [ ${value.crontabExpression} ] ${value.timezone}`}
      />
    );
  }

  return (
    <div className="configure-metric-full-row">
      <FieldRowDivider />
      <CustomScheduleCollectionConfig
        workspaceUuid={workspaceUuid}
        value={value}
        onChange={onChange}
      />
      <FieldRowDivider />
    </div>
  );
}

export function ColumnsToCompareConfigItem(props) {
  const { configData, fieldName, diffState = null, label = null } = props;

  const columnValues = configData.config.sourceTable[fieldName].map((srcCol, index) => {
    const targetCol =
      configData.config?.targetTable[fieldName].length > index
        ? configData.config.targetTable[fieldName][index]
        : "N/A";
    return `${srcCol} - ${targetCol}`;
  });

  if (diffState) {
    return (
      <>
        <ConfigSummaryCardRowDiff label={label} />
        {columnValues.map((columnLabel) => (
          <ConfigSummaryCardRowDiff
            key={columnLabel}
            icon={<Icon component={MetricCompareConfigItemIcon} />}
            value={columnLabel}
            diffState={diffState}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {columnValues.map((columnLabel) => (
        <ConfigSummaryCardRow
          key={columnLabel}
          icon={<Icon component={MetricCompareConfigItemIcon} />}
          label={columnLabel}
        />
      ))}
    </>
  );
}

// Vertically spaced out ConfigCard's.
export const DataAssetCards = DivWithClassName(
  "metric-config-data-asset-cards",
  "DataAssetCards"
);

// Flex row layout that gives equal space to each child.
export const FieldRow = DivWithClassName("metric-config-field-row", "FieldRow");

export const FieldColumn = DivWithClassName(
  "metric-config-field-column",
  "FieldColumn"
);

export const FieldSection = DivWithClassName(
  "metric-config-field-section",
  "FieldSection"
);

export const VerticalSplit = DivWithClassName(
  "metric-config-vertical-split",
  "VerticalSplit"
);

export const SubHeading = DivWithClassName("metric-config-sub-heading", "SubHeading");

export function FieldRowButton(props) {
  const { icon, onClick } = props;

  return (
    <div className="metric-config-field-row-delete-button">
      <button onClick={onClick}>{icon}</button>
    </div>
  );
}

export const FieldRowDivider = DivWithClassName("metric-config-divider", "Divider");

export const BorderedFields = DivWithClassName(
  "metric-config-bordered",
  "BorderedFields"
);
