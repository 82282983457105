import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ConfirmationDialog from "../../components/confirmation-dialog/ng-index";
import { TextWithIcon } from "../../components/button/ng-button";
import {
  ProfilerActiveIcon,
  ProfilerInactiveIcon,
} from "../../components/icons/toggle-icon/";
import ProfilerEnableView from "./profiler-enable-view";
import {
  AllEnableIcon,
  DataProfilesDisabledIcon,
  DeepAutoMetricsDiabledIcon,
  MetadataMetricsDisabledIcon,
} from "./icons";
import "./profiler-table-enable-view.scss";
import { EVENT, PAGE, trackEvent } from "../../utils/telemetry";
import {
  isAllDeepMetricsEnabled,
  toggleAllDeepMetrics,
  toggleAllMetadataMetrics,
} from "../../utils/table";
import queryString from "query-string";
import { dataSourceSupportsDataProfiling } from "../../utils/datasource";

function EnableAllButtonContent() {
  return <TextWithIcon icon={<ProfilerActiveIcon />}>Enable all</TextWithIcon>;
}

function DisableAllButtonContent() {
  return <TextWithIcon icon={<ProfilerInactiveIcon />}>Disable all</TextWithIcon>;
}

export default function ProfilerTableEnableView(props) {
  const location = useLocation();

  const [confirmationDialogContext, setConfirmationDialogContext] = useState(null);
  const {
    tables = [],
    dataSource,
    updateTableList,
    loading = false,
    canModifySettings,
  } = props;
  const [isMetaMetricsUpdateInProgress, setIsMetaMetricsUpdateInProgress] =
    useState(false);
  const [isDataProfilerUpdateInProgress, setIsDataProfilerUpdateInProgress] =
    useState(false);
  const [isDeepMetricsUpdateInProgress, setIsDeepMetricsUpdateInProgress] =
    useState(false);

  const profilerEnabledTables = [];
  const disabledMetadataMetricsTables = [];
  const disabledDataProfilerTables = [];
  const disabledDeepMetricsTables = [];
  tables.forEach((currentTable) => {
    if (!currentTable.status.allMetadataMetricsEnabled) {
      disabledMetadataMetricsTables.push(currentTable);
    }

    if (!currentTable.profilerConfig?.dataProfiler?.enabled) {
      disabledDataProfilerTables.push(currentTable);
    }

    if (currentTable.profilerConfig?.enabled) {
      profilerEnabledTables.push(currentTable);
      if (!isAllDeepMetricsEnabled(currentTable, dataSource)) {
        disabledDeepMetricsTables.push(currentTable);
      }
    }
  });

  function onToggleMetadata(tableList, enabled) {
    setIsMetaMetricsUpdateInProgress(true);
    updateTableList(
      tableList.map((currentTable) =>
        toggleAllMetadataMetrics(currentTable, dataSource, enabled)
      )
    ).finally(() => setIsMetaMetricsUpdateInProgress(false));
  }

  function onToggleDeepMetrics(tableList, enabled) {
    setIsDeepMetricsUpdateInProgress(true);
    updateTableList(
      tableList.map((currentTable) =>
        toggleAllDeepMetrics(currentTable, dataSource, enabled)
      )
    ).finally(() => setIsDeepMetricsUpdateInProgress(false));
  }

  function onEnableDataProfile(tableList) {
    setIsDataProfilerUpdateInProgress(true);
    updateTableList(
      tableList.map((currentTable) => {
        return {
          ...currentTable,
          profilerConfig: {
            ...currentTable.profilerConfig,
            dataProfiler: {
              ...(currentTable.profilerConfig.dataProfiler || {}),
              enabled: true,
            },
          },
        };
      })
    ).finally(() => setIsDataProfilerUpdateInProgress(false));
  }

  function onDisableDataProfile(tableList) {
    setIsDataProfilerUpdateInProgress(true);
    updateTableList(
      tableList.map((currentTable) => {
        return {
          ...currentTable,
          profilerConfig: {
            ...currentTable.profilerConfig,
            dataProfiler: {
              ...(currentTable.profilerConfig.dataProfiler || {}),
              enabled: false,
            },
          },
        };
      }),
      true // make optimistic update
    ).finally(() => setIsDataProfilerUpdateInProgress(false));
  }

  const allMetadataEnabled =
    disabledMetadataMetricsTables.length === 0 && tables.length > 0;
  const allDataProfilerEnabled =
    disabledDataProfilerTables.length === 0 && tables.length > 0;
  const allDeepMetricsEnabled =
    disabledDeepMetricsTables.length === 0 && tables.length > 0;

  const metadataDefaultDescription = allMetadataEnabled
    ? "All metadata metrics enabled"
    : "Enable all metadata metrics";

  const profilerDefaultDescription = allDataProfilerEnabled
    ? "All data profiles enabled"
    : "Enable all data profiles";

  const deepMetricsDefaultDescription = allDeepMetricsEnabled
    ? "Deep auto metrics enabled on all configured tables"
    : "Enable deep auto metrics on configured tables";

  const metadataViewDescription = !canModifySettings
    ? "All metadata metrics"
    : metadataDefaultDescription;

  const profilerViewDescription = !canModifySettings
    ? "All data profiles"
    : profilerDefaultDescription;

  const deepMetricsViewDescription = !canModifySettings
    ? "All deep auto metrics"
    : deepMetricsDefaultDescription;

  const queryParams = queryString.parse(location.search);
  const dataSourceUuid = queryParams?.dataSourceUuid || "";

  return (
    <div className="profiler-table-enable-view-container">
      {dataSourceSupportsDataProfiling(dataSource) && (
        <ProfilerEnableView
          gridOnly={true}
          icon={
            allDataProfilerEnabled ? <AllEnableIcon /> : <DataProfilesDisabledIcon />
          }
          loading={loading}
          description={profilerViewDescription}
          extraInfo={`${tables.length - disabledDataProfilerTables.length} of ${
            tables.length
          } Tables Enabled`}
          buttonEnabled={
            tables.length > 0 && !isDataProfilerUpdateInProgress && canModifySettings
          }
          isInProgress={isDataProfilerUpdateInProgress}
          buttonContent={
            canModifySettings ? (
              allDataProfilerEnabled ? (
                <DisableAllButtonContent />
              ) : (
                <EnableAllButtonContent />
              )
            ) : null
          }
          onClick={() => {
            if (disabledDataProfilerTables.length > 0) {
              trackEvent(EVENT.ENABLE_ALL_DATA_PROFILES, {
                page: PAGE.EXPLORER_DATASOURCE,
                workspace_id: dataSourceUuid,
              });
              onEnableDataProfile(disabledDataProfilerTables);
            } else {
              setConfirmationDialogContext({
                onOKClicked: () => {
                  trackEvent(EVENT.DISABLE_ALL_DATA_PROFILES, {
                    page: PAGE.EXPLORER_DATASOURCE,
                    workspace_id: dataSourceUuid,
                  });
                  onDisableDataProfile(tables);
                },
                msg: `You are going to disable data profiler across ${
                  tables.length
                } table${tables.length >= 1 ? "s" : ""}.`,
              });
            }
          }}
        />
      )}
      <ProfilerEnableView
        gridOnly={true}
        icon={allMetadataEnabled ? <AllEnableIcon /> : <MetadataMetricsDisabledIcon />}
        loading={loading}
        description={metadataViewDescription}
        extraInfo={`${tables.length - disabledMetadataMetricsTables.length} of ${
          tables.length
        } Tables Enabled`}
        buttonEnabled={
          tables.length > 0 && !isMetaMetricsUpdateInProgress && canModifySettings
        }
        isInProgress={isMetaMetricsUpdateInProgress}
        buttonContent={
          canModifySettings ? (
            allMetadataEnabled ? (
              <DisableAllButtonContent />
            ) : (
              <EnableAllButtonContent />
            )
          ) : null
        }
        onClick={() => {
          if (disabledMetadataMetricsTables.length > 0) {
            trackEvent(EVENT.ENABLE_ALL_METADATA_METRICS, {
              page: PAGE.EXPLORER_DATASOURCE,
              workspace_id: dataSourceUuid,
            });
            onToggleMetadata(disabledMetadataMetricsTables, true);
          } else {
            setConfirmationDialogContext({
              onOKClicked: () => {
                trackEvent(EVENT.DISABLE_ALL_METADATA_METRICS, {
                  page: PAGE.EXPLORER_DATASOURCE,
                  workspace_id: dataSourceUuid,
                });
                onToggleMetadata(tables, false);
              },
              msg: `You are going to disable metadata metrics across ${
                tables.length
              } table${tables.length >= 1 ? "s" : ""}.`,
            });
          }
        }}
      />
      {profilerEnabledTables.length > 0 && (
        <ProfilerEnableView
          gridOnly={true}
          icon={
            allDeepMetricsEnabled ? <AllEnableIcon /> : <DeepAutoMetricsDiabledIcon />
          }
          loading={loading}
          description={deepMetricsViewDescription}
          extraInfo={`${
            profilerEnabledTables.length - disabledDeepMetricsTables.length
          } of ${profilerEnabledTables.length} Tables Enabled`}
          buttonEnabled={
            profilerEnabledTables.length > 0 &&
            !isDeepMetricsUpdateInProgress &&
            canModifySettings
          }
          isInProgress={isDeepMetricsUpdateInProgress}
          buttonContent={
            canModifySettings ? (
              allDeepMetricsEnabled ? (
                <DisableAllButtonContent />
              ) : (
                <EnableAllButtonContent />
              )
            ) : null
          }
          onClick={() => {
            if (disabledDeepMetricsTables.length > 0) {
              trackEvent(EVENT.ENABLE_ALL_DEEP_AUTOMETRICS, {
                page: PAGE.EXPLORER_DATASOURCE,
                workspace_id: dataSourceUuid,
              });
              onToggleDeepMetrics(disabledDeepMetricsTables, true);
            } else {
              setConfirmationDialogContext({
                onOKClicked: () => {
                  trackEvent(EVENT.DISABLE_ALL_DATA_PROFILES, {
                    page: PAGE.EXPLORER_DATASOURCE,
                    workspace_id: dataSourceUuid,
                  });
                  onToggleDeepMetrics(profilerEnabledTables, false);
                },
                msg: `You are going to disable deep auto metrics across ${
                  profilerEnabledTables.length
                } table${profilerEnabledTables.length >= 1 ? "s" : ""}.`,
              });
            }
          }}
        />
      )}
      {confirmationDialogContext && (
        <ConfirmationDialog
          modalIsOpen={!!confirmationDialogContext}
          setModalIsOpen={(isOpen) => !isOpen && setConfirmationDialogContext(null)}
          okClicked={confirmationDialogContext.onOKClicked}
          confirmationMsg={confirmationDialogContext.msg}
          okText={"Confirm"}
        />
      )}
    </div>
  );
}
