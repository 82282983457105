import React, { useState } from "react";
import IntegrationDialogFooter from "../integration-dialog-footer";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../components/modal-dialog/modal-field-group";
import {
  LabeledInput,
  LabeledMultiSelect,
  LabeledSelect,
} from "../../../components/labeled-control/labeled-control";
import {
  opsGeniePriorityOptions,
  opsGenieResponderTypeOptions,
} from "../../../utils/options";
import { getAppDomainName } from "../../../utils/general";
import TakeoverPanel from "../../../components/takeover/takeover-panel";

import { AlertingChannelType } from "../../../utils/alerting";

import NgButton, { TextWithIcon } from "../../../components/button/ng-button";
import { PlusOutlined } from "@ant-design/icons";
import DeleteIcon from "../../../components/icons/delete-icon";
import { classesName } from "../../../utils/css";

import "./opsgenie-integration-basic-dialog.scss";

export function canAttemptAuthentication(value) {
  const { host, username, password } = value;
  return host && username && password;
}

const RespondersForm = (props) => {
  const RESPONDERS_LIMIT = 3;
  const { value, onChange } = props;
  const { responders } = value;

  function handleChangeResponder(index, key, newValue) {
    const sanitizedValue =
      key === "names" ? newValue.filter((value) => value.trim() !== "") : newValue;

    const newRespondersMap = responders.map((responder, i) => {
      if (i === index) {
        return { ...responder, [key]: sanitizedValue };
      }
      return responder;
    });

    onChange({ ...value, responders: newRespondersMap });
  }

  const handleRemoveResponder = (index) => {
    onChange({
      ...value,
      responders: responders.filter((_, i) => i !== index),
    });
  };

  const onAddResponder = () => {
    onChange({
      ...value,
      responders: [
        ...value.responders,
        {
          type: "",
          name: "",
        },
      ],
    });
  };

  const canAddResponder = responders.length < RESPONDERS_LIMIT;
  const hasMultipleResponders = responders?.length > 1;

  return (
    <div className="opsgenie-integration-basic-dialog-responders">
      <span className="opsgenie-integration-basic-dialog-responders-label">
        Responders (optional)
      </span>
      {/* We do want to map the length, to avoid always re-render the form fields when typing */}
      {Array.from({ length: responders.length }).map((responder, index) => (
        <div
          key={`${responder?.type}-${responder?.name}-${index}`}
          className={classesName(
            "opsgenie-integration-basic-dialog-responders-wrapper",
            hasMultipleResponders &&
              "opsgenie-integration-basic-dialog-responders-wrapper-multiples"
          )}
        >
          <div className="opsgenie-integration-basic-dialog-responders-wrapper-inputs">
            <LabeledSelect
              label="Responder Type"
              value={responders[index].type}
              options={opsGenieResponderTypeOptions}
              onChange={(newValue) => handleChangeResponder(index, "type", newValue)}
              staticLabel
            />

            <LabeledMultiSelect
              label="Responder Name"
              value={responders[index].names}
              onChange={(newTags = []) =>
                handleChangeResponder(index, "names", newTags)
              }
              staticLabel
            />
          </div>

          {hasMultipleResponders && (
            <button
              onClick={() => handleRemoveResponder(index)}
              className="opsgenie-integration-basic-dialog-responders-wrapper-delete-button"
            >
              <DeleteIcon />
            </button>
          )}
        </div>
      ))}

      <div className="opsgenie-integration-basic-dialog-responders-footer">
        {canAddResponder && (
          <>
            <span className="opsgenie-integration-basic-dialog-responders-footer-title">
              ADD ANOTHER RESPONDER
            </span>

            <NgButton outline onClick={onAddResponder}>
              <TextWithIcon icon={<PlusOutlined />}>Add</TextWithIcon>
            </NgButton>
          </>
        )}
      </div>
    </div>
  );
};

function OpsGenieIntegrationBasicDialogBodyComponent(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { value, onChange, onPreviewClick, enablePreview } = props;
  const { title, tags, apiKey, priority, instanceUrl } = value;

  async function onPreview() {
    try {
      setIsLoading(true);
      await onPreviewClick();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="opsgenie-integration-basic-dialog">
      <ModalFieldGroup>
        <ModalFieldRows>
          <LabeledInput
            label="Integration Name"
            value={title}
            onChange={(e) => onChange({ ...value, title: e.target.value })}
            staticLabel
          />

          <LabeledInput
            type="password"
            label="API Key"
            value={apiKey}
            onChange={(e) => onChange({ ...value, apiKey: e.target.value })}
            staticLabel
          />

          <LabeledSelect
            label="Priority"
            value={priority}
            options={opsGeniePriorityOptions}
            onChange={(newPriority) => onChange({ ...value, priority: newPriority })}
            staticLabel
          />

          <RespondersForm value={value} onChange={onChange} />

          <LabeledInput
            label="Host URL (optional)"
            value={instanceUrl}
            onChange={(e) => onChange({ ...value, instanceUrl: e.target.value })}
            staticLabel
          />

          <LabeledMultiSelect
            label="Tags (optional)"
            value={tags}
            onChange={(newTags = []) => onChange({ ...value, tags: newTags })}
            staticLabel
          />

          <NgButton
            link
            style={{ padding: 0, fontWeight: 500, fontSize: 12, letterSpacing: 1.2 }}
            onClick={onPreview}
            loading={isLoading}
            disabled={!enablePreview || isLoading}
          >
            SEND TEST MESSAGE
          </NgButton>
        </ModalFieldRows>
      </ModalFieldGroup>
    </div>
  );
}

function OpsGenieIntegrationBasicDialog(props) {
  const {
    value,
    OKText,
    onChange,
    onPreview,
    setIsOpen,
    enableSave,
    dialogTitle = "",
    onOkClicked,
    enablePreview,
  } = props;

  function getNewOpsGenieIntegration(integration) {
    return {
      ...integration,
      type: AlertingChannelType.OPSGENIE,
      domainName: getAppDomainName(),
      responders: integration.responders.filter(
        (responder) => responder.type !== "" || responder.name !== ""
      ),
    };
  }

  function closePanel() {
    setIsOpen(false);
  }

  function onOkClick() {
    onOkClicked(getNewOpsGenieIntegration(value));
    closePanel();
  }

  async function onPreviewClick() {
    await onPreview(getNewOpsGenieIntegration(value));
  }

  const cornerControls = (
    <IntegrationDialogFooter
      OkText={OKText}
      onOkClick={onOkClick}
      enableSave={enableSave}
      previewText={null}
      onCancelClick={closePanel}
    />
  );

  return (
    <TakeoverPanel title={dialogTitle} cornerControls={cornerControls}>
      <OpsGenieIntegrationBasicDialogBodyComponent
        value={value}
        onChange={onChange}
        enablePreview={enablePreview}
        onPreviewClick={onPreviewClick}
      />
    </TakeoverPanel>
  );
}

export default OpsGenieIntegrationBasicDialog;
