import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Button, Table } from "antd";
import { WarningOutlined, WarningTwoTone } from "@ant-design/icons";
import FullCompareSampleDataTakeOverview from "./full-compare-sample-data-takeover-view";
import { getDataSourceList } from "../../../actions/datasource/data-source-action";
import {
  openWorkspaceTakeover,
  closeTakeover,
} from "../../../actions/takeover/takeover-action";
import {
  INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME,
  INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME,
} from "../../../utils/constants";
import { getDisplayFloatStr, getRangeDisplayStr } from "../../../utils/general";
import { NextGenPalette } from "../../../utils/color";
import { TakeoverWidth } from "../../../utils/enums";
import { getDisplayTableName, getFullTableName } from "../../../utils/datasource";

import "./compare-table-view.scss";

function FullCompareTableView(props) {
  const {
    dataSource,
    dataSourceList,
    metric,
    timestamp,
    value,
    isMonitored = false,
    isPreview = false,
    isProfiler = false,
    incidents = [],
    showStatsData = false,
    getDataSourceList,
    match: {
      params: { workspaceUuid },
    },
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      getDataSourceList(workspaceUuid, { isRefresh: true });
      setIsLoaded(true);
    }
  }, [isLoaded, getDataSourceList, workspaceUuid]);

  if (!value || dataSourceList.loading) {
    return null;
  }

  let sourceTableName = "";
  if (metric.config.sourceTable.table) {
    sourceTableName = getDisplayTableName(
      getFullTableName(metric.config.sourceTable.table)
    );
  }

  let targetTableName = "";
  if (metric.config.targetTable.table) {
    targetTableName = getDisplayTableName(
      getFullTableName(metric.config.targetTable.table)
    );
  }

  let srcDataSource = null;
  if (metric.config.sourceTable.sourceUuid) {
    srcDataSource = dataSourceList.data.find(
      (currentDataSource) =>
        currentDataSource.metadata.uuid === metric.config.sourceTable.sourceUuid
    );
  }

  const sourceColumnName = metric.config.sourceTable.valueColumns?.join(", ");
  const targetColumnName = metric.config.targetTable.valueColumns?.join(", ");
  const sourceAttributeColumns =
    metric.config.sourceTable.attributeColumns &&
    metric.config.sourceTable.attributeColumns.length > 0
      ? metric.config.sourceTable.attributeColumns
      : [];
  const targetAttributeColumns =
    metric.config.targetTable.attributeColumns &&
    metric.config.targetTable.attributeColumns.length > 0
      ? metric.config.targetTable.attributeColumns
      : [];

  const keyMatching = getDisplayFloatStr(value.matchingPerc, true);
  const keyMatchingRangeStr = showStatsData
    ? getRangeDisplayStr(
        value.lowerBoundMatchingPercValue,
        value.upperBoundMatchingPercValue
      )
    : "";

  const attributeMatchingCounts =
    value && value.attributeMatchingCounts && value.attributeMatchingCounts.length > 0
      ? value.attributeMatchingCounts
      : [];
  const attributeMatching =
    value && value.attributeMatchingPercs && value.attributeMatchingPercs.length > 0
      ? value.attributeMatchingPercs
      : [];
  const lowerBoundAttributeMatchingPercValue =
    value &&
    value.lowerBoundAttributeMatchingPercValue &&
    value.lowerBoundAttributeMatchingPercValue.length > 0
      ? value.lowerBoundAttributeMatchingPercValue
      : [];
  const upperBoundAttributeMatchingPercValue =
    value &&
    value.upperBoundAttributeMatchingPercValue &&
    value.upperBoundAttributeMatchingPercValue.length > 0
      ? value.upperBoundAttributeMatchingPercValue
      : [];

  const incidentKeyIndexList = [];
  const incidentAttrIndexList = [];
  for (let currentIncident of incidents) {
    let incidentSlices = [];
    try {
      incidentSlices = JSON.parse(currentIncident.reason)["slice_values"];
    } catch (err) {
      console.log(
        `Fail to parse incident slice with ${JSON.stringify(currentIncident)}`
      );
      continue;
    }

    for (let currentIncidentSlice of incidentSlices) {
      if (
        currentIncidentSlice.hasOwnProperty(INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME)
      ) {
        incidentKeyIndexList.push(
          currentIncidentSlice[INCIDENT_AGGREGATION_KEY_COL_INDEX_KEY_NAME]
        );
      } else if (
        currentIncidentSlice.hasOwnProperty(
          INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME
        )
      ) {
        incidentAttrIndexList.push(
          currentIncidentSlice[INCIDENT_AGGREGATION_ATTR_COL_INDEX_KEY_NAME]
        );
      }
    }
  }

  const columns = [
    {
      title: "",
      dataIndex: "title",
      width: 165,
      className: "lightup-focused-column",
      onCell: (data, index) => {
        if (index === 0) {
          return { rowSpan: 1 };
        }

        if (index === 1) {
          return { rowSpan: sourceAttributeColumns.length };
        }

        // These remain are merged into above cell
        if (index > 1) {
          return { rowSpan: 0 };
        }
      },
    },
    {
      title: sourceTableName,
      dataIndex: "source",
      ellipsis: true,
    },
    {
      title: targetTableName,
      dataIndex: "target",
      ellipsis: true,
    },
    {
      title: "Match (%)",
      dataIndex: "matching",
      width: 200,
      render: (matching, data) => {
        if (!isProfiler) {
          return matching;
        }

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {matching}
            <div
              style={{
                background: "#F9F9F9",
                border: "1px solid #DDDDDD",
                borderRadius: "2px",
                padding: "5px 8px",
              }}
            >
              {data.detailedMatching}
            </div>
          </div>
        );
      },
      onCell: () => {
        return {
          style: { background: NextGenPalette.lightYellow },
        };
      },
    },
  ];

  if ((isProfiler && isMonitored) || incidents.length > 0) {
    columns.push({
      title: <WarningOutlined />,
      width: 56,
      align: "center",
      dataIndex: "isIncident",
      render: (isIncident) => {
        if (!isIncident) {
          return null;
        }

        return <WarningTwoTone twoToneColor="#FAB800" />;
      },
    });
  }

  if (!isPreview && isProfiler) {
    columns.push({
      title: "Failing records",
      width: 156,
      dataIndex: "type",
      render: (type, rowData) => {
        const allowRecordStorage =
          !!dataSource?.config.governance.allowRecordStorage &&
          !!srcDataSource?.config.governance.allowRecordStorage;
        const isButtonDisabled = !allowRecordStorage && type === "attribute";
        const outsideClickWrap = {};
        outsideClickWrap.fn = closeTakeover;
        return (
          <Button
            disabled={isButtonDisabled}
            onClick={() => {
              openWorkspaceTakeover(
                <FullCompareSampleDataTakeOverview
                  workspaceUuid={workspaceUuid}
                  closeTakeover={closeTakeover}
                  dataSource={dataSource}
                  srcDataSource={srcDataSource}
                  metric={metric}
                  type={type}
                  rowData={rowData}
                  metricData={value}
                  timestamp={timestamp}
                  outsideClickWrap={outsideClickWrap}
                />,
                TakeoverWidth.WIDE,
                () => outsideClickWrap?.fn()
              );
            }}
            type="link"
            className="full-compare-sample-dialog-trigger-container"
          >
            View
          </Button>
        );
      },
    });
  }

  if (showStatsData) {
    columns.push({
      title: "Threshold range",
      dataIndex: "matchingRange",
    });
  }

  const data = [];
  let keyMatchingStr;
  if (isPreview) {
    keyMatchingStr = "---";
  } else {
    keyMatchingStr = keyMatching;
  }

  data.push({
    key: data.length + 1,
    type: "key",
    title: "Key column",
    source: sourceColumnName,
    target: targetColumnName,
    matching: keyMatchingStr,
    detailedMatching: isPreview
      ? ""
      : `${value.matchingCount}/${
          value.matchingCount + value.sourceRemainingCount + value.targetRemainingCount
        }`,
    matchingRange: keyMatchingRangeStr,
    isIncident: incidentKeyIndexList.length > 0,
    index: -1,
  });

  if (
    sourceAttributeColumns.length > 0 &&
    sourceAttributeColumns.length === targetAttributeColumns.length
  ) {
    for (let i = 0; i < sourceAttributeColumns.length; i++) {
      const currentSourceAttributeColumn = sourceAttributeColumns[i];
      const currentTargetAttributeColumn = targetAttributeColumns[i];
      const currentAttributeMatch = getDisplayFloatStr(attributeMatching[i], true);
      const currentAttributeMatchRangeStr = showStatsData
        ? getRangeDisplayStr(
            lowerBoundAttributeMatchingPercValue[i],
            upperBoundAttributeMatchingPercValue[i]
          )
        : "";

      data.push({
        key: data.length + 1,
        type: "attribute",
        title: "Attribute column",
        source: currentSourceAttributeColumn,
        target: currentTargetAttributeColumn,
        matching: isPreview ? "---" : currentAttributeMatch,
        matchingCount: Math.round((value.matchingCount * attributeMatching[i]) / 100),
        totalCount: value.matchingCount,
        detailedMatching: isPreview
          ? ""
          : `${attributeMatchingCounts[i]}/${value.matchingCount}`,
        matchingRange: currentAttributeMatchRangeStr,
        isIncident: incidentAttrIndexList.includes(i),
        index: i,
      });
    }
  }

  return (
    <div className="full-compare-table-view-container">
      <Table bordered columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataSourceList: state.dataSourceReducer.dataSourceList,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid, opts) =>
    dispatch(getDataSourceList(workspaceUuid, opts)),
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick) =>
    dispatch(openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick)),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FullCompareTableView)
);
