import React from "react";
import { connect } from "react-redux";
import EventBarChartView from "./event-bar-chart-view";
import ZeroStatusChart from "../zero-status-chart";
import EventBarChartDetailTableView from "./event-bar-chart-detail-table-view";
import { EventMetricTypeName, TakeoverWidth } from "../../../utils/enums";
import {
  openWorkspaceTakeover,
  closeTakeover,
} from "../../../actions/takeover/takeover-action";

import "./index.scss";

function EventChartView(props) {
  const { title, data, openWorkspaceTakeover, metricType, closeTakeover, incidents } =
    props;

  const normalizedTitle = title || EventMetricTypeName[metricType] || "Event activity";
  let metricsData = [];
  if (typeof data?.metricsData?.length === "number") {
    metricsData = data.metricsData; // For old event chart
  } else if (typeof data?.metricsData?.signalData?.length === "number") {
    metricsData = data.metricsData.signalData;
  }

  function onBarClick(data) {
    if (data?.points?.length !== 1) {
      console.log(`Invalid event chart on click data ${JSON.stringify(data)}`);
      return;
    }

    const pointNumber = data.points[0].pointNumber;
    const selectedMetricData = metricsData[pointNumber];
    let normalizedSelectedMetricData = [];
    if (typeof selectedMetricData?.value?.length === "number") {
      normalizedSelectedMetricData = selectedMetricData;
    } else if (typeof selectedMetricData?.metadata?.events.length === "number") {
      normalizedSelectedMetricData = selectedMetricData?.metadata?.events;
    }

    if (normalizedSelectedMetricData.length < 1) {
      return;
    }

    setTimeout(() => {
      openWorkspaceTakeover(
        <EventBarChartDetailTableView
          title={normalizedTitle}
          data={{
            timestamp: selectedMetricData.timestamp,
            value: normalizedSelectedMetricData,
          }}
          closeTakeover={closeTakeover}
        />,
        TakeoverWidth.WIDE,
        () => {
          closeTakeover();
        }
      );
    }, 0);
  }

  return (
    <div className="event-chart-view-container">
      {metricsData.length > 0 ? (
        <EventBarChartView
          data={metricsData}
          onClick={onBarClick}
          incidents={incidents}
        />
      ) : (
        <ZeroStatusChart height={60} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick, className) =>
    dispatch(
      openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick, className)
    ),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventChartView);
