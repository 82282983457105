import NgToggleCheckbox from "../../toggle-checkbox/ng-toggle-checkbox";

function RedshiftConfigView(props) {
  const { value, onChange } = props;
  const { serverless = false } = value;

  return (
    <NgToggleCheckbox
      label="Serverless"
      labelPosition="left"
      value={serverless}
      onChange={(newServerless) => {
        onChange?.({ ...value, serverless: newServerless });
      }}
    />
  );
}

export default RedshiftConfigView;
