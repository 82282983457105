import { useEffect, useState } from "react";
import Popover from "../popover";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { getMetricCollectionSummaryPromise } from "../../rest/metrics";
import { Skeleton } from "antd";
import { useIsMounted } from "../../hooks/useIsMounted";

import "./index.scss";

export function MetricCollectionSummaryContent(props) {
  const { metricCollectionSummaryText, loading, onCloseClick } = props;

  return (
    <div className="metric-friendly-description">
      <div className="metric-friendly-description-header">
        <span className="metric-friendly-description-info-icon">
          <QuestionCircleOutlined />
        </span>
        <span className="metric-friendly-description-title">
          When will this metric be collected and what will be collected?
        </span>
        <span
          onClick={() => onCloseClick()}
          className="metric-friendly-description-close-btn"
        >
          <CloseOutlined />
        </span>
      </div>
      <div className="metric-friendly-description-body">
        <div className="metric-friendly-description-loading">
          {loading && <Skeleton active title={false} paragraph />}
        </div>
        {!loading && metricCollectionSummaryText}
      </div>
    </div>
  );
}

function MetricCollectionSummary(props) {
  const { workspaceUuid, metric, children: trigger, placement = "bottomLeft" } = props;

  const [metricCollectionSummaryText, setMetricCollectionSummaryText] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMounted = useIsMounted();

  useEffect(() => {
    if (popoverOpen) {
      setLoading(true);
      getMetricCollectionSummaryPromise(workspaceUuid, metric)
        .then(({ metricCollectionSummary }) => {
          if (isMounted()) {
            setMetricCollectionSummaryText(metricCollectionSummary);
            setLoading(false);
          }
        })
        .catch(() => {
          if (isMounted()) {
            setPopoverOpen(false);
          }
        });
    }
  }, [popoverOpen, workspaceUuid, metric, isMounted]);

  const popoverContent = (
    <MetricCollectionSummaryContent
      metric={metric}
      metricCollectionSummaryText={metricCollectionSummaryText}
      loading={loading}
      onCloseClick={() => setPopoverOpen(false)}
    />
  );

  return (
    <Popover
      trigger="click"
      content={popoverContent}
      overlayClassName="metric-friendly-description-overlay"
      placement={placement}
      visible={popoverOpen}
      onVisibleChange={setPopoverOpen}
    >
      {trigger}
    </Popover>
  );
}

export default MetricCollectionSummary;
