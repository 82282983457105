import React from "react";
import { DataSourceConfigItem, SchemaConfigItem, TableConfigItem } from "../fields";
import { getMetricTypeFromConfigData } from "../utils";
import { MetricCategory } from "../../../utils/enums";
import { ConfigSummaryCard, ConfigSummaryCardRow } from "../summary-card";
import { default as Icon } from "@ant-design/icons/lib/components/Icon";
import {
  MetricColumnIcon,
  MetricDataSourceTypeIcon,
  MetricSchemaIcon,
  MetricTableIcon,
  MetricValueColumnsIcon,
} from "../fields/icons";
import {
  FullCompareDataAssetSummary,
  isEmptyFullCompareDataAssetSummary,
} from "./full-compare";
import {
  AggregationCompareDataAssetSummary,
  isEmptyAggregationCompareDataAssetSummary,
} from "./aggregation-compare";
import queryString from "query-string";
import { IDIcon } from "../../icons/summary";

import "./data-asset-summary.scss";

function SingleSourceDataAssetSummary(props) {
  const { configData, dataSourceList, schemaList } = props;

  const metricType = getMetricTypeFromConfigData(configData);
  const selectedColumn =
    metricType === MetricCategory.CUSTOM_SQL
      ? configData.config?.table?.columnName
      : configData.config?.valueColumns?.map(({ columnName }) => columnName).join(", ");

  return (
    <>
      <DataSourceConfigItem
        dataSourceList={dataSourceList}
        value={configData.config.sources[0]}
        isEdit={false}
      />
      {configData.config.table?.schemaName && (
        <SchemaConfigItem
          schemaList={schemaList}
          value={configData.config.table.schemaName}
          isEdit={false}
        />
      )}
      {configData.config.table?.tableUuid && (
        <TableConfigItem
          tableList={[configData.config.table]}
          value={configData.config.table.tableUuid}
          isEdit={false}
        />
      )}
      {selectedColumn && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricValueColumnsIcon} />}
          label={selectedColumn}
        />
      )}
    </>
  );
}

export function isEmptyDataAssetSummary(configData) {
  const metricType = getMetricTypeFromConfigData(configData);
  switch (metricType) {
    case MetricCategory.FULL_COMPARE:
      return isEmptyFullCompareDataAssetSummary(configData);
    case MetricCategory.AGGREGATION_COMPARE:
      return isEmptyAggregationCompareDataAssetSummary(configData);
    default:
      return !(configData.config.sources?.length > 0);
  }
}

export function DataAssetSummary(props) {
  const { configData } = props;

  const metricType = getMetricTypeFromConfigData(configData);
  switch (metricType) {
    case MetricCategory.FULL_COMPARE:
      return <FullCompareDataAssetSummary {...props} />;
    case MetricCategory.AGGREGATION_COMPARE:
      return <AggregationCompareDataAssetSummary {...props} />;
    default:
      return <SingleSourceDataAssetSummary {...props} />;
  }
}

export function TargetDataAssetSummary(props) {
  const { location } = props;

  const queryParams = queryString.parse(location.search);

  if (!queryParams.dataSourceName) {
    return null;
  }

  const { dataSourceName, schemaName, tableName, columnName } = queryParams;

  return (
    <ConfigSummaryCard title="Data Asset" showEditButton={false}>
      {dataSourceName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricDataSourceTypeIcon} />}
          label={dataSourceName}
        />
      )}
      {schemaName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricSchemaIcon} />}
          label={schemaName}
        />
      )}
      {tableName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricTableIcon} />}
          label={tableName}
        />
      )}
      {columnName && (
        <ConfigSummaryCardRow
          icon={<Icon component={MetricValueColumnsIcon} />}
          label={columnName}
        />
      )}
    </ConfigSummaryCard>
  );
}

function singleSourceShortDataAssetSummary(configData, dataSourcesByUuid) {
  const dataSource = dataSourcesByUuid[configData.config.sources[0]];
  const segments = [
    {
      key: "dataSource",
      value: dataSource.metadata.name,
      icon: <MetricDataSourceTypeIcon />,
    },
  ];
  if (configData.config.table?.tableName) {
    segments.push({
      key: "schema",
      value: configData.config.table.schemaName,
      icon: <MetricSchemaIcon />,
    });
    segments.push({
      key: "table",
      value: configData.config.table.tableName,
      icon: <MetricTableIcon />,
    });
  }
  if (configData.config.valueColumns?.length > 0) {
    segments.push({
      key: "valueColumns",
      value: configData.config.valueColumns
        .map(({ columnName }) => columnName)
        .join(", "),
      icon: <MetricColumnIcon />,
    });
  }
  return { single: segments };
}

export function multiSourceShortDataAssetSummary(configData, dataSourcesByUuid) {
  const {
    config: { sourceTable, targetTable },
  } = configData;

  const sourceDataSource = dataSourcesByUuid[sourceTable.sourceUuid];
  const targetDataSource = dataSourcesByUuid[targetTable.sourceUuid];

  const segments = {
    source: [
      {
        key: "sourceDataSource",
        value: sourceDataSource.metadata.name,
        icon: <MetricDataSourceTypeIcon />,
      },
      {
        key: "sourceSchema",
        value: sourceTable.table.schemaName,
        icon: <MetricSchemaIcon />,
      },
      {
        key: "sourceTable",
        value: sourceTable.table.tableName,
        icon: <MetricTableIcon />,
      },
    ],
    target: [
      {
        key: "targetDataSource",
        value: targetDataSource.metadata.name,
        icon: <MetricDataSourceTypeIcon />,
      },
      {
        key: "targetSchema",
        value: targetTable.table.schemaName,
        icon: <MetricSchemaIcon />,
      },
      {
        key: "targetTable",
        value: targetTable.table.tableName,
        icon: <MetricTableIcon />,
      },
    ],
  };

  if (sourceTable.valueColumns?.length > 0) {
    segments.source.push({
      key: "sourceValueColumns",
      value: sourceTable.valueColumns.join(", "),
      icon: <MetricColumnIcon />,
    });
  }

  if (targetTable.valueColumns?.length > 0) {
    segments.target.push({
      key: "targetValueColumns",
      value: targetTable.valueColumns.join(", "),
      icon: <MetricColumnIcon />,
    });
  }

  return segments;
}

export function briefDataAssetSummary(configData, dataSourcesByUuid) {
  const metricType = getMetricTypeFromConfigData(configData);
  const baseSummary = {
    id: {
      key: "idSerial",
      value: configData.metadata.idSerial,
      icon: <IDIcon />,
    },
  };
  switch (metricType) {
    case MetricCategory.FULL_COMPARE:
    case MetricCategory.AGGREGATION_COMPARE:
      return {
        ...baseSummary,
        ...multiSourceShortDataAssetSummary(configData, dataSourcesByUuid),
      };
    default:
      return {
        ...baseSummary,
        ...singleSourceShortDataAssetSummary(configData, dataSourcesByUuid),
      };
  }
}

export function DataAssetSummaryTag(props) {
  const { icon, value } = props;
  return (
    <div className="data-asset-summary-tag">
      {icon} {value}
    </div>
  );
}
