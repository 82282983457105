import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getProfilerTableActivityLog } from "../../../actions/profiler/profiler-action";
import { useParams } from "react-router-dom";
import useSearchParams from "../../../hooks/useSearchParams";
import { getStringFromTimeStamp } from "../../../utils/time";
import { CommentIcon } from "../../incident/icons";
import { Spinner } from "../../../atom/spinner";
import Pagination from "../../../atom/pagination";
import NoDataState from "../../../assets/img/empty-state.svg";
import { getActivityChangeType } from "../utils";
import { TakeoverWidth } from "../../../utils/enums";
import { TableChangelogTakeoverView } from "../takeover/table-changelog";
import { RedoOutlined } from "@ant-design/icons";

import "./profiler-data-source-table-activity.scss";

const actionToMessageMap = {
  update: "Table updated",
  create: "Table created",
};
function ProfileDatasourceActivityLog({
  profilerConfigTableChangeLog,
  getProfilerTableActivityLog,
  openTakeover,
  closeTakeover,
}) {
  const [changeLogs, setChangeLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const currentData = useMemo(
    () => changeLogs.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    [pageSize, currentPage, changeLogs]
  );

  const params = useParams();
  const searchParams = useSearchParams();
  const contextIds = useMemo(() => {
    const { workspaceUuid } = params;
    const dataSourceUuid = searchParams.get("dataSourceUuid");
    const tableUuid = searchParams.get("tableUuid");
    return { workspaceUuid, dataSourceUuid, tableUuid };
  }, [params, searchParams]);

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  useEffect(() => {
    fetchActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextIds?.tableUuid]);
  const fetchActivity = () => {
    const { workspaceUuid, dataSourceUuid, tableUuid } = contextIds;
    getProfilerTableActivityLog(workspaceUuid, dataSourceUuid, tableUuid);
  };
  useEffect(() => {
    if (profilerConfigTableChangeLog.data) {
      setChangeLogs(profilerConfigTableChangeLog.data);
    }
  }, [profilerConfigTableChangeLog.data]);

  if (profilerConfigTableChangeLog.loading) {
    return (
      <div className="activity-loader">
        <Spinner size="large" tip="Fetching activity history..." />
      </div>
    );
  }

  if (profilerConfigTableChangeLog.error) {
    return (
      <div className="activity-error">
        <span>Error: Unable to retrieve logs at the moment </span>{" "}
        <RedoOutlined className="error-icon" onClick={fetchActivity} />
      </div>
    );
  }

  function openDiffConfigTakeover(changelog) {
    openTakeover(
      <TableChangelogTakeoverView changelog={changelog} onClose={closeTakeover} />,
      TakeoverWidth.MEDIUM,
      closeTakeover
    );
  }

  return (
    <div className="activity-container">
      <div>
        {currentData?.length ? (
          currentData?.map((res) => {
            const changeType = getActivityChangeType(res);
            return (
              <div className="activity-item-container" key={res.timestamp}>
                <div className="activity-item-panel-container">
                  <div className="activity-item-icon-container">
                    <CommentIcon width={16} />
                  </div>
                  <div>
                    <div className="activity-item-content-container">
                      <div className="activity-item-title-container">
                        {actionToMessageMap[res.action]}
                        {changeType && <span> ({changeType})</span>}
                      </div>
                      <div
                        className="activity-item-diff-handle"
                        onClick={() => openDiffConfigTakeover(res)}
                      >
                        View Changes
                      </div>
                    </div>
                    <div className="activity-item-description-container">
                      <div>{res.user}</div>
                      <div>{getStringFromTimeStamp(res.timestamp)}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="activity-item-empty-state">
            <img src={NoDataState} alt="No activity logs" />
            <div className="message">
              <span className="activity-item-empty-state-title">
                No activity available yet!
              </span>
              <span className="activity-item-empty-state-subtitle">
                You will see activity logs when the table is updated
              </span>
            </div>
          </div>
        )}
      </div>
      {changeLogs.length > pageSize && (
        <Pagination
          className="activity-pagination"
          current={currentPage}
          pageSize={pageSize}
          total={changeLogs.length}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={handlePageChange}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  profilerConfigTableChangeLog: state.profilerReducer.profilerConfigTableChangeLog,
});

const mapDispatchToProps = (dispatch) => ({
  getProfilerTableActivityLog: (workspaceUuid, dataSourceUuid, tableUuid) =>
    dispatch(getProfilerTableActivityLog(workspaceUuid, dataSourceUuid, tableUuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDatasourceActivityLog);
