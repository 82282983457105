import * as types from "./profiler-action-types";
import batchAPIRequestPromise from "../../utils/api-batch";
import { indexBy } from "../../utils/iterables";
import {
  createDataSourceTablePromise,
  deleteDataSourceTablePromise,
  getDataSourcePartitionSampleDataPromise,
  getDataSourcePromise,
  getDataSourceSummaryPromise,
  getDataSourceUsagePromise,
  getDataSourceListPromise,
  getDataSourceTreePromise,
  getDataSourceSchemaSummaryPromise,
  getDataSourceSchemaListPromise,
  getDataSourceSchemaUsagePromise,
  getDataSourceTableBlobListPromise,
  getDataSourceTableListPromise,
  getDataSourceTableSummaryPromise,
  getDataSourceTablePromise,
  getDataSourceTableHealthPromise,
  getDataSourceTableDataProfilePromise,
  getDataSourceTableUsagePromise,
  getDataSourceTableColumnListPromise,
  getDataSourceSchemaSqlPromise,
  getDataSourceTableColumnListDataProfilePromise,
  getDataSourceTableColumnSummaryPromise,
  getDataSourceTableColumnUsagePromise,
  getDataSourceTableColumnDataProfilePromise,
  triggerDataSourceMetricPromise,
  updateDataSourceProfilerConfigPromise,
  updateDataSourceSchemaProfilerConfigPromise,
  updateDataSourceTableProfilerConfigPromise,
  updateDataSourceTableColumnProfilerConfigPromise,
  createGenericCancelToken,
  getDataSourceTableChangeLogPromise,
} from "../../rest/datasource";
import {
  getIncidentListPromise,
  updateIncidentStatusPromise,
} from "../../rest/incident";
import { getFilterDataPromise, getSignalDataPromise } from "../../rest/metrics";
import {
  getKpiPromise,
  addKpiPromise,
  getKpiListPromise,
  getKpiCachedSliceValueListPromise,
  getKpiTableSchemaSamplesPreviewPromise,
} from "../../rest/kpi";
import {
  getRuleListPromise,
  addRulePromise,
  updateRulePromise,
  addRulesPromise,
} from "../../rest/rule";
import { getKpiList } from "../kpi/kpi-action";
import { getColumnTypeCategory } from "../../utils/column";
import {
  TableColumnTypeCategory,
  MetricFilterType,
  MetricType,
  AutoMetricsType,
  MetricConfigType,
} from "../../utils/enums";
import { getDefaultProfilerDisplayPeriod } from "../../utils/defaults";
import { getUnixTime, subDays, fromUnixTime } from "date-fns";
import { getAutoMetricTypeFromKPI, getSliceByColumns } from "../../utils/metric";
import { initialProfilerState } from "../../reducers/profiler/profiler-reducer";
import { reconciledProfilerConfig } from "../../utils/data-profiling";
import { isSchemaChangeEnabled } from "../../utils/table";

function setProfilerTreeData(profilerTreeData) {
  return { type: types.SET_PROFILER_TREE_DATA, profilerTreeData };
}

function setProfilerCustomMetricTreeData(profilerCustomMetricTreeData) {
  return {
    type: types.SET_PROFILER_CUSTOM_METRIC_TREE_DATA,
    profilerCustomMetricTreeData,
  };
}

function setProfilerCurrentDataSourceSummary(profilerCurrentDataSourceSummary) {
  return {
    type: types.SET_PROFILER_CURRENT_DATA_SOURCE_SUMMARY,
    profilerCurrentDataSourceSummary,
  };
}

function setProfilerCurrentSchemaSummary(profilerCurrentSchemaSummary) {
  return {
    type: types.SET_PROFILER_CURRENT_SCHEMA_SUMMARY,
    profilerCurrentSchemaSummary,
  };
}

function setProfilerCurrentTableAutoMetricsData(profilerCurrentTableAutoMetricsData) {
  return {
    type: types.SET_PROFILER_CURRENT_TABLE_AUTO_METRICS_DATA,
    profilerCurrentTableAutoMetricsData,
  };
}

function setProfilerCurrentTableOverviewSummary(profilerCurrentTableOverviewSummary) {
  return {
    type: types.SET_PROFILER_CURRENT_TABLE_OVERVIEW_SUMMARY,
    profilerCurrentTableOverviewSummary,
  };
}

function setProfilerCurrentMetricMetricsData(profilerCurrentMetricMetricsData) {
  return {
    type: types.SET_PROFILER_CURRENT_METRIC_METRICS_DATA,
    profilerCurrentMetricMetricsData,
  };
}

function setProfilerCurrentMetricSliceValues(profilerCurrentMetricSliceValues) {
  return {
    type: types.SET_PROFILER_CURRENT_METRIC_SLICE_VALUES,
    profilerCurrentMetricSliceValues,
  };
}

function setProfilerConfigDataSourceList(profilerConfigDataSourceList) {
  return {
    type: types.SET_PROFILER_CONFIG_DATA_SOURCE_LIST,
    profilerConfigDataSourceList,
  };
}

function setProfilerConfigTableList(profilerConfigTableList) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_LIST,
    profilerConfigTableList,
  };
}

function setProfilerConfigCurrentDataSource(profilerConfigCurrentDataSource) {
  return {
    type: types.SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE,
    profilerConfigCurrentDataSource,
  };
}

export function setProfilerConfigDataSourceTableList(
  profilerConfigDataSourceTableList
) {
  return {
    type: types.SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST,
    profilerConfigDataSourceTableList,
  };
}

function setProfilerConfigDataSourceTableListUsage(
  profilerConfigDataSourceTableListUsage
) {
  return {
    type: types.SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_LIST_USAGE,
    profilerConfigDataSourceTableListUsage,
  };
}

function setProfilerConfigDataSourceColumnList(profilerConfigDataSourceColumnList) {
  return {
    type: types.SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_COLUMN_LIST,
    profilerConfigDataSourceColumnList,
  };
}

function setProfilerConfigDataSourceTableColumnList(
  profilerConfigDataSourceTableColumnList
) {
  return {
    type: types.SET_PROFILER_CONFIG_CURRENT_DATA_SOURCE_TABLE_COLUMN_LIST,
    profilerConfigDataSourceTableColumnList,
  };
}

function setProfilerConfigTableCurrentDataSource(profilerConfigTableCurrentDataSource) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_CURRENT_DATA_SOURCE,
    profilerConfigTableCurrentDataSource,
  };
}

function setProfilerConfigTableCurrentTable(profilerConfigTableCurrentTable) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_CURRENT_TABLE,
    profilerConfigTableCurrentTable,
  };
}

function setProfilerConfigTableBlobList(profilerConfigTableBlobList) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_BLOB_LIST,
    profilerConfigTableBlobList,
  };
}

function setProfilerConfigTableHealthData(profilerCurrentTableHealthData) {
  return {
    type: types.SET_PROFILER_TABLE_HEALTH_DATA,
    profilerCurrentTableHealthData,
  };
}

function setProfilerConfigTableColumnList(profilerConfigTableColumnList) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_COLUMN_LIST,
    profilerConfigTableColumnList,
  };
}
function setProfilerConfigTableChangeLog(profilerConfigTableChangeLog) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_CHANGE_LOG,
    profilerConfigTableChangeLog,
  };
}

function setProfilerConfigTableColumnListUsage(profilerConfigTableColumnListUsage) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_COLUMN_LIST_USAGE,
    profilerConfigTableColumnListUsage,
  };
}

function setProfilerConfigTableMetrics(profilerConfigTableMetrics) {
  return {
    type: types.SET_PROFILER_CONFIG_TABLE_COLUMN_METRICS,
    profilerConfigTableMetrics,
  };
}

function setProfilerColumnCurrentMetricsData(profilerColumnCurrentMetricsData) {
  return {
    type: types.SET_PROFILER_COLUMN_CURRENT_METRICS_DATA,
    profilerColumnCurrentMetricsData,
  };
}

function setProfilerCurrentIncidentList(profilerCurrentIncidentList) {
  return {
    type: types.SET_PROFILER_CURRENT_INCIDENT_LIST,
    profilerCurrentIncidentList,
  };
}

function setProfilerCurrentIncidentMetricsData(profilerCurrentIncidentMetricsData) {
  return {
    type: types.SET_PROFILER_CURRENT_INCIDENT_METRICS_DATA,
    profilerCurrentIncidentMetricsData,
  };
}

function setProfilerCurrentMonitorObject(profilerCurrentMonitorObject) {
  return {
    type: types.SET_PROFILER_CURRENT_MONITOR_OBJECT,
    profilerCurrentMonitorObject,
  };
}

function setProfilerPartitionSampleData(profilerPartitionSampleData) {
  return {
    type: types.SET_PROFILER_PARTITION_SAMPLE_DATA,
    profilerPartitionSampleData,
  };
}

function setProfilerTableListPartitionSampleData(profilerTableListPartitionSampleData) {
  return {
    type: types.SET_PROFILER_TABLE_LIST_PARTITION_SAMPLE_DATA,
    profilerTableListPartitionSampleData,
  };
}

function setProfilerConfigDataSourceSchemaList(profilerConfigDataSourceSchemaList) {
  return {
    type: types.SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST,
    profilerConfigDataSourceSchemaList,
  };
}

function setProfilerConfigDataSourceSchemaListUsage(
  profilerConfigDataSourceSchemaListUsage
) {
  return {
    type: types.SET_PROFILER_CONFIG_DATA_SOURCE_SCHEMA_LIST_USAGE,
    profilerConfigDataSourceSchemaListUsage,
  };
}

function setProfilerColumnDataProfileData(profilerColumnDataProfileData) {
  return {
    type: types.SET_PROFILER_COLUMN_DATA_PROFILE_DATA,
    profilerColumnDataProfileData,
  };
}

function setProfilerTableDataProfileTable(profilerTableDataProfileTable) {
  return {
    type: types.SET_PROFILER_TABLE_DATA_PROFILE_TABLE,
    profilerTableDataProfileTable,
  };
}

function setProfilerTableDataProfileTableDataProfile(
  profilerTableDataProfileTableDataProfile
) {
  return {
    type: types.SET_PROFILER_TABLE_DATA_PROFILE_TABLE_DATA_PROFILE,
    profilerTableDataProfileTableDataProfile,
  };
}
function setProfilerTableDataProfileData(profilerTableDataProfileData) {
  return {
    type: types.SET_PROFILER_TABLE_DATA_PROFILE_DATA,
    profilerTableDataProfileData,
  };
}

function setProfilerTableMetadataMetricsData(profilerTableMetadataMetricsData) {
  return {
    type: types.SET_PROFILER_TABLE_METADATA_METRICS_DATA,
    profilerTableMetadataMetricsData,
  };
}

// When using the explorer node menus, we want certain actions (e.g. "Monitor metrics") to occur
// after we've navigated to the target node. Setting the profilerAfterTreeNavigationAction state
// queues an action to be taken by the node page after you navigate to it. The node page monitors
// this part of the Redux store, and if it sees a queued action, it will do the appropriate thing
// then clear the queued action.

export const ProfilerAfterTreeNavigationActionType = Object.freeze({
  DATASOURCE_MONITOR_ALL_METRICS: "DATASOURCE_MONITOR_ALL_METRICS",
  SCHEMA_MONITOR_ALL_METRICS: "SCHEMA_MONITOR_ALL_METRICS",
  TABLE_MONITOR_ALL_METRICS: "TABLE_MONITOR_ALL_METRICS",
  TABLE_TRIGGER_ALL_METRICS: "TABLE_TRIGGER_ALL_METRICS",
  TABLE_OPEN_CONFIG: "TABLE_OPEN_CONFIG",
  COLUMN_MONITOR_ALL_METRICS: "COLUMN_MONITOR_ALL_METRICS",
});

export function setProfilerAfterTreeNavigationAction(action) {
  return {
    type: types.SET_PROFILER_AFTER_TREE_NAVIGATION_ACTION,
    action,
  };
}

function setProfilerVirtualTableColumnList(profilerVirtualTableColumnList) {
  return {
    type: types.SET_PROFILER_VIRTUAL_TABLE_COLUMN_LIST,
    profilerVirtualTableColumnList,
  };
}

function setProfilerVirtualTableSchemaList(profilerVirtualTableSchemaList) {
  return {
    type: types.SET_PROFILER_VIRTUAL_TABLE_SCHEMA_LIST,
    profilerVirtualTableSchemaList,
  };
}

function setProfilerVirtualTableSampleDataList(profilerVirtualTableSampleDataList) {
  return {
    type: types.SET_PROFILER_VIRTUAL_TABLE_SAMPLE_DATA_LIST,
    profilerVirtualTableSampleDataList,
  };
}

function filterTreeDataTables(tables) {
  const filteredTables = [];
  for (let currentTable of tables) {
    const { firstSeenTs, lastSeenTs, removedTs, schemaUpdatedTs, ...otherProperties } =
      currentTable;
    if (removedTs) {
      continue;
    }

    filteredTables.push({
      firstSeenTs: firstSeenTs || 0,
      removedTs: removedTs || 0,
      lastSeenTs: lastSeenTs || 0,
      schemaUpdatedTs: schemaUpdatedTs || 0,
      health: true,
      ...otherProperties,
    });
  }
  return filteredTables;
}

function getProfilerTreeDataWithProfilerConfigUpdated(
  profilerTreeData,
  dataSourceUuid,
  paramsList
) {
  const paramsListTableMap = paramsList.reduce((map, { tableUuid, ...rest }) => {
    map[tableUuid] = rest;
    return map;
  }, {});
  const newProfilerTreeData = { ...profilerTreeData };
  const dataSource = newProfilerTreeData.data.find(
    (dataSource) => dataSource.metadata.uuid === dataSourceUuid
  );

  dataSource?.schemas?.forEach((schema) => {
    schema.tables?.forEach((table) => {
      if (table.uuid in paramsListTableMap) {
        table.profilerConfig = paramsListTableMap[table.uuid].profilerConfig;
      }
    });
  });

  return newProfilerTreeData;
}

function getDataSourceTree(workspaceUuid, dataSource) {
  return new Promise((resolve, reject) => {
    getDataSourceTreePromise(workspaceUuid, dataSource.metadata.uuid)
      .then((response) => {
        const schemas = response.schemas || [];
        const metrics = response.metrics || [];
        const filteredTableSchemas = schemas.map((schema) => {
          schema.tables = filterTreeDataTables(schema.tables || []);
          return schema;
        });
        resolve({
          ...dataSource,
          schemas: filteredTableSchemas,
          metrics,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAutoMetricTreeData(workspaceUuid, dataSourceList) {
  return new Promise((resolve, reject) => {
    const enabledDataSourceList = dataSourceList.filter(
      (dataSource) =>
        dataSource.config &&
        dataSource.config.profiler &&
        dataSource.config.profiler.enabled
    );

    if (enabledDataSourceList.length === 0) {
      resolve([]);
      return;
    }

    const allSourceListRequestPromise = enabledDataSourceList.map((dataSource) => {
      return getDataSourceTree(workspaceUuid, dataSource);
    });

    Promise.all(allSourceListRequestPromise)
      .then(function (profilerDataSourceList) {
        resolve(profilerDataSourceList);
      })
      .catch(function (err) {
        reject(err);
        console.log("Fail to get all the tables list");
      });
  });
}

function getCustomMetricTreeData(workspaceUuid) {
  return new Promise((resolve, reject) => {
    getKpiListPromise(workspaceUuid, { type: [MetricType.CUSTOM] })
      .then((kpiList) => {
        resolve(kpiList);
      })
      .catch(function (err) {
        reject(err);
        console.log("Fail to get kpi list for loading custom metric tree data");
      });
  });
}

export function getProfilerTreeData(workspaceUuid, opts = {}) {
  const { isRefresh = false } = opts;
  return (dispatch, getState) => {
    if (!isRefresh) {
      dispatch(
        setProfilerTreeData({
          loading: true,
          data: [],
          workspaceUuid,
          dataSourceList: [],
        })
      );
      dispatch(
        setProfilerCustomMetricTreeData({
          loading: true,
          data: [],
          workspaceUuid,
          dataSourceList: [],
        })
      );
    }

    // Adds a softLoading when isRefresh to identify it's will be silently load new data
    if (isRefresh) {
      const data = getState().profilerReducer.profilerTreeData;
      dispatch(
        setProfilerTreeData({
          ...data,
          softLoading: true,
        })
      );
    }
    getDataSourceListPromise(workspaceUuid)
      .then(function (dataSourceList) {
        getAutoMetricTreeData(workspaceUuid, dataSourceList)
          .then(function (treeData) {
            const lastRequestedWorkspaceUuid =
              getState().profilerReducer.profilerTreeData.workspaceUuid;
            if (
              lastRequestedWorkspaceUuid &&
              lastRequestedWorkspaceUuid !== workspaceUuid
            ) {
              console.log(
                `Received profiler tree data for non-selected workspace (${workspaceUuid}); discarding it.`
              );
              return;
            }

            dispatch(
              setProfilerTreeData({
                loading: false,
                softLoading: false,
                dataSourceList,
                workspaceUuid,
                data: treeData,
              })
            );
          })
          .catch(function (_err) {
            if (!isRefresh) {
              dispatch(
                setProfilerTreeData({
                  loading: false,
                  softLoading: false,
                  dataSourceList,
                  workspaceUuid,
                  data: [],
                })
              );
            }
          });
        getCustomMetricTreeData(workspaceUuid)
          .then(function (customMetricData) {
            const lastRequestedWorkspaceUuid =
              getState().profilerReducer.profilerCustomMetricTreeData.workspaceUuid;
            if (
              lastRequestedWorkspaceUuid &&
              lastRequestedWorkspaceUuid !== workspaceUuid
            ) {
              console.log(
                `Received profiler custom metric tree data for non-selected workspace (${workspaceUuid}); discarding it.`
              );
              return;
            }

            dispatch(
              setProfilerCustomMetricTreeData({
                loading: false,
                dataSourceList,
                workspaceUuid,
                data: customMetricData,
              })
            );
          })
          .catch(function (_err) {
            if (!isRefresh) {
              dispatch(
                setProfilerCustomMetricTreeData({
                  loading: false,
                  dataSourceList,
                  workspaceUuid,
                  data: null,
                })
              );
            }
          });
      })
      .catch(function (error) {
        console.log(
          `Fail to get datasource list while loading profiler data source summary list for ${error}`
        );
        if (!isRefresh) {
          dispatch(
            setProfilerTreeData({
              loading: false,
              data: [],
              workspaceUuid,
              dataSourceList: [],
            })
          );
        }
      });
  };
}

export function refreshTreeDataSourceNode(workspaceUuid, dataSource) {
  return (dispatch, getState) => {
    getDataSourcePromise(workspaceUuid, dataSource.metadata.uuid).then(
      (updatedDataSource) => {
        // If we're currently configuring the datasource in question, make sure the config panel's
        // state is up-to-date.

        // When accessing through url the config source is null
        // that checking is needed to setup the current config source
        const currentConfigSource =
          getState().profilerReducer.profilerConfigCurrentDataSource;
        if (currentConfigSource?.metadata.uuid === updatedDataSource.metadata.uuid) {
          dispatch(setProfilerConfigCurrentDataSource(updatedDataSource));
        }
        getDataSourceTree(workspaceUuid, updatedDataSource).then(
          (updatedDataSourceNodeInfo) => {
            const currentTreeData = getState().profilerReducer.profilerTreeData;
            const updatedTreeData = {
              ...currentTreeData,
              data: [...currentTreeData.data],
              dataSourceList: [...currentTreeData.dataSourceList],
            };

            // Update dataSource object.
            const dataSourceIndexToUpdate = updatedTreeData.dataSourceList.findIndex(
              (source) => source.metadata.uuid === dataSource.metadata.uuid
            );
            if (dataSourceIndexToUpdate >= 0) {
              updatedTreeData.dataSourceList[dataSourceIndexToUpdate] =
                updatedDataSource;
            }

            // Update dataSource summary schemas.
            const summaryIndexToUpdate = updatedTreeData.data.findIndex(
              (sourceSummary) =>
                sourceSummary.metadata.uuid === dataSource.metadata.uuid
            );
            if (summaryIndexToUpdate >= 0) {
              updatedTreeData.data[summaryIndexToUpdate] = {
                ...updatedTreeData.data[summaryIndexToUpdate],
                ...updatedDataSource,
                schemas: updatedDataSourceNodeInfo.schemas,
              };
            }

            dispatch(setProfilerTreeData(updatedTreeData));
          }
        );
      }
    );
  };
}

export function resetProfilerTreeData() {
  return (dispatch, _getState) => {
    dispatch(
      setProfilerTreeData({
        loading: true,
        data: [],
        type: "",
        workspaceUuid: "",
        dataSourceList: [],
      })
    );
    dispatch(
      setProfilerCustomMetricTreeData({
        loading: true,
        data: [],
        type: "",
        workspaceUuid: "",
        dataSourceList: [],
      })
    );
  };
}

export function refreshProfilerTreeCustomMetric(workspaceUuid, metricUuid) {
  return (dispatch, getState) => {
    return getKpiPromise(workspaceUuid, metricUuid)
      .then((refreshedMetric) => {
        const profilerCustomMetricTreeData =
          getState().profilerReducer.profilerCustomMetricTreeData;
        const newData = profilerCustomMetricTreeData.data.map((metric) =>
          metric.metadata.uuid === refreshedMetric.metadata.uuid
            ? refreshedMetric
            : metric
        );
        dispatch(
          setProfilerCustomMetricTreeData({
            ...profilerCustomMetricTreeData,
            data: newData,
          })
        );
        return refreshedMetric;
      })
      .catch((err) =>
        console.log(`Failed to refresh profiler custom metric ${metricUuid}`, err)
      );
  };
}

export function getProfilerCurrentDataSourceSummary(
  workspaceUuid,
  currentDataSource,
  options = {}
) {
  const { isRefresh = false } = options;
  return (dispatch, getState) => {
    if (!isRefresh) {
      dispatch(
        setProfilerCurrentDataSourceSummary({
          loading: true,
          data: null,
        })
      );
    }

    getDataSourceSummaryPromise(workspaceUuid, currentDataSource.metadata.uuid)
      .then(function (dataSourceSummary) {
        dispatch(
          setProfilerCurrentDataSourceSummary({
            loading: false,
            data: dataSourceSummary,
          })
        );
      })
      .catch((err) => {
        console.log(`Failed to get datasource summary due to ${err}`);
        dispatch(
          setProfilerCurrentDataSourceSummary({
            loading: false,
            data: null,
          })
        );
      });
  };
}

export function getProfilerConfigDataSourceList(workspaceUuid) {
  return (dispatch, getState) => {
    dispatch(setProfilerConfigDataSourceList({ loading: true, data: [] }));
    getDataSourceListPromise(workspaceUuid)
      .then(function (dataSourceList) {
        dispatch(
          setProfilerConfigDataSourceList({
            loading: false,
            data: dataSourceList,
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to get config data source list ${err}`);
        dispatch(setProfilerConfigDataSourceList({ loading: false, data: [] }));
      });
  };
}

export function updateProfilerConfigDataSource(
  workspaceUuid,
  dataSourceUuid,
  dataSourceProfilerConfig
) {
  return (dispatch, getState) => {
    return updateDataSourceProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      dataSourceProfilerConfig
    )
      .then(function (response) {
        dispatch(getProfilerConfigDataSourceList(workspaceUuid));
      })
      .catch(function (err) {
        console.log(`Fail to put config data source list ${err}`);
      });
  };
}

export function getProfilerConfigDataSourceSchemaList(workspaceUuid, dataSource) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid },
    } = dataSource;
    dispatch(setProfilerConfigDataSourceSchemaList({ loading: true, data: [] }));
    return getDataSourceSchemaListPromise(workspaceUuid, uuid)
      .then(function (data) {
        dispatch(
          setProfilerConfigDataSourceSchemaList({
            loading: false,
            data: data.data,
          })
        );
        return data.data;
      })
      .catch(function (err) {
        dispatch(setProfilerConfigDataSourceSchemaList({ loading: false, data: [] }));
        console.log(`Fail to get config schema list ${err}`);
      });
  };
}

export function resetProfilerConfigDataSourceSchemaList() {
  return (dispatch, _getState) => {
    dispatch(setProfilerConfigDataSourceSchemaList({ loading: true, data: [] }));
  };
}

export function updateProfilerConfigDataSourceProfilerConfig(
  workspaceUuid,
  dataSourceUuid,
  dataSourceProfilerConfig
) {
  return (dispatch, getState) => {
    const { profilerConfigCurrentDataSource } = getState().profilerReducer;
    return updateDataSourceProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      dataSourceProfilerConfig
    )
      .then(function () {
        dispatch(
          setProfilerConfigCurrentDataSource({
            ...profilerConfigCurrentDataSource,
            config: {
              ...profilerConfigCurrentDataSource.config,
              profiler: dataSourceProfilerConfig,
            },
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to put config data source ${err}`);
      });
  };
}

export function getProfilerConfigTableList(workspaceUuid, dataSource, opts = {}) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid },
    } = dataSource;

    const { isRefresh = false } = opts;
    !isRefresh && dispatch(setProfilerConfigTableList({ loading: true, data: [] }));
    getDataSourceTableListPromise(workspaceUuid, uuid)
      .then(function (data) {
        dispatch(
          setProfilerConfigTableList({
            loading: false,
            data: data.data.filter((currentTable) => !currentTable.removedTs),
          })
        );
      })
      .catch(function (err) {
        !isRefresh &&
          dispatch(setProfilerConfigTableList({ loading: false, data: [] }));
        console.log(`Fail to get config table list ${err}`);
      });
  };
}

export function getProfilerConfigCurrentDataSource(workspaceUuid, dataSourceUuid) {
  return (dispatch, getState) => {
    return getDataSourcePromise(workspaceUuid, dataSourceUuid)
      .then(function (dataSource) {
        dispatch(setProfilerConfigCurrentDataSource(dataSource));
        return dataSource;
      })
      .catch(function (err) {
        console.log(`Fail to get config data source list ${err}`);
      });
  };
}

export function getProfilerConfigDataSourceTableList(
  workspaceUuid,
  dataSource,
  schema
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid },
    } = dataSource;
    dispatch(setProfilerConfigDataSourceTableList({ loading: true, data: [] }));
    getDataSourceTableListPromise(workspaceUuid, uuid)
      .then(function (data) {
        dispatch(
          setProfilerConfigDataSourceTableList({
            data: data.data.filter((currentTable) => {
              const { removedTs, schemaName } = currentTable;
              if (removedTs) {
                return false;
              }

              if (schema && schemaName !== schema.name) {
                return false;
              }

              return true;
            }),
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to get config datasource table list ${err}`);
        dispatch(setProfilerConfigDataSourceTableList({ loading: false, data: [] }));
      });
  };
}

export function getProfilerConfigDataSourceTableColumnList(
  workspaceUuid,
  dataSource,
  tableUuids
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    dispatch(
      setProfilerConfigDataSourceTableColumnList({
        loading: true,
        data: {},
      })
    );

    Promise.all(
      tableUuids.map((tableUuid) => {
        return getDataSourceTableColumnListPromise(
          workspaceUuid,
          dataSourceUuid,
          tableUuid
        );
      })
    )
      .then(function (allColumnsResponse) {
        const columnsMapper = {};
        for (let i = 0; i < tableUuids.length; i++) {
          columnsMapper[tableUuids[i]] = allColumnsResponse[i];
        }
        dispatch(
          setProfilerConfigDataSourceTableColumnList({
            loading: false,
            data: columnsMapper,
          })
        );
      })
      .catch(function (err) {
        dispatch(
          setProfilerConfigDataSourceTableColumnList({
            loading: false,
            data: {},
          })
        );
        console.log(`Fail to get config datasource table column list ${err}`);
      });
  };
}

export function getProfilerConfigDataSourceColumnList(
  workspaceUuid,
  dataSource,
  tableUuid
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    dispatch(setProfilerConfigDataSourceColumnList([]));
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (response) {
        dispatch(setProfilerConfigDataSourceColumnList(response));
      })
      .catch(function (err) {
        console.log(`Fail to get config datasource column list ${err}`);
      });
  };
}

export function getProfilerConfigTableListUsage(
  workspaceUuid,
  dataSource,
  tableUuids,
  tableProfilerConfigs = []
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    dispatch(
      setProfilerConfigDataSourceTableListUsage({
        loading: true,
        data: {},
      })
    );
    Promise.all(
      tableUuids.map((tableUuid, index) => {
        return getDataSourceTableUsagePromise(
          workspaceUuid,
          dataSourceUuid,
          tableUuid,
          MetricFilterType.DATA,
          tableProfilerConfigs[index] ?? null
        );
      })
    )
      .then(function (allResponses) {
        let totalMetrics = 0;
        let totalRules = 0;
        for (let currentResponse of allResponses) {
          for (let currentMetricUsage of currentResponse.metrics) {
            totalMetrics = totalMetrics + 1;
            for (let currentRule of currentMetricUsage.rules) {
              if (currentRule.isLive) {
                totalRules = totalRules + 1;
              }
            }
          }
        }
        dispatch(
          setProfilerConfigDataSourceTableListUsage({
            loading: false,
            data: {
              totalMetrics,
              totalRules,
            },
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to get config table list usage for ${err}`);
      });
  };
}

export function updateProfilerConfigSchemaList(
  workspaceUuid,
  dataSource,
  paramsList,
  isRefreshTree = false
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    const updateProfilerConfigPayloads = paramsList.map(
      ({ uuid: schemaUuid, profilerConfig }) => {
        return {
          relative_url: `/api/v1/ws/${workspaceUuid}/sources/${dataSourceUuid}/profile/schemas/${schemaUuid}/profiler-config`,
          method: "put",
          data: profilerConfig,
        };
      }
    );

    return batchAPIRequestPromise(updateProfilerConfigPayloads)
      .then(function (allResponse) {
        const profilerConfigResponseMapper = {};
        for (let i = 0; i < paramsList.length; i++) {
          if (allResponse.data[i].status_code !== 200) {
            console.log(`Fail to update for ${paramsList[i].schemaUuid}`);
            continue;
          }
          profilerConfigResponseMapper[paramsList[i].schemaUuid] =
            allResponse.data[i].data;
        }

        const profilerConfigDataSourceSchemaList =
          getState().profilerReducer.profilerConfigDataSourceSchemaList;
        const newSchemaList = [];
        for (let currentSchema of profilerConfigDataSourceSchemaList.data) {
          if (!profilerConfigResponseMapper[currentSchema.uuid]) {
            newSchemaList.push(currentSchema);
          } else {
            newSchemaList.push({
              ...currentSchema,
              profilerConfig: profilerConfigResponseMapper[currentSchema.uuid],
            });
          }
        }
        dispatch(
          setProfilerConfigDataSourceSchemaList({
            loading: false,
            data: newSchemaList,
          })
        );

        if (isRefreshTree) {
          const profilerTreeData = getState().profilerReducer.profilerTreeData;
          const { workspaceUuid } = profilerTreeData;
          dispatch(getProfilerTreeData(workspaceUuid, { isRefresh: true }));
          dispatch(
            getProfilerCurrentDataSourceSummary(workspaceUuid, dataSource, {
              isRefresh: true,
            })
          );
        }
      })
      .catch(function (err) {
        console.log(`Fail to put config data source schema list ${err}`);
      });
  };
}

export function getProfilerConfigSchemaListUsage(
  workspaceUuid,
  dataSource,
  schemaUuids
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    dispatch(
      setProfilerConfigDataSourceSchemaListUsage({
        loading: true,
        data: {},
      })
    );
    Promise.all(
      schemaUuids.map((schemaUuid) => {
        return getDataSourceSchemaUsagePromise(
          workspaceUuid,
          dataSourceUuid,
          schemaUuid
        );
      })
    )
      .then(function (allResponses) {
        let totalMetrics = 0;
        let totalRules = 0;
        for (let currentResponse of allResponses) {
          for (let currentMetricUsage of currentResponse.metrics) {
            totalMetrics = totalMetrics + 1;
            for (let currentRule of currentMetricUsage.rules) {
              if (currentRule.isLive) {
                totalRules = totalRules + 1;
              }
            }
          }
        }
        dispatch(
          setProfilerConfigDataSourceSchemaListUsage({
            loading: false,
            data: {
              totalMetrics,
              totalRules,
            },
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to get config schema list usage for ${err}`);
      });
  };
}

export function prepareTableListPayload(workspaceUuid, dataSourceUuid, paramsList) {
  return paramsList.map(({ uuid: tableUuid, profilerConfig, status }) => {
    if (!profilerConfig.dataTimezone) {
      delete profilerConfig.dataTimezone;
    }

    const withReconciledDataProfilerConfig = status
      ? reconciledProfilerConfig(profilerConfig, status)
      : profilerConfig;

    return {
      relative_url: `/api/v1/ws/${workspaceUuid}/sources/${dataSourceUuid}/profile/tables/${tableUuid}/profiler-config`,
      method: "put",
      data: withReconciledDataProfilerConfig,
    };
  });
}

export function updateProfilerConfigTableList(
  workspaceUuid,
  dataSource,
  paramsList,
  opts = {}
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;

    const updateProfilerConfigPayloads = prepareTableListPayload(
      workspaceUuid,
      dataSourceUuid,
      paramsList
    );

    if (opts.isOptimisticUpdate) {
      // optimistically update profiler config for specified tables in datasource,
      // this avoid to make unnecessary request while processing the bulk update
      const newProfilerTreeData = getProfilerTreeDataWithProfilerConfigUpdated(
        getState().profilerReducer.profilerTreeData,
        dataSourceUuid,
        paramsList
      );
      dispatch(setProfilerTreeData(newProfilerTreeData));
    }

    return batchAPIRequestPromise(updateProfilerConfigPayloads)
      .then(function (allResponse) {
        const profilerConfigResponseMapper = {};
        for (let i = 0; i < paramsList.length; i++) {
          if (allResponse.data[i].status_code !== 200) {
            console.log(`Fail to update for ${paramsList[i].uuid}`);
            continue;
          }
          profilerConfigResponseMapper[paramsList[i].uuid] = allResponse.data[i].data;
        }

        const profilerConfigDataSourceTableList =
          getState().profilerReducer.profilerConfigDataSourceTableList;
        const newTableList = [];
        for (let currentTable of profilerConfigDataSourceTableList.data) {
          if (!profilerConfigResponseMapper[currentTable.uuid]) {
            newTableList.push(currentTable);
          } else {
            newTableList.push({
              ...currentTable,
              profilerConfig: profilerConfigResponseMapper[currentTable.uuid],
            });
          }
        }

        dispatch(
          setProfilerConfigDataSourceTableList({
            loading: false,
            data: newTableList,
          })
        );

        if (opts.isRefreshTree) {
          const profilerTreeData = getState().profilerReducer.profilerTreeData;
          const { workspaceUuid } = profilerTreeData;
          dispatch(getProfilerTreeData(workspaceUuid, { isRefresh: true }));
        }
        return Promise.resolve(allResponse);
      })
      .catch(function (err) {
        if (opts.isOptimisticUpdate) {
          // restore profiler tree data if batch update failed and is an optimistic update
          dispatch(getProfilerTreeData(workspaceUuid, { isRefresh: true }));
        }
        console.log(`Fail to put config data source list ${err}`);
      });
  };
}

export function updateTableDataProfilerConfig(
  workspaceUuid,
  dataSource,
  schema,
  table
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;

    const { uuid: tableUuid } = table;

    const withReconciledDataProfilerConfig = reconciledProfilerConfig(
      table.profilerConfig,
      table.status
    );

    return updateDataSourceTableProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      tableUuid,
      withReconciledDataProfilerConfig
    )
      .then(function (_newProfilerConfig) {
        getProfilerTableDataProfileTable(workspaceUuid, dataSource, tableUuid, {
          quiet: true,
        })(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Fail to put config data source list ${err}`);
      });
  };
}

export function resetProfilerConfigDataSource() {
  return (dispatch, getState) => {
    dispatch(setProfilerConfigCurrentDataSource(null));
    dispatch(setProfilerConfigDataSourceTableList({ loading: true, data: [] }));
    dispatch(setProfilerConfigDataSourceColumnList([]));
    dispatch(setProfilerConfigTableMetrics([]));
    dispatch(setProfilerPartitionSampleData({ loading: false, data: null }));
  };
}

export function getProfilerConfigTableCurrentDataSource(workspaceUuid, dataSourceUuid) {
  return (dispatch, getState) => {
    getDataSourcePromise(workspaceUuid, dataSourceUuid)
      .then(function (dataSource) {
        dispatch(setProfilerConfigTableCurrentDataSource(dataSource));
      })
      .catch(function (err) {
        console.log(`Fail to get config table data source object ${err}`);
      });
  };
}

export function getProfilerConfigTableCurrentTable(
  workspaceUuid,
  dataSource,
  tableUuid
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    getDataSourceTablePromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (response) {
        dispatch(setProfilerConfigTableCurrentTable(response));
      })
      .catch(function (err) {
        console.log(`Fail to get config table data source object ${err}`);
      });
  };
}

export function getProfilerConfigTableColumnList(
  workspaceUuid,
  dataSource,
  table,
  enableProfilerConfigCheck = false,
  opts = {}
) {
  const { isRefresh = false } = opts;
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    const { uuid: tableUuid } = table;

    if (enableProfilerConfigCheck && !table?.profilerConfig?.enabled) {
      dispatch(setProfilerConfigTableColumnList({ loading: false, data: [] }));
      return;
    }

    if (!isRefresh) {
      dispatch(setProfilerConfigTableColumnList({ loading: true, data: [] }));
    }
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (data) {
        dispatch(setProfilerConfigTableColumnList({ loading: false, data }));
      })
      .catch(function (err) {
        if (!isRefresh) {
          dispatch(setProfilerConfigTableColumnList({ loading: false, data: [] }));
        }
        console.log(`Fail to get config table column list ${err}`);
      });
  };
}

export function getProfilerConfigTableColumnListUsage(
  workspaceUuid,
  dataSource,
  table,
  columnUuids,
  opts
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    const { uuid: tableUuid } = table;

    dispatch(
      setProfilerConfigTableColumnListUsage({
        loading: true,
        data: {},
      })
    );

    return Promise.all(
      columnUuids.map((columnUuid) => {
        return getDataSourceTableColumnUsagePromise(
          workspaceUuid,
          dataSourceUuid,
          tableUuid,
          columnUuid,
          opts
        );
      })
    )
      .then(function (allResponses) {
        let totalMetrics = 0;
        let totalRules = 0;
        for (let currentResponse of allResponses) {
          for (let currentMetricUsage of currentResponse.metrics) {
            totalMetrics = totalMetrics + 1;
            for (let currentRule of currentMetricUsage.rules) {
              if (currentRule.isLive) {
                totalRules = totalRules + 1;
              }
            }
          }
        }
        const columnListUsage = {
          loading: false,
          data: {
            totalMetrics,
            totalRules,
          },
        };
        dispatch(setProfilerConfigTableColumnListUsage(columnListUsage));

        return columnListUsage;
      })
      .catch(function (err) {
        console.log(`Fail to get config column list usage for ${err}`);
      });
  };
}

export function updateProfilerConfigColumnList(
  workspaceUuid,
  dataSource,
  table,
  paramsList,
  refreshTree = false
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    const { uuid: tableUuid } = table;
    return Promise.all(
      paramsList.map(({ uuid: columnUuid, profilerConfig }) => {
        return updateDataSourceTableColumnProfilerConfigPromise(
          workspaceUuid,
          dataSourceUuid,
          tableUuid,
          columnUuid,
          profilerConfig
        );
      })
    )
      .then(function (allResponse) {
        const profilerConfigResponseMapper = {};
        for (let i = 0; i < paramsList.length; i++) {
          profilerConfigResponseMapper[paramsList[i].columnUuid] = allResponse[i];
        }

        const profilerConfigTableColumnList =
          getState().profilerReducer.profilerConfigTableColumnList;
        const newColumnList = [];
        for (let currentColumn of profilerConfigTableColumnList.data) {
          if (!profilerConfigResponseMapper[currentColumn.uuid]) {
            newColumnList.push(currentColumn);
          } else {
            newColumnList.push({
              ...currentColumn,
              profilerConfig: profilerConfigResponseMapper[currentColumn.uuid],
            });
          }
        }
        dispatch(
          setProfilerConfigTableColumnList({
            loading: false,
            data: newColumnList,
          })
        );

        if (refreshTree) {
          const profilerTreeData = getState().profilerReducer.profilerTreeData;
          const { workspaceUuid } = profilerTreeData;
          dispatch(getProfilerTreeData(workspaceUuid, { isRefresh: true }));
        }
      })
      .catch(function (err) {
        console.log(`Fail to update profiler columnList due to ${err}`);
      });
  };
}

export function updateProfilerConfigColumn(
  workspaceUuid,
  dataSource,
  table,
  columnUuid,
  columnProfilerConfig
) {
  return (dispatch, getState) => {
    const {
      metadata: { uuid: dataSourceUuid },
    } = dataSource;
    const { uuid: tableUuid } = table;
    updateDataSourceTableColumnProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      tableUuid,
      columnUuid,
      columnProfilerConfig
    )
      .then(function (response) {
        dispatch(getProfilerConfigTableColumnList(workspaceUuid, dataSource, table));
      })
      .catch(function (err) {
        console.log(`Fail to put config column ${err}`);
      });
  };
}

export function getProfilerConfigTableMetrics(
  workspaceUuid,
  dataSourceUuid,
  tableUuid
) {
  return (dispatch, getState) => {
    const queryOptions = {};
    if (dataSourceUuid) {
      queryOptions["dataSourceUuids"] = [dataSourceUuid];
    }

    if (tableUuid) {
      queryOptions["tableUuids"] = [tableUuid];
    }

    getKpiListPromise(workspaceUuid, queryOptions)
      .then((profilerConfigTableMetrics) => {
        dispatch(setProfilerConfigTableMetrics(profilerConfigTableMetrics));
      })
      .catch((err) => {
        console.log(`Fail to get metrics`);
      });
  };
}

export function resetProfilerConfigTable() {
  return (dispatch, getState) => {
    dispatch(setProfilerConfigTableCurrentDataSource(null));
    dispatch(setProfilerConfigTableCurrentTable(null));
    dispatch(setProfilerConfigTableColumnList({ loading: false, data: [] }));
    dispatch(setProfilerConfigTableMetrics([]));
  };
}

export function resetProfilerCurrentDataSourceSummary() {
  return (dispatch, getState) => {
    dispatch(setProfilerCurrentDataSourceSummary(null));
  };
}

export function getProfilerCurrentSchemaSummary(
  workspaceUuid,
  currentDataSource,
  currentSchema,
  opts = {}
) {
  return (dispatch, getState) => {
    const {
      isSummaryEnabled = false,
      isEventChartEnabled = false,
      isRefresh = false,
    } = opts;
    const endTime =
      typeof currentSchema.lastScannedTs === "number"
        ? fromUnixTime(Math.floor(currentSchema.lastScannedTs))
        : new Date();

    const startTime = subDays(endTime, 7);
    const startTimestamp = getUnixTime(startTime);
    const endTimestamp = getUnixTime(endTime);
    const allPromises = [];
    if (isSummaryEnabled) {
      allPromises.push(
        getDataSourceSchemaSummaryPromise(
          workspaceUuid,
          currentDataSource.metadata.uuid,
          currentSchema.uuid
        )
      );
    }

    if (isEventChartEnabled && currentSchema.profilerConfig.tableListChange?.enabled) {
      allPromises.push(
        getProfilerChartInfoPromise(
          workspaceUuid,
          [AutoMetricsType.TABLE_ACTIVITY],
          currentDataSource.metadata.uuid,
          currentSchema.uuid
        )
      );
    }

    if (allPromises.length === 0) {
      return;
    }

    !isRefresh &&
      dispatch(
        setProfilerCurrentSchemaSummary({
          loading: true,
          data: null,
        })
      );

    Promise.all(allPromises)
      .then((allResponses) => {
        let summary = null;
        let metricData = null;
        if (isSummaryEnabled) {
          summary = allResponses.shift();
        }

        if (isEventChartEnabled) {
          metricData = allResponses.shift();
        }

        dispatch(
          setProfilerCurrentSchemaSummary({
            loading: false,
            data: {
              schemaUuid: currentSchema.uuid,
              summary,
              chartData: metricData?.[AutoMetricsType.TABLE_ACTIVITY]?.[0] || null,
            },
            queryPeriod: {
              startTimestamp,
              endTimestamp,
            },
          })
        );
      })
      .catch(function (err) {
        dispatch(
          setProfilerCurrentSchemaSummary({
            loading: false,
            data: null,
            queryPeriod: null,
          })
        );
        console.log("Fail to get table summary info." + err);
      });
  };
}

export function resetProfilerCurrentSchemaSummary() {
  return (dispatch, getState) => {
    dispatch(
      setProfilerCurrentSchemaSummary({
        loading: true,
        data: null,
      })
    );
  };
}

function getRelatedRulesForMetricsPromise(workspaceUuid, metricsList) {
  return new Promise((resolvedPromise, rejectedPromise) => {
    if (metricsList.length === 0) {
      resolvedPromise({});
    } else {
      const metricUuids = metricsList.map(({ metadata: { uuid } }) => uuid);
      getRuleListPromise(workspaceUuid, { metricUuids })
        .then((autoRuleList) => {
          const autoMetricUuidToRulesMapper = {};
          for (let currentAutoRule of autoRuleList) {
            if (currentAutoRule.config.metrics.length === 0) {
              console.log(`Invalid auto rule ${JSON.stringify(currentAutoRule)}`);
              continue;
            }

            const metricUuid = currentAutoRule.config.metrics[0];
            if (autoMetricUuidToRulesMapper[metricUuid]) {
              autoMetricUuidToRulesMapper[metricUuid].push(currentAutoRule);
            } else {
              autoMetricUuidToRulesMapper[metricUuid] = [currentAutoRule];
            }
          }
          resolvedPromise(autoMetricUuidToRulesMapper);
        })
        .catch((err) => {
          rejectedPromise(err);
        });
    }
  });
}

function getProfilerChartInfoPromise(
  workspaceUuid,
  allowedAutoMetricTypes,
  dataSourceUuid,
  schemaUuid,
  tableUuid,
  columnUuid = "",
  viewedMonitorUuidByAutometricType = {}
) {
  return new Promise((resolve, reject) => {
    const queryOptions = {};

    if (columnUuid) {
      queryOptions["columnUuids"] = [columnUuid];
    } else {
      if (dataSourceUuid) {
        queryOptions["dataSourceUuids"] = [dataSourceUuid];
      }

      if (schemaUuid) {
        queryOptions["schemaUuid"] = [schemaUuid];
      }

      if (tableUuid) {
        queryOptions["tableUuids"] = [tableUuid];
      }
    }

    queryOptions["type"] = [MetricType.AUTO];

    getKpiListPromise(workspaceUuid, queryOptions)
      .then((autoMetrics) => {
        const currentTime = new Date();
        autoMetrics = autoMetrics.filter(
          (currentAutoMetric) =>
            currentAutoMetric.config.aggregation &&
            currentAutoMetric.config.aggregation.type &&
            allowedAutoMetricTypes.includes(currentAutoMetric.config.aggregation.type)
        );

        getRelatedRulesForMetricsPromise(workspaceUuid, autoMetrics)
          .then((autoMetricUuidToRulesMapper) => {
            /* For each auto metric type, we will generate the following information.
             * related auto metric
             * related rules
             * related metric data
             * related incidents.
             */

            const metricIdToAutoMetricMapper = {};
            const ruleIdToMetricsInfoIndexMapper = {};
            const activeMetricsInfo = [];
            const allMetricsRequestPromise = [];
            let currentActiveRuleUuids = [];
            let queryIncidentsStartTimestamp = getUnixTime(currentTime);
            let queryIncidentsEndTimestamp = 0;
            for (let currentAutoMetric of autoMetrics) {
              const currentMetricType = currentAutoMetric.config.aggregation.type;
              if (!allowedAutoMetricTypes.includes(currentMetricType)) {
                continue;
              }

              const currentAutoMetricUuid = currentAutoMetric.metadata.uuid;
              const currentRelatedRules =
                autoMetricUuidToRulesMapper[currentAutoMetricUuid] || [];

              const { startTimestamp, endTimestamp } = getDefaultProfilerDisplayPeriod(
                currentAutoMetric,
                currentTime
              );

              activeMetricsInfo.push({
                metric: currentAutoMetric,
                rules: currentRelatedRules,
                queryPeriod: {
                  startTimestamp,
                  endTimestamp,
                },
                incidents: [],
              });

              for (let currentRelatedRule of currentRelatedRules) {
                ruleIdToMetricsInfoIndexMapper[currentRelatedRule.metadata.uuid] =
                  activeMetricsInfo.length - 1;
                if (
                  currentRelatedRule.status &&
                  currentRelatedRule.status.training &&
                  currentRelatedRule.status.training.isTrained
                ) {
                  currentActiveRuleUuids.push(currentRelatedRule.metadata.uuid);
                }
              }

              queryIncidentsStartTimestamp = Math.min(
                startTimestamp,
                queryIncidentsStartTimestamp
              );
              queryIncidentsEndTimestamp = Math.max(
                endTimestamp,
                queryIncidentsEndTimestamp
              );

              const filterUuid =
                viewedMonitorUuidByAutometricType[currentMetricType] || "";
              const getDataPromise = filterUuid
                ? getFilterDataPromise
                : getSignalDataPromise;
              allMetricsRequestPromise.push(
                getDataPromise(workspaceUuid, {
                  dataSourceUuid,
                  tableUuid,
                  columnUuid,
                  metricUuid: currentAutoMetricUuid,
                  filterUuid,
                  autoMetricType: currentMetricType,
                  startTime: startTimestamp,
                  endTime: endTimestamp,
                  metric: currentAutoMetric,
                })
              );

              metricIdToAutoMetricMapper[currentAutoMetricUuid] = currentMetricType;
            }

            const getAllIncidentsPromise =
              currentActiveRuleUuids.length === 0
                ? Promise.resolve([])
                : getIncidentListPromise(
                    workspaceUuid,
                    {
                      startTime: queryIncidentsStartTimestamp,
                      endTime: queryIncidentsEndTimestamp,
                      filterUuids: currentActiveRuleUuids,
                      customerOptions: {
                        showRuleWithIncidentsOnly: true,
                      },
                    },
                    false
                  );

            Promise.all([getAllIncidentsPromise, ...allMetricsRequestPromise])
              .then(([getAllIncidentsResponse, ...allMetricsRequestResponse]) => {
                for (let allIncidentsPerRule of getAllIncidentsResponse) {
                  const { id: ruleId, incidents } = allIncidentsPerRule;
                  const metricInfoIndex = ruleIdToMetricsInfoIndexMapper[ruleId];
                  if (
                    typeof metricInfoIndex === "number" &&
                    activeMetricsInfo[metricInfoIndex]
                  ) {
                    if (!activeMetricsInfo[metricInfoIndex].incidents) {
                      activeMetricsInfo[metricInfoIndex].incidents = [];
                    }

                    for (let currentIncident of incidents) {
                      const { startTimestamp, endTimestamp } =
                        activeMetricsInfo[metricInfoIndex].queryPeriod || {};
                      if (startTimestamp && endTimestamp) {
                        if (
                          currentIncident.endTime < startTimestamp ||
                          currentIncident.startTime > endTimestamp
                        ) {
                          continue;
                        }
                      }

                      activeMetricsInfo[metricInfoIndex].incidents.push({
                        ...currentIncident,
                        main: true,
                        skipIndicatorText: true,
                      });
                    }
                  }
                }

                const chartInfo = {};
                for (let i = 0; i < activeMetricsInfo.length; i++) {
                  activeMetricsInfo[i].data = allMetricsRequestResponse[i];
                  const autoMetricType =
                    activeMetricsInfo[i].metric.config.aggregation.type;
                  if (!chartInfo[autoMetricType]) {
                    chartInfo[autoMetricType] = [];
                  }

                  chartInfo[autoMetricType].push(activeMetricsInfo[i]);
                }

                resolve(chartInfo);
              })
              .catch((err) => {
                reject(err);
                console.log(`Fail to get metrics data for ${err}`);
              });
          })
          .catch((err) => {
            reject(err);
            console.log(`Fail to get table summary info ${err}`);
          });
      })
      .catch((err) => {
        reject(err);
        console.log(`Fail to get profiler auto metric info due to ${err}`);
      });
  });
}

export function getProfilerCurrentTableOverviewSummary(
  workspaceUuid,
  currentDataSource,
  currentTable
) {
  return (dispatch, _getState) => {
    dispatch(
      setProfilerCurrentTableOverviewSummary({
        data: [],
        loading: true,
      })
    );

    getDataSourceTableSummaryPromise(
      workspaceUuid,
      currentDataSource.metadata.uuid,
      currentTable.uuid
    )
      .then((data) => {
        dispatch(
          setProfilerCurrentTableOverviewSummary({
            data,
            loading: false,
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to get current table overview summary due to ${err}`);
        dispatch(
          setProfilerCurrentTableOverviewSummary({
            data: [],
            loading: false,
          })
        );
      });
  };
}

export function getProfilerCurrentTableHealthData(
  workspaceUuid,
  currentDataSource,
  currentTable,
  opts = {}
) {
  const { isRefresh = false } = opts;
  return (dispatch, _getState) => {
    if (!isRefresh) {
      dispatch(
        setProfilerConfigTableHealthData({
          data: null,
          loading: true,
        })
      );
    }

    return getDataSourceTableHealthPromise(
      workspaceUuid,
      currentDataSource.metadata.uuid,
      currentTable.uuid
    )
      .then((data) => {
        dispatch(
          setProfilerConfigTableHealthData({
            data,
            loading: false,
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(`Fail to get current table health data due to ${err}`);
        if (!isRefresh) {
          dispatch(
            setProfilerConfigTableHealthData({
              data: null,
              loading: false,
            })
          );
        }
        return null;
      });
  };
}

export function getProfilerCurrentTableAutoMetricsData(
  workspaceUuid,
  currentDataSource,
  currentTable,
  opts = {}
) {
  const {
    isRefresh,
    isDataChartEnabled = false,
    viewedMonitorUuidByAutometricType = {},
  } = opts;
  return (dispatch, _getState) => {
    if (!isDataChartEnabled) {
      return;
    }

    if (!isRefresh) {
      dispatch(
        setProfilerCurrentTableAutoMetricsData({
          loading: true,
          data: null,
        })
      );
    }
    const endTime =
      typeof currentTable.lastScannedTs === "number"
        ? fromUnixTime(Math.floor(currentTable.lastScannedTs))
        : new Date();

    const startTime = subDays(endTime, 7);
    const startTimestamp = getUnixTime(startTime);
    const endTimestamp = getUnixTime(endTime);
    const allPromises = [];
    isDataChartEnabled &&
      allPromises.push(
        getProfilerChartInfoPromise(
          workspaceUuid,
          [AutoMetricsType.DATA_DELAY, AutoMetricsType.VOLUME],
          currentDataSource.metadata.uuid,
          "",
          currentTable.uuid,
          "",
          viewedMonitorUuidByAutometricType
        )
      );

    return Promise.all(allPromises)
      .then((allResponses) => {
        const chartInfo = isDataChartEnabled ? allResponses.shift() : null;
        dispatch(
          setProfilerCurrentTableAutoMetricsData({
            loading: false,
            data: {
              chartInfo,
              queryPeriod: {
                startTimestamp,
                endTimestamp,
              },
            },
          })
        );
      })
      .catch(function (err) {
        if (!isRefresh) {
          dispatch(
            setProfilerCurrentTableAutoMetricsData({
              loading: false,
              data: null,
              queryPeriod: null,
            })
          );
        }
        console.log("Fail to get table summary info." + err);
      });
  };
}

export function getProfilerCurrentTableMetadataMetricsData(
  workspaceUuid,
  currentDataSource,
  currentTable,
  opts = {}
) {
  const {
    isRefresh = false,
    isDataChartEnabled = true,
    isEventChartEnabled = true,
    viewedMonitorUuidByAutometricType = {},
  } = opts;
  return (dispatch, _getState) => {
    const metadataMetrics = currentTable.profilerConfig.metadataMetrics ?? {};
    const { rowCount, updateDelay, byteCount, schemaChange } = metadataMetrics;
    if (!rowCount && !updateDelay && !byteCount && !schemaChange?.enabled) {
      return Promise.resolve();
    }

    if (!isRefresh) {
      dispatch(
        setProfilerTableMetadataMetricsData({
          loading: true,
          data: null,
        })
      );
    }

    const endTime =
      typeof currentTable.lastScannedTs === "number"
        ? fromUnixTime(Math.floor(currentTable.lastScannedTs))
        : new Date();

    const startTime = subDays(endTime, 7);
    const startTimestamp = getUnixTime(startTime);
    const endTimestamp = getUnixTime(endTime);
    const allPromises = [];

    const autoMetricsTypes = [];
    if (isDataChartEnabled) {
      rowCount && autoMetricsTypes.push(AutoMetricsType.ROW_COUNT);
      byteCount && autoMetricsTypes.push(AutoMetricsType.BYTE_COUNT);
      updateDelay && autoMetricsTypes.push(AutoMetricsType.UPDATE_DELAY);
    }

    isEventChartEnabled &&
      isSchemaChangeEnabled(currentTable) &&
      autoMetricsTypes.push(AutoMetricsType.COLUMN_ACTIVITY);

    autoMetricsTypes.length > 0 &&
      allPromises.push(
        getProfilerChartInfoPromise(
          workspaceUuid,
          autoMetricsTypes,
          currentDataSource.metadata.uuid,
          "",
          currentTable.uuid,
          "",
          viewedMonitorUuidByAutometricType
        )
      );

    return Promise.all(allPromises)
      .then((allResponses) => {
        const chartInfo = isDataChartEnabled ? allResponses.shift() : null;

        dispatch(
          setProfilerTableMetadataMetricsData({
            loading: false,
            data: {
              chartInfo,
              eventList: [],
              eventIncidents: [],
              queryPeriod: {
                startTimestamp,
                endTimestamp,
              },
            },
          })
        );
      })
      .catch(function (err) {
        if (!isRefresh) {
          dispatch(
            setProfilerTableMetadataMetricsData({
              loading: false,
              data: null,
              queryPeriod: null,
            })
          );
        }
        console.log("Fail to get table metadata metrics data info." + err);
      });
  };
}

export function updateProfilerTableAutoMetricsChartData(workspaceUuid, opts = {}) {
  return (dispatch, getState) => {
    const { profilerCurrentTableAutoMetricsData } = getState().profilerReducer;

    const {
      metricUuid,
      filterUuid,
      autoMetricType,
      dataSourceUuid,
      tableUuid,
      metric,
    } = opts;

    const currentChartQueryPeriod =
      profilerCurrentTableAutoMetricsData.data?.chartInfo[autoMetricType]?.[0]
        ?.queryPeriod;
    if (!currentChartQueryPeriod) {
      console.log(`Fail to find query period for ${autoMetricType}`);
      return;
    }

    const queryObject = {
      startTime: currentChartQueryPeriod.startTimestamp,
      endTime: currentChartQueryPeriod.endTimestamp,
      metricUuid,
      filterUuid,
      sliceKeyValuePairs: [],
      autoMetricType,
      dataSourceUuid,
      tableUuid,
      columnUuid: null,
      metric,
    };

    let queryDataPromise;
    if (filterUuid) {
      queryDataPromise = getFilterDataPromise;
    } else {
      queryDataPromise = getSignalDataPromise;
    }

    return queryDataPromise(workspaceUuid, queryObject).then((metricData) => {
      const newObject = {
        ...profilerCurrentTableAutoMetricsData,
        data: {
          ...profilerCurrentTableAutoMetricsData.data,
          chartInfo: {
            ...profilerCurrentTableAutoMetricsData.data.chartInfo,
            [autoMetricType]: [
              {
                ...profilerCurrentTableAutoMetricsData.data.chartInfo[
                  autoMetricType
                ][0],
                data: metricData,
              },
            ],
          },
        },
      };
      dispatch(setProfilerCurrentTableAutoMetricsData(newObject));
    });
  };
}

export function updateProfilerTableMetadataMetricsChartData(workspaceUuid, opts = {}) {
  return (dispatch, getState) => {
    const { profilerTableMetadataMetricsData } = getState().profilerReducer;
    const {
      metricUuid,
      filterUuid,
      autoMetricType,
      dataSourceUuid,
      tableUuid,
      metric,
    } = opts;

    const currentChartQueryPeriod =
      profilerTableMetadataMetricsData.data?.chartInfo[autoMetricType]?.[0]
        ?.queryPeriod;
    if (!currentChartQueryPeriod) {
      console.log(`Fail to find query period for ${autoMetricType}`);
      return;
    }

    const queryObject = {
      startTime: currentChartQueryPeriod.startTimestamp,
      endTime: currentChartQueryPeriod.endTimestamp,
      metricUuid,
      filterUuid,
      sliceKeyValuePairs: [],
      autoMetricType,
      dataSourceUuid,
      tableUuid,
      columnUuid: null,
      metric,
    };

    let queryDataPromise;
    if (filterUuid) {
      queryDataPromise = getFilterDataPromise;
    } else {
      queryDataPromise = getSignalDataPromise;
    }

    return queryDataPromise(workspaceUuid, queryObject).then((metricData) => {
      const newObject = {
        ...profilerTableMetadataMetricsData,
        data: {
          ...profilerTableMetadataMetricsData.data,
          chartInfo: {
            ...profilerTableMetadataMetricsData.data.chartInfo,
            [autoMetricType]: [
              {
                ...profilerTableMetadataMetricsData.data.chartInfo[autoMetricType][0],
                data: metricData,
              },
            ],
          },
        },
      };
      dispatch(setProfilerTableMetadataMetricsData(newObject));
    });
  };
}

export function resetProfilerCurrentTableSummary() {
  return (dispatch, getState) => {
    dispatch(setProfilerCurrentTableAutoMetricsData({ loading: true, data: null }));
    dispatch(setProfilerTableMetadataMetricsData({ loading: true, data: null }));
  };
}

export function getProfilerColumnCurrentMetricsData(
  workspaceUuid,
  dataSource,
  table,
  column,
  opts = {}
) {
  const {
    isRefresh,
    isEventChartEnabled = false,
    isSummaryEnabled = false,
    isDataChartEnabled = false,
    viewedMonitorUuidByAutometricType = {},
  } = opts;
  return (dispatch, getState) => {
    if (!isSummaryEnabled && !isEventChartEnabled && !isDataChartEnabled) {
      return;
    }

    if (!isRefresh) {
      dispatch(
        setProfilerColumnCurrentMetricsData({
          loading: true,
          data: null,
        })
      );
    }

    const allPromises = [];
    if (isSummaryEnabled) {
      allPromises.push(
        getDataSourceTableColumnSummaryPromise(
          workspaceUuid,
          dataSource.metadata.uuid,
          table.uuid,
          column.uuid
        )
      );
    }

    const requestAutoMetricsTypes = [];
    if (isEventChartEnabled) {
      requestAutoMetricsTypes.push(AutoMetricsType.CATEGORY_ACTIVITY);
    }

    if (isDataChartEnabled) {
      requestAutoMetricsTypes.push(AutoMetricsType.MISSING_VALUE);
      const columnTypeCategory = getColumnTypeCategory(column);
      if (columnTypeCategory === TableColumnTypeCategory.STRING) {
        requestAutoMetricsTypes.push(AutoMetricsType.CATEGORICAL_DISTRIBUTION);
      } else if (columnTypeCategory === TableColumnTypeCategory.NUMERIC) {
        requestAutoMetricsTypes.push(AutoMetricsType.NUMERICAL_DISTRIBUTION);
      }
    }

    requestAutoMetricsTypes.length > 0 &&
      allPromises.push(
        getProfilerChartInfoPromise(
          workspaceUuid,
          requestAutoMetricsTypes,
          dataSource.metadata.uuid,
          "",
          table.uuid,
          column.uuid,
          viewedMonitorUuidByAutometricType
        )
      );

    Promise.all(allPromises)
      .then((allResponses) => {
        const summary = isSummaryEnabled ? allResponses.shift() : null;
        dispatch(
          setProfilerColumnCurrentMetricsData({
            loading: false,
            data: {
              summary,
              chartInfo: allResponses.shift() || null,
            },
          })
        );
      })
      .catch((err) => {
        if (!isRefresh) {
          dispatch(
            setProfilerColumnCurrentMetricsData({
              loading: false,
              data: null,
            })
          );
        }
        console.log(`Fail to get current column metrics data for ${err}`);
      });
  };
}

export function updateProfilerColumnChartData(workspaceUuid, opts = {}) {
  return (dispatch, getState) => {
    const { profilerColumnCurrentMetricsData } = getState().profilerReducer;
    const {
      data: { chartInfo },
    } = profilerColumnCurrentMetricsData;

    const {
      metricUuid,
      filterUuid,
      autoMetricType,
      dataSourceUuid,
      tableUuid,
      columnUuid,
      metric,
    } = opts;

    const { queryPeriod } = chartInfo[autoMetricType][0];
    const queryObject = {
      startTime: queryPeriod.startTimestamp,
      endTime: queryPeriod.endTimestamp,
      metricUuid,
      filterUuid,
      sliceKeyValuePairs: [],
      autoMetricType,
      dataSourceUuid,
      tableUuid,
      columnUuid,
      metric,
    };

    let queryDataPromise;
    if (filterUuid) {
      queryDataPromise = getFilterDataPromise;
    } else {
      queryDataPromise = getSignalDataPromise;
    }

    queryDataPromise(workspaceUuid, queryObject).then((metricData) => {
      const newObject = {
        ...profilerColumnCurrentMetricsData,
        data: {
          ...profilerColumnCurrentMetricsData.data,
          chartInfo: {
            ...profilerColumnCurrentMetricsData.data.chartInfo,
            [autoMetricType]: [
              {
                ...profilerColumnCurrentMetricsData.data.chartInfo[autoMetricType][0],
                data: metricData,
              },
            ],
          },
        },
      };
      dispatch(setProfilerColumnCurrentMetricsData(newObject));
    });
  };
}

export function resetProfilerColumn() {
  return (dispatch, getState) => {
    dispatch(setProfilerColumnCurrentMetricsData({ loading: false, data: null }));
  };
}

export function createSystemProfilerRule(workspaceUuid, ruleConfig) {
  return (dispatch, getState) => {
    return addRulePromise(workspaceUuid, ruleConfig)
      .then((newRule) => {
        console.log(
          `System profiler rule creation for ${JSON.stringify(newRule)} is successful.`
        );
      })
      .catch((err) => {
        console.log(`Fail to create system profiler rule due to ${err}`);
      });
  };
}

export function toggleSystemProfilerRuleStatus(workspaceUuid, ruleConfig) {
  return (dispatch, getState) => {
    ruleConfig.config.isLive = !ruleConfig.config.isLive;
    return updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig).catch(
      (err) => {
        console.log(`Fail to toggle system profiler rule status due to ${err}`);
      }
    );
  };
}

export function updateSystemProfileDataSourceRuleAlertingChannel(
  workspaceUuid,
  dataSourceUuid,
  dataSourceProfilerConfig
) {
  return (dispatch, getState) => {
    updateDataSourceProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      dataSourceProfilerConfig
    )
      .then(function (response) {
        const profilerDataSourceSummaryList =
          getState().profilerReducer.profilerTreeData;
        for (let currentDataSourceSummaryData of profilerDataSourceSummaryList.data) {
          if (currentDataSourceSummaryData.metadata.uuid === dataSourceUuid) {
            currentDataSourceSummaryData.config.profiler = dataSourceProfilerConfig;
            break;
          }
        }

        dispatch(
          setProfilerTreeData({
            ...profilerDataSourceSummaryList,
            data: [...profilerDataSourceSummaryList.data],
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to put config data source for alert channel ${err}`);
      });
  };
}

export function updateSystemProfileSchemaRuleAlertingChannel(
  workspaceUuid,
  dataSourceUuid,
  schemaUuid,
  schemaProfilerConfig
) {
  return (dispatch, getState) => {
    return updateDataSourceSchemaProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      schemaUuid,
      schemaProfilerConfig
    )
      .then(function (response) {
        const profilerDataSourceSummaryList =
          getState().profilerReducer.profilerTreeData;
        for (let currentDataSourceSummaryData of profilerDataSourceSummaryList.data) {
          if (currentDataSourceSummaryData.metadata.uuid === dataSourceUuid) {
            for (let currentSchema of currentDataSourceSummaryData.schemas) {
              if (currentSchema.uuid === schemaUuid) {
                currentSchema.profilerConfig = schemaProfilerConfig;
                break;
              }
            }
            break;
          }
        }

        dispatch(
          setProfilerTreeData({
            ...profilerDataSourceSummaryList,
            data: [...profilerDataSourceSummaryList.data],
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to put config schema for alert channel ${err}`);
      });
  };
}

export function updateSystemProfileDataSourceTableRuleAlertingChannel(
  workspaceUuid,
  dataSourceUuid,
  schemaUuid,
  tableUuid,
  tableProfilerConfig,
  tableStatus = null
) {
  const withReconciledDataProfilerConfig = reconciledProfilerConfig(
    tableProfilerConfig,
    tableStatus
  );
  return (dispatch, getState) => {
    return updateDataSourceTableProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      tableUuid,
      withReconciledDataProfilerConfig
    )
      .then(function (newTableProfilerConfig) {
        const profilerDataSourceSummaryList =
          getState().profilerReducer.profilerTreeData;
        for (let currentDataSourceSummaryData of profilerDataSourceSummaryList.data) {
          if (currentDataSourceSummaryData.metadata.uuid === dataSourceUuid) {
            for (let currentSchema of currentDataSourceSummaryData.schemas) {
              if (currentSchema.uuid === schemaUuid) {
                for (let currentTable of currentSchema.tables) {
                  if (currentTable.uuid === tableUuid) {
                    currentTable.profilerConfig = newTableProfilerConfig;
                    break;
                  }
                }
                break;
              }
            }
            break;
          }
        }
        dispatch(
          setProfilerTreeData({
            ...profilerDataSourceSummaryList,
            data: [...profilerDataSourceSummaryList.data],
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to put config data source for alert channel ${err}`);
      });
  };
}

export function updateSystemProfileTableRuleAlertingChannel(
  workspaceUuid,
  ruleConfig,
  newAlertingChannels
) {
  return (dispatch, getState) => {
    ruleConfig.config.alertConfig = newAlertingChannels;
    return updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig).catch(
      (err) => {
        console.log(`Fail to update system profiler rule alerting channels ${err}`);
      }
    );
  };
}

export function createColumnSystemProfilerRule(workspaceUuid, ruleConfig) {
  return (dispatch, getState) => {
    return addRulePromise(workspaceUuid, ruleConfig)
      .then((newRule) => {
        console.log(
          `System column profiler rule creation for ${JSON.stringify(
            ruleConfig
          )} is successful.`
        );
        const metricUuid = newRule.config.metrics[0];
        console.log(`created metricUuid ${metricUuid}`);
        const profilerColumnCurrentMetricsData =
          getState().profilerReducer.profilerColumnCurrentMetricsData.data;
        if (profilerColumnCurrentMetricsData) {
          let isFound = false;
          for (let autoMetricType in profilerColumnCurrentMetricsData) {
            if (
              profilerColumnCurrentMetricsData[autoMetricType] &&
              profilerColumnCurrentMetricsData[autoMetricType].length > 0
            ) {
              for (
                let i = 0;
                i < profilerColumnCurrentMetricsData[autoMetricType].length;
                i++
              ) {
                if (
                  profilerColumnCurrentMetricsData[autoMetricType][i].metric &&
                  profilerColumnCurrentMetricsData[autoMetricType][i].metric.metadata
                    .uuid === metricUuid
                ) {
                  const oldRules =
                    profilerColumnCurrentMetricsData[autoMetricType][i].rules || [];
                  profilerColumnCurrentMetricsData[autoMetricType][i].rules = [
                    ...oldRules,
                    newRule,
                  ];
                  isFound = true;
                  break;
                }
              }
            }

            if (isFound) {
              break;
            }
          }
        }
        dispatch(
          setProfilerColumnCurrentMetricsData({
            loading: false,
            data: { ...profilerColumnCurrentMetricsData },
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to create column system profiler rule`);
      });
  };
}

export function toggleColumnSystemProfilerRuleStatus(workspaceUuid, ruleConfig) {
  return (dispatch, getState) => {
    ruleConfig.config.isLive = !ruleConfig.config.isLive;
    updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig)
      .then(() => {
        const profilerColumnCurrentMetricsData =
          getState().profilerReducer.profilerColumnCurrentMetricsData;
        dispatch(
          setProfilerColumnCurrentMetricsData({
            loading: profilerColumnCurrentMetricsData.loading,
            data: { ...profilerColumnCurrentMetricsData.data },
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to update profiler column rule status ${err}`);
      });
  };
}

export function updateProfilerColumnRuleAlertingChannel(
  workspaceUuid,
  ruleConfig,
  newAlertingChannels
) {
  return (dispatch, getState) => {
    ruleConfig.config.alertConfig = newAlertingChannels;
    updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig)
      .then(() => {
        const profilerColumnCurrentMetricsData =
          getState().profilerReducer.profilerColumnCurrentMetricsData;
        dispatch(
          setProfilerColumnCurrentMetricsData({
            loading: profilerColumnCurrentMetricsData.loading,
            data: { ...profilerColumnCurrentMetricsData.data },
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to update profiler column alerting channels ${err}`);
      });
  };
}

export function updateProfilerColumnCategoryListChange(
  workspaceUuid,
  dataSourceUuid,
  schemaUuid,
  tableUuid,
  columnUuid,
  columnProfilerConfig
) {
  return (dispatch, getState) => {
    return updateDataSourceTableColumnProfilerConfigPromise(
      workspaceUuid,
      dataSourceUuid,
      tableUuid,
      columnUuid,
      columnProfilerConfig
    )
      .then(function (response) {
        const profilerDataSourceSummaryList =
          getState().profilerReducer.profilerTreeData;
        for (let currentDataSourceSummaryData of profilerDataSourceSummaryList.data) {
          if (currentDataSourceSummaryData.metadata.uuid === dataSourceUuid) {
            for (let currentSchema of currentDataSourceSummaryData.schemas) {
              if (currentSchema.uuid === schemaUuid) {
                for (let currentTable of currentSchema.tables) {
                  if (currentTable.uuid === tableUuid) {
                    for (let currentColumn of currentTable.columns) {
                      if (currentColumn.uuid === columnUuid) {
                        currentColumn.profilerConfig = columnProfilerConfig;
                        break;
                      }
                    }
                    break;
                  }
                }
                break;
              }
            }
            break;
          }
        }

        dispatch(
          setProfilerTreeData({
            ...profilerDataSourceSummaryList,
            data: [...profilerDataSourceSummaryList.data],
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to put config column ${err}`);
      });
  };
}

export function resetProfilerCurrentMetricMetricsData() {
  return (dispatch, _getState) => {
    dispatch(
      setProfilerCurrentMetricMetricsData({
        ...initialProfilerState.profilerCurrentMetricMetricsData,
      })
    );
  };
}

export function getProfilerCurrentMetricMetricsData(
  workspaceUuid,
  metric,
  queryOptions = {},
  opts = {}
) {
  return (dispatch, getState) => {
    if (!queryOptions.isRefresh) {
      dispatch(
        setProfilerCurrentMetricMetricsData({
          loading: true,
          data: { metric, rule: null, metricsData: [] },
        })
      );
    }
    const {
      startTimestamp,
      endTimestamp,
      sliceValues = [],
      monitorUuid,
      isSliced,
    } = queryOptions;

    getRelatedRulesForMetricsPromise(workspaceUuid, [metric])
      .then(function (relatedRulesMapper) {
        const currentRelatedRules = relatedRulesMapper[metric.metadata.uuid] || [];
        const currentActiveRuleUuids = currentRelatedRules
          .filter(
            (currentRelatedRule) =>
              currentRelatedRule.status &&
              currentRelatedRule.status.training &&
              currentRelatedRule.status.training.isTrained
          )
          .map((currentRelatedRule) => currentRelatedRule.metadata.uuid);

        const getMetricsDataFunction = monitorUuid
          ? getFilterDataPromise
          : getSignalDataPromise;
        const uuidKeyName = "metricUuid";
        const uuidValue = metric.metadata.uuid;

        const getAllIncidentsPromise =
          currentActiveRuleUuids.length === 0
            ? Promise.resolve([])
            : getIncidentListPromise(
                workspaceUuid,
                {
                  startTime: startTimestamp,
                  endTime: endTimestamp,
                  filterUuids: currentActiveRuleUuids,
                  customerOptions: {
                    showRuleWithIncidentsOnly: true,
                  },
                },
                false
              );

        const autoMetricType = getAutoMetricTypeFromKPI(metric);
        let allMetricsDataPromises;
        if (!isSliced) {
          allMetricsDataPromises = [
            getMetricsDataFunction(workspaceUuid, {
              [uuidKeyName]: uuidValue,
              startTime: startTimestamp,
              endTime: endTimestamp,
              filterUuid: monitorUuid,
              autoMetricType,
              metric,
            }),
          ];
        } else {
          allMetricsDataPromises = sliceValues.map((sliceValue) => {
            const sliceKeyValuePairs = [];
            for (let sliceKey in sliceValue) {
              sliceKeyValuePairs.push({
                key: sliceKey,
                value: sliceValue[sliceKey],
              });
            }
            return getMetricsDataFunction(workspaceUuid, {
              [uuidKeyName]: uuidValue,
              startTime: startTimestamp,
              endTime: endTimestamp,
              autoMetricType,
              metric,
              filterUuid: monitorUuid,
              sliceKeyValuePairs,
            });
          });
        }

        Promise.all([getAllIncidentsPromise, ...allMetricsDataPromises])
          .then(function ([allIncidents, ...allMetricsDataResponse]) {
            const lastRequestedMetricUuid =
              getState().profilerReducer.profilerCurrentMetricMetricsData?.data?.metric
                ?.metadata?.uuid;
            if (
              lastRequestedMetricUuid &&
              lastRequestedMetricUuid !== metric.metadata.uuid
            ) {
              console.log(
                `Received profiler metric data for non-selected metric (${metric.metadata.uuid}); discarding it.`
              );
              return;
            }

            if (
              opts.discardIfNoTriggeredStatus &&
              Boolean(metric?.status?.triggered) &&
              !metric?.status?.triggered?.triggerRunStatus
            ) {
              // This condition is used to avoid race conditions with metrics without triggerRunStatus
              console.log("Triggered metric without triggerRunStatus discarded.");
              return;
            }

            // All incidents, including from slices which were not requested. We need this to show
            // total incident counts per monitor.
            const allSliceIncidents = allIncidents.reduce(
              (allSlice, monitorIncidents) => {
                allSlice.push(...monitorIncidents.incidents);
                return allSlice;
              },
              []
            );

            const metricsData = [];
            for (let i = 0; i < allMetricsDataResponse.length; i++) {
              const targetSlice = sliceValues[i] || null;
              const groupedFilterIncidents = targetSlice
                ? allIncidents.filter(({ slice }) => {
                    for (let targetSliceKey in targetSlice) {
                      if (slice[targetSliceKey] !== targetSlice[targetSliceKey]) {
                        return false;
                      }
                    }

                    return true;
                  })
                : allIncidents;

              const normalizedIncidents = [];
              for (let currentGroupedFilterIncidents of groupedFilterIncidents) {
                for (let currentIncident of currentGroupedFilterIncidents.incidents) {
                  normalizedIncidents.push({
                    ...currentIncident,
                    main: true,
                    skipIndicatorText: true,
                  });
                }
              }

              metricsData.push({
                data: allMetricsDataResponse[i],
                incidents: normalizedIncidents,
                sliceValue: targetSlice,
              });
            }
            dispatch(
              setProfilerCurrentMetricMetricsData({
                loading: false,
                data: {
                  metric,
                  rules: currentRelatedRules,
                  incidents: allSliceIncidents,
                  metricsData,
                },
              })
            );
          })
          .catch(function (err) {
            if (!queryOptions.isRefresh) {
              dispatch(
                setProfilerCurrentMetricMetricsData({
                  loading: false,
                  data: {
                    metric,
                    rules: [],
                    incidents: [],
                    metricsData: [],
                  },
                })
              );
            }
            console.log(`Fail to get metrics data for custom metric for ${err}`);
          });
      })
      .catch(function (err) {
        console.log(`Fail to get related rules for custom metric for ${err}`);
        dispatch(
          setProfilerCurrentMetricMetricsData({
            loading: false,
            data: { metric, rules: [], incidents: [], metricsData: [] },
          })
        );
      });
  };
}

export function resetProfilerCurrentMetricSliceValue() {
  return (dispatch, _getState) => {
    dispatch(
      setProfilerCurrentMetricSliceValues({
        ...initialProfilerState.profilerCurrentMetricSliceValues,
      })
    );
  };
}

export function getProfilerCurrentMetricSliceValue(
  workspaceUuid,
  metric,
  queryOptions
) {
  return (dispatch, getState) => {
    if (
      queryOptions.useCache &&
      getState().profilerReducer.profilerCurrentMetricSliceValues.updatedAt > 0
    ) {
      return Promise.resolve(
        getState().profilerReducer.profilerCurrentMetricSliceValues.data
      );
    }
    if (!queryOptions.isRefresh) {
      dispatch(
        setProfilerCurrentMetricSliceValues({
          loading: true,
          metric,
          data: [],
          updatedAt: 0,
        })
      );
    }
    const queryDuration = {
      startTs: queryOptions.startTimestamp,
      endTs: queryOptions.endTimestamp,
    };
    if (
      metric.config.configType === MetricConfigType.METRIC_CONFIG &&
      metric.config.sliceValueSelections &&
      metric.config.sliceValueSelections.length > 0 &&
      metric.config.sliceValueSelections[0].include &&
      metric.config.sliceValueSelections[0].include.length > 0
    ) {
      const sliceByColumns = getSliceByColumns(metric);
      const data = metric.config.sliceValueSelections[0].include.map((sliceValue) => {
        return { [sliceByColumns[0]]: sliceValue };
      });
      dispatch(
        setProfilerCurrentMetricSliceValues({
          loading: false,
          metric,
          data,
          updatedAt: Date.now(),
        })
      );
      return Promise.resolve(data);
    } else if (
      metric.config.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG &&
      metric.config.targetTable.sliceValueSelections &&
      metric.config.targetTable.sliceValueSelections.length > 0 &&
      metric.config.targetTable.sliceValueSelections[0].include &&
      metric.config.targetTable.sliceValueSelections[0].include.length > 0
    ) {
      const data = metric.config.targetTable.sliceValueSelections[0].include.map(
        (sliceValue) => {
          return {
            0: sliceValue,
          };
        }
      );
      dispatch(
        setProfilerCurrentMetricSliceValues({
          loading: false,
          metric,
          data,
          updatedAt: Date.now(),
        })
      );
      return Promise.resolve(data);
    } else {
      return getKpiCachedSliceValueListPromise(
        workspaceUuid,
        {
          metricUuid: metric.metadata.uuid,
          ...queryDuration,
        },
        true,
        { withIncidents: queryOptions?.withIncidents }
      )
        .then((sliceValues) => {
          dispatch(
            setProfilerCurrentMetricSliceValues({
              loading: false,
              metric,
              data: sliceValues,
              updatedAt: Date.now(),
            })
          );
          return sliceValues;
        })
        .catch((err) => {
          console.log(`Fail to get slice values ${err}`);
          if (!queryOptions.isRefresh) {
            dispatch(
              setProfilerCurrentMetricSliceValues({
                loading: false,
                metric,
                data: [],
                updatedAt: 0,
              })
            );
          }
        });
    }
  };
}

export function createCustomMetricRule(workspaceUuid, ruleConfig) {
  return (dispatch, getState) => {
    return addRulePromise(workspaceUuid, ruleConfig)
      .then((newRule) => {
        console.log(
          `System custom metric rule creation for ${JSON.stringify(
            ruleConfig
          )} is successful.`
        );
        const customMetricCurrentMetricsData =
          getState().profilerReducer.profilerCurrentMetricMetricsData.data;
        if (customMetricCurrentMetricsData) {
          const oldRules = customMetricCurrentMetricsData.rules || [];
          customMetricCurrentMetricsData.rules = [...oldRules, newRule];
        }
        dispatch(
          setProfilerCurrentMetricMetricsData({
            loading: false,
            data: { ...customMetricCurrentMetricsData },
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to create column system profiler rule`);
      });
  };
}

export function toggleCustomMetricRuleStatus(workspaceUuid, ruleConfig) {
  return (dispatch, getState) => {
    ruleConfig.config.isLive = !ruleConfig.config.isLive;
    updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig)
      .then(() => {
        const profilerCurrentMetricMetricsData =
          getState().profilerReducer.profilerCurrentMetricMetricsData;
        dispatch(
          setProfilerCurrentMetricMetricsData({
            loading: profilerCurrentMetricMetricsData.loading,
            data: { ...profilerCurrentMetricMetricsData.data },
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to update profiler column rule status ${err}`);
      });
  };
}

export function updateCustomMetricRuleAlertingChannel(
  workspaceUuid,
  ruleConfig,
  newAlertingChannels
) {
  return (dispatch, getState) => {
    ruleConfig.config.alertConfig = newAlertingChannels;
    updateRulePromise(workspaceUuid, ruleConfig.metadata.uuid, ruleConfig)
      .then(() => {
        const profilerCurrentMetricMetricsData =
          getState().profilerReducer.profilerCurrentMetricMetricsData;
        dispatch(
          setProfilerCurrentMetricMetricsData({
            loading: profilerCurrentMetricMetricsData.loading,
            data: { ...profilerCurrentMetricMetricsData.data },
          })
        );
      })
      .catch((err) => {
        console.log(`Fail to update profiler column alerting channels ${err}`);
      });
  };
}

export function resetProfilerCurrentMetricSummary() {
  return (dispatch, getState) => {
    dispatch(
      setProfilerCurrentMetricMetricsData({
        loading: false,
        data: { metric: null, rules: [], metricsData: [] },
      })
    );
    dispatch(setProfilerCurrentMetricSliceValues({ loading: true, data: [] }));
  };
}

export function getProfilerCurrentIncidentList(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    dispatch(setProfilerCurrentIncidentList({ loading: true, data: [] }));
    getIncidentListPromise(workspaceUuid, queryObject)
      .then(function (incidentList) {
        dispatch(
          setProfilerCurrentIncidentList({ loading: false, data: incidentList })
        );
      })
      .catch(function (err) {
        dispatch(setProfilerCurrentIncidentList({ loading: false, data: [] }));
        console.log(`Fail to get incident list ${err}`);
      });
  };
}

export function getProfilerCurrentIncidentMetricData(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    dispatch(setProfilerCurrentIncidentMetricsData({ loading: true, data: null }));
    getSignalDataPromise(workspaceUuid, queryObject)
      .then(function (metricsData) {
        dispatch(
          setProfilerCurrentIncidentMetricsData({
            loading: false,
            data: metricsData,
          })
        );
      })
      .catch(function (err) {
        dispatch(setProfilerCurrentIncidentMetricsData({ loading: false, data: null }));
        console.log(`Fail to get profiler incident metrics data ${err}`);
      });
  };
}

export function updateProfilerIncidentStatus(workspaceUuid, incidentList, newStatus) {
  return (dispatch, getState) => {
    const incidentUuids = incidentList.map(({ uuid }) => uuid);

    const allRequests = incidentUuids.map((uuid) =>
      updateIncidentStatusPromise(workspaceUuid, uuid, newStatus)
    );

    Promise.all(allRequests)
      .then(function () {
        const currentGroupIncidentListData =
          getState().profilerReducer.profilerCurrentIncidentList.data;
        let newGroupIncidentListData = [];
        let isFound = false;

        for (let currentGroupIncidentList of currentGroupIncidentListData) {
          for (let i = 0; i < currentGroupIncidentList.incidents.length; i++) {
            if (incidentUuids.includes(currentGroupIncidentList.incidents[i].uuid)) {
              currentGroupIncidentList.incidents[i] = {
                ...currentGroupIncidentList.incidents[i],
                status: newStatus,
              };
              isFound = true;
            }
          }

          if (isFound) {
            newGroupIncidentListData.push({
              ...currentGroupIncidentList,
              incidents: [...currentGroupIncidentList.incidents],
            });
          } else {
            newGroupIncidentListData.push(currentGroupIncidentList);
          }
        }

        isFound &&
          dispatch(
            setProfilerCurrentIncidentList({
              loading: false,
              data: newGroupIncidentListData,
            })
          );
      })
      .catch(function (err) {
        console.log("Fail to update incident status.");
      });
  };
}

export function getProfilerCurrentMonitorObject(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    const { sourceUuid, schemaUuid, tableUuid, columnUuid } = queryObject;
    const queryOptions = {};
    sourceUuid && (queryOptions["dataSourceUuids"] = [sourceUuid]);
    schemaUuid && (queryOptions["schemaUuids"] = [schemaUuid]);
    tableUuid && (queryOptions["tableUuids"] = [tableUuid]);
    columnUuid && (queryOptions["columnUuids"] = [columnUuid]);
    let usagePromiseObject;
    if (columnUuid && tableUuid && schemaUuid && sourceUuid) {
      dispatch(
        setProfilerCurrentMonitorObject({ loading: true, data: null, uuid: columnUuid })
      );
      usagePromiseObject = getDataSourceTableColumnUsagePromise(
        workspaceUuid,
        sourceUuid,
        tableUuid,
        columnUuid
      );
    } else if (sourceUuid && tableUuid && schemaUuid) {
      dispatch(
        setProfilerCurrentMonitorObject({ loading: true, data: null, uuid: tableUuid })
      );
      usagePromiseObject = getDataSourceTableUsagePromise(
        workspaceUuid,
        sourceUuid,
        tableUuid
      );
    } else if (sourceUuid && schemaUuid) {
      dispatch(
        setProfilerCurrentMonitorObject({ loading: true, data: null, uuid: schemaUuid })
      );
      usagePromiseObject = getDataSourceSchemaUsagePromise(
        workspaceUuid,
        sourceUuid,
        schemaUuid
      );
    } else if (sourceUuid) {
      dispatch(
        setProfilerCurrentMonitorObject({ loading: true, data: null, uuid: sourceUuid })
      );
      usagePromiseObject = getDataSourceUsagePromise(workspaceUuid, sourceUuid);
    } else {
      console.log(`Invalid params for ${JSON.stringify(queryObject)}`);
      return;
    }

    Promise.all([usagePromiseObject, getKpiListPromise(workspaceUuid, queryOptions)])
      .then(([currentObjectUsage, allMetrics]) => {
        if (
          currentObjectUsage.uuid !==
          getState().profilerReducer.profilerCurrentMonitorObject.uuid
        ) {
          console.warn(
            `Received usage data for non-selected node ${currentObjectUsage.uuid}; discarding it.`
          );
          return;
        }
        const allMetricsMapper = {};
        allMetrics.forEach(
          (currentMetric) =>
            (allMetricsMapper[currentMetric.metadata.uuid] = currentMetric)
        );
        currentObjectUsage.metrics = currentObjectUsage.metrics.filter(
          (currentMetric) => allMetricsMapper[currentMetric.uuid]
        );

        dispatch(
          setProfilerCurrentMonitorObject({
            loading: false,
            data: {
              usage: currentObjectUsage,
              refs: allMetrics,
            },
          })
        );
      })
      .catch((err) => {
        dispatch(setProfilerCurrentMonitorObject({ loading: true, data: null }));
        console.log(
          `Fail to get current monitor object for ${JSON.stringify(
            queryObject
          )} due to ${err}`
        );
      });
  };
}

function updateBatchProfileConfigPromise(workspaceUuid, profilerConfigUpdates) {
  if (!profilerConfigUpdates || profilerConfigUpdates.length === 0) {
    return Promise.resolve({ data: [] });
  }

  const profilerConfigUpdatesPayloads = profilerConfigUpdates.map(
    (profilerConfigUpdate) => {
      const {
        type,
        dataSourceUuid,
        schemaUuid,
        tableUuid,
        columnUuid,
        profilerConfig,
      } = profilerConfigUpdate;
      let url = "";
      // @Todo use utility function to generate uri
      if (type === AutoMetricsType.TABLE_ACTIVITY) {
        url = `/api/v1/ws/${workspaceUuid}/sources/${dataSourceUuid}/profile/schemas/${schemaUuid}/profiler-config`;
      } else if (type === AutoMetricsType.COLUMN_ACTIVITY) {
        url = `/api/v1/ws/${workspaceUuid}/sources/${dataSourceUuid}/profile/tables/${tableUuid}/profiler-config`;
      } else if (type === AutoMetricsType.CATEGORY_ACTIVITY) {
        url = `/api/v1/ws/${workspaceUuid}/sources/${dataSourceUuid}/profile/tables/${tableUuid}/columns/${columnUuid}/profiler-config`;
      } else {
        console.log(`Unknown type ${type}`);
      }

      return {
        relative_url: url,
        method: "put",
        data: profilerConfig,
      };
    }
  );

  return batchAPIRequestPromise(profilerConfigUpdatesPayloads);
}

function updateTreeAfterBatchProfilerConfigUpdate(dispatch, getState) {
  return (profilerConfigUpdates, profilerConfigBatchResponses) => {
    if (
      profilerConfigBatchResponses &&
      profilerConfigBatchResponses.data &&
      profilerConfigBatchResponses.data.length > 0
    ) {
      const uuidToUpdateProfilerConfigMapper = {};
      for (let i = 0; i < profilerConfigUpdates.length; i++) {
        if (profilerConfigBatchResponses.data[i].status_code === 200) {
          let uuid;
          let type = profilerConfigUpdates[i].type;
          if (type === AutoMetricsType.TABLE_ACTIVITY) {
            uuid = profilerConfigUpdates[i].schemaUuid;
          } else if (type === AutoMetricsType.COLUMN_ACTIVITY) {
            uuid = profilerConfigUpdates[i].tableUuid;
          } else if (type === AutoMetricsType.CATEGORY_ACTIVITY) {
            uuid = profilerConfigUpdates[i].columnUuid;
          } else {
            continue;
          }

          uuidToUpdateProfilerConfigMapper[uuid] =
            profilerConfigBatchResponses.data[i].data;
        }
      }

      const profilerDataSourceSummaryList = getState().profilerReducer.profilerTreeData;
      for (let currentDataSourceSummaryData of profilerDataSourceSummaryList.data) {
        // All the data source uuid should be the same
        if (
          uuidToUpdateProfilerConfigMapper[currentDataSourceSummaryData.metadata.uuid]
        ) {
          currentDataSourceSummaryData.config.profiler =
            uuidToUpdateProfilerConfigMapper[
              currentDataSourceSummaryData.metadata.uuid
            ];
        }

        for (let currentSchema of currentDataSourceSummaryData.schemas) {
          if (uuidToUpdateProfilerConfigMapper[currentSchema.uuid]) {
            currentSchema.profilerConfig =
              uuidToUpdateProfilerConfigMapper[currentSchema.uuid];
          }

          for (let currentTable of currentSchema.tables) {
            if (uuidToUpdateProfilerConfigMapper[currentTable.uuid]) {
              currentTable.profilerConfig =
                uuidToUpdateProfilerConfigMapper[currentTable.uuid];
            }

            for (let currentColumn of currentTable.columns) {
              if (uuidToUpdateProfilerConfigMapper[currentColumn.uuid]) {
                currentColumn.profilerConfig =
                  uuidToUpdateProfilerConfigMapper[currentColumn.uuid];
              }
            }
          }
        }
      }
      dispatch(
        setProfilerTreeData({
          ...profilerDataSourceSummaryList,
          data: [...profilerDataSourceSummaryList.data],
        })
      );
    }
  };
}

export function monitorAllForDataSource(workspaceUuid, configObjects) {
  return (dispatch, getState) => {
    const { rules = [], profilerConfigUpdates = [] } = configObjects;
    return Promise.all([
      addRulesPromise(workspaceUuid, rules),
      updateBatchProfileConfigPromise(workspaceUuid, profilerConfigUpdates),
    ])
      .then(([_, profilerConfigBatchResponses]) => {
        updateTreeAfterBatchProfilerConfigUpdate(dispatch, getState)(
          profilerConfigUpdates,
          profilerConfigBatchResponses
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function monitorAllForSchema(workspaceUuid, configObjects) {
  return (dispatch, getState) => {
    // It happened to be the same as monitorAllForDataSource. Once we start to show more data on datasource summary page. It would be different.
    const { rules = [], profilerConfigUpdates = [] } = configObjects;
    return Promise.all([
      addRulesPromise(workspaceUuid, rules),
      updateBatchProfileConfigPromise(workspaceUuid, profilerConfigUpdates),
    ])
      .then(([_, profilerConfigBatchResponses]) => {
        updateTreeAfterBatchProfilerConfigUpdate(dispatch, getState)(
          profilerConfigUpdates,
          profilerConfigBatchResponses
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function updateTableMetricsDataRules(
  profilerCurrentTableMetricsData,
  metricUuidToRuleMapper
) {
  if (profilerCurrentTableMetricsData?.chartInfo) {
    for (let autoMetricType in profilerCurrentTableMetricsData.chartInfo) {
      if (
        profilerCurrentTableMetricsData.chartInfo[autoMetricType] &&
        profilerCurrentTableMetricsData.chartInfo[autoMetricType].length > 0
      ) {
        for (
          let i = 0;
          i < profilerCurrentTableMetricsData.chartInfo[autoMetricType].length;
          i++
        ) {
          const currentMetricUuid =
            profilerCurrentTableMetricsData.chartInfo[autoMetricType][i].metric &&
            profilerCurrentTableMetricsData.chartInfo[autoMetricType][i].metric.metadata
              .uuid
              ? profilerCurrentTableMetricsData.chartInfo[autoMetricType][i].metric
                  .metadata.uuid
              : "";
          if (
            currentMetricUuid &&
            metricUuidToRuleMapper[currentMetricUuid] &&
            metricUuidToRuleMapper[currentMetricUuid].length > 0
          ) {
            const oldRules =
              profilerCurrentTableMetricsData.chartInfo[autoMetricType][i].rules || [];
            profilerCurrentTableMetricsData.chartInfo[autoMetricType][i].rules = [
              ...oldRules,
              ...metricUuidToRuleMapper[currentMetricUuid],
            ];
          }
        }
      }
    }
    return profilerCurrentTableMetricsData;
  }
}

export function monitorAllForTable(workspaceUuid, configObjects) {
  return (dispatch, getState) => {
    const { rules = [], profilerConfigUpdates = [] } = configObjects;

    return Promise.all([
      addRulesPromise(workspaceUuid, rules),
      updateBatchProfileConfigPromise(workspaceUuid, profilerConfigUpdates),
    ])
      .then(([newRuleBatchResponses, profilerConfigBatchResponses]) => {
        // Handle batch new rule creation
        if (
          newRuleBatchResponses &&
          newRuleBatchResponses.data &&
          newRuleBatchResponses.data.length > 0
        ) {
          const metricUuidToRuleMapper = {};
          for (let newRuleBatchResponse of newRuleBatchResponses.data) {
            if (newRuleBatchResponse.status_code === 201) {
              const newCreatedRule = newRuleBatchResponse.data;
              metricUuidToRuleMapper[newCreatedRule.config.metrics[0]] = [
                newCreatedRule,
              ];
            } else {
              console.log(
                `Failed to create one rule in batch process due to ${newRuleBatchResponse.status}`
              );
            }
          }

          dispatch(
            setProfilerTableMetadataMetricsData({
              loading: false,
              data: {
                ...updateTableMetricsDataRules(
                  getState().profilerReducer.profilerTableMetadataMetricsData.data,
                  metricUuidToRuleMapper
                ),
              },
            })
          );
          dispatch(
            setProfilerCurrentTableAutoMetricsData({
              loading: false,
              data: {
                ...updateTableMetricsDataRules(
                  getState().profilerReducer.profilerCurrentTableAutoMetricsData.data,
                  metricUuidToRuleMapper
                ),
              },
            })
          );
        }

        // Handle the schema change
        updateTreeAfterBatchProfilerConfigUpdate(dispatch, getState)(
          profilerConfigUpdates,
          profilerConfigBatchResponses
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function monitorAllForColumn(workspaceUuid, configObjects) {
  return (dispatch, getState) => {
    const { rules = [] } = configObjects;
    return addRulesPromise(workspaceUuid, rules)
      .then((newRuleBatchResponses) => {
        // Handle batch new rule creation
        if (
          newRuleBatchResponses &&
          newRuleBatchResponses.data &&
          newRuleBatchResponses.data.length > 0
        ) {
          const metricUuidToRuleMapper = {};
          for (let newRuleBatchResponse of newRuleBatchResponses.data) {
            if (newRuleBatchResponse.status_code === 201) {
              const newCreatedRule = newRuleBatchResponse.data;
              metricUuidToRuleMapper[newCreatedRule.config.metrics[0]] = [
                newCreatedRule,
              ];
            } else {
              console.log(
                `Failed to create one rule in batch process due to ${newRuleBatchResponse.status}`
              );
            }
          }

          const profilerColumnCurrentMetricsData =
            getState().profilerReducer.profilerColumnCurrentMetricsData.data;
          if (profilerColumnCurrentMetricsData) {
            for (let autoMetricType in profilerColumnCurrentMetricsData) {
              if (
                profilerColumnCurrentMetricsData[autoMetricType] &&
                profilerColumnCurrentMetricsData[autoMetricType].length > 0
              ) {
                for (
                  let i = 0;
                  i < profilerColumnCurrentMetricsData[autoMetricType].length;
                  i++
                ) {
                  const currentMetricUuid =
                    profilerColumnCurrentMetricsData[autoMetricType][i].metric &&
                    profilerColumnCurrentMetricsData[autoMetricType][i].metric.metadata
                      .uuid
                      ? profilerColumnCurrentMetricsData[autoMetricType][i].metric
                          .metadata.uuid
                      : "";
                  if (
                    currentMetricUuid &&
                    metricUuidToRuleMapper[currentMetricUuid] &&
                    metricUuidToRuleMapper[currentMetricUuid].length > 0
                  ) {
                    const oldRules =
                      profilerColumnCurrentMetricsData[autoMetricType][i].rules || [];
                    profilerColumnCurrentMetricsData[autoMetricType][i].rules = [
                      ...oldRules,
                      ...metricUuidToRuleMapper[currentMetricUuid],
                    ];
                  }
                }
              }
            }
          }
          dispatch(
            setProfilerColumnCurrentMetricsData({
              loading: false,
              data: { ...profilerColumnCurrentMetricsData },
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function createProfilerCustomMetric(workspaceUuid, newKPI, isFreshTree) {
  return (dispatch, getState) => {
    addKpiPromise(workspaceUuid, newKPI)
      .then((createdMetric) => {
        const profilerConfigTableMetrics =
          getState().profilerReducer.profilerConfigTableMetrics;
        dispatch(
          setProfilerConfigTableMetrics([...profilerConfigTableMetrics, createdMetric])
        );
        dispatch(getKpiList(workspaceUuid, { isForceRefresh: true }));

        if (isFreshTree) {
          const profilerTreeData = getState().profilerReducer.profilerTreeData;
          const { workspaceUuid } = profilerTreeData;
          getProfilerTreeData(workspaceUuid)(dispatch, getState);
        }
      })
      .catch((err) => {
        console.log(`Fail to create custom metric due to ${err}`);
      });
  };
}

export function getProfilerPartitionSampleData(
  workspaceUuid,
  dataSourceUuid,
  schemaName,
  tableName
) {
  return (dispatch) => {
    dispatch(setProfilerPartitionSampleData({ loading: true, data: null }));
    getDataSourcePartitionSampleDataPromise(workspaceUuid, dataSourceUuid, {
      schemaName,
      tableName,
    })
      .then((partitionSampleData) => {
        dispatch(
          setProfilerPartitionSampleData({
            loading: false,
            data: partitionSampleData,
          })
        );
      })
      .catch((err) => {
        dispatch(setProfilerPartitionSampleData({ loading: false, data: null }));
        console.log(
          `Fail to get partition sample data for ${dataSourceUuid} due to ${err}`
        );
      });
  };
}

export function getProfilerTableListPartitionSampleData(
  workspaceUuid,
  dataSourceUuid,
  tableList
) {
  return (dispatch) => {
    if (tableList.length === 0) {
      console.log("Early return due to empty table list");
      return;
    }

    const defaultData = {};
    tableList.forEach(({ uuid }) => (defaultData[uuid] = null));
    dispatch(
      setProfilerTableListPartitionSampleData({
        loading: true,
        data: defaultData,
      })
    );
    Promise.all(
      tableList.map(({ tableName, schemaName }) =>
        getDataSourcePartitionSampleDataPromise(workspaceUuid, dataSourceUuid, {
          schemaName,
          tableName,
        })
      )
    )
      .then(function (allSampleData) {
        const data = {};
        for (let i = 0; i < tableList.length; i++) {
          data[tableList[i].uuid] = allSampleData[i];
        }
        dispatch(setProfilerTableListPartitionSampleData({ loading: false, data }));
      })
      .catch(function (err) {
        console.log(
          `Fail to get all partition sample data for table list due to ${err}`
        );
        dispatch(
          setProfilerTableListPartitionSampleData({
            loading: false,
            data: defaultData,
          })
        );
      });
  };
}

export function triggerProfilerMetrics(
  workspaceUuid,
  dataSourceUuid,
  payload,
  isRefreshTreeData = true
) {
  return (dispatch, getState) => {
    return triggerDataSourceMetricPromise(workspaceUuid, dataSourceUuid, payload)
      .then(function (response) {
        if (isRefreshTreeData) {
          const metricUuids = response["metric_uuids"];
          Promise.all(
            metricUuids.map((metricUuid) => getKpiPromise(workspaceUuid, metricUuid))
          ).then((metricList) => {
            const metricMapper = indexBy(metricList, (metric) => metric.metadata.uuid);
            const profilerCustomMetricTreeData =
              getState().profilerReducer.profilerCustomMetricTreeData;
            const newData = profilerCustomMetricTreeData.data.map(
              (metric) => metricMapper[metric.metadata.uuid] || metric
            );

            dispatch(
              setProfilerCustomMetricTreeData({
                ...profilerCustomMetricTreeData,
                data: newData,
              })
            );
          });
        }
        console.log("Triggering metrics is successful");
      })
      .catch(function (err) {
        console.log(`Fail to trigger metrics for ${JSON.stringify(payload)}`);
      });
  };
}

export function getProfilerColumnDataProfileData(
  workspaceUuid,
  dataSource,
  table,
  column,
  opts = {}
) {
  return (dispatch, getState) => {
    const { quiet = false } = opts;
    if (!quiet) {
      dispatch(setProfilerColumnDataProfileData({ loading: true, data: null }));
    }
    getDataSourceTableColumnDataProfilePromise(
      workspaceUuid,
      dataSource.metadata.uuid,
      table.uuid,
      column.uuid
    )
      .then(function (data) {
        dispatch(setProfilerColumnDataProfileData({ loading: false, data }));
      })
      .catch(function (err) {
        if (!quiet) {
          dispatch(setProfilerColumnDataProfileData({ loading: false, data: null }));
        }
        console.log(`Fail to get column profile data for ${err}`);
      });
  };
}

export function getProfilerTableColumnListDataProfileData(
  workspaceUuid,
  dataSource,
  table
) {
  return (dispatch, getState) => {
    dispatch(setProfilerTableDataProfileData({ loading: true, data: null }));
    getDataSourceTableColumnListDataProfilePromise(
      workspaceUuid,
      dataSource.metadata.uuid,
      table.uuid
    )
      .then(function (data) {
        dispatch(setProfilerTableDataProfileData({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setProfilerTableDataProfileData({ loading: false, data: null }));
        console.log(`Fail to get table profile data for ${err}`);
      });
  };
}

export function getProfilerTableDataProfileTable(
  workspaceUuid,
  dataSource,
  tableUuid,
  opts = {}
) {
  const { quiet = false } = opts;
  return (dispatch, getState) => {
    if (!quiet) {
      dispatch(setProfilerTableDataProfileTable({ loading: true, data: null }));
    }
    return getDataSourceTablePromise(workspaceUuid, dataSource.metadata.uuid, tableUuid)
      .then(function (data) {
        dispatch(setProfilerTableDataProfileTable({ loading: false, data }));
        return data;
      })
      .catch(function (err) {
        if (!quiet) {
          dispatch(setProfilerTableDataProfileTable({ loading: false, data: null }));
        }
        console.log(
          `Fail to get profiler table data profile table object for ${tableUuid} with ${err}`
        );
      });
  };
}

export function resetProfilerTableDataProfileTable() {
  return (dispatch, getState) => {
    dispatch(setProfilerTableDataProfileTable({ loading: false, data: null }));
  };
}

export function getProfilerTableDataProfileTableDataProfile(
  workspaceUuid,
  dataSource,
  table,
  opts = {}
) {
  return (dispatch, getState) => {
    const { quiet = false } = opts;
    if (!quiet) {
      dispatch(
        setProfilerTableDataProfileTableDataProfile({ loading: true, data: null })
      );
    }
    getDataSourceTableDataProfilePromise(
      workspaceUuid,
      dataSource.metadata.uuid,
      table.uuid
    )
      .then(function (data) {
        dispatch(setProfilerTableDataProfileTableDataProfile({ loading: false, data }));
      })
      .catch(function (err) {
        if (!quiet) {
          dispatch(
            setProfilerTableDataProfileTableDataProfile({ loading: false, data: null })
          );
        }
        console.log(
          `Fail to get profiler table data profile information for ${table.uuid} with ${err}`
        );
      });
  };
}

export function createProfilerVirtualTable(
  workspaceUuid,
  dataSource,
  payload,
  isRefreshTreeData = false
) {
  return (dispatch, getState) => {
    return createDataSourceTablePromise(
      workspaceUuid,
      dataSource.metadata.uuid,
      payload
    )
      .then(function (data) {
        console.log(`virtual table is created`);
        if (isRefreshTreeData) {
          const profilerTreeData = getState().profilerReducer.profilerTreeData;
          const { workspaceUuid } = profilerTreeData;
          dispatch(getProfilerTreeData(workspaceUuid, { isRefresh: true }));
        }
        return Promise.resolve(data);
      })
      .catch(function (err) {
        console.log(`Fail to create virtual table due to ${err}`);
        return Promise.reject(err);
      });
  };
}

export function deleteProfilerVirtualTables(workspaceUuid, dataSource, tables) {
  return (dispatch, getState) => {
    if (tables.length === 0) {
      return;
    }

    const tableUuids = tables.map(({ uuid }) => uuid);
    return Promise.all(
      tableUuids.map((uuid) =>
        deleteDataSourceTablePromise(
          workspaceUuid,
          dataSource.metadata.uuid,
          uuid,
          true
        )
      )
    )
      .then(function (data) {
        console.log(`virtual table ${tableUuids.join(",")} is delete`);
        const profilerTreeData = getState().profilerReducer.profilerTreeData;
        const { workspaceUuid } = profilerTreeData;
        dispatch(getProfilerTreeData(workspaceUuid, { isRefresh: true }));
      })
      .catch(function (err) {
        console.log(
          `Fail to delete virtual table ${tableUuids.join(",")} due to ${err}`
        );
      });
  };
}

export function getProfilerConfigTableBlobList(workspaceUuid, dataSource, payload) {
  return (dispatch, getState) => {
    const cancelToken = createGenericCancelToken();
    dispatch(
      setProfilerConfigTableBlobList({
        loading: true,
        data: [],
        cancelToken: cancelToken,
      })
    );
    getDataSourceTableBlobListPromise(
      workspaceUuid,
      dataSource.metadata.uuid,
      payload,
      cancelToken
    )
      .then(function (data) {
        dispatch(
          setProfilerConfigTableBlobList({ loading: false, data, cancelToken: null })
        );
      })
      .catch(function (err) {
        dispatch(
          setProfilerConfigTableBlobList({
            loading: false,
            data: [],
            cancelToken: null,
          })
        );
        console.log(`Fail to get blob list due to ${err}`);
      });
  };
}

export function resetProfilerConfigBlobStorageForm() {
  return (dispatch, getState) => {
    const { cancelToken } = getState().profilerReducer.profilerConfigTableBlobList;
    if (cancelToken) {
      cancelToken.cancel();
    }
    dispatch(
      setProfilerConfigTableBlobList({ loading: false, data: [], cancelToken: null })
    );
  };
}

export function resetProfilerCurrentIncidentList() {
  return (dispatch, getState) => {
    dispatch(setProfilerCurrentIncidentList({ loading: true, data: [] }));
    dispatch(setProfilerCurrentIncidentMetricsData({ loading: true, data: null }));
  };
}

export function verifyVirtualTableSql(workspaceUuid, dataSourceUuid, payload) {
  return (dispatch, getState) => {
    dispatch(
      setProfilerVirtualTableColumnList({ loading: true, data: [], isLoaded: true })
    );
    getDataSourceSchemaSqlPromise(workspaceUuid, dataSourceUuid, payload)
      .then(function (schemas) {
        dispatch(
          setProfilerVirtualTableColumnList({
            loading: false,
            data: schemas,
            isLoaded: true,
          })
        );
      })
      .catch(function (error) {
        dispatch(
          setProfilerVirtualTableColumnList({
            loading: false,
            data: [],
            isLoaded: true,
          })
        );
        console.log(`Fail to get virtual table column list for ${error}`);
      });
  };
}

export function previewVirtualTableSchemaList(
  workspaceUuid,
  dataSourceUuid,
  virtualTableConfig
) {
  return (dispatch, getState) => {
    dispatch(
      setProfilerVirtualTableSchemaList({
        loading: true,
        data: [],
      })
    );

    getDataSourceSchemaSqlPromise(workspaceUuid, dataSourceUuid, {
      queryScope: virtualTableConfig.profilerConfig.queryScope,
      sql: virtualTableConfig.profilerConfig.sql,
    })
      .then(function (schemas) {
        dispatch(
          setProfilerVirtualTableSchemaList({
            loading: false,
            data: schemas,
          })
        );
      })
      .catch(function (error) {
        dispatch(
          setProfilerVirtualTableSchemaList({
            loading: false,
            data: [],
          })
        );
        console.log(`Fail to get virtual table column list for ${error}`);
      });
  };
}

export function previewVirtualTableSampleDataList(workspaceUuid, payload) {
  return (dispatch, getState) => {
    dispatch(
      setProfilerVirtualTableSampleDataList({
        loading: true,
        data: [],
        error: null,
      })
    );

    getKpiTableSchemaSamplesPreviewPromise(workspaceUuid, payload)
      .then(function (data) {
        dispatch(
          setProfilerVirtualTableSampleDataList({ loading: false, data, error: null })
        );
      })
      .catch(function (error) {
        dispatch(
          setProfilerVirtualTableSampleDataList({
            loading: false,
            data: [],
            error: error?.response?.data?.error || "unknown error",
          })
        );
        console.log(`Fail to get sample data list for ${error}`);
      });
  };
}

export function resetProfilerVirtualTableView() {
  return (dispatch, getState) => {
    dispatch(
      setProfilerVirtualTableColumnList({
        loading: false,
        data: [],
        isLoaded: false,
      })
    );
    dispatch(
      setProfilerVirtualTableSchemaList({
        loading: false,
        data: [],
      })
    );
    dispatch(
      setProfilerVirtualTableSampleDataList({ loading: false, data: [], error: null })
    );
  };
}

export function getProfilerTableActivityLog(
  workspaceUuid,
  dataSourceUuid,
  tableUuid,
  opts = {}
) {
  return (dispatch) => {
    const { quiet = false } = opts;
    if (!quiet) {
      dispatch(
        setProfilerConfigTableChangeLog({ loading: true, data: null, error: false })
      );
    }
    getDataSourceTableChangeLogPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (data) {
        dispatch(
          setProfilerConfigTableChangeLog({ loading: false, data, error: false })
        );
      })
      .catch(function (err) {
        if (!quiet) {
          dispatch(
            setProfilerConfigTableChangeLog({ loading: false, data: [], error: true })
          );
        }
        console.log(
          `Fail to get profiler table data activity log for ${tableUuid} with ${err}`
        );
      });
  };
}
