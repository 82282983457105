import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { getIncidentStatusDisplayStr } from "../../utils/incident";
import { incidentStatusOptions } from "../../utils/options";

import "./index.scss";

class IncidentStatusOptionMenu extends Component {
  render() {
    const {
      disableBorder = false,
      onChangeIncidentStatusClick,
      readOnly,
      status,
      disabled = false,
    } = this.props;

    return readOnly ? (
      getIncidentStatusDisplayStr(status)
    ) : (
      <div
        className={`incident-status-option-menu-container${
          disableBorder ? " no-border" : ""
        }`}
      >
        <Select
          value={status}
          style={{ width: "100%" }}
          onChange={onChangeIncidentStatusClick}
          options={incidentStatusOptions}
          disabled={disabled}
        />
      </div>
    );
  }
}

IncidentStatusOptionMenu.propTypes = {
  onChangeIncidentStatusClick: PropTypes.func.isRequired,
};

export default IncidentStatusOptionMenu;
