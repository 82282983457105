import React from "react";
import EmailIntegrationBasicDialog from "./email-integration-basic-dialog";
import { DefaultTimeZone } from "../../../utils/time";

function AddEmailIntegrationDialog(props) {
  const { onAdd, onPreview, modalIsOpen, setIsOpen } = props;
  const defaultEmailInstance = {
    title: "",
    emailAddressList: [],
    timezone: DefaultTimeZone,
    muteResolvedAlerts: false,
    digestPeriodInSeconds: 0,
    enableRecurring: false,
  };

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  return (
    <EmailIntegrationBasicDialog
      defaultData={defaultEmailInstance}
      className={"add-email-integration-dialog-container"}
      dialogTitle={"Add Email Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      OKText={"Add"}
      enablePreview={true}
      enableSave={true}
    />
  );
}

export default AddEmailIntegrationDialog;
