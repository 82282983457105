import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { getDropdownItems } from "../../components/entity-list/menu-utils";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { ActiveIcon, MutedIcon } from "./alerting-status-badge";

import "./alerting-actions-menu.scss";

const AlertingActions = Object.freeze({
  ACTIVATE: "activate",
  MUTE: "mute",
  MANAGE_CHANNELS: "manageChannels",
});

function AlertingActionsMenu(props) {
  const {
    trigger,
    monitors,
    workspaceUserPermissions,
    onAlertConfigUpdated,
    onManageChannels,
  } = props;

  const activateLabel =
    monitors.length > 1 ? (
      "Unmute"
    ) : (
      <span className="alerting-actions-menu-activate">
        <span>Unmute</span>
        <span>{monitors[0].config.alertConfig?.channels?.length ?? 0}</span>
      </span>
    );

  function alertingMutedUpdated(monitor, newIsMuted) {
    return {
      ...monitor,
      config: {
        ...monitor.config,
        alertConfig: {
          ...(monitor.config.alertConfig || {}),
          isMuted: newIsMuted,
          channels: (monitor.config.alertConfig?.channels || []).map((channel) => ({
            ...channel,
          })),
        },
      },
    };
  }

  const channelsConfigured = (monitor) =>
    monitor.config.alertConfig?.channels?.length > 0;

  const menuConfig = [
    {
      action: AlertingActions.ACTIVATE,
      supportsMultiple: true,
      item: {
        label: activateLabel,
        icon: <ActiveIcon />,
        onClick: (targetMonitors) =>
          onAlertConfigUpdated(
            targetMonitors.map((monitor) => alertingMutedUpdated(monitor, false))
          ),
      },
      validTarget: (monitor) =>
        channelsConfigured(monitor) && monitor.config.alertConfig?.isMuted,
    },
    {
      action: AlertingActions.MUTE,
      supportsMultiple: true,
      item: {
        label: "Mute",
        icon: <MutedIcon />,
        onClick: () =>
          onAlertConfigUpdated(
            monitors.map((monitor) => alertingMutedUpdated(monitor, true))
          ),
      },
      validTarget: (monitor) =>
        channelsConfigured(monitor) && !monitor.config.alertConfig?.isMuted,
    },
    {
      action: AlertingActions.MANAGE_CHANNELS,
      supportsMultiple: true,
      item: {
        label: "Manage Channels",
        icon: <SettingOutlined />,
        onClick: () => onManageChannels(monitors),
      },
    },
  ];

  const menuItems = getDropdownItems(
    menuConfig,
    monitors,
    Object.values(AlertingActions),
    workspaceUserPermissions
  );

  return <NgDropdownMenu trigger={trigger} menuItems={menuItems} menuStyle="minor" />;
}

export default AlertingActionsMenu;
