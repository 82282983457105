import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Empty } from "antd";
import Tag from "../../atom/tag";
import Button from "../../components/button/ng-button";
import {
  PlusOutlined,
  EditOutlined,
  FilterOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { AppPermissions } from "../../utils/permissions";
import TableChartView from "./charts/table/table-chart-view";
import DashboardChartPopupMenu from "./dashboard-chart-popup-menu";
import DashboardChartConfigDialog from "./dashboard-chart-config-dialog";
import { getFilterSummaryMsg } from "./utils";
import {
  DashboardChartType,
  DashboardFilterType,
  DashboardFilterValue,
} from "../../utils/enums";
import { getDefaultUserSetting } from "../../utils/user-setting";
import { hasPermission } from "../../utils/uri-path";

import "./dashboard.scss";

const chartTypeMapper = {
  [DashboardChartType.TABLE]: TableChartView,
};

function AddButton({ onClick }) {
  return (
    <Button primary icon={<PlusOutlined />} onClick={onClick}>
      Add chart
    </Button>
  );
}

function getIncidentListPerChart(dashboardChartListSetPerChart, chartUuid) {
  return dashboardChartListSetPerChart[chartUuid] || { loading: true, data: [] };
}

const CHART_DIALOG_UUID = "dashboard-dialog-uuid";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workspaceUuid: "",
      dataSourceOptions: [],
      dataSourceList: [],
      kpiOptions: [],
      kpiList: [],
      ruleOptions: [],
      ruleList: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      dataSourceList,
      kpiList,
      ruleList,
      match: {
        params: { workspaceUuid },
      },
      workspaceUserPermissions,
    } = props;
    let isChanged = false;
    if (state.workspaceUuid !== workspaceUuid) {
      if (
        hasPermission(workspaceUserPermissions, [
          AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCELIST,
        ])
      ) {
        props.getDataSourceList(workspaceUuid);
      }

      if (
        hasPermission(workspaceUserPermissions, [
          AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
        ])
      ) {
        props.getKpiList(workspaceUuid);
      }

      if (
        hasPermission(workspaceUserPermissions, [
          AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
        ])
      ) {
        props.getRuleList(workspaceUuid);
      }

      props.getTagList(workspaceUuid);
      props.getDashBoardChartList(workspaceUuid);

      isChanged = true;
      state = {
        ...state,
        workspaceUuid,
      };
    }

    if (state.dataSourceList !== dataSourceList) {
      const dataSourceOptions = dataSourceList.map((currentDataSource) => {
        return {
          label: currentDataSource.metadata.name,
          value: currentDataSource.metadata.name,
        };
      });

      isChanged = true;
      state = {
        ...state,
        dataSourceList,
        dataSourceOptions,
      };
    }

    if (state.kpiList !== kpiList) {
      const kpiOptions = [];
      const kpiSet = new Set();
      kpiList.forEach((currentKpi) => {
        const kpiName = currentKpi.metadata.name;
        if (!kpiSet.has(kpiName)) {
          kpiSet.add(kpiName);
          kpiOptions.push({
            label: kpiName,
            value: kpiName,
          });
        }
      });

      isChanged = true;
      state = {
        ...state,
        kpiList,
        kpiOptions,
      };
    }

    if (state.ruleList !== ruleList) {
      const ruleOptions = [];
      const ruleSet = new Set();
      ruleList.forEach((currentRule) => {
        const ruleName = currentRule.metadata.name;
        if (!ruleSet.has(ruleName)) {
          ruleSet.add(ruleName);
          ruleOptions.push({
            label: ruleName,
            value: ruleName,
          });
        }
      });

      isChanged = true;
      state = {
        ...state,
        ruleList,
        ruleOptions,
      };
    }

    if (isChanged) {
      return state;
    }

    return null;
  }

  render() {
    const { dataSourceOptions, kpiOptions, ruleOptions, workspaceUuid } = this.state;
    const {
      dashboardChartList,
      tagList,
      dashboardIncidentListSetPerChart,
      workspaceUserPermissions,
    } = this.props;
    const isEditDashboardEnabled = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_DASHBOARD_CHART_VIEWS_EDIT_DASHBOARDCHARTLIST,
      AppPermissions.BACKEND_APPS_DASHBOARD_CHART_VIEWS_VIEW_DASHBOARDCHARTDETAIL,
      AppPermissions.BACKEND_APPS_DASHBOARD_CHART_VIEWS_EDIT_DASHBOARDCHARTDETAIL,
    ]);

    const defaultChartConfig = {
      metadata: {
        name: "",
      },
      config: {
        type: "table",
        groupColumns: ["datasource"],
        aggregationColumns: ["incident"],
        filters: [
          {
            type: DashboardFilterType.TIME_DURATION,
            value: {
              type: DashboardFilterValue.TIME_DURATION,
              duration: {
                value: 1,
                unit: "weeks",
              },
            },
          },
        ],
      },
    };

    return (
      <div className="dashboard-body-container lightup-vertical-flex-container">
        {!dashboardChartList.loading && dashboardChartList.data.length === 0 && (
          <div className="dashboard-zero-chart-config-container">
            <Empty
              image={
                <svg
                  width="224"
                  height="189"
                  viewBox="0 0 224 189"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M189.958 23.945C192.718 23.8486 195.033 26.0077 195.13 28.7675L199.559 155.601C199.655 158.361 197.496 160.676 194.736 160.773L32.2753 166.446C29.5155 166.542 27.2002 164.383 27.1038 161.624L22.6747 34.7897C22.5783 32.03 24.7374 29.7146 27.4972 29.6183L189.958 23.945Z"
                    fill="#D7DEEB"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M191.725 22.1745C194.484 22.0781 196.8 24.2372 196.896 26.997L201.325 153.831C201.422 156.59 199.263 158.906 196.503 159.002L34.0419 164.675C31.2821 164.772 28.9668 162.613 28.8704 159.853L24.4413 33.0192C24.3449 30.2595 26.504 27.9441 29.2638 27.8478L191.725 22.1745Z"
                    fill="#E4EBF7"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M186.929 37.3805C189.138 37.3753 190.924 39.1621 190.919 41.3714L190.655 148.676C190.649 150.886 188.854 152.681 186.645 152.686L41.8035 153.028C39.5946 153.033 37.8082 151.246 37.8137 149.037L38.0778 41.7322C38.0833 39.5229 39.8784 37.7276 42.0874 37.7224L186.929 37.3805Z"
                    fill="#000022"
                    fillOpacity="0.1"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M188.086 36.1803C190.295 36.1751 192.081 37.9619 192.076 40.1712L191.811 147.476C191.806 149.685 190.011 151.481 187.802 151.486L42.9602 151.828C40.7513 151.833 38.965 150.046 38.9704 147.837L39.2346 40.532C39.24 38.3227 41.0351 36.5274 43.2441 36.5222L188.086 36.1803Z"
                    fill="white"
                  />
                  <path
                    opacity="0.53"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.1632 27.7461L157.934 160.429L35.4829 164.761C31.6037 164.898 28.3536 161.853 28.2385 157.973L24.5352 33.1461C24.4529 30.374 26.6426 28.0645 29.4152 27.9992L40.1632 27.7461Z"
                    fill="#02244D"
                    fillOpacity="0.05"
                  />
                  <path
                    opacity="0.8"
                    d="M112.564 126.6C131.404 126.6 146.677 111.496 146.677 92.8631C146.677 74.2306 131.404 59.126 112.564 59.126C93.7247 59.126 78.4521 74.2306 78.4521 92.8631C78.4521 111.496 93.7247 126.6 112.564 126.6Z"
                    fill="white"
                    fillOpacity="0.85"
                  />
                  <path
                    d="M152.663 70.815C158.664 93.2104 145.373 116.23 122.978 122.231C100.583 128.232 77.5628 114.941 71.562 92.5459"
                    stroke="#1890FF"
                    strokeWidth="6"
                  />
                  <path
                    d="M87.9512 72.4088L97.0942 77.2592L101.572 71.4871L110.473 76.3875L116.729 64L126.063 71.4871L137.01 65.3416"
                    stroke="#1890FF"
                    strokeWidth="6"
                    strokeLinecap="round"
                  />
                  <rect
                    x="89.2769"
                    y="89.3516"
                    width="46.4073"
                    height="6"
                    rx="3"
                    fill="#7BB2F9"
                  />
                  <rect
                    x="89.2769"
                    y="99.959"
                    width="25.1926"
                    height="6"
                    rx="3"
                    fill="#7BB2F9"
                  />
                </svg>
              }
              description={
                <span>
                  <div className="no-data-info-container">No charts yet!</div>
                  <div className="no-data-action-container">
                    {isEditDashboardEnabled
                      ? "Add a chart to create your dashboard"
                      : "You’ll be able to view charts once your team members add them to the dashboard"}
                  </div>
                </span>
              }
            >
              {isEditDashboardEnabled && (
                <DashboardChartConfigDialog
                  TriggerComponent={AddButton}
                  ChartViewComponent={TableChartView}
                  workspaceUuid={workspaceUuid}
                  initialValues={{
                    name: defaultChartConfig.metadata.name,
                    ...defaultChartConfig.config,
                  }}
                  dialogTitle={"Add chart"}
                  onSubmit={(newValues) => {
                    const { name, ...otherParams } = newValues;
                    defaultChartConfig.metadata.name = name;
                    defaultChartConfig.config = {
                      ...defaultChartConfig.config,
                      ...otherParams,
                    };
                    this.props.addDashboardChartConfig(
                      workspaceUuid,
                      defaultChartConfig
                    );
                  }}
                  dataSourceOptions={dataSourceOptions}
                  kpiOptions={kpiOptions}
                  ruleOptions={ruleOptions}
                  tagList={tagList}
                  uuid={CHART_DIALOG_UUID}
                  incidentList={getIncidentListPerChart(
                    dashboardIncidentListSetPerChart,
                    CHART_DIALOG_UUID
                  )}
                  getData={this.props.getDashboardIncidentListPerChart.bind(
                    this.props,
                    workspaceUuid
                  )}
                  userSetting={getDefaultUserSetting()}
                />
              )}
            </Empty>
          </div>
        )}
        {!dashboardChartList.loading && dashboardChartList.data.length > 0 && (
          <div className="dashboard-indicator-container lightup-horizon-flex-container">
            <div className="lightup-rest-flex-item-container" />
            {isEditDashboardEnabled && (
              <DashboardChartConfigDialog
                TriggerComponent={AddButton}
                ChartViewComponent={TableChartView}
                initialValues={{
                  name: defaultChartConfig.metadata.name,
                  ...defaultChartConfig.config,
                }}
                workspaceUuid={workspaceUuid}
                dialogTitle={"Add chart"}
                onSubmit={(newValues) => {
                  const { name, ...otherParams } = newValues;
                  defaultChartConfig.metadata.name = name;
                  defaultChartConfig.config = {
                    ...defaultChartConfig.config,
                    ...otherParams,
                  };
                  this.props.addDashboardChartConfig(workspaceUuid, defaultChartConfig);
                }}
                dataSourceOptions={dataSourceOptions}
                kpiOptions={kpiOptions}
                ruleOptions={ruleOptions}
                tagList={tagList}
                uuid={CHART_DIALOG_UUID}
                incidentList={getIncidentListPerChart(
                  dashboardIncidentListSetPerChart,
                  CHART_DIALOG_UUID
                )}
                getData={this.props.getDashboardIncidentListPerChart.bind(
                  this.props,
                  workspaceUuid
                )}
                userSetting={getDefaultUserSetting()}
              />
            )}
          </div>
        )}
        {dashboardChartList.data.map((dashboardChartConfig, index) => {
          const name = dashboardChartConfig.metadata.name;
          const uuid = dashboardChartConfig.metadata.uuid;
          const { type: chartType, ...otherParams } = dashboardChartConfig.config;
          if (!chartTypeMapper[chartType]) {
            console.log(`Unknown chart type ${chartType}. Dropping.`);
            return null;
          }

          const ChartViewComponent = chartTypeMapper[chartType];
          const incidentList = getIncidentListPerChart(
            dashboardIncidentListSetPerChart,
            uuid
          );
          const TriggerComponent = (props) => {
            return <EditOutlined onClick={props.onClick} width={24} height={24} />;
          };

          const { filterSummary, timeSummary } = getFilterSummaryMsg(
            otherParams.filters
          );
          return (
            <div className="dashboard-chart-view-container" key={uuid}>
              <div className="dashboard-chart-view-header-container lightup-horizon-flex-container">
                <div className="dashboard-chart-name-container">{name}</div>
                {timeSummary && (
                  <div className="dashboard-chart-time-summary-container">
                    <Tag
                      icon={
                        <ClockCircleOutlined style={{ color: "rgba(0, 0, 0, 0.7)" }} />
                      }
                    >
                      {timeSummary}
                    </Tag>
                  </div>
                )}
                {filterSummary && (
                  <div className="dashboard-chart-config-summary-container">
                    <Tag
                      icon={<FilterOutlined style={{ color: "rgba(0, 0, 0, 0.7)" }} />}
                    >
                      {filterSummary}
                    </Tag>
                  </div>
                )}
                <div className="lightup-rest-flex-item-container" />
                {isEditDashboardEnabled && (
                  <>
                    <div className="dashboard-chart-edit-container">
                      <DashboardChartConfigDialog
                        TriggerComponent={TriggerComponent}
                        ChartViewComponent={ChartViewComponent}
                        initialValues={{ name, ...dashboardChartConfig.config }}
                        workspaceUuid={workspaceUuid}
                        dialogTitle={"Edit chart"}
                        onSubmit={(newValues) => {
                          const { name, ...otherParams } = newValues;
                          dashboardChartConfig.metadata.name = name;
                          dashboardChartConfig.config = {
                            ...dashboardChartConfig.config,
                            ...otherParams,
                          };
                          this.props.updateDashboardChartConfig(
                            workspaceUuid,
                            dashboardChartConfig
                          );
                        }}
                        dataSourceOptions={dataSourceOptions}
                        kpiOptions={kpiOptions}
                        ruleOptions={ruleOptions}
                        tagList={tagList}
                        uuid={CHART_DIALOG_UUID}
                        incidentList={getIncidentListPerChart(
                          dashboardIncidentListSetPerChart,
                          CHART_DIALOG_UUID
                        )}
                        getData={this.props.getDashboardIncidentListPerChart.bind(
                          this.props,
                          workspaceUuid
                        )}
                        userSetting={getDefaultUserSetting()}
                      />
                    </div>
                    <div className="dashboard-chart-popup-menu-container">
                      <DashboardChartPopupMenu
                        onDelete={() =>
                          this.props.deleteDashboardChartConfig(
                            workspaceUuid,
                            dashboardChartConfig
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              <ChartViewComponent
                uuid={uuid}
                workspaceUuid={workspaceUuid}
                config={otherParams}
                incidentList={incidentList}
                userSetting={getDefaultUserSetting()}
                getData={this.props.getDashboardIncidentListPerChart.bind(
                  this.props,
                  workspaceUuid
                )}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(Dashboard);
