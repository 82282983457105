import React from "react";
import LegacyModalDialogAdapter from "../../../../../components/legacy-adapter/legacy-modal-dialog-adapter";
import IntegrationDialogFooter from "../../../../integrations/integration-dialog-footer";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../../../../components/modal-dialog/modal-field-group";
import { LabeledInput } from "../../../../../components/labeled-control/labeled-control";
import TimeOption from "../../../../../components/time-option/time-option";
import {
  getDisplayTimeFromSecond,
  getSecondFromDisplayTime,
} from "../../../../../utils/time";

const incidentWindowOptions = [
  { value: "h", label: "Hour" },
  { value: "d", label: "Day" },
  { value: "w", label: "Week" },
];

function AtlanIntegrationBasicDialogBodyComponent(props) {
  const {
    title,
    onTitleChange,
    baseUrl,
    onBaseUrlChange,
    bearerToken,
    onBearerTokenChange,
    incidentWindow,
    onIncidentWindowChange,
  } = props;

  return (
    <ModalFieldGroup>
      <ModalFieldRows>
        <LabeledInput
          label="Title"
          value={title}
          onChange={(e) => onTitleChange(e.target.value)}
        />
        <LabeledInput
          label="Host"
          value={baseUrl}
          onChange={(e) => onBaseUrlChange(e.target.value)}
        />
        <LabeledInput
          label="Bearer Token"
          type="password"
          value={bearerToken}
          onChange={(e) => onBearerTokenChange(e.target.value)}
        />
        <TimeOption
          label="Incident Window"
          value={
            incidentWindow === undefined
              ? undefined
              : getDisplayTimeFromSecond(incidentWindow, true, ["h", "d", "w"])
          }
          onChange={(incidentWindowStr) => {
            const newIncidentWindow = getSecondFromDisplayTime(incidentWindowStr);
            if (incidentWindow !== newIncidentWindow) {
              onIncidentWindowChange(newIncidentWindow);
            }
          }}
          options={incidentWindowOptions}
        />
      </ModalFieldRows>
    </ModalFieldGroup>
  );
}

function AtlanIntegrationBasicDialog(props) {
  let {
    defaultData: {
      metadata: { uuid, name: defaultTitle },
      config: {
        type,
        baseUrl: defaultBaseUrl,
        disableAnnouncements,
        bearerToken: defaultBearerToken,
        incidentWindow: defaultIncidentWindow,
      },
    },
    dialogTitle = "",
    className = "",
    triggerComponent,
    onOkClicked,
    onPreview,
    OKText,
    modalIsOpen,
    setIsOpen,
    enableSave,
    enablePreview,
  } = props;

  const [title, setTitle] = React.useState(defaultTitle);
  const [baseUrl, setBaseUrl] = React.useState(defaultBaseUrl);
  const [bearerToken, setBearerToken] = React.useState(defaultBearerToken);
  const [incidentWindow, setIncidentWindow] = React.useState(defaultIncidentWindow);

  const [modalIsOpenDefault, setIsOpenDefault] = React.useState(false);
  if (modalIsOpen === undefined || setIsOpen === undefined) {
    modalIsOpen = modalIsOpenDefault;
    setIsOpen = setIsOpenDefault;
  } else {
    triggerComponent = null;
  }

  const isValidData = title && baseUrl && bearerToken && incidentWindow;

  function getNewAtlanIntegration() {
    return {
      metadata: {
        ...(uuid ? { uuid } : {}),
        name: title,
      },
      config: {
        type,
        baseUrl,
        bearerToken,
        incidentWindow,
        disableAnnouncements,
      },
    };
  }

  function closeModal() {
    setTitle(defaultTitle);
    setBaseUrl(defaultBaseUrl);
    setBearerToken(defaultBearerToken);
    setIncidentWindow(defaultIncidentWindow);
    setIsOpen(false);
  }

  function onOkClick() {
    if (onOkClicked) {
      const newAtlanIntegration = getNewAtlanIntegration();
      onOkClicked(newAtlanIntegration);
    }
    closeModal();
  }

  function onPreviewClick() {
    if (onPreview) {
      const newAtlanIntegration = getNewAtlanIntegration();
      onPreview(newAtlanIntegration);
    }
  }

  return (
    <LegacyModalDialogAdapter
      title={dialogTitle}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={`atlan-integration-basic-dialog-container ${className}`}
      triggerComponent={triggerComponent}
      contentComponent={
        <AtlanIntegrationBasicDialogBodyComponent
          title={title}
          onTitleChange={setTitle}
          baseUrl={baseUrl}
          onBaseUrlChange={setBaseUrl}
          bearerToken={bearerToken}
          onBearerTokenChange={setBearerToken}
          incidentWindow={incidentWindow}
          onIncidentWindowChange={setIncidentWindow}
        />
      }
      footerComponent={
        <IntegrationDialogFooter
          onPreviewClick={onPreviewClick}
          onCancelClick={closeModal}
          onOkClick={onOkClick}
          OkText={OKText}
          enablePreview={enablePreview}
          enableSave={enableSave && isValidData}
          previewText={"Test connection"}
        />
      }
    />
  );
}

export default AtlanIntegrationBasicDialog;
