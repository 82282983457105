import React, { useEffect, useState } from "react";
import { differenceInDays, differenceInHours } from "date-fns";
import { ExpirationIcon } from "../icons/trial";
import { CloseOutlined } from "@ant-design/icons";
import ButtonIcon from "../button/button-icon";

import "./expiration-banner.scss";

function ExpirationBanner(props) {
  const { expirationTimestamp, onCloseClick } = props;

  const now = new Date();
  const expirationDate = new Date(expirationTimestamp * 1000);
  // We can't use differenceInDays here because it will round down.
  const daysRemaining = Math.ceil(differenceInHours(expirationDate, now) / 24);

  return (
    <div className="trial-expiration-banner">
      <ExpirationIcon />
      <span className="trial-expiration-banner-message">
        You have {daysRemaining} days remaining on your free trial
      </span>
      <ButtonIcon icon={<CloseOutlined />} onClick={() => onCloseClick()} />
    </div>
  );
}

export const dismissalStorageKey =
  "TRIAL_EXPIRATION_BANNER_LAST_DISMISSED_TIMESTAMP_MS";

// Remind the user again every 24 hours.
function maybeClearDismissal(storage) {
  try {
    const lastDismissal = new Date(
      parseInt(storage.getItem(dismissalStorageKey)) * 1000
    );
    if (differenceInDays(Date.now(), lastDismissal) >= 1) {
      storage.removeItem(dismissalStorageKey);
    }
  } catch (_e) {
    storage.removeItem(dismissalStorageKey);
  }
}

function DismissableExpirationBanner(props) {
  const { expirationTimestamp, storage = localStorage } = props;

  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    maybeClearDismissal(storage);
    setIsBannerVisible(storage.getItem(dismissalStorageKey) === null);
  }, [storage]);

  if (!isBannerVisible) {
    return null;
  }

  return (
    <ExpirationBanner
      expirationTimestamp={expirationTimestamp}
      onCloseClick={() => {
        storage.setItem(dismissalStorageKey, Date.now());
        setIsBannerVisible(false);
      }}
    />
  );
}

export default DismissableExpirationBanner;
