import React from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Dropdown, Select, Input, Menu } from "antd";
import Button from "../../components/button/ng-button";
import DashboardChartTimeRange from "./dashboard-chart-time-range";
import { DashboardFilterType, DashboardFilterValue } from "../../utils/enums";
import {
  incidentDirectionOptions,
  incidentSeverityOptions,
  incidentStatusOptions,
  incidentValidationStatusOptions,
} from "../../utils/options";

import "./dashboard-chart-filter-list.scss";

function TagSelect(props) {
  const { tagList, value, onChange, ...otherProperties } = props;
  const options = tagList.map((currentTagName) => {
    return {
      label: currentTagName,
      value: currentTagName,
    };
  });

  const normalizedValue = value.map((currentValue) => {
    if (typeof currentValue === "string") {
      return currentValue;
    }

    if (typeof currentValue === "object") {
      return currentValue.key;
    }

    return currentValue;
  });

  function onSelectChange(newTagName) {
    onChange(newTagName);
  }

  return (
    <Select
      options={options}
      mode={"multiple"}
      allowClear={true}
      value={normalizedValue}
      onChange={onSelectChange}
      {...otherProperties}
    />
  );
}

function DashboardChartFilterList(props) {
  const { dataSourceOptions, kpiOptions, ruleOptions, tagList = [], form } = props;

  return (
    <Form.List name="filters">
      {(fields, { add, remove }) => {
        function onMenuClick(eventObj) {
          const { key } = eventObj;
          if (
            [
              DashboardFilterType.DATASOURCES,
              DashboardFilterType.METRICS,
              DashboardFilterType.MONITORS,
              DashboardFilterType.VALIDATION_STATUS,
            ].includes(key)
          ) {
            add({
              type: key,
              value: {
                type: DashboardFilterValue.STR,
                strList: [],
              },
            });
          } else if (key === DashboardFilterType.TIME_DURATION) {
            add({
              type: key,
              value: {
                type: DashboardFilterValue.TIME_DURATION,
                duration: {
                  value: 1,
                  unit: "weeks",
                },
              },
            });
          } else if (
            [
              DashboardFilterType.SEVERITY,
              DashboardFilterType.DIRECTION,
              DashboardFilterType.STATUS,
            ].includes(key)
          ) {
            add({
              type: key,
              value: {
                type: DashboardFilterValue.INT,
                intList: [],
              },
            });
          } else if (key === DashboardFilterType.TAGS) {
            add({
              type: key,
              value: {
                type: DashboardFilterValue.TAG,
                tagList: [],
              },
            });
          }
        }

        const filterList = form.getFieldValue(["filters"]) || [];
        let isDataSourceDisabled = false;
        let isMetricDisabled = false;
        let isMonitorDisabled = false;
        let isDateRangeDisabled = false;
        let isSeverityDisabled = false;
        let isDirectionDisabled = false;
        let isStatusDisabled = false;
        let isValidationStatusDisabled = false;

        for (let currentFilter of filterList) {
          if (currentFilter.type === DashboardFilterType.DATASOURCES) {
            isDataSourceDisabled = true;
          } else if (currentFilter.type === DashboardFilterType.METRICS) {
            isMetricDisabled = true;
          } else if (currentFilter.type === DashboardFilterType.MONITORS) {
            isMonitorDisabled = true;
          } else if (currentFilter.type === DashboardFilterType.TIME_DURATION) {
            isDateRangeDisabled = true;
          } else if (currentFilter.type === DashboardFilterType.SEVERITY) {
            isSeverityDisabled = true;
          } else if (currentFilter.type === DashboardFilterType.DIRECTION) {
            isDirectionDisabled = true;
          } else if (currentFilter.type === DashboardFilterType.STATUS) {
            isStatusDisabled = true;
          } else if (currentFilter.type === DashboardFilterType.VALIDATION_STATUS) {
            isValidationStatusDisabled = true;
          }
        }

        const menu = (
          <Menu onClick={onMenuClick}>
            {!isDataSourceDisabled && (
              <Menu.Item key={DashboardFilterType.DATASOURCES}>Datasource</Menu.Item>
            )}
            {!isDateRangeDisabled && (
              <Menu.Item
                key={DashboardFilterType.TIME_DURATION}
                disabled={isDateRangeDisabled}
              >
                Date range
              </Menu.Item>
            )}
            {!isDirectionDisabled && (
              <Menu.Item
                key={DashboardFilterType.DIRECTION}
                disabled={isDirectionDisabled}
              >
                Direction
              </Menu.Item>
            )}
            {!isMetricDisabled && (
              <Menu.Item key={DashboardFilterType.METRICS} disabled={isMetricDisabled}>
                Metric
              </Menu.Item>
            )}
            {!isMonitorDisabled && (
              <Menu.Item
                key={DashboardFilterType.MONITORS}
                disabled={isMonitorDisabled}
              >
                Monitor
              </Menu.Item>
            )}
            {!isSeverityDisabled && (
              <Menu.Item
                key={DashboardFilterType.SEVERITY}
                disabled={isSeverityDisabled}
              >
                Severity
              </Menu.Item>
            )}
            {!isStatusDisabled && (
              <Menu.Item key={DashboardFilterType.STATUS} disabled={isStatusDisabled}>
                Status
              </Menu.Item>
            )}
            <Menu.Item key={DashboardFilterType.TAGS}>Tag</Menu.Item>
            {!isValidationStatusDisabled && (
              <Menu.Item
                key={DashboardFilterType.VALIDATION_STATUS}
                disabled={isValidationStatusDisabled}
              >
                Validation status
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <div>
            {fields.map((field, index) => {
              const currentType = form.getFieldValue(["filters", index, "type"]);
              let label = "";
              let placeholder = "";
              let options = [];
              let valuePaths = [];
              let currentItem = null;
              if (currentType === DashboardFilterType.DATASOURCES) {
                label = "Datasource";
                placeholder = "Select datasource(s)";
                options = dataSourceOptions;
                valuePaths = [index, "value", "strList"];
                currentItem = (
                  <Select
                    placeholder={placeholder}
                    options={options}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              } else if (currentType === DashboardFilterType.METRICS) {
                label = "Metric";
                placeholder = "Select metric(s)";
                options = kpiOptions;
                valuePaths = [index, "value", "strList"];
                currentItem = (
                  <Select
                    placeholder={placeholder}
                    options={options}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              } else if (currentType === DashboardFilterType.MONITORS) {
                label = "Monitor";
                placeholder = "Select monitor(s)";
                options = ruleOptions;
                valuePaths = [index, "value", "strList"];
                currentItem = (
                  <Select
                    placeholder={placeholder}
                    options={options}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              } else if (currentType === DashboardFilterType.TIME_DURATION) {
                label = "Date range";
                valuePaths = [index];
                currentItem = <DashboardChartTimeRange />;
              } else if (currentType === DashboardFilterType.SEVERITY) {
                label = "Severity";
                placeholder = "Select severity(s)";
                valuePaths = [index, "value", "intList"];
                currentItem = (
                  <Select
                    placeholder={placeholder}
                    options={incidentSeverityOptions}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              } else if (currentType === DashboardFilterType.DIRECTION) {
                label = "Direction";
                placeholder = "Select direction(s)";
                valuePaths = [index, "value", "intList"];
                currentItem = (
                  <Select
                    placeholder={placeholder}
                    options={incidentDirectionOptions}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              } else if (currentType === DashboardFilterType.TAGS) {
                label = "Tag";
                placeholder = "Select tag(s)";
                valuePaths = [index, "value", "tagList"];
                currentItem = (
                  <TagSelect
                    tagList={tagList}
                    placeholder={placeholder}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              } else if (currentType === DashboardFilterType.STATUS) {
                label = "Status";
                placeholder = "Select status(s)";
                valuePaths = [index, "value", "intList"];
                currentItem = (
                  <Select
                    placeholder={placeholder}
                    options={incidentStatusOptions}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              } else if (currentType === DashboardFilterType.VALIDATION_STATUS) {
                label = "Validation status";
                placeholder = "Select validation status(s)";
                valuePaths = [index, "value", "strList"];
                currentItem = (
                  <Select
                    placeholder={placeholder}
                    options={incidentValidationStatusOptions}
                    mode={"multiple"}
                    allowClear={true}
                  />
                );
              }

              return (
                <div key={field.key}>
                  <Form.Item
                    name={[index, "type"]}
                    label=""
                    hidden={true}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={[index, "value", "type"]}
                    label=""
                    hidden={true}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className="filter-list-form-item-container"
                    name={valuePaths}
                    label={
                      <div>
                        {label}
                        {currentType !== DashboardFilterType.TIME_DURATION && (
                          <DeleteOutlined onClick={() => remove(field.name)} />
                        )}
                      </div>
                    }
                  >
                    {currentItem}
                  </Form.Item>
                </div>
              );
            })}
            <Form.Item>
              <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
                <Button outline icon={<PlusOutlined />}>
                  Add filter
                </Button>
              </Dropdown>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default DashboardChartFilterList;
