import React, { useState } from "react";
import { getStringFromTimeStamp } from "../../../../utils/time";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { CommentIcon } from "../../icons";
import {
  ServiceNowTypeIcon,
  JiraTypeIcon,
} from "../../../integrations/integration-icon";
import { IncidentCommentLogType } from "../../../../utils/enums";

import "./activity-tab.scss";

const ActivityType = Object.freeze({
  COMMENT: "comment",
  TICKET: "ticket",
});

const ReportType = Object.freeze({
  JIRA: "jira",
  SERVICENOW: "servicenow",
});

const reportTypeNames = {
  [ReportType.JIRA]: "Jira",
  [ReportType.SERVICENOW]: "ServiceNow",
};

const reportTypeIcons = {
  [ReportType.JIRA]: <JiraTypeIcon />,
  [ReportType.SERVICENOW]: <ServiceNowTypeIcon />,
};

function ActivityItem(props) {
  const { icon, content, description, actionConfig = {} } = props;
  const [isHover, setIsHover] = useState(false);

  function onMouseEnter() {
    setIsHover(true);
  }

  function onMouseLeave() {
    setIsHover(false);
  }

  return (
    <div
      className={`activity-item-container${isHover ? " hover" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="activity-item-panel-container">
        <div className="activity-item-icon-container">{icon}</div>
        <div>
          <div className="activity-item-content-container">{content}</div>
          <div className="activity-item-description-container">{description}</div>
        </div>
      </div>
      {isHover && actionConfig.enableEdit && (
        <EditOutlined onClick={actionConfig.onEdit} />
      )}
      {isHover && actionConfig.enableDelete && (
        <DeleteOutlined onClick={actionConfig.onDelete} />
      )}
    </div>
  );
}

function TicketActivityItem(props) {
  const {
    value: {
      timestamp,
      config: { link, reportType, name },
      extra,
    },
    onCaseUpdated,
  } = props;

  // Older tickets might not have a name associated with them. For these,
  // use the last segment of the URL link (this works for JIRA).
  const ticketName = name ?? link.substring(link.lastIndexOf("/") + 1);

  const icon = reportTypeIcons[reportType];

  const content = (
    <span>
      {reportTypeNames[reportType]} ticket{" "}
      <a href={link} rel="noreferrer" target="_blank">
        {ticketName}
      </a>{" "}
      submitted
    </span>
  );

  const description = <span>{getStringFromTimeStamp(timestamp)}</span>;

  function onDeleteJiraTicket() {
    const { caseItem, report } = extra;
    caseItem.config.reports = caseItem.config.reports.filter(
      (currentReportItem) =>
        currentReportItem.type !== report.type ||
        currentReportItem.value !== report.value
    );

    onCaseUpdated(caseItem);
  }

  return (
    <ActivityItem
      icon={icon}
      content={content}
      description={description}
      actionConfig={{ enableDelete: true, onDelete: onDeleteJiraTicket }}
    />
  );
}

function CommentActivityItem(props) {
  const {
    value: {
      timestamp,
      config: { comment, createdBy },
      extra,
    },
    onDelete,
    onEdit,
    enableEditDeleteComment,
  } = props;

  const isEditableDeletable = enableEditDeleteComment && extra.raw.isEditable;
  const isUserGenerated = extra.raw.type === IncidentCommentLogType.USER_GENERATED;
  const commentPrefix = isUserGenerated ? "Comment: " : "";
  const content = (
    <span>
      {commentPrefix}
      {comment}
    </span>
  );
  const createdByText = createdBy ? `${createdBy}   ` : "";
  const description = (
    <span>{`${createdByText}${getStringFromTimeStamp(timestamp)}`}</span>
  );

  function onDeleteComment() {
    onDelete(extra.raw);
  }

  function onEditComment() {
    onEdit(extra.raw);
  }

  return (
    <ActivityItem
      icon={<CommentIcon width={14} />}
      content={content}
      description={description}
      actionConfig={{
        enableDelete: isEditableDeletable,
        enableEdit: isEditableDeletable,
        onDelete: onDeleteComment,
        onEdit: onEditComment,
      }}
    />
  );
}

function ActivityTab(props) {
  const {
    incidentCommentList,
    incidentCaseList,
    onDeleteComment,
    onCaseUpdated,
    onEditComment,
    enableEditDeleteComment,
  } = props;

  const activityList = [];
  incidentCommentList.forEach((currentComment) => {
    activityList.push({
      type: ActivityType.COMMENT,
      timestamp: currentComment.createdTs,
      config: {
        comment: currentComment.comment,
        createdBy: currentComment.ownedBy?.username,
      },
      extra: {
        raw: currentComment,
      },
    });
  });

  incidentCaseList.forEach((currentCase) => {
    currentCase.config.reports.forEach((currentReport) => {
      activityList.push({
        type: ActivityType.TICKET,
        timestamp: currentReport.creationTs,
        config: {
          reportType: currentReport.type,
          name: currentReport.name,
          link: currentReport.value,
          createdBy: currentReport.createdBy,
        },
        extra: {
          caseItem: currentCase,
          report: currentReport,
        },
      });
    });
  });

  activityList.sort((a, b) => {
    const aCreationTs = a.timestamp;
    const bCreationTs = b.timestamp;
    if (aCreationTs === bCreationTs) {
      return 0;
    }

    if (aCreationTs > bCreationTs) {
      return -1;
    }

    return 1;
  });

  return (
    <div className="incident-activity-tab-container">
      {activityList.length === 0 && (
        <div className="incident-activity-tab-zero-state">
          Incident activity will be displayed here
        </div>
      )}
      {activityList.length > 0 &&
        activityList.map((activityItem, index) => {
          if (activityItem.type === ActivityType.TICKET) {
            return (
              <TicketActivityItem
                value={activityItem}
                key={index}
                onCaseUpdated={onCaseUpdated}
              />
            );
          } else if (activityItem.type === ActivityType.COMMENT) {
            return (
              <CommentActivityItem
                value={activityItem}
                key={index}
                onEdit={onEditComment}
                onDelete={onDeleteComment}
                enableEditDeleteComment={enableEditDeleteComment}
              />
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default ActivityTab;
