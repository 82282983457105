import React, { Component } from "react";
import { withRouter } from "react-router";
import MetricWizard from "../metric/";
import { getApprovalConfig } from "../../utils/approvals";

class AddKPIView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workspaceUuid: "",
      isDataCompare: undefined,
      approvalsConfig: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onSaveDraft = this.onSaveDraft.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDataSourceChanged = this.onDataSourceChanged.bind(this);
    this.onTableChanged = this.onTableChanged.bind(this);
    this.onSrcDataSourceChanged = this.onSrcDataSourceChanged.bind(this);
    this.onTableColumnChanged = this.onTableColumnChanged.bind(this);
    this.onSrcTableChanged = this.onSrcTableChanged.bind(this);
    this.onInColumnTableChanged = this.onInColumnTableChanged.bind(this);
    this.getCurrentKpiPartitionSampleData =
      this.getCurrentKpiPartitionSampleData.bind(this);
    this.getCurrentKpiSrcPartitionSampleData =
      this.getCurrentKpiSrcPartitionSampleData.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: {
        params: { workspaceUuid },
      },
      isDataCompare,
    } = props;
    let isChanged = false;
    if (workspaceUuid !== state.workspaceUuid) {
      props.getDataSourceList(workspaceUuid);
      props.getTagList(workspaceUuid);
      props.getIntegrationList(workspaceUuid);
      const currentWorkspace = props.workspaceList?.find(
        ({ uuid }) => uuid === workspaceUuid
      );
      const approvalsConfig = getApprovalConfig(
        currentWorkspace,
        props.workspaceUserPermissions
      );
      state = {
        ...state,
        workspaceUuid,
        approvalsConfig,
      };
      isChanged = true;
    }

    if (isDataCompare !== state.isDataCompare) {
      props.getKpiList(workspaceUuid);
      state = {
        ...state,
        isDataCompare,
      };
      isChanged = true;
    }

    if (isChanged) {
      return state;
    }

    return null;
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel();
  }

  onSave(newKPI, newRuleList, _) {
    return this.props.onSave(newKPI, newRuleList);
  }

  onSaveDraft(newDraft, newRuleList, _) {
    return this.props.onSave(newDraft, newRuleList, { isDraft: true });
  }

  onDataSourceChanged(workspaceUuid, dataSource) {
    this.props.resetCurrentCustomSqlKpiTableColumnList();
    this.props.getCurrentKpiSchemaList(workspaceUuid, dataSource, false);
    this.props.getCurrentKpiTableList(workspaceUuid, dataSource);
  }

  onSrcDataSourceChanged(workspaceUuid, dataSource) {
    this.props.getCurrentKpiSchemaList(workspaceUuid, dataSource, true);
    this.props.getCurrentKpiSrcTableList(workspaceUuid, dataSource);
  }

  onSchemaChanged(_workspaceUuid, _dataSource, _schemaName) {
    this.props.resetCurrentCustomSqlKpiTableColumnList();
  }

  onTableChanged(workspaceUuid, dataSourceUuid, tableUuid) {
    this.props.getCurrentKpiTableColumnList(workspaceUuid, dataSourceUuid, tableUuid);
  }

  onSrcTableChanged(workspaceUuid, dataSourceUuid, tableUuid) {
    this.props.getCurrentKpiSrcTableColumnList(
      workspaceUuid,
      dataSourceUuid,
      tableUuid
    );
  }

  onCustomSqlTableChanged(workspaceUuid, dataSource, tableUuid) {
    this.props.resetCurrentCustomSqlKpiTableColumnList();
    if (tableUuid) {
      this.props.getCurrentCustomSqlKpiTableColumnList(
        workspaceUuid,
        dataSource,
        tableUuid
      );
    }
  }

  onInColumnTableChanged(workspaceUuid, dataSourceUuid, tableUuid) {
    this.props.getCurrentKpiInColumnColumnList(
      workspaceUuid,
      dataSourceUuid,
      tableUuid
    );
  }

  onTableColumnChanged(workspaceUuid, kpi) {
    this.props.getCurrentKpiTableColumnValueList(workspaceUuid, kpi);
  }

  getCurrentKpiPartitionSampleData(dataSourceUuid, table) {
    this.props.getCurrentKpiPartitionSampleData(
      this.props.match.params.workspaceUuid,
      dataSourceUuid,
      table
    );
  }

  getCurrentKpiSrcPartitionSampleData(dataSourceUuid, table) {
    this.props.getCurrentKpiSrcPartitionSampleData(
      this.props.match.params.workspaceUuid,
      dataSourceUuid,
      table
    );
  }

  render() {
    const { workspaceUuid, approvalsConfig } = this.state;
    return (
      <MetricWizard
        waffle={this.props.waffle}
        dataSourceList={this.props.dataSourceList}
        kpiList={this.props.kpiList}
        currentSchemaList={this.props.currentSchemaList}
        currentSrcSchemaList={this.props.currentSrcSchemaList}
        currentTableList={this.props.currentTableList}
        currentSrcTableList={this.props.currentSrcTableList}
        currentColumnList={this.props.currentColumnList}
        currentCustomSqlColumnList={this.props.currentCustomSqlColumnList}
        currentSrcColumnList={this.props.currentSrcColumnList}
        currentInColumnColumnList={this.props.currentInColumnColumnList}
        currentColumnValueList={this.props.currentColumnValueList}
        currentFullCompareSrcTableColumnValueList={
          this.props.currentFullCompareSrcTableColumnValueList
        }
        currentFullCompareTargetTableColumnValueList={
          this.props.currentFullCompareTargetTableColumnValueList
        }
        currentColumnInfo={this.props.currentColumnInfo}
        lastPreviewStatus={this.props.lastPreviewStatus}
        lastPreviewResult={this.props.lastPreviewResult}
        lastPreviewTime={this.props.lastPreviewTime}
        previewKpi={this.props.previewKpi.bind(this.props, workspaceUuid)}
        terminateKpiPreview={this.props.terminateKpiPreview.bind(
          this.props,
          workspaceUuid
        )}
        verifyKpiCustomSql={this.props.verifyKpiCustomSql.bind(
          this.props,
          workspaceUuid
        )}
        resetKPIStatus={this.props.resetKPIStatus}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onDataSourceChanged={this.onDataSourceChanged.bind(this, workspaceUuid)}
        onSchemaChanged={this.onSchemaChanged.bind(this, workspaceUuid)}
        onSrcDataSourceChanged={this.onSrcDataSourceChanged.bind(this, workspaceUuid)}
        onTableChanged={this.onTableChanged.bind(this, workspaceUuid)}
        onCustomSqlTableChanged={this.onCustomSqlTableChanged.bind(this, workspaceUuid)}
        onSrcTableChanged={this.onSrcTableChanged.bind(this, workspaceUuid)}
        onInColumnTableChanged={this.onInColumnTableChanged.bind(this, workspaceUuid)}
        onTableColumnChanged={this.onTableColumnChanged.bind(this, workspaceUuid)}
        getKpiList={this.props.getKpiList.bind(this, workspaceUuid)}
        currentKpiPartitionSampleData={this.props.currentKpiPartitionSampleData}
        getCurrentKpiPartitionSampleData={this.getCurrentKpiPartitionSampleData}
        currentKpiSrcPartitionSampleData={this.props.currentKpiSrcPartitionSampleData}
        getCurrentKpiSrcPartitionSampleData={this.getCurrentKpiSrcPartitionSampleData}
        getCurrentKpiSampleDataTableSchemaList={this.props.getCurrentKpiSampleDataTableSchemaList.bind(
          this.props,
          workspaceUuid
        )}
        currentKpiSampleDataTableSchemaList={
          this.props.currentKpiSampleDataTableSchemaList
        }
        getCurrentKpiSampleDataSampleDataList={this.props.getCurrentKpiSampleDataSampleDataList.bind(
          this.props,
          workspaceUuid
        )}
        currentKpiSampleDataSampleDataList={
          this.props.currentKpiSampleDataSampleDataList
        }
        currentKpiValidateFailingRecordsSqlResult={
          this.props.currentKpiValidateFailingRecordsSqlResult
        }
        validateKpiFailingRecordsSql={this.props.validateKpiFailingRecordsSql.bind(
          this.props,
          workspaceUuid
        )}
        tagList={this.props.tagList}
        isEdit={false}
        isClone={!!this.props.isClone}
        isDataCompare={this.props.isDataCompare}
        defaultData={this.props.defaultData}
        approvalsConfig={approvalsConfig}
        integrationList={this.props.integrationList}
        metricCategoryWhiteList={this.props.metricCategoryWhiteList}
        onCreateDraft={this.onSaveDraft}
      />
    );
  }
}

export default withRouter(AddKPIView);
