import React, { Component } from "react";
import { Redirect } from "react-router";
import UniversalAuth0Login from "./universal-auth0-login";
import auth0, { UNVERIFIED_EMAIL_CODE } from "../../utils/auth0";
import LightupLogo from "../../assets/img/LightupLogo_dark_1x.png";
import { LabeledInput } from "../../components/labeled-control/labeled-control";
import NgButton from "../../components/button/ng-button";
import { EVENT, initializeTracking, trackEvent } from "../../utils/telemetry";
import UnverifiedEmail from "../../components/trial/unverified-email";

import "./login.scss";

class LoginView extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
    };
    this.nextUrl = null;

    this.onSubmit = this.onSubmit.bind(this);

    this.onAuth0LoginSuccess = this.onAuth0LoginSuccess.bind(this);
    this.onAuth0LoginFailure = this.onAuth0LoginFailure.bind(this);
  }

  componentDidMount() {
    initializeTracking();
  }

  onSubmit() {
    const authInfo = { username: this.state.username, password: this.state.password };
    this.nextUrl = null;
    this.props.login(authInfo);
  }

  onAuth0LoginSuccess({ idToken: auth0IdToken, nextUrl }) {
    console.log(`Auth0 id token is ${auth0IdToken}`);
    const authInfo = { auth0IdToken };
    this.nextUrl = nextUrl;
    console.log(`Auth0 id token is ${auth0IdToken}`);
    this.props.login(authInfo);
  }

  onAuth0LoginFailure(err) {
    if (err.code === UNVERIFIED_EMAIL_CODE) {
      this.props.setCurrentUserInfo({ isUserEmailUnverified: true });
      console.log("User email is unverified");
    } else {
      auth0.logout();
      console.log("Fail to login in with auth0");
    }
  }

  render() {
    const {
      userInfo: { auth0ClientId, auth0DomainName, loginSucceed, isUserEmailUnverified },
      location,
    } = this.props;
    const { username, password } = this.state;

    if (isUserEmailUnverified) {
      return <UnverifiedEmail logout={this.props.logout} />;
    }

    let fromLocation = "/";
    if (this.nextUrl) {
      fromLocation = this.nextUrl;
    } else if (location.state && location.state.from && location.state.from.pathname) {
      fromLocation =
        location.state && location.state.from && location.state.from.pathname;
    }

    if (loginSucceed) {
      trackEvent(EVENT.USER_LOGIN, {
        url: window?.location?.href || "",
      });
      console.log(`Going to ${JSON.stringify(fromLocation)} from login`);
      this.nextUrl = null;
      return <Redirect push to={fromLocation} />;
    }

    if (!!auth0ClientId) {
      console.log(
        `We are in saas mode. Oauth login page only with ${auth0ClientId} and ${auth0DomainName}`
      );
      return (
        <UniversalAuth0Login
          clientId={auth0ClientId}
          hostedDomain={auth0DomainName}
          onSuccess={this.onAuth0LoginSuccess}
          onFailure={this.onAuth0LoginFailure}
          fromLocation={fromLocation}
        />
      );
    }

    return (
      <div className="login-page">
        <div className="login-box">
          <div className="login-header">
            <img className="login-logo" src={LightupLogo} alt="Lightup Logo" />
          </div>
          <div className="login-fields">
            <LabeledInput
              label="Username"
              staticLabel
              value={username}
              onChange={(e) => this.setState({ username: e.target.value })}
            />
            <LabeledInput
              label="Password"
              type="password"
              staticLabel
              value={password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <NgButton
              className="login-button"
              primary
              block
              size="large"
              onClick={() => this.onSubmit()}
              disabled={!username || !password}
            >
              Login
            </NgButton>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginView;
