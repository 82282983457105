import React, { useState, useMemo } from "react";
import { Input } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import Button from "../../button/ng-button";
import Table from "../../table/ng-table";
import TakeoverPanel from "../../takeover/takeover-panel";
import { getStringFromTimeStamp } from "../../../utils/time";
import { fnSorter } from "../../../utils/sort";

import "./event-bar-chart-detail-table-view.scss";

function EventBarChartDetailTableView(props) {
  const { title, data, closeTakeover } = props;
  const [searchText, setSearchText] = useState("");

  function onCloseClick() {
    closeTakeover();
  }

  console.log(data);
  const { timestamp, value } = data;
  const cornerControls = (
    <div className="event-bar-chart-detail-table-view-panel-controls">
      <Button outline size="large" onClick={onCloseClick}>
        Close
      </Button>
    </div>
  );

  const filteredValue = useMemo(() => {
    if (!setSearchText) {
      return value;
    }

    return value.filter((currentValue) => {
      return (
        currentValue.eventDetail.msg
          .toLowerCase()
          .indexOf(searchText.toLowerCase().trim()) !== -1
      );
    });
  }, [value, searchText]);

  const globalTimestamp = timestamp;
  const columns = [
    {
      title: "Time",
      key: "eventTs",
      dataIndex: ["eventTs"],
      render: function (timestamp) {
        return getStringFromTimeStamp(timestamp || globalTimestamp, { graph: true });
      },
      sorter: { compare: fnSorter((row) => row.eventTs) },
    },
    {
      title: "Description",
      key: "msg",
      dataIndex: ["eventDetail", "msg"],
      sorter: { compare: fnSorter((row) => row.eventDetail.msg) },
    },
  ];

  const infoMsg = `${value.length} Changes Detected - ${getStringFromTimeStamp(
    timestamp,
    { onlyDate: true }
  )}`;

  return (
    <TakeoverPanel
      title={title}
      titleIcon={<SettingOutlined />}
      cornerControls={cornerControls}
    >
      <div className="event-bar-chart-detail-table-view-content-container">
        <div className="event-bar-chart-detail-table-view-content-header-container">
          <div className="event-bar-chart-detail-table-view-content-header-info-container">
            {infoMsg}
          </div>
          <Input.Search
            allowClear={true}
            placeholder={"Search..."}
            value={searchText}
            onChange={(e) =>
              searchText !== e.target.value && setSearchText(e.target.value)
            }
            style={{ width: 300 }}
          />
        </div>
        <div className="event-bar-chart-detail-table-view-content-table-container">
          <Table
            dataSource={filteredValue}
            columns={columns}
            rowKey={(record) => record.uuid}
            size={"middle"}
            pagination={{
              position: ["bottomRight"],
              hideOnSinglePage: true,
            }}
            sortDirections={["ascend", "descend", "ascend"]}
          />
        </div>
      </div>
    </TakeoverPanel>
  );
}

export default EventBarChartDetailTableView;
