import React, { useState } from "react";
import NgDropdownMenu from "../../../ng-dropdown-menu";
import ConfirmationDialog from "../../../confirmation-dialog/ng-index";
import { DeleteOutlined } from "@ant-design/icons";
import "./rule-preview-config-menu.scss";

function RulePreviewConfigMenu(props) {
  const { onPreviewDeleteClicked } = props;
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    {
      label: "Delete preview",
      icon: <DeleteOutlined />,
      onClick: () => setIsOpen(true),
    },
  ];

  return (
    <div className="rule-preview-config-menu-container">
      <NgDropdownMenu menuItems={menuItems} />
      <ConfirmationDialog
        modalIsOpen={isOpen}
        setModalIsOpen={setIsOpen}
        okClicked={() => onPreviewDeleteClicked()}
        okText={"OK"}
        showCancelButton={true}
        title={"Delete preview"}
        confirmationMsg={
          "Are you sure you want to cancel this preview? You cannot undo this action"
        }
      />
    </div>
  );
}

export default RulePreviewConfigMenu;
