import { NextGenPalette, BrightColorSet } from "../../utils/color";
import { IncidentScore, IncidentValidationStatus } from "../../utils/enums";

export const timestampDateTickFormat = "%b %d, %Y";
export const timestampDateTimeTickFormat = "%b %d, %Y, %H:%M";

export function timestampXAxisConfig() {
  return {
    zeroline: false,
    showgrid: false,
    showline: true,
    linewidth: 1,
    linecolor: NextGenPalette.darkGrey,
    tickfont: {
      size: 10,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      color: NextGenPalette.slate,
    },
    tickformat: timestampDateTickFormat,
    hoverformat: timestampDateTimeTickFormat,
    ticks: "outside",
    ticklen: 6,
    tickcolor: "rgb(255, 255, 255)",
    fixedrange: true,
  };
}

export function yAxisConfig(title) {
  const yAxis = {
    showgrid: false,
    zeroline: false,
    showline: false,
    linewidth: 1,
    linecolor: NextGenPalette.darkGrey,
    tickfont: {
      size: 10,
      family: "Neue-Haas-Unica-W1G, Sans-serif",
      color: NextGenPalette.slate,
    },
    ticks: "outside",
    ticklen: 2,
    tickcolor: "rgb(255, 255, 255)",
    titlefont: { color: NextGenPalette.slate },
    overlaying: "y",
    side: "left",
    fixedrange: true,
    automargin: true,
  };

  if (title) {
    Object.assign(yAxis, {
      title: {
        text: title,
        font: {
          color: NextGenPalette.slate,
          family: "Neue-Haas-Unica-W1G, Sans-serif",
          size: 10,
        },
      },
    });
  }

  return yAxis;
}

export function plotConfig() {
  return {
    displayModeBar: false,
    showlegend: false,
    responsive: true,
  };
}

export function getIncidentColorCode(incident) {
  if (incident?.validationStatus === IncidentValidationStatus.RESOLVED) {
    return BrightColorSet.RESOLVED_INCIDENT;
  }

  if (incident?.score === IncidentScore.HIGH) {
    return BrightColorSet.HIGH_IMPACT_INCIDENT;
  }

  return BrightColorSet.INCIDENT;
}
