import React from "react";

export function WorkspacesAdminIcon(props = {}) {
  return (
    <svg viewBox="0 0 14 14" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05536 0.700919C6.20128 0.811166 6.28705 0.983466 6.28705 1.16635L6.28702 4.87574C6.28702 5.13652 6.11393 5.36562 5.86307 5.43687L2.49271 6.39415C2.31679 6.44412 2.12761 6.40869 1.98169 6.29844C1.83577 6.1882 1.75 6.0159 1.75 5.83301L1.75001 2.12361C1.75001 1.86283 1.9231 1.63373 2.17396 1.56247L5.54434 0.605207C5.72027 0.55524 5.90944 0.590672 6.05536 0.700919ZM2.91667 2.56434L2.91667 5.06092L5.12036 4.43501L5.12038 1.93843L2.91667 2.56434ZM10.9893 4.85522C11.1305 4.96578 11.213 5.13515 11.213 5.31449V12.0007C11.213 12.269 11.03 12.5027 10.7695 12.567L7.39915 13.3993C7.22504 13.4423 7.04084 13.4029 6.89964 13.2923C6.75845 13.1817 6.67596 13.0123 6.67596 12.833V6.14682C6.67596 5.87852 6.85896 5.64482 7.11944 5.5805L10.4898 4.74817C10.6639 4.70517 10.8481 4.74465 10.9893 4.85522ZM7.84262 6.60362V12.0881L10.0463 11.5439V6.0594L7.84262 6.60362ZM6.05536 6.0656C6.20128 6.17585 6.28705 6.34815 6.28705 6.53103L6.28702 10.2404C6.28702 10.5012 6.11393 10.7303 5.86307 10.8016L2.49271 11.7588C2.31679 11.8088 2.12761 11.7734 1.98169 11.6631C1.83577 11.5529 1.75 11.3806 1.75 11.1977L1.75001 7.48828C1.75001 7.22749 1.9231 6.99839 2.17397 6.92714L5.54434 5.96989C5.72027 5.91992 5.90945 5.95536 6.05536 6.0656ZM2.91667 7.92901L2.91667 10.4256L5.12036 9.79969L5.12038 7.30311L2.91667 7.92901Z"
        fill="#121224"
        fillOpacity="0.85"
      />
    </svg>
  );
}
