import React from "react";
import PagerDutyIntegrationBasicDialog from "./pagerduty-integration-basic-dialog";
import { DefaultTimeZone } from "../../../utils/time";

function AddPagerDutyIntegrationDialog(props) {
  const { onAdd, onPreview, modalIsOpen, setIsOpen } = props;
  const defaultPagerDutyInstance = {
    title: "",
    routingKey: "",
    description: "",
    severity: "info",
    timezone: DefaultTimeZone,
  };

  const onOkClicked = (newData) => {
    onAdd(newData);
  };

  return (
    <PagerDutyIntegrationBasicDialog
      defaultData={defaultPagerDutyInstance}
      className={"add-pager-duty-integration-dialog-container"}
      dialogTitle={"Add PagerDuty Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      OKText={"Add"}
      enablePreview={true}
      enableSave={true}
    />
  );
}

export default AddPagerDutyIntegrationDialog;
