import React from "react";
import { fnSorter } from "../../utils/sort";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { ProfileSchemaIcon, ProfileTableIcon } from "./icons";
import { getIconFromColumn } from "./column-icons";
import { getDataSourceIcon } from "../../utils/icon";
import { metricCategoryIconComponent } from "../../components/metric/fields/icons";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { classesName } from "../../utils/css";
import { highlightQueryString } from "../../utils/search";
import { isSomeMetadataMetricEnabled } from "../../utils/table";

export const treeNodeMetricPermissions = [
  AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
  AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
];

export const treeNodeColumnPermissions = [
  AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
  AppPermissions.BACKEND_APPS_SOURCE_PROFILER_RESULT_VIEWS_VIEW_PROFILERCOLUMNEVENTLISTVIEW,
  AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
  AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
];

export function filterTreeBySearchValue(fullTreeData, searchValue) {
  searchValue = (searchValue || "").trim();
  fullTreeData = fullTreeData || [];
  if (!searchValue || fullTreeData.length === 0) {
    return [fullTreeData, []];
  }

  const filteredTree = [];
  const matchedKeys = [];

  for (let currentTreeNode of fullTreeData) {
    const { title, children = [], ...otherProperties } = currentTreeNode;

    // Traverse tree using DFS
    const [filteredChildren, childrenMatchedKeys] = filterTreeBySearchValue(
      children,
      searchValue
    );

    const isGroupingNode = typeof title !== "string";
    const isEmptyGrouping = isGroupingNode && filteredChildren.length === 0;

    const { match: isSearchMatch, highlighted } = !isGroupingNode
      ? highlightQueryString(searchValue, title, "tree-search-value")
      : { match: false, highlighted: title };
    const isFilteredNode = !isSearchMatch && filteredChildren.length === 0;

    if ((isSearchMatch && !isGroupingNode) || filteredChildren.length > 0) {
      matchedKeys.push(currentTreeNode.key);
    }

    if (isEmptyGrouping || isFilteredNode) {
      continue;
    }

    let filteredTitle;
    if (isGroupingNode) {
      filteredTitle = title;
    } else if (!isSearchMatch) {
      filteredTitle = <span>{title}</span>;
    } else {
      filteredTitle = highlighted;
    }

    filteredTree.push({
      title: filteredTitle,
      children: filteredChildren,
      ...otherProperties,
    });
    matchedKeys.push(...childrenMatchedKeys);
  }

  return [filteredTree, matchedKeys];
}

export function treeHeadingKey(parentInfo, childrenType) {
  const { dataSource, schemaInfo, tableInfo, columnInfo } = parentInfo;
  const [parentType] = getSelectedNodeTypeAndId(parentInfo);
  const parentRank = ProfilerTreeNodeTypeRank[parentType];
  let key = `datasource-${dataSource.metadata.uuid}`;
  if (parentRank >= ProfilerTreeNodeTypeRank[ProfilerTreeNodeType.SCHEMA]) {
    key += `-schema-${schemaInfo.uuid}`;
  }
  if (parentRank >= ProfilerTreeNodeTypeRank[ProfilerTreeNodeType.TABLE]) {
    key += `-table-${tableInfo.uuid}`;
  }
  if (parentRank >= ProfilerTreeNodeTypeRank[ProfilerTreeNodeType.COLUMN]) {
    key += `-column-${columnInfo.uuid}`;
  }
  const childrenTypeStr = isCustomMetricType(childrenType)
    ? "custom-metrics"
    : `${childrenType}s`;
  key += `-${childrenTypeStr}`;
  return key;
}

export const ProfilerTreeNodeType = Object.freeze({
  DATASOURCE: "datasource",
  SCHEMA: "schema",
  TABLE: "table",
  COLUMN: "column",
  DATASOURCE_CHILD_METRIC: "datasourceChildMetric",
  TABLE_CHILD_METRIC: "tableChildMetric",
  COLUMN_CHILD_METRIC: "columnChildMetric",
});

export function isCustomMetricType(nodeType) {
  return [
    ProfilerTreeNodeType.DATASOURCE_CHILD_METRIC,
    ProfilerTreeNodeType.TABLE_CHILD_METRIC,
    ProfilerTreeNodeType.COLUMN_CHILD_METRIC,
  ].includes(nodeType);
}

export const ProfilerTreeNodeTypeRank = Object.freeze({
  [ProfilerTreeNodeType.DATASOURCE]: 0,
  [ProfilerTreeNodeType.SCHEMA]: 1,
  [ProfilerTreeNodeType.DATASOURCE_CHILD_METRIC]: 1,
  [ProfilerTreeNodeType.TABLE]: 2,
  [ProfilerTreeNodeType.COLUMN]: 3,
  [ProfilerTreeNodeType.TABLE_CHILD_METRIC]: 3,
  [ProfilerTreeNodeType.COLUMN_CHILD_METRIC]: 4,
});

export function getSelectedNodeTypeAndId(node = {}) {
  const { dataSource, schemaInfo, tableInfo, columnInfo, metric } = node ?? {};
  if (dataSource && !schemaInfo && !metric) {
    return [ProfilerTreeNodeType.DATASOURCE, dataSource.metadata.uuid];
  }
  if (dataSource && schemaInfo && !tableInfo) {
    return [ProfilerTreeNodeType.SCHEMA, schemaInfo.uuid];
  }
  if (dataSource && metric && !tableInfo) {
    return [ProfilerTreeNodeType.DATASOURCE_CHILD_METRIC, metric.uuid];
  }
  if (dataSource && schemaInfo && tableInfo && !metric && !columnInfo) {
    return [ProfilerTreeNodeType.TABLE, tableInfo.uuid];
  }
  if (dataSource && schemaInfo && tableInfo && metric && !columnInfo) {
    return [ProfilerTreeNodeType.TABLE_CHILD_METRIC, metric.uuid];
  }
  if (dataSource && schemaInfo && tableInfo && columnInfo && !metric) {
    return [ProfilerTreeNodeType.COLUMN, columnInfo.uuid];
  }
  if (dataSource && schemaInfo && tableInfo && columnInfo && metric) {
    return [ProfilerTreeNodeType.COLUMN_CHILD_METRIC, metric.uuid];
  }
  return [null, null];
}

// Returns the non-grouping menu parent of childNodeInfo. Returns childNodeInfo when there
// is no parent (i.e. when childNodeInfo represents a dataSource).
function parentNodeInfo(childNodeInfo) {
  const [childType] = getSelectedNodeTypeAndId(childNodeInfo);
  let parentInfo = { ...childNodeInfo };
  switch (childType) {
    case ProfilerTreeNodeType.DATASOURCE:
      break;
    case ProfilerTreeNodeType.SCHEMA:
      parentInfo.schemaInfo = null;
      break;
    case ProfilerTreeNodeType.TABLE:
      parentInfo.tableInfo = null;
      break;
    case ProfilerTreeNodeType.COLUMN:
      parentInfo.columnInfo = null;
      break;
    case ProfilerTreeNodeType.DATASOURCE_CHILD_METRIC:
    case ProfilerTreeNodeType.TABLE_CHILD_METRIC:
    case ProfilerTreeNodeType.COLUMN_CHILD_METRIC:
      parentInfo.metric = null;
      break;
    default:
      parentInfo = null;
  }
  return parentInfo;
}

function* parentNodeKeys(childNodeInfo) {
  let [childType] = getSelectedNodeTypeAndId(childNodeInfo);
  let parentInfo = parentNodeInfo(childNodeInfo);
  let [parentType, parentId] = getSelectedNodeTypeAndId(parentInfo);
  while (parentType !== childType) {
    yield treeHeadingKey(parentInfo, childType);
    yield parentId;
    childType = parentType;
    parentInfo = parentNodeInfo(parentInfo);
    [parentType, parentId] = getSelectedNodeTypeAndId(parentInfo);
  }
}

export function getInitialExpandedAndSelectedNodes(selectedNode, dataSourceUuid) {
  const expandedKeys = new Set();
  const selectedKeys = new Set();

  if (!selectedNode || selectedNode.dataSource.metadata.uuid !== dataSourceUuid) {
    return [expandedKeys, selectedKeys];
  }

  const [, selectedNodeId] = getSelectedNodeTypeAndId(selectedNode);
  selectedKeys.add(selectedNodeId);
  for (let parentKey of parentNodeKeys(selectedNode)) {
    expandedKeys.add(parentKey);
  }

  return [expandedKeys, selectedKeys];
}

export function getSearchMatchExpandedKeys(searchMatchedNodes) {
  return searchMatchedNodes.reduce((expandedKeys, matchedNode) => {
    const parentInfo = parentNodeInfo(matchedNode);
    const [nodeType] = getSelectedNodeTypeAndId(matchedNode);
    expandedKeys.push(treeHeadingKey(parentInfo, nodeType));
    return expandedKeys;
  }, []);
}

function IconContainer(props) {
  const { icon, overrideFill = true } = props;
  const className = classesName(
    "profiler-datasource-node-icon-container",
    overrideFill && "override-fill"
  );
  return <div className={className}>{icon}</div>;
}

function TreeTitleNode(props) {
  const { title } = props;
  return <span className="profiler-data-source-node-tree-title">{title}</span>;
}

const schemaSortFn = fnSorter((s) => s.name);
const tableSortFn = fnSorter((t) => t.tableName);
const columnSortFn = fnSorter((c) => c.columnName);
const metricSortFn = fnSorter((m) => m.name);

function metricsNodeAtLevel(
  pathArgs,
  metrics,
  workspaceUserPermissions,
  metricLookup = {}
) {
  const canViewMetricsNodes = hasPermission(
    workspaceUserPermissions,
    treeNodeMetricPermissions
  );
  if (!canViewMetricsNodes) {
    return [null, {}];
  }

  const metricsGroupChildren = [];
  const idToSelectNodeMapper = {};

  for (let metric of metrics.sort(metricSortFn)) {
    const { name, uuid } = metric;

    idToSelectNodeMapper[uuid] = {
      ...pathArgs,
      metric: metric,
    };

    const TypeIcon = metricLookup[uuid]
      ? metricCategoryIconComponent(getMetricTypeFromConfigData(metricLookup[uuid]))
      : null;

    const icon = TypeIcon ? <TypeIcon /> : null;

    metricsGroupChildren.push({
      title: name || "Custom Metric",
      key: uuid,
      icon: <IconContainer icon={icon} />,
      isLeaf: true,
      children: [],
    });
  }

  let node = null;
  if (metricsGroupChildren.length > 0) {
    const [childrenType] = getSelectedNodeTypeAndId(
      idToSelectNodeMapper[metricsGroupChildren[0].key]
    );
    node = {
      title: <TreeTitleNode title={`Metrics (${metricsGroupChildren.length})`} />,
      key: treeHeadingKey(pathArgs, childrenType),
      selectable: false,
      isLeaf: false,
      children: metricsGroupChildren,
    };
  }
  return [node, idToSelectNodeMapper];
}

function schemasNode(pathArgs, schemas, workspaceUserPermissions, metricLookup) {
  const schemasGroupChildren = [];
  const idToSelectNodeMapper = {};

  for (let schema of schemas.sort(schemaSortFn)) {
    const tables = schema.tables || [];

    const nodeInfo = {
      ...pathArgs,
      schemaInfo: schema,
    };
    idToSelectNodeMapper[schema.uuid] = nodeInfo;

    const schemaChildren = [];

    const [tablesChild, tableAndColMapper] = tablesNode(
      nodeInfo,
      tables,
      workspaceUserPermissions,
      metricLookup
    );

    Object.assign(idToSelectNodeMapper, tableAndColMapper);

    const icon = <ProfileSchemaIcon width={14} viewBox="0 0 22 22" />;

    if (tablesChild) {
      schemaChildren.push(tablesChild);
    }

    schemasGroupChildren.push({
      title: schema.name,
      key: schema.uuid,
      icon: <IconContainer icon={icon} />,
      isLeaf: schemaChildren.length === 0,
      children: schemaChildren,
    });
  }

  const node =
    schemasGroupChildren.length > 0
      ? {
          title: <TreeTitleNode title={`Schemas (${schemasGroupChildren.length})`} />,
          key: treeHeadingKey(pathArgs, ProfilerTreeNodeType.SCHEMA),
          selectable: false,
          isLeaf: false,
          children: schemasGroupChildren,
        }
      : null;

  return [node, idToSelectNodeMapper];
}

export function showTableNode(tableInfo) {
  return Boolean(
    tableInfo.profilerConfig?.enabled ||
      tableInfo.profilerConfig?.dataProfiler?.enabled ||
      isSomeMetadataMetricEnabled(tableInfo)
  );
}

function tablesNode(pathArgs, tables, workspaceUserPermissions, metricLookup) {
  const filteredTables = tables.filter(showTableNode).sort(tableSortFn);

  const tablesGroupChildren = [];
  const idToSelectedNodeMapper = {};

  for (let currentTable of filteredTables) {
    const nodeInfo = {
      ...pathArgs,
      tableInfo: currentTable,
    };
    idToSelectedNodeMapper[currentTable.uuid] = nodeInfo;
    const tableChildren = [];

    const columns = (currentTable.columns || []).sort(columnSortFn);
    const [columnsChild, colMapper] = columnsNode(
      nodeInfo,
      columns,
      workspaceUserPermissions,
      metricLookup
    );

    const metrics = currentTable.metrics || [];
    const [metricsChild, metricsMapper] = metricsNodeAtLevel(
      nodeInfo,
      metrics,
      workspaceUserPermissions,
      metricLookup
    );

    if (columnsChild) {
      tableChildren.push(columnsChild);
    }
    if (metricsChild) {
      tableChildren.push(metricsChild);
    }
    Object.assign(idToSelectedNodeMapper, colMapper, metricsMapper);

    tablesGroupChildren.push({
      title: currentTable.tableName,
      key: currentTable.uuid,
      icon: (
        <IconContainer
          icon={<ProfileTableIcon width={14} height={24} viewBox="0 0 22 22" />}
        />
      ),
      isLeaf: tableChildren.length === 0,
      children: tableChildren,
    });
  }

  const node =
    tablesGroupChildren.length > 0
      ? {
          title: <TreeTitleNode title={`Tables (${tablesGroupChildren.length})`} />,
          key: treeHeadingKey(pathArgs, ProfilerTreeNodeType.TABLE),
          selectable: false,
          isLeaf: false,
          children: tablesGroupChildren,
        }
      : null;

  return [node, idToSelectedNodeMapper];
}

function columnsNode(pathArgs, columns, workspaceUserPermissions, metricLookup) {
  const columnGroupChildren = [];
  const idToSelectedNodeMapper = {};

  for (let column of columns) {
    const mapped = {
      ...pathArgs,
      columnInfo: column,
    };
    idToSelectedNodeMapper[column.uuid] = mapped;
    const columnChildren = [];

    const enableColumnLevelData = hasPermission(
      workspaceUserPermissions,
      treeNodeColumnPermissions
    );

    const columnMetrics = column.metrics || [];
    const [columnMetricsNode, columnMetricsMapper] = metricsNodeAtLevel(
      mapped,
      columnMetrics,
      workspaceUserPermissions,
      metricLookup
    );
    Object.assign(idToSelectedNodeMapper, columnMetricsMapper);

    if (columnMetricsNode) {
      columnChildren.push(columnMetricsNode);
    }

    const TypeIcon = getIconFromColumn(column);
    columnGroupChildren.push({
      title: column.columnName,
      icon: <IconContainer icon={<TypeIcon height={24} />} />,
      isLeaf: columnChildren.length === 0,
      children: columnChildren,
      key: column.uuid,
      disabled: !enableColumnLevelData,
    });
  }

  const node =
    columnGroupChildren.length > 0
      ? {
          title: <TreeTitleNode title={`Columns (${columnGroupChildren.length})`} />,
          key: treeHeadingKey(pathArgs, ProfilerTreeNodeType.COLUMN),
          selectable: false,
          isLeaf: false,
          children: columnGroupChildren,
        }
      : null;

  return [node, idToSelectedNodeMapper];
}

export function treeData(dataSource, workspaceUserPermissions, metricLookup = {}) {
  const {
    metadata: { uuid, name = "" },
    config: {
      connection: { type: datasourceType },
    },
    schemas,
  } = dataSource;

  const idToSelectedNodeMapper = {};
  const mapped = {
    dataSource,
    schemaInfo: null,
    tableInfo: null,
    columnInfo: null,
  };
  idToSelectedNodeMapper[uuid] = mapped;

  const [schemaChildrenNode, schemaAndTableAndColMapper] = schemasNode(
    mapped,
    schemas,
    workspaceUserPermissions,
    metricLookup
  );

  const metrics = dataSource.metrics || [];
  const [metricsChildrenNode, metricsMapper] = metricsNodeAtLevel(
    { dataSource },
    metrics,
    workspaceUserPermissions,
    metricLookup
  );

  Object.assign(idToSelectedNodeMapper, schemaAndTableAndColMapper, metricsMapper);
  const dataSourceChildren = [];
  if (schemaChildrenNode) {
    dataSourceChildren.push(schemaChildrenNode);
  }
  if (metricsChildrenNode) {
    dataSourceChildren.push(metricsChildrenNode);
  }

  const DatasourceIcon = getDataSourceIcon(datasourceType);

  const root = [
    {
      icon: <IconContainer icon={<DatasourceIcon />} overrideFill={false} />,
      title: name,
      key: uuid,
      isLeaf: dataSourceChildren.length === 0,
      children: dataSourceChildren,
    },
  ];

  return [root, idToSelectedNodeMapper];
}

function* treeMetrics(dataSourceTree) {
  function* metricsSeq(metrics) {
    for (let metric of metrics) {
      yield metric;
    }
  }
  const dsMetrics = dataSourceTree.metrics || [];
  yield* metricsSeq(dsMetrics);
  for (let schema of dataSourceTree.schemas) {
    for (let table of schema.tables) {
      const tableMetrics = table.metrics || [];
      yield* metricsSeq(tableMetrics);
      for (let column of table.columns) {
        const columnMetrics = column.metrics || [];
        yield* metricsSeq(columnMetrics);
      }
    }
  }
}

export function findDatasourceTreeMetric(dataSourceTree, metricUuid) {
  const dsTreeMetrics = treeMetrics(dataSourceTree);
  for (let metric of dsTreeMetrics) {
    if (metric.uuid === metricUuid) {
      return metric;
    }
  }
  return null;
}
