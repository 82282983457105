import { MAX_BATCH_REQUEST_NUMBER } from "./constants";
import axios from "./api";
import { toast } from "../components/toast/toast";
import { NotificationTypeConst } from "./enums";

export default function batchAPIRequestPromise(batchPayloads = []) {
  if (batchPayloads.length === 0) {
    return Promise.resolve({ data: [] });
  }

  return new Promise((resolve, reject) => {
    const normalizedPayload = [];
    for (let i = 0; i < batchPayloads.length; i = i + MAX_BATCH_REQUEST_NUMBER) {
      normalizedPayload.push(batchPayloads.slice(i, i + MAX_BATCH_REQUEST_NUMBER));
    }

    Promise.all(
      normalizedPayload.map((currentPayload) =>
        axios.post("/batch/", currentPayload, { baseURL: "/api/v1/" })
      )
    )
      .then((allBatchResponses) => {
        const data = [];
        allBatchResponses.forEach((batchResponse) => {
          batchResponse.data.forEach((oneResponse) => {
            if (oneResponse.status_code >= 400) {
              let errorString = "Internal server error";
              if (oneResponse.data.error) {
                errorString = oneResponse.data.error;
              }
              toast(errorString, NotificationTypeConst.FAILURE);
            }
            data.push(oneResponse);
          });
        });
        resolve({ data });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
