import React from "react";
import { HiveAuthType } from "../../../utils/enums";
import { HostnameInput } from "./basic-config-view";
import { LabeledInput, LabeledSelect } from "../../labeled-control/labeled-control";

const HiveAuthTypeOptions = [
  { label: "No Auth", value: HiveAuthType.NO_AUTH },
  { label: "Kerberos", value: HiveAuthType.KERBEROS },
];

function commaSeparatedValuesToArray(valuesStr) {
  return (valuesStr ?? "").split(",").map((v) => v.trim());
}

function arrayToCommaSeparatedValues(valuesArr) {
  return (valuesArr ?? []).join(",");
}

function HiveConfigView(props) {
  const { value, onChange } = props;
  const {
    type,
    authType,
    host,
    port,
    user,
    password,
    krbAuthenticationServerIp,
    krbAuthenticationServerHostname,
    krbAuthenticationServerRealm,
    krbAuthenticationServerDomains,
    krbHiveHostname,
    krbHiveRealm,
    krbHiveDomains,
    krbPrincipal,
    krbKeytabFileBase64String,
  } = value;

  return (
    <>
      <LabeledSelect
        label="Auth type"
        staticLabel
        value={authType}
        onChange={(newAuthType) => {
          if (newAuthType === authType) {
            return;
          }

          const newValue = {
            type,
            authType: newAuthType,
            host: "",
            port: "",
          };

          if (newValue.authType === HiveAuthType.KERBEROS) {
            newValue.krbAuthenticationServerIp = "";
            newValue.krbAuthenticationServerHostname = "";
            newValue.krbAuthenticationServerRealm = "";
            newValue.krbAuthenticationServerDomains = [];
            newValue.krbHiveHostname = "";
            newValue.krbHiveRealm = "";
            newValue.krbHiveDomains = [];
            newValue.krbPrincipal = "";
            newValue.krbKeytabFileBase64String = "";
          } else if (newValue.authType === HiveAuthType.LDAP) {
            newValue.user = "";
            newValue.password = "";
          }

          onChange && onChange(newValue);
        }}
        options={HiveAuthTypeOptions}
      />
      <HostnameInput
        label="Hive host"
        host={host}
        port={port}
        value={value}
        onChange={(newValue) => {
          if (authType === HiveAuthType.LDAP) {
            newValue = { ...newValue, password: "" };
          }
          onChange({ ...newValue });
        }}
      />
      {authType === HiveAuthType.LDAP && (
        <LabeledInput
          label="Username"
          staticLabel
          value={user}
          autoComplete="off"
          onChange={(e) => {
            const newUser = e.target.value;
            onChange && onChange({ ...value, user: newUser });
          }}
        />
      )}
      {authType === HiveAuthType.LDAP && (
        <LabeledInput
          label="Password"
          staticLabel
          type="password"
          autoComplete="off"
          value={password}
          onChange={(e) => {
            const newPassword = e.target.value;
            onChange && onChange({ ...value, password: newPassword });
          }}
        />
      )}
      {authType === HiveAuthType.KERBEROS && (
        <>
          <LabeledInput
            label="Hive master node hostname"
            staticLabel
            value={krbHiveHostname}
            onChange={(e) => {
              const newKrbHiveHostname = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbHiveHostname: newKrbHiveHostname,
                });
            }}
          />
          <LabeledInput
            label="Hive cluster realm"
            staticLabel
            value={krbHiveRealm}
            onChange={(e) => {
              const newKrbHiveRealm = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbHiveRealm: newKrbHiveRealm,
                });
            }}
          />
          <LabeledInput
            label="Hive master node domains"
            staticLabel
            value={arrayToCommaSeparatedValues(krbHiveDomains)}
            onChange={(e) => {
              const newKrbHiveDomains = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbHiveDomains: commaSeparatedValuesToArray(newKrbHiveDomains),
                });
            }}
          />
          <LabeledInput
            label="Kerberos authentication server IP"
            staticLabel
            value={krbAuthenticationServerIp}
            onChange={(e) => {
              const newKrbAuthenticationServerIp = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbAuthenticationServerIp: newKrbAuthenticationServerIp,
                });
            }}
          />
          <LabeledInput
            label="Kerberos authentication server hostname"
            staticLabel
            value={krbAuthenticationServerHostname}
            onChange={(e) => {
              const newKrbAuthenticationServerHostname = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbAuthenticationServerHostname: newKrbAuthenticationServerHostname,
                });
            }}
          />
          <LabeledInput
            label="Kerberos authentication server realm"
            staticLabel
            value={krbAuthenticationServerRealm}
            onChange={(e) => {
              const newKrbAuthenticationServerRealm = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbAuthenticationServerRealm: newKrbAuthenticationServerRealm,
                });
            }}
          />
          <LabeledInput
            label="Kerberos authentication server domains"
            staticLabel
            value={arrayToCommaSeparatedValues(krbAuthenticationServerDomains)}
            onChange={(e) => {
              const newKrbAuthenticationServerDomains = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbAuthenticationServerDomains: commaSeparatedValuesToArray(
                    newKrbAuthenticationServerDomains
                  ),
                });
            }}
          />
          <LabeledInput
            label="Kerberos principal"
            staticLabel
            value={krbPrincipal}
            onChange={(e) => {
              const newKrbPrincipal = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbPrincipal: newKrbPrincipal,
                });
            }}
          />
          <LabeledInput
            label="Base64-encoded Kerberos keytab file"
            staticLabel
            type="password"
            value={krbKeytabFileBase64String}
            onChange={(e) => {
              const newKrbKeytabFileBase64String = e.target.value;
              onChange &&
                onChange({
                  ...value,
                  krbKeytabFileBase64String: newKrbKeytabFileBase64String,
                });
            }}
          />
        </>
      )}
    </>
  );
}

export default HiveConfigView;
