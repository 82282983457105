import { MetricCategory, TableColumnTypeCategory } from "./enums";

export function toggleColumnMetricType(column, metricType, enabled) {
  let updatedColumn = { ...column };
  switch (metricType) {
    case MetricCategory.DISTRIBUTION:
      if (column.profilerConfig.columnTypeCategory === TableColumnTypeCategory.STRING) {
        updatedColumn.profilerConfig.categoricalDistribution.enabled = enabled;
      } else if (
        column.profilerConfig.columnTypeCategory === TableColumnTypeCategory.NUMERIC
      ) {
        updatedColumn.profilerConfig.numericalDistribution.enabled = enabled;
      }
      break;
    case MetricCategory.NULL_PERCENT:
      updatedColumn.profilerConfig.missingValue.enabled = enabled;
      break;
    case MetricCategory.CATEGORY_ACTIVITY:
      updatedColumn.profilerConfig.categoryListChange.enabled = enabled;
      break;
    default:
      console.warn(`Attempted to toggle invalid column metric type: ${metricType}`);
  }
  return updatedColumn;
}

export function getColumnTypeCategory(column) {
  if (!column) {
    console.log("column object is empty");
    return "";
  }

  return column.profilerConfig?.columnTypeCategory || column.columnTypeCategory;
}
