export function DividingLineIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75004 2.91659C1.75004 2.59442 2.01121 2.33325 2.33337 2.33325H3.50004C3.82221 2.33325 4.08337 2.59442 4.08337 2.91659C4.08337 3.23875 3.82221 3.49992 3.50004 3.49992H2.33337C2.01121 3.49992 1.75004 3.23875 1.75004 2.91659Z"
        fill="#121224"
      />
      <path
        d="M0.583374 6.99992C0.583374 6.67775 0.844541 6.41659 1.16671 6.41659H12.8334C13.1555 6.41659 13.4167 6.67775 13.4167 6.99992C13.4167 7.32209 13.1555 7.58325 12.8334 7.58325H1.16671C0.844541 7.58325 0.583374 7.32209 0.583374 6.99992Z"
        fill="#121224"
      />
      <path
        d="M5.83337 2.33325C5.51121 2.33325 5.25004 2.59442 5.25004 2.91659C5.25004 3.23875 5.51121 3.49992 5.83337 3.49992H8.16671C8.48887 3.49992 8.75004 3.23875 8.75004 2.91659C8.75004 2.59442 8.48887 2.33325 8.16671 2.33325H5.83337Z"
        fill="#121224"
      />
      <path
        d="M9.91671 2.91659C9.91671 2.59442 10.1779 2.33325 10.5 2.33325H11.6667C11.9889 2.33325 12.25 2.59442 12.25 2.91659C12.25 3.23875 11.9889 3.49992 11.6667 3.49992H10.5C10.1779 3.49992 9.91671 3.23875 9.91671 2.91659Z"
        fill="#121224"
      />
      <path
        d="M2.33337 10.4999C2.01121 10.4999 1.75004 10.7611 1.75004 11.0833C1.75004 11.4054 2.01121 11.6666 2.33337 11.6666H3.50004C3.82221 11.6666 4.08337 11.4054 4.08337 11.0833C4.08337 10.7611 3.82221 10.4999 3.50004 10.4999H2.33337Z"
        fill="#121224"
      />
      <path
        d="M5.25004 11.0833C5.25004 10.7611 5.51121 10.4999 5.83337 10.4999H8.16671C8.48887 10.4999 8.75004 10.7611 8.75004 11.0833C8.75004 11.4054 8.48887 11.6666 8.16671 11.6666H5.83337C5.51121 11.6666 5.25004 11.4054 5.25004 11.0833Z"
        fill="#121224"
      />
      <path
        d="M10.5 10.4999C10.1779 10.4999 9.91671 10.7611 9.91671 11.0833C9.91671 11.4054 10.1779 11.6666 10.5 11.6666H11.6667C11.9889 11.6666 12.25 11.4054 12.25 11.0833C12.25 10.7611 11.9889 10.4999 11.6667 10.4999H10.5Z"
        fill="#121224"
      />
    </svg>
  );
}
