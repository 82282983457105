import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import NgMultiSelect from "../../components/multi-select/ng-multi-select";
import TableSchemaView from "../../components/metric/table-schema-view";
import TableSampleDataView from "../../components/metric/table-sample-data-view";
import ProfilerDataProfileInProgressView from "./profiler-data-profile-in-progress-view";
import ProfilerDataProfileColumnView from "./profiler-data-profile-column-view";
import ProfilerDataProfileConfig from "./profiler-data-profile-config";
import ProfilerDataSourceTableTimestampAnalysis from "./table/profiler-data-source-table-timestamp-analysis";
import { getStringFromTimeStamp } from "../../utils/time";
import { ConnectionHealth, DataProfileRunStatus, QueryScope } from "../../utils/enums";
import {
  getProfilerConfigTableColumnList,
  getProfilerTableDataProfileTable,
  getProfilerTableDataProfileTableDataProfile,
  getProfilerTableListPartitionSampleData,
  resetProfilerTableDataProfileTable,
  updateTableDataProfilerConfig,
} from "../../actions/profiler/profiler-action";
import {
  getCurrentKpiSampleDataTableSchemaList,
  getCurrentKpiSampleDataSampleDataList,
} from "../../actions/kpi/kpi-action";
import { v4 as uuidv4 } from "uuid";
import ButtonText from "../../components/button/button-text";
import {
  getDefaultDataProfilerConfig,
  isValidTableConfig,
} from "../../utils/data-profiling";
import { getConnectionHealth, isPartitionConfigEnabled } from "../../utils/datasource";
import { useInterval } from "../../utils/hooks";
import { DATA_PROFILE_POLLING_INVERVAL } from "./profiler";
import { getTableColumnSummary, getTotalSampleFromTableDataProfileData } from "./utils";
import ProfilerExceptionView from "./profiler-exception-view";
import ChartEnableView from "../../components/chart-view/chart-enable-view";

import { EVENT, PAGE, getTableDetailProps, trackEvent } from "../../utils/telemetry";

import "./profiler-data-profile-table-view.scss";

function getSampleDataRequestPayload(dataSourceUuid, tableUuid, dataProfileConfig) {
  const {
    randomSample = true,
    dataTimezone = "UTC",
    partitionTimezone = "UTC",
    timestampColumn = null,
    timeRange,
    partitions = [],
    queryScope: queryScopeFromConfig,
  } = dataProfileConfig;
  const queryScope = randomSample ? QueryScope.FULL_TABLE : queryScopeFromConfig;
  const normalizedPreviewPeriod =
    queryScope === QueryScope.TIME_RANGE
      ? {
          previewStartTs: timeRange.startTime,
          previewEndTs: timeRange.endTime,
        }
      : {};

  return {
    sourceUuid: dataSourceUuid,
    tableUuid,
    previewUuid: uuidv4(),
    queryScope,
    dataTimezone,
    partitionTimezone,
    timestampColumn,
    timestampColumnFunctions: null,
    partitions,
    ...normalizedPreviewPeriod,
  };
}

function PausedDataSourcePlaceholder() {
  const content = (
    <div className="profiler-paused-datasource-placeholder">
      Data will be profiled when data source is no longer paused
    </div>
  );
  return <ChartEnableView content={content} gridOnly />;
}

function ProfilerDataProfileTableView(props) {
  const {
    workspaceUuid,
    dataProfileData,
    dataSource,
    schema,
    table,
    tableDataProfile,
    currentTable,
    tableSchemaList,
    getTableSchemaList,
    tableSampleDataList,
    getTableSampleDataList,
    tableColumnList,
    getTableColumnList,
    enableSearch = true,
    onRefresh,
    selectedColumns: defaultSelectedColumn,
    tableListPartitionSampleData,
    getCurrentTable,
    resetCurrentTable,
    getCurrentTableDataProfile,
    getProfilerTableListPartitionSampleData,
    updateTableConfig,
  } = props;

  const [selectedColumns, setSelectedColumns] = useState(defaultSelectedColumn || []);
  const [dataProfileConfig, setDataProfileConfig] = useState(
    getDefaultDataProfilerConfig(currentTable)
  );
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);

  const columnsInProgress =
    !currentTable?.status?.dataProfilerStatus?.columnProfilesReady;
  const isConfigReady = isValidTableConfig(dataProfileConfig);

  const isDataSourcePaused =
    getConnectionHealth(dataSource) === ConnectionHealth.PAUSED;

  useInterval(() => {
    if (columnsInProgress) {
      onRefresh();
      getCurrentTableDataProfile(workspaceUuid, dataSource, table, { quiet: true });
      getCurrentTable(workspaceUuid, dataSource, table.uuid, { quiet: true });
    }
  }, DATA_PROFILE_POLLING_INVERVAL);

  useEffect(() => {
    resetCurrentTable();
  }, [resetCurrentTable]);

  useEffect(() => {
    getTableSchemaList(workspaceUuid, dataSource.metadata.uuid, table.uuid);
    getTableColumnList(workspaceUuid, dataSource, table);
    getCurrentTableDataProfile(workspaceUuid, dataSource, table);
    getCurrentTable(workspaceUuid, dataSource, table.uuid);
    if (isPartitionConfigEnabled(dataSource.config.connection.type)) {
      getProfilerTableListPartitionSampleData(workspaceUuid, dataSource.metadata.uuid, [
        table,
      ]);
    }
  }, [
    workspaceUuid,
    dataSource,
    table,
    getCurrentTable,
    getCurrentTableDataProfile,
    getTableSchemaList,
    getTableColumnList,
    getProfilerTableListPartitionSampleData,
  ]);

  useEffect(() => {
    if (currentTable && currentTable.uuid === table.uuid) {
      const newDataProfileConfig = getDefaultDataProfilerConfig(currentTable);
      setDataProfileConfig(newDataProfileConfig);
      if (isValidTableConfig(newDataProfileConfig)) {
        getTableSampleDataList(
          workspaceUuid,
          getSampleDataRequestPayload(
            dataSource.metadata.uuid,
            currentTable.uuid,
            newDataProfileConfig
          )
        );
      }
    }
  }, [workspaceUuid, dataSource, table, currentTable, getTableSampleDataList]);

  useEffect(
    () => setSelectedColumns(defaultSelectedColumn || []),
    [defaultSelectedColumn]
  );

  useEffect(() => setSelectedColumns([]), [dataProfileData]);

  const columnOptions = useMemo(() => {
    if (!dataProfileData || dataProfileData.loading || !dataProfileData.data) {
      return [];
    }

    return dataProfileData.data.map(({ name }) => ({ label: name, value: name }));
  }, [dataProfileData]);

  const currentDataProfileData = useMemo(() => {
    if (!dataProfileData || dataProfileData.loading || !dataProfileData?.data) {
      return [];
    }

    if (selectedColumns.length === 0) {
      return dataProfileData.data || [];
    }

    return dataProfileData.data.filter(({ name }) => selectedColumns.includes(name));
  }, [selectedColumns, dataProfileData]);

  const currentTableColumnSummary = useMemo(() => {
    return getTableColumnSummary(tableColumnList);
  }, [tableColumnList]);

  const timestampAnalysisColumns = (dataProfileData?.data || []).filter((column) =>
    Boolean(column.dataProfile?.tableVolumePreview)
  );

  const lastRecordedTs =
    typeof currentTable?.status?.dataProfilerStatus.recordedTs === "number"
      ? currentTable?.status?.dataProfilerStatus.recordedTs
      : null;

  const totalSample = getTotalSampleFromTableDataProfileData(
    tableDataProfile?.data?.dataProfile
  );

  const partitionSampleData = {
    loading: tableListPartitionSampleData.loading,
    data: tableListPartitionSampleData.data?.[table.uuid],
  };

  const handleConfigureClick = () => {
    if (!isConfigurationOpen) {
      trackEvent(EVENT.CONFIGURE_DATA_PROFILE, {
        ...getTableDetailProps(table, schema, dataSource),
        page: PAGE.EXPLORER_TABLE_DATA_PROFILE,
      });
    }

    setIsConfigurationOpen(!isConfigurationOpen);
  };

  const handleProfilerChange = (newDataProfileConfig) => {
    setDataProfileConfig(newDataProfileConfig);
  };

  const onUpdateClick = () => {
    const newTable = {
      ...currentTable,
      profilerConfig: {
        ...currentTable.profilerConfig,
        dataProfiler: {
          ...currentTable.profilerConfig.dataProfiler,
          ...dataProfileConfig,
        },
        rerunDataProfiler: true, // this will force rerun data profiler
      },
    };

    trackEvent(EVENT.UPDATE_DATA_PROFILE, {
      ...getTableDetailProps(table, schema, dataSource),
      page: PAGE.EXPLORER_TABLE_DATA_PROFILE,
    });

    updateTableConfig(workspaceUuid, dataSource, schema, newTable).then(() => {
      onRefresh();
      getCurrentTableDataProfile(workspaceUuid, dataSource, table);
    });
    setIsConfigurationOpen(false);
  };

  const runStatus = currentTable?.status?.dataProfilerStatus?.runStatus;

  if (runStatus === DataProfileRunStatus.EXCEPTION) {
    const runStatusMessage = currentTable?.status?.dataProfilerStatus?.runStatusMessage;

    return (
      <ProfilerExceptionView runStatusMessage={runStatusMessage}>
        <ProfilerDataProfileConfig
          tableUuid={table.uuid}
          dataSource={dataSource}
          columnList={tableColumnList}
          value={dataProfileConfig}
          tablePartitions={table.profilerConfig.partitions}
          partitionSampleData={partitionSampleData}
          onChange={handleProfilerChange}
          onUpdateClick={onUpdateClick}
        />
      </ProfilerExceptionView>
    );
  }

  return (
    <div className="profiler-data-profile-table-overview-container">
      <div className="profiler-data-profile-table-overview-header-container">
        <div className="profiler-data-profile-table-overview-header-title-container">
          Data profile
          <ButtonText
            colored
            className="profiler-data-profile-table-overview-configure"
            disabled={!isConfigReady}
            onClick={handleConfigureClick}
          >
            Configure
          </ButtonText>
        </div>
      </div>

      {isConfigurationOpen && (
        <div className="profiler-data-profile-table-overview-configuration-container">
          <ProfilerDataProfileConfig
            tableUuid={table.uuid}
            dataSource={dataSource}
            columnList={tableColumnList}
            value={dataProfileConfig}
            tablePartitions={table.profilerConfig.partitions}
            partitionSampleData={partitionSampleData}
            onChange={handleProfilerChange}
            onUpdateClick={onUpdateClick}
          />
        </div>
      )}
      {columnsInProgress && (
        <div className="profiler-data-profiler-table-overview-data-profiler-in-progress-container">
          {isDataSourcePaused ? (
            <PausedDataSourcePlaceholder />
          ) : (
            <ProfilerDataProfileInProgressView />
          )}
        </div>
      )}

      {!columnsInProgress && (
        <div className="profiler-data-profiler-table-overview-data-profiler-result-container">
          {currentTableColumnSummary && (
            <div className="profiler-data-profile-table-overview-summary-container">
              <div className="profiler-data-profile-table-overview-summary-number-container">
                {[
                  {
                    label: "# of columns",
                    value: currentTableColumnSummary.totalColumns,
                  },
                  {
                    label: "# of numerical columns",
                    value: currentTableColumnSummary.numericalColumns,
                  },
                  {
                    label: "# of string columns",
                    value: currentTableColumnSummary.categoricalColumns,
                  },
                  {
                    label: "# of timestamp columns",
                    value: currentTableColumnSummary.timestampColumns,
                  },
                  { label: "# of rows (approx)", value: totalSample },
                ].map(({ label, value }, index) => (
                  <div
                    className="profiler-data-profile-table-overview-summary-row-container"
                    key={index}
                  >
                    <div className="profiler-data-profile-table-overview-summary-row-label-container">
                      {label}
                    </div>
                    <div className="profiler-data-profile-table-overview-summary-row-value-container">
                      {value}
                    </div>
                  </div>
                ))}
              </div>
              <div className="profiler-data-profile-table-overview-summary-timestamp-container">
                <div className="profiler-data-profile-table-overview-summary-row-container">
                  <div className="profiler-data-profile-table-overview-summary-row-label-container">
                    Data profile generated on
                  </div>
                  <div className="profiler-data-profile-table-overview-summary-row-value-container">
                    {lastRecordedTs === null
                      ? "N/A"
                      : getStringFromTimeStamp(lastRecordedTs)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="profiler-data-profile-table-overview-table-schema-container">
            <TableSchemaView data={tableSchemaList} tableColumnList={tableColumnList} />
          </div>
          {timestampAnalysisColumns.length > 0 && (
            <div className="profiler-data-profile-table-overview-timestamp-analysis-container">
              <ProfilerDataSourceTableTimestampAnalysis
                data={timestampAnalysisColumns}
              />
            </div>
          )}
          {isConfigReady && (
            <div className="profiler-data-profile-table-overview-table-sample-data-container">
              <TableSampleDataView
                sampleData={tableSampleDataList}
                tableSchemaList={tableSchemaList}
                tableColumnList={tableColumnList}
              />
            </div>
          )}
          {enableSearch && columnOptions.length > 0 && (
            <div className="profiler-data-profile-table-overview-column-select-container">
              <NgMultiSelect
                value={selectedColumns}
                options={columnOptions}
                placeholder="Select column name..."
                size="large"
                onChange={setSelectedColumns}
              />
            </div>
          )}
          <div className="profiler-data-profiler-table-overview-column-list-container">
            {currentDataProfileData.map((dataProfileData) => {
              const columnInfo = tableColumnList.find(
                (column) => column.uuid === dataProfileData.uuid
              );
              return (
                <ProfilerDataProfileColumnView
                  level="table"
                  key={dataProfileData.uuid}
                  data={{
                    data: dataProfileData,
                  }}
                  columnInfo={columnInfo}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  waffle: state.userReducer.currentUserInfo.waffle,
  tableSchemaList: state.kpiReducer.currentKpiSampleDataTableSchemaList,
  tableSampleDataList: state.kpiReducer.currentKpiSampleDataSampleDataList,
  tableColumnList: state.profilerReducer.profilerConfigTableColumnList.data,
  currentTable: state.profilerReducer.profilerTableDataProfileTable.data,
  tableDataProfile: state.profilerReducer.profilerTableDataProfileTableDataProfile,
  tableListPartitionSampleData:
    state.profilerReducer.profilerTableListPartitionSampleData,
});

const mapDispatchToProps = (dispatch) => ({
  getTableSchemaList: (workspaceUuid, dataSourceUuid, tableUuid) =>
    dispatch(
      getCurrentKpiSampleDataTableSchemaList(workspaceUuid, dataSourceUuid, tableUuid)
    ),
  getTableSampleDataList: (workspaceUuid, requestObject) =>
    dispatch(getCurrentKpiSampleDataSampleDataList(workspaceUuid, requestObject)),
  getTableColumnList: (workspaceUuid, dataSource, table) =>
    dispatch(getProfilerConfigTableColumnList(workspaceUuid, dataSource, table)),
  updateTableConfig: (workspaceUuid, dataSource, schema, table) =>
    dispatch(updateTableDataProfilerConfig(workspaceUuid, dataSource, schema, table)),
  getCurrentTable: (workspaceUuid, dataSource, tableUuid) =>
    dispatch(getProfilerTableDataProfileTable(workspaceUuid, dataSource, tableUuid)),
  resetCurrentTable: () => {
    dispatch(resetProfilerTableDataProfileTable());
  },
  getCurrentTableDataProfile: (workspaceUuid, dataSource, table, opts) =>
    dispatch(
      getProfilerTableDataProfileTableDataProfile(
        workspaceUuid,
        dataSource,
        table,
        opts
      )
    ),
  getProfilerTableListPartitionSampleData: (workspaceUuid, dataSource, tableList) =>
    dispatch(
      getProfilerTableListPartitionSampleData(workspaceUuid, dataSource, tableList)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilerDataProfileTableView);
