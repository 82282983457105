import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { indexBy } from "../../../../utils/iterables";
import ButtonIcon from "../../../button/button-icon";
import ButtonText from "../../../button/button-text";
import NgButton, { TextWithIcon } from "../../../button/ng-button";
import MetricSearch from "../../../search/metric-search";
import { FieldRow, FieldRowButton } from "../../fields";
import MetricConfigTabPanel from "../../metric-config-tab-panel";
import MetricConfigSummaryView from "../../summary-view/";
import {
  DataAssetSummaryTag,
  briefDataAssetSummary,
} from "../../summary-view/data-asset-summary";
import { isTableOrColumnActivityMetric } from "../../../../utils/metric";
import { Spinner } from "../../../../atom/spinner";

import "./index.scss";

function DataAssetSummaryColumn(props) {
  const { title, segments } = props;
  return (
    <div className="related-metric-data-asset-column">
      {title && <div className="related-metric-data-asset-column-title">{title}</div>}
      {segments.map(({ key, icon, value }) => (
        <DataAssetSummaryTag key={key} icon={icon} value={value} />
      ))}
    </div>
  );
}

function RelatedMetricItem(props) {
  const { metric, dataSourcesByUuid, onDeleteClick, disabled = false } = props;

  const [dataAssetSummaryVisible, setDataAssetSummaryVisible] = useState(false);

  if (!metric) {
    return null;
  }

  const dataAssetSummary = briefDataAssetSummary(metric, dataSourcesByUuid);

  return (
    <div className="related-metric-item">
      <div className="related-metric-item-header">
        <span className="related-metric-item-title">{metric.metadata.name}</span>
        <ButtonText
          className="related-metric-item-info-toggle"
          colored
          onClick={() => setDataAssetSummaryVisible(!dataAssetSummaryVisible)}
        >
          {dataAssetSummaryVisible ? "Hide info" : "Show info"}
        </ButtonText>
        {!disabled && (
          <ButtonIcon
            data-testid="delete-related-metric"
            className="related-metric-item-delete"
            icon={<DeleteOutlined />}
            onClick={() => onDeleteClick(metric.metadata.uuid)}
          />
        )}
      </div>
      {dataAssetSummaryVisible && (
        <div className="related-metric-item-data-asset-summary">
          <DataAssetSummaryTag
            icon={dataAssetSummary.id.icon}
            value={`ID: ${dataAssetSummary.id.value}`}
          />
          <div className="related-metric-data-asset-columns">
            {dataAssetSummary.single && (
              <DataAssetSummaryColumn segments={dataAssetSummary.single} />
            )}
            {dataAssetSummary.source && (
              <DataAssetSummaryColumn
                title="Source"
                segments={dataAssetSummary.source}
              />
            )}
            {dataAssetSummary.target && (
              <DataAssetSummaryColumn
                title="Target"
                segments={dataAssetSummary.target}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function addRelatedMetric(configData, metricUuid) {
  const relatedMetricsSet = new Set(configData.config.relatedMetrics ?? []);
  relatedMetricsSet.add(metricUuid);
  const newRelatedMetrics = [...relatedMetricsSet];
  return {
    ...configData,
    config: {
      ...configData.config,
      relatedMetrics: newRelatedMetrics,
    },
  };
}

function removeRelatedMetric(configData, metricUuid) {
  const relatedMetricsSet = new Set(configData.config.relatedMetrics ?? []);
  relatedMetricsSet.delete(metricUuid);
  const newRelatedMetrics = [...relatedMetricsSet];
  return {
    ...configData,
    config: {
      ...configData.config,
      relatedMetrics: newRelatedMetrics,
    },
  };
}

function RelatedMetricsConfig(props) {
  const {
    metrics,
    dataSourceList,
    configData,
    getKpiList,
    onConfigDataChange,
    disabled = false,
  } = props;

  const [isAddMetricControlVisible, setIsAddMetricControlVisible] = useState(false);

  const relatedMetrics = useMemo(
    () => configData.config.relatedMetrics ?? [],
    [configData]
  );

  useEffect(() => {
    if (
      !metrics.updatedAt &&
      !metrics.loading &&
      (isAddMetricControlVisible || relatedMetrics.length > 0)
    ) {
      getKpiList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metrics.loading,
    metrics.updatedAt,
    isAddMetricControlVisible,
    relatedMetrics.length,
  ]);

  const metricsByUuid = useMemo(
    () => indexBy(metrics.data, (metric) => metric.metadata.uuid),
    [metrics]
  );
  const dataSourcesByUuid = useMemo(
    () => indexBy(dataSourceList, (dataSource) => dataSource.metadata.uuid),
    [dataSourceList]
  );
  const nonAddedMetrics = useMemo(() => {
    if (relatedMetrics.length === 0) {
      return metrics.data;
    }
    const relatedMetricsSet = new Set(relatedMetrics);
    return metrics.data.filter(
      (metric) => !relatedMetricsSet.has(metric.metadata.uuid)
    );
  }, [metrics.data, relatedMetrics]);

  return (
    <div className="related-metrics-config">
      <div className="related-metric-config-header">
        <div className="related-metrics-config-title-row">
          Related metrics
          <NgButton
            testId="add-related-metric"
            outline
            onClick={() => {
              setIsAddMetricControlVisible(true);
            }}
            disabled={disabled || isTableOrColumnActivityMetric(configData)}
          >
            <TextWithIcon icon={<PlusOutlined />}>Add</TextWithIcon>
          </NgButton>
        </div>
        <div className="related-metrics-description">
          Select related metrics to make them easily accessible during incident analysis
        </div>
      </div>
      {isAddMetricControlVisible && (
        <FieldRow className="related-metrics-config-add-metric">
          <MetricSearch
            label="Metric"
            metrics={nonAddedMetrics}
            loading={metrics.loading}
            disabled={metrics.loading}
            dataSourceList={dataSourceList}
            onChange={(newMetricUuid) => {
              onConfigDataChange(addRelatedMetric(configData, newMetricUuid));
              setIsAddMetricControlVisible(false);
            }}
          />
          <FieldRowButton
            icon={<DeleteOutlined />}
            onClick={() => setIsAddMetricControlVisible(false)}
          />
        </FieldRow>
      )}
      {!metrics.loading && (
        <div className="related-metrics-list">
          {relatedMetrics.map((metricUuid) => (
            <RelatedMetricItem
              key={metricUuid}
              metric={metricsByUuid[metricUuid]}
              dataSourcesByUuid={dataSourcesByUuid}
              disabled={disabled}
              onDeleteClick={(metricUuid) => {
                onConfigDataChange(removeRelatedMetric(configData, metricUuid));
              }}
            />
          ))}
        </div>
      )}
      {metrics.loading && relatedMetrics.length > 0 && (
        <div className="related-metrics-loading">
          <Spinner size="large" />
        </div>
      )}
    </div>
  );
}

function MetricConfigRelatedMetricsTabPanel(props) {
  const {
    kpiList,
    dataSourceList,
    configData,
    getKpiList,
    disabled,
    onConfigDataChange,
  } = props;

  return (
    <MetricConfigTabPanel
      summarySection={<MetricConfigSummaryView {...props} />}
      contentSection={
        <RelatedMetricsConfig
          metrics={kpiList}
          getKpiList={getKpiList}
          dataSourceList={dataSourceList}
          configData={configData}
          onConfigDataChange={onConfigDataChange}
          disabled={disabled}
        />
      }
    />
  );
}

export default MetricConfigRelatedMetricsTabPanel;
