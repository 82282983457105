import { getColumnTypeCategory } from "../../utils/column";
import {
  AutoMetricsType,
  MetricConfigType,
  TableType,
  MetricStatus,
  TableColumnTypeCategory,
  TriggerRunStatus,
  isFileSource,
  MetricCategory,
} from "../../utils/enums";
import {
  getDefaultRuleConfigForAutoMetrics,
  getDefaultRuleConfigForCustomMetric,
} from "../../utils/defaults";
import { MetricActions } from "./profiler-metric-actions-menu";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import queryString from "query-string";
import {
  getMetricStatus,
  isAutoCreationTypeMetric,
  isAutoMetric,
  isMetadataMetric,
  isActivityMetric,
  isMetricBackfilling,
  isMetricWaitingForFirstCollection,
  metricTypeSupportsQueryHistory,
} from "../../utils/metric";
import { isMonitorLoading, isMonitorTraining } from "../../utils/monitor";
import { capitalizeFirstLetter, isRenderableString } from "../../utils/general";
import { AppPermissions } from "../../utils/permissions";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { isRelatedToVirtualTable } from "../../utils/table";

// How frequently to refresh schema status.
export const SCHEMA_REFRESH_POLLING_INTERVAL = 30000;

export function profilerTableChartViewConfig(xTitle, yTitle, symptomConfig = {}) {
  return {
    symptomType: "",
    feature: "",
    autosize: false,
    height: 200,
    marginTop: 0,
    startTime: 0,
    endTime: 0,
    detectInterval: 0,
    isIncidentOnly: false,
    xTitle,
    y2Title: yTitle,
    textHeightInPx: 1,
    className: "",
    ...symptomConfig,
  };
}

export function shouldChartRefreshMetrics(chartMetricsData) {
  if (chartMetricsData.loading || !chartMetricsData.data) {
    return false;
  }

  const chartInfo = chartMetricsData.data.chartInfo;
  const chartMetrics = Object.values(chartInfo).map(
    (chartInfoItem) => chartInfoItem[0].metric
  );
  const chartMonitors = Object.values(chartInfo).reduce(
    (allMonitors, chartInfoItem) => allMonitors.concat(chartInfoItem[0].rules),
    []
  );
  return (
    chartMetrics.some(isMetricInVolatileState) ||
    chartMonitors.some(isMonitorInVolatileState)
  );
}

export const ProfilerSummaryTabKey = Object.freeze({
  AUTO_METRICS: "autoMetrics",
  CONFIGURATION: "Configuration",
  CONFIGURE_AUTO_METRICS: "configureAutoMetrics",
  CONFIGURE_DATA_PROFILES: "configureDataProfiles",
  DATA_PROFILE: "dataProfile",
  HEALTH: "health",
  MANAGE_COLUMNS: "manageColumns",
  MANAGE_SCHEMAS: "manageSchemas",
  MANAGE_TABLES: "manageTables",
  SUMMARY: "summary",
  ACTIVITY: "activity",
});

// We are grouping the metric by type(Table/Column/CustomSql). Since there is no uuid for custom sql, we use a fake uuid for group purpose.
const fakeCustomSqlUuid = "fakeCustomSqlUuid";
export function getGroupedAutoMetricsMapFromUsage(CurrentMonitorObject) {
  const { usage, refs } = CurrentMonitorObject;
  const metricMapper = {};
  const groupedAutoMetricsMapper = {};
  refs.forEach((currentRef) => {
    metricMapper[currentRef.metadata.uuid] = currentRef;
  });

  for (let currentMetricInfo of usage.metrics) {
    if (currentMetricInfo.rules && currentMetricInfo.rules.length > 0) {
      continue;
    }

    let uuid;
    const currentMetric = metricMapper[currentMetricInfo.uuid] || "";
    if (!currentMetric) {
      continue;
    }

    // We currently don't support auto monitor creation for aggregation/rbr metrics.
    if (
      [
        MetricConfigType.FULL_COMPARE_METRIC_CONFIG,
        MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG,
      ].includes(currentMetric?.config?.configType)
    ) {
      continue;
    }

    if (currentMetric.config?.table?.type === TableType.CUSTOM_SQL) {
      uuid = fakeCustomSqlUuid;
    } else if (
      [
        AutoMetricsType.TABLE_ACTIVITY,
        AutoMetricsType.COLUMN_ACTIVITY,
        AutoMetricsType.CATEGORY_ACTIVITY,
      ].includes(currentMetric.config.aggregation.type)
    ) {
      uuid = currentMetric.config.aggregation.type;
    } else if (
      [
        AutoMetricsType.DATA_DELAY,
        AutoMetricsType.VOLUME,
        AutoMetricsType.BYTE_COUNT,
        AutoMetricsType.ROW_COUNT,
        AutoMetricsType.UPDATE_DELAY,
      ].includes(currentMetric.config.aggregation.type)
    ) {
      // This is a table level metric;
      uuid = currentMetric.config.table.tableUuid;
    } else {
      // This is a column level metric;
      uuid = currentMetric.config.valueColumns[0].columnUuid;
    }

    const groupedAutoMetrics = groupedAutoMetricsMapper[uuid] || [];
    groupedAutoMetrics.push(currentMetric);
    groupedAutoMetricsMapper[uuid] = groupedAutoMetrics;
  }

  return groupedAutoMetricsMapper;
}

function buildNewRuleForSqlMetrics(alertChannelConfig, groupedAutoMetricsMapper) {
  if (!groupedAutoMetricsMapper[fakeCustomSqlUuid]?.length) {
    return [];
  }

  return groupedAutoMetricsMapper[fakeCustomSqlUuid].map((currentMetric) =>
    getDefaultRuleConfigForCustomMetric(
      {
        symptomType: "",
        config: {},
        alertChannelConfig,
      },
      currentMetric,
      0
    )
  );
}

export function buildNewRuleAndProfileConfigUpdateForColumn(
  alertChannelConfig,
  dataSource,
  schema,
  tableInfo,
  columnInfo,
  groupedAutoMetricsMapper,
  topLevel = false
) {
  const rules = [];
  const profilerConfigUpdates = [];
  const columnAutoMetrics = groupedAutoMetricsMapper[columnInfo.uuid] || [];
  for (let currentColumnMetric of columnAutoMetrics) {
    const newRule = getDefaultRuleConfigForAutoMetrics(
      {
        symptomType: "",
        config: {},
        alertChannelConfig,
      },
      currentColumnMetric,
      dataSource,
      tableInfo,
      columnInfo
    );
    rules.push(newRule);
  }

  const columnUuidsForColumnActivity = (
    groupedAutoMetricsMapper[AutoMetricsType.CATEGORY_ACTIVITY] || []
  ).map((currentMetric) => currentMetric.config.valueColumns[0].columnUuid);

  if (columnUuidsForColumnActivity.indexOf(columnInfo.uuid) !== -1) {
    profilerConfigUpdates.push({
      type: AutoMetricsType.CATEGORY_ACTIVITY,
      dataSourceUuid: dataSource.metadata.uuid,
      schemaUuid: schema.uuid,
      tableUuid: tableInfo.uuid,
      columnUuid: columnInfo.uuid,
      profilerConfig: {
        ...columnInfo.profilerConfig,
        categoryListChange: {
          monitoring: {
            enabled: true,
            alertConfig: {
              mutingSchedules: [],
              isMuted: !!alertChannelConfig.isMuted,
              channels: (alertChannelConfig.alertingChannelList || []).map(
                (alertChannelId) => {
                  return {
                    channelId: alertChannelId,
                    isMuted: alertChannelConfig.isMuted,
                  };
                }
              ),
            },
          },
          enabled: true,
        },
      },
    });
  }

  topLevel &&
    rules.push(
      ...buildNewRuleForSqlMetrics(alertChannelConfig, groupedAutoMetricsMapper)
    );
  return { rules, profilerConfigUpdates };
}

export function buildNewRuleAndProfileConfigUpdateForTable(
  alertChannelConfig,
  dataSource,
  schema,
  tableInfo,
  groupedAutoMetricsMapper,
  topLevel = false
) {
  let rules = [];
  let profilerConfigUpdates = [];
  const tableUuid = tableInfo.uuid;

  // Start to create table level rules
  const tableAutoMetrics = groupedAutoMetricsMapper[tableUuid] || [];
  if (tableAutoMetrics && tableAutoMetrics.length > 0) {
    for (let currentTableMetric of tableAutoMetrics) {
      const newRule = getDefaultRuleConfigForAutoMetrics(
        { symptomType: "", config: {}, alertChannelConfig },
        currentTableMetric,
        dataSource,
        tableInfo,
        null
      );
      rules.push(newRule);
    }
  }

  // process table metadata change
  if (tableInfo && tableInfo.profilerConfig) {
    const tableUuidsForColumnActivity = (
      groupedAutoMetricsMapper[AutoMetricsType.COLUMN_ACTIVITY] || []
    ).map((currentMetric) => currentMetric.config.table.tableUuid);

    if (tableUuidsForColumnActivity.indexOf(tableInfo.uuid) !== -1) {
      profilerConfigUpdates.push({
        type: AutoMetricsType.COLUMN_ACTIVITY,
        dataSourceUuid: dataSource.metadata.uuid,
        schemaUuid: schema.uuid,
        tableUuid: tableInfo.uuid,
        columnUuid: null,
        profilerConfig: {
          ...tableInfo.profilerConfig,
          metadataMetrics: {
            ...tableInfo.profilerConfig.metadataMetrics,
            schemaChange: {
              ...tableInfo.profilerConfig.metadataMetrics.schemaChange,
              monitoring: {
                enabled: true,
                alertConfig: {
                  mutingSchedules: [],
                  isMuted: !!alertChannelConfig.isMuted,
                  channels: (alertChannelConfig.alertingChannelList || []).map(
                    (alertChannelId) => {
                      return {
                        channelId: alertChannelId,
                        isMuted: alertChannelConfig.isMuted,
                      };
                    }
                  ),
                },
              },
            },
          },
        },
      });
    }
  }

  // process column level metrics
  for (let currentColumn of tableInfo.columns) {
    const currentColumnResult = buildNewRuleAndProfileConfigUpdateForColumn(
      alertChannelConfig,
      dataSource,
      schema,
      tableInfo,
      currentColumn,
      groupedAutoMetricsMapper,
      false
    );

    rules = [...rules, ...currentColumnResult.rules];
    profilerConfigUpdates = [
      ...profilerConfigUpdates,
      ...currentColumnResult.profilerConfigUpdates,
    ];
  }

  topLevel &&
    rules.push(
      ...buildNewRuleForSqlMetrics(alertChannelConfig, groupedAutoMetricsMapper)
    );
  return { rules, profilerConfigUpdates };
}

export function buildNewRuleAndProfileConfigUpdateForSchema(
  alertChannelConfig,
  dataSource,
  schema,
  groupedAutoMetricsMapper,
  topLevel
) {
  let rules = [];
  let profilerConfigUpdates = [];

  // process table schema change
  const schemaUuidsForTableActivity = (
    groupedAutoMetricsMapper[AutoMetricsType.TABLE_ACTIVITY] || []
  ).map((currentMetric) => currentMetric.config.table.schemaUuid);
  if (schemaUuidsForTableActivity.indexOf(schema.uuid) !== -1) {
    profilerConfigUpdates.push({
      type: AutoMetricsType.TABLE_ACTIVITY,
      dataSourceUuid: dataSource.metadata.uuid,
      schemaUuid: schema.uuid,
      tableUuid: null,
      columnUuid: null,
      profilerConfig: {
        ...schema.profilerConfig,
        tableListChange: {
          enabled: true,
          monitoring: {
            enabled: true,
            alertConfig: {
              mutingSchedules: [],
              isMuted: !!alertChannelConfig.isMuted,
              channels: (alertChannelConfig.alertingChannelList || []).map(
                (alertChannelId) => {
                  return {
                    channelId: alertChannelId,
                    isMuted: alertChannelConfig.isMuted,
                  };
                }
              ),
            },
          },
        },
      },
    });
  }

  // process table level metrics
  for (let currentTable of schema.tables) {
    const currentTableResult = buildNewRuleAndProfileConfigUpdateForTable(
      alertChannelConfig,
      dataSource,
      schema,
      currentTable,
      groupedAutoMetricsMapper,
      false
    );

    rules = [...rules, ...currentTableResult.rules];
    profilerConfigUpdates = [
      ...profilerConfigUpdates,
      ...currentTableResult.profilerConfigUpdates,
    ];
  }

  topLevel &&
    rules.push(
      ...buildNewRuleForSqlMetrics(alertChannelConfig, groupedAutoMetricsMapper)
    );

  return { rules, profilerConfigUpdates };
}

export function buildNewRuleAndProfileConfigUpdateForDataSource(
  alertChannelConfig,
  dataSource,
  groupedAutoMetricsMapper,
  topLevel = false
) {
  let rules = [];
  let profilerConfigUpdates = [];

  // process schema list change when we support it.

  // process table level metrics
  for (let currentSchema of dataSource.schemas) {
    const currentSchemaResult = buildNewRuleAndProfileConfigUpdateForSchema(
      alertChannelConfig,
      dataSource,
      currentSchema,
      groupedAutoMetricsMapper,
      false
    );

    rules = [...rules, ...currentSchemaResult.rules];
    profilerConfigUpdates = [
      ...profilerConfigUpdates,
      ...currentSchemaResult.profilerConfigUpdates,
    ];
  }

  topLevel &&
    rules.push(
      ...buildNewRuleForSqlMetrics(alertChannelConfig, groupedAutoMetricsMapper)
    );
  return { rules, profilerConfigUpdates };
}

export function profilerMetricActions(metric, dataSource, opts = {}) {
  const { includeDisable = false } = opts;
  const excludedActions = [MetricActions.VIEW_IN_EXPLORER];
  if (!metric) {
    // This prevents render any option until the metric is available
    return [];
  }

  if (
    !dataSource ||
    isFileSource(dataSource?.config?.connection?.type) ||
    !metricTypeSupportsQueryHistory(getMetricTypeFromConfigData(metric))
  ) {
    excludedActions.push(MetricActions.VIEW_QUERY_HISTORY);
  }
  const isRowByRowMetric =
    getMetricTypeFromConfigData(metric) === MetricCategory.FULL_COMPARE;
  let shouldHideClone = isRelatedToVirtualTable(metric) && !isRowByRowMetric;

  if (isAutoMetric(metric) || isMetadataMetric(metric) || isActivityMetric(metric)) {
    excludedActions.push(MetricActions.DELETE);
  } else if (!includeDisable) {
    excludedActions.push(MetricActions.DISABLE);
  }
  if (shouldHideClone) {
    excludedActions.push(MetricActions.CLONE);
  }

  return Object.values(MetricActions).filter(
    (action) => !excludedActions.includes(action)
  );
}

export function explorerMetricUrl(metric, tabKey) {
  const workspaceUuid = metric.metadata.workspaceId;
  const queryParams = {
    dataSourceUuid: metric.config.sources[0],
    metricUuid: metric.metadata.uuid,
    schemaUuid: metric.config?.table?.schemaUuid,
    schemaName: metric.config?.table?.schemaName,
    tableUuid: metric.config?.table?.tableUuid,
    columnUuid: metric.config?.valueColumns?.[0]?.columnUuid,
  };

  const isAutoCreationType = isAutoCreationTypeMetric(metric);
  if (tabKey === undefined && isAutoCreationType) {
    if (metric?.config?.aggregation?.type === AutoMetricsType.TABLE_ACTIVITY) {
      tabKey = ProfilerSummaryTabKey.SUMMARY;
    } else {
      tabKey = ProfilerSummaryTabKey.AUTO_METRICS;
    }
  }

  // Custom metric nodes do not have tabs.
  if (tabKey && isAutoCreationType) {
    queryParams.tabKey = tabKey;
  }

  const path = getURIInstance(URIPath.EXPLORER, { workspaceUuid });
  return `${path}?${queryString.stringify(queryParams)}`;
}

export function explorerEventIncidentUrl(workspaceUuid, creatorInfo) {
  const { dataSourceInfo, schemaInfo, tableInfo, columnInfo } = creatorInfo;
  const queryParams = {
    dataSourceUuid: dataSourceInfo.uuid,
  };

  if (schemaInfo && tableInfo) {
    queryParams.schemaName = schemaInfo.name;
    queryParams.tableUuid = tableInfo.uuid;

    if (columnInfo) {
      queryParams.columnUuid = columnInfo.uuid;
    }
  }
  const path = getURIInstance(URIPath.EXPLORER, { workspaceUuid });
  return `${path}?${queryString.stringify(queryParams)}`;
}

// Returns whether a metric is expected to change status without the user
// directly interacting with it.
export function isMetricInVolatileState(metric) {
  const status = getMetricStatus(metric);
  const hasPendingTrigger =
    metric?.status?.triggered?.triggerRunStatus === TriggerRunStatus.PENDING;
  return (
    isMetricBackfilling(metric) ||
    isMetricWaitingForFirstCollection(metric) ||
    [MetricStatus.PAUSING, MetricStatus.RESUMING].includes(status) ||
    hasPendingTrigger
  );
}

export function isMonitorInVolatileState(monitor) {
  return isMonitorLoading(monitor) || isMonitorTraining(monitor);
}

export function getChartSymptomConfigForFocusedMonitor(monitorList, focusedUuid) {
  const focusedMonitor = monitorList?.find(
    (currentMonitor) => currentMonitor.metadata.uuid === focusedUuid
  );

  return {
    symptomType: focusedMonitor?.config?.symptom?.type ?? "",
    feature: focusedMonitor?.config?.symptom?.featureConfig?.type ?? "",
  };
}

export function getTableColumnSummary(columnList) {
  let totalColumns = columnList.length;
  let numericalColumns = 0;
  let categoricalColumns = 0;
  let timestampColumns = 0;
  columnList.forEach((column) => {
    const columnTypeCategory = getColumnTypeCategory(column);
    if (columnTypeCategory === TableColumnTypeCategory.STRING) {
      categoricalColumns = categoricalColumns + 1;
    } else if (columnTypeCategory === TableColumnTypeCategory.NUMERIC) {
      numericalColumns = numericalColumns + 1;
    } else if (columnTypeCategory === TableColumnTypeCategory.TIMESTAMP) {
      timestampColumns = timestampColumns + 1;
    }
  });

  return {
    totalColumns,
    numericalColumns,
    categoricalColumns,
    timestampColumns,
  };
}

export function getTotalSampleFromTableDataProfileData(tableDataProfileData) {
  if (typeof tableDataProfileData?.numRows === "number") {
    return tableDataProfileData?.numRows;
  }

  if (typeof tableDataProfileData?.approxNumRows === "number") {
    return tableDataProfileData?.approxNumRows;
  }

  return "N/A";
}

export function getLabelFromName(name) {
  if (!isRenderableString(name)) {
    return null;
  }
  return capitalizeFirstLetter(name.trim().replace(/[-_\s]+/g, " "));
}

export function canViewDataProfile(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEDATAPROFILELISTVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEDATAPROFILEDETAILVIEW,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_TABLESAMPLESPREVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCESCHEMA,
  ]);
}

export function canModifyTableProfilerConfig(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
  ]);
}

export function canViewChecklist(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEDATAPROFILELISTVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEDATAPROFILEDETAILVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCESCHEMA,
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ]);
}

export const ProfilerTabOptionOperation = Object.freeze({
  ADD: "addOption",
  REMOVE: "removeOption",
});

export function getActivityChangeType(json) {
  if (json.action === "create") {
    return "";
  }
  const { sql: sqlBefore = "", ...beforeRest } = json.before || {};
  const { sql: sqlAfter = "", ...afterRest } = json.after || {};

  const sqlChanged = sqlBefore !== sqlAfter;

  const configChanged = JSON.stringify(beforeRest) !== JSON.stringify(afterRest);

  if (configChanged && sqlChanged) {
    return "SQL, settings";
  } else if (configChanged) {
    return "settings";
  } else if (sqlChanged) {
    return "SQL";
  }
  return "";
}
