import { connect } from "react-redux";
import {
  deleteDataSource,
  getDataSourceList,
  getDataSourceUsage,
  updateDataSourceProfilerConfig,
  updateDataSource,
  updateDataSourceTags,
} from "../../actions/datasource/data-source-action";
import { getRuleList } from "../../actions/rule/rule-action";
import { getTagList } from "../../actions/tag/tag-action";
import { getKpiList } from "../../actions/kpi/kpi-action";
import DataSourceList from "./datasource-list";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  kpiList: state.kpiReducer.kpiList,
  dataSourceList: state.dataSourceReducer.dataSourceList,
  dataSourceUsage: state.dataSourceReducer.currentDataSourceUsage,
  ruleList: state.ruleReducer.ruleList,
  tagList: state.tagReducer.tagList,
});

const mapDispatchToProps = (dispatch) => ({
  deleteDataSource: (workspaceUuid, dataSource) =>
    dispatch(deleteDataSource(workspaceUuid, dataSource)),
  updateDataSource: (workspaceUuid, dataSource) =>
    dispatch(updateDataSource(workspaceUuid, dataSource)),
  updateDataSourceTags: (workspaceUuid, dataSource, newTags) =>
    dispatch(updateDataSourceTags(workspaceUuid, dataSource, newTags)),
  updateDataSourceProfilerConfig: (workspaceUuid, dataSource, newProfilerConfig) =>
    dispatch(
      updateDataSourceProfilerConfig(workspaceUuid, dataSource, newProfilerConfig)
    ),
  getDataSourceList: (workspaceUuid, opts) =>
    dispatch(getDataSourceList(workspaceUuid, opts)),
  getKpiList: (workspaceUuid) => dispatch(getKpiList(workspaceUuid)),
  getRuleList: (workspaceUuid) => dispatch(getRuleList(workspaceUuid)),
  getTagList: (workspaceUuid) => dispatch(getTagList(workspaceUuid)),
  getDataSourceUsage: (workspaceUuid, dataSource) =>
    dispatch(getDataSourceUsage(workspaceUuid, dataSource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSourceList);
