import React from "react";
import { NextGenPalette } from "../../utils/color";
import { plotConfig } from "../../components/chart/utils";
import Plot from "../../utils/plotly";
import NgCard from "../../components/ng-card/ng-card";
import { dqDimensionTypeTitles } from "../../components/dq-dashboard/util";
import NoData from "./no-data";
import ChartSpinner from "../../components/chart-view/chart-spinner";
import { Tooltip } from "antd";

import "./monitor-summary-heatmap.scss";

function metricInfoStr(monitorInfo) {
  const { sourceName, schemaName, tableName, columnName } = monitorInfo;

  return [sourceName, schemaName, tableName, columnName]
    .filter((s) => typeof s === "string" && s !== "")
    .join(" > ");
}

function MonitorSummaryHeatmapTable(props) {
  const { monitorRows } = props;

  return (
    <table className="dq-dash-heatmap-table">
      <thead>
        <tr>
          <th>Monitor</th>
          <th>Metric</th>
        </tr>
      </thead>
      <tbody>
        {monitorRows.map((info) => (
          <tr key={info.monitorUuid}>
            <td>{info.monitorName}</td>
            <td>
              <div className="dq-dash-heatmap-table-metric-name">{info.metricName}</div>
              <div className="dq-dash-heatmap-table-metric-info">
                {metricInfoStr(info)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function MonitorSummaryHeatmapPlot(props) {
  const { heatmapPlotData } = props;

  const numRows = Array.isArray(heatmapPlotData.z) ? heatmapPlotData.z.length : 0;
  const rowHeight = 42;
  const marginBottom = 20;

  const layout = {
    xaxis: {
      showgrid: false,
      zeroline: false,
      tickfont: {
        size: 10,
        family: "Neue-Haas-Unica-W1G, Sans-serif",
        color: NextGenPalette.slate,
      },
      tickformat: "%b %d",
      ticks: "outside",
      ticklen: 6,
      tickcolor: "rgb(255, 255, 255)",
      fixedrange: true,
    },
    yaxis: {
      showgrid: false,
      zeroline: false,
      showline: 0,
      ticks: "",
      showticklabels: false,
    },
    // A specific height is required to align the heatmap with the table to the left.
    height: numRows * rowHeight + marginBottom,
    width: 425,
    margin: {
      t: 0,
      r: 10,
      b: marginBottom,
      l: 0,
    },
  };

  const plotData = [
    {
      ...heatmapPlotData,
      type: "heatmap",
      colorscale: [
        [0, "#EAEAF466"], // rgba($darkGrey2, 0.4)
        [1, NextGenPalette.errorRed],
      ],
      zmin: 0,
      zmax: 1,
      showscale: false,
      xgap: 2,
      ygap: 2,
      hoverongaps: false,
      hovertemplate: "%{x}<extra></extra>",
    },
  ];

  const config = plotConfig();

  return <Plot data={plotData} layout={layout} config={config} />;
}

function MonitorSummaryHeatmap(props) {
  const { loading = false, dimensionType, monitorHeatmapData } = props;

  const monitorRows = monitorHeatmapData?.monitorRows ?? [];
  const heatmapTimestamps = monitorHeatmapData?.heatmapTimestamps ?? [];

  const heatmapPlotDataInit = {
    z: [],
    x: heatmapTimestamps.map((ts) => new Date(ts * 1000)),
  };

  const heatmapPlotData = monitorRows.reduce((data, monitorRow) => {
    data.z.push(monitorRow.heatmapData);
    return data;
  }, heatmapPlotDataInit);

  // The Plotly heatmap plots z rows from the bottom up - i.e. earlier entries in the z array appear further
  // down in the heatmap. We are showing metrics in our table in the opposite order - the first metric entry appears
  // at the top of the table. We therefore want to reverse the z-array before passing it to Plotly, so that the
  // entries in the table and the plot line up properly.
  heatmapPlotData.z.reverse();

  const noData = heatmapPlotData.z.length === 0;

  const tooltip =
    "Table of failed monitors with columns for monitor, metric, and a heatmap of incidents. On the heatmap, red cells indicate days that produced incidents. Up to ten failing monitors are listed, ordered by number of days with at least one logged incident.";
  const title = <Tooltip title={tooltip}>2 Week Monitor Summary</Tooltip>;

  return (
    <NgCard subtitle={dqDimensionTypeTitles[dimensionType]} title={title}>
      {loading && <ChartSpinner />}
      {!loading && noData && <NoData />}
      {!loading && !noData && (
        <div className="dq-dash-heatmap">
          <div className="dq-dash-heatmap-table-container">
            <MonitorSummaryHeatmapTable monitorRows={monitorRows} />
          </div>
          <div className="dq-dash-heatmap-plot-container">
            <MonitorSummaryHeatmapPlot heatmapPlotData={heatmapPlotData} />
          </div>
        </div>
      )}
    </NgCard>
  );
}

export default MonitorSummaryHeatmap;
