export const SET_RULE_LIST = "SET_RULE_LIST";
export const SET_RULE_LIST_PAGE_CONFIGURATION = "SET_RULE_LIST_PAGE_CONFIGURATION";
export const SET_RULE_INCIDENT_LIST = "SET_RULE_INCIDENT_LIST";
export const SET_CURRENT_EDIT_RULE = "SET_CURRENT_EDIT_RULE";
export const SET_CURRENT_ADD_RULE = "SET_CURRENT_ADD_RULE";
export const SET_CURRENT_CLONE_RULE = "SET_CURRENT_CLONE_RULE";
export const SET_CURRENT_DELETE_RULE = "SET_CURRENT_DELETE_RULE";
export const SET_CURRENT_SAVE_RULE = "SET_CURRENT_SAVE_RULE";
// Metrics information type
export const SET_NON_SLICE_RULE_TRAINING_DATA = "SET_NON_SLICE_RULE_TRAINING_DATA";
export const SET_SLICE_RULE_TRAINING_DATA = "SET_SLICE_RULE_TRAINING_DATA";

// Current Rule Status
export const SET_CURRENT_RULE_STATUS = "SET_CURRENT_RULE_STATUS";

// Preview information
export const SET_CURRENT_ADD_PREVIEW = "SET_CURRENT_ADD_PREVIEW";
export const SET_PREVIEW_LIST = "SET_PREVIEW_LIST";
export const SET_PREVIEW_INCIDENT_LIST = "SET_PREVIEW_INCIDENT_LIST";
export const SET_CURRENT_PREVIEW_TRAINING_DATA = "SET_CURRENT_PREVIEW_TRAINING_DATA";
export const SET_CURRENT_PREVIEW_SUMMARY_DATA = "SET_CURRENT_PREVIEW_SUMMARY_DATA";
export const SET_CURRENT_PREVIEW_METRICS_DATA = "SET_CURRENT_PREVIEW_METRICS_DATA";
export const SET_CURRENT_PREVIEW_SLICE_VALUE = "SET_CURRENT_PREVIEW_SLICE_VALUE";
