import {
  DataSourceType,
  SymptomTypeConst,
  WorkspaceIconName,
  ConnectionHealth,
} from "./enums";

import {
  DataSourceMysqlIcon,
  DataSourceSnowFlakeIcon,
  DataSourceRedshiftIcon,
  DataSourceBigQueryIcon,
  DataSourcePostgresIcon,
  DataSourceAthenaIcon,
  DataSourceDataBricksIcon,
  DataSourceHiveIcon,
  DataSourceMicrosoftSqlIcon,
  DataSourceOracleIcon,
  DataSourceIncortaIcon,
  DataSourceTeradataIcon,
  DataSourceGreenplumIcon,
  DataSourceAzureBlobStorageIcon,
  DataSourceDremioIcon,
  DataSourceS3BucketIcon,
  DataSourceSapHanaIcon,
} from "../components/icons/datasource-icon";

import {
  ConnectionHealthSuccessIcon,
  ConnectionHealthErrorIcon,
  ConnectionHealthUnknownIcon,
  ConnectionHealthPausedIcon,
} from "../components/icons/connection-health/";

import {
  I1Icon,
  I2Icon,
  I3Icon,
  I4Icon,
  I5Icon,
  I6Icon,
  I7Icon,
  I8Icon,
  I9Icon,
} from "../components/icons/workspace-icon";

import {
  ValueOutOfExpectationIcon,
  SharpChangeIcon,
  SlowBurnTrendChangeIcon,
  ManualThresholdIcon,
} from "../components/icons/symptom";

const typeToIconMapper = {
  [DataSourceType.POSTGRES]: DataSourcePostgresIcon,
  [DataSourceType.REDSHIFT]: DataSourceRedshiftIcon,
  [DataSourceType.SNOWFLAKE]: DataSourceSnowFlakeIcon,
  [DataSourceType.BIGQUERY]: DataSourceBigQueryIcon,
  [DataSourceType.MYSQL]: DataSourceMysqlIcon,
  [DataSourceType.ATHENA]: DataSourceAthenaIcon,
  [DataSourceType.DATABRICKS]: DataSourceDataBricksIcon,
  [DataSourceType.HIVE]: DataSourceHiveIcon,
  [DataSourceType.MICROSOFTSQL]: DataSourceMicrosoftSqlIcon,
  [DataSourceType.ORACLE]: DataSourceOracleIcon,
  [DataSourceType.INCORTA]: DataSourceIncortaIcon,
  [DataSourceType.TERADATA]: DataSourceTeradataIcon,
  [DataSourceType.GREENPLUM]: DataSourceGreenplumIcon,
  [DataSourceType.AZUREBLOBSTORAGE]: DataSourceAzureBlobStorageIcon,
  [DataSourceType.DREMIO]: DataSourceDremioIcon,
  [DataSourceType.S3BUCKET]: DataSourceS3BucketIcon,
  [DataSourceType.SAPHANA]: DataSourceSapHanaIcon,
};

export function getDataSourceIcon(dataSourceType) {
  return typeToIconMapper[dataSourceType] || null;
}

export function getRuleSymptomInfo(symptomType) {
  if (
    [
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS,
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
    ].includes(symptomType)
  ) {
    return {
      icon: ValueOutOfExpectationIcon,
      msg: "Metric value is outside the expectations based on past patterns.",
    };
  }

  if (symptomType === SymptomTypeConst.SHARP_CHANGE) {
    return {
      icon: SharpChangeIcon,
      msg: "Metric suddenly moves more than it normally does.",
    };
  }

  if (symptomType === SymptomTypeConst.SLOW_BURN_TREND_CHANGE) {
    return {
      icon: SlowBurnTrendChangeIcon,
      msg: "Metric trend slowly drifts away from past trend.",
    };
  }

  if (symptomType === SymptomTypeConst.MANUAL_THRESHOLD) {
    return {
      icon: ManualThresholdIcon,
      msg: "Metric breaches a defined threshold.",
    };
  }

  console.log(`Unknown symptom type ${symptomType}`);
  return null;
}

export const workspaceIconNameToIconMapper = {
  [WorkspaceIconName.I1]: I1Icon,
  [WorkspaceIconName.I2]: I2Icon,
  [WorkspaceIconName.I3]: I3Icon,
  [WorkspaceIconName.I4]: I4Icon,
  [WorkspaceIconName.I5]: I5Icon,
  [WorkspaceIconName.I6]: I6Icon,
  [WorkspaceIconName.I7]: I7Icon,
  [WorkspaceIconName.I8]: I8Icon,
  [WorkspaceIconName.I9]: I9Icon,
};

export function getWorkspaceIconFromName(iconName) {
  if (!workspaceIconNameToIconMapper[iconName]) {
    console.log(`Fail to find icon ${iconName}`);
    return I1Icon;
  }

  return workspaceIconNameToIconMapper[iconName];
}

const connectionHealthToIconMapper = {
  [ConnectionHealth.SUCCESS]: ConnectionHealthSuccessIcon,
  [ConnectionHealth.ERROR]: ConnectionHealthErrorIcon,
  [ConnectionHealth.UNKNOWN]: ConnectionHealthUnknownIcon,
  [ConnectionHealth.PAUSED]: ConnectionHealthPausedIcon,
};

export function getConnectionHealthIcon(connectionHealth) {
  return connectionHealthToIconMapper[connectionHealth] || ConnectionHealthUnknownIcon;
}
