import React from "react";

export function DetailIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <g opacity="0.85">
        <path
          d="M5.24967 7C5.24967 6.67783 5.51084 6.41667 5.83301 6.41667H10.4997C10.8218 6.41667 11.083 6.67783 11.083 7C11.083 7.32217 10.8218 7.58333 10.4997 7.58333H5.83301C5.51084 7.58333 5.24967 7.32217 5.24967 7Z"
          fill="#121224"
        />
        <path
          d="M5.83301 8.75C5.51084 8.75 5.24967 9.01117 5.24967 9.33333C5.24967 9.6555 5.51084 9.91667 5.83301 9.91667H10.4997C10.8218 9.91667 11.083 9.6555 11.083 9.33333C11.083 9.01117 10.8218 8.75 10.4997 8.75H5.83301Z"
          fill="#121224"
        />
        <path
          d="M2.91634 7C2.91634 6.67783 3.17751 6.41667 3.49967 6.41667H4.08301C4.40517 6.41667 4.66634 6.67783 4.66634 7C4.66634 7.32217 4.40517 7.58333 4.08301 7.58333H3.49967C3.17751 7.58333 2.91634 7.32217 2.91634 7Z"
          fill="#121224"
        />
        <path
          d="M3.49967 8.75C3.17751 8.75 2.91634 9.01117 2.91634 9.33333C2.91634 9.6555 3.17751 9.91667 3.49967 9.91667H4.08301C4.40517 9.91667 4.66634 9.6555 4.66634 9.33333C4.66634 9.01117 4.40517 8.75 4.08301 8.75H3.49967Z"
          fill="#121224"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.583008 2.625C0.583008 1.81959 1.23593 1.16667 2.04134 1.16667H11.958C12.7634 1.16667 13.4163 1.81959 13.4163 2.625V11.375C13.4163 12.1804 12.7634 12.8333 11.958 12.8333H2.04134C1.23593 12.8333 0.583008 12.1804 0.583008 11.375V2.625ZM1.74967 2.625C1.74967 2.46392 1.88026 2.33333 2.04134 2.33333H11.958C12.1191 2.33333 12.2497 2.46392 12.2497 2.625V3.5H1.74967V2.625ZM12.2497 4.66667V11.375C12.2497 11.5361 12.1191 11.6667 11.958 11.6667H2.04134C1.88026 11.6667 1.74967 11.5361 1.74967 11.375V4.66667H12.2497Z"
          fill="#121224"
        />
      </g>
    </svg>
  );
}
