import React from "react";
import ChartView from "../chart-view/ng-index";
import { AutoMetricsType } from "../../utils/enums";
import { getStringFromTimeStamp } from "../../utils/time";
import "./training-result-chart.scss";

function TrainingResultChart(props) {
  const {
    nonSliceRuleTrainingData,
    symptomType,
    feature,
    kpiName,
    sliceInfo,
    metricType = AutoMetricsType.NONE,
    metric = null,
    thresholdOption = {},
    onThresholdOptionChange = null,
  } = props;

  if (!nonSliceRuleTrainingData || !nonSliceRuleTrainingData.length) {
    return null;
  }

  return (
    <div className="rule-training-result-chart">
      {nonSliceRuleTrainingData.map((trainingData) => {
        let startTime = 0;
        let endTime = 0;
        if (
          trainingData &&
          trainingData.duration &&
          typeof trainingData.duration.startTime === "number" &&
          typeof trainingData.duration.endTime === "number"
        ) {
          startTime = trainingData.duration.startTime;
          endTime = trainingData.duration.endTime;
        }

        const key = `${startTime}-${endTime}`;
        const titleName = sliceInfo ? `${kpiName} | ${sliceInfo}` : kpiName;
        const { showSignalData, showStatsData } = thresholdOption[key] || {
          showSignalData: true,
          showStatsData: true,
        };
        return (
          <div className="rule-chart-data-panel" key={key}>
            <div className="rule-training-period-information-container">
              Training Period: {getStringFromTimeStamp(startTime)} to{" "}
              {getStringFromTimeStamp(endTime)}
            </div>
            <ChartView
              metricType={metricType}
              data={trainingData}
              defaultShowSignalData={showSignalData}
              defaultShowStatsData={showStatsData}
              onChartOptionChange={(newThresholdSetting) => {
                onThresholdOptionChange &&
                  onThresholdOptionChange(key, newThresholdSetting);
              }}
              incidents={[]}
              title={titleName}
              metric={metric}
              config={{
                symptomType,
                feature,
                startTime: 0,
                endTime: 0,
                detectInterval: 0,
                autosize: false,
                height: 250,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default TrainingResultChart;
