import { EVENT, trackEvent } from "../../../../utils/telemetry";

export const tableConfigChangeEventTracking = (
  enabled,
  actionType,
  currentBulkColumnUuids,
  eventProps = {}
) => {
  if (actionType === "status" && enabled) {
    if (currentBulkColumnUuids.length > 1) {
      trackEvent(EVENT.ACTIVATE_COLUMN_BULK, eventProps);
    }

    if (currentBulkColumnUuids.length === 1) {
      trackEvent(EVENT.ACTIVATE_COLUMN, eventProps);
    }
  }

  if (actionType === "distribution") {
    if (currentBulkColumnUuids.length > 1) {
      trackEvent(
        enabled
          ? EVENT.ENABLE_DISTRIBUTION_AUTOMETRIC_BULK
          : EVENT.DISABLE_DISTRIBUTION_AUTOMETRIC_BULK,
        eventProps
      );
    }

    if (currentBulkColumnUuids.length === 1) {
      trackEvent(
        enabled
          ? EVENT.ENABLE_DISTRIBUTION_AUTOMETRIC
          : EVENT.DISABLE_DISTRIBUTION_AUTOMETRIC,
        eventProps
      );
    }
  }

  if (actionType === "missingValue") {
    if (currentBulkColumnUuids.length > 1) {
      trackEvent(
        enabled
          ? EVENT.ENABLE_NULL_PERCENT_AUTOMETRIC_BULK
          : EVENT.DISABLE_NULL_PERCENT_AUTOMETRIC_BULK,
        eventProps
      );
    }

    if (currentBulkColumnUuids.length === 1) {
      trackEvent(
        enabled
          ? EVENT.ENABLE_NULL_PERCENT_AUTOMETRIC
          : EVENT.DISABLE_NULL_PERCENT_AUTOMETRIC,
        eventProps
      );
    }
  }
};
