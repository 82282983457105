import React from "react";
import { withRouter } from "react-router-dom";
import {
  getTableRows,
  incidentDirectionColumn,
  incidentDurationColumn,
  incidentIdColumn,
  incidentSeverityColumn,
  incidentStartTimeColumn,
  incidentStatusColumn,
} from "./columns";
import { ListPageColumnKey } from "../../utils/enums";
import { fnSorter } from "../../utils/sort";
import NgTable, { NgTableEmptyState } from "../../components/table/ng-table";
import { NoIncidentsIcon } from "./incident-list-entity-table";

function ProfilerIncidentList(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    workspaceUserPermissions,
    loading,
    groupedIncidentList,
    onIncidentStatusChanged,
  } = props;

  const tableRows = getTableRows(groupedIncidentList);

  const columns = [
    incidentIdColumn({
      dataIndex: ["incidentData", "id"],
      renderProps: { workspaceUuid, workspaceUserPermissions },
    }),
    {
      title: "Metric",
      key: ListPageColumnKey.METRIC_NAME,
      dataIndex: ["metricName"],
      sorter: { compare: fnSorter((row) => row.metricName) },
      width: 220,
    },
    {
      title: "Monitor",
      key: ListPageColumnKey.MONITOR_NAME,
      dataIndex: ["monitorName"],
      sorter: { compare: fnSorter((row) => row.monitorName) },
      width: 220,
    },
    incidentStartTimeColumn({
      dataIndex: ["incidentData", "startTime"],
    }),
    incidentSeverityColumn({
      dataIndex: ["incidentData", "score"],
    }),
    incidentDirectionColumn({
      dataIndex: ["incidentData", "direction"],
    }),
    incidentDurationColumn({
      dataIndex: ["incidentData", "duration"],
    }),
    incidentStatusColumn({
      dataIndex: ["incidentData", "status"],
      renderProps: {
        workspaceUserPermissions,
        onChange: (incidentData, newStatus) => {
          onIncidentStatusChanged([incidentData], newStatus);
        },
      },
    }),
  ];

  return (
    <NgTable
      loading={loading}
      columns={columns}
      dataSource={tableRows}
      rowKey={(row) => row.incidentData.id}
      sortDirections={["ascend", "descend", "ascend"]}
      pagination={{ position: ["topRight"], hideOnSinglePage: true, size: "default" }}
      scroll={{ x: 1300 }}
      emptyState={
        <NgTableEmptyState title="No incidents detected" icon={<NoIncidentsIcon />} />
      }
    />
  );
}

export default withRouter(ProfilerIncidentList);
