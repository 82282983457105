import { getColumnTypeCategory } from "../../utils/column";
import { TableColumnTypeCategory } from "../../utils/enums";
import {
  ProfileTableColumnNumberTypeIcon,
  ProfileTableColumnStringTypeIcon,
  ProfileTableColumnTimestampTypeIcon,
  ProfileTableColumnBooleanTypeIcon,
  ProfileTableColumnOtherTypeIcon,
} from "./icons";

const columnTypeIconMapper = {
  [TableColumnTypeCategory.NUMERIC]: ProfileTableColumnNumberTypeIcon,
  [TableColumnTypeCategory.STRING]: ProfileTableColumnStringTypeIcon,
  [TableColumnTypeCategory.TIMESTAMP]: ProfileTableColumnTimestampTypeIcon,
  [TableColumnTypeCategory.BOOLEAN]: ProfileTableColumnBooleanTypeIcon,
};

export function getIconFromColumn(column) {
  const columnTypeCategory = getColumnTypeCategory(column);
  if (!columnTypeCategory) {
    return ProfileTableColumnOtherTypeIcon;
  }

  return columnTypeIconMapper[columnTypeCategory] || ProfileTableColumnOtherTypeIcon;
}
