import { EVENT, trackEvent } from "../../utils/telemetry";

const getEventByKey = (type) => {
  switch (type) {
    case "datasource":
      return EVENT.SELECT_DATASOURCES_TAB;

    case "queryGovernance":
      return EVENT.SELECT_QUERY_GOVERNANCE_TAB;

    case "alert":
      return EVENT.SELECT_SYSTEM_EVENTS_TAB;

    case "integration":
      return EVENT.SELECT_INTEGRATIONS_TAB;

    case "schedule":
      return EVENT.SELECT_SCHEDULES_TAB;

    case "editWorkspace":
      return EVENT.SELECT_WORKSPACE_TAB;

    default:
      return null;
  }
};

export const trackWorkspaceNodeClick = (currentKey, props = {}) => {
  const eventName = getEventByKey(currentKey);

  if (eventName) {
    trackEvent(eventName, {
      ...props,
    });
  }
};
