import {
  FeatureTypeConst,
  getSymptomTypeDisplayName,
  MonitorRunStatus,
  MonitorStatus,
  MonitorTrainingStatus,
  PodJobStatus,
  RunStatus,
  SymptomTypeConst,
} from "./enums";
import { isNullOrUndefined } from "./objects";

export function getMonitorTypeName(monitor) {
  const symptomType = monitor?.config.symptom.type;
  return symptomType
    ? getSymptomTypeDisplayName(
        symptomType,
        monitor.config.symptom.featureConfig.type
      ).trim()
    : "";
}

export function isRawDataCorrelated(symptomType, feature) {
  if (!feature) {
    console.log("featureType is empty!");
  }

  return [
    FeatureTypeConst.ROLLING_MEAN,
    FeatureTypeConst.FORECAST_ERROR,
    FeatureTypeConst.VALUE,
    FeatureTypeConst.CHANGE,
    FeatureTypeConst.PERCENTAGE_CHANGE,
  ].includes(feature);
}

export function isTrainingRequired(symptomType) {
  return ![
    SymptomTypeConst.SLOW_BURN_TREND_CHANGE,
    SymptomTypeConst.MANUAL_THRESHOLD,
  ].includes(symptomType);
}

const monitorRunStatusToSearchOption = {
  [MonitorRunStatus.LIVE]: "Live",
  [MonitorRunStatus.LOADING]: "Live",
  [MonitorRunStatus.OK]: "Live",
  [MonitorRunStatus.RESUMING]: "Live",
  [MonitorRunStatus.TRAINING_IN_PROGRESS]: "Training",
  [MonitorRunStatus.PAUSED_TRAINING_FAILURE_INSUFFICIENT_DATA]:
    "Insufficient training data",
  [MonitorRunStatus.EXCEPTION]: "Error",
  [MonitorRunStatus.PAUSED_TRAINING_FAILURE]: "Error",
  [MonitorRunStatus.PARTIALLY_LIVE]: "Live",
  [MonitorRunStatus.PAUSED_BY_USER]: "Paused by user",
  [MonitorRunStatus.PAUSED_METRIC_PAUSE]: "Paused by metric",
  [MonitorRunStatus.PAUSED_SOURCE]: "Paused by source or schedule",
};

const monitorRunStatusPausedOptions = [
  MonitorRunStatus.PAUSED_BY_USER,
  MonitorRunStatus.PAUSED_METRIC_PAUSE,
  MonitorRunStatus.PAUSED_SOURCE,
];

export function getMonitorRunStatusOptions(monitor) {
  const options = [];
  const monitorRunStatus = monitor.status.displayStatus;
  const searchOption = monitorRunStatusToSearchOption[monitorRunStatus];
  if (searchOption) {
    options.push(searchOption);
  }
  if (monitorRunStatusPausedOptions.includes(monitorRunStatus)) {
    options.push("Paused");
  }
  return options;
}

export function getMonitorStatusFromRule(ruleObjects) {
  if (ruleObjects && ruleObjects.length > 0) {
    let isAnyInProgress = false;
    let isAnyMonitored = false;
    for (let currentRuleObject of ruleObjects) {
      if (!currentRuleObject.status || !currentRuleObject.status.training) {
        continue;
      }

      if (!currentRuleObject.status.training.isTrained) {
        if (
          currentRuleObject.status.training.trainingJobState === PodJobStatus.FAILURE
        ) {
          return MonitorStatus.FAILED;
        } else if (
          ![PodJobStatus.COMPLETED, PodJobStatus.SUCCESS].includes(
            currentRuleObject.status.training.trainingJobState
          )
        ) {
          isAnyInProgress = true;
        }
      } else if (currentRuleObject.config.isLive) {
        isAnyMonitored = true;
      }
    }

    if (isAnyInProgress) {
      return MonitorStatus.IN_PROCESS;
    }

    if (isAnyMonitored) {
      return MonitorStatus.MONITORED;
    }
  }

  return MonitorStatus.NOT_MONITORED;
}

export function isChangeFeatureType(featureType) {
  return [FeatureTypeConst.CHANGE, FeatureTypeConst.PERCENTAGE_CHANGE].includes(
    featureType
  );
}

export function monitorHasNoData(monitor) {
  return isNullOrUndefined(monitor.status.lastSampleTs);
}

export function isMonitorLiveAndRunning(monitor) {
  const runStatus = monitor.status.runStatus;
  const trainingStatus = monitor.status.training?.status;
  return (
    monitor.config.isLive &&
    (!runStatus || runStatus === RunStatus.OK) &&
    trainingStatus !== MonitorTrainingStatus.IN_PROGRESS
  );
}

// Loading represents a monitor state after training, but before the
// monitor is fully functional.
export function isMonitorLoading(monitor) {
  return monitor?.status.displayStatus === MonitorRunStatus.LOADING;
}

export function isMonitorTraining(monitor) {
  return monitor?.status.displayStatus === MonitorRunStatus.TRAINING_IN_PROGRESS;
}
