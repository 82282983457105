import React from "react";
import { MetricWhereClauseType } from "../../../utils/enums";
import SummaryViewSectionTitle from "./summary-view-section-title";
import { WhereConditionsConfigItem } from "../fields";

function WhereConditionsSummary(props) {
  const { configData } = props;
  let clauseLogic = "";
  if (configData.config?.whereClause?.clauseLogic) {
    clauseLogic = [MetricWhereClauseType.AND, MetricWhereClauseType.OR].includes(
      configData.config.whereClause.clauseLogic
    )
      ? configData.config.whereClause.clauseLogic
      : MetricWhereClauseType.CUSTOM;
  }

  return (
    Boolean(configData.config.whereClause) && (
      <>
        <SummaryViewSectionTitle title={`Where (${clauseLogic})`} />
        <WhereConditionsConfigItem
          isEdit={false}
          value={configData.config.whereClause}
        />
      </>
    )
  );
}

export default WhereConditionsSummary;
