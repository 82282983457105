import React from "react";
import IncidentDirectionSelector from "../../../incident-direction-selector/incident-direction-selector";
import { IncidentDirection, ConfigDirection } from "../../../../utils/enums";

function ConfigDirectionSelector(props) {
  const { value: direction, layout = "horizontal", disabled, onChange } = props;

  let directionSet = [];
  if (direction) {
    if (direction === ConfigDirection.BOTH) {
      directionSet = [IncidentDirection.UP, IncidentDirection.DOWN];
    } else if (direction === ConfigDirection.UP) {
      directionSet = [IncidentDirection.UP];
    } else {
      directionSet = [IncidentDirection.DOWN];
    }
  }

  function onDirectionChange(newDirectionSet) {
    let newDirection = "";
    if (newDirectionSet.length === 2) {
      newDirection = ConfigDirection.BOTH;
    } else if (newDirectionSet.length === 1) {
      if (newDirectionSet[0] === IncidentDirection.UP) {
        newDirection = ConfigDirection.UP;
      } else {
        newDirection = ConfigDirection.DOWN;
      }
    }

    if (newDirection !== direction) {
      onChange(newDirection);
    }
  }

  return (
    <IncidentDirectionSelector
      value={directionSet}
      layout={layout}
      disabled={disabled}
      onChange={onDirectionChange}
    />
  );
}

export default ConfigDirectionSelector;
