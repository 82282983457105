import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { SettingOutlined } from "@ant-design/icons";
import Button from "../../../../button/ng-button";
import TextTooltip from "../../../../text-tooltip/text-tooltip";
import NgTable from "../../../../table/ng-table";
import { MetricCategory } from "../../../../../utils/enums";
import TakeoverPanel from "../../../../takeover/takeover-panel";
import { getMetricTypeFromConfigData } from "../../../utils";
import { VerticalSplit } from "../../../fields";
import { HeaderTableIcon } from "../../../icons";
import { getDisplayTableName, getFullTableName } from "../../../../../utils/datasource";

import "./aggregation-compare-metric-takeover-view.scss";

function isRestrictedMetricType(metricType) {
  return [
    MetricCategory.TABLE_ACTIVITY,
    MetricCategory.COLUMN_ACTIVITY,
    MetricCategory.UPDATE_DELAY,
  ].includes(metricType);
}

function MetricSelectionTable({ value, onChange, metricList }) {
  const columns = [];
  columns.push({
    title: "NAME",
    key: "name",
    dataIndex: ["metadata", "name"],
    render: (name) => <TextTooltip text={name} />,
  });

  columns.push({
    title: "COLUMN",
    key: "valueColumns",
    dataIndex: ["config", "valueColumns"],
    ellipsis: true,
    render: function (valueColumns) {
      if (valueColumns?.length > 0) {
        const displayValueColumns = (valueColumns || [])
          .map((currentValueColumn) => currentValueColumn.columnName)
          .join(", ");
        return <TextTooltip text={displayValueColumns} />;
      } else {
        return <span className="aggregation-compare-metric-takeover-view-na">N/A</span>;
      }
    },
  });

  function onRowSelected(record, isSelected) {
    onChange(isSelected ? [record] : []);
  }

  return (
    <NgTable
      className="metric-selection-table-container"
      dataSource={metricList}
      columns={columns}
      rowKey={(metric) => metric.metadata.uuid}
      size={"middle"}
      pagination={{ position: ["bottomRight"], hideOnSinglePage: true }}
      sortDirections={["ascend", "descend", "ascend"]}
      rowSelection={{
        selectedRowKeys: value.map((metric) => metric.metadata.uuid),
        onSelect: onRowSelected,
        hideSelectAll: true,
      }}
    />
  );
}

function AggregationCompareMetricTakeOverView(props) {
  const {
    value: defaultValue = [],
    onSave,
    sourceTable,
    targetTable,
    kpiList,
    closeTakeover,
  } = props;

  const [sourceSelectedRowKeys, setSourceSelectedRowKeys] = useState([]);
  const [targetSelectedRowKeys, setTargetSelectedRowKeys] = useState([]);

  const sourceTableDisplayName = sourceTable
    ? getDisplayTableName(getFullTableName(sourceTable.table))
    : "";
  const targetTableDisplayName = targetTable
    ? getDisplayTableName(getFullTableName(targetTable.table))
    : "";

  const { sourceMetricList, targetMetricList } = useMemo(() => {
    const sourceMetricList = [];
    const targetMetricList = [];

    if (sourceTable && targetTable && kpiList?.length > 0) {
      const existingSourceMetricUuidsMapper = {};
      const existingTargetMetricUuidsMapper = {};
      defaultValue.forEach(({ sourceMetricUuid, targetMetricUuid }) => {
        existingSourceMetricUuidsMapper[sourceMetricUuid] = true;
        existingTargetMetricUuidsMapper[targetMetricUuid] = true;
      });

      for (let currentMetric of kpiList) {
        const currentMetricCategory = getMetricTypeFromConfigData(currentMetric);
        if (
          [
            MetricCategory.AGGREGATION_COMPARE,
            MetricCategory.DATA_DELAY,
            MetricCategory.FULL_COMPARE,
          ].includes(currentMetricCategory)
        ) {
          continue;
        }

        const currentMetricTableUuid = currentMetric.config?.table?.tableUuid;
        if (!currentMetricTableUuid) {
          continue;
        }

        if (isRestrictedMetricType(getMetricTypeFromConfigData(currentMetric))) {
          continue;
        }

        const currentMetricUuid = currentMetric.metadata.uuid;
        const currentMetricSource = currentMetric.config.sources[0];
        if (
          sourceTable.sourceUuid === currentMetricSource &&
          currentMetricTableUuid === sourceTable.table.tableUuid &&
          !existingSourceMetricUuidsMapper[currentMetricUuid]
        ) {
          sourceMetricList.push(currentMetric);
        }

        if (
          targetTable.sourceUuid === currentMetricSource &&
          currentMetricTableUuid === targetTable.table.tableUuid &&
          !existingTargetMetricUuidsMapper[currentMetricUuid]
        ) {
          targetMetricList.push(currentMetric);
        }
      }
    }

    return { sourceMetricList, targetMetricList };
  }, [defaultValue, sourceTable, targetTable, kpiList]);

  function onCancelClicked() {
    closeTakeover();
  }

  function onSaveClicked() {
    onSave({
      sourceMetricUuid: sourceSelectedRowKeys[0].metadata.uuid,
      targetMetricUuid: targetSelectedRowKeys[0].metadata.uuid,
    });
    closeTakeover();
  }

  const selectedMetricNumber =
    sourceSelectedRowKeys.length + targetSelectedRowKeys.length;

  const cornerControls = (
    <div className="aggregation-compare-metric-takeover-view-panel-controls">
      <Button outline size="large" onClick={onCancelClicked}>
        Cancel
      </Button>
      <Button
        primary
        size="large"
        onClick={onSaveClicked}
        disabled={selectedMetricNumber !== 2}
      >
        {`Add Metrics (${selectedMetricNumber}/2)`}
      </Button>
    </div>
  );

  return (
    <TakeoverPanel
      title="Add Metrics"
      titleIcon={<SettingOutlined />}
      cornerControls={cornerControls}
    >
      <div className="aggregation-compare-metric-takeover-view-content-container">
        <VerticalSplit>
          <div>
            <div className="aggregation-compare-metric-takeover-view-table-name">
              <HeaderTableIcon />
              {sourceTableDisplayName}
            </div>
            <MetricSelectionTable
              metricList={sourceMetricList}
              value={sourceSelectedRowKeys}
              onChange={setSourceSelectedRowKeys}
            />
          </div>
          <div>
            <div className="aggregation-compare-metric-takeover-view-table-name">
              <HeaderTableIcon />
              {targetTableDisplayName}
            </div>
            <MetricSelectionTable
              metricList={targetMetricList}
              value={targetSelectedRowKeys}
              onChange={setTargetSelectedRowKeys}
            />
          </div>
        </VerticalSplit>
      </div>
    </TakeoverPanel>
  );
}

const mapStateToProps = (state) => ({
  kpiList: state.kpiReducer.kpiList.data,
});

const mapDispatchToProps = (_dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AggregationCompareMetricTakeOverView);
