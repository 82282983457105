import {
  EVENT,
  PAGE,
  getColumnDetailProps,
  getDatasourceDetailProps,
  getSchemaDetailProps,
  getTableDetailProps,
  trackEvent,
} from "../../../utils/telemetry";

const trackColumnEvent = (metric, columnInfo, schemaInfo, dataSource) => {
  if (metric) {
    trackEvent(EVENT.SELECT_METRIC_UNDER_COLUMN, {
      ...getColumnDetailProps(columnInfo, schemaInfo, dataSource),
      metric_id: metric?.uuid || "",
      page: PAGE.EXPLORER,
    });
    return;
  }

  trackEvent(EVENT.SELECT_COLUMN, {
    ...getColumnDetailProps(columnInfo, schemaInfo, dataSource),
    page: PAGE.EXPLORER,
  });
};

const trackTableEvent = (metric, tableInfo, schemaInfo, dataSource) => {
  if (metric) {
    trackEvent(EVENT.SELECT_METRIC_UNDER_TABLE, {
      ...getTableDetailProps(tableInfo, schemaInfo, dataSource),
      metric_id: metric?.uuid || "",
      page: PAGE.EXPLORER,
    });
    return;
  }

  trackEvent(EVENT.SELECT_TABLE, {
    ...getTableDetailProps(tableInfo, schemaInfo, dataSource),
    page: PAGE.EXPLORER,
  });
};

const trackSchemaEvent = (metric, schemaInfo, dataSource) => {
  if (metric) {
    trackEvent(EVENT.SELECT_METRIC_UNDER_SCHEMA, {
      ...getSchemaDetailProps(schemaInfo, dataSource),
      metric_id: metric?.uuid || "",
      page: PAGE.EXPLORER,
    });
    return;
  }

  trackEvent(EVENT.SELECT_SCHEMA, {
    ...getSchemaDetailProps(schemaInfo, dataSource),
    page: PAGE.EXPLORER,
  });
};

const trackDatasourceEvent = (metric, dataSource) => {
  if (metric) {
    trackEvent(EVENT.SELECT_METRIC_UNDER_DATASOURCE, {
      ...getDatasourceDetailProps(dataSource),
      metric_id: metric?.uuid || "",
      page: PAGE.EXPLORER,
    });
    return;
  }

  trackEvent(EVENT.SELECT_DATASOURCE, {
    ...getDatasourceDetailProps(dataSource),
    page: PAGE.EXPLORER,
  });
};

export const trackProfilerDataSourceTreeClick = (data) => {
  const columnInfo = data?.columnInfo;
  const tableInfo = data?.tableInfo;
  const schemaInfo = data?.schemaInfo;
  const dataSource = data?.dataSource;
  const metric = data?.metric;

  if (columnInfo) {
    return trackColumnEvent(metric, columnInfo, schemaInfo, dataSource);
  }

  if (tableInfo) {
    return trackTableEvent(metric, tableInfo, schemaInfo, dataSource);
  }

  if (schemaInfo) {
    return trackSchemaEvent(metric, schemaInfo, dataSource);
  }

  if (dataSource) {
    return trackDatasourceEvent(metric, dataSource);
  }
};
