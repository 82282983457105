export function union(setA, ...restSets) {
  let un = new Set(setA);
  for (let otherSet of restSets) {
    for (let elem of otherSet) {
      un.add(elem);
    }
  }
  return un;
}

export function difference(setA, setB) {
  let diff = new Set(setA);
  for (let elem of setB) {
    diff.delete(elem);
  }
  return diff;
}

export function equals(setA, setB) {
  if (setA.size !== setB.size) {
    return false;
  }
  for (let elem of setA) {
    if (!setB.has(elem)) {
      return false;
    }
  }
  return true;
}
