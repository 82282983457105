import { connect } from "react-redux";

const MaskedStarIcons = () => {
  return (
    <svg fill="none" width="56" height="8" viewBox="0 0 56 8">
      <path
        d="M5.642 7.014L3.444 5.334L1.344 7L1.26 6.944L2.254 4.41L0.14 3.094L0.168 2.982L2.702 3.038L3.444 0.588H3.542L4.242 3.038L6.804 2.982L6.832 3.094L4.69 4.382L5.712 6.958L5.642 7.014ZM12.6147 7.014L10.4167 5.334L8.31666 7L8.23266 6.944L9.22666 4.41L7.11266 3.094L7.14066 2.982L9.67466 3.038L10.4167 0.588H10.5147L11.2147 3.038L13.7767 2.982L13.8047 3.094L11.6627 4.382L12.6847 6.958L12.6147 7.014ZM19.5873 7.014L17.3893 5.334L15.2893 7L15.2053 6.944L16.1993 4.41L14.0853 3.094L14.1133 2.982L16.6473 3.038L17.3893 0.588H17.4873L18.1873 3.038L20.7493 2.982L20.7773 3.094L18.6353 4.382L19.6573 6.958L19.5873 7.014ZM26.56 7.014L24.362 5.334L22.262 7L22.178 6.944L23.172 4.41L21.058 3.094L21.086 2.982L23.62 3.038L24.362 0.588H24.46L25.16 3.038L27.722 2.982L27.75 3.094L25.608 4.382L26.63 6.958L26.56 7.014ZM33.5326 7.014L31.3346 5.334L29.2346 7L29.1506 6.944L30.1446 4.41L28.0306 3.094L28.0586 2.982L30.5926 3.038L31.3346 0.588H31.4326L32.1326 3.038L34.6946 2.982L34.7226 3.094L32.5806 4.382L33.6026 6.958L33.5326 7.014ZM40.5053 7.014L38.3073 5.334L36.2073 7L36.1233 6.944L37.1173 4.41L35.0033 3.094L35.0313 2.982L37.5653 3.038L38.3073 0.588H38.4053L39.1053 3.038L41.6673 2.982L41.6953 3.094L39.5533 4.382L40.5753 6.958L40.5053 7.014ZM47.4779 7.014L45.2799 5.334L43.1799 7L43.0959 6.944L44.0899 4.41L41.9759 3.094L42.0039 2.982L44.5379 3.038L45.2799 0.588H45.3779L46.0779 3.038L48.6399 2.982L48.6679 3.094L46.5259 4.382L47.5479 6.958L47.4779 7.014ZM54.4506 7.014L52.2526 5.334L50.1526 7L50.0686 6.944L51.0626 4.41L48.9486 3.094L48.9766 2.982L51.5106 3.038L52.2526 0.588H52.3506L53.0506 3.038L55.6126 2.982L55.6406 3.094L53.4986 4.382L54.5206 6.958L54.4506 7.014Z"
        fill="#121224"
      />
    </svg>
  );
};

function MaskedColumnContent({ value = "", waffle }) {
  return <MaskedStarIcons />;
}

const mapStateToProps = (state) => ({
  waffle: state.userReducer.currentUserInfo.waffle,
});

export default connect(mapStateToProps)(MaskedColumnContent);
