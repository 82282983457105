export function OffsetTimeIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5001 9.33333C10.5001 9.01117 10.2389 8.75 9.91675 8.75C9.59458 8.75 9.33341 9.01117 9.33341 9.33333V10.5C9.33341 10.8222 9.59458 11.0833 9.91675 11.0833H11.0834C11.4056 11.0833 11.6667 10.8222 11.6667 10.5C11.6667 10.1778 11.4056 9.91667 11.0834 9.91667H10.5001V9.33333Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2084 3.20833C10.2084 3.41844 10.1714 3.6199 10.1035 3.80654C10.6591 3.89592 11.0834 4.37756 11.0834 4.95833V7.12077C12.43 7.50164 13.4167 8.73976 13.4167 10.2083C13.4167 11.9802 11.9803 13.4167 10.2084 13.4167C9.21212 13.4167 8.32189 12.9625 7.73343 12.25H2.33341C1.68909 12.25 1.16675 11.7277 1.16675 11.0833V4.95833C1.16675 4.37756 1.59111 3.89592 2.14666 3.80653C2.07877 3.61989 2.04175 3.41844 2.04175 3.20833V2.625C2.04175 1.6585 2.82525 0.875 3.79175 0.875H8.45841C9.42491 0.875 10.2084 1.6585 10.2084 2.625V3.20833ZM2.33341 11.0833V4.95833H9.91675V7.01308C8.28152 7.16044 7.00008 8.53474 7.00008 10.2083C7.00008 10.5117 7.04218 10.8052 7.12085 11.0833H2.33341ZM10.2084 8.16667C9.08083 8.16667 8.16675 9.08075 8.16675 10.2083C8.16675 11.3359 9.08083 12.25 10.2084 12.25C11.336 12.25 12.2501 11.3359 12.2501 10.2083C12.2501 9.08075 11.336 8.16667 10.2084 8.16667ZM4.95841 3.79167H5.54175V2.04167H4.95841V3.79167ZM7.29175 3.79167H6.70841V2.04167H7.29175V3.79167Z"
        fill="#121224"
      />
    </svg>
  );
}
