import React, { useState } from "react";
import { connect } from "react-redux";
import { updateProfilerConfigTableList } from "../../../actions/profiler/profiler-action";
import NgTooltip from "../../../components/tooltip/ng-tooltip";
import { dataSourceSupportsMetricType } from "../../../utils/datasource";
import { MetricCategory, isFileSource as getIsFileSource } from "../../../utils/enums";
import { metadataMetricTypes } from "../../../utils/metric";
import {
  isTableMetricTypeEnabled,
  tableSupportsMetricType,
  toggleTableMetricType,
} from "../../../utils/table";
import { PAGE, getSchemaDetailProps } from "../../../utils/telemetry";
import { ManageTabHeader, ManageTabNgTable, tableNameColumn } from "../manage-tabs";
import { profilerConfigChangeEventTracking } from "../takeover/schema/schema-tracking";
import { profilerEnabledFnSorter } from "../../../utils/profiler-util";
import { fnSorter } from "../../../utils/sort";
import useBatchUpdate from "../use-batch-update";
import NgButton from "../../../components/button/ng-button";
import { SettingOutlined } from "@ant-design/icons";
import useNavigationBlocker from "../../../hooks/useNavigationBlocker";
import { NgTableTheme } from "../../../components/table/ng-table";
import Switch from "../../../atom/switch";
import Checkbox from "../../../atom/checkbox";

import "./profiler-manage-tables-tab.scss";

function ProfilerSchemaConfigureAutometrics(props) {
  const {
    availableNodeKeys,
    config,
    enableEdit,
    loading,
    searchText,
    tableList,
    workspaceUuid,
    onCurrentSelectNodeChange,
    setSearchText,
    updateProfilerConfigTableList,
    onConfigure,
  } = props;

  const [currentSelectTableKeys, setCurrentSelectTableKeys] = useState([]);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [enableMonitors, setEnableMonitors] = useState(true);
  const {
    currentEntityList: currentTableList,
    toggleAction,
    batchList,
    isDirty,
    reset,
  } = useBatchUpdate({
    entityList: tableList,
    getEntityKey: (table) => table.uuid,
    entityUpdate: (table, payload) => {
      const { metricType: currentMetricType, enabled } = payload;
      const metricTypes = Array.isArray(currentMetricType)
        ? currentMetricType
        : [currentMetricType];
      return metricTypes
        .filter((metricType) => isMetricTypeSupported(table, metricType))
        .reduce((acc, metricType) => {
          return toggleTableMetricType(acc, metricType, enabled);
        }, table);
    },
    ignoreKeys: [
      "firstSeenTs",
      "lastScannedTs",
      "lastSeenTs",
      "removedTs",
      "schemaUpdatedTs",
    ],
  });

  useNavigationBlocker(isDirty, "You have unsaved changes - discard?", reset);

  if (!config.dataSource) {
    console.log("No data source is provided");
    return null;
  }

  if (!config.schema) {
    console.log("No schema is provided");
    return null;
  }

  const { dataSource, schema } = config;

  const dataSourceType = dataSource.config.connection.type;
  const isFileSource = getIsFileSource(dataSourceType);

  const metadataMetricsColumns = [
    {
      title: "Row Count",
      tooltip:
        "Row count is a metadata metric that measures the number of rows in the table",
      type: MetricCategory.ROW_COUNT,
      width: 125,
    },
    {
      title: "Byte Count",
      tooltip:
        "Byte count is a metadata metric that measures the number of rows in the table",
      type: MetricCategory.BYTE_COUNT,
      width: 125,
    },
    {
      title: "Column Activity",
      tooltip:
        "Column activity is a metadata metric that measures changes to the columns in the table - this can be a column type change or addition or deletion of a column",
      type: MetricCategory.EVENT,
      width: 160,
    },
    {
      title: "Update Delay",
      tooltip:
        "Update Delay is a metadata metric that measures the number of minutes since the table was last updated",
      type: MetricCategory.UPDATE_DELAY,
      width: 140,
    },
  ].filter(({ type }) => dataSourceSupportsMetricType(dataSource, type));

  const deepMetricsColumns = [
    {
      title: "Data Delay",
      type: MetricCategory.DATA_DELAY,
      tooltip:
        "Data Delay is a deep metric that measures the number of minutes since the latest data timestamp",
      width: 120,
    },
    {
      title: "Data Volume",
      type: MetricCategory.DATA_VOLUME,
      tooltip:
        "Data Volume is a deep metric that measures the number of rows added to the table in each hour, where each hour is specified by the data timestamp",
      width: 130,
    },
  ].filter(({ type }) => dataSourceSupportsMetricType(dataSource, type));

  const availableMetrics = [...metadataMetricsColumns, ...deepMetricsColumns].map(
    ({ type }) => type
  );

  function updateTables(workspaceUuid, dataSource, paramsList) {
    setIsUpdateInProgress(true);
    updateProfilerConfigTableList(workspaceUuid, dataSource, paramsList).finally(() => {
      setIsUpdateInProgress(false);
    });
  }

  function isMetricTypeSupported(table, metricType) {
    const isDeepMetric =
      !metadataMetricTypes.has(metricType) && metricType !== MetricCategory.EVENT;
    return (
      (!isDeepMetric || table.profilerConfig?.enabled) &&
      tableSupportsMetricType(table, metricType)
    );
  }

  function onProfilerConfigChange(currentTable, enabled, metricType = null) {
    const currentBulkTableUuids =
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid];

    if (metricType !== null) {
      profilerConfigChangeEventTracking(enabled, metricType, currentBulkTableUuids, {
        ...getSchemaDetailProps(schema, dataSource),
        page: PAGE.MANAGE_TABLES,
      });
    }

    toggleAction(currentBulkTableUuids, {
      metricType: metricType === null ? availableMetrics : metricType,
      enabled,
    });
  }

  function saveChanges() {
    if (batchList.length > 0) {
      updateTables(
        workspaceUuid,
        dataSource,
        enableMonitors
          ? batchList.map((table) => ({
              ...table,
              profilerConfig: {
                ...table.profilerConfig,
                createAutometricMonitors: true,
              },
            }))
          : batchList
      );
    }
  }

  function onSelectChange(newSelectedRowKeys) {
    setCurrentSelectTableKeys(newSelectedRowKeys);
  }

  function onConfigureClick(currentTable) {
    const currentBulkTableUuids = new Set(
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid]
    );
    const targetTableList = tableList.filter(({ uuid }) =>
      currentBulkTableUuids.has(uuid)
    );
    onConfigure(targetTableList.map(({ tableName }) => tableName));
  }

  const rowSelection = {
    selectedRowKeys: currentSelectTableKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  function getColumnConfig(config) {
    const { title, type, tooltip, width } = config;
    const isDeepMetric =
      !metadataMetricTypes.has(type) && type !== MetricCategory.EVENT;
    const metricTypeEnabledSorter = (row) =>
      isTableMetricTypeEnabled(row, type) ? 1 : 0;
    const sortFn = isDeepMetric
      ? profilerEnabledFnSorter(metricTypeEnabledSorter)
      : fnSorter(metricTypeEnabledSorter);

    return {
      title: tooltip ? <NgTooltip title={tooltip}>{title}</NgTooltip> : title,
      key: title,
      width,
      render: function (_, row) {
        return (
          <Switch
            size="small"
            checked={isTableMetricTypeEnabled(row, type)}
            onChange={(checked) => onProfilerConfigChange(row, checked, type)}
            disabled={
              !enableEdit || !isMetricTypeSupported(row, type) || isUpdateInProgress
            }
          />
        );
      },
      sorter: {
        compare: sortFn,
      },
    };
  }

  const columns = [
    {
      title: "",
      children: [
        tableNameColumn({
          dataIndex: "tableName",
          width: 208,
          fixed: "left",
          renderProps: {
            availableNodeKeys,
            dataSource,
            schema,
            onConfigureClick,
            onCurrentSelectNodeChange,
            renderPrefix: (_, data) => {
              const isEnabled = availableMetrics.some((type) =>
                isTableMetricTypeEnabled(data, type)
              );
              return (
                <Switch
                  size="small"
                  checked={isEnabled}
                  disabled={!enableEdit || isUpdateInProgress}
                  onClick={() => onProfilerConfigChange(data, !isEnabled)}
                />
              );
            },
          },
        }),
      ],
    },
    {
      title: (
        <span className="profiler-schema-configure-autometrics-group-column-title">
          Metadata Metrics
        </span>
      ),
      children: metadataMetricsColumns.map(getColumnConfig),
    },
    {
      title: (
        <span className="profiler-schema-configure-autometrics-group-column-title">
          Deep Metrics
        </span>
      ),
      children: [
        {
          title: "Config",
          width: 110,
          render: (data) => {
            const style = { margin: "auto", width: 32 };
            if (data.profilerConfig.enabled) {
              style.backgroundColor = "#F3F4F6";
            }
            return (
              <NgButton outline onClick={() => onConfigureClick(data)} style={style}>
                <SettingOutlined />
              </NgButton>
            );
          },
        },
        ...deepMetricsColumns.map(getColumnConfig),
      ],
    },
  ];

  const filteredTableList =
    searchText.length > 0
      ? currentTableList.filter(
          ({ tableName }) =>
            tableName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : currentTableList;

  const isScanning = !schema.lastTablesScannedTs;

  const advancedControls = (
    <Checkbox
      className="manage-table-advanced-checkbox-container"
      checked={enableMonitors}
      onChange={(e) => {
        setEnableMonitors(e.target.checked);
      }}
    >
      Enable monitors
    </Checkbox>
  );

  const headerControls = (
    <>
      <NgButton
        outline
        testId="manage-table-autometrics-cancel-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={reset}
      >
        Cancel
      </NgButton>
      <NgButton
        testId="manage-table-autometrics-save-button"
        disabled={!isDirty || isUpdateInProgress || !enableEdit}
        onClick={saveChanges}
      >
        {isUpdateInProgress ? "Saving..." : "Save"}
      </NgButton>
    </>
  );

  const columnSeparator = metadataMetricsColumns.length + 2;

  return (
    <div className="profiler-manage-tables-tab">
      <ManageTabHeader
        entityName="table"
        searchText={searchText}
        setSearchText={setSearchText}
        lastScannedTs={schema.lastScannedTs}
        rightControls={headerControls}
        advancedControls={advancedControls}
      />
      <div
        className={`profiler-manage-tables-tab-table-container table-separator-column-${columnSeparator}`}
      >
        {(!isFileSource || tableList.length !== 0) && (
          <ManageTabNgTable
            entityName="table"
            theme={NgTableTheme.DARK}
            loadingTitle={isScanning ? "Scanning..." : undefined}
            rowSelection={rowSelection}
            dataSource={filteredTableList}
            columns={columns}
            rowKey={"uuid"}
            loading={loading || isScanning}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.profilerReducer.profilerConfigDataSourceTableList.loading,
  tableList: state.profilerReducer.profilerConfigDataSourceTableList.data,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfilerConfigTableList: (workspaceUuid, dataSource, paramsList) =>
    dispatch(
      updateProfilerConfigTableList(workspaceUuid, dataSource, paramsList, {
        isRefreshTree: true,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilerSchemaConfigureAutometrics);
