import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getQueryObject } from "../../utils";
import {
  HighSeverityIcon,
  NormalSeverityIcon,
} from "../../../../components/icons/severity/";
import {
  IncidentDirectionBothIcon,
  IncidentDirectionDownIcon,
  IncidentDirectionUpIcon,
} from "../../../../components/icons/incident-direction-icon";
import {
  DashboardFilterType,
  IncidentCreatorType,
  IncidentDirection,
  IncidentScore,
  TableType,
} from "../../../../utils/enums";
import { getQueryStringFromUserSetting } from "../../../../utils/user-setting";
import { getSliceDisplayString } from "../../../../utils/general";
import { usePrevious } from "../../../../utils/hooks";
import {
  getAlignedTimePeriodList,
  getStringFromTimeStamp,
} from "../../../../utils/time";
import { getURIInstance, URIPath } from "../../../../utils/uri-path";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import { getLocalFilteredIncidentList } from "../../../incidents/incident-search";
import {
  getIncidentStatusDisplayStr,
  getIncidentValidationStatusDisplayStr,
  getTagsFromCreatorInfo,
} from "../../../../utils/incident";
import { getFullTableName } from "../../../../utils/datasource";
import NgTable from "../../../../components/table/ng-table";
import { deepcopy } from "../../../../utils/objects";

import "./table-chart-view.scss";

function addNewColumnInfo(resultList, newColumnInfoList) {
  if (newColumnInfoList.length === 1) {
    // For performance improvement
    resultList.forEach(({ uniqueKeyArray, initData }) => {
      const { attributeName, key, initValue, additionalInfo } = newColumnInfoList[0];
      if (key !== undefined) {
        uniqueKeyArray.push(key);
      }

      if (initValue !== undefined) {
        initData[attributeName] = initValue;
      }

      if (additionalInfo) {
        for (let key in additionalInfo) {
          initData[key] = additionalInfo[key];
        }
      }
    });

    return resultList;
  }

  const newResultList = [];
  for (let currentResultItem of resultList) {
    const { uniqueKeyArray, initData } = currentResultItem;
    for (let currentNewInfoItem of newColumnInfoList) {
      const { attributeName, key, initValue, additionalInfo = {} } = currentNewInfoItem;
      const newUniqueKeyArray = [...uniqueKeyArray];
      key && newUniqueKeyArray.push(key);
      const newInitData = {
        ...initData,
        ...additionalInfo,
        [attributeName]: initValue,
      };
      newResultList.push({
        uniqueKeyArray: newUniqueKeyArray,
        initData: newInitData,
      });
    }
  }

  return newResultList;
}

function getUniqueKeys(
  creatorInfo,
  slice,
  groupColumns,
  aggregationColumns,
  listOfSelectedTags,
  selectedTimeRange,
  incident
) {
  let result = [{ uniqueKeyArray: [], initData: { tags: "" } }];
  let key;
  let initValue;
  if (groupColumns.includes("metric")) {
    key = creatorInfo.kpiInfo.metadata.uuid;
    initValue = creatorInfo.kpiInfo.metadata.name;

    result = addNewColumnInfo(result, [
      {
        attributeName: "metric",
        key,
        initValue,
        additionalInfo: { metricId: creatorInfo.kpiInfo.metadata.idSerial.toString() },
      },
    ]);
  }

  if (groupColumns.includes("monitor")) {
    key = creatorInfo.filterInfo.metadata.uuid;
    initValue = creatorInfo.filterInfo.metadata.name;

    result = addNewColumnInfo(result, [
      {
        attributeName: "monitor",
        key,
        initValue,
        additionalInfo: {
          monitorId: creatorInfo.filterInfo.metadata.idSerial.toString(),
        },
      },
    ]);
  }

  if (groupColumns.includes("datasource")) {
    key = creatorInfo.dataSourceInfo.metadata.uuid;
    initValue = creatorInfo.dataSourceInfo.metadata.name;
    result = addNewColumnInfo(result, [
      {
        attributeName: "datasource",
        key,
        initValue,
        additionalInfo: {
          dataSourceId: creatorInfo.dataSourceInfo.metadata.idSerial.toString(),
        },
      },
    ]);
  }

  if (groupColumns.includes("slice") && slice) {
    result = addNewColumnInfo(result, [
      { attributeName: "slice", key: slice, initValue: slice },
    ]);
  }

  if (groupColumns.includes("table")) {
    key = "table-n-a";
    initValue = "N/A";
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      if (
        creatorInfo.kpiInfo.config.table &&
        creatorInfo.kpiInfo.config.table.type === TableType.TABLE
      ) {
        key = getFullTableName(creatorInfo.kpiInfo.config.table);
        initValue = getFullTableName(creatorInfo.kpiInfo.config.table);
      }
    }

    result = addNewColumnInfo(result, [{ attributeName: "table", key, initValue }]);
  }

  if (groupColumns.includes("column")) {
    key = "column-n-a";
    initValue = "N/A";
    if (
      creatorInfo.type === IncidentCreatorType.FILTER &&
      creatorInfo.kpiInfo.config.valueColumns &&
      creatorInfo.kpiInfo.config.valueColumns.length > 0
    ) {
      key = creatorInfo.kpiInfo.config.valueColumns
        .map(({ columnName }) => columnName)
        .join(",");
      initValue = key;
    }

    result = addNewColumnInfo(result, [{ attributeName: "column", key, initValue }]);
  }

  if (groupColumns.includes("status") && incident) {
    result = addNewColumnInfo(result, [
      {
        attributeName: "status",
        key: incident.status,
        initValue: incident.status,
      },
    ]);
  }

  if (groupColumns.includes("validationStatus") && incident) {
    result = addNewColumnInfo(result, [
      {
        attributeName: "validationStatus",
        key: incident.validationStatus,
        initValue: incident.validationStatus,
      },
    ]);
  }

  if (groupColumns.includes("severity") && incident) {
    result = addNewColumnInfo(result, [
      {
        attributeName: "severity",
        key: incident.score,
        initValue: incident.score,
      },
    ]);
  }

  if (groupColumns.includes("direction") && incident) {
    const directionKey = incident.direction === null ? "" : incident.direction;
    result = addNewColumnInfo(result, [
      {
        attributeName: "direction",
        key: directionKey,
        initValue: incident.direction,
      },
    ]);
  }

  if (aggregationColumns.includes("incident")) {
    result = addNewColumnInfo(result, [
      { attributeName: "incident", key: "", initValue: 0 },
    ]);
  }

  if (aggregationColumns.includes("sliceSet")) {
    result = addNewColumnInfo(result, [
      { attributeName: "sliceSet", key: "", initValue: new Set() },
    ]);
  }

  if (groupColumns.includes("tags")) {
    let currentTags = getTagsFromCreatorInfo(creatorInfo, false) || [];
    if (listOfSelectedTags && listOfSelectedTags.length > 0) {
      currentTags = currentTags.filter((currentTag) => {
        for (let currentSelectedTags of listOfSelectedTags) {
          if (currentSelectedTags.includes(currentTag)) {
            return true;
          }
        }
        return false;
      });
    }

    if (currentTags && currentTags.length > 0) {
      result = addNewColumnInfo(
        result,
        currentTags.map((currentTagName) => {
          return {
            attributeName: "tags",
            key: currentTagName,
            initValue: currentTagName,
          };
        })
      );
    }
  }

  if (groupColumns.includes("week") && incident) {
    const alignedWeekTimePeriodList = getAlignedTimePeriodList(
      Math.max(getUnixTime(selectedTimeRange.startTime), incident.startTime),
      Math.min(getUnixTime(selectedTimeRange.endTime), incident.endTime),
      "1w"
    );
    if (alignedWeekTimePeriodList.length > 0) {
      result = addNewColumnInfo(
        result,
        alignedWeekTimePeriodList.map((currentTimePeriod) => {
          return {
            attributeName: "week",
            key: currentTimePeriod.startTimestamp,
            initValue: currentTimePeriod,
          };
        })
      );
    }
  }

  if (groupColumns.includes("day") && incident) {
    const alignedDayTimePeriodList = getAlignedTimePeriodList(
      Math.max(getUnixTime(selectedTimeRange.startTime), incident.startTime),
      Math.min(getUnixTime(selectedTimeRange.endTime), incident.endTime),
      "1d"
    );
    if (alignedDayTimePeriodList.length > 0) {
      result = addNewColumnInfo(
        result,
        alignedDayTimePeriodList.map((currentTimePeriod) => {
          return {
            attributeName: "day",
            key: currentTimePeriod.startTimestamp,
            initValue: currentTimePeriod,
          };
        })
      );
    }
  }

  if (groupColumns.includes("hour") && incident) {
    const alignedHourTimePeriodList = getAlignedTimePeriodList(
      Math.max(getUnixTime(selectedTimeRange.startTime), incident.startTime),
      Math.min(getUnixTime(selectedTimeRange.endTime), incident.endTime),
      "1h"
    );
    if (alignedHourTimePeriodList.length > 0) {
      result = addNewColumnInfo(
        result,
        alignedHourTimePeriodList.map((currentTimePeriod) => {
          return {
            attributeName: "hour",
            key: currentTimePeriod.startTimestamp,
            initValue: currentTimePeriod,
          };
        })
      );
    }
  }

  return result.map(({ uniqueKeyArray, initData }) => {
    const currentUniqueKey = uniqueKeyArray.join(",");
    const currentInitData = { ...initData, uuid: currentUniqueKey };
    return {
      uniqueKey: currentUniqueKey,
      initData: currentInitData,
    };
  });
}

function updateRowData(rowData, aggregationColumns, incidentNumber, slice) {
  if (aggregationColumns.includes("incident")) {
    rowData["incident"] = rowData["incident"] + incidentNumber;
  }

  if (aggregationColumns.includes("sliceSet")) {
    slice && rowData["sliceSet"].add(slice);
  }
}

function getIncidentNumberTimeSetting(currentTimeRange, selectedTimeRange) {
  const startTime = fromUnixTime(
    Math.max(currentTimeRange.startTimestamp, getUnixTime(selectedTimeRange.startTime))
  );
  const endTime = fromUnixTime(
    Math.min(currentTimeRange.endTimestamp, getUnixTime(selectedTimeRange.endTime))
  );
  return {
    startTime,
    endTime,
    current: endTime,
    currentInterval: "custom",
  };
}

function getIncidentNumberLink(
  currentUsersSetting,
  localFilterSetting,
  currentRowData
) {
  const defaultUserSetting = deepcopy(currentUsersSetting);
  defaultUserSetting.localFilterSetting = deepcopy(localFilterSetting);

  // The value from grouped by column will override the value from filter list.
  if (currentRowData.dataSourceId) {
    defaultUserSetting.localFilterSetting.dataSourceId = [currentRowData.dataSourceId];
  }

  if (currentRowData.monitorId) {
    defaultUserSetting.localFilterSetting.monitorId = [currentRowData.monitorId];
  }

  if (currentRowData.metricId) {
    defaultUserSetting.localFilterSetting.metricId = [currentRowData.metricId];
  }

  if (currentRowData.table) {
    defaultUserSetting.localFilterSetting.tableName = [currentRowData.table];
  }

  if (currentRowData.column) {
    defaultUserSetting.localFilterSetting.columnName = [currentRowData.column];
  }

  if (currentRowData.slice) {
    defaultUserSetting.localFilterSetting.slice = [currentRowData.slice];
  }

  if (currentRowData.tags) {
    defaultUserSetting.localFilterSetting.tagName = [currentRowData.tags];
  }

  if (currentRowData.hasOwnProperty("severity")) {
    defaultUserSetting.localFilterSetting.severity = [currentRowData.severity];
  }

  if (currentRowData.hasOwnProperty("status")) {
    defaultUserSetting.localFilterSetting.status = [currentRowData.status];
  }

  if (currentRowData.validationStatus) {
    defaultUserSetting.localFilterSetting.validationStatus = [
      currentRowData.validationStatus,
    ];
  }

  if (currentRowData.hasOwnProperty("direction")) {
    defaultUserSetting.localFilterSetting.direction = [currentRowData.direction];
  }

  if (currentRowData.hour) {
    defaultUserSetting.time = getIncidentNumberTimeSetting(
      currentRowData.hour,
      currentUsersSetting.time
    );
  } else if (currentRowData.day) {
    defaultUserSetting.time = getIncidentNumberTimeSetting(
      currentRowData.day,
      currentUsersSetting.time
    );
  } else if (currentRowData.week) {
    defaultUserSetting.time = getIncidentNumberTimeSetting(
      currentRowData.week,
      currentUsersSetting.time
    );
  }

  return getQueryStringFromUserSetting(defaultUserSetting);
}

function getGroupSortFunction(key) {
  if (key === "datasource") {
    return (a, b) => {
      return a.datasource.localeCompare(b.datasource);
    };
  } else if (key === "metric") {
    return (a, b) => {
      return a.metric.localeCompare(b.metric);
    };
  } else if (key === "monitor") {
    return (a, b) => {
      return a.monitor.localeCompare(b.monitor);
    };
  } else if (key === "table") {
    return (a, b) => {
      return a.table.localeCompare(b.table);
    };
  } else if (key === "column") {
    return (a, b) => {
      return a.column.localeCompare(b.column);
    };
  } else if (key === "direction") {
    return (a, b) => {
      if (a.direction === b.direction) {
        return 0;
      }

      if (a.direction < b.direction) {
        return -1;
      }

      return 1;
    };
  } else if (key === "slice") {
    return (a, b) => {
      return (a.slice ?? "").localeCompare(b.slice ?? "");
    };
  } else if (key === "tags") {
    return (a, b) => {
      return a.tags.localeCompare(b.tags);
    };
  } else if (key === "validationStatus") {
    return (a, b) => {
      return getIncidentValidationStatusDisplayStr(a.validationStatus).localeCompare(
        getIncidentValidationStatusDisplayStr(b.validationStatus)
      );
    };
  } else if (key === "status") {
    return (a, b) => {
      if (a.status === b.status) {
        return 0;
      }

      return getIncidentStatusDisplayStr(a.status).localeCompare(
        getIncidentStatusDisplayStr(b.status)
      );
    };
  } else if (key === "severity") {
    return (a, b) => {
      if (a.severity === b.severity) {
        return 0;
      }

      if (a.severity < b.severity) {
        return -1;
      }

      return 1;
    };
  } else if (key === "week") {
    return (a, b) => {
      if (a.week.startTimestamp === b.week.startTimestamp) {
        return 0;
      }

      if (a.week.startTimestamp < b.week.startTimestamp) {
        return -1;
      }

      return 1;
    };
  } else if (key === "day") {
    return (a, b) => {
      if (a.day.startTimestamp === b.day.startTimestamp) {
        return 0;
      }

      if (a.day.startTimestamp < b.day.startTimestamp) {
        return -1;
      }

      return 1;
    };
  } else if (key === "hour") {
    return (a, b) => {
      if (a.hour.startTimestamp === b.hour.startTimestamp) {
        return 0;
      }

      if (a.hour.startTimestamp < b.hour.startTimestamp) {
        return -1;
      }

      return 1;
    };
  } else {
    console.log("unknown key");
    return (a, b) => 0;
  }
}

function getAggregationSortFunction(key) {
  if (key === "incident") {
    return (a, b) => {
      if (a.incident === b.incident) {
        return 0;
      }

      if (a.incident > b.incident) {
        return 1;
      }

      return -1;
    };
  } else if (key === "sliceSet") {
    return (a, b) => {
      if (a.sliceSet === undefined || b.sliceSet === undefined) {
        return 0;
      }

      const aSliceNumber = a.sliceSet.size;
      const bSliceNumber = b.sliceSet.size;
      if (aSliceNumber === bSliceNumber) {
        return 0;
      }

      if (aSliceNumber > bSliceNumber) {
        return 1;
      }

      return -1;
    };
  } else {
    console.log("unknown key");
    return (a, b) => 0;
  }
}

function getIncidentSummaryTableColumns(
  workspaceUuid,
  groupColumns,
  aggregationColumns,
  userSetting,
  localFilterSetting,
  sortedInfo
) {
  const tableColumns = [];
  for (let i = 0; i < groupColumns.length; i++) {
    const currentGroupColumn = groupColumns[i];
    if (currentGroupColumn === "datasource") {
      tableColumns.push({
        title: "Datasource",
        key: "datasource",
        dataIndex: "datasource",
        sortOrder: sortedInfo.columnKey === "datasource" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("datasource"),
        },
      });
    } else if (currentGroupColumn === "monitor") {
      tableColumns.push({
        title: "Monitor",
        key: "monitor",
        dataIndex: "monitor",
        sortOrder: sortedInfo.columnKey === "monitor" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("monitor"),
        },
      });
    } else if (currentGroupColumn === "direction") {
      tableColumns.push({
        title: "Direction",
        key: "direction",
        dataIndex: "direction",
        sortOrder: sortedInfo.columnKey === "direction" && sortedInfo.order,
        render: function (direction) {
          if (direction === IncidentDirection.UP) {
            return <IncidentDirectionUpIcon />;
          } else if (direction === IncidentDirection.DOWN) {
            return <IncidentDirectionDownIcon />;
          } else if (direction === IncidentDirection.BOTH) {
            return <IncidentDirectionBothIcon />;
          } else {
            return "";
          }
        },
        sorter: {
          compare: getGroupSortFunction("direction"),
        },
      });
    } else if (currentGroupColumn === "metric") {
      tableColumns.push({
        title: "Metric",
        key: "metric",
        dataIndex: "metric",
        sortOrder: sortedInfo.columnKey === "metric" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("metric"),
        },
      });
    } else if (currentGroupColumn === "table") {
      tableColumns.push({
        title: "Table",
        key: "table",
        dataIndex: "table",
        sortOrder: sortedInfo.columnKey === "table" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("table"),
        },
      });
    } else if (currentGroupColumn === "tags") {
      tableColumns.push({
        title: "Tag",
        key: "tags",
        dataIndex: "tags",
        sortOrder: sortedInfo.columnKey === "tags" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("tags"),
        },
      });
    } else if (currentGroupColumn === "column") {
      tableColumns.push({
        title: "Column",
        key: "column",
        dataIndex: "column",
        sortOrder: sortedInfo.columnKey === "column" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("column"),
        },
      });
    } else if (currentGroupColumn === "severity") {
      tableColumns.push({
        title: "Severity",
        key: "severity",
        dataIndex: "severity",
        render: (severity) => {
          return severity === IncidentScore.HIGH ? (
            <HighSeverityIcon />
          ) : (
            <NormalSeverityIcon />
          );
        },
        sortOrder: sortedInfo.columnKey === "severity" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("severity"),
        },
      });
    } else if (currentGroupColumn === "week") {
      tableColumns.push({
        title: "Week",
        key: "week",
        dataIndex: "week",
        render: (weekPeriod) => {
          if (!weekPeriod || typeof weekPeriod.startTimestamp !== "number") {
            return;
          }
          return getStringFromTimeStamp(weekPeriod.startTimestamp, {
            onlyDate: true,
          });
        },
        sortOrder: sortedInfo.columnKey === "week" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("week"),
        },
      });
    } else if (currentGroupColumn === "day") {
      tableColumns.push({
        title: "Day",
        key: "day",
        dataIndex: "day",
        render: (dayPeriod) => {
          if (!dayPeriod || typeof dayPeriod.startTimestamp !== "number") {
            return;
          }
          return getStringFromTimeStamp(dayPeriod.startTimestamp, {
            onlyDate: true,
          });
        },
        sortOrder: sortedInfo.columnKey === "day" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("day"),
        },
      });
    } else if (currentGroupColumn === "hour") {
      tableColumns.push({
        title: "Hour",
        key: "hour",
        dataIndex: "hour",
        render: (hourPeriod) => {
          if (!hourPeriod || typeof hourPeriod.startTimestamp !== "number") {
            return;
          }
          return getStringFromTimeStamp(hourPeriod.startTimestamp);
        },
        sortOrder: sortedInfo.columnKey === "hour" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("hour"),
        },
      });
    } else if (currentGroupColumn === "status") {
      tableColumns.push({
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (status) => getIncidentStatusDisplayStr(status),
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("status"),
        },
      });
    } else if (currentGroupColumn === "validationStatus") {
      tableColumns.push({
        title: "Validation status",
        key: "validationStatus",
        dataIndex: "validationStatus",
        render: (validationStatus) =>
          getIncidentValidationStatusDisplayStr(validationStatus),
        sortOrder: sortedInfo.columnKey === "validationStatus" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("validationStatus"),
        },
      });
    } else if (currentGroupColumn === "slice") {
      tableColumns.push({
        title: "Slice",
        key: "slice",
        dataIndex: "slice",
        sortOrder: sortedInfo.columnKey === "slice" && sortedInfo.order,
        sorter: {
          compare: getGroupSortFunction("slice"),
        },
      });
    }
  }

  for (let i = 0; i < aggregationColumns.length; i++) {
    const currentAggregationColumn = aggregationColumns[i];
    if (currentAggregationColumn === "incident") {
      tableColumns.push({
        title: "# Incident",
        key: "incident",
        dataIndex: "incident",
        sortOrder: sortedInfo.columnKey === "incident" && sortedInfo.order,
        render: (incident, rowData) => {
          let isNA = false;
          for (let dataKey in rowData) {
            if (rowData.hasOwnProperty(dataKey) && rowData[dataKey] === "N/A") {
              isNA = true;
            }
          }

          if (incident === 0 || isNA) {
            return incident;
          }

          const searchQueryString = getIncidentNumberLink(
            userSetting,
            localFilterSetting,
            rowData
          );
          return (
            <Link
              to={{
                pathname: getURIInstance(URIPath.NG_INCIDENT_LIST, {
                  workspaceUuid,
                }),
                search: searchQueryString,
              }}
            >
              {incident}
            </Link>
          );
        },
        sorter: {
          compare: getAggregationSortFunction("incident"),
        },
      });
    } else if (currentAggregationColumn === "sliceSet") {
      tableColumns.push({
        title: "# Slice",
        key: "sliceSet",
        dataIndex: "sliceSet",
        sortOrder: sortedInfo.columnKey === "sliceSet" && sortedInfo.order,
        sorter: {
          compare: getAggregationSortFunction("sliceSet"),
        },
        render: function (sliceSet) {
          return sliceSet.size;
        },
      });
    }
  }

  return tableColumns;
}

function getAggregatedIncidentSummary(
  groupedIncidentList,
  groupColumns,
  aggregationColumns,
  selectedTags,
  selectedTimeRange
) {
  const uniqueKeyMapper = {};
  const incidentSummaryList = [];
  for (let currentGroupedIncidentList of groupedIncidentList) {
    const { incidents, creatorInfo, slice = {} } = currentGroupedIncidentList;
    const sliceDisplayStr = getSliceDisplayString(slice);

    let currentLoopIncidents;
    let incidentsNumber;

    // In order to improve performance and if we don't need to group by any incident attribute, just skip iterating the inner incident loop.
    if (
      groupColumns.includes("severity") ||
      groupColumns.includes("status") ||
      groupColumns.includes("validationStatus") ||
      groupColumns.includes("direction") ||
      groupColumns.includes("week") ||
      groupColumns.includes("day") ||
      groupColumns.includes("hour")
    ) {
      incidentsNumber = 1;
      currentLoopIncidents = incidents;
    } else {
      incidentsNumber = incidents.length;
      currentLoopIncidents = [null];
    }

    for (let currentIncident of currentLoopIncidents) {
      const uniqueKeys = getUniqueKeys(
        creatorInfo,
        sliceDisplayStr,
        groupColumns,
        aggregationColumns,
        selectedTags,
        selectedTimeRange,
        currentIncident
      );

      for (let currentUniqueKeyInfo of uniqueKeys) {
        const { uniqueKey: currentUniqueKey, initData } = currentUniqueKeyInfo;

        if (!uniqueKeyMapper.hasOwnProperty(currentUniqueKey)) {
          uniqueKeyMapper[currentUniqueKey] = incidentSummaryList.push(initData) - 1;
        }

        const currentRowData = incidentSummaryList[uniqueKeyMapper[currentUniqueKey]];
        updateRowData(
          currentRowData,
          aggregationColumns,
          incidentsNumber,
          sliceDisplayStr
        );
      }
    }
  }

  return incidentSummaryList;
}

function normalizeIncidentList(incidentSummaryList, groupColumns, aggregationColumns) {
  if (incidentSummaryList.length === 0 || groupColumns.length === 0) {
    return incidentSummaryList;
  }

  const filteredGroupColumns = groupColumns.filter(
    (currentColumn) => ["table", "column", "slice"].indexOf(currentColumn) === -1
  );

  if (filteredGroupColumns.length === 0) {
    incidentSummaryList = incidentSummaryList.filter((currentRowData) => {
      if (groupColumns.includes("table") && currentRowData["table"]) {
        return true;
      }

      if (groupColumns.includes("column") && currentRowData["column"]) {
        return true;
      }

      if (groupColumns.includes("slice") && currentRowData["slice"]) {
        return true;
      }

      return false;
    });
  }

  return incidentSummaryList;
}

function getDefaultSortInfo(groupColumns, aggregationColumns) {
  if (groupColumns.length === 0 && aggregationColumns.length === 0) {
    return {
      columnKey: "",
      order: "",
    };
  }

  if (aggregationColumns.length > 0) {
    return {
      columnKey: aggregationColumns[0],
      order: "descend",
    };
  }

  return {
    columnKey: groupColumns[0],
    order: "descend",
  };
}

function TableChartView(props) {
  const {
    uuid,
    workspaceUuid,
    config,
    incidentList,
    userSetting,
    getData,
    isDialogMode = false,
  } = props;
  const { groupColumns, aggregationColumns, filters = [] } = config;

  let timeSetting = null;
  let listOfTagList = [];
  const localFilterSetting = { ...userSetting.localFilterSetting };
  for (let currentFilter of filters) {
    if (currentFilter.type === DashboardFilterType.DATASOURCES) {
      localFilterSetting.dataSourceName = currentFilter.value.strList;
    } else if (currentFilter.type === DashboardFilterType.METRICS) {
      localFilterSetting.kpiName = currentFilter.value.strList;
    } else if (currentFilter.type === DashboardFilterType.MONITORS) {
      localFilterSetting.filterName = currentFilter.value.strList;
    } else if (currentFilter.type === DashboardFilterType.SEVERITY) {
      localFilterSetting.severity = currentFilter.value.intList;
    } else if (currentFilter.type === DashboardFilterType.DIRECTION) {
      localFilterSetting.direction = currentFilter.value.intList;
    } else if (currentFilter.type === DashboardFilterType.STATUS) {
      localFilterSetting.status = currentFilter.value.intList;
    } else if (currentFilter.type === DashboardFilterType.VALIDATION_STATUS) {
      localFilterSetting.validationStatus = currentFilter.value.strList;
    } else if (currentFilter.type === DashboardFilterType.TAGS) {
      const normalizedTagList = currentFilter.value.tagList.map((currentTag) => {
        if (typeof currentTag === "string") {
          return currentTag;
        }

        // old format;
        if (typeof currentTag === "object" && currentTag.key) {
          return currentTag.key;
        }

        return "";
      });
      listOfTagList.push(normalizedTagList);
    } else if (currentFilter.type === DashboardFilterType.TIME_DURATION) {
      timeSetting = currentFilter;
      const queryObject = getQueryObject(currentFilter);
      const endTime = fromUnixTime(queryObject.endTime);
      const startTime = fromUnixTime(queryObject.startTime);
      userSetting.time = {
        currentInterval: "custom",
        startTime,
        endTime,
        current: endTime,
      };
    }
  }

  localFilterSetting.tagName = listOfTagList;

  if (timeSetting === null) {
    const endTime = new Date();
    userSetting.time = {
      currentInterval: "custom",
      startTime: fromUnixTime(0),
      endTime,
      current: endTime,
    };
  }

  const previousTimeSetting = usePrevious(timeSetting);
  const [sortedInfo, setSortedInfo] = useState(
    getDefaultSortInfo(groupColumns, aggregationColumns)
  );
  useEffect(() => {
    if (
      (!previousTimeSetting ||
        JSON.stringify(timeSetting) !== JSON.stringify(previousTimeSetting)) &&
      timeSetting
    ) {
      const queryObject = getQueryObject(timeSetting);
      getData(uuid, queryObject);
    }
  }, [uuid, previousTimeSetting, timeSetting, getData]);

  useEffect(() => {
    if (groupColumns.length === 0 && aggregationColumns.length === 0) {
      return;
    }

    if (
      groupColumns.includes(sortedInfo.columnKey) ||
      aggregationColumns.includes(sortedInfo.columnKey)
    ) {
      return;
    }

    setSortedInfo(getDefaultSortInfo(groupColumns, aggregationColumns));
  }, [groupColumns, aggregationColumns, sortedInfo]);

  if (groupColumns.length === 0 && aggregationColumns.length === 0) {
    return isDialogMode ? (
      <div className="table-chart-view-no-params-container">
        <svg
          width="68"
          height="68"
          viewBox="0 0 68 68"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <circle cx="34" cy="34" r="34" fill="#C4C4C4" fillOpacity="0.2" />
          <path
            d="M36.3285 28.7434H31.6716C31.0992 28.7434 30.6368 29.2058 30.6368 29.7782V47.3708C30.6368 47.9432 31.0992 48.4056 31.6716 48.4056H36.3285C36.9009 48.4056 37.3633 47.9432 37.3633 47.3708V29.7782C37.3633 29.2058 36.9009 28.7434 36.3285 28.7434ZM46.2889 19.4297H41.6321C41.0597 19.4297 40.5972 19.8921 40.5972 20.4645V47.3708C40.5972 47.9432 41.0597 48.4056 41.6321 48.4056H46.2889C46.8613 48.4056 47.3238 47.9432 47.3238 47.3708V20.4645C47.3238 19.8921 46.8613 19.4297 46.2889 19.4297ZM26.368 38.0571H21.7111C21.1387 38.0571 20.6763 38.5195 20.6763 39.0919V47.3708C20.6763 47.9432 21.1387 48.4056 21.7111 48.4056H26.368C26.9404 48.4056 27.4028 47.9432 27.4028 47.3708V39.0919C27.4028 38.5195 26.9404 38.0571 26.368 38.0571Z"
            fill="#868786"
          />
        </svg>
        <div>Choose groups or values to preview data</div>
      </div>
    ) : null;
  }

  const localFilteredIncidentList = getLocalFilteredIncidentList(
    incidentList.data,
    localFilterSetting,
    true
  );

  const currentIncidentSummary = getAggregatedIncidentSummary(
    localFilteredIncidentList,
    groupColumns,
    aggregationColumns,
    localFilterSetting.tagName || [],
    userSetting.time
  );

  const tableColumns = getIncidentSummaryTableColumns(
    workspaceUuid,
    groupColumns,
    aggregationColumns,
    userSetting,
    localFilterSetting,
    sortedInfo
  );

  const data = normalizeIncidentList(
    currentIncidentSummary,
    groupColumns,
    aggregationColumns
  );
  return (
    <div className="table-chart-view-outer-container">
      <NgTable
        className="table-chart-view-container"
        dataSource={data}
        columns={tableColumns}
        loading={incidentList.loading}
        rowKey={"uuid"}
        size={"middle"}
        pagination={{
          position: ["bottomRight"],
          hideOnSinglePage: data.length <= 10,
        }}
        sortDirections={["ascend", "descend", "ascend"]}
        onChange={(pagination, filters, sorter) => {
          setSortedInfo(sorter);
        }}
      />
      {isDialogMode && !incidentList.loading && (
        <div className="table-chart-view-indicator-container">
          <InfoCircleOutlined />
          Only groupings with incidents are displayed
        </div>
      )}
    </div>
  );
}

export default TableChartView;
