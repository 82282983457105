import React, { useState } from "react";
import DailySummary from "./daily-summary";
import { DqDashboardDimensionType, TakeoverWidth } from "../../utils/enums";
import DimensionTrendChart from "./dimension-trend-chart";
import MonitorSummaryHeatmap from "./monitor-summary-heatmap";
import SettingsTakeover from "./settings";
import { summaryEvaluationDelay } from "./dq-dashboard-data";

import "./dq-dashboard.scss";

function DqDashboard(props) {
  const {
    loading = false,
    canUpdateConfiguration,
    workspaceUuid,
    dailySummary = {},
    trendDataByDimensionType = {},
    heatmapDataByDimensionType = {},
    statusFilters,
    dataSourceFilters,
    openWorkspaceTakeover,
    closeTakeover,
    currentTime,
    startTime,
    endTime,
    onDashboardSettingsSave,
    onTimeRangeChange,
  } = props;

  const [selectedDimensionType, setSelectedDimensionType] = useState(
    DqDashboardDimensionType.OVERALL
  );

  const trendData = trendDataByDimensionType[selectedDimensionType];
  const heatmapData = heatmapDataByDimensionType[selectedDimensionType];

  const [evaluationDelay] = summaryEvaluationDelay(dailySummary);

  function onSettingsSave(settings) {
    onDashboardSettingsSave(settings);
    closeTakeover();
  }

  function onSettingsClick() {
    openWorkspaceTakeover(
      <SettingsTakeover
        workspaceUuid={workspaceUuid}
        canUpdateConfiguration={canUpdateConfiguration}
        onSave={onSettingsSave}
        closeTakeover={closeTakeover}
        timezone={dailySummary.timezone}
        evalDelay={evaluationDelay}
        statusFilters={statusFilters}
        dataSourceFilters={dataSourceFilters}
      />,
      TakeoverWidth.NARROW,
      () => closeTakeover()
    );
  }

  return (
    <div className="dq-dash">
      <DailySummary
        loading={loading}
        dailySummary={dailySummary}
        selectedDimensionType={selectedDimensionType}
        onDimensionButtonClick={setSelectedDimensionType}
        onSettingsClick={onSettingsClick}
        onCloseSettingsClick={() => closeTakeover()}
      />
      <DimensionTrendChart
        loading={loading}
        dimensionType={selectedDimensionType}
        trendData={trendData}
        currentTime={currentTime}
        startTime={startTime}
        endTime={endTime}
        evaluationDelay={evaluationDelay}
        onTimeRangeChange={onTimeRangeChange}
      />
      <MonitorSummaryHeatmap
        loading={loading}
        dimensionType={selectedDimensionType}
        monitorHeatmapData={heatmapData}
      />
    </div>
  );
}

export default DqDashboard;
