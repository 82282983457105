import React, { useState } from "react";
import IncidentDirectionSelector from "../incident-direction-selector/incident-direction-selector";
import IncidentSeveritySelector from "../incident-severity-selector/incident-severity-selector";
import NgToggleCheckbox from "../toggle-checkbox/ng-toggle-checkbox";
import Popover from "../popover";
import Button from "../button/ng-button";

import "./index.scss";

function FilterIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.22019 2.38095C1.31541 2.17406 1.52234 2.0415 1.75009 2.0415H12.2501C12.4778 2.0415 12.6848 2.17406 12.78 2.38095C12.8752 2.58784 12.8413 2.83123 12.6932 3.00423L8.63342 7.74564V12.2498C8.63342 12.4515 8.52927 12.6388 8.35799 12.7453C8.18672 12.8517 7.97261 12.8622 7.7918 12.7729L5.6918 11.7358C5.49276 11.6375 5.36676 11.4348 5.36676 11.2128V7.74564L1.30699 3.00423C1.15886 2.83123 1.12497 2.58784 1.22019 2.38095ZM3.01751 3.20817L6.39319 7.15063C6.48369 7.25632 6.53342 7.39088 6.53342 7.53002V10.8503L7.46676 11.3112V7.53002C7.46676 7.39088 7.51649 7.25632 7.60699 7.15063L10.9827 3.20817H3.01751Z"
        fill="#121224"
      />
    </svg>
  );
}

function IncidentLocalOptionFilter(props) {
  const {
    localFilterSetting: defaultLocalFilterSetting,
    customerOptions: defaultCustomOptions,
    onSave,
    onCustomOptionsSave,
  } = props;
  const [visible, setVisible] = useState(false);

  const [localFilterSetting, setLocalFilterSetting] = useState({
    ...defaultLocalFilterSetting,
  });
  const [customerOptions, setCustomOptions] = useState({ ...defaultCustomOptions });

  const { direction = [], severity = [] } = localFilterSetting;
  const { rejected = false } = customerOptions;

  function onCancel() {
    setLocalFilterSetting({
      ...defaultLocalFilterSetting,
      direction: [],
      severity: [],
    });
    setCustomOptions({ ...defaultCustomOptions, rejected: false });
  }

  function onApply() {
    if (
      JSON.stringify(localFilterSetting) !== JSON.stringify(defaultLocalFilterSetting)
    ) {
      onSave(localFilterSetting);
    }

    if (JSON.stringify(customerOptions) !== JSON.stringify(defaultCustomOptions)) {
      onCustomOptionsSave(customerOptions);
    }

    setVisible(false);
  }

  const popOverContent = (
    <div className="ng-incident-local-filter-filter-content-container">
      <div className="ng-incident-local-filter-filter-title-container">Filter</div>
      <div className="ng-incident-local-filter-filter-config-row-container">
        <div className="ng-incident-local-filter-filter-config-label-container shared-label">
          Direction (both)
        </div>
        <div className="ng-incident-local-filter-filter-config-value-container">
          <IncidentDirectionSelector
            value={direction}
            onChange={(newDirection) => {
              setLocalFilterSetting({
                ...localFilterSetting,
                direction: newDirection,
              });
            }}
          />
        </div>
      </div>
      <div className="ng-incident-local-filter-filter-config-row-container">
        <div className="ng-incident-local-filter-filter-config-label-container shared-label">
          Severity
        </div>
        <div className="ng-incident-local-filter-filter-config-value-container">
          <IncidentSeveritySelector
            value={severity}
            onChange={(newSeverity) => {
              setLocalFilterSetting({
                ...localFilterSetting,
                severity: newSeverity,
              });
            }}
          />
        </div>
      </div>
      <div className="ng-incident-local-filter-filter-config-row-container">
        <div className="ng-incident-local-filter-filter-config-label-container rejected-label">
          Show rejected incidents
        </div>
        <div className="ng-incident-local-filter-filter-config-value-container">
          <NgToggleCheckbox
            value={rejected}
            onChange={() => {
              setCustomOptions({
                ...customerOptions,
                rejected: !rejected,
              });
            }}
          />
        </div>
      </div>
      <div className="ng-incident-local-filter-footer-container">
        <div className="lightup-rest-flex-item-container" />
        <Button outline size="large" onClick={onCancel}>
          Clear
        </Button>
        <Button primary size="large" onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  );

  function onVisibleChange(visible) {
    setVisible(visible);
  }

  return (
    <Popover
      overlayClassName="ng-incident-local-option-filter-container"
      content={popOverContent}
      trigger="click"
      placement={"bottomRight"}
      onVisibleChange={onVisibleChange}
      visible={visible}
    >
      <Button
        outline
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          margin: 0,
          width: 32,
          height: 32,
        }}
      >
        <FilterIcon />
      </Button>
    </Popover>
  );
}

export default IncidentLocalOptionFilter;
