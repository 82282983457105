import React, { useState, useEffect } from "react";
import IncidentEventTimelineLineView from "../ng-incident-event-timeline-line-view-dense";
import ZeroStatusChart from "../zero-status-chart";
import FullCompareTableView from "./full-compare-table-view";
import AggregationCompareTableView from "./aggregation-compare-table-view";
import { getIncidentListForCurrentTimestamp } from "../../../utils/general";
import { AutoMetricsType, EventItemType, IncidentScore } from "../../../utils/enums";

import "./index.scss";

function FullCompareChartView(props) {
  const {
    data,
    dataSource,
    metric,
    metricType,
    isMonitored = false,
    config: { isPreview = false, isProfiler = false },
    incidents = [],
    chartOption: { showStatsData = false },
    onCurrentPointChange,
  } = props;

  const [currentPoint, setCurrentPoint] = useState({
    timestamp: 0,
    value: null,
  });

  useEffect(() => {
    let currentPoint;
    if (
      data &&
      data.metricsData &&
      data.metricsData.signalData &&
      data.metricsData.signalData.length > 0
    ) {
      let defaultIncident = null;
      for (let currentIncident of incidents) {
        if (currentIncident.main) {
          defaultIncident = currentIncident;
          break;
        }
      }

      const signalDataLength = data.metricsData.signalData.length;
      if (defaultIncident) {
        for (let i = signalDataLength - 1; i >= 0; i--) {
          const currentSignalData = data.metricsData.signalData[i];
          if (currentSignalData.timestamp <= defaultIncident.endTime) {
            currentPoint = {
              timestamp: currentSignalData.timestamp,
              value: currentSignalData.value,
            };
            break;
          }
        }
      }
      currentPoint = currentPoint || {
        timestamp: data.metricsData.signalData[signalDataLength - 1].timestamp,
        value: data.metricsData.signalData[signalDataLength - 1].value,
      };
      onCurrentPointChange && onCurrentPointChange(currentPoint);
    } else {
      currentPoint = { timestamp: 0, value: null };
      onCurrentPointChange && onCurrentPointChange(null);
    }

    setCurrentPoint(currentPoint);
  }, [data, incidents, onCurrentPointChange]);

  const currentIncidents = getIncidentListForCurrentTimestamp(
    incidents,
    currentPoint.timestamp
  );
  let DateCompareTableView;
  if (metricType === AutoMetricsType.AGGREGATION_COMPARE) {
    DateCompareTableView = AggregationCompareTableView;
  } else {
    DateCompareTableView = FullCompareTableView;
  }

  if (isPreview && metricType === AutoMetricsType.FULL_COMPARE) {
    return (
      <div className="full-compare-chart-view-container">
        <div className="full-compare-chart-data-container">
          <DateCompareTableView
            dataSource={dataSource}
            metric={metric}
            value={[]}
            isPreview={true}
            isProfiler={false}
          />
        </div>
      </div>
    );
  }

  function onHeatMapClick(data) {
    if (data && data.value) {
      onCurrentPointChange && onCurrentPointChange(data.value);
      setCurrentPoint(data.value);
    }
  }

  let startTimestamp = null;
  let endTimestamp = null;
  if (
    data &&
    data.duration &&
    typeof data.duration.startTime === "number" &&
    typeof data.duration.endTime === "number"
  ) {
    startTimestamp = data.duration.startTime;
    endTimestamp = data.duration.endTime;
  }

  const metricsData =
    data &&
    data.metricsData &&
    data.metricsData.signalData &&
    data.metricsData.signalData.length > 0
      ? data.metricsData.signalData
      : [];

  const metadata =
    data && data.metricsData && data.metricsData.metadata
      ? data.metricsData.metadata
      : {};

  const normalizedTimeLine = metricsData.map((currentPoint) => {
    const { timestamp, value } = currentPoint;
    let status;
    const currentIncidentList = getIncidentListForCurrentTimestamp(
      incidents,
      timestamp
    );
    if (currentIncidentList.length === 0) {
      status = EventItemType.NORMAL;
    } else {
      status = EventItemType.INCIDENT;
      for (let currentIncident of currentIncidentList) {
        if (currentIncident.score === IncidentScore.HIGH) {
          status = EventItemType.HIGH_IMPACT_INCIDENT;
          break;
        }
      }
    }

    return {
      startTime: timestamp,
      endTime: timestamp,
      value: {
        timestamp,
        value,
      },
      status,
    };
  });

  return (
    <div className="full-compare-chart-view-container">
      <div className="full-compare-chart-data-container">
        {metricsData.length > 0 ? (
          <DateCompareTableView
            dataSource={dataSource}
            metric={metric}
            timestamp={currentPoint.timestamp}
            value={currentPoint.value}
            metadata={metadata}
            incidents={currentIncidents}
            isMonitored={isMonitored}
            isPreview={isPreview}
            isProfiler={isProfiler}
            showStatsData={showStatsData}
          />
        ) : (
          <ZeroStatusChart height={160} />
        )}
      </div>
      {metricsData.length > 0 && startTimestamp && endTimestamp && (
        <div className="full-compare-timeline-container">
          <IncidentEventTimelineLineView
            eventList={normalizedTimeLine}
            eventListStartTime={startTimestamp}
            eventListEndTime={endTimestamp}
            currentPoint={currentPoint}
            onClick={onHeatMapClick}
            config={{
              height: 100,
              marginTop: 0,
              marginLeft: 30,
              marginRight: 60,
              marginBottom: 0,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default FullCompareChartView;
