import React from "react";
import { getStringFromTimeStamp } from "../../utils/time";
import LegacyModalDialogAdapter from "../legacy-adapter/legacy-modal-dialog-adapter";
import ModalConfirmationButtons from "../modal-dialog/modal-confirmation-buttons";
import NgButton from "../button/ng-button";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import NgToggleCheckbox from "../toggle-checkbox/ng-toggle-checkbox";
import { NextGenPalette } from "../../utils/color";
import ButtonIcon from "../button/button-icon";
import Alert, { AlertType } from "../alert";

import "./user-api-key-dialog.scss";

function KeyIcon(props) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M18.5 6.47266C18.5 3.02734 15.7227 0.25 12.2773 0.25C8.86719 0.285156 6.125 3.02734 6.125 6.4375C6.125 7.14062 6.23047 7.77344 6.40625 8.40625L0.746094 14.0664C0.570312 14.2422 0.5 14.4531 0.5 14.6641V17.4062C0.5 17.8984 0.851562 18.25 1.34375 18.25H5.28125C5.73828 18.25 6.125 17.8984 6.125 17.4062V16H7.53125C7.98828 16 8.375 15.6484 8.375 15.1562V13.75H9.67578C9.88672 13.75 10.168 13.6445 10.3086 13.4688L11.1523 12.5195C11.5039 12.5898 11.8906 12.625 12.3125 12.625C15.7227 12.625 18.5 9.88281 18.5 6.47266ZM12.3125 4.75C12.3125 3.83594 13.0508 3.0625 14 3.0625C14.9141 3.0625 15.6875 3.83594 15.6875 4.75C15.6875 5.69922 14.9141 6.4375 14 6.4375C13.0508 6.4375 12.3125 5.69922 12.3125 4.75Z"
        fill={NextGenPalette.systemBlack}
      />
    </svg>
  );
}

function UserAPIKeyDialogBodyComponent(props) {
  let {
    userAPIKeyList = [],
    currentAddedUserAPIKey = null,
    addUserAPIKey,
    deleteUserAPIKey,
    updateUserAPIKeyActiveStatus,
    resetCurrentAddedUserAPIKey,
  } = props;

  function onGenerateNewKeyClicked() {
    console.log("Generating new key.");
    addUserAPIKey();
  }

  function onDownloadKeyClicked() {
    console.log("Downloading new key");
    const a = document.createElement("a");
    a.href = URL.createObjectURL(
      new Blob([JSON.stringify(currentAddedUserAPIKey)], {
        type: "text/plain",
      })
    );
    a.setAttribute("download", "lightup-api-credential.json");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    resetCurrentAddedUserAPIKey();
  }

  function onDeleteAPIKeyClicked(id) {
    if (
      currentAddedUserAPIKey &&
      currentAddedUserAPIKey.metadata &&
      currentAddedUserAPIKey.metadata.id === id
    ) {
      resetCurrentAddedUserAPIKey();
    }
    deleteUserAPIKey(id);
  }

  function onToggleAPIKeyActiveStatusClicked(id, newActiveStatus) {
    updateUserAPIKeyActiveStatus(id, newActiveStatus);
  }

  const currentTimestamp = Math.floor(Date.now() / 1000);

  return (
    <div className="user-api-key-dialog-body-container">
      <div className="user-api-key-indicator-container">
        API credentials are long-term credentials. You can use API credential for
        programmatic access to Lightup APIs.
      </div>
      {currentAddedUserAPIKey && (
        <div className="user-api-key-download-info-container">
          <Alert
            type={AlertType.SUCCESS}
            message="API Credential Generated Successfully"
            description={
              <div className="user-api-key-download-description">
                This is the only time you can download the API credential. Click the
                download button to download the credential and store in a secure
                location.
                <NgButton outline onClick={onDownloadKeyClicked}>
                  Download
                  <DownloadOutlined />
                </NgButton>
              </div>
            }
          />
        </div>
      )}
      {userAPIKeyList.length > 0 && (
        <div className="user-api-key-list-container">
          {userAPIKeyList.map(
            ({ metadata: { id, createdTs }, data: { expiredTs, active } }, index) => {
              return (
                <div className="user-api-key-item-container" key={index}>
                  <KeyIcon />
                  <div className="user-api-key-generated-time-container">
                    Generated:{" "}
                    <span className="user-api-key-timestamp">
                      {getStringFromTimeStamp(createdTs)}
                    </span>
                  </div>
                  <div className="user-api-key-expired-time-container">
                    {expiredTs > currentTimestamp ? "Expires: " : "Expired: "}
                    <span className="user-api-key-timestamp">
                      {getStringFromTimeStamp(expiredTs)}
                    </span>
                  </div>
                  <div className="user-api-key-active-container">
                    <NgToggleCheckbox
                      label={"Disable"}
                      labelPosition={"left"}
                      value={!active}
                      onChange={onToggleAPIKeyActiveStatusClicked.bind(
                        null,
                        id,
                        !active
                      )}
                    />
                  </div>
                  <ButtonIcon
                    className="user-api-key-delete-icon-container"
                    onClick={onDeleteAPIKeyClicked.bind(null, id)}
                    icon={<DeleteOutlined style={{ color: NextGenPalette.errorRed }} />}
                  />
                </div>
              );
            }
          )}
        </div>
      )}
      {userAPIKeyList.length < 2 && (
        <NgButton outline onClick={onGenerateNewKeyClicked}>
          Generate API credential
        </NgButton>
      )}
    </div>
  );
}

function UserAPIKeyDialogFooterComponent(props) {
  const { onCancelClicked, onApplyClicked } = props;

  return (
    <ModalConfirmationButtons
      onOkClick={onApplyClicked}
      onCancelClick={onCancelClicked}
    />
  );
}

function UserAPIKeyDialog(props) {
  const {
    userAPIKeyList = [],
    currentAddedUserAPIKey = null,
    addUserAPIKey,
    deleteUserAPIKey,
    updateUserAPIKeyActiveStatus,
    modalIsOpen,
    setIsOpen,
    resetCurrentAddedUserAPIKey,
  } = props;

  function onCancelClicked() {
    setIsOpen(false);
  }

  function onApplyClicked() {
    setIsOpen(false);
  }

  return (
    <LegacyModalDialogAdapter
      title={"API Credentials"}
      width={780}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"user-api-key-dialog-container"}
      triggerComponent={null}
      contentComponent={
        <UserAPIKeyDialogBodyComponent
          userAPIKeyList={userAPIKeyList}
          currentAddedUserAPIKey={currentAddedUserAPIKey}
          addUserAPIKey={addUserAPIKey}
          deleteUserAPIKey={deleteUserAPIKey}
          updateUserAPIKeyActiveStatus={updateUserAPIKeyActiveStatus}
          resetCurrentAddedUserAPIKey={resetCurrentAddedUserAPIKey}
        />
      }
      footerComponent={
        <UserAPIKeyDialogFooterComponent
          onCancelClicked={onCancelClicked}
          onApplyClicked={onApplyClicked}
        />
      }
    />
  );
}

export default UserAPIKeyDialog;
