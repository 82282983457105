import moment from "moment";
import { useState } from "react";

const { default: TimeRangeSelector } = require("../time-range-selector");

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const getTime = (date) => {
  return {
    hour: date.hour(),
    minute: date.minute(),
    second: date.second(),
  };
};

const disabledDate = (startTime, activeInput, daysLimit) => (current) => {
  const isSelectingStart = activeInput === "Start date";
  const shouldDisabled =
    startTime && !isSelectingStart
      ? moment(current).startOf("day").diff(moment(startTime).startOf("day"), "days") >
        daysLimit
      : false;
  return shouldDisabled;
};

const disabledTime = (startTime, daysLimit) => (date, partial) => {
  const isSelectingEnd = partial === "end";
  const lastEnabledDate =
    startTime && moment(startTime).add(daysLimit, "days").format("YYYY-MM-DD");
  const startOfDate = date && moment(date).startOf("day").format("YYYY-MM-DD");
  const isLastEnabledDay = lastEnabledDate === startOfDate;

  if (isSelectingEnd && lastEnabledDate && isLastEnabledDay) {
    const selectedTime = getTime(date);
    const disabledTime = getTime(moment(startTime).add(daysLimit, "days"));
    const disabledHours = () => range(disabledTime.hour + 1, 24);
    const disabledMinutes =
      selectedTime.hour === disabledTime.hour
        ? () => range(disabledTime.minute + 1, 60)
        : undefined;
    const disabledSeconds =
      selectedTime.hour === disabledTime.hour &&
      selectedTime.minute === disabledTime.minute
        ? () => range(disabledTime.second + 1, 60)
        : undefined;

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
    };
  } else {
    return {};
  }
};

function TimeRangeLimitedSelector(props) {
  const { daysLimit, onChange, initialValues, ...rest } = props;
  const [innerValue, setInnerValue] = useState({
    startTime: moment(initialValues?.startTime || undefined),
    endTime: moment(initialValues?.endTime || undefined),
  });
  const [endDateInputDisabled, setEndDateInputDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  // Indicates whether the user is selecting the start date or the end date
  const [activeSelection, setActiveSelection] = useState(null);

  const handleOpenChange = (open) => {
    setOpen(open);
    // Resets endDate when the date picker is opened to prevent already selected end date overlapping the disabled dates
    if (open) {
      setInnerValue((prev) => ({
        ...prev,
        endTime: null,
      }));
    }
  };
  const handleActiveInputChange = (e) => {
    if (e.target.placeholder === "Start date") {
      // The end date input should be always disabled when the user is selecting the start date to force the "ok" button click
      // this is necessary to force an onChange event and generate the new disabled dates
      setEndDateInputDisabled(true);
    }
    setActiveSelection(e.target.placeholder);
  };

  const handleOnChange = (newTimeRange) => {
    let isRangeValid = false;
    const { startTime, endTime } = newTimeRange;
    const newStartTime = startTime && moment(startTime);
    const newEndTime = endTime && moment(endTime);
    if (newStartTime) {
      // Enable the end date input when the start date is selected after clicking "ok"
      setEndDateInputDisabled(false);
    }

    if (newStartTime && newEndTime) {
      isRangeValid = newEndTime.diff(newStartTime, "days") <= daysLimit;
    }

    setInnerValue({
      startTime: newStartTime,
      // Store the end time only if the range is valid
      endTime: isRangeValid ? newEndTime : undefined,
    });

    if (isRangeValid) {
      setOpen(false);
      onChange &&
        onChange({
          startTime,
          endTime,
        });
    }
  };

  // Needed for when user directly clicks "ok" without selecting the start date to trigger force an onChange event
  // and enable the End date input
  const handleOnOkClick = (newTimeRange) => {
    if (newTimeRange && newTimeRange.length > 0) {
      handleOnChange({
        startTime: newTimeRange.length > 0 ? newTimeRange[0]?.toDate() : null,
        endTime: newTimeRange.length > 1 ? newTimeRange[1]?.toDate() : null,
      });
    }
  };

  return (
    <TimeRangeSelector
      open={open}
      onOpenChange={handleOpenChange}
      onFocus={handleActiveInputChange}
      onChange={handleOnChange}
      value={{
        startTime: innerValue.startTime && innerValue.startTime.toDate(),
        endTime: innerValue.endTime && innerValue.endTime.toDate(),
      }}
      disabledDate={disabledDate(innerValue.startTime, activeSelection, daysLimit)}
      allowEmpty={[false, true]}
      disabled={[false, endDateInputDisabled]}
      disabledTime={disabledTime(innerValue.startTime, daysLimit)}
      onOk={handleOnOkClick}
      {...rest}
    />
  );
}

export default TimeRangeLimitedSelector;
