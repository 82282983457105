import { connect } from "react-redux";
import {
  getDataSourceSystemIntegrationMetadata,
  addDataSource,
  getDataSource,
  previewDataSource,
  resetDataSourceStatus,
} from "../../actions/datasource/data-source-action";

import AddDataSourceWizard from "./add-data-source";

const mapStateToProps = (state) => ({
  lastPreviewStatus: state.dataSourceReducer.lastPreviewStatus,
  lastPreviewTime: state.dataSourceReducer.lastPreviewTime,
  lastPreviewResult: state.dataSourceReducer.lastPreviewResult,
  addDataSourceStatus: state.dataSourceReducer.creatingStatus,
  currentCloneDataSource: state.dataSourceReducer.currentCloneDataSource,
  alationDataSourceList: state.dataSourceReducer.dataSourceAlationDataSourceList.data,
  atlanDataSourceList: state.dataSourceReducer.dataSourceAtlanDataSourceList.data,
  waffle: state.userReducer.currentUserInfo.waffle,
});

const mapDispatchToProps = (dispatch) => ({
  addDataSource: (workspaceUuid, newDataSource) =>
    dispatch(addDataSource(workspaceUuid, newDataSource)),
  getDataSource: (workspaceUuid, id, requestVersion) =>
    dispatch(getDataSource(workspaceUuid, id, requestVersion, true)),
  previewDataSource: (workspaceUuid, newDataSource) =>
    dispatch(previewDataSource(workspaceUuid, newDataSource)),
  getDataSourceSystemIntegrationMetadata: () =>
    dispatch(getDataSourceSystemIntegrationMetadata()),
  resetDataSourceStatus: () => dispatch(resetDataSourceStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDataSourceWizard);
