import fromUnixTime from "date-fns/fromUnixTime";
import React, { useEffect, useState } from "react";
import { ProfilerAfterTreeNavigationActionType } from "../../../actions/profiler/profiler-action";
import ChartView from "../../../components/chart-view/ng-index";
import TreePath from "../../../components/explorer/tree-path";
import {
  AutoMetricsType,
  EventMetricTypeName,
  MonitorStatus,
  TreePathLevel,
} from "../../../utils/enums";
import { useInterval } from "../../../utils/hooks";
import { AppPermissions } from "../../../utils/permissions";
import {
  EVENT,
  PAGE,
  getSchemaDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import { hasPermission } from "../../../utils/uri-path";
import { getDefaultUserSetting } from "../../../utils/user-setting";
import ProfilerChecklistView from "../profiler-checklist-view";
import ProfilerEnableView from "../profiler-enable-view";
import ProfilerMonitorAllDialog from "../profiler-monitor-all-dialog";
import ProfilerSummaryTabView, {
  defaultTabKey,
  schemaNodeSupportsTabKey,
} from "../profiler-summary-tab-view";
import ProfilerTableEnableView from "../profiler-table-enable-view";
import {
  ProfilerSummaryTabKey,
  SCHEMA_REFRESH_POLLING_INTERVAL,
  canViewChecklist,
  buildNewRuleAndProfileConfigUpdateForSchema,
  isMetricInVolatileState,
  isMonitorInVolatileState,
} from "../utils";
import ProfilerSchemaConfigureAutometrics from "./profiler-schema-configure-autometrics-tab";
import ProfilerSchemaConfigureDataProfiles from "./profiler-schema-configure-data-profiles-tab";
import ProfilerSchemaManageTables from "./profiler-schema-manage-tables-tab";
import { ProfilerTreeNodeType } from "../tree-data";
import { ProfilerStatsCardView } from "../profiler-stats-card-view";
import { getStringFromTimeStamp } from "../../../utils/time";

import "./profiler-schema-overview.scss";

export function ProfilerDataSourceSchemaOverviewHeader(props) {
  const {
    datasourceType,
    datasourceName,
    schema,
    currentSelectNode,
    onCurrentSelectNodeChange,
    isTreeExpand,
    onTreeExpandChange,
  } = props;

  return (
    <div>
      <TreePath
        datasource={{ type: datasourceType, name: datasourceName }}
        schema={schema}
        selectedLevel={TreePathLevel.SCHEMA}
        currentSelectNode={currentSelectNode}
        onCurrentSelectNodeChange={onCurrentSelectNodeChange}
        isTreeExpand={isTreeExpand}
        onTreeExpandChange={onTreeExpandChange}
      />
    </div>
  );
}

function ProfilerSchemaOverview(props) {
  const {
    alertingChannelList,
    availableNodeKeys,
    currentSchemaSummary,
    currentSelectNode,
    dataSource,
    dataSourceTableList,
    monitorAllForSchema,
    profilerAfterTreeNavigationAction,
    profilerCurrentMonitorObject,
    schemaInfo,
    tabKey,
    waffle,
    metricRefreshInterval,
    workspaceUserPermissions,
    workspaceUuid,
    getProfilerConfigDataSourceTableList,
    getProfilerCurrentMonitorObject,
    getProfilerCurrentSchemaSummary,
    onCurrentSelectNodeChange,
    onGoToIncidentPage,
    onOpenTableConfig,
    onTabChange,
    refreshTreeDataSourceNode,
    resetProfilerCurrentSchemaSummary,
    setProfilerAfterTreeNavigationAction,
    updateSystemProfileSchemaRuleAlertingChannel,
    updateTableList,
    isTreeExpand,
    onTreeExpandChange,
  } = props;

  const canModifySettings = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
  ]);

  const canViewSummary = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SCHEMAPROFILESUMMARYVIEW,
  ]);

  const canAddMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ]);

  const canModifyMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
  ]);

  const canCreateTable = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_TABLEPROFILELISTVIEW,
  ]);

  const canViewEventChart = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_RESULT_VIEWS_VIEW_PROFILEREVENTLISTVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_COLUMNDATAPROFILEDETAILVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_COLUMNDATAPROFILELISTVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEDATAPROFILEDETAILVIEW,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEDATAPROFILELISTVIEW,
  ]);

  const {
    metadata: { uuid, name: datasourceName },
    config: {
      connection: { type: datasourceType },
    },
  } = dataSource;

  function onMonitorAll() {
    getProfilerCurrentMonitorObject({
      sourceUuid: dataSource.metadata.uuid,
      schemaUuid: schemaInfo.uuid,
    });
    setMonitorDialogContext(true);
  }

  const [monitorDialogContext, setMonitorDialogContext] = useState(false);
  const [manageTabSearchText, setManageTabSearchText] = useState("");
  const [tableTabSearchItem, setTableTabSearchItem] = useState({});

  useInterval(() => {
    if (isScanning) {
      refreshTreeDataSourceNode(dataSource);
    }
  }, SCHEMA_REFRESH_POLLING_INTERVAL);

  useEffect(() => {
    if (profilerAfterTreeNavigationAction) {
      switch (profilerAfterTreeNavigationAction.type) {
        case ProfilerAfterTreeNavigationActionType.SCHEMA_MONITOR_ALL_METRICS:
          onMonitorAll();
          break;
        default:
          console.warn(
            "Don't know how to handle action",
            profilerAfterTreeNavigationAction
          );
      }
      setProfilerAfterTreeNavigationAction(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilerAfterTreeNavigationAction]);

  function shouldRefreshMetrics() {
    if (currentSchemaSummary.loading) {
      return false;
    }

    const chartMetric = currentSchemaSummary.data?.chartData?.metric || null;
    const chartMonitors = currentSchemaSummary.data?.chartData?.rules || [];

    return (
      (chartMetric && isMetricInVolatileState(chartMetric)) ||
      chartMonitors.some(isMonitorInVolatileState)
    );
  }

  function refreshMetricsData() {
    getProfilerCurrentSchemaSummary(dataSource, schemaInfo, {
      isSummaryEnabled: canViewSummary,
      isEventChartEnabled: canViewEventChart,
      isRefresh: true,
    });
  }

  useInterval(() => {
    if (shouldRefreshMetrics() && canViewEventChart) {
      refreshMetricsData();
    }
  }, metricRefreshInterval);

  useEffect(
    () => {
      getProfilerConfigDataSourceTableList(dataSource, schemaInfo);

      if (canViewSummary || canViewEventChart) {
        getProfilerCurrentSchemaSummary(dataSource, schemaInfo, {
          isSummaryEnabled: canViewSummary,
          isEventChartEnabled: canViewEventChart,
        });
      }

      return () => {
        resetProfilerCurrentSchemaSummary();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspaceUuid, canViewEventChart, canViewSummary, dataSource?.uuid, schemaInfo]
  );

  useEffect(() => {
    setTableTabSearchItem({});
    setManageTabSearchText("");
  }, [schemaInfo.uuid, setTableTabSearchItem]);

  if (!dataSource || !schemaInfo) {
    return null;
  }

  const tableListChange = schemaInfo.profilerConfig.tableListChange || {
    enabled: false,
    monitoring: { enabled: false },
  };

  const monitorStatus = tableListChange.monitoring.enabled
    ? MonitorStatus.MONITORED
    : MonitorStatus.NOT_MONITORED;
  const alertConfig = tableListChange.monitoring.alertConfig || {
    isMuted: false,
    channels: [],
    mutingSchedules: [],
  };

  function updateMonitorStatus(isEnabled, alertConfig) {
    const newTableListChange = {
      ...tableListChange,
      monitoring: {
        ...tableListChange.monitoring,
        enabled: isEnabled,
      },
    };

    if (alertConfig) {
      newTableListChange.monitoring.alertConfig = alertConfig;
    }

    const newProfilerConfig = {
      ...schemaInfo.profilerConfig,
      tableListChange: newTableListChange,
    };

    updateSystemProfileSchemaRuleAlertingChannel(
      uuid,
      schemaInfo.uuid,
      newProfilerConfig
    );
  }

  function updateAlertChannel(_monitor, newAlertConfig) {
    const newTableListChange = {
      ...tableListChange,
      monitoring: {
        ...tableListChange.monitoring,
        alertConfig: newAlertConfig,
      },
    };
    updateSystemProfileSchemaRuleAlertingChannel(uuid, schemaInfo.uuid, {
      ...schemaInfo.profilerConfig,
      tableListChange: newTableListChange,
    });
  }

  function updateTableListChange(newTableListChange) {
    const newProfilerConfig = {
      ...schemaInfo.profilerConfig,
      tableListChange: newTableListChange,
    };

    updateSystemProfileSchemaRuleAlertingChannel(
      uuid,
      schemaInfo.uuid,
      newProfilerConfig
    ).then(() => {
      refreshTreeDataSourceNode(dataSource);
      refreshMetricsData();
    });
  }

  function onGoToIncidentList() {
    const userSetting = getDefaultUserSetting();
    const currentMetric = currentSchemaSummary.data?.chartData?.metric;
    const queryPeriod = currentSchemaSummary?.queryPeriod || {
      startTimestamp: 0,
      endTimestamp: 0,
    };
    userSetting.time = {
      currentInterval: "custom",
      startTime: fromUnixTime(queryPeriod.startTimestamp),
      endTime: fromUnixTime(queryPeriod.endTimestamp),
      current: fromUnixTime(queryPeriod.endTimestamp),
    };

    userSetting.localFilterSetting = {
      filterName: [],
      severity: [],
      dataSourceName: [datasourceName],
      tableName: [],
      kpiName: [currentMetric?.metadata?.name],
      slice: [],
      tagName: [],
      direction: [],
      monitorId: [],
      showMyRule: false,
      showProceededDataOnly: true,
    };

    const chartInfo = {
      autoMetricType: AutoMetricsType.TABLE_ACTIVITY,
      currentMetric: currentMetric || null,
      dataSourceInfo: dataSource,
      schemaInfo: schemaInfo,
      tableInfo: null,
      columnInfo: null,
    };
    onGoToIncidentPage({
      userSetting,
      rules: currentSchemaSummary.data?.chartData?.rules || [],
      chartInfo,
    });
  }

  function onEnableMonitorAllClicked(groupedAutoMetricsMapper, alertChannelConfig) {
    if (profilerCurrentMonitorObject.loading) {
      return;
    }

    trackEvent(EVENT.MONITOR_DEEP_METRICS, {
      ...getSchemaDetailProps(schemaInfo, dataSource),
      page: PAGE.EXPLORER_SCHEMA,
    });

    const { rules, profilerConfigUpdates } =
      buildNewRuleAndProfileConfigUpdateForSchema(
        alertChannelConfig,
        dataSource,
        schemaInfo,
        groupedAutoMetricsMapper,
        true
      );

    monitorAllForSchema({ rules, profilerConfigUpdates }).then(() => {
      getProfilerCurrentSchemaSummary(dataSource, schemaInfo, {
        isSummaryEnabled: canViewSummary,
        isEventChartEnabled: canViewEventChart,
      });
    });
  }

  function onAutoMetricsConfigureClick(table = []) {
    setTableTabSearchItem({ table });
    onTabChange(ProfilerSummaryTabKey.MANAGE_TABLES);
  }

  const isScanning = !schemaInfo.lastTablesScannedTs;

  const createdDate = schemaInfo.firstSeenTs
    ? getStringFromTimeStamp(schemaInfo.firstSeenTs)
    : "-";
  const lastScannedDate = schemaInfo.lastTablesScannedTs
    ? getStringFromTimeStamp(schemaInfo.lastTablesScannedTs)
    : "-";
  let totalTables = "-";
  let activeTables = "-";
  let metricCount = "-";
  let monitorCount = "-";
  const monitorSummary = {
    metricCount: 0,
    monitorCount: 0,
  };

  if (
    canViewSummary &&
    currentSchemaSummary &&
    currentSchemaSummary.data?.summary &&
    !currentSchemaSummary.loading &&
    !isScanning
  ) {
    totalTables = currentSchemaSummary.data.summary.totalTables;
    activeTables = currentSchemaSummary.data.summary.activeTables;
    metricCount = currentSchemaSummary.data.summary.metricCount;
    monitorCount = currentSchemaSummary.data.summary.monitorCount;
    monitorSummary.metricCount = metricCount;
    monitorSummary.monitorCount = monitorCount;
  }

  const profilerConfig = {
    type: "schema",
    dataSource,
    schema: schemaInfo,
  };

  const manageTabProps = {
    key: schemaInfo.uuid,
    workspaceUuid,
    config: profilerConfig,
    onOpenTableConfig,
    enableEdit: canAddMetric && canModifyMetric,
    enableCreateTable: canCreateTable,
    searchText: manageTabSearchText,
    setSearchText: setManageTabSearchText,
    availableNodeKeys,
    onCurrentSelectNodeChange,
  };

  const configTabs = [
    {
      key: ProfilerSummaryTabKey.SUMMARY,
      content: (
        <div className="profiler-schema-overview-body">
          <div className="profiler-schema-overview-body-left">
            <ProfilerStatsCardView
              title="Schema info"
              statsInfoList={[
                { label: "Tables", value: totalTables },
                { label: "Active tables", value: activeTables },
                { label: "Metrics", value: metricCount },
                { label: "Monitors", value: monitorCount },
              ]}
              extraInfoList={[
                { label: "Added to workspace", value: createdDate },
                { label: "Last scanned by Lightup", value: lastScannedDate },
              ]}
            />
            {canViewEventChart && !isScanning && (
              <ChartView
                key={schemaInfo.uuid}
                enableConfig={{
                  enableConfigMode: true,
                  enabled: tableListChange.enabled,
                  onClick: () =>
                    updateTableListChange({
                      ...tableListChange,
                      enabled: true,
                    }),
                }}
                monitorConfig={{
                  enableMetric: true,
                  singularMonitor: true,
                  onStartMonitor: (newConfig) => {
                    if (tableListChange.monitoring.enabled) {
                      return;
                    }

                    updateMonitorStatus(true, newConfig.alertChannelConfig);
                  },
                  onStopMonitor: () => {
                    if (!tableListChange.monitoring.enabled) {
                      return;
                    }

                    updateMonitorStatus(false);
                  },
                  monitorStatus: monitorStatus,
                  alertingChannelList,
                  currentAlertingChannels: alertConfig,
                  onAlertChannelChange: updateAlertChannel,
                  relatedRules: currentSchemaSummary.data?.chartData?.rules || [],
                  metricActionsOpts: {
                    includeDisable: true,
                  },
                }}
                onDisableMetricClick={() =>
                  updateTableListChange({
                    ...tableListChange,
                    enabled: false,
                  })
                }
                incidentConfig={{
                  onGoToIncidentList,
                }}
                title={EventMetricTypeName[AutoMetricsType.TABLE_ACTIVITY]}
                metricType={AutoMetricsType.TABLE_ACTIVITY}
                showSubTitle={true}
                metric={currentSchemaSummary.data?.chartData?.metric}
                loading={currentSchemaSummary.loading}
                data={currentSchemaSummary.data?.chartData?.data || []}
                incidents={currentSchemaSummary.data?.chartData?.incidents || []}
                config={{}}
                workspaceUserPermissions={workspaceUserPermissions}
              />
            )}
            {isScanning ? (
              <ProfilerEnableView
                fullHeight
                gridOnly={true}
                isInProgress={true}
                description={"Scanning schemas..."}
              />
            ) : (
              <ProfilerTableEnableView
                loading={currentSchemaSummary.loading || dataSourceTableList.loading}
                dataSource={dataSource}
                tables={dataSourceTableList.data}
                updateTableList={updateTableList}
                canModifySettings={canModifySettings}
              />
            )}
          </div>
          <div className="profiler-schema-overview-body-right">
            {canViewChecklist(workspaceUserPermissions) && (
              <ProfilerChecklistView
                config={{
                  workspaceUuid,
                  dataSource: dataSource,
                  schema: schemaInfo,
                }}
                monitorSummary={monitorSummary}
                onMonitorAll={onMonitorAll}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      key: ProfilerSummaryTabKey.CONFIGURE_DATA_PROFILES,
      content: <ProfilerSchemaConfigureDataProfiles {...manageTabProps} />,
    },
    {
      key: ProfilerSummaryTabKey.CONFIGURE_AUTO_METRICS,
      content: (
        <ProfilerSchemaConfigureAutometrics
          {...manageTabProps}
          onConfigure={onAutoMetricsConfigureClick}
        />
      ),
    },
    {
      key: ProfilerSummaryTabKey.MANAGE_TABLES,
      content: (
        <ProfilerSchemaManageTables
          {...manageTabProps}
          workspaceUserPermissions={workspaceUserPermissions}
          searchItem={tableTabSearchItem}
          onSearchItemChange={setTableTabSearchItem}
        />
      ),
    },
  ].filter(({ key }) => schemaNodeSupportsTabKey({ waffle, dataSource }, key));

  return (
    <div className="profiler-schema-overview-container">
      <ProfilerDataSourceSchemaOverviewHeader
        datasourceType={datasourceType}
        datasourceName={datasourceName}
        schema={schemaInfo.name}
        currentSelectNode={currentSelectNode}
        onCurrentSelectNodeChange={onCurrentSelectNodeChange}
        isTreeExpand={isTreeExpand}
        onTreeExpandChange={onTreeExpandChange}
      />
      <div className="profiler-schema-overview-body-container">
        <ProfilerSummaryTabView
          tabConfigs={configTabs}
          value={
            tabKey ||
            defaultTabKey(ProfilerTreeNodeType.SCHEMA, {
              workspaceUserPermissions,
              dataSource,
              schemaInfo,
            })
          }
          onChange={onTabChange}
        />
      </div>
      {monitorDialogContext && (
        <ProfilerMonitorAllDialog
          modalIsOpen={monitorDialogContext}
          setModalIsOpen={(isOpen) => !isOpen && setMonitorDialogContext(false)}
          alertingChannelList={alertingChannelList}
          data={profilerCurrentMonitorObject}
          okClicked={onEnableMonitorAllClicked}
        />
      )}
    </div>
  );
}

export default ProfilerSchemaOverview;
