import React from "react";
import { getAlertingChannelDisplayType } from "../../utils/alerting";
import "./index.scss";
import NgMultiSelect from "../multi-select/ng-multi-select";
import NgToggleCheckbox from "../toggle-checkbox/ng-toggle-checkbox";
import { SubmitType } from "../../views/integrations/integration-metadata";

function AlertConfigSettingView(props) {
  // Value is against alertConfig v1 model.
  const {
    value = {
      isMuted: false,
      channels: [],
      mutingSchedules: [],
    },
    onChange,
    alertingChannelList = [],
    scheduleList = [],
    enableMute = true,
    enableSchedule = true,
    readonly = false,
  } = props;

  const { isMuted = false, channels = [], mutingSchedules = [] } = value;

  const alertChannelOptions = [];
  alertingChannelList.forEach((currentAlertChannel) => {
    if (currentAlertChannel.mode === SubmitType.MANUAL) {
      return;
    }

    alertChannelOptions.push({
      label: `${getAlertingChannelDisplayType(currentAlertChannel.type)}: ${
        currentAlertChannel.title
      }`,
      value: currentAlertChannel.id,
    });
  });

  const scheduleOptions = scheduleList.map((currentSchedule) => {
    return {
      label: currentSchedule.title,
      value: currentSchedule.id,
    };
  });

  return (
    <div className="alert-config-setting-view-container">
      <div className="alert-config-setting-select-label">
        Select the channels to send notifications
      </div>
      <div className="alert-config-setting-select-container">
        <NgMultiSelect
          disabled={readonly}
          size="large"
          mode="multiple"
          value={channels.map(({ channelId }) => channelId)}
          placeholder={"Select"}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
          }
          options={alertChannelOptions}
          onChange={(newChannels) => {
            onChange &&
              onChange({
                channels: newChannels.map((channelId) => {
                  return {
                    channelId,
                  };
                }),
                mutingSchedules,
                isMuted,
              });
          }}
        />
      </div>
      {enableSchedule && (
        <>
          <div className="alert-config-setting-select-label alert-config-setting-schedule-select-label">
            Select notification muting schedule
          </div>
          <div className="alert-config-setting-select-indicator-label">
            Use schedules to control when notifications should be sent. Define a
            schedule under settings.
          </div>
          <div className="alert-config-setting-select-container">
            <NgMultiSelect
              disabled={readonly}
              size="large"
              value={mutingSchedules}
              placeholder={"Select"}
              filterOption={true}
              options={scheduleOptions}
              onChange={(newMutingSchedules) => {
                onChange &&
                  onChange({
                    isMuted,
                    channels,
                    mutingSchedules: newMutingSchedules,
                  });
              }}
            />
          </div>
        </>
      )}
      {enableMute && (
        <NgToggleCheckbox
          className="alert-config-setting-toggle-checkbox-container"
          size="mini"
          disabled={readonly}
          value={isMuted}
          onChange={() => {
            const newIsMuted = !isMuted;
            onChange &&
              onChange({
                channels: channels.map(({ channelId }) => {
                  return {
                    channelId,
                  };
                }),
                mutingSchedules,
                isMuted: newIsMuted,
              });
          }}
          label={"Mute notifications"}
          labelPosition={"left"}
        />
      )}
    </div>
  );
}

export default AlertConfigSettingView;
