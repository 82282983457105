import React from "react";
import { WhereConditionsConfigItem } from "./index";

function WhereClause(props) {
  const {
    disabled = false,
    configData,
    columnList,
    onConfigDataChange,
    schemaList,
    tableList,
  } = props;

  return (
    <WhereConditionsConfigItem
      disabled={disabled}
      value={configData.config.whereClause}
      columnList={columnList}
      schemaList={schemaList}
      tableList={tableList}
      onChange={(whereClause) => {
        onConfigDataChange({
          ...configData,
          config: {
            ...configData.config,
            whereClause,
            whereConditions: whereClause?.whereConditions ?? [],
          },
        });
      }}
      configData={configData}
    />
  );
}

export default WhereClause;
