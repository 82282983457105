import React from "react";
import AlationIntegrationBasicDialog from "./alation-integration-basic-dialog";
import { EditOutlined } from "@ant-design/icons";

function EditAlationIntegrationDialog(props) {
  const {
    data,
    onEdit,
    onPreview,
    modalIsOpen,
    setIsOpen,
    enableSave = true,
    enablePreview = true,
  } = props;

  const onOkClicked = (newData) => {
    onEdit(newData);
  };

  return (
    <AlationIntegrationBasicDialog
      defaultData={data}
      className={"edit-alation-integration-dialog-container"}
      dialogTitle={"Edit Alation Integration"}
      onOkClicked={onOkClicked}
      onPreview={onPreview}
      triggerComponent={<EditOutlined />}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      enableSave={enableSave}
      enablePreview={enablePreview}
    />
  );
}

export default EditAlationIntegrationDialog;
