import {
  getStringCompareFunction,
  stringListCompareFunction,
} from "../../../utils/search";
import { capitalizeFirstLetter } from "../../../utils/general";
import { DraftStateNameMap, DraftTypeNameMap } from "../../../utils/enums";
import { metricFields } from "./use-search-metrics-config";
export const GENERAL_SEARCH_FIELD = "searchName";
const stringCompareFunction = getStringCompareFunction(false);

export const draftFields = {
  DRAFT_ID: "draftId",
  DRAFT_NAME: "draftName",
  DRAFT_TYPE: "draftType",
  DRAFT_STATUS: "draftStatus",
  DRAFT_REQUEST_ACTION: "requestAction",
  DRAFT_TARGET_OBJECT: "targetObject",
  CREATED_BY: metricFields.CREATED_BY,
  LAST_MODIFY_BY: metricFields.LAST_MODIFY_BY,
};

export const draftFieldOptionValueGetterFn = {
  [draftFields.DRAFT_ID]: (draft) => draft.metadata.idSerial,
  [draftFields.DRAFT_NAME]: (draft) => draft.draftMetadata.name,
  [draftFields.DRAFT_TYPE]: (draft) => capitalizeFirstLetter(draft.type),
  [draftFields.DRAFT_STATUS]: (draft) =>
    DraftStateNameMap[draft.draftMetadata.state] || "",
  [draftFields.DRAFT_REQUEST_ACTION]: (draft) =>
    DraftTypeNameMap[draft.draftMetadata.type] || "",
  [draftFields.DRAFT_TARGET_OBJECT]: (draft) => draft.metadata.name,
  [draftFields.CREATED_BY]: (draft) => draft?.metadata.ownedBy?.username,
  [draftFields.LAST_MODIFY_BY]: (draft) => draft?.metadata.updatedBy?.username,
};

export const draftFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    return (
      searchName.length &&
      !stringListCompareFunction(
        [
          row.draftMetadata.name,
          row.type,
          row.draftMetadata.state,
          row.draftMetadata.type,
          row.metadata.name,
          row.metadata.ownedBy?.username,
          row.metadata.updatedBy?.username,
        ],
        searchName,
        stringCompareFunction
      )
    );
  },
  [draftFields.DRAFT_ID]: (row, draftId) => {
    return !draftId.includes(row.metadata.idSerial.toString());
  },
  [draftFields.DRAFT_NAME]: (row, draftName) => {
    return !draftName.includes(row.draftMetadata.name);
  },
  [draftFields.DRAFT_TYPE]: (row, draftType) => {
    return !draftType.includes(capitalizeFirstLetter(row.type));
  },
  [draftFields.DRAFT_STATUS]: (row, draftStatus) => {
    return !draftStatus.includes(DraftStateNameMap[row.draftMetadata.state] || "");
  },
  [draftFields.DRAFT_REQUEST_ACTION]: (row, requestAction) => {
    return !requestAction.includes(DraftTypeNameMap[row.draftMetadata.type] || "");
  },
  [draftFields.DRAFT_TARGET_OBJECT]: (row, targetObject) => {
    return !targetObject.includes(row.metadata.name);
  },
  [draftFields.CREATED_BY]: (row, createdBy) => {
    return !stringCompareFunction(row.metadata.ownedBy?.username, createdBy);
  },
  [draftFields.LAST_MODIFY_BY]: (row, lastModifyBy) => {
    return !stringCompareFunction(row.metadata.updatedBy?.username, lastModifyBy);
  },
};
