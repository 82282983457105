import { useCallback, useMemo } from "react";
import { metricTypeNames } from "../../metric/utils";

import { indexBy } from "../../../utils/iterables";
import { getSearchFieldConfig } from "./use-search-config";
import {
  metricFieldOptionValueGetterFn,
  metricFields,
  metricFilterFn,
} from "./use-search-metrics-config";
import {
  monitorFieldOptionValueGetterFn,
  monitorFields,
  monitorFilterFn,
} from "./use-search-monitors-config";
import {
  incidentFieldOptionValueGetterFn,
  incidentFields,
  incidentFilterFn,
  nestedIncidentFields,
} from "./use-search-incidents-config";
import { IncidentCreatorType } from "../../../utils/enums";
import {
  incidentDirectionOptions,
  incidentSeverityOptions,
  incidentStatusOptions,
  incidentValidationStatusOptions,
} from "../../../utils/options";
import {
  dataSourceFieldOptionValueGetterFn,
  dataSourceFields,
  dataSourceFilterFn,
} from "./use-search-datasource-config";
import {
  notificationFieldOptionValueGetterFn,
  notificationFields,
  notificationFilterFn,
} from "./use-search-notifications-config";
import {
  integrationFieldOptionValueGetterFn,
  integrationFields,
  integrationFilterFn,
} from "./use-search-integrations-config";
import {
  scheduleFieldOptionValueGetterFn,
  scheduleFields,
  scheduleFilterFn,
} from "./use-search-schedule-config";
import {
  tableFieldOptionValueGetterFn,
  tableFields,
  tableFilterFn,
} from "./use-search-table-config";
import {
  draftFieldOptionValueGetterFn,
  draftFields,
  draftFilterFn,
} from "./use-search-draft-config";

export const searchEntityType = {
  METRIC: "metric",
  MONITOR: "monitor",
  INCIDENT: "incident",
  DATASOURCE: "dataSource",
  NOTIFICATION: "notification",
  INTEGRATION: "integration",
  SCHEDULE: "schedule",
  TABLE: "table",
  DRAFT: "draft",
};

const searchFieldsMapping = {
  [searchEntityType.METRIC]: getSearchFieldConfig(Object.values(metricFields)),
  [searchEntityType.MONITOR]: getSearchFieldConfig(Object.values(monitorFields)),
  [searchEntityType.INCIDENT]: getSearchFieldConfig(Object.values(incidentFields)),
  [searchEntityType.DATASOURCE]: getSearchFieldConfig(Object.values(dataSourceFields)),
  [searchEntityType.NOTIFICATION]: getSearchFieldConfig(
    Object.values(notificationFields)
  ),
  [searchEntityType.INTEGRATION]: getSearchFieldConfig(
    Object.values(integrationFields)
  ),
  [searchEntityType.SCHEDULE]: getSearchFieldConfig(Object.values(scheduleFields)),
  [searchEntityType.TABLE]: getSearchFieldConfig(Object.values(tableFields)),
  [searchEntityType.DRAFT]: getSearchFieldConfig(Object.values(draftFields)),
};

const addToSet = (set, value) => {
  if (Array.isArray(value)) {
    value.forEach((v) => v && set.add(v));
  } else if (value) {
    set.add(value);
  }
};

const getSearchItemsArray = (searchItem) => {
  return Object.entries(searchItem)
    .map(([fieldName, searchTerms]) => {
      return {
        fieldName,
        searchTerms,
      };
    })
    .filter(
      (searchItem) =>
        Array.isArray(searchItem.searchTerms) && searchItem.searchTerms.length > 0
    );
};

const filterIncidents = (rules, searchItemsArray, options) => {
  const { showMyRule, userId } = options;
  let result = [];

  // row matcher function list
  const matcherFunctionList = searchItemsArray
    .filter(
      ({ fieldName }) =>
        typeof incidentFilterFn[fieldName] === "function" &&
        !nestedIncidentFields.includes(fieldName)
    )
    .map(({ fieldName, searchTerms }) => {
      const filterFn = incidentFilterFn[fieldName];
      return (row) => {
        const creatorIsFilter = row.creatorInfo.type === IncidentCreatorType.FILTER;
        return !filterFn(row, creatorIsFilter, searchTerms);
      };
    });

  if (showMyRule) {
    matcherFunctionList.push((row) => row.ownedBy === userId);
  }

  // nested incidents matcher function list
  const incidentNestedMatcherFunctionList = searchItemsArray
    .filter(
      ({ fieldName }) =>
        typeof incidentFilterFn[fieldName] === "function" &&
        nestedIncidentFields.includes(fieldName)
    )
    .map(({ fieldName, searchTerms }) => {
      const filterFn = incidentFilterFn[fieldName];
      return (incident, row) =>
        !filterFn(incident, row.creatorInfo.kpiInfo, searchTerms);
    });

  // nested incidents will also being filtered in case it matches with the nested incident matchers
  const incidentNestedMatcher = (row) => {
    if (incidentNestedMatcherFunctionList.length === 0) {
      return row;
    }
    const filteredIncidents = row.incidents.filter((incident) =>
      incidentNestedMatcherFunctionList.every((fn) => fn(incident, row))
    );
    if (filteredIncidents.length === 0) {
      return null;
    }
    return { ...row, incidents: filteredIncidents };
  };

  for (const rule of rules) {
    const nestedMatched = incidentNestedMatcher(rule);
    if (matcherFunctionList.every((fn) => fn(rule)) && nestedMatched) {
      result.push(nestedMatched);
    }
  }

  return result;
};

export default function useSearch({
  entityType,
  metrics,
  monitors,
  incidents,
  dataSources,
  notifications,
  integrations,
  schedules,
  tables,
  drafts,
  userId,
}) {
  const options = useMemo(() => {
    const fields = searchFieldsMapping[entityType];
    const fieldOptionsMap = {};

    fields.forEach((field) => {
      fieldOptionsMap[field.fieldName] = new Set();
    });

    if (entityType === searchEntityType.METRIC) {
      for (const metric of metrics) {
        for (const field of fields) {
          const getterFn = metricFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(metric);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.MONITOR) {
      const metricsByUuid = indexBy(metrics, (metric) => metric.metadata.uuid);
      for (const monitor of monitors) {
        for (const field of fields) {
          const metrics = monitor.config.metrics;
          const metric = metrics.length > 0 && metricsByUuid[metrics[0]];
          const getterFn = monitorFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(monitor, metric);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.INCIDENT) {
      for (const incident of incidents) {
        for (const field of fields) {
          const getterFn = incidentFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(incident);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.DATASOURCE) {
      for (const dataSource of dataSources) {
        for (const field of fields) {
          const getterFn = dataSourceFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(dataSource);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.NOTIFICATION) {
      for (const notification of notifications) {
        for (const field of fields) {
          const getterFn = notificationFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(notification);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.INTEGRATION) {
      for (const integration of integrations) {
        for (const field of fields) {
          const getterFn = integrationFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(integration);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.SCHEDULE) {
      for (const schedule of schedules) {
        for (const field of fields) {
          const getterFn = scheduleFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(schedule);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.TABLE) {
      for (const table of tables) {
        for (const field of fields) {
          const getterFn = tableFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(table);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    if (entityType === searchEntityType.DRAFT) {
      for (const draft of drafts) {
        for (const field of fields) {
          const getterFn = draftFieldOptionValueGetterFn[field.fieldName];
          const optionsSet = fieldOptionsMap[field.fieldName];
          if (getterFn) {
            const value = getterFn(draft);
            addToSet(optionsSet, value);
          }
        }
      }
    }

    const fieldOptionsArray = fields.map((field) => {
      const optionsSet = fieldOptionsMap[field.fieldName];
      return {
        label: field.title,
        type: field.fieldName,
        items: [...optionsSet],
      };
    });

    // Fields independent from entities values
    if (entityType === searchEntityType.INCIDENT) {
      fieldOptionsArray.unshift({
        label: "Direction",
        type: incidentFields.DIRECTION,
        items: incidentDirectionOptions.map((option) => option.label),
      });

      fieldOptionsArray.unshift({
        label: "Severity",
        type: incidentFields.SEVERITY,
        items: incidentSeverityOptions.map((option) => option.label),
      });

      fieldOptionsArray.unshift({
        label: "Status",
        type: incidentFields.INCIDENT_STATUS,
        items: incidentStatusOptions.map((option) => option.label),
      });

      fieldOptionsArray.unshift({
        label: "Validation status",
        type: incidentFields.VALIDATION_STATUS,
        items: incidentValidationStatusOptions.map((option) => option.label),
      });

      fieldOptionsArray.unshift({
        label: "Progress",
        type: incidentFields.INCIDENT_PROGRESS,
        items: ["Active", "Completed"],
      });
    }

    if (
      [
        searchEntityType.METRIC,
        searchEntityType.MONITOR,
        searchEntityType.INCIDENT,
      ].includes(entityType)
    ) {
      fieldOptionsArray.unshift({
        label: "Metric type",
        type: metricFields.METRIC_TYPE,
        items: Object.values(metricTypeNames),
      });
    }

    if (dataSources && dataSources.length > 0) {
      fieldOptionsArray.unshift({
        label: "Data source",
        type: metricFields.DATA_SOURCE_NAME,
        items: dataSources.map((dataSource) => dataSource.metadata.name),
      });
    }

    const filteredFieldOptions = fieldOptionsArray.filter(
      (field) => field.items.length > 0
    );
    return filteredFieldOptions;
  }, [
    metrics,
    monitors,
    incidents,
    entityType,
    dataSources,
    notifications,
    integrations,
    schedules,
    tables,
    drafts,
  ]);

  const filter = useCallback(
    (rows, searchItem) => {
      let result = [];
      let metricLookup = null;
      if (entityType === searchEntityType.MONITOR) {
        metricLookup = metrics
          ? indexBy(metrics, (metric) => metric.metadata.uuid)
          : null;
      }
      const searchItemsArray = getSearchItemsArray(searchItem);
      if (searchItemsArray.length === 0) {
        return rows;
      }

      if (entityType === searchEntityType.INCIDENT) {
        return filterIncidents(rows, searchItemsArray, {
          showMyRule: searchItem.showMyRule,
          userId,
        });
      }

      let matcherFunctionList = [];

      if (entityType === searchEntityType.METRIC) {
        matcherFunctionList = searchItemsArray
          .filter(({ fieldName }) => typeof metricFilterFn[fieldName] === "function")
          .map(({ fieldName, searchTerms }) => {
            const filterFn = metricFilterFn[fieldName];
            return (row) => !filterFn(row, searchTerms);
          });
      }

      if (entityType === searchEntityType.MONITOR) {
        matcherFunctionList = searchItemsArray
          .filter(({ fieldName }) => typeof monitorFilterFn[fieldName] === "function")
          .map(({ fieldName, searchTerms }) => {
            const filterFn = monitorFilterFn[fieldName];
            return (row) => {
              const monitorMetrics = row.monitorData.config.metrics;
              const metric =
                monitorMetrics?.length > 0 ? metricLookup[monitorMetrics[0]] : null;
              return !filterFn(row, metric, searchTerms);
            };
          });
      }

      if (entityType === searchEntityType.DATASOURCE) {
        matcherFunctionList = searchItemsArray
          .filter(
            ({ fieldName }) => typeof dataSourceFilterFn[fieldName] === "function"
          )
          .map(({ fieldName, searchTerms }) => {
            const filterFn = dataSourceFilterFn[fieldName];
            return (row) => !filterFn(row, searchTerms);
          });
      }

      if (entityType === searchEntityType.NOTIFICATION) {
        matcherFunctionList = searchItemsArray
          .filter(
            ({ fieldName }) => typeof notificationFilterFn[fieldName] === "function"
          )
          .map(({ fieldName, searchTerms }) => {
            const filterFn = notificationFilterFn[fieldName];
            return (row) => !filterFn(row, searchTerms);
          });
      }

      if (entityType === searchEntityType.INTEGRATION) {
        matcherFunctionList = searchItemsArray
          .filter(
            ({ fieldName }) => typeof integrationFilterFn[fieldName] === "function"
          )
          .map(({ fieldName, searchTerms }) => {
            const filterFn = integrationFilterFn[fieldName];
            return (row) => !filterFn(row, searchTerms);
          });
      }

      if (entityType === searchEntityType.SCHEDULE) {
        matcherFunctionList = searchItemsArray
          .filter(({ fieldName }) => typeof scheduleFilterFn[fieldName] === "function")
          .map(({ fieldName, searchTerms }) => {
            const filterFn = scheduleFilterFn[fieldName];
            return (row) => !filterFn(row, searchTerms);
          });
      }

      if (entityType === searchEntityType.TABLE) {
        matcherFunctionList = searchItemsArray
          .filter(({ fieldName }) => typeof tableFilterFn[fieldName] === "function")
          .map(({ fieldName, searchTerms }) => {
            const filterFn = tableFilterFn[fieldName];
            return (row) => !filterFn(row, searchTerms);
          });
      }

      if (entityType === searchEntityType.DRAFT) {
        matcherFunctionList = searchItemsArray
          .filter(({ fieldName }) => typeof draftFilterFn[fieldName] === "function")
          .map(({ fieldName, searchTerms }) => {
            const filterFn = draftFilterFn[fieldName];
            return (row) => !filterFn(row, searchTerms);
          });
      }

      if (matcherFunctionList.length === 0) {
        return rows;
      }

      for (const row of rows) {
        // We will only include rows that match all conditions
        if (matcherFunctionList.every((fn) => fn(row))) {
          result.push(row);
        }
      }
      return result;
    },
    [entityType, metrics, userId]
  );

  return {
    options,
    filter,
  };
}
