import React, { useState } from "react";

import { NextGenPalette } from "../../utils/color";
import { workspaceIconNameToIconMapper } from "../../utils/icon";

import "./workspace-icon-selector.scss";

function Arrow(props) {
  const { clickable = true, onClick, left, right } = props;
  const fill = clickable ? NextGenPalette.systemBlack : NextGenPalette.darkGrey;
  const clickableClass = clickable ? "clickable" : "";
  return (
    <button
      className={`ws-icon-selector-arrow-container ${clickableClass}`}
      onClick={() => {
        if (clickable) {
          onClick();
        }
      }}
    >
      {right && (
        <svg
          width="5"
          height="8"
          viewBox="0 0 5 8"
          fill={fill}
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.68549 3.43907C4.94947 3.82815 4.90908 4.36203 4.56431 4.7068L1.73588 7.53523C1.34536 7.92576 0.712192 7.92576 0.321668 7.53523C-0.0688565 7.14471 -0.0688565 6.51154 0.321668 6.12102L2.44299 3.9997L0.321641 1.87835C-0.0688834 1.48782 -0.0688836 0.854657 0.321641 0.464132C0.712165 0.0736079 1.34533 0.0736079 1.73585 0.464132L4.56428 3.29256C4.56917 3.29745 4.574 3.30237 4.57876 3.30734C4.61862 3.34884 4.65419 3.39294 4.68549 3.43907Z"
            fill="{fill}"
          />
        </svg>
      )}
      {left && (
        <svg
          width="5"
          height="8"
          viewBox="0 0 5 8"
          fill={fill}
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.314513 4.56093C0.0505309 4.17185 0.0909219 3.63797 0.435692 3.2932L3.26412 0.464768C3.65464 0.0742439 4.28781 0.0742439 4.67833 0.464768C5.06886 0.855293 5.06886 1.48846 4.67833 1.87898L2.55701 4.0003L4.67836 6.12165C5.06888 6.51218 5.06888 7.14534 4.67836 7.53587C4.28784 7.92639 3.65467 7.92639 3.26415 7.53587L0.435718 4.70744C0.43083 4.70255 0.426003 4.69763 0.421238 4.69266C0.381384 4.65116 0.345809 4.60706 0.314513 4.56093Z"
            fill={fill}
          />
        </svg>
      )}
    </button>
  );
}

const iconEntries = Object.entries(workspaceIconNameToIconMapper);

function WorkspaceIconSelector(props) {
  const { onChange, maxScroll = 4, value, editable } = props;
  const initialSelectedIcon = value ?? iconEntries[0][0];
  let initialScroll = iconEntries.findIndex((iconEntry) => {
    return iconEntry[0] === initialSelectedIcon;
  });
  initialScroll = Math.max(0, initialScroll - maxScroll);

  const [scrollPos, setScrollPos] = useState(initialScroll);
  const [selectedIcon, setSelectedIcon] = useState(initialSelectedIcon);

  // Icons are 78px wide + margin, so each arrow click scrolls one icon-width.
  const scrollOffset = -(scrollPos * 85);

  return (
    <div className="ws-icon-selector">
      <div className="ws-icon-selector-header">
        <h6 className="typo-h6">Icon Types</h6>
        <div className="ws-icon-selector-arrows">
          <Arrow
            left
            clickable={scrollPos > 0}
            onClick={() => {
              setScrollPos(scrollPos - 1);
            }}
          />
          <Arrow
            right
            clickable={scrollPos < maxScroll}
            onClick={() => {
              setScrollPos(scrollPos + 1);
            }}
          />
        </div>
      </div>
      <div
        className="ws-icon-selector-icon-container"
        style={{ transform: `translateX(${scrollOffset}px)` }}
      >
        {iconEntries.map((iconEntry) => {
          const [iconName, Icon] = iconEntry;
          const selectedClass = iconName === selectedIcon ? "selected" : "";
          const editableClass = editable ? "editable" : "";
          return (
            <div
              key={iconName}
              className={`ws-icon-selector-icon ${selectedClass} ${editableClass}`}
              onClick={() => {
                if (!editable) {
                  return;
                }
                setSelectedIcon(iconName);
                onChange && onChange(iconName);
              }}
            >
              <Icon />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default WorkspaceIconSelector;
