import React, { useMemo } from "react";
import {
  LabeledInput,
  LabeledPartitionSelect,
} from "../labeled-control/labeled-control";
import { AntdDeleteIcon } from "../icons/delete-icon/";
import { BorderedFields } from "../metric/fields";

import "./index.scss";

export default function PartitionConfigItem(props) {
  const {
    value = [],
    onChange,
    columnList = [],
    className = "",
    disabled = false,
    partitionSampleData = null,
    direction = "horizontal",
    hideColumnName = false,
  } = props;

  const { columnNameToPartitionSampleMapper, columnNameToSuggestedFormatMapper } =
    useMemo(() => {
      const result = {
        columnNameToPartitionSampleMapper: {},
        columnNameToSuggestedFormatMapper: {},
      };
      if (!partitionSampleData?.loading && partitionSampleData?.data) {
        if (partitionSampleData.data.data.length > 0) {
          for (let currentSampleItem of partitionSampleData.data.data) {
            for (let currentSampleItemColumnName in currentSampleItem) {
              if (
                !result.columnNameToPartitionSampleMapper.hasOwnProperty(
                  currentSampleItemColumnName
                )
              ) {
                result.columnNameToPartitionSampleMapper[currentSampleItemColumnName] =
                  [];
              }

              let currentSampleItemData =
                currentSampleItem[currentSampleItemColumnName];
              if (
                result.columnNameToPartitionSampleMapper[
                  currentSampleItemColumnName
                ].indexOf(currentSampleItemData) !== -1
              ) {
                continue;
              }

              result.columnNameToPartitionSampleMapper[
                currentSampleItemColumnName
              ].push(currentSampleItemData);
            }
          }
        }

        if (partitionSampleData.data.inferred_format) {
          result.columnNameToSuggestedFormatMapper =
            partitionSampleData.data.inferred_format;
        }
      }
      return result;
    }, [partitionSampleData]);

  return (
    <div
      className={`ng-partition-config-list-container ${className} ${
        disabled ? "disabled" : ""
      }`}
    >
      {value.map((currentPartition, index) => {
        const { columnName = "", format = "" } = currentPartition;

        let partitionSampleString = "";
        if (partitionSampleData && columnName) {
          if (partitionSampleData.loading) {
            partitionSampleString = "Fetching sample data…";
          } else if (
            columnNameToPartitionSampleMapper[columnName] &&
            columnNameToPartitionSampleMapper[columnName].length > 0
          ) {
            partitionSampleString = `Sample: ${columnNameToPartitionSampleMapper[columnName][0]}`;
          }
        }

        return (
          <BorderedFields key={index}>
            <div className="partition-config-item-container">
              <div
                className={`partition-config-item-column-outer-container ${direction}`}
              >
                {!hideColumnName && (
                  <div className="partition-config-item-column-container">
                    <LabeledPartitionSelect
                      staticLabel
                      label={`partition ${index + 1}`}
                      value={columnName}
                      columnList={columnList}
                      onChange={(newColumnName) => {
                        if (newColumnName === columnName) {
                          return;
                        }

                        const currentColumn = columnList.find(
                          ({ columnName: currentColumnName }) =>
                            newColumnName === currentColumnName
                        );

                        let newFormat = "";
                        if (columnNameToSuggestedFormatMapper[newColumnName]) {
                          newFormat = columnNameToSuggestedFormatMapper[newColumnName];
                        } else if (currentColumn?.columnType === "date") {
                          newFormat = "%Y-%m-%d";
                        }

                        value[index] = {
                          columnName: newColumnName,
                          format: newFormat,
                        };
                        onChange([...value]);
                      }}
                    />
                    {partitionSampleString && (
                      <div className="partition-config-item-column-description-container">
                        {partitionSampleString}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`partition-config-item-column-container ${
                    hideColumnName ? "full" : ""
                  }`}
                >
                  <LabeledInput
                    staticLabel
                    label="format"
                    value={format}
                    onChange={(e) => {
                      const newFormat = e.target.value;
                      if (newFormat !== format) {
                        value[index] = { columnName, format: newFormat };
                        onChange([...value]);
                      }
                    }}
                  />
                  <div className="partition-config-item-column-description-container">
                    The format is based on the{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.python.org/3/library/datetime.html#strftime-and-strptime-format-codes"
                    >
                      1989 C standard
                    </a>
                    .
                  </div>
                </div>
              </div>
              <AntdDeleteIcon
                onClick={() => {
                  value.splice(index, 1);
                  onChange([...value]);
                }}
              />
            </div>
          </BorderedFields>
        );
      })}
    </div>
  );
}
