import { EVENT, trackEvent } from "../../utils/telemetry";

const getEventByKey = (type) => {
  switch (type) {
    case "explorer":
      return EVENT.SELECT_EXPLORER_TAB;

    case "metrics":
      return EVENT.SELECT_METRICS_TAB;

    case "monitors":
      return EVENT.SELECT_MONITORS_TAB;

    case "incidents":
      return EVENT.SELECT_INCIDENTS_TAB;

    case "dashboard":
      return EVENT.SELECT_DASHBOARD_TAB;

    default:
      return null;
  }
};

export const trackWorkspaceHeaderClick = (currentKey, props = {}) => {
  const eventName = getEventByKey(currentKey);

  if (eventName) {
    trackEvent(eventName, { ...props });
  }
};
