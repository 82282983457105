import { getTimePeriodFromSetting } from "../../utils/time";
import { DashboardFilterType, TimeDurationUnit } from "../../utils/enums";

export function getQueryObject(timeSetting) {
  const normalizedTimePeriod = getTimePeriodFromSetting(timeSetting);
  const queryObject = { customerOptions: { rejected: true } };
  if (!normalizedTimePeriod) {
    return queryObject;
  }

  return {
    startTime: normalizedTimePeriod.startTimestamp,
    endTime: normalizedTimePeriod.endTimestamp,
    ...queryObject,
  };
}

function getFilterTimeSummary(timeSetting) {
  if (timeSetting.type === DashboardFilterType.TIME_DURATION) {
    const durationValue = timeSetting.value.duration;
    const { value: numberValue, unit } = durationValue;
    let unitDescriptionMsg = "";
    if (unit === TimeDurationUnit.SECONDS) {
      unitDescriptionMsg = "second";
    } else if (unit === TimeDurationUnit.MINUTES) {
      unitDescriptionMsg = "minute";
    } else if (unit === TimeDurationUnit.HOURS) {
      unitDescriptionMsg = "hour";
    } else if (unit === TimeDurationUnit.DAYS) {
      unitDescriptionMsg = "day";
    } else if (unit === TimeDurationUnit.WEEKS) {
      unitDescriptionMsg = "week";
    } else if (unit === TimeDurationUnit.MONTHS) {
      unitDescriptionMsg = "month";
    } else if (unit === TimeDurationUnit.YEARS) {
      unitDescriptionMsg = "year";
    } else {
      return "";
    }

    if (numberValue > 1) {
      unitDescriptionMsg = `${unitDescriptionMsg}s`;
    }

    return `${numberValue} ${unitDescriptionMsg}`;
  }

  return "";
}

export function getFilterSummaryMsg(filterList) {
  let summaryArray = [];
  let timeSummary = "";
  filterList = filterList || [];
  for (let currentFilter of filterList) {
    if (currentFilter.type === DashboardFilterType.DATASOURCES) {
      summaryArray.push(`datasource (${currentFilter.value.strList.length})`);
    } else if (currentFilter.type === DashboardFilterType.METRICS) {
      summaryArray.push(`metric (${currentFilter.value.strList.length})`);
    } else if (currentFilter.type === DashboardFilterType.MONITORS) {
      summaryArray.push(`monitor (${currentFilter.value.strList.length})`);
    } else if (currentFilter.type === DashboardFilterType.SEVERITY) {
      summaryArray.push(`severity (${currentFilter.value.intList.length})`);
    } else if (currentFilter.type === DashboardFilterType.DIRECTION) {
      summaryArray.push(`direction (${currentFilter.value.intList.length})`);
    } else if (currentFilter.type === DashboardFilterType.STATUS) {
      summaryArray.push(`status (${currentFilter.value.intList.length})`);
    } else if (currentFilter.type === DashboardFilterType.TAGS) {
      summaryArray.push(`tag (${currentFilter.value.tagList.length})`);
    } else if (currentFilter.type === DashboardFilterType.VALIDATION_STATUS) {
      summaryArray.push(`validation (${currentFilter.value.strList.length})`);
    } else if (currentFilter.type === DashboardFilterType.TIME_DURATION) {
      timeSummary = getFilterTimeSummary(currentFilter);
    }
  }

  return {
    filterSummary: summaryArray.join(" "),
    timeSummary,
  };
}
