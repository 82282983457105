import React from "react";
import { getStringFromTimeStamp } from "../../../../utils/time";
import { ProfilerStatsCardView } from "../../profiler-stats-card-view";

function TableInfoSummary(props) {
  const { tableInfo } = props;
  const {
    firstSeenTs,
    lastScannedTs,
    indexed,
    partitioned,
    metricsCount,
    activeMetricsCount,
    totalMonitorsCount,
    activeMonitorsCount,
  } = tableInfo;

  return (
    <ProfilerStatsCardView
      title="Table info"
      statsInfoList={[
        { label: "Partitioned", value: partitioned ? "Yes" : "No" },
        { label: "Indexed", value: indexed ? "Yes" : "No" },
        {
          label: "Total metrics",
          value: metricsCount,
          expandItems: [
            { label: "Live metrics", value: activeMetricsCount },
            { label: "Paused metrics", value: metricsCount - activeMetricsCount },
          ],
        },
        {
          label: "Total monitors",
          value: totalMonitorsCount,
          expandItems: [
            { label: "Live monitors", value: activeMonitorsCount },
            {
              label: "Paused monitors",
              value: totalMonitorsCount - activeMonitorsCount,
            },
          ],
        },
      ]}
      extraInfoList={[
        { label: "Added to workspace", value: getStringFromTimeStamp(firstSeenTs) },
        {
          label: "Last scanned by Lightup",
          value:
            typeof lastScannedTs === "number"
              ? getStringFromTimeStamp(lastScannedTs)
              : "N/A",
        },
      ]}
    />
  );
}

export default TableInfoSummary;
